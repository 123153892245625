class NewServiceInfo {
  constructor(supplierId, info) {
    this.supplierId = supplierId;
    this.id = info._id;
    this.serviceName = info.serviceName;
    this.category = info.category;
    this.serviceDetails = info.serviceDetails;
    this.locations = info.locations;
    this.description = info.description;
    this.capacity = { qty: info.qty, uom: info.uom };
    this.qty = info.qty;
    this.uom = info.uom;
    this.addedBy = "";
    this.isUpdate = false;

    this.usps = [];
    if (info.usp1?.length > 0) this.usps.push(info.usp1);
    if (info.usp2?.length > 0) this.usps.push(info.usp2);
    if (info.usp3?.length > 0) this.usps.push(info.usp3);
    if (info.usp4?.length > 0) this.usps.push(info.usp4);
    if (info.usp5?.length > 0) this.usps.push(info.usp5);
    if (info.usp6?.length > 0) this.usps.push(info.usp6);

  }
}
export default { NewServiceInfo };
export { NewServiceInfo };
