import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import DashboardLayout from "layouts/Dashboard";
import Page from "views/UserProfile/components/Page";
import { Helmet } from "react-helmet";
import RequirementForm from "../components/RequirementForm";

import {
  Grid,
  TextField,
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Typography,
  Button,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { headCells } from "./helper";

function SupplierList({ suppliers }) {
  const [pageSize, setPageSize] = useState(5);

  return (
    <Grid item md={12}>
      <Card>
        <CardHeader title="Enquiries sent to Organizations" />
        <br />
        <Divider />

        <CardContent>
          <Grid item xs={12} md={12}>
            <Box sx={{ height: 400 }}>
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnsPanel": {
                    backgroundColor: "red",
                  },
                }}
                // eslint-disable-next-line no-underscore-dangle
                getRowId={(row) => row._id}
                rows={suppliers ? suppliers : []}
                columns={headCells}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
              />
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

SupplierList.propTypes = {
  suppliers: PropTypes.array,
};

export default SupplierList;
