export function getAddressHO(addresses) {
  let address = undefined;
  addresses?.map((item) => {
    if (item.addressType === "HEAD OFFICE") {
      address = { ...item };
    }
  });

  // console.log("getAddressHO return", address);
  return address;
}

export function getAddressStr(address) {
  let addressStr = "";
  if (address) {
    if (address.addressLine1 && address.addressLine1 !== ""){
        addressStr += address.addressLine1 + ", ";
    }
    if (address.addressLine2 && address.addressLine2 !== ""){
      addressStr += address.addressLine2 + ", ";
    }
    
    addressStr += address.city + ", " + address.state + ", ";
    addressStr += address.country + ", " + address.pincode + ".";
  }
  return addressStr;
}
