import React, { useState } from 'react'
import { Grid, Typography, TextField, FormHelperText, Box, useMediaQuery, useTheme, } from '@mui/material';
import { Formik } from 'formik';
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import axios from "utils/axios";
import LogInMobilePopUp from 'views/Auth/SignInSendMobileOTP/LogInMobilePopUp';

const HeroSection = ({ interest, ...rest }) => {
    const [openLogInModal, setOpenLogInModal] = useState(false);
    const [thankYoumsg, setThankYoumsg] = useState(false);
    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;
    const phoneNumber = "+919503339814";
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"))
    const handleClick = () => setOpenLogInModal(true)
    const handleCloseLogInModal = () => setOpenLogInModal(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required')
            // .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed in the name field'),
            .matches(/^[a-zA-Z\s]*$/, "Name should contain only alphabets"),
        number: Yup.number()
            .typeError('Mobile number must be a number')
            .required('Mobile number is required')
            .test(
                'len',
                'Mobile number must be 10 digits',
                (val) => val && val.toString().length === 10
            ),
    });

    return (

        <Formik
            initialValues={{ name: '', number: "", interestShown: interest === "Get Started Free" ? "Get Started Free" : "Book a Demo" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const response = await axios.post("/api/auth/demo/request", values);
                    if (response.data.error === false) {
                        setThankYoumsg(true)
                    }
                } catch (error) {
                    console.error("Error:", error);
                    setSubmitting(false);
                }
            }}
        >
            {({ handleSubmit, values, handleChange, errors, touched, handleBlur, }) => (
                <>
                    <Grid container sx={{ padding: isMd ? "45px 21px 24px 21px" : "25px 45px 45px 45px", display: "flex", alignItems: "center", justifyContent: isMd ? "center" : "space-between", backgroundColor: "#FBF8F6" }} >
                        <Grid >
                            <Typography
                                sx={{
                                    fontFamily: "PP Telegraf",
                                    fontWeight: 800,
                                    fontSize: isMd ? "36px" : "54px",
                                    lineHeight: "67px",
                                    letterSpacing: "-1.44px",
                                    color: "#111827",
                                    marginBottom: "29px",
                                    textAlign: isMd ? "center" : ""
                                }}
                            >Get Started</Typography>
                            <Grid sx={{ maxWidth: isMd ? "496px" : "477px" }}>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontFamily: "Roboto",
                                        fontSize: isMd ? "22px" : "24px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "32px",
                                        textAlign: isMd ? "center" : ""
                                    }}
                                >Intelligent vendor sourcing & matching platform</Typography>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontFamily: "Roboto",
                                        fontSize: isMd ? "18px" : "20px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "28px",
                                        margin: "25px 0px",
                                        textAlign: isMd ? "center" : ""
                                    }}
                                >
                                    Contact us for more information, for a personalized demo, or to speak with our team of experts.
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontFamily: "Roboto",
                                        fontSize: isMd ? "18px" : "20px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "28px",
                                        textAlign: isMd ? "center" : ""
                                    }}
                                >
                                    Want to talk to sales right now? +91-9503339814
                                </Typography>
                            </Grid>
                        </Grid >
                        {thankYoumsg === false && <form onSubmit={handleSubmit}{...rest}>
                            <Grid
                                sx={{
                                    maxWidth: "457px",
                                    height: "512px",
                                    flexShrink: 0,
                                    backgroundColor: "#FFF",
                                    padding: isMd ? "24px 37px" : "28px 35px 38px 35px",
                                    marginTop: isMd ? "54px" : ""
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontFamily: "PP Telegraf",
                                        fontSize: isMd ? "24px" : "32px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: isMd ? "28px" : "39px",
                                    }}
                                >Search vendors & manage RFQs all from one place</Typography>

                                <Grid
                                    margin={"25px 0px 56px 0px"}
                                    display={"flex"}
                                    gap={"16px"}
                                    flexDirection={"column"}>
                                    <Grid >
                                        <label
                                            style={{
                                                color: "#5E5873",
                                                fontFamily: "Roboto",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "17.4px", /* 145% */
                                                letterSpacing: "0.14px",
                                            }}
                                        >Name</label>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                size="small"
                                                placeholder="Name"
                                            />
                                            {touched.name && errors.name && (
                                                <FormHelperText sx={{ color: "red", marginLeft: "10px" }}>{errors.name}</FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid >
                                        <label
                                            style={{
                                                color: "#5E5873",
                                                fontFamily: "Roboto",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "17.4px", /* 145% */
                                                letterSpacing: "0.14px",
                                            }}
                                        >Number</label>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                name="number"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                                    handleChange({ target: { name: 'number', value: numericValue } });
                                                }}
                                                value={values.number}
                                                size="small"
                                                placeholder="Number"
                                            />
                                            {touched.number && errors.number && (
                                                <FormHelperText sx={{ color: "red", marginLeft: "10px" }}>{errors.number}</FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid sx={{ textAlign: "center" }}>
                                    <Box
                                        onClick={handleSubmit}
                                        type='submit'
                                        variant="contained"
                                        color="secondary"

                                        sx={{
                                            borderRadius: "5px",
                                            width: "100%", textAlign: "end",
                                            backgroundColor: "#FD6600",
                                            color: "white",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            fontFamily: "Roboto",
                                            lineHeight: "14px",
                                            fontStyle: "normal",
                                            padding: "20px 0px",
                                            cursor: "pointer",
                                            "&:hover": {
                                                backgroundColor: "#FD6600",
                                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", 
                                                transition: "background-color 0.3s, box-shadow 0.3s"
                                            },
                                        }}
                                    >
                                    Submit
                                    </Box>
                                </Grid>

                                {!userProfile && <Typography
                                    sx={{
                                        color: "#000",
                                        fontFamily: "Roboto",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "17.4px",
                                        letterSpacing: "0.14px",
                                        marginTop: "14px"
                                    }}
                                >You already have your Business registered?
                                    <span
                                        onClick={handleClick}
                                        style={{
                                            cursor: "pointer",
                                            color: "#3E69A3",
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "17.4px",
                                            letterSpacing: "0.14px",
                                            textDecorationLine: "underline",
                                        }}
                                    >Login</span>
                                </Typography>}


                            </Grid>
                        </form>}

                        {thankYoumsg && <Grid
                            sx={{
                                maxWidth: "475px",
                                height: "512px",
                                backgroundColor: "#FFF",
                                padding: isMd ? "58px 21px 49px 21px" : "73px 33px 85px 33px",
                                marginTop: isMd ? "54px" : ""
                            }}
                        >

                            <Typography
                                sx={{
                                    color: "#FD6600",
                                    textAlign: "center",
                                    fontFamily: "PP Telegraf",
                                    fontSize: isMd ? "28px" : "36px",
                                    fontStyle: "normal",
                                    fontWeight: 800,
                                    lineHeight: "39px",
                                }}>Thank you ! </Typography>
                            <Typography sx={{
                                color: "#000",
                                fontFamily: " PP Telegraf",
                                fontSize: isMd ? "22px" : "32px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "39px",
                                textAlign: "center"
                            }}>

                                Your demo request has been submitted successfully.
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#000",
                                    textAlign: "center",
                                    fontFamily: "Roboto",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    lineHeight: "30px",
                                    marginTop: "46px",
                                    maxWidth: "398px"
                                }}>our sales executives will get in touch with you shortly . In the meantime, if you have any urgent questions or specific requirements, feel free to contact our support team at <span
                                    style={{
                                        color: "#000",
                                        fontFamily: "Roboto",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "30px",
                                    }}
                                >demo@neevay.com</span> or call us at {" "}
                                <span
                                    style={{
                                        color: "#000",
                                        fontFamily: "Roboto",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "30px",
                                    }}
                                > <a href={`tel:${phoneNumber}`}>{phoneNumber}</a></span></Typography>

                        </Grid>}
                    </Grid >
                    <LogInMobilePopUp
                        setOpenLogInModal={setOpenLogInModal}
                        openLogInModal={openLogInModal}
                        handleCloseLogInModal={handleCloseLogInModal}
                    />

                </>
            )
            }
        </Formik >



    )
}

export default HeroSection