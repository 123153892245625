import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from "utils/axios";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import { Helmet } from "react-helmet";
import RequirementsList from "./RequirementsList";
import AddIcon from "@mui/icons-material/Add";

import * as XLSX from "xlsx";
import IosShareIcon from "@mui/icons-material/IosShare";
import { dateFormatter } from "utils/formatUtils";

function Requirements() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const requirement = useSelector((state) => state.requirement);
  const loading = requirement.loading;
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  if (!userProfile) {
    navigate("/register");
  } else if (userProfile?.userId > 0 && userProfile?.name) {
  } else {
    navigate("/register/user");
  }
  useEffect(() => {
    //console.log("Requirements is refreshed:", requirement);
  }, [requirement?.requirements]);

  // TODO_SP: Check if there is need to pull data, everytime user lands on the page

  // export data in excel format code start from here
  const [newRfqDataExcel, setNewRfqDataExcel] = useState([]);
  const [showError, setShowError] = useState(false);
  // const WhitelistUser = [1459, 939];
  const WhitelistUser = [89 ,90, 91, 92, 94, 95, 96, 97, 98, 126, 1010, 1504, 1507, 5145, 8873, 8874, 9373, 9591, 9609, 9610, 9611, 12113, 12125, 12225, 12236]
  
  const exportToExcel = () => {
    if (newRfqDataExcel.length < 1) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    } else {
      setShowError(false);
      const modifiedData = newRfqDataExcel.map((data) => {
        const newObject = {
          // "Enquiry Date": new Date(data.createDate).toLocaleDateString(),
          "Enquiry Date": dateFormatter(data.createDate),
          Label: data.name,
          "Vendor Name": data.businessName,
          Status: data.status,
          // "Supply by": new Date(data.workStartDate).toLocaleDateString(),
          "Supply by": dateFormatter(data.workStartDate),
          "Project Location": data.location,
          "Credit Period": data.creditPeriod,
          "Enquiry Id": data.rfqId,
        };

        if (WhitelistUser.some((user) => user === userProfile.userId)) {
          newObject.email = data?.email;
          newObject.mobile = data?.mobile;
        }
        return newObject;
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedData);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Business Enquiries");

      XLSX.writeFile(workbook, `EnquiriesData.xlsx`);
    }
  };
  // export data in excel format code end here

  return (
    <DashboardLayout path="/requirements">
      <Helmet>
        <title>Neevay - RFQs</title>
      </Helmet>

      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container height="calc(100vh - 64px)" alignItems="baseline">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginX: "20px",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                Business Enquiries
              </Typography>

              {/* <Button
                // color="secondary"
                variant="contained"
                onClick={exportToExcel}
                sx={{
                  borderRadius: "10px",
                  padding: 0,
                  height: "28px",
                  width: "104px",
                  backgroundColor: "#04EEAD",
                  "&:hover": {
                    backgroundColor: "#4CF0C0",
                  },
                }}
              >
                <Typography
                  fontSize="16px"
                  paddingX="12px"
                  color="black"
                  paddingLeft="10px"
                  fontWeight="600"
                >
                  Export
                </Typography>
                <IosShareIcon
                  fontSize="medium"
                  sx={{
                    color: "black",
                    height: "100%",
                    padding: "4px",
                    paddingRight: "10px",
                    width: "1.4em",
                  }}
                />
              </Button> */}
              {/* <Button href="/add-requirement" color="secondary" variant="contained"
                sx={{
                  borderRadius: "10px",
                  padding: 0,
                  height: "36px",
                }}>
                <Typography fontSize="16px" paddingX="12px" >
                  Create New Requirement
                </Typography>
                <AddIcon
                  fontSize="medium"
                  sx={{
                    color: "rgba(255, 255, 255, 1)",
                    height: "100%",
                    padding: "4px",
                    backgroundColor: "#fbfbfb5e",
                    width: "1.4em"
                  }}
                />

              </Button> */}


            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {showError && (
                <Typography color="red" fontSize="12px">
                  Select at least one enquiry to export
                </Typography>
              )}
            </Grid>
            <Grid item sm={12} fullWidth height={"90%"} mt={"12px"}>
              <RequirementsList
                title="Active Requirements"
                loading={loading}
                requirements={requirement?.requirements?.filter(
                  (row) =>
                    !(row.status === "CLOSED" || row.status === "EXPIRED")
                )}
                setNewRfqDataExcel={setNewRfqDataExcel}
              />
            </Grid>
            {/* <Grid item sm={12} mt={4} fullWidth>
              <RequirementsList
                title="Closed Requirements"
                requirements={requirement?.requirements?.filter(
                  (row) => (row.status === "CLOSED" || row.status === "EXPIRED")
                )}
              />
            </Grid> */}
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
  );
}

export default Requirements;
