// import { Button, Grid, TextField, Typography } from '@mui/material'
// import React from 'react'
// import InputAdornment from '@material-ui/core/InputAdornment';
// import { Link } from 'react-router-dom';

// function AddClientsForm() {
//     return (
//         <Grid display={"flex"} gap={"16px"} flexDirection={"column"} sx={{ marginTop: "21px", textAlign: "start", width: "90%", margin: "25px" }}>
//             <Typography style={{fontSize:"24px", color:"#5E5873"}}>Top Clients</Typography>

//             <Grid>
//                 <TextField
//                 size='small'
//                 fullWidth
//                 placeholder='Clients'
//                 InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                        <Button
//                        color='secondary'
//                        variant='contained'
//                        sx={{
//                         borderRadius:"5px",
//                         width:"65px",
//                         height:"25px"
//                        }}
//                        >
//                         Add
//                        </Button>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//             </Grid>

//             <Grid display={"flex"} justifyContent={"end"} marginTop={"200px"} gap={2} >
//                     <Link style={{color:"#5F5959", fontSize:"14px", textDecoration:"underline"}}>Skip for Now</Link>
//                     <Button sx={{width:"65px",height:"27px", borderRadius:"5px"}} variant='contained'color='secondary'>Next</Button>
//                 </Grid>

//         </Grid>
//     )
// }

// export default AddClientsForm

import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Box,
  FormHelperText,
  Button,
  Autocomplete,
  Typography,
  MenuItem,
  Select,
  FormControl,
  TextareaAutosize,
  Chip,
  Paper,
  InputAdornment,
} from "@mui/material";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { addMultipleClient } from "actions/clientAction";
import { updateStepperCount } from "actions/businessActions";

function AddClientsForm({ ...rest }) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState([]);

  const navigate = useNavigate();

  const business = useSelector((state) => state.business);
  const supplierId = business.profile?.supplierId;
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddChip = () => {
    if (inputValue.trim() !== "") {
      setChips([...chips, inputValue]);
      setInputValue("");
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };

  const containerRef = useRef(null);

  // This useEffect will run whenever chips are added or removed
  useEffect(() => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [chips]);

  return (
    <Grid sx={{ maxWidth: "100vw" }}>
      <Formik
        initialValues={{ clients: [],  }}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const stepperCountValue = 5;
            dispatch(addMultipleClient(supplierId, chips));
            dispatch(updateStepperCount(supplierId, stepperCountValue));
            navigate("/my-business")

          } catch (error) {
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => {
            return (
              <form onSubmit={handleSubmit} {...rest}>
                <Grid container flexDirection="column" gap={2}>
                  <Grid>
                    <TextField
                      size="small"
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          // Check for Enter keycode
                          handleAddChip();
                        }
                      } }
                      fullWidth
                      placeholder="Clients"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              color="secondary"
                              variant="contained"
                              sx={{
                                borderRadius: "5px",
                                width: "70px",
                                height: "32px",
                                fontSize: "14px",
                                fontWeight: 600,
                                backgroundColor: "black",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "black", // Set the same background color on hover
                                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                                  transition: "background-color 0.3s, box-shadow 0.3s",
                                },
                              }}
                              type="button"
                              onClick={handleAddChip}
                            >
                              Add
                            </Button>
                          </InputAdornment>
                        ),
                      }} />
                  </Grid>

                  {/* Display chips for the entered clients */}
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                    ref={containerRef}
                  >
                    {chips.map((chip, index) => (
                      <Grid>
                        <Chip
                          key={index}
                          label={chip}
                          onDelete={handleDeleteChip(chip)}
                          style={{ width: "auto", margin: "5px" }} />
                      </Grid>
                    ))}
                  </Grid>

                  {/* Add other form fields using Field component here */}
                  <Grid
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: "76px",
                        height: "25px",
                        fontFamily: "Roboto",
                        borderRadius: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                        backgroundColor: "black",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "black", // Set the same background color on hover
                          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                          transition: "background-color 0.3s, box-shadow 0.3s",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                  <Typography
          onClick={() => navigate("/my-business")}
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            cursor: "pointer",
            textAlign: "center",
            textDecoration: "underline",
            color: "#5F5959",
            width: "80px",
            margin: "0 auto",
          }}
        >
          Skip for Now
        </Typography>
                </Grid>
              </form>
            );
          }}
      </Formik>
    </Grid>
  );
}

export default AddClientsForm;
