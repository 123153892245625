import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import Slider from 'react-slick'

const CompanyLogoSlider = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true,
    });
    const settings = {
        infinite: true,
        slidesToShow: isMd ? 6 : 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 6000,
        autoplaySpeed: 6000,
        cssEase: "linear",
        arrows: false,
    };
    const companyLogoImages = [
        "/business/homepage_floating_logos_aquara.svg",
        "/business/homepage_floating_logos_basil.svg",
        "/business/homepage_floating_logos_gajkesaristeel.svg",
        "/business/homepage_floating_logos_nyati.svg",
        "/business/homepage_floating_logos_oxy.svg",
        "/business/homepage_floating_logos_reliance.svg",
        "/business/homepage_floating_logos_skyi.svg",
        "/business/homepage_floating_logos_thermax.svg",
        "/business/homepage_floating_logos_vascon.svg",
        "/business/homepage_floating_logos_vj.svg"
    ]
    return (
        <Grid sx={{ paddingX: "20px", paddingY: "30px" }}>

            <Slider {...settings}>
                {companyLogoImages.map((imgUrl) => <Box
                    component="img"
                    sx={{
                        height: "46px"
                    }}
                    alt="logo"
                    src={`${BASE_URL}/assets${imgUrl}`}
                />
                )}
            </Slider>
        </Grid>
    )
}

export default CompanyLogoSlider