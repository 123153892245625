import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";

const AttachmentSendRfqSection = ({
  entityId,
  isSmallScreen,
  isSubmitting,
  attachmentsUploadsErrors,
  errors,
  attachmentsSkeleton,
  handleFileUpload,
  handleFileUploadClick,
  handleSubmit,
  values
}) => {
  const [errorMsg, setErrorMsg] = useState("");
 

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        mt="18px"
        alignItems={entityId&&"center"}
        sx={{ position: "sticky", bottom: 0, backgroundColor: "#fff" }}
      >
        <Grid sx={{ display: "flex", gap:entityId? "16px":"", alignItems: entityId?"center":"start",flexDirection:!entityId&&"column" }}>
          <Grid>
            <input
              accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify the file types you want to allow
              id="file-upload"
              type="file"
              disabled={attachmentsSkeleton?.length > 0}
              style={{ display: "none" }}
              onClick={handleFileUploadClick}
              onChange={handleFileUpload}
            />
            <label htmlFor="file-upload">
              <Grid
                sx={{
                  display: "flex",
                  padding: "4px 6px",
                  borderRadius: "12px",
                  width: "125px",
                  cursor: "pointer",
                  margin: "8px 0px ",
                  cursor: attachmentsSkeleton.length > 0 && "wait",
                  backgroundColor:
                    attachmentsSkeleton.length > 0 ? "grey" : "white",
                  color: attachmentsSkeleton.length > 0 ? "white" : "black",
                  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <AttachFileIcon
                  sx={{
                    width: "0.7em",
                    ml: "2px",
                    cursor: "pointer",
                  }}
                />
                <Typography style={{ cursor: "pointer" }}>
                  Attach File
                </Typography>
              </Grid>
            </label>
          </Grid>
          {!isSmallScreen && (
            <Typography sx={{ color: "#707070" }}>
              You can attach any supporting document here
            </Typography>
          )}
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "column" ,marginTop:!entityId&&"4px"}}>
          <Button
            // type="submit"
            disabled={isSubmitting || attachmentsSkeleton.length > 0}
            onClick={() => {
              if (
                errors.location ||
                errors.location === undefined ||
                errors.rfqType ||
                errors.rfqType === undefined ||
                errors.projectName ||
                errors.projectName === undefined ||
                errors.name ||
                errors.name === undefined ||
                errors.description ||
                errors.description === undefined
              ) {
                if (entityId) {
                if(values.location&&values.rfqType&&values.projectName&&values.name&&values.description){
                  setErrorMsg("");
                }else{
                  setErrorMsg("Please fill out all required fields");

                }}else{
                  if(values.name&&values.description){
                    setErrorMsg("");
                  }else{
                    setErrorMsg("Please fill out all required fields");
  
                  }
                }
                setTimeout(() => {
                  setErrorMsg("");
                }, 5000);
                handleSubmit();
              } 
            }}
            sx={{
              borderRadius: "17px",
              marginBottom: "4px",
              padding: 0,
              height: "34px",
              paddingX: "8px",
              color: "black",
              backgroundColor: "#002E33",
              "&:hover": {
                backgroundColor: "#002E33",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
              },
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "white", }}>
              {isSmallScreen ? "send" : (entityId ? "Contact Vendor" : "Send Message")}
            </Typography>
            {/* <SendIcon
              sx={{
                width: "0.7em",
                ml: "6px",
              }}
            /> */}
          </Button>
          {errorMsg && (
            <Typography
              style={{ marginTop: "4px", fontSize: "12px", color: "red" }}
            >
              {errorMsg}
            </Typography>
          )}
        </Grid>
      </Grid>
      {errors.submit && (
        <Box mt={"8px"}>
          <FormHelperText error>{errors.submit}</FormHelperText>
        </Box>
      )}
      {attachmentsUploadsErrors && (
        <Box mt={3}>
          <Typography color="red" fontWeight="12px" margin="14px">
            {" "}
            {attachmentsUploadsErrors}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AttachmentSendRfqSection;
