import React from 'react'

const EasyStepsForSm = () => {
  return (
      <div className='flex justify-around py-[42px]'>
        <div className='flex flex-col justify-center items-center'>
          <p className='text-white bg-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px] mb-2'>1</p>
          <p className='text-base font-bold text-[#FF6B00]'>Search & </p>
          <p className='text-base font-bold text-[#FF6B00]'>Filter</p>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <p className='bg-white text-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px] mb-2'>2</p>
          <p className='text-base font-bold '>Contact & </p>
          <p className='text-base font-bold '>Send RFQs</p>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <p className='bg-white text-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px] mb-2'>3</p>
          <p className='text-base font-bold '>Close Deals</p>
          <p className='text-base font-bold '>Offline</p>
        </div>
      </div>
  )
}

export default EasyStepsForSm
