import axios from "utils/axios";

import {
  SEARCH_URL,
  SEARCH_COMPANY_URL,
  SEARCH_URL_BY_NAME,
  SEARCH_URL_BY_SERVICE_NAME,
  SEARCH_BUSINESS_BY_NAME,
  SEARCH_BUSINESS_BY_NAME_AUTH,
} from "config/services";

class SearchService {
  search = (searchKeyWord, filterStr, startPage) =>
    new Promise((resolve, reject) => {
      // console.log("searchService:search", searchKeyWord, filterStr, startPage);

      let searchUrl = `${SEARCH_URL}?queryParam=${searchKeyWord}`;
      if (filterStr) {
        searchUrl += `&filterParam=${filterStr}`;
      }
      if (startPage) {
        const startNumber = (startPage - 1) * 60;
        searchUrl += `&start=${startNumber}`;
      }

      axios
        .get(searchUrl, { searchKeyWord })
        .then((response) => {
          // console.log("searchService:search: Response", response.data);

          if (response.data.error) {
            // console.log("searchService:search:", response.error, response.data);
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });
  searchByName = (searchKeyWord, filterStr, startPage) =>
    new Promise((resolve, reject) => {
      // console.log("searchService:search", searchKeyWord, filterStr, startPage);

      let searchUrl = `${SEARCH_URL_BY_NAME}?queryParam=${searchKeyWord}`;
      if (filterStr) {
        searchUrl += `&filterParam=${filterStr}`;
      }
      if (startPage) {
        const startNumber = (startPage - 1) * 60;
        searchUrl += `&start=${startNumber}`;
      }

      axios
        .get(searchUrl, { searchKeyWord })
        .then((response) => {
          console.log("searchService:search: Response", response.data);

          if (response.data.error) {
            // console.log("searchService:search:", response.error, response.data);
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });
  searchByService = (searchKeyWord, filterStr, startPage) =>
    new Promise((resolve, reject) => {
      // console.log("searchService:search", searchKeyWord, filterStr, startPage);

      let searchUrl = `${SEARCH_URL_BY_SERVICE_NAME}?queryParam=${searchKeyWord}`;
      if (filterStr) {
        searchUrl += `&filterParam=${filterStr}`;
      }
      if (startPage) {
        const startNumber = (startPage - 1) * 60;
        searchUrl += `&start=${startNumber}`;
      }

      axios
        .get(searchUrl, { searchKeyWord })
        .then((response) => {
          console.log("searchService:search: Response", response.data);

          if (response.data.error) {
            // console.log("searchService:search:", response.error, response.data);
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });

  searchCompany = (searchKeyWord) =>
    new Promise((resolve, reject) => {
      // console.log("searchService:searchCompany", searchKeyWord);
      const searchUrl = `${SEARCH_BUSINESS_BY_NAME_AUTH}?queryParam=${searchKeyWord}`;
      // const searchUrl = `${SEARCH_BUSINESS_BY_NAME}?queryParam=${searchKeyWord}`;

      axios
        .get(searchUrl, { searchKeyWord })
        .then((response) => {
          //  console.log("searchService:searchCompany: Response", response.data);

          if (response.data.error) {
            //  console.log("searchService:searchCompany:", response.error, response.data);
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });
}

const searchService = new SearchService();

export default searchService;
