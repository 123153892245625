import registerCRMService from "services/registerCRMService"
export const FETCH_CRM_LEAD = "@lead/fetch"
export const GET_LEAD_SUCCESS = "@get/lead/success"
export const GET_LEAD_FAILURE = "@get/lead/failure"
export const UPDATE_CRM_LEAD = "@update/lead"
export const CREATE_LEAD_ACTIVITY = "@create/lead/activity"
export const CREATE_LEAD_ACTIVITY_SUCCESS = "@create/lead/activity/success"
export const CREATE_CRM_ACTIVITY_FAILURE = "@create/lead/activity/failure"
export const UPDATE_CRM_LEAD_SUCCESS = "@update/lead/success"
export const UPDATE_CRM_LEAD_FAILURE = "@update/lead/failure"

export function getCRMLeadByPhone(mobile){
    return async (dispatch) =>{
        try{
            const phone = mobile

            dispatch({
                type:FETCH_CRM_LEAD,
                payload:{
                    phone
                }
            })
            const getLead = await registerCRMService.getCRMLeadByPhone(phone)
            dispatch({
                type: GET_LEAD_SUCCESS,
                payload: {
                  getLead,
                },
              });
        }catch(err){
      
            let errMsg = err?.response?.data?.message;
            const error = { message: errMsg };
      
            dispatch({ type: GET_LEAD_FAILURE });
            throw error;
          }
    }
}

export function updateCRMLeadById(leadId,newLeadValues){
    return async (dispatch) =>{
        try{
            const leadIdToUpdate = leadId;
            const updatedLeadInfo = newLeadValues;
           
            dispatch({
                type:UPDATE_CRM_LEAD,
                payload:{
                    leadIdToUpdate,
                    updatedLeadInfo
                }
            })
            const UpdatedtLead = await registerCRMService.updateCRMLeadByLeadId(leadIdToUpdate,updatedLeadInfo)
            dispatch({
                type: UPDATE_CRM_LEAD_SUCCESS,
                payload: {
                  UpdatedtLead,
                },
              });
        }catch(err){
      
            let errMsg = err?.response?.data?.message;
            const error = { message: errMsg };
      
            dispatch({ type: UPDATE_CRM_LEAD_FAILURE});
            throw error;
          }
    }
}

export function addLeadActivity(activityData){
    return async (dispatch) =>{
        try{
            const activityInfo = activityData;
           
            dispatch({
                type:CREATE_LEAD_ACTIVITY,
                payload:{
                    activityInfo
                }
            })
            const ActivityAdded = await registerCRMService.createLeadActivity(activityInfo)
            dispatch({
                type: CREATE_LEAD_ACTIVITY_SUCCESS,
                payload: {
                    ActivityAdded
                },
              });
        }catch(err){
      
            let errMsg = err?.response?.data?.message;
            const error = { message: errMsg };
      
            dispatch({ type: CREATE_CRM_ACTIVITY_FAILURE});
            throw error;
          }
    }
}