import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  Alert,
} from "@mui/material";
import { AddNote, PastConversations, ResendRfqToCcSection } from "./sections";

const ResendRequirementPopUp = ({
  selectedUserId,
  resendRfqPopUp,
  handleClose,
  enquiry,
  setOpen,
}) => {
  const allEmails =
    enquiry?.emails?.length > 0 ? enquiry?.emails : [enquiry?.email];

  const [errorMessage, setErrorMessage] = useState("");
  const [emails, setEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRightEmail = (supplier) => {
    {
      if (
        supplier.noteDescriptions &&
        supplier.noteDescriptions.length > 0 &&
        supplier.noteDescriptions[supplier.noteDescriptions.length - 1]
          .emails &&
        supplier.noteDescriptions[supplier.noteDescriptions.length - 1].emails
          .length > 0
      ) {
        return supplier.noteDescriptions[supplier.noteDescriptions.length - 1]
          .emails;
      } else if (supplier.emails && supplier.emails.length > 0) {
        return supplier.emails;
      } else {
        return [supplier.email];
      }
    }
  };

  useEffect(() => {
    if (errorMessage) {
      const timeoutId = setTimeout(() => {
        setErrorMessage("");
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [errorMessage]);

  useEffect(() => {
    const selectedEmails = handleRightEmail(enquiry);
    setEmails(selectedEmails);
  }, [enquiry]);

  return (
    <Dialog
      fullScreen={isSm}
      fullWidth={true}
      maxWidth={"xl"}
      open={resendRfqPopUp}
      onClose={handleClose}
    >
      <Grid
        sx={{
          backgroundColor: "#FBF8F6",
          display: "flex",
          position: "sticky",
          top: 0,
          zIndex: 10,
          justifyContent: "space-between",
          padding: "10px 14px 10px 27px",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: "#000000" }}>Resend Enquiry</Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Grid sx={{ cursor: "pointer" }} onClick={handleClose}>
          x
        </Grid>
      </Grid>
      <Grid sx={{ padding: isSm ? "14px" : "10px 27px 20px" }}>
        <ResendRfqToCcSection
          enquiry={enquiry}
          emails={emails}
          setEmails={setEmails}
          ccEmails={ccEmails}
          setCcEmails={setCcEmails}
        />
        <AddNote
          selectedUserId={selectedUserId}
          enquiry={enquiry}
          handleClose={handleClose}
          setOpen={setOpen}
          setErrorMessage={setErrorMessage}
          emails={emails}
          ccEmails={ccEmails}
        />
        <PastConversations enquiry={enquiry} />
      </Grid>
    </Dialog>
  );
};

export default ResendRequirementPopUp;
