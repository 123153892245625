import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";




import Container from "components/Container";
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';


const pages = [
  {
    id: "dashboard",
    href: "/user-dashboard",
    title: "My Dashboard",
    icon: <SpeedOutlinedIcon />
  },
  {
    id: "profile",
    href: "/user-profile",
    title: "Profile",
    icon: <PersonOutlineOutlinedIcon />
  },
  {
    id: "mybusiness",
    href: "/my-business",
    title: "Business",
    icon: <WorkOutlineOutlinedIcon />
  },
  // {
  //   id: "members",
  //   href: "/members",
  //   title: "Members",
  //   icon: <GroupsOutlinedIcon />

  // },
  {
    id: "requirements",
    href: "/requirements",
    title: "Enquiries Sent",
    icon: <EventNoteOutlinedIcon />

  },

  {
    id: "leads",
    href: "/leads",
    title: "Leads Received ",
    icon: <InsertChartOutlinedRoundedIcon />

  },
  {
    id: "favourites",
    href: "/shortlisted-profile",
    title: "Shortlisted Profiles",
    icon: <InventoryOutlinedIcon />

  },
  // {
  //   id: 'notifications',
  //   href: '/user-notifications',
  //   title: 'Notifications',
  // },
  // {
  //   id: "orders",
  //   href: "/user-orders",
  //   title: "Orders",
  //   icon: <Inventory2OutlinedIcon />

  // },
];

const Page = ({ children,isPublicProfile }) => {
  const [activeLink, setActiveLink] = useState("");

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;



  return (
    <Box style={{ padding: 0 }}>
      <Container
        padding={"0 !important"}
        marginX={"0 !important"}
        style={{ minWidth: "100%" }}
      >
        <Grid container style={{ minWidth: "100%" }}>
          {/* {isMd ? <Grid item xs={12} md={2.3} style={{ minHeight: "100%" }}>
            <Card
              sx={{
                boxShadow: 3,
                minHeight: "100%",
                backgroundColor: "#E2E2E2",
                borderRadius: "0",
              }}
            >
              <List
                disablePadding
                sx={{
                  display: { xs: "inline-flex", md: "flex" },
                  flexDirection: { xs: "row", md: "column" },
                  overflow: "auto",
                  flexWrap: "nowrap",
                  width: "100%",
                  paddingY: { xs: 3, md: 4 },
                  paddingX: { xs: 4, md: 1 },

                }}
              >
                {pages.map((item) => (
                  <ListItem
                    key={item.id}
                    component={"a"}
                    href={item.href}
                    disableGutters
                    sx={{
                      marginRight: { xs: 2, md: 0 },
                      flex: 0,
                      paddingX: { xs: 0, md: 1 },
                      borderLeft: {
                        xs: "none",
                        md: "2px solid transparent",
                      },
                      borderLeftColor: {
                        md:
                          activeLink === item.href
                            ? theme.palette.primary.main
                            : "transparent",
                      },
                      backgroundColor: {
                        md:
                          activeLink === item.href ? "#47B59E" : "transparent",
                      },
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: activeLink !== item.href ? "#EBE8E8" : null,
                      },
                    }}
                  >
                    <span
                      className="icon"
                      style={{
                        color: activeLink === item.href ? "white" : "#757373",
                        marginRight: '12px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {item.icon}
                    </span>
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color={activeLink === item.href ? "white" : "#262728"}
                      style={{
                        fontFamily: "Roboto",

                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "19px",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid> : null} */}

          <Grid item xs={12} md={12}>
            <Card sx={{ boxShadow: 3, padding: isPublicProfile?4:"", borderRadius: 0 }}>

              {children}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
