import jwtDecode from "jwt-decode";
import axios from "utils/axios";
import { UserProfile } from "interface/userProfileIf";

import {
  SIGNIN_EMAIL_PASS_URL,
  SIGNIN_SEND_OTP_URL,
  SIGNIN_WITH_MOBILE_OTP,
  SIGNUP_SEND_OTP_URL,
  SIGNUP_VALIDATE_OTP_URL,
  UPDATE_CRM_ID_IN_USER_URL,
  WHATSAPP_OTP_URL,
  WHATSAPP_VALIDATE_OTP_URL,
} from "config/services";

class AuthService {
  setUserId = (userId) => {
    if (userId) {
      localStorage.setItem("userId", userId);
    } else {
      localStorage.removeItem("userId");
    }
  };

  setUserMobile = (mobile) => {
    if (mobile) {
      localStorage.setItem("mobile", mobile);
    } else {
      localStorage.removeItem("mobile");
    }
  };
  setUserEmail = (email) => {
    if (email) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem("accessToken");

  getUserId = () => localStorage.getItem("userId");

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    // console.log(
    //   "isValidToken() accessToken: ",
    //   accessToken,
    //   " decoded",
    //   decoded
    // );
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          this.setUserId(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    // console.log("handleAuthentication: ", accessToken);

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
      this.setUserId(null);
    }
  }

  isAuthenticated = () => !!this.getAccessToken();

  signinWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "authService:signinWithEmailAndPassword:",
      //   email,
      //   "pass",
      //   password
      // );
      axios
        .post(SIGNIN_EMAIL_PASS_URL, { email, password })
        .then((response) => {
          // console.log(
          //   "authService:signinWithEmailAndPassword: Response",
          //   response?.data
          // );

          if (response?.data?.error) {
            // console.log(
            //   "authService:signinWithEmailAndPassword:Error1",
            //   response.error,
            //   response.data
            // );
            reject(response?.data?.message);
          } else {
            if (response?.data?.payload) {
              this.setSession(response?.data?.payload?.token);
              this.setUserId(response?.data?.payload?.user?.userId);
              this.setUserEmail(response?.data?.payload?.user?.email);
              this.setUserMobile(response?.data?.payload?.user?.mobile);
            }
            // resolve(user);
            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          if (error?.response) {
            reject(error?.response?.data);
          }

          reject(error);
        });
    });

  signUpMobile = (name, mobile) =>
    new Promise((resolve, reject) => {
      //const payload = { "email": email };

      axios
        .post(SIGNUP_SEND_OTP_URL, { name, mobile })
        .then((response) => {
          // console.log(
          //   "signUp Mobile ===()" + name,
          //   mobile,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.error);
          } else {
            resolve(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInSendMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

  whatsAppMobileOTP = (whatsapp) =>
    new Promise((resolve, reject) => {
      axios
        .post(WHATSAPP_OTP_URL, { whatsapp })
        .then((response) => {
          // console.log(
          //   // "signUp Mobile ===()" + name,
          //   // mobile,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data);
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInSendMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

  verifyWhatsappOTP = (whatsapp, otp) =>
    new Promise((resolve, reject) => {
      axios
        .post(WHATSAPP_VALIDATE_OTP_URL, { whatsapp, otp })
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error);
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        })
    })



  verifyRegisterOTP = (mobile, name, otp, crmLeadId, sourceName, isWhatsappNo, isVerified) =>
    new Promise((resolve, reject) => {
      //const payload = { "email": email };
      const whatsapp = isWhatsappNo ? mobile : "";
      const isWhatsAppVerified = isWhatsappNo && true;
      axios
        .post(SIGNUP_VALIDATE_OTP_URL, { mobile, name, otp, crmLeadId, sourceName, isWhatsappNo, whatsapp, isWhatsAppVerified, isVerified })
        .then((response) => {
          // console.log(
          //   "signUp Mobile ===()" + mobile,
          //   otp,
          //   JSON.stringify(response)
          // );

          if (response?.data?.error) {
            reject({ message: response?.data?.message });
          } else {
            if (response?.data?.payload) {
              this.setSession(response?.data?.payload?.token);
              this.setUserId(response?.data?.payload?.user?.userId);
              this.setUserEmail(response?.data?.payload?.user?.email);
              this.setUserMobile(response?.data?.payload?.user?.mobile);
            }
            // resolve(user);
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInSendMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

  signInSendMobileOTP = (mobile) =>
    new Promise((resolve, reject) => {
      //const payload = { "email": email };

      axios
        .post(SIGNIN_SEND_OTP_URL, { mobile })
        .then((response) => {
          // console.log("signInSendMobileOTP()" + JSON.stringify(response));

          if (response.data.error) {
            reject(response.data.error);
          } else {
            resolve(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInSendMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

  signInWithMobileOTP = (mobile, otp) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNIN_WITH_MOBILE_OTP, { mobile, otp })
        .then((response) => {
          // console.log("signInWithMobileOTP" + JSON.stringify(response));

          if (response?.data?.error) {
            reject({ message: response?.data?.message });
          } else {
            if (response?.data?.payload) {
              this.setSession(response?.data?.payload?.token);
              this.setUserId(response?.data?.payload?.user?.userId);
              this.setUserEmail(response?.data?.payload?.user?.email);
              this.setUserMobile(response?.data?.payload?.user?.mobile);
            }
            // resolve(user);
            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInWithMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          if (error?.response) {
            reject(error?.response?.data)
          }
          reject(error);
        });
    });

  updatedCrmUserData = (userId, crmLeadId) =>
    new Promise((resolve, reject) => {
      axios
        .post(UPDATE_CRM_ID_IN_USER_URL, { userId, crmLeadId })
        .then((response) => {
          // console.log("CRM LEAD ID UPDATION RESPONSE", response);

          if (response.data.error) {
            reject(response.data.error);
          } else {
            resolve(response.data.message);
          }
        })
        .catch((error) => {
          // console.log("CRM LEAD ID UPDATION RESPONSE", error);
          reject(error);
        });
    });

  // verifyMobileOTP = (mobile, otp) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(SIGNIN_VERIFY_MOBILE_OTP_URL, { mobile, token: otp })
  //       .then((response) => {
  //         console.log("verifyMobileOTP(mobile)" + JSON.stringify(response));
  //
  //         if (response.data.error) {
  //           reject(response.data.error);
  //         } else {
  //           resolve(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "verifyMobileOTP() Service(Response Error):" +
  //             JSON.stringify(error)
  //         );
  //         reject(error);
  //       });
  //   });
  //

  // signup = (newUser) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(SIGNUP_URL, newUser)
  //       .then((response) => {
  //         console.log("authService:signup", response.data);
  //         if (response.data) {
  //           const email = response.data;
  //
  //           console.log("Response: " + JSON.stringify(response.data));
  //
  //           resolve(email);
  //         } else {
  //           console.log("authService:signup: error", response.data);
  //           reject(response.data.error);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("authService:signup: error", error);
  //         reject(error);
  //       });
  //   });
  //
  // signInWithEmailOrMobilePassword = (mobile, email, password) =>
  //   new Promise((resolve, reject) => {
  //     console.log(
  //       "authService:signInWithEmailOrMobilePassword:",
  //       email,
  //       "mobile",
  //       mobile,
  //       "pass",
  //       password
  //     );
  //
  //     axios
  //       .post(email ? SIGNIN_WITH_EMAIL_PASS : SIGNIN_WITH_MOBILE_PASS, {
  //         email,
  //         mobile,
  //         password,
  //         device: "WEB",
  //       })
  //       .then((response) => {
  //         console.log(
  //           "authService:signInWithEmailOrMobilePassword: Response",
  //           response.data
  //         );
  //
  //         if (response.data) {
  //           if (response.data.error) {
  //             reject(response.data.message);
  //           }
  //
  //           console.log("User Info: ", response.data.data);
  //
  //           const payload = response.data.data;
  //
  //           console.log("User Info: ", payload);
  //
  //           if (payload) {
  //             this.setUserId(payload.user.email);
  //             this.setSession(payload.token);
  //
  //             const user = new UserProfile(payload.user);
  //
  //             console.log(
  //               "authService:signInWithEmailOrMobilePassword: user",
  //               user
  //             );
  //
  //             resolve(user);
  //           } else {
  //             resolve(undefined);
  //           }
  //         } else {
  //           console.log(
  //             "authService:signInWithEmailOrMobilePassword:Error1",
  //             response.error,
  //             response.data
  //           );
  //           reject(response.error);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "authService:signInWithEmailOrMobilePassword:Catch Error ",
  //           error
  //         );
  //
  //         if (error.response) {
  //           reject(error.response.data);
  //         }
  //
  //         reject(error);
  //       });
  //   });
  //

  loginInWithToken = (userId) =>
    new Promise((resolve, reject) => {
      axios
        .get("/api/auth/info", {
          params: { userId: userId },
        })
        .then((response) => {
          if (response.data) {
            const userInfo = response.data.user;
            const userEntity = response.data.userEntity;

            const user = new UserProfile(userInfo,userEntity);
            // console.log("loginInWithToken->User: " + JSON.stringify(user));

            resolve(user);
          } else {
            // console.log(
            //   "loginInWithToken->reject" + JSON.stringify(response.data.error)
            // );
            reject(response.data.error);
          }
        })
        .catch((error) => {
          // console.log("loginInWithToken->error" + JSON.stringify(error));
          reject(error);
        });
    });

  signout = () => {
    new Promise((resolve, reject) => {
      this.setSession(null);
      this.setUserId(null);

      axios
        .post("/api/auth/signout")
        .then((response) => {
          this.setUserEmail(null);
          this.setUserMobile(null);

          // console.log(
          //   "authService:signinWithEmailAndPassword: Response",
          //   response.data
          // );
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            // console.log(
            //   "authService:signinWithEmailAndPassword:Error2",
            //   error.response
            // );
            reject(error.response.data);
          }
          reject(error);
        });
    });
  };

  //
  // resetPassword = (email) =>
  //   new Promise((resolve, reject) => {
  //     //const payload = { "email": email };
  //
  //     axios
  //       .post("/api/auth/resetpassword", { email })
  //       .then((response) => {
  //         console.log("resetPassword(response)" + JSON.stringify(response));
  //
  //         if (response.data.error) {
  //           reject(response.data.error);
  //         } else {
  //           resolve(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "resetPassword() Service(Response Error):" + JSON.stringify(error)
  //         );
  //         reject(error);
  //       });
  //   });

  // resetPasswordChange = (info) =>
  //   new Promise((resolve, reject) => {
  //     //const payload = { "email": email };
  //
  //     axios
  //       .post(RESET_PASSWORD_CHANGE_URL, info)
  //       .then((response) => {
  //         console.log(
  //           "resetPasswordChange(response)" + JSON.stringify(response)
  //         );
  //
  //         if (response.data.error) {
  //           reject(response.data.error);
  //         } else {
  //           resolve(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "resetPasswordChange() Service(Response Error):" +
  //             JSON.stringify(error)
  //         );
  //         reject(error);
  //       });
  //   });

  // changePassword = (passInfo) =>
  //   new Promise((resolve, reject) => {
  //     //console.log("changePassword() Service:" + JSON.stringify(passInfo));
  //
  //     const payload = passInfo;
  //
  //     axios
  //       .post("/api/auth/change-password", { payload })
  //       .then((response) => {
  //         console.log("changePassword(response)" + JSON.stringify(response));
  //
  //         if (response.data.error) {
  //           reject(response.data.error);
  //         } else {
  //           resolve(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "changePassword() Service(Response Error):" + JSON.stringify(error)
  //         );
  //         reject(error);
  //       });
  //   });

  // sendMobileSignUpOTP = (email, mobile) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(SIGNUP_SEND_MOBILE_OTP_URL, { mobile })
  //       .then((response) => {
  //         console.log("sendMobileSignUpOTP(mobile)" + JSON.stringify(response));
  //
  //         if (response.data.error) {
  //           reject(response.data.error);
  //         } else {
  //           resolve(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "sendMobileSignUpOTP() Service(Response Error):" +
  //             JSON.stringify(error)
  //         );
  //         reject(error);
  //       });
  //   });

  // signInWithEmailOTP = (email, otp) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(SIGNIN_WITH_EMAIL_OTP, { email, token: otp })
  //       .then((response) => {
  //         console.log("signInWithEmailOTP" + JSON.stringify(response));
  //
  //         if (response.data.error) {
  //           reject(response.data.error);
  //         } else {
  //           resolve(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "signInWithEmailOTP() Service(Response Error):" +
  //             JSON.stringify(error)
  //         );
  //         reject(error);
  //       });
  //   });

  // signUpEmailVerifyToken = (token) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(SIGNUP_EMAIL_VERIFY_TOKEN_URL, { token })
  //       .then((response) => {
  //         console.log("signUpEmailVerifyToken" + JSON.stringify(response));
  //
  //         if (response.data.error) {
  //           reject(response.data.message);
  //         } else {
  //           resolve(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "signUpEmailVerifyToken() Service(Response Error):" +
  //             JSON.stringify(error)
  //         );
  //         reject(error);
  //       });
  //   });

  // updateProfile = (userInfo) =>
  //   new Promise((resolve, reject) => {
  //     //console.log("updateProfile() Service:" + JSON.stringify(userInfo));
  //
  //     const payload = userInfo;
  //
  //     axios
  //       .post("/api/auth/update-profile", { payload })
  //       .then((response) => {
  //         //console.log("updateProfile(response)" + JSON.stringify(response));
  //
  //         if (response.data.error) {
  //           reject(response.data.error);
  //         } else {
  //           resolve(response.data.payload);
  //         }
  //       })
  //       .catch((error) => {
  //         //console.log("updateProfile() Service(Response Error):" + JSON.stringify(error));
  //         reject(error);
  //       });
  //   });
}

const authService = new AuthService();

export default authService;
