import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  FormControl,
  Fab,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { signin } from "actions/authActions";
import { SIGN_UP_COUNTER } from "config";


function SignInEmailForm({ className, onSubmitSuccess, ...rest }) {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passValue, setpassValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setpassValues({ ...passValue, showPassword: !passValue.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .min(5)
          .max(80)
          .required("Please enter valid email address"),
        password: Yup.string()
          .min(1)
          .max(15)
          .required("Please Enter your password"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(signin(values.email, values.password));
          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="email"
                id="email"
                label="Email"
                type="email"
                value={values.email}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 80 }}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onChange={handleChange}
                  inputProps={{ maxLength: 15 }}
                  value={values.password}
                  type={passValue.showPassword ? "text" : "password"}
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {passValue.showPassword ? (
                          <VisibilityIcon style={{ color: "#47B59E" }} />
                        ) : (
                          <VisibilityOffIcon style={{ color: "#47B59E" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {Boolean(touched.password && errors.password) && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid item alignItems={"center"} justifyContent={"center"}>
              <Button
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0.6,0.7)",
                    transition: "background-color 0.4s, box-shadow 0.4s", // Smooth transition
                  },
                }}
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignInEmailForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

SignInEmailForm.default = {
  onSubmitSuccess: () => {},
};

export default SignInEmailForm;
