import tagsService from "services/tagsService";

export const TAGS_FETCH = "@tags/fetch";
export const TAGS_FETCH_SUCCESS = "@tags/fetch-success";
export const TAGS_FETCH_FAILURE = "@tags/fetch-failure";

export const TAGS_FETCH_BY_USERID = "@tags/fetch-byId";
export const TAGS_FETCH_BY_USERID_SUCCESS = "@tags/fetch-byId-success";
export const TAGS_FETCH_BY_USERID_FAILURE = "@tags/fetch-byId-failure";

export const TAG_ADD_SUCCESS = "@tag/add-success";
export const TAG_ADD_FAILURE = "@tag/add-failure";

export function fetchTag() {
  return async (dispatch) => {
    try {
      dispatch({ type: TAGS_FETCH });
      const tags = await tagsService.fetchTags();
    //   console.log("my new tagssssss", tags);
      dispatch({
        type: TAGS_FETCH_SUCCESS,
        payload: {
          tags,
        },
      });
    } catch (error) {
      dispatch({ type: TAGS_FETCH_FAILURE });
      throw error;
    }
  };
}

export function fetchTagByUserId({ userId, entityId }) {
  return async (dispatch) => {
    try {
      dispatch({ type: TAGS_FETCH_BY_USERID });
      const tags = await tagsService.fetchTagByUserId({ userId, entityId });
    //   console.log("my new tagssssss", tags);
      dispatch({
        type: TAGS_FETCH_BY_USERID_SUCCESS,
        payload: {
          tags,
        },
      });
    } catch (error) {
      dispatch({ type: TAGS_FETCH_BY_USERID_FAILURE });
      throw error;
    }
  };
}

export function addTag(tag) {
  return async (dispatch) => {
    try {
      const payload = await tagsService.addTags(tag);

      dispatch({
        type: TAG_ADD_SUCCESS,
        payload: payload,
      });
    } catch (error) {
      dispatch({ type: TAG_ADD_FAILURE });
      throw error;
    }
  };
}
