import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MaterialQuotationForm from 'views/VendorQuotationForms/MaterialQuotationForm';
import ProvidingQuotationForm from 'views/VendorQuotationForms/ProvidingQuotationForm';
import LabourQuotationForm from 'views/VendorQuotationForms/LabourQuotationForm';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Typography } from '@mui/material';
import { registerUserWithMobile } from 'actions/authActions'; // Replace with your actual import paths
import { getUrl } from 'actions/businessActions';
import LeadsMobileVerification from 'views/Auth/SignInOTPVerify/LeadsMobileVerification';

function QuotationFormRouter() {
    const { rfqType, rfqId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mobileNumber = searchParams.get('mobile');

    console.log("Mobile Number:", mobileNumber); // Check mobile number

    // Redux state
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;
    const leads = useSelector((state) => state.lead.leads);

    console.log("User Profile:", userProfile); // Check user profile state
    console.log("Leads:", leads); // Check if leads data is loaded
    

    // Local state
    const [rfqData, setRfqData] = useState(null);
    const [openOtpVerifyModal, setOpenOtpVerifyModal] = useState(false);
    const [loading, setLoading] = useState(false);

    // User authentication and OTP verification logic
    useEffect(() => {
        if (!userProfile && mobileNumber) {
            console.log("Dispatching getUrl with:", location.pathname);
            dispatch(getUrl(location.pathname));
            setLoading(true); // Start loading
            console.log("Dispatching registerUserWithMobile with:", mobileNumber);
            dispatch(registerUserWithMobile("", mobileNumber, () => {
                setLoading(false); // Stop loading after OTP is sent
                setOpenOtpVerifyModal(true); // Open OTP modal
            }));
        }
    }, [userProfile, mobileNumber, dispatch, location.pathname]);

    // Find RFQ data once leads are loaded
    useEffect(() => {
        if (leads && leads.length > 0) {
            const foundRfqData = leads.find(rfq => rfq.rfqId === Number(rfqId));
            console.log("Found RFQ Data:", foundRfqData);
            setRfqData(foundRfqData);
        }
    }, [leads, rfqId]);

    // Redirect if userProfile and mobileNumber are set
    useEffect(() => {
        if (userProfile && mobileNumber) {
            const newUrl = location.pathname;
            navigate(newUrl, { replace: true });
        }
    }, [userProfile, mobileNumber, navigate, location.pathname]);

    // Show loading while OTP is being sent
    if (loading) {
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                minHeight="calc(100vh - 66px)"
                direction="column"
            >
                <CircularProgress style={{ marginBottom: '10px' }} />
                <Typography variant="h6">
                    We are sending OTP to verify your mobile number. Please wait...
                </Typography>
            </Grid>
        );
    }

    // Render the OTP modal if it's open
    if (openOtpVerifyModal) {
        return (
            <LeadsMobileVerification
                isWhatsappNo={false}
                setOtpPopUp={setOpenOtpVerifyModal}
                setOpenLogInModal={setOpenOtpVerifyModal}
                mobile={mobileNumber}
            />
        );
    }

    // Show a loading indicator while leads are being fetched
    if (!leads || leads.length === 0) {
        return <div className="flex flex-col justify-center items-center h-screen">
            <div className="animate-spin rounded-full border-2 mb-2 border-t-2 border-orange-500 border-t-gray-200 h-8 w-8"></div>
            <p className='text-sm font-medium'>Fetching Leads...</p>
        </div>;
    }

    // Check if rfqData is available
    if (!rfqData) {
        return <div>RFQ not found</div>;
    }

    // Render the appropriate form based on rfqType
    switch (rfqType) {
        case 'material':
            navigate(`/material-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId:rfqData?.suppliers[0]?.supplierId } })
            break;
        case 'labour':
            navigate(`/labour-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId:rfqData?.suppliers[0]?.supplierId } })
            break;
        case 'contractor':
            navigate(`/providing-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId:rfqData?.suppliers[0]?.supplierId } })
            break;
        default:
            return <div>Invalid RFQ Type</div>;
    }
}

export default QuotationFormRouter;
