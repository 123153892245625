class NewAddReqInfo {
  constructor(supplierId, values) {
    this.supplierId = supplierId;
    this.name = values.name;
    this.rfqId = values.rfqId;
    this.projectName = values.projectName;
    this.summary = values.summary;
    this.description = values.description;
    this.startDate = values.startDate;
    this.endDate = values.endDate;
    this.validity = values.validity;
    this.projectLocation = values.location;
    this.capacity = values.capacity;
    this.uom = values.uom;
    this.creditPeriod = values.creditPeriod;
  }
}

class ReqInfo {
  constructor(supplierId, rfqId, values) {
    this.supplierId = supplierId;
    this.rfqId = rfqId;
    this.name = values.name;
    this.projectName = values.projectName;
    this.summary = values.summary;
    this.description = values.description;
    this.startDate = values.startDate;
    this.endDate = values.endDate;
    this.validity = values.validity;
    this.projectLocation = values.location;
    this.capacity = values.capacity;
    this.uom = values.uom;
    this.creditPeriod = values.creditPeriod;
  }
}

class RfqDeleteInfo {
  constructor(supplierId, rfqId) {
    this.supplierId = supplierId;
    this.rfqId = rfqId;
  }
}

class RfqView {
  constructor(supplierId, rfqId) {
    this.rfqId = rfqId;
    this.supplierId = supplierId;
  }
}

class NewEnquiryInfo {
  constructor(
    supplierId,
    requirement,
    enquiry,
    userCompany,
    userName,
    selectedFilesBase,
    newCcEmails,
    subscriptionType
  ) {
    this.supplierId = supplierId;
    this.name = requirement.name;
    // this.rfqId = values.rfqId;
    this.projectName = requirement.projectName;
    this.summary = requirement.summary;
    this.description = requirement.description;
    this.startDate = requirement.startDate;
    this.endDate = requirement.endDate;
    this.validity = requirement.validity;
    this.projectLocation = requirement.location;
    this.capacity = requirement.capacity;
    this.uom = requirement.uom;
    this.creditPeriod = requirement.creditPeriod;
    this.suppliers = enquiry;
    this.userCompany = userCompany;
    this.userName = userName;
    this.createDate = new Date(); // this is code made for creat date by per user
    this.requestedBy = requirement.requestedBy;
    this.requesterMobile = requirement.requesterMobile;
    this.siteEngineerContact = requirement.siteEngineerContact;
    this.siteEngineerName = requirement.siteEngineerName;
    this.selectedFilesBase = selectedFilesBase;
    this.newCcEmails = newCcEmails;
    this.subscriptionType = subscriptionType;
    this.isEmailAuthorized = requirement.isEmailAuthorized;
    this.rfqType = requirement.rfqType;
    this.estimatedValue = +requirement.estimatedvalue || 0;
    this.isEntityUser = requirement.isEntityUser;
    this.entityId = requirement.entityId;
  }
}

class ExistingEnquiryInfo {
  constructor(supplierId, rfqId, enquiry) {
    this.supplierId = supplierId;
    this.rfqId = rfqId;
    this.suppliers = enquiry;
  }
}

export default { NewAddReqInfo };
export {
  NewAddReqInfo,
  RfqDeleteInfo,
  RfqView,
  ReqInfo,
  NewEnquiryInfo,
  ExistingEnquiryInfo,
};
