import { Button, Chip, Tooltip, Typography } from "@mui/material";
import React from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { dateFormatter } from "utils/formatUtils";

const SupplierUtils = (handleResendRequirement) => [
  {
    field: "businessName",
    headerName: "Vendor Name",
    minWidth: 200,
    renderCell: (params) => (
      <>
        {/* <Tooltip title={params.value} arrow> */}
        <Typography
          sx={{
            textAlign: "start",
            width: 190,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "default",
          }}
          title={params?.value}
        >
          {params.value}
        </Typography>
        {/* </Tooltip> */}
      </>
    ),
    sortable: false,
    headerAlign: "center",
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 120,
    headerAlign: "center",
    sortable: false,
    renderCell: (params) => (
      <Chip
        label={params.value}
        variant="outlined"
        size="small"
        style={{
          color: params.value === "Delivered" ? "grey" : "#1212c6",
          border: "none",
          // borderColor: params.value === 'Delivered' ? 'grey' : 'blue',
        }}
        // color={params.value === 'Delivered' ? 'grey' : 'secondary'}
        icon={
          params.value == "Delivered" ? (
            <DoneAllIcon />
          ) : (
            <DoneAllIcon color="#1212c6" />
          )
        }
      />

      // <Chip label={params.value} variant="outlined" size="small"
      //   color={params.value === 'Delivered' ? 'grey' : 'secondary'}
      //   // icon={params.value == "Delivered" ? <DoneAllIcon /> : <DoneAllIcon color="secondary" />}
      //   icon={<DoneAllIcon />}
      // />
    ),
  },
  {
    field: "resendCount",
    headerName: "Resend Count",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.resendCount ? params.row.resendCount : 0,
    width: 130,
    sortable: false,
  },
  // {
  //   field: "Action",
  //   headerName: "Action",
  //   headerAlign: "center",
  //   align: "center",
  //   // valueGetter: (params) => dateFormatter(params.row.ResendCount),
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Button
  //       variant="outline"
  //       sx={{
  //         paddingX: "1px",
  //         paddingY: "1px",
  //         borderRadius: "5px",
  //         border: "1px solid black",
  //       }}
  //       onClick={() => handleResendRequirement(params.row)}
  //     >
  //       Resend
  //     </Button>
  //   ),
  // },
  {
    field: "reminderDate",
    headerName: "Resend Date",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.reminderDate ? dateFormatter(params.row.reminderDate) : "-",
    width: 140,
    sortable: false,
  },
  {
    field: "mobile",
    headerName: "Vendor Contact",
    headerAlign: "center",
    align: "center",
    width: 150,
    sortable: false,
  },
  {
    field: "vendorEmail",
    headerName: "Vendor Email",
    headerAlign: "center",
    align: "center",
    width: 200,
    // renderCell: renderCellExpand,
    renderCell: (params) => (
      <>
        {/* <Tooltip title={params.value} arrow> */}
        <Typography
          sx={{
            textAlign: "start",
            width: 180,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "default",
          }}
          title={params?.value}
        >
          {params.value}
        </Typography>
        {/* </Tooltip> */}
      </>
    ),
    sortable: false,
  },
];

export default SupplierUtils;
