import React, { useEffect, useState } from "react";
import Select from "../../../../components/Select";

export const VendorQuotation = ({
  handleQuotationChange,
  quotationDetails,
  resetQuotationDetails,
  resetQuotationTerms,
  selectedSupplier,
  errors,
  removeFieldErrors,
}) => {
  const [quotationReceived, setQuotationReceived] = useState(null);

  const handleQuotationTerms = (event) => {
    resetQuotationTerms();
    handleQuotationChange("alignedToTerms", event.target.value === "yes");
  };

  const handleQuotationReceived = (event) => {
    resetQuotationDetails();
    setQuotationReceived(event.target.value);
    handleQuotationChange("received", event.target.value === "yes");
  };

  const errorField = (msg) => (
    <span className="text-red-500 text-sm">{msg}</span>
  );

  useEffect(() => {
    if (quotationDetails?.received.toString()) {
      setQuotationReceived(quotationDetails?.received ? "yes" : "no");
    } else {
      setQuotationReceived(null);
    }
  }, [selectedSupplier]);

  return (
    <>
      <b>Quotation Received ?</b>
      <div className="mt-2">
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio text-primary-500"
            name="quotationReceived"
            value="yes"
            checked={quotationReceived === "yes" || quotationDetails.received}
            onChange={handleQuotationReceived}
          />
          <span className="ml-2">Yes</span>
        </label>
        <label className="inline-flex items-center ml-6">
          <input
            type="radio"
            className="form-radio text-primary-500"
            name="quotationReceived"
            value="no"
            checked={
              quotationReceived === "no" || quotationDetails?.received === false
            }
            onChange={handleQuotationReceived}
          />
          <span className="ml-2">No</span>
        </label>

        {quotationReceived === "yes" && (
          <div className="flex flex-col mt-4">
            <b>Quotation Amount</b>
            <input
              className="w-full mt-2 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary-500"
              type="text"
              placeholder="Enter the quotation amount"
              value={quotationDetails.amount}
              onChange={(e) => {
                handleQuotationChange("amount", e.target.value);
                removeFieldErrors("amount");
              }}
            />
            {errors.amount && errorField(errors.amount)}
            <b className="mt-4">Quotation aligned to Companies terms</b>
            <div className="flex mt-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-primary-500"
                  name="quotationTerms"
                  value="yes"
                  checked={quotationDetails.alignedToTerms}
                  onChange={handleQuotationTerms}
                />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  className="form-radio text-primary-500"
                  name="quotationTerms"
                  value="no"
                  checked={!quotationDetails.alignedToTerms}
                  onChange={handleQuotationTerms}
                />
                <span className="ml-2">No</span>
              </label>
            </div>
            {quotationDetails["alignedToTerms"] === false && (
              <div className="mt-4">
                <b>Reason for non-alignment</b>
                <Select
                  options={[
                    { key: "Payment", value: "Payment" },
                    { key: "Availability", value: "Availability" },
                    { key: "other", value: "Other" },
                  ]}
                  onChange={(_key, value) => {
                    handleQuotationChange("nonAlignmentReason", value);
                    if (value !== "Other") {
                      handleQuotationChange("otherNonAlignmentReason", "");
                    }
                    removeFieldErrors("nonAlignmentReason");
                  }}
                  selectedValues={
                    quotationDetails.nonAlignmentReason
                      ? [quotationDetails.nonAlignmentReason]
                      : []
                  }
                  className="mt-2"
                  placeholder="Select Reason"
                />
                {errors.nonAlignmentReason &&
                  errorField(errors.nonAlignmentReason)}
                {quotationDetails.nonAlignmentReason === "Other"  && (
                  <div className="mt-2 flex flex-col space-y-2">
                    <label>Other Reason</label>
                    <input
                      className="w-full mt-2 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary-500"
                      type="text"
                      placeholder="Enter the reason"
                      value={quotationDetails.otherNonAlignmentReason}
                      onChange={(e) => {
                        handleQuotationChange(
                          "otherNonAlignmentReason",
                          e.target.value
                        );
                        removeFieldErrors("otherNonAlignmentReason");
                      }}
                    />
                    {errors.otherNonAlignmentReason &&
                      errorField(errors.otherNonAlignmentReason)}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {quotationReceived === "no" && (
          <div className="mt-4">
            <b>Reason</b>
            <Select
              options={[
                { key: "Vendor not reachable", value: "Vendor not reachable" },
                {
                  key: "Vendor not Interested",
                  value: "Vendor not Interested",
                },
                { key: "Cannot provide material/services", value: "Cannot provide material/services" },
                {key:"Rejected terms", value:"Rejected terms"},
                { key: "other", value: "Other" },
              ]}
              onChange={(_key, value) => {
                handleQuotationChange("reason", value);
                if (value !== "Other") {
                  handleQuotationChange("otherReason", "");
                }
                removeFieldErrors("reason");
              }}
              selectedValues={
                quotationDetails.reason ? [quotationDetails.reason] : []
              }
              className="mt-2"
              placeholder="Select Reason"
            />
            {errors.reason && errorField(errors.reason)}
            {quotationDetails.reason === "Other" && (
              <div className="mt-2 flex flex-col space-y-2">
                <label>Other Reason</label>
                <input
                  className="w-full mt-2 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary-500"
                  type="text"
                  placeholder="Enter the reason"
                  value={quotationDetails.otherReason}
                  onChange={(e) => {
                    handleQuotationChange("otherReason", e.target.value);
                    removeFieldErrors("otherReason");
                  }}
                />
                {errors.otherReason && errorField(errors.otherReason)}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
