import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import serviceData from '../servicedata.json';

const ServicesSlider = ({ scrollToHome }) => {
    return (
        <div className='mx-4'>
            <p className='text-white text-2xl font-bold'>Services</p>
            <hr className=' mt-2 mb-[27px]' />
            <Swiper
                spaceBetween={20} // Space between slides
                slidesPerView={1.4} // Allow multiple slides per view
                grabCursor={true} // Change cursor to grabbing when user interacts with the slider
            >
                {serviceData.map((service) => (
                    <SwiperSlide key={service.id} className="flex-shrink-0 w-[260px]">
                        <div className="bg-gradient-to-r  from-[#0B0B1A] from-50% via-[#7E7E95] via-40% to-[#0B0B1A] to-10% rounded-lg overflow-hidden">
                            <img
                                src={service.src}
                                alt={service.alt}
                                className="h-[150px] w-full object-cover rounded-t-lg"
                            />
                            <p className="text-white pl-4 py-2 font-bold text-base">{service.title}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className='flex justify-center'>
                <button onClick={scrollToHome} className='text-white text-xl font-bold flex items-center pt-[52px] pb-[72px]'>
                    Find More Services
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_FindMoreArrow.svg" alt="" className='h-6 mt-2 pl-2' />
                </button>
            </div>
        </div>
    );
};

export default ServicesSlider;
