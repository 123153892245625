import {
  Alert,
  Button,
  Card,
  Checkbox,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { fetchBusinessOwnerEmail } from "actions/businessActions";
import { fetchTag } from "actions/tagsActions";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import FavoritesDialogBox from "views/Business/components/FavoritesDialogBox";

function MobileSearchCategory({
  checkedItems = [],
  setCheckedItems,
  data,
  currentPage,
}) {
  const dispatch = useDispatch();
  const { searchString, region } = useParams();
  const { user } = useSelector((state) => state.auth);
  const favourite = useSelector((state) => state.favourite.favourites);

  const [openSave, setOpenSave] = useState(false);
  const [openFavoritesTag, setOpenFavoritesTag] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  const isRealEstateOrEPC =
    data?.businessCategory === "Real Estate Developer" ||
    data?.businessCategory === "EPC Contractor";

  const isShortlisted = favourite?.some(
    (item) => item.supplierId === data.supplierId
  );
  const TotalYears = new Date().getFullYear() - data?.establishYear;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let defaultImg = "/assets/default/default-search-image.jpg";

  const logoSrcUrl =
    data?.logoUrl && data?.logoUrl !== ""
      ? data?.logoUrl.startsWith("/assets/business")
        ? `${BASE_URL}${data?.logoUrl}`
        : data?.logoUrl
      : defaultImg;

  //create a business area value if dist not available the store the value of city
  const businessArea = data?.addresses_district
    ? data?.addresses_district
    : data?.addresses_city;

  const businessLocation =
    businessArea && data?.addresses_state
      ? `${businessArea}, ${data?.addresses_state}`
      : businessArea
      ? businessArea
      : data?.addresses_state
      ? data?.addresses_state
      : "";

  const handleFavoritesTagClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFavoritesTag(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSave(false);
  };

  const handleShortlistTagBox = (event) => {
    if (user?.isVerified === true) {
      event.preventDefault();
      dispatch(fetchTag());
      setOpenFavoritesTag(true);
    }
  };
  const handleCheckboxChange = async (
    itemId,
    itemName,
    itemOwnerId,
    businessEmail,
    contact
  ) => {
    const suppliers = [{ supplierId: itemId }];
    const result = await dispatch(fetchBusinessOwnerEmail(suppliers));
    // console.log("#@#@#@#@#@@@=>", result);
    const resultEmails = Array.isArray(result[0]?.emails)
      ? result[0]?.emails
      : [];
    const emails = [...new Set([...resultEmails, businessEmail])];
    const updatedCheckedItem = {
      supplierId: itemId,
      businessName: itemName[0],
      ownerId: Number(itemOwnerId),
      businessOwnerCrmIds: null,
      mobile: contact,
      emails: emails,
    };

    const isChecked = checkedItems.some((item) => item.supplierId === itemId);

    if (isChecked) {
      // Uncheck the checkbox and remove data from checkedItems
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item.supplierId !== itemId)
      );
    } else {
      // Check the checkbox and add data to checkedItems
      if (checkedItems.length < 60) {
        setCheckedItems((prevCheckedItems) => [
          ...prevCheckedItems,
          updatedCheckedItem,
        ]);
      } else {
        seterrMsg("You can select only 60 businesses.");
      }
    }
  };

  const handleCloseAlert = () => {
    seterrMsg(null);
  };

  return (
    <>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/business-details/${
          data?.url
        }/${searchString}/${region}/${currentPage}?${`Category=${data?.businessCategory}`}`}
        target="_blank"
        onClick={(e) => {
          if (openFavoritesTag) e.preventDefault();
        }}
      >
        <Card sx={{ maxwidth: "350px", pointerEvents: user ? "auto" : "none" }}>
          <Grid display={"flex"} justifyContent={"space-between"}>
            {data?.subscriptionType == "PREMIUM" ? (
              <Box
                component="img"
                sx={{
                  height: "14px",
                  // width: "60px",
                  paddingTop: "0px",
                }}
                src="/assets/home/verified.png"
              />
            ) : (
              <Box sx={{ height: "17px", marginTop: "8px" }}></Box>
            )}
            {!isRealEstateOrEPC && user?.isVerified && (
              <Checkbox
                sx={{
                  // color: 'black', // Change color of Checkbox
                  "&.Mui-checked": {
                    color: "black", // Apply the color when checked
                  },
                }}
                // disabled={!user?.isVerified}
                checked={
                  checkedItems &&
                  checkedItems.some(
                    (checkedItem) => checkedItem.supplierId === data.supplierId
                  )
                }
                onChange={() =>
                  handleCheckboxChange(
                    data.supplierId,
                    data.businessName,
                    data.owners,
                    data.businessEmail,
                    data.contact
                  )
                }
              />
            )}
          </Grid>
          <Grid
            style={{
              paddingRight: "16px",
              paddingLeft: "16px",
              paddingBottom: "16px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                gap: "15px",
              }}
            >
              <Grid>
                <Box
                  component="img"
                  sx={{ height: "56px", width: "56px" }}
                  alt="Logo"
                  src={logoSrcUrl}
                />
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "rgba(10, 102, 194, 1)",
                  }}
                >
                  {data.businessName}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                >
                  {/* {data.businessCategory} */}
                  {businessLocation}
                </Typography>
                <Grid display={"flex"} gap={4}>
                  {TotalYears ? (
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      {TotalYears} Years in Business
                    </Typography>
                  ) : (
                    <Typography sx={{ marginBottom: "30px" }}></Typography>
                  )}
                  {data?.isISOCertified && data?.gstCertified == "true" ? (
                    <Grid display={"flex"} gap={2}>
                      <Box
                        component="img"
                        sx={{ height: "19px", width: "19px" }}
                        src="/assets/home/Iso.svg"
                      />

                      <Box
                        component="img"
                        sx={{ height: "19px", width: "19px" }}
                        src="/assets/home/gst.png"
                      />
                    </Grid>
                  ) : data?.isISOCertified ? (
                    <Box
                      component="img"
                      sx={{ height: "19px", width: "19px" }}
                      src="/assets/home/Iso.svg"
                    />
                  ) : data?.gstCertified == "true" ? (
                    <Box
                      component="img"
                      sx={{ height: "19px", width: "19px" }}
                      src="/assets/home/gst.png"
                    />
                  ) : (
                    <Box />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Typography
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              {data?.hightlights}
            </Typography>
          </Grid>

          <Grid sx={{ width: "100%", padding: "10px" }}>
            {!isRealEstateOrEPC && (
              <>
                {" "}
                {isShortlisted ? (
                  <Box
                    component="img"
                    sx={{ height: "24px", mb: "8px", float: "right" }}
                    alt="Business Logo"
                    src={`${BASE_URL}/assets/business/web-business-page-shortlistedIcon.svg`}
                  />
                ) : (
                  <Button
                    onClick={handleShortlistTagBox}
                    disabled={user?.isVerified === false}
                    sx={{
                      float: "right",
                      width: "86px",
                      height: "24px",
                      mb: "8px",
                      border: "1px solid #555",
                      fontSize: "14px",
                      color: "#555",
                      borderRadius: "4px",
                      "&:hover": {
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      },
                    }}
                  >
                    Shortlist
                  </Button>
                )}
              </>
            )}
          </Grid>

          <FavoritesDialogBox
            supplierId={data?.supplierId}
            businessName={data?.businessName[0]}
            handleClose={handleFavoritesTagClose}
            open={openFavoritesTag}
            setOpenSave={setOpenSave}
            businessEmail={data?.businessEmail}
            contact={data?.contact}
            subscriptionType={data?.subscriptionType}
            url={data?.url}
          />
          <Snackbar
            open={openSave}
            autoHideDuration={4000}
            onClose={handleCloseSnackbar}
            message="Business shortlisted succesfully"
            direction="left"
          />
        </Card>
      </Link>
      {errMsg && (
        <Snackbar
          open={errMsg !== null}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseAlert}
            severity="error"
          >
            {errMsg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default MobileSearchCategory;
