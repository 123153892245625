import React from "react";
import { Page, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
// import logo from "../../assets/logo.jpg";
import InvoiceTitle from "./InvoiceTitle";
import InvoiceNo from "./InvoiceNo";
import BillTo from "./BillTo";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceFrom from "./InvoiceFrom";
import InvoiceForm from "./InvoiceFrom";
import InvoiceTo from "./InvoiceTo";
import InvoiceNumber from "./InvoiceNumber";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableFooter from "./InvoiceTableFooter";
Font.register({
  family: "Cambria Regular",
  src: "/assets/fonts/Cambria-Regular.ttf",
  fontWeight: 100,
});

const styles = StyleSheet.create({
  page: {
    // backgroundColor: "#fff",
    fontFamily: "Cambria Regular",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 84,
    height: 70,
    marginLeft: "auto",
    marginRight: "auto",
  },
});
const from = {
  line1: "Orville Business Port, Office no 410,",
  line2: "Opposite Konark Campus,Viman Nagar",
  city: "Pune",
  state: "Maharashtra",
  pincode: "411014",
  GSTIN: "27AAJTC1313K1ZB",
  CIN: "U72900PN2022PTC207836",
};
const to = {
  line1: "Orville Business Port, Office no 410,",
  line2: "Opposite Konark Campus,Viman Nagar",
  city: "Pune",
  state: "Maharashtra",
  pincode: "411014",
  // Mobile: "8956851824",
  CIN: "accounts@tathaatvam.com",
};

const PdfDocument = ({ invoicedata }) => {
  // console.log("PDFDOCUMENT1",invoicedata)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <Image style={styles.logo} src={logo} /> */}
        <InvoiceTitle title={"TAX INVOICE"} />
        <InvoiceForm
          invoicedata={invoicedata}
          title={"Tathaatvam Technology Solutions Private Limited"}
          from={from}
        />
        <InvoiceTo
          invoicedata={invoicedata}
          title={"Tathaatvam Technology Solutions Private Limited"}
          to={to}
        />
        <InvoiceNumber invoice={invoicedata} number={"TTS001/2023-23"} />
        <InvoiceTableHeader invoice={invoicedata} />
        {/* <InvoiceNo invoice={invoicedata} /> */}
        <InvoiceTableFooter />
        <BillTo invoice={invoicedata} />

        {/* <InvoiceItemsTable invoice={invoicedata} /> */}
        <InvoiceThankYouMsg />
      </Page>
    </Document>
  );
};

export default PdfDocument;
