import React from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { alpha, useTheme } from "@mui/material/styles";
import {
  ProfileMenuButton,
  SupportCallButton,
  DashboardButton,
} from "./components";
import SearchBar from "components/SearchBar";
import Logo from "components/Logo";

import { myProfileMenuList } from "./data.js";
import { useSelector } from "react-redux";
import SearchBarNew from "components/SearchBarNew";
import { width } from "@mui/system";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  hoverText: {
    color: "#FFFFFF",
    '&:hover': {
      color: "#47B59E", // Change color on hover
      cursor: 'pointer', // Change cursor on hover if needed
    },
  }
}))
const Topbar = ({ setLoginPopUpIsOpen }) => {
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  const theme = useTheme();
  const { mode } = theme.palette;
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleScrollToContact = () => {
    const height = document.documentElement.scrollHeight - window.innerHeight;
    window.scrollTo({ top: height, behavior: "smooth" });
    return false;
  };
  const classes = useStyles();
  return (
    <Toolbar>
      {isSm ? (
        <>
          {/* <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={1}>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{ flexGrow: 3, display: { xs: "flex", md: "none" } }}
                  >
                    {userProfile ? <DashboardButton /> : " "}
                  </Box>
                  <Box sx={{ flexGrow: 3, mr: 2 }}>
                    <Link to="/">
                      <Logo height="60px" />
                    </Link>
                  </Box>
                  <ProfileMenuButton menuItems={myProfileMenuList} />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}> */}
          {/* <SupportCallButton /> */}
          {/* <SearchBar />
                </Box>
              </Stack>
            </Grid>
          </Grid> */}
          <Grid
            style={{
              display: "flex",
              // flexDirection: "column",
              justifyContent: "space-around",
              width: "100%",
              height: "70px",
              position: "relative",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "start",
                width: "100%",
                marginTop: "8px",
              }}
            >
              <Grid item>
                <Link to={"/"}>
                  <Box
                    component="img"
                    sx={{
                      height: "42",
                      width: "46px",
                      // marginLeft: "16px",
                      cursor: "pointer",
                    }}
                    alt="logo"
                    src="/assets/home/logoHeroSection.svg"
                  />
                </Link>
              </Grid>
              <Grid
                item
                style={
                  {

                  }
                }
              >
                <ProfileMenuButton global={true} setLoginPopUpIsOpen={setLoginPopUpIsOpen} menuItems={myProfileMenuList} />
              </Grid>
            </Grid>
            <Grid
              // marginRight={isSmall ? "-17px" : "17px"}
              style={{ width: "90%", position: "absolute", bottom: "-17px" }}>
              <SearchBarNew global={true} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {/* <Box sx={{ mr: 2 }}>
            <Link to="/">
              <Logo height="60px" />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <SearchBar sx={{ ml: 2 }} />
          <Box sx={{ flexGrow: 1 }} />
          <Box alignItems={"end"} sx={{ display: { xs: "none", md: "flex" } }}>
            <SupportCallButton />
           {userProfile? <DashboardButton />:" "}
            <ProfileMenuButton menuItems={myProfileMenuList} />
          </Box> */}
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              // paddingTop: "15px",
              alignItems: "center",
              height: "66px",
            }}
          >
            <Grid item>
              <Link to={"/"}>
                <Box
                  component="img"
                  sx={{
                    height: "42px",
                    width: "68px",
                    cursor: "pointer",
                  }}
                  alt="logo"
                  src="/assets/home/logoHeroSection.svg"
                />
              </Link>
            </Grid>
            <Grid item xs={5.2} lg={6}>
              <SearchBarNew global={true} />
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                style={{
                  fontFamily: "Roboto",

                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "5px",

                  letterSpacing: "0.5px",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
              >
                <Link
                  to={"/about-us"}
                  style={{ textDecoration: "none" }}
                  className={classes.hoverText}
                >
                  ABOUT US
                </Link>
              </Grid>
              <Grid
                style={{
                  fontFamily: "Roboto",

                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "5px",
                  marginRight: "20px",
                  letterSpacing: "0.5px",
                  cursor: "pointer",
                }}
              >
                <Link
                  to={"/subscribe-plan"}
                  style={{ textDecoration: "none" }}
                  className={classes.hoverText}
                >
                  PRICING
                </Link>
              </Grid>
              <Grid
                style={{
                  fontFamily: "Roboto",

                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  marginRight: "20px",
                  letterSpacing: "0.5px",
                  cursor: "pointer",
                }}
              >
                <Link
                  style={{ textDecoration: "none" }}
                  className={classes.hoverText}
                  onClick={handleScrollToContact}
                >
                  CONTACT US
                </Link>
              </Grid>
              <Grid>
                <ProfileMenuButton global={true} setLoginPopUpIsOpen={setLoginPopUpIsOpen} menuItems={myProfileMenuList} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Toolbar>
  );
};

Topbar.propTypes = {};

export default Topbar;
