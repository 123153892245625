import {
  ADD_NEW_PROJECT_SUCCESS,
  EDIT_RFQ_PROJECT_SUCCESS,
  FETCH_RFQ_PROJECTS,
  FETCH_RFQ_PROJECTS_FAILURE,
  FETCH_RFQ_PROJECTS_SUCCESS,
} from "actions/rfqActions";
import produce from "immer";

const initialState = {
  loading: false,
  error: false,
  errorMassage: "",
  projects: [],
};

const projects = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RFQ_PROJECTS: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.projects = [];
      });
    }
    case FETCH_RFQ_PROJECTS_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.projects = action.payload;
      });
    }
    case FETCH_RFQ_PROJECTS_FAILURE: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = true;
        draft.errorMassage = action.payload;
      });
    }
    case EDIT_RFQ_PROJECT_SUCCESS:
      {
        // find the edited project in our projects collection and replace old one to new one
        const updatedProjectData = [...state.projects];
        const updatedProject = action.payload;
        const index = updatedProjectData.findIndex(
          (project) => project._id === updatedProject._id
        );
        if (index !== -1) {
          updatedProjectData[index] = updatedProject;
        }
        return produce(state, (draft) => {
          draft.loading = false;
          draft.projects = updatedProjectData;
        });
      }
      break;
    case ADD_NEW_PROJECT_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.projects = [action.payload, ...draft.projects];
      });
    }
    default:
      return state;
  }
};

export default projects;
