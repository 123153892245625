import React from 'react';
import location from '../locationdata.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const LocationSlider = ({scrollToHome}) => {
    const cities = location.cities;
    const citiesPerSlide = 15; // 5 rows * 3 columns

    // Function to chunk the array
    const chunkArray = (array, size) => {
        return array.reduce((acc, _, index) =>
            index % size === 0 ? [...acc, array.slice(index, index + size)] : acc
        , []);
    };

    const cityChunks = chunkArray(cities, citiesPerSlide);

    return (
        <div>
            <p className='text-white text-2xl font-bold ml-4'>Locations</p>
            <hr className='mx-4 mt-2 mb-[27px]' />
            <div className='mx-6'>
                <Swiper
                    // spaceBetween={40}
                    slidesPerView={1} // Show one grid per slide
                >
                    {cityChunks.map((chunk, index) => (
                        <SwiperSlide key={index}>
                            <div className='grid grid-cols-3 gap-4'>
                                {chunk.map((city, cityIndex) => (
                                    <div key={cityIndex} className=' '>
                                        <p className='text-white underline text-lg'>{city}</p>
                                    </div>
                                ))}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className='flex justify-center'>
                <button  onClick={scrollToHome}  className='text-white text-xl font-bold flex items-center pt-[52px]'>
                    Find More Locations <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_FindMoreArrow.svg" alt="arrow-img" className='h-6 mt-2 pl-2' />
                </button>
            </div>
        </div>
    );
};

export default LocationSlider;
