import React from 'react';

function Logo(props) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <img
      alt="Logo"
      src={`${BASE_URL}/assets/business/NEEVAY_logo_new_footer.svg`}
      {...props}
    />
  );
}

export default Logo;
