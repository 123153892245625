import React from "react";

import { Button, Chip, Tooltip, Typography } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { dateFormatter } from "utils/formatUtils";
import { ConfirmDialog } from "components";
import { fetchRequirements } from "actions/requirementsAction";

export const headCells = (
  userEntity,
  showDelete,
  deleteRfq,
  closeRfq,
  modal,
  setModal,
  modalClose,
  setModalClose,
  viewRequirement,
  supplierId,
  renderCellExpand,
  handleOpenDescription,
  handleResendRequirement,
  rfqResendmessage,
  handleOpenSuppllierData
) => {
  return userEntity
    ? [
        // { field: 'rfqId', headerName: 'Id', minWidth: 50 },
        // {
        //   field: "id",
        //   headerName: "Sr No",
        //   headerAlign: 'center',
        //   align: 'center',
        //   width: 60,
        //   sortable: false,
        // },
        {
          field: "createdAt",
          headerName: "Create Date",
          headerAlign: "center",
          align: "center",
          valueGetter: (params) => dateFormatter(params.row.createdAt),
          width: 120,
          sortable: false,
        },
        {
          field: "name",
          headerName: "Name",
          minWidth: 200,
          renderCell: renderCellExpand,
          sortable: false,
          headerAlign: "center",
        },
        {
          field: "supplierCount",
          headerName: "Vendor Count",
          headerAlign: "center",
          align: "center",
          width: 130,
          sortable: false,
        },
        {
          field: "action1",
          headerName: "Vendors Details",
          sortable: false,
          minWidth: 180,
          headerAlign: "center",
          align: "center",
          renderCell: (params) => {
            return (
              <Button
                variant="contained"
                sx={{
                  paddingX: "5px",
                  paddingY: "1px",
                  borderRadius: "5px",
                  border:"solid 1px black",
                  color: "black",
                  backgroundColor: "white",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "#CDCDCD",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  },
                }}
                onClick={() => handleOpenSuppllierData(params.row)}
              >
                View Vendors
              </Button>
            );
          },
        },
        // {
        //   field: "reminderDate",
        //   headerName: "Resend Date",
        //   headerAlign: "center",
        //   align: "center",
        //   valueGetter: (params) => params.row.reminderDate? dateFormatter(params.row.reminderDate) :"-",
        //   width: 140,
        //   sortable: false,
        // },
        // {
        //   field: "resendCount",
        //   headerName: "Resend Count",
        //   headerAlign: "center",
        //   align: "center",
        //   valueGetter: (params) => params.row.resendCount ? params.row.resendCount : 0,
        //   width: 130,
        //   sortable: false,
        // },
        // {
        //   field: "Action",
        //   headerName: "Action",
        //   headerAlign: "center",
        //   align: "center",
        //   // valueGetter: (params) => dateFormatter(params.row.ResendCount),
        //   width: 120,
        //   sortable: false,
        //   renderCell: (params) => (
        //     <Button
        //       variant="outline"
        //       sx={{
        //         paddingX:"1px",
        //         paddingY:"1px",
        //         borderRadius:"5px",
        //         border:"1px solid black"
        //       }}
        //       onClick={() => handleResendRequirement(params.row)}
        //     >
        //       Resend
        //     </Button>)
        // },

        // {
        //   field: "businessName",
        //   headerName: "Vendor Name",
        //   minWidth: 300,
        //   renderCell: renderCellExpand,
        //   sortable: false,
        //   headerAlign: 'center',
        // },
        // {
        //   field: "status",
        //   headerName: "Status",
        //   minWidth: 120,
        //   headerAlign: 'center',
        //   sortable: false,
        //   renderCell: (params) => (
        //     <Chip
        //       label={params.value}
        //       variant="outlined"
        //       size="small"
        //       style={{
        //         color: params.value === 'Delivered' ? 'grey' : '#1212c6',
        //         border: "none",
        //         // borderColor: params.value === 'Delivered' ? 'grey' : 'blue',
        //       }}
        //       // color={params.value === 'Delivered' ? 'grey' : 'secondary'}
        //       icon={params.value == "Delivered" ? <DoneAllIcon /> : <DoneAllIcon color="#1212c6" />}
        //     />

        //     // <Chip label={params.value} variant="outlined" size="small"
        //     //   color={params.value === 'Delivered' ? 'grey' : 'secondary'}
        //     //   // icon={params.value == "Delivered" ? <DoneAllIcon /> : <DoneAllIcon color="secondary" />}
        //     //   icon={<DoneAllIcon />}
        //     // />

        //   ),
        // },
        {
          field: "location",
          headerName: "Location",
          minWidth: 150,
          headerAlign: "center",
          align: "center",
          renderCell: renderCellExpand,
          sortable: false,
        },
        {
          field: "action2",
          headerName: "Description",
          sortable: false,
          minWidth: 200,
          headerAlign: "center",
          align: "center",
          renderCell: (params) => {
            const onClick = (e) => {
              e.stopPropagation(); // don't select this row after clicking
              const { api } = params;
              const thisRow = {};
              api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                  // eslint-disable-next-line no-return-assign
                  (c) =>
                    (thisRow[c.field] = params.getValue(
                      params.row.rfqId,
                      c.field
                    ))
                );
            };
            return (
              <>
                {/* <Button sx={{ color: "blue" }} onClick={() => viewRequirement(supplierId, params.row.rfqId)}> */}
                <Button
                  sx={{ color: "blue" }}
                  onClick={() => handleOpenDescription(params.row.description)}
                >
                  <u> Click here to view</u>
                </Button>
                {/* {showDelete && params.row.summary && params.row.rfqId && (
              <>
                <ConfirmDialog
                  title="Are you Sure"
                  description={`Do you want to permanently delete ${params.row.summary}, action can't be undone`}
                  isOpen={modal === params.row.rfqId && params.row.rfqId}
                  confirmAction={() => {
                    // console.log("Delete confirm: ", params.row.rfqId);
                    setModal(false);
                    deleteRfq(supplierId, params.row.rfqId);
                  }}
                  cancelAction={() => setModal(false)}
                />
                <Button onClick={() => setModal(params.row.rfqId)}>Delete</Button>
              </>
            )}
            <ConfirmDialog
              title="Are you Sure"
              description={`Do you want to permanently close ${params.row.summary}, action can't be undone`}
              isOpen={modalClose === params.row.rfqId && params.row.rfqId}
              confirmAction={() => {
                // console.log("Delete confirm: ", params.row.rfqId);
                setModalClose(false);
                closeRfq(supplierId, params.row.rfqId);
              }}
              cancelAction={() => setModalClose(false)}
            />
            {params.row?.status !== "CLOSED" && (
              <Button onClick={() => setModalClose(params.row.rfqId)}>Close</Button>
            )} */}
              </>
            );
          },
        },
        // {
        //   field: "mobile",
        //   headerName: "Vendor Contact",
        //   headerAlign: "center",
        //   align: "center",
        //   width: 150,
        //   sortable: false,
        // },
        // {
        //   field: "email",
        //   headerName: "Vendor Email",
        //   headerAlign: "center",
        //   align: "center",
        //   width: 200,
        //   renderCell: renderCellExpand,
        //   sortable: false,
        // },
        {
          field: "workStartDate",
          headerName: " Supply by",
          headerAlign: "center",
          align: "center",
          valueGetter: (params) => dateFormatter(params.row.workStartDate),
          width: 100,
          sortable: false,
        },
        {
          field: "creditPeriod",
          headerName: "Credit Period",
          minWidth: 120,
          headerAlign: "center",
          align: "center",
          sortable: false,
        },
        {
          field: "rfqId",
          headerName: "Enquiry Id",
          headerAlign: "center",
          align: "center",
          width: 100,
          sortable: false,
        },
        // {
        //   field: "projectName",
        //   headerName: "Project Name",
        //   minWidth: 150,
        //   renderCell: renderCellExpand,
        // },
        // {
        //   field: "summary",
        //   headerName: "Summary",
        //   minWidth: 300,
        //   renderCell: renderCellExpand, // hover effect for see all content.
        // },
        // {
        //   field: "validityDate",
        //   headerName: " Valid Till",
        //   valueGetter: (params) => dateFormatter(params.row.validityDate),
        // },
        // {
        //   field: "location",
        //   headerName: "Project Location",
        //   width: 200,
        //   renderCell: renderCellExpand,
        // },
        // {
        //   field: "status",
        //   headerName: "Status",
        // },
        // {
        //   field: "action",
        //   headerName: "Action",
        //   sortable: false,
        //   minWidth: 250,
        //   renderCell: (params) => {
        //     const onClick = (e) => {
        //       e.stopPropagation(); // don't select this row after clicking
        //       const { api } = params;
        //       const thisRow = {};
        //       api
        //         .getAllColumns()
        //         .filter((c) => c.field !== "__check__" && !!c)
        //         .forEach(
        //           // eslint-disable-next-line no-return-assign
        //           (c) => (thisRow[c.field] = params.getValue(params.row.rfqId, c.field))
        //         );
        //     };
        //     return (
        //       <>
        //         <Button onClick={() => viewRequirement(supplierId, params.row.rfqId)}>
        //           View / Edit
        //         </Button>
        //         {showDelete && params.row.summary && params.row.rfqId && (
        //           <>
        //             <ConfirmDialog
        //               title="Are you Sure"
        //               description={`Do you want to permanently delete ${params.row.summary}, action can't be undone`}
        //               isOpen={modal === params.row.rfqId && params.row.rfqId}
        //               confirmAction={() => {
        //                 // console.log("Delete confirm: ", params.row.rfqId);
        //                 setModal(false);
        //                 deleteRfq(supplierId, params.row.rfqId);
        //               }}
        //               cancelAction={() => setModal(false)}
        //             />
        //             <Button onClick={() => setModal(params.row.rfqId)}>Delete</Button>
        //           </>
        //         )}
        //         <ConfirmDialog
        //           title="Are you Sure"
        //           description={`Do you want to permanently close ${params.row.summary}, action can't be undone`}
        //           isOpen={modalClose === params.row.rfqId && params.row.rfqId}
        //           confirmAction={() => {
        //             // console.log("Delete confirm: ", params.row.rfqId);
        //             setModalClose(false);
        //             closeRfq(supplierId, params.row.rfqId);
        //           }}
        //           cancelAction={() => setModalClose(false)}
        //         />
        //         {params.row?.status !== "CLOSED" && (
        //           <Button onClick={() => setModalClose(params.row.rfqId)}>Close</Button>
        //         )}
        //       </>
        //     );
        //   },
        // },
      ]
    : [
        {
          field: "rfqId",
          headerName: "Message Id",
          headerAlign: "center",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          field: "createdAt",
          headerName: "Create Date",
          headerAlign: "center",
          align: "center",
          valueGetter: (params) => dateFormatter(params.row.createdAt),
          width: 220,
          sortable: false,
        },
        {
          field: "businessName",
          headerName: " Vendor Name",
          minWidth: 250,
          renderCell: renderCellExpand,
          sortable: false,
          headerAlign: "center",
        },

        {
          field: "mobile",
          headerName: "Vendor Contact",
          headerAlign: "center",
          align: "center",
          width: 200,
          sortable: false,
        },
        {
          field: "emails",
          headerName: " Email",
          minWidth: 260,
          renderCell: (params) => {
            // Check if emails property exists and is an array
            if (params.row.emails && Array.isArray(params.row.emails)) {
              // Filter out null or undefined values from the emails array
              const validEmails = params.row.emails.filter((email) => email);

              if (validEmails.length > 0) {
                const emailText = validEmails.join(", ");

                return (
                  <Tooltip title={emailText}>
                    <Typography
                      sx={{
                        textAlign: "start",
                        width: 240,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        cursor: "default",
                      }}
                      title={params?.value}
                    >
                      {emailText}
                    </Typography>
                  </Tooltip>
                );
              } else {
                return "No valid emails available";
              }
            } else {
              return "No emails available";
            }
          },
          sortable: false,
          headerAlign: "center",
        },
        {
          field: "action2",
          headerName: "Message",
          sortable: false,
          minWidth: 200,
          headerAlign: "center",
          align: "center",
          renderCell: (params) => {
            const onClick = (e) => {
              e.stopPropagation(); // don't select this row after clicking
              const { api } = params;
              const thisRow = {};
              api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                  // eslint-disable-next-line no-return-assign
                  (c) =>
                    (thisRow[c.field] = params.getValue(
                      params.row.rfqId,
                      c.field
                    ))
                );
            };
            return (
              <>
                <Button
                  sx={{ color: "blue" }}
                  onClick={() => handleOpenDescription(params.row.description)}
                >
                  <u> Click here to view</u>
                </Button>
              </>
            );
          },
        },

        // {

        //   field: "description",
        //   headerName: "Message",
        //   minWidth: 300,
        //   renderCell: renderCellExpand,
        //   sortable: false,
        //   headerAlign: "center",
        // },
      ];
};
