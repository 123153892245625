import React, { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import Header from "../../../../components/Header";
import Chip from "../../../../components/Chip";
import RfqDetailsSection from "../RfqDetailsSection";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";
import SidePanel from "layouts/SidePanel";

import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading";
import { CcMemberList, mulResendRfq, resendRfq } from "actions/rfqActions";

const validationSchema = Yup.object({
  mailBody: Yup.string().required("Mail Body is required"),
});

const validateEmail = (data) => {
  const email = data.trim();
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const ResendBulkRfq = ({
  toggleTab,
  vendorName,
  mailTo,
  ccMails,
  rfqId,
  loading,
}) => {
  const auth = useSelector((state) => state.auth);
  const ccMembers = useSelector((state) => state.ccMemberList);
  const dispatch = useDispatch();
  const [selectedFilesBase, setSelectedFilesBase] = useState([]);
  const [resendFormValues, setResendFormValues] = useState({
    mailBody: "<p>This is a gentle reminder</p>",
    mailTo,
    ccMails,
  });
  const [selectedSupplierId, setSelectedSupplierId] = useState("");
  const [addToMail, setAddToMail] = useState(false);
  const [addCcMail, setAddCcMail] = useState(false);
  const [newMailTo, setNewMailTo] = useState("");
  const [newMailToList, setNewMailToList] = useState({});
  const [newCcMail, setNewCcMail] = useState("");
  const [mailToError, setMailToError] = useState("");
  const [ccMailError, setCcMailError] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const ccMemberList = ccMembers?.ccEmails;
  const user = auth?.user;
  const entityId = user?.userEntity?.entityId;

  const updateResendFormValues = (updatedValues) => {
    setResendFormValues(updatedValues);
  };

  const onCloseTag = (mail, type) => {
    if (type === "ccMails") {
      const updatedMail = resendFormValues["ccMails"].filter(
        (email) => email !== mail
      );
      setResendFormValues({ ...resendFormValues, [type]: updatedMail });
    } else {
      const updatedMailTo = resendFormValues["mailTo"];
      delete updatedMailTo[mail];
      setResendFormValues({ ...resendFormValues, mailTo: updatedMailTo });
    }
  };

  const handleAddToMail = () => {
    const newBusinessMails = Object.keys(newMailToList).filter(
      (mail) => newMailToList[mail]
    );
    if (!newBusinessMails.length) {
      setMailToError("Select at least one mail");
      return;
    }
    const updatedMailTo = { ...resendFormValues.mailTo };
    updatedMailTo[selectedSupplierId] = {
      businessName: resendFormValues.mailTo[selectedSupplierId].businessName,
      mails: newBusinessMails,
    };
    setResendFormValues((prevVal) => ({
      ...prevVal,
      mailTo: updatedMailTo,
    }));
    setNewMailTo("");
    setNewMailToList({});
    setAddToMail(false);
  };

  const handleAddCcMail = () => {
    if (!validateEmail(newCcMail)) {
      setCcMailError("Invalid email");
      return;
    }
    if (resendFormValues.ccMails.includes(newCcMail.trim())) {
      setCcMailError("Email already exists");
      return;
    }
    setResendFormValues((prevVal) => ({
      ...prevVal,
      ccMails: [...prevVal.ccMails, newCcMail],
    }));
    setNewCcMail("");
    setCcMailError("");
    setAddCcMail(false);
  };
  const handleSuggestionClick = (email) => {
    setNewCcMail(email);
    setShowSuggestions(false);
    // form.setFieldValue(field.name, email);
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className="font-bold">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setNewCcMail(value);
    // form.setFieldValue(field.name, value);

    if (value) {
      const matchedSuggestions = ccMemberList.filter((email) =>
        email.includes(value)
      );
      setSuggestions(matchedSuggestions);
      setShowSuggestions(matchedSuggestions.length > 0);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // handle form submission here body -
    const payload = {
      entityId: "demo",
      rfqId,
      suppliers: Object.keys(resendFormValues.mailTo).map((supplierId) => ({
        supplierId,
        emails: resendFormValues.mailTo[supplierId].mails,
      })),
      userId: user?.userId,
      additionalNote: {
        ccEmails: values?.ccMails?.length ? values?.ccMails : [],
        createdAt: Date(Date.now()),
        desc: values?.mailBody,
        sentByEmail: user?.email || "",
        senderName: user?.name || "",
        senderUserId: user?.userId,
        file: selectedFilesBase,
      },
    };
    console.log("payload", payload);
    const response = await dispatch(mulResendRfq({ payload }));
    toggleTab("Vendors");
    setSubmitting(false);
  };
  useEffect(() => {
    dispatch(CcMemberList(entityId));
  }, []);
  return (
    <SidePanel>
      <div className="w-full">
        <Header title="RFQs" />
        <div className="pt-20 p-6 flex flex-col gap-y-6 w-full bg-[#F4F4F4] min-h-svh">
          <BreadCrumbs
            paths={[
              { title: "RFQs", onClick: () => toggleTab("Vendors") },
              { title: vendorName, onClick: () => toggleTab("Overview") },
              { title: "Reinvite Enquiry", onClick: () => {} },
            ]}
          />
          <Formik
            enableReinitialize
            initialValues={resendFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col gap-y-4">
              <div className="rounded-lg bg-white shadow-md p-6">
                <h3 className="text-gray-900 font-bold text-xl mb-4">
                  Reinvite Enquiry
                </h3>
                <div className="mb-4 flex">
                  <p>To: </p>
                  <div className="flex flex-wrap gap-2 ml-1">
                    {Object.keys(resendFormValues.mailTo).map((supplierId) => (
                      <Button
                        variant="text"
                        style={{ padding: 0 }}
                        onClick={() => {
                          const mailList = {};
                          resendFormValues.mailTo[supplierId].mails.forEach(
                            (m) => {
                              mailList[m] = true;
                            }
                          );
                          setSelectedSupplierId(supplierId);
                          setNewMailToList(mailList);
                          setAddToMail(true);
                        }}
                      >
                        <Chip
                          key={supplierId}
                          label={
                            resendFormValues.mailTo[supplierId].businessName
                          }
                          title={resendFormValues.mailTo[supplierId].mails.join(
                            ", "
                          )}
                          onRemove={
                            Object.keys(resendFormValues.mailTo).length > 1
                              ? (e) => {
                                  e.stopPropagation();
                                  onCloseTag(supplierId, "mailTo");
                                }
                              : null
                          }
                        />
                      </Button>
                    ))}
                  </div>
                </div>
                <div className="mb-4 flex">
                  <p>Cc: </p>
                  <div className="ml-1 flex flex-wrap gap-2">
                    {resendFormValues.ccMails.map((mail) => (
                      <Chip
                        key={mail}
                        label={mail}
                        onRemove={() => onCloseTag(mail, "ccMails")}
                      />
                    ))}
                    <Button
                      type="button"
                      variant="text"
                      onClick={() => setAddCcMail(true)}
                    >
                      + Click to add Mail
                    </Button>
                  </div>
                </div>
                <h3 className="text-gray-900 font-bold text-xl mb-4">
                  Subject - Updated RFQ
                </h3>
                <div className="border-2">
                  <RfqDetailsSection
                    selectedFilesBase={selectedFilesBase}
                    setSelectedFilesBase={setSelectedFilesBase}
                    fieldName="mailBody"
                    updateFormValues={updateResendFormValues}
                  />
                </div>
              </div>
              <div
                className="p-6 flex justify-end items-center gap-4 rounded-lg shadow-md w-full bg-white fixed bottom-0 left-0"
                style={{ boxShadow: "0px -1px 14px 0px rgba(0, 0, 0, 0.1)" }}
              >
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => toggleTab("Vendors")}
                >
                  Cancel
                </Button>
                <Button type="submit">
                  {loading ? <Loading /> : "Reinvite"}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
        <Popup
          heading="Add To mail"
          open={addToMail}
          primaryButton={{
            text: "Add selected mail",
          }}
          handleSubmit={handleAddToMail}
          onClose={() => {
            setNewMailTo("");
            setAddToMail(false);
          }}
        >
          <div className="flex gap-x-2">
            <input
              type="text"
              placeholder="Enter mail"
              className="border-2 border-gray-300 rounded-lg p-2 w-full"
              value={newMailTo}
              onChange={(e) => setNewMailTo(e.target.value)}
            />{" "}
            <Button
              variant="outline"
              onClick={() => {
                if (!validateEmail(newMailTo)) {
                  setMailToError("Invalid email");
                  return;
                }
                setNewMailToList((preVal) => ({
                  ...preVal,
                  [newMailTo]: true,
                }));
                setNewMailTo("");
              }}
            >
              {" "}
              +{" "}
            </Button>
          </div>
          {mailToError && <p className="text-red-500 m-0">{mailToError}</p>}
          {Object.keys(newMailToList).map((mail) => (
            <div className="flex items-center gap-x-2 mt-2">
              <input
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                type="checkbox"
                name="mail"
                checked={newMailToList[mail]}
                onClick={(e) =>
                  setNewMailToList((preVal) => ({
                    ...preVal,
                    [mail]: e.target.checked,
                  }))
                }
              />
              <p>{mail}</p>
            </div>
          ))}
        </Popup>
        <Popup
          heading="Add CC mail"
          open={addCcMail}
          primaryButton={{
            text: "Add",
          }}
          handleSubmit={handleAddCcMail}
          secondaryButton={{
            text: "Cancel",
          }}
          onClose={() => {
            setNewCcMail("");
            setCcMailError("");
            setAddCcMail(false);
            setShowSuggestions(false);
          }}
        >
          <input
            type="text"
            placeholder="Enter mail"
            className="border-2 relative border-gray-300 rounded-lg p-2 w-full"
            value={newCcMail}
            onChange={handleInputChange}
            //  onChange={(e) => setNewCcMail(e.target.value)}
          />
          {showSuggestions && (
            <div className="fixed bg-white border border-gray-300 mt-1 rounded-lg pt-2 z-10 max-h-60 overflow-y-auto">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                  onMouseDown={() => handleSuggestionClick(suggestion)}
                >
                  {getHighlightedText(suggestion, newCcMail)}
                </div>
              ))}
            </div>
          )}
          {ccMailError && <p className="text-red-500 m-0">{ccMailError}</p>}
        </Popup>
      </div>
    </SidePanel>
  );
};

export default ResendBulkRfq;
