import React from "react";
import { Grid, Box, Typography } from "@mui/material";

function AboutNeevay() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3} />
        <Grid item xs={7} sm={7} md={7}>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} textAlign={"start"}>
                <Typography variant="h6">ABOUT NEEVAY</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} textAlign={"start"}>
                <Typography variant="caption text">
                  Welcome to the <b>New Way of Networking</b> in the <b>Construction
                  Business!</b> We aim to consolidate India’s construction
                  workforce, with over thousands of members and counting in
                  Pune, and Mumbai and growing in all major cities.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} textAlign={"start"}>
                <Typography variant="caption text" sx={{}}>
                  Let’s not just build buildings but also strong connections
                  through our unique digital networking platform. We have
                  something for everyone involved in the construction sector-
                  right from the developers to vendors.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} textAlign={"start"}>
                <Typography variant="caption text" sx={{}}>
                  <b>Neevay</b> focuses on
                  building meaningful relationships between businesses in the
                  industry and solving multiple problems that can help unlock
                  greater opportunities for growth.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={2} sm={2} md={2} />
      </Grid>
    </Box>
  );
}



export default AboutNeevay;
