import React, { useEffect, useState } from 'react';
import Hamburger from './Hamburger';
import LogInMobilePopUp from 'views/Auth/SignInSendMobileOTP/LogInMobilePopUp';
import { useDispatch, useSelector } from 'react-redux';
import { signout } from 'actions/authActions';
import { useNavigate } from 'react-router-dom';
const Navbar = () => {
    const [loginModal, setLoginModal] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;
    const openLoginPopup = () => {
        setLoginModal(true);
    }
    const handleCloseLogInModal = () => {
        setLoginModal(false);
    }
    const handleLogout = async () => {
        setMenuOpen(false);
        try {
            await dispatch(signout());
            navigate("/");
        } catch (error) { }
    };
    const getInitials = (name) => {
        if (!name) return '';
        const initials = name.split(' ').map(n => n[0]).join('');
        return initials.toUpperCase();
    };
    const joinVendorClicked = () => {
        navigate("/register/business");
    }
    const myBusinessClicked = () => {
        navigate("/my-business");
    }
    const enquiriesClicked = () => {
        navigate("/requirements");
    }
    const myLeadsClicked = () =>{
        navigate("/leads");
    }
    const signupClicked = () => {
        navigate("/get-started");
    }
    useEffect(() => {
        if (userProfile?.userEntity?.entityId) {
            navigate("/rfqs");
        }
        if (userProfile !== null) {
            if (userProfile?.userId > 0 && userProfile?.name) {
            } else {
                navigate("/register/user");
            }
        }
    }, [userProfile]);

    return (
        <>
            <div className='bg-cover'>
                <div className='flex justify-between items-center h-[102px] md:h-[91px] bg-white px-6 md:px-10 lg:px-11'>
                    <ul className='flex justify-between items-center lg:space-x-6 xl:space-x-10'>
                        <li>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_neevayLogo.svg" alt="Neevay" className='hidden md:block w-[112px] h-[34px] md:h-[39px] md:w-full' />
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/neevay_mobile.svg" alt="Neevay" className='block md:hidden w-full'/>
                        </li>
                    </ul>
                    <div className='flex justify-around md:justify-between items-center xs:py-2 md:py-0'>

                        {userProfile?.userType === "buyer" ? (<button onClick={enquiriesClicked} className=' text-[#F16500] text-sm md:text-base mr-4 md:mr-[22px] xl:pr-0 border-none font-bold underline'>Enquiries</button>) : userProfile?.userType === "vendor" ? (<button onClick={myLeadsClicked} className=' text-[#F16500] text-sm md:text-base mr-4 md:mr-[22px] xl:pr-0 border-none font-bold underline'>My Leads</button>) : null}

                        {userProfile?.userType === "buyer" ? (<button onClick={joinVendorClicked} className=' text-[#F16500] text-sm md:text-base mr-4 md:mr-[22px] xl:pr-0 border-none font-bold underline'>Join as Vendor</button>) : userProfile?.userType === "vendor" ? (<button onClick={myBusinessClicked} className=' text-[#F16500] text-sm md:text-base mr-4 md:mr-[22px] xl:pr-0 border-none font-bold underline'>My Business</button>) : null}

                        {!userProfile ? (
                            <>
                                <button onClick={openLoginPopup} className=' h-[43px] px-6 md:h-[44px] md:px-0 md:w-[108px] xs:text-sm md:text-base mx-4 border hover:font-bold border-black'>Login</button>
                                <button onClick={signupClicked} className='flex items-center justify-around md:text-sm xs:text-sm  md:h-[44px] md:w-[135px] w-[100px] h-[43px] hover:font-bold text-white bg-gradient-to-r from-[#24242D] to-[#0B0B1A] border-l-4 border-[#F16500]'>
                                    Signup
                                    {/* <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_Line_standing.svg" alt="|" className='xs:h-4 md:h-5 md:block' />
                                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_Vector.svg" alt="v" className='xs:w-4 md:w-2.5 md:h-2 md:block' /> */}
                                </button>
                            </>
                        ) : (
                            <div className='relative '>

                                {/* {userProfile?.userType === "buyer" ? (<button onClick={enquiriesClicked} className='hidden md:block text-[#F16500] pr-[22px] xl:pr-0 border-none font-bold underline'>Enquiries</button>) : userProfile?.userType === "vendor" ? (<button onClick={myLeadsClicked} className='hidden md:block text-[#F16500] pr-[22px] xl:pr-0 border-none font-bold underline'>My Leads</button>) : null}

                                {userProfile?.userType === "buyer" ? (<button onClick={joinVendorClicked} className='hidden md:block text-[#F16500] pr-[22px] xl:pr-0 border-none font-bold underline'>Join as Vendor</button>) : userProfile?.userType === "vendor" ? (<button onClick={myBusinessClicked} className='hidden md:block text-[#F16500] pr-[22px] xl:pr-0 border-none font-bold underline'>My Business</button>) : null} */}

                                <div className="flex items-center cursor-pointer" onClick={() => setMenuOpen(!menuOpen)}>
                                    <div className="h-8 w-8 flex items-center justify-center bg-gray-500 rounded-full text-white font-semibold text-sm">
                                        {getInitials(userProfile?.name)}
                                    </div>
                                </div>
                                {menuOpen && (
                                    <div className="absolute right-0 mt-2 w-20 bg-white border border-gray-200 rounded shadow-lg z-50">
                                        <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Log out</button>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className='hidden'>
                            <Hamburger />
                        </div>
                    </div>
                </div>
            </div>
            <LogInMobilePopUp setOpenLogInModal={setLoginModal} openLogInModal={loginModal} handleCloseLogInModal={handleCloseLogInModal} />
        </>
    );
};
export default Navbar;