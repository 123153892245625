import { Box, Card, Grid, Typography, useMediaQuery } from "@mui/material";
import { DEFAULT_IMAGES } from "config";
import React from "react";
import Slider from "react-slick";
import AwardsCertificateCard from "./AwardsCertificateCard";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AddAwardsForm from "views/Business/components/AddAwardsForm";
import AddCertificateForm from "views/Business/components/AddCertificateForm";

function AwardsCertification({
  profile,
  IsSearchPublicProfile,
  permissions,
  isEditView,
}) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isSm ? 3 : IsSearchPublicProfile ? 4 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const certificate = profile?.businessProfile?.certificateImages;
  const awards = profile?.awards;
  const awardsCertificate = [
    ...(awards || []).map((data) => ({
      imgUrl: data?.awardImage,
      title: data?.awardTitle,
      awardId:data.awardId
    })),
    ...(certificate || []).map((data) => ({
      imgUrl: data?.certificateImage,
      title: data?.certificateTitle,
      certificateId:data.certificateId
    })),
  ]; // add two arry of obj(certificate and awards) in one
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isOwner) {
        setOpen(true);
      } else {
      }
    } else {
      navigate("/subscribe-plan");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmitSuccess = () => {
    navigate("/my-business");
  };
  return (
    <Grid
      sx={{ marginX: "46px", paddingX: IsSearchPublicProfile ? "26px" : "" }}
    >
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          marginY: "16px",
        }}
      >
        Awards & Certification
      </Typography>
      <Grid style={{ display: "flex", gap: "20px" }}>
        <Grid style={{ marginBottom: "10px" }}>
          {permissions &&
            !permissions.isViewOnly &&
            permissions.isFreeUpdateAllowed && (
              <Typography
                color="secondary"
                // size="small"
                disabled={!(permissions && permissions.isUpdateAllowed)}
                sx={{ fontSize: "15px", cursor: "pointer" }}
                // endIcon={<AddCircleRoundedIcon />}
                onClick={handleClickOpen}
              >
                <Link sx={{ textDecoration: "none", color: "blue" }}>
                  {" "}
                  Click Here{" "}
                </Link>
                <span style={{ color: "black" }}> to upload awards</span>
              </Typography>
            )}
        </Grid>
        <Grid>
          {permissions &&
            !permissions.isViewOnly &&
            permissions.isFreeUpdateAllowed && (
              <Typography
                // variant="contained"
                color="secondary"
                size="small"
                // disabled={!(permissions && permissions.isUpdateAllowed)}
                sx={{ fontSize: "15px", cursor: "pointer" }}
                // endIcon={<AddCircleRoundedIcon />}
                onClick={handleClickOpen}
              >
                <Link sx={{ textDecoration: "none", color: "blue" }}>
                  {" "}
                  Click Here{" "}
                </Link>
                <span style={{ color: "black" }}> to upload Certificate</span>
              </Typography>
            )}
        </Grid>
      </Grid>

      {awardsCertificate?.length > 3 ? (
        <Slider {...settings}>
          {awardsCertificate?.map((data) => (
            <AwardsCertificateCard data={data} permissions={permissions} />
          ))}
        </Slider>
      ) : (
        <Grid display={"flex"} gap={"10px"}>
          {awardsCertificate?.map((data) => (
            <AwardsCertificateCard data={data} permissions={permissions} />
          ))}
        </Grid>
      )}

      <AddAwardsForm
        open={open}
        handleClose={handleClose}
        // handleSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
      />

      <AddCertificateForm
        open={open}
        handleClose={handleClose}
        // handleSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
      />
    </Grid>
  );
}

export default AwardsCertification;
