/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  ORDERS_FETCH,
  ORDERS_FETCH_SUCCESS,
  ORDERS_FETCH_FAILURE,
  INVOICE_TO_PRINT,
  INVOICE_TO_PRINT_SUCCESS,
  INVOICE_TO_PRINT_FAILURE,
} from "actions/ordersAction";

import { SIGNOUT } from "actions/authActions";

const initialState = {
  orders: [],
  invoiceToPrint: undefined,
  count: 0,
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_FETCH:
      {
        return produce(state, (draft) => {
          draft.orders = [];
          draft.count = 0;
        });
      }
      break;

    case ORDERS_FETCH_SUCCESS:
      {
        const { orders } = action.payload;
        // console.log("ordersReducer: ORDERS_FETCH_SUCCESS ", orders, action.payload);

        return produce(state, (draft) => {
          draft.orders = orders;
          draft.count = orders?.length;
        });
      }
      break;

    case ORDERS_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.orders = [];
          draft.count = 0;
        });
      }
      break;

    case INVOICE_TO_PRINT:
      {
        return produce(state, (draft) => {
          draft.invoiceToPrint = [];
          draft.count = 0;
        });
      }
      break;

    case INVOICE_TO_PRINT_SUCCESS:
      {
        const { invoiceDetails } = action.payload;
        // console.log(
        //   "ordersReducer: INVOICE_TO_PRINT_SUCCESS ",
        //   invoiceDetails,
        //   action.payload.invoiceDetails
        // );

        return produce(state, (draft) => {
          draft.invoiceToPrint = invoiceDetails;
        });
      }
      break;

    case INVOICE_TO_PRINT_FAILURE:
      {
        return produce(state, (draft) => {
          draft.invoiceToPrint = [];
        });
      }
      break;

    case SIGNOUT:
      {
        return produce(state, (draft) => {
          draft.orders = [];
          draft.invoiceToPrint = undefined;
          draft.count = 0;
        });
      }
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default ordersReducer;
