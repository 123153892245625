import favouritesService from "services/favouritesService";

export const FAVOURITES_FETCH = "@favourites/fetch";
export const FAVOURITES_FETCH_SUCCESS = "@favourites/fetch-success";
export const FAVOURITES_FETCH_FAILURE = "@favourites/fetch-failure";

export const FAVOURITES_ADD = "@favourites/add";
export const FAVOURITES_ADD_SUCCESS = "@favourites/add-success";
export const FAVOURITES_ADD_FAILURE = "@favourites/add-failure";

export const FAVOURITES_REMOVE = "@favourites/remove";
export const FAVOURITES_REMOVE_SUCCESS = "@favourites/remove-success";
export const FAVOURITES_REMOVE_FAILURE = "@favourites/remove-failure";

export function fetchFavourites() {
  return async (dispatch) => {
    try {
      dispatch({ type: FAVOURITES_FETCH });

      const favourites = await favouritesService.fetchFavourites();

      // console.log("fetchFavourites()(after):" + JSON.stringify(favourites));
      dispatch({
        type: FAVOURITES_FETCH_SUCCESS,
        payload: {
          favourites,
        },
      });
    } catch (error) {
      dispatch({ type: FAVOURITES_FETCH_FAILURE });
      throw error;
    }
  };
}

export function addFavourites(supplierId, supplierName, tags, businessEmail, contact, isPremium, url,) {
  // console.log("addFavourites", supplierId, supplierName, tags);
  return async (dispatch) => {
    try {
      // dispatch({ type: FAVOURITES_ADD });

      const payload = await favouritesService.addFavourites(
        supplierId,
        supplierName,
        tags,
        businessEmail,
        contact,
        isPremium,
        url,
      );

      // console.log("addFavourites()(after):" + JSON.stringify(payload));
      dispatch({
        type: FAVOURITES_ADD_SUCCESS,
        payload: payload,
      });
    } catch (error) {
      dispatch({ type: FAVOURITES_ADD_FAILURE });
      throw error;
    }
  };
}
export function removeFavourites(supplierId, supplierName) {
  // console.log("addFavourites", supplierId, supplierName);
  return async (dispatch) => {
    try {
      // dispatch({ type: FAVOURITES_ADD });

      const payload = await favouritesService.removeFavourites(
        supplierId,
        supplierName
      );

      // console.log("addFavourites()(after):" + JSON.stringify(payload));
      dispatch({
        type: FAVOURITES_REMOVE_SUCCESS,
        payload: payload,
      });
    } catch (error) {
      dispatch({ type: FAVOURITES_REMOVE_FAILURE });
      throw error;
    }
  };
}
