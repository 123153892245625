import SidePanel from "layouts/SidePanel";
import React, { useEffect, useState } from "react";
import CreateProjectForm from "./components/CreateProjectForm";
import ProjectsTableSection from "./components/ProjectsTableSection";
import { useDispatch, useSelector } from "react-redux";
import { fetchRfqProjects } from "actions/rfqActions";
import StaticPopup from "components/StaticPopup";
import CreateNewProjectForm from "./components/CreateNewProjectForm";

const ProjectSection = () => {
  const dispatch = useDispatch();
  const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
  const auth = useSelector((state) => state.auth);

  const user = auth?.user;
  const entityId = user?.userEntity?.entityId;

  const handleOpenCreateProjectForm = () => {
    setShowCreateProjectForm(true);
  };

  useEffect(() => {
    dispatch(fetchRfqProjects(entityId));
  }, []);

  return (
    <SidePanel>
      <div className="w-full h-screen bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
        <div className="bg-white sticky top-0 z-10 shadow-md p-4">
          <h1 className="text-base font-bold">Projects</h1>
        </div>
        <div className="px-5 pt-2">
          <div className="w-full flex justify-end">
            <button
              className="cursor-pointer flex items-center justify-center px-4 py-2 text-xs text-white rounded-sm bg-black "
              onClick={handleOpenCreateProjectForm}
            >
              Create New Project
            </button>
          </div>
        </div>
        <ProjectsTableSection />
        {/* <CreateProjectForm
          setShowCreateProjectForm={setShowCreateProjectForm}
          showCreateProjectForm={showCreateProjectForm}
        /> */}
        <StaticPopup
          openModal={showCreateProjectForm}
          handleClose={() => setShowCreateProjectForm(false)}
          header={"Add Project"}
          size={"sm"}
          width={"3xl"}
          bodyStyle={"pb-0 pt-2"}
        >
          {showCreateProjectForm && (
            <CreateNewProjectForm
              setShowCreateProjectForm={setShowCreateProjectForm}
            />
          )}
        </StaticPopup>
      </div>
    </SidePanel>
  );
};

export default ProjectSection;
