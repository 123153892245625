import React from "react";

const RepeatColData = [
  {
    field: "createdAt",
    headerName: "Create Date",
  },
  {
    field: "repeatRfqId",
    headerName: "Order Id",
  },
  {
    field: "orderName",
    headerName: "Order Name",
  },
  {
    field: "description",
    headerName: "Description",
  },
  {
    field: "orderValue",
    headerName: "Order Value",
  },
  {
    field: "orderCategory",
    headerName: "Order Category",
  },
  {
    field: "projectName",
    headerName: "Project Name",
  },
  {
    field: "poDetails",
    headerName: "PO Number",
  },
  {
    field: "vendorName",
    headerName: "Vendor Name",
  },
  {
    field: "typeOfOrder",
    headerName: "Order Type",
  },
];

export default RepeatColData;
