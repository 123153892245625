import {
  CLEAR_NETWORK_FILTERS,
  filteredSearch,
  neevayNetworkFilteredSearch,
} from "actions/searchActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedFacetString, updateSearchFilter } from "utils/searchUtils";
import {
  CheckboxFilter,
  RadioFilter,
  RangeFilter,
  SearchCheckboxFilter,
} from "views/CompanyDirectory/components/FiltersType";


const FilterSection = () => {
  const [openKeys, setOpenKeys] = useState({"Annual Turnover": true,
    "Company Type"
    : 
    true,
    "Supplier Type"
    : 
    true});
  const [chips, setChips] = useState([]);
  const [isOn, setIsOn] = useState({});
  const search = useSelector((state) => state.search);
  const dispatch = useDispatch();

// console.log("SEARCH", search);
  const searchKeyWord = search?.searchKeyWord;
  const selectedFacets = search?.selectedFacets;
  const regionString = search?.regionString;
  const facetsData = search?.facets;
  const isMaterialOnlyTraderSelected = selectedFacets?.some(
    (facet) => facet.facet === "Category" && facet.values.includes("Material Supplier")
  );
  const appliedFilters = selectedFacets?.reduce((acc, item) => {
    acc[item.facet] =
      item.facet == "GST Certified" ? item?.values?.join() : item.values;
    return acc;
  }, {});

  const handleToggleButton = (key) => {
    setIsOn((prev) => ({ ...prev, [key]: !prev[key] }));
    // onChange();
    handleInputChange(key, "true", false);
  };

  const chipFilterValue = (chip) => {
    switch (chip.key) {
      case "GST Certified":
        return `GST : ${chip?.value == "true" ? "Yes" : "No"}`;

      case "Subscription Type":
        return "Verified Vendors";

      case "Is Projects":
        return `Past Project : ${chip?.value == "true" ? "Yes" : "No"}`;
      case "Is Clients":
        return `Past Clients : ${chip?.value == "true" ? "Yes" : "No"}`;

      default:
        return chip?.value;
    }
  };

  const handleInputChange = (field, value, isMultiple) => {
    const isPresent = appliedFilters[field]?.includes(value);
    handleFacetSelection(field, value, !Boolean(isPresent));
  };

  const handleFacetSelection = (facetTitle, facetValue, isChecked) => {
    //send filter title and value to 2 fun for getting proper filter string
    // console.log("11111", facetTitle, "=====", facetValue, "=====", isChecked);
    // console.log("000", selectedFacets);
    const filterSet = updateSearchFilter(
      selectedFacets,
      facetTitle,
      facetValue,
      isChecked
    ); // give the array of obj that include {facetTitle:facetValue}
    // console.log("this is a filterSet", filterSet);
    const filterStr = getSelectedFacetString(filterSet); // give selected array they give string for backend
    // console.log("this is a filterString", filterStr);
    dispatch(
      neevayNetworkFilteredSearch({
        searchKeyWord,
        regionString,
        filterStr,
        facetTitle,
        facetValue,
        isSelected: isChecked,
      })
    ); // update data
  };

  const handleToggle = (key) => {
    setOpenKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const clearNetworkFilter = () => {
    const Category = appliedFilters?.Category?.join();
    dispatch({
      type: CLEAR_NETWORK_FILTERS,
    });
    if (Category !== "" && Category) {
      const filterStr = `businessProfile.businessCategory_str:(${Category})`; // after reset all filter we apply this one filter string for category
      dispatch(
        filteredSearch(
          searchKeyWord,
          regionString,
          filterStr,
          "",
          "Category",
          Category,
          true
        )
      );
    }
  };

  useEffect(() => {
    let chipsData = [];
    Object.keys(appliedFilters).forEach((key) => {
      if (Array.isArray(appliedFilters[key])) {
        chipsData = [
          ...chipsData,
          ...appliedFilters[key].map((value) => ({
            key,
            value,
            isMultiple: true,
          })),
        ];
      } else {
        chipsData.push({ key, value: appliedFilters[key], isMultiple: false });
      }
    });
    setChips(chipsData);
  }, [selectedFacets]);


  const filterOptions = [
    {
      key: "Supplier Type",
      title: "Vendor Type",
      type: "checkboxes",
      options: [
        "Trader",
        "Manufacturer"
      ],
      condition: isMaterialOnlyTraderSelected,
    },
    {
      key: "Annual Turnover",
      title: "Turnover",
      type: "radio",
      options: [
        "Less than 40 Lacs",
        "Greater than 40 Lacs",
        "Greater than 1.5 Crores",
        "Greater than 5 Crores",
        "Greater than 25 Crores",
        "Greater than 100 Crores",
        "Greater than 500 Crores",
      ],
    },
    {
      key: "Company Type",
      title: "Company Type",
      type: "checkboxes",
      options: [
        "Private Limited",
        "Public Limited",
        "Partnership",
        "Limited Liability Partnership",
        "Proprietorship",
      ],
    },
    // Add more filters as needed
  ];

  // console.log("facetsData",facetsData)

  return (
    <div
      className="w-64 border rounded-md border-gray-200 dark:border-gray-700 bg-white shadow-md p-4 pt-0 overflow-auto"
      style={{
        height: "calc(100vh - 120px)",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
      }}
    >
      <div className="sticky bg-white z-[1] top-0 pt-4">
        <div className="flex  justify-between">
          <h2 className="mb-4 text-sm font-medium text-gray-700 dark:text-gray-400">
            Filters
          </h2>
        </div>
        <div className="flex flex-wrap max-h-40 overflow-y-auto bg-white px-2">
          {chips?.map((chip) =>
            chip?.key !== "Category" ? (
              <div className="flex items-center max-w-40 bg-blue-100 border border-blue-500 rounded-full px-3 py-1 mr-2 mb-2">
                <p
                  className="truncate text-xs text-blue-600"
                  title={chipFilterValue(chip)}
                >
                  {chipFilterValue(chip)}
                </p>
                <button
                  onClick={() =>
                    handleFacetSelection(chip?.key, chip?.value, false)
                  }
                  className="ml-1 flex-shrink-0 w-4 h-4  bg-blue-100 text-black hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-200"
                >
                  <svg
                    className="w-2 h-2 mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
      <div className="border-y py-3 text-gray-500 border-gray-300">
        <div className="flex cursor-pointer items-center justify-between ">
          <span className="flex gap-2 text-xs font-medium">
            Verified Vendors{" "}
            <img
              src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Verified_image_nn.png"
              alt="verified"
              className="h-[18px] w-[18px]"
            />
          </span>
          <button
            onClick={() =>
              handleInputChange("Subscription Type", "PREMIUM", true)
            }
            className={`w-10 h-5 flex items-center rounded-full p-1 cursor-pointer ${
              appliedFilters["Subscription Type"]?.includes("PREMIUM")
                ? "bg-blue-700"
                : "bg-gray-300"
            }`}
          >
            <div
              className={`bg-white w-3 h-3 rounded-full shadow-md transform ${
                appliedFilters["Subscription Type"]?.includes("PREMIUM")
                  ? "translate-x-5"
                  : "translate-x-0"
              }`}
            />
          </button>
        </div>
      </div>

      <div className="border-b text-gray-500 border-gray-300 py-3">
        <div className="flex  items-center justify-between text-gray-500 ">
          <p className="text-xs font-medium">GST Availability</p>
          <button
            onClick={() => handleToggleButton("GST Certified")}
            className={`w-10 h-5 flex items-center rounded-full p-1 cursor-pointer ${
              appliedFilters["GST Certified"] ? "bg-blue-700" : "bg-gray-300"
            }`}
          >
            <div
              className={`bg-white w-3 h-3 rounded-full shadow-md transform ${
                appliedFilters["GST Certified"]
                  ? "translate-x-5"
                  : "translate-x-0"
              }`}
            />
          </button>
        </div>
      </div>

      <div className="border-b text-gray-500 border-gray-300 py-3">
        <div className="flex  items-center justify-between text-gray-500">
          <p className="text-xs font-medium">Past Project</p>
          <button
            onClick={() => handleToggleButton("Is Projects")}
            className={`w-10 h-5 flex items-center rounded-full p-1 cursor-pointer ${
              appliedFilters["Is Projects"] ? "bg-blue-700" : "bg-gray-300"
            }`}
          >
            <div
              className={`bg-white w-3 h-3 rounded-full shadow-md transform ${
                appliedFilters["Is Projects"]
                  ? "translate-x-5"
                  : "translate-x-0"
              }`}
            />
          </button>
        </div>
      </div>

      <div className="border-b text-gray-500 border-gray-300 py-3">
        <div className="flex  items-center justify-between text-gray-500">
          <p className="text-xs font-medium">Past Clients</p>
          <button
            onClick={() => handleToggleButton("Is Clients")}
            className={`w-10 h-5 flex items-center rounded-full p-1 cursor-pointer ${
              appliedFilters["Is Clients"] ? "bg-blue-700" : "bg-gray-300"
            }`}
          >
            <div
              className={`bg-white w-3 h-3 rounded-full shadow-md transform ${
                appliedFilters["Is Clients"] ? "translate-x-5" : "translate-x-0"
              }`}
            />
          </button>
        </div>
      </div>

      {filterOptions?.filter(({ condition }) => condition !== false)
      .map(({ key, title, type, options, showOptions }) => (
        <div className="border-b text-gray-500 border-gray-300 py-3">
          <div
            className="flex  items-center justify-between text-gray-500"
            onClick={() => handleToggle(key)}
          >
            <p className="text-xs font-medium">{title}</p>
            <svg
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              className={`w-5 h-5 transition-transform duration-200 ease-in-out ${
                openKeys[key] ? "transform rotate-180" : ""
              }`}
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          {openKeys[key] && (
            <>
              {type === "checkboxes" && (
                <CheckboxFilter
                  addedFilters={appliedFilters}
                  handleInputChange={handleInputChange}
                  title={title}
                  field={key}
                  options={options}
                  facetsData = {facetsData}
                />
              )}
              {type === "radio" && (
                <RadioFilter
                  addedFilters={appliedFilters}
                  handleInputChange={handleInputChange}
                  title={title}
                  field={key}
                  options={options}
                  showOptions={showOptions}
                />
              )}
              {type === "search checkbox" && (
                <SearchCheckboxFilter
                  addedFilters={appliedFilters}
                  handleInputChange={handleInputChange}
                  title={title}
                  type={type}
                  options={options}
                />
              )}
              {type === "range" && (
                <RangeFilter
                  addedFilters={appliedFilters}
                  handleInputChange={handleInputChange}
                  title={title}
                  type={type}
                  options={options}
                />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterSection;
