class OrderInfo {
    constructor(plan, info, supplierId, ) {
        this.supplierId = supplierId;
        if (plan) {
            this.amount = plan.amount;
            this.desc = plan.desc;
            this.items = plan.items;
            this.businessName = info.businessName;
            this.gstin = info.gstin;
            this.address = info.address; 
        }
        if(info) {
            this.invoiceDetails = {
                name: info.name,
                businessName: info.businessName,
                mobile: info.mobile,
                gstin: info.gstin,
                address: info.address,
                email : info.email,  
                stateCode : info.stateCode
            }
        }
    }
  }

  class PaymentInfo {
    constructor( info ) {
       this.paymentOrderId = info?.razorpay_order_id;
       this.paymentId = info?.razorpay_payment_id;
       this.signature = info?.razorpay_signature;
    }
  }


export default { OrderInfo };
export { OrderInfo, PaymentInfo };


