import React from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Box,
  FormHelperText,
  Button,
  Autocomplete,
  Typography,
  Chip,
  Paper,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { updateSupplierStepper } from "actions/businessActions";
import CustomToolbar from "components/QuillCustomTollBar";
import { QuillEditor } from "components";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 200,
    },
  },
}));

function BusinessRegStep2({ ...rest }) {
  const dispatch = useDispatch();
  const mobileNumberRegex = /^[0-9]{10}$/; // Change this regex according to your requirements

  const classes = useStyles();

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const [tags, setTags] = React.useState([]);
  const business = useSelector((state) => state.business);
  const supplierId = business.profile?.supplierId;

  const BUSINESS_DOMAIN = [
    "AIRPORTS",
    "BRIDGES",
    "COMMERCIAL",
    "DATA CENTERS",
    "HOSPITALS",
    "HOTEL",
    "INDUSTRIAL / PHARMA",
    "INSTITUTIONAL",
    "METRO",
    "RAILWAY",
    "RESIDENTIAL",
    "ROADS",
  ];

  return (
    <Grid sx={{ maxWidth: "100vw" }}>
      <Formik
        initialValues={{
          businessHeadline: "",
          domains: [],
          description: "",
          stepperCountValue: 2,
        }}
        validationSchema={Yup.object().shape({
          businessHeadline: Yup.string()
            .max(100, "Business headline cannot exceed 100 characters")
            .matches(
              /[a-zA-Z]/,
              "Business Headline should have atleast one alphabet"
            )
            .required("Business Headline is required"),
          description: Yup.string()
            .max(2000, "Business description cannot exceed 2000 characters")
            .required("Business Description is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // console.log("values...", values)
          try {
            values.supplierId = supplierId;
            dispatch(updateSupplierStepper(values));
          } catch (error) {
            console.log("error", error);
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} {...rest}>
            <Grid container flexDirection="column" gap={2}>
              <Grid>
                <Typography
                  style={{
                    color: " #5E5873",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "17.4px",
                    letterSpacing: "0.14px",
                    marginBottom: "5px",
                  }}
                >
                  Can I have your Business Headline ?
                </Typography>
                <TextField
                  size="small"
                  name="businessHeadline"
                  id="businessHeadline"
                  type="businessHeadline"
                  value={values.businessHeadline}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(
                    touched.businessHeadline && errors.businessHeadline
                  )}
                  helperText={
                    touched.businessHeadline && errors.businessHeadline
                  }
                />
              </Grid>
              <Grid>
                <Typography
                  style={{
                    color: " #5E5873",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "17.4px",
                    letterSpacing: "0.14px",
                    marginBottom: "5px",
                  }}
                >
                  Tell us more about your Business.
                </Typography>
                <Grid style={{border:"1px solid rgb(94, 88, 115)" ,borderRadius:"5px"}}> 
                <CustomToolbar />
                <QuillEditor
                        required
                        className={classes.editor}
                        value={values.description}
                        onChange={(value) => setFieldValue("description", value)}
                        onBlur={() => handleBlur("description", values.description)}
                        modules={modules}
                        placeholder={"Description......"}
                        error={Boolean(touched.description && errors.description)}
                        helperText={
                            touched.description && errors.description
                                ? "Please enter discription"
                                : ""
                        }
                    />
                </Grid>
                
                    {touched.description && errors.description && (
                    <Box mt={"8px"}>
                        <FormHelperText error>{errors.description}</FormHelperText>
                    </Box>
                )}
                
              </Grid>
              <Grid>
                <Typography
                  style={{
                    color: " #5E5873",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "17.4px",
                    letterSpacing: "0.14px",
                    marginBottom: "5px",
                  }}
                >
                  In which Domain Do you work ?
                </Typography>
                <Autocomplete
                  size="small"
                  multiple
                  value={values.domains}
                  onChange={(event, newValue) => {
                    setFieldValue(
                      "domains",
                      newValue.map((option) => option.value || option)
                    );
                  }}
                  disablePortal
                  id="domains"
                  options={BUSINESS_DOMAIN}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      name="domains"
                      placeholder="Add Domains"
                      value={tags}
                      onChange={(e) =>
                        setTags([...tags, e.target.value.split(",")])
                      }
                      // error={formValid.domains && formValid.domains.isRequired}
                    />
                  )}
                />
              </Grid>

              {/* Add other form fields using Field component here */}

              <Button
                color="secondary"
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  height: "38px",
                  fontSize: "14px",
                  fontWeight: 600,
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", 
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  },
                }}
                disabled={isSubmitting}
              >
                Save & Next{" "}
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
}

export default BusinessRegStep2;
