import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "utils/axios";

const ListOfBlog = () => {
  const [getBlogs, setGetBlogs] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteBlog, setDeleteBlog] = useState();
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
  };

  const handleOpen = (blog) => {
    setOpen(true);
    setDeleteBlog(blog);
  };
  const handleClose = () => setOpen(false);

  const getAllBlogs = async () => {
    try {
      const response = await axios.get("/api/blogs/getallblogs");
      setGetBlogs(response?.data?.blogs);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleViewClick = async (blogId) => {
    try {
      const response = await axios.get(
        `/api/blogs/get/${blogId}`
      );
      const { blog } = response?.data;
      navigate("/cms/pre-view-blog", { state: { blog, getBlogs } });
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  // To handle update the blog
  const handleEditBlog = (blog) => {
    navigate("/cms/blog-form", { state: blog });
  };

  // To handle publish the blog
  const handlePublishBlog = async (blog) => {
    const response = await axios.post(
      `/api/blogs/publish/${blog._id}`,
      blog
    );
    if (response.status === 200) {
      getAllBlogs();
    }
  };

  // To handle unpublish the blog
  const handleUnpublishBlog = async (blog) => {
    const response = await axios.delete(
      `/api/blogs/unpublish/${blog._id}`
    );
    if (response.status === 200) {
      getAllBlogs();
    }
  };

  // To handle delete the blog
  const handleDeleteBlog = async (blog) => {
    const response = await axios.delete(
      `/api/blogs/deleteBlog/${blog._id}`
    );
    if (response.status === 200) {
      getAllBlogs();
    }
    setOpen(false);
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <>
      <Grid
      sx={{marginTop:"50px"}}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {/* <Grid > */}
       
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "20px", fontWeight: 700 }}>
                    Heading Of Blog
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getBlogs?.map((blog) => (
                  <TableRow key={blog.id}>
                    <TableCell component="th" scope="row" width={"600px"}>
                      <Typography
                        variant="body1"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleViewClick(blog._id)}
                      >
                        {blog.heading}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                       sx={{
                        cursor: "pointer",
                        width: "140px",
                        paddingY: "10px",
                        // background: "#46AC9E",
                        borderRadius: "4px",
                        margin: "auto",
                        mb: "30px",
                        cursor: "pointer",
                        marginRight: "20px",
                        "&:hover": {
                          backgroundColor:"#46AC9E",// Change background color on hover
                          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                          transition: "background-color 0.3s, box-shadow 0.3s",
                        },
                      }}
                        variant="contained"
                        onClick={() => handleViewClick(blog._id)}
                      >
                        View Temporary
                      </Button>
                      <Button
                       sx={{
                        cursor: "pointer",
                        width: "100px",
                        paddingY: "10px",
                        // background: "#FF6600",
                        borderRadius: "4px",
                        margin: "auto",
                        mb: "30px",
                        cursor: "pointer",
                        marginRight: "20px",
                        "&:hover": {
                          backgroundColor: "#46AC9E", // Change background color on hover
                          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                          transition: "background-color 0.3s, box-shadow 0.3s",
                        },
                      }}
                        variant="contained"
                        onClick={() => handleEditBlog(blog)}
                      >
                        Edit
                      </Button>
                      <Button
                       sx={{
                        cursor: "pointer",
                        width: "100px",
                        paddingY: "10px",
                        // background: "#FF6600",
                        borderRadius: "4px",
                        margin: "auto",
                        mb: "30px",
                        cursor: "pointer",
                        marginRight: "20px",
                        "&:hover": {
                          backgroundColor: "#46AC9E", // Change background color on hover
                          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                          transition: "background-color 0.3s, box-shadow 0.3s",
                        },
                      }}
                        variant="contained"
                        onClick={() => handlePublishBlog(blog)}
                        disabled={blog?.published}
                      >
                        Publish
                      </Button>
                      <Button
                      sx={{
                        cursor: "pointer",
                        width: "100px",
                        paddingY: "10px",
                        // background: "#FF6600",
                        borderRadius: "4px",
                        margin: "auto",
                        mb: "30px",
                        cursor: "pointer",
                        marginRight: "20px",
                        "&:hover": {
                          backgroundColor: "#46AC9E", // Change background color on hover
                          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                          transition: "background-color 0.3s, box-shadow 0.3s",
                        },
                      }}
                        // sx={{ marginRight: "20px" }}
                        variant="contained"
                        onClick={() => handleUnpublishBlog(blog)}
                        disabled={!blog?.published}
                      >
                        Unpublish
                      </Button>
                      <Button
                      sx={{
                        cursor: "pointer",
                        width: "100px",
                        paddingY: "10px",
                        background: "#D82634",
                        borderRadius: "4px",
                        margin: "auto",
                        mb: "30px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#D82634", // Change background color on hover
                          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                          transition: "background-color 0.3s, box-shadow 0.3s",
                        },
                      }}
                        // sx={{ background: "#D82634" }}
                        variant="contained"
                        onClick={() => handleOpen(blog)}
                      >
                        delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        {/* </Grid> */}
      </Grid>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-description"
              sx={{ fontSize: "20px", fontWeight: 600 }}
            >
              Are you sure you want to delete this blog permanently?
            </Typography>
            <Grid sx={{ display: "flex", marginTop: "20px" }}>
              <Button
                sx={{
                  cursor: "pointer",
                  width: "100px",
                  paddingY: "10px",
                  background: "#46AC9E",
                  borderRadius: "4px",
                  margin: "auto",
                  mb: "30px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#46AC9E", // Change background color on hover
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  },
                }}
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  cursor: "pointer",
                  width: "100px",
                  paddingY: "10px",
                  background: "#D82634",
                  borderRadius: "4px",
                  margin: "auto",
                  mb: "30px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#D82634", // Change background color on hover
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  },
                }}
                variant="contained"
                onClick={() => handleDeleteBlog(deleteBlog)}
              >
                Delete
              </Button>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ListOfBlog;
