import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  Link,
  Typography,
  MenuList,
  MenuItem,
  DialogContent,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";

import { headCells } from "./utils";
import { memberDelete, changeOwnerType } from "actions/membersAction";
import ChangeRoll from "./ChangeRoll";

function MembersList({ title, members }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [modal, setModal] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [rowData, setRowData] = useState("");

  const [view, setView] = useState(false);

  const openDialogView = (memberType) => {
    setView(true);
    setRowData(memberType);
  };

  const viewClose = () => {
    setView(false);
  };

  const getOwnerType = (supplierId, ownerType, memberId) => {
    changeOwnerType(supplierId, ownerType, memberId);

    viewClose();
  };

  useEffect(() => {}, [members]);
  // const closeDailog = () => {
  //   setModal(false)
  // };
  // const OpenDialog = () => {
  //   setModal(true)
  // };

  const deleteMember = (supplierId, userId) => {
    setModal(true);
    try {
      dispatch(memberDelete(supplierId, userId));
    } catch (error) {
      // console.log(error)
    }
  };

  // console.log("members ======: ", members);

  return (
    <Card mt={2}>
      <CardHeader title={title} />
      <Divider sx={{ mt: 1 }} />
      <CardContent>
        <Box sx={{ height: 400 }}>
          <DataGrid
            rows={members}
            columns={headCells(
              true,
              deleteMember,
              modal,
              setModal,
              openDialogView
            )}
            getRowId={(row: any) => row.memberId}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
          />
        </Box>

        <Dialog onClose={viewClose} open={view} maxWidth="md">
          <DialogContent>
            <ChangeRoll
              viewClose={viewClose}
              getOwnerType={getOwnerType}
              rowData={rowData}
            />
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
}

MembersList.propTypes = {
  title: PropTypes.string,
  members: PropTypes.array,
};

export default MembersList;
