import axios from "utils/axios";

import {
  REQUIREMENTS_FETCH_URL,
  REQUIREMENT_ADD_URL,
  REQUIREMENT_DELETE_URL,
  VIEW_REQUIREMENT_FETCH_URL,
  REQUIREMENT_UPDATE_URL,
  SEND_NEW_ENQUIRY_URL,
  SEND_EXISTING_ENQUIRY_URL,
  REQUIREMENT_CLOSE_URL,
  REQUIREMENT_RESEND_URL,
  SEND_REPEAT_FIXED_RFQ,
  GET_REPEAT_FIXED_RFQ,
  GET_SEARCH_RFQ,
  GET_SEARCH_REPEAT_FIXED_RFQ,
  ADD_SUPPLIER_TO_RFQ,
  EDIT_REPEAT_FIXED_RFQ,
  DELETE_REPEAT_FIXED_RFQ,
  SEARCH_REPEAT_FIXED_RFQ,
} from "config/services";

class RequirementsService {
  fetchRequirements = (userId, pageNumber, filterDayValue) =>
    new Promise((resolve, reject) => {
      // console.log("RequirementsService.fetchRequirements called+++++",mobile);
      // const userMobile = mobile
      const page = pageNumber || 1;
      let url;
      if (filterDayValue) {
        url = `/api/rfqs/by/page?userId=${userId}&page=${page}&startDate=${filterDayValue}`;
      } else {
        url = `/api/rfqs/by/page?userId=${userId}&page=${page}`;
      }
      axios
        // .get(`http://localhost:5035${url}`)
        .get(`${url}`)
        // .post(REQUIREMENTS_FETCH_URL, { userUserId })
        .then((response) => {
          // console.log("RequirementsService.fetchRequirements() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log(
            //   "RequirementsService.fetchRequirements() error:",
            //   error
            // );
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  resendRequirements = (
    userId,
    rfqId,
    supplierId,
    additionalNote,
    currentPage
  ) =>
    new Promise((resolve, reject) => {
      // console.log("RequirementsService.fetchRequirements called+++++",mobile);
      const page = currentPage;
      axios
        .post(REQUIREMENT_RESEND_URL, {
          // .post("http://localhost:5035/api/rfqs/resend", {
          userId,
          rfqId,
          supplierId,
          additionalNote,
          page,
        })
        .then((response) => {
          // console.log("RequirementsService.fetchRequirements() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log(
            //   "RequirementsService.fetchRequirements() error:",
            //   error
            // );
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  addVendorToExistingRfq = (userId, rfqId, supplierList, currentPage) =>
    new Promise((resolve, reject) => {
      // console.log("RequirementsService.fetchRequirements called+++++",mobile);
      const page = currentPage || 1;
      axios
        .put(ADD_SUPPLIER_TO_RFQ, {
          userId,
          rfqId,
          supplierList,
          page,
        })
        .then((response) => {
          // console.log("RequirementsService.fetchRequirements() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data) {
              resolve(response?.data);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log(
            //   "RequirementsService.fetchRequirements() error:",
            //   error
            // );
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  addRequirements = (newReqInfo) =>
    new Promise((resolve, reject) => {
      // console.log("AddReqService.addRequirements called", newReqInfo);
      const payload = newReqInfo;
      axios
        .post(REQUIREMENT_ADD_URL, { payload })
        .then((response) => {
          // console.log("AddReqService.addRequirements() resp:", response)
          if (response?.data?.error) {
            //console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.addRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  updateRequirements = (newReqInfo) =>
    new Promise((resolve, reject) => {
      // console.log("AddReqService.updateRequirements called", newReqInfo);
      const payload = newReqInfo;
      axios
        .post(REQUIREMENT_UPDATE_URL, { payload })
        .then((response) => {
          // console.log("AddReqService.addRequirements() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.updateRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  deleteRequirement = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("AddReqService.deleteRequirement called", payload);

      axios
        .post(REQUIREMENT_DELETE_URL, { payload })
        .then((response) => {
          // console.log("AddReqService.addRequirements() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.deleteRequirement() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  closeRequirement = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("AddReqService.closeRequirement called", payload);

      axios
        .post(REQUIREMENT_CLOSE_URL, { payload })
        .then((response) => {
          // console.log("AddReqService.closeRequirement() resp:", response);
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.closeRequirement() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  fetchRequirement = (supplierId, rfqId) =>
    new Promise((resolve, reject) => {
      // console.log("AddReqService.deleteRequirement called", payload);
      let rfqUrl = `${VIEW_REQUIREMENT_FETCH_URL}?supplierId=${supplierId}&rfqId=${rfqId}`;
      axios
        .get(rfqUrl, {})
        .then((response) => {
          // console.log("AddReqService.addRequirements() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.deleteRequirement() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  sendNewEnquiry = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("AddReqService.sendNewEnquiry called}}}}}}", payload);
      axios
        .post(SEND_NEW_ENQUIRY_URL, { payload })
        .then((response) => {
          // console.log("AddReqService.sendNewEnquiry() resp:", response);
          if (response?.data?.error) {
            //console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.sendNewEnquiry() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  sendExistingEnquiry = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("AddReqService.sendExistingEnquiry called", payload);
      axios
        .post(SEND_EXISTING_ENQUIRY_URL, { payload })
        .then((response) => {
          // console.log("AddReqService.sendExistingEnquiry() resp:", response)
          if (response?.data?.error) {
            //console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.sendExistingEnquiry() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  sendRepeatFixedRfq = (sendRfq) =>
    new Promise((resolve, reject) => {
      axios
        .post(SEND_REPEAT_FIXED_RFQ, sendRfq)
        // .post("http://localhost:5035/api/rfqs/repeat-fixed/create", sendRfq)
        .then((response) => {
          if (response?.data?.error) {
            //console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.addRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  editRepeatFixedRfq = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .patch(EDIT_REPEAT_FIXED_RFQ, payload)
        .then((response) => {
          if (response?.data?.error) {
            //console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.addRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  fetchRepeatFixedRfq = (userIds, pageNumber) =>
    new Promise((resolve, reject) => {
      const page = pageNumber || 1;
      let rfqUrl = `${GET_REPEAT_FIXED_RFQ}?page=${page}`;
      axios
        .post(rfqUrl, { userIds, page })
        // .get(
        //   `http://localhost:5035/api/rfqs/repeat-fixed/rfqs?userId=${userId}&page=${pageNum}`
        // )
        .then((response) => {
          if (response?.data?.error) {
            //console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.addRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  searchRepeatFixedRfqs = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(SEARCH_REPEAT_FIXED_RFQ, payload)
        .then((response) => {
          if (response?.data?.error) {
            //console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.addRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
  };

  deleteRepeatFixedRfq = (payload) =>
    new Promise((resolve, reject) => {
      const pageNumber = payload?.page ?? 1;
      const userId = payload?.userId;
      const repeatRfqId = payload?.repeatRfqId;
      axios
        .delete(
          `${DELETE_REPEAT_FIXED_RFQ}?repeatRfqId=${repeatRfqId}&userId=${userId}&page=${pageNumber}`
        )
        .then((response) => {
          if (response?.data?.error) {
            //console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.addRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  searchRepeatFixedRfq = (userId, searchKey, pageNumber) =>
    new Promise((resolve, reject) => {
      const page = pageNumber || 1;
      let searchRepeatRfqsUrl = `${GET_SEARCH_REPEAT_FIXED_RFQ}?userId=${userId}&searchParam=${searchKey}&page=${page}`;
      // console.log("this is searchRepeatRfqsUrl url ", searchRepeatRfqsUrl);
      axios
        .get(searchRepeatRfqsUrl)
        .then((response) => {
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.addRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  searchRfqs = (userId, searchKey, pageNumber) =>
    new Promise((resolve, reject) => {
      const page = pageNumber || 1;
      let searchRfqsUrl = `${GET_SEARCH_RFQ}?userId=${userId}&searchParam=${searchKey}&page=${page}`;
      // console.log("this is url ", searchRfqsUrl);
      axios
        .get(searchRfqsUrl)
        .then((response) => {
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("AddReqService.addRequirements() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}
const requirementsService = new RequirementsService();

export default requirementsService;
