import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { useTheme } from "@mui/material/styles";
import OTPInput, { ResendOTP } from "otp-input-react";
import OtpInput from "react-otp-input";
import "./stylePopup.css";

import {
  Box,
  Button,
  FormHelperText,
  Fab,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import { verifyRegisterOTP, registerUserWithMobile, sendwhatsAppOTP } from "actions/authActions";
import { SIGN_UP_COUNTER } from "config";
import { height } from "@mui/system";
import { addLeadActivity } from "actions/crmAction";

function SignInOtpVerifyFormPopUp({
  isWhatsappNo,
  setOtpPopUp,
  setOpenLogInModal,
  className,
  mobile,
  ...rest
}) {
  const dispatch = useDispatch();
  const [counter, setCounter] = React.useState(SIGN_UP_COUNTER);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const auth = useSelector((state) => state.auth);
  const isUserRegisterd = auth.userRegisterOrNot;

  const crmLeadId = useSelector((state) => state.crm);

  useEffect(() => {
    const activityData = {
      RelatedProspectId: crmLeadId.leadId,
      ActivityEvent: 209,
      ActivityNote: "User Attempt to Sign In",
    };
    dispatch(addLeadActivity(activityData));
  }, [crmLeadId.leadId]);
  const onSubmitSuccess = () => {
    setOpenLogInModal(false);
    if (isUserRegisterd === false) {
      navigate("/register/user",{state:{"isWhatsappNo":isWhatsappNo}});
    } else {
      const activityData = {
        RelatedProspectId: crmLeadId.leadId,
        ActivityEvent: 209,
        ActivityNote: "User Sign In Successfully",
      };
      dispatch(addLeadActivity(activityData));
      navigate(auth?.latestUrl);
    }
  };
  const handleChangeMobile = () => {
    setOtpPopUp(true);
  };

  const resendOTP = () => {
    // console.log("Sending Request for sending OTP", mobile);

    try {
      const name = "";
      if (isWhatsappNo) {
        dispatch(sendwhatsAppOTP(mobile))
      }else{
        dispatch(registerUserWithMobile(name, mobile));
      }
      setCounter(SIGN_UP_COUNTER);
    } catch (error) {
      // console.log("resendOTP: error", error);
    }
    // dispatch()
  };

  // state = { otp: '' };

  const [otp1, setOTP1] = useState("");

  const otpHandleChange = (otp1) => {
    setOTP1(otp1);
  };

  return (
    <Formik
      initialValues={
        {
          // otp1: "",
          // otp2: "",
          // otp3: "",
          // otp4: "",
        }
      }
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const otpA = [values.otp1, values.otp2, values.otp3, values.otp4];
          const otp = otpA.join("");
          const name = "";
          const sourceName = "web app"
          await dispatch(
            verifyRegisterOTP(mobile, name, otp1, crmLeadId.leadId,sourceName ,isWhatsappNo)
          );
          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={12}
          >
            <Grid
              xs={12}
              mb={2}
              border={"1px solid"}
              p={1.5}
              borderRadius={"3px"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                color={"#000"}
                variant="subtitle2"
                letterSpacing={"1px"}
              >
                +91 {mobile}
              </Typography>
              <Typography
                onClick={handleChangeMobile}
                className="link_change_phone"
              >
                Change Phone
              </Typography>
            </Grid>

            <Grid xs={12} mb={2} mt={1}>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "16px",
                }}
              >
                Enter OTP Here
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
              {isMd ? (
                <>
                  <TextField
                    fullWidth
                    required
                    autoFocus
                    sx={{ letterSpacing: "10px" }}
                    placeholder="OTP"
                    onChange={(e) =>
                      setOTP1(e.target.value.replace(/[^0-9]/g, ""))
                    }
                    value={otp1}
                    inputProps={{
                      maxLength: 4,
                      inputMode: "numeric",
                    }}
                  />
                </>
              ) : (
                <span style={{ marginLeft: "22px" }}>
                  <OtpInput
                    className="otpInputStyleCssPopup"
                    value={otp1}
                    onChange={otpHandleChange}
                    numInputs={4}
                    separator={<span></span>}
                    shouldAutoFocus
                    isInputNum
                    inputStyle={{ width: "2rem" }}
                    onBlur={(e, index) => {
                      const value = e.target.value;
                      if (!/^\d$/.test(value)) {
                        e.preventDefault();
                        return;
                      }
                    }}
                  />
                </span>
              )}
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit == "Request failed with status code 401" ? "Please enter correct OTP" : errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid item container alignItems={"left"} justifyContent={"left"}>
              {counter > 0 && (
                <Box mt={2}>
                  <Typography
                    fontWeight={500}
                    align="center"
                    color="textSecondary"
                  >
                    You will receive OTP in{" "}
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      {" "}
                      {counter}
                    </span>{" "}
                    secs
                  </Typography>
                </Box>
              )}

              {counter <= 0 && (
                <Box mt={3} display={"flex"}>
                  <Typography
                    fontWeight={500}
                    align="left"
                    color="textSecondary"
                  >
                    Not recevied OTP? {"  "}
                    <Link
                      component={"button"}
                      color={"primary"}
                      onClick={resendOTP}
                      underline={"none"}
                      sx={{ marginLeft: "5px" }}
                    >
                      Resend OTP
                    </Link>
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 2 }}
              xs={12}
            >
              <Button
                className="customButtonLoginPopup"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{backgroundColor: "#FD6600",
               color:"white",
               "&:hover": {
                 backgroundColor: "#FD6600",
                 transition: "background-color 0.3s, box-shadow 0.3s",
                 boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
               }
               }}
              >
                submit
              </Button>
              <Typography
                mt={2}
                fontWeight={300}
                fontSize="12px"
                align="center"
                color="textSecondary"
              >
                By signing in, you agree to portals{" "}
                <RouterLink to="/terms-and-condition" target="_blank">
                  Terms of Use
                </RouterLink>
              </Typography>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignInOtpVerifyFormPopUp.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  mobile: PropTypes.string,
};

SignInOtpVerifyFormPopUp.default = {
  onSubmitSuccess: () => { },
};

export default SignInOtpVerifyFormPopUp;
