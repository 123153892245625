import React, { useEffect, useState } from "react";
import { FilterSection, RfqsTableSection } from "./Components";
import BreadCrumbs from "./Components/BreadCrumbs";
import GeneralInformation from "./Components/GeneralInformation";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import RfqDetailsSection from "./Components/RfqDetailsSection";
import {
  FilterRfqByDate,
  fetchRfqProjects,
  fetchRfqs,
  fetchSearchRfqs,
  saveRfq,
  searchRfqWithDate,
} from "actions/rfqActions";
import SelectVendorsSlider from "./Components/SelectVendorsSlider";
import SidePanel from "layouts/SidePanel";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import DateRangeComponent from "./DateRangeComponent";
import DateRangePicker from "./DateRangePicker ";

const validationSchema = Yup.object({
  rfqName: Yup.string().required("RFQ Name is required"),
  // estimatedValue: Yup.number().required("Estimated Value is required"),
  requirementType: Yup.string().required("Requirement Type is required"),
  emailCopyRecipients: Yup.array()
    .of(Yup.string().email("Invalid email format"))
    .required("Email Copy Recipients is required"),
  projectName: Yup.string().required("Project Name is required"),
  // projectLocation: Yup.string().required("Project Location is required"),
  creditPeriod: Yup.string(),
  rfqDetails: Yup.string().required("RFQ Details is required"),
});

const RfqsSections = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const rfqData = useSelector((state) => state.rfq);
  const rfqsData = useSelector((state) => state.rfqs);
  const auth = useSelector((state) => state.auth);
  const projectData = useSelector((state) => state.rfqProjects);

  const projects = projectData?.projects ?? [];
  const user = auth?.user;

  const currentPage = location.pathname;
  const [workspaceView, setWorkspaceView] = useState("My Workspace");
  const [workspaceDropDownValue, setWorkspaceDropDownValue] = useState("All");
  const [rfqFilterStatus, setRfqFilterStatus] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);
  const [openAddVendorOption, setOpenAddVendorOption] = useState(false);
  const [sliderName, setSliderName] = useState("");
  const [selectedFilesBase, setSelectedFilesBase] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([user?.userId]);
  const [dateFilter, setDateFilter] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [clearFilter, setClearFilter] = useState({ status: false, name: "" });
  const [rfqFormValues, setRfqFormValues] = useState({
    rfqName: "",
    estimatedValue: "",
    requirementType: "",
    dueDate: "",
    emailCopyRecipients: [],
    projectName: "",
    projectLocation: "",
    creditPeriod: "",
    rfqDetails: "",
    indentId: "",
  });

  const today = new Date().toISOString().split("T")[0];
  const loading = rfqData?.loading;
  const entityId = user?.userEntity?.entityId;
  const entityName = user?.userEntity?.entityName;
  const directoryName = user?.userEntity?.directoryName;
  const userId = user?.userId;
  const teamUserIds = user?.teamMembers?.map((member) => member.userId);

  const updateRfqFormValues = (updatedValues) => {
    setRfqFormValues(updatedValues);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // handle form submission here
    // Find the selected project
    const selectedProject = projects.find(
      (project) => project._id === values.projectName
    );
    const formValue = {
      ...values,
      projectId: selectedProject._id,
    };
    setRfqFormValues(formValue);
    const response = await dispatch(
      saveRfq({
        values: { ...formValue, projectName: selectedProject.name , entityName:entityName },
        selectedFilesBase,
        entityId,
      })
    );
  };
  const toggleForm = () => {
    setShowForm(!showForm);
    if (showForm) {
      navigate("/rfqs");
    } else {
      navigate("/rfqs/create");
    }
  };

  const handleSliderOpen = (title) => {
    setSliderName(title);
    setOpenSlider(true);
  };
  const handleSliderClose = () => {
    setOpenSlider(false);
  };

  const handleGetRfqs = ({
    status = "",
    searchKey = rfqsData?.searchParam,
  }) => {
    if (searchKey === "" && startDate === "" && endDate === "") {
      dispatch(fetchRfqs({ entityId, userIds: teamMemberData, status }));
      return;
    }
    if (startDate != "" && endDate != "") {
      fetchFilterDateRfq({ page: 1, status, searchKey });
    } else {
      // dispatch(fetchRfqs({ entityId, userIds: teamMemberData, status }));
      dispatch(
        fetchSearchRfqs({
          entityId,
          searchParam: searchKey,
          status,
          userIds: teamMemberData,
        })
      );
    }
  };
  const handleCloseRfqForm = () => {
    setRfqFormValues({
      rfqName: "",
      estimatedValue: "",
      requirementType: "",
      dueDate: new Date(),
      emailCopyRecipients: [],
      projectName: "",
      projectLocation: "",
      creditPeriod: "",
      rfqDetails: "",
    });
    fetchPersonalRfqs();
    setOpenAddVendorOption(false);
  };

  const handleChangeTeamMember = (event) => {
    const selectedUserId = event.target.value;
    setStartDate("");
    setClearFilter({ status: true, name: "team member" });
    setEndDate(today);
    setDateFilter(false);
    setWorkspaceDropDownValue(selectedUserId);
    if (selectedUserId === "All") {
      fetchAllRfqs();
    } else if (selectedUserId === "Team") {
      dispatch(
        fetchRfqs({ entityId, userIds: teamUserIds, status: rfqFilterStatus })
      );
      setTeamMemberData(teamUserIds);
    } else {
      dispatch(
        fetchRfqs({
          entityId,
          userIds: [selectedUserId],
          status: rfqFilterStatus,
        })
      );
      setTeamMemberData([event.target.value]);
    }
  };

  const handleChangeTeamMemberNew = (selectedUserId) => {
    dispatch(
      searchRfqWithDate({
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds:
          selectedUserId.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserId,
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData(selectedUserId);
  };

  const fetchAllRfqs = () => {
    setWorkspaceView("Team Workspace");
    setStartDate("");
    // setClearFilter({ status: true, name: "all rfq" });
    setEndDate(today);
    setDateFilter(false);
    dispatch(
      searchRfqWithDate({
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds: [...teamUserIds, user?.userId],
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData([...teamUserIds, user?.userId]);
    setWorkspaceDropDownValue("All");
  };

  const fetchPersonalRfqs = () => {
    setWorkspaceView("My Workspace");
    setStartDate("");
    // setClearFilter({ status: true, name: "fetchPersonalRfqs" });
    // setEndDate(today);
    // setDateFilter(false);

    dispatch(
      searchRfqWithDate({
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds: [userId],
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData([user?.userId]);
    setWorkspaceDropDownValue("All");
  };

  const fetchFilterDateRfq = ({
    page = 1,
    status = rfqFilterStatus,
    searchKey,
  } = {}) => {
    dispatch(
      searchRfqWithDate({
        entityId,
        searchParam: searchKey,
        status,
        userIds: teamMemberData.map((number) => String(number)),
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
      })
    );
  };

  const handleApplyFilterDate = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    // dispatch(FilterRfqByDate(payload));
    dispatch(
      searchRfqWithDate({
        entityId,
        searchParam: rfqsData?.searchParam,
        status: rfqFilterStatus,
        userIds: teamMemberData.map((number) => String(number)),
        startDate,
        endDate,
      })
    );
  };
  const clearDateFilter = () => {
    // dispatch(
    //   fetchRfqs({ entityId, userIds: [userId], status: rfqFilterStatus })
    // );
    dispatch(
      fetchSearchRfqs({
        entityId,
        searchParam: rfqsData?.searchParam,
        status: rfqFilterStatus,
        userIds: [userId],
      })
    );
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    dispatch(
      fetchRfqs({ entityId, userIds: [userId], status: rfqFilterStatus })
    );
    dispatch(fetchRfqProjects(entityId));
    if (currentPage === "/rfqs/create") {
      setShowForm(true);
    }
  }, []);

  useEffect(() => {
    if (currentPage === "/rfqs/create") {
      setShowForm(true);
    }
    if (currentPage === "/rfqs") {
      setShowForm(false);
    }
  }, [currentPage]);

  return (
    <SidePanel>
      <div>
        <div className="bg-white sticky top-0 z-10 shadow-md p-4">
          <h1 className="text-2xl font-bold">RFQs</h1>
        </div>
        {!showForm ? (
          <div className="p-5 min-h-[calc(100vh-64px)]  bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] ">
            <div className="w-full flex gap-4">
              <div className="min-w-[200px] h-10">
                {/* {user?.teamMembers?.length > 0 &&
                  workspaceView === "Team Workspace" && (
                    <FormControl size="small" sx={{ minWidth: 150 }}>
                      <Select
                        id="demo-simple-select-helper"
                        value={workspaceDropDownValue}
                        onChange={handleChangeTeamMember}
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"Team"}>Team</MenuItem>
                        {user?.teamMembers?.map((member) => (
                          <MenuItem value={member.userId} key={member.userId}>
                            {member?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )} */}
              </div>
              <div className="w-full flex items-end">
                {user?.teamMembers?.length > 0 && (
                  <div className="flex gap-4">
                    <button
                      className={`pb-2 ${
                        workspaceView === "My Workspace"
                          ? " border-b border-orange-500 font-medium"
                          : ""
                      } `}
                      onClick={fetchPersonalRfqs}
                    >
                      <p className="text-sm">My Workspace</p>
                    </button>
                    <button
                      className={`pb-2 ${
                        workspaceView === "Team Workspace"
                          ? " border-b border-orange-500 font-medium"
                          : ""
                      } `}
                      onClick={fetchAllRfqs}
                    >
                      <p className="text-sm">Team Workspace</p>
                    </button>
                  </div>
                )}
                {/* {!dateFilter ? (
                  <button
                    type="button"
                    className="m-3 rounded-md border border-blue-700 text-blue-700 hover:text-blue-800 hover:border-blue-800 shadow-sm px-2 py-1 text-sm font-medium focus:outline-none"
                    onClick={() => {
                      setDateFilter(true);
                      setEndDate(today);
                    }}
                  >
                    Date Range
                  </button>
                ) : (
                  <div className="m-3 my-0 rounded-lg flex gap-2 items-center p-1 relative">
                    <div className="flex flex-col">
                      <input
                        type="date"
                        id="start-date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        max={new Date().toISOString().split("T")[0]}
                        className="border border-gray-300 rounded-md p-2"
                      />
                    </div>
                    <p>To</p>
                    <div className="flex flex-col">
                      <input
                        type="date"
                        id="end-date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        max={new Date().toISOString().split("T")[0]}
                        className="border border-gray-300 rounded-md p-2"
                      />
                    </div>
                  </div>
                )} */}
                {/* <DateRangePicker
                  handleApplyFilterDate={handleApplyFilterDate}
                  clearDateFilter={clearDateFilter}
                  clearFilter={clearFilter}
                /> */}

                <button
                  className="cursor-pointer mb-2 ml-auto flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800"
                  onClick={toggleForm}
                >
                  <svg
                    className="h-3.5 w-3.5 mr-2 -ml-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    />
                  </svg>
                  Create Requirement
                </button>
              </div>
            </div>
            <div className="flex gap-4 max-h-[calc(100vh-144px)]">
              <div className="min-w-[200px] bg-white">
                <FilterSection
                  handleChangeTeamMemberNew={handleChangeTeamMemberNew}
                  workspaceView={workspaceView}
                  rfqsData={rfqsData}
                  handleGetRfqs={handleGetRfqs}
                  setRfqFilterStatus={setRfqFilterStatus}
                  user={user}
                  handleApplyFilterDate={handleApplyFilterDate}
                  clearDateFilter={clearDateFilter}
                  clearFilter={clearFilter}
                />
              </div>
              <div className="flex-grow bg-white rounded overflow-y-auto">
                <RfqsTableSection
                  handleGetRfqs={handleGetRfqs}
                  workspaceView={workspaceView}
                  entityId={entityId}
                  userIds={teamMemberData}
                  rfqsData={rfqsData}
                  rfqFilterStatus={rfqFilterStatus}
                  user={user}
                  fetchFilterDateRfq={fetchFilterDateRfq}
                  dateFilterOn={startDate != "" && endDate != ""}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="p-6 flex flex-col gap-y-6  min-h-svh bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
            <BreadCrumbs
              paths={[
                {
                  title: "RFQs",
                  onClick: () => {
                    setShowForm(false);
                    navigate("/rfqs");
                  },
                },
                {
                  title: "Create RFQ",
                  onClick: () => {
                    setShowForm(true);
                    navigate("/rfqs/create");
                  },
                },
              ]}
            />
            <Formik
              enableReinitialize
              initialValues={{
                ...rfqFormValues,
                workStartDate: Date(Date.now()),
                requesterName: user?.name || "",
                requesterMobile: user?.mobile || "",
                userBusinessName: user?.userEntity
                  ? user?.userEntity.entityName
                  : user?.company || "",
                requestedBy: user?.email || "",
                requesterId: user?.userId || "",
                isEntityUser: user?.userEntity ? true : false,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, submitForm, errors }) => (
                <Form className="flex flex-col gap-y-4">
                  <GeneralInformation projects={projects} />
                  <div className="rounded-lg shadow-md bg-white mb-20">
                    <RfqDetailsSection
                      selectedFilesBase={selectedFilesBase}
                      setSelectedFilesBase={setSelectedFilesBase}
                      fieldName="rfqDetails"
                      updateFormValues={updateRfqFormValues}
                    />
                  </div>

                  <div
                    className="p-6 flex justify-end items-center gap-4 rounded-lg shadow-md bg-white fixed bottom-0 w-screen left-0"
                    // style={{ width: "calc(100% - 132px)" }}
                    style={{
                      boxShadow: "0px -1px 14px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <button
                      className="px-4 py-2"
                      onClick={() => {
                        toggleForm();
                        handleCloseRfqForm();
                      }}
                      type="button"
                    >
                      Cancel
                    </button>
                    {!openAddVendorOption && (
                      <button
                        type="button"
                        className="px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded-lg mr-2"
                        disabled={loading} // Disable the button when loading is true
                        onClick={async (e) => {
                          e.preventDefault();
                          await submitForm();
                          const firstErrorField = Object.keys(errors)[0];
                          const errorElement =
                            document.getElementById(firstErrorField);
                          if (errorElement) {
                            errorElement.scrollIntoView({
                              behavior: "smooth",
                            });
                          } else if (values.rfqName) {
                            setShowForm(false);
                            navigate("/rfqs");
                            handleCloseRfqForm();
                          }
                        }}
                      >
                        {loading ? "Saving Draft..." : "Save as Draft"}
                      </button>
                    )}
                    <div className="relative">
                      <button
                        className={`${
                          loading
                            ? " bg-gray-300"
                            : " bg-blue-700 hover:bg-blue-800"
                        } text-white rounded-lg px-4 py-2`}
                        type="button"
                        disabled={loading || openAddVendorOption} // Disable the button when loading is true
                        onClick={async (e) => {
                          e.preventDefault();
                          await submitForm();

                          const firstErrorField = Object.keys(errors)[0];
                          const errorElement =
                            document.getElementById(firstErrorField);
                          if (errorElement) {
                            errorElement.scrollIntoView({
                              behavior: "smooth",
                            });
                          } else if (values.rfqName) {
                            setOpenAddVendorOption(true);
                          }
                          // updateRfqFormValues(values);
                          // setPressSaveOnly(false);
                        }}
                      >
                        {/* {loading ? "Loading..." : "Save and Find Vendors"} */}
                        Save and Find Vendors
                      </button>

                      {/* // up dropdown of company directory and neevay network -----------------------start5 from here */}
                      {openAddVendorOption &&
                        !loading &&
                        rfqData?.rfq?.rfqId > 0 && (
                          <div className="absolute rounded-lg py-2 bottom-[110%] right-0 w-52 bg-white border  border-gray-500">
                            <div
                              className="py-2 pl-3 hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleSliderOpen("CD")}
                            >
                              <p className="text-left font-medium text-gray-900">
                                {/* {directoryName} Directory{" "} */}
                                Internal Vendors
                              </p>
                            </div>
                            <div
                              className="py-2 pl-3 flex items-center hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleSliderOpen("NN")}
                            >
                              <div
                                style={{
                                  maxWidth: "250px",
                                  marginRight: "8px",
                                }}
                              >
                                <p className="font-medium text-gray-900">
                                  Vendor Marketplace{" "}
                                </p>
                              </div>

                              <div
                                style={{
                                  borderRadius: "3px",
                                  border: "0.5px solid #039400",
                                  background: "#E1FFDE",
                                  padding: "0 4px", // You can adjust the padding as needed
                                }}
                                className="flex items-center"
                              >
                                <span className="text-black font-inter font-semibold text-xs ">
                                  N
                                </span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="5"
                                  height="5"
                                  viewBox="0 0 5 5"
                                  fill="none"
                                >
                                  <circle
                                    cx="2.5"
                                    cy="2.5"
                                    r="2.5"
                                    fill="#039400"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        )}
                      {/* // up dropdown of company directory and neevay network -----------------------end from here */}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}

        <SelectVendorsSlider
          rfqId={rfqData?.rfq?.rfqId}
          sliderName={sliderName}
          openSlider={openSlider}
          setOpenSlider={setOpenSlider}
          handleSliderClose={handleSliderClose}
        />
      </div>
    </SidePanel>
  );
};

export default RfqsSections;
