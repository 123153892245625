import React, { useState } from "react";
import { TextField, Button, Box, Grid, Typography, CardMedia, Link, useTheme, useMediaQuery } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useFormik } from "formik";
import AddCertificateForm from "./AddCertificateForm";
import { useNavigate } from "react-router";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
import PropTypes from "prop-types";

const AddCertificate = ({ isPublicProfile, isCertificateExists, handleSubmit, permissions }) => {
  const handleAddService = () => {
    // console.log("handleAddService");
    // dispatch(addServiceTrigger);
  };
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1024));
  const isSm = useMediaQuery(theme.breakpoints.down(768));
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    if (permissions?.isUpdateAllowed && permissions?.isOwner) {
        setOpen(true);
     } else {
      navigate("/subscribe-plan");
    }

  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/my-business");
  };

  return (
    <Grid conatiner mt={2} ml={3}>
      <Grid item md={12} sm={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item sm={12} >
              {((isPublicProfile ) || !isPublicProfile) && (
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant="businessTitle"
                    style={{marginTop:isSm&&"10px"}}
                  // fontSize={"18px"}
                  // color={"rgba(0, 0, 0, 0.6)"}
                  // fontWeight={"700"}
                  >
                    CERTIFICATION
                  </Typography>
                  {!isPublicProfile &&
                    <CardMedia component="img" image={DEFAULT_IMAGES.PREMIUM_BADGE} height="20px" sx={{ width: '25px', ml: 1, mt: -1 }} />}
                </Box>
              )}
            </Grid>

            <Grid item sm={12} xs={12}>
              {permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed && (
                  <Typography
                    // variant="contained"
                    color="secondary"
                    size="small"
                    // disabled={!(permissions && permissions.isUpdateAllowed)}
                    sx={{ fontSize: "15px", cursor: "pointer" }}
                    // endIcon={<AddCircleRoundedIcon />}
                    onClick={handleClickOpen}
                  >
                    <Link sx={{textDecoration: "underline", color:"#2864BE",marginTop:isSm&&"10px" }}> Click Here </Link><span style={{ color: 'black' }}> to upload Certificate</span>

                  </Typography>
                )}
            </Grid>
            <AddCertificateForm
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              onSubmitSuccess={handleSubmitSuccess}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
AddCertificate.propTypes = {
  isPublicProfile: PropTypes.bool,
  supplierId: PropTypes.number,
  isCertificateExists: PropTypes.bool,
};

export default AddCertificate;
