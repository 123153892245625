import { filterCompanyDirectory } from "actions/companyDirectoryActions";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import filterData from "./FiltersData";
import { CheckboxFilter, RadioFilter } from "./FiltersType";
import { updateCompanyDirectory } from "utils/searchUtils";
import Slider from "../../../components/Slider";
import { fetchTag, fetchTagByUserId } from "actions/tagsActions";
import { regionsActions } from "../../../actions/regionsActions";
import tagsService from "services/tagsService";

const CompanyDropdown = ({ fixedStyle }) => (
  <div
    style={fixedStyle}
    // className="absolute top-0 left-full bg-white border border-gray-200"
    className=" bg-white border border-gray-200"
  >
    <div
      // className="fixed z-20 px-3 py-4 w-64 bg-white border border-gray-200 p-4 shadow-lg rounded-xl"
      className="px-3 py-4 w-64 bg-white border border-gray-200 p-4 shadow-lg rounded-xl"
    >
      <input
        type="text"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="search tag name"
        // value={searchTagTerm}
        // onChange={handleInputSearchTagsKey}
      />
      <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2">
        <input
          type="checkbox"
          className="w-4 h-4 bg-gray-100 border-gray-300 rounded font-medium text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <span>Supplier Pune</span>
      </label>

      <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2">
        <input
          type="checkbox"
          className="w-4 h-4 bg-gray-100 border-gray-300 rounded font-medium text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <span>Contract Pune</span>
      </label>
      <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2">
        <input
          type="checkbox"
          className="w-4 h-4 bg-gray-100 border-gray-300 rounded font-medium text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <span>TMT Bar</span>
      </label>
      <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2">
        <input
          type="checkbox"
          className="w-4 h-4 bg-gray-100 border-gray-300 rounded font-medium text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <span>Steel</span>
      </label>
    </div>
  </div>
);

const AllFilters = ({ containerRef }) => {
  const companyDirectory = useSelector((state) => state.companyDirectory);
  const vendorsPayload = useSelector((state) => state.companyDirectory);
  const tagsData = useSelector((state) => state.tags);
  const regions = useSelector((state) => state.regions);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const relativeRef = useRef(null);
  const [fixedStyle, setFixedStyle] = useState({});

  const [openKeys, setOpenKeys] = useState({ tags: true, category: true, gstTurnOverRange: true, gstStatus: true });
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const [searchLocationTerm, setSearchLocationTerm] = useState("");
  const [tagsOptions, setTagsOptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedTab, setSelectedTab] = useState("My Tags");
  const [selectedTag, setSelectedTag] = useState(null);
  const [tags, setTags] = useState([]);
  const [readMoreTags, setReadMoreTags] = useState(true);

  const userProfile = auth.user;
  const userId = userProfile?.userId;
  const entityId = userProfile?.userEntity?.entityId;
  // const tags = tagsData?.tags?.map((obj) => obj?.tagName);
  const regionDistricts = regions?.regionDistricts;
  const filtersFacets = filterData;
  const appliedFilters = companyDirectory?.appliedFilters;
  const searchKey = companyDirectory?.searchKey;
  const switchTabs = ["My Tags", "Company Tags"];
  const companyTags = [
    "Omkar",
    "Arjun Samrat",
    "Vaidehi Upachare",
    "Snehal Bhujange",
    "tathaatavm neevay solutions",
  ];

  const toggleTab = (tab) => {
    setSelectedTab(tab);
  };

  const handleReadMoreClick = () => {
    setReadMoreTags((preVal) => !preVal);
  };

  const handleInputSearchTagsKey = (event) => {
    setSearchTagTerm(event.target.value);
    const filteredResults = tags?.filter((name) =>
      name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
    );
    setTagsOptions(filteredResults);
  };

  const handleInputSearchLocationKey = (event) => {
    setSearchLocationTerm(event.target.value);
    const filteredResults = regionDistricts?.filter((name) =>
      name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
    );
    setLocations(
      filteredResults.length > 5
        ? filteredResults?.slice(0, 5)
        : filteredResults
    );
  };

  const handleToggle = (key) => {
    setOpenKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleInputChange = (field, value, isMultiple) => {
    const filterData = updateCompanyDirectory({
      field,
      value,
      isMultiple,
      appliedFilters,
    });
    dispatch(
      filterCompanyDirectory({ filterData, searchKey, userId, entityId })
    );
  };
  const getTagsByUserId = async () => {
    const tagsData = await tagsService.fetchTagByUserId({ userId, entityId });
    const tags = tagsData.tags?.filter((tag) => tag !== "");

    setTagsOptions(tags);
    setTags(tags);
  };

  useEffect(() => {
    dispatch(fetchTag());
    dispatch(regionsActions());
    getTagsByUserId();
  }, []);
  useEffect(() => {
    getTagsByUserId();
  }, [vendorsPayload?.applyTagsCount]);

  useEffect(() => {
    const handleScroll = () => {
      if (relativeRef.current && containerRef.current) {
        const relativeRect = relativeRef.current.getBoundingClientRect();
        setFixedStyle({
          position: "fixed",
          top: relativeRect.top + "px",
          left: relativeRect.left + 15 + "px",
          width: relativeRect.width + "px",
          height: relativeRect.height + "px",
          zIndex: 999, // Example z-index
        });
      }
    };

    // Attach scroll event listener to the container
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    // Initial positioning
    handleScroll();

    return () => {
      // Clean up the event listener
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [selectedTag]);

  return (
    <div className="p-3 pt-0">

      <div className="border-y py-3 text-gray-500 border-gray-300">
        <div className="flex cursor-pointer items-center justify-between ">
          <span className="flex gap-2 text-xs font-medium">
            Verified Vendors{" "}
            <img
              src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Verified_image_nn.png"
              alt="verified"
              className="h-[14px] w-[14px]"
            />
          </span>
          <button
            onClick={() =>
              handleInputChange("isPremium", true, false)
            }
            className={`w-10 h-5 flex items-center rounded-full p-1 cursor-pointer ${
              appliedFilters.isPremium
                ? "bg-blue-700"
                : "bg-gray-300"
            }`}
          >
            <div
              className={`bg-white w-3 h-3 rounded-full shadow-md transform ${
                appliedFilters.isPremium
                  ? "translate-x-5"
                  : "translate-x-0"
              }`}
            />
          </button>
        </div>
      </div>
      <div className="flex flex-col text-gray-500">
        {filtersFacets?.map(({ key, title, type, options }) => (
          <div className="border-b border-gray-300 py-3">
            <div
              className="flex items-center justify-between"
              onClick={() => handleToggle(key)}
            >
              <p className="text-sm text-gray-500 font-medium">{title}</p>
              <svg
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                className={`w-6 h-6 transition-transform duration-200 ease-in-out ${
                  openKeys[key] ? "transform rotate-180" : ""
                }`}
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            {openKeys[key] && (
              <>
                {type === "checkboxes" && (
                  <CheckboxFilter
                    addedFilters={appliedFilters}
                    handleInputChange={handleInputChange}
                    title={title}
                    field={key}
                    options={options}
                  />
                )}
                {type === "radio" && (
                  <RadioFilter
                    addedFilters={appliedFilters}
                    handleInputChange={handleInputChange}
                    title={title}
                    field={key}
                    options={options}
                  />
                )}
                {/* {type === "search checkbox" && (
                  <SearchCheckboxFilter
                    addedFilters={appliedFilters}
                    handleInputChange={handleInputChange}
                    title={title}
                    type={type}
                    options={options}
                  />
                )} */}
                {type === "range" && (
                  <Slider
                    className="mt-4"
                    startCount={0}
                    endCount={100}
                    difference={10}
                    value={0} //TODO: Update this
                    onChange={handleInputChange}
                  />
                )}
                {type === "search checkbox" && (
                  <>
                    {/* <div className="flex my-4 justify-center">
                      {switchTabs.map((tab) => (
                        <button
                          key={tab}
                          onClick={() => toggleTab(tab)}
                          className={`mr-6 py-2 bg-white text-gray-700 border-b-2 font-medium ${
                            selectedTab === tab
                              ? "border-blue-700"
                              : "border-white"
                          }`}
                        >
                          {tab}
                        </button>
                      ))}
                    </div> */}
                    <div className="max-w-60 overflow-auto">
                      <input
                        type="text"
                        className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 placeholder:text-xs text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search tag name"
                        value={searchTagTerm}
                        onChange={handleInputSearchTagsKey}
                      />
                      {searchTagTerm !== "" && tagsOptions.length === 0 && (
                        <p className="text-xs text-gray-400 text-center mt-2">{`No Match Found `}</p>
                      )}
                      <CheckboxFilter
                        addedFilters={appliedFilters}
                        handleInputChange={handleInputChange}
                        title={title}
                        field={key}
                        options={
                          readMoreTags ? tagsOptions?.slice(0, 5) : tagsOptions
                        }
                      />
                      {tagsOptions?.length > 5 && (
                        <p
                          className="text-blue-600 cursor-pointer text-xs"
                          onClick={handleReadMoreClick}
                        >
                          {readMoreTags ? "View More" : "View Less"}
                        </p>
                      )}
                    </div>
                    {false && selectedTab === "My Tags" && (
                      <>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="search tag name"
                          value={searchTagTerm}
                          onChange={handleInputSearchTagsKey}
                        />
                        {searchTagTerm !== "" && tagsOptions.length === 0 && (
                          <p className="text-xs text-gray-400 text-center mt-2">{`No Match Found `}</p>
                        )}
                        <CheckboxFilter
                          addedFilters={appliedFilters}
                          handleInputChange={handleInputChange}
                          title={title}
                          field={key}
                          options={tagsOptions}
                        />

                        {/* // ) : ( */}
                        <div className="flex flex-col">
                          {companyTags?.map((name) => (
                            <div className="w-full flex justify-between">
                              <button
                                className="w-[230px] relative flex cursor-pointer items-center justify-between p-2 hover:bg-gray-100"
                                onClick={() => setSelectedTag(name)}
                              >
                                <p
                                  className="font-medium truncate"
                                  title={name}
                                >
                                  {name}
                                </p>
                                <svg
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={`w-5 h-5 transform -rotate-90 `}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>

                                {/* {selectedTag === name && <CompanyDropdown />} */}
                              </button>

                              {selectedTag === name && (
                                <div ref={relativeRef} className="relative w-0">
                                  {/* <CompanyDropdown fixedStyle={fixedStyle} /> */}
                                  {/* Content of the relative div */}
                                </div>
                              )}
                            </div>
                          ))}
                          <div
                            style={fixedStyle}
                            // className="absolute top-0 left-full bg-white border border-gray-200"
                            className=" bg-white"
                          >
                            <div
                              // className="fixed z-20 px-3 py-4 w-64 bg-white border border-gray-200 p-4 shadow-lg rounded-xl"
                              className="px-3 py-4 w-64 bg-white border border-gray-200 p-4 shadow-lg rounded-xl"
                            >
                              <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="search tag name"
                                // value={searchTagTerm}
                                // onChange={handleInputSearchTagsKey}
                              />
                              <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded font-medium text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <span>Supplier Pune</span>
                              </label>

                              <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded font-medium text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <span>Contract Pune</span>
                              </label>
                              <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded font-medium text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <span>TMT Bar</span>
                              </label>
                              <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded font-medium text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <span>Steel</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {type === "search radio" && (
                  <>
                    <input
                      type="text"
                      className="bg-gray-50  mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search district here"
                      value={searchLocationTerm}
                      onChange={handleInputSearchLocationKey}
                    />
                    {searchLocationTerm !== "" && locations.length === 0 && (
                      <p className="text-xs text-gray-400 text-center mt-2">{`No Match Found `}</p>
                    )}
                    <RadioFilter
                      addedFilters={appliedFilters}
                      handleInputChange={handleInputChange}
                      title={title}
                      field={key}
                      options={locations}
                    />
                  </>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllFilters;
