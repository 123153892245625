import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { editRfq, } from "actions/rfqActions";
import SidePanel from "layouts/SidePanel";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import GeneralInformationNew from "./Components/GeneralInformationNew";

const validationSchema = Yup.object({
    rfqName: Yup.string().required("Required"),
    requirementType: Yup.string().required("Required"),
    emailCopyRecipients: Yup.array()
        .of(Yup.string().email("Invalid email format"))
        .required("Required"),
    projectName: Yup.string().required("Required"),
    // creditPeriod: Yup.string(),
    providingDate: Yup.date(),
    // Conditional validation for materials
    materials: Yup.array().when('requirementType', {
        is: 'material',
        then: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required("Required"),
                    quantity: Yup.number().typeError('Quantity should be number').required('Required').min(1, 'Invalid quantity'),
                    unit: Yup.string().required("Required"),
                    // brands: Yup.array().min(1, "At least one brand must be selected"),
                    // specifications: Yup.string().required("Required"),
                })
            )
            .min(1, "At least one material is required")
            .required("Materials are required"),
        otherwise: Yup.array().notRequired(),
    }),

    // Conditional validation for labourDetails
    labourDetails: Yup.array().when('requirementType', {
        is: 'labour', // Assuming 'supplier' is the type for labour details
        then: Yup.array().of(
            Yup.object().shape({
                skill: Yup.string().required("Required"),
                quantity: Yup.number().required("Required").min(1, "Must be at least 1"),
            })
        )
            .required("Labour details are required")
            .min(1, "At least one labour detail is required"),
        otherwise: Yup.array().notRequired(),
    }),

    // Conditional Validation for providingFixingDetails
    providingFixingDetails: Yup.string().when('requirementType', {
        is: 'contractor', // When requirementType is 'contractor'
        then: Yup.string().required('Required'),
        otherwise: Yup.string(), // No validation if not 'contractor'
    }),
});

const EditRfqNew = ({ setOpenEditRfq, data, setActiveTab }) => {

    console.log("DATA", data)

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const rfqData = useSelector((state) => state.rfq);
    // console.log("RFQDATA", rfqData)
    const rfqsData = useSelector((state) => state.rfqs);
    const auth = useSelector((state) => state.auth);
    const projectData = useSelector((state) => state.rfqProjects);

    const projects = projectData?.projects ?? [];
    const user = auth?.user;

    const formatDate = (dateString, toDisplayFormat = false) => {
        if (!dateString) return "";
        const date = new Date(dateString);
    
        if (toDisplayFormat) {
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
    
        return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };
    

    const [showForm, setShowForm] = useState(true);
    const [openAddVendorOption, setOpenAddVendorOption] = useState(false);
    const [rfqFormValues, setRfqFormValues] = useState({
        rfqId: data?.rfqId,
        rfqName: data?.name,
        // estimatedValue: "",
        requirementType: data.rfqType,
        // dueDate: "",
        providingDate: formatDate(data.providingDate),
        emailCopyRecipients: [...data?.newCcEmails],
        attachments: data.selectedFilesBase,
        projectName: data.projectId || data?.projectName | "",
        projectId: data.projectId,
        // projectLocation: "",
        // creditPeriod: "",
        rfqDetails: "",
        // indentId: "",
        materials: data?.materials,
        otherCharges: data?.otherCharges || {},
        labourDetails: data.labourDetails,
        providingFixingDetails: data.providingFixingDetails,
        acceptCounterTerms: data?.acceptCounterTerms,
        paymentTerms: data.paymentTerms,
        rfqDetails: data?.description,
        indentId: data?.indentId ?? "",
        materials: data?.materials || [],
        otherCharges: data?.otherCharges || {},
        labourDetails: data?.labourDetails || [],
        providingFixingDetails: data?.providingFixingDetails || "",
        acceptCounterTerms: data?.acceptCounterTerms || false,
        paymentTerms: data?.paymentTerms || '',

    });

    const loading = rfqData?.loading;
    const entityId = user?.userEntity?.entityId;

    const updateRfqFormValues = (updatedValues) => {
        setRfqFormValues(updatedValues);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("INTHESUBMIT", values)

        const selectedProject = projects.find(
            (project) => project._id === values.projectName
        );

        const formValue = {
            ...values,
            projectId: selectedProject._id,
        };

        setRfqFormValues(formValue);

        dispatch(editRfq({ values: formValue }));
        setOpenEditRfq(false);
        setActiveTab("Overview");

    };

    const handleCloseRfqForm = () => {
        setRfqFormValues({
            rfqName: "",
            // estimatedValue: "",
            requirementType: "",
            dueDate: new Date(),
            emailCopyRecipients: [],
            projectName: "",
            projectLocation: "",
            creditPeriod: "",
            rfqDetails: "",
        });
    };

    return (
        // <SidePanel>
            <div >
                <div
                    className={`fixed inset-0 z-30 flex justify-end transition-all duration-500 ${showForm ? "opacity-100 visible" : "opacity-0 invisible"
                        }`}
                >
                    <div className="fixed inset-0 bg-black opacity-50" />
                    <div
                        className={`fixed z-20 top-0 right-0 h-full w-[45%] bg-white transition-transform duration-500 transform ${showForm ? "translate-x-0" : "translate-x-full"
                            }`}
                    >

                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                ...rfqFormValues,
                                // workStartDate: Date(Date.now()),
                                requesterName: user?.name || "",
                                requesterMobile: user?.mobile || "",
                                userBusinessName: user?.userEntity
                                    ? user?.userEntity.entityName
                                    : user?.company || "",
                                requestedBy: user?.email || "",
                                requesterId: user?.userId || "",
                                isEntityUser: user?.userEntity ? true : false,
                                // rfqDetails: "rfqDetails"
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, submitForm, errors }) => (
                                <Form className="h-full justify-start">

                                    {/* header */}
                                    <div className="sticky top-0 bg-white z-20">
                                        <div className="flex px-8 py-3 justify-between items-center">
                                            <h1 className="text-neevay-theme-text-600 font-medium text-xl">
                                                Update RFQ
                                            </h1>
                                            <Button
                                                variant="text"
                                                className="text-gray-600 text-sm flex items-center gap-x-2"
                                                style={{ background: "#FBFBF9" }}
                                                onClick={() => {
                                                    setShowForm(false);
                                                    setOpenEditRfq(false);
                                                    setActiveTab("Overview");

                                                }}
                                            >
                                                Close
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11M11 1L1 11"
                                                        stroke="#4F4F42"
                                                        stroke-linecap="round"
                                                    />
                                                </svg>
                                            </Button>
                                        </div>
                                        <div className=" mb-2">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="h-full overflow-y-auto pb-[100px]">
                                        <GeneralInformationNew
                                            projects={projects}
                                            updateFormValues={updateRfqFormValues}
                                            entityId={entityId}
                                        />
                                    </div>
                                    <div
                                        className="px-3 py-4 sticky bottom-0 z-20 bg-white flex justify-between items-center shadow-md "
                                        style={{
                                            boxShadow: "0px -4px 4px 0px #0000001A",
                                        }}
                                    >

                                        <div className="space-x-2 sticky bottom-0 flex justify-end w-full px-8">

                                            <button
                                                type="button"
                                                className="px-4 bg-[#1E1E08] text-sm w-fit whitespace-nowrap py-2 hover:bg-gray-600 text-white mr-2 "
                                                disabled={loading} // Disable the button when loading is true
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    await submitForm();
                                                    const firstErrorField = Object.keys(errors)[0];
                                                    const errorElement =
                                                        document.getElementById(firstErrorField);
                                                    if (errorElement) {
                                                        errorElement.scrollIntoView({
                                                            behavior: "smooth",
                                                        });
                                                    } else if (values.rfqName) {
                                                        setOpenEditRfq(false);
                                                        setActiveTab("Overview");
                                                        setShowForm(false);
                                                        // navigate("/rfqs");
                                                        handleCloseRfqForm();
                                                    }
                                                }}
                                            >
                                                {loading ? "Updating..." : "Update"}
                                            </button>

                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        // </SidePanel>
    );
};

export default EditRfqNew;
