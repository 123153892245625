import axios from "utils/axios";

import {
  SUBSCRIBE_PLAN,
  SUBSCRIBE_OMS_UPDATE,
  INSTANT_PAYMENT
} from "config/services";

class SubscribeService {
  
  createOrder = (orderInfo) =>
    new Promise((resolve, reject) => {
      
      axios
        .post(SUBSCRIBE_PLAN, { payload: orderInfo })
        .then((response) => {
          // console.log(
          //   "SubscribeService.createOrder()",
          //   orderInfo           
          // );

          if (response?.data?.error) {
            reject(response?.data?.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "SubscribeService.createOrder() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

    
    paymentConfirmation = ( info) =>
    new Promise((resolve, reject) => {
      axios
        .post(INSTANT_PAYMENT, { payload: info })
        .then((response) => {
          // console.log(
          //   "SubscribeService.createOrder()",
          //   createOrderData, response           
          // );

          if (response?.data?.error) {
            reject(response?.data?.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "SubscribeService.createOrder() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

    omsUpdate = (name, businessName, gstin, stateCode, address) =>
    new Promise((resolve, reject) => {
      const payload = {name, businessName, gstin, stateCode, address}
      axios
        .post(SUBSCRIBE_OMS_UPDATE, { payload })
        .then((response) => {
          // console.log(
          //   "SubscribeService.addUpdateService()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "SubscribeService.addUpdateService() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });
  
}

const subscribeService = new SubscribeService();

export default subscribeService;
