import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";

import { editProjectTrigger, deleteProject } from "actions/projectActions";

import { ImageEditor } from "components";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";

const locationIcon = "/assets/icons/location_icon.png";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const missingImageAlt = DEFAULT_IMAGES.PROJECT;

function ProjectCard({
  supplierId,
  projectName,
  clientName,
  location,
  description,
  imageUrl,
  isPublicProfile,
  projectData,
  permissions,
  projectId,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEditProject = () => {
    dispatch(editProjectTrigger(projectData));
    navigate("/add-project");
  };

  const handleDeleteProject = () => {
    dispatch(deleteProject(supplierId, projectName, projectId));
  };

  // Read more logic start here
  const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };
  const cardDescriptionHeight = showFullText ? "auto" : "85px"
  const displayText = showFullText ? description : description.slice(0, 120)
  // Read more logic end here

  return (
    <Grid
      sx={{
        maxWidth: "302px",
        margin: "auto",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px"
      }}>

      {/* Edit button start from here */}
      {permissions && permissions.isUpdateAllowed && (
        <Box
          mr={3}
          sx={{ display: "flex", justifyContent: "end", textAlign: "right" }}
        >
          <>
            <Typography variant="body2" sx={{ mr: 0 }}>
              <Tooltip title="Edit">
                <IconButton
                  disabled={!permissions?.isOwner}
                  sx={{ color: "primary.main" }}
                  onClick={handleEditProject}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Typography>

            <Typography variant="body2">
              <Tooltip title="Delete">
                <IconButton
                  disabled={!permissions?.isOwner}
                  sx={{ color: "primary.main" }}
                  onClick={handleDeleteProject}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Typography>
          </>
        </Box>
      )}
      {/* Edit button start end here */}


      <Grid xs={12} >
        {/* projectImage card start. fix height with width auto or 100%*/}

        {(
          permissions && !permissions.isViewOnly && permissions.isUpdateAllowed ?
           <ImageEditor
            type={IMAGE_TYPE.PROJECT}
            name={projectName}
            supplierId={supplierId}
            inImage={
              imageUrl ? `${imageUrl}` : DEFAULT_IMAGES.PROJECT
            }
            id={projectId}
          /> :
            <Card
              variant="outlined"
              sx={{
                width: "100%",
                minHeight: "201px",
                // borderRadius: "20px 20px 0 0",
                position: "relative"
              }}
            >
              {/* <CardMedia
                component="img"
                sx={{ height: "201px", width: "100%" }}
                image={imageUrl}
                alt={projectName}
              /> */}


              {/* image section is start from here */}

              <CardMedia
                component="img"
                sx={{
                  filter: "blur(5px)",
                  position: "relative",
                  objectFit: "none",
                  maxHeight: "201px"
                }}


                image={
                  imageUrl ? `${imageUrl}` : DEFAULT_IMAGES.PROJECT
                }
                alt="Image not found"
              />
              <CardMedia
                sx={{
                  position: "absolute", top: "0", right: "1px", left: "1px", maxWidth: "100%",
                  maxHeight: "201px"
                }}
                component="img"

                image={
                  imageUrl ? `${imageUrl}` : DEFAULT_IMAGES.PROJECT
                }
                alt="Image not found"
              />

              {/* image section is end from here */}


            </Card>
        )}
      </Grid>

      {/* projectImage card end here */}

      {/* projectInfo card start here add distance acording figma */}

      <Grid sx={{ padding: "15px 10px 20px" }}>
        <Grid iteam xs={12} sx={{ marginBottom: "10px" }}>
          <Typography fontSize="14px" fontWeight="700">
            {projectName ? projectName : "Project Name"}
          </Typography>
        </Grid>
        <Grid iteam xs={12}>
          <Typography fontSize="14px" fontWeight="600">
            {clientName ? clientName : "Client Name"}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          display="flex"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          margin="8.5px 0"
        >
          {location && <>
            <Grid>
              <img src={locationIcon} />
            </Grid>
            <Grid>
              <Typography fontSize="14px" fontWeight="500" marginLeft={"5px"}>
                {/* {location ? location : "Location"} */}
                {location}
              </Typography>
            </Grid>
          </>}
        </Grid>

        <Grid minHeight={cardDescriptionHeight} >

          {description.length > 130 ?
            (<Typography fontSize="14px" fontWeight="400">{displayText}{showFullText ? ' ' : '...  '}
              <Button sx={{ padding: "0 " }} onClick={toggleFullText}>
                <Typography fontSize="14px" fontWeight="400"> {showFullText ? 'Read Less' : 'Read More'} </Typography>
              </Button>
            </Typography>
            ) :
            <Typography fontSize="14px" fontWeight="400">
              {description ? description : "Description"}
            </Typography>}

        </Grid>

      </Grid>

      {/* projectInfo end here */}


    </Grid>
  );
}

ProjectCard.propTypes = {
  projectName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  location: PropTypes.string,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isPublicProfile: PropTypes.bool,
  serviceData: PropTypes.object,
  supplierId: PropTypes.number,
  permissions: PropTypes.object,
  projectId: PropTypes.string,
};

export default ProjectCard;
