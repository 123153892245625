import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const LeadNotFound = ({ data }) => {
    const [timer, setTimer] = useState(data);
    const navigte = useNavigate()
    useEffect(() => {
        setTimer(data)
    }, [data])
    setTimeout(() => { setTimer(0) }, 12000) // if someone don't have any leads or 0 leads then they dont show the loader for this after 2 sec we give the ans
    return (
        <Grid container alignItems={"center"} justifyContent={"center"} height={"100%"} >
            {timer === 0 ?
                <Grid item display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} >
                    <Typography>
                        This lead is not availble for you
                    </Typography>
                    <Button variant="outlined" color="primary" sx={{ margin: "16px 0" }} onClick={() => { navigte("/leads") }}>
                        View All Leads
                    </Button>
                </Grid> : <CircularProgress sx={{color:"#FD6600"}} />}
        </Grid>
    )
}

export default LeadNotFound