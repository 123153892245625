import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


const borderColor = '#3778C2'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        // borderBottomColor: '#3778C2',
        backgroundColor: 'white',
        color: 'black',
        borderTopWidth: 1,
        marginTop:25,
        marginBottom:25,
        borderBottomWidth: 1,
        alignItems: 'left',
        textAlign: 'left',
        // fontStyle: 'bold',
        // flexGrow: 1,
        // justifyContent: "space-between",
        // display:"flex"
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        // justifyContent: 'flex-end',
        alignItems: 'right',
    },
    label: {
        width: 200,
        alignItems: 'left',
        marginBottom:10,
        // fontStyle: 'bold',
    },
    label1: {
        width: 200,
        alignItems: 'left',
        marginTop:10,
        marginBottom:10
    }
});

const InvoiceTableHeader = ({invoice}) => {
    
    const orderDes = (invoice) ? `${invoice.orderDesc}` : undefined;
    const amount = (invoice) ? `${invoice.orderAmount}` : undefined;
    const date = (invoice) ? `${invoice.createdAt}` : undefined;
    function Unix_timestamp(t)
    {
    var dt = new Date(t*1000);
    var hr = dt.getHours();
    var m = "0" + dt.getMinutes();
    var s = "0" + dt.getSeconds();
    return hr+ ':' + m.substr(-2) + ':' + s.substr(-2);  
    }
     
  let timestamp = new Date(date).getTime();
  let Time = new Date(timestamp).getTime();
  const t =Unix_timestamp(Time)
  let Day = new Date(timestamp).getDate();
  // let Month = new Date(timestamp).getMonth() + 1;
    let Month = new Date(timestamp).toLocaleString('default',{month:'short'});
  let Year = new Date(timestamp).getFullYear();
  let OurNewDateFormat = `${Day}-${Month}-${Year}`;
  
  var someDate = new Date(OurNewDateFormat);
  var numberOfDaysToAdd = 365;
  var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
//   console.log(new Date(result))

 let timestamp1 = new Date(result).getTime();
  let Time1 = new Date(timestamp1).getTime();
  const t1 =Unix_timestamp(Time1)
  let Day1 = new Date(timestamp1).getDate();
  let Month1 = new Date(timestamp1).toLocaleString('default',{month:'short'});
  let Year1 = new Date(timestamp1).getFullYear();
  let OurNewDateFormat1 = `${Day1}-${Month1}-${Year1}`;
    return(
        <>
    <View style={styles.container}>
         {/* <Text style={styles.description}>Date 14-03-2023</Text> */}
    
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label1}>Date </Text>
            <Text style={styles.label1}>{OurNewDateFormat}</Text>
            {/* <Text >{"14-03-2023"}</Text> */}
         </View>
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Description </Text>
            <Text style={{fontFamily:"Cambria"}}>{orderDes}</Text>
         </View>
      
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Subscription Period </Text>
            <Text style={{fontFamily:"Cambria"}}>{OurNewDateFormat} TO {OurNewDateFormat1}</Text>
         </View>
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Amount </Text>
            <Text >Rs. {amount}</Text>
         </View>
    </View>
        </>

    )
    };

export default InvoiceTableHeader;