import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./style.css";
import "./index.css";
import "./font.css";
import App from "./App";
import { configureStore } from "store";
import { SnackbarProvider } from "notistack";

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import * as serviceWorker from './serviceWorker';

import reportWebVitals from "./reportWebVitals";

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
const GTM_ID = process.env.REACT_APP_GTM_ID;

// console.log("GA GTM",GA_TRACKING_ID, GTM_ID );
const tagManagerArgs = {
  gtmId: GTM_ID
}

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(GA_TRACKING_ID);

const store = configureStore();
// const settings = restoreSettings(); TODO_SP check if this is need to initial settings

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
