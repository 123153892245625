import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

function B2CModal({ open, handleClose }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    boxShadow: 24,
    p: 2,
    borderRadius: "7px",
  };

  const iconStyle = {
    position: "absolute",
    top: "-10px", // Adjust this value as needed
    right: "-10px", // Adjust this value as needed
    // zIndex: 99999,
    cursor: "pointer",
    color: "#fff",
    backgroundColor: "#4B4747",
    borderRadius: "50%",
  };

  const handleIconClick = () => {
    handleClose(); // Call the handleClose function to close the modal
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style} style={{ width: isMd ? "453px" : "320px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              component="img"
              sx={{
                height: 25,
                width: 25,
                position: "absolute",
                right: -10,
                top: -10,
                cursor: "pointer",
              }}
              alt="green vector"
              src="/assets/icons/crossIcon.svg"
              onClick={handleIconClick}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "123.188%",
                letterSpacing: "0.28px",
              }}
            >
              Currently our portal deals with only B2B, we have your lead, and
              we will get back to you soon.{" "}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default B2CModal;
