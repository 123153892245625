import { Button, Modal } from "flowbite-react";
import { useState } from "react";

const Popup = ({ displayField, values, heading }) => {
  const [openModal, setOpenModal] = useState(false);

  const toTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  return (
    <>
      <button onClick={() => setOpenModal(true)}>
        <p className="truncate w-52 text-left" title={values[0][displayField]}>
          {values[0][displayField]}
        </p>
      </button>
      <Modal
        style={{ zIndex: "50" }}
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header>{heading || values[0][displayField]}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {values.map((value, index) => (
              <div key={index}>
                {Object.keys(value).map((key) => (
                  <p
                    key={key}
                    className="text-base leading-relaxed text-gray-500 dark:text-gray-400"
                  >
                    {toTitleCase(key)}: {value[key]}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Popup;
