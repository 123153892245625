import leadsService from "services/leadsService";

export const LEADS_FETCH = "@leads/fetch";
export const LEADS_FETCH_SUCCESS = "@leads/fetch-success";
export const LEADS_FETCH_FAILURE = "@leads/fetch-failure";

export const SEND_ACK = "@leads/send-ack";
export const SEND_ACK_SUCCESS = "@leads/send-ack-success";
export const SEND_ACK_FAILURE = "@leads/send-ack-failure";

export function fetchLeads(vendorsIn, userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: LEADS_FETCH });

      const leads = await leadsService.fetchLeads(vendorsIn, userId);

      // console.log("fetchLeads()(after):" + JSON.stringify(leads));
      dispatch({
        type: LEADS_FETCH_SUCCESS,
        payload: {
          leads,
        },
      });
    } catch (error) {
      dispatch({ type: LEADS_FETCH_FAILURE });
      throw error;
    }
  };
}

export function sendAckLead(supplierId, rfqId, viewByName, viewById, acknowledgeDate) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_ACK });

      const acknowledge = await leadsService.sendAckLead(supplierId, rfqId, viewByName, viewById, acknowledgeDate);

      //console.log("fetchLeads()(after):" ,acknowledge);
      // await dispatch(fetchLeads());

      dispatch({
        type: SEND_ACK_SUCCESS,
        payload: {
          acknowledge,
        },
      });



    } catch (error) {
      dispatch({ type: SEND_ACK_FAILURE });
      throw error;
    }
  };
}
