import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
Font.register({
  family: "Cambria",
  fonts: [
    {
      src: "/assets/fonts/cambria.ttf",
      
    },
  ],
});
const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 24,
  },
  reportTitle: {
    // color: "black",
    // // letterSpacing: 2,
    // fontSize: 10,
    // textAlign: "left",
    // textTransform: "uppercase",
    // fontWeight: "demibold",
    // fontfamily: "Cambria",
    color: "black",
    // letterSpacing: 2,
    fontSize: 12,
    textAlign: "left",
    textTransform: "uppercase",
    fontFamily: "Cambria",
  },
  titles: {
    color: "black",
    // letterSpacing: 2,
    fontSize: 12,
    textAlign: "left",
    textTransform: "capitalize",
    fontWeight: "demibold",
    fontFamily: "Cambria",
  },
  invoiceDateContainer: {
    flexDirection: "row",

    // justifyContent: 'flex-end',
    alignItems: "right",
  },
  label: {
    width: 200,
    alignItems: "left",
  },
  label1: {
    width: 200,
    alignItems: "left",
    fontSize: 12,
    // fontStyle: "bold",
  },
  order: {
    color: "black",
    fontSize: 10,
    width: 200,
    textAlign: "left",
    textTransform: "capitalize",
    fontfamily: "Arial",
  },
});

const InvoiceTo = ({ invoicedata, title, to }) => {
  // console.log("BUSINESSTO",invoicedata)
  // const customerBusinessName = invoicedata.invoiceDetails.businessName
  const customerBusinessName = invoicedata.invoiceDetails
    ? `${invoicedata.invoiceDetails.businessName}`
    : undefined;
  const customerEmail = invoicedata.invoiceDetails
    ? `${invoicedata.userId}`
    : undefined;
  const customerAddress = invoicedata.invoiceDetails
    ? `${invoicedata.invoiceDetails.address}`
    : undefined;

  const gstin = invoicedata.invoiceDetails
    ? `${invoicedata.invoiceDetails.gstin}`
    : undefined;

  // console.log("customerBusinessName",customerBusinessName)
  return (
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>INVOICE TO</Text>
      <Text style={styles.titles}>{customerBusinessName}</Text>
      <Text style={styles.order}>{customerAddress}</Text>
      {/* <Text style={styles.reportTitle}>{to.line2}</Text> */}
      {/* <Text style={styles.reportTitle}>{to.city}</Text> */}
      {/* <Text style={styles.reportTitle}>{to.state}</Text> */}
      {/* <Text style={styles.reportTitle}>{to.pincode}</Text> */}
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label2}>{to.city} - </Text>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label2}>{to.pincode} </Text>
        </View>
      </View>

      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label2}>GSTIN :- </Text>
        <Text>{gstin}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label2}>State :- </Text>
        <Text>{to.state}</Text>
      </View>
      {/* <Text style={styles.reportTitle}>{to.GSTIN}</Text> */}
      {/* <View style={styles.invoiceDateContainer}>
            <Text style={styles.label2}>Mobile No :- </Text>
            <Text >{to.Mobile}</Text>

        </View > */}
      {/* <Text style={styles.reportTitle}>{to.CIN}</Text> */}
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label2}>Email :- </Text>
        <Text>{customerEmail}</Text>
      </View>
    </View>
  );
};

export default InvoiceTo;
