import React from 'react'
import PropTypes from 'prop-types'

import CssBaseline from "@mui/material/CssBaseline";
import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function TermsAndConditionData() {
    const theme = createTheme();
    return (<ThemeProvider theme={theme}>
        <Container maxWidth="lg" className="center-modal">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: 5,
                    p: 4,
                    borderRadius: 1,
                    mb: 4,
                }}
            >
                <Typography component="h1" variant="h5">
                    TERMS AND CONDITIONS
                </Typography>
                <br />

                <Typography>
                    <br />
                    The following Service Terms apply to Customer to the extent the
                    specific Service is included in the applicable ordering
                    document. Website may update these Service Terms from time to
                    time. Website reserves the right to upgrade, update or
                    discontinue any aspect or feature of a Service in whole or in
                    part; provided, however, that if Website discontinues a Service
                    in whole during the term of an ordering document, then Website
                    will provide Customer with an alternative or replacement
                    service. The following agreement captures the terms and
                    conditions of use ("Agreement"), applicable to Your use of
                    www.neevay.com ("Web Site"), which promotes business between
                    suppliers and buyers nationally. It is an agreement between You
                    as the user of the Web Site/www.neevay.com Services and
                    Tathaatvam Technology Solutions Pvt Ltd. The expressions “You”
                    “Your” or “User(s)” refers to any person who accesses or uses
                    the Web Site for any purpose. By subscribing to or interacting
                    with other person who accesses or uses the Website for any
                    purpose. on or entering into negotiations in respect of sale or
                    supply of services on or using the Website Services in any
                    manner for any purpose, you undertake and agree that You have
                    fully read, understood and accepted the Agreement. If you do not
                    agree, please exit this website.
                </Typography>
                <br />
                <Typography alignLeft>
                    1. ABOUT OUR TERMS AND CONDITIONS
                </Typography>
                <br />
                <Typography alignLeft>
                    1.1. WEBSITE- MERELY A VENUE/PLATFORM
                </Typography>
                <br />
                <Typography>
                    The Web Site acts as a match-making platform for user(s) to
                    negotiate and interact with other user(s) for entering into
                    negotiations in respect thereof for supply services. Website is
                    parties to any negotiations that take place between the user(s)
                    of the Website and are further not parties to any agreement
                    including an agreement for supply of services or otherwise,
                    concluded between the user(s) of the website. Website does not
                    control and is not liable in respect of or responsible for the
                    quality, safety, genuineness, lawfulness or availability of the
                    services offered for sale on the Web Site or the ability of the
                    User(s) supplying the services to complete a sale or the ability
                    of User(s) purchasing services to complete a purchase. This
                    agreement shall not be deemed to create any partnership, joint
                    venture, or any other joint business relationship between
                    Website and any other party.
                </Typography>
                <br />
                <Typography align="left">1.2. SERVICES PROVIDED BY US</Typography>
                <br />
                <Typography>
                    We provide the following services to its Customers and their
                    respective definitions are classified here under: - - Business
                    Registration. - Business Search.
                </Typography>
                <br />
                <Typography>1.3. YOUR CONSENT TO OUR PRIVACY</Typography>
                <br />
                <Typography>
                    By agreeing to these terms and conditions, you agree to the
                    terms of our Privacy Notice. Before using this website, please
                    carefully review our Privacy Notice. All Personal Information
                    provided to us as a result of your use of this website will be
                    handled in accordance with our Privacy Notice.
                </Typography>
                <br />
                <Typography>1.4. USER(S)-ELIGIBILITY</Typography>
                <br />
                <Typography>
                    User(s) represent and warrant that they have the right to avail
                    or use the services provided by Company, including but limited
                    to the Web Site or any other services provided by Company in
                    relation to the use of the Web Site ("Company’s Services").
                    Company’s Services can only be availed by those individuals or
                    business entities, including sole proprietorship firms,
                    companies and partnerships, which are authorised under
                    applicable law to form legally binding agreements. As such,
                    natural persons below 18 years of age and business entities or
                    organisations that are not authorised by law to operate in India
                    or other countries are not authorised to avail or use Company’s
                    Services. User(s) agree to abide by the Agreement and any other
                    rules and regulations imposed by the applicable law from time to
                    time. Company or the website shall have no liability to the
                    User(s) or anyone else for any content, information or any other
                    material transmitted over Company’s Services, including any
                    fraudulent, untrue, misleading, inaccurate, defamatory,
                    offensive or illicit material and that the risk of damage from
                    such material rests entirely with each User(s). The user shall
                    do its own due diligence before entering into any transaction
                    with other user(s) on the website. Company at its sole
                    discretion reserves the right to refuse Company’s Services to
                    anyone at any time. Company’s Services are not available and may
                    not be availed or used by User(s) whose Accounts have been
                    temporarily or indefinitely suspended by Company.
                </Typography>
                <br />
                <Typography>1.5. USER(S) AGREEMENT</Typography>
                <br />
                <Typography>
                    This Agreement applies to any person who accesses or uses the
                    Web Site or uses Company Services for any purpose. It also
                    applies to any legal entity which may be represented by any
                    person who accesses or uses the Web Site, under actual or
                    apparent authority. User(s) may use this Web Site and/or Company
                    Services solely for their commercial/business purposes. This
                    Agreement applies to all services offered on the Web Site and by
                    Company, collectively with any additional terms and conditions
                    that may be applicable in respect of any specific service used
                    or accessed by User(s) on the Web Site. In the event of any
                    conflict or inconsistency between any provision of this
                    Agreement and any additional terms and conditions applicable in
                    respect of any service offered on the Web Site, such additional
                    terms and conditions applicable in respect of that service shall
                    prevail over this Agreement. This Agreement shall govern the
                    User’s usage of Company Services and, the User acknowledges that
                    this Agreement shall supersede all or any term, agreement,
                    contract executed between Company and the User.
                </Typography>
                <br />
                <Typography>1.6. INTELLECTUAL PROPERTY RIGHTS</Typography>
                <br />
                <Typography>
                    Company is the sole owner and the lawful licensee of all the
                    rights to the Web Site and its content ("Web Site Content"). Web
                    Site Content means the design, layout, text, images, graphics,
                    sound, video etc. of or made available on the Web Site. The Web
                    Site Content embodies trade secrets and other intellectual
                    property rights protected under worldwide copyright and other
                    applicable laws pertaining to intellectual property. All title,
                    ownership and intellectual property rights in the Web Site and
                    the Web Site Content shall remain with the Company, its
                    affiliates or licensor’s of the Web Site content, as the case
                    may be. All rights, not otherwise claimed under this Agreement
                    by Company, are hereby reserved. Any information or
                    advertisements contained on, distributed through, or linked,
                    downloaded or accessed from any of the services contained on the
                    Web Site or any offer displayed on or in connection with any
                    service offered on the Web Site ("Website Information") is
                    intended, solely to provide general information for the personal
                    use of the User(s), who fully accept any and all responsibility
                    and liabilities arising from and out of the use of such
                    Information. Company does not represent, warrant or endorse in
                    any manner the accuracy or reliability of Website Information,
                    or the quality of any products and/or services obtained by the
                    User(s) as a result of any Website Information. For any content
                    and or link uploaded to the Website by the User from YouTube,
                    the User agrees to abide and accepts, the terms of service of
                    YouTube, available at https://www.youtube.com/t/terms.
                </Typography>
                <br />
                <Typography>
                    The Information is provided “as is” with no guarantee of
                    completeness, accuracy, timeliness or of the results obtained
                    from the use of the Information, and without warranty of any
                    kind, express or implied, including, but not limited to
                    warranties of performance, merchantability and fitness for a
                    particular purpose. Nothing contained in the Agreement shall to
                    any extent substitute for the independent investigations and the
                    sound technical and business judgment of the User(s). In no
                    event shall Company be liable for any direct, indirect,
                    incidental, punitive, or consequential damages of any kind
                    whatsoever with respect to Company’s Services. User(s) hereby
                    acknowledge that any reliance upon the Information shall be at
                    their sole risk and further understand and acknowledge that the
                    Information has been compiled from publicly aired and published
                    sources. Company respects the rights of such entities and cannot
                    be deemed to be infringing on the respective copyrights or
                    businesses of such entities. Company reserves the right, in its
                    sole discretion and without any obligation, to make improvements
                    to, or correct any error or omissions in any portion of the
                    Information.
                </Typography>
                <br />
                <Typography>1.6.1 Trademark</Typography>
                <br />
                <Typography>
                    "Website" and related icons and logos are registered trademarks
                    or trademarks or service marks of Company in various
                    jurisdictions and are protected under applicable copyright,
                    trademark and other proprietary and intellectual property rights
                    laws. The unauthorized adoption copying, modification, use or
                    publication of these marks is strictly prohibited.
                </Typography>
                <br />
                <Typography>1.6.2. Copyright</Typography>
                <br />
                <Typography>
                    All Web Site Content including Website Information is
                    copyrighted to Company excluding any third-party content and any
                    links to any third-party websites being made available or
                    contained on the Web Site. User(s) may not use any trademark,
                    service mark or logo of any independent third parties without
                    prior written approval from such parties. User(s) acknowledge
                    and agree that Company is not an arbiter or judge of disputes
                    concerning intellectual property rights and as such cannot
                    verify that User(s) supplying merchandise or providing services
                    on the Web Site have the right to sell the merchandise or
                    provide the services offered by such User(s). Company encourages
                    User(s) to assist Company in identifying listings on the Web
                    Site which in the User(s) knowledge or belief infringe their
                    rights. User(s) further acknowledge and agree by taking down a
                    listing, Company does not and cannot be deemed to be endorsing a
                    claim of infringement and further that in those instances in
                    which Company declines to take down a listing, Company does not
                    and cannot be deemed to be endorsing that the listing is not
                    infringing of third party rights or endorsing any sale or supply
                    of merchandise or services pursuant to or on account of such
                    listing. Company respects the intellectual property rights of
                    others, and we expect our User(s) to do the same. User(s) agree
                    to not copy, download or reproduce the Web Site Content,
                    Information or any other material, text, images, video clips,
                    directories, files, databases or listings available on or
                    through the Web Site ("Company Content") for the purpose of
                    re-selling or re-distributing, mass mailing (via email, wireless
                    text messages, physical mail or otherwise) operating a business
                    competing with Company, or otherwise commercially exploiting the
                    Company Content unless otherwise agreed between the parties.
                    Systematic retrieval of Company Content to create or compile,
                    directly or indirectly, a collection, compilation, database or
                    directory (whether through robots, spiders, automatic devices or
                    manual processes) without written permission from Company is
                    prohibited. In addition, use of the Company Content for any
                    purpose not expressly permitted in this Agreement is prohibited
                    and entitles Company to initiate appropriate legal action.
                    User(s) agree that as a condition of their access to and use of
                    Company’s Services, they will not use Company’s Services to
                    infringe the intellectual property rights of any third parties
                    in any way. Company reserves the right to terminate the right of
                    any User(s) to access or use Company’s Services for any
                    infringement of the rights of third parties in conjunction with
                    use of the Company’s Service, or in the event Company is of the
                    believes that User(s) conduct is prejudicial to the interests of
                    Company, its affiliates, or other User(s), or for any other
                    reason, at Company’s sole discretion, with or without cause.
                </Typography>
                <br />
                <Typography>1.6.3. URL's/Sub-Domain</Typography>
                <br />
                <Typography>
                    URL’s/ Sub-domain names assigned by Company to User(s)(including
                    both paid and free User(s)) are the exclusive property of
                    Company and it cannot be assumed to be permanent in any case.
                    Company reserves the right, without prior notice, at any point
                    of time, to suspend or terminate or restrict access to or edit
                    any URL's/Sub-domain names. In all such cases, company will not
                    be liable to any party for any direct, indirect, special or
                    other consequential damages, including, without limitation, any
                    lost profits, business interruption or otherwise.
                </Typography>
                <br />
                <Typography>
                    Company may allow User(s) access to content, products or
                    services offered by third parties through hyperlinks (in the
                    form of word link, banners, channels or otherwise) to the
                    websites offered by such third parties ("Third Party Websites").
                    Company advises its User(s) to read the terms and conditions of
                    use and/or privacy policies applicable in respect of such Third
                    Party Websites prior to using or accessing such Third Party
                    Websites. User(s) acknowledge and agree that Company has no
                    control over any content offered on Third Party Websites, does
                    not monitor such Third Party Websites, and shall in no manner be
                    deemed to be liable or responsible to any person for such Third
                    Party Sites, or any content, products or services made available
                    thereof.
                </Typography>
                <br />
                <Typography>1.7. LINKS TO THIRD PARTY SITES</Typography>
                <br />
                <Typography>
                    Links to third party sites are provided on Web Site as a
                    convenience to User(s). User(s) acknowledge and agree that
                    Company does not have any control over the content of such
                    websites and/ or any information, resources or materials
                    provided therein. Company may allow User(s) access to content,
                    products or services offered by third parties through hyperlinks
                    (in the form of word link, banners, channels or otherwise) to
                    the websites offered by such third parties ("Third Party
                    Websites"). Company advises its User(s) to read the terms and
                    conditions of use and/or privacy policies applicable in respect
                    of such Third Party Websites prior to using or accessing such
                    Third Party Websites. User(s) acknowledge and agree that Company
                    has no control over any content offered on Third Party Websites,
                    does not monitor such Third Party Websites, and shall in no
                    manner be deemed to be liable or responsible to any person for
                    such Third Party Sites, or any content, products or services
                    made available thereof.
                </Typography>
                <br />
                <Typography> 1.8. TERMINATION</Typography>
                <Typography>
                    <br />
                    Most content and some of the features on the Web Site are made
                    available to User(s) free of charge. However, Company reserves
                    the right to restrict access to certain areas or features of the
                    Web Site (to paying or registered User(s)) at any time without
                    assigning any reason and with or without notice to such User(s).
                    Company also reserves the universal right to deny access to
                    particular User(s) to any or all of its services or content
                    without any prior notice or explanation in order to protect the
                    interests of Company and/ or other User(s) of the Web Site.
                    Company further reserves the right to limit, deny or create
                    different access to the Web Site and its features with respect
                    to different User(s), or to change any or all of the features of
                    the Web Site or introduce new features without any prior notice
                    to User(s). Company reserves the right to terminate the
                    membership/subscription of any User(s) temporarily or
                    permanently for any of the following reasons:
                    <br />
                    • If any false information in connection with their account
                    registered with COMPANY is provided by such User(s), or if such
                    User(s) are engaged in fraudulent or illegal
                    activities/transactions.
                    <br />
                    • If such User(s) breaches any provisions of the Agreement.
                    <br />
                    • If such User(s) utilizes the Web Site to send spam messages or
                    repeatedly publish the same product information.
                    <br />
                    • If such User(s) posts any material that is not related to
                    trade or business cooperation.
                    <br />
                    • If such User(s) impersonates or unlawfully uses another
                    person’s or business entity’s name to post information or
                    conduct business in any manner.
                    <br />
                    • (f) If such User(s) is involved in unauthorized access, use,
                    modification, or control of the Web Site database, network or
                    related services.
                    <br />
                    • If such User(s) obtains by any means another registered
                    User(s) Username and/or Password.
                    <br />
                    • Or any other User(s) activity that may not be in accordance
                    with the ethics and honest business practices.
                    <br />
                    If COMPANY terminates the membership of any registered User(s)
                    including those User(s) who have subscribed for the paid
                    services of Company, such person will not have the right to
                    re-enrol or join the Web Site under a new account or name unless
                    invited to do so in writing by Company in any case of
                    termination, no subscription/membership fee/charges paid by the
                    User(s)will be refunded. User(s) acknowledge that inability to
                    use the Web Site wholly or partially for whatever reason may
                    have adverse effect on their business. User(s) hereby agree that
                    in no event shall Company be liable to any User(s) or any third
                    parties for any inability to use the Web Site (whether due to
                    disruption, limited access, changes to or termination of any
                    features on the Web Site or otherwise), any delays, errors or
                    omissions with respect to any communication or transmission, or
                    any damage (direct, indirect, consequential or otherwise)
                    arising from the use of or inability to use the Web Site or any
                    of its features.
                    <br />
                    Registered business of the user will be active in the portal
                    during the subscription eligibility period and refund of the
                    subscription amount will not be allowed. If user doesn’t renew
                    the subscription, registered business be marked as inactive but
                    retained in the system for activation later after successful
                    renew of the service.
                </Typography>
                <br />
                <Typography>1.9. REGISTERED USER(S)</Typography>
                <br />
                <Typography>
                    To become a registered User(s) of the Web Site a proper
                    procedure has been made available on the Web Site which is for
                    the convenience of User(s) so that they can easily use the
                    website. User(s) can become registered User(s) by filling an
                    on-line registration form on the Web Site by providing the
                    required information (including name, contact information,
                    details of User(s)business, etc.). Company will establish an
                    account ("Account") for the User(s) upon successful registration
                    and will assign a user alias ("User ID") and password
                    ("Password") for log-in access to the User(s)’s Account. Company
                    may at its sole discretion assign to User(s) upon registration a
                    web-based email or messaging account (“Email Account”) with
                    limited storage space to send or receive emails or messages.
                    User(s) will be responsible for the content of all the messages
                    communicated through the account. User(s) registering on the Web
                    Site on behalf of business entities represent and warrant that:
                    <br />
                    • they have the requisite authority to bind such business entity
                    this Agreement;
                    <br />
                    • the address provided by such User(s)at the time of
                    registration is the principal place of business of such business
                    entity; and
                    <br />
                    • all other information provided to Company during the
                    registration process is true, accurate, current and complete.
                    For purposes of this provision, a branch or representative
                    office of a User(s)will not be considered a separate entity and
                    the principal place of business of the User(s)will be deemed to
                    be that of its head office.
                    <br />
                    User(s) agree that by registering on the Web Site, they consent
                    to the inclusion of their personal data in Company’s on-line
                    database and authorize Company to share such information with
                    other User(s). Company may refuse registration and deny the
                    membership and associated User ID and Password to any User(s)
                    for whatever reason. Company may suspend or terminate a
                    registered membership at any time without any prior notification
                    in interest of Company or general interest of its User(s)
                    without assigning any reason thereof and there shall arise no
                    further liability on Company of whatsoever nature due to the
                    suspension or termination of the User account. User(s)
                    registered on the Web Site are in no manner a part of or
                    affiliated to Company.
                    <br />
                </Typography>
                <br />
                <Typography>1.10. DATA PROTECTION</Typography>
                <br />
                <Typography>
                    Personal information supplied by User(s) during the use of the
                    Web Site is governed by Company’s privacy policy ("Privacy
                    Policy").
                </Typography>
                <br />
                <Typography>1.11. POSTING YOUR CONTENT ON WEBSITE</Typography>
                <br />
                <Typography>
                    Some content displayed on the Web Site is provided or posted by
                    third parties. User(s) can post their content on some of the
                    sections/services of the Web Site using the self-help submit and
                    edit tools made at the respective sections of the Web Site.
                    User(s)may need to register and/or pay for using or availing
                    some of these services. User(s) understand and agree that
                    Company in such case is not the author of the content and that
                    neither Company nor any of its affiliates, directors, officers
                    or employees have entered into any arrangement including any
                    agreement of sale or agency with such third parties by virtue of
                    the display of such content on the Web Site. User(s) further
                    understand and agree Company is not responsible for the
                    accuracy, propriety, lawfulness or truthfulness of any third
                    party content made available on the Web Site and shall not be
                    liable to any User(s) in connection with any damage suffered by
                    the User(s) on account of the User(s)’s reliance on such
                    content. Company shall not be liable for a User(s) activities on
                    the Web Site, and shall not be liable to any person in
                    connection with any damage suffered by any person as a result of
                    any User's conduct.
                    <br />
                    User(s) solely represent, warrant and agree to:
                    <br />
                    (a) provide Company with true, accurate, current and complete
                    information to be displayed on the Web Site;
                    <br />
                    (b) maintain and promptly amend all information provided on the
                    Web Site to keep it true, accurate, current and complete.
                    <br />
                    User(s) hereby represent, warrants and agree that User(s) shall
                    be solely responsible for ensuring that any material or
                    information posted by User(s)on the Web Site or provided to the
                    Web Site or authorized by the User(s) for display on the Web
                    Site, does not, and that the products represented thereby do
                    not, violate any Third Party Rights, or is posted with the
                    permission of the owner(s) of such Third Party Rights. User(s)
                    hereby represent, warrant and agree that they have the right to
                    manufacture, offer, sell, import and distribute the products
                    offered and displayed on the Web Site, and that such
                    manufacture, offer, sale, importation and/or distribution of
                    those products violates no Third Party Rights. User(s)agree that
                    they will not use Company Content and/or Company’s Services to
                    send junk mail, chain letters or spamming. Further, registered
                    User(s)of the Web Site agree that they will not use the Email
                    Account to publish, distribute, transmit or circulate any
                    unsolicited advertising or promotional information.
                    User(s)further hereby represent, warrant and agree (i) to host,
                    display, upload, modify, publish, transmit, store, update or
                    share; or (ii) submit to Company for display on the Web Site or
                    transmit or sought to be transmitted through Company’s Services
                    any content, material or information that does not and shall at
                    no point:
                    <br />
                     Contain fraudulent information or make fraudulent offers of
                    items or involve the sale or attempted sale of counterfeit or
                    stolen items or items whose sales and/or marketing is prohibited
                    by applicable law, or otherwise promote other illegal
                    activities;
                    <br />
                     Belong to another person and to which User(s) do not have any
                    right to;
                    <br />
                     Be part of a scheme to defraud other User(s) of the Web Site
                    or for any other unlawful purpose;
                    <br />
                     Be intended to deceive or mislead the addressee about the
                    origin of such messages or knowingly and intentionally is used
                    to communicate any information which (i) is patently false or
                    grossly offensive or menacing/misleading in nature but may
                    reasonably be perceived as a fact; or (ii) harass a person,
                    entity or agency for financial gain or to cause any injury to
                    any person;
                    <br />
                     Relate to sale of products or services that infringe or
                    otherwise abet or encourage the infringement or violation of any
                    third party's copyright, patent, trademarks, trade secret or
                    other proprietary right or rights of publicity or privacy, or
                    any other Third Party Rights;
                    <br />
                     Violate any applicable law, statute, ordinance or regulation
                    (including without limitation those governing export control,
                    consumer protection, unfair competition, anti-discrimination or
                    false advertising);
                    <br />
                     Relate to any controversial weapons, cluster munitions or
                    anti-personnel mines and other such defence equipment;
                    <br />
                     Be defamatory, abusive libellous, unlawfully threatening,
                    unlawfully harassing, grossly harmful, indecent, seditious,
                    blasphemous, paedophilic, hateful, invasive of another’s
                    privacy, including bodily privacy racially, ethnically
                    objectionable, disparaging, relating or encouraging money
                    laundering or gambling, leading to breach of confidence, or
                    otherwise unlawful or objectionable in any manner whatever;
                    <br />
                     Be vulgar, obscene or contain or infer any pornography or
                    sex-related merchandising or any other content or otherwise
                    promotes sexually explicit materials or is otherwise harmful to
                    minors;
                    <br />
                     Promote discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation or age;
                    <br />
                     Contain any material that constitutes unauthorized advertising
                    or harassment (including but not limited to spamming), invades
                    anyone's privacy or encourages conduct that would constitute a
                    criminal offense, give rise to civil liability, or otherwise
                    violate any law or regulation;
                    <br />
                     Solicit business from any User(s) in connection with a
                    commercial activity that competes with Company;
                    <br />
                     Threaten the unity, integrity, defence, security or
                    sovereignty of India, friendly relations with foreign states, or
                    public order or causes incitement to the commission of any
                    cognisable offence or prevents investigation of any offence or
                    is insulting any other nation;
                    <br />
                     Contain any computer viruses or other destructive devices and
                    codes that have the effect of damaging, interfering with,
                    intercepting or expropriating any software or hardware system,
                    data or personal information or that are designed to interrupt,
                    destroy or limit the functionality of any computer resource;
                    <br /> Link directly or indirectly to or include descriptions
                    of goods or services that are prohibited under the prevailing
                    law; or Otherwise create any liability for Company or its
                    affiliates.
                </Typography>
                <br />
                <Typography>
                    Company reserves the right in its sole discretion to remove any
                    material/content/photos/offers displayed on the Web Site which
                    in Company’s reasonable belief is unlawful or could subject
                    Company to liability or in violation of the Agreement or is
                    otherwise found inappropriate in Company’s opinion. Company
                    reserves the right to cooperate fully with governmental
                    authorities, private investigators and/or injured third parties
                    in the investigation of any suspected criminal or civil
                    wrongdoing. In connection with any of the foregoing, Company
                    reserves the right to suspend or terminate the Account of any
                    User(s) as deemed appropriate by Company at its sole discretion.
                    User(s) agree that Company shall have no liability to any
                    User(s), including liability in respect of consequential or any
                    other damages, in the event Company takes any of the actions
                    mentioned in this provision. User(s) understand and agree that
                    the Web Site acts as a content integrator and is not responsible
                    for the information provided by User(s) displayed on the Web
                    Site. Company does not have any role in developing the content
                    displayed on the Web Site. Company Y has the right to promote
                    any content including text, images, videos, brochures etc.
                    provided by User(s)on various platforms owned by the Company.
                </Typography>
                <br />
                <Typography>1.12. DISCLAIMER OF WARRANTIES</Typography>
                <br />
                <Typography>
                    Although Company attempts to provide accurate Content on the
                    website, it makes no representation, endorsement or warranty
                    that such Content is accurate or suitable for any particular
                    purpose. The website and its Content are provided on an "as is"
                    basis. Use of the website and its Content is at the user's sole
                    risk. The website and Content are provided without any
                    representations, endorsements, or warranties of any kind
                    whatsoever, either expressed or implied, including, but not
                    limited to, any warranties of title or accuracy and any implied
                    warranties of merchantability, fitness for a particular purpose
                    or non infringement, with the sole exception of warranties (if
                    any) which cannot be expressly excluded under applicable law. As
                    noted below, Company also makes no representations, endorsements
                    or warranties, either express or implied, with respect to any
                    website operated by a third party.
                </Typography>
                <br />
                <Typography>1.13. LIMITATIONS OF LIABILITY</Typography>
                <br />
                <Typography>
                    In no event will Company be liable for any damages, including,
                    without limitation, indirect, incidental, special, consequential
                    or punitive damages, whether under a contract, tort or any other
                    theory of liability, arising in connection with any party's use
                    of the website or in connection with any failure of performance,
                    error, omission, interruption, defect, delay in operation or
                    transmission, computer virus, line system failure, loss of data
                    or loss of use related to this website or any website operated
                    by any third party or any contents of this website or any other
                    website, even if Company is aware of the possibility of such
                    damages.
                </Typography>
                <br />
                <Typography>1.14. MODIFICATION</Typography>
                <br />
                <Typography>
                    Company may, at any time, make modifications, changes and
                    alterations to the Contents of this website, including these
                    terms and conditions, without prior notice. You are responsible
                    for regularly reviewing these terms and conditions. Your
                    continued use of this website following any modifications,
                    changes or alterations shall constitute your acceptance of such
                    modifications, changes or alterations.
                </Typography>
                <br />
                <Typography>1.15. WAVIER</Typography>
                <br />
                <Typography>
                    No waiver by Company of any right under or term or provision of
                    these terms and conditions will be deemed a waiver of any other
                    right, term or provision of these terms and conditions at that
                    time or a waiver of that or any other right, term or provision
                    of these terms and conditions at any other time.
                </Typography>
                <br />
                <Typography>1.16. LAW AND JURISDICTION</Typography>
                <br />
                <Typography>
                    This Agreement and the Privacy Policy shall be governed in all
                    respects by the laws of Indian Territory. Company considers
                    itself and intends itself to be subject to the jurisdiction of
                    the Courts of Pune, India only. You are responsible for
                    complying with the laws of the jurisdiction from which you are
                    accessing this website and you agree that you will not access or
                    use the information on this website in violation of such laws.
                    Any information disclosed to Company outside a pre-existing and
                    documented confidential business relationship will be deemed
                    non-confidential and non-proprietary, and Company may therefore
                    develop, use and freely disclose or publish similar ideas
                    without compensating you or accounting to you. You represent
                    that you have the lawful right to submit such information and
                    agree that you will not submit any information unless you are
                    legally entitled to do so.
                </Typography>
                <br />
                <Typography>1.17. CONTACT</Typography>
                <br />
                <Typography>
                    Our contact information and our help centre also provides
                    information about our services. For general inquiries, you may
                    contact us at enquiry@neevay.com For legal notices or service of
                    process, you may write us at given legal.support@neevay.com
                </Typography>
                <br />
            </Box>
        </Container>
    </ThemeProvider>
    )
}

TermsAndConditionData.propTypes = {}

export default TermsAndConditionData
