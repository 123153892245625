import axios from "utils/axios";

import {
  ADD_CERTIFICATE_URL,
  LIST_CERTIFICATE_URL,
  DELETE_CERTIFICATE_URL,
} from "config/services";

class CertificateService {
  listCertificate = (supplierId) =>
    new Promise((resolve, reject) => {
      axios
        .get(LIST_CERTIFICATE_URL, { supplierId })
        .then((response) => {
          // console.log(
          //   "CertificateService.listCertificate()",
          //   supplierId,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "CertificateService.listCertificate() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  addCertificate = (supplierId, certificateTitle, certificateImage) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("supplierId", supplierId);
      formData.append("certificateTitle", certificateTitle);
      formData.append("certificateImage", certificateImage);
      axios
        .post(ADD_CERTIFICATE_URL, formData)
        .then((response) => {
          // console.log(
          //   "CertificateService.addCertificate()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "CertificateService.addCertificate() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  deleteCertificate = (supplierId, certificateId, certificateName) =>
    new Promise((resolve, reject) => {
      const payload = {
        supplierId, certificateId, certificateName
      }

      axios
        .post(DELETE_CERTIFICATE_URL, payload)
        .then((response) => {
          // console.log(
          //   "CertificateService.deleteCertificate()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "CertificateService.deleteCertificate() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });
}

const certificateService = new CertificateService();

export default certificateService;
