import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";

import {  Box, Grid, Container } from "@mui/material";


import Main from "layouts/Main";
import { Helmet } from "react-helmet";

import HeroSection from "./HeroSection";

function AboutUs({ msg, ...rest }) {
  const theme = useTheme();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <Main>
      <Helmet>
        <title>Neevay - About Us</title>
      </Helmet>

      <Grid maxWidth>
        <Container maxWidth="lg">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={"20px"}>
              <Grid item xs={12} marginTop={"15px"}>
                <img
                  alt='Hero Image'
                  src={`${BASE_URL}/assets/business/web-aboutus-hero.jpg`}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <HeroSection/>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>
    </Main>
  );
}

AboutUs.propTypes = {

};

export default AboutUs;
