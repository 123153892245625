/* eslint-disable no-param-reassign */
import produce from "immer";

import {
  SUBSCRIBE_PLAN,
  CREATE_ORDER_SUCCESS,
  CANCEL_ORDER,
} from "actions/subscribeAction";
import { SIGNOUT } from "actions/authActions";
const initialState = {
  planToSubscribe: undefined,
  draftOrder: undefined,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIBE_PLAN:
      {

        const plan = { ...action.payload };

        return produce(state, (draft) => {
          draft.planToSubscribe = plan;
          localStorage.setItem('plan', JSON.stringify(plan));
        });
      }
      break;

    case CREATE_ORDER_SUCCESS:
      {
        const order = action.payload?.order;
        // console.log("orderReducer() CREATE_ORDER_SUCCESS order", order);
        return produce(state, (draft) => {
          draft.draftOrder = order;
          // console.log("initialState.createdOrder", draft.draftOrder);
        });
      }
      break;

    case CANCEL_ORDER:
      {
        return produce(state, (draft) => {
          draft.draftOrder = undefined;
          draft.planToSubscribe = undefined;
        });
      }
      break;
    case SIGNOUT:
      {
        return produce(state, (draft) => {
          draft.draftOrder = undefined;
          draft.planToSubscribe = undefined;
        });
      }
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default orderReducer;
