import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { CREATE_CRM_LEAD_URL } from "config/services";
import axios from "utils/axios";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { registerUserWithMobile, sendwhatsAppOTP } from "actions/authActions";
import { getCRMLeadByPhone } from "actions/crmAction";

function SignUpMobileFormNew({
  className,
  onSubmitSuccess,
  setIsWhatsappNo,
  isWhatsappNo,
  ...rest
}) {
  const dispatch = useDispatch();
 

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setIsWhatsappNo(checked);
  };



  return (
    <Formik
      initialValues={{
        name: "",
        mobile: "",
      }}
      validationSchema={Yup.object().shape({
        mobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .min(10)
          .max(10)
          .required("Please Enter your mobile"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (isWhatsappNo) {
            dispatch(sendwhatsAppOTP(values.mobile));
          } else {
            dispatch(registerUserWithMobile(values.name, values.mobile));
          }
          localStorage.setItem("signUpMobile", values.mobile);
          onSubmitSuccess(values.mobile);
          const payload = [
            {
              Attribute: "EmailAddress",
              Value: "",
            },
            {
              Attribute: "Phone",
              Value: values.mobile,
            },
          ];

          axios
            .post(CREATE_CRM_LEAD_URL, payload)
            .then((crmResponse) => {
              // console.log(":CREATE_CRM_LEAD: Response",crmResponse)
              dispatch(getCRMLeadByPhone(values.mobile));
            })
            .catch((crmError) => {
              // console.log("CRMERROR",crmError)
            });
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid
            container
            spacing={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item >
            <Typography style={{
                    color: "#B9B9C3",
                     textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "53px",
                }}>Mobile Number</Typography>
            </Grid>
            <Grid
              item
              style={{
                width: "80%",
                height: "50px",
                marginTop:"-40px"
              }}
            >
                
              <TextField
              color="primary" 
              focused 
                name="mobile"
                size="small"
                id="mobile"
                value={values.mobile}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 10,  style: {
                    color: 'white', // Set the text color to white
                  } }}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            {/* {values.mobile && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 6,
                  marginTop:"-10px"
                }}
              >
                <Checkbox
                  color="secondary"
                  name="whatsappNo"
                  onChange={handleCheckboxChange}
                  checked={isWhatsappNo}
                  sx={{
                    height: "5px",
                    width: "5px",
                    borderRadius: "3px",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#B9B9C3",
                  }}
                >
                This is my WhatsApp Number
                </Typography>
              </Grid>
            )} */}

            <Grid item alignItems={"center"} justifyContent={"center"}>
              <Button
                style={{
                  backgroundColor: "#FD6600",
                  width: "153px",
                  height: "36px",
                  color: "white",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  borderRadius:"5px",
                  fontWeight: "700",
                  lineHeight: "53px",
                  textTransform: "uppercase",
                  "&:hover": {
                    backgroundColor: "#FD6600",
                    boxShadow:  "0px 0px 10px 0px rgba(0,0,0,0.7)",
                  },
                }}
                type="submit"
                variant="contained"
              >
                Send OTP
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignUpMobileFormNew.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

SignUpMobileFormNew.default = {
  onSubmitSuccess: () => {},
};

export default SignUpMobileFormNew;
