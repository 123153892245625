import serviceService from "services/serviceService";
import { NewServiceInfo } from "interface/serviceIf";
export const SERVICE_CREATE = "@service/create";
export const SERVICE_CREATE_SUCCESS = "@service/create-success";
export const SERVICE_CREATE_FAILURE = "@service/create-failure";

export const SERVICE_DELETE = "@service/delete";
export const SERVICE_DELETE_SUCCESS = "@service/delete-success";
export const SERVICE_DELETE_FAILURE = "@service/delete-failure";

export const SERVICE_ADD_TRIGGER = "@service/add-trigger";
export const SERVICE_EDIT_TRIGGER = "@service/edit-trigger";

export function addServiceTrigger() {
  return async (dispatch) => {
    dispatch({
      type: SERVICE_ADD_TRIGGER,
      payload: {},
    });
  };
}

export function editServiceTrigger(service, serviceId) {
  return async (dispatch) => {
    // console.log("editServiceTrigger() ", service);
    dispatch({
      type: SERVICE_EDIT_TRIGGER,
      payload: { service, serviceId },
    });
  };
}

export function addService(supplierId, values) {
  return async (dispatch) => {
    const newService = new NewServiceInfo(supplierId, values);
    console.log("values in action",values )
    try {
      dispatch({
        type: SERVICE_CREATE,
        payload: {
          service: newService,
        },
      });

      const payload = await serviceService.addUpdateService(newService);
      // console.log("service service", payload);
      dispatch({
        type: SERVICE_CREATE_SUCCESS,
        payload: { ...payload },
      });
    } catch (error) {
      dispatch({ type: SERVICE_CREATE_FAILURE });
      throw error;
    }
  };
}

export function deleteService(supplierId, serviceName, serviceId, businessName) {
  return async (dispatch) => {
        try {
      dispatch({
        type: SERVICE_DELETE,
        payload: {
          serviceName,
        },
      });

      const payload = await serviceService.deleteService(
        supplierId,
        serviceName,
        serviceId,
        businessName
      );
      // console.log("service delete", payload);

      dispatch({
        type: SERVICE_DELETE_SUCCESS,
        payload: { ...payload },
      });
    } catch (error) {
      dispatch({ type: SERVICE_DELETE_FAILURE });
      throw error;
    }
  };
}
