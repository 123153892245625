import React, { useState } from 'react';
// import LeadsData from './LeadsData';
import SubmittedQuotations from './SubmittedQuotations';
import { useSelector } from 'react-redux';
import LeadsDataBoard from './LeadsDataBoard';
import LeadsFilters from './LeadsFilters';
import SidePanel from 'layouts/SidePanel';
import NewRequirements from './NewRequirements';
// import NewRequirements from './LeadsData';

const VendorLeads = () => {
    const lead = useSelector((state) => state.lead);
    const loading = lead.loading;

    const [viewMode, setViewMode] = useState('grid'); // State for view mode

    const handleViewModeChange = (mode) => {
        setViewMode(mode); // Change the view mode
    };

    return (
        <>
            <SidePanel>
                <div className="w-full h-screen bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
                    <div className="bg-white sticky top-0 z-10 shadow-md p-4">
                        <h1 className="text-base font-bold">My Leads</h1>
                    </div>
                    {loading ? (
                        <div className="flex flex-col justify-center items-center h-screen">
                            <div className="animate-spin rounded-full border-2 mb-2 border-t-2 border-orange-500 border-t-gray-200 h-8 w-8"></div>
                            <p className='text-sm font-medium'>Fetching Leads...</p>
                        </div>
                    ) : (
                        <div className=' space-y-3 z-0'>
                            <div className='flex justify-between items-center'>

                                {/* <div>
                            <button onClick={() => handleViewModeChange('grid')} className={`mr-2 ${viewMode === 'grid' ? 'font-bold' : ''}`}>Grid</button>
                            <button onClick={() => handleViewModeChange('board')} className={`${viewMode === 'board' ? 'font-bold' : ''}`}>Board</button>
                        </div> */}
                            </div>
                            {/* <LeadsDataBoard leads={lead?.leads?.filter((row) => row.status !== "CLOSED")} viewMode={viewMode} /> */}
                            {/* <LeadsFilters leads={lead?.leads?.filter((row) => row.status !== "CLOSED")} /> */}
                            <NewRequirements leads={lead?.leads?.filter((row) =>
                                row.status !== "CLOSED"
                            )} viewMode={viewMode} />
                            {/* <SubmittedQuotations leads={lead?.leads?.filter((row) => row.status !== "CLOSED" && row?.suppliers?.filter((supplier) => supplier.quotationReceived === true))} viewMode={viewMode} /> */}
                        </div>
                    )}
                </div>
            </SidePanel>
        </>
    );
};

export default VendorLeads;
