class NewProjectInfo {
  constructor(supplierId, info) {
    this.supplierId = supplierId;
    this.id = info.id;
    this.projectName = info.projectName;
    this.title = info.projectName; // info.title; putting title same
    this.description = info.description;
    this.clientName = info.clientName;
    this.location = info.location;
    this.projectSize = { qty: info.projectSize, uom: info.uom };
    // this.projectSize = { qty: info.qty, uom: info.uom };
    // this.qty = info.qty;
    // this.uom = info.uom;

    this.addedBy = "";
    this.isUpdate = false;

  }
}
export default { NewProjectInfo };
export { NewProjectInfo };
