import React from "react";

const Companies = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const companyLogoImages = [
    "business/web-homepage-shubham-company-logo.svg",
    "business/web-homepage-relionce-company-logo.svg",
    "business/web-homepage-vilas-company-logo.svg",
    "business/web-homepage-nyati-company-logo.svg",
    "business/web-homepage-vascon-company-logo.svg",
    "business/web-homepage-planedge-company-logo.svg",
    "business/web-homepage-pride-company-logo.svg",
    "business/web-homepage-thermax-companh-logo.svg",
  ];
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
        <h2 className="mb-8 lg:mb-16 text-4xl font-extrabold tracking-tight leading-tight text-center text-gray-900 dark:text-white md:text-4xl">
          Trusted by the fastest growing companies
        </h2>
        <div className="grid grid-cols-2 gap-8  text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-8 dark:text-gray-400">
          {companyLogoImages.map((logo) => (
            <div className="flex justify-center">
              <img
                style={{ height: "40px" }}
                src={`${BASE_URL}/assets/${logo}`}
              ></img>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Companies;
