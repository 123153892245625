import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Card,
  Grid,
  Box,
  IconButton,
  Button,
  CardContent,
  FormHelperText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { saveUrl } from "actions/businessActions";

const Url = ({
  isPublicProfile,
  url,
  urlCardHideShow,
  supplierId,
  isURLOpen,
  permissions,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(isURLOpen);
  const dispatch = useDispatch();
  const host = window.location.host;

  useEffect(() => {
    setOpen(isURLOpen);
  }, [isURLOpen]);

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12}>
        <Card sx={{ borderRadius: 5 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
            <Typography sx={{ wordBreak: "break-word" }}>
              <b>Business Url :</b>{" "}
              <span>
                <a href={`https://${host}/company/${url}`} target="_blank">
                  https://neevay.com/company/{url}
                </a>
              </span>
            </Typography>

            {permissions &&
              !permissions.isViewOnly &&
              permissions.isFreeUpdateAllowed && (
                <IconButton color="primary" onClick={() => setOpen(true)}>
                  <EditIcon />
                </IconButton>
              )}
          </Box>
          {isOpen ? (
            <Box>
              <Formik
                initialValues={{
                  url: url,
                }}
                validationSchema={Yup.object().shape({
                  url: Yup.string().required(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    await dispatch(saveUrl(supplierId, values.url));
                    setOpen(false);
                  } catch (error) {
                    // console.log("Form: error", error);
                    const message = error || "Something went wrong";
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    // className={clsx(classes.root, className)}
                    {...rest}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={12}>
                        <Card>
                          <CardContent>
                            <Grid container spacing={0}>
                              <Grid item xs={12} lg={12} md={12}>
                                <TextField
                                  size="small"
                                  error={Boolean(touched.url && errors.url)}
                                  fullWidth
                                  helperText={touched.url && errors.url}
                                  label="Url"
                                  name="url"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.url}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                            <Grid>
                              {errors.submit && (
                                <Box mt={3}>
                                  <FormHelperText error>
                                    {errors.submit}
                                  </FormHelperText>
                                </Box>
                              )}
                            </Grid>

                            <Grid
                              item
                              md={12}
                              sm={12}
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <Box mt={2}>
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  onClick={() => setOpen(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  type="submit"
                                  onClick={handleSubmit}
                                  sx={{ ml: 1 }}
                                >
                                  Save
                                </Button>
                              </Box>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          ) : (
            ""
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

Url.propTypes = {
  url: PropTypes.string.isRequired,
  saveUrl: PropTypes.func.isRequired,
  setURLHide: PropTypes.bool.isRequired,
  isPublicProfile: PropTypes.bool,
  permissions: PropTypes.object,
};

Url.defaultProps = {
  onSubmitSuccess: () => {},
  isPublicProfile: true,
};

export default Url;
