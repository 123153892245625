import React, { useState } from 'react'
import { Grid, Typography, Dialog, Box } from "@mui/material";
import {useSelector } from "react-redux";
const RfqSendSuccessPopUp = ({ isSmallScreen, openRfqSendSuccessPopUp, setOpenRfqSendSuccessPopUp }) => {
    const requirement = useSelector((state) => state.requirement);
    const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;
    const phoneNumber = 919503339814
    const rfqError = requirement?.rfqError
    const handleClose = () => {
        setOpenRfqSendSuccessPopUp(false)
    }
    return (
        <Dialog
            // fullWidth={true}
            // maxWidth={'200px'}
            open={openRfqSendSuccessPopUp}
            onClose={handleClose}>
            <Grid sx={{ cursor: "pointer", textAlign: "end", paddingX: "24px", paddingY: "10px", }} onClick={handleClose}>x</Grid>
            <Grid
                display={"flex"}
                width={isSmallScreen?"300px":"450px"}
                justifyContent={"center"}
                alignItems={"center"}
                padding={"5px 10px 10px"}
            // sx={{ minHeight: "75vh" }}
            >
                <Grid width={"600px"}

                >
                    {rfqError === false
                        ? <><Typography
                            sx={{
                                textAlign: "center",
                                fontFamily: "PP Telegraf",
                                fontSize: isSmallScreen? "16px":"20px",
                                fontWeight: "700"
                            }}
                        >Your {entityId? "requirement":"enquiries"}  has been sent successfully to the selected vendors!</Typography>
                            <Typography sx={{
                                // fontFamily: "PP Telegraf",
                                textAlign: "center",
                                fontSize: isSmallScreen? "16px":"20px",
                                marginTop: "20px"
                            }}>
                                If you wish to connect with them directly, contact details are visible now at
                                <a href="/requirements">
                                    <span style={{
                                        // fontFamily: "PP Telegraf",
                                        color: "#3963AB",
                                        fontSize: isSmallScreen? "16px":"20px",
                                        fontWeight: 600,
                                        lineHeight: "32p",
                                        textDecoration: "underline"
                                    }}> {entityId? "Requirement":"Enquiries"}</span>
                                </a>
                            </Typography>
                            <Grid display={"flex"} justifyContent={"center"}>
                                <Box sx={{
                                    marginTop: "34px",
                                    width:  "100px",
                                    padding: isSmallScreen ? "6px" : "10px",
                                    border: "1px solid #000",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}>
                                    <Typography sx={{
                                        textAlign: "center",
                                        fontSize: "20px",
                                        lineHeight: "18px",
                                        fontWeight:600
                                    }} onClick={handleClose}>
                                         Close
                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                        : <>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontFamily: "PP Telegraf",
                                    fontSize: isSmallScreen ? "24px" : "32px",
                                    fontWeight: "800"
                                }}
                            >Failed to send the {entityId? "Requirement":"Enquiries"}. Neevay support has been notified to troubleshoot.</Typography>
                             <Typography sx={{
                                // fontFamily: "PP Telegraf",
                                textAlign: "center",
                                fontSize: isSmallScreen ? "20px" : "24px",
                                marginTop: "20px"
                            }}>
                               Kindly contact us at <span
                                    style={{
                                        color: "#0A66C2",
                                        fontFamily: "Roboto",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "30px",
                                    }}
                                > <a href={`tel:${phoneNumber}`}>+{phoneNumber}</a></span> for assistance.
                            </Typography>
                        </>}
                </Grid>
            </Grid>
        </Dialog>
    )
}
export default RfqSendSuccessPopUp