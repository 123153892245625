import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    ClickAwayListener,
    Dialog,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    Popover,
    TextField,
    Tooltip,
    Typography,
  } from "@mui/material";
  import searchBusinessAction from "actions/searchBusinessAction";
  import React, { useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  import BusinessNameDropDown from "./VendorSection/BusinessNameDropDown";
  
  const VendorListSectionForNonEntity = ({
    entityId,
    enquiryState,
    setEnquiryState,
    isSmallScreen,
    existingRfqEditView,
  }) => {
    const dispatch = useDispatch();
    console.log(enquiryState,"enquiryState");
    const [expandVendorList, setExpandVendorList] = useState(false);
    const [businessNameKey, setBusinessNameKey] = useState("");
    const [showDropDown, setShowDropDown] = useState(true);
    const [selectedSupliersDetails, setSelectedSupliersDetails] = useState({});
    const [openSuplierEmailPopUp, setOpenSuplierEmailPopUp] = useState(false);
    const [editEmailsData, setEditEmailsData] = useState([]);
    const [newEmailValue, setNewEmailValue] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [addEmailError, setAddEmailError] = useState(false);
    // const [addEmailErroprMsg, setAddEmailErroprMsg] = useState("");
  
    const validateEmail = (email) => {
      const validationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = validationRegex.test(email) &&
          !/\.(com|in)[^a-zA-Z0-9]/.test(email) &&
          !/\.{2,}/.test(email) &&
          !/\.,com$/.test(email);
  
      return isValid;
    };
    const handleBusinessNameKey = (value) => {
      setBusinessNameKey(value);
      if (value !== "") dispatch(searchBusinessAction(value));
    };
    const handleShowMoreClick = () => {
      // setShowMoreAllEnquiry(true);
      setExpandVendorList(true);
    };
    const handleClickAwayVendorList = () => {
      setExpandVendorList(false);
    };
    const handleDeleteEnquiryVendore = (vendorSupplierId) => {
      setEnquiryState((preVal) =>
        preVal.filter((data) => data.supplierId !== vendorSupplierId)
      );
    };
    const handleClickAwayBusinessNameDropDown = () => {
      setShowDropDown(false);
    };
    const handleClickSearchSuppliers = () => {
      setShowDropDown(true);
    };
    const handleSetSupllierData = (data) => {
      setSelectedSupliersDetails(data);
      setNewEmailValue("");
      setEditEmailsData(data?.emails);
  
      setOpenSuplierEmailPopUp(true);
    };
    const handleCloseEmailsBox = () => {
      setOpenSuplierEmailPopUp(false);
    };
    const handleChangeNewEmailValue = (value) => {
      setNewEmailValue(value);
    };
    const handleAddNewEmail = () => {
      if (validateEmail(newEmailValue)) {
        if (selectedSupliersDetails?.emails?.includes(newEmailValue)) {
          setEmailError(true);
          setTimeout(() => {
            setEmailError(false);
          }, 3000);
          setEmailErrorMsg("This email is already exist");
        } else {
          setEmailError(false);
          const updatedDetails = {
            ...selectedSupliersDetails,
            emails: [...selectedSupliersDetails?.emails, newEmailValue],
          };
          setSelectedSupliersDetails(updatedDetails);
          setEditEmailsData((preVal) => [...preVal, newEmailValue]);
          setNewEmailValue("");
        }
      } else {
        setEmailError(true);
        setEmailErrorMsg("Please enter a valid email");
        setTimeout(() => {
          setEmailError(false);
        }, 3000);
      }
    };
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleAddNewEmail();
      }
    };
    const handleCheckboxChange = (email) => {
      if (editEmailsData.includes(email)) {
        setEditEmailsData(
          editEmailsData.filter((selectedEmail) => selectedEmail !== email)
        );
      } else {
        setEditEmailsData([...editEmailsData, email]);
      }
    };
    const handleUpdateSuplliersEmail = () => {
      if (editEmailsData?.length > 0) {
        const updatedData = enquiryState?.map((item) =>
          item.supplierId === selectedSupliersDetails?.supplierId
            ? { ...item, emails: editEmailsData }
            : item
        );
        setEnquiryState(updatedData);
        setOpenSuplierEmailPopUp(false);
      } else {
        setAddEmailError(true);
        setTimeout(() => {
          setAddEmailError(false);
        }, 3000);
      }
    };
    return (
      <>
        <ClickAwayListener onClickAway={() => handleClickAwayVendorList()}>
          <Grid
            paddingBottom={"10px"}
            display={"flex"}
            alignItems={isSmallScreen ? "baseline" : "baseline"}
            borderBottom={"1px #CFDAE5 solid"}
            flexDirection={isSmallScreen && "column"}
            gap={"10px"}
                    >
            <Grid sx={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ color: "#4C4C4C" }}>{ enquiryState.length>1?"Selected Vendors":"Selected Vendor"} </Typography>
              <Tooltip title="List of all vendors who will receive this message on their contact email  and number">
                <Box
                  component="img"
                  sx={{
                    marginX: "8px",
                    height: "18px",
                    width: "18px",
                  }}
                  alt="logo"
                  src="/assets/icons/questionMarkLogo.svg"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              xs
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              {expandVendorList && enquiryState?.length > 4
                ? enquiryState?.map((data) => (
                    <Tooltip title={data?.emails?.join(", ")}>
                      <Chip
                        onClick={() => handleSetSupllierData(data)}
                        label={data.businessName}
                        size="small"
                        variant="outlined"
                        onDelete={
                          enquiryState?.length > 1
                            ? () => handleDeleteEnquiryVendore(data.supplierId)
                            : undefined
                        }
                        sx={{
                          margin: "4px",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  ))
                : enquiryState?.slice(0, 4)?.map((data) => (
                    <Tooltip title={data?.emails?.join(", ")}>
                      <Chip
                        onClick={() => handleSetSupllierData(data)}
                        label={data.businessName}
                        size="small"
                        variant="outlined"
                        onDelete={
                          enquiryState?.length > 1
                            ? () => handleDeleteEnquiryVendore(data.supplierId)
                            : undefined
                        }
                        sx={{
                          margin: "4px",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  ))}
  
              {/* {(enquiryState.length <= 4 || expandVendorList) && (
                <ClickAwayListener
                  onClickAway={() => handleClickAwayBusinessNameDropDown()}
                >
                  <Grid
                    sx={{ position: "relative" }}
                    onClick={handleClickSearchSuppliers}
                  >
                    <TextField
                      autoFocus
                      sx={{ ml: "11px", width: "237px" }}
                      variant="standard"
                      name="addVendor"
                      id="addVendor"
                      placeholder="Add Vendor"
                      value={businessNameKey}
                      onChange={(e) => handleBusinessNameKey(e.target.value)}
                    />
                    {businessNameKey !== "" && showDropDown && (
                      <BusinessNameDropDown
                        enquiryState={enquiryState}
                        setEnquiryState={setEnquiryState}
                        setBusinessNameKey={setBusinessNameKey}
                        existingRfqEditView={existingRfqEditView}
                      />
                    )}{" "}
                  </Grid>
                </ClickAwayListener>
              )}
              {enquiryState.length > 4 && !expandVendorList && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    margin: "4px",
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={handleShowMoreClick}
                >
                  Show more...
                </Typography>
              )} */}
            </Grid>
          </Grid>
        </ClickAwayListener>
        {/* <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          onClose={handleCloseEmailsBox}
          open={openSuplierEmailPopUp}
        >
          <DialogTitle>{selectedSupliersDetails?.businessName}</DialogTitle>
  
          <Grid
            sx={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }}
            onClick={handleCloseEmailsBox}
          >
            X
          </Grid>
          <Grid sx={{ padding: "10px" }}>
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap="8px"
            >
              <TextField
                size="small"
                autoFocus
                name="name"
                id="name"
                placeholder="requirement1@stresstech.com"
                sx={{ width: "100%" }}
                value={newEmailValue}
                onKeyDown={handleKeyDown}
                onChange={(e) => handleChangeNewEmailValue(e.target.value)}
              />
              <Box onClick={handleAddNewEmail} sx={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <rect
                    width="19"
                    height="19"
                    rx="4"
                    fill={newEmailValue?.length > 0 ? "#04EEAD" : "#707070"}
                  />
                  <path
                    d="M4 9.5C4.29333 9.5 11.4556 9.5 15 9.5M9.5 4V15"
                    stroke={newEmailValue?.length > 0 ? "black" : "white"}
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
            </Grid>
            {emailError && (
              <Typography sx={{ fontSize: "12px", color: "red" }}>
                {emailErrorMsg}{" "}
              </Typography>
            )}
            {selectedSupliersDetails?.emails?.map((email, index) => (
              <Grid key={index} sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={editEmailsData.includes(email)}
                  onChange={() => handleCheckboxChange(email)}
                />
                <Typography>{email}</Typography>
              </Grid>
            ))}
            <Grid width="100%" display={"flex"} justifyContent={"center"}>
              <Button
                sx={{
                  // margin: "auto",
                  background: "#3963AB",
                  color: "#fff",
                  height: "36px",
                  marginTop: "12px",
                  "&:hover": {
                    background: "#314c7a",
                  },
                }}
                onClick={handleUpdateSuplliersEmail}
              >
                Add Selected Emails
              </Button>
            </Grid>
            {addEmailError && (
              <Typography sx={{ fontSize: "12px", color: "red" }}>
                Atleast one email is required
              </Typography>
            )}
          </Grid>
        </Dialog> */}
      </>
    );
  };
  
  export default VendorListSectionForNonEntity;
  