import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import SupplierUtils from "./SupplierUtils";
import { Box, Grid, Paper, Popper, Typography } from "@mui/material";
import PropTypes, { object } from "prop-types";

const SuppliersDataView = ({
  newRequirements,
  openRfqId,
  handleResendRequirement,
}) => {
  const supplierData = newRequirements.filter(
    (data) => data?.rfqId === openRfqId
  );
  const handleRightEmail = (supplier) => {
    {
      if (
        supplier.noteDescriptions &&
        supplier.noteDescriptions.length > 0 &&
        supplier.noteDescriptions[supplier.noteDescriptions.length - 1]
          .emails &&
        supplier.noteDescriptions[supplier.noteDescriptions.length - 1].emails
          .length > 0
      ) {
        return supplier.noteDescriptions[
          supplier.noteDescriptions.length - 1
        ].emails.join(", ");
      } else if (supplier.emails && supplier.emails.length > 0) {
        return supplier.emails.join(", ");
      } else {
        return supplier.email;
      }
    }
  };
  const suppliersData = supplierData?.[0]?.suppliers;
  const suppliers = suppliersData?.map((supplier, index) => {
    const { suppliers, ...rest } = supplierData[0];
    return {
      ...rest,
      ...supplier,
      id: index + 1,
      status: supplier.status == "ACKNOWLEDGED" ? "Read" : "Delivered",
      // email: supplier?.emails ? supplier?.emails.join(", ") : supplier?.email,
      // email:
      //   supplier?.emails.length > 0
      //     ? supplier?.emails.join(", ")
      //     : supplier?.email,
      vendorEmail: handleRightEmail(supplier),
    };
  });
  // console.log("this is a  ,", suppliers);
  return (
    <Grid sx={{ height: "300px" }}>
      <DataGrid
        rows={suppliers}
        columns={SupplierUtils(handleResendRequirement)}
        rowHeight={32}
        disableSelectionOnClick
        disableColumnMenu
      />
    </Grid>
  );
};

export default SuppliersDataView;
