import { searchRfqWithDate } from "actions/rfqActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterSection, RfqsTableSection } from "views/Rfqs/Components";

const RfqsTabView = ({ projectId, steeperName }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const rfqsData = useSelector((state) => state.rfqs);
  const user = auth?.user;

  const [workspaceView, setWorkspaceView] = useState("My Workspace");
  const [teamMemberData, setTeamMemberData] = useState([user?.userId]);
  const [rfqFilterStatus, setRfqFilterStatus] = useState("");

  const entityId = user?.userEntity?.entityId;
  const teamUserIds = user?.teamMembers?.map((member) => member.userId);

  const fetchAllRfqs = () => {
    setWorkspaceView("Team Workspace");
    dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds: [...teamUserIds, user?.userId],
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData([...teamUserIds, user?.userId]);
  };

  const fetchPersonalRfqs = () => {
    setWorkspaceView("My Workspace");
    dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds: [user?.userId],
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData([user?.userId]);
  };

  const handleChangeTeamMemberNew = (selectedUserId) => {
    dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds:
          selectedUserId.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserId,
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData(selectedUserId);
  };

  const handleApplyFilterDate = ({ startDate, endDate }) => {
    // dispatch(FilterRfqByDate(payload));
    dispatch(
      searchRfqWithDate({
        entityId,
        searchParam: rfqsData?.searchParam,
        status: rfqFilterStatus,
        userIds: teamMemberData.map((number) => String(number)),
        projectId,
        startDate,
        endDate,
      })
    );
  };

  useEffect(() => {
    if (steeperName === "rfqs") {
      if (user?.teamMembers?.length > 0) {
        fetchAllRfqs();
      } else {
        fetchPersonalRfqs();
      }
    }
  }, [steeperName]);

  return (
    <div className="p-1 rounded-lg h-[80vh] bg-gray-100">
      <div className="flex gap-4 max-h-[calc(80vh)]">
        <div className="min-w-[200px] bg-white">
          <FilterSection
            handleChangeTeamMemberNew={handleChangeTeamMemberNew}
            workspaceView={workspaceView}
            rfqsData={rfqsData}
            handleApplyFilterDate={handleApplyFilterDate}
            setRfqFilterStatus={setRfqFilterStatus}
            user={user}
            projectId={projectId}
          />
        </div>
        <div className="flex-grow bg-white rounded overflow-y-auto">
          <RfqsTableSection
            workspaceView={workspaceView}
            entityId={entityId}
            userIds={teamMemberData}
            rfqsData={rfqsData}
            rfqFilterStatus={rfqFilterStatus}
            user={user}
            projectView={true}
            projectId={projectId}
          />
        </div>
      </div>
    </div>
  );
};

export default RfqsTabView;
