import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Typography,
  Card,
  Grid,
  Box,
  IconButton,
  Button,
  CardHeader,
  Divider,
  CardContent,
  FormHelperText,
} from "@mui/material";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import { saveSocialUrls } from "actions/businessActions";

const SocialIconUrl = ({
  supplierId,
  socialLinks,
  handleSubmit,
  handleCancel,
  onSubmitSuccess,
  handleSubmitSuccess,
  ...rest
}) => {
  const dispatch = useDispatch();

  useEffect(() => {}, [supplierId]);

  // console.log("SocialIconUrl() supplierId: ", supplierId, socialLinks);

  return (
    <div>
      <Formik
        initialValues={{
          facebook: socialLinks ? socialLinks.facebookUrl : "",
          twitter: socialLinks ? socialLinks.twitterUrl : "",
          instagram: socialLinks ? socialLinks.instagramUrl : "",
          youtube: socialLinks ? socialLinks.youtubeUrl : "",
        }}
        validationSchema={Yup.object().shape({
          facebook: Yup.string().url("Must be a valid URL Link"),
          twitter: Yup.string().url("Must be a valid URL Link"),
          instagram: Yup.string().url("Must be a valid URL Link"),
          youtube: Yup.string().url("Must be a valid URL Link"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await dispatch(saveSocialUrls(supplierId, values));
            // onSubmitSuccess(socialProfile);
            handleSubmitSuccess();
          } catch (error) {
            // console.log("Form: error", error);
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            // className={clsx(classes.root, className)}
            {...rest}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sx={{ display: "flex" }}
                      >
                        <img
                          src="/assets/icons/facebook.svg"
                          style={{ marginRight: "10px" }}
                        />
                        <TextField
                          size="small"
                          error={Boolean(touched.facebook && errors.facebook)}
                          fullWidth
                          helperText={touched.facebook && errors.facebook}
                          label="facebook URL"
                          name="facebook"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.facebook}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sx={{ display: "flex" }}
                      >
                        <img
                          src="/assets/icons/twitter.svg"
                          style={{ marginRight: "10px" }}
                        />
                        <TextField
                          size="small"
                          error={Boolean(touched.twitter && errors.twitter)}
                          fullWidth
                          helperText={touched.twitter && errors.twitter}
                          label="Twitter URL"
                          name="twitter"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.twitter}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sx={{ display: "flex" }}
                      >
                        <img
                          src="/assets/icons/instagram.svg"
                          style={{ marginRight: "10px" }}
                        />
                        <TextField
                          size="small"
                          error={Boolean(touched.instagram && errors.instagram)}
                          fullWidth
                          helperText={touched.instagram && errors.instagram}
                          label="Instagram URL"
                          name="instagram"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.instagram}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sx={{ display: "flex" }}
                      >
                        <img
                          src="/assets/icons/youtube.svg"
                          style={{ marginRight: "10px" }}
                        />
                        <TextField
                          size="small"
                          error={Boolean(touched.youtube && errors.youtube)}
                          fullWidth
                          helperText={touched.youtube && errors.youtube}
                          label="YouTube URL"
                          name="youtube"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.youtube}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Box mt={2}>
                        <Button
                          // color="primary"
                          sx={{
                            backgroundColor: "#FD6600",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FD6600",
                              transition:
                                "background-color 0.3s, box-shadow 0.3s",
                              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                            },
                          }}
                          variant="contained"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          // color="primary"
                          variant="contained"
                          sx={{
                            backgroundColor: "#FD6600",
                            ml: 1,
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FD6600",
                              transition:
                                "background-color 0.3s, box-shadow 0.3s",
                              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                            },
                          }}
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
SocialIconUrl.propTypes = {
  supplierId: PropTypes.string,
  socialLinks: PropTypes.object,
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  handleSubmitSuccess: PropTypes.func,
};
SocialIconUrl.default = {
  onSubmitSuccess: () => {},
};

export default SocialIconUrl;
