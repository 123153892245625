import { Edit } from "@mui/icons-material";
import { Chip, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

function Domains({
  profile,
  IsSearchPublicProfile,
  isMobileView = false,
  isEditView,
  permissions,
  PublicProfile,
}) {
  const domains = profile?.businessProfile?.domains;
  
  return (
    <Grid
      sx={{
        marginX: isMobileView ? "10px" : "46px",
        paddingX: IsSearchPublicProfile ? "26px" : "",
      }}
    >
      {/* <Grid style={{ display: "flex" }}> */}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            marginY: "10px",
            marginRight: "10px",
          }}
        >
          Domains
        </Typography>
        {isEditView && (
          <div>
            <Link to="/edit-business" style={{ textDecoration: "underline", color: "#2864BE",marginTop:"10px" }}>
            Click Here  

          </Link>
          <span style={{ color: "black" }}> to Edit Domain</span>
          </div>
        )}
      {/* </Grid> */}
      <Grid sx={{ marginY: "16px" }}>
        {domains?.map((label, index) => (
          <Chip
            key={index}
            label={label}
            sx={{ marginRight: "8px", marginBottom: "8px" }}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default Domains;
