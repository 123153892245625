import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import { addService } from "actions/serviceActions";
import { Grid, TextField, Autocomplete, Button, Chip } from "@mui/material";

import { SERVICE_CATEGORIES, LOCATIONS, UOMS } from "config";

const ServiceForm = ({data, onSubmitSuccess}) => {
  const dispatch = useDispatch();
  const [tags, setTags] = React.useState([]);

  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;

  const usps = data?.usps ? data.usps : [];


  return (
    <Grid>
      <Formik
        initialValues={{
          id: data?._id,
          serviceName: data?.serviceName,
          // category: data?.category,
          usp1: (usps?.length > 0) ? usps[0] : "",
          usp2: (usps?.length > 1) ? usps[1] : "",
          usp3: (usps?.length > 2) ? usps[2] : "",
          usp4: (usps?.length > 3) ? usps[3] : "",
          usp5: (usps?.length > 4) ? usps[4] : "",
          usp6: (usps?.length > 5) ? usps[5] : "",
        }}
        validationSchema={Yup.object().shape({
          serviceName: Yup.string()
            .max(50)
            .required("Service Name is required"),
            usp1: Yup.string()
            .max(50).required(),
            usp2: Yup.string()
            .max(50),
            usp3: Yup.string()
            .max(50),
           

        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            values._id = data?._id;

            await dispatch(addService(supplierId, values));
            onSubmitSuccess();
          } catch (error) {
            // console.log("Form: error", error);

            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
        }) => {
          return (
            <Grid >
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} >
                    <TextField
                      name="serviceName"
                      id="serviceName"
                      label="Service Name"
                      // disabled={data?true:false}
                      fullWidth
                      required
                      value={values.serviceName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.serviceName && errors.serviceName)}
                      helperText={touched.serviceName && errors.serviceName}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={values.category}
                      onChange={(event, newValue) => {
                        setFieldValue("category", newValue);
                      }}
                      disablePortal
                      id="category"
                      required
                      options={SERVICE_CATEGORIES}
                      fullWidth
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Category"
                          required
                          error={Boolean(touched.category && errors.category)}
                          helperText={touched.category && errors.category}
                        />
                      )}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={12}>
                    <TextField
                      name="description"
                      id="description"
                      label="Description"
                      variant="outlined"
                      fullWidth
                      required
                      multiline
                      rows={4}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 1000 }}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid> */}

              {/*}    <Grid item xs={12} md={12}>
                    <Autocomplete
                      style={{ margin: "10px 0" }}
                      value={values.serviceDetails}
                      multiple
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "serviceDetails",
                          newValue.map((option) => option.value || option)
                        );
                      }}
                      id="serviceDetails"
                      options={serviceList}
                      // defaultValue={[]}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Services Offered"
                          placeholder="Services Offered"
                          required
                          error={Boolean(
                            touched.serviceDetails && errors.serviceDetails
                          )}
                          helperText={
                            touched.serviceDetails && errors.serviceDetails
                          }
                          value={tags}
                          onChange={(e) =>
                            setTags([...tags, e.target.value.split(",")])
                          }
                        />
                      )}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} md={12}>
                    <Autocomplete
                      multiple
                      value={values.locations}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "locations",
                          newValue.map((option) => option.value || option)
                        );
                      }}
                      disablePortal
                      id="locations"
                      options={LOCATIONS}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City/Regions Serviced"
                          placeholder="Select Locations"
                          required
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="qty"
                      id="qty"
                      label="Minimum Serviced Quantity"
                      fullWidth
                      type="number"
                      value={values.qty}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.qty && errors.qty)}
                      helperText={touched.qty && errors.qty}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      fullWidth
                      value={values.uom}
                      onChange={(event, newValue) => {
                        setFieldValue("uom", newValue);
                      }}
                      disablePortal
                      id="uom"
                      options={UOMS}
                      required
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Unit of Measure" />
                        </>
                      )}
                    />
                  </Grid> */}


                  <Grid item xs={12} md={12}>
                    <TextField
                      name="usp1"
                      id="usp1"
                      label="USP 1"
                      fullWidth
                      required
                      value={values.usp1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.usp1 && errors.usp1)}
                      helperText={touched.usp1 && errors.usp1}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      name="usp2"
                      id="usp2"
                      label="USP 2"
                      fullWidth
                      required
                      value={values.usp2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.usp2 && errors.usp2)}
                      helperText={touched.usp2 && errors.usp2}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="usp3"
                      id="usp3"
                      label="USP 3"
                      fullWidth
                      required
                      value={values.usp3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.usp3 && errors.usp3)}
                      helperText={touched.usp3 && errors.usp3}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <TextField
                      name="usp4"
                      id="usp4"
                      label="USP 4"
                      fullWidth
                      required
                      value={values.usp4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.usp4 && errors.usp4)}
                      helperText={touched.usp4 && errors.usp4}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="usp5"
                      id="usp5"
                      label="USP 5"
                      fullWidth
                      required
                      value={values.usp5}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.usp5 && errors.usp5)}
                      helperText={touched.usp5 && errors.usp5}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="usp6"
                      id="usp6"
                      label="USP 6"
                      fullWidth
                      required
                      value={values.usp6}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.usp6 && errors.usp6)}
                      helperText={touched.usp6 && errors.usp6}
                    />
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      // sx={{ mr: 1 }}
                      sx={{backgroundColor: "#757575",
                      mr: 1,
                      color:"white",
                      "&:hover": {
                        backgroundColor: "#757575",
                        transition: "background-color 0.3s, box-shadow 0.3s",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                      }
                      }}
                      variant="contained"
                      href="/my-business"
                      color="secondary"
                    >
                      Cancel
                    </Button>{" "}
                    <Button variant="contained" color="secondary" onClick={handleSubmit}
                      sx={{backgroundColor: "black",
                      color:"white",
                      "&:hover": {
                        backgroundColor: "black",
                        transition: "background-color 0.3s, box-shadow 0.3s",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                      }
                      }}>
                      Save Service
                      {/* {value.isUpdate ? "Save Service" : "Save Service"} */}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          );
        }}
      </Formik>
    </Grid>
  );
};

ServiceForm.propTypes = {
  data: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
};

export default ServiceForm;
