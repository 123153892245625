import React, { useState, useRef, useEffect } from "react";

const CustomSelect = ({
  options,
  placeholder,
  selectedOption,
  onChange,
  selectedId,
  onAddNew,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedOption || null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);

  const handleOptionChange = (option) => {
    setSelected(option);
    setSearchTerm(""); // Clear the search term after selection
    onChange(option);
    setIsOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const filteredOptions = options.filter((option) =>
    option.label?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  const handleFocus = () => {
    setIsFocused(true);
    setIsOpen(true);
  };

  const handleBlur = () => {
    if (!searchTerm && !selected) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (selectedOption && selectedOption.value !== selected?.value) {
      setSelected(selectedOption);
    }
  }, [selectedOption]);
  

  return (
    <div className="relative" ref={ref}>
      <div className="flex justify-between items-center border  border-gray-300 px-3 min-h-[50px] cursor-pointer">
        <input
          type="text"
          // placeholder={isFocused || selected ? "" : placeholder}
          value={searchTerm || (selected && selected?.label ? selected?.label : selected)} // Show selected label when no search term
          onChange={handleSearchChange}
          className="p-1 mt-2  w-full border-none focus:outline-none focus:ring-0 text-sm placeholder:text-sm"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          className="w-8 h-8 border rounded-full flex items-center justify-center"
        >
          <img
            src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
            alt="arrow"
            className={`transition-transform duration-200 ease-in-out ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </button>
      </div>

      {/* Label as placeholder */}
      <label
        className={`absolute text-sm left-4 text-gray-500 transition-all duration-200 pointer-events-none ${
          isFocused || selected
            ? "text-xs transform -translate-y-[48px]"
            : "text-base top-1/2 transform -translate-y-1/2"
        }`}
        htmlFor="projectName"
      >
        {placeholder}
      </label>

      {isOpen && (
        <div className="absolute left-0 w-full mt-1 bg-white border  border-gray-300 shadow-md z-10 max-h-80 overflow-auto py-2">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`py-1 px-3 cursor-pointer text-sm hover:bg-gray-100 ${
                  option.value === selectedId ? "bg-gray-200" : ""
                }`}
                onClick={() => handleOptionChange(option)}
                role="button"
                tabIndex={0}
              >
                <h3 className="font-medium">{option.label}</h3>
                <p className="text-sm text-gray-500">{option.description}</p>
              </div>
            ))
          ) : (
            <div className="p-4 text-gray-500 text-sm">
              No options found{" "}
              {onAddNew && (
                <span
                className="text-sm"
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    fontStyle: "italic",
                    cursor: "pointer",
                  }}
                  onClick={onAddNew}
                >
                  Click to add new project
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
