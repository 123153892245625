import {
  ADD_TAGS_TO_COMPANY_DIR_VENDOR,
  ADD_VENDORS_TO_CD_FROM_N2,
  FILTER_COMPANY_DIR,
  REMOVE_TAGS_TO_COMPANY_DIR_VENDOR,
  SEARCH_COMPANY_DIR_VENDOR,
} from "config/services";
import axios from "utils/axios";

class CompanyDirectoryService {
  searchVendor = (searchParam, pageNum, entityId) =>
    new Promise((resolve, reject) => {
      const page = pageNum || 1;
      axios
        .get(
          `${SEARCH_COMPANY_DIR_VENDOR}?entityId=${entityId}&searchParam=${searchParam}&page=${page}`
        )
        .then((response) => {
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  addVendorsFromNeevayNetworkWithTags = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post(ADD_VENDORS_TO_CD_FROM_N2, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  filterVendor = ({
    pageNum = 1,
    filterData = {},
    searchKey = "",
    userId,
    entityId,
  }) =>
    new Promise((resolve, reject) => {
      const page = pageNum || 1;
      axios
        .post(`${FILTER_COMPANY_DIR}?entityId=${entityId}&page=${page}`, {
          filters: filterData,
          searchParam: searchKey,
          userId: userId,
        })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  addTags = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .patch(ADD_TAGS_TO_COMPANY_DIR_VENDOR, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  removeTags = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post(REMOVE_TAGS_TO_COMPANY_DIR_VENDOR, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const companyDirectoryService = new CompanyDirectoryService();
export default companyDirectoryService;
