import React, { useEffect, useRef, useState } from "react";
import { Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Box,
  FormHelperText,
  Button,
  Autocomplete,
  Typography,
  MenuItem,
  Select,
  FormControl,
  TextareaAutosize,
  Chip,
  Paper,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import * as Yup from "yup";
import { IMAGE_TYPE } from "config";

import { Link } from "react-router-dom";
import { addService } from "actions/serviceActions";
import { addProject, deleteProject, editProjectTrigger, updateProject } from "actions/projectActions";
import { updateStepperCount } from "actions/businessActions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';



function AddProjectsForm({ setActiveStep, setFetchDataEnabled, ...rest }) {
  const dispatch = useDispatch();
  ///////////////////////////////////////////////

  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);

  const handleAccordionChange = (index) => {
    // Toggle the accordion by setting the index to null if it's already selected
    setShowForm(false);
    setSelectedProjectIndex((prevIndex) =>
      prevIndex === index ? null : index
    );

    formik.setValues({
      projectName: projects[index]?.projectName || "",
      location: projects[index]?.location || "",
      clientName: projects[index]?.clientName || "",
      projectSize: projects[index]?.projectSize?.qty || "",
      uom: projects[index]?.projectSize?.uom || "",
      description: projects[index]?.description || "",
    });
  };

  //////////////////////////////////////////////////////

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [showForm, setShowForm] = useState(true);

  const business = useSelector((state) => state.business);

  const projects = business?.projects;

  // console.log("*******", projects);
  const supplierId = business.profile?.supplierId;

  const stepperCountValue = 4;

  const initialValues = {
    projectName: "",
    location: "",
    clientName: "",
    projectSize: "",
    uom: "",
    description: "",
  };

  const validationSchema = Yup.object({
    projectName: Yup.string()
      .required("Project Name is required")
      .matches(/[^0-9]/, "Please enter valid project name")
      .max(50, "Project Name cannot exceed 50 characters"),
    location: Yup.string().required("Project Location is required"),
    clientName: Yup.string().required("Client Name is required"),
    projectSize: Yup.string()
    .matches(/^[0-9]+$/, 'Project Size must be a valid number')
    .required('Project Size is required'),
    uom: Yup.string().required("Unit is required"),
    description: Yup.string().required("Description is required"),
  });

  const onSubmit = (values) => {
    console.log(values);

    dispatch(addProject(supplierId, values));
    setShowForm(false);
  };

  function handleNext() {
    dispatch(updateStepperCount(supplierId, stepperCountValue));
  }

  const clearAllFields = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

 

  const handleEditProject = async (projectData) => {
    dispatch(updateProject(supplierId,projectData));
    await setSelectedProjectIndex(null);

  };

  const handleDeleteProject = async (projectName, projectId) => {
    dispatch(deleteProject(supplierId, projectName, projectId));
    await setSelectedProjectIndex(null)

  };

  return (
    <Grid sx={{ maxWidth: "100vw" }}>
      <Grid container flexDirection="column" gap={2}>
        {/* Saved Services........... */}
        <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {projects?.map((project, index) => (
            // <Grid
            //   key={index}
            //   style={{
            //     width: "100%",
            //     height: "44px",
            //     borderRadius: "10px",
            //     border: "1px solid #B9B9C3",
            //     display: "flex",
            //     alignItems: "center",
            //     paddingLeft: "25px",
            //     display: "flex",
            //     flexDirection: "row",
            //     justifyContent: "space-between",
            //   }}
            // >
            //   <Typography
            //     style={{
            //       color: "#000",
            //       fontFamily: "Roboto",
            //       fontSize: "16px",
            //       fontWeight: "400",
            //     }}
            //   >
            //     {project.projectName}
            //   </Typography>
            //   <Box
            //     component="img"
            //     sx={{
            //       height: "25px",
            //       width: "25px",
            //       marginRight: "20px",
            //     }}
            //     alt="green check"
            //     src={`${BASE_URL}/assets/business/web-servicepage-GreenCheck.svg`}
            //   />
            // </Grid>
            <Accordion
             key={index}
             expanded={index === selectedProjectIndex}
             onChange={() => handleAccordionChange(index)}
           >
             <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls={`panel-${index}-content`}
               id={`panel-${index}-header`}
             >
               <Typography>{project.projectName}</Typography>
               <CheckCircleOutlineIcon style={{marginLeft:"10px", color:"#04EEAD"}}/>

             </AccordionSummary>
             <AccordionDetails>
             <Grid
            style={{
              height: "auto",
              borderRadius: "10px",
              width: "100%",
              border: "1px solid #B9B9C3",
              padding: "6px 15px",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography
                  style={{
                    color: "#5E5873",
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "28.8px",
                    letterSpacing: "0.14px",
                  }}
                >
                  Project {selectedProjectIndex?selectedProjectIndex + 1:  1}
                </Typography>
                
              </Grid>
              <Grid>
                <Typography
                  style={{
                    color: "black",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: "400",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={()=>handleDeleteProject(project.projectName,project._id)}

                >
                  DELETE
                </Typography>
              </Grid>
            </Grid>
            <Grid>
            <form onSubmit={formik.handleSubmit}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "8px",
                  }}
                >
                  {/* Formik TextFields */}
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      type="text"
                      name="projectName"
                      placeholder="Project Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.projectName}
                    />
                  </Grid>
                  {formik.touched.projectName && formik.errors.projectName ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "-6px",
                        marginBottom: "-6px",
                      }}
                    >
                      {formik.errors.projectName}
                    </div>
                  ) : null}

                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid xs={5.8}>
                      {" "}
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        name="location"
                        placeholder="Location"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.location}
                      />
                    </Grid>
                    <Grid xs={5.8}>
                      <TextField
                        type="text"
                        name="clientName"
                        size="small"
                        fullWidth
                        placeholder="Client Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.clientName}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid xs={5.8}>
                      <TextField
                        type="text"
                        name="projectSize"
                        size="small"
                        fullWidth
                        placeholder="Size Of Project"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.projectSize}
                      />
                    </Grid>
                    <Grid xs={5.8}>
                      <Autocomplete
                        size="small"
                        id="demo-simple-select"
                        clearIcon={null}
                        options={[
                          "Sq. Feet (SFT)",
                          "Running Meter (RMT)",
                          "Sq. Meter (SQM)",
                          "Numbers (Qty)",
                          "Cubic Meters (CUM)",
                          "Kilograms (Kgs)",
                          "Metric Ton (M.TON)",
                          "Litres",
                          "Bags",
                          "Cubic Foot (CFT)",
                        ]}
                        getOptionLabel={(option) => option}
                        value={formik.values.uom}
                        onChange={(event, newValue) => {
                          formik.handleChange({
                            target: { name: "uom", value: newValue },
                          });
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Unit" />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <TextField
                    name="description"
                    id="description"
                    type="description"
                    value={formik.values.description}
                    placeholder="Description"
                    variant="outlined"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    inputProps={{
                      style: { height: "130px" },
                    }}
                    multiline
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "15px",
                    marginTop: "10px",
                    marginBottom: "6px",
                  }}
                >
                  <Button
                    variant="conatined"
                    style={{
                      borderRadius: "3px",
                      border: " 1px solid #989898",
                      height: "27px",
                      background: "#FFF",
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                    onClick={() => setShowForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="conatined"
                    style={{
                      borderRadius: "3px",
                      border: " 1px solid #989898",
                      height: "27px",
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                    onClick={()=>handleEditProject(formik.values)}

                  >
                    Update
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
          </AccordionDetails>
          </Accordion>
          ))}
        </Grid>
        {/* Add Project form ........................ */}
        {showForm && (
          <Grid
            style={{
              height: "auto",
              borderRadius: "10px",
              width: "100%",
              border: "1px solid #B9B9C3",
              padding: "6px 15px",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography
                  style={{
                    color: "#5E5873",
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "28.8px",
                    letterSpacing: "0.14px",
                  }}
                >
                  Project {projects.length + 1}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    color: "black",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: "400",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={clearAllFields} // Connect the clearAllFields function to the "Clear All" button
                >
                  Clear all
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <form onSubmit={formik.handleSubmit}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "8px",
                  }}
                >
                  {/* Formik TextFields */}
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      type="text"
                      name="projectName"
                      placeholder="Project Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.projectName}
                    />
                  </Grid>
                  {formik.touched.projectName && formik.errors.projectName ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "-6px",
                        marginBottom: "-6px",
                      }}
                    >
                      {formik.errors.projectName}
                    </div>
                  ) : null}

                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid xs={5.8}>
                      {" "}
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        name="location"
                        placeholder="Location"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.location}
                      />
                      {formik.touched.location && formik.errors.location ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop:"6px"
                      }}
                    >
                      {formik.errors.location}
                    </div>
                  ) : null}
                    </Grid>
                    
                    <Grid xs={5.8}>
                      <TextField
                        type="text"
                        name="clientName"
                        size="small"
                        fullWidth
                        placeholder="Client Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.clientName}
                      />
                      {formik.touched.clientName && formik.errors.clientName ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "6px",
                      }}
                    >
                      {formik.errors.clientName}
                    </div>
                  ) : null}
                    </Grid>
                    
                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid xs={5.8}>
                      <TextField
                        type="text"
                        name="projectSize"
                        size="small"
                        fullWidth
                        placeholder="Size Of Project"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.projectSize}
                      />
                      {formik.touched.projectSize && formik.errors.projectSize ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "6px",
                      }}
                    >
                      {formik.errors.projectSize}
                    </div>
                  ) : null}
                    </Grid>
                    
                    <Grid xs={5.8}>
                      <Autocomplete
                        size="small"
                        id="demo-simple-select"
                        clearIcon={null}
                        options={[
                          "Sq. Feet (SFT)",
                          "Running Meter (RMT)",
                          "Sq. Meter (SQM)",
                          "Numbers (Qty)",
                          "Cubic Meters (CUM)",
                          "Kilograms (Kgs)",
                          "Metric Ton (M.TON)",
                          "Litres",
                          "Bags",
                          "Cubic Foot (CFT)",
                        ]}
                        getOptionLabel={(option) => option}
                        value={formik.values.uom}
                        onChange={(event, newValue) => {
                          formik.handleChange({
                            target: { name: "uom", value: newValue },
                          });
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Unit" />
                        )}
                      />
                      {formik.touched.uom && formik.errors.uom ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "6px",
                      }}
                    >
                      {formik.errors.uom}
                    </div>
                  ) : null}
                    </Grid>
                  </Grid>

                  <TextField
                    name="description"
                    id="description"
                    type="description"
                    value={formik.values.description}
                    placeholder="Description"
                    variant="outlined"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    inputProps={{
                      style: { height: "130px" },
                    }}
                    multiline
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "-6px",
                      }}
                    >
                      {formik.errors.description}
                    </div>
                  ) : null}
                </Grid>
                
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "15px",
                    marginTop: "10px",
                    marginBottom: "6px",
                  }}
                >
                  <Button
                    variant="conatined"
                    style={{
                      borderRadius: "3px",
                      border: " 1px solid #989898",
                      height: "27px",
                      background: "#FFF",
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                    onClick={() => setShowForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="conatined"
                    type="submit"
                    style={{
                      borderRadius: "3px",
                      border: " 1px solid #989898",
                      height: "27px",
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        )}

        {/* Add Another Project Button .......... */}
        {showForm ? null : (
          <Grid>
            <Grid
              style={{
                width: "175px",
                height: "25px",
                borderRadius: "8px",
                border: "1px solid #767676",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedProjectIndex(null);
                clearAllFields();
                setShowForm(true);
              }}
            >
              <Typography
                style={{
                  color: "black",

                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {" "}
                + Add another project
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* Next Button ................  */}
        <Grid
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleNext}
            variant="contained"
            // color="secondary"
            sx={{
              width: "76px",
              height: "25px",
              fontFamily: "Roboto",
              borderRadius: "5px",
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: !projects?.length || showForm?"rgba(0, 0, 0, 0.26)":"black",
              color: !projects?.length || showForm?"rgba(0, 0, 0, 0.26)":"white",
              "&:hover": {
                backgroundColor: "black", // Set the same background color on hover
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                transition: "background-color 0.3s, box-shadow 0.3s",
              },
            }}
            disabled={!projects?.length || showForm}

          >
            Next
          </Button>
        </Grid>
        <Typography
          onClick={() => {
            setFetchDataEnabled(false);
            setActiveStep(4);
          }}
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            cursor: "pointer",
            textAlign: "center",
            textDecoration: "underline",
            color: "#5F5959",
            width: "80px",
            margin: "0 auto",
          }}
        >
          Skip for Now
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AddProjectsForm;
