import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


const Negotiation = () => {

    const location = useLocation();
    const rfqData = location.state?.rfqData;
    const { rfqType, suppliers, otherCharges } = rfqData || {};
    

    const [editedFields, setEditedFields] = useState({});
    const [sortedSuppliers, setSortedSuppliers] = useState(
        suppliers?.filter(supplier => supplier?.quotationReceived).sort((a, b) => {
            const rankA = a.vendorQuotations?.rank;
            const rankB = b.vendorQuotations?.rank;
            const numA = parseInt(rankA?.substring(1) || "0");
            const numB = parseInt(rankB?.substring(1) || "0");
            return numA - numB;
        })
    );

    console.log("sortedSuppliers", sortedSuppliers)
    const getExtraCharges = () => {
        return Object.keys(otherCharges || {}).filter(charge => otherCharges[charge]);
    };
    const extraChargesKeys = getExtraCharges();
    const lineItems = rfqType === "material" ? rfqData?.materials || [] :
        rfqType === "labour" ? rfqData?.labourDetails || [] :
            rfqType === "contractor" ? [{ name: ReactHtmlParser(rfqData.providingFixingDetails) }] : [];

    const getVendorQuotationData = (supplier, index, values, vendorIdx) => {
        const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
        let vendorItem = {};

        if (rfqType === "material") {
            vendorItem = vendorQuotation?.materialDetails?.[index] || {};
        } else if (rfqType === "labour") {
            vendorItem = vendorQuotation?.labourItems?.[index] || {};
        } else if (rfqType === "contractor") {
            vendorItem = vendorQuotation?.contractorDetails || {};
        }

        // const fallbackSubTotal = calculateSubtotal(
        //     values?.vendors?.[vendorIdx]?.lineItems || [],
        //     rfqType
        // );

        return {
            ...vendorItem,
            subTotal: vendorQuotation?.totalWithGst,
            grandTotal: supplier.vendorQuotations?.grandTotalWithGst,
            otherCharges: vendorQuotation?.otherCharges || {}
        };
    };

    const calculateTotal = (basePrice, discount, gst, quantity) => {
        const discountedPrice = basePrice - (basePrice * (discount || 0) / 100);
        const priceWithGst = discountedPrice + (discountedPrice * (gst || 0) / 100);
        return quantity ? priceWithGst * quantity : priceWithGst;
    };

    const calculateSubtotal = (lineItems, rfqType) => {
        return lineItems.reduce((subtotal, item) => {
            let itemTotal = 0;
            if (rfqType === "material") {
                itemTotal = calculateTotal(item.basePrice, item.discount, item.gst, item.quantity);
            } else if (rfqType === "labour") {
                itemTotal = calculateTotal(item.labourRate, 0, item.gst, item.quantity);
            } else if (rfqType === "contractor") {
                itemTotal = calculateTotal(item.basePrice, 0, item.gst, 1);
            }
            return subtotal + itemTotal;
        }, 0);
    };

    // Helper function to calculate total charges from otherCharges
    const calculateTotalCharges = (otherCharges) => {
        return Object.values(otherCharges).reduce((total, charge) => total + (charge.totalCost || 0), 0);
    };


    const handleFieldChange = (vendorIdx, index, field, value, values, setFieldValue) => {
        // Use a shallow copy of the existing line items
        const updatedLineItems = [...values.vendors[vendorIdx].lineItems];

        // Update the specific field value
        setFieldValue(`vendors[${vendorIdx}].lineItems[${index}].${field}`, value);

        const vendorId = values.vendors[vendorIdx]?.vendorId;
        setEditedFields((prev) => ({
            ...prev,
            [`${vendorId}-${index}-${field}`]: true,
        }));

        if (['basePrice', 'discount', 'gst', 'quantity', 'labourRate'].includes(field)) {
            const item = updatedLineItems[index];

            // Calculate new values based on the changed field
            const basePrice = field === 'basePrice' ? parseFloat(value) || 0 : item.basePrice || 0;
            const discount = field === 'discount' ? parseFloat(value) || 0 : item.discount || 0;
            const gst = field === 'gst' ? parseFloat(value) || 0 : item.gst || 0;
            const quantity = field === 'quantity' ? parseFloat(value) || 1 : item.quantity || 1;
            const labourRate = field === 'labourRate' ? parseFloat(value) || 0 : item.labourRate || 0;

            let netPrice;
            if (rfqType === "material") {
                netPrice = calculateTotal(basePrice, discount, gst, quantity);
            } else if (rfqType === "labour") {
                netPrice = calculateTotal(labourRate, 0, gst, quantity);
            } else if (rfqType === "contractor") {
                netPrice = calculateTotal(basePrice, 0, gst, 1);
            }

            // Set the updated net price
            setFieldValue(`vendors[${vendorIdx}].lineItems[${index}].netPrice`, netPrice);

            // Recalculate the subtotal for this vendor
            const subtotal = calculateSubtotal(updatedLineItems, rfqType);
            // const grandTotal = subtotal + calculateTotalCharges(values?.vendors[vendorIdx]?.otherCharges);

            // Update the vendors array with the new grandTotal
            setFieldValue(`vendors[${vendorIdx}].subTotal`, subtotal);
            const otherCharges = values.vendors[vendorIdx]?.otherCharges || {};

            const updatedGrandTotal = subtotal + calculateTotalCharges(otherCharges);
            setFieldValue(`vendors[${vendorIdx}].grandTotal`, updatedGrandTotal);
        }

        console.log("AFTEREDIT", values);
    }

    const handleOtherChargesChange = (vendorIdx, field, value, values, setFieldValue, chargeKey) => {
        console.log("**** Other Charges Change:", vendorIdx, field, value, values);

        const updatedExtraCharges = { ...values.vendors[vendorIdx].otherCharges };

        // Update the specific field in the otherCharges object for the vendor
        const updatedCharge = {
            ...updatedExtraCharges[chargeKey],
            [field]: parseFloat(value) || 0,
        };

        // Recalculate the totalCost using the updated gst and mrp
        const gst = parseFloat(updatedCharge.gst || 0);
        const mrp = parseFloat(updatedCharge.mrp || 0);
        const totalCost = mrp + (mrp * gst / 100);

        // Update the total cost for the extra charge
        updatedCharge.totalCost = totalCost;

        // Update the otherCharges with the updated charge data
        updatedExtraCharges[chargeKey] = updatedCharge;
        const updatedSubTotal = values.vendors[vendorIdx]?.subTotal || 0;
        const updatedGrandTotal = updatedSubTotal + calculateTotalCharges(updatedExtraCharges);

        // Update the vendors array with the updated otherCharges
        const updatedVendors = [...values.vendors];
        updatedVendors[vendorIdx] = {
            ...updatedVendors[vendorIdx],
            otherCharges: updatedExtraCharges,
            grandTotal: updatedGrandTotal, // Recalculate the grand total
        };

        // Set the updated vendors object in Formik state
        setFieldValue('vendors', updatedVendors);
    };



    const initialValues = useMemo(() => {
        const values = { vendors: [] };

        sortedSuppliers?.forEach((supplier) => {
            const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];

            const vendorItems = lineItems.map((item, index) => {
                const vendorItem = rfqType === "material"
                    ? vendorQuotation?.materialDetails?.[index] || {}
                    : rfqType === "labour"
                        ? vendorQuotation?.labourItems?.[index] || {}
                        : rfqType === "contractor"
                            ? vendorQuotation?.contractorDetails || {}
                            : {};

                if (rfqType === "material") {
                    return {
                        basePrice: vendorItem.basePrice || 0,
                        discount: vendorItem.discount || 0,
                        gst: vendorItem.gst || 0,
                        quantity: vendorItem.quantity || 1,
                        netPrice: calculateTotal(
                            vendorItem.basePrice || 0,
                            vendorItem.discount || 0,
                            vendorItem.gst || 0,
                            vendorItem.quantity || 1
                        ),
                    };
                } else if (rfqType === "labour") {
                    return {
                        labourRate: vendorItem.labourRate || 0,
                        quantity: vendorItem.quantity || 1,
                        gst: vendorItem.gst || 0,
                        netPrice: calculateTotal(
                            vendorItem.labourRate || 0,
                            0,
                            vendorItem.gst || 0,
                            vendorItem.quantity || 1
                        ),
                    };
                } else if (rfqType === "contractor") {
                    return {
                        basePrice: vendorItem.basePrice || 0,
                        gst: vendorItem.gst || 0,
                        workStartDate: vendorItem.workStartDate || null,
                        netPrice: calculateTotal(
                            vendorItem.basePrice || 0,
                            0,
                            vendorItem.gst || 0,
                            1
                        ),
                    };
                }
                return {};
            });

            const otherCharges = vendorQuotation?.otherCharges
            const initialSubTotal = vendorItems.reduce((acc, item) => acc + item.netPrice, 0);
            const vendorGrandTotal = supplier.vendorQuotations?.grandTotalWithGst

            values.vendors.push({
                vendorId: supplier.supplierId,
                lineItems: vendorItems, // Ensure this is a unique reference
                otherCharges: otherCharges,
                subTotal: initialSubTotal,
                grandTotal: vendorGrandTotal,
                businessName: supplier.businessName,
                rank: supplier.vendorQuotations?.rank
            });
        });

        return values;
    }, [sortedSuppliers, rfqType, lineItems, calculateTotal, calculateTotalCharges, extraChargesKeys]);

    const navigate = useNavigate();

    const handleRunAnalysis = (values, setFieldValue) => {
        // Step 1: Recalculate the grand totals for each vendor and set their new grand totals
        const updatedVendors = values.vendors.map((vendor, idx) => {
            const updatedLineItems = vendor.lineItems || [];
            const liveSubtotal = calculateSubtotal(updatedLineItems, rfqType);
            const otherCharges = vendor?.otherCharges || {};
            const liveGrandTotal = liveSubtotal + calculateTotalCharges(otherCharges);

            // Return updated vendor data with recalculated grand total
            return {
                ...vendor,
                grandTotal: liveGrandTotal,
                subTotal: liveSubtotal,

            };
        });

        // Step 2: Sort vendors based on grandTotal in ascending order
        const sortedVendors = updatedVendors.sort((a, b) => a.grandTotal - b.grandTotal);
        sortedVendors.forEach((vendor, idx) => {
            vendor.rank = `L${idx + 1}`;
        });
        // Step 4: Update the Formik vendors array with the sorted vendors
        setFieldValue('vendors', sortedVendors);

        // Optionally, log the sorted and ranked vendors to verify
        console.log("Sorted and ranked vendors:", sortedVendors);
    };


    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setFieldValue }) => {
                console.log("SUBMISSION VALLUES",values);
                handleRunAnalysis(values, setFieldValue);

                setEditedFields({});
            }}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className='sticky top-0 shadow-md flex justify-between bg-white w-full z-10 py-2 px-6'>
                        <button onClick={(() => navigate(-1))} type='button' className='bg-[#EDEDE9] px-6 text-xs'>Back</button>
                        {/* <div className='w-11/12 gap-8 flex justify-between items-center'> */}
                        <div>
                            <h3 className='text-sm text-center text-[#484848] font-semibold'>Analyze for Negotiations</h3>
                            <p className='text-[#7E7E7E] text-[10px]'>Use this space to perform your internal analysis. None of the calculations will get saved.</p>
                        </div>
                        <button type='submit' className='bg-[#212118] text-xs text-white px-4'>Run Analysis</button>
                        {/* </div> */}
                    </div>
                    <div className="overflow-x-auto">
                        <table className="max-w-[400px]">
                            <thead>
                                <tr>
                                    <td className="sticky left-0 z-0 bg-white min-w-[200px]"></td>
                                    {values.vendors.map((vendor, idx) => (
                                        <th key={idx} className="py-4 px-2 m-2 min-w-[360px] text-left">
                                            <div className="flex items-center">
                                                <hr className="border-t border-[#707070] flex-grow" />
                                                {/* <span className="mx-2 font-bold">{vendor.businessName}</span> */}
                                                <p className='text-center text-xs px-2'>
                                                    {vendor.businessName}<br /> (Rank: {vendor?.rank} - Rs. {vendor?.grandTotal.toFixed(2)})
                                                </p>
                                                <hr className="border-t border-[#707070] flex-grow" />
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                                <tr>
                                    <th className="sticky mx-2 left-0 bg-white px-4 min-w-[200px] text-xs font-normal text-left pb-2 text-gray-500">
                                        Line Items
                                    </th>
                                    {sortedSuppliers?.map((supplier, idx) => (
                                        <td key={idx} className="px-2">
                                            <div className='grid grid-cols-12 text-[10px] min-w-[360px] p-2 uppercase text-gray-500'>
                                                {rfqType === "material" && (
                                                    <>
                                                        <p className='col-span-2 text-right border border-white'>Unit Cost</p>
                                                        <p className='col-span-2 text-right border border-white'>Disc. (%)</p>
                                                        <p className='col-span-2 text-right border border-white'>GST (%)</p>
                                                        <p className='col-span-3 text-right border border-white'>Delivery Date</p>
                                                        <p className='col-span-3 text-right border border-white'>Total (Rs.)</p>
                                                    </>
                                                )}
                                                {rfqType === "labour" && (
                                                    <>
                                                        <p className='col-span-3 text-right border border-white'>Labour Rate</p>
                                                        <p className='col-span-2 text-right border border-white'>Quantity</p>
                                                        <p className='col-span-2 text-right border border-white'>GST (%)</p>
                                                        <p className='col-span-3 text-center border border-white'>Start Date</p>
                                                        <p className='col-span-2 text-right border border-white'>Total (Rs.)</p>
                                                    </>
                                                )}
                                                {rfqType === "contractor" && (
                                                    <>
                                                        <p className='col-span-3 text-right border border-white'>Base Price</p>
                                                        <p className='col-span-3 text-right border border-white'>GST (%)</p>
                                                        <p className='col-span-3 text-right border border-white'>Start Date</p>
                                                        <p className='col-span-3 text-right border border-white'>Total (Rs.)</p>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {lineItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className={`sticky left-0 px-4 py-2 text-xs border border-gray-300 ${index % 2 === 0 ? 'bg-blue-50' : 'bg-white'}`}>
                                            <p className="font-medium text-[#707070] text-xs">{item.name || item.skill}</p>
                                            {/* {item.quantity && <p className='text-[10px]'>{item.quantity} {item.unit || ''}</p>} */}
                                        </td>
                                        {sortedSuppliers?.map((supplier, vendorIdx) => {
                                            const vendorData = getVendorQuotationData(supplier, index, values, vendorIdx);
                                            return (
                                                <td key={vendorIdx} className={`px-2 ${vendorIdx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                                    <div className={`text-xs border grid grid-cols-12 h-full ${index % 2 === 0 ? 'bg-blue-50' : ''}`}>
                                                        {rfqType === "material" && (
                                                            <>
                                                                <Field
                                                                    name={`vendors[${vendorIdx}].lineItems[${index}].basePrice`}
                                                                    className={`border-r col-span-2 px-1 py-2 text-right text-[10px] ${editedFields[`${values.vendors[vendorIdx].vendorId}-${index}-basePrice`] ? 'text-red-500' : ''}`}
                                                                    value={values.vendors[vendorIdx].lineItems[index].basePrice}
                                                                    onChange={(e) => handleFieldChange(vendorIdx, index, 'basePrice', parseFloat(e.target.value) || 0, values, setFieldValue)}
                                                                />
                                                                <Field
                                                                    name={`vendors[${vendorIdx}].lineItems[${index}].discount`}
                                                                    className='border-r text-[10px] px-1 py-2 text-right col-span-2'
                                                                    value={values.vendors[vendorIdx].lineItems[index].discount || ''}
                                                                    onChange={e => handleFieldChange(vendorIdx, index, 'discount', parseFloat(e.target.value) || 0, values, setFieldValue)}
                                                                />
                                                                <Field
                                                                    name={`vendors[${vendorIdx}].lineItems[${index}].gst`}
                                                                    className='border-r text-[10px] px-1 py-2 text-right col-span-2'
                                                                    value={values.vendors[vendorIdx].lineItems[index].gst || 0}
                                                                    onChange={e => handleFieldChange(vendorIdx, index, 'gst', parseFloat(e.target.value) || 0, values, setFieldValue)}
                                                                />
                                                                <p className="text-gray-500 border-r text-xs col-span-3 px-1 py-2 text-right">{new Date(vendorData.deliveryDate).toLocaleDateString('en-GB') || ""}</p>
                                                                <p className="text-gray-500 text-right text-xs border-r col-span-3 px-1 py-2">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.vendors[vendorIdx].lineItems[index].netPrice.toFixed(2)) || ''}</p>
                                                            </>
                                                        )}
                                                        {rfqType === "labour" && (
                                                            <>
                                                                <Field
                                                                    name={`vendors[${vendorIdx}].lineItems[${index}].labourRate`}
                                                                    className='border text-[10px] px-1 py-4 text-right col-span-3'
                                                                    value={vendorData.labourRate || ''}
                                                                    onChange={e => handleFieldChange(vendorIdx, index, 'labourRate', parseFloat(e.target.value) || 0, values, setFieldValue)}
                                                                />
                                                                <Field
                                                                    name={`vendors[${vendorIdx}].lineItems[${index}].quantity`}
                                                                    className='border text-[10px] px-1 py-4 text-right col-span-2'
                                                                    value={vendorData.quantity || ''}
                                                                    onChange={e => handleFieldChange(vendorIdx, index, 'quantity', parseFloat(e.target.value) || 1, values, setFieldValue)}
                                                                />
                                                                <Field
                                                                    name={`vendors[${vendorIdx}].lineItems[${index}].gst`}
                                                                    className='border text-[10px] px-1 py-4 text-right col-span-2'
                                                                    value={vendorData.gst || ''}
                                                                    onChange={e => handleFieldChange(vendorIdx, index, 'gst', parseFloat(e.target.value) || 0, values, setFieldValue)}
                                                                />
                                                                <p className="text-gray-500 border col-span-3 px-1 py-4 text-right">{new Date(vendorData.workStartDate).toLocaleDateString('en-GB') || ""}</p>
                                                                <p className="text-gray-500 text-right border col-span-2 px-1 py-4">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(vendorData?.totalAmount?.toFixed(2)) || ''}</p>
                                                            </>
                                                        )}
                                                        {rfqType === "contractor" && (
                                                            <>
                                                                <Field
                                                                    name={`vendors[${vendorIdx}].lineItems[${index}].basePrice`}
                                                                    className='border text-[10px] px-1 py-4 text-right col-span-3'
                                                                    value={vendorData.basePrice || ''}
                                                                    onChange={e => handleFieldChange(vendorIdx, index, 'basePrice', parseFloat(e.target.value) || 0, values, setFieldValue)}
                                                                />
                                                                <Field
                                                                    name={`vendors[${vendorIdx}].lineItems[${index}].gst`}
                                                                    className='border text-[10px] px-1 py-4 text-right col-span-3'
                                                                    value={vendorData.gst || ''}
                                                                    onChange={e => handleFieldChange(vendorIdx, index, 'gst', parseFloat(e.target.value) || 0, values, setFieldValue)}
                                                                />
                                                                <p className="text-gray-500 border col-span-3 px-1 py-4 text-right">{new Date(vendorData.workStartDate).toLocaleDateString('en-GB') || ""}</p>
                                                                <p className="text-gray-500 text-right border col-span-3 px-1 py-4">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(vendorData?.netPrice?.toFixed(2)) || ''}</p>
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                                <tr>
                                    <td className="sticky left-0 bg-white shadow-md pl-4 min-w-[200px] text-sm font-semibold">Sub Total</td>
                                    {sortedSuppliers?.map((supplier, idx) => {
                                        const updatedLineItems = values?.vendors?.[idx]?.lineItems || [];
                                        const liveSubtotal = calculateSubtotal(updatedLineItems, rfqType);

                                        return (
                                            <td key={idx} className={`w-full mt-3 mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                                <div className="border-t border-t-[#004491] mx-[10px] mt-2">
                                                    <p className="font-bold text-sm text-right mt-[7px] text-[#004491]">
                                                        <span className="bg-[#E7EAEF] px-6 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(liveSubtotal.toFixed(2))}</span>
                                                    </p>
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    <td className="sticky left-0 text-[#5D5D5D] font-semibold bg-white px-4 pt-2 ">Extra Charges</td>
                                    {sortedSuppliers?.map((_, idx) => (
                                        <td key={idx} className={`p-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}></td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="sticky left-0 bg-white px-4"></td>
                                    {sortedSuppliers?.map((_, idx) => (
                                        <td key={idx} className={`px-2 ${idx < sortedSuppliers?.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                            <div className="grid grid-cols-3 w-full pl-2 text-[10px] mb-2 ml-auto text-right text-gray-500">
                                                <p>GST (%)</p>
                                                <p className='text-right'>Amount (Rs.)</p>
                                                <p>Total Amount (Rs.)</p>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                {extraChargesKeys.map((chargeKey, index) => (
                                    <tr key={index}>
                                        <td className="sticky left-0 bg-white shadow-md min-w-[200px] px-4">
                                            <div className="flex items-center text-sm text-center text-[#707070]">
                                                <p>{chargeKey.charAt(0).toUpperCase() + chargeKey.slice(1)}</p>
                                            </div>
                                        </td>
                                        {sortedSuppliers?.map((supplier, vendorIdx) => {
                                            const vendorData = getVendorQuotationData(supplier, 0, values, vendorIdx);
                                            return (
                                                <td key={vendorIdx} className={`px-2 ${vendorIdx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                                    <div className="text-xs grid grid-cols-3 text-right border border-gray-300 w-3/4 ml-auto">
                                                        <Field
                                                            name={`vendors[${vendorIdx}].otherCharges.${chargeKey}.gst`}
                                                            className='border p-2 text-right'
                                                            value={values?.vendors[vendorIdx].otherCharges[chargeKey]?.gst || 0}
                                                            onChange={e => handleOtherChargesChange(vendorIdx, 'gst', e.target.value, values, setFieldValue, chargeKey)}
                                                        />
                                                        <Field
                                                            name={`vendors[${vendorIdx}].otherCharges.${chargeKey}.mrp`}
                                                            className='border p-2 text-right'
                                                            value={values?.vendors[vendorIdx].otherCharges[chargeKey]?.mrp || 0}
                                                            onChange={e => handleOtherChargesChange(vendorIdx, 'mrp', e.target.value, values, setFieldValue, chargeKey)}
                                                        />
                                                        <p className="text-gray-500 border p-2 text-right">
                                                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values?.vendors[vendorIdx].otherCharges[chargeKey]?.totalCost?.toFixed(2)) || 0}
                                                        </p>
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                                {/* Grand Total Row */}
                                <tr>
                                    <td className="sticky left-0 text-sm px-4 bg-white shadow-md min-w-[200px] font-semibold">Grand Total</td>
                                    {sortedSuppliers?.map((supplier, idx) => {
                                        const vendorData = getVendorQuotationData(supplier, 0, idx);

                                        const vendor = values.vendors[idx]; // Get the vendor data from Formik's state
                                        const updatedLineItems = values?.vendors?.[idx]?.lineItems || [];
                                        const liveSubtotal = calculateSubtotal(updatedLineItems, rfqType);
                                        const otherCharges = vendor?.otherCharges; // Get the other charges for the vendor

                                        // Calculate the live grand total: subTotal + total of other charges
                                        const liveGrandTotal = liveSubtotal + calculateTotalCharges(otherCharges);

                                        return (
                                            <td key={idx} className={`w-full mt-3 mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                                <div className="border-t border-t-[#004491] mx-[10px] mt-2">
                                                    <p className="font-bold text-sm text-right mt-[7px] text-[#004491]">
                                                        <span className='bg-[#E0F0EE] px-6 py-2'>Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(liveGrandTotal.toFixed(2))}</span> {/* Adjusted padding */}
                                                    </p>
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>

                                {/* <tr>
                                    <td className="sticky left-0 text-sm px-4 pt-4 pb-8 bg-white shadow-md min-w-[200px] font-semibold">
                                        Difference from Lowest
                                    </td>
                                    {sortedSuppliers.map((supplier, idx) => {
                                        const vendorQuotation = supplier.vendorQuotations;
                                        // Use the appropriate grand total based on withGst state
                                        const grandTotal = vendorQuotation.grandTotalWithGst
                                        // Calculate L1 supplier data
                                        const l1Supplier = sortedSuppliers[0]; // Assuming sortedSuppliers is sorted by total
                                        const l1Total = l1Supplier ? l1Supplier.vendorQuotations.grandTotalWithGst : 0;
                                        // Calculate deviation
                                        const deviation = l1Total ? grandTotal - l1Total : 0;
                                        const deviationPercentage = l1Total ? ((deviation / l1Total) * 100).toFixed(2) : 0;
                                        // map the data here
                                        return (
                                            <td key={idx} className={`${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                                {idx === 0 ? (
                                                    <p className='text-sm text-right pt-2 font-medium mr-1'>Lowest Quote</p>
                                                ) : (
                                                    <p className='text-sm text-right pt-2 mr-1'>
                                                        Rs. {deviation.toFixed(2)} ({deviationPercentage}%)
                                                    </p>
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr> */}

                            </tbody>
                        </table>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default Negotiation;
