import React from "react";
import { dateFormatter } from "utils/formatUtils";

const OverviewAwardee = ({ awardeeDetails, data, handleOpenEditAwardees }) => {
  const getAvatar = (name) => {
    const nameArray = name?.split(" ");
    const initials =
      nameArray?.length === 1
        ? nameArray[0]?.charAt(0).toUpperCase()
        : nameArray[0]?.charAt(0) + nameArray[1]?.charAt(0);
    return (
      <div className="flex items-center gap-x-2">
        <div className="flex items-center justify-center w-4 h-4 bg-black rounded-full">
          <span style={{ fontSize: "8px" }} className="text-white pt-1/2">
            {initials}
          </span>
        </div>
        <b>{name}</b>
      </div>
    );
  };

  const userBifurcation = awardeeDetails?.reduce((acc, awardee) => {
    const {
      supplierId,
      businessName,
      closingDetails,
      quotationDetails,
      contactDetails,
      vendorQuotations
    } = awardee;
    const rank = quotationDetails?.rank;
    // const amount = closingDetails?.amount;
    // const remark = closingDetails?.remark;
    // const poNumber = closingDetails?.poNumber;
    // const poDate = closingDetails?.poDate;
    const contactNumber = contactDetails[0]?.mobile;
    acc[supplierId] = {
      supplierId,
      businessName,
      rank,
      // amount,
      // remark,
      // poNumber,
      // poDate,
      closingDetails,
      contactNumber,
      vendorQuotations
    };
    return acc;
  }, {});

  return (
    <div className="flex flex-col w-full justify-start h-full">
      <div className="flex gap-2 items-center ">
        <h2 className="text-sm text-gray-900 mb-1 font-bold">RFQ Awardee/s</h2>
        {/* {data?.status === "CLOSED" && (
          <button>
            <p
              className="text-blue-700 underline p-2"
              onClick={handleOpenEditAwardees}
            >
              Edit
            </p>
          </button>
        )} */}
      </div>
      {Object?.values(userBifurcation)?.map((awardeeData) => {
        return (
          <div key={awardeeData?.supplierId} className=" text-xs">
            <b>{awardeeData?.businessName + " - " + awardeeData?.vendorQuotations?.rank}</b>
            {/* <br />
              <span>Date - {awardeeData?.date}</span> */}
            <br />
            <span className="text-slate-500 text-xs">
              Mobile# : {awardeeData?.contactNumber}
            </span>
            <p className="text-slate-500 text-xs">
              Closing Amount : {new Intl.NumberFormat('en-IN',).format(Number(awardeeData?.closingDetails?.[0]?.amount)?.toFixed(0))}
            </p>
            {/* {awardeeData?.closingDetails?.map((data, index) => (
              <div className="p-1 border rounded-lg mt-2">
                <span className="font-medium"> Purchase Order {index + 1}</span>
                <br />
                <span className="text-slate-500">
                  PO Number : {data?.poNumber}
                </span>
                <br />
                {data?.poDate && (
                  <>
                    <span className="text-slate-500">
                      PO Date : {dateFormatter(data?.poDate)}
                    </span>
                    <br />
                  </>
                )}
                <span className="text-slate-500">
                  PO Amount : {data?.amount}
                </span>
              </div>
            ))} */}
            {awardeeData?.closingDetails[0]?.remark && (
              <>
                <span className="text-xs">
                  <b>Reason - </b>
                  {awardeeData?.closingDetails[0]?.remark}
                </span>
              </>
            )}
          </div>
        );
      })}
      <div className=" text-xs">
        <h4 className="font-semibold mt-2 text-gray-600 ">Awarded by</h4>
        {getAvatar(data?.rfqClosingDetails?.name ?? "N/A")}
      </div>
    </div>
  );
};

export default OverviewAwardee;
