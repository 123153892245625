import React from "react";

const Testimonial = () => {
  return (
    <>
      <h1 className="text-center text-4xl font-extrabold mt-28 mb-8">
        Sound too good to be true ?
      </h1>
      <section
        style={{ backgroundColor: "#FBF8F6" }}
        className=" dark:bg-gray-900"
      >
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:px-6">
          <figure className="max-w-screen-md mx-auto">
            <svg
              className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <blockquote>
              <p className="text-xl text-black dark:text-white">
                {" "}
                “As a steel seller on Neevay portal has really helped our
                business. Easy connections with buyers and a straightforward
                approach make it a valuable resource for any steel seller.
                Highly recommended!”
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center mt-6 space-x-3">
              {/* <img
                className="w-14 h-14 rounded-full"
                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/web-pricing-Gokul.webp"
                alt="profile picture"
              /> */}
              <div className="flex items-center  divide-gray-500 ">
                <div className="pr-4 text-lg text-gray-900 dark:text-white">
                  Gokul Tiwari
                </div>
                <div className="pl-3  md:text-xl  font-semibold text-black">
                  Owner - AMP Impex
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
