import React from 'react';
import sectorData from '../sectordata.json';

const Sectorsfortab = () => {
  // Split sectorData into two arrays for two rows
  const firstRow = sectorData.slice(0, 3);
  const secondRow = sectorData.slice(3);

  return (
    <div className="container mx-auto mt-16">
      <div className='flex justify-evenly'>
        {/* First row */}
        {firstRow.map((sector) => (
          <div key={sector.id} className="flex flex-col items-center">
            <img
              src={sector.src}
              alt={sector.alt}
              className="h-[95px] w-[180px] object-cover rounded-t-lg"
            />
            <p className="text-white p-4 font-bold text-xl">{sector.title}</p>
          </div>
        ))}
      </div>

      {/* Second row */}
      <div className="flex justify-evenly mt-[34px]">
        {secondRow.map((sector) => (
          <div key={sector.id} className="flex flex-col items-center">
            <img
              src={sector.src}
              alt={sector.alt}
              className="h-[95px] w-[180px]  object-cover rounded-t-lg"
            />
            <p className="text-white p-4 font-bold text-xl">{sector.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sectorsfortab;
