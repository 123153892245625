import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  MenuItem,
  Menu,
  Alert,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import { Edit } from "@mui/icons-material";
import { ImageEditor } from "components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
import SendIcon from "@mui/icons-material/Send";

import { LocationOn } from "@mui/icons-material";
import SocialIconDailog from "./SocialIconDailog";
import { getAddressHO } from "utils/addressUtils";
import {
  fetchRequirement,
  sendRFQTrigger,
  sendNewRFQTrigger,
} from "actions/requirementsAction";
import FavoritesMenu from "./FavoritesMenu";
import { getUrl } from "actions/businessActions";
import { makeStyles } from "@mui/styles";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import VerificationPopUp from "views/VerificationModal";
import RequirementFormModal from "views/RequirementFormModal/RequirementFormModal";
import NonEntityRequirmentForm from "views/RequirementFormModal/NonEntityRequirmentForm";
const useStyles = makeStyles((theme) => ({
  hoverText: {
    "&:hover": {},
  },
  hoverButton: {
    "&:hover": {
      backgroundColor: "rgba(71, 181, 158, 1)",
      boxShadow: "0px 4px 4px 0px rgba(71, 181, 158, 0.2)",
    },
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const BASE_URL = process.env.REACT_APP_BASE_URL;

const myStyle = {
  marginTop: "35px",
};
const myStyleDesktop = {
  marginRight: "5px",
};

function ProfileHead({
  logoUrl,
  bannerUrl,
  supplierId,
  isPremium,
  businessName,
  businessDomain,
  location,
  projectsDelivered,
  businessCategory,
  isPublicProfile,
  socialLinks,
  addresses,
  permissions,
  url,
  businessOwnerData,
  userIdOfOwner,
  businessRegions,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [supplier, setSupplier] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openLogInModal, setOpenLogInModal] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSendRfqModel, setOpenSendRfqModel] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const isRealEstateDev =
    businessCategory == "Real Estate Developer" ? true : false;
  const isEpcContractor = businessCategory == "EPC Contractor" ? true : false;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;

  const regionCity = businessRegions?.Allcities;
  const regionState = businessRegions?.state;
  const regionSelectedCities = businessRegions?.Selectedcities;

  const regionsAllData =
    regionState?.length > 0
      ? [
        ...regionState.map((state) => `All over ${state}`),
        ...regionSelectedCities,
      ]
      : regionCity?.length && [...regionCity];
  let newregionsAllData = [];

  if (businessRegions?.state.length == 36) {
    newregionsAllData.push("All over India")
  } else if (regionsAllData?.length > 0) {
    for (let i = 0; i < regionsAllData?.length; i++) {
      if (regionSelectedCities?.length > 0 && regionState?.length > 0) {
        if (i == regionState?.length - 1) {
          newregionsAllData.push(
            regionsAllData[i] + " & " + regionsAllData[i + 1]
          );
          i++;
        } else {
          newregionsAllData.push(regionsAllData[i]);
        }
      } else {
        newregionsAllData.push(regionsAllData[i]);
      }
    }
  }
  const requirement = useSelector((state) => state.requirement);
  const requirements = requirement?.requirements;

  const business = useSelector((state) => state.business);


  const uploadSizeError = business?.uploadSizeError?.errorMessage
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  useEffect(() => {
    if (uploadSizeError) {
      setOpenSnackbar(true);

      const timeoutId = setTimeout(() => {
        setOpenSnackbar(false);
      }, 7000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [business?.uploadSizeError?.time]);




  

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => { }, [logoUrl, permissions]);

  const navigate = useNavigate();
  const currentLocation = useLocation();

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event, data) => {
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));

      setOpenLogInModal(true);
    } else if (userProfile?.isVerified === false) {
      setIsModalOpen(true)
    } else {

      setAnchorEl(event.currentTarget);

      const supplierData = [];
      supplierData.push({
        supplierId: supplierId,
        businessName: businessName,

        businessOwnerUserId: userIdOfOwner,
        isPremium: isPremium,
      });
      dispatch(sendNewRFQTrigger(supplierData));
      setOpenSendRfqModel(true)
      // navigate("/enquiry");
    }
  };

  const sendRFQ = (rfq) => {
    handleClose();

    dispatch(
      sendRFQTrigger(
        supplierId,
        businessName,
        "", //mobile,
        "", //email,
        rfq?.rfqId,
        userIdOfOwner,
        isPremium

      )
    );
    dispatch(fetchRequirement(business?.supplierId, rfq?.rfqId));

    navigate("/enquiry");
  };

  const sendNewRFQ = () => {
    handleClose();
    dispatch(
      sendNewRFQTrigger(
        supplierId,
        businessName,
        userIdOfOwner,
        isPremium,
        "", //data?.mobile,
        "" //data?.email
      )
    );
    setOpenSendRfqModel(true)
    // navigate("/enquiry");
  };

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => { }, [logoUrl, bannerUrl]);
  const address = getAddressHO(addresses);

  const locationStr = location ? location : address?.city;
  const socialIconList = [];
  if (socialLinks) {
    if (socialLinks?.facebookUrl) {
      socialIconList.push({
        name: "Facebook",
        image: "/assets/icons/facebook.svg", //Group256.svg",
        target: socialLinks.facebookUrl,
      });
    }

    if (socialLinks?.twitterUrl) {
      socialIconList.push({
        name: "Twitter",
        image: "/assets/icons/twitter.svg", //Group259.svg",
        target: socialLinks?.twitterUrl,
      });
    }

    if (socialLinks?.instagramUrl) {
      socialIconList.push({
        name: "Instagram",
        image: "/assets/icons/instagram.svg", //Group257.svg",
        target: socialLinks.instagramUrl,
      });
    }

    if (socialLinks?.youtubeUrl) {
      socialIconList.push({
        name: "Youtube",
        image: "/assets/icons/youtube.svg", //Group258.svg",
        target: socialLinks.youtubeUrl,
      });
    }
  }

  let highlight = undefined;

  if (businessCategory && projectsDelivered) {
    highlight =
      "(" +
      businessCategory +
      " with " +
      projectsDelivered +
      " Projects Delivered)";
  } else if (businessCategory) {
    highlight = "(" + businessCategory + ")";
  } else if (projectsDelivered) {
    highlight = "(" + projectsDelivered + " Projects Delivered)";
  }

  let bannerSrcUrl = DEFAULT_IMAGES.BANNER;

  if (bannerUrl && bannerUrl !== "") {
    if (bannerUrl.startsWith("/assets/business")) {
      const assetsPortion = bannerUrl.slice(0, bannerUrl.indexOf("/business"));
      bannerSrcUrl = `${BASE_URL}${bannerUrl}`;
    } else {
      bannerSrcUrl = bannerUrl;
    }
  }
  let logoSrcUrl = DEFAULT_IMAGES.LOGO;
  if (logoUrl && logoUrl !== "") {
    if (logoUrl.startsWith("/assets/business")) {
      const assetsPortion = logoUrl.slice(0, logoUrl.indexOf("/business"));
      logoSrcUrl = `${BASE_URL}${logoUrl}`;
    } else {
      logoSrcUrl = logoUrl;
    }
  }

  const isEdit =
    !isPublicProfile && permissions && permissions.isFreeUpdateAllowed;

  const premiumFlagUrl = "/assets/default/premium_flag_new.png";

  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };

  const MAX_CITIES_TO_DISPLAY = 7;
  const [showAllCities, setShowAllCities] = useState(false);

  const handleReadMoreClick = () => {
    setShowAllCities(true);
  };

  const handleViewLessClick = () => {
    setShowAllCities(false);
  };

  const renderedCities = newregionsAllData
    ? showAllCities
      ? newregionsAllData
      : newregionsAllData.slice(0, MAX_CITIES_TO_DISPLAY)
    : [];
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Card sx={{ borderRadius: 0 }}>
        <Grid container>
          <Grid item xs={12} md={12} xl={12} sx={{ p: 1 }}>
            {openSnackbar && (
              <Alert severity="error" sx={{ width: '100%' }}>
                {uploadSizeError}
              </Alert>
            )}

            {!isEdit ? (
              <CardMedia
                component="img"
                height="194"
                image={bannerSrcUrl}
                alt="Banner Image"
              />
            ) : (
              <Box
                style={{
                  height: "194px",
                  backgroundSize: "cover",
                }}
              >
                <ImageEditor
                  type={IMAGE_TYPE.BANNER}
                  style={{ height: "194" }}
                  inImage={bannerSrcUrl}
                  supplierId={supplierId}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>
      {isSm ? (
        <Box>
          {isPremium ? (
            <Box sx={{ display: "flex", justifyContent: "end", mt: 1.5 }}>
              <img
                src={premiumFlagUrl}
                height="25px"
                width="100px"
                style={{ marginTop: "0px", marginLeft: "5px" }}
                alt="Premium flag image not Available"
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mt: 1.5,
                height: "20px",
              }}
            />
          )}
        </Box>
      ) : (
        <Box />
      )}

      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12} md={2} xl={2} sx={{ mt: -9 }}>
          {!isEdit ? (
            <Avatar
              alt="Image not found"
              backgroundColor="primary.main"
              src={logoSrcUrl}
              sx={{
                width: "147px",
                height: "147px",
                borderRadius: "73px",
                border: "5px solid white",
                background: "white",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            />
          ) : (
            <Box
              style={{
                height: "140px",
                width: "140px",
                // backgroundColor: "#47B59E",
                borderRadius: "120px",
                border: "10px solid #f1eef8",
                // backgroundImage: `url(${getImage("logo", "logo")})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <ImageEditor
                type="logo"
                inImage={logoSrcUrl}
                supplierId={supplierId}
              />
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          xl={9}
          // sm={7}
          sx={{ ml: isPublicProfile ? 0 : 5 }}
        >
          <Grid
            item
            xs={12}
            md={12}
            xl={12}
            style={isSm ? myStyle : myStyleDesktop}
            marginLeft={"25px"}
          >


            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignContent: "center",
              }}
            >
              <Typography
                variant="businessTitle"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <b>{businessName}</b>
                {permissions &&
                  !permissions.isViewOnly &&
                  permissions.isFreeUpdateAllowed && (
                    <Link to="/edit-business">
                      <Tooltip title="Edit Business" sx={{ ml: 3 }}>
                        <IconButton sx={{ color: "primary.main" }}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  )}

                {isPremium && !isSm ? (
                  <img
                    src={premiumFlagUrl}
                    height="24px"
                    width="100px"
                    style={{ marginTop: "0px", marginLeft: "15px" }}
                    alt="Premium flag image not Available"
                  />
                ) : (
                  ""
                )}

              </Typography>
            </Box>
            <Box sx={{ mt: 0.5 }}>
              {highlight && (
                <Typography variant="businessSubTitle">{highlight}</Typography>
              )}
            </Box>
            {isEdit ? (
              <Box
                sx={{
                  mt: 0.5,
                  ml: -0.5,
                  display: "flex",
                  justifyContent: "start",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {locationStr && (
                  <Typography
                    variant="businessSubTitle"
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LocationOn
                      sx={{
                        fontSize: "16px",
                        mt: 0,
                      }}
                    />
                    <b style={{ margin: "5px" }}>{locationStr}</b>
                  </Typography>
                )}
              </Box>
            ) : (
              (regionCity?.length > 0 || regionState?.length > 0) && (
                <Box
                  sx={{
                    mt: 0.5,
                    ml: -0.5,
                    display: "flex",
                    justifyContent: "start",
                    alignContent: "center",
                    alignItems: "center",
                    mb: "10px",
                    border: "1px solid #E3E3E3",
                    p: "5px",
                    borderRadius: "11px",
                  }}
                >
                  <LocationOn
                    sx={{
                      fontSize: "16px",
                      mt: 0,
                      alignSelf: "flex-start",
                      mr: "5px",
                    }}
                  />
                  {(regionCity && regionCity.length > 0) ||
                    (regionState && regionState.length > 0) ? (
                    <Typography
                      variant="businessSubTitle"
                      sx={{ alignItems: "center", textAlign: "left" }}
                    >
                      Regions Served :{" "}
                      {newregionsAllData.length > 0 ? (
                        <span
                          style={{
                            fontWeight: "normal",
                            wordSpacing: "1px",
                            letterSpacing: "0.2px",
                          }}
                        >
                          {renderedCities.join(", ")}
                          {newregionsAllData.length > MAX_CITIES_TO_DISPLAY && (
                            <Grid
                              style={{
                                textAlign: "center",
                                borderTop: "1px solid #E3E3E3",
                                width: "100%",
                                padding: "4px",
                                marginTop: "6px",
                              }}
                            >
                              {!showAllCities ? (
                                <button
                                  onClick={handleReadMoreClick}
                                  style={{
                                    cursor: "pointer",
                                    background: "none",
                                    border: "none",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  View more (
                                  {newregionsAllData.length -
                                    MAX_CITIES_TO_DISPLAY}{" "}
                                  regions)
                                </button>
                              ) : (
                                <button
                                  onClick={handleViewLessClick}
                                  style={{
                                    cursor: "pointer",
                                    background: "none",
                                    border: "none",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  View less
                                </button>
                              )}
                            </Grid>
                          )}
                        </span>
                      ) : null}
                    </Typography>
                  ) : null}
                </Box>
              )
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            xl={8}
            sx={{ ml: -1, display: "flex", marginLeft: "20px" }}
          >
            {socialIconList.map((item) => (
              <IconButton href={item.target} target="_blank">
                <img src={item.image} />
              </IconButton>
            ))}
            {permissions &&
              !permissions.isViewOnly &&
              permissions.isFreeUpdateAllowed && (
                <SocialIconDailog
                  supplierId={supplierId}
                  socialLinks={socialLinks}
                />
              )}
          </Grid>
        </Grid>

        {isPublicProfile && (
          <>
            <Grid
              item
              md={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "end",
                flexDirection: "column",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  gap: "6px",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    gap: 3,
                    marginLeft: isSm ? "20px" : "",
                    paddingTop: isSm ? "5px" : "",
                  }}
                >

                  <FavoritesMenu
                    businessCategory={businessCategory}
                    isBig={true}
                    supplierId={supplierId}
                    businessName={businessName}
                    url={url}
                  />
                  <Box
                    container
                    className={
                      !isRealEstateDev &&
                      !isEpcContractor &&
                      classes.hoverButton
                    }
                    sx={{
                      backgroundColor:
                        isRealEstateDev || isEpcContractor
                          ? "#d3d3d3"
                          : "rgba(57, 99, 171, 1)",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "150px",
                      height: "25px",
                      cursor: !isRealEstateDev && !isEpcContractor && "pointer",
                      boxShadow:
                        !isRealEstateDev &&
                        !isEpcContractor &&
                        "0px 4px 4px 0px rgba(57, 99, 171, 0.5)",
                    }}
                  >
                    <Button
                      style={{
                        fontStyle: "normal",
                        fontSize: "14px",
                        paddingLeft: "23px",
                        color: "white",
                      }}
                      onClick={(e) => handleClick(e)}
                      disabled={isRealEstateDev || isEpcContractor}
                    >
                {entityId ? "Contact Vendor" : "Send Message"}
                    </Button>
                    <SendIcon
                      sx={{
                        color: "rgba(255, 255, 255, 1)",
                        height: "100%",
                        padding: "4px",
                        backgroundColor: "#fbfbfb5e",
                        borderTopRightRadius: "24px",
                        borderBottomRightRadius: "24px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    marginLeft: isSm ? "25px" : "7px",
                  }}
                >
                  {isRealEstateDev || isEpcContractor ? (
                    <Typography sx={{ fontSize: "10px", paddingTop: "4px" }}>
                      Links of this business are disabled*
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {requirements.map((rfq) => (
                  <MenuItem onClick={() => sendRFQ(rfq)} disableRipple>
                    {rfq.name}
                  </MenuItem>
                ))}

                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => sendNewRFQ()} disableRipple>
                  Create New Enquiry
                </MenuItem>
              </StyledMenu>{" "}

            </Grid>
          </>
        )}
      </Grid>

      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />
      <VerificationPopUp
        open={isModalOpen} // Pass the modal visibility state
        handleClose={handleCloseModal} // Pass the close modal function
      />
     {entityId?

<RequirementFormModal
open={openSendRfqModel}
setOpen={setOpenSendRfqModel}
/>
: <NonEntityRequirmentForm
open={openSendRfqModel}
setOpen={setOpenSendRfqModel}
/>
}

    </Container>
  );
}

ProfileHead.defaultProps = {
  isPublicProfile: true,
};

ProfileHead.propTypes = {
  logoUrl: PropTypes.string,
  bannerUrl: PropTypes.string,
  supplierId: PropTypes.string,
  isPremium: PropTypes.bool,
  socialLinks: PropTypes.object,
  businessName: PropTypes.string,
  businessDomain: PropTypes.string,
  location: PropTypes.string,
  businessCategory: PropTypes.string,
  projectsDelivered: PropTypes.string,
  isPublicProfile: PropTypes.bool,
  permissions: PropTypes.object,
};

export default ProfileHead;