import React, { useState } from "react";

const CustomInput = ({ label, id, type, name, value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value ?? "");
  const [inputType, setInputType] = useState(type === "date" ? "text" : type);

  const handleFocus = () => {
    setIsFocused(true);
    if (type === "date") {
      setInputType("date");
    }
  };

  const handleBlur = () => {
    if (inputValue === "") {
      setIsFocused(false);
      if (type === "date") {
        setInputType("text");
      }
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  return (
    <div className="relative flex items-center">
      <input
        id={id}
        type={inputType}
        name={name}
        value={inputValue}
        onChange={handleChange}
        className="border border-gray-300 text-sm  label:text-[#4F4F42] pt-4 w-full focus:outline-none focus:border-neevay-theme-surface-700 transition-all duration-200 h-[50px] focus:ring-0"
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <label
        htmlFor={id}
        className={`absolute left-4 text-gray-500 text-sm transition-all duration-200 ${
          isFocused || inputValue
            ? "text-xs transform -translate-y-4 px-1 left-[8px]"
            : "text-base top-1/2 transform -translate-y-1/2"
        }`}
      >
        {label}
      </label>
    </div>
  );
};

export default CustomInput;
