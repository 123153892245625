import { Delete, Edit } from "@mui/icons-material";
import { Card, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  addServiceTrigger,
  deleteService,
  editServiceTrigger,
} from "actions/serviceActions";
import { ImageEditor } from "components";
import { IMAGE_TYPE } from "config";
import { DEFAULT_IMAGES } from "config";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const BusinessOfferingCard = ({
  data,
  imageUrl,
  IsSearchPublicProfile,
  PublicProfile,
  permissions,
  isEditView,
  profile,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEditService = () => {
    dispatch(editServiceTrigger(data, data._id));
    navigate("/add-service");
  };

  const business = useSelector((state) => state.business);
const supplierId = business?.profile?.supplierId;
const businessName = business?.profile?.businessName;



  const handleDeleteService =  () => {
     console.log(
      business,
      supplierId, 
      businessName,
      data.serviceName,
      data._id,
      "del Offsering"
    );
     dispatch(deleteService(supplierId, data.serviceName, data._id,businessName));
  };
  return (
    <Grid padding={"0 5px"}>
      <Card
        sx={{
          border: "1px solid rgba(217, 217, 217, 1)",
          width: "190px",
          margin: "auto",
          minHeight: "270px",
        }}
      >
        {" "}
        <Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
           {isEditView? <Grid>
              {data?.serviceName && data?.serviceName.length > 13 ? (
                <Tooltip title={data.serviceName}>
                  <Typography
                    sx={{
                      paddingX: "10px",
                      paddingTop: "6px",
                      fontWeight: 600,
                      width:  "120px" ,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                    }}
                  >
                    {data.serviceName}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    paddingX: "10px",
                    paddingTop: "6px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {data.serviceName}
                </Typography>
              )}
            </Grid>
            :<Grid>
              {data?.serviceName && data?.serviceName.length > 22 ? (
                <Tooltip title={data.serviceName}>
                  <Typography
                    sx={{
                      paddingX: "10px",
                      paddingTop: "6px",
                      fontWeight: 600,
                      width:  "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                    }}
                  >
                    {data.serviceName}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    paddingX: "10px",
                    paddingTop: "6px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {data.serviceName}
                </Typography>
              )}
            </Grid>}

            <Grid>
              {permissions && permissions.isUpdateAllowed && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <>
                    <Typography sx={{ mr: 1 }}>
                      <Tooltip title="Edit">
                        <div onClick={handleEditService}>
                          <Link
                            style={{
                              fontSize: "14px",
                              color: "#2864BE",
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            Edit
                          </Link>
                        </div>
                      </Tooltip>
                    </Typography>

                    <Typography>
                      <Tooltip title="Delete">
                        <div
                          disabled={!permissions?.isOwner}
                          style={{ color: "primary.main" , cursor:"pointer"}}
                          onClick={handleDeleteService}
                        >
                          <Delete
                            style={{ fontSize: "18px", color: "#2864BE",paddingTop:"4px" }}
                          />
                        </div>
                      </Tooltip>
                    </Typography>
                  </>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            borderBottom: "1px solid rgba(217, 217, 217, 1)",
            marginBottom: "10px",
          }}
        ></Grid>
        {isEditView ? (
          <ImageEditor
            type={IMAGE_TYPE.SERVICE}
            name={data.serviceName}
            supplierId={profile?.supplierId}
            style={{ height: "126px" }}
            inImage={
              imageUrl ? `${BASE_URL}/${imageUrl}` : DEFAULT_IMAGES.SERVICE
            }
            id={data._id}
          />
        ) : (
          <div style={{  display:"flex", justifyContent:"center" }}>
            <Box
              component="img"
              alt="img"
              src={
                data?.bannerUrl
                  ? `${BASE_URL}/${data?.bannerUrl}`
                  : DEFAULT_IMAGES.SERVICE
              }
              sx={{
                maxWidth: "212px",
                maxHeight: "212px",
                margin: "auto",
                marginBottom: "8px",
              }}

            />
          </div>
        )}
        {data?.usps?.map(
          (point, index) =>
            index < 3 && (
              <Grid
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "8px",
                  marginBottom: "4px",
                }}
              >
                <Box
                  sx={{
                    height: "6px",
                    width: "6px",
                    backgroundColor: "black",
                    marginLeft: "10px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    width: "200px",
                    "&:hover": {
                      whiteSpace: "normal",
                    },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {point}
                </Typography>
              </Grid>
            )
        )}
      </Card>
    </Grid>
  );
};

export default BusinessOfferingCard;
