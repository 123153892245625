import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { deleteClient } from "actions/clientAction";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import AddClientForm from "views/Business/components/AddClientForm";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';

function TopClient({
  profile,
  IsSearchPublicProfile,
  isMobileView = false,
  PublicProfile,
  isEditView,
  permissions,
}) {
  const clients = profile?.clients;
  const isClientExists = clients?.length > 0 ? true : false;
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/my-business");
  };
  const chipData = ["Parande Spaces", "Poonawala Group", "Vascon Engineers"];
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const onClientDelete = (supplierId, clientId, clientName) => {
    dispatch(deleteClient(profile?.supplierId, clientId, clientName));
  };

  // const handleClickOpen = (supplierId) => {
  //   setOpen(true);
  // };

 
  const handleClickOpen = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isUpdateAllowed) {
        setOpen(true);
      } else {

      }
    } else {
      navigate("/subscribe-plan");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid
        sx={{
          marginX: isMobileView ? "10px" : "46px",
          paddingBottom: !isMobileView && "35px",
          paddingX: IsSearchPublicProfile ? "26px" : "",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Top Clients
        </Typography>
        <Grid >
              {permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed && (
                  <Typography
                    color="secondary"
                    size="small"
                    sx={{ fontSize: "15px", cursor: "pointer" }}
                    // disabled={!(permissions && permissions.isUpdateAllowed)}
                    // endIcon={<AddCircleRoundedIcon />}
                    onClick={handleClickOpen}
                  >
                    <Link style={{ textDecoration: "underline", color:"#2864BE"}}>
                      {" "}
                      Click Here{" "}
                    </Link>
                    <span style={{ color: "black" }}> to Add Client</span>
                  </Typography>
                )}
            </Grid>
            <AddClientForm
              open={open}
              handleClose={handleClose}
              // handleSubmit={handleSubmit}
              onSubmitSuccess={handleSubmitSuccess}
            />
            
            {profile?.clients?.length > 0 ? (
  <Grid sx={{ marginY: "16px" }}>
    {clients
      ?.filter(data => data.clientName.trim() !== '')
      .map((data, index) =>
        isEditView ? (
          <Chip
            key={index}
            label={data?.clientName}
            sx={{ marginRight: "8px", marginBottom: "8px" }}
            onDelete={() =>
              onClientDelete(
                profile?.supplierId,
                data.clientId,
                data.clientName
              )
            }
          />
        ) : (
          <Chip
            key={index}
            label={data?.clientName}
            sx={{ marginRight: "8px", marginBottom: "8px" }}
          />
        )
      )}
  </Grid>
) : (
  <Grid sx={{ marginY: "16px" }}>
    {chipData.map((clientName, index) => (
      <Chip
        key={index}
        label={clientName}
        sx={{ marginRight: "8px", marginBottom: "8px" }}
      />
    ))}
  </Grid>
)}

        
      </Grid>
    </>
  );
}

export default TopClient;
