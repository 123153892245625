import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
const EditVendorEmails = ({
  emails,
  enquiry,
  openSupplierEmailPopUp,
  setOpenSupplierEmailPopUp,
  setEmails,
}) => {
  const [newEmailValue, setNewEmailValue] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [selectedSuppliersDetails, setSelectedSuppliersDetails] = useState([]);
  const [addEmailError, setAddEmailError] = useState(false);
  const [editEmailsData, setEditEmailsData] = useState(emails);

  const handleCloseEmailsBox = () => {
    setOpenSupplierEmailPopUp(false);
  };
  const handleChangeNewEmailValue = (value) => {
    setNewEmailValue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddNewEmail();
    }
  };
  const validateEmail = (email) => {
    const validationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid =
      validationRegex.test(email) &&
      !/\.(com|in)[^a-zA-Z0-9]/.test(email) &&
      !/\.{2,}/.test(email) &&
      !/\.,com$/.test(email);

    return isValid;
  };
  const handleAddNewEmail = () => {
    if (validateEmail(newEmailValue)) {
      if (selectedSuppliersDetails?.includes(newEmailValue)) {
        setEmailError(true);
        setTimeout(() => {
          setEmailError(false);
        }, 3000);
        setEmailErrorMsg("This email is already exist");
      } else {
        setEmailError(false);
        setSelectedSuppliersDetails((preVal) => [newEmailValue, ...preVal]);
        setEditEmailsData((preVal) => [...preVal, newEmailValue]);
        setNewEmailValue("");
      }
    } else {
      setEmailError(true);
      setEmailErrorMsg("Please enter a valid email");
      setTimeout(() => {
        setEmailError(false);
      }, 3000);
    }
  };
  const handleCheckboxChange = (email) => {
    if (editEmailsData?.includes(email)) {
      setEditEmailsData(
        editEmailsData?.filter((selectedEmail) => selectedEmail !== email)
      );
    } else {
      setEditEmailsData([...editEmailsData, email]);
    }
  };
  const handleUpdateSuppliersEmail = () => {
    if (editEmailsData?.length > 0) {
      setEmails(editEmailsData);
      setOpenSupplierEmailPopUp(false);
    } else {
      setAddEmailError(true);
      setTimeout(() => {
        setAddEmailError(false);
      }, 3000);
    }
  };

  useEffect(() => {
    setEditEmailsData(emails);
    //get select email and all email merge them and remove duplicate email so selected emails get priority
    setSelectedSuppliersDetails((preVal) => [
      ...new Set([...emails, ...preVal]),
    ]);
  }, [emails]);
  // console.log("111111 emails value", emails);
  // console.log("222222 enquiry value", enquiry);
  useEffect(() => {
    const allEmails =
      enquiry?.emails?.length > 0
        ? [
            ...new Set([
              ...enquiry?.emails,
              ...enquiry?.noteDescriptions?.flatMap((item) => item?.emails),
            ]),
          ]
        : [
            ...new Set([
              enquiry?.email,
              ...enquiry?.noteDescriptions?.flatMap((item) => item?.emails),
            ]),
          ];
    // console.log("333333 allEmails value", allEmails);
    setSelectedSuppliersDetails(
      allEmails.filter((email) => email !== undefined)
    );
  }, [enquiry]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        sx={{ maxHeight: "80%" }}
        onClose={handleCloseEmailsBox}
        open={openSupplierEmailPopUp}
      >
        <DialogTitle>{enquiry?.businessName}</DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleCloseEmailsBox}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid sx={{ padding: "10px 10px 0px" }}>
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="8px"
          >
            <TextField
              size="small"
              autoFocus
              name="name"
              id="name"
              placeholder="Add new email"
              sx={{ width: "100%" }}
              value={newEmailValue}
              onKeyDown={handleKeyDown}
              onChange={(e) => handleChangeNewEmailValue(e.target.value)}
            />
            <Box onClick={handleAddNewEmail} sx={{ cursor: "pointer" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 19 19"
                fill="none"
              >
                <rect
                  width="19"
                  height="19"
                  rx="4"
                  fill={newEmailValue?.length > 0 ? "#04EEAD" : "#707070"}
                />
                <path
                  d="M4 9.5C4.29333 9.5 11.4556 9.5 15 9.5M9.5 4V15"
                  stroke={newEmailValue?.length > 0 ? "black" : "white"}
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
          </Grid>
          {emailError && (
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              {emailErrorMsg}{" "}
            </Typography>
          )}
          {selectedSuppliersDetails?.map((email, index) => (
            <Grid key={index} sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={editEmailsData?.includes(email)}
                onChange={() => handleCheckboxChange(email)}
              />
              <Typography>{email}</Typography>
            </Grid>
          ))}
          <Grid
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              width: "100%",
              background: "#fff",
            }}
          >
            <Grid width="100%" display={"flex"} justifyContent={"center"}>
              <Button
                sx={{
                  // margin: "auto",
                  background: "#3963AB",
                  color: "#fff",
                  height: "36px",
                  mb: "10px",
                  "&:hover": {
                    background: "#314c7a",
                  },
                }}
                onClick={handleUpdateSuppliersEmail}
              >
                Add Selected Emails
              </Button>
            </Grid>
            {addEmailError && (
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "red",
                }}
              >
                Atleast one email is required
              </Typography>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default EditVendorEmails;
