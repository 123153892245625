import { Card, Grid, Skeleton } from '@mui/material'
import React from 'react'

const MobileSearchCategorySkeleton = () => {
    const showCardsData = ["a", "b", "c", "d"]

    return (
        <Grid container spacing={2} >
            {showCardsData?.map((data) => <Grid item  key={data}>
                <Card
                    sx={{
                        width: "354px",
                        height: "135px",
                        padding: "14px 16px",
                        marginBottom: "20px",
                    
                    }}
                >
                   
                    <Grid sx={{ display: "flex", gap: "15px", marginX: "8px" }}>
                        <Grid>
                            <Skeleton
                                variant='circular'
                                width={56}
                                height={56}
                            />
                        </Grid>
                        <Grid width="200px">
                            <Skeleton
                                width={"100%"}
                                height={"24px"}
                            />
                            <Skeleton
                                width={"80%"}
                                height={"20px"}
                            />

                            <Skeleton
                                width={"90%"}
                                height={"20px"}
                            />

                        </Grid>
                    </Grid>
                  <Skeleton
                            width={327}
                            height={40}
                        /> 
                </Card>
            </Grid>)}
        </Grid>

    )
}

export default MobileSearchCategorySkeleton