import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, Grid, Card, CardContent, Box } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const useStyles = makeStyles((theme) => ({
  hoverText: {
    color: "#000",
    "&:hover": {
      color: "#47B59E", // Change color on hover
      cursor: "pointer", // Change cursor on hover if needed
    },
  },
}));
function FooterNew() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  function handlescroll() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/")
    // return false;
  }
  function handlescrollAbout() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/about-us")
  }


 

  const classes = useStyles();

  return (
    <Grid sx={{ maxWidth: "1380px", margin: "auto", }}>
      <Grid
        sx={{
          maxWidth: "100vw",
          backgroundImage: "#FDFDFB",
          borderRadius: 0,
          color: "#000",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            height: "100%",
            // background:"#F4F8F7",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          {isMd ? (
            isSm ? (
              <>
                <Grid container xs={12}>
                  <Grid xs={12} sx={{
                    marginTop: "20px",
                    display: "flex", justifyContent: "center", marginBottom: "20px"
                  }}>
                    <Box
                      component="img"
                      sx={{
                        height: "71px",
                        width: "99px",

                        alignItems: "center",
                      }}
                      alt="logo"
                      src={`${BASE_URL}/assets/business/NEEVAY_logo_new_footer.svg`}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      color: "#000",
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "20px",
                      borderBottom: " 2px solid rgba(255, 255, 255, 0.3)",
                    }}
                    xs={12}
                  >
                    <Grid xs={6} >
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          marginBottom: "10px",
                          color: "#000",
                          letterSpacing: "0.8px",
                        }}
                      >
                        Office Address
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "4px",
                          color: "#000",
                        }}
                      >
                        410, Orville Business Port
                      </Typography>

                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "4px",

                          color: "#000",
                        }}
                      >
                        Viman nagar, Pune
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "20px",
                          color: "#000",
                        }}
                      >
                        Maharashtra - 411014
                      </Typography>
                    </Grid>
                    <Grid xs={5} >
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          marginBottom: "10px",
                          color: "#000",
                          letterSpacing: "0.8px",



                        }}
                      >
                        Follow Us
                      </Typography>
                      <Grid
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // gap: "7px",
                          justifyContent: "space-between"

                        }}
                      >
                        <Grid>
                          <a
                            href={"https://www.facebook.com/neevayofficial"}
                            target="_blank"
                          >
                            <FacebookIcon
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "#3b5998",
                              }}
                            />
                          </a>
                        </Grid>
                        <Grid>
                          <a
                            href={"https://www.instagram.com/neevay_official/"}
                            target="_blank"
                          >
                            <InstagramIcon
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "#d62976",
                              }}
                            />
                          </a>
                        </Grid>
                        <Grid>
                          <a
                            href={"https://twitter.com/NeevayOfficial"}
                            target="_blank"
                          >
                            <TwitterIcon
                              style={{
                                height: "22px",
                                width: "22px",
                                color: "#00acee",
                              }}
                            />
                          </a>
                        </Grid>
                        <Grid>
                          <a
                            href={
                              "https://www.youtube.com/channel/UC3dTz8WCJBJXHKWmewBlvfw"
                            }
                            target="_blank"
                          >
                            <YouTubeIcon
                              style={{
                                height: "22px",
                                width: "22px",
                                color: "#FF0000",
                              }}
                            />
                          </a>
                        </Grid>
                        <Grid>
                          <a
                            href={"https://www.linkedin.com/company/neevay/"}
                            target="_blank"
                          >
                            <LinkedInIcon
                              style={{
                                height: "22px",
                                width: "22px",
                                color: "#0A66C2",
                              }}
                            />
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      color: "#000",
                      width: "100%",
                      marginTop: "20px",
                      borderBottom: " 2px solid rgba(255, 255, 255, 0.3)"
                    }}
                    xs={12}
                  >
                    <Grid xs={6}>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          marginBottom: "10px",
                          color: "#000",
                          letterSpacing: "0.8px",
                        }}
                      >
                        General Enquiry
                      </Typography>

                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "4px",

                          color: "#000",
                        }}
                      >
                        enquiry@neevay.com{" "}
                      </Typography>
                    </Grid>
                    <Grid xs={5}>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          marginBottom: "10px",
                          color: "#000",
                          letterSpacing: "0.8px",
                          // textAlign:"right"
                        }}
                      >
                        Looking For Demo
                      </Typography>
                      <Grid
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          textAlign: "start"
                        }}
                      >
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "14px",
                              marginBottom: "4px",

                              color: "#000",
                            }}
                          >
                            demo@neevay.com{" "}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "14px",
                              marginBottom: "20px",
                              color: "#000",
                            }}
                          >
                            +919503339814
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "16px",
                      marginBottom: "10px",
                      marginTop: "20px",
                      color: "#000",
                      letterSpacing: "0.8px",
                    }}
                  >
                    Support
                  </Typography>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      color: "#000",
                      width: "100%",
                      borderBottom: " 2px solid rgba(255, 255, 255, 0.3)",
                    }}
                    xs={12}
                  >
                    <Grid xs={6}>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "4px",

                          color: "#000",
                        }}
                      >
                        support@neevay.com{" "}
                      </Typography>
                    </Grid>
                    <Grid xs={5}>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "20px",
                          // textAlign:"center",
                          color: "#000",
                        }}
                      >
                        +919503339814
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "16px",
                      marginBottom: "5px",
                      marginTop: "20px",
                      color: "#000",
                      letterSpacing: "0.8px",
                    }}
                  >
                    Quick Links
                  </Typography>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      color: "#000",
                      width: "100%",
                      marginTop: "10px",
                      borderBottom: " 2px solid rgba(255, 255, 255, 0.3)",
                    }}
                    xs={12}
                  >
                    <Grid xs={6}>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "10px",

                          color: "#000",
                        }}
                        onClick={handlescroll}
                      >
                        Home
                      </Typography>

                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "10px",

                          color: "#000",
                        }}
                      >
                        <Link
                          to={"/terms-and-condition"}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                          }}
                        >
                          Terms of Use
                        </Link>
                      </Typography>

                     
                    </Grid>
                    <Grid xs={5}>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "10px",
                          // textAlign:"center",
                          color: "#000",
                        }}
                      >
                        <Link
                          to={"/about-us"}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                          }}
                        >
                          About Us
                        </Link>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "4px",
                          // textAlign:"center",

                          color: "#000",
                        }}
                      >
                        <Link
                          to={"/privacy-policy"}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </Typography>
                      {/* <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "10px",
                          // textAlign:"center",
                          color: "#000",
                        }}
                      >
                        <Link
                          to={"/subscribe-plan"}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                          }}
                        >
                          Membership Fees
                        </Link>
                      </Typography> */}
                   
                    </Grid>

                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      padding: "20px",
                    }}
                  >
                    <Grid
                      style={{ display: "flex", flexDirection: "row" }}
                      xs={12}
                    >
                      <Typography
                        style={{
                          color:"black",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          // color: "rgba(255, 255, 255, 0.9)",
                          // textAlign: "center",
                        }}
                      >
                        Copyright @ 2024 . All rights reserved by Tathaatvam
                        Technologies Solutions Pvt. Ltd.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container xs={12}>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                     
                    color: "#000",
                    width: "100%",
                    marginTop: "20px",
                    borderBottom: " 2px solid rgba(255, 255, 255, 0.3)",
                  }}
                  xs={12}
                >
                  <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      General Enquiry
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",

                        marginBottom: "20px",

                        color: "#000",
                      }}
                    >
                      enquiry@neevay.com{" "}
                    </Typography>
                  </Grid>

                  <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      Looking for Demo
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "15px",
                        color: "#000",
                      }}
                    >
                      demo@neevay.com{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",

                        marginBottom: "15px",

                        color: "#000",
                      }}
                    >
                      +919503339814
                    </Typography>
                  </Grid>

                  <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      Support
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "15px",
                        color: "#000",
                      }}
                    >
                      Support
                    </Typography>

                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",

                        color: "#000",
                      }}
                    >
                      +919503339814
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      Follow Us
                    </Typography>
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px",
                      }}
                    >
                      <Grid item>
                        <a
                          href={"https://www.facebook.com/neevayofficial"}
                          target="_blank"
                        >
                          <FacebookIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#3b5998",
                            }}
                          />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href={"https://www.instagram.com/neevay_official/"}
                          target="_blank"
                        >
                          <InstagramIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#d62976",
                            }}
                          />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href={"https://twitter.com/NeevayOfficial"}
                          target="_blank"
                        >
                          <TwitterIcon
                            style={{
                              height: "22px",
                              width: "22px",
                              color: "#00acee",
                            }}
                          />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href={
                            "https://www.youtube.com/channel/UC3dTz8WCJBJXHKWmewBlvfw"
                          }
                          target="_blank"
                        >
                          <YouTubeIcon
                            style={{
                              height: "22px",
                              width: "22px",
                              color: "#FF0000",
                            }}
                          />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href={"https://www.linkedin.com/company/neevay/"}
                          target="_blank"
                        >
                          <LinkedInIcon
                            style={{
                              height: "22px",
                              width: "22px",
                              color: "#0A66C2",
                            }}
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    marginBottom: "20px",
                    marginTop: "20px",
                    color: "#000",
                  }}
                >
                  Quick Links
                </Typography>
                <Grid
                  item
                  style={{
                    display: "flex",

                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "#000",
                    width: "100%",
                    borderBottom: " 2px solid rgba(255, 255, 255, 0.3)",
                  }}
                  xs={12}
                >
                  <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "15px",
                        color: "#000",
                      }}
                      onClick={handlescroll}
                    >
                      Home
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "15px",
                        color: "#000",
                      }}
                      onClick={handlescrollAbout}
                    >
                      About Us
                    </Typography>
                  </Grid>
                  {/* <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      <Link
                        to={"/subscribe-plan"}
                        style={{
                          textDecoration: "none",
                          color: "#000",
                        }}
                      >
                        Membership Fees
                      </Link>
                    </Typography>
                  </Grid> */}
                  <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "15px",
                        color: "#000",
                      }}
                    >
                      <Link
                        to={"/terms-and-condition"}
                        style={{
                          textDecoration: "none",
                          color: "#000",
                        }}
                      >
                        Terms of Use
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "15px",
                        color: "#000",
                      }}
                    >
                      <Link
                        to={"/privacy-policy"}
                        style={{
                          textDecoration: "none",
                          color: "#000",
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "#000",
                    width: "100%",
                    marginTop: "20px",

                    borderBottom: " 2px solid rgba(255, 255, 255, 0.3)",
                  }}
                  xs={12}
                >
                  <Grid item xs={3}>
                    <Grid>
                      <Box
                        component="img"
                        sx={{
                          height: "61px",
                          width: "85px",
                          marginBottom: "20px",
                        }}
                        alt="logo"
                        src={`${BASE_URL}/assets/business/NEEVAY_logo_new_footer.svg`}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={9}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "15px",
                        color: "#000",
                        letterSpacing: "0.8px",
                      }}
                    >
                      Office Address
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "10px",
                        color: "#000",
                      }}
                    >
                      410, Orville Business Port, Viman nagar, Pune, Maharashtra
                      - 411014
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginTop: "28px",
                    marginBottom: "28px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color:"black",
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "13px",
                        lineHeight: "15px",
                        // color: "rgba(255, 255, 255, 0.9)",
                        textAlign: "center",
                      }}
                    >
                      Copyright @ 2024 . All rights reserved by Tathaatvam
                      Technologies Solutions Pvt. Ltd.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
          ) : (

            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "#000",
                width: "100%",
                // paddingLeft: "70px",
                // paddingRight: "70px",
              }}
              xs={12}
            >
              <Grid item sx={3}>
                <Grid
                  container
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                    gap: "20px",
                  }}
                >
                  <Grid
                    item
                    style={{
                      marginTop: "12px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: "70px",
                        width: "100px",
                      }}
                      alt="logo"
                      src={`${BASE_URL}/assets/business/NEEVAY_logo_new_footer.svg`}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginBottom: "30px",
                      maxWidth: "180px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "5px",
                        color: "#000",
                        letterSpacing: "0.8px",
                      }}
                    >
                      Office Address
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "4px",
                        color: "#000",
                      }}
                    >
                      410, Orville Business Port
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "4px",

                        color: "#000",
                      }}
                    >
                      Viman nagar, Pune
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",

                        color: "#000",
                      }}
                    >
                      Maharashtra - 411014
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sx={9}
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "2px solid rgba(255, 255, 255, 0.3)",
                    paddingBottom: "25px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      General Enquiry
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",

                        color: "#000",
                      }}
                    >
                      enquiry@neevay.com{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      Looking for Demo
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "15px",
                        color: "#000",
                      }}
                    >
                      demo@neevay.com{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#000",
                      }}
                    >
                       +919503339814
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      Support
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginBottom: "15px",
                        color: "#000",
                      }}
                    >
                      support@neevay.com{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",

                        color: "#000",
                      }}
                    >
                      +919503339814
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      Follow Us
                    </Typography>
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px",
                      }}
                    >
                      <Grid item>
                        <a
                          href={"https://www.facebook.com/neevayofficial"}
                          target="_blank"
                        >
                          <FacebookIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#3b5998",
                            }}
                          />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href={"https://www.instagram.com/neevay_official/"}
                          target="_blank"
                        >
                          <InstagramIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#d62976",
                            }}
                          />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href={"https://twitter.com/NeevayOfficial"}
                          target="_blank"
                        >
                          <TwitterIcon
                            style={{
                              height: "22px",
                              width: "22px",
                              color: "#00acee",
                            }}
                          />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href={
                            "https://www.youtube.com/channel/UC3dTz8WCJBJXHKWmewBlvfw"
                          }
                          target="_blank"
                        >
                          <YouTubeIcon
                            style={{
                              height: "22px",
                              width: "22px",
                              color: "#FF0000",
                            }}
                          />
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href={"https://www.linkedin.com/company/neevay/"}
                          target="_blank"
                        >
                          <LinkedInIcon
                            style={{
                              height: "22px",
                              width: "22px",
                              color: "#0A66C2",
                            }}
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: "2px solid rgba(255, 255, 255, 0.3)",
                    marginTop: "25px",
                    paddingBottom: "25px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        marginBottom: "20px",
                        color: "#000",
                      }}
                    >
                      Quick Links
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >

                      <Grid
                        item
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          cursor: "pointer",
                          borderRight: "1px solid white",
                          paddingRight: "20px",
                        }}
                        onClick={handlescroll}
                        className={classes.hoverText}
                      >
                        Home
                      </Grid>

                      <Grid
                        item
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          cursor: "pointer",
                          borderRight: "1px solid white",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <Link
                          className={classes.hoverText}
                          to={"/about-us"}
                          onClick={handlescrollAbout}
                          style={{ textDecoration: "none" }}
                        >
                          About Us
                        </Link>
                      </Grid>
                      {/* <Grid
                        item
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          cursor: "pointer",
                          borderRight: "1px solid white",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <Link
                          className={classes.hoverText}
                          to={"/subscribe-plan"}
                          style={{ textDecoration: "none" }}
                        >
                          Pricing
                        </Link>
                      </Grid> */}
                      <Grid
                        item
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          cursor: "pointer",
                          borderRight: "1px solid white",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <Link
                          to={"/terms-and-condition"}
                          className={classes.hoverText}
                          style={{ textDecoration: "none" }}
                        >
                          Terms of Use
                        </Link>
                      </Grid>
                      <Grid
                        item
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "16px",
                          cursor: "pointer",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <Link
                          to={"/privacy-policy"}
                          className={classes.hoverText}
                          style={{ textDecoration: "none" }}
                        >
                          Privacy Policy
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "28px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "13px",
                        lineHeight: "15px",
                        color: "balck",
                      }}
                    >
                      Copyright @ 2024 . All rights reserved by Tathaatvam
                      Technologies Solutions Pvt. Ltd.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Grid>
    </Grid>
  );
}

export default FooterNew;
