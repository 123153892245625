import { Card, CardMedia, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import { useNavigate } from "react-router-dom";

function MobileSearchCardContainer({
  count,
  businessCategory,
  searchString,
  searchBy,
  numFound,
  region
}) {
  const navigate = useNavigate();

  const imgArray = {
    Contractors: "https://blobcontaineremaildemo.blob.core.windows.net/emailcontainer/contractorImg.svg",
    Supplier: "https://blobcontaineremaildemo.blob.core.windows.net/emailcontainer/supplier.svg",
    Consultants: "https://blobcontaineremaildemo.blob.core.windows.net/emailcontainer/consultant.svg",
    "Labour Contractor": "https://blobcontaineremaildemo.blob.core.windows.net/emailcontainer/labor.svg",
    "Real Estate Developer": "https://blobcontaineremaildemo.blob.core.windows.net/emailcontainer/RealEstate.svg",
  };

  const handleAllCategoryResults = () => {
    // if (searchBy) {
    navigate(
      `/search-business-category/${searchString}/${region}?Category=${businessCategory}`
    );
    // } else {
    //   navigate(
    //     `/search-business-category/${searchString}/all?Category=${businessCategory}`
    //   );
    // }
  };

  return (
    <Card
      sx={{
        maxWidth: "352px",
        height: "212px",
        margin: "24px 14px 0",
      }}
      onClick={handleAllCategoryResults}
    >
      <CardMedia
        component="img"
        height="150"
        width="354px"
        // image="/assets/home/contractorImg.svg"
        image={imgArray[businessCategory] || "/assets/home/contractorImg.svg"}
        alt="category"
      />

      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding=" 8px 14px"
      >
        <Grid>
          <Typography fontSize="18px" fontWeight="600">
            {businessCategory}
          </Typography>
          {count > 0 && (
            <Typography sx={{ color: "#757373", fontSize: "14px" }}>
              {count}{" "}
              {businessCategory[businessCategory?.length - 1] === "s"
                ? businessCategory
                : numFound > 1
                  ? businessCategory + "s"
                  : businessCategory}{" "}
              Found
            </Typography>
          )}
        </Grid>

        <ArrowForwardIosIcon
          onClick={handleAllCategoryResults}
          style={{ height: "30px", width: "20px" }}
        />
      </Grid>
    </Card>
  );
}

export default MobileSearchCardContainer;
