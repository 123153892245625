import React from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Minimal from "layouts/Minimal";
import { Helmet } from "react-helmet";

import AboutNeevay from "../components/AboutNeevay";
import SignUpMobile from "./SignUpMobile";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Link } from "react-router-dom";
import SignUpMobileForm from "./SignUpMobileForm";
import SignUpMobileFormNew from "./SignUpMobileFormNew";

function SignUp() {
  const theme = useTheme();
  const [isWhatsappNo, setIsWhatsappNo] = React.useState(false)
  const handleSubmitSuccess = (mobile) => {
    navigate("/signup-mobile-otp-verify", { state: { "sourceName": sourceName || "web app", isWhatsappNo: isWhatsappNo, mobile:mobile } });
  };

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { sourceName } = useParams();
  console.log("register", sourceName);

  const navigate = useNavigate();

  // const handleSubmitSuccess = () => {
  //   navigate("/signup-mobile-otp-verify", {
  //     state: { sourceName: sourceName || "web app" },
  //   });
  // };

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const backgroundImage = `url("${BASE_URL}/assets/business/web-registerpage-registerBgImage.png")`;
  const backgroundImageMb = `url("${BASE_URL}/assets/business/web-registerpage-registerBgImageMb.svg")`;

  const styles = {
    container: {
      height: "100vh",
      width: "100vw",
      background: backgroundImage,
      objectFit:"cover",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"

    },
    mbContainer: {
      height: "100vh",
      width: "100vw",
      background: backgroundImageMb,
      objectFit:"cover",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    },
  };

  return (
    <>
      <Helmet>
        <title>Neevay - Register</title>
      </Helmet>
      {isMd? <Box sx={styles.container}>
        <Grid container>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "100vh",
              width: "50vw",
            }}
          >
            <Typography
              style={{
                color: "#E1E1E1",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "38px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "53px",
                textTransform: "uppercase",
                width: "407px",
                height: "53px",
                letterSpacing:"0.5px"

              }}
            >
              Welcome to Neevay
            </Typography>
            <Typography
              style={{
                color: "#B9B9C3",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "32px",
                width: "489px",
                height: "64px",
              }}
            >
              Be a part of Growing Digital Ecosystem of Construction.{" "}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "100vh",
              width: "50vw",
            }}
          >
            <Box
              style={{
                width: "328px",
                height: "357px",
                borderRadius: "10px",
                background: "rgba(0, 0, 0, 0.64)",
                padding: "0",
                position: "relative",
              }}
            >
              <Grid
                style={{
                  width: "100%",
                  height: "53px",
                  backgroundColor: "#000",
                  borderRadius: "10px 10px 0 0",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <Typography
                  style={{
                    color: "#B9B9C3",

                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "53px" /* 220.833% */,
                    textTransform: "uppercase",
                  }}
                >
                  REGISTER
                </Typography>
                <Box
                  component="img"
                  sx={{
                    height: 22,
                    width: 22,
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer",
                  }}
                  alt="green vector"
                  src="/assets/icons/crossIconRegisterForm.svg"
                  onClick={() => navigate("/")}
                />
              </Grid>
              <Grid style={{
                  width: "100%",
                  position: "absolute",
                  top: "80px",
                  display:"flex",
                  flexDirection:"column",
                  alignItems:"center",
                  justifyContent:"center",
                  alignItems:"center"
                }}>

                  <SignUpMobileFormNew                 
                  isWhatsappNo={isWhatsappNo}
                  setIsWhatsappNo={setIsWhatsappNo}
                  onSubmitSuccess={handleSubmitSuccess}/>

              </Grid>
              <Grid
                style={{
                  width: "100%",
                  height: "31px",
                  backgroundColor: "#000",
                  borderRadius: "0 0 10px 10px",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center"
                }}
              >
                <Typography  style={{
                    color: "#B9B9C3",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}>Our</Typography>
                <Link
                to={"/terms-and-condition"}
                  style={{
                    color: "#B9B9C3",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    marginLeft: "5px"

                    // lineHeight: "53px",
                  }}
                >
                  Terms of Use 
                </Link>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>:
      <Box sx={styles.mbContainer}>
      <Grid container>
      <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "100vw",
              marginTop:"50px"
            }}
          >
            <Typography
              style={{
                color: "#E1E1E1",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "30px",
                textTransform: "uppercase",
                letterSpacing:"0.5px"
              }}
            >
              Welcome to Neevay
            </Typography>
            <Typography
              style={{
                color: "#B9B9C3",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              Be a part of Growing Digital Ecosystem of Construction.{" "}
            </Typography>
          </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100vw",
            marginTop: "47px"
          }}
        >
          <Box
            style={{
              width: "328px",
              height: "357px",
              borderRadius: "10px",
              background: "rgba(0, 0, 0, 0.64)",
              padding: "0",
              position: "relative",
              
            }}
          >
            <Grid
              style={{
                width: "100%",
                height: "53px",
                backgroundColor: "#000",
                borderRadius: "10px 10px 0 0",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <Typography
                style={{
                  color: "#B9B9C3",

                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "53px" /* 220.833% */,
                  textTransform: "uppercase",
                }}
              >
                REGISTER
              </Typography>
              <Box
                component="img"
                sx={{
                  height: 22,
                  width: 22,
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer",
                }}
                alt="green vector"
                src="/assets/icons/crossIconRegisterForm.svg"
                onClick={() => navigate("/")}
              />
            </Grid>
            <Grid style={{
                width: "100%",
                position: "absolute",
                top: "80px",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"center",
                alignItems:"center"
              }}>

                <SignUpMobileFormNew 
                isWhatsappNo={isWhatsappNo}
                setIsWhatsappNo={setIsWhatsappNo}
                onSubmitSuccess={handleSubmitSuccess}/>

            </Grid>
            <Grid
              style={{
                width: "100%",
                height: "31px",
                backgroundColor: "#000",
                borderRadius: "0 0 10px 10px",
                position: "absolute",
                bottom: 0,
                left: 0,
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
              }}
            >
              <Typography  style={{
                  color: "#B9B9C3",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}>Our</Typography>
              <Link
              to={"/terms-and-condition"}
                style={{
                  color: "#B9B9C3",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  marginLeft: "5px"

                  // lineHeight: "53px",
                }}
              >
                Terms of Use 
              </Link>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
      }
      
    </>
  );
}

export default SignUp;
