import { Card, Grid, Skeleton } from '@mui/material'
import React from 'react'
import SearchCardSkeleton from './SearchCardSkeleton'

const SearchContainerSkeleton = () => {
    const showCardsData = ["a", "b", "c", "d"]

    return (<>
        {showCardsData.map((data) => <Grid sx={{ backgroundColor: "white", padding: "20px 12px", borderRadius: "16px", marginBottom: "40px" }}>
            <Grid display="flex" justifyContent="space-between" alignItems="center" marginBottom={"18px"}>

                <Skeleton
                    width={300}
                    height={40}
                />

                <Skeleton
                    width={100}
                    height={35}
                />
            </Grid>

            <SearchCardSkeleton />

        </Grid >)}
    </>)
}

export default SearchContainerSkeleton