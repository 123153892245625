import { useTheme } from "@emotion/react";
import {
  Box,
  Chip,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useMediaQuery,
  ClickAwayListener,
} from "@mui/material";
import React, { useState } from "react";

const RfqToCcSection = ({
  businessName,
  isSmallScreen,
  newCcEmails,
  setNewCcEmails,
  handleChange,
  values,
  errors,
  touched,
  handleOnBlur,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const [openAddEmailDoalog, setOpenAddEmailDoalog] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showMoreAllAddedEmails, setShowMoreAllAddedEmails] = useState(false);
  const [expandCcList, setExpandCcList] = useState(true);

  const handleClickAwayCcList = () => {
    setExpandCcList(false);
  };
  const addEmailOnclick = () => {
    setOpenAddEmailDoalog(true);
  };
  const handleShowAddedEmails = () => {
    // setShowMoreAllAddedEmails(true);
    setExpandCcList(true);
  };
  const handleDeleteNewCcEmails = (emailData) => {
    setNewCcEmails((preVal) => preVal.filter((email) => email !== emailData));
  };
  const handleCloseAddEmailDoalog = () => {
    setOpenAddEmailDoalog(false);
  };
  const handleCloseAddedEmails = () => {
    setShowMoreAllAddedEmails(false);
  };
  const validateEmail = (email) => {
    const validationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid =
      validationRegex.test(email) &&
      !/\.(com|in)[^a-zA-Z0-9]/.test(email) &&
      !/\.{2,}/.test(email) &&
      !/\.,com$/.test(email);

    return isValid;
  };

  const handleAddEmail = () => {
    if (validateEmail(newEmail)) {
      setNewCcEmails([...newCcEmails, newEmail]);
      setNewEmail("");
      setEmailError(false);
      setOpenAddEmailDoalog(false);
    } else {
      setEmailError(true);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddEmail();
    }
  };
  return (
    <>
      <ClickAwayListener onClickAway={() => handleClickAwayCcList()}>
        <Grid
          borderBottom={"1px #CFDAE5 solid"}
          paddingY={"10px"}
          display={"flex"}
          alignItems={"baseline"}
        >
          <Grid
            marginRight={isSmallScreen ? "8px" : "68px"}
            display={"flex"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#4C4C4C" }}>cc</Typography>

            <Tooltip title="Requirement will be copied to these email addresses when sent to above list of vendors. If left blank, no one will be copied in email">
              <Box
                component="img"
                sx={{
                  marginX: "8px",
                  height: "18px",
                  width: "18px",
                }}
                alt="logo"
                src="/assets/icons/questionMarkLogo.svg"
              />
            </Tooltip>
          </Grid>
          <Grid display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
            {newCcEmails?.length > 4 && expandCcList
              ? newCcEmails?.map((email) => (
                  <Chip
                    label={email}
                    variant="outlined"
                    size="small"
                    onDelete={() => handleDeleteNewCcEmails(email)}
                    sx={{
                      maxWidth: !isSm ? "170px" : "auto",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "4px",
                    }}
                  />
                ))
              : newCcEmails?.slice(0, 4)?.map((email) => (
                  <Chip
                    label={email}
                    variant="outlined"
                    size="small"
                    onDelete={() => handleDeleteNewCcEmails(email)}
                    sx={{
                      maxWidth: !isSm ? "170px" : "auto",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "4px",
                    }}
                  />
                ))}
            {(newCcEmails.length <= 4 || expandCcList) && (
              <Grid onClick={addEmailOnclick}>
                <Typography
                  sx={{
                    ml: "10px",
                    fontSize: "14px",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Add Email +
                </Typography>
              </Grid>
            )}
            {newCcEmails.length > 4 && !expandCcList && (
              <Typography
                sx={{
                  fontSize: "14px",
                  margin: "4px",
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={handleShowAddedEmails}
              >
                Show all...
              </Typography>
            )}
          </Grid>
        </Grid>
      </ClickAwayListener>

      <Grid
        padding="6px 0px 10px"
        gap={isSmallScreen ? "8px" : "36px"}
        borderBottom={"1px #CFDAE5 solid"}
        display={"flex"}
        flexWrap={"wrap"}
        alignItems="baseline"
      >
        <Typography sx={{ color: "#4C4C4C" }}>Subject</Typography>
        <Grid display={"flex"} flexWrap={"wrap"}>
          <Typography>Quotation Request for {businessName} :</Typography>
          <TextField
            sx={{ ml: "11px", width: "237px" }}
            variant="standard"
            name="name"
            required
            id="name"
            placeholder="Label"
            onChange={handleChange}
            onBlur={() => handleOnBlur("name", values.name)}
            inputProps={{ maxLength: 100 }}
            value={values.name}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>
      </Grid>

      <Dialog
        open={openAddEmailDoalog}
        onClose={handleCloseAddEmailDoalog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add Email To CC</DialogTitle>
        <DialogContent>
          <DialogContentText>Add one email at a time</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={newEmail}
            error={emailError}
            helperText={emailError ? "Please enter a valid email" : ""}
            onKeyDown={handleKeyDown}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddEmailDoalog}>Cancel</Button>
          <Button onClick={handleAddEmail}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={showMoreAllAddedEmails}
        onClose={handleCloseAddedEmails}
      >
        <DialogContent>
          {newCcEmails?.map((email) => (
            <Chip
              size="small"
              label={email}
              variant="outlined"
              onDelete={() => handleDeleteNewCcEmails(email)}
              sx={{
                maxWidth: !isSm ? "170px" : "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                margin: "4px",
              }}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddedEmails}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RfqToCcSection;
