import React, {  useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {Topbar} from "layouts/Main/components";
import { AppBar } from "@mui/material";
import Page1 from "views/UserProfile/components/Page1";
import { Helmet } from "react-helmet";
import RequirementForm from "../components/RequirementForm";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FooterNew from "views/Home/components/FooterNew";
import { FloatingButton, GlobalNavbar } from "views/Home 3.0/components";


function SendEnquiry({ }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const handleSubmitSuccess = () => {
    navigate("/requirements"); // TODO_SP this should redirecto to previous page
  };
  const [crmIdOfOwners, setCrmIdOfOwners] = useState([])
     const requirement = useSelector((state) => state.requirement);
  const enquiry = requirement.enquiryToSend;
  const requirementToEdit = requirement.requirementToEdit;
  
  useEffect(() => {
    if (enquiry && enquiry.some(item => item.businessOwnerCrmIds)) {
      setCrmIdOfOwners(enquiry.map(item => item.businessOwnerCrmIds));
    }
  }, [enquiry]);


  const pages = {
    landings: [
      {
        title: "Sign Up",
        href: "/sign-up",
      },
      {
        title: "Sign In",
        href: "/sign-in",
      },
    ],

    profile: [
      {
        title: "My Profile",
        href: "/my-profile",
      },
    ],
    jobs: [
      {
        title: "Full Time",
        href: "/jobs-fulltime",
      },
      {
        title: "Part Time",
        href: "/jobs-partime",
      },
    ],
    account: [
      {
        title: "General Settings",
        href: "/account-general",
      },
      {
        title: "Security Settings",
        href: "/account-security",
      },
    ],
    blog: [
      {
        title: "Blog 1",
        href: "/blog-1",
      },
      {
        title: "Blog 2",
        href: "/blog-2",
      },
      {
        title: "Newsroom",
        href: "/blog-newsroom",
      },
      {
        title: "Article",
        href: "/blog-article",
      },
    ],
  };

  return (
    <>
     <Helmet>
        <title>Neevay - Requirements</title>
      </Helmet>
      {/* <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: "#262728",
          paddingY: "10px",
        }}
      >
        {/* <Topbar pages={pages} /> */}
      {/* </AppBar> */}
      <GlobalNavbar/>

      <Page1
        enquiry={enquiry}
        requirement={requirement}
      >
        <RequirementForm
          isNewRequirement={true}
          isNewRFQSend={enquiry[0]?.rfqId ? false : true}
          isExistingRFQSend={enquiry[0]?.rfqId ? true : false}
          enquiry={enquiry}
          multipleOwnerId={crmIdOfOwners}
          requirement={requirementToEdit}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </Page1>
      <FooterNew/>
      <FloatingButton />
    </>
  );
}


SendEnquiry.propTypes = {};

export default SendEnquiry;
