import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "utils/axios";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { regionsActions } from "actions/regionsActions";
// import regionsActions from "actions/regionsActions";

function SearchCityMobileView({ handleClickOnRegion, searchTerm }) {
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.regions.regionDistricts);
  // const [regions, setRegions] = useState([])
  const mostPopularSerchesData = ["Pan India", "Pune", "Mumbai", "Delhi", "Bengaluru"];
  const filteredDistricts = regions?.filter((district) =>
    district.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    dispatch(regionsActions());
  }, []);
  return (
    <>
      <Grid display={"flex"} justifyContent={"start"} zIndex={1}>
        <Box component={Grid} padding={"0 12px 12px"}>
          {searchTerm != "" ? (
            filteredDistricts?.length !== 0 ? (
              filteredDistricts?.map((data) => (
                <Grid

                  sx={{ color: "#757373", cursor: "pointer" }}
                  alignItems="center"
                  onClick={() => handleClickOnRegion(data)}
                  spacing={1}
                >
                  <Grid >
                    <NorthWestIcon
                      sx={{ color: "#cccccc", fontSize: "small" }}
                    />
                  </Grid>
                  <Grid >
                    <Typography variant="body2" sx={{ fontSize: "14px" }}>
                      {data}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography sx={{ color: "#cccccc" }}>No result found</Typography>
            )
          ) : (
            <Grid sx={{ color: "#757373" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "8px 0 2px",
                }}
              >
                Popular Regions
              </Typography>
              {mostPopularSerchesData?.map((data) => (
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  onClick={() => handleClickOnRegion(data)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <NorthWestIcon
                        sx={{ color: "#cccccc", fontSize: "small" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" sx={{ fontSize: "14px" }}>
                        {data}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "8px 0 2px",
                }}
              >
                Other Regions
              </Typography>
              {regions?.map((data) => (
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  onClick={() => handleClickOnRegion(data)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      {/* <SearchIcon fontSize="small" /> */}
                      <NorthWestIcon
                        sx={{ color: "#cccccc", fontSize: "small" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" sx={{ fontSize: "14px" }}>
                        {data}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid>{/* <NorthWestIcon fontSize="small" /> */}</Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Grid>
    </>
  );
}

export default SearchCityMobileView;
