import {
  Chip,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addFavourites } from "actions/favouritesAction";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ShortlistedTags = ({ profile, isEditView }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down(768));
  const [defaultValues, setDefaultValues] = useState([]);

  const supplierId = profile?.supplierId;
  const businessName = profile?.businessName;
  const businessEmail = profile?.businessProfile?.businessEmail;
  const contact = profile?.businessProfile?.contact;
  const subscriptionType = profile?.subscriptionType;
  const url = profile?.url;
  const favourite = useSelector((state) => state.favourite.favourites);
  const thisData = favourite.filter((data) => data.supplierId == supplierId);

  const colors = [
    "#F9FFD2",
    "#E7E7FF",
    "#FDE6FD",
    "#FFDBE0",
    "#E2FAF3",
    "#FAF2FB",
    "#FAEDE6",
    "#FFF6D9",
    "#E8F9F1",
    "#DEEDFC",
  ];

  const handleDeleteTags = (data) => {
    const selectedTags = defaultValues?.filter(
      (chip) => chip.tagName !== data.tagName
    );
    setDefaultValues(() => selectedTags);

    let favTags = selectedTags?.map((data) => data.tagName + ", ");
    // dispatch(addFavourites(supplierId, businessName, favTags));
    const isPremium = subscriptionType === "PREMIUM";
    dispatch(
      addFavourites(
        supplierId,
        businessName,
        favTags,
        businessEmail,
        contact,
        isPremium,
        url
      )
    );
  };

  useEffect(() => {
    const values =
      thisData[0]?.tags === undefined
        ? []
        : thisData[0]?.tags.map((tag) => {
            return { tagName: tag.slice(0, -2) };
          });

    setDefaultValues(values);
  }, [profile, favourite]);
  return (
    <>
      {!isEditView && defaultValues?.length > 0 && (
        <Grid
          sx={{
            padding: isSm ? "0px 10px 24px" : "0px 25px 24px",
            gap: "10px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>Tags </Typography>
          <Grid sx={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
            {defaultValues?.map((data, i) => (
              <Tooltip
                title={data?.tagName?.length > 15 ? data?.tagName : null}
              >
                <Chip
                  sx={{
                    marginX: "5px",
                    color: "#555",
                    backgroundColor: `${colors[i % 10]}`,
                    maxWidth: "140px",
                    height: "20px",
                    fontSize: "12px",
                  }}
                  onDelete={() => handleDeleteTags(data)}
                  deleteIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <path
                        d="M1 0.5L7 7.5M7 0.5L1 7.5"
                        stroke="#555555"
                        strokeWidth="0.5"
                      />
                    </svg>
                  }
                  label={data?.tagName}
                />
              </Tooltip>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ShortlistedTags;
