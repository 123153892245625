import {
  CircularProgress,
  Grid,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { search as searchAction } from "actions/searchActions";
import SearchCardsContainer from "./components/SearchCardsContainer";
import SearchTopSection from "./components/SearchTopSection";
import SearchContainerSkeleton from "./components/SearchContainersSkeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import MobileSearchCardContainer from "./components/MobileSearchCardContainer";
import MobileSearchSkeleton from "./components/MobileSearchSkeleton";
import { Helmet } from "react-helmet";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import { getUrl } from "actions/businessActions";
import { Box } from "@mui/system";
import { FloatingButton } from "views/Home 3.0/components";
import { fetchFavourites } from "actions/favouritesAction";
import SearchTopSectionMobile from "./components/SearchTopSectionMobile";

const NewSearch = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const { searchString, region } = useParams();
  const regionString = region
    ?.replace(/-/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  const search = useSelector((state) => state.search);
  const isSearching = search.isSearching;
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate()

  const [businessCategoryResults, setBusinessCategoryResults] = useState({});
  const [showResults, setShowResults] = useState(false); //when data is not undefine and completely sorted then they show all data
  const [openLogInModal, setOpenLogInModal] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);

  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const queryParams = queryString.parse(location.search);

  const results = search?.results;
  const numFound = search?.numFound;
  const searchKeyWord = search?.searchKeyWord;
  const searchType = search?.searchType;
  const businessCategory = search?.facets?.filter(
    (facet) => facet?.title == "Category"
  )?.[0]?.facets;
  useEffect(() => {
    if (results?.length > 0) {
      const businessCategoryResultsObj = {};
      for (const business of results) {
        if (!businessCategoryResultsObj[business?.businessCategory]) {
          businessCategoryResultsObj[business?.businessCategory] = [];
        }
        businessCategoryResultsObj[business?.businessCategory].push(business);
      }
      setBusinessCategoryResults((preVal) => businessCategoryResultsObj);
      setShowResults(true);
    }
  }, [businessCategory, results]);

  useEffect(() => {
    if (searchKeyWord) {
    } else {
      let searchBy = queryParams?.searchby;
      const modifiedSearchKeyWord = searchString?.replace(/-/g, " "); // we use becuse when some one serch with space our parent component code replace space to "-" so we revert
      // if (searchBy) {
      //   // we use this is because when searchby is by defult all then no value given from params if value is not undefine then our other code will be run
      //   const searchOption = searchBy === "service" ? "byService" : "byName"; // when type is byName but for user perspective we give the not byNmae or byService we only give name and service
      //   try {
      //     dispatch(searchAction(modifiedSearchKeyWord + searchOption));
      //   } catch (error) {
      //     console.log("SearchBar Error: ", error);
      //   }
      // } else {
      try {
        dispatch(searchAction(modifiedSearchKeyWord, regionString));

      } catch (error) { }
    }
    // }
  }, [searchKeyWord]);
  useEffect(() => {
    try {
      dispatch(getUrl(location.pathname));
      dispatch(fetchFavourites());
    } catch (error) {
      // console.log(error);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>

      <Grid backgroundColor="#EFF7FA" minHeight="100vh">
       {!isSm && <SearchTopSection
          checkedItems={checkedItems}
          searchKeyWord={searchKeyWord}
          searchType={searchType}
          numFound={search?.numFound}
        />}
       {isSm && <SearchTopSectionMobile
          checkedItems={checkedItems}
          searchKeyWord={searchKeyWord}
          searchType={searchType}
          numFound={search?.numFound}
        />}
        {isSearching ? (
          <Box
            sx={{
              position: "absolute",
              top: "55%",
              left: "55%",
              transform: "translate(-50%,-50%)",
              zIndex: 999,
            }}
          >
            <CircularProgress sx={{color:"#FD6600"}} />
          </Box>
        ) : numFound > 0 ? (
          <>
            {" "}
            {isSm ? (
              !showResults ? (
                <MobileSearchSkeleton />
              ) : (
                businessCategory?.map((facet) => (
                  <MobileSearchCardContainer
                    numFound={search?.numFound}
                    count={facet.count}
                    searchBy={queryParams?.searchby}
                    searchString={searchString}
                    businessCategory={facet.label}
                    showSkeletonData={showResults}
                    region={region}
                  // img={imgArray.map(item=>{item.img})}
                  />
                ))
              )
            ) : (
              <Grid paddingX={"14px"} marginTop={"12px"}>
                {!showResults ? (
                  <SearchContainerSkeleton />
                ) : (
                  businessCategory?.map((facet) => (
                    <SearchCardsContainer
                      checkedItems={checkedItems}
                      setCheckedItems={setCheckedItems}
                      searchBy={queryParams?.searchby}
                      searchString={searchString}
                      businessCategory={facet.label}
                      count={facet.count}
                      results={businessCategoryResults[facet.label]}
                      showResults={showResults}
                      regionString={regionString}
                      region={region}
                    // imgArray={imgArray}
                    />
                  ))
                )}
              </Grid>
            )}
          </>
        ) : (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isSm ? "100px" : "70px",
            }}
          >
            <Box component="img" src="/assets/home/NoResultFoundImg.svg" />
          </Grid>
        )}
      </Grid>
      {!user && (
        <div
          style={{
            backdropFilter: "blur(2px)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent gray background
            zIndex: 999,
          }}
        >
          <LogInMobilePopUp
            setOpenLogInModal={setOpenLogInModal}
            handleCloseLogInModal={() => {
              // setOpenLogInModal(false)
              navigate("/")
            }}
            openLogInModal={openLogInModal}
          />
        </div>
      )}
      <FloatingButton />
    </>
  );
};

export default NewSearch;
