import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

function BusinessNavbar({
  scrollContainerRef,
  profile,
  projects,
  IsSearchPublicProfile,
}) {
  const certificate = profile?.businessProfile?.certificateImages || [];
  const awards = profile?.awards || [];
  const AwardsCertificationArray = [...certificate, ...awards];
  const components = [
    { id: "component1", label: "About Us" },
    { id: "component3", label: "Business Offerings" },
    { id: "component4", label: "Projects Done" },
    { id: "component5", label: "Top Clients" },
    { id: "component6", label: "Awards & Certification" },
  ];
  const filteredComponents = components.filter((component) => {
    if (
      component.id === "component1" ||
      component.id === "component2" ||
      (component.id === "component3" && profile?.services?.length > 0) ||
      (component.id === "component4" && projects?.length > 0) ||
      (component.id === "component5" && profile?.clients?.length > 0) ||
      (component.id === "component6" && AwardsCertificationArray?.length > 0)
    ) {
      return component;
    }
    return false;
  });

  const [activeComponent, setActiveComponent] = useState(components[0].id);
  const scrollToComponent = (componentId) => {
    const element = document.getElementById(componentId);
    const targetPosition = element?.offsetTop - 160;

    // element?.scrollIntoView({ behavior: "smooth", block: 'start' });
    // scrollContainerRef?.current?.scrollTop = targetPosition;
    scrollContainerRef.current.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
    if (filteredComponents[filteredComponents?.length - 1]?.id == componentId)
      setTimeout(() => {
        setActiveComponent(componentId);
      }, 1000);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = scrollContainerRef.current.scrollTop;
      for (const component of components) {
        const element = document.getElementById(component.id);
        if (element) {
          const offsetTop = element.offsetTop - 180; //top nav height 80 + BusinessNavbar height 60 + top padding 40 =180
          const offsetHeight = element.offsetHeight;
          if (scrollY >= offsetTop && scrollY < offsetTop + offsetHeight) {
            if (activeComponent !== component.id)
              setActiveComponent(component.id);
          }
        }
      }
    };

    scrollContainerRef?.current?.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      scrollContainerRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, [components]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        // gap: "16px",
        position: "sticky",
        top: 0,
        padding: "30px 0px 18px",
        paddingLeft: IsSearchPublicProfile && "50px",
        borderBottom: "4px solid rgba(217, 217, 217, 1)",
        backgroundColor: "#fff",
        zIndex: 2,
      }}
    >
      {filteredComponents?.map((component) => (
        <Grid
          item
          key={component.id}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
            alignItems: "center",
            paddingX: "10px",
            borderRight: "1px solid rgba(217, 217, 217, 1)",
          }}
        >
          <Typography
            onClick={() => scrollToComponent(component.id)}
            color={activeComponent === component.id ? "white" : "inherit"}
            padding={"3px 6px"}
            borderRadius={"5px"}
            backgroundColor={
              activeComponent === component.id
                ? "black"
                : "inherit"
            }
          >
            {component.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default BusinessNavbar;
