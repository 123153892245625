import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

import Minimal from "layouts/Minimal";
import Logo from "components/Logo";
import SignInOTPVerifyForm from "./SignInOTPVerifyForm";

function SignInOTP({path}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  const mobile = auth.signinMobile;

  // console.log("SignInOTPVerify: auth", auth, " mobile: ", mobile);

  // useEffect(() => {}, [mobile]);

  const navigate = useNavigate();
  

  const handleSubmitSuccess = () => {

    navigate(auth?.latestUrl);
    // if(business?.latestUrl?.url !== ""){
    //   alert("not -1")
    //   navigate(business.latestUrl.url);
    // }else{
    //   alert("-1")
    //   navigate("/user-dashboard");
    // }
    };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      mt={10}
    >
      <Grid xs={12}>
        <Logo width="120px" />
      </Grid>
      <Grid xs={12} mt={4}>
        <Typography variant="h5" color={"#FD6600"} fontWeight="bold">
          {" "}
          SIGN IN{" "}
        </Typography>
        <Typography variant={"subtitle2"} mt={2}>
          Enter the OTP for mobile {mobile}
        </Typography>
      </Grid>
      <Grid p={6}>
        <SignInOTPVerifyForm
          mobile={mobile}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </Grid>
    </Grid>
  );
}

export default SignInOTP;
