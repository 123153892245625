import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { dateFormatter } from "utils/formatUtils";
import { Topbar} from "layouts/Main/components";
import { Container } from "@mui/system";
import {
  Stack,
  Typography,
  Grid,
  Button,
  Card,
  Icon,
  Box,
  AppBar,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { subscribeToPlan } from "actions/subscribeAction";
import { getIsPremium } from "utils/accessControl";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import FooterNew from "views/Home/components/FooterNew";
import { FloatingButton, GlobalNavbar } from "views/Home 3.0/components";


const pages = {
  landings: [
    {
      title: "Sign Up",
      href: "/sign-up",
    },
    {
      title: "Sign In",
      href: "/sign-in",
    },
  ],

  profile: [
    {
      title: "My Profile",
      href: "/my-profile",
    },
  ],
  jobs: [
    {
      title: "Full Time",
      href: "/jobs-fulltime",
    },
    {
      title: "Part Time",
      href: "/jobs-partime",
    },
  ],
  account: [
    {
      title: "General Settings",
      href: "/account-general",
    },
    {
      title: "Security Settings",
      href: "/account-security",
    },
  ],
  blog: [
    {
      title: "Blog 1",
      href: "/blog-1",
    },
    {
      title: "Blog 2",
      href: "/blog-2",
    },
    {
      title: "Newsroom",
      href: "/blog-newsroom",
    },
    {
      title: "Article",
      href: "/blog-article",
    },
  ],
};


function SubscribePlan() {
  const [openLogInModal, setOpenLogInModal] = React.useState(false);

  const business = useSelector((state) => state.business);
  const [profile, setProfile] = useState(null);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  useEffect(() => {
    setProfile(business.profile);
    window.scrollTo(0, 0);
  }, []);

  // const isPremium = getIsPremium(profile?.subscriptionType)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPremium = getIsPremium(profile?.subscriptionType)
  const planData = {
    amount: 6000,
    desc: "Neevay Portal Subscription",
    items: ["BASIC"],
    validity: 365,
  };

  async function subscribePlanHandle(amount, desc, items, email) {
    if (userProfile) {
      try {
        dispatch(subscribeToPlan(planData));
        navigate("/prepare-order");
      } catch (error) {
        // console.log("error", error.response.status);
      }
    } else {

      setOpenLogInModal(true);
    }

  }

  useEffect(() => {
    setProfile(business.profile);
  }, [business]);

  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };


  return (
    <>
      {/* <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: "#262728",
          paddingY: "10px",
        }}
      >
        <Topbar pages={pages} />
      </AppBar> */}
      <GlobalNavbar/>
      <Container
        sx={{
          maxWidth: "97.9vw",
          paddingTop: "24px",
          paddingBottom: "24px",
          marginTop: "24px",
          marginBottom: "24px",
          background: "#FFFFFF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "20px",
        }}
      >
        <Grid
          container
          xs={12}
          alignItems="center"
          justifyContent="space-between"
          marginTop="15px"
        >
          <Grid xs={12} sm={5.5} md={8}>
            <Typography variant="spOffer">
              Call us at +919090906073 to subscribe for Premium
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
            container
            justifyContent="space-between"
            marginTop={2}
          >
            <Button
              variant="contained"
              sx={{
                background: "#3963AB",
                borderRadius: "20px",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",

                color: "#FFFFFF",
              }}
            >
              3 Months
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "rgba(0, 0, 0, 0.09)",
                borderRadius: "20px",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#3963AB",
              }}
              disabled
            >
              12 Months
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "rgba(0, 0, 0, 0.09)",
                borderRadius: "20px",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#3963AB",
              }}
              disabled
            >
              24 Months
            </Button>
          </Grid>
        </Grid>
        <Grid container xs={12} justifyContent="flex-end" marginTop={2}>
          <Typography variant="guidedText">
            *Coming soon with One year and Two years plans.
          </Typography>
        </Grid>
        <Grid container xs={12} gap="15px" justifyContent="center">
          <Grid
            lg={3.75}
            md={3.75}
            sm={6}
            xs={10}
            sx={{
              marginTop: "20px",
              height: "537px",
              display: "flex",
              flexDirection: "column",
              background: "rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 90,
                width: 80,
                position: "absolute",
                right: 20,
                top: -10,
              }}
              alt="green vector"
              src="/assets/icons/greenVectore.svg"
            />
            <Icon
              sx={{
                width: "40px",
                height: "40px",
                marginTop: "30px",
                marginLeft: "50px",
              }}
            >
              <img src="/assets/icons/Shield_Icon.svg" />
            </Icon>
            <Typography
              variant="h6"
              sx={{
                marginLeft: "47px",
              }}
            >
              Basic
            </Typography>

            <Container sx={{ marginTop: "15px" }}>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon1.svg"
                />

                <Typography variant="spDetails">
                  Basic Business Registration
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon2.svg"
                />

                <Typography variant="spDetails">Create & Send RFQ</Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon3.svg"
                />

                <Typography variant="spDetails">
                  View Contact Details
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon4.svg"
                />

                <Typography variant="spDetails">Email/SMS/WhatsApp</Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon5.svg"
                />

                <Typography variant="spDetails">
                  Editable Business Profile
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon6.svg"
                />

                <Typography variant="spDetails">
                  Standard Customer Support
                </Typography>
              </Stack>
            </Container>
            <Container
              sx={{
                position: "absolute",
                bottom: "17px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "120px",
                  height: "35px",
                  background: "#47B59E",

                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "#FFFFFF",
                }}
                disabled
              >
                Current Plan
              </Button>
            </Container>
          </Grid>
          <Grid
            lg={3.75}
            md={3.75}
            sm={6}
            xs={10}
            sx={{
              marginTop: "20px",

              height: "537px",
              display: "flex",
              flexDirection: "column",
              background: "rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 90,
                width: 80,
                position: "absolute",
                right: 20,
                top: -5,
              }}
              alt="green vector"
              src="/assets/icons/blueVectore.svg"
            />
            <Box
              component="img"
              sx={{
                height: 90,
                width: 80,
                position: "absolute",
                left: -10,
                top: -15,
              }}
              alt="blue label"
              src="/assets/icons/BlueTag.svg"
            />
            <Icon
              sx={{
                width: "40px",
                height: "40px",
                marginTop: "30px",
                marginLeft: "50px",
              }}
            >
              <img src="/assets/icons/PremiumCrown.svg" />
            </Icon>
            <Typography
              variant="h6"
              sx={{
                marginLeft: "35px",
              }}
            >
              Premium
            </Typography>
            <Typography
              variant="spDetailsTitle"
              sx={{
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              All Basic Features + Premium Profile
            </Typography>

            <Container sx={{ marginTop: "10px", marginLeft: "35px" }}>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon7.svg"
                />

                <Typography variant="spDetails">Premium Listing</Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon8.svg"
                />

                <Typography variant="spDetails">Products & Services</Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon9.svg"
                />

                <Typography variant="spDetails">
                  Awards & Certifications
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon10.svg"
                />

                <Typography variant="spDetails">Projects Completed</Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon11.svg"
                />

                <Typography variant="spDetails">Our Clients</Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon12.svg"
                />

                <Typography variant="spDetails">
                  Client Testimonial/Stories
                </Typography>
              </Stack>
            </Container>
            <Typography
              variant="spDetailsTitle"
              sx={{
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              Premium Dashboard
            </Typography>
            <Container sx={{ marginTop: "10px", marginLeft: "35px" }}>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon13.svg"
                />

                <Typography variant="spDetails">
                  Profile Visitor Analytics
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon14.svg"
                />

                <Typography variant="spDetails">
                  Requirements Analytics
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"7px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon15.svg"
                />

                <Typography variant="spDetails">Customer Analytics</Typography>
              </Stack>
            </Container>
            <Typography
              variant="spDetailsTitle"
              sx={{
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              Premium Customer Support

            </Typography>
            {profile?.validity &&

              <Typography
                variant="spDetails"
                sx={{
                  textAlign: 'center',
                  marginTop: "10px",
                }}
              >
                Valid up to {' '}{dateFormatter(profile?.validity)}

              </Typography>
            }

            <Container
              sx={{
                position: "absolute",
                bottom: "17px",
                display: "flex",
                justifyContent: "center",
              }}
            >

              {/* <Button
                disabled={isPremium}
                onClick={() =>
                  subscribePlanHandle(
                    planData.amount,
                    planData.desc,
                    planData.items,
                    planData.email
                  )
                }
                variant="contained"
                sx={{
                  width: "120px",
                  height: "35px",
                  background: "#3963AB",

                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "#FFFFFF",
                }}
              >
                Buy Now
              </Button> */}
            </Container>
          </Grid>
          <Grid
            lg={3.75}
            md={3.75}
            sm={6}
            xs={10}
            sx={{
              marginTop: "20px",

              height: "537px",
              display: "flex",
              flexDirection: "column",
              background: "rgba(0, 0, 0, 0.02)",
              borderRadius: "20px",
              position: "relative",
            }}
          >
            <Card
              sx={{
                display: "flex",
                background: "rgba(0, 0, 0, 0.02)",
                backdropFilter: "blur(10px)",
                borderRadius: "20px",
                width: "100%",
                height: "537px",
                position: "absolute",
                zIndex: "1",
              }}
            ></Card>
            <Container
              sx={{
                backgroundColor: "#000000",
                borderRadius: "60px 0px",
                zIndex: "2",
                position: "absolute",
                top: "45%",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "rgba(255, 255, 255, 1)", textAlign: "center" }}
              >
                Coming Soon...
              </Typography>
            </Container>
            <Box
              component="img"
              sx={{
                height: 90,
                width: 80,
                position: "absolute",
                right: 20,
                top: -5,
              }}
              alt="green vector"
              src="/assets/icons/greenVectore.svg"
            />
            <Icon
              sx={{
                width: "40px",
                height: "40px",
                marginTop: "55px",
                marginLeft: "40px",
              }}
            >
              <img src="/assets/icons/Shield_Icon.svg" />
            </Icon>
            <Typography
              variant="h6"
              sx={{
                marginLeft: "40px",
              }}
            >
              Basic
            </Typography>

            <Container sx={{ marginTop: "15px" }}>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon1.svg"
                />

                <Typography variant="spDetails">
                  Basic Business Registration
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon2.svg"
                />

                <Typography variant="spDetails">Create & Send RFQ</Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon3.svg"
                />

                <Typography variant="spDetails">
                  View Contact Details
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon4.svg"
                />

                <Typography variant="spDetails">Email/SMS/WhatsApp</Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon5.svg"
                />

                <Typography variant="spDetails">
                  Editable Business Profile
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                marginBottom={"15px"}
              >
                <Box
                  component="img"
                  sx={{
                    height: 15,
                    width: 18,
                  }}
                  alt="building"
                  src="/assets/icons/SP_Icon6.svg"
                />

                <Typography variant="spDetails">
                  Standard Customer Support
                </Typography>
              </Stack>
            </Container>
            <Container
              sx={{
                position: "absolute",
                bottom: "17px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "120px",
                  height: "35px",
                  background: "#47B59E",

                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "#FFFFFF",
                }}
              >
                Current Plan
              </Button>
            </Container>
          </Grid>
        </Grid>
        <Grid container xs={12} justifyContent="center">
          <Box
            maxHeight={"100%"}
            maxWidth={"100%"}
            marginTop="10px"
            component="img"
            alt="green vector"
            src="/assets/icons/classification.svg"
          />
        </Grid>
      </Container>

      <FooterNew />
      <FloatingButton/>

      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />
    </>
  );
}

export default SubscribePlan;
