import { Box, Typography } from '@mui/material'
import React from 'react'

export const UnauthorizedAccess = () => {
  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Adjust height as needed
    }}
  >
    <Typography sx={{ fontSize: '16px', textAlign: 'center',fontWeight:500 }}>
      Unauthorized Access
    </Typography>
  </Box>
  )
}
