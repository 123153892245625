import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

import {
   Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";

function ProfileDescription({ summary, description, ...rest }) {
  const replacedOlString = description?.replaceAll('<ol>', '<ol style="padding-left: 34px">');
  // Replace <ul> with style attribute
  const replacedString = replacedOlString?.replaceAll('<ul>', '<ul style="padding-left: 34px">');

  return (
    <Container fullWidth="lg" style={{marginTop:"20px"}}>
      <Grid conatiner>
        <Card
          sx={{
            borderRadius: 5,
            p: 2,
            paddingX:"24px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12} xl={12} sx={{ p: 0}}>
            <Grid sx={{marginBottom:"10px"}}>
            <Typography style={{lineHeight:"1",fontWeight: 700,fontSize:"14px"}} >{summary}</Typography>
            </Grid>
                             <Typography variant="businessBody">
                {description ? ReactHtmlParser(replacedString) : ""}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Container>
  );
}

ProfileDescription.propTypes = {
  summary: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ProfileDescription;
