import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
  CardMedia,
  Link,
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useFormik } from "formik";
import AddClientForm from "./AddClientForm";
import { useNavigate } from "react-router";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
import PropTypes from "prop-types";

const AddClient = ({
  isPublicProfile,
  handleSubmit,
  permissions,
  isClientExists,
  onSubmitSuccess,
}) => {
  const handleAddService = () => {
    // console.log("handleAddService");
    // dispatch(addServiceTrigger);
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isUpdateAllowed) {
        setOpen(true);
      } else {

      }
    } else {
      navigate("/subscribe-plan");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/my-business");
  };

  return (
    <Grid conatiner mt={2} ml={3} mb={3}>
      <Grid item md={12} sm={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid container>
            <Grid item sm={12}>
              {((isPublicProfile && isClientExists) || !isPublicProfile) && (
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="businessTitle"
                  // fontSize={"18px"}
                  // color={"rgba(0, 0, 0, 0.6)"}
                  // fontWeight={"700"}
                  >
                    TOP CLIENTS
                  </Typography>
                  {!isPublicProfile && (
                    <CardMedia
                      component="img"
                      image={DEFAULT_IMAGES.PREMIUM_BADGE}
                      height="20px"
                      sx={{ width: "25px", ml: 1, mt: -1 }}
                    />
                  )}
                </Box>
              )}
            </Grid>
            <Grid item sm={12} xs={12}>
              {permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed && (
                  <Typography
                    color="secondary"
                    size="small"
                    sx={{ fontSize: "15px", cursor: "pointer" }}
                    // disabled={!(permissions && permissions.isUpdateAllowed)}
                    // endIcon={<AddCircleRoundedIcon />}
                    onClick={handleClickOpen}
                  >
                    <Link sx={{ textDecoration: "none", color: "blue" }}>
                      {" "}
                      Click Here{" "}
                    </Link>
                    <span style={{ color: "black" }}> to Add Client</span>
                  </Typography>
                )}
            </Grid>
            <AddClientForm
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              onSubmitSuccess={handleSubmitSuccess}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
AddClient.propTypes = {
  // supplierId: PropTypes.number,
  isPublicProfile: PropTypes.bool,
  isClientExists: PropTypes.bool,
};

export default AddClient;
