import React, { useRef, useState } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

const EmailTextField = () => {
  const { setFieldValue, errors, values } = useFormikContext();
  const ccMembers = useSelector((state) => state.ccMemberList);
  const [emailCopyRecipients, setEmailCopyRecipients] = useState(
    values.emailCopyRecipients || []
  );
  const [emailError, setEmailError] = useState(false);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const ccMemberList = ccMembers?.ccEmails;

  const isValidEmail = (email) => {
    // Regular expression to validate email format and ensure no invalid characters are present
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Check if the email matches the regular expression
    return regex.test(email);
  };

  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    // form.setFieldValue(field.name, value);

    if (value) {
      const matchedSuggestions = ccMemberList.filter((email) =>
        email.includes(value)
      );
      setSuggestions(
        matchedSuggestions.filter(
          (email) => !emailCopyRecipients.includes(email)
        )
      );
      setShowSuggestions(matchedSuggestions.length > 0);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (email) => {
    setInputValue("");
    handleAddEmails(email);
    setShowSuggestions(false);
    // form.setFieldValue(field.name, email);
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className="font-bold">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setShowSuggestions(false);
      e.preventDefault();
      const value = e.target.value.trim();
      if (isValidEmail(value)) {
        setEmailError(false);
        setInputValue("");
        setEmailCopyRecipients((prevRecipients) => {
          const uniqueEmails = new Set([...prevRecipients, value]);
          // Convert the Set back to an array
          return [...uniqueEmails];
        });
        setFieldValue("emailCopyRecipients", [...emailCopyRecipients, value]);
        e.target.value = "";
      } else {
        setEmailError(true);
        setTimeout(() => setEmailError(false), 3000);
      }
    }
  };

  const handleAddEmails = (textString) => {
    const emails = textString
      .split(/[ ,;]+/)
      .filter((email) => email.trim() !== "");
    const validEmailList = [];
    const invalidEmailList = [];

    emails.forEach((email) => {
      if (isValidEmail(email)) {
        validEmailList.push(email);
      } else {
        invalidEmailList.push(email);
      }
    });

    setEmailCopyRecipients((prevValidEmails) => {
      const uniqueEmails = new Set([...prevValidEmails, ...validEmailList]);
      // Convert the Set back to an array
      return [...uniqueEmails];
    });
    const uniqueEmails = new Set([...emailCopyRecipients, ...validEmailList]);
    setFieldValue("emailCopyRecipients", [...uniqueEmails]);

    if (invalidEmailList?.length >= 1) {
      setInvalidEmails(invalidEmailList);
      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
        setInvalidEmails([]);
      }, 5000);
      setInputValue(invalidEmailList);
      return invalidEmailList?.join(",");
    } else {
      setInputValue("");
      return "";
    }
  };

  const handleBlur = (e) => {
    setShowSuggestions(false);
    if (e.target.value !== "") e.target.value = handleAddEmails(e.target.value);
  };

  const handleInputPaste = (e) => {
    setShowSuggestions(false);
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    handleAddEmails(pastedText);
  };

  const handleRemoveRecipient = (indexToRemove) => {
    setEmailCopyRecipients((prevRecipients) =>
      prevRecipients.filter((_, i) => i !== indexToRemove)
    );
    setFieldValue(
      "emailCopyRecipients",
      emailCopyRecipients.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div
      className="max-h-[233px] overflow-auto shadow-sm cursor-text block h-full w-full border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
      onClick={() => {
        inputRef.current.focus();
      }}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          inputRef.current.focus();
        }
      }}
    >
      {emailCopyRecipients.length >= 1 && (
        <div className="flex flex-wrap px-2 py-2 gap-2">
          {emailCopyRecipients.map((recipient, index) => (
            <div
              key={index}
              className="flex items-center text-sm bg-gray-200 rounded-full px-2 py-1"
            >
              {recipient}
              <button
                type="button"
                className="ml-2 text-gray-500"
                onClick={() => handleRemoveRecipient(index)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path>
                </svg>
              </button>
            </div>
          ))}
        </div>
      )}
      <input
        ref={inputRef}
        type="email"
        className="rounded-md w-full border-0 h-[50px] text-sm placeholder:text-sm focus:outline-none focus:border-transparent focus:ring-0 focus:ring-offset-0"
        placeholder="CC Recipients"
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onPaste={handleInputPaste}
        onChange={handleInputChange}
        value={inputValue}
      />
      {emailError && (
        <div className="text-red-700 p-2">
          <p className="text-xs">
            {invalidEmails?.join(",")} Invalid email format
          </p>
        </div>
      )}
      {errors.emailCopyRecipients?.length > 0 ? (
        <div className="text-red-500">
          <p className="text-xs">{errors.emailCopyRecipients}</p>
        </div>
      ) : null}

      {showSuggestions && (
        <div className="absolute bg-white border border-gray-300 mt-1 w-full z-10 max-h-60 overflow-y-auto">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-200"
              onMouseDown={() => handleSuggestionClick(suggestion)}
            >
              {getHighlightedText(suggestion, inputValue)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmailTextField;
