import axios from "utils/axios";

import {
  MEMBERS_FETCH_URL,
  ADD_MEMBERS_URL,
  DELETE_MEMBERS_URL,
  OWNER_CHANGE_URL
} from "config/services";

class MemberService {

  fetchMember = (supplierId) =>
    new Promise((resolve, reject) => {

      // console.log("LeadsService.fetchLeads called");

      const fetchUrl = `${MEMBERS_FETCH_URL}?supplierId=${supplierId}`;
      axios.get(fetchUrl,{})
      .then((response) => {
        // console.log("LeadsService.fetchLeads() resp:", response)
        if(response?.data?.error) {
          //console.log("Failed to fetch records");
          reject(response.data.message);
        } else {
          if(response?.data?.payload){
            resolve(response?.data?.payload);
          }
        }
      })
      .catch((error) => {
        if(error.response) {
          // console.log("LeadsService.fetchLeads() error:", error);
          reject(error?.response?.data);
        }
        reject(error);
      });
    });

    addMember = (payload) =>
    new Promise((resolve, reject) => {

      // console.log("LeadsService.fetchLeads called");

      axios.post(ADD_MEMBERS_URL, {payload})
      .then((response) => {
        // console.log("LeadsService.fetchLeads() resp:", response)
        if(response?.data?.error) {
          // console.log("Failed to fetch records");
          reject(response.data.message);
        } else {
          if(response?.data?.payload){
            resolve(response?.data?.payload);
          }
        }
      })
      .catch((error) => {
        if(error.response) {
          // console.log("LeadsService.fetchLeads() error:", error);
          reject(error?.response?.data);
        }
        reject(error);
      });
    });

    memberDlt = (payload) =>
    new Promise((resolve, reject) => {

      // console.log("LeadsService.fetchLeads called");

      axios.post(DELETE_MEMBERS_URL, {payload})
      .then((response) => {
        // console.log("LeadsService.fetchLeads() resp:", response)
        if(response?.data?.error) {
          // console.log("Failed to fetch records");
          reject(response.data.message);
        } else {
          if(response?.data?.payload){
            resolve(response?.data?.payload);
          }
        }
      })
      .catch((error) => {
        if(error.response) {
          // console.log("LeadsService.fetchLeads() error:", error);
          reject(error?.response?.data);
        }
        reject(error);
      });
    });


    changeOwner = (payload) =>
   
    new Promise((resolve, reject) => {

      // console.log("payload", payload);
      
      axios.post(OWNER_CHANGE_URL, {payload})
      .then((response) => {
        // console.log("LeadsService.fetchLeads() resp:", response)
        if(response?.data?.error) {
          // console.log("Failed to fetch records");
          reject(response.data.message);
        } else {
          if(response?.data?.payload){
            resolve(response?.data?.payload);
          }
        }
      })
      .catch((error) => {
        
        if(error.response) {
          // console.log("LeadsService.fetchLeads() error:", error);
          reject(error?.response?.data);
        }
        reject(error);
      });
    });

    

}

const memberService = new MemberService();

export default memberService;
