import { Box, Grid, Pagination, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import repeatFixedUtils from "./repeatFixedUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchRepeatFixedRfq, searchRepeatFixedRfq } from "actions/requirementsAction";
const useStyles = makeStyles({
  dataGrid: {
    "& .MuiDataGrid-cell": {
      borderRight: "2px solid rgba(0, 0, 0, 0.1)", // Add vertical border to cells
    },
    "& .MuiDataGrid-colCellTitleContainer": {
      borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Add vertical border to header cells
    },
    "& .MuiDataGrid-footerContainer": {
      display: "none", //hideFooter pagination unused blank spacr
    },
  },
});
const RepeatFixedRfqTableSection = ({ searchValue, userId }) => {
  const [requirements, setRequirements] = useState([]);
  const repeatFixedRfq = useSelector((state) => state.repeatFixedRfq);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChangePageNumber = (event, value) => {
    // dispatch(fetchRepeatFixedRfq(userId, value));
    dispatch(searchRepeatFixedRfq(userId, searchValue, value));
  };
  useEffect(() => {
    if (repeatFixedRfq?.requirements?.length > 0) {
      const updatedRfq = repeatFixedRfq?.requirements?.map((data, index) => {
        return {
          ...data,
          id: index,
          typeOfOrder:
            data.typeOfOrder === "REPEAT_ORDER"
              ? "Repeat Order"
              : data.typeOfOrder === "FIXED_ORDER"
              ? "Fixed Order"
              : "Fixed/Repeat Order",
        };
      });

      setRequirements(updatedRfq);
    } else {
      setRequirements(repeatFixedRfq?.requirements);
    }
  }, [repeatFixedRfq]);
  return (
    <div>
      <Grid xs>
        {requirements?.length < 0 ? (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: "100px",
            }}
          >
            <Typography>You haven't sent any requirement</Typography>
          </Grid>
        ) : (
          <Box sx={{ height: "400px" }}>
            <DataGrid
              className={classes.dataGrid}
              rows={requirements}
              columns={repeatFixedUtils()}
              rowCount={14}
              pageSizeOptions={[10]}
              rowHeight={32}
              disableSelectionOnClick
              disableColumnMenu
              components={{
                Pagination: () => null, // Completely hide pagination component
              }}
            />
            <Pagination
              sx={{ float: "right", m: "12px" }}
              size="small"
              page={repeatFixedRfq?.currentPage}
              count={repeatFixedRfq?.totalPages}
              onChange={handleChangePageNumber}
            />
          </Box>
        )}
      </Grid>
    </div>
  );
};

export default RepeatFixedRfqTableSection;
