import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import OtpInput from "react-otp-input";

import "./style.css";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";

import { signInWithMobileOTP, signInSendMobileOTP } from "actions/authActions";
import { SIGN_UP_COUNTER } from "config";

function SignInOTPVerifyForm({ className, onSubmitSuccess, mobile, ...rest }) {
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(SIGN_UP_COUNTER);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (otp.length === 4) {
      setIsLoading(true);
      dispatch(signInWithMobileOTP(mobile, otp))
        .then(() => {
          onSubmitSuccess();
        })
        .catch((error) => {
          console.error("OTP verification failed", error);
          setSubmitError("OTP verification failed. Please try again.");
          setIsLoading(false);
        });
    }
  }, [otp, dispatch, mobile, onSubmitSuccess]);

  const handleChangeMobile = () => {
    navigate("/signin-mobile-send-otp");
  };

  const resendOTP = () => {
    dispatch(signInSendMobileOTP(mobile));
    setCounter(SIGN_UP_COUNTER);
  };

  return (
    <form {...rest}>
      <Grid item container alignItems="center" justifyContent="center" xs={12} md={12}>
        <Box 
          sx={{ 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center", 
            "& > :not(style)": { m: 1 } 
          }}
        >
          {isMd ? (
            <TextField
              fullWidth
              required
              autoFocus
              sx={{ letterSpacing: "10px" }}
              placeholder="OTP"
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
              inputProps={{ maxLength: 4 }}
            />
          ) : (
            <OtpInput
              className="otpInputStyleCss"
              value={otp}
              onChange={setOtp}
              numInputs={4}
              separator={<span></span>}
              shouldAutoFocus
              isInputNum
            />
          )}
          {submitError && (
            <FormHelperText error>{submitError}</FormHelperText>
          )}
        </Box>
        
        {counter > 0 ? (
          <Box mt={3}>
            <Typography fontWeight={500} align="center" color="textSecondary">
              You will receive OTP in{" "}
              <span style={{ color: "green", fontWeight: "bold" }}>{counter}</span> secs
            </Typography>
          </Box>
        ) : (
          <Grid item container alignItems="center" justifyContent="center" mt={3} p={2}>
            {isLoading && <CircularProgress size={24} sx={{ mr: 2 }} />}
            <Typography fontWeight={500} align="center" color="textSecondary">
              Not received OTP?{" "}
              <Link component="button" color="#FD6600" onClick={resendOTP} underline="none">
                Resend OTP
              </Link>
            </Typography>
          </Grid>
        )}

        <Grid item container alignItems="center" justifyContent="center" mt={3} p={2}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            variant="outlined"
            onClick={handleChangeMobile}
          >
            Change Mobile
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

SignInOTPVerifyForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  mobile: PropTypes.string.isRequired,
};

SignInOTPVerifyForm.defaultProps = {
  onSubmitSuccess: () => {},
};

export default SignInOTPVerifyForm;
