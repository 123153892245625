import { Chip, Grid, Skeleton, Typography } from '@mui/material'
import React from 'react'

const AttachmentChipSection = ({ isSmallScreen, attachmentsSkeleton, selectedFiles, handleDeleteSelectedFile }) => {
    return (
        <Grid sx={{ mt: "10px" }}>
            {attachmentsSkeleton?.length > 0 && (
                <Typography fontSize={"12px"} paddingLeft={"12px"}>
                    Your file is being scanned for upload, be patient and
                    continue to fill rest of the form.
                </Typography>
            )}
            <Grid paddingX={!isSmallScreen && "12px"}>
                {/* Display the selected file names */}
                <ul style={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedFiles?.map((file, index) => (
                        <Chip
                            label={file.Name}
                            clickable
                            component="a"
                            href={file.Content}
                            target="_blank"
                            rel="noopener noreferrer" // Recommended for security and performance reasons
                            variant="outlined"
                            onDelete={(event) =>
                                handleDeleteSelectedFile(file.Name, event)
                            }
                            sx={{
                                width: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                margin: "4px",
                                justifyContent: "space-between",
                            }}
                        />
                    ))}
                    {attachmentsSkeleton?.map(() => (
                        <Skeleton
                            variant="rounded"
                            sx={{ borderRadius: "20px", margin: "4px" }}
                            width={150}
                            height={32}
                        />
                    ))}
                </ul>
            </Grid>
        </Grid>
    )
}

export default AttachmentChipSection