import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import axios from "utils/axios";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  REMOVE_REGIONS_IN_SUPPLIER_URL,
  UPDATE_REGIONS_IN_SUPPLIER_URL,
} from "config/services";

function BusinessRegionsMobile({
  statesOfIndia,
  regionModel,
  setRegionModel,
  setSuccessMessage,
  supplierId,
  businessRegions,
  selectedCities,
  handleCitySelection,
  preSelectedCitys,
  setSearchQuery,
  filteredOptions,
  apiDistricts,
  setDistricts,
}) {
  const statesArray = [];
  statesOfIndia.map((state) => statesArray.push(`All over ${state.stateName}`));

  const RegionModalstyleSm = {
    overflow: "hidden",
    borderRadius: "10px",
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 2,
  };

  const regionStates = businessRegions?.state;
  const regioCountries = businessRegions?.country;
  const [regioCountry, setRegioCountry] = useState(regioCountries);

  const [selectedStates, setSelectedStates] = useState(
    regionStates ? regionStates : []
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleStateSelectionSM = (event, value) => {
    const selectedStateNames = value.map((state) => {
      const stateName = state.replace("All over ", "");
      const selectedState = statesOfIndia.find(
        (s) => s.stateName === stateName
      );
      return selectedState ? selectedState.stateName : null;
    });
    setSelectedStates(selectedStateNames.filter((name) => name !== null));
  };
  const handleCancle = () => {
    setRegionModel(false);
  };

  const handleCheckCountry = (event) => {
    const isChecked = event.target.checked;
    setRegioCountry(isChecked ? event.target.value : "");
    if (isChecked) {
      // If the checkbox is checked, add all states to the selectedStates array
      setSelectedStates(statesOfIndia.map((state) => state.stateName));
    } else {
      // If the checkbox is unchecked, clear the selectedStates array
      setSelectedStates([]);
    }
  };

  const handleSubmitClick = () => {
    setIsLoading(true);

    const country = regioCountry;
    const state = selectedStates;
    const city = selectedCities;
    const citiesToDelete = preSelectedCitys?.filter(
      (element) => !selectedCities?.includes(element)
    );
    axios
      .post(UPDATE_REGIONS_IN_SUPPLIER_URL, {
        country,
        state,
        city,
        citiesToDelete,
        supplierId,
      })
      .then((response) => {
        // console.log("REGIONS ADDED RESPONSE", response);
        setIsLoading(false); // Set loading state to false on success
        setRegionModel(false);
        setSuccessMessage("Regions added successfully..!");
        // setSelectedCities([])
        setSelectedStates([]);
        setTimeout(() => {
          window.location.reload();
        }, [1000]);
      })
      .catch((error) => {
        // console.log("REGIONS ADDED ERROR", error);
        setIsLoading(false); // Set loading state to false on error
      });
  };

  const handleClickChipSetStateRemove = (label) => {
    const stateName = label.replace("All over ", "");

    const updatedSelectedStates = selectedStates.filter(
      (state) => state !== stateName
    );
    setSelectedStates(updatedSelectedStates);
  };

  const trueValueCount = selectedStates?.length;

  useEffect(() => {
    setSelectedStates(regionStates);
  }, [regionStates]);
  useEffect(() => {

    setDistricts(
      apiDistricts
        .filter((data) => !selectedStates?.includes(data.state))
        .map((obj) => obj.districts)
        .flat()
    );
  }, [selectedStates]);

  return (
    <Modal
      open={regionModel}
      //   onClose={handleCancle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={RegionModalstyleSm}>
        <Grid>
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                Where do you provide your products or services ?
              </Typography>
            </Grid>
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <Box
                component="img"
                sx={{
                  height: "30px",
                  width: "30px",
                }}
                alt="logo"
                src="/assets/icons/indiaFlag.svg"
              />
            </Grid>
          </Grid>
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={regioCountry === "India"}
                  checked={trueValueCount == 36 ? true : false}
                  onChange={handleCheckCountry}
                  value="India"
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "#5A5858",
                  }}
                >
                  All over India
                </span>
              }
            />
          </Grid>

          <Typography mt={"3px"}>Selected States :</Typography>
          <Grid
            style={{
              width: "100%",
              margin: "auto",
              maxHeight: "220px",
              overflow: "auto",
            }}
          >
            <Autocomplete
              //   freeSolo
              clearOnBlur
              //   style={{ maxHeight: "200px", overflow: "auto" }}
              size="small"
              multiple
              value={
                selectedStates &&
                selectedStates.map((state) => `All over ${state}`)
              }
              disableClearable
              onChange={handleStateSelectionSM}
              options={statesArray}
              PaperComponent={({ children }) => (
                <Paper style={{ fontSize: "14px", color: "#757373" }}>
                  {children}
                </Paper>
              )}
              // getOptionLabel={(option) => statesArray}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((statesArray, index) => (
                  <Chip
                    size="small"
                    label={statesArray}
                    {...getTagProps({ index })}
                    style={{
                      margin: "4px",
                      backgroundColor: "#292A2D",
                      color: "white",
                      borderRadius: "5px",
                    }}
                    onDelete={() => handleClickChipSetStateRemove(statesArray)}
                    deleteIcon={
                      <ClearIcon
                        style={{
                          color: "#E2E2E2",
                          fontSize: "14px",
                        }}
                      />
                    }
                  />
                ))
              }
              // style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Fixed tag"
                  placeholder="Type to select state"
                />
              )}
            />
          </Grid>

          <Typography mt={"20px"}>Selected Cities :</Typography>
          <Grid
            style={{
              width: "100%",
              margin: "auto",
              maxHeight: "220px",
              overflow: "auto",
            }}
          >
            <Autocomplete
              //   freeSolo
              disabled={trueValueCount == 36}
              clearOnBlur
              //   style={{ maxHeight: "230px", overflow: "auto" }}
              size="small"
              multiple
              options={filteredOptions}
              value={selectedCities}
              disableClearable
              onChange={handleCitySelection}
              PaperComponent={({ children }) => (
                <Paper style={{ fontSize: "14px", color: "#757373" }}>
                  {children}
                </Paper>
              )}
              // getOptionLabel={(option) => citiesInIndia}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((citiesInIndia, index) => (
                  <Chip
                    size="small"
                    label={citiesInIndia}
                    {...getTagProps({ index })}
                    style={{
                      margin: "4px",
                      backgroundColor: "#292A2D",
                      color: "white",
                      borderRadius: "5px",
                    }}
                    deleteIcon={
                      <ClearIcon
                        style={{
                          color: "#E2E2E2",
                          fontSize: "14px",
                        }}
                      />
                    }
                  />
                ))
              }
              // style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Fixed tag"
                  placeholder="Type to select city"
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
              )}
            />
          </Grid>

          <Grid>
            <Grid
              style={{
                justifyContent: "end",
                display: "flex",
                position: "absolute",
                bottom: "10px",
                right: "15px",
                gap: "10px",
              }}
            >
              <Button
                onClick={handleCancle}
                sx={{ padding: "5px 20px", backgroundColor: "#757575",
                color:"white",
                "&:hover": {
                  backgroundColor: "#757575",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                } }}
                variant="contained"
                size="small"
                // color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton
                // disabled={
                //   selectedCities?.length > 0 || selectedStates?.length > 0
                //     ? false
                //     : true
                // }
                loading={isLoading}
                loadingPosition="end"
                onClick={handleSubmitClick}
                sx={{ padding: "5px 25px 5px 15px", backgroundColor: "black",
                color:"white",
                "&:hover": {
                  backgroundColor: "black",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                } }}
                variant="contained"
                size="small"
              >
                {isLoading ? "Adding.." : "Submit"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default BusinessRegionsMobile;
