import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Grid, Typography } from "@mui/material";

const Minimal = ({ children }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      flexDirection="row"
      display="flex"
      justifyContent="space-between"
      width="100%"
      height="100vh"
      position="fixed"
    >
      <Grid
        container
        width="100%"
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll",
        }}
      >
        {children}
      </Grid>
    </Box>
  );
};


Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
