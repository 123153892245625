import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "utils/axios";
import { useSelector } from "react-redux";

const VendorForm = ({ open, onClose, userProfile, onVendorAdded }) => {
  const auth = useSelector((state) => state.auth);

  const user = auth?.user;
  const entityName = user?.userEntity?.entityName;
  const directoryName = user?.userEntity?.directoryName;

  const formik = useFormik({
    initialValues: {
      vendorName: "",
      gstNumber: "",
      city: "",
      state: "",
      servicesOffered: "",
      name: "",
      email: "",
      mobile: "",
    },
    validationSchema: Yup.object({
      vendorName: Yup.string().required("Vendor Name is required"),
      mobile: Yup.string()
        .matches(/^[0-9]*$/, "Mobile number can only contain numbers")
        .min(10, "Mobile number must be 10 digits or more")
        .max(10, "Mobile number must be 10 digits or less")
        .required("Mobile number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("email is required"),
      servicesOffered: Yup.string().required("Tags is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      // Format the formData as per your requirements
      const formData = {
        entityId: userProfile?.userEntity?.entityId,
        vendorName: values.vendorName,
        gstNumber: values.gstNumber,
        contactDetails: [
          {
            name: values.name,
            mobile: values.mobile,
            email: values.email,
          },
        ],

        // serviceTags: values.servicesOffered
        //   .split(",")
        //   .map((tag) => ({ tagName: tag.trim() })),

        tags: [
          {
            userId: userProfile?.userId,
            tags: values.servicesOffered.split(",").map((tag) => tag.trim()),
          },
        ],
        location: [
          {
            city: values.city,
            state: values.state,
          },
        ],
        createdBy: userProfile?.userId,
        addedBy: userProfile?.name,
        // Add other fields as needed
      };

      try {
        const response = await axios.post(
          "/api/auth/create/internal/vendor",
          formData
        );

        if (!response.data.error) {
          onVendorAdded();
        }

        resetForm();

        onClose();
      } catch (error) {
        // Handle errors if the API request fails
        console.error("Error saving data:", error);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Add Vendor to {directoryName} directory
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          {/* Vendor Form Fields */}
          <TextField
            size="small"
            placeholder="Vendor Name"
            name="vendorName"
            value={formik.values.vendorName}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
            error={
              formik.touched.vendorName && Boolean(formik.errors.vendorName)
            }
            helperText={formik.touched.vendorName && formik.errors.vendorName}
            // required
          />
          <TextField
            size="small"
            placeholder="GST Number"
            name="gstNumber"
            value={formik.values.gstNumber}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            size="small"
            placeholder="Contact Person Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size="small"
                placeholder="Mobile"
                name="mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
                // required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                placeholder="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                // required
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size="small"
                placeholder="City"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                placeholder="State"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>

          <TextField
            size="small"
            placeholder="Add tags"
            name="servicesOffered"
            value={formik.values.servicesOffered}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
            error={
              formik.touched.servicesOffered &&
              Boolean(formik.errors.servicesOffered)
            }
            helperText={
              formik.touched.servicesOffered && formik.errors.servicesOffered
            }
            // required
          />

          <DialogActions>
            <Button sx={{ color: "black" }} onClick={onClose}>
              Cancel
            </Button>
            <Button
              size="small"
              type="submit"
              variant="contained"
              // disabled={!formik.isValid}
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#FD6600",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                  color: "white",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                },
              }}
            >
              Add Vendor
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default VendorForm;
