import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import axios from "utils/axios";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import { getUrl } from "actions/businessActions";

import { alpha, useTheme } from "@mui/material/styles";
import {
  AccountCircle,
  PhoneInTalk,
  Person,
  Logout,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import VerificationPopUp from "views/VerificationModal";
import { signout } from "actions/authActions";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import ThankYou from "views/Home/components/BookDemoForm/ThankYou";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100px",
    height: "39px",
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "0%",
      height: "100%",
      background: "#3963AB",
      transition: "width 0.6s",
    },
    "&:hover::before": {
      width: "100%",
    },
  },
  content: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    // marginRight: theme.spacing(),
  },
  text: {
    fontSize: "15px",
    fontFamily: "Roboto",
    fontWeight: "600",
    lineHeight: "14px",
    // paddingLeft: "5px",
    color: "white",
  },
}));

const buyerProfileMenuList = [
  {
    name: "Shortlisted Vendors",
    link: "/shortlisted-profile",
  },
  {
    name: " My Requirements",
    link: "/my-business",
  },
];
const vendorProfileMenuList = [
  {
    name: "Leads",
    link: "/leads",
  },
];

const ProfileMenuButton = ({
  userType,
  switchUser,
  handleCloseModal,
  buyerProfileMenuList,
  vendorProfileMenuList,
  setLoginPopUpIsOpen,
  showTypography = true,
  global = false,
  // userType,
}) => {
  const [openLogInModal, setOpenLogInModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [thankYouPopUp, setThankYouPopUp] = React.useState();
  const theme = useTheme();
  const { mode } = theme.palette;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorElUser, setAnchorElUser] = useState(null);
  const menuId = "profile-menu";

  const auth = useSelector((state) => state.auth);
  const business  = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const userProfile = auth.user;

  const userOpen = Boolean(anchorElUser);

  const classes = useStyles();

  const handleOpenUserMenu = (event) => {
    if (userProfile) {
      setAnchorElUser(event.currentTarget);
    } else {
      dispatch(getUrl("/"));
      setOpenLogInModal(true);
    }
  };

  const handleClose = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(signout());
      navigate("/");
    } catch (error) {}
  };

  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };
  const handleBrClick = () => {
    if (!userProfile) {
      setOpenLogInModal(true);
    } else {
      if (userProfile?.isVerified === false) {
        setIsModalOpen(true);
      } else {
        if (supplierId) {
          navigate("/my-business");
        } else {
          setThankYouPopUp(true);

          axios.post("/api/auth/demo/request", {
            name: userProfile?.name,
            number: userProfile?.mobile,
            interestShown: "Business Registration",
          });
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!showTypography) {
        handleClose();
      }
    };

    if (!showTypography) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showTypography]);
  if (openLogInModal) {
    if (setLoginPopUpIsOpen) {
      setLoginPopUpIsOpen(true);
    }
  }
  const hoverText = userProfile?.name;
  const [showTooltip, setShowTooltip] = useState(false);
  const capitalizeFirstLetter = (str) => {
    // console.log(str,);
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
  console.log(userProfile?.userType);
  return (
    <Box
      style={{
        textDecoration: "none",

        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
      }}
    >
      {/* <IconButton
        size="medium"
        edge="center"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleOpenUserMenu}
        color="primary"
        sx={{
          border: "2px solid #47B59E",
          mt: 1,
        }}
      >
        <Person sx={{ color: "primary.main" }} />
      </IconButton>
      <Box>
        <Typography
          onClick={handleOpenUserMenu}
          variant="caption"
          gutterBottom
          sx={{ textAlign: "center", color: showTypography ? "grey" : "white" }}
        >
          {userProfile ? "Me" : "Login"}
        </Typography>
      </Box> */}
      {userProfile ? (
        <Grid
          style={{
            height: "40px",
            width: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#04EEAD",
            borderRadius: "50%",
            color: "#000000",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "21px",
            // textTransform: "capitalize",
            cursor: "pointer",
            position: "relative", // Add relative positioning
          }}
          onClick={handleOpenUserMenu}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {isSm ? (
            anchorElUser ? (
              <CloseIcon />
            ) : (
              <MenuIcon />
            )
          ) : (
            userProfile?.name[0]
          )}
          {showTooltip && !isSm && (
            <span
              style={{
                position: "absolute",
                backgroundColor: "rgba(233, 241, 239, 1)",
                color: "black",
                fontSize: "12px",
                padding: "7px",
                borderRadius: "4px",
                bottom: "-45px",
                right: "0", // Start from the left side
                textTransform: "capitalize",
                whiteSpace: "nowrap", // Display all content in a single line
                textAlign: "center",
                boxShadow: " 0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              {capitalizeFirstLetter(hoverText)}
            </span>
          )}
        </Grid>
      ) : isSm ? (
        <Box
          component="img"
          sx={
            global
              ? {
                height: "40px",
                width: "40px",
              }
              : {
                height: "45px",
                width: "45px",
              }
          }
          alt="logo"
          src="/assets/home/SmLoginBtn.svg"
          onClick={handleOpenUserMenu}
        />
      ) : (
        <Grid>
          <div
            style={{
              cursor: "pointer",
              width: "100px",
              height: "33px",
              borderRadius: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(180deg, #47B59E 0%, #47B39C 100%)",
            }}
            variant="contained"
            onClick={handleOpenUserMenu}
            className={classes.button}
          >
            <div className={classes.content}>
              <Person sx={{ color: "white" }} />
              <Typography
                style={{
                  fontSize: "15px",
                  fontFamily: "Roboto",
                  fontWeight: "600",
                  lineHeight: "14px",
                  // paddingLeft: "1px",
                  letterSpacing: "1px",
                }}
                className={classes.text}
              >
                {" "}
                LOGIN
              </Typography>
            </div>
          </div>
        </Grid>
      )}

      {isSm ? (
        <Drawer
          id={menuId}
          anchorEl={anchorElUser}
          open={userOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{ background: "primary.main" }}
        >
          {userProfile ? (
            <Box sx={{ p: 2, mt: 2 }}>
              <Person />
              <Typography>
                <b>{userProfile?.name} </b>
              </Typography>
              <Typography>{userProfile?.email}</Typography>
              <Typography>{userProfile?.mobile}</Typography>
            </Box>
          ) : (
            <Box />
          )}

          {userType === "buyer" && 
          <>
            <Box sx={{
              height: "32px",
              width: "122px",
              border: "1px solid #000000",
              borderRadius: "6px",
              marginX:"16px"
            }}>
              <Grid sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    width: "55px",
                    height: "22px",
                    color: userType === "buyer" ? "#FFFFFF" : 'initial',
                    margin: "5px",
                    textAlign: "center",
                    borderRadius: "6px",
                    fontSize: "14px",
                    cursor: "pointer",
                    backgroundColor: userType === "buyer" ? "#2B2C30" : 'initial',
                    transition: "0.1s"
                  }}
                  onClick={switchUser}
                >
                  Buyers
                </Typography>
                <Typography
                  sx={{
                    width: "55px",
                    height: "22px",
                    color: userType === "vendor" ? "#FFFFFF" : 'initial',
                    margin: "5px",
                    textAlign: "center",
                    borderRadius: "6px",
                    fontSize: "14px",
                    cursor: "pointer",
                    backgroundColor: userType === "vendor" ? "#2B2C30" : 'initial',
                    transition: "0.1s"
                  }}
                  onClick={switchUser}
                >
                  Sellers
                </Typography>
                {/* )} */}
              </Grid>
            </Box>
            {buyerProfileMenuList?.map((item) => (
              <>
                <Link href={item.link} sx={{ textDecoration: "none" }}>
                  <MenuItem
                    sx={{ color: "black", p: 1.5 }}
                    onClick={handleClose}
                  >
                    {item.icon}
                    &nbsp;{item.name}
                  </MenuItem>
                </Link>
                <Divider />
              </>
            ))}</>}
          {userType === "vendor" &&<>
          <Box sx={{
              height: "32px",
              width: "122px",
              border: "1px solid #000000",
              borderRadius: "6px",
              marginX:"16px"
            }}>
              <Grid sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    width: "55px",
                    height: "22px",
                    color: userType === "buyer" ? "#FFFFFF" : 'initial',
                    margin: "5px",
                    textAlign: "center",
                    borderRadius: "6px",
                    fontSize: "14px",
                    cursor: "pointer",
                    backgroundColor: userType === "buyer" ? "#2B2C30" : 'initial',
                    transition: "0.1s"
                  }}
                  onClick={switchUser}
                >
                  Buyers
                </Typography>
                <Typography
                  sx={{
                    width: "55px",
                    height: "22px",
                    color: userType === "vendor" ? "#FFFFFF" : 'initial',
                    margin: "5px",
                    textAlign: "center",
                    borderRadius: "6px",
                    fontSize: "14px",
                    cursor: "pointer",
                    backgroundColor: userType === "vendor" ? "#2B2C30" : 'initial',
                    transition: "0.1s"
                  }}
                  onClick={switchUser}
                >
                  Sellers
                </Typography>
                {/* )} */}
              </Grid>
            </Box>
           
                <MenuItem
                  onClick={handleBrClick}
                  sx={{
                    // padding: "2px 6px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    color: "black",
                    // fontSize: "14px",
                    letterSpacing: "0.14px",
                    cursor: "pointer",
                    // paddingLeft: 1.7 ,

                    "&:hover": {
                      backgroundColor: "#D9D9D9",
                      transition: "background-color 0.3s ease-in"
                    },
                  }}
                >
                  {supplierId ? "Edit Business" : "Add Business"}
                </MenuItem>
              
        
            {vendorProfileMenuList?.map((item) => (
              <>
                <Link href={item.link} sx={{ textDecoration: "none" }}>
                  <MenuItem
                    sx={{ color: "black", }}
                    onClick={handleClose}
                  >
                    {item.icon}
                    &nbsp;{item.name}
                  </MenuItem>
                </Link>
                <Divider />
              </>
            ))  }</>}
          <Link onClick={handleLogout} sx={{ textDecoration: "none" }}>
            <MenuItem sx={{ color: "black", p: 1.5 }} onClick={handleClose}>
              <Logout fontSize="small" />
              &nbsp;Logout
            </MenuItem>
          </Link>
        </Drawer>
      ) : (
        <Menu
          id={menuId}
          anchorEl={anchorElUser}
          open={userOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >

          {userType === "vendor" && vendorProfileMenuList?.map((item) => (
            <>
              <Link href={item.link} sx={{ textDecoration: "none" }}>
                <MenuItem sx={{ color: "black", p: 1.5 }} onClick={handleClose}>
                  {item.icon}
                  &nbsp;{item.name}
                </MenuItem>
              </Link>
              <Divider />
            </>
          ))}
          {userType === "buyer" && buyerProfileMenuList?.map((item) => (
            <>
              <Link href={item.link} sx={{ textDecoration: "none" }}>
                <MenuItem sx={{ color: "black", p: 1.5 }} onClick={handleClose}>
                  {item.icon}
                  &nbsp;{item.name}
                </MenuItem>
              </Link>
              <Divider />
            </>
          ))}
          
          <Link onClick={handleLogout} sx={{ textDecoration: "none" }}>
            <MenuItem sx={{ color: "black", padding: "5px", marginTop: "10px" }} onClick={handleClose}>
              <Logout fontSize="small" />
              &nbsp;Logout
            </MenuItem>
          </Link>
        </Menu>
      )}
      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />
      <VerificationPopUp
        open={isModalOpen} // Pass the modal visibility state
        handleClose={handleCloseModal} // Pass the close modal function
      />
      {thankYouPopUp && (
        <ThankYou
          thankYouPopUp={thankYouPopUp}
          setThankYouPopUp={setThankYouPopUp}
        />
      )}
    </Box>
  );
};

ProfileMenuButton.propTypes = {
  menuItems: PropTypes.array,
};

export default ProfileMenuButton;
