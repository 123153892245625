import React from "react";
import { CdHeader, CdMainSection } from "./components";
import SidePanel from "layouts/SidePanel";

const CompanyDirectory = () => {
  return (
    <SidePanel>
      <div className="h-screen flex flex-col bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
        <div className="h-20">
          <CdHeader />
        </div>

        <div className="flex-grow overflow-x-auto overflow-y-auto">
          <CdMainSection />
        </div>
      </div>
    </SidePanel>
  );
};

export default CompanyDirectory;
