import { Button } from '@mui/base'
import { TableCell, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';

export const MobileProjectRow = ({cellStyle,data}) => {
    const [showFullText, setShowFullText] = useState(false);
    const toggleFullText = () => {
      setShowFullText(!showFullText);
    };
  return <>
 
    <TableCell style={cellStyle}>
      {data.description.length > 200 ? (
        <Typography fontSize="14px" fontWeight="400">
          {showFullText
            ? data.description
            : data.description.slice(0, 200)}
          {showFullText ? " " : "...  "}
          <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#2864BE",
                  cursor:"pointer"
                }}
                onClick={toggleFullText}
              >
                {" "}
                {showFullText ? "Read Less" : "Read More"}{" "}
              </span>
        </Typography>
      ) : (
        <Typography fontSize="14px" fontWeight="400">
          {data.description ? data.description : "Description"}
        </Typography>
      )}
    </TableCell>

   </>
}
