import React from 'react'
import LogInMobilePopUp from 'views/Auth/SignInSendMobileOTP/LogInMobilePopUp'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, useMediaQuery, useTheme, Typography } from '@mui/material';
import BookDemoForm from 'views/Home/components/BookDemoForm/BookDemoForm';
import ThankYou from 'views/Home/components/BookDemoForm/ThankYou';
import VerificationPopUp from 'views/VerificationModal';
import { useNavigate } from 'react-router';
import axios from "utils/axios";
import { getBusinessPermissions } from 'utils/accessControl';


const commonButtonStyles = {
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.2)",
    width: "50%"
};
const textStyle = {
    color: '#000000',
    fontWeight: 700,
    fontSize: '18px',
}

const FloatingButton = (setIsModalOpen) => {
    const [openLogInModal, setOpenLogInModal] = useState(false);
    const [openCompo, setOpenCompo] = React.useState(false);
    const [thankYouPopUp, setThankYouPopUp] = React.useState();

    const theme = useTheme();
    const navigate = useNavigate();
    const isMd = useMediaQuery(theme.breakpoints.down("md"));
    const auth = useSelector((state) => state.auth);
    const business = useSelector((state) => state.business);
    const isPublicProfile = false;
    const path = window.location.pathname

    const userProfile = auth.user;
    const userType = auth?.switchUserType || userProfile?.userType;

  const supplierId = business?.profile?.supplierId;
  const entityId = userProfile?.userEntity?.entityId;
    const isMyRfqButtonClicked = path == "/requirements"
    const isShortListButtonClicked = path == "/shortlisted-profile"
    const isLeadsButtonClicked = path == "/leads"
    const isEditBussinessButtonClicked = path == "/my-business"
    const isBookDemo = path == "/get-started"

    const handleGetStartedFree = () => {
        navigate("/get-started")
        window.scroll(0, 0)
    }

    const handleCloseLogInModal = () => {
        setOpenLogInModal(false);
    };


    const handleMrClick = () => {
        if (userProfile?.isVerified === false) {
            setIsModalOpen(true);
        } else {
            navigate("/requirements");

        }
    };

    const handleMyLeadClick = () => {
        if (!userProfile) {
            setOpenLogInModal(true);
        } else {
            if (userProfile?.isVerified === false) {
                setIsModalOpen(true);
            } else {
                if (userType === "vendor" || supplierId) {
                    navigate("/leads");
                }
            }
        }
    };

    const handleShortlistedVendorsClick = () => {
        if (!userProfile) {
            setOpenLogInModal(true);
        } else {
            if (userProfile?.isVerified === false) {
                setIsModalOpen(true);
            } else {
                navigate("/shortlisted-profile");
            }
        }
    };

    const handleBrClick = () => {
        if (!userProfile) {
            setOpenLogInModal(true);
        } else {
           
                if (permissions.isOwner === true) {
                    navigate("/my-business");
                } else {
                    navigate("/subscribe-plan")
                    // navigate("/register/business");

                    // setThankYouPopUp(true);

                   
                }
            
        }
    };

    const permissions = getBusinessPermissions(
        isPublicProfile,
        userProfile?.userId,
        business?.profile
      );
    return (<>
        {userProfile && isMd ?
            <Grid sx={{
                display: "flex",
                justifyContent: "center",
                position: "sticky",
                bottom: 0,
                height: "54px",
                zIndex: "99",
            }} >
                {isMd && userType === "buyer" && <> <Grid
                    sx={{
                        ...commonButtonStyles,
                    }}
                    onClick={handleMrClick}>
                    <Typography sx={{
                        ...textStyle,
                        borderBottom: isMyRfqButtonClicked ? '3px solid #04EEAD' : '#FFFFFF',
                    }}>
                         {entityId?   "Requirements":"Enquiries"}</Typography>
                </Grid>
                    <Grid sx={{
                        ...commonButtonStyles,
                    }} onClick={handleShortlistedVendorsClick}>
                        <Typography sx={{
                            ...textStyle,
                            borderBottom: isShortListButtonClicked ? '3px solid #04EEAD' : '#FFFFFF',
                        }}>
                            Shortlists
                        </Typography>
                    </Grid> </>}


                {
                    isMd && userType === "vendor" && <> <Grid sx={{
                        ...commonButtonStyles,
                    }} onClick={handleBrClick}>
                        <Typography
                            sx={{
                                ...textStyle,
                                borderBottom: (isEditBussinessButtonClicked || thankYouPopUp) && '3px solid #04EEAD',
                            }}
                        >{permissions.isOwner === true ? "Profile" : "Register as Vendor"}</Typography>
                    </Grid>
                        <Grid width={"137px"} sx={{
                            ...commonButtonStyles,
                        }} onClick={handleMyLeadClick}>
                            <Typography
                                sx={{
                                    ...textStyle,
                                    borderBottom: isLeadsButtonClicked ? '3px solid #04EEAD' : '#FFFFFF',
                                }}
                            >Leads</Typography>
                        </Grid> </>
                }
            </Grid >
            :
            isMd &&
            <Grid sx={{
                display: "flex",
                justifyContent: "center",
                position: "sticky",
                bottom: 0,
                height: "54px",
                zIndex: "99"
            }}>
                {isMd && <> <Grid sx={{
                    ...commonButtonStyles,
                }} onClick={handleBrClick}>
                    <Typography
                        sx={{
                            ...textStyle,
                            borderBottom: (isEditBussinessButtonClicked || openLogInModal ) && '3px solid #04EEAD',
                        }}
                    >{permissions.isOwner === true ? "Profile" : "Register as Vendor"}</Typography>
                </Grid>
                    <Grid width={"137px"} sx={{
                        ...commonButtonStyles,
                    }} onClick={handleGetStartedFree} >
                        <Typography
                            sx={{
                                ...textStyle,
                                borderBottom: isBookDemo && '3px solid #04EEAD',
                            }}
                        >Book a Demo</Typography>
                    </Grid>
                </>}
            </Grid>
        }

        {
            <LogInMobilePopUp
                setOpenLogInModal={setOpenLogInModal}
                openLogInModal={openLogInModal}
                handleCloseLogInModal={handleCloseLogInModal}
            />
        }
        {thankYouPopUp && (
            <ThankYou
                thankYouPopUp={thankYouPopUp}
                setThankYouPopUp={setThankYouPopUp}
            />
        )}
        {openCompo && (
            <BookDemoForm
                openCompo={openCompo}
                setOpenCompo={setOpenCompo}
                setThankYouPopUp={setThankYouPopUp}
            />
        )}


    </>)
}

export default FloatingButton