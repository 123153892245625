import { data } from "autoprefixer";
import searchService from "services/searchService";

export const SEARCH = "@search/search";
export const SEARCH_SUCCESS = "@search/search-success";
export const SEARCH_FAILURE = "@search/search-failure";

export const SEARCH_COMPANY = "@search/company";
export const SEARCH_COMPANY_SUCCESS = "@search/company-success";
export const SEARCH_COMPANY_FAILURE = "@search/company-failure";

export const SEARCH_FILTERED = "@search/filtered";
export const SEARCH_FILTERED_SUCCESS = "@search/filtered-success";
export const SEARCH_FILTERED_FAILURE = "@search/filtered-failure";

export const SEARCH_PAGE = "@search/page";
export const SEARCH_PAGE_SUCCESS = "@search/page-success";
export const SEARCH_PAGE_FAILURE = "@search/page-failure";

export const CLEAR_NETWORK_FILTERS = "@search/filter-clear";

export function search(searchKeyWord, region) {
  return async (dispatch) => {
    let payload; // Declare payload variable outside the try block
    const regionString = region
      ? region
          ?.split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "Pan India";
    // console.log("this is a search key wo0rd = ", regionString);

    try {
      let modifiedKeyword = searchKeyWord;
      let searchType = "";

      dispatch({
        type: SEARCH,
        payload: {
          searchKeyWord: modifiedKeyword, // Use modifiedKeyword in the payload
          searchType: searchType,
        },
      });

      if (
        regionString == "Pan India" ||
        regionString == undefined ||
        regionString == ""
      ) {
        payload = await searchService.search(modifiedKeyword);
      } else {
        payload = await searchService.search(
          modifiedKeyword,
          `regions.Allcities_str:("${regionString}")`
        );
      }

      dispatch({
        type: SEARCH_SUCCESS,
        payload: {
          ...payload,
        },
      });

      // TODO: Fetch the rest of the information for the user to initialize the application
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SEARCH_FAILURE });
      throw error;
    }
  };
}

export function filteredSearch(
  searchKeyWord,
  regionString,
  filterStr,
  searchType,
  facetTitle,
  facetValue,
  isSelected
) {
  return async (dispatch) => {
    try {
      let modifiedKeyword = searchKeyWord;
      const regionFiltrString = `regions.Allcities_str:("${regionString}")`;

      dispatch({
        type: SEARCH_FILTERED,
        payload: {
          facetTitle,
          facetValue,
          isSelected,
        },
      });
      // console.log("SearchAction.filteredSearch:", searchKeyWord, filterStr);
      // const payload = await searchService.search(modifiedKeyword, filterStr, 0);

      // new filter serch card code by omkar
      let payload;
      // if (searchType === 'all') {
      if (
        regionString == "Pan India" ||
        regionString == undefined ||
        regionString == ""
      ) {
        payload = await searchService.search(modifiedKeyword, filterStr, 0);
      } else {
        payload = await searchService.search(
          modifiedKeyword,
          regionFiltrString + " AND " + filterStr,
          0
        ); // WE add region string the apply other filter
      }

      /////////////// the code end here
      // console.log("SearchAction.filteredSearch: Resp:", payload);

      dispatch({
        type: SEARCH_FILTERED_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (err) {
      // console.log("SearchAction.search: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SEARCH_FILTERED_FAILURE });
      throw error;
    }
  };
}

export const paginatedSearch = ({
  searchKeyWord,
  regionString = "Pan India",
  filterStr = "",
  // searchType = "All", // searchType is not used
  pageNo = 1,
}) => {
  return async (dispatch) => {
    try {
      const regionFilterString = `regions.Allcities_str:("${regionString}")`;
      dispatch({
        type: SEARCH_PAGE,
        payload: {
          searchKeyWord,
          filterStr,
          pageNo,
        },
      });
      const payload =
        regionString === "Pan India"
          ? await searchService.search(searchKeyWord, filterStr, pageNo)
          : await searchService.search(
              searchKeyWord,
              regionFilterString + " AND " + filterStr,
              pageNo
            );
      dispatch({
        type: SEARCH_PAGE_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };
      dispatch({ type: SEARCH_PAGE_FAILURE });
      throw error;
    }
  };
};

export function searchByPage(
  searchKeyWord,
  regionString,
  filterStr,
  searchType,
  pageNo
) {
  return async (dispatch) => {
    try {
      let modifiedKeyword = searchKeyWord;
      const regionFiltrString = `regions.Allcities_str:("${regionString}")`;

      // if (searchKeyWord.endsWith('all')) {
      //   modifiedKeyword = searchKeyWord.slice(0, -3);

      // } else if (searchKeyWord.endsWith('byName')) {
      //   modifiedKeyword = searchKeyWord.slice(0, -6);

      // } else if (searchKeyWord.endsWith('byService')) {
      //   modifiedKeyword = searchKeyWord.slice(0, -9);

      // }
      dispatch({
        type: SEARCH_PAGE,
        payload: {
          modifiedKeyword,
          filterStr,
          pageNo,
          regionString,
        },
      });
      // console.log("SearchAction.searchByPage:", searchKeyWord, filterStr, pageNo);
      // const payload = await searchService.search(modifiedKeyword, filterStr, pageNo);
      // new filter serch card code by omkar
      let payload;
      if (regionString === "Pan India") {
        payload = await searchService.search(
          modifiedKeyword,
          filterStr,
          pageNo
        );
      } else {
        payload = await searchService.search(
          modifiedKeyword,
          regionFiltrString + " AND " + filterStr,
          pageNo
        );
      }
      // } else if (searchType === 'byName') {
      //   payload = await searchService.searchByName(modifiedKeyword, filterStr, pageNo);
      // } else if (searchType === 'byService') {
      //   payload = await searchService.searchByService(modifiedKeyword, filterStr, pageNo);
      // } else {
      //   payload = await searchService.search(modifiedKeyword, filterStr, pageNo);
      // }
      /////////////// the code end here

      // console.log("SearchAction.filteredSearch: Resp:", payload);

      dispatch({
        type: SEARCH_PAGE_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (err) {
      // console.log("SearchAction.search: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SEARCH_PAGE_FAILURE });
      throw error;
    }
  };
}

export function searchCompany(searchKeyWord) {
  return async (dispatch) => {
    try {
      let modifiedKeyword = searchKeyWord;

      if (searchKeyWord.endsWith("all")) {
        modifiedKeyword = searchKeyWord.slice(0, -3);
      } else if (searchKeyWord.endsWith("byName")) {
        modifiedKeyword = searchKeyWord.slice(0, -6);
      } else if (searchKeyWord.endsWith("byService")) {
        modifiedKeyword = searchKeyWord.slice(0, -9);
      }
      dispatch({
        type: SEARCH_COMPANY,
        payload: {
          modifiedKeyword,
        },
      });
      // console.log("SearchAction.searchCompany:", searchKeyWord);
      const payload = await searchService.searchCompany(modifiedKeyword);

      // console.log("SearchAction.search: Resp:", payload);

      dispatch({
        type: SEARCH_COMPANY_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (err) {
      // console.log("SearchAction.searchCompany: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SEARCH_COMPANY_FAILURE });
      throw error;
    }
  };
}

export function searchNeevayNetwork({
  searchKeyWord,
  regionString,
  filterStr,
  facetTitle,
  facetValue,
  isSelected,
}) {
  return async (dispatch) => {
    try {
      // in that function the region string is compulsory

      dispatch({
        type: SEARCH,
        payload: {
          regionString,
          searchKeyWord: searchKeyWord, // Use modifiedKeyword in the payload
        },
      });

      const regionFilterString = `regions.Allcities_str:("${regionString}")`;

      dispatch({
        type: SEARCH_FILTERED,
        payload: {
          facetTitle,
          facetValue,
          isSelected,
        },
      });
      const payload =
        regionString?.toLowerCase() === "pan india" ||
        regionString?.toLowerCase() === "pan-india"
          ? await searchService.search(searchKeyWord, filterStr, 0)
          : await searchService.search(
              searchKeyWord,
              regionFilterString + " AND " + filterStr,
              0
            ); // WE add region string the apply other filter

      dispatch({
        type: SEARCH_FILTERED_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (err) {
      // console.log("SearchAction.search: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SEARCH_FILTERED_FAILURE });
      throw error;
    }
  };
}

export function neevayNetworkFilteredSearch({
  searchKeyWord = "",
  regionString = "Pan India",
  filterStr,
  facetTitle,
  facetValue,
  isSelected,
}) {
  return async (dispatch) => {
    try {
      // in that function the region string is compulsory

      const regionFilterString = `regions.Allcities_str:("${regionString}")`;

      dispatch({
        type: SEARCH_FILTERED,
        payload: {
          facetTitle,
          facetValue,
          isSelected,
        },
      });
      const payload =
        regionString?.toLowerCase() === "pan india" ||
        regionString?.toLowerCase() === "pan-india"
          ? await searchService.search(searchKeyWord, filterStr, 0)
          : await searchService.search(
              searchKeyWord,
              regionFilterString + " AND " + filterStr,
              0
            ); // WE add region string the apply other filter

      dispatch({
        type: SEARCH_FILTERED_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (err) {
      // console.log("SearchAction.search: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SEARCH_FILTERED_FAILURE });
      throw error;
    }
  };
}
