import {
  BUSINESS_DATA_FETCH,
  BUSINESS_DATA_FETCH_FAILURE,
  BUSINESS_DATA_NOT_FOUND,
} from "actions/searchBusinessAction";
import produce from "immer";
const initialState = {
  suppliers: [],
};
const searchBusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_DATA_FETCH: {
      return produce(state, (draft) => {
        draft.suppliers = action?.suppliers;
      });
    }
    case BUSINESS_DATA_NOT_FOUND: {
      return produce(state, (draft) => {
        draft.suppliers = [];
      });
    }
    case BUSINESS_DATA_FETCH_FAILURE: {
      return produce(state, (draft) => {
        draft.suppliers = [];
      });
    }
    default: {
      return state;
    }
  }
};

export default searchBusinessReducer;
