const formats = [
  [
    { className: "ql-bold" },
    { className: "ql-italic" },
    { className: "ql-underline" },
  ],

  [
    {
      className: "ql-list",
      value: "ordered",
    },
    {
      className: "ql-list",
      value: "bullet",
    },
  ],
];

export default formats;
