import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        marginTop: 25
    },
    signature: {
        fontSize: 11,
        textAlign: 'left',
        marginBottom:5,
        marginTop: 25
        // textTransform: 'uppercase',
    },
    address: {
        fontSize: 8,
        textAlign: 'left',
        marginBottom:5,
        marginTop: 20
        // textTransform: 'uppercase',
    }
});

const InvoiceThankYouMsg = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.signature}>This is Computer Generated Invoice no Signature Required</Text>
        <br/><br/>
        <Text style={styles.address}>Regd.Office:S.nO.197/3. Office No.202, Garnets Bay,Opp. Four Point Sherton Hotel,
        Pune,Maharashtra,411014</Text>
    </View>
);

export default InvoiceThankYouMsg;