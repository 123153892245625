import React, { useState } from "react";
import BusinessOfferingCard from "./BusinessOfferingCard";
import { Grid, Typography } from "@mui/material";
import { addServiceTrigger } from "actions/serviceActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function BusinessOffering({ profile, PublicProfile, isEditView, permissions }) {
  const [showAll, setShowAll] = useState(false);
  const services = profile?.services;
  const visibleServices = showAll ? services : services?.slice(0, 2);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddService = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isOwner) {
        dispatch(addServiceTrigger);
        navigate("/add-service");
      } else {
      }
    } else {
      navigate("/subscribe-plan");
    }
    // console.log("handleAddService");
  };
  return (
    <>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          marginBottom: "10px",
          marginLeft: "10px",
        }}
      >
        Business Offerings
      </Typography>

      <Grid style={{ marginLeft: "10px", marginBottom: "20px" }}>
        {permissions &&
          !permissions.isViewOnly &&
          permissions.isFreeUpdateAllowed && (
            <Typography
              sx={{
                fontSize: "15px",
                cursor: "pointer",
              }}
              color="secondary"
              size="small"
              disabled={!(permissions && permissions.isUpdateAllowed)}
              onClick={handleAddService}
            >
              <Link style={{ textDecoration: "underline", color: "#2864BE" }}>
                {" "}
                Click Here{" "}
              </Link>
              <span style={{ color: "black" }}> to Add Service</span>
            </Typography>
          )}
      </Grid>
      {profile?.services?.length > 0 && (
        <Grid>
        {visibleServices?.map((data) => (
          <BusinessOfferingCard       
            data={data}
            IsSearchPublicProfile={true}
            isMobileView={true}
            PublicProfile={PublicProfile}
            imageUrl={
              data.bannerUrl === undefined || data.bannerUrl === ""
                ? undefined
                : data.bannerUrl
            }
            permissions={permissions}
            isEditView={isEditView}
            profile={profile}
          />
        ))}
        {services?.length > 2 && (
          <Typography
            style={{
              color: "#3963AB",
              textAlign: "center",
              textDecoration: "underline",
              fontSize: "16px",
            }}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "View Less Services" : "View More Services"}
          </Typography>
        )}
      </Grid>
        )}
      
    </>
  );
}

export default BusinessOffering;
