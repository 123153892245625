import produce from "immer";
import {
  SUBMIT_MATERIAL_QUOTATION,
  SUBMIT_MATERIAL_QUOTATION_SUCCESS,
  SUBMIT_MATERIAL_QUOTATION_FAILURE,
  SUBMIT_LABOUR_QUOTATION,
  SUBMIT_LABOUR_QUOTATION_SUCCESS,
  SUBMIT_LABOUR_QUOTATION_FAILURE,
  SUBMIT_PROVIDING_QUOTATION,
  SUBMIT_PROVIDING_QUOTATION_SUCCESS,
  SUBMIT_PROVIDING_QUOTATION_FAILURE,
} from "actions/vendorQuotationAction";

const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  quotation: {},
};

const vendorQuotation = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_MATERIAL_QUOTATION:
    case SUBMIT_LABOUR_QUOTATION: 
    case SUBMIT_PROVIDING_QUOTATION: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = false;
        draft.errorMessage = "";
      });
    }
    case SUBMIT_MATERIAL_QUOTATION_SUCCESS:
    case SUBMIT_LABOUR_QUOTATION_SUCCESS: 
    case SUBMIT_PROVIDING_QUOTATION_SUCCESS: {
      const { quotation } = action.payload;
      return produce(state, (draft) => {
        draft.loading = false;
        draft.quotation = quotation;
      });
    }
    case SUBMIT_MATERIAL_QUOTATION_FAILURE:
    case SUBMIT_LABOUR_QUOTATION_FAILURE: 
    case SUBMIT_PROVIDING_QUOTATION_FAILURE: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = true;
        draft.errorMessage = action.payload.error || "Something went wrong please try again";
      });
    }


    default: {
      return state;
    }
  }
};

export default vendorQuotation;
