class NewMember {
  constructor(supplierId, info) {
    this.supplierId = supplierId;
    this.email = info.email;
    this.mobile = info.mobile;
    this.name = info.name;
    this.memberType = info.memberType;


  }
}
export { NewMember };

class DeleteMember {
  constructor(supplierId, info) {
    this.supplierId = supplierId;
    this.memberId = info;
  }
}

export { DeleteMember };

class changeOwnerTypeIf {
  constructor(supplierId, ownerType, memberId) {
    this.supplierId = supplierId;
    this.ownerType = ownerType;
    this.memberId = memberId;
  }
}
export { changeOwnerTypeIf };