import React from 'react'
// Testimonials data
const testimonials = [
    {
        text: "The platform's user-friendly interface and diverse options made our decision-making process smooth and stress-free. A valuable resource for anyone in the construction industry!",
        name: "Megha Puranik",
        title: "PURCHASE HEAD",
        company: "VASCON ENGINEERS LTD",
        imgSrc: "https://storagereponeevaydevcdn.blob.core.windows.net/business/Testimonial_2.svg" // Add the image source here
    },
    {
        text: "As a steel seller, Neevay portal has really helped our business. Easy connections with buyers and a straight forward approach makes it a valuable resource for us.",
        name: "Gokul Tiwari",
        title: "OWNER",
        company: "AMP IMPEX",
        imgSrc: "https://storagereponeevaydevcdn.blob.core.windows.net/business/Testimonial_1.svg" // Add the image source here
    },
    // Add more testimonials here
];
const Testimonials = () => {
    return (
        <div className='bg-[#ECECEC] xl:py-[90px] md:py-[60px] py-[60px]'>
            <p className='text-center font-bold px-10 text-2xl md:text-[32px] pb-[38px] xl:pb-[90px]'>What Customers Say About Neevay Vendor Search</p>
            <div className='md:flex md:justify-evenly space-y-5 md:space-y-0'>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className='w-[343px] mx-auto md:w-[334px] md:h-[354px] lg:w-[460px] lg:h-[373px] xl:w-[580px] xl:h-[352px] bg-gradient-to-tr from-[#0B0B1A] to-[#1B1B26]'>
                        <div className='pt-4 px-[30px] md:px-[26px] lg:px-[44px] py-[30px] md:py-[44px] xl:px-[52px]'>
                            <p className='text-white pb-[19px] text-xs md:text-sm xs:py-3 md:py-0 md:pb-4'> CUSTOMER STORY</p>
                            <p className='xl:h-28 lg:h-[134px] text-sm lg:text-lg xl:text-xl md:text-base xl:font-bold text-white'>{testimonial.text}</p>
                            {/* <p className='hidden lg:block text-white'>{testimonial.text.split(" ").slice(0, 20).join(" ")}<br />{testimonial.text.split(" ").slice(20, 40).join(" ")}<br />{testimonial.text.split(" ").slice(40).join(" ")}</p>
                            <p className='hidden xl:block text-white font-bold'>{testimonial.text.split(" ").slice(0, 20).join(" ")}<br />{testimonial.text.split(" ").slice(20, 40).join(" ")}<br />{testimonial.text.split(" ").slice(40).join(" ")}</p>
                            <p className='md:hidden text-white'>{testimonial.text.split(" ").slice(0, 20).join(" ")}<br />{testimonial.text.split(" ").slice(20, 40).join(" ")}<br />{testimonial.text.split(" ").slice(40).join(" ")}</p> */}
                            <div className='flex items-center pt-[25px] md:pt-8 lg:pt-[25px] xl:pt-8'>
                                <img src={testimonial.imgSrc} alt={testimonial.name} className='bg-white h-[50px] w-[50px] xl:h-[84px] xl:w-[84px] lg:w-[88px] lg:h-[88px] md:h-[66px] md:w-[66px] rounded-full' />
                                <p className='text-white font-bold text-xs md:text-xl xs:pl-4 md:pl-[19px]'>{testimonial.name}<br /><span className='md:text-sm font-normal text-[10px]'>{testimonial.title}<br />{testimonial.company}</span></p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Testimonials