import React, { useState } from "react";
import UploadMaterialFile from "./UploadMaterialFile";
import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import Loading from "components/Loading";

const MaterialServiceView = ({
  handleSubmit,
  loading,
  edit,
  setProjectViewEdit,
  projectViewEdit,
}) => {
  const { values, errors, setFieldValue } = useFormikContext();

  const [isEdit, setIsEdit] = useState(!projectViewEdit);
  const [showUploadMaterialFile, setShowUploadMaterialFile] = useState(false);
  const [projectMaterialDetails, setProjectMaterialDetails] = useState({});

  const handleCloseUploadMaterial = () => {
    setShowUploadMaterialFile(false);
  };

  if (values?.materials?.length === 0 && edit && !isEdit) {
    return (
      <div className="h-[40vh] w-full flex flex-col justify-center items-center">
        <h5>No material / service add</h5>
        <button
          className="bg-blue-800 rounded-lg text-white px-2 py-1 mt-4"
          onClick={() => {
            setFieldValue("materials", [{ type: "", name: "", basePrice: "" }]);
            setIsEdit(true);
          }}
        >
          Add material/service
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex justify-end py-1">
        {isEdit && (
          <button
            type="button"
            onClick={() => {
              setShowUploadMaterialFile(true);
            }}
            className="bg-blue-600 ml-auto rounded-lg text-white px-2 py-1"
          >
            Bulk Upload
          </button>
        )}
      </div>
      <FieldArray name="materials">
        {({ insert, remove, push }) => (
          <div>
            <p className="font-medium mb-2">Material / Service details:</p>
            <div className="flex pl-2 w-full gap-2 space-x-4">
              {!isEdit && (
                <div className="w-[10%]">
                  <label className="block text-sm font-medium">Sr. No</label>
                </div>
              )}
              <div className="w-[20%]">
                <label className="block text-sm font-medium">
                  Type
                </label>
              </div>
              <div className="w-[20%]">
                <label className="block text-sm font-medium">
                  Name
                </label>
              </div>
              <div className="w-[20%]">
                <label className="block text-sm font-medium">
                  Budget Rate
                </label>
              </div>
              <div className="w-[20%]">
                <label className="block text-sm font-medium">
                </label>
              </div>
            </div>
            {values.materials.map((material, index) => (
              <div
                key={index}
                className={`flex pl-2 w-full gap-2 space-x-4 ${isEdit && "mb-2"}`}
              >
                {!isEdit && (
                  <div className="w-[10%]">
                    <p className="p-1 pl-0 w-full">{index + 1}</p>
                  </div>
                )}
                <div className="w-[20%]">
                  {isEdit ? (
                    <>
                      <Field
                        name={`materials.${index}.type`}
                        as="select"
                        className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="" label="Select type" />
                        {["Material", "Labour"].map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name={`materials.${index}.type`}
                        component="div"
                        className="mt-2 text-sm text-red-500"
                      />
                    </>
                  ) : (
                    <p className="p-1 pl-0 w-full">{material?.type || "--"}</p>
                  )}
                </div>
                <div className="w-[20%]">
                  {isEdit ? (
                    <>
                      <Field
                        name={`materials.${index}.name`}
                        type="text"
                        className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        name={`materials.${index}.name`}
                        component="div"
                        className="mt-2 text-sm text-red-500"
                      />
                    </>
                  ) : (
                    <p className="p-1 pl-0 w-full">{material?.name || "--"}</p>
                  )}
                </div>
                <div className="w-[20%]">
                  {isEdit ? (
                    <>
                      <Field
                        name={`materials.${index}.basePrice`}
                        type="text"
                        className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        name={`materials.${index}.basePrice`}
                        component="div"
                        className="mt-2 text-sm text-red-500"
                      />
                    </>
                  ) : (
                    <p className="p-1 pl-0 w-full">
                      {material?.basePrice || "--"}
                    </p>
                  )}
                </div>
                <div>
                  {values.materials.length > 1 && isEdit && (
                    <button
                      type="button"
                      className="mt-2"
                      onClick={() => remove(index)}
                    >
                      <svg
                        className="w-6 h-6"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        tabIndex="-1"
                        title="DeleteOutline"
                      >
                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM8 9h8v10H8zm7.5-5-1-1h-5l-1 1H5v2h14V4z"></path>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            ))}
            {isEdit && (
              <button
                type="button"
                className="underline p-2"
                onClick={() => push({ type: "", name: "", basePrice: "" })}
              >
                {values?.materials?.length === 0
                  ? "Add material/service"
                  : " +Add More"}
              </button>
            )}
          </div>
        )}
      </FieldArray>
      <UploadMaterialFile
        show={showUploadMaterialFile}
        onClose={handleCloseUploadMaterial}
        setProjectMaterialDetails={setProjectMaterialDetails}
        projectMaterialDetails={projectMaterialDetails}
        values={values}
        setFieldValue={setFieldValue}
      />
      <div className="px-1 py-2 sticky bottom-0 bg-gray-50 mx-[-18px]"
      style={{
        boxShadow: "0px -1px 16.100000381469727px 0px rgba(0, 0, 0, 0.05)",
      }}
      >
        {edit ? (
          <div>
            {!projectViewEdit ? (
              <button
                type="submit"
                className="bg-blue-500 rounded-lg text-white p-2 mt-4"
              >
                {loading ? <Loading /> : "Save Changes"}
              </button>
            ) : (
              <button
                type="button"
                className="bg-blue-500 rounded-lg text-white p-1 px-3 mt-4"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(true);
                  setProjectViewEdit(false);
                }}
              >
                Edit
              </button>
            )}
          </div>
        ) : (
          <button
            type="submit"
            className="bg-blue-800 rounded-lg text-white px-2 py-1 mt-4"
          >
            {loading ? <Loading /> : "Submit"}
          </button>
        )}
      </div>
    </>
  );
};

export default MaterialServiceView;
