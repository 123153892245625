import React, { memo, useEffect } from "react";


import MainLayout from "layouts/Main";

import { Helmet } from "react-helmet";
import PrivacyPolicyData from "./PrivacyPolicyData";

export function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainLayout>
      <Helmet>
        <title>Neevay - Privacy Policy</title>
      </Helmet>
      <PrivacyPolicyData />
    </MainLayout>
  );
}

PrivacyPolicy.propTypes = {};

export default memo(PrivacyPolicy);
