import memberService from "services/memberService";
import memberDlt from "services/memberService";
import {changeOwnerTypeIf} from "interface/memberIf"

import { NewMember } from "interface/memberIf";
import { DeleteMember } from "interface/memberIf";

export const MEMBERS_FETCH = "@members/fetch";
export const MEMBERS_FETCH_SUCCESS = "@members/fetch-success";
export const MEMBERS_FETCH_FAILURE = "@members/fetch-failure";

export const ADD_MEMBERS = "@members/add-member";
export const ADD_MEMBERS_SUCCESS = "@members/add-success";
export const ADD_MEMBERS_FAILURE = "@members/add-failure";

export const DELETE_MEMBER = "@members/delete-member";
export const DELETE_MEMBER_SUCCESS = "@members/delete-member-success";
export const DELETE_MEMBER_FAILURE = "@members/delete-member-failure";

export const CHANGE_OWNER = "@members/change-owner";
export const CHANGE_OWNER_SUCCESS = "@members/change-owner";
export const CHANGE_OWNER_FAILURE = "@members/change-owner";

export function fetchMembers(supplierId) {
  return async (dispatch) => {
    try {
      dispatch({ type: MEMBERS_FETCH });

      const members = await memberService.fetchMember(supplierId);

      // console.log("Members====?????????????????" + JSON.stringify(members));
      dispatch({
        type: MEMBERS_FETCH_SUCCESS,
        payload: {
          members,
        },
      });
    } catch (error) {
      dispatch({ type: MEMBERS_FETCH_FAILURE });
      throw error;
    }
  };
}

export function addMembers(supplierId, values) {
  // console.log("add member action", supplierId,  values )
  return async (dispatch) => {
    const newMember = new NewMember(supplierId, values);
    try {
      dispatch({
        type: ADD_MEMBERS,
        payload: {
          newMember,
        },
      });

      const payload = await memberService.addMember(newMember);

      dispatch({
        type: ADD_MEMBERS_SUCCESS,
        payload: {
          ...payload,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: ADD_MEMBERS_FAILURE });
      throw error;
    }
  };
}

export function memberDelete(userId, paramsId) {
  return async (dispatch) => {
    const deleteMember = new DeleteMember(userId, paramsId);
    try {
      dispatch({
        type: DELETE_MEMBER,
        payload: {
          deleteMember,
        },
      });

      const payload = await memberService.memberDlt(deleteMember);

      dispatch({
        type: DELETE_MEMBER_SUCCESS,
        payload: {
          ...payload,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: DELETE_MEMBER_FAILURE });
      throw error;
    }
  };
}

export function changeOwnerType(supplierId, ownerType, memberId) {
  // console.log("in action", supplierId, ownerType, memberId )
  const changeOwnerType = new changeOwnerTypeIf(supplierId, ownerType, memberId);
  return async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_OWNER,
        payload: {
          changeOwnerType,
        },
      });

      const payload = await memberService.changeOwner(changeOwnerType);

      dispatch({
        type: CHANGE_OWNER_SUCCESS,
        payload: {
          ...payload,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: CHANGE_OWNER_FAILURE });
      throw error;
    }
  };
}
