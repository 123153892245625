import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { searchRepeatFixedRfq, searchRfqs } from "actions/requirementsAction";

const SearchRfqs = ({
  searchValue,
  setSearchValue,
  selectedUserId,
  setPageNumber,
}) => {
  const dispatch = useDispatch();

  const handleSearch = async () => {
    dispatch(searchRfqs(selectedUserId, searchValue));
    dispatch(searchRepeatFixedRfq(selectedUserId, searchValue, 1));
    setPageNumber(1);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      dispatch(searchRfqs(selectedUserId, searchValue));
      dispatch(searchRepeatFixedRfq(selectedUserId, searchValue, 1));
      setPageNumber(1);
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="flex-start"
      gap="10px"
    >
      <TextField
        size="small"
        width="100px"
        sx={{ width: "250px", ml: "10px" }}
        placeholder="Search in name or description"
        variant="outlined"
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleEnterKey}
      />
      <Button
        variant="contained"
        // color="primary"
        style={{
          backgroundColor: "#808080",
          "&:hover": {
            backgroundColor: "#DADCE0",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
            color: "white",
          },
        }}
        onClick={handleSearch}
        sx={{ ml: "10px", padding: "4px" }}
      >
        <SearchIcon />
      </Button>
    </Box>
  );
};

export default SearchRfqs;
