import {
  deleteRepeatFixedRfq,
  fetchRepeatFixedRfq,
} from "actions/requirementsAction";
import { Button, Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateFormatter } from "utils/formatUtils";
import RepeatColData from "./RepeatColData";
import { PaginationSection } from "views/CompanyDirectory/components";
import EditRepeatOrderForm from "./EditRepeatOrderForm";
import StaticPopup from "components/StaticPopup";
import Loading from "components/Loading";
import RepeatFixedRfqPopUp from "views/UserProfile/Requirements/RepeatFixedRfqPopUp";

const RepeatRfqsTable = ({
  repeatFixedRfq,
  handlePageChange,
  pageNumber,
  userId,
  teamMemberData,
  repeatOrderLoading,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const [requirements, setRequirements] = useState([]);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [EditRepeatOrderData, setEditRepeatOrderData] = useState({});
  const [deleteOrderLoading, setDeleteOrderLoading] = useState(false);
  const [deleteOrderPopup, setDeleteOrderPopup] = useState(false);
  const [deleteOrderNumber, setDeleteOrderNumber] = useState(null);
  const repeatColData = RepeatColData;
  const handleOpenEditPopup = (requirement) => {
    setOpenEditPopup(true);
    setEditRepeatOrderData(requirement);
  };

  const openDeleteOrderPopup = (orderNumber) => {
    setDeleteOrderNumber(orderNumber);
    setDeleteOrderPopup(true);
  };

  const closeDeleteOrderPopup = () => {
    setDeleteOrderPopup(false);
    setDeleteOrderNumber(null);
  };

  const handleDeleteOrder = async () => {
    setDeleteOrderLoading(true);
    const payload = {
      repeatRfqId: deleteOrderNumber,
      userId,
      page: pageNumber,
    };
    const response = await dispatch(deleteRepeatFixedRfq(payload));
    setDeleteOrderLoading(false);
    closeDeleteOrderPopup();
  };

  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
  };

  useEffect(() => {
    if (repeatFixedRfq?.requirements?.length > 0) {
      const updatedRfq = repeatFixedRfq?.requirements?.map((data, index) => {
        return {
          ...data,
          id: index,
          typeOfOrder:
            data.typeOfOrder === "REPEAT_ORDER"
              ? "Repeat Order"
              : data.typeOfOrder === "FIXED_ORDER"
              ? "Fixed Vendor"
              : "Fixed/Repeat Order",
        };
      });

      setRequirements(updatedRfq);
    } else {
      setRequirements(repeatFixedRfq?.requirements);
    }
  }, [repeatFixedRfq]);

  if (repeatOrderLoading) {
    return (
      <div className="flex justify-center items-center h-[50vh] bg-white">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10"></div>
      </div>
    );
  }
  if (requirements?.length == 0) {
    return (
      <div className="flex justify-center items-center h-[50vh] bg-white">
        <p>No result found</p>
      </div>
    );
  }
  return (
    <div>
      <div className="overflow-x-auto bg-white">
        <table className="min-w-full divide-y divide-gray-200 shadow-md rounded-lg">
          <thead className="bg-gray-50">
            <tr>
              {repeatColData?.map(({ headerName }) => (
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <p className="truncate text-left">{headerName}</p>
                </th>
              ))}
              {(teamMemberData[0] == userId || isAdmin) && (
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <p className="truncate text-left">Action</p>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {requirements.map((requirement) => (
              <tr key={requirement?.id} className="hover:bg-blue-100">
                {repeatColData?.map(({ field }) =>
                  field === "createdAt" ? (
                    <td
                      className="w-44 p-4 whitespace-nowrap"
                      onClick={() => handleOpenEditPopup(requirement)}
                    >
                      {dateFormatter(requirement[field])}
                    </td>
                  ) : field === "orderValue" ? (
                    <td
                      className="max-w-48 p-4 whitespace-nowrap"
                      onClick={() => handleOpenEditPopup(requirement)}
                    >
                      <p
                        className="truncate text-right"
                        title={requirement[field]}
                      >
                        {requirement[field] ?? "--"}
                      </p>
                    </td>
                  ) : field === "poDetails" ? (
                    <td
                      className="max-w-48 p-4 whitespace-nowrap"
                      onClick={() => handleOpenEditPopup(requirement)}
                    >
                      <p
                        className="truncate text-left"
                        title={
                          requirement?.poDetails?.length >= 1
                            ? requirement?.poDetails
                                ?.map((data) => data?.poNumber)
                                .join(", ")
                            : "--"
                        }
                      >
                        {requirement?.poDetails?.length >= 1
                          ? requirement?.poDetails
                              ?.map((data) => data?.poNumber)
                              .join(", ")
                          : "--"}
                      </p>
                    </td>
                  ) : (
                    <td
                      className="max-w-48 p-4 whitespace-nowrap"
                      onClick={() => handleOpenEditPopup(requirement)}
                    >
                      <p
                        className="truncate text-left"
                        title={requirement[field]}
                      >
                        {requirement[field] ?? "--"}
                      </p>
                    </td>
                  )
                )}
                {(teamMemberData[0] == userId || isAdmin) && (
                  <td className="max-w-48 z-10 px-6 py-4 whitespace-nowrap">
                    <button
                      className="text-blue-700 font-semibold"
                      onClick={() =>
                        openDeleteOrderPopup(requirement?.repeatRfqId)
                      }
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <style>
          {`
          .overflow-x-auto::-webkit-scrollbar {
            height: 6px;
          }

          .overflow-x-auto::-webkit-scrollbar-thumb {
            background-color: #949494; 
          }
        `}
        </style>
      </div>

      {openEditPopup &&
        (EditRepeatOrderData?.poDetails?.length >= 1 ? (
          <RepeatFixedRfqPopUp
            userId={userId}
            repeatFixedPopUp={openEditPopup}
            setRepeatFixedPopUp={setOpenEditPopup}
            isEdited={true}
            repeatOrderValue={EditRepeatOrderData}
          />
        ) : (
          <EditRepeatOrderForm
            openEditPopup={openEditPopup}
            handleCloseEditPopup={handleCloseEditPopup}
            data={EditRepeatOrderData}
          />
        ))}
      {/* <StaticPopup
        openModal={deleteOrderPopup}
        handleClose={closeDeleteOrderPopup}
        header={"Delete Repeat Order"}
        height={"auto"}
      >
        <p className="font-semibold">
          Are you sure you want to delete this repeat order?
        </p>
        <div className="flex gap-4">
          <button
            className="py-2 px-3 w-14 border rounded-lg bg-blue-700 hover:bg-blue-800 text-white font-semibold flex justify-center"
            onClick={handleDeleteOrder}
          >
            {deleteOrderLoading ? <Loading /> : "Yes"}
          </button>

          <button
            className="py-2 px-3 w-14 border rounded-lg hover:bg-slate-100 font-semibold"
            onClick={closeDeleteOrderPopup}
          >
            No
          </button>
          {repeatFixedRfq?.error?.orderError && (
            <p className="text-red text-sm">
              Something went wrong please try letter
            </p>
          )}
        </div>
      </StaticPopup> */}

      <Modal
        show={deleteOrderPopup}
        size="md"
        onClose={closeDeleteOrderPopup}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            {/* <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" /> */}
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this repeat order?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="blue" onClick={() => handleDeleteOrder()}>
                {deleteOrderLoading ? <Loading /> : "Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => closeDeleteOrderPopup()}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RepeatRfqsTable;
