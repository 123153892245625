import { BUSINESS_TYPE, IMAGE_TYPE } from "config";

export function getBusinessLabel(type) {
  switch (type) {
    case "Proprietorship":
      return "Proprietorship";

    case "Partnership":
      return "Partnership";

    case "Limited Liability Partnership":
      return "Limited Liability Partnership";

    case "Private Limited":
      return "Private Limited";

    case "Public Limited":
      return "Public Limited";

    default:
      return "Others";
  }
}
export function getFileNameByImageType(type, name) {
  let fileName = "";

  switch (type) {
    case IMAGE_TYPE.LOGO:
      fileName = "logo.png";
      break;

    case IMAGE_TYPE.BANNER:
      fileName = "banner.png";
      break;

    case IMAGE_TYPE.SERVICE:
      fileName = "service_" + name + ".png";
      break;

    case IMAGE_TYPE.PROJECT:
      fileName = "project_" + name + ".png";
      break;

    case IMAGE_TYPE.PROFILE:
      fileName = "profile.png";
      break;
    case IMAGE_TYPE.CERTIFICATE:
      fileName = "certificate" + name + ".png";
      break;

    case IMAGE_TYPE.SEARCH:
      fileName = "search" + name + ".png";
      break;

    default:
      fileName = "default.png";
      break;
  }

  // console.log("getFileNameByImageType() ", type, name, fileName);
  return fileName;

}
