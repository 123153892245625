import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

const UploadMaterialFile = ({
  show,
  onClose,
  setProjectMaterialDetails,
  projectMaterialDetails,
  values,
  setFieldValue,
}) => {
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState([]);
  const [fileUploadError, setFileUploadError] = useState(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    formik.setFieldValue("file", file);
  };

  const { getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
    multiple: false,
  });

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required("File is required"),
    }),
    onSubmit: (values) => {
      console.log("Form values:", values);
      onClose();
    },
  });
  const handleFileUploadClick = (event) => {
    return (event.target.value = "");
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Check for required headers
        const requiredHeaders = [
          "Material Type",
          "Material Name",
          "Base Price",
        ];
        const headers = jsonData[0];
        const missingHeaders = requiredHeaders.filter(
          (header) => !headers.includes(header)
        );

        if (missingHeaders.length > 0) {
          setFileUploadError(
            `Excel does not contain ${missingHeaders.join(", ")}`
          );
          setTimeout(() => {
            setFileUploadError(null);
          }, 6000);
        } else {
          // Mapping of original headers to camelCase
          const headerMapping = {
            "Material Type": "type",
            "Material Name": "name",
            "Base Price": "basePrice",
          };

          // Map data to array of objects with camelCase keys
          const dataArray = [];
          for (let i = 1; i < jsonData.length; i++) {
            const row = jsonData[i];
            const rowObj = {};
            let missingField = null;
            requiredHeaders.forEach((header) => {
              const value = row[headers.indexOf(header)];
              if (value === undefined || value === null || value === "") {
                missingField = header;
              }
              rowObj[headerMapping[header]] = value;
            });
            if (missingField) {
              setFileUploadError(
                `Error: Mandatory field '${missingField}' is missing or empty in row ${
                  i + 1
                }`
              );
              setTimeout(() => {
                setFileUploadError(null);
              }, 6000);
              return;
            } else {
              dataArray.push(rowObj);
            }
          }
          setFileData(dataArray);
          console.log("dataArray: ", dataArray);
          setProjectMaterialDetails({
            data: dataArray,
            source: "uploadMaterialFile",
          });
          setFieldValue(
            "materials",
            [...new Set([...values?.materials, ...dataArray])].filter(
              (data) =>
                data.basePrice != "" && data?.name != "" && data?.type != ""
            )
          );
          setFileName(file.name);
          setFileUploadError(null);
          onClose();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    if (projectMaterialDetails?.data?.length == 0) {
      setFileName("");
      setFileData([]);
      setFileUploadError(null);
    }
  }, [projectMaterialDetails]);

  return (
    <>
      {show && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleOverlayClick}
        >
          <div
            className="relative bg-white rounded-lg max-w-lg w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={onClose}
              className="absolute top-6 right-6 text-gray-600 hover:text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="px-6 py-4 border-b border-solid">
              <h1 className="text-2xl font-bold">Upload Material File</h1>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="px-9 pt-5">
                {!fileName ? (
                  <div className="border-dashed border-2 border-gray-300 p-6 text-center">
                    <svg
                      className="mx-auto mb-3"
                      width="149"
                      height="105"
                      viewBox="0 0 149 105"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M33.525 104.75C15.0164 104.75 0 89.7338 0 71.2252C0 56.6046 9.35906 44.1724 22.3966 39.586C22.3733 38.9574 22.35 38.3288 22.35 37.7002C22.35 17.1196 39.0194 0.450195 59.6 0.450195C73.4058 0.450195 85.4422 7.94676 91.8911 19.1218C95.4298 16.7471 99.7136 15.3502 104.3 15.3502C116.639 15.3502 126.65 25.3611 126.65 37.7002C126.65 40.5405 126.115 43.2411 125.16 45.7555C138.756 48.5027 149 60.5391 149 74.9502C149 91.41 135.66 104.75 119.2 104.75H33.525ZM51.9172 54.2299C49.7287 56.4183 49.7287 59.9571 51.9172 62.1222C54.1056 64.2874 57.6444 64.3107 59.8095 62.1222L68.8892 53.0425V84.2627C68.8892 87.3591 71.3803 89.8502 74.4767 89.8502C77.5731 89.8502 80.0642 87.3591 80.0642 84.2627V53.0425L89.1439 62.1222C91.3323 64.3107 94.8711 64.3107 97.0362 62.1222C99.2014 59.9338 99.2247 56.395 97.0362 54.2299L78.4112 35.6049C76.2228 33.4165 72.6841 33.4165 70.5189 35.6049L51.8939 54.2299H51.9172Z"
                        fill="#9F9F9F"
                      />
                    </svg>

                    <input {...getInputProps()} />
                    <div className="flex justify-center mb-2">
                      <input
                        accept=".xlsx,.xls," // Specify the file types you want to allow
                        id="file-upload"
                        type="file"
                        style={{ display: "none" }}
                        onClick={handleFileUploadClick}
                        onChange={handleFileUpload}
                      />
                      <label htmlFor="file-upload">
                        <p className="text-white p-2 bg-blue-700 hover:bg-blue-800 cursor-pointer rounded-lg">
                          Upload Material Excel
                        </p>
                      </label>
                    </div>
                    <p>Drag and Drop the file here</p>
                    {fileUploadError && (
                      <div className="text-red-700 text-sm">
                        {fileUploadError}
                      </div>
                    )}
                    {/* {fileData.length >= 1 && (
                    <pre>{JSON.stringify(fileData, null, 2)}</pre>
                  )} */}
                  </div>
                ) : (
                  <div className="mt-4 flex justify-end">
                    <div className="mx-auto   inline-flex gap-1 bg-gray-200 px-3 py-1 text-sm rounded-full">
                      <p>{fileName}</p>
                      <button
                        className="px-1 rounded-full hover:bg-slate-50 "
                        onClick={() => {
                          setFileName("");
                          setFileData([]);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3 w-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
                <div className="mt-4 text-center flex flex-col gap-3 items-center mb-3">
                  <p className="text-gray-600">Don't have the template?</p>
                  <div>
                    <a
                      href="https://storagereponeevaydevcdn.blob.core.windows.net/business/Material_Template.xlsx"
                      className="text-blue-600 flex gap-1"
                    >
                      <p>Download here</p>
                      <svg
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.5625 1.5625C9.5625 0.974805 9.0877 0.5 8.5 0.5C7.9123 0.5 7.4375 0.974805 7.4375 1.5625V9.6209L5.00039 7.18379C4.58535 6.76875 3.91133 6.76875 3.49629 7.18379C3.08125 7.59883 3.08125 8.27285 3.49629 8.68789L7.74629 12.9379C8.16133 13.3529 8.83535 13.3529 9.25039 12.9379L13.5004 8.68789C13.9154 8.27285 13.9154 7.59883 13.5004 7.18379C13.0854 6.76875 12.4113 6.76875 11.9963 7.18379L9.5625 9.6209V1.5625ZM2.125 12.1875C0.95293 12.1875 0 13.1404 0 14.3125V15.375C0 16.5471 0.95293 17.5 2.125 17.5H14.875C16.0471 17.5 17 16.5471 17 15.375V14.3125C17 13.1404 16.0471 12.1875 14.875 12.1875H11.5049L10.0008 13.6916C9.1707 14.5217 7.82598 14.5217 6.9959 13.6916L5.49512 12.1875H2.125ZM14.3438 14.0469C14.5551 14.0469 14.7578 14.1308 14.9072 14.2803C15.0567 14.4297 15.1406 14.6324 15.1406 14.8438C15.1406 15.0551 15.0567 15.2578 14.9072 15.4072C14.7578 15.5567 14.5551 15.6406 14.3438 15.6406C14.1324 15.6406 13.9297 15.5567 13.7803 15.4072C13.6308 15.2578 13.5469 15.0551 13.5469 14.8438C13.5469 14.6324 13.6308 14.4297 13.7803 14.2803C13.9297 14.1308 14.1324 14.0469 14.3438 14.0469Z"
                          fill="blue"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div
                className="px-9 py-6 shadow-lg flex justify-between"
                style={{
                  boxShadow:
                    "0px -1px 16.100000381469727px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                <button
                  type="submit"
                  className="bg-blue-600 text-white py-2 px-5 rounded"
                  onClick={() => {
                    setProjectMaterialDetails({
                      data: fileData,
                      source: "uploadMaterialFile",
                    });
                    onClose();
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="text-blue-600 px-4 py-2"
                >
                  Cancel
                </button>
              </div> */}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadMaterialFile;
