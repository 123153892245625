import React from "react";
import GlobalSearchBar from "./GlobalSearchBar";
import SearchNew from "./SearchNew";

const NeevayNetworkHeader = () => {
  return (
    <div className="flex items-center justify-between w-full px-2 py-[9px] bg-white shadow-md">
      <div className="min-w-52">
        <h1 className="text-base font-bold pl-2">Vendor Marketplace</h1>
      </div>
      {/* <GlobalSearchBar /> */}
      <SearchNew />
      <div className="w-52"> </div>
    </div>
  );
};

export default NeevayNetworkHeader;
