import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const NeevayToday = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up(1024));
  const small = useMediaQuery(theme.breakpoints.down(600))
  
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <Grid>
      <Grid sx={{ marginBottom: "132px" }}>
        <Box
          width={"100%"}
          component="img"
          src={`${BASE_URL}/assets/business/web-aboutus-desktop-building.webp`}
        />
      </Grid>

      <Grid
        // spacing={8}

        sx={{
          display: "flex",
          alignItems: isMd ? "flex-start" : "center",
          flexDirection: isMd ? "row" : "column",
          gap: "40px",
          marginTop: "20px",
          paddingX: isMd ? "58px" : "20px",
          marginBottom: isMd ? "132px" : "65px",
        }}
      >
        <Box
          component="img"
          sx={{ width: isMd ? "50%" : "100%" }}
          src={`${BASE_URL}/assets/business/web-aboutus-desktop-office.webp`}
        />

        <Grid sx={{ width: isMd ? "50%" : "100%" }}>
          <Typography
            style={{
              fontFamily: "PP Telegraf",
              fontSize: isMd ? "48px" : "32px",
              lineHeight: "62.2px",
              color: "rgba(0, 0, 0, 1)",
              marginBottom: isMd ? "26px" : "20px",
            }}
          >
            Neevay today
          </Typography>
          <Typography
            sx={{
              color: "#63727E",
              fontSize: small ? "14px" : "16px",
              lineHeight: "30px",
              marginBottom: "20px",
            }}
          >
          Neevay has an office in Viman Nagar, Pune and it also calls Mumbai as second home. We’re a very locally driven organization, with teams on the ground meeting contractors, project engineers, onboarding them and listening to their requirements.
          </Typography>
          <Typography
            sx={{
              color: "#63727E",
              fontSize: small ? "14px" : "16px",
              lineHeight: "30px",
            }}
          >
            • 15000+ contractors and suppliers onboarded
          </Typography>
          <Typography
            sx={{
              color: "#63727E",
              fontSize: small ? "14px" : "16px",
              lineHeight: "30px",
            }}
          >
            • 500 Construction buyers including Vascon Engineers, Nyati, Reliance Retail
          </Typography>
          <Typography
            sx={{
              color: "#63727E",
              fontSize: small ? "14px" : "16px",
              lineHeight: "30px",
            }}
          >
            • 1500+ Matches/month
          </Typography>
        </Grid>
      </Grid>

      <Grid
        // spacing={8}

        sx={{
          display: "flex",
          alignItems: isMd ? "flex-start" : "center",
          flexDirection: isMd ? "row" : "column-reverse",
          gap: "40px",
          marginTop: "20px",
          paddingX: isMd ? "58px" : "20px",
          marginBottom: isMd ? "132px" : "65px",
        }}
      >
       
        <Grid sx={{ width: isMd ? "50%" : "100%" }}>
          <Typography
            style={{
              fontFamily: "PP Telegraf",
              fontSize: isMd ? "48px" : "32px",
              lineHeight: "62.2px",
              color: "rgba(0, 0, 0, 1)",
              marginBottom: isMd ? "26px" : "20px",
            }}
          >
           Where we’re going
          </Typography>
          <Typography
            sx={{
              color: "#63727E",
              fontSize: small ? "14px" : "16px",
              lineHeight: "30px",
              marginBottom: "20px",
            }}
          >
           What began as a journey to provide better matchmaking between contractors and buyers has grown into a product which integrates seamlessly into the workflows of purchase managers. We are working with very large teams of purchase managers and enabling them to use Neevay as a platform to search, select and onboard contractors efficiently.
          </Typography>
        </Grid>
        <Box
          component="img"
          sx={{ width: isMd ? "50%" : "100%" }}
          src={`${BASE_URL}/assets/business/web-aboutus-desktop-revolution.webp`}
        />
      </Grid>

    </Grid>
  );
};
