import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NewRequirements = ({ leads }) => {
    const navigate = useNavigate()
    const [showAll, setShowAll] = useState(false);
    // const initialLeadCount = 5;
    const leadsCount = leads?.length
    console.log(leadsCount);

    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const handleToggleView = () => {
        setShowAll(prevState => !prevState);
    };

    const openQuotationForm = (rfq) => {
        console.log("LEAD", rfq)
        switch (rfq?.rfqType) {
            case "material":
                if (rfq?.materials && rfq.materials.length > 0) {
                    navigate(`/material-form?rfqId=${rfq?.rfqId}&rfqType=${rfq?.rfqType}`, { 
                        state: { rfqData: rfq } 
                    });
                } else {
                    console.warn("Materials data is missing or empty.");
                    // Optional: Show alert or handle missing materials data
                }
                break;
        
            case "labour":
                if (rfq?.labourDetails && rfq.labourDetails.length > 0) {
                    navigate(`/labour-form?rfqId=${rfq?.rfqId}&rfqType=${rfq?.rfqType}`, { 
                        state: { rfqData: rfq } 
                    });
                } else {
                    console.warn("Labour details are missing or empty.");
                    // Optional: Show alert or handle missing labour data
                }
                break;
        
            case "contractor":
                if (rfq?.providingFixingDetails) {
                    navigate(`/providing-form?rfqId=${rfq?.rfqId}&rfqType=${rfq?.rfqType}`, { 
                        state: { rfqData: rfq } 
                    });
                } else {
                    console.warn("Contractor details are missing or empty.");
                    // Optional: Show alert or handle missing contractor data
                }
                break;
        
            default:
                console.warn("Invalid RFQ type.");
                // Optional: Handle invalid RFQ type if necessary
        }
    }

    // Sort leads by createdAt in descending order (latest first)
    const sortedLeads = (leads || []).sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA; // descending order (latest date first)
    });

    // Show all or a subset of leads based on the showAll state
    // const displayedLeads = showAll ? sortedLeads : sortedLeads.slice(0, 5);
    // Show only 5 leads initially
    const displayedLeads = showAll ? leads || [] : (leads || []);



    return (
        <>
            {leadsCount === 0 ? <div className="flex justify-center items-center min-h-[calc(100vh-5rem)] w-full">
                <div className="flex flex-col items-center">
                    <img
                        src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Search_image.png"
                        alt="Search Image"
                        className="h-[116px] w-[122px] mb-6"
                    />
                    <p className="text-md font-semibold text-[#3C3C3C] text-center">
                        You haven't received any Business leads<br />
                        Update your business to increase your business visibility.
                    </p>
                </div>
            </div>


                : <div className='mx-4 pt-2 space-y-3 overflow-y-auto '>
                    <div className='flex items-center space-x-2'>
                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/envelope-open-text-solid.svg" alt="" />
                        <h3 className='font-semibold text-sm'>New Requirements</h3>
                        <p className='text-[#727269] text-xs'>({leads?.length || 0})</p>
                    </div>
                    <div className='rounded-md bg-white border border-[#DCDCD0] shadow-md py-1'>
                        <div className='max-h-[400px] overflow-y-auto'>
                            {displayedLeads.map((lead, index) => (
                                <div key={index} className='flex justify-between border-b border-b-[#DCDCD0] py-1 px-4'>
                                    <div className='2xl:hidden flex items-center space-x-2'>
                                        <div className='h-[6px] w-[6px] bg-[#15E003] rounded-full'></div>
                                        <p className=' text-xs'>{lead?.name?.length > 80 ? `${lead.name.slice(0, 80)}...` : lead?.name}</p>
                                    </div>
                                    <div className='hidden 2xl:flex items-center space-x-2'>
                                        <div className='h-[6px] w-[6px] bg-[#15E003] rounded-full'></div>
                                        <p className=' text-xs'>{lead?.name?.length > 120 ? `${lead.name.slice(0, 120)}...` : lead?.name}</p>
                                    </div>
                                    <div className='flex items-center'>
                                        <button className='underline text-[10px] text-blue-600' onClick={() => openQuotationForm(lead)}>Add Quotation</button>
                                        <div className='flex items-center space-x-2 px-4'>
                                            <div className='2xl:hidden bg-blue-100 bg-opacity-60 flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md'>
                                                <div className='h-[6px] w-[6px] bg-[#5279AD] rounded-full'></div>
                                                <p className='truncate'>{lead?.userBusinessName?.length > 30 ? `${lead.userBusinessName.slice(0, 30)}...` : lead?.userBusinessName}</p>
                                            </div>
                                            <div className='hidden bg-blue-100 bg-opacity-60 2xl:flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md'>
                                                <div className='h-[6px] w-[6px] bg-[#5279AD] rounded-full'></div>
                                                <p className='truncate'>{lead?.userBusinessName?.length > 60 ? `${lead.userBusinessName.slice(0, 60)}...` : lead?.userBusinessName}</p>
                                            </div>
                                            <div className='bg-lime-100 2xl:hidden bg-opacity-60 flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md'>
                                                <div className='h-[6px] w-[6px] bg-[#88CC66] rounded-full'></div>
                                                <p className='truncate'>{lead?.requesterName?.length > 25 ? `${lead.requesterName.slice(0, 25)}...` : lead?.requesterName}</p>
                                            </div>
                                            <div className='hidden bg-lime-100 bg-opacity-60 2xl:flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md'>
                                                <div className='h-[6px] w-[6px] bg-[#88CC66] rounded-full'></div>
                                                <p className='truncate'>{lead?.requesterName?.length > 50 ? `${lead.requesterName.slice(0, 50)}...` : lead?.requesterName}</p>
                                            </div>
                                            {lead?.rfqType && (
                                                <div className={`flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md
                                        ${lead?.rfqType === 'material' ? 'bg-rose-100 bg-opacity-60' :
                                                        lead?.rfqType === 'contractor' ? 'bg-cyan-100 bg-opacity-60' :
                                                            'bg-orange-100 bg-opacity-60'}`}>
                                                    <div className={`h-[6px] w-[6px] rounded-full 
                                            ${lead?.rfqType === 'material' ? 'bg-[#C6546C]' :
                                                            lead?.rfqType === 'contractor' ? 'bg-cyan-400' :
                                                                'bg-orange-400'}`}></div>
                                                    <p>{lead?.rfqType}</p>
                                                </div>
                                            )}
                                        </div>
                                        <p className='text-[10px]'>
                                            {lead?.createdAt
                                                ? `${new Date(lead.createdAt).getDate()} ${monthNames[new Date(lead.createdAt).getMonth()]}, ${new Date(lead.createdAt).getFullYear()}`
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <button onClick={handleToggleView} className='underline text-[10px] text-blue-500 font-medium mx-4'>
                {showAll ? 'View less' : 'View more'}
            </button> */}
                </div>}</>
    );
};

export default NewRequirements;
