import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Dialog,
  useMediaQuery,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  FormHelperText,
  MenuItem,
  Select,
  Input,
  InputLabel,
} from "@mui/material";

import { addLeadActivity, updateCRMLeadById } from "actions/crmAction";
import TermsAndConditionData from "views/TermsAndCondition/TermsAndConditionData";
import PrivacyPolicyData from "views/PrivacyPolicy/PrivacyPolicyData";
import { useTheme } from "@emotion/react";
import { registerUserProfile } from "actions/registerActions";
import OtpInput from "react-otp-input";
import "../../Business/components/form.css";
import { LoadingButton } from "@mui/lab";
import axios from "utils/axios";
import { SEARCH_BUSINESS_BY_NAME_AUTH } from "config/services";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
function UserProfileForm({
  selectedUserType,
  setSelectedUserType,
  className,
  onSubmitSuccess,
  profile,
  ...rest
}) {
  const GreenRadio = withStyles({
    root: {
      color: '#46AC9E', // Color of the unchecked radio button
      '&$checked': {
        color: '#46AC9E', // Color of the checked radio button
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const [companies, setCompanies] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [termsOpen, setTermsOpen] = React.useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false);
  const [otpSent, setOtpSent] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [counter, setCounter] = React.useState(30);
  const [showSendOTP, setShowSendOTP] = React.useState(false);
  const [counterOn, setCounterOn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [dispalyMessage, setDisplayMessage] = React.useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const search = useSelector((state) => state.search);
  const leadId = useSelector((state) => state.crm);


  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleCompanyChange = async (event, setFieldValue) => {
    try {
      const companyTxt = event.target.value;
      axios
        .get(`${SEARCH_BUSINESS_BY_NAME_AUTH}?queryParam=${companyTxt}`)
        .then((response) =>
          setCompanies(response?.data?.payload?.response?.docs)
        );
    } catch (err) {
      console.log(err);
    }
    // dispatch(searchCompany(companyTxt));
    setFieldValue("company", event.target.value);
  };

  //Terms of Use and Privacy Policy dialog box code start here
  const handleClickTermsOpen = () => setTermsOpen(true);
  const handleTermsClose = () => setTermsOpen(false);
  const handleClickPrivacyPolicyOpen = () => setPrivacyPolicyOpen(true);
  const handlePrivacyPolicyClose = () => setPrivacyPolicyOpen(false);
  //Termss of Use and Privacy Policy dialog box code end here

  // useEffect(() => {
  //   setCompanies(search?.companies);
  // }, [search?.companies]);





 

  return (
    <>
      <Formik
        initialValues={{
          name: profile?.name || "",
          email: profile?.email || "",
          mobile: profile?.mobile || "",
          company: profile?.company || "",
          designation: profile?.roleInCompany || "",
          userType: profile?.userType || "",
          userIndustry: profile?.userIndustry || "",
          aboutNeevay: profile?.aboutNeevay || "",
          specify: profile?.specify || "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .matches(/^[a-zA-Z\s]*$/, "Name should contain only alphabets")

            .min(2)
            .max(50)
            .required("Name is required"),
          email: Yup.string()
            .email("Please enter a valid email")
            .max(80)
            .required("Email is required"),
          mobile: Yup.string()
            .matches(
              /^[6-9][0-9]{9}/,
              "Please enter valid 10 digit mobile number"
            )
            .max(10)
            .min(10)
            .required("Please enter mobile number"),

          userType: Yup.string().required("Please select your User Type"),
          company: Yup.string()
            .min(2)
            .max(120)
            .required("Please enter your company name"),
          designation: Yup.string()
            .min(2)
            .max(80)
            .required("Please enter your designation"),
          userType: Yup.string().required("Please select your User Type"),
          userIndustry: Yup.string().when("selectedUserType", {
            is: "buyer",
            then: Yup.string().required("Please select your User Type"),
            otherwise: Yup.string(),
          }),
          aboutNeevay: Yup.string().required("Please provide the details "),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {

              // Check if values.company contains only digits
  const isDigitsOnly = /^\d+$/.test(values.company);

  if (isDigitsOnly) {
    // If only digits, set an error
    setErrors({ company: 'Company name cannot consist of only digits' });
  } else{

    setIsLoading(true);
    const supplier = await search?.companies?.find((item) => {
      return item === values.company;
    }); // .businessName[values.company];
    const supplierName = await supplier;
    const newLeadValues = [
      {
        Attribute: "EmailAddress",
        Value: values.email,
      },
      {
        Attribute: "FirstName",
        Value: values.name,
      },
      {
        Attribute: "Phone",
        Value: values.mobile,
      },
      {
        Attribute: "Company",
        Value: supplierName,
      },
      {
        Attribute: "JobTitle",
        Value: values.designation,
      },
      {
        Attribute: "Source",
        Value: "Website Lead",
      },
    ];
    dispatch(updateCRMLeadById(leadId.leadId, newLeadValues));
    dispatch(
      registerUserProfile(
        profile.userId,
        values,
        onSubmitSuccess,
      )
    );
  }

            
          } catch (error) {
            console.log(error);
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }

          const activityData = {
            RelatedProspectId: leadId.leadId,
            ActivityEvent: 211,
            ActivityNote: "User Sign Up Successfully",
          };
          dispatch(addLeadActivity(activityData));
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              display={"flex"}
              gap={"16px"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ margin: "auto", textAlign: "start" }}
            >
              {/* Buyer Vendor */}
              <Grid sx={{ textAlign: "start" }}>
                <Grid>
                  <Typography
                    sx={{
                      color: "#5E5873",
                      fontSize: "24px",
                      fontWeight: 700,
                      marginTop: "28px",
                    }}
                  >
                    Create your User Profile
                  </Typography>
                  <Typography
                    sx={{
                      color: "#6E6B7B",
                      fontSize: "14px",
                      fontWeight: 400,
                      marginY: "10px",
                    }}
                  >
                    Join us
                  </Typography>
                </Grid>
                <Grid display={"flex"} flexDirection={"column"} gap={"16px"}>
                  <Grid>
                    <label className="inputLabel">What Brings you here?</label>
                    <Grid>
                      <FormControl style={{ textAlign: "left" }}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="userType"
                          value={values.userType}
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedUserType(e.target.value);
                            if (e.target.value !== "vendor") {
                              setFieldValue("userIndustry", "");
                            }
                          }}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel
                            sx={{ color: "#6E6B7B", fontSize: "12px" }}
                            value="vendor"
                            control={<GreenRadio />}
                            label="Vendor"
                          />
                          <FormControlLabel
                            sx={{ color: "#6E6B7B", fontSize: "12px" }}
                            value="buyer"
                            control={<GreenRadio  />}
                            label="Buyer"
                          />
                        </RadioGroup>
                      </FormControl>
                      {touched.userType && errors.userType && (
                        <FormHelperText
                          sx={{ color: "red", marginLeft: "10px" }}
                        >
                          {errors.userType}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  <Grid>
                    <label className="inputLabel">Name</label>
                    <Grid>
                      <TextField
                        size="small"
                        sx={{
                          border: "1px solid #D8D6DE",
                          borderRadius: "5px",
                        }}
                        fullWidth
                        required
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Raj Deshmukh"
                      />
                      {touched.name && errors.name && (
                        <FormHelperText
                          sx={{ color: "red", marginLeft: "10px" }}
                        >
                          {errors.name}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    <label className="inputLabel">Email </label>
                    <Grid item>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        id="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        placeholder="raj@gmail.com"
                      />
                    </Grid>
                  </Grid>
                  <Grid fullWidth>
                   
                      <label className="inputLabel">Mobile</label>
                      <Grid
                        item
                        
                        style={{
                          borderRadius: "5px",
                          height: "40px",
                          border: "1px solid #D8D6DE",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "14px",
                        }}
                      >
                     
                        <Typography
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "20.3px" /* 145% */,
                            letterSpacing: "0.14px",
                          }}
                        >{`(+91) ${values.mobile}`}</Typography>
                      </Grid>
                     
                     
                   
                    
                  </Grid>
 
                  <Grid>
                    <label className="inputLabel">Company </label>
                    <Grid item>
                      <Autocomplete
                        freeSolo
                        clearIcon={null}
                        getOptionLabel={(option) => option}
                        options={companies}
                        onChange={(event, newValue) => {
                          setFieldValue("company", newValue);
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              fullWidth
                              size="small"
                              required
                              id="company"
                              value={values.company}
                              onChange={(data) =>
                                handleCompanyChange(data, setFieldValue)
                              }
                              placeholder="Tathaatvam Technologies"
                              onBlur={handleBlur}
                              error={Boolean(touched.company && errors.company)}
                              helperText={touched.company && errors.company}
                            />
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid>
                    <label className="inputLabel">Role </label>
                    <Grid item>
                      <TextField
                        fullWidth
                        required
                        size="small"
                        id="designation"
                        value={values.designation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.designation && errors.designation
                        )}
                        helperText={touched.designation && errors.designation}
                        placeholder="Manager"
                      />
                    </Grid>
                  </Grid>

                  {values.userType === "buyer" && (
                    <Grid>
                      <label className="inputLabel">Your Industry</label>
                      <Grid item>
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="userIndustry"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userIndustry}
                            error={Boolean(
                              touched.userIndustry && errors.userIndustry
                            )}
                            helperText={
                              touched.userIndustry && errors.userIndustry
                            }
                          >
                            <MenuItem value={"Real Estate"}>
                              Real Estate
                            </MenuItem>
                            <MenuItem value={"Industrial"}>Industrial</MenuItem>
                            <MenuItem value={"Hospital"}>Hospital</MenuItem>
                            <MenuItem value={"Infra and Road"}>
                              Infra and Road
                            </MenuItem>
                            <MenuItem value={"Hotela"}>Hotels</MenuItem>
                            <MenuItem value={"Other"}>Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid>
                    <label className="inputLabel">
                      How did you hear about Neevay ?
                    </label>
                    <Grid item>
                      <Autocomplete
                        size="small"
                        id="demo-simple-select"
                        options={[
                          "Social Media",
                          "Search Engines",
                          "Online Communities/Forums",
                          "Advertising",
                          "Email Newsletter",
                          "Word of Mouth",
                          "Blog or News Article",
                          "Podcast",
                          "Other (Specify)",
                        ]}
                        getOptionLabel={(option) => option}
                        value={values.aboutNeevay}
                        onChange={(event, newValue) => {
                          handleChange({
                            target: { name: "aboutNeevay", value: newValue },
                          });
                        }}
                        onBlur={handleBlur}
                        clearIcon={null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select"  
                            error={Boolean(
                              touched.aboutNeevay && errors.aboutNeevay
                            )}
                            helperText={
                              touched.aboutNeevay && errors.aboutNeevay
                            }                          
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {values.aboutNeevay === "Other (Specify)" && (
                    <Grid>
                      <label className="inputLabel">Specify</label>
                      <Grid>
                        <TextField
                          size="small"
                          sx={{
                            border: "1px solid #D8D6DE",
                            borderRadius: "5px",
                          }}
                          fullWidth
                          id="specify"
                          name="specify"
                          value={values.specify} // You can use values.aboutNeevay to display the same value as selected in the dropdown
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Specify"
                        />
                        {touched.aboutNeevay && errors.aboutNeevay && (
                          <FormHelperText
                            sx={{ color: "red", marginLeft: "10px" }}
                          >
                            {errors.specify}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid display={"flex"} alignItems={"center"}>
                    <Grid>
                      <Typography sx={{ color: "#6E6B7B", fontSize: "14px" }}>
                        By clicking below I agree to{" "}
                        <Link
                          onClick={() => {
                            handleClickPrivacyPolicyOpen();
                          }}
                          style={{ color: "rgba(57, 99, 171, 1)" }}
                        >
                          Privacy Policy
                        </Link>
                        <Link
                          onClick={() => {
                            handleClickTermsOpen();
                          }}
                          style={{ color: "rgba(57, 99, 171, 1)" }}
                        >
                          {" "}
                          & terms
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                  <LoadingButton
                    loading={isLoading}
                    loadingPosition="end"
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{
                      borderRadius: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                      marginBottom: "50px",
                      backgroundColor: "black",
                      color:"white",
                      "&:hover": {
                        backgroundColor: "black",
                        transition: "background-color 0.3s, box-shadow 0.3s",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                      },
                    }}
                  >
                    {" "}
                    {isLoading ? "Submitting.." : "Start my Journey"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Dialog open={termsOpen} keepMounted onClose={handleTermsClose}>
        <TermsAndConditionData />
      </Dialog>
      <Dialog
        open={privacyPolicyOpen}
        keepMounted
        onClose={handlePrivacyPolicyClose}
      >
        <PrivacyPolicyData />
      </Dialog>
    </>
  );
}

UserProfileForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  profile: PropTypes.object,
};

UserProfileForm.default = {
  onSubmitSuccess: () => {},
};

export default UserProfileForm;
