import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import React from "react";
import { withStyles } from '@material-ui/core/styles';
const GreenRadio = withStyles({
  root: {
    // color: '#', // Color of the unchecked radio button
    '&$checked': {
      color: 'black', // Color of the checked radio button
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const RfqTypeSec = ({
  rfqTypeValue,
  setRfqTypeValue,
  values,
  setEstimatedvalue,
  handleChange,
  errors,
  touched,
  isVasconUser,
}) => {
  const handleChangeRfqType = (event) => {
    setRfqTypeValue(event.target.value);
  };
  const handleChangeEstimatedvalue = (event) => {
    setEstimatedvalue(event.target.value);
  };

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          alignItems: "center",
          pl: "4px",
          border: "4px solid #FBF8F6",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>Requirement Type : </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-error-radios"
          name="rfq-type"
          value={rfqTypeValue}
          onChange={handleChangeRfqType}
        >
          <FormControlLabel
            value="Contractor"
            control={<GreenRadio />}
            label="Contractor"
          />
          <FormControlLabel
            value="Material"
            control={<GreenRadio />}
            label="Material"
          />
          <FormControlLabel
            value="Both"
            control={<GreenRadio />}
            label="Contractor with Material"
          />
        </RadioGroup>
      </Grid>
      <Typography sx={{ fontSize: "12px", color: "red" }}>
        {errors.rfqType}
      </Typography>

      {isVasconUser && (
        <>
          {" "}
          <Grid
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "6px 4px 2px",
            }}
          >
            <Typography>Estimated value of this requirement : </Typography>
            <CurrencyRupeeIcon fontSize="small" />
            <TextField
              variant="standard"
              name="estimatedvalue"
              id="estimatedvalue"
              placeholder="Estimates value"
              onChange={handleChangeEstimatedvalue}
              value={values.estimatedvalue}
              error={Boolean(touched.estimatedvalue && errors.estimatedvalue)}
              helperText={touched.estimatedvalue && errors.estimatedvalue}
            />
          </Grid>
          <Typography sx={{ fontSize: "12px", pl: "12px", pb: "12px" }}>
            This will not be visible to vendor
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default RfqTypeSec;
