import axios from "utils/axios";

import {
  FETCH_BUSINESS_BY_ID_URL,
  FETCH_BUSINESS_BY_URL_URL,
  EDIT_ADDRESS_URL,
  PROFILE_URL_URL,
  UPDATE_BUSINESS_PROFILE_URL,
  BUSINESS_SOCIAL_ICON_URL,
  FETCH_BUSINESS_BY_USER_ID_URL,
  UPDATE_STEPPER_COUNT,
  UPDATE_SUPPLIER_DETAILS,
  HIDE_CONTACT_DETAIL,
  FETCH_TAGS_BY_BUSINESS,
} from "config/services";

class BusinessService {
  fetchBusinessById = (supplierId) =>
    new Promise((resolve, reject) => {
      // console.log("BusinessService.fetchBusinessById: ", supplierId);
      const searchUrl = `${FETCH_BUSINESS_BY_ID_URL}?supplierId=${supplierId}`;

      axios
        .get(searchUrl, { supplierId })
        .then((response) => {
          // console.log(
          //   "BusinessService.fetchBusinessById: Response",
          //   response.data
          // );

          if (response.data.error) {
            // console.log(
            //   "BusinessService.fetchBusinessById:",
            //   response.error,
            //   response.data
            // );
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });

  fetchMyTagsByVendor = ({ userId, entityId, vendorId }) =>
    new Promise((resolve, reject) => {
      const searchUrl = `${FETCH_TAGS_BY_BUSINESS}?userId=${userId}&entityId=${entityId}&vendorId=${vendorId}`;
      axios
        .get(searchUrl)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  hideContactDetails = (userId, supplierDetails, entityId) =>
    new Promise((resolve, reject) => {
      axios
        .post(HIDE_CONTACT_DETAIL, { userId, supplierDetails, entityId })
        .then((response) => {
          // console.log("hideContactDetails: Response****", response.data);
          if (response.data.error) {
            // console.log("hideContactDetails###", response.data.error);
            reject(response?.data?.error);
          } else {
            // console.log("hideContactDetails", response.data, "response");
            resolve(response?.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }
          reject(error);
        });
    });

  fetchBusinessByUserId = (userId) =>
    new Promise((resolve, reject) => {
      // console.log("BusinessService.fetchBusinessById: ", supplierId);
      const searchUrl = `${FETCH_BUSINESS_BY_USER_ID_URL}?ownerId=${userId}`;

      axios
        .get(searchUrl)
        .then((response) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });

  fetchBusinessByUrl = (businessUrl) =>
    new Promise((resolve, reject) => {
      // console.log("BusinessService.fetchBusinessByUrl: ", businessUrl);
      const searchUrl = `${FETCH_BUSINESS_BY_URL_URL}?businessUrl=${businessUrl}`;

      axios
        .get(searchUrl, { businessUrl })
        .then((response) => {
          // console.log(
          //   "BusinessService.fetchBusinessByUrl: Response",
          //   response.data
          // );

          if (response.data.error) {
            // console.log(
            //   "BusinessService.fetchBusinessByUrl:",
            //   response.error,
            //   response.data
            // );
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });

  updateBusinessProfile = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post(UPDATE_BUSINESS_PROFILE_URL, { payload })
        .then((response) => {
          // console.log(
          //   "BusinessService.updateBusinessProfile()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "BusinessService.updateBusinessProfile() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  updateSupplierStepper = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post(UPDATE_SUPPLIER_DETAILS, { payload })
        .then((response) => {
          // console.log(
          //   "BusinessService.updateBusinessProfile()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "BusinessService.updateBusinessProfile() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  updateAddress = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post(EDIT_ADDRESS_URL, { payload })
        .then((response) => {
          // console.log(
          //   "BusinessService.updateAddress()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "BusinessService.updateAddress() Service(Response Error):",
          //   error
          // );

          reject(error);
        });
    });

  updateStepperCount = (payload) =>
    new Promise((resolve, reject) => {
      console.log(payload);
      axios
        .post(UPDATE_STEPPER_COUNT, { payload })
        .then((response) => {
          // console.log(
          //   "BusinessService.updateAddress()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "BusinessService.updateAddress() Service(Response Error):",
          //   error
          // );

          reject(error);
        });
    });

  saveUrl = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("BusinessService.saveUrl: ", payload);

      axios
        .post(PROFILE_URL_URL, { payload })
        .then((response) => {
          // console.log("BusinessService.saveUrl: Response", response.data);

          if (response.data.error) {
            // console.log(
            //   "BusinessService.saveUrl:",
            //   response.error,
            //   response.data
            // );

            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ServiceService.serviceService() Service(Response Error):",
          //   error
          // );

          if (error.response) {
            reject(error?.response?.data?.message);
          }

          reject(error);
        });
    });

  saveSocialUrls = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("BusinessService.saveSocialUrls: ", payload);

      axios
        .post(BUSINESS_SOCIAL_ICON_URL, { payload })
        .then((response) => {
          // console.log(
          //   "BusinessService.saveSocialUrls: Response",
          //   response.data
          // );

          if (response.data.error) {
            // console.log(
            //   "BusinessService.saveSocialUrls:",
            //   response.error,
            //   response.data
            // );

            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "BusinessService.saveSocialUrls() Service(Response Error):",
          //   error
          // );

          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });

  fetchBusinessOwnersEmail = (suppliers) =>
    new Promise((resolve, reject) => {
      // console.log("BusinessService.fetchBusinessById: ", suppliersArray);
      axios
        .post(`/api/supplier/owner/emails`, { suppliers })
        .then((response) => {
          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }
          reject(error);
        });
    });
}

const businessService = new BusinessService();

export default businessService;
