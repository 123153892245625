import axios from "utils/axios";

import {
  ORDERS_FETCH_URL,
} from "config/services";

class OrdersService {

  fetchOrders = () =>
    new Promise((resolve, reject) => {

      // console.log("OrdersService.fetchOrders called");

      axios.get(ORDERS_FETCH_URL, {})
      .then((response) => {
        // console.log("OrdersService.fetchOrders() resp:", response)
        if(response?.data?.error) {
          // console.log("Failed to fetch records");
          reject(response.data.message);
        } else {
          if(response?.data?.payload){
            resolve(response?.data?.payload);
          }
        }
      })
      .catch((error) => {
        if(error.response) {
          // console.log("OrdersService.fetchOrders() error:", error);
          reject(error?.response?.data);
        }
        reject(error);
      });
    });

}

const ordersService = new OrdersService();

export default ordersService;
