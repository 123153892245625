import React, { useState } from "react";
import { Helmet } from "react-helmet";
import MainLayout from "layouts/Main";
import BlogPage from "./BlogPage";

function Blogs() {
return (
    <>
    <MainLayout>
      <Helmet>
        <title>Neevay - Blogs</title>
      </Helmet>
      <BlogPage/>
    </MainLayout>
    </>
  );
}

export default Blogs;
