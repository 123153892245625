import React, { useState } from 'react';
import Services from './Services';
import Sectors from './Sectors';
import Locations from './Locations';
import ServicesSlider from './ServicesSlider';
import SectorSlider from './SectorSlider';
import LocationSlider from './LocationSlider';
import Servicesfortab from './Servicesfortab';
import Locationsfortab from './Locationsfortab';
import Sectorsfortab from './Sectorsfortab';

const FindNextContractor = ({scrollToHome}) => {
    const [serviceClicked, setServiceClicked] = useState(true);
    const [sectorClicked, setSectorClicked] = useState(false);
    const [locationClicked, setLocationClicked] = useState(false);

    const handleShowService = () => {
        setServiceClicked(true);
        setSectorClicked(false);
        setLocationClicked(false);
    };

    const handleShowSector = () => {
        setSectorClicked(true);
        setServiceClicked(false);
        setLocationClicked(false);
    };

    const handleShowLocation = () => {
        setLocationClicked(true);
        setSectorClicked(false);
        setServiceClicked(false);
    };

    return (
        <div className='bg-gradient-to-r from-[#0B0B1A] via-[#1B1B26] to-[#0B0B1A] flex flex-col justify-center py-[60px] md:py-[80px] lg:py-[100px]'>

            <p className='text-[#dddddd] text-4xl pb-[70px] md:pb-0 md:text-4xl font-bold xs:px-4 text-center '>
                Find Your Next Contractor / Supplier
            </p>

            {/* For screens less than 768px */}
            <div className='block md:hidden space-y-4'>
                <ServicesSlider scrollToHome={scrollToHome} />
                <SectorSlider scrollToHome={scrollToHome} />
                <LocationSlider scrollToHome={scrollToHome} />
            </div>

            <div className='hidden md:block'>
                <div className='hidden md:flex justify-around md:mt-[74px] md:mb-[27px] lg:mt-[80px] lg:mb-[30px]'>
                    <img onClick={handleShowService} src='https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_construction_trades.svg' alt='service-img' className='w-[70px] h-[70px] cursor-pointer' />
                    <img onClick={handleShowSector} src='https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_market_sectors.svg' alt='sector-img' className='w-[70px] h-[70px] cursor-pointer' />
                    <img onClick={handleShowLocation} src='https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_service_areas.svg' alt='location-img' className='w-[70px] h-[70px] cursor-pointer' />
                </div>

                <div className='flex justify-around text-[#dddddd] text-2xl md:font-lighter'>
                    <button onClick={handleShowService} className={`pb-5 w-[171px] ${serviceClicked ? 'border-b-4 border-[#FF6B00]' : 'border-b-4 border-transparent'}`}>
                        Services
                    </button>
                    <button onClick={handleShowSector} className={`pb-5 w-[171px] ${sectorClicked ? 'border-b-4 border-[#FF6B00]' : 'border-b-4 border-transparent'}`}>
                        Sectors
                    </button>
                    <button onClick={handleShowLocation} className={`pb-5 w-[171px] ${locationClicked ? 'border-b-4 border-[#FF6B00]' : 'border-b-4 border-transparent'}`}>
                        Locations
                    </button>
                </div>
                <div className='h-[1px] mx-8 bg-[#adadad]'></div>
            </div>

            {/* For screens between 768px to 1024px */}
            <div className='hidden md:block xl:hidden'>
                {serviceClicked && <Servicesfortab />}
                {sectorClicked && <Sectorsfortab />}
                {locationClicked && <Locationsfortab />}
            </div>

            {/* For screens greater than 1024px */}
            <div className='hidden xl:block'>
                <div>
                    {serviceClicked && <Services />}
                    {sectorClicked && <Sectors />}
                    {/* {sectorClicked && <Sectorsfortab />} */}
                    {locationClicked && <Locations />}
                </div>
            </div>
            <div className='hidden md:block'>
                {serviceClicked && <div className='flex justify-center'>
                    <button onClick={scrollToHome} className='text-white text-xl font-bold flex items-center mt-[45px] '>
                        Find More Services
                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_FindMoreArrow.svg" alt="arrow" className='h-6 mt-2 pl-2' />
                    </button>
                </div>}
                {sectorClicked && <div className='flex justify-center'>
                    <button onClick={scrollToHome}  className='text-white text-xl font-bold flex items-center mt-[45px] '>
                        Find More Sectors
                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_FindMoreArrow.svg" alt="arrow" className='h-6 mt-2 pl-2' />
                    </button>
                </div>}
                {locationClicked && <div className='flex justify-center'>
                    <button onClick={scrollToHome}  className='text-white text-xl pl-4 font-bold flex items-center mt-[45px] '>
                        Find More Locations
                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_FindMoreArrow.svg" alt="arrow" className='h-6 mt-2 pl-2' />
                    </button>
                </div>}
            </div>
        </div>
    );
};

export default FindNextContractor;
