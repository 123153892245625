import { AppBar, Grid, useMediaQuery, useTheme } from "@mui/material";
import { SEARCH_FILTERED, filteredSearch } from "actions/searchActions";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSelectedFacetString } from "utils/searchUtils";
import BusinessProfileHead from "./BusinessProfileHead";
import BusinessProfileAbout from "./BusinessProfileAbout";
import BusinessOfferingPage from "./BusinessOfferingPage";
import TopClient from "./TopClient";
import Domains from "./Domains";
import AwardsCertification from "./AwardsCertification";
import Projects from "./Projects";
import BusinessNavbar from "./BusinessNavbar";
import queryString from "query-string";
import axios from "utils/axios";
import useIsMounted from "hooks/useIsMounted";
import { Topbar } from "layouts/Main/components";
import pages from "layouts/navigation";
import { Helmet } from "react-helmet";
import { FloatingButton } from "views/Home 3.0/components";
import NewBusinessProfileMobileView from "./MobileView/NewBusinessProfileMobileView";
import { GlobalNavbar } from "views/Home 3.0/components";
function NewBusinessProfilePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1024));
  const isSm = useMediaQuery(theme.breakpoints.down(768));

  const scrollContainerRef = useRef(null);
  const scrollContainerFirstRef = useRef(null);

  const { url, searchString, region } = useParams(); //get bussines url,searchby and serach string from link

  const [businessDataDetails, setbusinessDataDetails] = useState(null);
  const [profile, setProfile] = useState(null);
  const [projects, setProjects] = useState(null);

  const searchby = "all";
  const regionString = region?.replace(/-/g, " ");
  const search = useSelector((state) => state.search);
  const queryParams = queryString.parse(location.search); // get values from url after ?
  const searchType = search?.searchType || searchby;
  const data = search?.results;
  const searchKeyWord =
    search.searchKeyWord || searchString?.replace(/-/g, " ");
  const selectedFacets = search.selectedFacets;
  const certificate = profile?.businessProfile?.certificateImages || [];
  const awards = profile?.awards || [];
  const AwardsCertificationArray = [...certificate, ...awards];
  const getProfile = useCallback(
    (url) => {
      axios
        .get(`/api/supplier/profileurl?businessUrl=${url}`) //, {})
        .then((response) => {
          if (isMounted()) {
            setProfile(response.data.payload.profile);
            setProjects(response?.data?.payload?.projects?.projects);
          }
        });
    },
    [isMounted]
  );

  const convertFacetsIntoString = () => {
    return selectedFacets
      ?.map((obj) =>
        obj?.values
          ?.map(
            (value) =>
              `${obj?.facet?.replace(/ /g, "-")}=${value?.replace(/ /g, "-")}`
          )
          .join("&")
      )
      .join("&");
  };
  //   const ItemClick = (url) => {
  //     scrollContainerFirstRef.current.scrollTo({ top: 0, behavior: "smooth" });
  //     scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });

  //     navigate(
  //       `/business-details/${url}/${searchKeyWord}/${
  //         searchType || "all"
  //       }?${convertFacetsIntoString()}`
  //     );
  //     getProfile(url);
  //   };

  const handleGetDataFromUrlValues = (isSelected) => {
    // isSelected is true or false value when we add filter then true and when we clr filter then false and restet all data
    const filterSet = []; // use for getSelectedFacetString ()
    // get data foam the url ?Category=Contractors&Domains=COMMERCIAL that data store queryParams
    for (const [key, value] of Object.entries(queryParams)) {
      const facetTitle = key.replace(/-/g, " ");

      const processedValues = Array.isArray(value)
        ? value.map((item) => item.replace(/-/g, " "))
        : [value.replace(/-/g, " ")]; // we want array so we add this is array box

      processedValues.forEach((facetValue) => {
        // send one by one filter to redux store
        dispatch({
          type: SEARCH_FILTERED,
          payload: { facetTitle, facetValue, isSelected },
        });
      });

      filterSet.push({ facet: facetTitle, values: processedValues }); // make one array of all selected filters for sending the url to backend
    }

    const filterStr = getSelectedFacetString(filterSet); // give converted filter string [businessProfile.businessCategory_str:(Category)] like this
    dispatch(
      filteredSearch(searchKeyWord, regionString, filterStr, searchType)
    );
  };

  useEffect(() => {
    if (isSm) navigate(`/company/${url}`);
    getProfile(url);
    handleGetDataFromUrlValues(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>{profile?.businessName}</title>
      </Helmet>
      {/* <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: "#262728",
          color: "black",
        }}
      >
        <Topbar pages={pages} />
      </AppBar> */}
      <GlobalNavbar/>
      {isSm ? (<>
        <NewBusinessProfileMobileView
          profile={profile}
          businessDataDetails={businessDataDetails}
          projects={projects}
        />
         <FloatingButton/>
        </> 
      ) : (
        <Grid
          md={isMd ? 12 : 9}
          ref={scrollContainerRef}
          sx={{
            overflow: "auto",
            height: "calc(100vh - 66px)",
            paddingBottom: "48px",
          }}
        >
          <BusinessProfileHead
            IsSearchPublicProfile={!isMd} // is used for apply style only for desktop view
            profile={profile}
            businessDataDetails={businessDataDetails}
          />
          <BusinessNavbar
            IsSearchPublicProfile={!isMd}
            profile={profile}
            projects={projects}
            scrollContainerRef={scrollContainerRef}
          />
          <div id="component1">
            <BusinessProfileAbout
              profile={profile}
              IsSearchPublicProfile={!isMd}
            />
          </div>
          {/* <div id="component2">
            <BusinessContactUs profile={profile} />
          </div> */}
          <div id="component3">
            {profile?.services?.length > 0 && (
              <BusinessOfferingPage
                profile={profile}
                IsSearchPublicProfile={!isMd}
              />
            )}
          </div>
          <div id="component4">
            {projects?.length > 0 && (
              <Projects
                IsSearchPublicProfile={!isMd}
                profile={profile}
                projects={projects.filter((project) => !project.isDeleted)}
              />
            )}
          </div>

          <div id="component5">
            {profile?.clients?.length > 0 && (
              <TopClient profile={profile} IsSearchPublicProfile={!isMd} />
            )}
          </div>
          {profile?.businessProfile?.domains?.length > 0 && (
            <Domains profile={profile} IsSearchPublicProfile={!isMd} />
          )}
          <div id="component6">
            {AwardsCertificationArray?.length > 0 && (
              <AwardsCertification
                profile={profile}
                IsSearchPublicProfile={!isMd}
              />
            )}
          </div>
        </Grid>
      )}
    </>
  );
}

export default NewBusinessProfilePage;
