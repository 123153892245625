import React, { useState } from "react";
import {
  Grid,
  Typography,
  Chip,
  Tooltip,
  useTheme,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";

const PastConversations = ({ enquiry }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [showAllAttachments, setShowAllAttachments] = useState(false);
  const [Attachments, setAttachments] = useState(false);
  const showAttachments = showAllAttachments
    ? enquiry?.selectedFilesBase
    : isSm
    ? [enquiry?.selectedFilesBase[0]]
    : enquiry?.selectedFilesBase;
  const requesterDetails = `${enquiry.requesterName} <${enquiry.requestedBy}> wrote:`;
  const reverseEnquiry = enquiry?.noteDescriptions;
  var reversedArray;
  if (reverseEnquiry && Array.isArray(reverseEnquiry)) {
    const copyOfArray = [...reverseEnquiry];
    var reversedArray = copyOfArray.reverse();
    // console.log(reversedArray);
  } else {
    console.log("Invalid or empty array");
  }

  const handleRequesterDetails = (senderEmail, senderName) => {
    if (senderEmail) return `${senderName} <${senderEmail}> wrote:`;
    return `${enquiry.requesterName} <${enquiry.requestedBy}> wrote:`;
  };

  const formatDate = (createdAt) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    const formattedDate = new Date(createdAt).toLocaleString("en-US", options);
    return formattedDate;
  };

  const workDateFormat = (createdAt) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };

    const workDateFormatted = new Date(createdAt).toLocaleString(
      "en-US",
      options
    );

    const [month, day, year] = workDateFormatted.split("/");
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };
  const Details = `On ${formatDate(enquiry.createdAt)} ${
    enquiry.requesterName
  } <${enquiry.requestedBy}> wrote:`;

  return (
    <>
      <Grid>
        <Typography sx={{ fontWeight: 600, margin: "10px 0 10px 0" }}>
          Past Conversations
        </Typography>
      </Grid>

      {reversedArray?.map((data, index) => (
        <Grid sx={{ marginBottom: "27px" }} key={index}>
          <Typography
            sx={{
              color: "#535353",
              fontSize: "14px",
              lineHeight: "17.4px",
              letterSpacing: "0.14px",
              marginBottom: "5px",
            }}
          >
            {data.createdAt}{" "}
            {handleRequesterDetails(data?.sentByEmail, data?.senderName)}
          </Typography>
          <Grid
            sx={{
              border: "1px solid #A4A4A4",
              padding: "10px 14px 10px 14px",
              borderRadius: "5px",
              marginY: "7px",
            }}
          >
            <Grid>
              <Typography sx={{ fontSize: "14px", lineHeight: "normal" }}>
                {ReactHtmlParser(data.desc)}
              </Typography>
            </Grid>
          </Grid>

          {data?.file?.length > 0 && (
            <Grid
              display={"flex"}
              gap={"13px"}
              alignItems={"center"}
              flexWrap={"wrap"}
              marginTop={"7px"}
            >
              <Typography sx={{ fontSize: "14px" }}>Attachments : </Typography>
              {data?.file
                ?.slice(0, Attachments ? data.file.length : 1)
                .map((fileData, i) => (
                  <Tooltip title={fileData.Name} key={i}>
                    <Chip
                      label={fileData.Name}
                      rel="noopener noreferrer"
                      variant="outlined"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        justifyContent: "space-between",
                        width: "145px",
                        background: "#D2D2D2",
                        borderRadius: "20px",
                      }}
                    />
                  </Tooltip>
                ))}
              {data?.file?.length > 1 && isSm && (
                <Box
                  onClick={() => setAttachments(!Attachments)}
                  sx={{
                    border: "1px solid #777777",
                    borderRadius: "5px",
                    padding: "6px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                      lineHeight: "18.75px",
                    }}
                  >
                    {" "}
                    {Attachments ? "View Less" : "View More"}
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      ))}

      <Typography
        sx={{
          color: "#535353",
          fontSize: "14px",
          lineHeight: "17.4px",
          letterSpacing: "0.14px",
          marginBottom: "5px",
          marginTop: "27px",
        }}
      >
        {Details}
      </Typography>

      <Grid
        sx={{
          border: "1px solid #A4A4A4",
          padding: "10px 14px 10px 14px",
          borderRadius: "5px",
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>Dear Team,</Typography>
        <Grid>
          <Typography
            sx={{ fontSize: "14px", lineHeight: "normal", marginTop: "10px" }}
          >
            Please quote your competitive rates for below requirement at{" "}
            <span style={{ backgroundColor: "#EFFF8F" }}>
              {enquiry.location}
            </span>
            . earliest for our site at You may contact our engineer for any
            technical details{" "}
            <span style={{ backgroundColor: "#EFFF8F" }}>
              {enquiry.siteEngineerName}
            </span>{" "}
            at{" "}
            <span style={{ backgroundColor: "#EFFF8F" }}>
              {enquiry.siteEngineerContact}
            </span>{" "}
            Applicable credit period would be{" "}
            <span style={{ backgroundColor: "#EFFF8F" }}>
              {enquiry.creditPeriod}
            </span>{" "}
            and this requirement need to be supplied by{" "}
            <span style={{ backgroundColor: "#EFFF8F" }}>
              {workDateFormat(enquiry.workStartDate)}
            </span>
          </Typography>
        </Grid>
      </Grid>

      {enquiry?.selectedFilesBase?.length > 0 && (
        <Grid
          display={"flex"}
          gap={"13px"}
          alignItems={"center"}
          flexWrap={"wrap"}
          marginTop={"7px"}
        >
          <Typography sx={{ fontSize: "14px" }}>Attachments : </Typography>
          {showAttachments.map((file, index) => (
            <Tooltip key={index} title={file.Name}>
              <Chip
                label={file.Name}
                rel="noopener noreferrer" // Recommended for security and performance reasons
                variant="outlined"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  justifyContent: "space-between",
                  width: "145px",
                  background: "#D2D2D2",
                  borderRadius: "20px",
                }}
              />
            </Tooltip>
          ))}
          {enquiry?.selectedFilesBase.length > 1 && isSm && (
            <Box
              sx={{
                border: "1px solid #777777",
                borderRadius: "5px",
                padding: "6px 8px",
              }}
            >
              {showAllAttachments ? (
                <Typography
                  onClick={() => setShowAllAttachments(false)}
                  sx={{
                    fontSize: "14px",
                    cursor: "pointer",
                    lineHeight: "18.75px",
                  }}
                >
                  View Less
                </Typography>
              ) : (
                <Typography
                  onClick={() => setShowAllAttachments(true)}
                  sx={{
                    fontSize: "14px",
                    cursor: "pointer",
                    lineHeight: "18.75px",
                  }}
                >
                  View More
                </Typography>
              )}
            </Box>
          )}
        </Grid>
      )}
    </>
  );
};

export default PastConversations;
