import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";



import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import OrderList from "./OrderList";

function Orders() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const Orders = useSelector((state) => state.orders);
  // console.log("orders:", Orders);

  useEffect(() => {
    // console.log("orders is refreshed:" , )
  }, [Orders.orders]);

  // TODO_SP: Check if there is need to pull data, everytime user lands on the page

  return (
    <DashboardLayout path="/user-orders">
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Typography
              variant="h5"
              color="#FD6600"
              fontWeight="bold"
              mb={2}
            >
              Shortlisted Business Orders
            </Typography>
            <Divider />
            <OrderList
              orders={Orders.orders?.filter(
                (row) => row?.paymentOrder?.validated == true
              )}
            />
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
  );
}

export default Orders;
