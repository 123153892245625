import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { makeStyles } from "@material-ui/core";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundImage: `url("${BASE_URL}/assets/business/web-aboutus-desktop-hero-img.webp")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },
  MobilebackgroundImage: {
    backgroundImage: `url("${BASE_URL}/assets/business/web-aboutus-mobile-hero-img.webp")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },
}));
export const WhyWeHere = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up(1024));
  const small = useMediaQuery(theme.breakpoints.down(600));

  const classes = useStyles();
  return (
    <Grid>
      <Grid sx={{ marginBottom: isMd ? "108px" : "45px" }}>
        <Grid
          className={
            small ? classes.MobilebackgroundImage : classes.backgroundImage
          }
          sx={{ maxWidth: "100%" }}
        >
          <Grid>
            <Typography
              sx={{
                fontFamily: "PP Telegraf",
                color: "rgba(255, 255, 255, 1)",
                fontSize: small ? "30px" : "56px",
                textAlign: "center",
                paddingTop: "108px",
              }}
            >
              Building connections,
            </Typography>
            <Typography
              sx={{
                fontFamily: "PP Telegraf",
                color: "rgba(255, 255, 255, 1)",
                fontSize: small ? "30px" : "56px",
                textAlign: "center",
              }}
            >
              empowering construction futures
            </Typography>
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: small ? "18px" : "20px",
                textAlign: "center",
                marginTop: "68px",
                paddingBottom: "160px",
              }}
            >
              Connecting Construction Companies with Expert Contractors.
              Redefining Collaboration, One Project at a Time
            </Typography>
          </Grid>
          {small ? (
            <Box
              width={"100%"}
              component="img"
              marginBottom="-8px"
              src={`${BASE_URL}/assets/business/web-aboutus-mobileviewCurveImg.svg`}
            />
          ) : (
            <Box
              width={"100%"}
              component="img"
              marginBottom="-8px"
              src={`${BASE_URL}/assets/business/web-aboutus-curveImg.svg`}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: isMd ? "flex-start" : "center",
          flexDirection: isMd ? "row" : "column",
          gap: isMd ? "40px" : "25px",
          //   marginTop: "20px",
          paddingX: isMd ? "58px" : "20px",
          marginBottom: "132px",
        }}
      >
        {/* <Grid> */}
        <Box
          component="img"
          sx={{ width: isMd ? "50%" : "100%" }}
          src={`${BASE_URL}/assets/business/web-aboutus-desktop-handshake.webp`}
        />
        {/* </Grid> */}

        <Grid sx={{ width: isMd ? "50%" : "100%" }}>
          <Typography
            style={{
              fontFamily: "PP Telegraf",
              fontSize: isMd ? "48px" : "32px",
              lineHeight: "62.2px",
              color: "rgba(0, 0, 0, 1)",
              marginBottom: isMd ? "26px" : "20px",
            }}
          >
            Why we’re here
          </Typography>
          <Typography
            sx={{
              color: "#63727E",
              fontSize: small ? "14px" : "16px",
              lineHeight: "30px",
              marginBottom: "20px",
            }}
          >
            Finding the right contractor in the construction industry is
            painful. Buyer databases are often stale, contractors have either
            changed their line of business or have stopped supporting services
            that they previously offered.
          </Typography>
          <Typography
            sx={{
              color: "#63727E",
              fontSize: small ? "14px" : "16px",
              lineHeight: "30px",
              marginBottom: "20px",
            }}
          >
            Contractors on the other hand want to match with qualified leads
            that equal their minimum requirements and not get spammed.
          </Typography>
          <Typography
            sx={{ color: "#63727E",   fontSize: small ? "14px" : "16px", lineHeight: "30px" }}
          >
            By eliminating such inefficiencies we are building a platform that’s
            both remarkable and accessible. Because every business deserves to
            grow while minimizing time to match with the right partner
          </Typography>
        </Grid>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          alignItems: isMd ? "flex-start" : "center",
          flexDirection: isMd ? "row" : "column-reverse",
          gap: "40px",
          marginTop: "20px",
          paddingX: isMd ? "58px" : "20px",
          marginBottom: isMd ? "132px" : "65px",
        }}
      >
        <Grid sx={{ width: isMd ? "50%" : "100%" }}>
          <Typography
            style={{
              fontFamily: "PP Telegraf",
              fontSize: isMd ? "48px" : "32px",
              lineHeight: "62.2px",
              color: "rgba(0, 0, 0, 1)",
              marginBottom: "26px",
            }}
          >
            How we got started
          </Typography>
          <Typography
            sx={{
              color: "#63727E",
              fontSize: small ? "14px" : "16px",
              lineHeight: "30px",
              marginBottom: "20px",
            }}
          >
           Neevay was born during an MBA program conducted jointly by IIT Bombay and University of Washington in Mumbai. Our MD Hanish Raheja met Siddharth Moorthy and Kaizad Engineer who ran multi-crore construction businesses themselves and faced vendor sourcing problems in their own businesses. 
          </Typography>
           <Typography
            sx={{
              color: "#63727E",
              fontSize: "16px",
              lineHeight: "30px",
              marginBottom: "20px",
            }}
            
          >
            The trio determined to solve this problem not only for their own businesses but for the whole industry itself. So, in addition to their classes, they spent time doing market research and experimenting with various models. The vendor matching solution they came up with was an instant hit with major developers in Mumbai and Pune areas. Couple of years into the grind they solidified the foundation that would become Neevay today.
          </Typography>
           
        </Grid>
        <Box
          component="img"
          sx={{ width: isMd ? "50%" : "100%" }}
          src={`${BASE_URL}/assets/business/web-desktop-how-we-got-started-image.webp`}
        />
      </Grid>
    </Grid>
  );
};
