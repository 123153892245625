import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  InputBase,
  Tooltip,
  Typography,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchCityDropDown from "components/SearchCityDropDown";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import { search } from "actions/searchActions";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "actions/authActions";
import NewTopbar from "layouts/Main/components/Topbar/NewTopbar/NewTopbar";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import axios from "utils/axios";
import ThankYou from "views/Home/components/BookDemoForm/ThankYou";
import VerificationPopUp from "views/VerificationModal";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { getBusinessPermissions } from "utils/accessControl";
import SearchBarNew from "../HeroSectionNew.js/SearchBarNew";
import GlobalSearchBar from "./GlobalSearchBar";

function GlobalNavbar(setIsModalOpen) {
  const mostPopularSerchesData = [
    "Civil Contractor",
    "Plumbing Contractor",
    "Cement Supplier",
    "Structural Consultants",
    "Waterproofing Contractor",
    "TMT Bar Supplier",
  ];

  const sendGetStartedFree = () => {
    navigate("/get-started", { state: "Get Started Free" });
    window.scroll(0, 0);
  };

  const [serachInputClick, setSerachInputClick] = useState(false);
  const [SearchCityClick, setSearchCityClick] = useState(false);
  const [searchInputResionValue, setSearchInputRegionValue] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [openNewTopBar, setOpenNewTopBar] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [thankYouPopUp, setThankYouPopUp] = React.useState();
  const [openLogInModal, setOpenLogInModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useSelector((state) => state.auth);
  const isPublicProfile = false;
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;
  const userType = auth?.switchUserType || userProfile?.userType;
  const theme = useTheme();
  const textRef = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchString, region } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("Category");
  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const permissions = getBusinessPermissions(
    isPublicProfile,
    userProfile?.userId,
    business?.profile
  );

  const path = window.location.pathname;
  const isMyRfqButtonClicked = path == "/requirements";
  const isShortListButtonClicked = path == "/shortlisted-profile";
  const isLeadsButtonClicked = path == "/leads";
  const isEditBussinessButtonClicked = path == "/my-business";
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  const isGlobal = useMediaQuery(theme.breakpoints.down(600), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const subscriptionType = useSelector(
    (state) => state?.business?.profile?.subscriptionType
  );

  const handleClickAway = () => {
    setSerachInputClick(false);
    setSearchCityClick(false);
  };
  const handleClickSearchCity = () => {
    setSerachInputClick(false);
    setSearchCityClick(true);
  };
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
    setSerachInputClick(false);
  };
  const handleLogout = async () => {
    setAnchorEl(null);
    try {
      await dispatch(signout());
      navigate("/");
    } catch (error) {}
  };
  const handleClickOpen = () => setOpen(true);

  const handleSearchRegionInputChange = (e) => {
    const regionText = e.target.value;
    setSearchInputRegionValue(
      regionText
        ?.split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );
  };
  const handleClickSearchInput = () => {
    setOpenNewTopBar(true);
    setSerachInputClick(true);
    setSearchCityClick(false);
  };
  const handleSearchInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSerachInputClick(false);
      handleSearch();
      e.preventDefault();
    }
  };
  const searchesClick = (data) => {
    setSearchInputValue(data);
  };
  const handleClickOnRegion = (value) => {
    setSearchInputRegionValue(value);
    setSearchCityClick(false);
  };
  const handleSearch = () => {
    setSerachInputClick(false);
    setSearchCityClick(false);
    setSearchInputRegionValue("");
    const searchKeyWord = textRef.current.value;
    if (searchKeyWord && searchKeyWord.length > 2) {
      try {
        dispatch(search(textRef.current.value, searchInputResionValue));
        const modifiedSearchKeyWor = searchKeyWord.replace(/ /g, "-");
        const regionValue = searchInputResionValue
          ? searchInputResionValue.replace(/ /g, "-")
          : "Pan-India";
        navigate(`/search/${regionValue}/${modifiedSearchKeyWor}`);
      } catch (error) {}
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleaboutus = () => {
    navigate("/about-us");
  };
  const handleClick = () => {
    setOpenLogInModal(true);
  };

  const handleMyLeadClick = () => {
    if (!userProfile) {
      setOpenLogInModal(true);
    } else {
      if (userProfile?.isVerified === false) {
        setIsModalOpen(true);
      } else {
        if (userType === "vendor" || supplierId) {
          navigate("/leads");
        }
      }
    }
  };
  const handleCloseLogInModal = () => setOpenLogInModal(false);
  const handleMrClick = () => {
    if (userProfile?.isVerified === false) {
      setIsModalOpen(true);
    } else {
      navigate("/requirements");
    }
  };

  const handleBrClick = () => {
    if (!userProfile) {
      setOpenLogInModal(true);
    } else {
      if (permissions.isOwner === true) {
        navigate("/my-business");
      } else {
        navigate("/subscribe-plan");
        // navigate("/register/business");
        // setThankYouPopUp(true);
      }
    }
  };
  const handleShortlistedVendorsClick = () => {
    if (!userProfile) {
      setOpenLogInModal(true);
    } else {
      if (userProfile?.isVerified === false) {
        setIsModalOpen(true);
      } else {
        navigate("/shortlisted-profile");
      }
    }
  };
  const handleUpgrade = () => {
    navigate("/upgrade");
  };

  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleClickDropdown = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl1(null);
  };

  const openn = Boolean(anchorEl1);
  const id = openn ? 'simple-popover' : undefined;
  const handleLogoClick = () => {
    // Check if the current page is the home page ("/")
    if (window.location.pathname === "/") {
      // Scroll to the top
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (searchString) {
      const formattedSearchString = searchString.replace("-", " ");
      setSearchInputValue(formattedSearchString);
    }
  }, [searchString]);
  const isHomepage = location.pathname === "/";
  return (
    <>
      {isHomepage ? (
        <Grid
          sx={{
            background: " rgba(0, 46, 51, 1)",
            position: "sticky",
            zIndex: "99",
            top: -1,
          }}
        >
          <Grid
            container
            sx={{
              zIndex: "99",

              height: "62px",
              paddingX: isSm ? "40px" : "20px",
              maxWidth: "1380px",
              margin: "auto",
              display: "flex",

              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid width={isSm ? "85px" : "40px"}>
              <Link to="/" onClick={handleLogoClick}>
                <Box
                  component="img"
                  sx={{
                    height: isSm ? "35px" : "50px",
                    cursor: "pointer",
                  }}
                  alt="logo"
                  src={
                    isSm
                      ? `${BASE_URL}/assets/business/NEEVAY_Logo_new.svg`
                      : `${BASE_URL}/assets/business/NEEVAY_logo_new_smallscreen.svg`
                  }
                />
              </Link>
            </Grid>

            {userType === "buyer" && (
              <Grid
                item
                xs
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                {isGlobal ? (
                  <ClickAwayListener onClickAway={() => handleClickAway()}>
                    <Grid
                      onClick={handleClickOpen}
                      sx={{
                        justifyContent: !isSm && "space-between",
                        padding: "0 8px",
                        maxWidth: "600px",
                        height: "36px",
                        borderRadius:
                          isSm && serachInputClick
                            ? "10px 10px 0px 0px"
                            : SearchCityClick
                            ? "50px 50px 50px 0px"
                            : " 50px",
                        border: "1px solid #636363",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                        backgroundColor: "#FAFAFA",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {isSm && (
                        <Grid
                          onClick={handleClickSearchCity}
                          sx={{
                            display: "flex",
                            gap: "12px",
                            width: "26%",
                            height: "100%",
                            borderRight: "1px solid #333",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              width: "15px",
                              ml: "6px",
                            }}
                            alt="logo"
                            src={`${BASE_URL}/assets/business/web-homepage-location-logo.svg`}
                          />
                          <InputBase
                            value={searchInputResionValue}
                            onChange={handleSearchRegionInputChange}
                            sx={{
                              color: "#444040",
                              flex: 1,
                              fontSize: global ? "16px" : "16px",
                              fontWeight: "500",
                              marginRight: "3px",
                            }}
                            placeholder={isSm ? "PAN-India" : ""}
                          />
                        </Grid>
                      )}
                      <InputBase
                        sx={{ pl: isSm ? "8px" : "0px", width: "75%" }}
                        inputRef={textRef}
                        value={searchInputValue}
                        onClick={handleClickSearchInput}
                        onChange={handleSearchInputChange}
                        placeholder={
                          isSm
                            ? " Search for Products/Services"
                            : " Products/Services"
                        }
                        onKeyDown={handleKeyDown}
                      />
                      {isSm && (
                        <Button
                          onClick={handleSearch}
                          sx={{
                            width: "84px",
                            height: "28px",
                            backgroundColor: "#FD6600",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FD6600",
                              transition:
                                "background-color 0.3s, box-shadow 0.3s",
                              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                            },
                          }}
                        >
                          <Tooltip
                            title="Search"
                            style={{ fontWeight: "300px" }}
                          >
                            <Typography fontWeight={600}>Search</Typography>
                          </Tooltip>
                        </Button>
                      )}
                      {!isSm && (
                        <Box
                          // onClick={handleSearch}
                          sx={{
                            padding: "3px",
                            width: "31px",
                            height: "28px",
                            borderRadius: "50%",
                            // backgroundColor: "#FD6600",
                            color: "#FD6600",
                            "&:hover": {
                              // backgroundColor: "#FD6600",
                              transition:
                                "background-color 0.3s, box-shadow 0.3s",
                              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                            },
                          }}
                        >
                          <Tooltip
                            title="Search"
                            style={{ fontWeight: "300px" }}
                          >
                            <SearchIcon onClick={handleSearch} />
                          </Tooltip>
                        </Box>
                      )}

                      {isSm && (
                        <Grid
                          sx={{
                            position: "absolute",
                            width: "100%",
                            left: 0,
                            zIndex: 1,
                            top: "100%",
                          }}
                        >
                          {serachInputClick && (
                            <Grid
                              sx={{
                                width: "100%",
                                float: "right",
                                border: "1px solid #636363",
                                backgroundColor: "white",
                                borderRadius: "0px 0px 12px 12px",
                                padding: "4px 10px",
                              }}
                            >
                              <Grid sx={{ color: "#757373" }}>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    margin: "8px 0 2px",
                                  }}
                                >
                                  Most Popular Searches
                                </Typography>
                                {mostPopularSerchesData.map((data) => (
                                  <Grid
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    onClick={() => searchesClick(data)}
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      <Grid item>
                                        {/* <SearchIcon fontSize="small" /> */}
                                        <NorthWestIcon
                                          sx={{
                                            color: "#cccccc",
                                            fontSize: "small",
                                          }}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          variant="body2"
                                          sx={{ fontSize: "14px" }}
                                        >
                                          {data}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          )}
                          {SearchCityClick && (
                            <Grid sx={{ width: "75%" }}>
                              <SearchCityDropDown
                                searchTerm={searchInputResionValue}
                                handleClickOnRegion={handleClickOnRegion}
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </ClickAwayListener>
                ) : (
                  <GlobalSearchBar />
                )}
              </Grid>
            )}

            {userType === "vendor" && (
              <Grid item xs display={"flex"} justifyContent={"center"}></Grid>
            )}

            {isMd && userType === "buyer" ? (
              <>
                <Grid
                  item
                  width={"144px"}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Typography
                    onClick={handleMrClick}
                    sx={{
                      cursor: "pointer",
                      // backgroundColor: "#fff",
                      color: isMyRfqButtonClicked ? "#09B686" : "white",
                      fontWeight: 700,
                      fontSize: "16px",
                      letterSpacing: "0.14px",
                      cursor: "pointer",
                      paddingX: "10px",
                      paddingY: "3px",
                      "&:hover": {
                        backgroundColor: "#F1F1F1",
                        color: "black",
                        transition: "background-color 0.3s ease-in",
                        borderRadius: "15px",
                        paddingX: "10px",
                        paddingY: "3px",
                      },
                    }}
                  >
                    {entityId ? "Requirements" : "Enquiries"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  width={"173px"}
                  sx={{ display: "flex", gap: "6px", cursor: "pointer" }}
                >
                  <Typography
                    onClick={handleShortlistedVendorsClick}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor: "#fff",
                      color: isShortListButtonClicked ? "#09B686" : "white",
                      fontWeight: 700,
                      fontSize: "15px",
                      letterSpacing: "0.14px",
                      cursor: "pointer",
                      paddingY: "1.2px",
                      paddingLeft: "10px",
                      paddingRight: "6px",
                      "&:hover": {
                        backgroundColor: "#F1F1F1",
                        color: "black",
                        transition: "background-color 0.3s ease-in",
                        borderRadius: "15px",
                        paddingY: "1.2px",
                        paddingLeft: "10px",
                        paddingRight: "6px",
                      },
                    }}
                  >
                    Shortlisted Vendors
                    <ArrowOutwardIcon
                      sx={{ width: "17px", marginLeft: "1px" }}
                    />
                  </Typography>
                </Grid>
              </>
            ) : (
              userType === "vendor" &&
              isMd && (
                <Grid item width={supplierId ? "102px" : "145px"}>
                  <Typography
                    onClick={handleBrClick}
                    sx={{
                      textAlign: "center",
                      cursor: "pointer",
                      // backgroundColor: "#fff",
                      color: isEditBussinessButtonClicked ? "#09B686" : "white",
                      fontWeight: 700,
                      fontSize: "16px",
                      letterSpacing: "0.14px",
                      cursor: "pointer",
                      paddingY: "3px",
                      "&:hover": {
                        backgroundColor: "#F1F1F1",
                        color: "black",
                        transition: "background-color 0.3s ease-in",
                        borderRadius: "15px",
                        paddingY: "3px",
                      },
                    }}
                  >
                    {permissions.isOwner === true
                      ? "My Business"
                      : "Register as Vendor"}
                  </Typography>
                </Grid>
              )
            )}

            {isMd && userType === "vendor" && (
              <Grid
                item
                width={"110px"}
                sx={{ display: "flex", cursor: "pointer" }}
              >
                <Typography
                  onClick={handleMyLeadClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    // backgroundColor: "#fff",
                    color: isLeadsButtonClicked ? "#09B686" : "white",
                    fontWeight: 700,
                    fontSize: "16px",
                    letterSpacing: "0.14px",
                    cursor: "pointer",
                    paddingY: "1.2px",
                    paddingLeft: "10px",
                    paddingRight: "6px",
                    "&:hover": {
                      backgroundColor: "#F1F1F1",
                      color: "black",
                      transition: "background-color 0.3s ease-in",
                      borderRadius: "15px",
                      paddingY: "1.2px",
                      paddingLeft: "10px",
                      paddingRight: "6px",
                    },
                  }}
                >
                  My Leads
                  <ArrowOutwardIcon sx={{ width: "17px", marginLeft: "1px" }} />
                </Typography>
              </Grid>
            )}

            {userType === "vendor" && subscriptionType === "BASIC" && (
              <Box
                sx={{
                  cursor: "pointer",
                  border: " 0.3px solid #D18E20",
                  background: "#FBF8F6",
                  paddingX: "14px",
                  paddingY: "4px",
                  marginRight: "10px",
                }}
                onClick={handleUpgrade}
              >
                <Typography
                  sx={{
                    color: " #D18E20",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    letterSpacing: "0.14px",
                  }}
                >
                  Upgrade
                </Typography>
              </Box>
            )}
            {/*  */}

            {userProfile?.name ? (
              <Grid
                item
                width={"30px"}
                style={{
                  height: "30px",
                  width: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "white",
                  borderRadius: "5px",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "21px",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <Button
                  id="basic-button"
                  aria-label="more"
                  aria-controls={
                    Boolean(anchorEl) ? "custom-select-menu" : undefined
                  }
                  // aria-controls="custom-select-menu"
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                  onClick={handleOpenMenu}
                  sx={{ padding: 0 }}
                >
                  <Box
                    component={"img"}
                    alt="user icon"
                    height={"25px"}
                    src={`${BASE_URL}/assets/business/Global_header_logout.svg`}
                  />
                </Button>
                <Menu
                  id="custom-select-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleLogout}>Log out</MenuItem>
                </Menu>
              </Grid>
            ) : (
              <Grid display={"flex"} gap={2} alignItems={"center"}>
                {isMd && !userProfile && (
                  <>
                    {/* <Typography
                      onClick={handleClickDropdown}
                      sx={{
                        fontSize: "16px",
                        color: "white",
                        fontWeight: 700,
                        letterSpacing: "0.14px",
                        paddingX: "10px",
                        paddingY: "3px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#F1F1F1",
                          color: "black",
                          transition: "background-color 0.3s ease-in",
                          borderRadius: "15px",
                          paddingX: "10px",
                          paddingY: "3px",
                        },
                      }}
                    >
                      Resources
                    </Typography>
                    <Menu
                      id={id}
                      anchorEl={anchorEl1}
                      open={openn}
                      onClose={handleCloseDropdown}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      PaperProps={{
                        style: {
                          // backgroundColor: "rgba(255, 255, 255, 0.5)", // Transparent white color
                        },
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseDropdown}
                        component={Link}
                        to="/publish-blog"
                        style={{ paddingX: 2,paddingTop:0}} 
                      >
                        Blogs
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseDropdown}
                        component={Link}
                        style={{ paddingX: 2,paddingTop:0}} 
                        to="/casestudy-blog"
                      >
                        Case Study
                      </MenuItem>
                    </Menu> */}

                    <Typography
                      onClick={handleaboutus}
                      sx={{
                        fontSize: "16px",
                        color: "white",
                        fontWeight: 700,
                        letterSpacing: "0.14px",
                        paddingX: "10px",
                        paddingY: "3px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#F1F1F1",
                          color: "black",
                          transition: "background-color 0.3s ease-in",
                          borderRadius: "15px",
                          paddingX: "10px",
                          paddingY: "3px",
                        },
                      }}
                    >
                      About Us
                    </Typography>
                  </>
                )}
                {isMd && (
                  <Typography
                    onClick={handleBrClick}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor: "#fff",
                      color: isEditBussinessButtonClicked ? "#09B686" : "white",
                      fontWeight: 700,
                      fontSize: "16px",
                      letterSpacing: "0.14px",
                      cursor: "pointer",
                      paddingY: "3px",
                      paddingLeft: "10px",
                      paddingRight: "6px",
                      "&:hover": {
                        backgroundColor: "#F1F1F1",
                        color: "black",
                        transition: "background-color 0.3s ease-in",
                        borderRadius: "15px",
                        paddingY: "3px",
                        paddingLeft: "10px",
                        paddingRight: "6px",
                      },
                    }}
                  >
                    {permissions.isOwner === true
                      ? "My Business"
                      : "Register as Vendor"}
                    <ArrowOutwardIcon
                      sx={{ width: "17px", marginLeft: "1px" }}
                    />
                  </Typography>
                )}

                <Typography
                  onClick={handleClick}
                  sx={{
                    fontSize: "16px",
                    color: "white",
                    fontWeight: 700,
                    letterSpacing: "0.14px",
                    paddingX: "10px",
                    paddingY: "3px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#F1F1F1",
                      color: "black",
                      transition: "background-color 0.3s ease-in",
                      borderRadius: "15px",
                      paddingX: "10px",
                      paddingY: "3px",
                    },
                  }}
                >
                  Sign In
                </Typography>

                <Button
                  onClick={sendGetStartedFree}
                  sx={{
                    marginLeft: "5px",
                    height: "38px",
                    width: "156px",
                    borderRadius: "5px",
                    backgroundColor: "#FD6600",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#FD6600",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      mr: "8px",
                      color: "white",
                      fontSize: "14",
                      fontWeight: 500,
                      letterSpacing: "0.14px",
                    }}
                  >
                    Get Started
                  </Typography>
                  <Box
                    component="img"
                    sx={{
                      width: "12px",
                      color: "black",
                    }}
                    alt="logo"
                    src={`${BASE_URL}/assets/business/header_white_arrow.svg`}
                  />
                </Button>
              </Grid>
            )}

            {!isSm && openNewTopBar && (
              <NewTopbar
                open={open}
                handleClose={handleClose}
                regionstring={"Pan-India"}
                handleSearch={handleSearch}
                handleClickSearchInput={handleClickSearchInput}
                searchInputValue={searchInputValue}
                handleSearchInputChange={handleSearchInputChange}
                serachInputClick={serachInputClick}
                setSerachInputClick={setSerachInputClick}
                handleKeyDown={handleKeyDown}
                searchOption={"all"}
                global={false}
                setSearchInputValue={setSearchInputValue}
                searchInputTextValue={""}
                setSearchInputRegionValue={setSearchInputRegionValue}
                searchInputResionValue={searchInputResionValue}
                handleClickSearchCity={handleClickSearchCity}
                handleSearchRegionInputChange={handleSearchRegionInputChange}
                setSearchCityClick={setSearchCityClick}
                SearchCityClick={SearchCityClick}
                searchTerm={searchInputResionValue}
                setOpen={setOpen}
              />
            )}
            <LogInMobilePopUp
              setOpenLogInModal={setOpenLogInModal}
              openLogInModal={openLogInModal}
              handleCloseLogInModal={handleCloseLogInModal}
            />
            {thankYouPopUp && (
              <ThankYou
                thankYouPopUp={thankYouPopUp}
                setThankYouPopUp={setThankYouPopUp}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            zIndex: "99",
            background: " rgba(0, 46, 51, 1)",
            position: "sticky",
            zIndex: "99",
            top: -1,
            height: "62px",
            paddingX: isSm ? "40px" : "20px",
            // maxWidth:"1380px",
            // margin:"auto",
            display: "flex",

            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid width={isSm ? "85px" : "40px"}>
            <Link to="/" onClick={handleLogoClick}>
              <Box
                component="img"
                sx={{
                  height: isSm ? "35px" : "45px",
                  cursor: "pointer",
                }}
                alt="logo"
                src={
                  isSm
                    ? `${BASE_URL}/assets/business/NEEVAY_Logo_new.svg`
                    : `${BASE_URL}/assets/business/NEEVAY_logo_new_smallscreen.svg`
                }
              />
            </Link>
          </Grid>

          {userType === "buyer" && (
            <Grid
              item
              xs
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {isGlobal ? (
                <ClickAwayListener onClickAway={() => handleClickAway()}>
                  <Grid
                    onClick={handleClickOpen}
                    sx={{
                      justifyContent: !isSm && "space-between",
                      padding: "0 8px",
                      maxWidth: "600px",
                      height: "36px",
                      borderRadius:
                        isSm && serachInputClick
                          ? "10px 10px 0px 0px"
                          : SearchCityClick
                          ? "50px 50px 50px 0px"
                          : " 50px",
                      border: "1px solid #636363",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      backgroundColor: "#FAFAFA",
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {isSm && (
                      <Grid
                        onClick={handleClickSearchCity}
                        sx={{
                          display: "flex",
                          gap: "12px",
                          width: "26%",
                          height: "100%",
                          borderRight: "1px solid #333",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            width: "15px",
                            ml: "6px",
                          }}
                          alt="logo"
                          src={`${BASE_URL}/assets/business/web-homepage-location-logo.svg`}
                        />
                        <InputBase
                          value={searchInputResionValue}
                          onChange={handleSearchRegionInputChange}
                          sx={{
                            color: "#444040",
                            flex: 1,
                            fontSize: global ? "16px" : "16px",
                            fontWeight: "500",
                            marginRight: "3px",
                          }}
                          placeholder={isSm ? "PAN-India" : ""}
                        />
                      </Grid>
                    )}
                    <InputBase
                      sx={{ pl: isSm ? "8px" : "0px", width: "75%" }}
                      inputRef={textRef}
                      value={searchInputValue}
                      onClick={handleClickSearchInput}
                      onChange={handleSearchInputChange}
                      placeholder={
                        isSm
                          ? " Search for Products/Services"
                          : " Products/Services"
                      }
                      onKeyDown={handleKeyDown}
                    />
                    {isSm && (
                      <Button
                        onClick={handleSearch}
                        sx={{
                          width: "84px",
                          height: "28px",
                          backgroundColor: "#FD6600",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#FD6600",
                            transition:
                              "background-color 0.3s, box-shadow 0.3s",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                          },
                        }}
                      >
                        <Tooltip title="Search" style={{ fontWeight: "300px" }}>
                          <Typography fontWeight={600}>Search</Typography>
                        </Tooltip>
                      </Button>
                    )}
                    {!isSm && (
                      <Box
                        // onClick={handleSearch}
                        sx={{
                          padding: "3px",
                          width: "31px",
                          height: "28px",
                          borderRadius: "50%",
                          backgroundColor: "#FD6600",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#FD6600",
                            transition:
                              "background-color 0.3s, box-shadow 0.3s",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                          },
                        }}
                      >
                        <Tooltip title="Search" style={{ fontWeight: "300px" }}>
                          <SearchIcon onClick={handleSearch} />
                        </Tooltip>
                      </Box>
                    )}

                    {isSm && (
                      <Grid
                        sx={{
                          position: "absolute",
                          width: "100%",
                          left: 0,
                          zIndex: 1,
                          top: "100%",
                        }}
                      >
                        {serachInputClick && (
                          <Grid
                            sx={{
                              width: "100%",
                              float: "right",
                              border: "1px solid #636363",
                              backgroundColor: "white",
                              borderRadius: "0px 0px 12px 12px",
                              padding: "4px 10px",
                            }}
                          >
                            <Grid sx={{ color: "#757373" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  margin: "8px 0 2px",
                                }}
                              >
                                Most Popular Searches
                              </Typography>
                              {mostPopularSerchesData.map((data) => (
                                <Grid
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  onClick={() => searchesClick(data)}
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item>
                                      {/* <SearchIcon fontSize="small" /> */}
                                      <NorthWestIcon
                                        sx={{
                                          color: "#cccccc",
                                          fontSize: "small",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: "14px" }}
                                      >
                                        {data}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        )}
                        {SearchCityClick && (
                          <Grid sx={{ width: "75%" }}>
                            <SearchCityDropDown
                              searchTerm={searchInputResionValue}
                              handleClickOnRegion={handleClickOnRegion}
                            />
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </ClickAwayListener>
              ) : (
                <GlobalSearchBar />
              )}
            </Grid>
          )}

          {userType === "vendor" && (
            <Grid item xs display={"flex"} justifyContent={"center"}></Grid>
          )}

          {isMd && userType === "buyer" ? (
            <>
              <Grid
                item
                width={"144px"}
                display={"flex"}
                justifyContent={"center"}
              >
                <Typography
                  onClick={handleMrClick}
                  sx={{
                    cursor: "pointer",
                    // backgroundColor: "#fff",
                    color: isMyRfqButtonClicked ? "#09B686" : "white",
                    fontWeight: 700,
                    fontSize: "16px",
                    letterSpacing: "0.14px",
                    cursor: "pointer",
                    paddingX: "10px",
                    paddingY: "3px",
                    "&:hover": {
                      backgroundColor: "#F1F1F1",
                      color: "black",
                      transition: "background-color 0.3s ease-in",
                      borderRadius: "15px",
                      paddingX: "10px",
                      paddingY: "3px",
                    },
                  }}
                >
                  {entityId ? "Requirements" : "Enquiries"}
                </Typography>
              </Grid>
              <Grid
                item
                width={"173px"}
                sx={{ display: "flex", gap: "6px", cursor: "pointer" }}
              >
                <Typography
                  onClick={handleShortlistedVendorsClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    // backgroundColor: "#fff",
                    color: isShortListButtonClicked ? "#09B686" : "white",
                    fontWeight: 700,
                    fontSize: "15px",
                    letterSpacing: "0.14px",
                    cursor: "pointer",
                    paddingY: "1.2px",
                    paddingLeft: "10px",
                    paddingRight: "6px",
                    "&:hover": {
                      backgroundColor: "#F1F1F1",
                      color: "black",
                      transition: "background-color 0.3s ease-in",
                      borderRadius: "15px",
                      paddingY: "1.2px",
                      paddingLeft: "10px",
                      paddingRight: "6px",
                    },
                  }}
                >
                  Shortlisted Vendors
                  <ArrowOutwardIcon sx={{ width: "17px", marginLeft: "1px" }} />
                </Typography>
              </Grid>
            </>
          ) : (
            userType === "vendor" &&
            isMd && (
              <Grid item width={supplierId ? "102px" : "145px"}>
                <Typography
                  onClick={handleBrClick}
                  sx={{
                    textAlign: "center",
                    cursor: "pointer",
                    // backgroundColor: "#fff",
                    color: isEditBussinessButtonClicked ? "#09B686" : "white",
                    fontWeight: 700,
                    fontSize: "16px",
                    letterSpacing: "0.14px",
                    cursor: "pointer",
                    paddingY: "3px",
                    "&:hover": {
                      backgroundColor: "#F1F1F1",
                      color: "black",
                      transition: "background-color 0.3s ease-in",
                      borderRadius: "15px",
                      paddingY: "3px",
                    },
                  }}
                >
                  {permissions.isOwner === true
                    ? "My Business"
                    : "Register as Vendor"}
                </Typography>
              </Grid>
            )
          )}

          {isMd && userType === "vendor" && (
            <Grid
              item
              width={"110px"}
              sx={{ display: "flex", cursor: "pointer" }}
            >
              <Typography
                onClick={handleMyLeadClick}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  // backgroundColor: "#fff",
                  color: isLeadsButtonClicked ? "#09B686" : "white",
                  fontWeight: 700,
                  fontSize: "16px",
                  letterSpacing: "0.14px",
                  cursor: "pointer",
                  paddingY: "1.2px",
                  paddingLeft: "10px",
                  paddingRight: "6px",
                  "&:hover": {
                    backgroundColor: "#F1F1F1",
                    color: "black",
                    transition: "background-color 0.3s ease-in",
                    borderRadius: "15px",
                    paddingY: "1.2px",
                    paddingLeft: "10px",
                    paddingRight: "6px",
                  },
                }}
              >
                My Leads
                <ArrowOutwardIcon sx={{ width: "17px", marginLeft: "1px" }} />
              </Typography>
            </Grid>
          )}

          {userType === "vendor" && subscriptionType === "BASIC" && (
            <Box
              sx={{
                cursor: "pointer",
                border: " 0.3px solid #D18E20",
                background: "#FBF8F6",
                paddingX: "14px",
                paddingY: "4px",
                marginRight: "10px",
              }}
              onClick={handleUpgrade}
            >
              <Typography
                sx={{
                  color: " #D18E20",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  letterSpacing: "0.14px",
                }}
              >
                Upgrade
              </Typography>
            </Box>
          )}
          {/*  */}

          {userProfile?.name ? (
            <Grid
              item
              width={"30px"}
              style={{
                height: "30px",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "white",
                borderRadius: "5px",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "21px",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <Button
                id="basic-button"
                aria-label="more"
                aria-controls={
                  Boolean(anchorEl) ? "custom-select-menu" : undefined
                }
                // aria-controls="custom-select-menu"
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                onClick={handleOpenMenu}
                sx={{ padding: 0 }}
              >
                <Box
                  component={"img"}
                  alt="user icon"
                  height={"25px"}
                  src={`${BASE_URL}/assets/business/Global_header_logout.svg`}
                />
              </Button>
              <Menu
                id="custom-select-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
              </Menu>
            </Grid>
          ) : (
            <Grid display={"flex"} gap={2} alignItems={"center"}>
              {isMd && !userProfile && (
                <>
                {/* <Typography
                      onClick={handleClickDropdown}
                      sx={{
                        fontSize: "16px",
                        color: "white",
                        fontWeight: 700,
                        letterSpacing: "0.14px",
                        paddingX: "10px",
                        paddingY: "3px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#F1F1F1",
                          color: "black",
                          transition: "background-color 0.3s ease-in",
                          borderRadius: "15px",
                          paddingX: "10px",
                          paddingY: "3px",
                        },
                      }}
                    >
                      Resources
                    </Typography>
                    <Menu
                      id={id}
                      anchorEl={anchorEl1}
                      open={openn}
                      onClose={handleCloseDropdown}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      PaperProps={{
                        style: {
                          // backgroundColor: "rgba(255, 255, 255, 0.5)", // Transparent white color
                        },
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseDropdown}
                        component={Link}
                        to="/publish-blog"
                        style={{ paddingX: 2,paddingTop:0}} 
                      >
                        Blogs
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseDropdown}
                        component={Link}
                        style={{ paddingX: 2,paddingTop:0}} 
                        to="/casestudy-blog"
                      >
                        Case Study
                      </MenuItem>
                    </Menu> */}

                  <Typography
                    onClick={handleaboutus}
                    sx={{
                      fontSize: "16px",
                      color: "white",
                      fontWeight: 700,
                      letterSpacing: "0.14px",
                      paddingX: "10px",
                      paddingY: "3px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#F1F1F1",
                        color: "black",
                        transition: "background-color 0.3s ease-in",
                        borderRadius: "15px",
                        paddingX: "10px",
                        paddingY: "3px",
                      },
                    }}
                  >
                    About Us
                  </Typography>
                </>
              )}
              {isMd && (
                <Typography
                  onClick={handleBrClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    // backgroundColor: "#fff",
                    color: isEditBussinessButtonClicked ? "#09B686" : "white",
                    fontWeight: 700,
                    fontSize: "16px",
                    letterSpacing: "0.14px",
                    cursor: "pointer",
                    paddingY: "3px",
                    paddingLeft: "10px",
                    paddingRight: "6px",
                    "&:hover": {
                      backgroundColor: "#F1F1F1",
                      color: "black",
                      transition: "background-color 0.3s ease-in",
                      borderRadius: "15px",
                      paddingY: "3px",
                      paddingLeft: "10px",
                      paddingRight: "6px",
                    },
                  }}
                >
                  {permissions.isOwner === true
                    ? "My Business"
                    : "Register as Vendor"}
                  <ArrowOutwardIcon sx={{ width: "17px", marginLeft: "1px" }} />
                </Typography>
              )}

              <Typography
                onClick={handleClick}
                sx={{
                  fontSize: "16px",
                  color: "white",
                  fontWeight: 700,
                  letterSpacing: "0.14px",
                  paddingX: "10px",
                  paddingY: "3px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#F1F1F1",
                    color: "black",
                    transition: "background-color 0.3s ease-in",
                    borderRadius: "15px",
                    paddingX: "10px",
                    paddingY: "3px",
                  },
                }}
              >
                Sign In
              </Typography>

              <Button
                onClick={sendGetStartedFree}
                sx={{
                  marginLeft: "5px",
                  height: "38px",
                  width: "156px",
                  borderRadius: "5px",
                  backgroundColor: "#FD6600",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FD6600",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                  },
                }}
              >
                <Typography
                  sx={{
                    mr: "8px",
                    color: "white",
                    fontSize: "14",
                    fontWeight: 500,
                    letterSpacing: "0.14px",
                  }}
                >
                  Get Started
                </Typography>
                <Box
                  component="img"
                  sx={{
                    width: "12px",
                    color: "black",
                  }}
                  alt="logo"
                  src={`${BASE_URL}/assets/business/header_white_arrow.svg`}
                />
              </Button>
            </Grid>
          )}

          {!isSm && openNewTopBar && (
            <NewTopbar
              open={open}
              handleClose={handleClose}
              regionstring={"Pan-India"}
              handleSearch={handleSearch}
              handleClickSearchInput={handleClickSearchInput}
              searchInputValue={searchInputValue}
              handleSearchInputChange={handleSearchInputChange}
              serachInputClick={serachInputClick}
              setSerachInputClick={setSerachInputClick}
              handleKeyDown={handleKeyDown}
              searchOption={"all"}
              global={false}
              setSearchInputValue={setSearchInputValue}
              searchInputTextValue={""}
              setSearchInputRegionValue={setSearchInputRegionValue}
              searchInputResionValue={searchInputResionValue}
              handleClickSearchCity={handleClickSearchCity}
              handleSearchRegionInputChange={handleSearchRegionInputChange}
              setSearchCityClick={setSearchCityClick}
              SearchCityClick={SearchCityClick}
              searchTerm={searchInputResionValue}
              setOpen={setOpen}
            />
          )}
          <LogInMobilePopUp
            setOpenLogInModal={setOpenLogInModal}
            openLogInModal={openLogInModal}
            handleCloseLogInModal={handleCloseLogInModal}
          />
          {thankYouPopUp && (
            <ThankYou
              thankYouPopUp={thankYouPopUp}
              setThankYouPopUp={setThankYouPopUp}
            />
          )}
        </Grid>
      )}
    </>
  );
}
export default GlobalNavbar;
