import React from "react";
import Button from "./Button";

const Chip = ({ label, onRemove, title, className }) => {
  return (
    <div
      title={title}
      className={
        "inline-flex items-center cursor-pointer bg-transparent rounded-full border-2 px-3 py-1 text-sm font-medium text-gray-600 gap-x-1 " +
        className
      }
    >
      {label}
      {onRemove && (
        <Button
          variant="text"
          onClick={onRemove}
          style={{ padding: 0, fontSize: "1.25rem", color: "gray" }}
        >
          &times;
        </Button>
      )}
    </div>
  );
};

export default Chip;
