import produce from 'immer';
import { TAGS_FETCH, TAGS_FETCH_FAILURE, TAGS_FETCH_SUCCESS, TAG_ADD_SUCCESS } from 'actions/tagsActions';

import React from 'react'

const initialState = {
    tags: [],
    count: 0,
}

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TAGS_FETCH: {
            return produce(state, (draft) => {
                draft.tags = [];
                draft.count = 0;
            });
        }
            break;

        case TAGS_FETCH_SUCCESS: {
            const { tags } = action.payload;
            return produce(state, (draft) => {
                draft.tags = tags;
                draft.count = tags?.length;
            });
        }
            break;

        case TAGS_FETCH_FAILURE: {
            return produce(state, (draft) => {
                draft.tags = [];
                draft.count = 0;
            });
        }
            break;

        case TAG_ADD_SUCCESS: {
            const tags = action.payload;
            return produce(state, (draft) => {
                draft.tags = tags;
                draft.count = 0;
            });
        }
            break;

        default: {
            return state;
        }
            break;
    }
}

export default tagsReducer
