import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "../DateRangePicker ";
import { searchRfqWithDate } from "actions/rfqActions";

const FilterSection = ({
  handleChangeTeamMemberNew,
  workspaceView,
  rfqsData,
  handleGetRfqs,
  setRfqFilterStatus,
  user,
  projectId = "",
}) => {
  const dispatch = useDispatch();

  const [selectedStatus, setSelectedStatus] = useState("");
  const [openKeys, setOpenKeys] = useState({
    dateRange: true,
    status: true,
    team: true,
  });
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [dateFilterName, setDateFilterName] = useState("");
  const [closeDateFilterName, setCloseDateFilterName] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [allDataClr, setAllDataClr] = useState(false);

  const filterColData = [
    "OPEN",
    "CLOSED",
    "DRAFT",
    // "CANCELED",
    // "HOLD",
    // "EXPIRED",
    // "DELETED",
  ];
  const entityId = user?.userEntity?.entityId;
  const statusWiseCounts = rfqsData?.statusWiseCounts;
  const totalCount = rfqsData?.totalCount;
  const teamUserIds = user?.teamMembers?.map((member) => member.userId);
  const convertedData = statusWiseCounts?.reduce((acc, curr) => {
    acc[curr._id] = curr?.count;
    return acc;
  }, {});

  const handleTeamInputChange = (e, userId) => {
    let userIds;
    if (e.target.checked) {
      userIds = [...selectedUserIds, userId];
    } else {
      userIds = selectedUserIds?.filter((id) => id != userId);
    }
    setSelectedUserIds(userIds);
    handleChangeTeamMemberNew(userIds);
  };

  const handleChangeStatus = (status) => {
    setSelectedStatus(status);
    setRfqFilterStatus(status); //use for pass this value to search rfq api
    // handleGetRfqs({ status });
    dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status,
        userIds:
          selectedUserIds?.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserIds,
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
  };

  const handleToggle = (key) => {
    setOpenKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleDateChange = ({ startDate, endDate }) => {
    dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        dateFilterIsActive: true,
        startDate,
        endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds:
          selectedUserIds?.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserIds,
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
  };
  const handleCloseDateChange = ({ startDate, endDate }) => {
    dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        closeDateFilterIsActive: true,
        startDateOfClosing: startDate,
        endDateOfClosing: endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds:
          selectedUserIds.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserIds,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
      })
    );
  };
  const handleCloseDateClr = async () => {
    const response = await dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        closeDateFilterIsActive: false,
        startDateOfClosing: "",
        endDateOfClosing: "",
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds:
          selectedUserIds.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserIds,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
      })
    );
  };
  const handleClrSearch = async () => {
    const response = await dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        closeDateFilterIsActive: false,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
        searchParam: "",
        status: rfqsData?.filterStatus,
        userIds:
          selectedUserIds.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserIds,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
      })
    );
  };

  const handleDateClr = async () => {
    const response = await dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        dateFilterIsActive: false,
        startDate: "",
        endDate: "",
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds:
          selectedUserIds.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserIds,
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
  };

  useEffect(() => {
    if (workspaceView === "Team Workspace") {
      setSelectedUserIds([]);
    } else {
      setSelectedUserIds([user?.userId]);
    }
  }, [workspaceView]);
  useEffect(() => {
    setAllDataClr(false);
  }, [filterApplied]);
  return (
    <div className="flex flex-col items-start p-2 px-3 gap-5 h-full overflow-scroll">
      <p className="font-bold text-sm text-right">Filters</p>

      <div className="flex flex-col gap-4 w-full">
        {rfqsData?.searchParam && (
          <div className="flex justify-between items-center bg-blue-100 text-blue-700 text-sm font-medium px-2 py-0.5 rounded-full">
            <p
              className="max-w-[72px] mr-1 text-sm truncate"
              title={rfqsData?.searchParam}
            >
              {rfqsData?.searchParam}{" "}
            </p>
            <button
              onClick={() => handleClrSearch()}
              className="ml-1 flex items-center justify-center w-4 h-4 bg-blue-200 hover:bg-blue-300 rounded-full focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-blue-700"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        )}
        {rfqsData?.dateFilterIsActive && (
          <div className="w-full px-2 py-1 border rounded-3xl  border-[#99855C] bg-[#FEFEE3] flex justify-between">
            <div className="text-xs">
              <p>Create Date</p>
              <p className="font-normal">{dateFilterName}</p>
            </div>
            <button
              onClick={() => {
                handleDateClr();
              }}
            >
              <svg
                className="w-2 h-2"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.76519 1.36624C8.07754 1.05388 8.07754 0.546619 7.76519 0.234265C7.45284 -0.0780884 6.94557 -0.0780884 6.63322 0.234265L4.00195 2.86803L1.36819 0.236764C1.05583 -0.0755896 0.548572 -0.0755896 0.236218 0.236764C-0.0761353 0.549118 -0.0761353 1.05638 0.236218 1.36873L2.86998 4L0.238717 6.63377C-0.0736364 6.94612 -0.0736364 7.45338 0.238717 7.76574C0.551071 8.07809 1.05833 8.07809 1.37069 7.76574L4.00195 5.13197L6.63572 7.76324C6.94807 8.07559 7.45533 8.07559 7.76769 7.76324C8.08004 7.45089 8.08004 6.94362 7.76769 6.63127L5.13392 4L7.76519 1.36624Z"
                  fill="#797979"
                />
              </svg>
            </button>
          </div>
        )}
        {rfqsData?.closeDateFilterIsActive && (
          <div className="w-full px-2 py-1 border rounded-3xl  border-[#0037F9] bg-[#E5EAFC] flex justify-between">
            <div className="text-xs">
              <p>Close Date</p>
              <p className="font-normal">{closeDateFilterName}</p>
            </div>
            <button
              onClick={() => {
                handleCloseDateClr();
              }}
            >
              <svg
                className="w-2 h-2"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.76519 1.36624C8.07754 1.05388 8.07754 0.546619 7.76519 0.234265C7.45284 -0.0780884 6.94557 -0.0780884 6.63322 0.234265L4.00195 2.86803L1.36819 0.236764C1.05583 -0.0755896 0.548572 -0.0755896 0.236218 0.236764C-0.0761353 0.549118 -0.0761353 1.05638 0.236218 1.36873L2.86998 4L0.238717 6.63377C-0.0736364 6.94612 -0.0736364 7.45338 0.238717 7.76574C0.551071 8.07809 1.05833 8.07809 1.37069 7.76574L4.00195 5.13197L6.63572 7.76324C6.94807 8.07559 7.45533 8.07559 7.76769 7.76324C8.08004 7.45089 8.08004 6.94362 7.76769 6.63127L5.13392 4L7.76519 1.36624Z"
                  fill="#797979"
                />
              </svg>
            </button>
          </div>
        )}

        {/*------- Status section ---------- */}
        <div>
          <div
            className="flex gap-2 cursor-pointer"
            onClick={() => handleToggle("status")}
          >
            <p className="font-bold text-sm">Status</p>
            <svg
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              className={`w-5 h-5 transition-transform duration-200 ease-in-out ${
                openKeys["status"] ? "transform rotate-180" : ""
              }`}
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          {openKeys["status"] && (
            <div className="pl-3 flex flex-col gap-2 mt-2">
              <p
                className={`font-bold text-xs cursor-pointer ${
                  selectedStatus === "" ? "text-black" : "text-gray-600"
                } hover:text-black`}
                onClick={() => handleChangeStatus("")}
              >
                All ({totalCount})
              </p>
              {filterColData?.map((status) => (
                <p
                  key={status}
                  className={`font-bold text-xs cursor-pointer hover:text-black ${
                    selectedStatus === status ? "text-black" : "text-gray-600"
                  }`}
                  onClick={() => handleChangeStatus(status)}
                >
                  {status.charAt(0).toUpperCase() +
                    status.slice(1).toLowerCase()}{" "}
                  ({convertedData[status] ?? 0})
                </p>
              ))}
            </div>
          )}
        </div>

        {/* ------- Rfq date range -----  */}
        <div>
          <div
            className="flex gap-2 cursor-pointer"
            onClick={() => handleToggle("dateRange")}
          >
            <p className="font-bold text-sm">RFQ Date Range</p>
            <svg
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              className={`w-5 h-5 transition-transform duration-200 ease-in-out ${
                openKeys["dateRange"] ? "transform rotate-180" : ""
              }`}
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          {openKeys["dateRange"] && (
            <div className="pl-3">
              <div className="mt-2">
                <p className="font-bold mb-1 text-xs">Create Date</p>
                <DateRangePicker
                  style={"bg-gray-200 text-black"}
                  title="Create Date"
                  setDateFilterName={setDateFilterName}
                  handleApplyFilterDate={handleDateChange}
                  startDate={rfqsData?.startDate}
                  endDate={rfqsData?.endDate}
                />
              </div>
              <div className="mt-2">
                <p className="font-bold text-xs mb-1">Close Date</p>
                <DateRangePicker
                  style={"bg-gray-200 text-black"}
                  title="Close Date"
                  setDateFilterName={setCloseDateFilterName}
                  handleApplyFilterDate={handleCloseDateChange}
                  startDate={rfqsData?.startDateOfClosing}
                  endDate={rfqsData?.endDateOfClosing}
                />
              </div>
            </div>
          )}
        </div>

        {/* Team */}
        {workspaceView === "Team Workspace" && (
          <div>
            <div
              className="flex gap-2 cursor-pointer"
              onClick={() => handleToggle("team")}
            >
              <p className="font-bold text-sm">Team</p>
              <svg
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                className={`w-5 h-5 transition-transform duration-200 ease-in-out ${
                  openKeys["team"] ? "transform rotate-180" : ""
                }`}
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            {openKeys["team"] && (
              <div className="pl-3">
                <label className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-1">
                  <input
                    type="checkbox"
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={selectedUserIds?.includes(user?.userId)}
                    onChange={(e) => handleTeamInputChange(e, user?.userId)}
                  />
                  <p
                    title={user?.name}
                    className="text-xs w-28 truncate"
                  >
                    {user?.name}
                  </p>
                </label>
                {user?.teamMembers?.map((data, index) => (
                  <div>
                    <label
                      key={index}
                      className="flex cursor-pointer text-xs items-center hover:bg-blue-100 space-x-2 p-1"
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={selectedUserIds?.includes(data?.userId)}
                        onChange={(e) => handleTeamInputChange(e, data?.userId)}
                      />
                      <p
                        title={data?.name}
                        className=" w-28 truncate "
                      >
                        {data?.name}
                      </p>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSection;
