/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  // SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE,
  SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
  SIGNOUT,
  SILENT_LOGIN,
  SIGNIN_SEND_MOBILE_OTP,
  SIGNIN_SEND_MOBILE_OTP_SUCCESS,
  SIGNIN_SEND_MOBILE_OTP_FAILURE,
  SIGNIN_WITH_MOBILE_OTP,
  SIGNIN_WITH_MOBILE_OTP_FAILURE,
  SIGNIN_WITH_MOBILE_OTP_SUCCESS,
  REGISTER_USER_MOBILE,
  REGISTER_USER_MOBILE_SUCCESS,
  REGISTER_USER_MOBILE_FAILURE,
  VERIFY_REGISTER_OTP,
  VERIFY_REGISTER_OTP_SUCCESS,
  VERIFY_REGISTER_OTP_FAILURE,
  WHATSAPP_MOBILE_OTP_SEND_SUCCESS,
  WHATSAPP_MOBILE_OTP_SEND_FAILURE,
  VERIFY_WHATSAPP_OTP_SUCCESS,
  VERIFY_WHATSAPP_OTP_FAILURE,
  WHATSAPP_MOBILE_LOGIN_OTP_SEND_SUCCESS,
  SWITCH_USER_TYPE,
} from 'actions/authActions';

import {
  UPDATE_USER_PROFILE_SUCCESS,
  REGISTER_USER_SUCCESS
} from 'actions/registerActions';

import { SAVE_ROUTER_URL } from "actions/businessActions";
const initialState = {
  user: null,
  newUserInfo: null,
  signinMobile: null || localStorage.getItem("mobile"),
  signUpRegisterName: "",
  signUpRegisterMobile: null,
  userRegisterOrNot: null,
  latestUrl: "/",
  whatsAppOtpMsg: "",
  isWhatsAppOtpVerified: undefined,
  isWhatsappNoVerified: false,
  switchUserType: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {


    case SIGNIN_SEND_MOBILE_OTP: {
      const { mobile } = action.payload;

      // console.log("Reducer: SIGNUP_SEND_MOBILE_OTP_SUCCESS, mobile ", mobile);

      return produce(state, (draft) => {
        draft.signinMobile = mobile;
        localStorage.setItem("mobile", mobile)
      });
    }
      break;

    case VERIFY_REGISTER_OTP_SUCCESS:
    case SIGNIN_SUCCESS:
    case SIGNIN_WITH_MOBILE_OTP_SUCCESS:
    case SILENT_LOGIN:
    case UPDATE_USER_PROFILE_SUCCESS:
    case REGISTER_USER_SUCCESS: {
      const { user } = action.payload;
      const { isWhatsappNo } = action.payload
      // console.log("authReducer: SIGNIN_SUCCESS ", action.type, user)

      return produce(state, (draft) => {
        draft.user = user;
        draft.switchUserType = user?.userType;
        if (isWhatsappNo) {
          draft.isWhatsappNoVerified = true
        }
      });
    }
      break;

    case SIGNIN:
    case SIGNIN_WITH_MOBILE_OTP: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }
      break;

    case REGISTER_USER_MOBILE_SUCCESS: {
      return produce(state, (draft) => {
        draft.userRegisterOrNot = action.payload.user.isUserRegistered;
      });
    }
      break;

    case SIGNIN_FAILURE:
    case SIGNIN_WITH_MOBILE_OTP_FAILURE:
    case REGISTER_USER_MOBILE_FAILURE: {
      return produce(state, (draft) => {
        draft.user = null;
        // Maybe store error
      });
    }
      break;

    case SIGNOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.switchUserType = null;
        draft.newUserInfo = null;
        draft.signinMobil = null;
        draft.signUpRegisterName = null;
        draft.signUpRegisterMobile = null;
      });
    }
      break;

    case REGISTER_USER_MOBILE: {
      const { name, mobile } = action.payload;
      //console.log("Reducer: SIGNUP_SEND_MOBILE_OTP_SUCCESS, mobile ", mobile);
      return produce(state, (draft) => {
        draft.signUpRegisterName = name;
        draft.signUpRegisterMobile = mobile;

        draft.user = null;


        // console.log("mobile number =>>>>>>>>.",mobile)
      });
    }
      break;
    case SAVE_ROUTER_URL:
      {
        return produce(state, (draft) => {
          draft.latestUrl = action.payload.url;
        });
      }
      break;
    case WHATSAPP_MOBILE_LOGIN_OTP_SEND_SUCCESS: {
      return produce(state, (draft) => {
        draft.userRegisterOrNot = action.payload.message.isUserRegistered;
        draft.whatsAppOtpMsg = action.payload;
        draft.isWhatsAppOtpVerified = action.payload.error;
      });
    }
      break;

    case WHATSAPP_MOBILE_OTP_SEND_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.whatsAppOtpMsg = action.payload;
          draft.isWhatsAppOtpVerified = action.payload.error;
        });
      }
      break;
    case VERIFY_WHATSAPP_OTP_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.whatsAppOtpMsg = action.payload;
          draft.isWhatsAppOtpVerified = action.payload.error;
          draft.isWhatsappNoVerified = true
        });
      }
      break;
    case WHATSAPP_MOBILE_OTP_SEND_FAILURE:
    case VERIFY_WHATSAPP_OTP_FAILURE:
      {
        return produce(state, (draft) => {
          draft.whatsAppOtpMsg = action.payload;
          draft.isWhatsAppOtpVerified = action.payload.error;
          draft.isWhatsappNoVerified = false
        });
      }
      break;
    case SWITCH_USER_TYPE: {
      return produce(state, (draft) => {
        draft.switchUserType = action.userType;
      })
    }
    default: {
      return state;
    }
      break;
  }
};

export default authReducer;
