import React from 'react'
import MobileAwardsCertification from './MobileAwardsCertification';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Slider from 'react-slick';

function AwardsCertificate({ profile, isMobileView }) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const certificate = profile?.businessProfile?.certificateImages;
  const awards = profile?.awards;
  const awardsCertificate = [
    ...(awards || []).map((data) => ({
      imgUrl: data?.awardImage,
      title: data?.awardTitle,
    })),
    ...(certificate || []).map((data) => ({
      imgUrl: data?.certificateImage,
      title: data?.certificateTitle,
    })),
  ]; // add two arry of obj(certificate and awards) in one

  return (

    <Grid style={{ maxWidth: "100%", padding: "10px" }}>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          marginY: "26px",
        }}
      >
        Awards & Certification
      </Typography>
      {awardsCertificate?.length > 1 ? (
        <Slider {...settings} >
          {awardsCertificate?.map((data) => (
            <MobileAwardsCertification data={data} />
          ))}
        </Slider>
      ) : (
        <Grid display={"flex"} gap={"10px"} >
          {awardsCertificate?.map((data) => (
            <MobileAwardsCertification data={data} />
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default AwardsCertificate