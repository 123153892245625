import React, { useEffect, useRef } from "react";

const Select = ({
  options,
  onChange,
  className,
  placeholder = "",
  selectedKeys = [],
  selectedValues = [],
  disabled = false,
  hasCheckbox = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = useRef(null);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionChange = (optionKey, optionValue) => {
    onChange(optionKey, optionValue);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={`relative border border-gray-300 rounded-md p-2 ${className} ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      ref={ref}
    >
      <div
        onClick={toggleDropdown}
        className="flex justify-between items-center"
      >
        {selectedValues.length > 0 ? selectedValues.join(", ") : placeholder}
        <svg
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          className={`w-6 h-6 transition-transform duration-200 ease-in-out ${
            isOpen ? "transform rotate-180" : ""
          }`}
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
      {isOpen && (
        <div
          className="dropdown mt-2 fixed bg-white z-50 w-full p-2 border border-gray-300 rounded-md max-h-24 overflow-y-auto shadow-md"
          style={{
            top: ref.current.getBoundingClientRect().bottom,
            left: ref.current.getBoundingClientRect().left,
            width: ref.current.getBoundingClientRect().width,
          }}
        >
          {options.map((option) => (
            <div key={option.key} className="dropdown-option">
              {hasCheckbox ? (
                <>
                  <input
                    id={`multi-select-checkbox-${option.key}`}
                    type="checkbox"
                    checked={selectedKeys.includes(option.key)}
                    onChange={() =>
                      handleOptionChange(option.key, option.value)
                    }
                    className="mr-2"
                    disabled={disabled}
                  />
                  <label htmlFor={`multi-select-checkbox-${option.key}`}>
                    {option.value?.businessName}
                  </label>
                </>
              ) : (
                <div
                  onClick={() => {
                    handleOptionChange(option.key, option.value);
                    setIsOpen(false);
                  }}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                >
                  {option.value}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
