import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
  Link,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useFormik } from "formik";
import AddAwardsForm from "./AddAwardsForm";
import { useNavigate } from "react-router";
// import CertificateCard from "./CertificateCard";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";

import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
const AddAwards = ({
  supplierId,
  title,
  isPublicProfile,
  permissions,
  isAwardExists,
  handleSubmit,
  onSubmitSuccess,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1024));
  const isSm = useMediaQuery(theme.breakpoints.down(769));
  const handleAddService = () => {
    // console.log("handleAddService");
    // dispatch(addServiceTrigger);
  };
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    console.log("!permissions?.isUpdateAllowed",!permissions?.isUpdateAllowed )
    console.log("permissions?.isOwner",permissions?.isOwner )
    if (permissions?.isUpdateAllowed && permissions?.isOwner ) {
      
        setOpen(true);
      }  else {
      navigate("/subscribe-plan");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    navigate("/my-business");
  };

  const handlePlanUpgrade = () => {
    navigate("/subscribe-plan");
  };

  return (
    <Grid container mt={2} ml={0}>
      <Grid item md={12} sm={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item sm={12}>
              {((isPublicProfile ) || !isPublicProfile) && (
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="businessTitle"
                    sx={{ marginLeft: isSm && "28px" }}
                   
                  >
                    AWARDS
                  </Typography>
                  {!isPublicProfile && (
                    <CardMedia
                      component="img"
                      image={DEFAULT_IMAGES.PREMIUM_BADGE}
                      height="20px"
                      sx={{ width: "25px", ml: 1, mt: -1 }}
                    />
                  )}
                </Box>
              )}
            </Grid>
            <Grid item sm={12} xs={12}>
              {permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed && (
                  <Typography
                    color="secondary"
                    // size="small"
                    disabled={!(permissions && permissions.isUpdateAllowed)}
                    sx={{ fontSize: "15px", cursor: "pointer" }}
                    // endIcon={<AddCircleRoundedIcon />}
                    onClick={handleClickOpen}
                  >
                    <Link
                      sx={{
                        textDecoration: "underline",
                        color: "#2864BE",
                        marginLeft: isSm && "28px",
                      }}
                    >
                      {" "}
                      Click Here{" "}
                    </Link>
                    <span style={{ color: "black" }}> to upload awards</span>
                  </Typography>
                )}
            </Grid>

         
          </Grid>

          <AddAwardsForm
            open={open}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            onSubmitSuccess={handleSubmitSuccess}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddAwards;
