import {
  Alert,
  Button,
  Dialog,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { QuillEditor } from "components";
import CustomToolbar from "components/QuillCustomTollBar";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AttachmentChipSection,
  AttachmentSendRfqSection,
  DearTeamTemplateSection,
  DescriptionSection,
  RfqCcSection,
  RfqTypeSec,
  VendorListSection,
} from "./sections";
import { useState } from "react";
import { sendNewEnquiry } from "actions/requirementsAction";
import axios from "../../utils/axios";
import { useRef } from "react";
import RfqSendSuccessPopUp from "./RfqSendSuccessPopUp";
import RfqToCcSectionForNonEntity from "./sections/RfqCcSectionForNonEntity";
import VendorListSectionForNonEntity from "./sections/VendorListSectionForNonEntity";
const NonEntityRequirmentForm = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const requirement = useSelector((state) => state.requirement);
  const business = useSelector((state) => state.business);
  const scrollRfqFormRef = useRef(null);
  const subscriptionType = useSelector(
    (state) => state?.business?.profile?.subscriptionType
  );
  const [formData, setFormData] = useState({
    startDate: Date(Date.now()),
    creditPeriod: "",
    location: "",
    name: "",
    description: "",
    siteEngineerContact: "",
    siteEngineerName: "",
    projectName: "",
  });

  const [selectedFilesBase, setSelectedFilesBase] = useState([]);
  const [newCcEmails, setNewCcEmails] = useState([]);
  const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
  const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
  const [openRfqSendSuccessPopUp, setOpenRfqSendSuccessPopUp] = useState(false);
  const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState([]);
  const [rfqTypeValue, setRfqTypeValue] = useState();
  const [estimatedvalue, setEstimatedvalue] = useState();

  const isSmallScreen = useMediaQuery("(max-width:786px)");
  const usersToShowEstimate = [
    9611, 9609, 12125, 98, 12255, 12903, 126, 89, 90, 96, 95, 97, 8874, 9591,
    13474, 12259, 13161, 91, 92, 8873, 13188, 5145,
  ];
  const [isVasconUser, setIsVasconUser] = useState();
  const supplierId = business?.profile?.supplierId;
  const enquiry = requirement?.enquiryToSend;
  const entityId = userProfile?.userEntity?.entityId;
  const userCompany = userProfile?.company;
  const userName = userProfile?.name;
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 mb in bytes
  const isEmailAuthorized = userProfile?.isEmailAuthorized
    ? userProfile?.isEmailAuthorized
    : false;
  const businessName = business?.profile?.businessName;

  const [enquiryState, setEnquiryState] = useState(enquiry);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOnBlur = (keyName, value) => {
    setFormData((preVal) => ({ ...preVal, [keyName]: value }));
  };

  const handleDeleteSelectedFile = (name, event) => {
    event.preventDefault(); // use becuse when we click on chip dont one link
    setSelectedFilesBase((preVal) =>
      preVal.filter((data) => data.Name !== name)
    );
    setSelectedFiles((preVal) => preVal.filter((data) => data.Name !== name));
    setSelectedFilesTotalSize((preVal) =>
      preVal.filter((data) => data.Name !== name)
    ); // this is use when some file are delet then the data also delet from this array becase of change in total size of selected  file
  };

  const handleFileUploadClick = (event) => {
    return (event.target.value = "");
  };
  const handleCloseFileSizeError = () => {
    setOpenFilesSizeError(false);
  };
  const handleFileUpload = (event) => {
    scrollRfqFormRef.current.scrollTo({ bottom: 0, behavior: "smooth" });
    const files = event.target.files;
    // count of current seleted files
    const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => {
      return acc + obj.size;
    }, 0);

    // count of prev selected and current selected files
    const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce((acc, obj) => {
      return acc + obj.FileSize;
    }, sizeOfSelectedFiles);
    // Validate file sizes and perform file upload as needed
    if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
      setAttachmentsSkeleton((preVal) => [...preVal, ...Array.from(files)]);

      const convertedFilesPromises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);

          const reader = new FileReader();

          reader.onload = (e) => {
            const fileContent = e.target.result;
            const encryptedContent = btoa(fileContent); // Encrypt file content in Base64

            // Fallback mechanism for .dwg files
            let contentType = file.type;
            if (!contentType && file.name.endsWith(".dwg")) {
              contentType = "application/acad"; // Common MIME type for DWG files
            }

            // Include fileType, fileName, and encryptedContent in your request payload
            const payload = {
              Name: file.name,
              Content: encryptedContent,
              ContentType: contentType,
              FileSize: file.size,
            };
            resolve(payload); // Resolve the promise with the payload for demonstration purposes
          };

          reader.readAsBinaryString(file);
        });
      });

      const attachmentBlobLink = (convertedFiles) => {
        axios
          .post("/api/rfqs/upload/attachments", convertedFiles)
          .then((response) => {
            setSelectedFilesBase((preVal) => [...preVal, ...response?.data]);
            setSelectedFiles((preVal) => [...preVal, ...response?.data]);
            setSelectedFilesTotalSize((preVal) => [
              ...preVal,
              ...convertedFiles,
            ]);
            setAttachmentsSkeleton([]);
          })
          .catch((error) => {
            setAttachmentsSkeleton([]);
            setAttachmentsUploadsErrors(error.message);
            setTimeout(() => {
              setAttachmentsUploadsErrors("");
            }, 5000);
            console.error(error);
          });
      };

      Promise.all(convertedFilesPromises)
        .then((convertedFiles) => {
          attachmentBlobLink(convertedFiles);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setOpenFilesSizeError(true);
    }
  };

  useEffect(() => {
    setEnquiryState(() =>
      enquiry?.map((item) => ({
        ...item,
        emails: item.emails || [item.businessEmail],
      }))
    );
    setFormData({
      startDate: Date(Date.now()),
      creditPeriod: "",
      location: "",
      name: "",
      description: "",
      siteEngineerContact: "",
      siteEngineerName: "",
      projectName: "",
      requestedBy: userProfile?.email,
      requesterMobile: userProfile?.mobile,
    });
    setSelectedFilesBase([]);
    setNewCcEmails([]);
    setSelectedFiles([]);
    setSelectedFilesTotalSize([]);
    setRfqTypeValue();
    setEstimatedvalue("");
    setIsVasconUser(usersToShowEstimate.includes(userProfile?.userId));
  }, [enquiry]);

  return (
    <>
      <Dialog
        fullScreen={isSmallScreen}
        // fullWidth={true}
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
      >
        <Grid
          sx={{
            // backgroundColor: "#FBF8F6",
            display: "flex",
            position: "sticky",
            top: 0,
            zIndex: 10,
            justifyContent: "space-between",
            padding: "10px 14px 10px 27px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ color: "#111827", fontFamily: "PP Telegraf" }}
          ></Typography>
          <Grid sx={{ cursor: "pointer" }} onClick={handleClose}>
            x
          </Grid>
        </Grid>

        <Grid
          ref={scrollRfqFormRef}
          sx={{ padding: isSmallScreen ? "10px" : "20px 28px " }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              isEntityUser: false,
              name: formData.name,
              description: formData.description,
              requestedBy: userProfile?.email,
              requesterMobile: userProfile?.mobile,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(99, "Subject cannot exceed 100 characters")
                .required("Subject is required"),
              description: Yup.string().required("Description is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(
                  sendNewEnquiry(
                    supplierId,
                    values,
                    enquiryState,
                    userCompany,
                    userName,
                    selectedFilesBase,
                    newCcEmails,
                    userProfile?.userId,
                    subscriptionType
                  )
                );
                handleClose();
                setOpenRfqSendSuccessPopUp(true);
              } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <VendorListSectionForNonEntity
                    entityId={entityId}
                    enquiryState={enquiryState}
                    setEnquiryState={setEnquiryState}
                    isSmallScreen={isSmallScreen}
                  />
                  <RfqToCcSectionForNonEntity
                    entityId={entityId}
                    businessName={businessName}
                    isSmallScreen={isSmallScreen}
                    newCcEmails={newCcEmails}
                    setNewCcEmails={setNewCcEmails}
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleOnBlur}
                  />
                  <DescriptionSection
                    entityId={entityId}
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleOnBlur={handleOnBlur}
                  />

                  <AttachmentChipSection
                    entityId={entityId}
                    isSmallScreen={isSmallScreen}
                    attachmentsSkeleton={attachmentsSkeleton}
                    selectedFiles={selectedFiles}
                    handleDeleteSelectedFile={handleDeleteSelectedFile}
                  />

                  <AttachmentSendRfqSection
                    entityId={entityId}
                    isSmallScreen={isSmallScreen}
                    isSubmitting={isSubmitting}
                    attachmentsUploadsErrors={attachmentsUploadsErrors}
                    errors={errors}
                    handleFileUploadClick={handleFileUploadClick}
                    handleFileUpload={handleFileUpload}
                    attachmentsSkeleton={attachmentsSkeleton}
                    handleSubmit={handleSubmit}
                    values={values}
                  />
                </form>
              );
            }}
          </Formik>
        </Grid>
        <Snackbar
          open={openFilesSizeError}
          autoHideDuration={6000}
          onClose={handleCloseFileSizeError}
        >
          <Alert
            onClose={handleCloseFileSizeError}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Total size of attachments is not more than 10 MB
          </Alert>
        </Snackbar>
      </Dialog>
      <RfqSendSuccessPopUp
        isSmallScreen={isSmallScreen}
        openRfqSendSuccessPopUp={openRfqSendSuccessPopUp}
        setOpenRfqSendSuccessPopUp={setOpenRfqSendSuccessPopUp}
      />
    </>
  );
};

export default NonEntityRequirmentForm;
