import StaticPopup from 'components/StaticPopup';
import React, { useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from "formik";
import CustomInput from "./CustomInput";
import AddIndividual from './AddIndividual';
import StaticPopupNew from 'components/StaticPopupNew';
import * as XLSX from 'xlsx';
const downloatTemplate = "https://storagereponeevaydevcdn.blob.core.windows.net/business/materialBulkUpload.xlsx"
const RfqTypeMaterial = () => {

    const { values, setFieldValue, errors } = useFormikContext();
    const [openAddMaterialPopUp, setOpenAddMaterialPopUp] = useState(false);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState('');
    const [showAddOptions, setShowAddOptions] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [products, setProducts] = useState(values?.materials);
    const [expandedProducts, setExpandedProducts] = useState({});
    const [handleSpecPopup, setHandleSpecPopup] = useState(false);
    const [openSpecsPopup, setOpenSpecsPopup] = useState(false);
    const [selectedSpecs, setSelectedSpecs] = useState('');

    const handleSpecsButtonClick = (specifications) => {
        if (specifications) {
            setSelectedSpecs(specifications);
            setOpenSpecsPopup(true);
        } else {
            console.log("No specifications available for this product.");
        }
    };

    const closeSpecsPopup = () => {
        setOpenSpecsPopup(false);
    };

    const SpecsPopup = () => {
        setHandleSpecPopup(!handleSpecPopup);
    }

    const otherCharges = [
        { label: "Transport", key: "transport" },
        { label: "Loading / Offloading", key: "loadingOffloading" },
        { label: "Mathade Charges", key: "mathadeCharges" },
        { label: "Misc. Charges", key: "miscCharges" }
    ];

    const handleAddMaterialForm = () => {
        setOpenAddMaterialPopUp(true);
    }

    const handleBulkUpload = () => {
        setOpenUploadFile(!openUploadFile)
    }

    const validateExcel = (sheetData) => {
        // Ensure the first row has the expected columns
        const expectedHeaders = ['name', 'quantity', 'unit', 'brands', 'specifications'];
        const fileHeaders = sheetData[0]?.map(header => header.toLowerCase().trim());

        return expectedHeaders.every((header, index) => fileHeaders[index] === header);
    };

    const mapSheetDataToMaterials = (sheetData) => {
        // Skip the header row and map data rows to material structure
        return sheetData.slice(1).map(row => ({
            name: row[0] || '',
            quantity: row[1] || '',
            unit: row[2] || '',
            brands: row[3] ? row[3].split(',').map(brand => brand.trim()) : [],
            specifications: row[4] || ''
        }));
    };

    const handleFileUploadMaterial = (e) => {
        const file = e.target.files[0];
        setFile(file); // Set the file in state
        if (file) {
            console.log("File selected for materials upload:", file); // Log file info
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                console.log("Parsed Sheet Data for materials:", sheetData);

                if (validateExcel(sheetData)) {
                    const materials = mapSheetDataToMaterials(sheetData);
                    // Update the Formik values with new materials data
                    setFieldValue('materials', [...values.materials, ...materials]);
                    setFileError(''); // Clear any file format errors
                    setOpenUploadFile(false); // Close the upload modal
                } else {
                    setFileError('Invalid file format. Please upload the correct format.');
                }
            };
            reader.readAsArrayBuffer(file); // Trigger file reading
        }
        setShowAddOptions(false)
    };



    const handleCancel = (productIndex, brand) => {
        setProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                brands: updatedProducts[productIndex].brands.filter(b => b !== brand),
            };

            // Also update the Formik state
            setFieldValue(`materials`, updatedProducts);

            return updatedProducts;
        });
    };


    const toggleExpand = (productIndex) => {
        setExpandedProducts(prevState => ({
            ...prevState,
            [productIndex]: !prevState[productIndex],
        }));
    };

    const handleEdit = (productIndex) => {
        setEditIndex(productIndex);
        setOpenAddMaterialPopUp(true);
    };

    const handleRemove = (productIndex) => {
        const updatedProducts = products.filter((_, index) => index !== productIndex);
        if (updatedProducts.length === 0) {

            updatedProducts.push({
                name: '',
                quantity: '',
                unit: '',
                brands: [],
                specifications: '',
            });
        }
        setProducts(updatedProducts);
        setFieldValue('materials', updatedProducts);
    };

    const handleRadioChange = (key, value) => {
        setFieldValue(`otherCharges.${key}`, value); // Use Formik's setFieldValue to update otherCharges
    };


    useEffect(() => {
        setProducts(values?.materials || []);
    }, [values?.materials]);

    return (
        <div>
            {/* material */}
            <div>
                <div>
                    {/* add material */}
                    <div className='relative'>
                        <div className="flex w-fit justify-start items-center font-medium my-3 gap-5 ">
                            Material List
                            {/* <button
                                type='button'
                                onClick={() => setShowAddOptions(!showAddOptions)}
                                className="cursor-pointer h-[30px] hover:bg-gray-100 w-[30px] font-normal flex justify-center items-center border text-bese text-[#4F4F42]"
                            >
                                {showAddOptions ? '-' : '+'}
                            </button> */}
                        </div>

                        {/* Add Material Dropdown */}
                        {/* {showAddOptions && ( */}
                        <div className="flex justify-between items-center space-x-4 bg-white text-sm mt-1 mb-4">
                            <button type='button' className="hover:bg-gray-100 border-black  border p-2 w-full flex items-center text-left " onClick={handleAddMaterialForm}>
                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_add_indivisual.svg" alt="" className='px-2 mr-2' />
                                Add Material Items <br /> (Manually)
                            </button>
                            <button type='button' className="hover:bg-gray-100 border-black  border p-2 w-full flex items-center text-left" onClick={handleBulkUpload}>
                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_bulk_upload.svg" alt="" className="px-2 mr-2" />
                                Add Material Items <br />(Excel Upload)
                            </button>
                        </div>
                        {/* )} */}
                    </div>

                    <div>
                     {/* Validation errors for materials and labourDetails */}
                     {errors.materials  && (
                      <div className="text-red-500 text-xs">
                        At least one material item is required
                      </div>
                    )}
                  </div>


                    {/* table */}
                    {values?.materials?.some(material => material.name || material.quantity || material.brands.length > 0 || material.specifications) && <div className='mb-6 border border-black'>
                        <div className='grid grid-cols-7 gap-2 p-4 text-xs font-medium text-[#727269] bg-[#DDDDD3]'>
                            <p className='col-span-2'>Product</p>
                            <p>Quantity</p>
                            <p className='col-span-2 text-center'>Brand</p>
                            <p className='text-left col-span-2'>Specifications</p>
                            <p></p>
                        </div>
                        <div className='max-h-[200px] overflow-y-auto'>
                            {products.map((product, productIndex) => {
                                const isExpanded = expandedProducts[productIndex];
                                const displayedBrands = product.brands.length <= 2 || isExpanded ? product.brands : [product.brands[0]];
                                const hiddenCount = product.brands.length - 1;

                                return (
                                    <div key={productIndex} className={`grid grid-cols-7 gap-2 text-sm font-normal p-4 ${productIndex % 2 === 0 ? 'bg-[#FBFBF9]' : ''}`}>
                                        <p className='col-span-2 text-xs truncate'>{productIndex + 1}. {product?.name}</p>
                                        <p className='text-xs col-span-1 truncate'>{product?.quantity} {product?.unit}</p>
                                        {/* <div className='col-span-2 flex items-center justify-center flex-wrap'>
                                            {displayedBrands.map((brand, brandIndex) => (
                                                <span key={brandIndex} className='flex items-center justify-between px-2 py-1 mr-2 mb-2 w-fit text-xs font-normal bg-[#F5F5F3] rounded-full' >
                                                    {brand}
                                                    <button type='button' onClick={() => handleCancel(productIndex, brand)} className='ml-1'>
                                                        &times;
                                                    </button>
                                                </span> 
                                            ))}
                                            {product?.brands?.length > 2 && !isExpanded && (
                                                <button onClick={() => toggleExpand(productIndex)} className='ml-2 text-xs font-normal underline'>
                                                    {`+${hiddenCount} more`}
                                                </button>
                                            )}
                                        </div> */}
                                        <p className='text-xs col-span-2 text-center truncate'>{product?.brands[0]}</p>
                                        {/* specification button */}
                                        <button type='button' className='col-span-1 mx-auto' onClick={() => handleSpecsButtonClick(product?.specifications)}>
                                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/comment_create_rfq.svg" alt="" />
                                        </button>
                                        <div className='col-span-1 flex justify-end space-x-4'>
                                            {/* edit button */}
                                            <button type='button' onClick={() => handleEdit(productIndex)}>
                                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/pencil_gray_create_rfq.svg" alt="" />
                                            </button>
                                            {/* remove */}
                                            <button type='button' onClick={() => handleRemove(productIndex)}>
                                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/close_create_rfq.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}

                            <StaticPopup
                                openModal={openSpecsPopup}
                                handleClose={closeSpecsPopup}
                                width={"2xl"}
                                bodyStyle="pb-0 pt-0"
                                className="px-0"
                                header={"Specifications"}>
                                {/* specification content */}
                                <div className='py-8'>
                                    <p>{selectedSpecs}</p>
                                </div>
                            </StaticPopup>
                        </div>
                    </div>}

                    {/* miscellaneous otherCharges */}
                    <div>
                        <div>
                            <p className="text-base font-medium ">Other Charges</p>
                            <p className="text-[#8C8C8C] text-[10px]">Select whether each extra cost is needed for the bid. Vendors will fill them in when sending their offers.</p>
                        </div>

                        <div className="pt-4 pb-0">
                            {otherCharges.map((charge) => (
                                <div key={charge.key} className="mb-4 flex items-center justify-between text-sm">
                                    {/* Label for Charge */}
                                    <span className="font-medium text-[#434343] text-sm">
                                        {charge.label}
                                    </span>

                                    {/* Radio Buttons */}
                                    <div className="flex items-center space-x-6 ml-auto">
                                        {/* "Yes" option */}
                                        <label className="inline-flex items-center cursor-pointer text-sm">
                                            <input
                                                type="radio"
                                                name={`otherCharges.${charge.key}`}
                                                value="yes"
                                                className="h-3 w-3"
                                                checked={values.otherCharges[charge.key] === true}
                                                onChange={() => handleRadioChange(charge.key, true)}
                                            />
                                            {/* <span className="w-4 h-4 border border-gray-400 rounded-full peer-checked:bg-lime-600 peer-checked:border-lime-600 peer-focus:ring-0"></span> */}
                                            <span className="ml-2 text-[#505050]">Yes</span>
                                        </label>

                                        {/* "No" option */}
                                        <label className="inline-flex items-center cursor-pointer">
                                            <input
                                                type="radio"
                                                name={`otherCharges.${charge.key}`}
                                                value="no"
                                                className="h-3 w-3"
                                                checked={values.otherCharges[charge.key] === false || values.otherCharges[charge.key] === undefined}
                                                onChange={() => handleRadioChange(charge.key, false)}
                                            />
                                            {/* <span className="w-4 h-4 border border-gray-400 rounded-full peer-checked:bg-lime-600 peer-checked:border-lime-600 peer-focus:ring-0"></span> */}
                                            <span className="ml-2 text-[#505050]">No</span>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <StaticPopupNew
                        openModal={openAddMaterialPopUp}
                        handleClose={() => { setEditIndex(null); setOpenAddMaterialPopUp(false); setShowAddOptions(false); }}
                        width={"4xl"}
                        height={80}
                        bodyStyle="pb-0 pt-0 px-0"
                        className="px-0 text-sm"
                        header={"Add Material"}>
                        <AddIndividual setOpenAddMaterialPopUp={setOpenAddMaterialPopUp} editIndex={editIndex} setEditIndex={setEditIndex} setShowAddOptions={setShowAddOptions} />
                    </StaticPopupNew>

                    <StaticPopup
                        openModal={openUploadFile}
                        handleClose={() => setOpenUploadFile(false)}
                        size={"sm"}
                        width={"xl"}
                        bodyStyle="pb-0 pt-0"
                        className="px-0"
                        header={
                            <div className='text-base'>
                                Upload Files
                            </div>
                        }>

                        <div className="flex flex-col justify-center items-center px-0">
                            <div className="w-[400px] h-[180px] my-4 flex flex-col justify-center items-center border-2 border-dashed border-[#004491] bg-[#F1F7FF]">
                                <input
                                    id="file-upload-material"
                                    type="file"
                                    className="hidden"
                                    onChange={handleFileUploadMaterial}
                                    accept=".xlsx, .xls"
                                />
                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" className='w-8 h-8 mb-2' />
                                <p className="text-sm whitespace-nowrap">
                                    Drag & Drop or
                                    <label htmlFor="file-upload-material" className="underline px-2 text-[#1D28FA] cursor-pointer">
                                        Choose File
                                    </label>
                                    to upload
                                </p>
                            </div>

                            {fileError && <div className="text-red-500 text-sm">{fileError}</div>}

                            <div>
                                <p className='text-sm text-center w-full font-normal text-[#777777]'>Don't have the template? </p>
                                <div className='flex items-center justify-center gap-2'>
                                    <a href={downloatTemplate} download className='text-sm text-center text-[#1D28FA] font-normal'>
                                        Download Here
                                    </a>
                                    <svg
                                        width="17"
                                        height="18"
                                        viewBox="0 0 17 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.5625 1.5625C9.5625 0.974805 9.0877 0.5 8.5 0.5C7.9123 0.5 7.4375 0.974805 7.4375 1.5625V9.6209L5.00039 7.18379C4.58535 6.76875 3.91133 6.76875 3.49629 7.18379C3.08125 7.59883 3.08125 8.27285 3.49629 8.68789L7.74629 12.9379C8.16133 13.3529 8.83535 13.3529 9.25039 12.9379L13.5004 8.68789C13.9154 8.27285 13.9154 7.59883 13.5004 7.18379C13.0854 6.76875 12.4113 6.76875 11.9963 7.18379L9.5625 9.6209V1.5625ZM2.125 12.1875C0.95293 12.1875 0 13.1404 0 14.3125V15.375C0 16.5471 0.95293 17.5 2.125 17.5H14.875C16.0471 17.5 17 16.5471 17 15.375V14.3125C17 13.1404 16.0471 12.1875 14.875 12.1875H11.5049L10.0008 13.6916C9.1707 14.5217 7.82598 14.5217 6.9959 13.6916L5.49512 12.1875H2.125ZM14.3438 14.0469C14.5551 14.0469 14.7578 14.1308 14.9072 14.2803C15.0567 14.4297 15.1406 14.6324 15.1406 14.8438C15.1406 15.0551 15.0567 15.2578 14.9072 15.4072C14.7578 15.5567 14.5551 15.6406 14.3438 15.6406C14.1324 15.6406 13.9297 15.5567 13.7803 15.4072C13.6308 15.2578 13.5469 15.0551 13.5469 14.8438C13.5469 14.6324 13.6308 14.4297 13.7803 14.2803C13.9297 14.1308 14.1324 14.0469 14.3438 14.0469Z"
                                            fill="blue"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-end px-0' style={{
                            boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1)', // Adjust the shadow as needed
                        }}>
                            <button className='w-[95px] h-[35px] text-sm mx-4 my-4 bg-black text-white' onClick={() => setOpenUploadFile(false)}>
                                Upload
                            </button>
                        </div>
                    </StaticPopup>
                </div>
            </div >
        </div >
    )
}

export default RfqTypeMaterial
