import { Grid, Typography } from "@mui/material";
import React from "react";

function ProfileDetailBox({ profile, isEditView }) {
  const businessProfile = profile?.businessProfile;
  const isISO = businessProfile?.certificates;
  // const maskNumber = (number) => {
  //   if (number) {
  //     const maskedNumber = number.substring(0, 2) + 'XXXXXXXX' + number.substring(number.length - 2);
  //     return maskedNumber;
  //   } else {
  //     return number
  //   }
  // }
  // const gstNumber = maskNumber(profile?.statutory?.gstin)
  const gstNumber = profile?.statutory?.gstin
  return (
    <Grid
      sx={{
        backgroundColor: "rgba(215, 221, 233, 0.3)",
        borderRadius: "10px",
        minwidth: "100%",
        paddingX: "24px",
        paddingY: "14px",
        marginX: "8px",
        marginTop: "16px",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          marginBottom: "22px",
        }}
      >
        <Grid xs={6} sx={{ display: "flex", gap: "8px" }}>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            Establishment in
          </Typography>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            :
          </Typography>

        </Grid>
        <Grid xs={6} style={{ display: "flex", alignItems: "flex-start" }}>
          <Typography
            style={{
              fontWeight: 600,
              textAlign: "start",
            }}
          >
            {businessProfile?.establishYear
              ? businessProfile?.establishYear
              : "None"}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          marginBottom: "22px",
        }}
      >
        <Grid xs={6} sx={{ display: "flex", gap: "35px" }}>
          <Typography
            style={{
              color: "#555",
              fontSize: "16px",
            }}
          >
            GST Number
          </Typography>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            :
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Typography style={{ fontWeight: 600 }}>
            {profile?.statutory?.gstin ? isEditView ? profile?.statutory?.gstin : gstNumber : "NA"}
          </Typography>
        </Grid>
      </Grid>
      
      <Grid
        container
        sx={{
          display: "flex",
          marginBottom: "22px",
        }}
      >
         <Grid xs={6} sx={{ display: "flex", gap: "10px" }}>
          <Typography
            style={{
              color: "#555",
              fontSize: "16px",
            }}
          >
            Annual Turnover
          </Typography>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            :
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Typography style={{ fontWeight: 600 }}>
          {
              profile?.annualTurnOver
                ? profile?.annualTurnOver
                : "Not disclosed"
            }
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          marginBottom: "22px",
        }}
      >
        <Grid xs={6} sx={{ display: "flex", gap: "21px" }}>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            Company Type
          </Typography>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            :
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Typography
            style={{
              //   marginTop: "14px",
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            {businessProfile?.businessType
              ? businessProfile.businessType
                .split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
        }}
      >
        <Grid xs={6} sx={{ display: "flex", gap: "10px" }}>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            ISO Certification
          </Typography>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            :
          </Typography>
        </Grid>
        <Grid xs={6}>
          {isISO?.length > 0 ? (
            <Typography
              style={{
                fontWeight: 600,
              }}
            >
              {isISO[0]}
            </Typography>
          ) : (
            <Typography
              style={{
                // textAlign: "center",
                // marginTop: "14px",
                fontWeight: 600,
              }}
            >
              None
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProfileDetailBox;
