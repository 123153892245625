import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { useTheme } from "@mui/material/styles";
import OtpInput from "react-otp-input";
import "./stylePopup.css";
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    Link,
    Typography,
    CircularProgress,
} from "@mui/material";
import { verifyRegisterOTP, registerUserWithMobile, sendwhatsAppOTP } from "actions/authActions";
import { SIGN_UP_COUNTER } from "config";
import { addLeadActivity } from "actions/crmAction";

function LeadsMobileVerification({
    isWhatsappNo,
    setOtpPopUp,
    setOpenLogInModal,
    className,
    mobile,
    ...rest
}) {
    const dispatch = useDispatch();
    const [counter, setCounter] = React.useState(SIGN_UP_COUNTER);
    const navigate = useNavigate();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"));

    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => {
            clearInterval(timer);
        };
    }, [counter]);

    const auth = useSelector((state) => state.auth);
    const isUserRegisterd = auth.userRegisterOrNot;

    const crmLeadId = useSelector((state) => state.crm);


    const onSubmitSuccess = () => {
        setOpenLogInModal(false);
        if (isUserRegisterd === false) {
            navigate("/register/user", { state: { "isWhatsappNo": isWhatsappNo } });
        } else {
            navigate(auth?.latestUrl);
        }
    };

    const handleChangeMobile = () => {
        setOtpPopUp(true);
    };

    const resendOTP = () => {
        try {
            const name = "";
            if (isWhatsappNo) {
                dispatch(sendwhatsAppOTP(mobile))
            } else {
                dispatch(registerUserWithMobile(name, mobile));
            }
            setCounter(SIGN_UP_COUNTER);
        } catch (error) {
            // console.log("resendOTP: error", error);
        }
    };

    const [otp1, setOTP1] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const otpHandleChange = (otp1, formikSubmit) => {
        setOTP1(otp1);
        if (otp1.length === 4) {
            setIsLoading(true);
            formikSubmit();
        }
    };

    const lastTwoDigits = mobile.slice(-2);

    return (
        <Formik
            initialValues={{}}
            validationSchema={Yup.object().shape({})}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    const name = "";
                    const sourceName = "web app";
                    await dispatch(
                        verifyRegisterOTP(mobile, name, otp1, crmLeadId.leadId, sourceName, isWhatsappNo, onSubmitSuccess)
                    );
                    setIsLoading(false);
                    // onSubmitSuccess();
                } catch (error) {
                    const message = error.message || "Something went wrong";
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setIsLoading(false);
                    setSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit} {...rest}>
                    <Grid
                        item
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                        xs={12}
                        md={12}
                        mt={10}
                        mb={10}
                        style={{maxWidth:"400px", margin:"auto", marginTop:"80px", marginBottom:"80px"}}
                    >
                        <Grid> 
                            {/* <Grid sx={{ display: "flex", justifyContent: "end" }}>
                                <CancelOutlined onClick={() => setOpenLogInModal(false)} style={{ cursor: "pointer", color: "#B9B9C3", textAlign: "end", marginRight: isMd ? "-3rem" : "-4.5rem", marginTop: isMd ? "-15px" : "-20px" }} />
                            </Grid> */}
                            <Grid display={"flex"} justifyContent={"center"}>
                                <Typography sx={{ fontWeight: 400, fontSize: "20px", textAlign: "center", marginBottom: "18px" }}>OTP Verification</Typography>
                            </Grid>
                            {/* <Typography sx={{ fontWeight: 400, fontSize: "16px", textAlign: "center", color: "#6B6464" }}>Please Enter the OTP Received at</Typography> */}
                            <Typography sx={{ fontWeight: 400, fontSize: "16px", textAlign: "center",  }}>
                                Please enter OTP sent on your registered number <span style={{fontWeight:"bold"}}>{mobile}</span>  to access the requirement.
                            </Typography>
                            <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
                                {/* <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>+91 XXXXXXXX<span style={{ fontSize: "20px", }}>{lastTwoDigits}</span></Typography> */}
                                {/* <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>{mobile}</Typography> */}
                                {/* <Typography
                                    sx={{ fontSize: "12px", fontWeight: 400, color: "#3963AB" }}
                                    onClick={handleChangeMobile}
                                    className="link_change_phone"
                                >
                                    Change Phone
                                </Typography> */}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            xl={12}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "19px"
                            }}
                        >
                            <span style={{ marginLeft: "22px" }}>
                                <OtpInput
                                    className="otpInputStyle"
                                    value={otp1}
                                    onChange={(otp) => otpHandleChange(otp, handleSubmit)}
                                    numInputs={4}
                                    separator={<span></span>}
                                    shouldAutoFocus
                                    isInputNum
                                    inputStyle={{ width: "2rem" }}
                                    onBlur={(e, index) => {
                                        const value = e.target.value;
                                        if (!/^\d$/.test(value)) {
                                            e.preventDefault();
                                            return;
                                        }
                                    }}
                                />
                            </span>
                        </Grid>

                        <Grid item xs={12}>
                            {errors.submit && (
                                <Box mt={3}>
                                    <FormHelperText error>{errors.submit == "Request failed with status code 401" ? "Please enter correct OTP" : errors.submit}</FormHelperText>
                                </Box>
                            )}
                        </Grid>
                        <Grid>
                            {isLoading && ( // Loader
                                <Box mt={3} display={"flex"} justifyContent={"center"} marginRight={2}>
                                    <CircularProgress size={20} />
                                </Box>
                            )}

                        </Grid>
                        <Grid>
                            {counter > 0 && (
                                <Box marginTop={"12px"}>
                                    <Typography
                                        sx={{ fontSize: "12px", fontWeight: 400, color: "#6B6464", textAlign: "center", marginTop: "18px" }}
                                    >
                                        You will receive OTP in{" "}
                                        <span style={{ color: "#6B6464", fontWeight: 400, fontSize: "12px", }}>
                                            {" "}
                                            {counter}
                                        </span>{" "}
                                        sec
                                    </Typography>
                                </Box>
                            )}
                            {counter <= 0 && (
                                <Box mt={3} display={"flex"}>
                                    <Link
                                        sx={{
                                            color: "#6B6464", fontSize: "15px", fontWeight: 400, textAlign: "center", "&:hover": {
                                                cursor: "pointer",
                                            }
                                        }}
                                        onClick={resendOTP}
                                    >
                                        Resend OTP
                                    </Link>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
}

LeadsMobileVerification.propTypes = {
    className: PropTypes.string,
    onSubmitSuccess: PropTypes.func,
    mobile: PropTypes.string,
};

LeadsMobileVerification.default = {
    onSubmitSuccess: () => { },
};

export default LeadsMobileVerification;
