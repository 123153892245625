/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LEADS_FETCH,
  LEADS_FETCH_SUCCESS,
  LEADS_FETCH_FAILURE
} from 'actions/leadsAction';

import { SIGNOUT } from 'actions/authActions';

const initialState = {
  leads: [],
  count: 0,
};

const LeadsReducer = (state = initialState, action) => {
  switch (action.type) {


    case LEADS_FETCH: {
      return produce(state, (draft) => {
        draft.leads = [];
        draft.count = 0;
        draft.loading = true
      });
    }
      break;

    case LEADS_FETCH_SUCCESS: {
      const { leads } = action.payload;
      // console.log("LeadsReducer: LEADS_FETCH_SUCCESS ", leads, action.payload);

      return produce(state, (draft) => {
        draft.leads = leads;
        draft.count = leads?.length;
        draft.loading = false
      });
    }
      break;

    case LEADS_FETCH_FAILURE: {
      return produce(state, (draft) => {
        draft.leads = [];
        draft.count = 0;
        draft.loading = false

      });
    }
      break;

    case SIGNOUT: {
      return produce(state, (draft) => {
        draft.leads = [];
        draft.count = 0;
      });
    }
      break;


    default: {
      return state;
    }
      break;
  }
};

export default LeadsReducer;
