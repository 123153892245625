import React from 'react';

const MaterialRFQTable = ({ rfqData, sortedSuppliers, withGst, openDropdown, handleMaterialDropdown, l1Data }) => (
    rfqData?.materials?.map((material, rowIndex) => (
        <tr key={rowIndex} className='bg-white text-xs'>
            <td className="bg-white z-10 w-fit sticky left-0 border border-gray-300 px-6" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}>
                <p className="text-xs text-black bg-white">
                    {material?.name}<br />
                    {material?.quantity} {material?.unit}
                </p>
            </td>
            {sortedSuppliers?.map((supplier, vendorIndex) => {
                const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                const vendorItem = vendorQuotation?.materialDetails?.[rowIndex] || {};
                const supplierPrice = vendorItem.basePrice;
                const isLowerThanL1 = l1Data && l1Data.materialDetails.some(item => item.name === vendorItem.name && supplierPrice < item.basePrice);

                return (
                    <td key={vendorIndex} className={`min-w-[300px] px-4 z-0 border border-gray-300 ${vendorIndex < rfqData?.suppliers?.length - 1 ? 'border-r' : ''}`}>
                        <div onClick={() => handleMaterialDropdown(rowIndex, vendorIndex)} className='relative cursor-pointer flex justify-between items-end py-1'>
                            <div>
                                {vendorItem.deliveryDate && rfqData.providingDate && (
                                    new Date(vendorItem.deliveryDate).getTime() !== new Date(rfqData.providingDate).getTime() && (
                                        <p className='text-xs text-[#DC7604]'>
                                            Proposed: {new Date(vendorItem.deliveryDate).toLocaleDateString('en-GB')}
                                        </p>
                                    )
                                )}
                                <p className='text-xs text-[#777777]'>{vendorItem.name}</p>
                                <div className='flex justify-start gap-4'>
                                    <p className={`text-xs text-[#777777] ${false ? 'bg-lime-200 px-1' : ''}`}> @ Rs. {vendorItem?.basePrice}</p>
                                    {withGst && <p className='text-xs text-[#777777]'> GST: {vendorItem.gst} %</p>}
                                </div>
                            </div>
                            <p className='text-xs font-normal text-[#2D2D11]'>
                                Rs. {withGst
                                    ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(vendorItem.netPrice ?? 0)
                                    : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(vendorItem?.priceWithoutGst ?? 0)}
                            </p>

                        </div>
                        {/* Dropdown for material */}
                        {(openDropdown.rowIndex === rowIndex && openDropdown.vendorIndex === vendorIndex) && (
                            <div className='absolute w-[270px] z-10 bg-white shadow-md border border-[#FFA653] p-0 h-fit whitespace-nowrap'>
                                <div className='p-2 space-y-2 text-xs'>
                                    <label className='flex items-center justify-between w-full'>
                                        Brand
                                        <div className='relative group ml-auto'>
                                            <p className='px-2 text-xs w-fit max-w-1/2 border-none truncate  bg-[#F0F0F0] text-[#404040] text-right cursor-default'>{vendorItem.brand || "NA"}</p>
                                            {vendorItem.brand && (
                                                <div className="absolute bottom-full mb-1 hidden group-hover:flex px-2 py-1 bg-[#f0f0f0] text-[#404040] text-xs rounded shadow-lg">
                                                    {vendorItem.brand}
                                                </div>
                                            )}
                                        </div>
                                    </label>

                                    <label className='flex items-center justify-between w-full'>
                                        <span>Quantity</span>
                                        <p className='px-2 text-xs border-none bg-[#F0F0F0] text-[#404040] text-right' >{vendorItem.quantity || "0"} </p>
                                    </label>
                                    <label className='flex items-center justify-between w-full'>
                                        <span>GST</span>
                                        <p className='px-2 text-xs border-none bg-[#F0F0F0] text-[#404040] text-right'>{vendorItem.gst || "0"} </p>
                                    </label>
                                    <label className='flex items-center justify-between w-full'>
                                        <span>Discount (%)</span>
                                        <p className='px-2 text-xs border-none bg-[#F0F0F0] text-[#404040] text-right'>{vendorItem.discount || "0"} </p>
                                    </label>
                                    <label className='flex items-center justify-between w-full'>
                                        <span>Total</span>
                                        <p className='px-2 text-xs border-none bg-[#F0F0F0] text-[#404040] text-right'>{vendorItem.netPrice || "0"} </p>
                                    </label>
                                </div>
                                <div className=' p-2'>
                                    <p className='text-[#434343]'>Remarks:</p>
                                    <p
                                        placeholder='Note'
                                        className='placeholder:italic break-words whitespace-normal max-h-[60px] overflow-y-auto text-xs cursor-default resize-none border-[#FFA653] border-dashed'
                                    >{vendorItem.remark || "NA"}
                                    </p>
                                </div>


                                {vendorItem.attachments && vendorItem.attachments.length > 0 && (
                                    <div className="p-2">
                                        <h3 className="text-xs font-medium text-[#434343]">Attachments</h3>
                                        <div className="space-y-2">
                                            {vendorItem.attachments.map((attachment, index) => {
                                                const lastIndex = attachment.Name.lastIndexOf('.'); // Extract file extension
                                                const path = lastIndex !== -1 ? attachment.Name.substring(lastIndex + 1) : null;
                                                return (
                                                    <div key={index} className="flex justify-between items-center mt-2">
                                                        <div className="px-1 flex items-center justify-center border border-gray-300 rounded-full py-1 space-x-1 text-[#434343]">
                                                            {/* Dynamic icon based on file type */}
                                                            <img src={`/assets/icons/${path}.png`} alt={path} className="h-5 w-5" />
                                                            {/* Document name now clickable */}
                                                            <a
                                                                href={attachment.Content}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                download={attachment.Name}
                                                                className="text-xs truncate text-[#434343] hover:underline"
                                                            >
                                                                {attachment.Name}
                                                            </a>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </td>
                );
            })}
        </tr>
    ))
);

export default MaterialRFQTable;
