import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Box,
  Button,
  FormHelperText,
  Divider,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  useMediaQuery,
  Alert,
  Snackbar,
  Skeleton,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "../../../utils/axios";

import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  addRequirements,
  updateRequirements,
  sendNewEnquiry,
  sendExistingEnquiry,
} from "actions/requirementsAction";

import { QuillEditor } from "components";
import CustomToolbar from "components/QuillCustomTollBar";
import { makeStyles } from "@mui/styles";
import { addLeadActivity } from "actions/crmAction";
import {
  CREATE_CRM_LEAD_URL,
  GET_CRM_LEAD_BY_PHONE_NUMBER,
  GET_USER_DATA_BY_ID,
} from "config/services";
import { useEffect } from "react";
import { updateCrmId } from "actions/authActions";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useTheme } from "@emotion/react";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 200,
    },
  },
}));

function RequirementForm({
  isNewRequirement,
  isNewRFQSend,
  isExistingRFQSend,
  enquiry,
  requirement,
  onSubmitSuccess,
  multipleOwnerId,
  ...rest
}) {
  const auth = useSelector((state) => state.auth);
  const subscriptionType = useSelector(
    (state) => state?.business?.profile?.subscriptionType
  );
  const userProfile = auth.user;

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    startDate: Date(Date.now()),
    creditPeriod: "",
    location: "",
    endDate: null,
    name: "",
    description: "",
    requestedBy: userProfile.email,
    requesterMobile: userProfile.mobile,
    siteEngineerContact: "",
    siteEngineerName: "",
  });
  const [enquiryState, setEnquiryState] = useState(enquiry);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [ownerCrmId, setOwnerCrmId] = useState();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const handleOnBlur = (keyName, value) => {
    setFormData((preVal) => ({ ...preVal, [keyName]: value }));
  };
  const config = {
    askBeforePasteHTML: false, // use for ( when some one paste there content then sugestion popUp dont show )
    askBeforePasteFromWord: false,
    placeholder: "Tell us about your requirement",
  };

  let title = isNewRequirement
    ? "Create Requirement"
    : `View / Update Requirement id : ${requirement?.rfqId} `;
  let btnLabel = isNewRequirement ? "Add Requirement" : "Save";

  if (isNewRFQSend) {
    btnLabel = "Send Enquiry";
  }

  if (isExistingRFQSend) {
    if (enquiry) {
      title = `Sending enquiry with existing requirements`;
      btnLabel = "Send Enquiry";
    }
  }

  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const userCompany = userProfile?.company;
  const entityId = userProfile?.userEntity?.entityId;
  const userName = userProfile?.name;
  const isEmailAuthorized = userProfile?.isEmailAuthorized
    ? userProfile?.isEmailAuthorized
    : false;
  const classes = useStyles();
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
    // counter: {
    //   container: '#counter',
    //   unit: 'character'
    // }
  };
  // console.log(enquiry,"enquiry");
  const AddInfoBtn = () => {
    // console.log("we are succefully enterd");
    setShowMoreOption((preVal) => !preVal);
  };

  const [crmLeadIdOfUser, setCrmLeadIdOfUser] = useState(null);
  const getCrmOfUserByMobileUrl = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(
    userProfile?.mobile
  );
  const userIdOfUser = userProfile.userId;
  useEffect(() => {
    if (!userProfile?.crmLeadId || userProfile?.crmLeadId == null) {
      axios.get(getCrmOfUserByMobileUrl).then((response) => {
        setCrmLeadIdOfUser(response.data[0].ProspectID);
        const crmLeadid1 = response.data[0].ProspectID;
        dispatch(updateCrmId(userIdOfUser, crmLeadid1));
      });
    } else {
      setCrmLeadIdOfUser(userProfile?.crmLeadId);
    }
  }, []);

  const userIdOfOwner = enquiry?.[0]?.businessOwnerUserId
    ? enquiry?.[0]?.businessOwnerUserId
    : enquiry?.[0]?.userIdOfOwner;
  useEffect(() => {
    axios
      .post(GET_USER_DATA_BY_ID, { userId: userIdOfOwner })
      .then((response) => {
        // console.log("GET BUSINESS OWNER USER PROFILE RESPONSE",enquiry)

        if (multipleOwnerId?.length <= 0) {
          const updatedObject = [
            {
              ...enquiryState[0],
              businessOwnerMobileNumber: response?.data?.payload?.mobile,
            },
          ];
          setEnquiryState([...updatedObject]);
        }
        if (response?.data?.payload?.crmLeadId) {
          setOwnerCrmId(response?.data?.payload?.crmLeadId);
        } else {
          const urlForCrmByMobile = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(
            response?.data?.payload?.mobile
          );
          axios
            .get(urlForCrmByMobile)
            .then((responseD) => {
              setOwnerCrmId(responseD.data[0].ProspectID);
              const crmLeadId = responseD.data[0].ProspectID;
              dispatch(updateCrmId(userIdOfOwner, crmLeadId));
            })
            .catch((error) => {
              // console.log("ERROR",error)
              const payload = [
                {
                  Attribute: "EmailAddress",
                  Value: response?.data?.payload?.email,
                },
                {
                  Attribute: "FirstName",
                  Value: response?.data?.payload?.name,
                },
                {
                  Attribute: "Phone",
                  Value: response?.data?.payload?.mobile,
                },
              ];

              axios.post(CREATE_CRM_LEAD_URL, payload).then((crmResponse) => {
                // console.log(":CREATE_CRM_LEAD: Response",crmResponse)
                setOwnerCrmId(crmResponse?.data.Message.Id);
                // dispatch(getCRMLeadByPhone(values.mobile))
                const crmLeadIdNew = crmResponse?.data.Message.Id;
                dispatch(updateCrmId(userIdOfOwner, crmLeadIdNew));
              });
            });
        }
      })
      .catch((error) => {
        // console.log("GET BUSINESS OWNER USER PROFILE ERROR",error);
      });
  }, []);

  const handleDeleteEnquiryVendore = (vendorSupplierId) => {
    setEnquiryState((preVal) =>
      preVal.filter((data) => data.supplierId !== vendorSupplierId)
    );
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 mb in bytes
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
  const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
  const [newCcEmails, setNewCcEmails] = useState([]);
  const [openAddEmailDoalog, setOpenAddEmailDoalog] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [selectedFilesBase, setSelectedFilesBase] = useState([]);
  const [showMoreAllEnquiry, setShowMoreAllEnquiry] = useState(false);
  const [showMoreAllAddedEmails, setShowMoreAllAddedEmails] = useState(false);
  const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
  const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState([]);

  const handleShowMoreClick = () => {
    setShowMoreAllEnquiry(true);
  };
  const handleCloseMoreAllEnquiry = () => {
    setShowMoreAllEnquiry(false);
  };
  const handleShowAddedEmails = () => {
    setShowMoreAllAddedEmails(true);
  };
  const handleCloseAddedEmails = () => {
    setShowMoreAllAddedEmails(false);
  };

  const validateEmail = (email) => {
    const validationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid =
      validationRegex.test(email) &&
      !/\.(com|in)[^a-zA-Z0-9]/.test(email) &&
      !/\.{2,}/.test(email) &&
      !/\.,com$/.test(email);

    return isValid;
  };

  const handleAddEmail = () => {
    if (validateEmail(newEmail)) {
      setNewCcEmails([...newCcEmails, newEmail]);
      setNewEmail("");
      setEmailError(false);
      setOpenAddEmailDoalog(false);
    } else {
      setEmailError(true);
    }
  };
  const handleCloseAddEmailDoalog = () => {
    setOpenAddEmailDoalog(false);
  };

  const addEmailOnclick = () => {
    setOpenAddEmailDoalog(true);
  };
  const handleDeleteNewCcEmails = (emailData) => {
    setNewCcEmails((preVal) => preVal.filter((email) => email !== emailData));
  };

  const handleCloseFileSizeError = () => {
    setOpenFilesSizeError(false);
  };

  // console.log("selectedFilesSize:", selectedFilesSize);
  const handleFileUpload = (event) => {
    const files = event.target.files;
    // count of current seleted files
    const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => {
      return acc + obj.size;
    }, 0);

    // count of prev selected and current selected files
    const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce((acc, obj) => {
      return acc + obj.FileSize;
    }, sizeOfSelectedFiles);
    // Validate file sizes and perform file upload as needed
    if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
      setAttachmentsSkeleton((preVal) => [...preVal, ...Array.from(files)]);

      const convertedFilesPromises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);

          const reader = new FileReader();

          reader.onload = (e) => {
            const fileContent = e.target.result;
            const encryptedContent = btoa(fileContent); // Encrypt file content in Base64

            // Fallback mechanism for .dwg files
            let contentType = file.type;
            if (!contentType && file.name.endsWith(".dwg")) {
              contentType = "application/acad"; // Common MIME type for DWG files
            }

            // Include fileType, fileName, and encryptedContent in your request payload
            const payload = {
              Name: file.name,
              Content: encryptedContent,
              ContentType: contentType,
              FileSize: file.size,
            };
            resolve(payload); // Resolve the promise with the payload for demonstration purposes
          };

          reader.readAsBinaryString(file);
        });
      });

      const attachmentBlobLink = (convertedFiles) => {
        axios
          .post("/api/rfqs/upload/attachments", convertedFiles)
          .then((response) => {
            setSelectedFilesBase((preVal) => [...preVal, ...response?.data]);
            setSelectedFiles((preVal) => [...preVal, ...response?.data]);
            setSelectedFilesTotalSize((preVal) => [
              ...preVal,
              ...convertedFiles,
            ]);
            setAttachmentsSkeleton([]);
          })
          .catch((error) => {
            setAttachmentsSkeleton([]);
            setAttachmentsUploadsErrors(error.message);
            setTimeout(() => {
              setAttachmentsUploadsErrors("");
            }, 5000);
            console.error(error);
          });
      };

      Promise.all(convertedFilesPromises)
        .then((convertedFiles) => {
          attachmentBlobLink(convertedFiles);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setOpenFilesSizeError(true);
    }
  };

  const handleFileUploadClick = (event) => {
    return (event.target.value = "");
  };

  const handleDeleteSelectedFile = (name, event) => {
    event.preventDefault(); // use becuse when we click on chip dont one link
    setSelectedFilesBase((preVal) =>
      preVal.filter((data) => data.Name !== name)
    );
    setSelectedFiles((preVal) => preVal.filter((data) => data.Name !== name));
    setSelectedFilesTotalSize((preVal) =>
      preVal.filter((data) => data.Name !== name)
    ); // this is use when some file are delet then the data also delet from this array becase of change in total size of selected  file
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: requirement?.name ? requirement?.name : formData.name,
        projectName: requirement?.projectName ? requirement.projectName : "",
        summary: requirement?.summary ? requirement?.summary : "",
        description: requirement?.description
          ? requirement?.description
          : formData.description,
        startDate: requirement?.workStartDate
          ? requirement?.workStartDate
          : formData.startDate, //null,//Date.now(),
        endDate: requirement?.workEndDate
          ? requirement?.workEndDate
          : formData.endDate, //Date.now(),
        validity: requirement?.validityDate ? requirement?.validityDate : null, //Date.now(),
        location: requirement?.location
          ? requirement?.location
          : formData.location,
        capacity: requirement?.capacity ? requirement?.capacity : "",
        uom: requirement?.uom ? requirement?.uom : "",
        creditPeriod: requirement?.creditPeriod
          ? requirement?.creditPeriod
          : formData.creditPeriod,
        requestedBy: formData.requestedBy,
        requesterMobile: formData.requesterMobile,
        siteEngineerContact: formData.siteEngineerContact,
        siteEngineerName: formData.siteEngineerName,
        isEmailAuthorized: isEmailAuthorized,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          // .min(25, "Subject must be at least 25 characters")
          .max(99, "Subject cannot exceed 100 characters")
          .required("Subject is required"),
        description: Yup.string().required("description is required"),
        startDate: Yup.date().required("Must enter start date"),
        location: Yup.string().required("location is required"),
        creditPeriod: Yup.string().max(25),
        requestedBy: Yup.string()
          .email("Invalid email address")
          .required("Email is required")
          .matches(
            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email address"
          ),
        requesterMobile: Yup.string().matches(
          /^[0-9]{10,12}$/,
          "Mobile number must be between 10 and 12 digits"
        ),
        siteEngineerContact: Yup.string().matches(
          /^[0-9]{10,12}$/,
          "Mobile number must be between 10 and 12 digits"
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        let businessNames;
        let supplierIds;
        if (multipleOwnerId?.length > 0) {
          businessNames = enquiry.map((item) => item.businessName).join(", ");
        }

        if (multipleOwnerId?.length > 0) {
          supplierIds = enquiry.map((ids) => ids.supplierId).join(", ");
        }
        const createActivity = {
          RelatedProspectId: userProfile.crmLeadId
            ? userProfile.crmLeadId
            : crmLeadIdOfUser,
          ActivityEvent: 210,
          ActivityNote: "Requirement created",
          Fields: [
            {
              SchemaName: "mx_Custom_1",
              Value: values.name,
            },
            {
              SchemaName: "mx_Custom_2",
              Value: values.projectName,
            },
            {
              SchemaName: "mx_Custom_3",
              Value: values.description,
            },
            {
              SchemaName: "mx_Custom_4",
              Value: values.location,
            },
          ],
        };

        const maxLength = 199; // Maximum length for the value of SchemaName "mx_Custom_2"

        const businessNameValue =
          multipleOwnerId?.length > 0
            ? businessNames
            : enquiry?.[0].businessName;
        const truncatedBusinessName =
          businessNameValue.length > maxLength
            ? "businessNames are more than 200 characters"
            : businessNameValue;

        const requirementSendData = {
          RelatedProspectId: userProfile.crmLeadId
            ? userProfile.crmLeadId
            : crmLeadIdOfUser,
          ActivityEvent: 212,
          ActivityNote: "Requirement sent successfully",
          Fields: [
            {
              SchemaName: "mx_Custom_1",
              Value: values.name,
            },
            {
              SchemaName: "mx_Custom_2",
              Value: truncatedBusinessName,
              // "Value": multipleOwnerId?.length > 0 ? businessNames : enquiry?.[0].businessName
            },
            {
              SchemaName: "mx_Custom_4",
              Value:
                multipleOwnerId?.length > 0
                  ? supplierIds
                  : enquiry?.[0].supplierId,
            },
          ],
        };

        const requirementReceivedData = {
          RelatedProspectId: ownerCrmId,
          ActivityEvent: 213,
          ActivityNote: "Requirement received successfully",
          Fields: [
            {
              SchemaName: "mx_Custom_1",
              Value: userProfile.mobile,
            },
            {
              SchemaName: "mx_Custom_2",
              Value: userProfile.name,
            },
            {
              SchemaName: "mx_Custom_3",
              Value: userProfile.userId,
            },
            {
              SchemaName: "mx_Custom_4",
              Value: "rfqid",
            },
          ],
        };

        if (multipleOwnerId && multipleOwnerId.length > 0) {
          const activitiesPromises = multipleOwnerId.map((id, index) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const requirementReceivedDataMultiple = {
                  RelatedProspectId: id,
                  ActivityEvent: 213,
                  ActivityNote: "Requirement received successfully",
                  Fields: [
                    {
                      SchemaName: "mx_Custom_1",
                      Value: userProfile.mobile,
                    },
                    {
                      SchemaName: "mx_Custom_2",
                      Value: userProfile.name,
                    },
                    {
                      SchemaName: "mx_Custom_3",
                      Value: userProfile.userId,
                    },
                    {
                      SchemaName: "mx_Custom_4",
                      Value: "rfqid",
                    },
                  ],
                };

                dispatch(addLeadActivity(requirementReceivedDataMultiple))
                  .then(() => resolve())
                  .catch((error) => reject(error));
              }, index * 1000); // Delay each API call by 1 seconds (5000 milliseconds)
            });
          });

          Promise.all(activitiesPromises)
            .then(() => {
              // All API calls have completed successfully
            })
            .catch((error) => {
              // Handle errors, if any, from the API calls
            });
        }

        try {
          if (isNewRFQSend) {
            await dispatch(
              sendNewEnquiry(
                supplierId,
                values,
                enquiryState,
                userCompany,
                userName,
                selectedFilesBase,
                newCcEmails,
                userProfile?.userId,
                subscriptionType
              )
            );

            dispatch(addLeadActivity(requirementSendData));
            dispatch(addLeadActivity(createActivity));
            dispatch(addLeadActivity(requirementReceivedData));
          } else if (isExistingRFQSend) {
            await dispatch(
              sendExistingEnquiry(supplierId, requirement?.rfqId, enquiryState)
            );
          } else {
            await dispatch(
              requirement
                ? updateRequirements(
                    supplierId,
                    requirement?.rfqId,
                    values,
                    userProfile?.mobile
                  )
                : addRequirements(supplierId, values, userProfile?.userId),
              dispatch(addLeadActivity(createActivity))
            );
          }

          onSubmitSuccess();
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => {
        return (
          <form onSubmit={handleSubmit} {...rest} sx={{ marginTop: 0 }}>
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize="18px">{title}</Typography>
              {isSm && (
                <Button
                  disabled={isSubmitting || attachmentsSkeleton.length > 0}
                  variant="contained"
                  // color="secondary"
                  type="submit"
                  onClick={handleSubmit}
                  sx={{
                    borderRadius: "17px",
                    padding: 0,
                    height: "34px",
                    color: "black",
                    backgroundColor: "#002E33",
                    "&:hover": {
                      backgroundColor: "#002E33",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                    },
                  }}
                >
                  <Typography
                    fontSize="16px"
                    paddingX="12px"
                    fontWeight="600"
                    color={"white"}
                  >
                    {entityId ? "Contact Vendor" : "Send Message"}
                  </Typography>
                  {/* <SendIcon
                    fontSize="medium"
                    sx={{
                      color: "rgba(255, 255, 255, 1)",
                      height: "100%",
                      padding: "4px",
                      backgroundColor: "#fbfbfb5e",
                      width: "1em",
                    }}
                  /> */}
                </Button>
              )}
            </Grid>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap="16px"
              flexWrap={"wrap"}
              margin="12px auto"
            >
              <Grid item width="270px" display={"flex"} alignItems={"center"}>
                <Typography fontSize={"16px"}>Supply by</Typography>
                <Tooltip title="Provide the expected date of delivery here">
                  <IconButton>
                    <Box
                      component="img"
                      sx={{
                        height: "18px",
                        width: "18px",
                      }}
                      alt="logo"
                      src="/assets/icons/questionMarkLogo.svg"
                    />
                  </IconButton>
                </Tooltip>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    id="startDate"
                    name="startDate"
                    openTo="day"
                    inputFormat="dd/MM/yyyy"
                    views={["year", "month", "day"]}
                    value={values.startDate}
                    onChange={(newValue) => {
                      setFormData((preVal) => ({
                        ...preVal,
                        ["startDate"]: newValue,
                      }));
                      setFieldValue("startDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "150px !important",
                          "& .MuiInputBase-input ": {
                            padding: "4px 8px !important",
                          },
                        }}
                        size={"small"}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item width="300px" display={"flex"} alignItems={"center"}>
                <Typography fontSize={"16px"}>Credit period</Typography>
                <Tooltip title="Let your vendor know the expected credit period for this requirement">
                  <IconButton>
                    <Box
                      component="img"
                      sx={{
                        height: "18px",
                        width: "18px",
                      }}
                      alt="logo"
                      src="/assets/icons/questionMarkLogo.svg"
                    />
                  </IconButton>
                </Tooltip>
                <TextField
                  size={"small"}
                  sx={{
                    width: "170px !important",
                    "& .MuiInputBase-input ": {
                      padding: "4px 8px !important",
                    },
                  }}
                  name="creditPeriod"
                  id="creditPeriod"
                  placeholder="Credit Period (Range)"
                  onChange={handleChange}
                  onBlur={() =>
                    handleOnBlur("creditPeriod", values.creditPeriod)
                  }
                  value={values.creditPeriod}
                  error={Boolean(touched.creditPeriod && errors.creditPeriod)}
                  helperText={touched.creditPeriod && errors.creditPeriod}
                />
              </Grid>

              <Grid item width="250px" display={"flex"} alignItems={"center"}>
                <Typography fontSize={"16px"}>Location</Typography>
                <Tooltip title="Mention the location of your project for delivery of product or service">
                  <IconButton>
                    <Box
                      component="img"
                      sx={{
                        height: "18px",
                        width: "18px",
                      }}
                      alt="logo"
                      src="/assets/icons/questionMarkLogo.svg"
                    />
                  </IconButton>
                </Tooltip>
                <TextField
                  sx={{
                    width: "150px !important",
                    "& .MuiInputBase-input ": {
                      padding: "4px 8px !important",
                    },
                  }}
                  size={"small"}
                  required
                  name="location"
                  id="location"
                  placeholder="Project Location"
                  onChange={handleChange}
                  onBlur={() => handleOnBlur("location", values.location)}
                  inputProps={{ maxLength: 50 }}
                  value={values.location}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
              </Grid>

              <Grid item width="230px" display={"flex"} alignItems={"center"}>
                <Typography fontSize={"16px"}>Expiry</Typography>
                <Tooltip title="This is the optional date, requirement will be expired and contact details will not be visible to vendor after this date. If left blank, default expiry will be applied.">
                  <IconButton>
                    <Box
                      component="img"
                      sx={{
                        height: "18px",
                        width: "18px",
                      }}
                      alt="logo"
                      src="/assets/icons/questionMarkLogo.svg"
                    />
                  </IconButton>
                </Tooltip>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    id="endDate"
                    name="endDate"
                    inputFormat="dd/MM/yyyy"
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={values.endDate}
                    onChange={(newValue) => {
                      setFormData((preVal) => ({
                        ...preVal,
                        ["endDate"]: newValue,
                      }));
                      setFieldValue("endDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "150px !important",
                          "& .MuiInputBase-input": {
                            padding: "4px 0px 4px 8px !important",
                          },
                        }}
                        size={"small"}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid
              style={{
                border: "1px solid rgba(112, 112, 112, 1)",
                borderRadius: "10px 10px 0px 0px ",
                padding: "20px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  width={"150px"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography style={{ fontSize: "18px" }}>
                    Vendor List,
                  </Typography>
                  <Tooltip title="List of all vendors this requirement will be floated for quotation">
                    <IconButton>
                      <Box
                        component="img"
                        sx={{
                          height: "18px",
                          width: "18px",
                        }}
                        alt="logo"
                        src="/assets/icons/questionMarkLogo.svg"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid
                  item
                  xs
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  {enquiryState?.map(
                    (data, index) =>
                      index < 4 && (
                        <Tooltip title={enquiry[index].businessEmail}>
                          <Chip
                            label={data.businessName}
                            size="small"
                            variant="outlined"
                            onDelete={
                              enquiryState?.length > 1
                                ? () =>
                                    handleDeleteEnquiryVendore(data.supplierId)
                                : undefined
                            }
                            sx={{
                              margin: "4px",
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      )
                  )}
                  {enquiryState.length > 4 && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        margin: "4px",
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={handleShowMoreClick}
                    >
                      Show more...
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid display={"flex"} alignItems={"baseline"}>
                <Grid
                  width={isSm ? "150px" : "50px"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography style={{ fontSize: "18px" }}>cc,</Typography>

                  <Tooltip title="Requirement will be copied to these email addresses when sent to above list of vendors. If left blank, no one will be copied in email">
                    <IconButton>
                      <Box
                        component="img"
                        sx={{
                          height: "18px",
                          width: "18px",
                        }}
                        alt="logo"
                        src="/assets/icons/questionMarkLogo.svg"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                  {newCcEmails?.map(
                    (email, index) =>
                      index < 4 && (
                        <Chip
                          label={email}
                          variant="outlined"
                          size="small"
                          onDelete={() => handleDeleteNewCcEmails(email)}
                          sx={{
                            maxWidth: !isSm ? "170px" : "auto",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            margin: "4px",
                          }}
                        />
                      )
                  )}
                  {newCcEmails.length > 4 && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        margin: "4px",
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={handleShowAddedEmails}
                    >
                      Show all...
                    </Typography>
                  )}
                  <Grid sx={{ margin: "16px" }} onClick={addEmailOnclick}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Add Email +
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider margin="15px auto" />

              <Grid
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
                gap={isMd ? "24px" : "12px"}
                margin={"12px 0"}
              >
                <Grid display={"flex"} alignItems="center">
                  <Typography style={{ fontSize: "18px" }}>Subject</Typography>
                  <Tooltip title="Provide the product or service name which will help you relate to this requirement in the list.">
                    <IconButton>
                      <Box
                        component="img"
                        sx={{
                          height: "18px",
                          width: "18px",
                        }}
                        alt="logo"
                        src="/assets/icons/questionMarkLogo.svg"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  sx={{
                    width: "690px !important",
                    "& .MuiInputBase-input ": {
                      padding: "4px 8px !important",
                    },
                  }}
                  name="name"
                  fullWidth
                  required
                  id="name"
                  placeholder="Subject"
                  onChange={handleChange}
                  onBlur={() => handleOnBlur("name", values.name)}
                  inputProps={{ maxLength: 100 }}
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Divider margin="15px auto" />

              <Grid container alignItems={"baseline"} m={"22px 0px"}>
                <Grid item sx={{ width: "250px" }}>
                  <Typography fontSize={"18px"} marginBottom="8px">
                    Requester Details :
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"start"}
                  gap="8px"
                >
                  <Grid display={"flex"} flexWrap={!isMd && "wrap"} gap="16px">
                    <Grid display={"flex"} gap="8px" alignItems={"center"}>
                      <Typography fonntSize="16px" fontWeight={300}>
                        Mobile No.
                      </Typography>
                      <TextField
                        sx={{
                          width: "150px !important",
                          "& .MuiInputBase-input ": {
                            padding: "4px 8px !important",
                          },
                        }}
                        size={"small"}
                        name="requesterMobile"
                        id="requesterMobile"
                        placeholder="mobile number"
                        onChange={handleChange}
                        onBlur={() =>
                          handleOnBlur(
                            "requesterMobile",
                            values.requesterMobile
                          )
                        }
                        inputProps={{ maxLength: 25 }}
                        value={values.requesterMobile}
                        error={Boolean(
                          touched.requesterMobile && errors.requesterMobile
                        )}
                        helperText={
                          touched.requesterMobile && errors.requesterMobile
                        }
                      />
                    </Grid>
                    <Grid display={"flex"} gap="8px" alignItems={"center"}>
                      <Typography fonntSize="16px" fontWeight={300}>
                        Email ID
                      </Typography>
                      <TextField
                        sx={{
                          width: isMd ? "240px !important" : "150px !important",
                          "& .MuiInputBase-input ": {
                            padding: "4px 8px !important",
                          },
                        }}
                        size={"small"}
                        name="requestedBy"
                        // required
                        id="requestedBy"
                        // label="Requirement Label"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={() =>
                          handleOnBlur("requestedBy", values.requestedBy)
                        }
                        inputProps={{ maxLength: 25 }}
                        value={values.requestedBy}
                        error={Boolean(
                          touched.requestedBy && errors.requestedBy
                        )}
                        helperText={touched.requestedBy && errors.requestedBy}
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    style={{
                      color: "#3963AB",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    This details will be shared with vendor, Edit details if
                    Required.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item sx={{ width: "250px" }}>
                  <Typography fontSize={"18px"} marginBottom="8px">
                    Engineer Details :
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  display={"flex"}
                  flexWrap={!isMd && "wrap"}
                  gap="16px"
                >
                  <Grid display={"flex"} gap="8px" alignItems={"center"}>
                    <Typography fonntSize="16px" fontWeight={300}>
                      Name
                    </Typography>
                    <TextField
                      sx={{
                        width: "150px !important",
                        "& .MuiInputBase-input ": {
                          padding: "4px 8px !important",
                        },
                      }}
                      size={"small"}
                      name="siteEngineerName"
                      id="siteEngineerName"
                      placeholder="Name"
                      onChange={handleChange}
                      onBlur={() =>
                        handleOnBlur(
                          "siteEngineerName",
                          values.siteEngineerName
                        )
                      }
                      inputProps={{ maxLength: 25 }}
                      value={values.siteEngineerName}
                      error={Boolean(
                        touched.siteEngineerName && errors.siteEngineerName
                      )}
                      helperText={
                        touched.siteEngineerName && errors.siteEngineerName
                      }
                    />
                  </Grid>
                  <Grid display={"flex"} gap="8px" alignItems={"center"}>
                    <Typography fonntSize="16px" fontWeight={300}>
                      Mobile No.
                    </Typography>
                    <TextField
                      sx={{
                        width: "150px !important",
                        "& .MuiInputBase-input ": {
                          padding: "4px 8px !important",
                        },
                      }}
                      size={"small"}
                      name="siteEngineerContact"
                      id="siteEngineerContact"
                      placeholder="mobile number"
                      onChange={handleChange}
                      onBlur={() =>
                        handleOnBlur(
                          "siteEngineerContact",
                          values.siteEngineerContact
                        )
                      }
                      inputProps={{ maxLength: 25 }}
                      value={values.siteEngineerContact}
                      error={Boolean(
                        touched.siteEngineerContact &&
                          errors.siteEngineerContact
                      )}
                      helperText={
                        touched.siteEngineerContact &&
                        errors.siteEngineerContact
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                border: "1px solid rgba(112, 112, 112, 1)",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <Paper
                variant="outlined"
                sx={{ position: "relative", borderRadius: "0px 0px 10px 10px" }}
              >
                <QuillEditor
                  required
                  className={classes.editor}
                  value={values.description}
                  onChange={(value) => setFieldValue("description", value)}
                  onBlur={() => handleOnBlur("description", values.description)}
                  modules={modules}
                  placeholder={"Create Your Requirement"}
                  error={Boolean(touched.description && errors.description)}
                  helperText={
                    touched.description && errors.description
                      ? "Please enter discription"
                      : ""
                  }
                />
                <CustomToolbar />
                <Grid display={"flex"} alignItems={"center"}>
                  <Grid>
                    <input
                      accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify the file types you want to allow
                      id="file-upload"
                      type="file"
                      disabled={attachmentsSkeleton.length > 0}
                      style={{ display: "none" }}
                      onClick={handleFileUploadClick}
                      onChange={handleFileUpload}
                    />
                    <label htmlFor="file-upload">
                      <Grid
                        sx={{
                          display: "flex",
                          padding: "4px 6px",
                          borderRadius: "12px",
                          width: "125px",
                          cursor: "pointer",
                          margin: "8px 12px ",
                          cursor: attachmentsSkeleton.length > 0 && "wait",
                          backgroundColor:
                            attachmentsSkeleton.length > 0 ? "grey" : "white",
                          color:
                            attachmentsSkeleton.length > 0 ? "white" : "black",
                          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <AttachFileIcon
                          sx={{
                            width: "0.7em",
                            ml: "2px",
                          }}
                        />
                        <Typography sx={{ textDecoration: "underline" }}>
                          Attach File's
                        </Typography>
                      </Grid>
                    </label>
                  </Grid>
                  <Typography fontSize={"12px"}>
                    Accepted files: Excel, PDF or Image
                  </Typography>
                </Grid>
                {attachmentsSkeleton.length > 0 && (
                  <Typography fontSize={"12px"} paddingLeft={"12px"}>
                    Your file is being scanned for upload, be patient and
                    continue to fill rest of the form.
                  </Typography>
                )}

                <Grid paddingX="12px">
                  {/* Display the selected file names */}
                  <ul style={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedFiles.map((file, index) => (
                      <Chip
                        label={file.Name}
                        clickable
                        component="a"
                        href={file.Content}
                        target="_blank"
                        rel="noopener noreferrer" // Recommended for security and performance reasons
                        variant="outlined"
                        onDelete={(event) =>
                          handleDeleteSelectedFile(file.Name, event)
                        }
                        sx={{
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          margin: "4px",
                          justifyContent: "space-between",
                        }}
                      />
                    ))}
                    {attachmentsSkeleton.map(() => (
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "20px", margin: "4px" }}
                        width={150}
                        height={32}
                      />
                    ))}
                  </ul>
                </Grid>
              </Paper>
              {touched.description && errors.description && (
                <Box mt={3}>
                  <FormHelperText error>{errors.description}</FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {attachmentsUploadsErrors && (
                <Box mt={3}>
                  <Typography color="red" fontWeight="12px" margin="14px">
                    {" "}
                    {attachmentsUploadsErrors}
                  </Typography>
                </Box>
              )}
            </Grid>
            {!isSm && (
              <Button
                disabled={isSubmitting || attachmentsSkeleton.length > 0}
                variant="contained"
                // color="secondary"
                type="submit"
                onClick={handleSubmit}
                sx={{
                  borderRadius: "17px",
                  padding: 0,
                  height: "34px",
                  margin: "12px auto",
                  float: "right",
                  color: "white",
                  backgroundColor: "#002E33",
                  "&:hover": {
                    backgroundColor: "#002E33",
                  },
                }}
              >
                <Typography
                  fontSize="16px"
                  paddingX="12px"
                  fontWeight="600"
                  color="white"
                >
                  {entityId ? "Contact Vendor" : "Send Message"}
                </Typography>
                {/* <SendIcon
                  fontSize="medium"
                  sx={{
                    color: "rgba(255, 255, 255, 1)",
                    height: "100%",
                    padding: "4px",
                    backgroundColor: "#fbfbfb5e",
                    width: "1em",
                  }}
                /> */}
              </Button>
            )}
            <Dialog
              open={openAddEmailDoalog}
              onClose={handleCloseAddEmailDoalog}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>Add Email To CC</DialogTitle>
              <DialogContent>
                <DialogContentText>Add one email at a time</DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={newEmail}
                  error={emailError}
                  helperText={emailError ? "Please enter a valid email" : ""}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddEmailDoalog}>Cancel</Button>
                <Button onClick={handleAddEmail}>Add</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth
              maxWidth="sm"
              open={showMoreAllEnquiry}
              onClose={handleCloseMoreAllEnquiry}
            >
              <DialogContent>
                {enquiryState?.map((data, index) => (
                  <Tooltip title={enquiry[index].businessEmail}>
                    <Chip
                      // disabled={enquiry?.length < 2}
                      label={data.businessName}
                      size="small"
                      variant="outlined"
                      // onDelete={() => handleDeleteSelectedFile(data)}
                      onDelete={
                        enquiryState?.length > 1
                          ? () => handleDeleteEnquiryVendore(data.supplierId)
                          : undefined
                      }
                      sx={{
                        margin: "4px",
                        justifyContent: "space-between",
                      }}
                    />
                  </Tooltip>
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseMoreAllEnquiry}>Close</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth
              maxWidth="sm"
              open={showMoreAllAddedEmails}
              onClose={handleCloseAddedEmails}
            >
              <DialogContent>
                {newCcEmails?.map((email) => (
                  <Chip
                    size="small"
                    label={email}
                    variant="outlined"
                    onDelete={() => handleDeleteNewCcEmails(email)}
                    sx={{
                      maxWidth: !isSm ? "170px" : "auto",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "4px",
                    }}
                  />
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddedEmails}>Close</Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              open={openFilesSizeError}
              autoHideDuration={6000}
              onClose={handleCloseFileSizeError}
            >
              <Alert
                onClose={handleCloseFileSizeError}
                severity="warning"
                sx={{ width: "100%" }}
              >
                Total size of attachments is not more than 10 MB
              </Alert>
            </Snackbar>
          </form>
        );
      }}
    </Formik>
  );
}
RequirementForm.default = {
  onSubmitSuccess: () => {},
  isNewRequirement: true,
  isNewRFQSend: false,
  isExistingRFQSend: false,
  requirement: undefined,
};

RequirementForm.propTypes = {
  isNewRequirement: PropTypes.bool.isRequired,
  isNewRFQSend: PropTypes.bool,
  isExistingRFQSend: PropTypes.bool,
  enquiry: PropTypes.object,
  requirement: PropTypes.object,
  supplier: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
};

export default RequirementForm;
