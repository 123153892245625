import React from "react";
import { useNavigate } from "react-router";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Minimal from "layouts/Minimal";
import Logo from "components/Logo";
import { Helmet } from "react-helmet";

import AboutNeevay from "../components/AboutNeevay";
import SignInEmail from "./SignInEmail";

function SignIn() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  // const navigate = useNavigate();
  //
  // const handleSubmitSuccess = () => {
  //   navigate("/dashboard");
  // };

  return (
    <Minimal>
      <Helmet>
        <title>Neevay - SignIn Email</title>
      </Helmet>
      {isMd ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid
              item
              height="100vh"
              xs={12}
              sm={12}
              md={6}
              sx={{
                backgroundColor: "black",
                color: "#ffffff",
              }}
            >
              <Grid py={"100px"}>
                <AboutNeevay />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <SignInEmail />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <SignInEmail />
            </Grid>
            <Grid
              item
              // height="100vh"
              xs={12}
              sm={12}
              md={6}
              sx={{
                backgroundColor: "secondary.main",
                color: "#ffffff",
              }}
            >
              <Grid py={"100px"}>
                <AboutNeevay />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Minimal>
  );
}

export default SignIn;
