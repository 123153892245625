import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";



import { getBusinessLabel } from "utils/constantUtils";

function ProfileAbout({
  establishedOn,
  gstCertified,
  isoCertified,
  businessType,
  isPublicProfile,
  bProfile,
  bStatutory,
  ...rest
}) {
  

  return (
    <Container fullWidth="lg">
      <Card
        sx={{
          borderRadius: 5,
          p: 3,
          minHeight: isPublicProfile ? 230 : 300,
          maxHeight: isPublicProfile ? 230 : 300,
          overflow: "auto",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src="/assets/icons/building.png" height="24px" />
              <Typography
                variant="businessCard"
                sx={{ ml: 2 }}
              >{`Establishment in ${establishedOn}`}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src="/assets/icons/gst.png" alt="check" height="24px" />
              <Typography variant="businessCard" sx={{ ml: 2 }}>
                {gstCertified
                  ? `GST Number : ${bStatutory.gstin}`
                  : "Not Available"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src="/assets/icons/register.png"
                alt="register"
                height="24px"
              />
              <Typography variant="businessCard" sx={{ ml: 2 }}>
                {getBusinessLabel(businessType)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src="/assets/icons/iso.png" alt="check" height="24px" />
              <Typography variant="businessCard" sx={{ ml: 2 }}>
                {isoCertified
                  ? `ISO Certification: ${bProfile.certificates.join(", ")}`
                  : "ISO Certification: None"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

ProfileAbout.defaultProps = {
  isPublicProfile: true,
};

ProfileAbout.propTypes = {
  establishedOn: PropTypes.string.isRequired,
  gstCertified: PropTypes.bool.isRequired,
  isoCertified: PropTypes.bool.isRequired,
  businessType: PropTypes.string.isRequired,
  isPublicProfile: PropTypes.bool,
};

export default ProfileAbout;
