import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputBase,
  ListItem,
  Paper,
  TextField,
  createFilterOptions,
  createTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { addFavourites } from "actions/favouritesAction";
import { useDispatch, useSelector } from "react-redux";
import { addTag } from "actions/tagsActions";
import axios from "utils/axios";
import {
  CREATE_CRM_LEAD_URL,
  GET_CRM_LEAD_BY_PHONE_NUMBER,
  GET_USER_DATA_BY_ID,
} from "config/services";
import { updateCrmId } from "actions/authActions";
import { addLeadActivity } from "actions/crmAction";

const FavoritesDialogBox = ({
  businessName,
  supplierId,
  handleClose,
  open,
  itemOwnerId,
  userProfile,
  setOpenSave,
  businessEmail,
  contact,
  subscriptionType,
  url,
}) => {
  // const [green, setGreen] = useState(false)
  // console.log("extra data ===", businessEmail, contact, subscriptionType, url);
  const dispatch = useDispatch();
  let selectedTags = [];
  const favourite = useSelector((state) => state.favourite.favourites);
  const thisData = favourite.filter((data) => data.supplierId == supplierId);
  // const defaultValues = thisData[0]?.tags === undefined ? [] : thisData[0]?.tags.map((tag) => { return { tagName: tag.slice(0, -2) } })

  const tagsNew = useSelector((state) => state.tags);
  const [newTag, setNewTag] = React.useState();
  const [defaultValues, setDefaultValues] = useState([]);
  const [chipData, setChipData] = React.useState(tagsNew.tags);

  const handleClick = (data) => {
    // console.log(data.target.value);
    setNewTag(data.target.value);
  };

  const handleAddTag = () => {
    dispatch(addTag({ tagName: newTag }));
    setChipData([...chipData, { tagName: newTag }]);
  };

  const submitTagsData = async () => {
    setOpenSave(true);
    const collectionTag = selectedTags?.map((data) => data.props);
    // console.log(collectionTag.map((data) => data.label));
    let favTags = collectionTag?.map((data) => data.label + ", ");
    const isPremium = subscriptionType === "PREMIUM";
    dispatch(addFavourites(
      supplierId,
      businessName,
      favTags,
      businessEmail,
      contact,
      isPremium,
      url,));
    handleClose();
    // if (selectedTags) {
    //   let crmLeadIdOfUser = "";
    //   const itemOwnerIds = itemOwnerId;
    //   try {
    //     const response = await axios.post(GET_USER_DATA_BY_ID, {
    //       userId: itemOwnerIds,
    //     });
    //     const userPayload = response?.data?.payload;

    //     if (userPayload?.crmLeadId) {
    //       crmLeadIdOfUser = userPayload.crmLeadId;
    //     } else {
    //       const businessOwnerMobileNumber = userPayload.mobile;
    //       const urlForCrmByMobile = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(
    //         businessOwnerMobileNumber
    //       );
    //       const responseD = await axios.get(urlForCrmByMobile);
    //       const prospectID = responseD?.data?.[0]?.ProspectID;

    //       if (prospectID) {
    //         crmLeadIdOfUser = prospectID;
    //         dispatch(updateCrmId(itemOwnerIds, prospectID));
    //       } else {
    //         const payload = [
    //           {
    //             Attribute: "EmailAddress",
    //             Value: userPayload.email,
    //           },
    //           {
    //             Attribute: "FirstName",
    //             Value: userPayload.name,
    //           },
    //           {
    //             Attribute: "Phone",
    //             Value: businessOwnerMobileNumber,
    //           },
    //         ];

    //         const crmResponse = await axios.post(CREATE_CRM_LEAD_URL, payload);
    //         const crmLeadId = crmResponse?.data?.Message?.Id;

    //         if (crmLeadId) {
    //           crmLeadIdOfUser = crmLeadId;
    //           dispatch(updateCrmId(itemOwnerIds, crmLeadId));
    //         }
    //       }
    //     }

    //     const activityData = {
    //       RelatedProspectId: crmLeadIdOfUser,
    //       ActivityEvent: 220,
    //       ActivityNote: "Profile Shortlisted",
    //       Fields: [
    //         {
    //           SchemaName: "mx_Custom_1",
    //           Value: userProfile.name,
    //         },
    //         {
    //           SchemaName: "mx_Custom_2",
    //           Value: userProfile.mobile,
    //         },
    //         {
    //           SchemaName: "mx_Custom_3",
    //           Value: userProfile.userId,
    //         },
    //         {
    //           SchemaName: "mx_Custom_4",
    //           Value: `Your profile is shortlisted`,
    //         },
    //       ],
    //     };

    //     dispatch(addLeadActivity(activityData));
    //   } catch (error) {
    //     // console.error("Error:", error);
    //   }
    // }
  };

  useEffect(() => {
    setChipData(tagsNew.tags);
  }, [tagsNew.tags]);

  useEffect(() => {
    const values =
      thisData[0]?.tags === undefined
        ? []
        : thisData[0]?.tags.map((tag) => {
          return { tagName: tag.slice(0, -2) };
        });

    setDefaultValues(values);
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth="true"
      onClose={handleClose}
      maxWidth="sm"
    >
      <DialogContent>
        <Autocomplete
          sx={{ width: "100%" }}
          multiple
          id="multiple-limit-tags"
          // defaultValues={defaultValues}
          value={defaultValues}
          options={chipData}
          getOptionLabel={(option) => option.tagName}
          isOptionEqualToValue={(option, value) =>
            option.tagName === value.tagName
          }
          onChange={(event, newValue) => {
            setDefaultValues(newValue);
          }}
          renderInput={(params) => {
            selectedTags = params.InputProps.startAdornment;
            return (
              <TextField
                onChange={(data) => handleClick(data)}
                {...params}
                label="select tags"
                placeholder="Favorites"
              />
            );
          }}
          noOptionsText={<Chip label="add tag" onClick={handleAddTag} />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => submitTagsData()}
         sx={{backgroundColor: "#FD6600",
               color:"white",
               "&:hover": {
                 backgroundColor: "#FD6600",
                 transition: "background-color 0.3s, box-shadow 0.3s",
                 boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
               }
               }}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

FavoritesDialogBox.propTypes = {};

export default FavoritesDialogBox;
