import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { Formik } from "formik";
import { Box, Button, FormHelperText, Grid, TextField } from "@mui/material";

import { signInSendMobileOTP } from "actions/authActions";

function SignInSendMobileOTPForm({ className, onSubmitSuccess, ...rest }) {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        mobile: "",
      }}
      validationSchema={Yup.object().shape({
        mobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .min(10)
          .max(10)
          .required("Please Enter your mobile"),

      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(signInSendMobileOTP(values.mobile));
          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.mobile && errors.mobile)}
                fullWidth
                helperText={touched.mobile && errors.mobile}
                label="Mobile"
                margin="normal"
                name="mobile"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
                required
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid item alignItems={"center"} justifyContent={"center"}>
              <Button
               sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0.6,0.7)",
                  transition: "background-color 0.4s, box-shadow 0.4s", // Smooth transition
                },
              }}
                // color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Send Mobile OTP
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignInSendMobileOTPForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

SignInSendMobileOTPForm.default = {
  onSubmitSuccess: () => {},
};

export default SignInSendMobileOTPForm;
