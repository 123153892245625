import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

function ConfirmDialog({
  title,
  description,
  confirmAction,
  cancelAction,
  isOpen,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={cancelAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
        sx={{backgroundColor: "#FD6600",
        color:"white",
        "&:hover": {
          backgroundColor: "#FD6600",
          transition: "background-color 0.3s, box-shadow 0.3s",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
        }
        }}
        onClick={cancelAction}>Cancel</Button>
        <Button 
        
        sx={{backgroundColor: "#FD6600",
        color:"white",
        "&:hover": {
          backgroundColor: "#FD6600",
          transition: "background-color 0.3s, box-shadow 0.3s",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
        }
        }}onClick={confirmAction} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  confirmAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ConfirmDialog;
