import {
  Box,
  Grid,
  Typography,
  Snackbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { getIsPremium } from "utils/accessControl";
import SignInMobileOtpPopUp from "views/Auth/SignInSendMobileOTP/SignInMobileOtpPopUp";
import { fetchTag } from "actions/tagsActions";
import FavoritesDialogBox from "views/Business/components/FavoritesDialogBox";
import SendEnquiryButton from "./SendEnquiryButton";
import ProfileDetailBox from "./ProfileDetailBox";
import BusinessContactUs from "../BusinessContactUs";
import BusinessProfileAbout from "../BusinessProfileAbout";
import ProfileAbout from "./ProfileAbout";
import BusinessOffering from "./BusinessOffering";
import TopClient from "../TopClient";
import Domains from "../Domains";
import AwardsCertificate from "./AwardsCertificate";
import AwardsCertification from "../AwardsCertification";
import MobileProjects from "./MobileProjects";
import { Link } from "react-router-dom";
import Awards from "views/Business/components/Awards";
import CertificateSection from "views/Business/components/CertificateSection";
import { ImageEditor } from "components";
import SendEnquiryEditView from "views/Business/MyBusiness/SendEnquiryEditView";
import SendEnquiryMobileViewEdit from "views/Business/MyBusiness/SendEnquiryMobileViewEdit";
import BusinessContactEditView from "views/Business/MyBusiness/BusinessContactEditView";

function NewBusinessProfileMobileView({
  profile,
  projects,
  isEditView,
  permissions,
  PublicProfile,
}) {
  const businessProfile = profile?.businessProfile;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let defaultImg = "/assets/default/default-search-image.jpg";
  const logoSrcUrl =
    businessProfile?.logoUrl && businessProfile?.logoUrl !== ""
      ? businessProfile?.logoUrl.startsWith("/assets/business")
        ? `${BASE_URL}${businessProfile?.logoUrl}`
        : businessProfile?.logoUrl
      : defaultImg;
  const bProfile = profile?.businessProfile;
  let isAwardExists = false;

  //create a business area value if dist not available the store the value of city
  const businessArea = businessProfile?.addresses_district
    ? businessProfile?.addresses_district
    : businessProfile?.addresses_city;

  const businessLocation =
    businessArea && businessProfile?.addresses_state
      ? `${businessArea}, ${businessProfile?.addresses_state}`
      : businessArea
      ? businessArea
      : businessProfile?.addresses_state
      ? businessProfile?.addresses_state
      : "";

  const capitalizeFirstLetter = (str) => {
    if (typeof str === "string") {
      return str
        ?.split(" ")
        ?.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        ?.join(" ");
    } else {
      return str;
    }
  };
  const certificate = profile?.businessProfile?.certificateImages || [];
  const awards = profile?.awards || [];

  const AwardsCertificationArray = [...certificate, ...awards];

  return (
    <Grid>
      <Grid
        sx={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "space-between",
          marginX: "20px",
          marginBottom: "10px",
        }}
      >
        <Grid sx={{ display: "flex", gap: "25px" }}>
          <Grid
            sx={{
              display: "flex",

              alignItems: "top",
            }}
          >
            {isEditView ? (
              // <Box sx={{
              //   overflow: "hidden",
              //   border: "1px solid #D9D9D9",
              //   borderRadius: "50%",
              //   width: "75px",
              //   height: "75px",
              // }}>

              <ImageEditor
                type="logo"
                inImage={logoSrcUrl}
                supplierId={profile?.supplierId}
              />
            ) : (
              // </Box>
              <Box
                component="img"
                sx={{
                  overflow: "hidden",
                  border: "1px solid #D9D9D9",
                  borderRadius: "50%",
                  width: "65px",
                  height: "65px",
                }}
                alt="Logo"
                src={logoSrcUrl}
              />
            )}
          </Grid>
          <Grid flexWrap="wrap">
            <Grid style={{ display: "flex" }}>
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "rgba(10, 102, 194, 1)",
                }}
              >
                {profile?.businessName}
              </Typography>
            </Grid>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "20px",
                marginTop: "6px",
              }}
            >
              {/* {businessProfile?.businessCategory} */}
              {businessLocation}
            </Typography>
            {businessProfile?.establishYear ? (
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                {/* <Box
                  sx={{
                    height: "6px",
                    width: "6px",
                    borderRadius: "50px",
                    backgroundColor: "grey",
                    marginRight: "8px",
                  }}
                /> */}
                <Typography
                  style={{
                    color: "rgba(68, 64, 64, 1)",
                  }}
                >
                  {new Date().getFullYear() - businessProfile?.establishYear >
                    0 &&
                    `${
                      new Date().getFullYear() - businessProfile?.establishYear
                    } Years in Business`}
                </Typography>
              </Grid>
            ) : (
              <Typography sx={{ marginBottom: "30px" }}></Typography>
            )}

            {!isEditView && (
              <Grid>
                {profile?.subscriptionType == "PREMIUM" ? (
                  <Box
                    component="img"
                    sx={{
                      height: "20px",
                      paddingTop: "3px",
                    }}
                    src={`${BASE_URL}/assets/business/web-tag-PopularImgForMobile.svg`}
                  />
                ) : (
                  <Box sx={{ height: "17px", marginTop: "8px" }}></Box>
                )}
              </Grid>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignContent: "center",
              }}
            >
              <Typography
                // variant="businessTitle"
                sx={{ display: "flex" }}
              >
                {permissions &&
                  !permissions.isViewOnly &&
                  permissions.isFreeUpdateAllowed && (
                    <Link
                      to="/edit-business"
                      style={{ fontSize: "14px", color: "#2864BE" }}
                    >
                      {" "}
                      Edit Business
                    </Link>
                  )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {isEditView ? (
        // <SendEnquiryMobileViewEdit
        //   profile={profile}
        //   isMobileView={true}
        //   isEditView={isEditView}
        // />
        ""
      ) : (
        <SendEnquiryButton
          profile={profile}
          isMobileView={true}
          isEditView={isEditView}
        />
      )}
      <ProfileDetailBox profile={profile} isEditView={isEditView} />
      {isEditView ? (
        <BusinessContactEditView
          profile={profile}
          isMobileView={true}
          PublicProfile={PublicProfile}
          permissions={permissions}
          isEditView={isEditView}
        />
      ) : (
        <BusinessContactUs
          profile={profile}
          isMobileView={true}
          PublicProfile={PublicProfile}
          permissions={permissions}
          isEditView={isEditView}
        />
      )}

      <ProfileAbout
        profile={profile}
        isEditView={isEditView}
        PublicProfile={PublicProfile}
        permissions={permissions}
      />
      <BusinessOffering
        profile={profile}
        isMobileView={true}
        PublicProfile={PublicProfile}
        permissions={permissions}
        isEditView={isEditView}
      />
      <MobileProjects
        profile={profile}
        PublicProfile={PublicProfile}
        permissions={permissions}
        isEditView={isEditView}
        projects={projects?.filter((project) => !project.isDeleted)}
      />
      <TopClient
        profile={profile}
        isMobileView={true}
        PublicProfile={PublicProfile}
        permissions={permissions}
        isEditView={isEditView}
      />
      {profile?.businessProfile?.domains?.length > 0 && (
        <Domains
          profile={profile}
          isMobileView={true}
          PublicProfile={PublicProfile}
          permissions={permissions}
          isEditView={isEditView}
        />
      )}
      {isEditView ? (
        <Grid
          container
          sx={{ mt: 3, marginBottom: "20px" }}
          direction={!isAwardExists ? "row-reverse" : "row"}
        >
          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            xl={5.5}
            style={{ marginY: "20px" }}
          >
            <Awards
              awards={profile?.awards}
              supplierId={profile?.supplierId}
              // isPublicProfile={isPublicProfile}
              permissions={permissions}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={12} xl={5.5}>
            <CertificateSection
              certificateDetails={bProfile?.certificateImages}
              supplierId={profile?.supplierId}
              // isPublicProfile={isPublicProfile}
              permissions={permissions}
            />
          </Grid>
        </Grid>
      ) : (
        AwardsCertificationArray?.length > 0 && (
          <AwardsCertificate profile={profile} isMobileView={true} />
        )
      )}
    </Grid>
  );
}

export default NewBusinessProfileMobileView;
