import React, { memo, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  CardMedia,
  Tooltip,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { CameraAlt as CameraAltIcon } from "@mui/icons-material";
import { Delete as Delete } from "@mui/icons-material";

import { SEARCH_DEFAULT_IMAGE, IMAGE_TYPE, DEFAULT_IMAGES } from "config";
import { uploadImage, deleteImage } from "actions/imageActions";
import { getFileNameByImageType } from "utils/constantUtils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ImageEditor({ type, name, inImage, supplierId, id }) {
  const dispatch = useDispatch();
  const uploadFile = useRef(type);
  const [image, setImage] = useState(null);
  const [hasUploaded, setHasUploaded] = useState(false);

  const [open, setOpen] = React.useState(false);

  // delete functions
  const [deleteOpen, DeletesetOpen] = React.useState(false);
  const DeletehandleClickOpen = () => {
    DeletesetOpen(true);
  };
  const DeletehandleClose = () => {
    DeletesetOpen(false);
  };
  const deleteImageFunc = () => {
    dispatch(deleteImage(type, supplierId, id));
    DeletesetOpen(false);
  };
  // delete functions

  const [state, setState] = useState({
    editor: "",
    allowZoomOut: false,
    cropperOpen: false,
    img: null,
    scale: 1,
    croppedImg: inImage,
  });

  let ieHeight = 200;
  let ieWidht = 200;
  let ieBorder = 50;
  let ieBorderRadius = 100;
  let ieIconButtonPosition = 0;
  let ieIconButtonPositionX = 0;
  let ieIconAlignment = "right";
  let isImageDelete = false;

  switch (type) {
    case IMAGE_TYPE.LOGO:
      ieHeight = 100;
      ieWidht = 100;
      ieBorder = 50;
      ieBorderRadius = 100;
      ieIconButtonPosition = 0;
      ieIconAlignment = "center";
      isImageDelete = inImage !== DEFAULT_IMAGES.LOGO;

      break;
    case IMAGE_TYPE.SERVICE:
      // Edit for new Project card  w=250 h=300px to 181px 126px as per figma
      ieHeight = 126;
      ieWidht = 181;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      ieIconAlignment = "right";
      break;
    case IMAGE_TYPE.PROJECT:
      // Edit for new Project card  w=375px h=250 to 302px 201px as per figma
      ieHeight = 201;
      ieWidht = 302;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      ieIconAlignment = "right";
      break;

    case IMAGE_TYPE.BANNER:
      ieHeight = 200;
      ieWidht = 1000;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -5;
      ieIconButtonPositionX = 5;
      ieIconAlignment = "right";
      isImageDelete = inImage !== DEFAULT_IMAGES.BANNER;
      break;

    case IMAGE_TYPE.SEARCH:
      break;
    case IMAGE_TYPE.AWARDS:
      ieHeight = 300;
      ieWidht = 300;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -1;
      ieIconButtonPositionX = 15;
      ieIconAlignment = "right";
      break;
    case IMAGE_TYPE.CERTIFICATE:
      ieHeight = 300;
      ieWidht = 300;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = -1;
      ieIconButtonPositionX = 15;
      ieIconAlignment = "right";
      break;

    case IMAGE_TYPE.CLIENT:
      ieHeight = 100;
      ieWidht = 100;
      ieBorder = 50;
      ieBorderRadius = 0;
      ieIconButtonPosition = 0;
      ieIconButtonPositionX = 10;
      ieIconAlignment = "center";
      break;

    default:
      break;
  }

  useEffect(() => {
    setState({ ...state, croppedImg: inImage });
  }, [inImage]);

  const handleClickOpen = (e) => {
    setOpen(true);
    // handlePhoto(e);
  };
  const handleDeleteOpen = (e) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setState({ ...state, scale });
  };

  const handleAllowZoomOut = (e) => {
    setState({ ...state, allowZoomOut: e.target.value });
  };

  const handleFileChange = (e) => {
    // console.log("change");
    e.preventDefault();
    const newImage = e.target.files[0];
    setImage(newImage);
    setHasUploaded(true);
    // let url = window.URL.createObjectURL(e.target.files[0]);
    // setState({
    //   img: url,
    //   cropperOpen: true,
    // });
  };

  const handleSave = (e) => {
    if (state.editor) {
      // const canvasScaled = state.editor.getImageScaledToCanvas();
      //const croppedImg = canvasScaled.toDataURL();

      const canvas = state.editor.getImage();
      const croppedImg = canvas.toDataURL();

      setState({
        ...state,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
      });

      // console.log("handleSave: type: ", type, " name:", name, "supplierId: ", supplierId);

      let fileName = getFileNameByImageType(type, name);
      fetch(croppedImg)
        .then((res) => res.blob())
        .then((blob) => new File([blob], fileName, { type: blob.type }))
        .then((file) =>
          dispatch(uploadImage(file, type, name, supplierId, id))
        );
    }
  };

  const handleCancel = () => {
    setState({ croppedImg: inImage, cropperOpen: false });
    window.location.reload(true);
  };

  const setEditorRef = (editor) => {
    state.editor = editor;
  };
  const handlePhoto = (e) => {
    e.preventDefault(); // TODO_SP need to check why preventDefault was called
    //emptyCache();
    uploadFile.current.click();
  };

  const handleDefaultImageDisplay = (type) => {
    switch (type) {
      case IMAGE_TYPE.SERVICE:
        return (
          // Edit for new Project card  300px to 126px as per figma
          <CardMedia
            component="img"
            maxHeight="300px"
            image={state.croppedImg}
          />
        );
        break;

      case IMAGE_TYPE.PROJECT:
        return (
          <CardMedia component="img" height="201px" image={state.croppedImg} />
          // Edit for new Project card  375px to 201px
        );
        break;

      case IMAGE_TYPE.LOGO:
        // console.log("ImageEditor: ", state.croppedImg);
        return (
          <Avatar
            onClick={handlePhoto}
            src={state.croppedImg}
            style={{ height: 80, width: 80 }}
          />
        );
        break;

      case IMAGE_TYPE.BANNER:
        return (
          <CardMedia
            component="img"
            height="194px"
            onClick={handlePhoto}
            image={inImage}
          />
        );
        break;

      case IMAGE_TYPE.CERTIFICATE:
        return (
          <CardMedia
            component="img"
            height="200px"
            // widht="200px"
            onClick={handlePhoto}
            image={state.croppedImg}
          />
        );
        break;
      case IMAGE_TYPE.AWARDS:
        return (
          <CardMedia
            component="img"
            height="200px"
            // widht="200px"
            onClick={handlePhoto}
            image={state.croppedImg}
          />
        );
        break;
      case IMAGE_TYPE.CLIENT:
        return (
          <CardMedia component="img" height="200px" image={state.croppedImg} />
        );
        break;

      case IMAGE_TYPE.SEARCH:
        return (
          <CardMedia component="img" height="120px" image={state.croppedImg} />
        );
        break;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: type === IMAGE_TYPE.LOGO ? "center" : "left",
          alignItems: type === IMAGE_TYPE.LOGO ? "center" : "left",
        }}
      >
        {handleDefaultImageDisplay(type)}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: ieIconAlignment,
          alignItems: type === IMAGE_TYPE.LOGO ? "center" : "right",
        }}
      >
        <Tooltip title="Select Image for Preview">
          <IconButton
            id="uploadImageBtn"
            color="primary"
            aria-label="preview picture"
            component="label"
            sx={{
              backgroundColor: "white",
              mt: ieIconButtonPosition,
              ml: ieIconButtonPositionX,
            }}
          >
            <input
              onClick={handleClickOpen}
              ref={uploadFile}
              type="file"
              accept="image/*"
              id={`contained-button-file-${type}`}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <CameraAltIcon style={{ color: "#2864BE", fontSize: "20px" }} />
          </IconButton>
        </Tooltip>
        {isImageDelete && (
          <Tooltip title="Delete image">
            <IconButton
              onClick={DeletehandleClickOpen}
              id="uploadImageBtn"
              color="primary"
              aria-label="preview picture"
              component="label"
              sx={{
                backgroundColor: "white",
                mt: ieIconButtonPosition,
              }}
            >
              <Delete style={{ color: "#2864BE", fontSize: "20px" }} />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <Dialog
        open={deleteOpen}
        onClose={DeletehandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={DeletehandleClose}
           sx={{backgroundColor: "#FD6600",
           color:"white",
           "&:hover": {
             backgroundColor: "#FD6600",
             transition: "background-color 0.3s, box-shadow 0.3s",
             boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
           }
           }}
          >Cancel</Button>
          <Button onClick={deleteImageFunc} autoFocus
           sx={{backgroundColor: "#FD6600",
           color:"white",
           "&:hover": {
             backgroundColor: "#FD6600",
             transition: "background-color 0.3s, box-shadow 0.3s",
             boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
           }
           }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {image && hasUploaded && (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Neevay Image Editor
          </BootstrapDialogTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: 500,
            }}
          >
            <AvatarEditor
              ref={setEditorRef}
              image={image}
              width={ieWidht}
              height={ieHeight}
              border={ieBorder}
              quality={1}
              borderRadius={ieBorderRadius}
              color={[255, 255, 255, 0.8]}
              scale={state.scale}
              style={{
                marginBottom: 10,
                paddingBottom: 0,
                backgroundColor: "#47e59e",
              }}
            />
            {image && (
              <>
                Zoom In:
                <input
                  name="scale"
                  type="range"
                  onChange={(e) => {
                    handleScale(e);
                    handleAllowZoomOut();
                  }}
                  min="0.1"
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
                <div style={{ marginBottom: "10px" }}>
                  {""}
                  <Button
                    variant="contained"
                    sx={{backgroundColor: "#FD6600",
                    mr: 2,
                    color:"white",
                    "&:hover": {
                      backgroundColor: "#FD6600",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                    }
                    }}
                    onClick={() => {
                      handleCancel();
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  {""}
                  <Button
                    sx={{backgroundColor: "#FD6600",
                    ml: 1,
                    color:"white",
                    "&:hover": {
                      backgroundColor: "#FD6600",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                    }
                    }}
                    variant="contained"
                    onClick={() => {
                      handleSave();
                      handleClose();
                    }}
                  >
                    Save
                  </Button>
                </div>
                {""}
              </>
            )}
          </div>
        </BootstrapDialog>
      )}
    </div>
  );
}
ImageEditor.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inImage: PropTypes.object.isRequired,
  supplierId: PropTypes.string,
  id: PropTypes.string,
};

export default ImageEditor;
