import { Button, Grid } from "@mui/material";
import { searchByPage } from "actions/searchActions";
import React, { useEffect, useState } from "react";
import { getSelectedFacetString } from "utils/searchUtils";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch } from "react-redux";

const SearchPagination = ({ search, regionString, currentPage, totalPages, setCurrentPage, setTotalPages }) => {
  const searchType = search?.searchType;
  const searchKeyWord = search?.searchKeyWord;
  const numFound = search?.numFound;
  const selectedFacets = search?.selectedFacets;

  const dispatch = useDispatch();

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(()=>{
    setCurrentPage(1)
    
    },[searchKeyWord])
  useEffect(() => {
    const filterStr = getSelectedFacetString(selectedFacets);
    dispatch(searchByPage(searchKeyWord, regionString, filterStr, searchType, currentPage));
  }, [currentPage, regionString, selectedFacets, searchType, dispatch]);

  useEffect(() => {
    setTotalPages(Math.ceil(numFound / 60));
  }, [numFound, setTotalPages]);
  return (
    <Grid
      spacing={2}
      direction="row"
      justifyContent="space-evenly"
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          fontSize: "13px",
          lineHeight: "18px",
          fontWeight: "700",
          color: "black",
        }}
        disabled={currentPage === 1}
        onClick={handlePrevClick}
      >
        <ChevronLeftIcon />
        Previous
      </Button>
      <span>
        {currentPage} /{totalPages}
      </span>
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          fontSize: "13px",
          lineHeight: "18px",
          fontWeight: "700",
          color: "black",
        }}
        disabled={currentPage === totalPages}
        onClick={handleNextClick}
      >
        Next
        <ChevronRightIcon />
      </Button>
    </Grid>
  );
};

export default SearchPagination;
