import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Topbar, Sidebar, } from './components';
import pages from '../navigation';
import FooterNew from 'views/Home/components/FooterNew';
import { useLocation } from 'react-router';
import { FloatingButton, GlobalNavbar } from 'views/Home 3.0/components';

const Main = ({ children, colorInvert = false, bgcolor = 'transparent' }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });
  const location = useLocation();
  const hideFooterPaths = ['/search']; // Define the paths where you want to hide the footer

  const shouldDisplayFooter = !hideFooterPaths.includes(location.pathname);

  return (
    <Box>
      {/* <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          // backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
          backgroundColor: "#262728",
        }}
        elevation={trigger ? 1 : 0}
      >
        <Topbar
          onSidebarOpen={handleSidebarOpen}
          pages={pages}
          // colorInvert={trigger ? false : colorInvert}
        />
         </AppBar> */}
         <GlobalNavbar/>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        {children}
        <Divider />
      </main>
      {shouldDisplayFooter ?null: <FooterNew />}
      <FloatingButton/>
    </Box>
  );
};


Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;
