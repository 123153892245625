import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import {
  Box,
  Card,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { Delete } from "@mui/icons-material";

import { deleteCertificate } from "actions/certificateAction";

import AddCertificate from "./AddCertificate";
import { DEFAULT_IMAGES } from "config";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function CertificateSection({
  supplierId,
  certificateDetails,
  isPublicProfile,
  permissions,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  
  const [open,SetOpen]=useState()
  const maxSteps = certificateDetails?.length;
  const [autoPlayState, setAutoPlayState] = useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleDeleteCertificate = (
    supplierId,
    certificateId,
    certificateName
  ) => {
    dispatch(deleteCertificate(supplierId, certificateId, certificateName));
    navigate("/my-business");
  };

  const handleClickOpen = () => {
    SetOpen(true);
  };

  const handleClose = () => {
    SetOpen(false);
  };

  const isCertificateExists = certificateDetails?.length > 0 ? true : false;

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10}>
        <AddCertificate
          sx={{ mt: 4 }}
          supplierId={supplierId}
          isPublicProfile={isPublicProfile}
          permissions={permissions}
          isCertificateExists={isCertificateExists}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={10} sx={{ mt: 2 }}>
        <Card sx={{ boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.5)" }}>
          {certificateDetails && certificateDetails.length > 0 && (
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents={false} // Disable swiping with mouse events
              interval={3000} // Auto-play interval
              autoplay={autoPlayState}
            >
              {certificateDetails?.map((data) => (
                <Grid container key={data.certificateId}>
                  <Grid item md={12} xs={12} sm={12}>
                    <Paper
                      square
                      elevation={0}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: 50,
                        pl: 2,
                        bgcolor: "background.default",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {permissions &&
                          !permissions.isViewOnly &&
                          permissions.isUpdateAllowed && (
                            <Typography variant="body2">
                              <Tooltip title="Delete">
                                <div
                                  disabled={!permissions?.isOwner}
                                  sx={{ color: "primary.main", ml: -2 }}
                                  onClick={() =>
                                    handleClickOpen(
                                      supplierId,
                                      data.certificateId,
                                      data.certificateName
                                    )
                                  }
                                >
                                  <Delete
                                    style={{
                                      fontSize: "20px",
                                      color: "#2864BE",
                                      marginRight: "10px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </Typography>
                          )}
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid container md={12} xs={12} sm={12}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          justifyContent: "center",
                        }}
                        onMouseEnter={() => setAutoPlayState(false)}
                        onMouseLeave={() => setAutoPlayState(true)}
                      >
                        <CardMedia
                          sx={{
                            height: "300px",
                            width: "300px",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: "10px",
                          }}
                          component="img"
                          image={
                            data.certificateImage
                              ? `${BASE_URL}/${data.certificateImage}`
                              : DEFAULT_IMAGES.CERTIFICATE
                          }
                          alt="Certificate Image not found"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ textAlign: "center" }}
                  >
                    <Typography
                      variant="body2"
                      pl={3}
                      sx={{ fontWeight: "700px" }}
                    >
                      {data.certificateTitle}
                    </Typography>
                  </Grid>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                      <Typography>Are you sure want to delete ?</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} variant="contained"
                       sx={{backgroundColor: "#FD6600",
                       color:"white",
                       "&:hover": {
                         backgroundColor: "#FD6600",
                         transition: "background-color 0.3s, box-shadow 0.3s",
                         boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                       }
                       }}
                      >
                        Cancel
                      </Button>
                      <Button
                      sx={{backgroundColor: "#FD6600",
                      color:"white",
                      "&:hover": {
                        backgroundColor: "#FD6600",
                        transition: "background-color 0.3s, box-shadow 0.3s",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                      }
                      }}
                        onClick={() =>
                          handleDeleteCertificate(
                            supplierId,
                            data.certificateId,
                            data.certificateName
                          )
                        }
                        variant="contained"
                      >
                        <Box onClick={handleClose}> Submit</Box>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              ))}
            </AutoPlaySwipeableViews>
          )}
          {certificateDetails?.length > 0 && (
            <MobileStepper
              sx={{
                ".MuiMobileStepper-dotActive": { backgroundColor: "#2979FF" },
              }}
              onChangeIndex={handleStepChange}
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  sx={{ color: "secondary.main" }}
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  sx={{ color: "secondary.main" }}
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight sx={{ color: "secondary.main" }} />
                  ) : (
                    <KeyboardArrowLeft sx={{ color: "secondary.main" }} />
                  )}
                  Back
                </Button>
              }
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

CertificateSection.defaultProps = {
  isPublicProfile: true,
};

CertificateSection.propTypes = {
  isPublicProfile: PropTypes.bool,
  certificateDetails: PropTypes.array.isRequired,
  supplierId: PropTypes.number,
  permissions: PropTypes.object,
};

export default CertificateSection;
