import React from "react";
import { dateFormatter } from "utils/formatUtils";

const statusColor = {
  closed: "#FDE8E8",
  deleted: "#00FFFF",
  canceled: "#808080",
  hold: "#800080",
  open: "#BFFFBA",
  draft: "#FFFCB1",
  expired: "#9B1C1C",
  trash: "#9B1C1C",
};
const OverviewInfo = ({ data }) => {

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  
  const date = new Date(data?.validityDate);
  const dateBlocks = date
    .toLocaleDateString("en-GB", {
      weekday: "long",
      month: "long",
      year: "numeric",
    })
    .split(" ");
  const formattedDate = `${dateBlocks[2]}, ${dateBlocks[0]} ${dateBlocks[1]}`;

  const mappingData = [
    {
      label: "RFQ Subject",
      value: data?.name,
    },
    {
      label: "Requirement Type",
      value: capitalizeFirstLetter(data?.rfqType),
    },
    {
      label: "Status",
      value: (
        <div className="flex items-center gap-x-1">
          <div
            className="p-2 rounded-full border-solid"
            style={{
              borderWidth: "0.5px",
              background: statusColor[data?.status.toLowerCase()],
            }}
          />
          {data?.status}
        </div>
      ),
    },
    // {
    //   label: "Delivery Date",
    //   value: data?.validityDate ? dateFormatter(data?.validityDate) : "NA",
    // },
    // {
    //   label: "Indent Number",
    //   value: data?.indentId ? data?.indentId : "NA",
    // },
    // {
    //   label: "Credit Period",
    //   value: data?.creditPeriod ? data?.creditPeriod : "NA",
    // },
    {
      label: "Project Name",
      value: data?.projectName,
    },
    {
      label: "Project Location",
      value: (
        <div className="flex items-center gap-x-2">
          <p className="w-4/5 cursor-pointer truncate" title={data?.location}>
            {data?.location}
          </p>
        </div>
      ),
    },
    // {
    //   label: "Client Name",
    //   value: data?.userBusinessName ,
    // },
    // {
    //   label: "Estimated Value",
    //   value: `Rs. ${data?.estimatedValue}`,
    // },
  ];

  const getStyledSet = (label, value, index) => (
    <div key={label + index} className="">
      <p className="block text-xs font-normal text-[#6B7280]">{label}</p>
      <b className="text-[#111928] text-xs">{value}</b>
    </div>
  );

  return (
    <div className="w-full" style={{ fontFamily: "goldman_sans" }}>
      <div className="grid grid-cols-3 gap-4">
        <h2 className="text-gray-900 text-sm font-bold mb-1 col-span-3">
          General Information
        </h2>
        {mappingData?.map((item, index) =>
          getStyledSet(item.label, item.value, index)
        )}
      </div>
    </div>
  );
};

export default OverviewInfo;
