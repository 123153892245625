import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BusinessListData from "./BusinessListData";

const BusinessData = ({ search, ItemClick }) => {
  const { url } = useParams(); //get bussines url from link

  const [listOfCompanys, setListOfCompanys] = useState(search?.results);

  const result = search?.results;

  useEffect(() => {
    // if (data?.length > 0) {
    //   const reorderedData = data?.reduce((acc, obj) => {
    //     if (obj.url === url) {
    //       acc.unshift(obj);
    //     } else {
    //       acc.push(obj);
    //     }
    //     return acc;
    //   }, []);
    //   setListOfCompanys(reorderedData);
    //   // }
    // }
      setListOfCompanys(result);

  }, [url, result]);

  return (
    <Grid
      sx={{
        backgroundColor: "rgba(239, 247, 250, 1)",
        cursor: "pointer",
        overflow: "hidden",
      }}
    >
      {listOfCompanys?.map((item, index) => (
        <BusinessListData
          data={item}
          index={index}
          url={url}
          // logoSrcUrl={logoSrcUrl}
          ItemClick={ItemClick}
        />
      ))}
    </Grid>
  );
};

export default BusinessData;
