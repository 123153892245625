import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "utils/axios";
import { ErrorMessage, Field, useFormikContext } from "formik";
import QuillEditorComponent from "./QuillEditorComponent";

const RfqDetailsSection = ({
  // selectedFilesBase,
  // setSelectedFilesBase,
  fieldName,
  updateFormValues = (updatedValues) => { },
}) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
  const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
  const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState([]);
  const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
  const [selectedFilesBase, setSelectedFilesBase] = useState(values?.attachments || []);



  // const { values } = useFormikContext();

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 mb in bytes

  const handleFileUploadClick = (event) => {
    return (event.target.value = "");
  };

  const handleFileUpload = (event) => {
    const currentValues = { ...values };
    updateFormValues(currentValues);
    const files = event.target.files;
    // count of current seleted files
    const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => {
      return acc + obj.size;
    }, 0);

    // count of prev selected and current selected files
    const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce((acc, obj) => {
      return acc + obj.FileSize;
    }, sizeOfSelectedFiles);
    // Validate file sizes and perform file upload as needed
    if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
      setAttachmentsSkeleton((preVal) => [...preVal, ...Array.from(files)]);

      const convertedFilesPromises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);

          const reader = new FileReader();

          reader.onload = (e) => {
            const fileContent = e.target.result;
            const encryptedContent = btoa(fileContent); // Encrypt file content in Base64

            // Fallback mechanism for .dwg files
            let contentType = file.type;
            if (!contentType && file.name.endsWith(".dwg")) {
              contentType = "application/acad"; // Common MIME type for DWG files
            }

            // Include fileType, fileName, and encryptedContent in your request payload
            const payload = {
              Name: file.name,
              Content: encryptedContent,
              ContentType: contentType,
              FileSize: file.size,
            };
            resolve(payload); // Resolve the promise with the payload for demonstration purposes
          };

          reader.readAsBinaryString(file);
        });
      });

      const attachmentBlobLink = (convertedFiles) => {
        axios
          .post("/api/rfqs/upload/attachments", convertedFiles)
          .then((response) => {
            setSelectedFilesBase((prev) => {
              const updatedFiles = [...prev, ...response.data]; // Combine previous files with new ones
              setFieldValue("attachments", updatedFiles); // Update formik state with the combined attachments
              return updatedFiles; // Return the updated files for the state
            })
            setSelectedFilesTotalSize((preVal) => [
              ...preVal,
              ...convertedFiles,
            ]);
            setAttachmentsSkeleton([]);
          })
          .catch((error) => {
            setAttachmentsSkeleton([]);
            setAttachmentsUploadsErrors(error.message);
            setTimeout(() => {
              setAttachmentsUploadsErrors("");
            }, 5000);
            console.error(error);
          });
      };

      Promise.all(convertedFilesPromises)
        .then((convertedFiles) => {
          attachmentBlobLink(convertedFiles);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setOpenFilesSizeError(true);
      setTimeout(() => {
        setOpenFilesSizeError(false);
      }, 6000);
    }
  };

  const handleDeleteSelectedFile = (name, event) => {
    const currentValues = { ...values };
    updateFormValues(currentValues);
    event.preventDefault(); // use becuse when we click on chip dont one link
    // setSelectedFilesBase((preVal) =>
    //   preVal.filter((data) => data.Name !== name)
    // );
    const updatedAttachments = currentValues.attachments.filter((data) => data.Name !== name);

    // Set the updated attachments back to Formik's state
    setFieldValue("attachments", updatedAttachments);
    setSelectedFilesTotalSize((preVal) =>
      preVal.filter((data) => data.Name !== name)
    ); // this is use when some file are delet then the data also delet from this array becase of change in total size of selected  file

    setSelectedFilesBase((prev) =>
      prev.filter((data) => data.Name !== name)
    );
  };
  const handleCloseFileSizeError = () => {
    setOpenFilesSizeError(false);
  };
  return (
    <div className="w-full">
      {/* {!values?.acceptCounterTerms && <h3 className="text-[#434343] font-medium pt-4">Attachments</h3>} */}

      {/* <label
        className="block text-base font-bold text-gray-700 p-6 pb-0"
        htmlFor="rfqDetails"
      >
        RFQ Details<span className="text-red-500">*</span>
      </label> */}
      {/* {values?.acceptCounterTerms && <span> */}
        <Field name={fieldName} >
          {({ field, form }) => (
            <QuillEditorComponent
              fieldName={fieldName}
              placeholder="Please write your payment terms here (Rich Text)"
              field={field}
              form={form}
              className="placeholder:text-sm"
            />
          )}
        </Field>
        <div id="rfqDetails"></div>{" "}
        <ErrorMessage
          name="rfqDetails"
          component="div"
          className="text-red-500 text-xs ml-2"
        />
      {/* </span>
      } */}

      <div className="flex items-center">
        <div>
          <input
            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify the file types you want to allow
            id="file-upload"
            type="file"
            disabled={attachmentsSkeleton.length > 0}
            style={{ display: "none" }}
            onClick={handleFileUploadClick}
            onChange={handleFileUpload}
          />
          <label htmlFor="file-upload">
            <div
              className={`flex items-center px-2 py-1 mt-6 rounded-2xl cursor-pointer ${attachmentsSkeleton.length > 0
                ? "bg-gray-400 text-white"
                : "bg-white text-black"
                } ${attachmentsSkeleton.length > 0 && "cursor-wait"} shadow-md`}
            >
              <img className="mr-2" src="https://storagereponeevaydevcdn.blob.core.windows.net/business/files.svg" alt="Attachment Icon" />
              <p className="text-sm">Add Attachment</p>
            </div>
          </label>
        </div>
        <p className="text-xs ml-2">Accepted files: Excel, PDF or Image</p>
      </div>
      {attachmentsSkeleton.length > 0 && (
        <p className="text-xs pl-3">
          Your file is being scanned for upload, be patient and continue to fill
          the rest of the form.
        </p>
      )}
      <div className="px-3">
        {/* Display the selected file names */}
        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {selectedFilesBase.map((file, index) => (
            <a
              href={file.Content}
              target="_blank"
              rel="noopener noreferrer"
              className="border border-gray-300 px-3 py-1 rounded-full flex m-2"
            >
              <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                {file.Name}
              </span>
              <span
                className="ml-2 cursor-pointer"
                onClick={(event) => handleDeleteSelectedFile(file.Name, event)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 48 48"
                >
                  <circle cx="24" cy="24" r="22" fill="gray" />
                  <line
                    x1="15"
                    y1="15"
                    x2="33"
                    y2="33"
                    stroke="white"
                    strokeWidth="2"
                  />
                  <line
                    x1="33"
                    y1="15"
                    x2="15"
                    y2="33"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
              </span>
            </a>
          ))}
          {attachmentsSkeleton.map(() => (
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: "20px", margin: "4px" }}
              width={150}
              height={32}
            />
          ))}
        </ul>
      </div>
      <div>
        {attachmentsUploadsErrors && (
          <div className="mt-3">
            <p className="text-red-500 font-semibold text-base mt-14">
              {attachmentsUploadsErrors}
            </p>
          </div>
        )}
      </div>
      <div
        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
          }`}
      >
        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
          <p>Total size of attachments is not more than 10 MB</p>
          <button
            onClick={handleCloseFileSizeError}
            className="px-4 py-2 focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RfqDetailsSection;
