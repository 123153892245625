
export const dateFormatter = dbFormatDate => {
  let formatedDate = dbFormatDate;
  const options = {
    // weekday: 'short',
    year: '2-digit',
    month: 'short',
    day: '2-digit',
    // hour: 'numeric',
    // minute: 'numeric',
    // timeZone: 'Asia/Kolkata',
  };
  if (typeof dbFormatDate === 'string') {
    formatedDate = new Date(dbFormatDate).toLocaleString('en-IN', options);
  }

  return formatedDate;
};


export const moneyFormatter = amount => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'INR',
  });

  return formatter.format(amount);
};