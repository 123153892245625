import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
Font.register({
    family: "Cambria",
    fonts: [
      {
        src: "/assets/fonts/cambria.ttf",
      },
    ],
  });
const styles = StyleSheet.create({
    titleContainer: {
        marginTop: 24,
    },
    reportTitle: {
        color: 'black',
        // letterSpacing: 4,
        fontSize: 15,
        textAlign: 'center',
        textTransform: 'uppercase',
        // fontWeight: 'bold'
        // fontWeight:"bold",
        fontFamily: "Cambria",
    }
});

const InvoiceTitle = ({ title }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
);

export default InvoiceTitle;