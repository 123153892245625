import React from 'react';
const cities = [
  "Adoni", "Agra", "Ahmedabad", "Ahmednagar", "Ajmer", "Aligarh", "Almora",
  "Amravati", "Amreli", "Amritsar", "Amroha", "Aurangabad", "Ayodhya", "Ballia",
  "Bareilly", "Bengaluru", "Bhopal", "Bidar", "Bijapur", "Bikaner", "Chennai",
  "Coimbatore", "Dehradun", "Dehri", "Deogarh", "Dhubri", "Durg", "Delhi",
  "Faridabad", "Firozabad", "Firozpur", "Ghaziabad", "Ghazipur", "Goa", "Guntur",
  "Gurugram", "Guwahati", "Gwalior", "Haridwar", "Hazaribag", "Hyderabad", "Imphal",
  "Indore", "Jabalpur", "Jaipur", "Jalandhar", "Jorethang", "Jorhat", "Kanpur",
  "Kashipur", "Kochi", "Kolkata", "Kota", "Kolhapur", "Latur", "Lucknow", "Ludhiana",
  "Manali", "Mumbai", "Nagpur", "Nanded", "Nandurbar", "Nashik",
  "Nellore", "Nizamabad", "Noida", "Nurpur", "Osmanabad", "Palampur", "Pali", "Patna", "Prayagraj", "Pune", "Purulia", "Raipur", "Rajkot", "Ranchi",
  "Rishikesh", "Rohru", "Satara", "Shimla", "Solapur", "Sonipat", "Sundarnagar",
  "Surat", "Tezpur", "Thane", "Thiruvananthapuram", "Tirupati","Udaipur", "Ujjain", "Vadodara", "Varanasi", "Vasai-Virar", "Vijayanagara",
  "Vijayawada", "Visakhapatnam", "Wardha", "Yavatmal"
];
// Function to split the cities into columns
const splitIntoColumns = (arr, columns) => {
  const perColumn = Math.ceil(arr.length / columns);
  return new Array(columns).fill().map((_, columnIndex) =>
    arr.slice(columnIndex * perColumn, (columnIndex + 1) * perColumn)
  );
};
const Locationsfortab = () => {
  // Split the cities into 4 columns
  const columns = splitIntoColumns(cities, 4);
  return (
    <div>
      <div className='flex justify-around text-sm text-white md:pt-[43px] '>
        {columns.map((column, index) => (
          <ul key={index} className='space-y-2'>
            {column.map((city, i) => (
              <li key={i}>{city}</li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};
export default Locationsfortab;