import produce from 'immer';

import{
    GET_LEAD_SUCCESS
} from 'actions/crmAction'

const initialState = {
    leadId:null,
}

const crmReducer = (state = initialState, action) => {
    if(action.type == GET_LEAD_SUCCESS){
       const {getLead} =  action.payload;
       return produce(state,(draft)=>{
        draft.leadId = getLead
       })
    }
    return state;
    }

export default crmReducer;    