import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";

import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";

import ProfileForm from "./ProfileForm";
import ProfileImageUploader from "./ProfileImageUploader";
import { profileImageUpload } from "actions/profileImageAction";
import { getUrl } from "actions/businessActions";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  if (!userProfile) {
    navigate("/register");
  } else if (userProfile?.userId > 0 && userProfile?.name) {
    } else {
      navigate("/register/user");
    }

  const business = useSelector((state) => state.business);


  const handleImageUplaod = (file, type, name,) => {
    // console.log(
    //   "handleImageUplaod:profileImageUpload:file",
    //   file,
    //   "type",
    //   type,
    //   "name",
    //   name
    // );
    dispatch(profileImageUpload(file, type, name, business?.supplierId));
  };

  const handleSubmitSuccess = () => {
    //navigate("/shortlisted-profile");
    navigate("/user-dashboard");
  };
  const currentLocation = useLocation()
  // console.log("Profile: userProfile:", userProfile);
  useEffect(() => {
    try {
      dispatch(getUrl(currentLocation.pathname));
    } catch (error) {
      // console.log(error);
    }
  }, [])
  return (
    <DashboardLayout path="/user-profile">
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid
              md={12}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <ProfileImageUploader
                type="profileImage"
                profileImage={handleImageUplaod}
                inImage={`${BASE_URL}/${userProfile?.imgUrl}`}
              />
            </Grid>
            <Typography
              variant="h5"
              // color={"primary.main"}
              color={"#FD6600"}
              fontWeight="bold"
              mb={2}
            >
              Update Your Profile
            </Typography>
            <Divider />
            <ProfileForm profileData={userProfile} onSubmitSuccess={handleSubmitSuccess} />
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
  );
}
Profile.propTypes = {
  profileImage: PropTypes.func,
};

export default Profile;
