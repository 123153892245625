import React, { useState, useEffect } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import {
  Grid,
  Typography,
  Button,
  Skeleton,
  Chip,
  Tooltip,
  Snackbar,
  Box,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resendRequirements } from "actions/requirementsAction";
import axios from "../../../utils/axios";

const AddAttachmentSection = ({
  selectedUserId,
  desc,
  enquiry,
  handleClose,
  setOpen,
  seterrorMessage,
  emails,
  ccEmails,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const MAX_FILE_SIZE = 10 * 1024 * 1024;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesBase, setSelectedFilesBase] = useState([]);
  const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
  const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
  const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
  const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState([]);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const RFQ = useSelector((state) => state.requirement);
  const currentPage = RFQ?.currentPage;

  const handleSubmit = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    // Get the components of the time
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    // Format the time as a string with leading zeros
    const formattedHours = String(hours % 12 || 12).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    // Format the time as a string
    const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;

    // Combine date and time
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;

    const additionalNote = {
      //we are send this string in <p></p> because of we send desc in parser html
      sentByEmail: userProfile?.email,
      senderName: userProfile?.name,
      senderUserId: userProfile?.userId,
      desc: desc === "" ? "<p>This is gentle reminder</p>" : desc,
      file: selectedFiles,
      createdAt: formattedDateTime,
      emails: emails,
      ccEmails: ccEmails,
    };
    try {
      await dispatch(
        resendRequirements(
          selectedUserId,
          enquiry?.rfqId,
          enquiry.supplierId,
          additionalNote,
          currentPage
        )
      );
      handleClose();
      setOpen(true);
    } catch (error) {
      seterrorMessage(error.message);
    }
  };

  const handleFileUploadClick = (event) => {
    return (event.target.value = "");
  };
  const handleCloseFileSizeError = () => {
    setOpenFilesSizeError(false);
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    // count of current seleted files
    const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => {
      return acc + obj.size;
    }, 0);

    // count of prev selected and current selected files
    const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce((acc, obj) => {
      return acc + obj.FileSize;
    }, sizeOfSelectedFiles);
    // Validate file sizes and perform file upload as needed
    if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
      setAttachmentsSkeleton((preVal) => [...preVal, ...Array.from(files)]);

      const convertedFilesPromises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);

          const reader = new FileReader();

          reader.onload = (e) => {
            const fileContent = e.target.result;
            const encryptedContent = btoa(fileContent); // Encrypt file content in Base64

            // Fallback mechanism for .dwg files
            let contentType = file.type;
            if (!contentType && file.name.endsWith(".dwg")) {
              contentType = "application/acad"; // Common MIME type for DWG files
            }

            // Include fileType, fileName, and encryptedContent in your request payload
            const payload = {
              Name: file.name,
              Content: encryptedContent,
              ContentType: contentType,
              FileSize: file.size,
            };
            resolve(payload); // Resolve the promise with the payload for demonstration purposes
          };

          reader.readAsBinaryString(file);
        });
      });

      const attachmentBlobLink = (convertedFiles) => {
        axios
          .post("/api/rfqs/upload/attachments", convertedFiles)
          .then((response) => {
            setSelectedFilesBase((preVal) => [...preVal, ...response?.data]);
            setSelectedFiles((preVal) => [...preVal, ...response?.data]);
            setSelectedFilesTotalSize((preVal) => [
              ...preVal,
              ...convertedFiles,
            ]);
            setAttachmentsSkeleton([]);
          })
          .catch((error) => {
            setAttachmentsSkeleton([]);
            setAttachmentsUploadsErrors(error.message);
            setTimeout(() => {
              setAttachmentsUploadsErrors("");
            }, 5000);
            console.error(error);
          });
      };

      Promise.all(convertedFilesPromises)
        .then((convertedFiles) => {
          attachmentBlobLink(convertedFiles);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setOpenFilesSizeError(true);
    }
  };

  const handleDeleteSelectedFile = (name, event) => {
    event.preventDefault(); // use becuse when we click on chip dont one link
    setSelectedFilesBase((preVal) =>
      preVal.filter((data) => data.Name !== name)
    );
    setSelectedFiles((preVal) => preVal.filter((data) => data.Name !== name));
    setSelectedFilesTotalSize((preVal) =>
      preVal.filter((data) => data.Name !== name)
    ); // this is use when some file are delet then the data also delet from this array becase of change in total size of selected  file
  };
  return (
    <>
      {!isMd && (
        <Grid
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          mt="18px"
        >
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <Grid>
              <input
                accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify the file types you want to allow
                id="file-upload"
                type="file"
                disabled={attachmentsSkeleton.length > 0}
                style={{ display: "none" }}
                onClick={handleFileUploadClick}
                onChange={handleFileUpload}
              />
              <label htmlFor="file-upload">
                <Grid
                  sx={{
                    width: "154px",
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                    padding: "8px",
                    cursor: attachmentsSkeleton.length > 0 && "wait",
                    backgroundColor:
                      attachmentsSkeleton.length > 0 ? "grey" : "white",
                    color: attachmentsSkeleton.length > 0 ? "white" : "black",
                    // boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <AttachFileIcon
                    sx={{
                      width: "0.7em",
                      ml: "2px",
                    }}
                  />
                  <Typography>Add Attachment</Typography>
                </Grid>
              </label>
            </Grid>
            <Typography sx={{ color: "#707070" }}>
              You can attach any supporting document here
            </Typography>
          </Grid>

          <Button
            disabled={attachmentsSkeleton.length > 0}
            type="submit"
            onClick={handleSubmit}
            sx={{
              borderRadius: "17px",
              padding: "8px 20px",
              height: "34px",
              color: "white",
              backgroundColor: "#002E33",
              "&:hover": {
                backgroundColor: "#002E33",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                color: "white",
                transition: "background-color 0.3s, box-shadow 0.3s",
              },
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: 500, color: "white" }}
            >
              Resend Enquiry
            </Typography>
            {/* <SendIcon
              sx={{
                width: "0.7em",
                ml: "6px",
              }}
            /> */}
          </Button>
        </Grid>
      )}
      {!isMd && (
        <>
          {attachmentsUploadsErrors && (
            <Box mt={3}>
              <Typography color="red" fontWeight="12px" margin="14px">
                {" "}
                {attachmentsUploadsErrors}
              </Typography>
            </Box>
          )}
          {/*  */}
          {attachmentsSkeleton.length > 0 && (
            <Typography fontSize={"12px"} paddingLeft={"12px"}>
              Your file is being scanned for upload, be patient and continue to
              fill rest of the form.
            </Typography>
          )}

          <Grid sx={{ marginY: "10px" }}>
            {/* Display the selected file names */}
            <ul style={{ display: "flex", flexWrap: "wrap" }}>
              {selectedFiles.map((file, index) => (
                <Tooltip title={file.Name}>
                  <Chip
                    label={file.Name}
                    clickable
                    component="a"
                    href={file.Content}
                    target="_blank"
                    rel="noopener noreferrer" // Recommended for security and performance reasons
                    variant="outlined"
                    onDelete={(event) =>
                      handleDeleteSelectedFile(file.Name, event)
                    }
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "4px",
                      justifyContent: "space-between",
                      width: "145px",
                      padding: "6px 6px",
                      borderRadius: "20px",
                    }}
                  />
                </Tooltip>
              ))}
              {attachmentsSkeleton.map(() => (
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "20px", margin: "4px" }}
                  width={150}
                  height={32}
                />
              ))}
            </ul>
          </Grid>
        </>
      )}

      {/* isSm */}
      {isMd && (
        <>
          {attachmentsUploadsErrors && (
            <Box>
              <Typography color="red" fontWeight="12px" margin="14px">
                {" "}
                {attachmentsUploadsErrors}
              </Typography>
            </Box>
          )}
          {/*  */}
          {attachmentsSkeleton.length > 0 && (
            <Typography fontSize={"12px"}>
              Your file is being scanned for upload, be patient and continue to
              fill rest of the form.
            </Typography>
          )}

          <Grid sx={{ marginY: "10px" }}>
            {/* Display the selected file names */}
            <ul style={{ display: "flex", flexWrap: "wrap" }}>
              {selectedFiles.map((file, index) => (
                <Tooltip title={file.Name}>
                  <Chip
                    label={file.Name}
                    clickable
                    component="a"
                    href={file.Content}
                    target="_blank"
                    rel="noopener noreferrer" // Recommended for security and performance reasons
                    variant="outlined"
                    onDelete={(event) =>
                      handleDeleteSelectedFile(file.Name, event)
                    }
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // margin: "4px",
                      justifyContent: "space-between",
                      width: "145px",
                      padding: "6px 6px",
                      borderRadius: "20px",
                    }}
                  />
                </Tooltip>
              ))}
              {attachmentsSkeleton.map(() => (
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "20px", margin: "4px" }}
                  width={150}
                  height={32}
                />
              ))}
            </ul>
          </Grid>

          <Grid>
            <Grid
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"space-between"}
              mt="10px"
            >
              <Grid>
                <input
                  accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,." // Specify the file types you want to allow
                  id="file-upload"
                  type="file"
                  disabled={attachmentsSkeleton.length > 0}
                  style={{ display: "none" }}
                  onClick={handleFileUploadClick}
                  onChange={handleFileUpload}
                />
                <label htmlFor="file-upload">
                  <Grid
                    sx={{
                      width: "154px",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                      padding: "8px",
                      cursor: attachmentsSkeleton.length > 0 && "wait",
                      backgroundColor:
                        attachmentsSkeleton.length > 0 ? "grey" : "white",
                      color: attachmentsSkeleton.length > 0 ? "white" : "black",
                      // boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <AttachFileIcon
                      sx={{
                        width: "0.7em",
                        ml: "2px",
                      }}
                    />
                    <Typography>Add Attachment</Typography>
                  </Grid>
                </label>
              </Grid>
              <Button
                disabled={attachmentsSkeleton.length > 0}
                type="submit"
                onClick={handleSubmit}
                sx={{
                  borderRadius: "17px",
                  padding: "8px 16px",
                  height: "34px",
                  color: "black",
                  backgroundColor: "#04EEAD",
                  "&:hover": {
                    backgroundColor: "#4CF0C0",
                  },
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Resend
                <SendIcon
                  sx={{
                    width: "0.7em",
                    ml: "6px",
                  }}
                />
              </Button>
            </Grid>
            <Typography sx={{ color: "#707070", marginTop: "6px" }}>
              You can attach any supporting document here
            </Typography>
          </Grid>
        </>
      )}
      {/* sm */}

      <Snackbar
        open={openFilesSizeError}
        autoHideDuration={6000}
        onClose={handleCloseFileSizeError}
      >
        <Alert
          onClose={handleCloseFileSizeError}
          severity="warning"
          sx={{ width: "100%" }}
        >
          Total size of attachments is not more than 10 MB
        </Alert>
      </Snackbar>
      {/*  */}
    </>
  );
};

export default AddAttachmentSection;
