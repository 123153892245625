import React from "react";

import { Button } from "@mui/material";
import { dateFormatter } from "utils/formatUtils";
import { ConfirmDialog } from "components";
import { fetchRequirements } from "actions/requirementsAction";
import { Link, useNavigate } from "react-router-dom";


export const headCells = (
  showDelete,
  deleteFev,
  modal,
  setModal,
  businessName,
  renderCellExpand,
) => [
    {
      field: "supplierName",
      headerName: "Company Name",
      width: 250,
      renderCell: (params) => {
        // console.log(params.row.url);
        const navigate = useNavigate
        return (
          <Link
            style={{ textDecoration: "none" }}
            to={`/company/${params.row.url}`}
            target="_blank"
            key={params.id}
          >
            {params.formattedValue}
          </Link>
          // <span onClick={(() => {
          //   navigate(params.row.url)
          // })}>
          //   {params.formattedValue}
          // </span>
        );
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      sortable: false,
      renderCell: renderCellExpand,
      width: 220,
    },
    {
      field: "contact",
      headerName: "Company Contact",
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      field: "businessEmail",
      headerName: "Company Email",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const { api } = params;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              // eslint-disable-next-line no-return-assign
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

        };
        return (
          <>
            <ConfirmDialog
              title="Are you Sure"
              description={`Do you want to permanently delete ${params.row.supplierName}, action can't be undone`}
              isOpen={modal === params.id && params.id}
              confirmAction={() => {
                // console.log("Delete confirm: ", params.id);
                setModal(false);
                deleteFev(businessName, params.id);
              }}
              cancelAction={() => setModal(false)}
            />
            <Button onClick={() => setModal(params.id)}>Delete</Button>
          </>
        );
      },
    },
  ];
