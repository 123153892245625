import businessService from "services/businessService";
import {
  BusinessAddress,
  BusinessProfileToUpdate,
  BusinessSocialIconToUpdate,
  BusinessStepperCount,
} from "interface/businessIf";
export const FETCH_BUSINESS_BY_ID = "@business/id/fetch";
export const FETCH_BUSINESS_BY_ID_SUCCESS = "@business/id/fetch-success";
export const FETCH_BUSINESS_BY_ID_FAILURE = "@business/id/fetch-failure";

export const HIDE_CONTACT_DETAIL = "@business/hideNumber";
export const HIDE_CONTACT_DETAIL_SUCCESS = "@business/hideNumber-success";
export const HIDE_CONTACT_DETAIL_FAILURE = "@business/hideNumber-failure";

export const FETCH_BUSINESS_BY_URL = "@business/url/fetch";
export const FETCH_BUSINESS_BY_URL_SUCCESS = "@business/url/fetch-success";
export const FETCH_BUSINESS_BY_URL_FAILURE = "@business/url/fetch-failure";

export const UPDATE_BUSINESS_PROFILE = "@business/profile/update";
export const UPDATE_BUSINESS_PROFILE_SUCCESS =
  "@business/profile/update-success";
export const UPDATE_BUSINESS_PROFILE_FAILURE =
  "@business/profile/update-failure";

export const EDIT_ADDRESS = "@business/address/update";
export const EDIT_ADDRESS_SUCCESS = "@business/address/update-success";
export const EDIT_ADDRESS_FAILURE = "@business/address/update-failure";

export const UPDATE_STEPPERCOUNT = "@business/stepper-count/update";
export const UPDATE_STEPPERCOUNT_SUCCESS =
  "@business/stepper-count/update-success";
export const UPDATE_STEPPERCOUNT_FAILURE =
  "@business/stepper-count/update-failure";

export const SAVE_URL = "@business/url/save";
export const SAVE_URL_SUCCESS = "@business/url/save-success";
export const SAVE_URL_FAILURE = "@business/url/save-failure";

export const SOCIAL_URL = "@business/socialurl/save";
export const SOCIAL_URL_SUCCESS = "@business/socialurl/save-success";
export const SOCIAL_URL_FAILURE = "@business/socialurl/save-failure";

export const UPDATE_BUSINESS_PROFILE_STEPPER =
  "@update/business/profile-stepper";
export const UPDATE_BUSINESS_PROFILE_STEPPER_SUCCESS =
  "@update/business/profile-stepper-success";
export const UPDATE_BUSINESS_PROFILE_STEPPER_FAILURE =
  "@update/business/profile-stepper-failure";

export const SAVE_ROUTER_URL = "@business/url/save-router-url";

export const FETCH_BUSINESS_OWNER_EMAIL = "@business/owner/email/fetch";
export const FETCH_BUSINESS_OWNER_EMAIL_SUCCESS =
  "@business/owner/email/fetch-success";
export const FETCH_BUSINESS_OWNER_EMAIL_FAILURE =
  "@business/owner/email/fetch-failure";

export function fetchBusinessById(supplierId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_BUSINESS_BY_ID,
        payload: {
          supplierId,
        },
      });

      // console.log("BusinessAction.fetchBusinessById:", supplierId);
      const payload = await businessService.fetchBusinessById(supplierId);

      // console.log("BusinessAction.fetchBusinessById: Resp:", payload);

      dispatch({
        type: FETCH_BUSINESS_BY_ID_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      // console.log("BusinessAction.fetchBusinessById: Error", error);

      dispatch({ type: FETCH_BUSINESS_BY_ID_FAILURE });
      throw error;
    }
  };
}

export function fetchBusinessByUserId(userId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_BUSINESS_BY_ID,
        payload: {
          userId,
        },
      });

      // console.log("BusinessAction.fetchBusinessById:", userId);
      const payload = await businessService.fetchBusinessByUserId(userId);

      // console.log("BusinessAction.fetchBusinessById: Resp:", payload);

      dispatch({
        type: FETCH_BUSINESS_BY_ID_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      // console.log("BusinessAction.fetchBusinessById: Error", error);

      dispatch({ type: FETCH_BUSINESS_BY_ID_FAILURE });
      throw error;
    }
  };
}

export function fetchBusinessByUrl(url) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_BUSINESS_BY_URL,
        payload: {
          url,
        },
      });

      // console.log("BusinessAction.fetchBusinessByUrl:", url);
      const payload = await businessService.fetchBusinessByUrl(url);

      // console.log("BusinessAction.fetchBusinessByUrl: Resp:", payload);

      dispatch({
        type: FETCH_BUSINESS_BY_URL_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      // console.log("BusinessAction.fetchBusinessById: Error", error);

      dispatch({ type: FETCH_BUSINESS_BY_URL_FAILURE });
      throw error;
    }
  };
}

export function updateBusinessProfile(supplierId, values) {
  return async (dispatch) => {
    const newBProfile = new BusinessProfileToUpdate(supplierId, values);

    try {
      dispatch({
        type: UPDATE_BUSINESS_PROFILE,
        payload: {
          newBProfile,
        },
      });

      const payload = await businessService.updateBusinessProfile(newBProfile);

      dispatch({
        type: UPDATE_BUSINESS_PROFILE_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      dispatch({ type: UPDATE_BUSINESS_PROFILE_FAILURE });
      throw error;
    }
  };
}

export function updateSupplierStepper(values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_BUSINESS_PROFILE_STEPPER,
        payload: values,
      });

      const payload = await businessService.updateSupplierStepper(values);

      dispatch({
        type: UPDATE_BUSINESS_PROFILE_STEPPER_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      console.log("error", error);
      dispatch({ type: UPDATE_BUSINESS_PROFILE_STEPPER_FAILURE });
      throw error;
    }
  };
}

export function updateAddress(supplierId, values) {
  const bAddress = new BusinessAddress(supplierId, values);

  return async (dispatch) => {
    try {
      dispatch({
        type: EDIT_ADDRESS,
        payload: {
          bAddress,
        },
      });

      const payload = await businessService.updateAddress(bAddress);

      dispatch({
        type: EDIT_ADDRESS_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      dispatch({ type: EDIT_ADDRESS_FAILURE });

      throw error;
    }
  };
}

export function updateStepperCount(supplierId, stepperCountValue) {
  const stepperToupdate = new BusinessStepperCount(
    supplierId,
    stepperCountValue
  );

  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_STEPPERCOUNT,
        payload: {
          stepperToupdate,
        },
      });

      const payload = await businessService.updateStepperCount(stepperToupdate);

      dispatch({
        type: UPDATE_STEPPERCOUNT_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      dispatch({ type: UPDATE_STEPPERCOUNT_FAILURE });

      throw error;
    }
  };
}

export function saveUrl(supplierId, url) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SAVE_URL,
        payload: {
          url,
          supplierId,
        },
      });

      // console.log("BusinessAction.saveUrl:", url);
      const payload = await businessService.saveUrl({ supplierId, url });

      // console.log("BusinessAction.saveUrl: Resp:", payload);

      dispatch({
        type: SAVE_URL_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      // console.log("BusinessAction.saveUrl: Error", error);
      dispatch({ type: SAVE_URL_FAILURE });
      throw error;
    }
  };
}

export function saveSocialUrls(supplierId, values) {
  const bsocialIcon = new BusinessSocialIconToUpdate(supplierId, values);
  return async (dispatch) => {
    try {
      dispatch({
        type: SOCIAL_URL,
        payload: {
          ...bsocialIcon,
        },
      });

      // console.log("BusinessAction.saveUrl:", url);
      const payload = await businessService.saveSocialUrls({
        ...bsocialIcon,
      });

      // console.log("BusinessAction.saveUrl: Resp:", payload);

      dispatch({
        type: SOCIAL_URL_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      // console.log("BusinessAction.saveUrl: Error", error);
      dispatch({ type: SOCIAL_URL_FAILURE });
      throw error;
    }
  };
}

export function getUrl(currentLocation) {
  const url = currentLocation;

  return async (dispatch) => {
    try {
      dispatch({
        type: SAVE_ROUTER_URL,
        payload: { url },
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function fetchBusinessOwnerEmail(suppliers) {
  console.log("*****1", suppliers);
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_BUSINESS_OWNER_EMAIL,
        payload: {
          suppliers,
        },
      });
      const payload = await businessService.fetchBusinessOwnersEmail(suppliers);
      dispatch({
        type: FETCH_BUSINESS_OWNER_EMAIL_SUCCESS,
        payload: {
          ...payload,
        },
      });
      return payload; // Return the result from the service
    } catch (error) {
      dispatch({ type: FETCH_BUSINESS_OWNER_EMAIL_FAILURE });
      throw error;
    }
  };
}

// hide Contact detail from nonentity
export function hideContactDetails(userId, supplierDetails, entityId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: HIDE_CONTACT_DETAIL,
        payload: {
          userId,
          supplierDetails,
          entityId,
        },
      });

      // console.log("BusinessAction:HideNumber:", supplierId, userId, entityId);
      const payload = await businessService.hideContactDetails(
        userId,
        supplierDetails,
        entityId
      );

      // console.log("BusinessAction.fetchBusinessById: Resp:", payload);

      dispatch({
        type: HIDE_CONTACT_DETAIL_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      // console.log("BusinessAction.fetchBusinessById: Error", error);
      dispatch({ type: HIDE_CONTACT_DETAIL_FAILURE });
      throw error;
    }
  };
}
