import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import FavoritesDialogBox from "views/Business/components/FavoritesDialogBox";
import { fetchTag } from "actions/tagsActions";
import { fetchBusinessOwnerEmail } from "actions/businessActions";

const SearchCard = ({
  checkedItems,
  setCheckedItems,
  BusinessData,
  searchString,
  searchBy = "all",
  filterUrl,
  region,
  currentPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down(768));
  const { user } = useSelector((state) => state.auth);
  const favourite = useSelector((state) => state.favourite.favourites);
  const [openHoverTooltip, setOpenHoverTooltip] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openFavoritesTag, setOpenFavoritesTag] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  const handleCheckboxChange = async (
    itemId,
    itemName,
    itemOwnerId,
    businessEmail,
    contact
  ) => {
    const suppliers = [{ supplierId: itemId }];
    const result = await dispatch(fetchBusinessOwnerEmail(suppliers));
    // console.log("#@#@#@#@#@@@=>", result);
    const resultEmails = Array.isArray(result[0]?.emails)
      ? result[0]?.emails
      : [];
    const emails = [...new Set([...resultEmails, businessEmail])];
    const updatedCheckedItem = {
      supplierId: itemId,
      businessName: itemName[0],
      ownerId: Number(itemOwnerId),
      businessOwnerCrmIds: null,
      mobile: contact,
      // businessEmail: businessEmail,
      emails: emails,
    };
    // console.log(BusinessData);

    const isChecked = checkedItems.some((item) => item.supplierId === itemId);

    if (isChecked) {
      // Uncheck the checkbox and remove data from checkedItems
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item.supplierId !== itemId)
      );
    } else {
      // Check the checkbox and add data to checkedItems
      if (checkedItems.length < 60) {
        setCheckedItems((prevCheckedItems) => [
          ...prevCheckedItems,
          updatedCheckedItem,
        ]);
      } else {
        seterrMsg("You can select only 60 businesses.");
      }
    }
  };

  const handleCloseAlert = () => {
    seterrMsg(null);
  };

  const isShortlisted = favourite?.some(
    (item) => item.supplierId === BusinessData.supplierId
  );
  const TotalYears = new Date().getFullYear() - BusinessData?.establishYear;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let defaultImg = "/assets/default/default-search-image.jpg";

  const logoSrcUrl =
    BusinessData?.logoUrl && BusinessData?.logoUrl !== ""
      ? BusinessData?.logoUrl.startsWith("/assets/business")
        ? `${BASE_URL}${BusinessData?.logoUrl}`
        : BusinessData?.logoUrl
      : defaultImg;

  const handleCloseTooltip = () => setOpenHoverTooltip(false);

  const handleFavoritesTagClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFavoritesTag(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSave(false);
  };

  const handleShortlistTagBox = (event) => {
    // if (user?.isVerified ===true) {
    event.preventDefault();
    dispatch(fetchTag());
    setOpenFavoritesTag(true);
    // }
  };

  const handleOpeneTooltip = () => {
    if (BusinessData?.businessName[0]?.length > 23) setOpenHoverTooltip(true);
  };

  const capitalizeFirstLetter = (str) => {
    if (typeof str === "string") {
      return str
        ?.split(" ")
        ?.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        ?.join(" ");
    } else {
      return str;
    }
  };

  const isRealEstateOrEPC =
    BusinessData?.businessCategory === "Real Estate Developer" ||
    BusinessData?.businessCategory === "EPC Contractor";

  //create a business area value if dist not available the store the value of city
  const businessArea = BusinessData.addresses_district
    ? BusinessData.addresses_district
    : BusinessData.addresses_city;

  const businessLocation =
    businessArea && BusinessData.addresses_state
      ? `${businessArea}, ${BusinessData.addresses_state}`
      : businessArea
      ? businessArea
      : BusinessData.addresses_state
      ? BusinessData.addresses_state
      : "";

  const originalText = BusinessData?.businessName[0];
  const formattedText = capitalizeFirstLetter(originalText);

  const DesktopView = `/business-details/${BusinessData?.url}/${searchString}/${region}/${currentPage}?${filterUrl}`;
  const mobileView = `/company/${BusinessData.url}`;
  const targetURL = user ? (isSm ? mobileView : DesktopView) : "";
  console.log(checkedItems.length, "Len");
  console.log(checkedItems, "checkedItems");
  return (
    <>
      <Link
        // to={isSm ? mobileView : DesktopView}
        to={targetURL}
        style={{ textDecoration: "none", color: "black" }}
        target="_blank"
      >
        <Card
          sx={{
            backdropFilter: !user ? "blur(3px)" : "",
            width: "291px",
            height: "235px",
            border: "1px solid rgba(0, 0, 0, 0.5)",
            paddingBottom: "10px",
            // margin: "auto",
            "&:hover": {
              boxShadow: "-1px 0px 29px 0px rgba(0,0,0,0.8)",
            },
            pointerEvents: user ? "auto" : "none",
            opacity: user ? 1 : 0.5,
          }}
        >
          <Grid display={"flex"} justifyContent={"space-between"}>
            {BusinessData?.subscriptionType == "PREMIUM" ? (
              <Box
                component="img"
                sx={{
                  height: "17px",
                  // width: "79px",
                  marginTop: "4px",
                  // marginLeft: "0px",
                }}
                src="/assets/home/verified.png"
              />
            ) : (
              <Box sx={{ height: "17px", marginTop: "8px" }}></Box>
            )}
            {!isRealEstateOrEPC && user?.isVerified && (
              <Checkbox
                sx={{
                  // color: 'black', // Change color of Checkbox
                  "&.Mui-checked": {
                    color: "black", // Apply the color when checked
                  },
                }}
                // disabled={!user?.isVerified}
                checked={
                  checkedItems &&
                  checkedItems.some(
                    (checkedItem) =>
                      checkedItem.supplierId === BusinessData.supplierId
                  )
                }
                onChange={() =>
                  handleCheckboxChange(
                    BusinessData.supplierId,
                    BusinessData.businessName,
                    BusinessData.owners,
                    BusinessData.businessEmail,
                    BusinessData.contact
                  )
                }
              />
            )}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              gap: "15px",
              marginX: "8px",
              marginTop: "6px",
            }}
          >
            <Grid>
              <Box
                component="img"
                sx={{ height: "56px", width: "56px" }}
                alt="Business Logo"
                src={logoSrcUrl}
              />
            </Grid>
            <Grid
            // onMouseOver={handleTextFeiekd}
            >
              <Tooltip
                title={BusinessData?.businessName}
                open={openHoverTooltip}
                onClose={handleCloseTooltip}
                onOpen={handleOpeneTooltip}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "rgba(10, 102, 194, 1)",
                    width: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {BusinessData?.businessName}
                </Typography>
              </Tooltip>
              <Typography
                className="truncate"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                {/* {BusinessData?.businessCategory} */}
                {businessLocation}
              </Typography>
              {TotalYears ? (
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    marginBottom: "10px",
                    color: "rgba(68, 64, 64, 1)",
                  }}
                >
                  {TotalYears} Years in Business
                </Typography>
              ) : (
                <Typography sx={{ marginBottom: "30px" }}></Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            sx={{
              backgroundColor: "rgba(215, 221, 233, 0.3)",
              height: "63px",
              // marginTop: "15px",
              marginX: "9px",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              {BusinessData?.hightlights}
            </Typography>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
              alignItems: "center",
              marginX: "8px",
            }}
          >
            {BusinessData?.isISOCertified &&
            BusinessData?.gstCertified == "true" ? (
              <Grid display={"flex"} gap={2}>
                {/* {BusinessData?.isISOCertified &&              */}
                <Box
                  component="img"
                  sx={{ height: "19px", width: "19px" }}
                  src="/assets/home/Iso.svg"
                />
                {/* }           */}

                {/* {BusinessData?.gstCertified && */}
                <Box
                  component="img"
                  sx={{ height: "19px", width: "19px" }}
                  src="/assets/home/gst.png"
                />
                {/* } */}
              </Grid>
            ) : BusinessData?.isISOCertified ? (
              <Box
                component="img"
                sx={{ height: "19px", width: "19px" }}
                src="/assets/home/Iso.svg"
              />
            ) : BusinessData?.gstCertified == "true" ? (
              <Box
                component="img"
                sx={{ height: "19px", width: "19px" }}
                src="/assets/home/gst.png"
              />
            ) : (
              <Box />
            )}
            {!isRealEstateOrEPC && (
              <>
                {" "}
                {isShortlisted ? (
                  <Box
                    component="img"
                    sx={{ height: "24px" }}
                    alt="Business Logo"
                    src={`${BASE_URL}/assets/business/web-business-page-shortlistedIcon.svg`}
                  />
                ) : (
                  <Button
                    onClick={handleShortlistTagBox}
                    disabled={user?.isVerified === false}
                    sx={{
                      zIndex: 2,
                      width: "86px",
                      height: "24px",
                      border: "1px solid #555",
                      fontSize: "14px",
                      color: "#555",
                      borderRadius: "4px",
                      "&:hover": {
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      },
                    }}
                  >
                    Shortlist
                  </Button>
                )}
              </>
            )}
            <FavoritesDialogBox
              supplierId={BusinessData?.supplierId}
              businessName={BusinessData?.businessName[0]}
              handleClose={handleFavoritesTagClose}
              open={openFavoritesTag}
              setOpenSave={setOpenSave}
              businessEmail={BusinessData?.businessEmail}
              contact={BusinessData?.contact}
              subscriptionType={BusinessData?.subscriptionType}
              url={BusinessData?.url}
            />
            <Snackbar
              open={openSave}
              autoHideDuration={4000}
              onClose={handleCloseSnackbar}
              message="Business shortlisted succesfully"
              direction="left"
            />
          </Grid>
        </Card>
      </Link>
      {errMsg && (
        <Snackbar
          open={errMsg !== null}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseAlert}
            severity="error"
          >
            {errMsg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SearchCard;
