import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

import Minimal from "layouts/Minimal";
import Logo from "components/Logo";
import SignUpOTPVerifyForm from "./SignUPOTPVerifyForm";
import SignUpOTPVerifyFormNew from "./SignUPOTPVerifyFormNew";

function SignUpOTPnew() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const location = useLocation();
  const { sourceName } = location.state;
  console.log("location", sourceName);

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  const mobile = auth.signUpRegisterMobile;
  const name = auth.signUpRegisterName;
  const mobileLs = localStorage.getItem("signUpMobile")
  const {isWhatsappNo} = location.state
  // console.log("SignUPOTP: auth", auth, " mobile: ", mobile);

  // useEffect(() => {}, [mobile]);

  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    // if(userProfile?.name){
      navigate("/")
    // }else{
    // navigate("/register/user",{ state: { "isWhatsappNo": isWhatsappNo } });
    // }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
     
    >
       <Grid p={6} sx={{ mt: -4 }}>

        <SignUpOTPVerifyFormNew
        isWhatsappNo={isWhatsappNo}
          mobile={mobile || mobileLs}
          name={name}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </Grid>
    </Grid>
  );
}

export default SignUpOTPnew;
