import { Card, Grid, Skeleton } from "@mui/material";
import React from "react";

function MobileSearchSkeleton() {
  const showCards =["a","b","c","d"]
  return (<>
    {
      showCards.map(item=><Card
        sx={{
          maxWidth: "352px",
          height: "212px",
          marginBottom: "14px",
          marginX:"14px"
        }}
      >
        <Skeleton variant="rectangular" height={150} />
        <Grid
          sx={{ backgroundColor: "white", height: "56px", paddingLeft: "12px" }}
        >
          <Skeleton width={140} height={26} />
          <Skeleton width={165} height={26} />
        </Grid>
      </Card>
      )
    }
   
   </>);
}

export default MobileSearchSkeleton;
