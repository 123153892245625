import { MEMBER_LIST_SUCCESS } from "actions/rfqActions";
import produce from "immer";

const initialState = {
  ccEmails: [],
};

const ccMemberListReducer = (state = initialState, action) => {
  if (action.type == MEMBER_LIST_SUCCESS) {
    return produce(state, (draft) => {
      draft.ccEmails = action?.ccMemberList;
    });
  }
  return state;
};
export default ccMemberListReducer;
