import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Dialog,
  Button,
  CardMedia,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  CardContent,
  Card,
} from "@mui/material";
import PropTypes from "prop-types";
import { Delete, Edit } from "@mui/icons-material";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ClientCard = ({
  isPublicProfile,
  permissions,
  handleDeleteClient,
  clientLogoImage,
  supplierId,
  clientName,
  clientId,
  onSubmitSuccess,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (supplierId) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      md={12}
      xs={12}
      sm={12}
    // sx={{ border: "1px solid black" }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography>Are you sure want to delete ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" size="small">
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDeleteClient(supplierId, clientId, clientName)}
          >
            <Box onClick={handleClose}> Submit</Box>
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Typography
          variant="body1"
          color="secondary.main"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <b>{clientName} </b>
        </Typography> */}
      <Grid item xs={12} sm={12} md={12} >
        <Card
          fullWidth
          elevation={1}
          sx={{
            borderRadius: "0px 30px 0px 30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "80px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            pl: 2,
            pr: 1,
          }}
        >
          <Grid item xs={10} sm={10} md={10} sx={{ textAlign: 'center' }}>
            <Typography>{clientName}</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Box sx={{ display: "flex", justifyContent: "end", mt: -4 }}>
              {(permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed) && (
                  <Typography variant="body2">
                    <Tooltip title="Delete">
                      <IconButton
                        disabled={!permissions?.isOwner}
                        sx={{ color: "primary.main" }}
                        onClick={() =>
                          handleClickOpen(supplierId, clientId, clientName)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                )}
            </Box>

            {/* <img
              src={
                clientLogoImage
                  ? `${BASE_URL}/${clientLogoImage}`
                  : DEFAULT_IMAGES.CLIENT
              }
              height="100px"
              alt="Logo Image not found"
            /> */}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
ClientCard.propTypes = {
  supplierId: PropTypes.number,
  clientName: PropTypes.string,
  clientId: PropTypes.number,
  clientLogoImage: PropTypes.string,
  handleDeleteClient: PropTypes.func,
  isPublicProfile: PropTypes.bool,
  permissions: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
};

export default ClientCard;
