class UserProfile {
  constructor(userInfo,userEntity) {
    if (userInfo) {
      this.email = userInfo.email;
      this.mobile = userInfo.mobile;
      this.name = userInfo.name;
      this.firstName = userInfo.firstName;
      this.lastName = userInfo.lastName;
      this.company = userInfo.company;
      this.roleInCompany = userInfo.roleInCompany;
      this.isEmailVerified = userInfo.isEmailVerified;
      this.isMobileVerified = userInfo.isMobileVerified;
      this.lastLoggedIn = userInfo.lastLoggedIn;
      this.userType = userInfo.userType;
      this.whatsapp = userInfo.whatsapp;
      this.imgUrl = userInfo.imgUrl;
      this.personaldetails = userInfo.details;
      this.isSupplierSubscribed = userInfo.isSupplierSubscribed;
      this.isSupplierBusinessRegistered = userInfo.isSupplierBusinessRegistered;
      this.supplierId = userInfo.supplierId;
      this.userId = userInfo.userId;
      this.isFirstLogin = userInfo.isFirstLogin;
      this.crmLeadId = userInfo.crmLeadId;
      this.isVerified = userInfo.isVerified;
      this.isBlocked = userInfo.isBlocked;

      this.companyName = userInfo.companyName;
      this.isEmailAuthorized = userInfo.isEmailAuthorized;
      this.teamMembers = userInfo.teamMembers || [];
      this.vendorsIn = userInfo.vendorsIn;
      this.userEntity = userEntity || null;
    }
  }
}

class NewUserRegisterInfo {
  constructor(userId, userInfo) {
    this.company = userInfo.company;
    this.userId = userId;
    this.name = userInfo.name;
    this.email = userInfo.email;
    this.mobile = userInfo.mobile;
    this.whatsapp = userInfo.whatsapp;
    this.roleInCompany = userInfo.designation;
    this.userType = userInfo.userType;
    this.businessDealsWith = userInfo.businessDealsWith;
    this.userIndustry = userInfo.userIndustry;
    this.aboutNeevay = userInfo.aboutNeevay;
    this.isWhatsAppVerified = userInfo.isWhatsappNoVerified;
  }
}

class UserProfileInfo {
  constructor(userInfo) {
    this.name = userInfo.name;
    this.email = userInfo.email;
    this.mobile = userInfo.mobile;
    this.whatsapp = userInfo.whatsapp;
    this.company = userInfo.company;
    this.roleInCompany = userInfo.roleInCompany;
    this.userType = "Other";
    this.details = userInfo.personaldetails;
    this.businessDealsWith = userInfo.businessDealsWith;
  }
}

class NewCompanyInfo {
  constructor(data, address) {
    this.gstin = data.gstin;
    this.businessName = data.businessName;
    // this.address = data.address;
    this.businessMobile = data.mobile;
    this.businessType = data.businessType;
    this.establishYear = data.establishYear;
    this.othergst = data.othergst;
    this.category = data.category;
    this.annualTurnOver = data.annualTurnOver;
    this.businessDealsWith = data.businessDealsWith;
    this.hasGstNumber = data.hasGstNumber;

    this.addresses = [
      {
        addressType: data.addressType,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        pincode: data.pincode,
        country: data.country,
      },
    ];
    if (address) {
      this.addresses = [address];
    }
  }
}

export default { UserProfile };
export { UserProfile, NewUserRegisterInfo, UserProfileInfo, NewCompanyInfo };
