import axios from "utils/axios";
export const BUSINESS_DATA_FETCH = "@business/fetch";
export const BUSINESS_DATA_FETCH_FAILURE = "@business/fetch-failure";
export const BUSINESS_DATA_NOT_FOUND = "@business/not-found";

const searchBusinessAction = (searchKeyWord) => {
  return async (dispatch) => {
    try {
      axios
        .get(`/api/supplier/search/business/name?queryParam=${searchKeyWord}`)
        // .get(
        //   `http://localhost:5030/api/supplier/search/business/name?queryParam=${searchKeyWord}`
        // )
        .then((response) => {
          const payload = response?.data?.supplier;
          dispatch({
            type: BUSINESS_DATA_FETCH,
            suppliers: payload,
          });
        })
        .catch((error) => {
          dispatch({
            type: BUSINESS_DATA_NOT_FOUND,
            message: error,
          });
          console.error("Error fetching data:", error);
          return error;
        });
    } catch (error) {
      dispatch({
        type: BUSINESS_DATA_FETCH_FAILURE,
        error: error,
      });
      throw error;
    }
  };
};

export default searchBusinessAction;
