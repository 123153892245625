import axios from "utils/axios";

import { ADD_CLIENT_URL, ADD_MULTIPLE_CLIENTS, DELETE_CLIENT_URL } from "config/services";

class ClientService {
  addClient = (supplierId, clientName, clientLogoImage) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("supplierId", supplierId);
      formData.append("clientName", clientName);
      formData.append("clientLogoImage", clientLogoImage);
      axios
        .post(ADD_CLIENT_URL, formData)
        .then((response) => {
          // console.log(
          //   "ClientService.clientService()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ClientService.clientService() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  addMultipleClient = (payload) =>
    new Promise((resolve, reject) => {    
      axios
        .post(ADD_MULTIPLE_CLIENTS, payload)
        // .post("http://localhost:5030/api/supplier/multiple/clients/add", payload)
        .then((response) => {
          // console.log(
          //   "ClientService.clientService()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ClientService.clientService() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  deleteClient = (supplierId, clientId, clientName) =>
    new Promise((resolve, reject) => {
      const payload = {
        supplierId,
        clientId,
        clientName,
      };

      axios
        .post(DELETE_CLIENT_URL, payload)
        .then((response) => {
          // console.log(
          //   "ClientService.deleteCertificate()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ClientService.deleteCertificate() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });
}

const clientService = new ClientService();

export default clientService;
