import React from "react";
import Button from "./Button";

const Header = ({ title, button, onClick }) => {
  return (
    <div className="flex items-center justify-between z-10 h-14 w-full fixed px-4 bg-white shadow-md">
      <h1 className=" font-bold">{title}</h1>
      {button && <Button onClick={onClick}>{button}</Button>}
    </div>
  );
};

export default Header;
