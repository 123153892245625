import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
// Font.register({ family: 'Sarif', src: "/assets/fonts/cambriab.tff" });
Font.register({
  family: "Cambria",
  fonts: [
    {
      src: "/assets/fonts/cambria.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 24,
  },
  reportTitle: {
    color: "black",
    // letterSpacing: 2,
    fontSize: 10,
    textAlign: "left",
    textTransform: "capitalize",
    // fontfamily: "Arial",
  },
  titles: {
    // color: 'black',
    // letterSpacing: 2,
    fontSize: 12,
    textAlign: "left",
    textTransform: "capitalize",
    // fontWeight: "ultrabold",
    fontFamily: "Cambria",
  },
  invoiceDateContainer: {
    flexDirection: "row",

    // justifyContent: 'flex-end',
    alignItems: "right",
  },
  label: {
    width: 30,
    alignItems: "left",
  },
  label1: {
    width: 70,
    alignItems: "left",
    
  },
  label2: {
    width: 50,
    alignItems: "left",
    // fontWeight: "bold",
  },

});

const InvoiceForm = ({ title, from }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.titles}>{title}</Text>
        <Text style={styles.reportTitle}>{from.line1}</Text>
        <Text style={styles.reportTitle}>{from.line2}</Text>
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>{from.city} </Text>
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label1}>{from.state} -</Text>


            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>{from.pincode}</Text>


            </View >


        </View >
        {/* <Text style={styles.reportTitle}>{from.city}</Text>
        <Text style={styles.reportTitle}>{from.state}</Text>
        <Text style={styles.reportTitle}>{from.pincode}</Text> */}
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label2}>GSTIN :- </Text>
      <Text style={{ fontFamily: "Cambria" }}>{from.GSTIN}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>CIN :- </Text>
      <Text style={{ fontFamily: "Cambria" }}>{from.CIN}</Text>
    </View>
    {/* <Text style={styles.reportTitle}>{from.GSTIN}</Text>
        <Text style={styles.reportTitle}>{from.CIN}</Text> */}
  </View>
);

export default InvoiceForm;
