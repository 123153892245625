import React from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DashboardLayout from "layouts/Dashboard";
import Page from "views/UserProfile/components/Page";
import { Helmet } from "react-helmet";

import ServiceForm from "./ServiceForm";

function Service({}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1024));
  const isSm = useMediaQuery(theme.breakpoints.down(768));
  const business = useSelector((state) => state.business);

  const serviceDataToEdit = business.serviceDataToEdit;

  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/my-business");
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Neevay - My Services</title>
      </Helmet>
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid >
            <Grid item>
              <Typography
                variant="h5"
                color={"black"}

                fontWeight="bold"
                mb={2}
                style={{marginTop:isSm&&"20px"}}
              >
                {business.isServiceEdit ? "Edit Service" : "Add Service"}
              </Typography>
              <Divider />
              <ServiceForm
                data={serviceDataToEdit}
                onSubmitSuccess={handleSubmitSuccess}
              />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
  );
}

Service.propTypes = {};

export default Service;
