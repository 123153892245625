import React from "react";

const Button = ({
  variant,
  children,
  className,
  type = "button",
  ...props
}) => {
  let buttonStyle = "";

  switch (variant) {
    case "primary":
      buttonStyle =
        "bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg";
      break;
    case "secondary":
      buttonStyle =
        "bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg";
      break;
    case "disabled":
      buttonStyle =
        "bg-gray-400 text-white font-bold py-2 px-4 rounded-lg cursor-auto";
      break;
    case "outline":
      buttonStyle =
        "bg-transparent text-blue-700 font-semibold hover:text-blue-800 py-2 px-4 border border-blue-700 rounded-lg hover:border-blue-800";
      break;
    case "text":
      buttonStyle =
        "bg-transparent text-blue-700 font-semibold hover:text-blue-800 py-2 px-4";
      break;
    default:
      buttonStyle =
        "bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg";
  }

  return (
    <button
      type={type}
      className={`${buttonStyle} ${className}`}
      {...props}
      disabled={variant === "disabled"}
    >
      {children}
    </button>
  );
};

export default Button;
