/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  FETCH_BUSINESS_BY_ID,
  FETCH_BUSINESS_BY_ID_SUCCESS,
  FETCH_BUSINESS_BY_ID_FAILURE,
  FETCH_BUSINESS_BY_URL,
  FETCH_BUSINESS_BY_URL_SUCCESS,
  FETCH_BUSINESS_BY_URL_FAILURE,
  SAVE_URL_SUCCESS,
  EDIT_ADDRESS_SUCCESS,
  SOCIAL_URL_SUCCESS,
  SAVE_URL,
  UPDATE_BUSINESS_PROFILE_STEPPER_SUCCESS,
  UPDATE_BUSINESS_PROFILE_STEPPER_FAILURE,
  UPDATE_STEPPERCOUNT_SUCCESS,
  HIDE_CONTACT_DETAIL_SUCCESS,
} from "actions/businessActions";

import {
  SERVICE_ADD_TRIGGER,
  SERVICE_EDIT_TRIGGER,
  SERVICE_CREATE_SUCCESS,
  SERVICE_DELETE_SUCCESS,
} from "actions/serviceActions";

import {
  PROJECT_ADD_TRIGGER,
  PROJECT_EDIT_TRIGGER,
  PROJECT_CREATE_SUCCESS,
  PROJECT_DELETE_SUCCESS,
  PROJECT_UPDATE_SUCCESS,
} from "actions/projectActions";

import {
  BUSINESS_IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_FAILURE,
} from "actions/imageActions";

import {
  CLIENT_CREATE_SUCCESS,
  CLIENT_DELETE_SUCCESS,
} from "actions/clientAction";

import {
  CERTIFICATE_CREATE_SUCCESS,
  CERTIFICATE_DELETE_SUCCESS,
} from "actions/certificateAction";

import {
  AWARD_CREATE_SUCCESS,
  AWARD_DELETE_SUCCESS,
} from "actions/awardsAction";

import { SIGNOUT } from "actions/authActions";
import { SUBSCRIBE_SUCCESS } from "actions/subscribeAction";
import { DELETE_IMAGE_SUCCESS } from "actions/imageActions";
import { REGISTER_NEW_COMPANY_SUCCESS } from "actions/registerActions";

const initialState = {
  supplierId: 0,
  companyName: "",
  profile: {},
  services: [],
  projects: [],
  isServiceEdit: false,
  serviceDataToEdit: undefined,
  isProjectEdit: false,
  projectDataToEdit: undefined,
  imageToUpload: undefined,
  uploadSizeError: {},
  contactsData: []
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_BY_ID:
      {
        const { supplierId } = action.payload;

        return produce(state, (draft) => {
          draft.supplierId = supplierId;
          draft.serviceDataToEdit = undefined;
        });
      }
      break;

    case REGISTER_NEW_COMPANY_SUCCESS:
    case FETCH_BUSINESS_BY_ID_SUCCESS:
    case SERVICE_CREATE_SUCCESS:
    case SERVICE_DELETE_SUCCESS:
    case SAVE_URL_SUCCESS:
    case EDIT_ADDRESS_SUCCESS:
    case PROJECT_CREATE_SUCCESS:
    case PROJECT_DELETE_SUCCESS:
    case PROJECT_UPDATE_SUCCESS:
    case BUSINESS_IMAGE_UPLOAD_SUCCESS:
    case CLIENT_CREATE_SUCCESS:
    case CERTIFICATE_CREATE_SUCCESS:
    case AWARD_CREATE_SUCCESS:
    case AWARD_DELETE_SUCCESS:
    case CLIENT_DELETE_SUCCESS:
    case CERTIFICATE_DELETE_SUCCESS:
    case DELETE_IMAGE_SUCCESS:
    case UPDATE_STEPPERCOUNT_SUCCESS:
      {
        const { profile, projects } = action.payload;
        localStorage.setItem("profile", JSON.stringify(profile));
        // console.log(
        //   "businessReducer: FETCH_BUSINESS_BY_URL_SUCCESS ",
        //   action.payload
        // );
        // console.log(
        //   "businessReducer: FETCH_BUSINESS_BY_URL_SUCCESS (2) ",
        //   profile,
        //   projects
        // );

        return produce(state, (draft) => {
          draft.profile = profile;
          if (projects) {
            draft.projects = projects?.projects;
          }
          draft.serviceDataToEdit = undefined;
          draft.projectDataToEdit = undefined;
          draft.isProjectEdit = false;
          draft.isServiceEdit = false;
        });
      }
      break;

    case SOCIAL_URL_SUCCESS:
    case SUBSCRIBE_SUCCESS:
      {
        //const { profile, projects } = action.payload;
        // console.log("reducer Social:", action.payload);
        const profile = action.payload;

        return produce(state, (draft) => {
          draft.profile = profile;
          // if(projects){
          //   draft.projects = projects?.projects;
          // }
          draft.serviceDataToEdit = undefined;
          draft.projectDataToEdit = undefined;
          draft.isProjectEdit = false;
          draft.isServiceEdit = false;
        });
      }
      break;

    case FETCH_BUSINESS_BY_ID_FAILURE:
      {
        return produce(state, (draft) => {
          draft.profile = {};
          draft.services = [];
          draft.projects = [];
          draft.serviceDataToEdit = undefined;
        });
      }
      break;

    case SERVICE_ADD_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.serviceDataToEdit = undefined;
        });
      }
      break;

    case SERVICE_EDIT_TRIGGER:
      {
        // console.log("serviceReducer() SERVICE_EDIT_TRIGGER", action.payload);
        const { service } = action.payload;
        return produce(state, (draft) => {
          draft.isServiceEdit = true;
          draft.serviceDataToEdit = service;
        });
      }
      break;

    case PROJECT_ADD_TRIGGER:
      {
        return produce(state, (draft) => {
          draft.serviceDataToEdit = undefined;
          draft.projectDataToEdit = undefined;
        });
      }
      break;

    case PROJECT_EDIT_TRIGGER:
      {
        // console.log("serviceReducer() PROJECT_EDIT_TRIGGER", action.payload);
        const { project } = action.payload;
        return produce(state, (draft) => {
          draft.isProjectEdit = true;
          draft.projectDataToEdit = project;
        });
      }
      break;

    case IMAGE_UPLOAD:
      {
        const { file, type, name, supplierId, id } = action.payload;
        // console.log("ImageReducer() IMAGE_UPLOAD", action.payload);
        return produce(state, (draft) => {
          draft.imageToUpload = { file, type, name, supplierId, id };
        });
      }
      break;

    case SIGNOUT:
      {
        return produce(state, (draft) => {
          draft.supplierId = 0;
          draft.companyName = "";
          draft.profile = {};
          draft.services = [];
          draft.projects = [];
          draft.serviceDataToEdit = undefined;
          draft.projectDataToEdit = undefined;
          draft.imageToUpload = undefined;
        });
      }
      break;
    case IMAGE_UPLOAD_SUCCESS:
    case BUSINESS_IMAGE_UPLOAD_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.profile = state.profile;
        });
      }
      break;

    case IMAGE_UPLOAD_FAILURE: {
      const { message } = action.payload;
      return produce(state, (draft) => {
        draft.uploadSizeError = { errorMessage: message, time: Date.now() };
      });
    }
    case UPDATE_BUSINESS_PROFILE_STEPPER_SUCCESS: {
      const profile = action.payload;
      return produce(state, (draft) => {
        draft.profile = profile;
      });
    }
    case UPDATE_BUSINESS_PROFILE_STEPPER_FAILURE: {
      const { profile} = action.payload;
      return produce(state, (draft) => {
        draft.profile = {};
      });
    }
    case HIDE_CONTACT_DETAIL_SUCCESS:
      return produce(state, (draft) => {
        draft.contactsData= action.payload// Assuming payload contains the new contact details
      });

    default:
      {
        return state;
      }
      break;
  }
};

export default businessReducer;
