import SidePanel from "layouts/SidePanel";
import React, { useEffect, useRef, useState } from "react";
import { RepeatRfqsTable } from "./Componets";
import Button from "components/Button";
import RepeatFixedRfqPopUp from "views/UserProfile/Requirements/RepeatFixedRfqPopUp";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import { fetchRepeatFixedRfq } from "actions/requirementsAction";
import { PaginationSection } from "views/CompanyDirectory/components";
import requirementsService from "services/requirementsService";
import ErrorPopup from "components/ErrorPopup";
const RepeatRFQs = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchRef = useRef(null);

  const auth = useSelector((state) => state.auth);
  const repeatFixedRfqs = useSelector((state) => state.repeatFixedRfq);
  const userProfile = auth?.user;
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [workspaceView, setWorkspaceView] = useState("My Workspace");
  const [workspaceDropDownValue, setWorkspaceDropDownValue] = useState("All");
  const [repeatFixedRfq, setRepeatFixedRfq] = useState(repeatFixedRfqs);
  const [repeatFixedPopUp, setRepeatFixedPopUp] = useState(false);
  const [repeatOrderLoading, setRepeatOrderLoading] = useState(false);
  const [repeatOrderError, setRepeatOrderError] = useState(false);
  const [teamMemberData, setTeamMemberData] = useState([userProfile?.userId]);
  const [pageNumber, setPageNumber] = useState(repeatFixedRfq?.currentPage);

  const userId = userProfile?.userId;
  const currentPageUrl = location.pathname;
  const totalPages = repeatFixedRfq?.totalPages;
  const teamUserIds = userProfile?.teamMembers?.map((member) => +member.userId);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") {
      setRepeatFixedRfq(repeatFixedRfqs);
      setRepeatOrderLoading(false);
      setSearchKey("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // setLoading(true);
      setPageNumber(1);
      handleSearchRfqs(1);
      e.preventDefault();
    }
  };

  const handleSearchRfqs = async (pageNo) => {
    setRepeatOrderLoading(true);
    const payload = {
      searchParam: searchValue,
      page: pageNo ?? pageNumber ?? 1,
      userIds: teamMemberData,
    };
    setSearchKey(searchValue);
    try {
      const response = await requirementsService.searchRepeatFixedRfqs(payload);
      setRepeatFixedRfq({
        requirements: response?.rfqs,
        currentPage: response.currentPage,
        totalPages: response.totalPages,
        count: response.totalRepeatRfqs,
      });
    } catch (error) {
      console.error("Error fetching RFQs:", error);
      setRepeatOrderError(true);
      // Optionally, you can set an error state here if needed
    } finally {
      setRepeatOrderLoading(false);
    }
  };
  const resetToDefault = () => {
    setPageNumber(1);
    setSearchValue("");
    setSearchKey("");
  };
  const fetchAllRepeatOrders = () => {
    setWorkspaceView("Team Workspace");
    setWorkspaceDropDownValue("All");
    setTeamMemberData([...teamUserIds, userProfile?.userId]);
    dispatch(fetchRepeatFixedRfq([...teamUserIds, userProfile?.userId], 1));
    resetToDefault();
  };

  const fetchPersonalRepeatOrders = () => {
    setWorkspaceView("My Workspace");
    setTeamMemberData([userProfile?.userId]);
    dispatch(fetchRepeatFixedRfq([userProfile?.userId], 1));
    resetToDefault();
  };

  const handleChangeTeamMember = (event) => {
    const selectedUserId = event.target.value;
    setWorkspaceDropDownValue(selectedUserId);
    if (selectedUserId === "All") {
      fetchAllRepeatOrders();
    } else if (selectedUserId === "Team") {
      dispatch(fetchRepeatFixedRfq(teamUserIds, 1));
      setTeamMemberData(teamUserIds);
    } else {
      dispatch(fetchRepeatFixedRfq([selectedUserId], 1));
      setTeamMemberData([selectedUserId]);
    }
    setPageNumber(1);
    setSearchValue("");
    setSearchKey("");
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
    if (searchValue === "") {
      dispatch(fetchRepeatFixedRfq(teamMemberData, page));
    } else {
      handleSearchRfqs(page);
    }
  };

  useEffect(() => {
    if (currentPageUrl === "/repeated-orders/create") setRepeatFixedPopUp(true);
    dispatch(fetchRepeatFixedRfq([userId], 1));

    // when user click ctrl + f  then focus on search bar
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault(); // Prevent default Ctrl + F behavior
        searchRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    setRepeatFixedRfq(repeatFixedRfqs);
  }, [repeatFixedRfqs]);
  return (
    <SidePanel>
      <div className="bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] h-screen flex flex-col ">
        <div className="bg-white sticky top-0 p-4 shadow-xl h-16">
          <h1 className="text-2xl font-bold">Repeat Orders </h1>
        </div>
        <div className="w-full px-5 flex justify-between items-center pt-4">
          <div className="min-w-[150px] h-10 mb-2">
            {userProfile?.teamMembers?.length > 0 &&
              workspaceView === "Team Workspace" && (
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <Select
                    id="demo-simple-select-helper"
                    value={workspaceDropDownValue}
                    onChange={handleChangeTeamMember}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"Team"}>Team</MenuItem>
                    {userProfile?.teamMembers?.map((member) => (
                      <MenuItem value={+member.userId}>{member?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
          </div>
          <div className="w-full flex justify-between ">
            {userProfile?.teamMembers?.length > 0 && (
              <div className="flex gap-4">
                <button
                  className={`pb-2 ${
                    workspaceView === "My Workspace"
                      ? " border-b border-orange-500 font-medium"
                      : ""
                  } `}
                  onClick={fetchPersonalRepeatOrders}
                >
                  <p className="text-sm">My Workspace</p>
                </button>
                <button
                  className={`pb-2 ${
                    workspaceView === "Team Workspace"
                      ? " border-b border-orange-500 font-medium"
                      : ""
                  } `}
                  onClick={fetchAllRepeatOrders}
                >
                  <p className="text-sm">Team Workspace</p>
                </button>
              </div>
            )}
            <div className="mb-2 ml-auto">
              <Button
                type="button"
                variant="primary"
                onClick={() => setRepeatFixedPopUp(true)}
              >
                Create Repeat Order
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full px-5">
          <div className="flex p-2  bg-white flex-col items-center justify-between  space-y-3 md:flex-row md:space-y-0 md:space-x-4 ">
            <div>
              <p className="font-medium text-gray-600">
                {repeatFixedRfq?.requirements?.length >= 1 && (
                  <>
                    {" "}
                    {`Showing ${(repeatFixedRfq?.currentPage - 1) * 10 + 1}-${
                      (repeatFixedRfq?.currentPage - 1) * 10 +
                      repeatFixedRfq?.requirements?.length
                    } of ${repeatFixedRfq?.count} Repeat Order ${
                      repeatFixedRfq?.count > 1 ? "results" : "result"
                    } `}
                    {searchKey != "" ? (
                      <span>
                        for{" "}
                        <span className="text-[#fd6600] font-bold">
                          "{searchKey}"
                        </span>
                      </span>
                    ) : null}
                  </>
                )}{" "}
              </p>
            </div>
            <div className="w-full md:w-1/2 my-2 ">
              <div className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    ref={searchRef}
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-100 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search here..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-5 flex-grow overflow-x-auto overflow-y-auto">
          <RepeatRfqsTable
            repeatFixedRfq={repeatFixedRfq}
            handlePageChange={handlePageChange}
            pageNumber={pageNumber}
            userId={userId}
            teamMemberData={teamMemberData}
            repeatOrderLoading={repeatOrderLoading}
            isAdmin={userProfile?.teamMembers?.length > 0}
          />
        </div>
        {totalPages > 1 && (
          <div className="h-20">
            <PaginationSection
              totalPages={totalPages}
              currentPage={pageNumber}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
      </div>

      <RepeatFixedRfqPopUp
        repeatFixedPopUp={repeatFixedPopUp}
        setRepeatFixedPopUp={setRepeatFixedPopUp}
        userId={userId}
      />
      <ErrorPopup
        openModal={repeatOrderError}
        handleClosePopup={() => setRepeatOrderError(false)}
        warningText={"Something went wrong please try again"}
      />
    </SidePanel>
  );
};

export default RepeatRFQs;
