import React from 'react'
import { useNavigate } from 'react-router';

const BenefitsOfNeevay = ({scrollToHome}) => {

    const navigate = useNavigate()
    const contactVendor = () => {
        navigate("/get-started");
        window.scroll(0, 0);
    }

    return (
        <div className='py-[80px]'>
            <p className='font-extrabold text-4xl md:text-[40px] text-center pb-10 md:pb-16'> Benefits of Neevay</p>
            <div className='md:space-y-12 lg:space-y-12 xl:space-y-12 px-4 md:pt-0 md:px-0'>

                {/* explore */}
                <div className='md:flex md:justify-around'>
                    <div className='xs:mx-4 md:mx-0'>
                        <p style={{ fontFamily: "rajdhani" }} className=' md:pt-14 xl:pt-24 text-[#F16500] font-bold xs:text-[14px] md:text-base lg:text-xl xl:text-base '>EXPLORE</p>
                        <p className='md:text-2xl lg:text-[32px] text-2xl font-bold py-2 md:pb-8 md:pt-4'>Browse vendor profiles</p>
                        {/* text for md */}
                        <p className='hidden md:block lg:hidden md:text-sm text-sm '>Browse through thousands of vendor<br /> profiles in your domain for free. You<br /> can also view their contact information</p>
                        {/* text for lg */}
                        <p className='hidden lg:block text-base'>Browse through thousands of vendor profiles in your<br /> domain for free. You can also view their contact<br /> information.</p>
                        {/* text for mobile */}
                        <p className='md:hidden text-lg '>Browse through thousands of vendor<br /> profiles in your domain for free. You<br /> can also view their contact information</p>

                        <button onClick={scrollToHome} className='md:text-sm lg:text-base xs:text-lg font-bold md:mt-0 mt-4 md:pt-8 text-[#F16500] flex items-center'>
                            Start Search Now
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_Arrow.svg" alt="->" className='h-3 ml-2 w-4 md:h-3 md:w-5 md:ml-4' />
                        </button>
                    </div>
                    <div className='xs:flex xs:justify-center xs:items-center md:mb-0 md:mt-0 mt-[45px] mb-[80px]'>
                        <div className='xl:w-[587px] xl:h-[587px] lg:w-[396px] lg:h-[396px] md:h-[328px] md:w-[328px] w-[292px] h-[292px] flex flex-col justify-center items-center bg-[#F4F4F4] rounded-full'>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_browseVendors.svg" alt="" className='xl:h-[650px] xl:w-[620px] lg:w-[468px] lg:h-[330px] md:w-[387px] md:h-[330px] max-w-[120%]' />
                        </div>
                    </div>
                </div>

                {/* privacy */}
                <div className='flex md:flex-row xs:flex-col-reverse md:justify-around'>
                    <div className='xs:flex xs:justify-center xs:items-center'>
                        <div className='xl:w-[587px] xl:h-[587px] lg:w-[396px] lg:h-[396px] md:h-[328px] md:w-[328px] w-[292px] h-[292px] mt-[45px] mb-[80px] md:my-0 xs:mx-auto flex flex-col justify-center items-center bg-[#F4F4F4] rounded-full'>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage4_zeroSpam.svg" alt="" className='xl:h-[536px] lg:h-[370px] md:h-[308px] h-[273px] w-full' />
                        </div>
                    </div>
                    <div className='xs:ml-4'>
                        <p style={{ fontFamily: "rajdhani" }} className=' md:pt-6 lg:pt-10 text-[#F16500] font-bold xs:text-[14px]  md:text-base lg:text-xl xl:text-base'>PRIVACY</p>
                        <p className='xs:text-2xl md:text-2xl lg:text-[32px] font-bold xs:py-2 md:pb-8 md:pt-4'>Zero Spam</p>
                        {/* text for md */}
                        <p className='md:block hidden lg:hidden md:text-sm'>Unlike other platforms, you won't receive<br /> spam calls by contacting our vendors.</p>
                        <p className='md:block hidden lg:hidden md:text-sm pt-2'>Each request to a vendor just goes to<br /> that particular vendor and no one else.</p>
                        {/* text for lg */}
                        <p className='hidden lg:block text-base '>Unlike other platforms, you won't receive spam calls<br /> by contacting our vendors.</p>
                        <p className='hidden lg:block text-base pt-2 '>Each request to a vendor just goes to that particular<br />vendor and no one else.</p>
                        {/* text for mobile */}
                        <p className='md:hidden text-lg '>Unlike other platforms, you won't<br /> receive spam calls by contacting our<br /> vendors.</p>
                        <p className='md:hidden text-lg pt-2 '>Each request to a vendor just goes to<br /> that particular vendor and no one else.</p>

                        <button onClick={contactVendor} className='md:text-sm xs:text-lg font-bold pt-4 md:pt-8 text-[#F16500] flex items-center'>
                            Contact Vendors Now
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_Arrow.svg" alt="->" className='h-3 ml-2 w-4 md:h-3 md:w-5 md:ml-4' />
                        </button>
                    </div>
                </div>

                {/* tenders */}
                <div className='md:flex md:justify-around'>
                    <div className='ml-4 md:ml-0'>
                        <p style={{ fontFamily: "rajdhani" }} className=' md:pt-16 lg:pt-24 text-[#F16500] font-bold xs:text-[14px] md:text-base lg:text-xl xl:text-base'>TENDERS</p>
                        <p className='text-2xl md:text-2xl lg:text-[32px] font-bold xs:py-[8px] md:pb-8 md:pt-4'>Post Open Requirements</p>
                        {/* text for lg */}
                        <p className='hidden lg:block text-base '>Want our vendors to contact you instead ?</p>
                        <p className='hidden lg:block text-base pt-3'>List your requirements as open requirement and vendors on the<br /> platform will express interest. Select vendor profiles you like<br /> and invite them into your bidding process</p>
                        <p className='hidden lg:block text-base pt-3'>Easily hide your identity to get protection from getting spammed</p>
                        {/* text for md */}
                        <p className='hidden md:block lg:hidden text-sm '>Want our vendors to contact you instead ?</p>
                        <p className='hidden md:block lg:hidden text-sm pt-3'>List your requirements as open <br />requirement and vendors will <br />interest. Select vendors and invite them in<br /> your bidding process </p>
                        <p className='hidden md:block lg:hidden text-sm pt-3'>Easily hide your identity to get protection<br /> from unsolicited vendors</p>
                        {/* text for mobile */}
                        <p className='md:hidden text-lg '>Want our vendors to contact you<br /> instead ?</p>
                        <p className='md:hidden text-lg pt-2 '>List your requirements as open<br /> requirement and our vendors will <br />express interest. Select vendors and<br /> invite them in your bidding process</p>
                        <p className='md:hidden text-lg pt-2 '>Easily hide your identity to get<br /> protection from unsolicited vendors</p>
                    </div>
                    <div className='xs:flex xs:justify-center xs:items-center xs:mt-[45px] xs:mb-[80px]'>
                        <div className='md:w-[328px] md:h-[328px] lg:w-[396px] lg:h-[396px] xl:h-[587px] xl:w-[587px] flex flex-col justify-center items-center bg-[#F4F4F4] rounded-full'>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage4_post_open_rfq.svg" alt="" className='w-full xl:h-[550px] lg:h-[395px] md:h-[314px] h-[283px] ' />
                        </div>
                    </div>
                </div>

                {/* access */}
                <div className='flex xs:flex-col-reverse md:flex-row md:justify-around'>
                    <div className='flex justify-center items-center mt-[45px] md:mt-0 '>
                        <div className='xl:w-[587px] xl:h-[587px] lg:w-[396px] lg:h-[396px] md:w-[328px] md:h-[328px] xs:w-[292px] xs:h-[292px] flex flex-col justify-center items-center bg-[#F4F4F4] rounded-full'>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_filterVendors.svg" alt="filter vendors" className='w-full md:h-[305px] lg:h-[368px] xl:h-[490px] xs:h-[273px] ' />
                        </div>
                    </div>
                    <div className='ml-4'>
                        <p style={{ fontFamily: "rajdhani" }} className=' md:pt-6 lg:pt-16 text-[#F16500] font-bold xs:text-[14px] md:text-base lg:text-xl xl:text-base'>ACCESS</p>
                        <p className='xs:text-2xl md:text-2xl lg:text-[32px] font-bold xs:py-[8px] md:pb-8 md:pt-4'>Filter Vendors</p>
                        {/* text for lg */}
                        <p className='hidden lg:block text-base'>Filters vendor on the basis of services, sector, GST,<br /> Turnover, number of projects completed and 10 more<br /> parameters</p>
                        {/* text for md */}
                        <p className='hidden md:block lg:hidden text-sm '>Filters vendor on the basis of services,<br /> sector, GST, Turnover, number of<br /> projects completed and 10 more<br />parameters</p>
                        {/* text for mobile */}
                        <p className='md:hidden text-lg '>Filters vendor on the basis of services,<br /> sector, GST, Turnover, number of<br /> projects completed and 10 more<br /> parameters</p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BenefitsOfNeevay
