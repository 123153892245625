import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import axios from "utils/axios";
import { useSelector } from 'react-redux';
import QuillEditorComponent from '../QuillEditorComponent';

const LastCall = ({ closeModal, vendors, rfqId, entityId, rfqData }) => {
    const auth = useSelector((state) => state.auth);
    const user = auth?.user;
    const { enqueueSnackbar } = useSnackbar();
    const [closureDate, setClosureDate] = useState('');
    const [defaultMessage, setDefaultMessage] = useState('');

    const getMessageTemplate = (closureDate) => (
        `This is to inform you that the RFQ for <b>${rfqData.projectDetails ? rfqData?.projectDetails.name : rfqData?.projectName || ""}</b>, <b>${rfqData?.location}</b> below will be closing soon.<br />
        Closure Date : <b>${closureDate}</b><br />
        If you haven't shared your quotation yet, please submit it as soon as possible.`
    );

    useEffect(() => {
        setDefaultMessage(getMessageTemplate(closureDate));
    }, [closureDate, rfqData, user]);

    const allVendor = { businessName: 'All', supplierId: 'all' };
    const vendorList = vendors.length > 1 ? [allVendor, ...vendors] : vendors;

    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCheckboxChange = (vendor) => {
        if (vendor.supplierId === 'all') {
            setIsAllSelected(!isAllSelected);
            setSelectedVendors(!isAllSelected ? vendors.map(v => v.supplierId) : []);
        } else {
            const updatedVendors = selectedVendors.includes(vendor.supplierId)
                ? selectedVendors.filter(id => id !== vendor.supplierId)
                : [...selectedVendors, vendor.supplierId];

            setSelectedVendors(updatedVendors);
            setIsAllSelected(updatedVendors.length === vendors.length);
        }
    };

    const handleNotifyVendors = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post(
                '/api/rfqs/last-call',
                { rfqId, entityId, closureDate, supplierIds: selectedVendors, message: values.message },
                { headers: { 'Content-Type': 'application/json' } }
            );

            enqueueSnackbar('Vendors notified successfully', { variant: 'success', autoHideDuration: 3000 });
            setSelectedVendors([]);
            setClosureDate('');
            closeModal();
        } catch (error) {
            enqueueSnackbar(error.response?.data?.message || 'An error occurred. Please try again.', { variant: 'error', autoHideDuration: 3000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik
            initialValues={{ message: defaultMessage }}
            onSubmit={handleNotifyVendors}
            enableReinitialize // Ensures message updates in Formik when defaultMessage changes
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className="bg-white bg-opacity-100 shadow-lg">
                        <div className="px-6 pt-2 overflow-y-auto">
                            <div className="mb-4">
                                <label className="block mb-2 text-left text-sm font-semibold text-[#4F4F42]">
                                    Target Closure Date
                                </label>
                                <input
                                    type="date"
                                    value={closureDate}
                                    onChange={(e) => setClosureDate(e.target.value)}
                                    className="text-sm text-left font-semibold text-[#4F4F42] mb-2"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-left mb-2 text-sm font-semibold text-[#4F4F42]">
                                    Select all the vendors who should be notified
                                </label>
                                <div className="bg-[#DCDCD0] bg-opacity-50 p-3 border shadow-md">
                                    <div className="max-h-[78px] overflow-y-auto p-1">
                                        {vendorList.map((vendor, index) => (
                                            <div key={vendor.supplierId} className="flex items-center mb-2">
                                                <input
                                                    type="checkbox"
                                                    id={`vendor-${index}`}
                                                    value={vendor.supplierId}
                                                    checked={vendor.supplierId === 'all' ? isAllSelected : selectedVendors.includes(vendor.supplierId)}
                                                    onChange={() => handleCheckboxChange(vendor)}
                                                    className="mr-2 focus:ring-1 rounded-sm"
                                                />
                                                <label htmlFor={`vendor-${index}`} className="text-sm font-normal text-[#4F4F42]">
                                                    {vendor.businessName}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm text-left font-semibold mb-2 text-[#4F4F42]">
                                    Please write the message to send to the vendors
                                </label>
                                <QuillEditorComponent
                                    fieldName="message"
                                    placeholder="Please write your message here (Rich Text)"
                                />
                            </div>
                        </div>
                        <div
                            style={{ boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1)' }}
                            className="z-5 flex justify-end sticky bottom-0 bg-white space-x-2 py-4 px-6"
                        >
                            <button
                                onClick={closeModal}
                                className="px-4 py-2 text-xs font-normal text-gray-700 border hover:bg-gray-100"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={loading}
                                className="px-4 py-2 text-xs font-normal text-white bg-black hover:bg-gray-800"
                            >
                                {loading ? 'Loading...' : 'Notify Vendors'}
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LastCall;
