import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  CardMedia,
  Link,
} from "@mui/material";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

import ServiceCard from "./ServiceCard";

import { addServiceTrigger } from "actions/serviceActions";

import { SEARCH_DEFAULT_IMAGE } from "config";
import ServiceCardPublic from "./ServiceCardPublic";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function ProfileService({
  supplierId,
  services,
  isPublicProfile,
  permissions,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(
    services?.length ? services[0].serviceName : "NotSelected"
  );

  useEffect(() => {
    if (services?.length) {
      setValue(services[0]?.serviceName);
    }
  }, [services]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleAddService = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isOwner) {
        dispatch(addServiceTrigger);
        navigate("/add-service");
      } else {

      }


    } else {
      navigate("/subscribe-plan");
    }
    // console.log("handleAddService");

  };

  const handlePlanUpgrade = () => {
    navigate("/subscribe-plan");
  };

  let isServiceExists = false;

  if (!services) {
    isServiceExists = false;
  } else {
    services.map((service) => {
      if (!service.isDeleted) {
        isServiceExists = true;
      }
    });
  }

  return (
    <Container >
      {/* <Grid container mt={2}> */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container mt={2}>
          <Grid item sm={12}>
            {((isPublicProfile && isServiceExists) || !isPublicProfile) && (
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="businessTitle"
                // fontSize={"18px"}
                // color={"rgba(0, 0, 0, 0.6)"}
                // fontWeight={"700"}
                >
                  BUSINESS OFFERINGS
                </Typography>
                {!isPublicProfile && <CardMedia
                  component="img"
                  image={DEFAULT_IMAGES.PREMIUM_BADGE}
                  height="20px"
                  sx={{ width: "25px", ml: 1, mt: -1 }}
                />}

              </Box>
            )}
          </Grid>

          <Grid item sm={12}>
            {permissions &&
              !permissions.isViewOnly &&
              permissions.isFreeUpdateAllowed && (
                <Typography
                  sx={{
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                  color="secondary"
                  size="small"
                  disabled={!(permissions && permissions.isUpdateAllowed)}
                  // sx={{ ml: 2 }}
                  // endIcon={<AddCircleRoundedIcon />}
                  onClick={handleAddService}
                >
                  <Link sx={{ textDecoration: "none", color: "blue" }}>
                    {" "}
                    Click Here{" "}
                  </Link>
                  <span style={{ color: "black" }}> to Add Service</span>
                </Typography>
              )}
          </Grid>
        </Grid>
        {/* {!isPublicProfile && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disabled={permissions && permissions.isUpdateAllowed}
              sx={{ ml: 2 }}
              endIcon={<WorkspacePremiumIcon />}
              onClick={handlePlanUpgrade}
            >
              Upgrade
            </Button>
          )} */}
      </Box>

      {(
        <Grid container columnGap={6} justifyContent="space-between" margin={"20px ,0"}>


          {/* The Private or edit  section start from here */}

          {permissions && !permissions.isViewOnly && permissions.isUpdateAllowed && (
            <Box fullWidth sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <TabList
                  orientation="horizontal"
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    // borderRight: 2,
                    borderBottom: 1,
                    borderColor: "divider",
                    // bgcolor: "secondary.main",
                  }}
                >
                  {services &&
                    services.length > 0 &&
                    services.map((service, index) => (
                      <Tab
                        label={service.serviceName}
                        value={service.serviceName}
                        variant="contained"
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          borderRight: "1px solid rgba(229, 229, 229, 0.6)",
                        }}
                      />
                    ))}
                </TabList>

                {services &&
                  services.length > 0 &&
                  services.map((service, index) => (
                    <TabPanel value={service.serviceName} >
                      <Grid
                        item
                        key={index + service.serviceName}
                        sx={{
                          maxWidth: "680px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: "20px",
                          padding: " 24px 10px",
                          marginBottom: "20px"
                        }}
                      >
                        <ServiceCard
                          serviceName={service.serviceName}
                          description={service.description}
                          serviceDetails={service.serviceDetails}
                          imageUrl={
                            service.bannerUrl === undefined ||
                              service.bannerUrl === ""
                              ? undefined
                              : service.bannerUrl
                          }
                          isPublicProfile={isPublicProfile}
                          serviceData={service}
                          supplierId={supplierId}
                          usps={service.usps}
                          permissions={permissions}
                          serviceId={service._id}
                        />
                      </Grid>
                    </TabPanel>
                  ))}
              </TabContext>
            </Box>
          )}

          {/* The Private or edit  section start from here */}

          {/* The public section start from here */}

          {(isPublicProfile || (permissions && (permissions.isViewOnly || !permissions.isUpdateAllowed))) &&
            <Grid xs={12} container gap={6} justifyContent="space-between" >{(

              services &&
              services.length > 0 &&
              services.map((service, index) => (
                <Grid
                  item
                  key={index + service.serviceName}
                  sx={{
                    // width: "520px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "20px",
                    padding: " 24px 10px",
                    marginBottom: "20px"
                  }}
                  xs={12} sm={12} md={5.6}
                >
                  <ServiceCardPublic
                    serviceName={service.serviceName}
                    description={service.description}
                    serviceDetails={service.serviceDetails}
                    imageUrl={
                      service.bannerUrl === undefined ||
                        service.bannerUrl === ""
                        ? undefined
                        : service.bannerUrl
                    }
                    isPublicProfile={isPublicProfile}
                    serviceData={service}
                    supplierId={supplierId}
                    usps={service.usps}
                    permissions={permissions}
                    serviceId={service._id}
                  />
                </Grid>
              )))
            } </Grid>
          }

          {/* The public section end from here */}
        </Grid>
      )}
      {/* </Grid> */}
    </Container>
  );
}

ProfileService.defaultProps = {
  isPublicProfile: true,
};

ProfileService.propTypes = {
  services: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool,
  supplierId: PropTypes.number,
  permissions: PropTypes.object,
};

export default ProfileService;
