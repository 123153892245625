import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "column",
    backgroundColor: "white",
    alignItems: "left",
    textAlign: "left",
  },
  billTo: {
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  invoiceDateContainer: {
    flexDirection: "row",

    // justifyContent: 'flex-end',
    alignItems: "right",
  },
  invoiceDateContainer1: {
    flexDirection: "row",
    marginTop: 11,
    // justifyContent: 'flex-end',
    alignItems: "right",
  },
  label: {
    width: 200,
    alignItems: "left",
  },
  label1: {
    width: 200,
    alignItems: "left",
    fontSize: 12,
    // fontStyle: "bold",
    fontFamily: "Cambria"
    // marginTop:5
  },
});

const BillTo = ({ invoice }) => {
  const igst = invoice.gst ? `${invoice.gst.igst}` : undefined;
  const cgst = invoice.gst ? `${invoice.gst.cgst}` : undefined;
  const sgst = invoice.gst ? `${invoice.gst.sgst}` : undefined;
  const totalAmount = invoice ? `${invoice.totalAmount}` : undefined;

  return (
    <View style={styles.headerContainer}>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>IGST </Text>
        <Text>Rs. {igst}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>CGST </Text>
        <Text>Rs. {cgst}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>SGST </Text>
        <Text>Rs. {sgst}</Text>
      </View>
      <View style={styles.invoiceDateContainer1}>
        <Text style={styles.label1}>Total Paid </Text>
        <Text style={{ fontFamily: "Cambria" }}>Rs. {totalAmount}</Text>
      </View>
    </View>
  );
};

export default BillTo;
