import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import BlogForm from "./BlogForm";
import ListOfBlog from "./ListOfBlog";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UnauthorizedAccess } from "./UnauthorizedAccess";

const Blog = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  // Array of allowed mobile numbers
  // const allowedMobileNumbers = ['7841801413', '9822197553'];
  const allowedMobileNumbers = ['7979758639', '9958880339','8826386281','9822197553',];

  // Filter routes based on userProfile
 const filterCmsUser=userProfile && allowedMobileNumbers.includes(userProfile.mobile);
  return <>
  
   {filterCmsUser? <>
      <h1
        style={{
          textAlign: "center",
          fontSize: "30px",
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: 700,
        }}
      >
        Publish Neevay Blogs
      </h1>
      <Box sx={{ borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Blog Form" />
          <Tab label="List Of Blogs" />
        </Tabs>
        {value === 0 && <BlogForm />}
        {value === 1 && <ListOfBlog />}
      </Box>
    </>:<UnauthorizedAccess/>}
  </>
};

export default Blog;
