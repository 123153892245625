import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { CREATE_CRM_LEAD_URL } from "config/services";
import axios from "utils/axios";

import {
  Box,
  Button,
  FormHelperText,
  FormControl,
  Fab,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { registerUserWithMobile, sendwhatsAppOTP } from "actions/authActions";
import { SIGN_UP_COUNTER } from "config";
import { getCRMLeadByPhone } from "actions/crmAction";

function SignUpMobileForm({
  className,
  onSubmitSuccess,
  setIsWhatsappNo,
  isWhatsappNo,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passValue, setpassValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setpassValues({ ...passValue, showPassword: !passValue.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setIsWhatsappNo(checked);
  };

  const [checked, setChecked] = React.useState(false);

  return (
    <Formik
      initialValues={{
        name: "",
        mobile: "",
      }}
      validationSchema={Yup.object().shape({
        // name: Yup.string().min(2).max(80).required("Please enter valid name"),
        mobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .min(10)
          .max(10)
          .required("Please Enter your mobile"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (isWhatsappNo) {
            dispatch(sendwhatsAppOTP(values.mobile));
          } else {
            dispatch(registerUserWithMobile(values.name, values.mobile));
          }
          localStorage.setItem("signUpMobile", values.mobile);
          onSubmitSuccess();
          const payload = [
            {
              Attribute: "EmailAddress",
              Value: "",
            },
            {
              Attribute: "Phone",
              Value: values.mobile,
            },
          ];

          axios
            .post(CREATE_CRM_LEAD_URL, payload)
            .then((crmResponse) => {
              // console.log(":CREATE_CRM_LEAD: Response",crmResponse)
              dispatch(getCRMLeadByPhone(values.mobile));
            })
            .catch((crmError) => {
              // console.log("CRMERROR",crmError)
            });
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={4} maxWidth="sm">
            {/* <Grid item xs={12}>
              <TextField
                name="name"
                id="name"
                label="Name"
                type="text"
                value={values.name}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 80 }}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid> */}

            <Grid item xs={12}>
              <TextField
                name="mobile"
                id="mobile"
                label="Mobile"
                value={values.mobile}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            {values.mobile && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 6,
                }}
              >
                <Checkbox
                  color="secondary"
                  name="whatsappNo"
                  onChange={handleCheckboxChange}
                  checked={isWhatsappNo}
                  sx={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "3px",
                    // border: "1px solid #898792"
                  }}
                />
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#6E6B7B",
                  }}
                >
                  Get OTP On Whatsapp Number
                </Typography>
              </Grid>
            )}

            {/* <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
            
              <Checkbox
                defaultChecked={checked}
                onChange={() => setChecked(!checked)}
              />
              <Typography>
                I have read and accept the{" "}
                <b>Terms and Conditions and Privacy Policy</b>{" "}
              </Typography>
            </Grid> */}

            <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 2 }}
            >
              <Button
                // color="secondary"
                // disabled={isSubmitting}

                // disabled={!checked}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: 30,
                  backgroundColor: "#FD6600",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FD6600",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                  },
                }}
              >
                Continue
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 2 }}
            >
              <Typography>
                Do you have an Account ?{" "}
                <span style={{ color: "blue" }}>
                  <Link to="/signin"> Sign In </Link>
                </span>{" "}
              </Typography>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignUpMobileForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

SignUpMobileForm.default = {
  onSubmitSuccess: () => {},
};

export default SignUpMobileForm;
