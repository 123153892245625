import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
    addEmailsToSupplier,
    inviteVendors,
    quotationReceived,
    removeVendorsFromRfq,
    resendBounceEmail,
} from "actions/rfqActions";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/Loading";
import Popup from "../../../../components/Popup";
import { VendorQuotation } from "./VendorQuotation";
import { Tooltip } from "flowbite-react";
import * as Yup from "yup";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import WarningPopup from "components/WarningPopup";
import StaticPopup from "components/StaticPopup";

const initialQuotationDetails = {
    received: "",
    amount: "",
    alignedToTerms: true,
    reason: "",
    otherReason: "",
    nonAlignmentReason: "",
    otherNonAlignmentReason: "",
};

const PostRfqInviteVendors = ({
    loading,
    vendorData,
    openResendRfq,
    rfqStatus,
    reInviteFunctions,
}) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const { rfqId } = useParams();
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [warningMsg, setWarningMsg] = useState(false);
    const [addEmailsLoader, setAddEmailsLoader] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [validEmails, setValidEmails] = useState([]);
    const [invalidEmails, setInvalidEmails] = useState([]);
    const [openQuotation, setOpenQuotation] = useState(false);
    const [openSupplierContact, setOpenSupplierContact] = useState(false);
    const [quotationDetails, setQuotationDetails] = useState(
        initialQuotationDetails
    );
    const [openResendEmailPopup, setOpenResendEmailPopup] = useState(false);
    const [resendEmailData, setResendEmailData] = useState({});
    const [quotationErrors, setQuotationErrors] = useState({});
    const [openWarningDeletePopup, setOpenWarningDeletePop] = useState(false);
    const [deleteVendorId, setDeleteVendorIdent] = useState("");
    const [resendNewEmail, setResendNewEmail] = useState("");
    const [resendLoading, setResendLoading] = useState({});
    const [resendErrorMessage, setResendErrorMessage] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [allStatus, setAllStatus] = useState([]);
    const user = auth.user;
    const entityId = user?.userEntity?.entityId;

    const navigate = useNavigate();


    const navigateToEditRfq = (supplier) => {
        const updatedRfq = { ...vendorData, suppliers: [supplier] };
    
        switch (vendorData?.rfqType) {
            case "material":
                if (vendorData?.materials && vendorData.materials.length > 0) {
                    navigate(`/material-form?rfqId=${vendorData?.rfqId}&rfqType=${vendorData?.rfqType}`, { 
                        state: { rfqData: updatedRfq, isEdit: false } 
                    });
                } else {
                    console.warn("Materials data is missing or empty.");
                    // Optional: Show alert or handle missing materials data
                }
                break;
    
            case "labour":
                if (vendorData?.labourDetails && vendorData.labourDetails.length > 0) {
                    navigate(`/labour-form?rfqId=${vendorData?.rfqId}&rfqType=${vendorData?.rfqType}`, { 
                        state: { rfqData: updatedRfq, isEdit: false } 
                    });
                } else {
                    console.warn("Labour details are missing or empty.");
                    // Optional: Show alert or handle missing labour data
                }
                break;
    
            case "contractor":
                if (vendorData?.providingFixingDetails ) {
                    navigate(`/providing-form?rfqId=${vendorData?.rfqId}&rfqType=${vendorData?.rfqType}`, { 
                        state: { rfqData: updatedRfq, isEdit: false } 
                    });
                } else {
                    console.warn("Contractor details are missing or empty.");
                    // Optional: Show alert or handle missing contractor data
                }
                break;
    
            default:
                console.warn("Invalid RFQ type.");
                // Optional: Handle invalid RFQ type if necessary
        }
    };
    

    const getQuotationReceivedComponent = (rank, supplier, isInvited) => {
        if (!isInvited) return " ";
        if (rank) {
            return (
                <div className="flex items-center justify-center border border-[#C4C4B9] hover:border-black py-1 gap-2">
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/clock.svg" alt="" />
                    <b className="text-xs font-normal">{rank}</b>
                    {/* {(rfqStatus === "OPEN" || rfqStatus === "DRAFT") && (
                        <Button
                            variant="text"
                            onClick={() => {
                                setQuotationDetails(supplier?.quotationDetails);
                                setSelectedSupplier(supplier);
                                setOpenQuotation(true);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                focusable="false"
                                // stroke="blue"
                                viewBox="0 0 24 24"
                                className="w-4 h-4 stroke-gray-400 fill-gray-400 hover:stroke-gray-600 hover:fill-gray-600"
                            >
                                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"></path>{" "}
                            </svg>
                        </Button>
                    )} */}
                </div>
            );
        }
        if (supplier?.quotationDetails?.received === false) {
            return (
                <div className="flex items-center">
                    {/* <b className="text-sm">--</b> */}
                    {(rfqStatus === "OPEN" || rfqStatus === "DRAFT") && (
                        <Button
                            variant="text"
                            onClick={() => {
                                setQuotationDetails(supplier?.quotationDetails);
                                setSelectedSupplier(supplier);
                                setOpenQuotation(true);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                focusable="false"
                                // stroke="blue"
                                viewBox="0 0 24 24"
                                className="w-4 h-4 stroke-gray-400 fill-gray-400 hover:stroke-gray-600 hover:fill-gray-600"
                            >
                                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"></path>{" "}
                            </svg>
                        </Button>
                    )}
                </div>
            );
        }
        if (supplier?.quotationDetails?.alignedToTerms === false) {
            return (
                <div className="flex items-center">
                    <b className="text-sm">NA</b>
                    {(rfqStatus === "OPEN" || rfqStatus === "DRAFT") && (
                        <Button
                            variant="text"
                            onClick={() => {
                                setQuotationDetails(supplier?.quotationDetails);
                                setSelectedSupplier(supplier);
                                setOpenQuotation(true);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                focusable="false"
                                // stroke="blue"
                                viewBox="0 0 24 24"
                                className="w-4 h-4 stroke-gray-400 fill-gray-400 hover:stroke-gray-600 hover:fill-gray-600"
                            >
                                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"></path>{" "}
                            </svg>
                        </Button>
                    )}
                </div>
            );
        }
        return (
            <button
                className="flex w-full items-center justify-center gap-2 text-xs py-1 px-2 bg-white border border-[#C4C4B9] text-black hover:border-[#585C62] hover:text-gray-700"
                // onClick={() => {
                //     setSelectedSupplier(supplier);
                //     setOpenQuotation(true);
                // }}
                onClick={()=>navigateToEditRfq(supplier)}
                // disabled={!(rfqStatus === "OPEN" || rfqStatus === "DRAFT")}
            >
                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/pending.svg" alt="" />
                Add Quotation
            </button>
        );
    };

    const getRemark = (supplier) => {
        return (
            supplier?.quotationDetails?.otherNonAlignmentReason ||
            supplier?.quotationDetails?.nonAlignmentReason ||
            supplier?.quotationDetails?.otherReason ||
            supplier?.quotationDetails?.reason ||
            supplier?.quotationDetails?.remark ||
            ""
        );
    };

    const handleOpenWarningPopup = (supplierId) => {
        setOpenWarningDeletePop(true);
        setDeleteVendorIdent(supplierId);
    };

    const handleContactStatus = (contactDetails) => {
        const data = contactDetails?.map((contact) => {
            return contact?.emailStatus;
        });
        if (data?.includes("Bounce")) {
            return "bounced _email_new.svg";
        }
        // Check for "open" if "bounce" is not found
        if (data?.includes("Open")) {
            return "opened_cropped_new.svg";
        }
        // Check for "Delivery" if neither "bounce" nor "open" are found
        if (data?.includes("Delivery")) {
            return "double_check_new.svg";
        }
        // Return an empty string if none of the keys are found
        return "double_check_new.svg";
    };

    const handleResendBounceEmail = async (newEmail) => {
        const payload = {
            supplierId: selectedSupplier?.supplierId,
            oldEmail: resendEmailData?.email,
            newEmail,
            rfqId: +rfqId,
        };
        try {
            const response = await dispatch(resendBounceEmail(payload));
            setResendLoading({});
            setOpenResendEmailPopup(false);
            handleClosePopUp();
        } catch (err) {
            setResendLoading({ newEmail: false });
            setResendErrorMessage(err?.message);
            setTimeout(() => {
                setResendErrorMessage("");
            }, 6000);
        }
    };

    const handleResendEmail = (email) => { };

    const handleCloseWarningPopup = () => {
        setOpenWarningDeletePop(false);
        setDeleteVendorIdent("");
    };

    const handleClosePopUp = () => {
        setSelectedSupplier(null);
        setOpenSupplierContact(false);
        setOpenResendEmailPopup(false);
        setResendNewEmail("");
        setInputValue("");
        setValidEmails([]);
        setInvalidEmails([]);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (invalidEmails?.length >= 1) setInvalidEmails([]);
    };

    const handleAddEmailUsingString = (stringOfEmails) => {
        const emails = stringOfEmails
            .split(/[ ,;]+/)
            .filter((email) => email.trim() !== "");
        const validEmailList = [];
        const invalidEmailList = [];

        emails.forEach((email) => {
            if (isValidEmail(email)) {
                if (
                    !selectedSupplier.contactDetails.some(
                        (existingObj) => existingObj.email === email
                    )
                )
                    validEmailList.push(email);
            } else {
                invalidEmailList.push(email);
            }
        });
        const emailObjects = validEmailList?.map((email) => ({
            name: "User",
            email: email,
        }));
        // Create a new Set to store unique emails
        const uniqueEmailsSet = new Set([...validEmails, ...validEmailList]);

        // Convert the Set back to an array
        const uniqueEmailsArray = Array.from(uniqueEmailsSet);

        // Update validEmails state with unique emails
        setValidEmails(uniqueEmailsArray);

        // Filter out duplicate email objects
        const uniqueEmailObjects = emailObjects.filter(
            (emailObj) =>
                !selectedSupplier.contactDetails.some(
                    (existingObj) => existingObj.email === emailObj.email
                )
        );

        setSelectedSupplier((prevState) => ({
            ...prevState,
            contactDetails: [...prevState.contactDetails, ...uniqueEmailObjects],
        }));
        if (invalidEmailList?.length >= 1) {
            setInvalidEmails(invalidEmailList);
            setInputValue(invalidEmailList.join());
        } else {
            setInputValue("");
        }
    };

    const handleInputPaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData("text");
        handleAddEmailUsingString(pastedText);
    };

    const handleAddEmailClick = () => {
        if (inputValue.trim() !== "") {
            const email = inputValue.trim();
            handleAddEmailUsingString(email);
        }
    };
    const handleSaveEmailsToSupplier = async () => {
        const supplierId = selectedSupplier?.supplierId;
        const response = await dispatch(
            addEmailsToSupplier({ entityId, rfqId, supplierId, emails: validEmails })
        );
        setTimeout(() => {
            setAddEmailsLoader(false);
        });
        handleClosePopUp();
    };

    const isValidEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleDeleteUserEmail = (email) => {
        setSelectedSupplier((prevState) => ({
            ...prevState,
            contactDetails: prevState.contactDetails.filter(
                (contact) => contact.email !== email
            ),
        }));

        const emails = validEmails.filter((newEmail) => newEmail !== email);
        setValidEmails(emails);
    };

    const handleClickInvite = (supplierIds) => {
        if (rfqStatus === "OPEN" || rfqStatus === "DRAFT") {
            dispatch(inviteVendors({ rfqId, supplierIds }));
        } else {
            setWarningMsg(true);
            setTimeout(() => {
                setWarningMsg(false);
            }, 4000);
        }
    };

    const handleDeleteVendor = async (supplierId) => {
        if (rfqStatus === "OPEN" || rfqStatus === "DRAFT") {
            const response = await dispatch(
                removeVendorsFromRfq({ rfqId, supplierId })
            );
            handleCloseWarningPopup();
        } else {
            setWarningMsg(true);
            setTimeout(() => {
                setWarningMsg(false);
            }, 4000);
            handleCloseWarningPopup();
        }
    };

    const getInitials = (name) =>
        name
            ?.split(" ")
            ?.map((word) => word[0])
            ?.join("")
            ?.toUpperCase();

    const unInvitedSuppliers = vendorData?.suppliers?.filter(
        (supplier) => supplier?.state.toLowerCase() !== "invited"
    );

    const resetQuotationDetails = () => {
        setQuotationDetails(initialQuotationDetails);
        setQuotationErrors({});
    };

    const resetQuotationTerms = () => {
        setQuotationDetails((prevState) => ({
            ...prevState,
            nonAlignmentReason: "",
            otherNonAlignmentReason: "",
        }));
    };

    const removeFieldErrors = (field) => {
        if (quotationErrors[field]) {
            setQuotationErrors((prevState) => {
                delete prevState[field];
                return { ...prevState };
            });
        }
    };

    const handleQuotationChange = (name, value) => {
        setQuotationDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const addQuotation = async () => {
        const quotationSchema = Yup.object().shape({
            received: Yup.boolean().required("Received is required"),
            amount: Yup.number().when("received", {
                is: true,
                then: Yup.number()
                    .required("Amount is required")
                    .min(1, "Amount must be greater than 0"),
                otherwise: Yup.number().notRequired(),
            }),
            alignedToTerms: Yup.boolean().when("received", {
                is: true,
                then: Yup.boolean().required("Alignment to terms is required"),
                otherwise: Yup.boolean().notRequired(),
            }),
            // otherNonAlignmentReason: Yup.string().when(
            //   ["received", "alignedToTerms"],
            //   {
            //     is: (received, alignedToTerms) => received && !alignedToTerms,
            //     then: Yup.string().required("Non-alignment reason is required"),
            //     otherwise: Yup.string().notRequired(),
            //   }
            // ),
            nonAlignmentReason: Yup.string().when(["received", "alignedToTerms"], {
                is: (received, alignedToTerms) => received && !alignedToTerms,
                then: Yup.string().required("Non-alignment reason is required"),
            }),
            otherNonAlignmentReason: Yup.string().when("nonAlignmentReason", {
                is: "Other",
                then: Yup.string().required("Other non-alignment reason is required"),
            }),
            reason: Yup.string().when("received", {
                is: false,
                then: Yup.string().required("Reason is required"),
                otherwise: Yup.string().notRequired(),
            }),
            otherReason: Yup.string().when("reason", {
                is: "Other",
                then: Yup.string().required("Other reason is required"),
                otherwise: Yup.string().notRequired(),
            }),
        });
        try {
            await quotationSchema.validate(
                {
                    ...quotationDetails,
                    amount: Number(quotationDetails.amount)
                        ? Number(quotationDetails.amount)
                        : 0,
                },
                { abortEarly: false }
            );
            // Filter out properties with empty values
            const filtersQuotationDetails = Object.fromEntries(
                Object.entries(quotationDetails).filter(([_, value]) => value !== "")
            );
            const payload = {
                rfqId,
                supplierId: selectedSupplier?.supplierId,
                quotationDetails: filtersQuotationDetails,
            };
            const response = dispatch(quotationReceived(payload));
            setOpenQuotation(false);
            setSelectedSupplier(null);
            resetQuotationDetails();
        } catch (error) {
            const errors = {};
            error.inner?.forEach((err) => {
                errors[err.path] = err.message;
            });
            if (errors.received) {
                alert("Please select Quotation Received");
            }
            setQuotationErrors(errors);
        }
    };

    const renderSupplierRow = (supplier, index) => {
        const isInvited = supplier?.state.toLowerCase() === "invited";
        const isChecked =
            reInviteFunctions.isBulkReinvite[supplier?.supplierId] ?? false;

        const handleInviteClick = () => {
            if (!isInvited) {
                handleClickInvite([supplier?.supplierId]);
            }
        };

        return (
            <tr
                key={supplier?.supplierId}
                className="h-10 border-b border-gray-300 border-l"
            >

                {/* vendors */}
                <td className="px-4 py-2 border-l hover:bg-[#ECECE3]">
                    <h5 className="text-xs truncate">
                        {supplier?.businessName}
                    </h5>
                </td>

                {/* status */}
                <td className="px-4 py-2 flex items-center border-l hover:bg-[#ECECE3] ">
                    {/* <button
                        className={`py-1 px-2 w-fit font-medium text-xs rounded-md text-center ${isInvited
                            ? "text-purple-600 bg-purple-100"
                            : "text-gray-800 bg-gray-200 cursor-pointer hover:bg-gray-300"
                            }`}
                        onClick={handleInviteClick}
                        disabled={isInvited}
                    >
                        {isInvited ? "Invited" : "Invite"}
                    </button> */}

                    <p
                        content={
                            handleContactStatus(supplier?.contactDetails) === "bounced _email_new.svg"
                                ? "Email bounced"
                                : handleContactStatus(supplier?.contactDetails) === "opened_cropped_new.svg"
                                    ? "Email opened"
                                    : "Email delivered"
                        }
                    >
                        <span
                            className={`py-1 px-2 cursor-default w-fit my-auto text-xs rounded-[3px] text-center ${handleContactStatus(supplier?.contactDetails) === "bounced _email_new.svg"
                                ? "text-orange-800 bg-orange-100"  // Colors for Bounced
                                : handleContactStatus(supplier?.contactDetails) === "opened_cropped_new.svg"
                                    ? "text-yellow-800 bg-yellow-100"  // Colors for Opened
                                    : "text-indigo-800 bg-indigo-100"  // Colors for Delivered
                                }`}
                        >
                            {handleContactStatus(supplier?.contactDetails) === "bounced _email_new.svg"
                                ? "Bounced"
                                : handleContactStatus(supplier?.contactDetails) === "opened_cropped_new.svg"
                                    ? "Opened"
                                    : "Delivered"}
                        </span>

                    </p>
                </td>

                {/* reinvite(count) */}
                <td className="px-4 border-l hover:bg-[#ECECE3]">
                    {isInvited ? (
                        <div className="flex gap-x-2 items-center">
                            {(rfqStatus === "OPEN" || rfqStatus === "DRAFT") && (""
                                // <input
                                //     type="checkbox"
                                //     checked={isChecked}
                                //     onClick={(e) => {
                                //         reInviteFunctions.addBulkReinvite(
                                //             e.target.checked,
                                //             supplier?.supplierId
                                //         );
                                //     }}
                                //     name={"reinvite-" + index}
                                //     className="h-4 w-4 m-1 text-blue-600 border-gray-300 rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                                // />
                            )}
                            <div className="flex items-center gap-x-2">
                                <div className="py-1 px-2 w-[80px] text-xs rounded-md text-center bg-green-100 text-green-800 cursor-pointer">
                                    <button
                                        disabled={!(rfqStatus === "OPEN" || rfqStatus === "DRAFT")}
                                        onClick={openResendRfq.bind(null, supplier)}
                                    >
                                        Reinvite ({supplier?.resendCount ?? 0})
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <> </>
                    )}
                </td>

                {/* contact number */}
                <td className="px-4 relative  text-right group border-l hover:bg-[#ECECE3]">
                    <button
                        disabled={supplier?.contactDetails?.length <= 1}
                        className={`${supplier?.contactDetails?.length <= 1 && "cursor-text"
                            }  text-xs text-right flex items-center gap-1 cursor-pointer w-fit`}
                        onClick={() => {
                            setSelectedSupplier(supplier);
                            setOpenSupplierContact(true);
                        }}
                    >
                        <p className="truncate text-xs text-right">
                            {supplier?.contactDetails[0]?.mobile || " "}
                        </p>
                        {supplier?.contactDetails?.length > 1 && (
                            <span className="text-blue-600 text-xs text-right truncate underline">
                                +{supplier?.contactDetails?.length - 1} more
                            </span>
                        )}
                    </button>
                </td>

                {/* email */}
                <td className="px-4 relative group border-l hover:bg-[#ECECE3]">
                    <button
                        disabled={supplier?.contactDetails?.length <= 1}
                        className={`${supplier?.contactDetails?.length <= 1 && "cursor-text"
                            }  text-xs flex items-center gap-1 cursor-pointer w-fit`}
                        onClick={() => {
                            setSelectedSupplier(supplier);
                            setOpenSupplierContact(true);
                        }}
                    >
                        <p className="truncate max-w-[170px] ">
                            {supplier?.contactDetails[0]?.email || " "}
                        </p>

                        {supplier?.contactDetails?.length > 1 && (
                            <span className="text-blue-600 text-xs truncate underline">
                                +{supplier?.contactDetails?.length - 1} more
                            </span>
                        )}
                    </button>
                </td>

                {/* quotation level */}
                <td className="px-4 text-xs border-l hover:bg-[#ECECE3]">
                    {getQuotationReceivedComponent(
                        supplier?.vendorQuotations?.rank,
                        supplier,
                        isInvited
                    )}
                </td>

                {/* amount (Rs.) */}
                <td className="px-4 text-right text-xs border-l hover:bg-[#ECECE3]">
                    {supplier?.vendorQuotations?.grandTotalWithoutGst.toFixed(2) ?? ""}
                </td>

                {/* remark */}
                {/* <td className="px-4 py-2 text-xs max-w-[300px] border-l hover:bg-[#ECECE3]">
                    {getRemark(supplier)?.length > 10 ? (
                        <Tooltip content={getRemark(supplier)} placement="bottom">
                            {getRemark(supplier)?.substring(0, 10) + "..."}
                        </Tooltip>
                    ) : (
                        getRemark(supplier)
                    )}
                </td> */}

                {/* invited by */}
                <td className="px-4 text-xs max-w-[300px] border-l hover:bg-[#ECECE3]">
                    {supplier?.addedBy?.name?.length > 20 ? (
                        <Tooltip content={supplier?.addedBy?.name} placement="bottom">
                            {/* {supplier?.addedBy?.name?.substring(0, 20) + "..."} */}
                            <p className="truncate w-[100px]">{supplier?.addedBy?.name}</p>
                        </Tooltip>
                    ) : (
                        supplier?.addedBy?.name ?? ""
                    )}
                </td>
                {/* {rfqStatus != "CLOSED" && (
          <td className="px-4 py-3 text-gray-500 text-sm">
            <Tooltip content="Delete">
              <button
                onClick={() => handleOpenWarningPopup(supplier?.supplierId)}
                disabled={!(rfqStatus === "OPEN" || rfqStatus === "DRAFT")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-p79yt4"
                  viewBox="0 0 24 24"
                  focusable="false"
                  width="18px"
                  height="18px"
                  aria-hidden="true"
                >
                  <path
                    fill="#4B5563"
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
                  />
                </svg>
              </button>
            </Tooltip>

          </td>
        )} */}
            </tr>
        );
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleAddEmailClick();
            e.preventDefault();
        }
    };
    useEffect(() => {
        const allStatusValues = vendorData?.suppliers?.map((supplier) => {
            return handleContactStatus(supplier?.contactDetails);
        });
        setAllStatus(allStatusValues);
    }, [vendorData]);
    return (
        <div>
            {(rfqStatus === "OPEN" || rfqStatus === "DRAFT") &&
                unInvitedSuppliers.length > 0 && (
                    <div className="flex justify-between items-center w-full rounded-md bg-primary-50 p-4 pr-12">
                        <div>
                            <h4 className="text-lg font-semibold text-primary-800 mb-1">
                                Invite Outstanding Vendors
                            </h4>
                            <p className="text-sm text-primary-800">
                                {unInvitedSuppliers?.length} vendor has not been invited to{" "}
                                {vendorData?.name}. Once invited, an email invitation will be
                                sent about this RFQ
                            </p>
                        </div>
                        <Button
                            disabled={!(rfqStatus === "OPEN" || rfqStatus === "DRAFT")}
                            onClick={() =>
                                handleClickInvite(
                                    unInvitedSuppliers.map((supplier) => supplier?.supplierId)
                                )
                            }
                        >
                            {!loading ? "Invite" : <Loading />}
                        </Button>
                    </div>
                )}
            <div
                className="overflow-x-auto mt-3 pb-5"
                style={{ maxHeight: "calc(100vh - 18rem)"}}
            >
                {/* <div className="flex gap-4 justify-end">
                    <div className="text-sm flex items-center gap-[1px]">
                        <img
                            className="w-4 h-4"
                            src="https://storagereponeevaydevcdn.blob.core.windows.net/business/bounced _email_new.svg"
                        />
                        <p>: Email bounced</p>
                    </div>

                    <div className="text-sm flex items-center gap-[1px]">
                        <img
                            className="w-4 h-4"
                            src="https://storagereponeevaydevcdn.blob.core.windows.net/business/opened_cropped_new.svg"
                        />
                        <p>: Email opened</p>
                    </div>

                    <div className="text-sm flex items-center gap-[1px]">
                        <img
                            className="w-4 h-4"
                            src="https://storagereponeevaydevcdn.blob.core.windows.net/business/double_check_new.svg"
                        />
                        <p>: Email delivered</p>
                    </div>
                </div> */}
                <table className="w-full relative">
                    <thead className="text-xs  z-10 text-[#727269] dark:bg-gray-700 rounded-lg dark:text-gray-400 sticky top-0 bg-[#F5F5F3]">
                        <tr className=" border border-gray-300 rounded-lg max-w-full text-xs ">
                            {[
                                "Vendors",
                                "Status",
                                <div>
                                    {/* {(rfqStatus === "OPEN" || rfqStatus === "DRAFT") && (
                                        <input
                                            type="checkbox"
                                            name={"reinvite-all"}
                                            checked={reInviteFunctions.hasInvitedAll}
                                            onClick={(e) => {
                                                reInviteFunctions.inviteReinviteAll(
                                                    e.target.checked,
                                                    vendorData?.suppliers
                                                        .filter(
                                                            (supplier) =>
                                                                supplier?.state.toLowerCase() === "invited"
                                                        )
                                                        .map((supplier) => supplier?.supplierId) ?? []
                                                );
                                            }}
                                            className="h-4 w-4 m-1 text-blue-600 border-gray-300 rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                                        />
                                    )} */}
                                    <span className="ml-2">Reinvite (count)</span>
                                </div>,
                                "Contact Number",
                                "Email",
                                "Quotation Level",
                                "Amount (Rs.)",
                                // "Remarks",
                                "Invited By",
                                // "Action",
                            ].map((header, index) => (
                                <>
                                    {!(rfqStatus === "CLOSED" && header === "Action") && (
                                        <th
                                            key={index}
                                            scope="col"
                                            className="px-4 pb-2 pt-2 border-r border-gray-300"
                                        >
                                            <p
                                                className={`truncate text-${header === "Amount" ? "right" : "left"
                                                    } text-xs font-medium text-[#727269]`}
                                            >
                                                {header}
                                            </p>
                                        </th>
                                    )}
                                </>
                            ))}
                        </tr>
                    </thead>

                    <tbody className="bg-white">
                        {vendorData?.suppliers?.map(renderSupplierRow)}
                    </tbody>
                </table>
            </div>

            <div
                className={`${openSupplierContact
                    ? "fixed inset-0 flex items-center justify-center z-50"
                    : "hidden"
                    }`}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                onClick={handleClosePopUp}
            >
                <div
                    className={`relative p-5 w-full max-w-2xl max-h-full bg-white rounded-lg shadow dark:bg-gray-700 overflow-y-auto overflow-x-hidden`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="py-2 flex items-center justify-between border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Contact details
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 gap-2 bg-transparent px-2 hover:bg-gray-200 hover:text-gray-700 rounded-lg text-sm h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={handleClosePopUp}
                        >
                            Close
                            <svg
                                className="w-2 h-2"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="max-h-[50vh] overflow-auto py-2">
                        {selectedSupplier &&
                            selectedSupplier?.contactDetails.map((contact) => (
                                <div className="flex justify-between">
                                    <div
                                        key={contact?.email}
                                        className="flex items-center gap-4 mb-2"
                                    >
                                        <div className="size-10 flex items-center justify-center bg-slate-400 rounded-2xl text-xl font-semibold text-white">
                                            {getInitials(contact?.name ?? "User")}
                                        </div>
                                        <div>
                                            <div className="text-base text-gray-700">
                                                {contact?.name ?? "User"}
                                            </div>
                                            <div className="text-sm text-gray-400">
                                                {contact?.email}
                                            </div>
                                            <div className="text-sm text-gray-400">
                                                {contact?.mobile}
                                            </div>
                                        </div>
                                        {validEmails.includes(contact?.email) && (
                                            <button
                                                type="button"
                                                onClick={() => handleDeleteUserEmail(contact?.email)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6 text-gray-600 mt-2"
                                                    focusable="false"
                                                    aria-hidden="true"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                >
                                                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"></path>
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                    {contact?.emailStatus && (
                                        <div>
                                            {openResendEmailPopup && (
                                                <div className="flex flex-col gap-2">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="p-1 w-[200px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter new email"
                                                            value={resendNewEmail}
                                                            onChange={(e) => {
                                                                setResendNewEmail(e.target.value);
                                                                if (emailValidation) setEmailValidation(false);
                                                            }}
                                                        />
                                                        {emailValidation && (
                                                            <p className="text-red-600 text-sm">
                                                                Invalid email
                                                            </p>
                                                        )}
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            if (isValidEmail(resendNewEmail)) {
                                                                setResendLoading({ newEmail: true });
                                                                handleResendBounceEmail(resendNewEmail);
                                                            } else {
                                                                setEmailValidation(true);
                                                            }
                                                        }}
                                                        className="px-2 py-1 ml-auto rounded-lg hover:bg-blue-600 bg-blue-700 text-white text-sm"
                                                    >
                                                        {resendLoading["newEmail"] ? (
                                                            <Loading />
                                                        ) : (
                                                            "Send Email"
                                                        )}
                                                    </button>
                                                    {resendErrorMessage != "" && (
                                                        <p className="ml-auto text-sm text-red-600">
                                                            {resendErrorMessage}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                            {contact?.emailStatus == "Bounce" ? (
                                                !openResendEmailPopup && (
                                                    <div className="flex text-blue-600 items-center">
                                                        <button
                                                            onClick={() => {
                                                                setOpenResendEmailPopup(true);
                                                                setResendEmailData(contact);
                                                            }}
                                                            className="p-2 py-1 rounded-lg text-sm"
                                                        >
                                                            Resend Email
                                                        </button>
                                                        <Tooltip content="Error sending the email to the recipient">
                                                            <svg
                                                                className="w-5 h-5"
                                                                focusable="false"
                                                                fill="currentColor"
                                                                aria-hidden="true"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z"></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="p-2 text-sm">
                                                    {contact?.emailStatus === "Open" ? (
                                                        <div className="flex gap-2 text-[#1212c6]">
                                                            <DoneAllIcon size="small" color="#1212c6" />
                                                            <p>Email opened</p>
                                                        </div>
                                                    ) : (
                                                        <div className="flex gap-2">
                                                            <DoneAllIcon size="small" />
                                                            <p>Email sent</p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        <div className="mt-3 flex gap-1 flex-col"></div>
                    </div>
                    {selectedSupplier?.state.toLowerCase() != "invited" && (
                        <div>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                onPaste={handleInputPaste}
                                onKeyDown={handleKeyDown}
                                placeholder="example@gmail.com"
                                className="border border-gray-300 p-2 rounded"
                            />
                            <button
                                type="button"
                                onClick={handleAddEmailClick}
                                className="bg-blue-500 hover:bg-blue-600 text-white ml-1 px-4 py-2 rounded my-2"
                            >
                                Add Email
                            </button>
                            {invalidEmails.length > 0 && (
                                <div className="text-red-500 mt-2">
                                    {invalidEmails.map((email, index) => (
                                        <div key={index}>{email} is not a valid email.</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {/* <div className="py-2 flex items-center gap-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            {selectedSupplier?.state.toLowerCase() === "invited" ? (
              <button
                onClick={handleClosePopUp}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded "
              >
                Close
              </button>
            ) : (
              <button
                onClick={() => {
                  setAddEmailsLoader(true);
                  handleSaveEmailsToSupplier();
                }}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded "
              >
                {addEmailsLoader ? <Loading /> : "Save"}
              </button>
            )}
          </div> */}
                </div>
            </div>

            {warningMsg && (
                <div className="flex justify-center absolute bottom-9 left-0 w-screen">
                    <div
                        id="toast-warning"
                        className="flex  items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                        role="alert"
                    >
                        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
                            </svg>
                            <span className="sr-only">Warning icon</span>
                        </div>
                        <div className="ms-3 text-sm font-normal">
                            This RFQ has been closed
                        </div>
                        <button
                            type="button"
                            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                            dataDismissTarget="#toast-warning"
                            ariaLabel="Close"
                            onClick={() => setWarningMsg(false)}
                        >
                            <span className="sr-only">Close</span>
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            )}

            <Popup
                open={openQuotation}
                onClose={() => {
                    setOpenQuotation(false);
                    setSelectedSupplier(null);
                    resetQuotationDetails();
                }}
                handleSubmit={addQuotation}
                primaryButton={{
                    text: "Save",
                }}
                heading={
                    <div>
                        Quotation Received{" "}
                        <h6 className="text-sm text-[#5B6069]">
                            {selectedSupplier?.businessName}
                        </h6>
                    </div>
                }
            >
                <VendorQuotation
                    handleQuotationChange={handleQuotationChange}
                    quotationDetails={quotationDetails}
                    resetQuotationDetails={resetQuotationDetails}
                    resetQuotationTerms={resetQuotationTerms}
                    selectedSupplier={selectedSupplier}
                    errors={quotationErrors}
                    removeFieldErrors={removeFieldErrors}
                />
            </Popup>
            <WarningPopup
                openModal={openWarningDeletePopup}
                loading={loading}
                handleClosePopup={handleCloseWarningPopup}
                handleSubmit={() => handleDeleteVendor(deleteVendorId)}
                warningText={"Are you sure you want to delete this Vendor?"}
            />
        </div>
    );
};

export default PostRfqInviteVendors;
