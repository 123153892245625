import { GET_CITIES_FAILURE, GET_CITIES_SUCCESS, GET_REGIONS_FAILURE, GET_REGIONS_SUCCESS } from "actions/regionsActions";
import produce from "immer";

const initialState = {
    regionDistricts: [],
    cities: []
}

const regionsReducer = (state = initialState, action) => {
    if (action.type == GET_REGIONS_SUCCESS) {
        return produce(state, (draft) => {
            draft.regionDistricts = action?.allDistricts;
        })
    } else if (action?.type == GET_REGIONS_FAILURE) {
        return produce(state, (draft) => {
            draft.regionDistricts = [];
        })
    } else if (action?.type == GET_CITIES_SUCCESS) {
        return produce(state, (draft) => {
            draft.cities = action.allCities
        })
    } else if (action?.type == GET_CITIES_FAILURE) {
        return produce(state, (draft) => {
            draft.regionDistricts = [];
        })
    }
    else {
        return state
    }
}

export default regionsReducer