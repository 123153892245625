import { Grid, Typography, } from '@mui/material'
import { Box } from '@mui/system'
import axios from "utils/axios";
import NorthWestIcon from '@mui/icons-material/NorthWest';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { regionsActions, searchCitiesAction } from 'actions/regionsActions';
// import regionsActions, { searchCitiesAction } from 'actions/regionsActions';

const SearchCityDropDown = ({ searchTerm, handleClickOnRegion }) => {
    const dispatch = useDispatch()
    const regions = useSelector((state) => state.regions.regionDistricts)
    const cities = useSelector((state) => state.regions.cities)
    // const [regions, setRegions] = useState([])
    const mostPopularSerchesData = ["Pune", "Mumbai", "Delhi", "Bengaluru"]
    const filteredDistricts = cities?.filter((cities) =>
    cities.toLowerCase().includes(searchTerm.toLowerCase())
    );


    useEffect(() => {
        // dispatch(regionsActions());
        dispatch(searchCitiesAction());
    }, [])
    return (
        <Grid xs={12} display={"flex"} justifyContent={"start"} zIndex={1} >
            <Box
                component={Grid}
                padding={"0 12px 12px"}
                // xs={6}
                sx={{
                    backgroundColor: "white",
                    borderTop: "1px solid #C6C6C6",
                    borderRadius: "0px 0px 12px 12px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
                    maxHeight: "250px",
                    overflow: "auto",
                    // paddingY: "14px"
                }}>
                {searchTerm != "" ?
                    filteredDistricts?.length !== 0 ?
                        filteredDistricts?.map((data) => <Grid container sx={{ color: "#757373", cursor: "pointer" }} alignItems="center" onClick={() => handleClickOnRegion(data)} spacing={1}>
                            <Grid item>
                                <NorthWestIcon sx={{ color: '#cccccc', fontSize: 'small' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" sx={{ fontSize: '14px' }}>
                                    {data}
                                </Typography>
                            </Grid>
                        </Grid>)
                        :
                        <Typography sx={{ color: "#cccccc" }} >No result found</Typography>
                    : <Grid sx={{ color: "#757373" }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", margin: "8px 0 2px" }}>Popular Regions</Typography>
                        {mostPopularSerchesData?.map((data) => <Grid
                            display={"flex"}
                            justifyContent={"space-between"}
                            onClick={() => handleClickOnRegion(data)}
                            sx={{
                                cursor: "pointer"
                            }}
                        >

                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <NorthWestIcon sx={{ color: '#cccccc', fontSize: 'small' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" sx={{ fontSize: '14px' }}>
                                        {data}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        )}
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", margin: "8px 0 2px" }}>Other Regions</Typography>
                        {cities?.map((data) => <Grid
                            display={"flex"}
                            justifyContent={"space-between"}
                            onClick={() => handleClickOnRegion(data)}
                            sx={{
                                cursor: "pointer"
                            }}
                        >

                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    {/* <SearchIcon fontSize="small" /> */}
                                    <NorthWestIcon sx={{ color: '#cccccc', fontSize: 'small' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" sx={{ fontSize: '14px' }}>
                                        {data}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid >
                                {/* <NorthWestIcon fontSize="small" /> */}
                            </Grid>
                        </Grid>
                        )}
                    </Grid>}
            </Box>
        </Grid>
    )
}

export default SearchCityDropDown