import React, { useEffect, useState } from "react";
import {
  Grid,
  Tooltip,
  Typography,
  Box,
  Chip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditVendorEmails from "./EditVendorEmails";
import AddEmail from "./AddEmail";

const ResendRfqToCcSection = ({
  ccEmails,
  setCcEmails,
  enquiry,
  emails,
  setEmails,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
    const [showAllNewCcEmails, setShowAllNewCcEmails] = useState(false);
  const [openSupplierEmailPopUp, setOpenSupplierEmailPopUp] = useState(false);
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  
  const handleOpenEditEmail = () => {
    setOpenSupplierEmailPopUp(true);
  };
  const addEmailOnclick = () => {
    setOpenAddEmailDialog(true);
  };
  const handleRemoveCcEmails = (email) => {
    console.log("this is a data", email, ccEmails);
    setCcEmails(ccEmails?.filter((data) => data !== email));
  };
  // const handleAllEmails = ()=>{
  // }

  useEffect(() => {
    const noteDescriptions = enquiry?.noteDescriptions || [];
    const ccEmails =
      noteDescriptions.length > 0
        ? noteDescriptions[noteDescriptions?.length - 1]?.ccEmails ||
          enquiry?.newCcEmails
        : enquiry?.newCcEmails;
    // const newCcEmails =
    setCcEmails(ccEmails);
  }, [enquiry?.newCcEmails]);
  return (
    <>
      <Grid
        paddingBottom={"10px"}
        display={"flex"}
        alignItems={"center"}
        borderBottom={"1px #CFDAE5 solid"}
      >
        <Typography>To,</Typography>
        <Tooltip title="List of all vendors this requirement will be floated for quotation">
          <Box
            component="img"
            sx={{
              marginX: "8px",
              height: "18px",
              width: "18px",
              marginRight: isSm ? "11px" : "34px",
            }}
            alt="logo"
            src="/assets/icons/questionMarkLogo.svg"
          />
        </Tooltip>
        <Tooltip title={emails?.join(", ")}>
          <Chip
            onClick={handleOpenEditEmail}
            label={enquiry.businessName}
            size="small"
            variant="outlined"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "145px",
              padding: "6px",
              border: "1px solid #4C4C4C",
              textAlign: "center",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </Grid>
      <Grid paddingY={"17px"} display={"flex"} alignItems={"baseline"}>
        <Grid display={"flex"} alignItems={"center"}>
          <Typography>cc,</Typography>
          <Tooltip title="Requirement will be copied to these email addresses when sent to above list of vendors. If left blank, no one will be copied in email">
            <Box
              component="img"
              sx={{
                marginX: "8px",
                height: "18px",
                width: "18px",
                marginRight: isSm ? "11px" : "34px",
              }}
              alt="logo"
              src="/assets/icons/questionMarkLogo.svg"
            />
          </Tooltip>
          <Grid display={"flex"} gap={1} flexWrap={"wrap"}>
            {ccEmails?.map((data) => (
              <Tooltip title={data}>
                <Chip
                  label={data}
                  size="small"
                  variant="outlined"
                  onDelete={() => handleRemoveCcEmails(data)}
                  sx={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "145px",
                    border: "1px solid #4C4C4C",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            ))}

            {/* {enquiry?.newCcEmails.length > 1 && isSm && (
                <Box
                  sx={{
                    border: "1px solid #777777",
                    borderRadius: "5px",
                    padding: "6px 8px",
                  }}
                >
                  {showAllNewCcEmails ? (
                    <Typography
                      onClick={() => setShowAllNewCcEmails(false)}
                      sx={{
                        fontSize: "14px",
                        cursor: "pointer",
                        lineHeight: "18.75px",
                      }}
                    >
                      View Less
                    </Typography>
                  ) : (
                    <Typography
                      onClick={() => setShowAllNewCcEmails(true)}
                      sx={{
                        fontSize: "14px",
                        cursor: "pointer",
                        lineHeight: "18.75px",
                      }}
                    >
                      View More
                    </Typography>
                  )}
                </Box>
              )} */}
          </Grid>
          <Grid onClick={addEmailOnclick}>
            <Typography
              sx={{
                ml: "10px",
                fontSize: "14px",
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Add Email +
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        padding="6px 0px 10px"
        borderBottom={"1px #CFDAE5 solid"}
        display={"flex"}
        alignItems="baseline"
      >
        <Typography>Subject</Typography>
        <Typography sx={{ ml: isSm ? "11px" : "36px" }}>
          Reminder ({enquiry.resendCount + 1}) : {enquiry.name}
        </Typography>
      </Grid>

      <EditVendorEmails
        emails={emails}
        enquiry={enquiry}
        openSupplierEmailPopUp={openSupplierEmailPopUp}
        setOpenSupplierEmailPopUp={setOpenSupplierEmailPopUp}
        setEmails={setEmails}
      />
      <AddEmail
        emails={ccEmails}
        setEmails={setCcEmails}
        openAddEmailDialog={openAddEmailDialog}
        setOpenAddEmailDialog={setOpenAddEmailDialog}
      />
    </>
  );
};

export default ResendRfqToCcSection;
