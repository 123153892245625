import axios from "utils/axios";

import { ADD_UPDATE_SERVICE_URL, DELETE_SERVICE_URL } from "config/services";

class ServiceService {
  addUpdateService = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post(ADD_UPDATE_SERVICE_URL, { payload })
        .then((response) => {
          // console.log(
          //   "ServiceService.addUpdateService()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ServiceService.addUpdateService() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  deleteService = (supplierId, serviceName, serviceId, businessName) =>
    new Promise((resolve, reject) => {
      axios
        .post(DELETE_SERVICE_URL, { payload: { supplierId, serviceName, serviceId, businessName } })
        .then((response) => {
          // console.log(
          //   "ServiceService.deleteService()",
          //   supplierId,
          //   serviceName,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ServiceService.deleteService() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });
}

const serviceService = new ServiceService();

export default serviceService;
