import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

// Your cities data
const cities = [
  "Adoni", "Agra", "Ahmedabad", "Ahmednagar", "Ajmer", "Aligarh", "Almora",
  "Amravati", "Amreli", "Amritsar", "Amroha", "Aurangabad", "Ayodhya", "Ballia",
  "Bareilly", "Bengaluru", "Bhopal", "Bidar", "Bijapur", "Bikaner", "Chennai",
  "Coimbatore", "Dehradun", "Dehri", "Deogarh", "Dhubri", "Durg", "Delhi",
  "Faridabad", "Firozabad", "Firozpur", "Ghaziabad", "Ghazipur", "Goa", "Guntur",
  "Gurugram", "Guwahati", "Gwalior", "Haridwar", "Hazaribag", "Hyderabad", "Imphal",
  "Indore", "Jabalpur", "Jaipur", "Jalandhar", "Jorethang", "Jorhat", "Kanpur",
  "Kashipur", "Kochi", "Kolkata", "Kota", "Kolhapur", "Latur", "Lucknow", "Ludhiana",
  "Manali", "Mumbai", "Nagpur", "Nanded", "Nandurbar", "Nashik",
  "Nellore", "Nizamabad", "Noida", "Nurpur", "Osmanabad", "Palampur", "Pali", "Patna", "Prayagraj", "Pune", "Purulia", "Raipur", "Rajkot", "Ranchi",
  "Rishikesh", "Rohru", "Satara", "Shimla", "Solapur", "Sonipat", "Sundarnagar",
  "Surat", "Tezpur", "Thane", "Thiruvananthapuram", "Tirupati","Udaipur", "Ujjain", "Vadodara", "Varanasi", "Vasai-Virar", "Vijayanagara",
  "Vijayawada", "Visakhapatnam", "Wardha", "Yavatmal"
];

// Function to group cities into columns with a max of 10 rows each
const groupCitiesIntoColumns = (arr, columns) => {
  const result = [];
  let currentIndex = 0;
  
  while (currentIndex < arr.length) {
    const column = arr.slice(currentIndex, currentIndex + 10);
    result.push(column);
    currentIndex += 10;
  }
  
  // Group every 4 columns together
  const groupedColumns = [];
  for (let i = 0; i < result.length; i += 4) {
    groupedColumns.push(result.slice(i, i + 4));
  }

  return groupedColumns;
};

const Locations = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0); // State to track active slide index
  const swiperRef = useRef(null); // Ref for Swiper instance

  // Sort cities alphabetically
  const sortedCities = cities.sort((a, b) => a.localeCompare(b));

  // Group the cities into columns with a max of 10 rows each
  const groupedColumns = groupCitiesIntoColumns(sortedCities, 4);

  // Function to navigate to previous slide
  const navigatePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  // Function to navigate to next slide
  const navigateNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div>
      <Swiper ref={swiperRef} spaceBetween={30} slidesPerView={1} onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}>

        {groupedColumns.map((columns, index) => (
          <SwiperSlide key={index}>
            <div className='flex justify-evenly text-lg text-white md:py-[45px] lg:py-[60px]'>
              {columns.map((column, columnIndex) => (
                <ul key={columnIndex} className='space-y-[11px]'>
                  {column.map((city, cityIndex) => (
                    <li key={cityIndex}>{city}</li>
                  ))}
                </ul>
              ))}
            </div>
          </SwiperSlide>
        ))}

      </Swiper>

      {/* Navigation Arrows */}
      <div className="flex justify-center mt-4">
        <img onClick={navigatePrev} src="https://storagereponeevaydevcdn.blob.core.windows.net/business/backward_arrow.svg" alt="backward-arrow" className='bg-gray-300 rounded-full w-5 h-5 cursor-pointer mr-4' />
        <img onClick={navigateNext} src="https://storagereponeevaydevcdn.blob.core.windows.net/business/forward_arrow.svg" alt="forward-arrow" className='bg-gray-300 rounded-full w-5 h-5 cursor-pointer ml-4' />
      </div>
    </div>
  );
};

export default Locations;
