import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import DashboardLayout from "layouts/Dashboard";
import Page from "views/UserProfile/components/Page";
import { Helmet } from "react-helmet";
import RequirementForm from "../components/RequirementForm";

import { Grid } from "@mui/material";

import SupplierList from "./SupplierList";

function ViewRequirement({}) {
  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    navigate("/requirements");
  };

  const requirement = useSelector((state) => state.requirement);
  const requirementToEdit = requirement.requirementToEdit;

  // console.log("ViewRequirement() ", requirementToEdit);

  return (
    <DashboardLayout>
      <Helmet>
        <title>Neevay - View Edit Requirements</title>
      </Helmet>
      <Page>
        <RequirementForm
          isNewRequirement={false}
          requirement={requirementToEdit}
          onSubmitSuccess={handleSubmitSuccess}
        />{" "}
        <SupplierList suppliers={requirementToEdit?.suppliers} />
      </Page>
    </DashboardLayout>
  );
}

ViewRequirement.propTypes = {};

export default ViewRequirement;
