import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

export const OurBackers = () => {
  const theme = useTheme();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const isLg = useMediaQuery(theme.breakpoints.down(1024));
  const isMd = useMediaQuery(theme.breakpoints.down(786));
  const isSm = useMediaQuery(theme.breakpoints.down(600));

  const cardSize = (isLg || isSm) && !isMd ? "282px" : "454px";
  const containerSize = isSm
    ? "299px"
    : isMd
    ? "387px"
    : isLg
    ? "631px"
    : "1010px";
  const leadership = [
    {
      photo: `/assets/business/web-aboutus-desktop-kaizad.webp`,
      linkName: "KAIZAD ENGINEER",
      link: "https://www.linkedin.com/in/kaizad-engineer-2978a412/",
      name: "Kaizad Engineer",
      desc: "Recipient of the 2004 Indian Building Congress Award, an expert in Remedial Engineering in Construction, with extensive experience leading diverse projects, including Cement Plants, Steel Plants, Heritage Buildings, and Palaces. Published 36 research papers at National and International Symposia.",
    },
    {
      photo: `/assets/business/web-aboutus-desktop-sid.webp`,
      linkName: "SIDDHARTH MOORTHY",
      link: "https://www.linkedin.com/in/svm2012/",
      name: "Siddharth Moorthy",
      desc: "Prominent Real Estate Developer and leader in Construction. Acknowledged with awards including Sakal Achievers of Maharashtra and Femina Pune’s Most Powerful Entrepreneur. Passionate about infrastructure projects, he mentors Hanish Raheja and addresses the vendor gap in Construction Projects.",
    },
    {
      photo: `/assets/business/web-aboutus-desktop-zubin.webp`,
      linkName: "ZUBIN ENGINEER",
      link: "https://www.linkedin.com/in/zubin-engineer-322b1588/",
      name: "Zubin Engineer",
      desc: "More than 28 years of experience handling Construction & Remedial Engineering Companies at front, he is a humble leader who believes in giving the best values to their customers through a great team and technology together.",
    },
    {
      photo: `/assets/business/web-aboutus-desktop-santosh.webp`,
      // linkName: "SANTOSH SUNDARARAJAN",
      // link: "https://www.linkedin.com/in/kaizad-engineer-2978a412/",
      name: "Santosh Sundararajan",
      desc: "Veteran of Construction Industry, his experience of Construction Industry in Neevay’s Board is invaluable due to his dynamic nature and experience of handling Construction Projects all over India in various sectors be it Infra, Real Estate, Hospitals, Hotels etc.",
    },
  ];
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        flexDirection: "column",
        maxWidth: containerSize,
        margin: "auto",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "start",
        }}
      >
        <Typography
          sx={{
            fontFamily: "PP Telegraf",
            fontSize: isMd ? "32px" : "48px",
            fontWeight: 800,
            lineHeight: "62.2px",
            color: "black",
            marginBottom: "12px",
          }}
        >
          Our Backers
        </Typography>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-between",
          // gap: "56px"
        }}
      >
        {leadership?.map((item) => (
          <Grid sx={{ marginBottom: "56px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "19px",
                letterSpacing: "1px",
                marginTop: "6px",
              }}
            >
              {item.name}
            </Typography>

            <a href={item.link} target="_blank">
              <Grid
                sx={{ display: "flex", alignItems: "center", marginY: "6px" }}
              >
                {item.name != "Santosh Sundararajan" && (
                  <Box
                    component="img"
                    src="/assets/home/LinkdenIcon.svg"
                    sx={{ marginRight: "16px", width: "16px" }}
                  />
                )}
                <Typography
                  sx={{
                    color: "#6C747E",
                    fontFamily: "IBM Plex Mono",
                    fontWeight: 600,
                    fontSize: "14px",
                    // lineHeight: "19.6px",
                    textDecorationLine: "underline",
                    letterSpacing: "1px",
                    // borderBottom:"solid 1px #6C747E",
                  }}
                >
                  {item.linkName}
                </Typography>
              </Grid>
            </a>

            {/* <Grid
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: "36px",
                }}
              > */}
            <Grid
              sx={{
                backgroundColor: "rgba(233, 244, 253, 1)",
                width: isSm ? "299px" : "321px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "6px",
              }}
            >
              <Box
                component="img"
                // sx={{ maxHeight:isSm?"100px": "123px" }}
                src={`${BASE_URL}/${item.photo}`}
              />
            </Grid>
            <Typography
              sx={{
                color: "#63727E",
                fontSize: "14px",
                width: isSm ? "299px" : "321px",
                marginTop: "10px",
              }}
            >
              {item.desc}
            </Typography>
            {/* </Grid> */}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
