import { Chip, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import BusinessRegions from "views/Business/components/BusinessRegions";
import BusinessRegionsMobile from "views/Business/components/BusinessRegionsMobile";

function ProfileAbout({ profile ,isEditView,permissions,PublicProfile}) {
  const businessProfile = profile?.businessProfile;
  const businessRegions = profile?.regions;
  const description = businessProfile?.description;
  const regionState = businessRegions?.state || [];
  const regionSelectedCities = businessRegions?.Selectedcities || [];
  const region = [...regionState, ...regionSelectedCities];

  const descriptionHeightRef = useRef(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showReadmore, setShowReadmore] = useState(false);
  const [descriptionHeight, setDescriptionHeight] = useState("auto");
  const replacedOlString = description?.replaceAll(
    "<ol>",
    '<ol style="padding-left: 34px; list-style-type: decimal">'
  );
  // Replace <ul> with style attribute
  const replacedString = replacedOlString?.replaceAll(
    "<ul>",
    '<ul style="padding-left: 34px; list-style-type: disc">'
  );

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
    setDescriptionHeight(!showFullDescription ? "auto" : "190px");
  };
  const desc = replacedString || "";
  // const displayedDescription = showFullDescription ? desc : desc.slice(0, 350); // Adjust the character limit as needed
  useEffect(() => {
    setDescriptionHeight("auto"); // to display read more and read less to update descriptionHeight when data is change
    setTimeout(() => {
      if (descriptionHeightRef?.current) {
        const componentHeight =
          descriptionHeightRef.current.getBoundingClientRect().height;
        setShowReadmore(componentHeight > 190);
        if (componentHeight > 191) setDescriptionHeight("191px");
      }
    }, [100]);
    setShowFullDescription(false);
  }, [businessProfile]);
  return (
    <Grid style={{ paddingLeft: "16px", paddingRight: "14px" }}>
      <Grid>
        <Typography
          sx={{ fontSize: "18px", fontWeight: "600", marginTop: "18px" }}
        >
          What you should know about {profile?.businessName}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          //   marginRight: "28px",
          paddingBottom: "35px",
          paddingTop: "20px",
        }}
      >
        <Grid>
          <Grid>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, marginBottom: "5px" }}
            >
              {businessProfile?.businessHeadline}
            </Typography>
            <Typography
            dangerouslySetInnerHTML={{ __html: desc }}
              ref={descriptionHeightRef}
              sx={{
                fontSize: "16px",
                height: descriptionHeight,
                overflow: "hidden",
              }}
            />
              {/* {ReactHtmlParser(desc)}
            </Typography> */}
            {showReadmore && (
              <span
                style={{
                  color: "#2864BE",
                  textDecoration: "underline",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={toggleDescription}
              >
                {" "}
                {showFullDescription ? "Read Less" : "Read More"}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>


      <Grid sx={{ marginTop: "20px" }}>
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            marginBottom: "10px",
          }}
        >
          Region Served
        </Typography>
        {!isEditView ? (
          <Grid>
            {regionState?.length == 36 ? (
              <Chip
                label={"All Over India"}
                variant="outlined"
                size="small"
                style={{ marginRight: "10px", marginBottom: "6px" }}
              />
            ) : region && region?.length > 0 ? (
              region?.map((item) => (
                <Chip
                  label={item}
                  variant="outlined"
                  size="small"
                  style={{ marginRight: "10px", marginBottom: "6px" }}
                />
              ))
            ) : (
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  width: "383px",
                  marginTop: "8px",
                  marginBottom: "29px",
                }}
              >
                Not Disclosed
              </Typography>
            )}
          </Grid>
        ) : (
          <BusinessRegions
            supplierId={profile?.supplierId}
            services={profile?.services}
            PublicProfile={PublicProfile}
            permissions={permissions}
            businessRegions={profile?.regions}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default ProfileAbout;
