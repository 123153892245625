import React from 'react';
import serviceData from '../servicedata.json';

const Services = () => {
  return (
    <div className="container mx-auto my-4">
      {/* First row */}
      <div className="flex justify-center">
        {serviceData.slice(0, 4).map((service) => (
          <div key={service.id} className="bg-gradient-to-r from-[#0B0B1A] from-50% via-[#7E7E95] via-40% to-[#0B0B1A] to-10%  rounded-lg overflow-hidden hover:scale-110 transition-transform duration-500 ease-in-out mb-8 mx-4 flex-shrink-0">
            <img
              src={service.src}
              alt={service.alt}
              className="md:h-[101px] md:w-[150px] lg:h-[167px] lg:w-[250px] object-cover rounded-t-lg"
            />
            <p className="text-white p-4 font-bold text-xl">{service.title}</p>
          </div>
        ))}
      </div>

      {/* Second row */}
      <div className="flex justify-center mt-4">
        {serviceData.slice(4).map((service) => (
          <div key={service.id} className="bg-gradient-to-r from-[#0B0B1A] from-50% via-[#7E7E95] via-40% to-[#0B0B1A] to-10% rounded-lg overflow-hidden hover:scale-110 transition-transform duration-500 ease-in-out mb-8 mx-4 flex-shrink-0">
            <img
              src={service.src}
              alt={service.alt}
              className="md:h-[101px] md:w-[150px] lg:h-[167px] lg:w-[250px] object-cover rounded-t-lg"
            />
            <p className="text-white p-4 font-bold text-xl">{service.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
