import React from 'react';
import { Field, ErrorMessage, useFormikContext } from "formik";
import QuillEditorComponent from './QuillEditorComponent'; // Import the QuillEditorComponent

const RfqTypeProviding = () => {
  const { values, setFieldValue } = useFormikContext();
  const handleRadioChange = (key, value) => {
    setFieldValue(`otherCharges.${key}`, value);
  };


  const otherCharges = [
    // { label: "Mathade Charges", key: "mathadeCharges" },
    { label: "Misc. Charges", key: "miscCharges" }
  ];
  return (
    <div>
      <div className="space-y-8 pt-4">
        {/* Providing & Fixing Details */}
        <div>
          <h3 className="text-base text-[#434343] font-medium mb-2">
            Providing & Fixing Details
          </h3>

          <div className="relative">
            {/* Using Field for Formik */}
            <Field name="providingFixingDetails">
              {({ field, form }) => (
                <QuillEditorComponent
                  fieldName="providingFixingDetails"
                  placeholder="Please write your providing & fixing details here"
                  field={field}
                  form={form}
                />
              )}
            </Field>

            {/* Error Message */}
            <ErrorMessage
              name="providingFixingDetails"
              component="div"
              className="text-red-500 text-xs ml-2 mt-1"
            />
          </div>
        </div>

        {/* Miscellaneous Charges */}
        {/* <div>
          <p className="text-base font-medium text-[#434343]">Other Charges</p>
          <p className="text-[#8C8C8C] text-[10px]">
            Select whether each extra cost is needed for the bid. Vendors will fill them in when sending their offers.
          </p>
          <div className="pt-4">
            {otherCharges.map((charge) => (
              <div key={charge.key} className="mb-4 flex items-center justify-between">
                <span className="text-sm font-medium text-[#434343]">
                  {charge.label}
                </span>
                <div className="flex items-center space-x-6 ml-auto">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name={`otherCharges.${charge.key}`}
                      value="yes"
                      className=" h-3 w-3"
                      checked={values.otherCharges[charge.key] === true}
                      onChange={() => handleRadioChange(charge.key, true)}
                    />
                    <span className="ml-2 text-sm text-[#505050]">Yes</span>
                  </label>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name={`otherCharges.${charge.key}`}
                      value="no"
                      className="h-3 w-3"
                      checked={values.otherCharges[charge.key] === false || values.otherCharges[charge.key] === undefined}
                      onChange={() => handleRadioChange(charge.key, false)}
                    />
                    <span className="ml-2 text-sm text-[#505050]">No</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RfqTypeProviding;
