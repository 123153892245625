import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Divider,
  MenuItem,
  Menu,
  TextField,
  Snackbar,
  Alert,
  Modal,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { styled, alpha } from "@mui/material/styles";
import { addFavourites } from "actions/favouritesAction";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { getUrl } from "actions/businessActions";
import FavoritesDialogBox from "./FavoritesDialogBox";
import { Box } from "@mui/system";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import { fetchTag } from "actions/tagsActions";
import VerificationPopUp from "views/VerificationModal";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const FavoritesMenu = ({
  businessCategory,
  supplierId,
  businessName,
  url,
  isBig,
  itemOwnerId,
  checkedItems,
  data,
}) => {
  const modalStyle = {
    borderRadius: "5px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxWidth: 440,
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 4,
  };
  const isRealEstateDev = businessCategory == "Real Estate Developer" ? true : false;
  const isEpcContractor = businessCategory == "EPC Contractor" ? true : false;
  const [tags, setTags] = useState([]);
  const [anchorE2, setAnchorEl2] = React.useState(null);
  const [openSave, setOpenSave] = React.useState(false);
  const dispatch = useDispatch();
  const business = useSelector((state) => state.business);
  const [openFavoritesTag, setOpenFavoritesTag] = React.useState(false);

  const [openLogInModal, setOpenLogInModal] = React.useState(false);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const currentLocation = useLocation();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  const handleClickSave = (event) => {
    //  if (checkedItems.length==0) {
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));
      setOpenLogInModal(true);
      // navigate("/signin-mobile");
    } else if (userProfile.isVerified === false) {
      setIsModalOpen(true)
    } else {
      setAnchorEl2(event.currentTarget);
      // setSupplier(data);
      dispatch(fetchTag());
      setOpenFavoritesTag(true);
      setOpenSave(true);
      // dispatch(addFavourites(supplierId, businessName, tags));
    }
    // }
  };
  const handleSaveClose = () => {
    setOpenSave(false);
  };
  const handleFavoritesTagClose = (event, reason) => {
    // console.log(tags);
    if (reason === "clickaway") {
      return;
    }
    setOpenFavoritesTag(false);
  };
  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };
  return (
    <Grid>
      {isBig ? (
        //   <Button
        //   variant="contained"
        // color="secondary"
        //   disabled={isRealEstateDev}
        //     style={{
        //       boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        //       width: isSm ? "120px" : "200px",
        //       height: isBig ? "25px" : "20px",
        //       fontSize: isBig ? "15px" : "10px",
        //       borderRadius: "30px",
        //       // marginLeft: "5px",
        //       marginRight: isBig ? null : "5px",
        //       marginBottom: isBig ? null : "2.5px",

        //     }}
        //     onClick={(e) => handleClickSave(e)}
        //   >
        //     {/* {!isBig && (
        //   <PlaylistAddCheckIcon
        //     sx={{
        //       width: 18,
        //       ml: 0,
        //     }}
        //   />
        // )} */}
        //     Shortlist
        //   </Button>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {<Box

            variant="outlined"
            size="medium"
            onClick={(e) => isRealEstateDev || isEpcContractor ? "" : handleClickSave(e)}

            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: isRealEstateDev || isEpcContractor ? "rgba(152, 152, 152, 1)" : "#262728",
              padding: "5px 7px",
              lineHeight: "16px",
              fontStyle: "normal",
              textDecoration: "underline",
              cursor: !isRealEstateDev && !isEpcContractor && "pointer",
            }}
          >
            Shortlist
          </Box>}
          <Box
            component="img"
            src="/assets/home/ShortListedIcon.png"
            style={{
              width: "16px",
              height: "10px",
              color: "rgba(152, 152, 152, 1)",
            }}
          />
        </Box>
      ) : (
        <>
          {checkedItems && checkedItems.length > 0 ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {<Box

                variant="outlined"
                size="medium"
                // onClick={(e) => handleClickSave(e)}
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "rgba(152, 152, 152, 1)",
                  padding: "5px 7px",
                  lineHeight: "16px",
                  fontStyle: "normal",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Shortlist
              </Box>}
              <Box
                component="img"
                src="/assets/home/ShortListedIcon.png"
                style={{
                  width: "14px",
                  height: "9px",
                  color: "rgba(152, 152, 152, 1)",
                }}
              />
            </Box>
          ) : (
            <>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  variant="outlined"
                  size="medium"
                  onClick={(e) => isRealEstateDev || isEpcContractor ? "" : handleClickSave(e)}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: isRealEstateDev || isEpcContractor ? "rgba(152, 152, 152, 1)" : "#262728",
                    // color: "#262728;",
                    padding: "5px 7px",
                    lineHeight: "16px",
                    fontStyle: "normal",
                    textDecoration: "underline",
                    cursor: !isRealEstateDev && !isEpcContractor && "pointer",
                  }}
                >
                  Shortlist
                </Box>
                <Box
                  component="img"
                  src="/assets/home/ShortListedIcon.png"
                  style={{
                    width: "14px",
                    height: "9px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                />
              </Box>


              {/* {checkedItems && checkedItems.length > 0 ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    variant="outlined"
                    size="medium"
                    // onClick={(e) =>handleClickSave(e)}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#262728;",
                      padding: "5px 7px",
                      lineHeight: "16px",
                      // border: "solid 1px #000000",
                      fontStyle: "normal",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Shortlist
                  </Box>
                  <Box
                    component="img"
                    src="/assets/home/ShortListedIcon.png"
                    style={{
                      width: "14px",
                      height: "9px",
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    variant="outlined"
                    size="medium"
                    onClick={(e) => handleClickSave(e)}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#262728;",
                      padding: "5px 7px",
                      lineHeight: "16px",
                      // border: "solid 1px #000000",
                      fontStyle: "normal",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Shortlist
                  </Box>
                  <Box
                    component="img"
                    src="/assets/home/ShortListedIcon.png"
                    style={{
                      width: "14px",
                      height: "9px",
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  />
                </Box>
              )} */}
            </>
          )}
        </>
      )}

      {/* <Modal
        open={openLogInModal}
        onClose={handleCloseLogInModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}> */}

      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />
      {/* </Box>
      </Modal> */}

      {/* <FavoritesDialogBox
        supplierId={supplierId}
        businessName={businessName}
        url={url}
        handleClose={handleFavoritesTagClose}
        open={openFavoritesTag}
        tags={tags}
        itemOwnerId={itemOwnerId}
        userProfile={userProfile}
      /> */}
      <VerificationPopUp
        open={isModalOpen} // Pass the modal visibility state
        handleClose={handleCloseModal} // Pass the close modal function
      />
    </Grid>
  );
};

FavoritesMenu.propTypes = {
  favourites: PropTypes.array,
  isBig: PropTypes.bool,
};

export default FavoritesMenu;