import React from "react";
import OverviewInfo from "./OverviewInfo";
import OverviewContacts from "./OverviewContacts";
import OverviewDetails from "./OverviewDetails";
import OverviewAwardee from "./OverviewAwardee";
import RfqOverview from "../RfqOverview";
import EditRfqNew from "views/Rfqs/EditRfqNew";

const Overview = ({ data, hasAwarded, handleOpenEditAwardees, openEditRfq, setOpenEditRfq, setActiveTab }) => {
  return (
    <div className="flex gap-x-6 w-full pb-5" style={{ fontFamily: "goldman_sans" }}>
      <div className="flex flex-col w-3/4 gap-y-3">
        <div className="col-span-3 row-span-2 bg-white rounded-sm shadow-md flex items-center justify-center p-4">
          <OverviewInfo data={data} />
        </div>
        {/* <div className="col-span-3 row-span-2 bg-white rounded-lg shadow-md flex items-center justify-center p-8">
          <OverviewDetails
            description={data?.description}
            selectedFilesBase={data?.selectedFilesBase}
          />
        </div> */}
        <div className="rounded-sm">
          <RfqOverview data={data} />
        </div>
      </div>
      <div className="flex flex-col w-1/4 gap-y-3">
        {hasAwarded && (
          <div className="bg-white rounded-sm shadow-md flex items-center justify-center px-4 py-3 max-h-[calc(100vh-280px)] overflow-auto">
            <OverviewAwardee
              awardeeDetails={data?.suppliers.filter(
                (supplier) => supplier?.closingDetails?.length >= 1
              )}
              data={data}
              handleOpenEditAwardees={handleOpenEditAwardees}
            />
          </div>
        )}
        <div className="  flex items-center justify-center px-4 py-3">
          <OverviewContacts
            newCcEmails={data?.newCcEmails}
            creator={data?.requesterName}
          />
        </div>
      </div>


      {openEditRfq && <EditRfqNew setOpenEditRfq={setOpenEditRfq} data={data} setActiveTab={setActiveTab} />}

    </div>
  );
};

export default Overview;
