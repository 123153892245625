import React from 'react'
import { Customers, HeroSection } from './sections'
import FooterNew from 'views/Home/components/FooterNew'
import { FloatingButton, GlobalNavbar, } from 'views/Home 3.0/components'
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";

const GetStartedFree = () => {
    const location = useLocation();
    const interest = location.state

    return (
        <>
            <Helmet> <title>Neevay - Get Started Free</title></Helmet>
            <GlobalNavbar />
            <HeroSection interest={interest} />
            <Customers />
            <FooterNew />
            <FloatingButton />
        </>
    )
}

export default GetStartedFree