import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Topbar } from "layouts/Main/components";
import pages from "layouts/navigation";
import { Link, useNavigate, useParams } from "react-router-dom";
import { search as searchAction } from "actions/searchActions";
import { useDispatch, useSelector } from "react-redux";
import { FloatingButton, GlobalNavbar } from "views/Home 3.0/components";
import { sendNewRFQTrigger } from "actions/requirementsAction";
import RequirementFormModal from "views/RequirementFormModal/RequirementFormModal";
import NonEntityRequirmentForm from "views/RequirementFormModal/NonEntityRequirmentForm";

const SearchTopSectionMobile = ({
  checkedItems,
  searchType,
  searchKeyWord = "searchKeyWord",
  numFound,
  showBackBtn = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;
  const [openSendRfqModel, setOpenSendRfqModel] = useState(false);
  const typeMap = {
    byService: "By Service/Products",
    byName: "By Company",
    all: "All Profiles",
  };

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down(1280));
  const type = typeMap[searchType] || "";
  const { region } = useParams();
  const regionString =
    region === "Pan-India" ? "PAN India" : region?.replace(/-/g, " ");

  const openPreviousPage = () => {
    dispatch(searchAction(searchKeyWord));
    navigate(`/search/${region}/${searchKeyWord}`);
  };
  const handleMultipleBusiness = async (data) => {
    dispatch(sendNewRFQTrigger(data));
    setOpenSendRfqModel(true);
  };

  return (
    <>
      {/* <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: "#262728",
          color: "black",
        }}
      >
        <Topbar pages={pages} /> */}
      {/* </AppBar> */}
      <GlobalNavbar />
      <Box padding={"0px 24px 0px"} paddingTop={isMd ? "18px" : "8px"}>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Grid
            display="flex"
            flexWrap={"wrap"}
            marginBottom={"4px"}
            paddingTop={isSm && "16px"}
          >
            <Typography
              sx={{ color: "rgba(102, 102, 102, 1)", fontSize: "12px" }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#00AB6B",
                }}
                to="/"
              >
                Home{" "}
              </Link>{" "}
              » {regionString} »{" "}
            </Typography>
            <Typography
              color={"rgba(102, 102, 102, 1)"}
              marginLeft={"4px"}
              textTransform={"capitalize"}
              fontSize={"12px"}
            >
              {" "}
              {searchKeyWord}{" "}
            </Typography>
          </Grid>
          {/* {showBackBtn && (
            <Typography
              style={{
                fontSize: "12px",
                color: "#00AB6B",
                marginRight: "18px",
                // marginBottom: "12px",
                cursor: "pointer",
                paddingTop: isSm && "14px",
              }}
              onClick={openPreviousPage}
            >
              {"<<"} Back
            </Typography>
          )} */}
        </Grid>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid>
            <h1 style={{ fontWeight: 400, fontSize: "20px" }}>
              {" "}
              Showing {numFound > 1 ? "Results" : "Result"} for{" "}
              <strong style={{ textTransform: "capitalize" }}>
                {" "}
                {searchKeyWord}
              </strong>{" "}
              {`(${regionString})`}
              {/* {`(PAN-India)`} */}
            </h1>
          </Grid>
        </Grid>

        {entityId ? (
          <RequirementFormModal
            open={openSendRfqModel}
            setOpen={setOpenSendRfqModel}
          />
        ) : (
          <NonEntityRequirmentForm
            open={openSendRfqModel}
            setOpen={setOpenSendRfqModel}
          />
        )}
      </Box>
    </>
  );
};

export default SearchTopSectionMobile;
