import {
  Button,
  Dialog,
  Grid,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { VendorListSection } from "views/RequirementFormModal/sections";
import { useDispatch } from "react-redux";
import { addVendorToExistingRfq } from "actions/requirementsAction";

const AddExtraVendorSection = ({
  userId,
  rfqId,
  currentPage,
  newRequirements,
}) => {
  const dispatch = useDispatch();
  const selectedRfqData = newRequirements?.filter(
    (data) => data?.rfqId == rfqId
  );
  const [openAddVendorPopUp, setOpenAddVendorPopUp] = useState(false);
  const [enquiryState, setEnquiryState] = useState([]);
  const [duplicateError, setDuplicateError] = useState(false);
  const [duplicateValue, setDuplicateValue] = useState("");

  const handleCloseAddVendorPopUp = () => {
    setOpenAddVendorPopUp(false);
    setEnquiryState([]);
  };
  const handleOpenAddVendorPopUp = () => {
    setOpenAddVendorPopUp(true);
  };

  const handleAddVendors = () => {
    dispatch(addVendorToExistingRfq(userId, rfqId, enquiryState, currentPage));
    handleCloseAddVendorPopUp();
  };

  const findDuplicateCheck = (
    alreadySelectedSuppliers,
    newVendorSupplierId
  ) => {
    for (const supplier of alreadySelectedSuppliers) {
      if (
        supplier.hasOwnProperty("supplierId") &&
        supplier.supplierId == newVendorSupplierId
      ) {
        setEnquiryState(
          enquiryState?.filter(
            (data) => data?.supplierId != newVendorSupplierId
          )
        );
        setDuplicateValue(supplier?.businessName);
        setDuplicateError(true);
        return true; // Found the object with the specified supplierId
      }
    }
    return false; // supplierId not found in any of the objects
  };

  const handleDuplicateErrorMsgClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDuplicateError(false);
  };

  useEffect(() => {
    if (enquiryState?.length > 0) {
      const selectedSupplier = selectedRfqData[0]?.suppliers;
      const hasCommonSupplierId = findDuplicateCheck(
        selectedSupplier,
        enquiryState[enquiryState?.length - 1]?.supplierId
      );
      if (hasCommonSupplierId) {
      }
      console.log("this is a data ", selectedSupplier);
      console.log("this is a 22222 ", enquiryState);
      console.log("this is a hasCommonSupplierId 4444 ", hasCommonSupplierId);
    }
  }, [enquiryState]);
  return (
    <Grid
      sx={{
        width: "100%",
        display: "flex",
        mb: "5px",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: "#FD6600",
          paddingY: "2px",
          color: "white",
          "&:hover": {
            backgroundColor: "#FD6600",
            transition: "background-color 0.3s, box-shadow 0.3s",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
          },
        }}
        onClick={handleOpenAddVendorPopUp}
      >
        add vendor
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openAddVendorPopUp}
        keepMounted
        onClose={handleCloseAddVendorPopUp}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseAddVendorPopUp}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{"Add New Vendor"}</DialogTitle>
        <DialogContent>
          <Grid sx={{ height: "250px" }}>
            <VendorListSection
              enquiryState={enquiryState}
              setEnquiryState={setEnquiryState}
              isSmallScreen={false}
              existingRfqEditView={true}
            />
          </Grid>
          {duplicateError && (
            <Snackbar
              open={duplicateError}
              autoHideDuration={5000}
              onClose={handleDuplicateErrorMsgClose}
            >
              <Alert
                onClose={handleDuplicateErrorMsgClose}
                severity="warning"
                variant="filled"
                sx={{ width: "100%" }}
              >
                {`${duplicateValue} is already exists in RFQ`}
              </Alert>
            </Snackbar>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={enquiryState?.length < 1}
            onClick={handleAddVendors}
            sx={{
              backgroundColor: "#FD6600",
              color: "white",
              "&:hover": {
                backgroundColor: "#FD6600",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
              },
            }}
          >
            {enquiryState?.length <= 1 ? "Add Vendor" : "Add Vendors"}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AddExtraVendorSection;
