import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { Box, Button, Checkbox, FormHelperText, Grid, Icon, Modal, TextField, Typography } from "@mui/material";
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { registerUserWithMobile, sendwhatsAppOTP } from "actions/authActions";
import "./style.css"
import { CREATE_CRM_LEAD_URL } from "../../../config/services";
import axios from "utils/axios";
import { getCRMLeadByPhone } from "actions/crmAction";
import { useTheme } from "@mui/material/styles";
import LogInOtpVerifyPopUp from "../SignInOTPVerify/LogInOtpVerifyPopUp";

function LogInMobilePopUp({ setOpenLogInModal, handleCloseLogInModal, openLogInModal, className, ...rest }) {
    const dispatch = useDispatch();
    const theme = useTheme();

    const [otpPopUp, setOtpPopUp] = React.useState(true)
    const [mobileNumber, setMobileNumber] = React.useState();
    const [isWhatsappNo, setIsWhatsappNo] = React.useState(false)
    const [isUserBlocked, setIsUserBlocked] = React.useState(null)

    const onSubmitSuccess = () => {
        setOtpPopUp(false)
    }
    const isMd = useMediaQuery(theme.breakpoints.down("md"));
    // modal style here
    const modalStyle = {
        borderRadius: '25px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMd ? 355 : 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        outline: "none",
        p: isMd ? 3 : 4,
    };
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target
        setIsWhatsappNo(checked);
    };

    
    return (
        <Modal
            open={openLogInModal}
            onClose={handleCloseLogInModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Box sx={modalStyle} >
                {otpPopUp ? isUserBlocked ? <><Typography
                    style={{
                        fontSize: "24px",

                        textAlign: "center",

                        fontWeight: "600",

                        marginBottom: "14px",
                        color: "#FD0B0B"
                    }}
                >
                    Blocked  !      </Typography>

                    <Grid
                        style={{
                            backgroundColor: "#FFE9E9",
                            padding: "24px",
                            marginBottom: "14px",
                            borderLeft: "8px solid #F97C7C",
                            borderWidth: "8px",
                        }}
                    >
                        <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
                            Your account has been blocked please contact us for reactivating.
                        </Typography>
                    </Grid>
                </> :
                    <Formik
                        initialValues={{
                            mobile: "",
                        }}
                        validationSchema={Yup.object().shape({
                            mobile: Yup.string()
                                .matches(
                                    /^[6-9][0-9]{9}/,
                                    "Please enter valid 10 digit mobile number"
                                )
                                .min(10)
                                .max(10)
                                .required("Please Enter your mobile"),

                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            setMobileNumber(values.mobile)
                            try {
                                const name = ""
                                if (isWhatsappNo) {
                                    dispatch(sendwhatsAppOTP(values.mobile,onSubmitSuccess))
                                } else {
                                    dispatch(registerUserWithMobile(name, values.mobile,onSubmitSuccess));
                                }
                                // onSubmitSuccess();
                                const payload = [{
                                    "Attribute": "EmailAddress",
                                    "Value": ""
                                },
                                {
                                    "Attribute": "Phone",
                                    "Value": values.mobile
                                },]

                                await axios.post(CREATE_CRM_LEAD_URL, payload).
                                    then((crmResponse) => {
                                        // console.log(":CREATE_CRM_LEAD: Response",crmResponse)
                                        dispatch(getCRMLeadByPhone(values.mobile))
                                    }).
                                    catch((crmError) => {
                                        // console.log("CRMERROR",crmError)
                                        dispatch(getCRMLeadByPhone(values.mobile))
                                    })
                            } catch (error) {
                                if (error?.response?.data?.isBlocked) {
                                    setIsUserBlocked(error?.response?.data?.isBlocked)
                                }
                                const message = error.message || "Something went wrong";
                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit} {...rest}>
                                <Grid container spacing={2}>
                                    <Grid container justifyContent="flex-end" marginBottom={"28px"}>
                                        <CancelOutlined onClick={handleCloseLogInModal} style={{ cursor: "pointer", color: "#B9B9C3", textAlign: "right", marginRight: "-15px" }} />
                                    </Grid>
                                    <Typography style={{ fontSize: "20px", fontWeight: 600, lineHeight: "30px", textAlign: "center", color: "#000000", marginBottom: "10px" }}>Login Now to Explore Thousands of Vendors in Neevay Network!</Typography>
                                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                        <TextField
                                            color="secondary"
                                            sx={{ outline: "none" }}
                                            error={Boolean(touched.mobile && errors.mobile)}
                                            fullWidth
                                            helperText={touched.mobile && errors.mobile}
                                            placeholder="Enter Mobile Number"
                                            margin="normal"
                                            name="mobile"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                // Only allow digits
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === '' || regex.test(e.target.value)) {
                                                    handleChange(e);
                                                }
                                            }}
                                            inputProps={{ maxLength: 10 }}
                                            required
                                            value={values.mobile}
                                            variant="outlined"
                                            autoFocus
                                            style={{
                                                width: "350px", height: "26px", borderRadius: "5px", transition: 'border-color 0.3s',
                                                paddingLeft: "4px", fontSize: "16px", fontWeight: 400
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {errors.submit && (
                                            <Box >
                                                <FormHelperText error>Something went wrong, please try again.
                                                </FormHelperText>
                                            </Box>
                                        )}
                                    </Grid>

                                    {/* {values.mobile && <Grid
                                        item xs={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginTop: "10px",
                                            gap: 4
                                        }}
                                    >
                                        <Checkbox
                                            color="secondary"
                                            name="whatsappNo"
                                            onChange={handleCheckboxChange}
                                            checked={isWhatsappNo}
                                            sx={{
                                                height: "18px",
                                                width: "18px",
                                                borderRadius: "3px",
                                                // border: "1px solid #898792"
                                            }}
                                        />
                                        <Typography style={{ fontSize: "12px", fontWeight: "400", color: "#6E6B7B" }}>Get OTP On Whatsapp Number</Typography>
                                    </Grid>} */}
                                    <Grid item xs={12} display={"flex"} justifyContent={"center"} marginTop={"10px"} >
                                        <Button
                                            // color="secondary"
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            style={{
                                                width: "147px",
                                                height: "36px",
                                                borderRadius: "5px",
                                                border: "1px solid #D8D6DE",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                marginTop: "10px",
                                                backgroundColor: "#FD6600",
                                                color:"white",
                                                "&:hover": {
                                                  backgroundColor: "#FD6600",
                                                  transition: "background-color 0.3s, box-shadow 0.3s",
                                                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                                                }
                                            }}
                                        >
                                            Login With OTP
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                    :

                    <>
                        <LogInOtpVerifyPopUp isWhatsappNo={isWhatsappNo} setOtpPopUp={setOtpPopUp} setOpenLogInModal={setOpenLogInModal} mobile={mobileNumber} />
                    </>
                }

            </Box>
        </Modal >
    );
}

LogInMobilePopUp.propTypes = {
    className: PropTypes.string,
    onSubmitSuccess: PropTypes.func,
};

LogInMobilePopUp.default = {
    onSubmitSuccess: () => { },
};

export default LogInMobilePopUp;
