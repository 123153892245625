import {
  Button,
  CircularProgress,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchGstInfo, registerNewCompany } from "actions/registerActions";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./form.css";
import { useNavigate } from "react-router-dom";
import axios from "utils/axios";
import { useState } from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { withStyles } from '@material-ui/core/styles';


const GreenRadio = withStyles({
  root: {
    color: '#46AC9E', // Color of the unchecked radio button
    '&$checked': {
      color: '#46AC9E', // Color of the checked radio button
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
function BusinessRegistrationForm() {

 
  const [gstin, setGSTIn] = React.useState("");
  const [additionalDetailsOpen, setAdditionalDetailsOpen] =
    React.useState(false);
  const [isBusinessNameDisabled, setIsBusinessNameDisabled] =
    React.useState(false);
  const [gstMessage, setGstMessage] = useState(false);

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  // const business = useSelector((state) => state.business);
  // const supplierId = business?.profile?.supplierId;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchGSTInfo = (event, values) => {
    setGSTIn(event.target.value);
    console.log("gst....", gstin);
  };

  const handleFetchGST = async (setFieldValue) => {
    try {
      const gstInfo = await dispatch(fetchGstInfo(gstin));
      if (gstInfo) {
        setFieldValue("businessName", gstInfo.companyName);
        setFieldValue("addressLine1", gstInfo.address.addressLine1);
        setFieldValue("addressLine2", gstInfo.address.addressLine2);
        setFieldValue("city", gstInfo.address.city);
        setFieldValue("country", gstInfo.address.country);
        setFieldValue("pincode", gstInfo.address.pincode);
        setFieldValue("state", gstInfo.address.state);
        setFieldValue("businessType", gstInfo.businessType);
        setFieldValue("establishYear", gstInfo.establishYear);
        setFieldValue("annualTurnOver", gstInfo.annualTurnOver);
        setIsBusinessNameDisabled(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGSTNumberChange = (event, setFieldValue) => {
    const newValue = event.target.value;
    if (newValue === "no") {
      // Clear the fields when the user selects "No" for GST
      setFieldValue("hasGSTNumber", newValue);
      setFieldValue("gstin", "");
      setFieldValue("businessName", "");
      setFieldValue("businessType", "");
      setFieldValue("annualTurnOver", "");
      setFieldValue("addressLine1", "");
      setFieldValue("addressLine2", "");
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("pincode", "");
      setFieldValue("establishYear", "");
      setIsBusinessNameDisabled(false);
    } else {
      // Keep the value for "hasGSTNumber"
      setFieldValue("hasGSTNumber", newValue);
    }
  };
  return (
    <Formik
      initialValues={{
        hasGSTNumber: "",
        gstin: "",
        businessName: "",
        businessType: "",
        annualTurnOver: "",
        establishYear: "",
        addressLine1: "",
        addressLine2: "",
        addressType: "HEAD OFFICE",
        city: "",
        state: "",
        pincode: "",
        country: "India",
      }}
      validationSchema={Yup.object().shape({
        hasGSTNumber: Yup.string().required("This field is required"),
        gstin: Yup.string()
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            "Please Enter Valid GST Number"
          )
          .max(20)
          .min(10)
          .when("hasGSTNumber", {
            is: "yes",
            then: Yup.string(),
            // .required('This field is required'),
            otherwise: Yup.string().notRequired(),
          }),
        businessName: Yup.string().required("This field is required"),
        businessType: Yup.string().required("This field is required"),
        // annualTurnOver: Yup.string().required("This field is required"),
        addressLine1: Yup.string().required("This field is required"),
        city: Yup.string().required("This field is required"),
        state: Yup.string().required("This field is required"),
        pincode: Yup.string()
          .required("This field is required")
          .matches(/^\d{6}$/, "Kindly Enter Valid Pincode"),
        establishYear: Yup.string()
          .matches(/^(19\d{2}|20\d{2})$/, "Please enter valid 4 digit year")
          .required("This field is required"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {

          dispatch(registerNewCompany(values));
          //  await axios.post("/api/auth/demo/request", {
          //   name: userProfile?.name,
          //   number: userProfile?.mobile,
          //   interestShown: "Business Registration",
          // });
          navigate("/register/business/details");


        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);

        }


      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            sx={{
              display: "flex",
              gap: "16px",
              flexDirection: "column",
              alignItems: "center",
              marginTop: isSm ? "22px" : "52px",
              paddingX: isSm ? "20px" : "",
            }}
          >
            <Grid textAlign={"start"}>
              <Grid>
                <Typography
                  sx={{
                    color: "#5E5873",
                    fontFamily: "Roboto",
                    fontSize: "24px",
                  }}
                >
                  Let's Register your Business Now......
                </Typography>
                <Typography
                  sx={{
                    color: "#6E6B7B",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                  }}
                >
                  Help Us to Create your Business Profile by Answering some
                  questions
                </Typography>
              </Grid>

              <Grid
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                marginTop={"16px"}
                marginBottom={"25px"}
              >
                <Grid>
                  <FormControl>
                    <FormLabel className="inputLabel" component="legend">
                      Do you have GST Number ?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="hasGSTNumber"
                      value={values.hasGSTNumber}
                      onChange={(event) => {
                        handleGSTNumberChange(event, setFieldValue);
                        if (event.target.value === "yes") {
                          setAdditionalDetailsOpen(false);
                        } else {
                          setAdditionalDetailsOpen(true);
                        }
                      }}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.hasGSTNumber && errors.hasGSTNumber
                      )}
                      helperText={touched.hasGSTNumber && errors.hasGSTNumber}
                    >
                      <FormControlLabel
                        sx={{
                          color: "#6E6B7B",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        value="yes"
                        control={<GreenRadio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        sx={{
                          color: "#6E6B7B",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        value="no"
                        control={<GreenRadio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {values.hasGSTNumber === "yes" && (
                  <>
                    <Grid>
                      <Typography
                        style={{
                          color: " #707070",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "20px",
                          letterSpacing: "0.14px",
                          marginBottom: "6px",
                        }}
                      >
                        Can I have your GST Number ?
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        name="gstin"
                        value={values.gstin}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          fetchGSTInfo(e, values);
                        }}
                        error={
                          Boolean(touched.gstin && errors.gstin) || gstMessage
                        }
                        helperText={
                          (touched.gstin && errors.gstin) ||
                          (gstMessage &&
                            "This GST No. is already registered with us, Kindly enter new GST No.")
                        }
                      />
                    </Grid>

                    <Grid>
                      <Typography
                        sx={{
                          color: "#3963AB",
                          fontSize: "12px",
                          lineHeight: "14px",
                          letterSpacing: " 0.01px",
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        By clicking below, I provide my consent to fetch the GST
                        details.
                      </Typography>
                    </Grid>
                    <Grid style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={async () => {
                          try {
                            const res = await axios.get(
                              `/api/supplier/check/gst/exist?gstNumber=${values.gstin}`
                            );

                            if (res.data.error === true) {
                              setGstMessage(true);
                            } else if (res.data.error === false) {
                              await setGstMessage(false);
                              await handleFetchGST(setFieldValue);
                              await setAdditionalDetailsOpen(true);
                            }
                          } catch (error) {
                            // Handle errors here
                            console.error("Error:", error);
                          }
                        }}
                        color="secondary"
                        variant="contained"
                        sx={{
                          fontSize: "12px",
                          height: "30px",
                          width: "120px",
                          borderRadius: "5px",
                          backgroundColor: "black",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "black",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                            transition: "background-color 0.3s, box-shadow 0.3s",
                          },
                        }}
                        disabled={!values.gstin}
                      >
                        Get Details
                      </Button>
                    </Grid>
                  </>
                )}

                {additionalDetailsOpen && (
                  <>

                    <Grid>
                      <Typography
                        style={{
                          color: "rgb(112, 112, 112)",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          letterSpacing: "0.14px",
                          fontFamily: "Roboto",
                          marginBottom: "5px",
                        }}
                      >
                        Business Name
                      </Typography>
                      <TextField
                        size="small"
                        name="businessName"
                        id="businessName"
                        type="businessName"
                        value={values.businessName}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.businessName && errors.businessName
                        )}
                        helperText={touched.businessName && errors.businessName}
                      />

                    </Grid>
                    <Grid container gap={2}>
                      <Grid item xs={isSm || isMd ? 12 : 5.7}>
                        <FormControl fullWidth>
                          <Typography
                            style={{
                              color: " #707070",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              letterSpacing: "0.14px",
                              marginBottom: "6px",
                            }}
                          >
                            Company Type
                          </Typography>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="businessType"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.businessType}
                            error={Boolean(
                              touched.businessType && errors.businessType
                            )}
                            helperText={
                              touched.businessType && errors.businessType
                            }
                            disabled={isBusinessNameDisabled}
                          >
                            <MenuItem value={"Proprietorship"}>
                              Proprietorship
                            </MenuItem>
                            <MenuItem value={"Partnership"}>
                              Partnership
                            </MenuItem>
                            <MenuItem value={"Limited Liability Partnership"}>
                              Limited Liability Partnership
                            </MenuItem>
                            <MenuItem value={"Private Limited"}>Private Limited</MenuItem>
                            <MenuItem value={"Public Limited"}>
                              Public Limited Company
                            </MenuItem>
                            {/* <MenuItem value={"OTHERS"}>Others</MenuItem> */}
                          </Select>
                        </FormControl>
                        <Typography sx={{ color: "#D32F2F", fontSize: "12px", pl: "10px" }}>{touched.businessType && errors.businessType}</Typography>
                      </Grid>
                      <Grid item xs={isSm || isMd ? 12 : 5.7}>
                        <FormControl fullWidth>
                          <Typography
                            style={{
                              color: " #707070",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              letterSpacing: "0.14px",
                              marginBottom: "6px",
                            }}
                          >
                            Annual Turnover
                          </Typography>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="annualTurnOver"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.annualTurnOver}
                          >
                            <MenuItem value={"Rs. 0 to 40 lakhs"}>
                              Rs. 0 to 40 lakhs
                            </MenuItem>
                            <MenuItem value={"Rs. 40 lakhs to 1.5 Cr."}>
                              Rs. 40 lakhs to 1.5 Cr.
                            </MenuItem>
                            <MenuItem value={"Rs. 1.5 Cr. to 5 Cr."}>
                              Rs. 1.5 Cr. to 5 Cr.
                            </MenuItem>
                            <MenuItem value={"Rs. 5 Cr. to 25 Cr."}>
                              Rs. 5 Cr. to 25 Cr.
                            </MenuItem>
                            <MenuItem value={"Rs. 25 Cr. to 100 Cr. "}>
                              Rs. 25 Cr. to 100 Cr.
                            </MenuItem>
                            <MenuItem value={"Rs. 100 Cr. to 500 Cr."}>
                              Rs. 100 Cr. to 500 Cr.
                            </MenuItem>
                            <MenuItem value={"Rs. 500 Cr. and above"}>
                              Rs. 500 Cr. and above
                            </MenuItem>
                          </Select>
                          <Typography sx={{ fontSize: "10px" }}>Note: We recommend to select a range from the dropdown for your business to be filtered by the buyer</Typography>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid>
                      <Typography
                        style={{
                          color: " #707070",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "20px",
                          letterSpacing: "0.14px",
                          marginBottom: "6px",
                        }}
                      >
                        Company Address
                      </Typography>

                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <Grid>
                          <Typography
                            style={{
                              color: " #707070",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              letterSpacing: "0.14px",
                              marginBottom: "6px",
                            }}
                          >
                            Address Line 1{" "}
                          </Typography>
                          <TextField
                            name="addressLine1"
                            id="addressLine1"
                            type="addressLine1"
                            value={values.addressLine1}
                            variant="outlined"
                            fullWidth
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(
                              touched.addressLine1 && errors.addressLine1
                            )}
                            helperText={
                              touched.addressLine1 && errors.addressLine1
                            }
                          />
                        </Grid>

                        <Grid>
                          <Typography
                            style={{
                              color: " #707070",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              letterSpacing: "0.14px",
                              marginBottom: "6px",
                            }}
                          >
                            Address Line 2{" "}
                          </Typography>
                          <TextField
                            name="addressLine2"
                            id="addressLine2"
                            type="addressLine2"
                            value={values.addressLine2}
                            variant="outlined"
                            fullWidth
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(
                              touched.addressLine2 && errors.addressLine2
                            )}
                            helperText={
                              touched.addressLine2 && errors.addressLine2
                            }
                          />
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              color: "#707070",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              letterSpacing: "0.14px",
                              marginBottom: "6px",
                            }}
                          >
                            City{" "}
                          </Typography>
                          <TextField
                            name="city"
                            id="city"
                            type="city"
                            value={values.city}
                            variant="outlined"
                            fullWidth
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                          />
                        </Grid>

                        <Grid>
                          <Typography
                            style={{
                              color: " #707070",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              letterSpacing: "0.14px",
                              marginBottom: "6px",
                            }}
                          >
                            State{" "}
                          </Typography>
                          <TextField
                            name="state"
                            id="state"
                            type="state"
                            value={values.state}
                            variant="outlined"
                            fullWidth
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.state && errors.state)}
                            helperText={touched.state && errors.state}
                          />
                        </Grid>

                        <Grid>
                          <Typography
                            style={{
                              color: " #707070",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              letterSpacing: "0.14px",
                              marginBottom: "6px",
                            }}
                          >
                            Pincode{" "}
                          </Typography>
                          <TextField
                            name="pincode"
                            id="pincode"
                            type="pincode"
                            value={values.pincode}
                            variant="outlined"
                            fullWidth
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.pincode && errors.pincode)}
                            helperText={touched.pincode && errors.pincode}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid>
                      <Typography
                        style={{
                          color: " #707070",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "20px",
                          letterSpacing: "0.14px",
                          marginBottom: "6px",
                        }}
                      >
                        Establishment Year
                      </Typography>
                      <TextField
                        sx={{ width: "200px", height: "36px" }}
                        size="small"
                        name="establishYear"
                        value={values.establishYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.establishYear && errors.establishYear
                        )}
                        helperText={
                          touched.establishYear && errors.establishYear
                        }
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Grid xs={5.8}>
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => resetForm()}
                          sx={{
                            width: "100%",
                            borderRadius: "5px",
                            fontSize: "14px",
                            fontWeight: 600,
                            backgroundColor: "black",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "black",
                              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", 
                              transition: "background-color 0.3s, box-shadow 0.3s",

                            },
                          }}
                        >
                          Reset Form
                        </Button>
                      </Grid>
                      <Grid xs={5.8}>
                        <Button
                          sx={{
                            width: "100%",
                            borderRadius: "5px",
                            fontSize: "13px",
                            fontWeight: 600,
                            backgroundColor: "black",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "black",
                              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", 
                               transition: "background-color 0.3s, box-shadow 0.3s",
                            },
                          }}
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting} // Disable the button when submitting or loading
                        >

                          Verify & Proceed

                        </Button>

                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}

    </Formik>
  );
}
export default BusinessRegistrationForm;
