import { Card, Grid, Skeleton } from '@mui/material'
import React from 'react'

const SearchCardSkeleton = () => {
    const showCardsData = ["a", "b", "c", "d"]

    return (
        <Grid height="250px" display="flex" flexWrap="wrap" overflow="hidden" >
            {showCardsData?.map((data) => <Grid item xs={3} md={3} lg={3} key={data}>
                <Card
                    sx={{
                        width: "291px",
                        height: "244px",
                        // border: "1px solid rgba(0, 0, 0, 0.5)",
                        paddingBottom: "10px",
                        paddingTop: "20px",
                        marginBottom: "20px",
                        marginLeft: "20px",
                    }}
                >
                    <Grid sx={{ display: "flex", gap: "15px", marginX: "8px" }}>
                        <Grid>
                            <Skeleton
                                variant='circular'
                                width={56}
                                height={56}
                            />
                        </Grid>
                        <Grid width="200px">
                            <Skeleton
                                width={"100%"}
                                height={"24px"}
                            />
                            <Skeleton
                                width={"80%"}
                                height={"20px"}
                            />

                            <Skeleton
                                width={"90%"}
                                height={"20px"}
                            />

                        </Grid>
                    </Grid>
                    <Skeleton
                        // width={}
                        height={75}
                    />
                    <Grid
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "15px",
                            alignItems: "center",
                            marginX: "8px",
                        }}
                    >
                        <Skeleton
                            variant='circular'
                            width={30}
                            height={30}
                        />
                        <Skeleton
                            width={100}
                            height={35}
                        />
                    </Grid>
                </Card>
            </Grid>)}
        </Grid>

    )
}

export default SearchCardSkeleton