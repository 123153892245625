import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ProfileHead from "./ProfileHead";
import ProfileDescription from "./ProfileDescription";
import ProfileAbout from "./ProfileAbout";
import ProfileContact from "./ProfileContact";
import ProfileHallOfFame from "./ProfileHallOfFame";
import ProfileService from "./ProfileService";

import { useNavigate, useLocation } from "react-router";
import { Grid, Divider } from "@mui/material";
import Url from "./Url";

import { getIsPremium } from "utils/accessControl";
import Awards from "./Awards";
import { getUrl } from "actions/businessActions";
import CertificateSection from "./CertificateSection";

import ClientSection from "./ClientSection";
import BusinessRegions from "./BusinessRegions";

function BusinessProfile({ profile, projects, isPublicProfile, permissions }) {
  const bProfile = profile?.businessProfile;
  const bStatutory = profile?.statutory;
  const userIdOfOwner = profile?.owners?.[0];


  let isAwardExists = false;

  if (profile?.awards && profile?.awards?.length > 0) {
    isAwardExists = true;
  }

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const userProfile = auth.user;
  const navigate = useNavigate();
  const currentLocation = useLocation()




  useEffect(() => {
    try {
      dispatch(getUrl(currentLocation.pathname));
    } catch (error) {
      // console.log(error);
    }
    if (!userProfile && !isPublicProfile) {
      navigate("/signin-mobile");
    }
  }, []);


  return (
    <Grid container sx={{ mb: 2 }}>
      <ProfileHead
        supplierId={profile?.supplierId}
        isPremium={getIsPremium(profile?.subscriptionType)}
        logoUrl={bProfile?.logoUrl}
        bannerUrl={bProfile?.bannerUrl}
        businessName={profile?.businessName}
        businessDomain={profile?.domains?.join(", ")}
        location={profile?.location}
        projectsDelivered={bProfile?.projectCount}
        businessCategory={bProfile?.businessCategory}
        socialLinks={bProfile?.socialLinks}
        isPublicProfile={isPublicProfile}
        addresses={bProfile?.addresses}
        permissions={permissions}
        url={profile?.url}
        whatsappMobileNumber={bProfile?.businessMobile}
        // businessOwnerData={businessOwnerData}
        userIdOfOwner={userIdOfOwner}
        businessRegions={profile?.regions}
      />
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <ProfileAbout
            establishedOn={bProfile?.establishYear}
            gstCertified={bStatutory?.gstin}
            isoCertified={bProfile?.certificates?.length}
            businessType={bProfile?.businessType}
            isPublicProfile={isPublicProfile}
            bProfile={bProfile}
            bStatutory={bStatutory}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <ProfileContact
            supplierId={profile?.supplierId}
            website={bProfile?.websiteUrl}
            email={bProfile?.businessEmail}
            mobile={bProfile?.businessMobile}
            contact={bProfile?.contact}
            addresses={bProfile?.addresses}
            isPublicProfile={isPublicProfile}
            permissions={permissions}
            businessOwnerUserId={userIdOfOwner}
            bCategory={bProfile}
          />
        </Grid>
        <Divider />
        <ProfileDescription
          summary={bProfile?.businessHeadline}
          description={bProfile?.description}
        />
        {!isPublicProfile && (
          <Grid item xs={12} sm={12} md={6} xl={6} sx={{ ml: 2.8, mt: 4 }}>
            <Url
              url={profile?.url}
              supplierId={profile?.supplierId}
              isPublicProfile={isPublicProfile}
              permissions={permissions}
            />
          </Grid>
        )}
      </Grid>







      <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mt: 3 }}>
        <BusinessRegions
          supplierId={profile?.supplierId}
          services={profile?.services}
          isPublicProfile={isPublicProfile}
          permissions={permissions}
          businessRegions={profile?.regions}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mt: 3 }}>
        <ProfileService
          supplierId={profile?.supplierId}
          services={profile?.services}
          isPublicProfile={isPublicProfile}
          permissions={permissions}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mt: 5 }}>
        <ProfileHallOfFame
          supplierId={profile?.supplierId}
          projects={projects}
          isPublicProfile={isPublicProfile}
          permissions={permissions}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mt: 5 }}>
        <ClientSection
          supplierId={profile?.supplierId}
          clients={profile?.clients}
          isPublicProfile={isPublicProfile}
          permissions={permissions}
        />
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ ml: 1, mt: 3 }}
        direction={isPublicProfile && !isAwardExists ? "row-reverse" : "row"}
      >
        <Grid item xs={12} md={6} sm={12} xl={5}>
          <Awards
            awards={profile?.awards}
            supplierId={profile?.supplierId}
            isPublicProfile={isPublicProfile}
            permissions={permissions}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12} xl={5}>
          <CertificateSection
            certificateDetails={bProfile?.certificateImages}
            supplierId={profile?.supplierId}
            isPublicProfile={isPublicProfile}
            permissions={permissions}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

BusinessProfile.defaultProps = {
  isPublicProfile: true,
};

BusinessProfile.propTypes = {
  profile: PropTypes.object,
  projects: PropTypes.array,
  isPublicProfile: PropTypes.bool,
  permissions: PropTypes.object,
};

export default BusinessProfile;
