import axios from "utils/axios";

import {
    CREATE_CRM_LEAD_URL,
    GET_CRM_LEAD_BY_PHONE_NUMBER,
    UPDATE_CRM_LEAD_BY_ID,
    CREATE_LEAD_ACTIVITY_URL
  } from "config/services";

  class RegisterCRMService {
     
    getCRMLeadByPhone = (mobile) =>
    new Promise((resolve, reject) => 
    {
      const getCRMByPhoneUrl = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(mobile)
        axios.get(getCRMByPhoneUrl)
        .then((response)=>{
            // console.log("GET CRM LEAD BY PHONE RESPONSE",response.data[0].ProspectID)
            if (response?.data?.error) {
                // console.log(
                // "GET CRM LEAD BY PHONE RESPONSE Err msg:",response?.error,response?.data);
                reject(response.data.message);
            }
            else {
                if (response.data) {
                }
                resolve(response.data[0].ProspectID);
            }  
        })
        .catch((error) => {
            if (error.response) {
            reject(error?.response?.data);
            }

            reject(error);
        });
    }); 



    updateCRMLeadByLeadId = (leadId,payload) =>
    new Promise((resolve, reject) => 
    {
       const updateLeadByLeadIdUrl = UPDATE_CRM_LEAD_BY_ID.concat(leadId)
       axios.post(updateLeadByLeadIdUrl,payload)
       .then((response)=>{
        // console.log("UPDATE CRM LEAD BY ID RESPONSE",response)
        if (response?.data?.error) {
            // console.log("UPDATE CRM LEAD BY ID RESPONSE Err msg:",response?.error,response?.data);
            reject(response.data.message);
        }
        else {
            if (response) {
            }
            resolve(response);
        }  
     })
        .catch((error) => {
            if (error.response) {
            reject(error?.response?.data);
            }

            reject(error);
        });
    })
    
    createLeadActivity = (activityInfo) =>
    new Promise((resolve, reject) => 
    {
       axios.post(CREATE_LEAD_ACTIVITY_URL,activityInfo)
       .then((response)=>{
        // console.log("CREATE_LEAD_ACTIVITY RESPONSE",response)
        if (response?.data?.error) {
            // console.log("CREATE_LEAD_ACTIVITY RESPONSE Err msg:",response?.error,response?.data);
            reject(response.data.message);
        }
        else {
            if (response) {
            }
            resolve(response);
        }  
     })
        .catch((error) => {
            if (error.response) {
            reject(error?.response?.data);
            }

            reject(error);
        });
    })

}


const registerCRMService = new RegisterCRMService();


export default registerCRMService;