import requirementsService from "services/requirementsService";
import {
  NewAddReqInfo,
  RfqDeleteInfo,
  RfqView,
  ReqInfo,
  NewEnquiryInfo,
  ExistingEnquiryInfo,
} from "interface/addReqIf";

export const REQUIREMENTS_FETCH = "@requirements/fetch";
export const REQUIREMENTS_FETCH_SUCCESS = "@requirements/fetch-success";
export const REQUIREMENTS_FETCH_SUCCESS_RESEND =
  "@requirements/fetch-success_resend";
export const REQUIREMENTS_FETCH_FAILURE = "@requirements/fetch-failure";

export const ADD_REQ_FETCH = "@requirements/add";
export const ADD_REQ_FETCH_SUCCESS = "@requirements/add-success";
export const ADD_REQ_FETCH_FAILURE = "@requirements/add-failure";

export const RFQ_DELETE = "@requirements/delete";
export const RFQ_DELETE_SUCCESS = "@requirements/delete-success";
export const RFQ_DELETE_FAILURE = "@requirements/delete-failure";

export const RFQ_CLOSE = "@requirements/close";
export const RFQ_CLOSE_SUCCESS = "@requirements/close-success";
export const RFQ_CLOSE_FAILURE = "@requirements/close-failure";

export const RFQ_VIEW = "@requirements/rfq-view";
export const RFQ_VIEW_SUCCESS = "@requirements/rfq-view-success";
export const RFQ_VIEW_FAILURE = "@requirements/rfq-view-failure";

export const SEND_RFQ_TRIGGER = "@requirements/existing/rfq/send-trigger";
export const SEND_NEW_RFQ_TRIGGER = "@requirements/new/rfq/send-trigger";
export const SEND_MULTIPLE_RFQ_TRIGGER =
  "@requirements/multiple/rfq/send-trigger";

export const SEND_NEW_ENQUIRY = "@requirements/enquiry/new/send";
export const SEND_NEW_ENQUIRY_SUCCESS =
  "@requirements/enquiry/new/send-success";
export const SEND_NEW_ENQUIRY_FAILURE =
  "@requirements/enquiry/new/send-failure";

export const SEND_EXISTING_ENQUIRY = "@requirements/enquiry/existing/send";
export const SEND_EXISTING_ENQUIRY_SUCCESS =
  "@requirements/enquiry/existing/send-success";
export const SEND_EXISTING_ENQUIRY_FAILURE =
  "@requirements/enquiry/existing/send-failure";

export const SEND_REPEAT_FIXED_RFQ_SUCCESS =
  "@requirements/repeat-fixed/send-success";
export const SEND_REPEAT_FIXED_RFQ_FAILURE =
  "@requirements/repeat-fixed/send-failure";

export const EDIT_REPEAT_FIXED_ORDER = "@repeat-fixed-order/edit";
export const EDIT_REPEAT_FIXED_ORDER_SUCCESS =
  "@repeat-fixed-order/edit/success";
export const EDIT_REPEAT_FIXED_ORDER_FAILURE =
  "@repeat-fixed-order/edit/failure";

export const FETCH_REPEAT_FIXED_RFQ_SUCCESS =
  "@requirements/repeat-fixed/fetch ";

export const FETCH_REPEAT_FIXED_RFQ_FAILURE =
  "@requirements/repeat-fixed/fetch/failure";

export const FETCH_SEARCH_RFQS_SUCCESS = "@requirements/search/rfqs/success";
export const FETCH_SEARCH_RFQS_FAILURE = "@requirements/search/rfqs/failure";

export const FETCH_UPDATED_RFQS_SUCCESS = "@requirements/updated/rfqs/success";

export const FETCH_SEARCH_REPEAT_FIXED_RFQS_SUCCESS =
  "@requirements/search/repeat-fixed/order/success";
export const FETCH_SEARCH_REPEAT_FIXED_RFQS_FAILURE =
  "@requirements/search/repeat-fixed/order/failure";

export const DELETE_REPEAT_FIXED_ORDER_SUCCESS =
  "@ordered/delete/order/success";
export const DELETE_REPEAT_FIXED_ORDER_FAILURE =
  "@ordered/delete/order/failure";

export function fetchRequirements(userId, pageNumber, filterDayValue) {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUIREMENTS_FETCH });
      const requirements = await requirementsService.fetchRequirements(
        userId,
        pageNumber,
        filterDayValue
      );

      // console.log("fetchRequirements()(after):" + JSON.stringify(requirements));
      dispatch({
        type: REQUIREMENTS_FETCH_SUCCESS,
        payload: {
          requirements,
        },
      });
    } catch (error) {
      dispatch({ type: REQUIREMENTS_FETCH_FAILURE });
      throw error;
    }
  };
}

export function resendRequirements(
  userId,
  rfqId,
  supplierId,
  additionalNote,
  currentPage
) {
  return async (dispatch) => {
    try {
      // dispatch({ type: REQUIREMENTS_FETCH });
      const requirements = await requirementsService.resendRequirements(
        userId,
        rfqId,
        supplierId,
        additionalNote,
        currentPage
      );

      // if (requirements) {
      //   dispatch(fetchRequirement());
      // }

      // console.log("fetchRequirements()(after):" + JSON.stringify(requirements));
      dispatch({
        type: REQUIREMENTS_FETCH_SUCCESS_RESEND,
        payload: {
          requirements,
        },
      });
    } catch (error) {
      // dispatch({ type: REQUIREMENTS_FETCH_FAILURE });
      throw error;
    }
  };
}

export function addVendorToExistingRfq(
  userId,
  rfqId,
  supplierList,
  currentPage
) {
  return async (dispatch) => {
    try {
      // dispatch({ type: REQUIREMENTS_FETCH });
      const requirements = await requirementsService.addVendorToExistingRfq(
        userId,
        rfqId,
        supplierList,
        currentPage
      );

      // if (requirements) {
      //   dispatch(fetchRequirement());
      // }

      dispatch({
        type: FETCH_UPDATED_RFQS_SUCCESS,
        payload: {
          requirements,
        },
      });
    } catch (error) {
      // dispatch({ type: REQUIREMENTS_FETCH_FAILURE });
      throw error;
    }
  };
}

export function addRequirements(supplierId, values, userId) {
  return async (dispatch) => {
    try {
      const newReqInfo = new NewAddReqInfo(supplierId, values);

      dispatch({
        type: ADD_REQ_FETCH,
        payload: {
          newReqInfo,
        },
      });
      // console.log("addReqAction.addRequirements:", newReqInfo);

      const resp = await requirementsService.addRequirements(newReqInfo);

      // console.log("addReqAction.addRequirements Resp:", resp);

      dispatch({
        type: ADD_REQ_FETCH_SUCCESS,
        payload: {
          resp,
        },
      });
      await dispatch(fetchRequirements(userId));

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("RegisterAction.registerUserProfile: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: ADD_REQ_FETCH_FAILURE });
      throw error;
    }
  };
}

export function updateRequirements(supplierId, rfqId, values, mobile) {
  return async (dispatch) => {
    try {
      const newReqInfo = new ReqInfo(supplierId, rfqId, values);

      dispatch({
        type: ADD_REQ_FETCH,
        payload: {
          newReqInfo,
        },
      });
      // console.log("addReqAction.updateRequirements:", newReqInfo);

      const resp = await requirementsService.updateRequirements(newReqInfo);

      // console.log("addReqAction.updateRequirements Resp:", resp);

      dispatch({
        type: ADD_REQ_FETCH_SUCCESS,
        payload: {
          resp,
        },
      });
      // await dispatch(fetchRequirements(mobile));
      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("RegisterAction.updateRequirements: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: ADD_REQ_FETCH_FAILURE });
      throw error;
    }
  };
}

export function deleteRequirement(supplierId, rfqId, mobile) {
  return async (dispatch) => {
    try {
      const rfqInfo = new RfqDeleteInfo(supplierId, rfqId);

      dispatch({
        type: RFQ_DELETE,
        payload: {
          rfqInfo,
        },
      });
      // console.log("addReqAction.deleteRequirement:", rfqInfo);

      const requirements = await requirementsService.deleteRequirement(rfqInfo);

      // console.log("addReqAction.deleteRequirement Resp:", requirements);
      dispatch({
        type: RFQ_DELETE_SUCCESS,
        payload: {
          requirements,
        },
      });
      // await dispatch(fetchRequirements(mobile));
    } catch (error) {
      // console.log("RegisterAction.deleteRequirement: Error", error);
      dispatch({ type: RFQ_DELETE_FAILURE });
      throw error;
    }
  };
}

export function closeRequirement(supplierId, rfqId, mobile) {
  return async (dispatch) => {
    try {
      const rfqInfo = new RfqDeleteInfo(supplierId, rfqId);

      dispatch({
        type: RFQ_CLOSE,
        payload: {
          rfqInfo,
        },
      });
      // console.log("addReqAction.closeRequirement:", rfqInfo);

      const requirements = await requirementsService.closeRequirement(rfqInfo);

      // console.log("addReqAction.closeRequirement Resp:", requirements);
      dispatch({
        type: RFQ_CLOSE_SUCCESS,
        payload: {
          requirements,
        },
      });
      // await dispatch(fetchRequirements(mobile));
    } catch (error) {
      // console.log("RegisterAction.closeRequirement: Error", error);
      dispatch({ type: RFQ_CLOSE_FAILURE });
      throw error;
    }
  };
}

export function fetchRequirement(supplierId, rfqId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: RFQ_VIEW,
        payload: {
          supplierId,
          rfqId,
        },
      });
      // console.log("addReqAction.deleteRequirement:", rfqId);

      const requirement = await requirementsService.fetchRequirement(
        supplierId,
        rfqId
      );

      // console.log("addReqAction.deleteRequirement Resp:", requirement);

      dispatch({
        type: RFQ_VIEW_SUCCESS,
        payload: {
          requirement,
        },
      });
    } catch (error) {
      // console.log("RegisterAction.deleteRequirement: Error", error);
      dispatch({ type: RFQ_VIEW_FAILURE });
      throw error;
    }
  };
}

export function sendNewRFQTrigger(
  // supplierId,
  // supplierName,
  // supplierEmail,
  // supplierMobile
  data,
  businessOwnerData
) {
  return async (dispatch) => {
    dispatch({
      type: SEND_NEW_RFQ_TRIGGER,
      // payload: { supplierId, supplierName, supplierEmail, supplierMobile },
      payload: data,
      businessOwnerData,
    });
  };
}
// /////////////////////////////////////////////////////////////////////////////////
export function sendMultipleRFQTrigger(multipleBusinesses) {
  return async (dispatch) => {
    dispatch({
      type: SEND_MULTIPLE_RFQ_TRIGGER,
      payload: multipleBusinesses,
    });
  };
}
/////////////////////////////////////////////////////////////////////////////////////////
export function sendRFQTrigger(
  // supplierId,
  // supplierName,
  // supplierEmail,
  // supplierMobile,
  // rfqId
  enquiry
) {
  return async (dispatch) => {
    // console.log(
    //   "sendRFQTrigger() ",
    //   supplierId,
    //   supplierName,
    //   supplierEmail,
    //   supplierMobile,
    //   rfqId
    // );
    dispatch({
      type: SEND_RFQ_TRIGGER,
      // payload: {
      //   supplierId,
      //   supplierName,
      //   supplierEmail,
      //   supplierMobile,
      //   rfqId,
      // },
      payload: enquiry,
    });
  };
}

export function sendNewEnquiry(
  supplierId,
  values,
  enquiry,
  userCompany,
  userName,
  selectedFilesBase,
  newCcEmails,
  mobileNumber,
  subscriptionType
) {
  return async (dispatch) => {
    try {
      const newEnquiry = new NewEnquiryInfo(
        supplierId,
        values,
        enquiry,
        userCompany,
        userName,
        selectedFilesBase,
        newCcEmails,
        subscriptionType
      );
      // console.log("this is a rfq send data", newEnquiry);
      dispatch({
        type: SEND_NEW_ENQUIRY,
        payload: {
          newEnquiry,
        },
      });

      const resp = await requirementsService.sendNewEnquiry(newEnquiry);

      dispatch({
        type: SEND_NEW_ENQUIRY_SUCCESS,
        payload: {
          resp,
        },
      });
      dispatch(fetchRequirements(mobileNumber)); // this dispatch use for auto refresh data and show new enq in refq collections

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (error) {
      // console.log("RegisterAction.updateRequirements: Error =====", error);

      dispatch({ type: SEND_NEW_ENQUIRY_FAILURE });
      throw error;
    }
  };
}

export function sendExistingEnquiry(supplierId, rfqId, enquiry) {
  return async (dispatch) => {
    try {
      // console.log("sendExistingEnquiry(): ", supplierId, rfqId, enquiry);
      const newEnquiry = new ExistingEnquiryInfo(supplierId, rfqId, enquiry);

      dispatch({
        type: SEND_EXISTING_ENQUIRY,
        payload: {
          newEnquiry,
        },
      });
      // console.log("addReqAction.sendExistingEnquiry:", newEnquiry);

      const resp = await requirementsService.sendExistingEnquiry(newEnquiry);

      // console.log("addReqAction.sendExistingEnquiry Resp:", resp);
      dispatch({
        type: SEND_EXISTING_ENQUIRY_SUCCESS,
        payload: {
          resp,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (error) {
      // console.log("RegisterAction.updateRequirements: Error", error);

      dispatch({ type: SEND_EXISTING_ENQUIRY_FAILURE });
      throw error;
    }
  };
}

export function sendRepeatFixedRfq(sendRfq) {
  return async (dispatch) => {
    try {
      const newRfq = await requirementsService.sendRepeatFixedRfq(sendRfq);
      dispatch({
        type: SEND_REPEAT_FIXED_RFQ_SUCCESS,
        payload: {
          newRfq,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (error) {
      dispatch({ type: SEND_REPEAT_FIXED_RFQ_FAILURE });
      throw error;
    }
  };
}

export function editRepeatFixedRfq(payload) {
  return async (dispatch) => {
    try {
      const order = await requirementsService.editRepeatFixedRfq(payload);
      dispatch({
        type: EDIT_REPEAT_FIXED_ORDER_SUCCESS,
        payload: {
          order,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (error) {
      dispatch({ type: EDIT_REPEAT_FIXED_ORDER_FAILURE });
      throw error;
    }
  };
}

export function deleteRepeatFixedRfq(payload) {
  return async (dispatch) => {
    try {
      const order = await requirementsService.deleteRepeatFixedRfq(payload);
      dispatch({
        type: DELETE_REPEAT_FIXED_ORDER_SUCCESS,
        payload: { order },
      });
      // TODO SP Fetch Rest of the information for the user to initialize the application
      // dispatch(fetchRepeatFixedRfq(userId, pageNumber));
    } catch (error) {
      dispatch({ type: DELETE_REPEAT_FIXED_ORDER_FAILURE });
      throw error;
    }
  };
}

export function fetchRepeatFixedRfq(userIds, pageNumber) {
  return async (dispatch) => {
    try {
      const repeatFixedRfq = await requirementsService.fetchRepeatFixedRfq(
        userIds,
        pageNumber
      );
      // console.log("this is a payload of repeated ===", repeatFixedRfq);
      dispatch({
        type: FETCH_REPEAT_FIXED_RFQ_SUCCESS,
        payload: {
          repeatFixedRfq,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_REPEAT_FIXED_RFQ_FAILURE });
      throw error;
    }
  };
}

export function searchRepeatFixedRfq(userId, searchKey, pageNumber) {
  return async (dispatch) => {
    try {
      const repeatFixedRfq = await requirementsService.searchRepeatFixedRfq(
        userId,
        searchKey,
        pageNumber
      );
      // console.log("this is a payload of repeated ===", repeatFixedRfq);
      dispatch({
        type: FETCH_SEARCH_REPEAT_FIXED_RFQS_SUCCESS,
        payload: {
          repeatFixedRfq,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_SEARCH_REPEAT_FIXED_RFQS_FAILURE });
      throw error;
    }
  };
}

export function searchRfqs(userId, searchKey, pageNumber) {
  return async (dispatch) => {
    try {
      const searchResult = await requirementsService.searchRfqs(
        userId,
        searchKey,
        pageNumber
      );
      // console.log("this is a payload of repeated ===", repeatFixedRfq);
      dispatch({
        type: FETCH_SEARCH_RFQS_SUCCESS,
        payload: {
          searchResult,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_SEARCH_RFQS_FAILURE });
      throw error;
    }
  };
}
