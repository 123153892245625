import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { ConfirmDialog } from "components";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Stack,
  Link,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import { removeFavourites } from "actions/favouritesAction";
import { headCells } from "./utils";

// ############renderCellExpand code start from here (renderCellExpand use foe hover effect to show full data)######################

import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.string,
};

// ############renderCellExpand code end from here#####################

function FavouritesList({
  favourites,
  businessName,
  profile,
  setSelectedRow,
  setSelectedRowDataId,
  loading,
}) {
  const [modal, setModal] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  useEffect(() => {}, [favourites]);

  // console.log("FavouritesList: ", favourites);


  const deleteFev = (supplierId, businessName) => {
    try {
      dispatch(removeFavourites(businessName));
    } catch (error) {}
  };

  // send enq code start froam here
  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleSelectionModelChange = (newSelection) => {
    setSelectedRow(newSelection.length); // get the value of how many row or check box selected
    setSelectedRowDataId(newSelection); // newSelection give the suplierId slected company
    setSelectionModel(newSelection);
  };
  // send enq code end  here


  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Box sx={{ height: 600 }}>
        {loading ? (
          <Grid style={{display:"flex",justifyContent:"center",marginTop:"100px"}}>
            <CircularProgress sx={{color:"#FD6600"}} />
          </Grid>
        ) : favourites.length === 0 ? (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: "100px",
            }}
          >
            <Typography>You haven't shortlisted any Vendor</Typography>
          </Grid>
        ) : (
          <DataGrid
            // loading={favourites.length === 0?false:""}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            rows={favourites}
            columns={headCells(
              true,
              deleteFev,
              modal,
              setModal,
              businessName,
              renderCellExpand
            )}
            getRowId={(row) => row.supplierId}
            pageSize={25}
            disableSelectionOnClick
          />
        )}
      </Box>
    </Stack>
  );
}

FavouritesList.propTypes = {
  favourites: PropTypes.array,
};

export default FavouritesList;
