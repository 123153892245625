import {
  DELETE_REPEAT_FIXED_ORDER_FAILURE,
  DELETE_REPEAT_FIXED_ORDER_SUCCESS,
  EDIT_REPEAT_FIXED_ORDER_SUCCESS,
  FETCH_REPEAT_FIXED_RFQ_FAILURE,
  FETCH_REPEAT_FIXED_RFQ_SUCCESS,
  FETCH_SEARCH_REPEAT_FIXED_RFQS_SUCCESS,
} from "actions/requirementsAction";
import produce from "immer";

const initialState = {
  count: null,
  currentPage: null,
  requirements: [],
  totalPages: null,
  error: {},
};
const repeatRfqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPEAT_FIXED_RFQ_SUCCESS: {
      const data = action?.payload?.repeatFixedRfq;
      return produce(state, (draft) => {
        draft.count = data?.totalRepeatRfqs;
        draft.currentPage = data?.currentPage;
        draft.requirements = data?.rfqs;
        draft.totalPages = data?.totalPages;
      });
    }
    case FETCH_REPEAT_FIXED_RFQ_FAILURE: {
      return produce(state, (draft) => {
        draft.count = 0;
        draft.currentPage = 0;
        draft.requirements = [];
        draft.totalPages = 0;
      });
    }
    case EDIT_REPEAT_FIXED_ORDER_SUCCESS: {
      console.log(
        "this is EDIT_REPEAT_FIXED_ORDER_SUCCESS",
        state?.requirements
      );
      return produce(state, (draft) => {
        draft.requirements = state?.requirements.map((obj) =>
          action?.payload?.order?.repeatRfqId === obj?.repeatRfqId
            ? action?.payload?.order
            : obj
        );
      });
    }
    case FETCH_SEARCH_REPEAT_FIXED_RFQS_SUCCESS:
      {
        // console.log("this is a data of action payload", action?.payload);
        const { repeatFixedRfq } = action?.payload;
        return produce(state, (draft) => {
          draft.count = repeatFixedRfq?.totalCount;
          draft.currentPage = repeatFixedRfq?.currentPage;
          draft.requirements = repeatFixedRfq?.repeatRfqs;
          draft.totalPages = repeatFixedRfq?.totalPages;
        });
      }
      break;
    case DELETE_REPEAT_FIXED_ORDER_SUCCESS: {
      const { order } = action?.payload;
      return produce(state, (draft) => {
        draft.currentPage = order?.currentPage;
        draft.requirements = order?.rfqs;
        draft.totalPages = order?.totalPages;
      });
    }
    case DELETE_REPEAT_FIXED_ORDER_FAILURE:
      {
        return produce(state, (draft) => {
          draft.error = { orderError: true };
        });
      }
      break;
    default: {
      return state;
    }
  }
};

export default repeatRfqReducer;
