import React from "react";
import { Rating, RatingStar, Tooltip } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "utils/axios";
import { Helmet } from "react-helmet";
const HeroSection = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleBasicClick = () => {
    navigate("/register/business");
    axios.post("/api/auth/demo/request", {
      userId: userProfile?.userId,
      name: userProfile?.name,
      number: userProfile?.mobile,
      interestShown: "Subscription",
      showingIntrestIn: "BASIC",
    });
  };
  const handlePremiumClick = () => {
    navigate("/register/business");
    axios.post("/api/auth/demo/request", {
      userId: userProfile?.userId,
      name: userProfile?.name,
      number: userProfile?.mobile,
      interestShown: "Subscription",
      showingIntrestIn: "PREMIUM",
    });
  };
  return (
    <div>
      <Helmet>
        <title>Subscribe Plan</title>
      </Helmet>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-7 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Apna construction business badhana expensive nahi hona chahiye
            </h2>
            <p className="font-light text-center  text-xl text-gray-500 dark:text-gray-400">
              Increase your exposure to thousands of construction companies{" "}
            </p>
            <p className="mb-5 font-light text-center text-xl text-gray-500 dark:text-gray-400">
              {" "}
              looking to find local vendors just like you
            </p>
          </div>

          <div className="flex justify-center items-center">
            <div className="lg:grid gap-4 lg:grid-cols-[1fr,repeat(1,minmax(0,1fr)),0fr] space-y-6 lg:space-y-0">
              <div
                style={{ width: "338px", height: "810px" }}
                className="flex flex-col p-6  border-solid border-black mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              >
                <h3 className=" text-xl font-semibold">Basic</h3>
                <div className="flex justify-center items-baseline mt-3 mb-14">
                  <span className="mr-2 text-5xl font-extrabold">Free</span>
                </div>

                <a
                  href="#"
                  onClick={handleBasicClick}
                  class=" mb-6 text-white  bg-black focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-base px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
                  style={{ transition: "box-shadow 0.3s", boxShadow: "none" }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow =
                      "0px 0px 10px 0px rgba(0,0,0,0.7)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = "none";
                  }}
                >
                  Select plan
                </a>

                <ul role="list" class="mb-8 space-y-3 text-left">
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span> Business Profile</span>
                    {/* <img style={{ width: "15px", height: "15px", marginTop: "3px" }}
                                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}></img> */}
                  </li>

                  <li className="flex items-center space-x-3 ">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span> Basic URL </span>
                    {/* <img style={{ width: "15px", height: "15px", marginTop: "3px" }}
                                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}></img> */}
                  </li>
                  <li className="flex items-center space-x-3 ">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span> Support</span>
                    {/* <img style={{ width: "15px", height: "15px", marginTop: "3px" }}
                                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}></img> */}
                  </li>
                  <li className="flex items-center space-x-3 ">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span> Basic Dashboard </span>
                    {/* <img style={{ width: "15px", height: "15px", marginTop: "3px" }}
                                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}></img> */}
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span> Basic Listing </span>
                    {/* <img style={{ width: "15px", height: "15px", marginTop: "3px" }}
                                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}></img> */}
                  </li>

                  <li className="flex items-center space-x-3 line-through">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Unlimited Leads </span>
                  </li>
                  <li className="flex items-center space-x-3 line-through">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Verified Work Orders </span>
                  </li>
                  <li className="flex items-center space-x-3 line-through">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Ad free Profile</span>
                  </li>
                  <li className="flex items-center space-x-3 line-through">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span> Contact Buyer</span>
                  </li>

                  <li className="flex items-center space-x-3 line-through">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>References in Profile </span>
                  </li>
                  <li className="flex items-center space-x-3 line-through">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Endorse Other Vendors </span>
                  </li>
                  <li className="flex items-center space-x-3 line-through">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Open RFQ/Tenders </span>
                  </li>
                </ul>
              </div>

              <div
                style={{ width: "338px", height: "810px" }}
                className="flex flex-col p-6  border-solid mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-blue-700  shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              >
                <h3 className=" text-xl font-semibold">Standard</h3>
                <h3 className=" text-xl  text-gray-400 line-through">
                  ₹ 24,000{" "}
                </h3>
                <div className="flex flex-col justify-center  text-center  mt-1 ">
                  <span className="  mb-9 text-5xl font-extrabold text-center">
                    ₹ 12,000{" "}
                    <span className=" text-xs font-extrabold text-center">
                      {" "}
                      + GST{" "}
                    </span>
                    <span className="text-xs text-center dark:text-gray-400">
                      /Year
                    </span>
                  </span>

                  {/* <span className='text-gray-400 mb-3 mt-1'>₹1000 per month, paid annually</span> */}
                </div>

                <a
                  href="#"
                  onClick={handlePremiumClick}
                  class=" mb-6 text-white bg-black focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-base px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
                  style={{ transition: "box-shadow 0.3s", boxShadow: "none" }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow =
                      "0px 0px 10px 0px rgba(0,0,0,0.7)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = "none";
                  }}
                >
                  Select Plan
                </a>

                <ul role="list" class="mb-8 space-y-3 text-left">
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span> Business Profile</span>
                    <Tooltip
                      placement="bottom"
                      content=" A publicly shareable business profile which also acts as a website"
                      className="bg-gray-400  max-w-80 max-h-14"
                      arrow={false}
                    >
                      <img
                        style={{ width: "15px", height: "13px" }}
                        src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                      ></img>
                    </Tooltip>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span> Customized URL </span>
                    <Tooltip
                      placement="bottom"
                      content=" Premium members get a customized url to choose from : https://neevay.com/ <customized-name>"
                      className="bg-gray-400  max-w-80"
                      arrow={false}
                    >
                      <img
                        style={{ width: "15px", height: "13px" }}
                        src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                      ></img>
                    </Tooltip>
                  </li>
                  <li class="flex items-start space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <div className="flex flex-col">
                      <span> Support</span>
                      {/* <span> (Email/Call/WhatsApp) </span> */}
                    </div>
                    <Tooltip
                      placement="bottom"
                      content="Our team will support you right from creating an eye-catchy profile to any questions that you have about using the portal to getting business leads"
                      className="bg-gray-400  max-w-80 "
                      arrow={false}
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "13px",
                          marginTop: "5px",
                        }}
                        src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                      ></img>
                    </Tooltip>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span> Premium Dashboard </span>

                    <Tooltip
                      placement="bottom"
                      content="Dashboard offers an overview of the business activities from viewing leads to profile visitors, etc. giving an overall view of the business"
                      className="bg-gray-400  max-w-80 "
                      arrow={false}
                    >
                      <img
                        style={{ width: "15px", height: "13px" }}
                        src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                      ></img>
                    </Tooltip>
                  </li>
                  <li className="flex items-center  space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>

                    <span>Profile Boosting </span>

                    <Tooltip
                      placement="bottom"
                      content="Eligible profiles get a profile boost in the buyer search results. Qualifying activities include responding to RFQs on time, getting good ratings from Buyers, etc."
                      className="bg-gray-400  max-w-80 "
                      arrow={false}
                    >
                      <img
                        style={{ width: "15px", height: "13px" }}
                        src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                      ></img>
                    </Tooltip>
                  </li>
                  <li className="flex items-center  space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Unlimited Leads </span>
                    <Tooltip
                      placement="bottom"
                      content="Premium Users get access to unlimited leads each month. That means there is no cap."
                      className="bg-gray-400  max-w-80 "
                      arrow={false}
                    >
                      <img
                        style={{ width: "15px", height: "13px" }}
                        src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                      ></img>
                    </Tooltip>
                  </li>

                  <li className="flex items-center  space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span> Verified Work Orders</span>
                    <Tooltip
                      placement="bottom"
                      content="Premium vendors can have Neevay put verify their work orders from the buyers on their behalf and put the verified tag on their profile"
                      className="bg-gray-400  max-w-80 "
                      arrow={false}
                    >
                      <img
                        style={{ width: "15px", height: "13px" }}
                        src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                      ></img>
                    </Tooltip>
                  </li>
                  <li className="flex items-center  space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span> Ad Free Profile</span>
                    <Tooltip
                      placement="bottom"
                      content="Vendor profile will not feature any banner ads as compared to free basic profiles which will usually be ad-supported"
                      className="bg-gray-400  max-w-80 "
                      arrow={false}
                    >
                      <img
                        style={{ width: "15px", height: "13px" }}
                        src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                      ></img>
                    </Tooltip>
                  </li>
                  <li className="flex items-start space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>
                      Contact Buyer
                      <div className="flex gap-4">
                        <span style={{ fontSize: "14px", color: "#00B594" }}>
                          {" "}
                          (Coming Soon){" "}
                        </span>

                        <Tooltip
                          placement="bottom"
                          content="Vendors can now directly contact the buyers on the Neevay platform and invite them to work together."
                          className="bg-gray-400  max-w-80 "
                          arrow={false}
                        >
                          <img
                            style={{ width: "15px", height: "13px" }}
                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                          ></img>
                        </Tooltip>
                      </div>
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>
                      References in Profile
                      <div className="flex gap-4">
                        <span style={{ fontSize: "14px", color: "#00B594" }}>
                          {" "}
                          (Coming Soon){" "}
                        </span>

                        <Tooltip
                          placement="bottom"
                          content="  Premium vendors become eligible to receive Buyer references. Also, vendors can request the buyers to give them ratings on the Neevay platform. Over time positive ratings builds buyer trust"
                          className="bg-gray-400  max-w-80 "
                          arrow={false}
                        >
                          <img
                            style={{ width: "15px", height: "13px" }}
                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                          ></img>
                        </Tooltip>
                      </div>
                    </span>
                  </li>

                  <li className="flex items-start space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>

                    <span>
                      {" "}
                      Endorse Other Vendors
                      <div className="flex gap-4">
                        <span style={{ fontSize: "14px", color: "#00B594" }}>
                          {" "}
                          (Coming Soon){" "}
                        </span>

                        <Tooltip
                          placement="bottom"
                          content=" Premium vendors can now drive collective growth. Endorse complimentary businesses on your vendor profile and get featured on their profile. This increases your overall visibility to buyers which translates to more business."
                          className="bg-gray-400  max-w-80 "
                          arrow={false}
                        >
                          <img
                            style={{ width: "15px", height: "13px" }}
                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                          ></img>
                        </Tooltip>
                      </div>
                    </span>
                  </li>
                  <li className="flex  items-start space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-black "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>
                      {" "}
                      Open RFQ/Tenders
                      <div className="flex gap-4">
                        <span style={{ fontSize: "14px", color: "#00B594" }}>
                          {" "}
                          (Coming Soon){" "}
                        </span>

                        <Tooltip
                          placement="bottom"
                          content="Be the first to know about the open tenders in your area. Neevay works with different buyers who post open RFQs/Tenders."
                          className="bg-gray-400  max-w-80 "
                          arrow={false}
                        >
                          <img
                            style={{ width: "15px", height: "13px" }}
                            src={`${BASE_URL}/assets/business/web-pricingpage-question-mark.svg`}
                          ></img>
                        </Tooltip>
                      </div>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
