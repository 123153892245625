import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";

import { headCells } from "./utils";
import { sendAckLead } from "actions/leadsAction";

import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { makeStyles } from "@mui/styles";
import { UserProfile } from "interface/userProfileIf";
import ThankYou from "views/Home/components/BookDemoForm/ThankYou";

const useStyles = makeStyles({
  dataGrid: {
    "& .MuiDataGrid-cell": {
      borderRight: "2px solid rgba(0, 0, 0, 0.1)", // Add vertical border to cells
    },
    "& .MuiDataGrid-colCellTitleContainer": {
      borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Add vertical border to header cells
    },
  },
});

// ############renderCellExpand code start from here (renderCellExpand use foe hover effect to show full data)######################

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.string,
};

// ############renderCellExpand code end from here#####################

function LeadsList({ title, leads, supplierId, loading }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [modal, setModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newLeads, setNewLeads] = useState(leads);
  const [newLeadsFixedData, setNewLeadsFixedData] = useState(leads);
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  // console.log("####################", newLeads);
  // console.log("%%%%%%%%%%%%%%%%%%%%%%%%", newLeadsFixedData);
  useEffect(() => {
    // console.log("this nis a leads", leads);
    leads = leads.map((data, index) => {
      return {
        ...data,
        srNo: index + 1,
        leadStatus:
          data?.suppliers[0]?.status == "ACKNOWLEDGED" ? "Read" : "Unread",
        createDate: data.createdAt, // this code make for when the createdate is not avilable in old rfq then use updateAt date
      };
    });
    leads = leads.map((data) => {
      for (let x in data)
        if (data[x] === null || data[x] === "") data[x] = "NA";
      return data;
    });
    leads.sort((a, b) => b.rfqId - a.rfqId);
    setNewLeads(leads);
    setNewLeadsFixedData(leads);
  }, [leads]);

  const handleClickLeadsViewData = (id) => {
    navigate(`/leads/${id}`);
  };

  const sendRFQAck = (supplierId, rfqId) => {
    // dispatch(sendAckLead(supplierId, rfqId));
  };
  const classes = useStyles();

  ///////////////////////////////////////////// FILTER AND SORT LOGIC HERE START
  const [sortOption, setSortOption] = useState("name");
  const [filterType, setFilterType] = useState("");
  const [last7DaysClick, setLast7DaysClick] = useState(false);
  const [last3oDaysClick, setLast3oDaysClick] = useState(false);
  const [allDaysClick, setAllDaysClick] = useState(true);

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const location = useLocation();

  const [checked, setChecked] = useState(
    location.pathname === "/leads/pending_leads"
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      navigate("/leads/pending_leads");
    } else {
      navigate("/leads");
    }
  };

  const handleFilterOptionChange = (event) => {
    // console.log("this is event", event.target.value);
    setSortOption(event.target.value);
    let sortedArray = [...newLeads];
    switch (event.target.value) {
      case "null": {
        sortedArray.sort((a, b) => a.srNo - b.srNo);
        break;
      }
      case "name": {
        sortedArray.sort((a, b) => {
          if (a.userBusinessName && b.userBusinessName) {
            return a.userBusinessName.localeCompare(b.userBusinessName);
          }
          return 0;
        });
        break;
      }
      case "oldest": {
        sortedArray.sort(
          (a, b) => new Date(a.createDate) - new Date(b.createDate)
        );
        break;
      }
      case "newest": {
        sortedArray.sort(
          (a, b) => new Date(b.createDate) - new Date(a.createDate)
        );
        break;
      }
      case "viewed": {
        sortedArray.sort((a, b) => {
          if (a.leadStatus && b.leadStatus) {
            return b.leadStatus.localeCompare(a.leadStatus);
          }
          return 0;
        });
        break;
        break;
      }
      case "notView": {
        sortedArray.sort((a, b) => {
          if (a.leadStatus && b.leadStatus) {
            return a.leadStatus.localeCompare(b.leadStatus);
          }
          return 0;
        });
        break;
        break;
      }
    }
    setNewLeads((preVal) => sortedArray);
  };

  const handleFilterTypeChange = (event) => {
    handleFilterDataAllDays();

    setFilterType(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    const searchKey = event.target.value;

    const filteredArray = newLeadsFixedData.filter((obj) => {
      if (filterType === "name") {
        const businessName = obj?.userBusinessName || ""; // Default value: empty string
        return businessName.toLowerCase().includes(searchKey.toLowerCase());
      } else if (filterType === "location") {
        const location = obj?.location || ""; // Default value: empty string
        return location.toLowerCase().includes(searchKey.toLowerCase());
      }
      return true;
    });
    setNewLeads(filteredArray);
  };

  const handleFilterDataLastSevenDays = () => {
    setFilterType("");
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const filteredArray = newLeadsFixedData.filter((obj) => {
      const objDate = new Date(obj.createDate);
      return objDate >= sevenDaysAgo;
    });
    setNewLeads(filteredArray);
    setLast7DaysClick(true);
    setLast3oDaysClick(false);
    setAllDaysClick(false);
  };
  const handleFilterDataLastMonths = () => {
    setFilterType("");
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const filteredArray = newLeadsFixedData.filter((obj) => {
      const objDate = new Date(obj.createDate);
      return objDate >= sevenDaysAgo;
    });
    setNewLeads(filteredArray);
    setLast7DaysClick(false);
    setLast3oDaysClick(true);
    setAllDaysClick(false);
  };
  const handleFilterDataAllDays = () => {
    setNewLeads(newLeadsFixedData);
    setLast7DaysClick(false);
    setLast3oDaysClick(false);
    setAllDaysClick(true);
  };
  ///////////////////////////////////////////// FILTER AND SORT LOGIC END
  return (
    <Card mt={2} style={{ height: "100%" }}>
      {/* <CardHeader title={title} />
      <Divider sx={{ mt: 1 }} /> */}
      <Grid
        xs={12}
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Grid marginLeft={"20px"}>
          <FormControlLabel
            control={<Checkbox 
              sx={{
                // color: 'black', // Change color of Checkbox
                '&.Mui-checked': {
                  color: 'black', // Apply the color when checked
                },
              }}
              checked={checked} onChange={handleChange} />}
            label={"Show All Unread Leads"}
          />
        </Grid>

        <Grid
          display="flex"
          mb="12px"
          flexWrap={"wrap"}
          justifyContent={"space-between"}
        >
          <Grid display="flex" gap="16px" mb="12px" flexWrap={"wrap"}>
            {/* <Grid
            height="25px"
            display="flex"
            gap="8px"
            border="1px solid rgba(217, 217, 217, 1)"
            sx={{ backgroundColor: "rgba(245, 245, 246, 1)", paddingLeft: "6px" }}
            borderRadius="5px">
            <Typography >Sort By</Typography>
            <FormControl >
              <Select style={{ height: '25px', border: "none" }} value={sortOption} onChange={handleFilterOptionChange}>
                <MenuItem value="null">Null</MenuItem>
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="oldest">Oldest</MenuItem>
                <MenuItem value="newest">Newest</MenuItem>
                <MenuItem value="viewed">Viewed</MenuItem>
                <MenuItem value="notView">Not view</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            display="flex"
            flexWrap="wrap"
            gap="8px"
            maxWidth="160px"
            border="1px solid rgba(217, 217, 217, 1)"
            sx={{ backgroundColor: "rgba(245, 245, 246, 1)", paddingLeft: "6px" }}
            borderRadius="5px"  >
            <Typography>Filters</Typography>
            <FormControl >
              <Select style={{ height: '25px', widows: "auto" }} value={filterType} onChange={handleFilterTypeChange}>
                <MenuItem value="">Null</MenuItem>
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="location">Location</MenuItem>
              </Select>
            </FormControl>
            {filterType && (
              <Grid sx={{ backgroundColor: "white", }}>
                <TextField
                  InputProps={{ style: { height: '25px', } }}
                  placeholder={`Search ${filterType}`}
                  onChange={handleSearchQueryChange}
                />
              </Grid>
            )}
          </Grid> */}
          </Grid>

          <Grid display={"flex"} gap="8px" flexWrap={"wrap"} marginX="20px">
            <Button
              color="secondary"
              variant="contained"
              sx={{
                padding: "2px 6px",
                borderRadius: "5px",
                cursor: "pointer",
                backgroundColor: "#fff",
                border: "1px solid #ABB3C0",
                boxShadow: "0px 0.5px 0px #ABB3C0",
                color: "black",
                "&:hover": {
                  backgroundColor: "#D9D9D9",
                  transition: "background-color 0.3s ease-in",
                },
              }}
              onClick={handleFilterDataLastSevenDays}
            >
              Last 7 Days
            </Button>

            <Button
              color="secondary"
              variant="contained"
              sx={{
                padding: "2px 6px",
                borderRadius: "5px",
                cursor: "pointer",
                backgroundColor: "#fff",
                border: "1px solid #ABB3C0",
                boxShadow: "0px 0.5px 0px #ABB3C0",
                color: "black",
                "&:hover": {
                  backgroundColor: "#D9D9D9",
                  transition: "background-color 0.3s ease-in",
                },
              }}
              onClick={handleFilterDataLastMonths}
            >
              Last 30 Days
            </Button>

            <Button
              color="secondary"
              variant="contained"
              sx={{
                padding: "2px 6px",
                borderRadius: "5px",
                cursor: "pointer",
                backgroundColor: "#fff",
                border: "1px solid #ABB3C0",
                boxShadow: "0px 0.5px 0px #ABB3C0",
                color: "black",
                "&:hover": {
                  backgroundColor: "#D9D9D9",
                  transition: "background-color 0.3s ease-in",
                },
              }}
              onClick={handleFilterDataAllDays}
            >
              All Requirements
            </Button>
          </Grid>
          {/* :
            <Grid>
               <FormControl >
                <Select style={{ height: '25px', widows: "auto" }} value={filterType} onChange={handleFilterTypeChange}>
                  <MenuItem value="">Null</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="location">Location</MenuItem>
                </Select>
              </FormControl> 
          </Grid>} */}
        </Grid>

        <Grid
          xs
          sx={{
            height: 700,
            // '& .blue': {
            //   color: '#3963AB',
            // },
            // '& .green': {
            //   color: 'green',
            // },
            // '& .red': {
            //   color: "red"
            // }
          }}
        >
          {loading ? (
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "100px",
              }}
            >
              < CircularProgress sx={{color:"#FD6600"}}/>
            </Grid>
          ) : newLeads.length === 0 ? (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: "70px",
              }}
            >
              {last7DaysClick ? (
                <Typography>
                  You have'nt received any leads from last 7 days
                </Typography>
              ) : last3oDaysClick ? (
                <Typography>
                  You have'nt received any leads from last 30 days
                </Typography>
              ) : (
                <Typography>You Don't have any leads.</Typography>
              )}
              <Typography>
                {supplierId && (
                  <span>
                    {" "}
                    <Link href="/my-business" target="_blank" color={"#3963AB"} fontWeight={"600"}>
                      Update
                    </Link>{" "}
                  </span>
                )}{" "}
                your business to increase your business visibility.
              </Typography>
            </Grid>
          ) : (
            <DataGrid
              // loading={newLeads.length === 0 ? false : ""}

              className={classes.dataGrid}
              rows={newLeads}
              columns={headCells(
                true,
                sendRFQAck,
                modal,
                setModal,
                supplierId,
                renderCellExpand,
                handleClickLeadsViewData
              )}
              getRowId={(row) => row.rfqId}
              // getCellClassName={(params) => {
              //   if (params.field == "status")
              //     return params.row.status === "CLOSED" ? 'red' : params.row.status === "OPEN" ? "black" : "green" // if status is close then they show red color otherwise blue for open and green for done
              //   return " "
              // }}
              // pageSize={pageSize}
              // onPageSizeChange={(size) => setPageSize(size)}
              // rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
              rowHeight={32}
              disableSelectionOnClick
              disableColumnMenu
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

LeadsList.propTypes = {
  title: PropTypes.string,
  leads: PropTypes.array,
  supplierId: PropTypes.number,
};

export default LeadsList;
