import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

import Minimal from "layouts/Minimal";
import Logo from "components/Logo";
import SignInEmailForm from "./SignInEmailForm";

function SignInEmail() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  const mobile = auth.signinMobile;

  // console.log("SignInEmail: auth", auth, " mobile: ", mobile);

  // useEffect(() => {}, [mobile]);

  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      mt={10}
    >
      <Grid xs={12} paddingLeft={"13px"}>
        <Logo width="120px" />
      </Grid>
      <Grid xs={12} mt={4}>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          {" "}
          SIGN IN{" "}
        </Typography>
        <Typography variant={"subtitle2"} mt={2}>
          Login with Email & Password
        </Typography>
      </Grid>
      <Grid p={6}>
        <SignInEmailForm onSubmitSuccess={handleSubmitSuccess} />
        <Typography variant={"subtitle2"} mt={2}>
          Login with Mobile OTP{" "}
          <Link
            component={"a"}
            color={"primary"}
            // color={"#FD6600"}
            href={"/signin-mobile"}
            underline={"none"}
          >
            Mobile Login
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SignInEmail;
