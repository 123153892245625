import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from "date-fns";
import "./custom-datepicker.css"; // Add custom styles for the date picker

const DateRangePicker = ({
  title,
  handleApplyFilterDate,
  style = "bg-gray-800 text-white",
  setDateFilterName,
  startDate,
  endDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [focusedInput, setFocusedInput] = useState("dateStart");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("is between");
  const buttonRef = useRef(null);
  const dropDownRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const handleDateChange = (date) => {
    if (focusedInput === "dateStart") {
      setDateStart(date);
      if (["is", "is on or after"].includes(selectedOption)) {
        handleApplyFilterDate({
          startDate: formatNewDate(date),
          endDate:
            selectedOption === "is"
              ? formatNewDate(date)
              : formatNewDate(new Date()),
        });
        setIsOpen(false);
      } else {
        setFocusedInput("dateEnd");
        setTimeout(() => endDateRef?.current?.focus(), 0);
      }
    } else {
      setDateEnd(date);
      const endDateFormatted = formatNewDate(date);

      if (selectedOption === "is on or before") {
        handleApplyFilterDate({ startDate: "", endDate: endDateFormatted });
        setIsOpen(false);
      } else if (dateStart) {
        handleApplyFilterDate({
          startDate: formatNewDate(dateStart),
          endDate: endDateFormatted,
        });
        setIsOpen(false);
      }
      setFocusedInput("dateStart");
      setTimeout(() => startDateRef?.current?.focus(), 0);
    }
  };

  const formatDate = (date) => {
    return date ? format(date, "dd/MM/yyyy") : "";
  };
  const formatNewDate = (date) => {
    return date ? format(date, "yyyy-MM-dd") : "";
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      if (selectedOption === "is on or before") {
        setFocusedInput("dateEnd");
        setTimeout(() => endDateRef?.current?.focus(), 0);
      } else {
        setFocusedInput("dateStart");
        setTimeout(() => startDateRef?.current?.focus(), 0);
      }
    }
  };

  const convertDateFormat = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };
  const filterButtonName = () => {
    if (selectedOption === "is") {
      setDateFilterName(`Date : ${convertDateFormat(startDate)}`);
      return `Date : ${convertDateFormat(startDate)}`;
    }
    if (selectedOption === "is between") {
      setDateFilterName(`${convertDateFormat(startDate)} -> ${convertDateFormat(endDate)}`);
      return `Date : ${convertDateFormat(startDate)} -> ${convertDateFormat(endDate)}`;
    }
    if (selectedOption === "is on or after") {
      setDateFilterName(`On or after ${convertDateFormat(startDate)}`);
      return `Date : On or after ${convertDateFormat(startDate)}`;
    }
    if (selectedOption === "is on or before") {
      setDateFilterName(`On or before ${convertDateFormat(endDate)}`);
      return `Date : On or before ${convertDateFormat(endDate)}`;
    }
  };

  // const dateFilterClear = () => {
  //   setFilterApplied(false);
  //   setFocusedInput("dateStart");
  //   setIsOpen(false);
  //   setDateEnd(null);
  //   setDateStart(null);
  //   clearDateFilter();
  // };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (focusedInput === "dateStart" && startDateRef?.current) {
      startDateRef?.current?.focus();
    } else if (focusedInput === "dateEnd" && endDateRef?.current) {
      endDateRef?.current?.focus();
    }
  }, [focusedInput]);

  // useEffect(() => {
  //   if (clearFilter?.status) {
  //     setFocusedInput("dateStart");
  //     setSelectedOption("is between");
  //     setIsOpen(false);
  //   }
  // }, [clearFilter]);

  // useEffect(() => {
  //   setDateStart(startDate);
  //   setDateEnd(endDate);
  // }, [startDate, endDate]);

  filterButtonName();
  return (
    <div className="relative" ref={buttonRef}>
      <button
        onClick={handleButtonClick}
        className={`${style}  px-2 py-1 mb-2 border border-black flex w-32 text-xs justify-between items-center rounded-3xl`}
      >
        {/* {filterButtonName()} */}
        <svg
          className="w-4 h-4 transform"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"></path>
        </svg>
        Select Date
        <svg
          className="w-4 h-4 transform"
          style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div
          className="fixed inset-0 bg-slate-400 bg-opacity-55 z-30 flex justify-center items-center"
          onClick={() => setIsOpen(false)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="mt-2 p-4 bg-gray-800 text-white border border-gray-700 rounded-md shadow-lg z-10"
          >
            <div className="flex gap-2 items-center mb-2">
              <p>{title}</p>
              <div className="relative">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="px-2 py-1 bg-gray-700 text-white rounded-md flex items-center"
                >
                  {selectedOption}
                  <svg
                    className="ml-2 w-4 h-4 transform"
                    style={{
                      transform: dropdownOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <div ref={dropDownRef}>
                  {dropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-gray-800 border border-gray-700 rounded-md shadow-lg z-20">
                      {[
                        "is",
                        "is between",
                        "is on or after",
                        "is on or before",
                      ].map((option) => (
                        <button
                          key={option}
                          onClick={() => {
                            if (option === "is on or before") {
                              setFocusedInput("dateEnd");
                            } else {
                              setFocusedInput("dateStart");
                            }
                            setDateEnd(null);
                            setDateStart(null);
                            // clearDateFilter();
                            setSelectedOption(option);
                            setDropdownOpen(false);
                          }}
                          className="block w-full text-left px-4 py-2 text-white hover:bg-gray-700"
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-5 mb-2">
              {(selectedOption == "is between" ||
                selectedOption == "is" ||
                selectedOption == "is on or after") && (
                <div className="relative">
                  <input
                    type="text"
                    value={formatDate(dateStart)}
                    placeholder="Start Date"
                    readOnly
                    ref={startDateRef}
                    className="px-2 py-1 mr-2 bg-gray-900 text-white rounded-md pr-8" // Add padding to the right to avoid overlap with the button
                    onFocus={() => setFocusedInput("dateStart")}
                  />
                  {dateStart && (
                    <button
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-200"
                      onClick={() => setDateStart(null)}
                    >
                      x
                    </button>
                  )}
                </div>
              )}
              {(selectedOption == "is on or before" ||
                selectedOption == "is between") && (
                <div className="relative">
                  <input
                    type="text"
                    value={formatDate(dateEnd)}
                    placeholder="End Date"
                    readOnly
                    ref={endDateRef}
                    className="px-2 py-1 bg-gray-900 text-white rounded-md pr-8" // Add padding to the right to avoid overlap with the button
                    onFocus={() => setFocusedInput("dateEnd")}
                  />
                  {dateEnd && (
                    <button
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-200"
                      onClick={() => setDateEnd(null)}
                    >
                      x
                    </button>
                  )}
                </div>
              )}
            </div>
            <div>
              {focusedInput === "dateStart" && (
                <DatePicker
                  selected={dateStart}
                  onChange={handleDateChange}
                  selectsStart
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  inline
                  dateFormat="dd/MM/yyyy"
                  maxDate={dateEnd || new Date()} // Ensure dateStart is not greater than today
                />
              )}
              {focusedInput === "dateEnd" && (
                <div className="flex justify-end">
                  <DatePicker
                    selected={dateEnd}
                    onChange={handleDateChange}
                    selectsEnd
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    inline
                    dateFormat="dd/MM/yyyy"
                    minDate={dateStart} // Ensure dateEnd is not less than dateStart if dateStart is selected, otherwise not less than today
                    maxDate={new Date()} // Ensure dateEnd is not greater than today
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
