import React, { useEffect, useRef } from 'react';
import serviceData from '../servicedata.json';

const Servicesfortab = () => {
  const firstRow = serviceData.slice(0, 4);
  const secondRow = serviceData.slice(4, 8);

  const firstRowRef = useRef(null);
  const secondRowRef = useRef(null);

  useEffect(() => {
    // Scroll to the leftmost position when the component mounts
    if (firstRowRef.current) {
      firstRowRef.current.scrollLeft = 0;
    }
    if (secondRowRef.current) {
      secondRowRef.current.scrollLeft = 0;
    }
  }, []);

  const renderRow = (rowData, rowRef, isFirstRow) => (
    <div ref={rowRef} className={`flex overflow-x-auto scrollbar-hide w-full justify-evenly ${isFirstRow ? 'mb-8' : ''}`}>
      <div className="flex space-x-[20px] px-4">
        {rowData.map((serviceItem, index) => (
          <div key={index} className="h-[172px] w-[206px] bg-gradient-to-r from-[#0B0B1A] from-50% via-[#7E7E95] via-40% to-[#0B0B1A] to-10% rounded-lg overflow-hidden flex-shrink-0">
            <img src={serviceItem.src} alt={serviceItem.alt} className="h-[129px] w-[206px] object-cover rounded-t-lg" />
            <p className="text-white p-2 font-bold text-base">{serviceItem.title}</p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="ml-4 my-4">
      {renderRow(firstRow, firstRowRef, true)}
      {renderRow(secondRow, secondRowRef, false)}
    </div>
  );
};

export default Servicesfortab;
