import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const OurLeadership = () => {
  const theme = useTheme();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  // const isMd = useMediaQuery(theme.breakpoints.up(1024));
  // const isSm = useMediaQuery(theme.breakpoints.down(600));
  // const istab = useMediaQuery(theme.breakpoints.down(786));

  const isLg = useMediaQuery(theme.breakpoints.down(1024));
  const isMd = useMediaQuery(theme.breakpoints.down(786));
  const isSm = useMediaQuery(theme.breakpoints.down(600));

  // const cardSize = isLg ? (isSm ? "282px" : "454px") : "454px";
  const cardSize = (isLg || isSm) && !isMd ? "282px" : "454px";
  const containerSize = isSm
    ? "299px"
    : isMd
    ? "387px"
    : isLg
    ? "656px"
    : "1010px";
  const leadership = [
    {
      photo: `/assets/business/web-aboutus-desktop-hanish.webp`,
      // photo: `/assets/business/web-aboutus-desktop-rohit.webp`,
      position: "FOUNDER AND MANAGING DIRECTOR",
      name: "Hanish Raheja",
      desc: "After 15-years working with Fortune 200 Technology Companies in various leadership roles, Hanish Raheja’s Entrepreneurial spirit led him to establish Neevay with the vision of transforming the construction industry in India for the better. Upskilled his Business Acumen at prestigious Executive MBA of IIT Bombay and Washington University in St. Louis, and proud member of Beta Gamma Sigma, an International Business Honor Society of Top 5% of Business Schools across the world. Hanish is a problem solver, and believes in solving the complex problems in the most simplest way possible.Along with two like-minded friends and veterans of Construction Industry, Siddharth Moorthy and Kaizad Engineer, Hanish Raheja formed the nucleus of Neevay in 2022 bringing 100+ years of Construction Experience in the Company Board, that sought to consolidate and organise the fragmented construction industry.",
    },
    {
      photo: `/assets/business/web-aboutus-desktop-rohit.webp`,
      position: "VICE PRESIDENT OF SALES AND MARKETING",
      name: "Rohit Shelke",
      desc: "With over six years of experience as a seasoned entrepreneur, I have demonstrated my passion for innovation and leadership by playing a pivotal role in the development of Neevay, a groundbreaking construction platform that simplifies vendor discovery. By leveraging my expertise in agile methodologies, teamwork, and leadership, I have been instrumental in driving Neevay's growth and success, making it an indispensable tool for construction professionals seeking seamless vendor partnerships..",
    },
  ];
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        flexDirection: "column",
        maxWidth: containerSize,
        margin: "auto",
        // paddingX:isSm&&"10px"
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "start",
          // textAlign: "start",
        }}
      >
        <Typography
          sx={{
            fontFamily: "PP Telegraf",
            fontSize: isMd ? "32px" : "48px",
            fontWeight: 800,
            lineHeight: "62.2px",
            color: "black",
            marginBottom: "12px",
          }}
        >
          Our Team
        </Typography>
      </Grid>

      <Grid
        sx={
          {
            // display: "flex",
            // flexWrap: "wrap",
            // justifyContent: "space-between",
            // gap: "90px",
          }
        }
      >
        <Grid sx={{ display: "flex", gap: "56px", flexDirection: "column",justifyContent:"center",alignItems:"center" }}>
          {leadership?.map((item) => (
            <Grid>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "19px",
                  letterSpacing: "1px",
                  marginTop: "6px",
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  color: "#6C747E",
                  fontFamily: "IBM Plex Mono",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "19.6px",
                  letterSpacing: "1px",
                  textDecoration: "uppercase",
                  marginY: "6px",
                }}
              >
                {item.position}
              </Typography>

              <Grid
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: "36px",
                }}
              >
                {/* <Grid
                  sx={{
                    backgroundColor: "rgba(233, 244, 253, 1)",
                    width: isSm ? "299px" : "387px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "6px",
                  }}
                > */}
                  <Box
                    component="img"
                    sx={
                      {
                        maxWidth: "100%",
                        maxHeight: isSm ? "155px" : "181px",
                      }
                    }
                    src={`${BASE_URL}/${item.photo}`}
                  />
                {/* </Grid> */}



                <Typography
                  sx={{
                    color: "#63727E",
                    fontSize: "14px",
                    maxWidth: "570px",
                    // marginTop: "10px",
                  }}
                >
                  {item.desc}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Box
          width={"100%"}
          component="img"
          sx={{ marginTop: "56px" }}
          src={`${BASE_URL}/assets/business/web-aboutus-desktop-team.webp`}
        />
        <Typography
          sx={{
            color: "#6C747E",
            fontFamily: "IBM Plex Mono",
            fontWeight: 600,
            fontSize: isSm ? "12px" : "14px",
            lineHeight: "19.6px",
            letterSpacing: "1px",
            textDecoration: "uppercase",
            marginTop: isSm ? "0px" : "22px",
            marginBottom: "70px",
          }}
        >
          Neevay Team on - 1/12/2023 in Pune
        </Typography>
      </Grid>
    </Grid>
  );
};
