import React, { useState } from "react";
import { useSelector } from "react-redux";
import { dateFormatter } from "utils/formatUtils";
import ProjectOverview from "./ProjectOverview";
import Loading from "components/Loading";
import StaticPopup from "components/StaticPopup";
import CreateNewProjectForm from "./CreateNewProjectForm";

const ProjectsTableSection = () => {
  const project = useSelector((state) => state.rfqProjects);
  const [openPopup, setOpenPopup] = useState(false);
  const [projectOverView, setProjectOverView] = useState({});

  const projects = project.projects;
  const loading = project.loading;
  const rows = [
    "Sr No.",
    "Project Name",
    // "RFQ Sent ",
    "Region",
    "Location",
    "Create Date",
    "Project Budget",
    // "RFQs Count",
  ];

  const handleOpenPopup = (row) => {
    setProjectOverView(row);
    setOpenPopup(true);
  };
  const handleClosePopup = () => {
    setProjectOverView(null);
    setOpenPopup(false);
  };

  return (
    <div className="flex-grow bg-white border border-gray-300 rounded h-auto m-5 mt-2">
      <section className="flex items-center px-4 py-2">
        <div className=" mx-auto w-full">
          <div className=" dark:bg-gray-800 sm:rounded-lg">
            <div className="flex flex-col items-center justify-between  space-y-3 md:flex-row md:space-y-0 md:space-x-4">
              <div>
                <p className="text-xs text-gray-500">
                  {`${projects?.length ?? 0} Projects`}
                </p>
              </div>
              {/* Todo :  create search bar working properly */}
              {/* <div className="w-full md:w-1/2">
                <div className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-100 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Search"
                      // value={searchValue}
                      // onChange={handleSearchChange}
                      // onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {loading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10"></div>
        </div>
      ) : (
        <>
          {projects.length >= 1 ? (
            <>
              <div className="overflow-x-auto overflow-y-auto max-h-[450px]">
                <table className="w-full text-sm text-left text-gray-100 dark:text-gray-400">
                  <thead className="sticky top-0 text-xs max-w-full bg-gray-100 text-gray-500  dark:bg-gray-700 dark:text-gray-400">
                    <tr className="border-solid border-gray-200">
                      {rows?.map((title, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-4 py-3 text-gray-500 max-w-52"
                        >
                          <p className="truncate ">{title}</p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {projects?.map((row, rowIndex) => (
                      <tr key={rowIndex} className="text-black text-xs border-b-2">
                        <td>
                          <div className="flex justify-start px-4 py-2">
                            <p className="text-gray-500">
                              {rowIndex + 1}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-start max-w-60 px-4 py-2">
                            <button
                              onClick={() => handleOpenPopup(row)}
                              className=" max-w-60"
                            >
                              <p title={row?.name} className="truncate">
                                {row?.name}
                              </p>
                            </button>
                          </div>
                        </td>
                        {/* <td>
                          <div className="flex justify-start px-4 py-2">
                            <p className="text-gray-900 font-semibold">
                              {row?.rfqSent ?? 0}
                            </p>
                          </div>
                        </td> */}
                        <td>
                          <div className="flex justify-start px-4 py-2">
                            <p className="text-gray-900 text-xs">
                              {row?.location?.region || "--"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-start px-4 py-2">
                            <p className="text-gray-900 ">
                              {row?.location?.city}, {row?.location?.state}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-start px-4 py-2">
                            <p className="text-gray-500 ">
                              {dateFormatter(row?.createdAt)}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-start px-4 py-2">
                            <p className="text-gray-500 ">
                              {row?.estimateValue || "--"}
                            </p>
                          </div>
                        </td>
                        {/* <td>
                          <div className="flex justify-start px-4 py-2">
                            <p className="text-gray-500 font-medium">
                              {row?.rfqsCount || "--"}
                            </p>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <style>
                  {`
      .overflow-x-auto::-webkit-scrollbar {
        height: 6px;
      }

      .overflow-x-auto::-webkit-scrollbar-thumb {
        background-color: #949494; 
      }
    `}
                </style>
              </div>
              {/* <PaginationSection
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      /> */}
            </>
          ) : (
            <div className="h-[50vh] flex justify-center items-center">
              <h6>No Projects found </h6>
            </div>
          )}
        </>
      )}
      {/* Todo : Create pagination (26-03-2024) */}
      {/* <ProjectOverview
        data={projectOverView}
        handleClosePopup={handleClosePopup}
        openPopup={openPopup}
      /> */}
      <StaticPopup
        openModal={openPopup}
        handleClose={handleClosePopup}
        header={"View Project"}
        size={"sm"}
        width={"5xl"}
        height = {"auto"}
        bodyStyle={"pb-0 pt-2 px-0 "}
      >
        {openPopup && (
          <CreateNewProjectForm
            setShowCreateProjectForm={setOpenPopup}
            isEdit={true}
            data={projectOverView}
          />
        )}
      </StaticPopup>
    </div>
  );
};

export default ProjectsTableSection;
