import React from "react";
import Button from "./Button";
import Loading from "./Loading";
import { Tooltip } from "flowbite-react";

const Popup = ({
  open,
  onClose,
  handleSubmit,
  children,
  primaryButton,
  secondaryButton,
  heading,
  className,
  loading,
  alignRight = false,
}) => {
  return (
    <div
      className={`${
        open ? "fixed inset-0 flex items-center justify-center z-50" : "hidden"
      }`}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      onClick={onClose}
    >
      <form
        className={`relative p-4 w-full max-w-2xl max-h-[80vh] bg-white rounded-lg  shadow dark:bg-gray-700 overflow-y-auto overflow-x-hidden ${className}`}
        onClick={(e) => e.stopPropagation()}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {heading}
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="p-4 md:p-5">{children}</div>
        <div
          className={`flex items-center gap-x-2 p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 ${
            alignRight ? "justify-self-end flex-row-reverse" : "justify-start"
          }`}
        >
          {primaryButton?.variant === "disabled" && primaryButton?.tooltip ? (
            <Tooltip content={primaryButton?.tooltip} placement="top">
              <Button
                type="submit"
                variant={primaryButton?.variant ?? "primary"}
              >
                {loading ? <Loading /> : primaryButton?.text ?? "Submit"}
              </Button>
            </Tooltip>
          ) : (
            <Button type="submit" variant={primaryButton?.variant ?? "primary"}>
              {loading ? <Loading /> : primaryButton?.text ?? "Submit"}
            </Button>
          )}
          {secondaryButton && (
            <Button
              type="button"
              variant={secondaryButton?.variant ?? "outline"}
              onClick={
                secondaryButton.onClick ? secondaryButton.onClick : onClose
              }
            >
              {secondaryButton?.text ?? "Cancel"}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Popup;
