/* eslint-disable no-param-reassign */
import produce from 'immer';
// import {
//   FAVOURITES_FETCH,
//   FAVOURITES_FETCH_SUCCESS,
//   FAVOURITES_FETCH_FAILURE
// } from 'actions/favouritesAction';

import { SIGNOUT } from 'actions/authActions';

const initialState = {
  notifications: [],
  count: 0,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {


    // case FAVOURITES_FETCH: {
    //   return produce(state, (draft) => {
    //     draft.favourites = [];
    //     draft.count = 0;
    //   });
    // }
    // break;
    //
    // case FAVOURITES_FETCH_SUCCESS: {
    //   const { favourites } = action.payload;
    //   //console.log("notificationReducer: FAVOURITES_FETCH_SUCCESS ", favourites, action.payload);
    //
    //   return produce(state, (draft) => {
    //     draft.favourites = favourites;
    //     draft.count = favourites?.length;
    //   });
    // }
    // break;
    //
    // case FAVOURITES_FETCH_FAILURE: {
    //   return produce(state, (draft) => {
    //     draft.favourites = [];
    //     draft.count = 0;
    //   });
    // }
    // break;

    case SIGNOUT: {
      return produce(state, (draft) => {
        draft.notifications = [];
        draft.count = 0;
      });
    }
    break;

    default: {
      return state;
    }
    break;
  }
};

export default notificationReducer;
