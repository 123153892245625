import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { editServiceTrigger, deleteService } from "actions/serviceActions";

import { ImageEditor } from "components";

import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function ServiceCardPublic({
  supplierId,
  serviceName,
  description,
  serviceDetails,
  usps,
  imageUrl,
  isPublicProfile,
  serviceData,
  permissions,
  serviceId,
  ...rest
}) {
  // Read more logic start here
  const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };
  const cardHeight = showFullText ? "auto" : "403px"
  const displayText = showFullText ? description : description.slice(0, 150)
  // Read more logic end here
  return (
    <Grid container>

      <Grid container justifyContent="space-between">

        <Grid container justifyContent="flex-start" xs={8} >
          <Grid item margin={"0 20px 0 0"}>
            <img src="/assets/icons/business.png" alt="icon" />
          </Grid>
          <Grid item >
            <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
              {serviceName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>




      <Divider xs={12} sx={{
        border: " 1px solid", width: "100%",
        margin: "8px 0",
        borderColor: "rgb(227, 220, 82)"
      }} />



      <Grid container spacing={2} >
        <Grid item xs={12} sm={5} md={5.5} sx={{ position: "relative", minHeight:"126px" }}>
          <CardMedia
            component="img"
            // sx={{minWidth:"205px"}}
            sx={{
              filter: "blur(5px)",
              position: "relative",
              objectFit: "none",
              minHeight:"155px",
              border:"1px solid black"
            }}
            // maxHeight="126px"
            image={
              imageUrl ? `${BASE_URL}/${imageUrl}` : DEFAULT_IMAGES.SERVICE
            }
            // sx={{ backgroundImage : `url(${BASE_URL})` }}
            // sx={{ backgroundColor:"red" }}
            // alt="Uploading..."
          />
          <CardMedia
            sx={{ position: "absolute", top: "16px", right: "0", maxWidth: "94%" }}
            component="img"

            // height="126px"
            image={
              imageUrl ? `${BASE_URL}/${imageUrl}` : DEFAULT_IMAGES.SERVICE
            }
            // sx={{ backgroundImage : `url(${BASE_URL})` }}
            // sx={{ backgroundColor:"red" }}
            alt="Uploading..."
          />


        </Grid>


        <Grid item xs={12} sm={7} md={6.5}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0.5}
          >

            <Grid item md={12}>
              <Grid container flexDirection="column">
                {usps?.map((item, index) => (
                  <Grid item md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                    {index < 3 && <ListItem alignItems="flex-start" disablePadding dense={true}>
                      <ArrowRightIcon
                        sx={{
                          display: "list-item",
                          ml: 1,
                          color: "primary.main",
                        }}
                      />
                      <ListItemText sx={{ marginTop: "0" }}><Typography variant="businessBody">{item}</Typography></ListItemText>
                    </ListItem>

                    }

                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Grid xs={12} sx={{ marginTop: "20px" }}>
        {description.length > 152 ?
          (<Typography variant="businessBody">{displayText}
            <Button sx={{ padding: "0 " }} onClick={toggleFullText}>
              <Typography variant="businessBody"> {showFullText ? 'Read Less' : '...Read More'} </Typography>
            </Button>
          </Typography>
          ) :
          <Typography variant="businessBody">
            {description ? description : "Description"}
          </Typography>}
      </Grid>
    </Grid>
  )
}

ServiceCardPublic.propTypes = {}

export default ServiceCardPublic
