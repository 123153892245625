import React from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import { Helmet } from "react-helmet";

function Notifications() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  // console.log("Notifications: userProfile:", userProfile);

  return (
    <DashboardLayout path="/user-notifications">
    <Helmet>
      <title>Notifications</title>
    </Helmet>
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Typography variant="h5"  color="#FD6600" fontWeight="bold" mb={2}>
              View All Your Notifications
            </Typography>
            <Divider />
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
  );
}

export default Notifications;
