/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  FAVOURITES_FETCH,
  FAVOURITES_FETCH_SUCCESS,
  FAVOURITES_FETCH_FAILURE,
  FAVOURITES_ADD_SUCCESS,
  FAVOURITES_REMOVE_SUCCESS,
} from "actions/favouritesAction";

import { SIGNOUT } from "actions/authActions";

const initialState = {
  favourites: [],
  count: 0,
};

const favouritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAVOURITES_FETCH:
      {
        return produce(state, (draft) => {
          draft.favourites = [];
          draft.count = 0;
          draft.loading = true;
        });
      }
      break;

    case FAVOURITES_FETCH_SUCCESS:
      {
        const { favourites } = action.payload;
        // console.log(
        //   "favouritesReducer: FAVOURITES_FETCH_SUCCESS ",
        //   favourites,
        //   action.payload
        // );

        const filterFavourites = favourites.filter((favourite) => {
          return favourite?.tags?.length > 0;
        });
        return produce(state, (draft) => {
          draft.favourites = filterFavourites;
          draft.count = favourites?.length;
          draft.loading = false;
        });
      }
      break;

    case FAVOURITES_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.favourites = [];
          draft.count = 0;
          draft.loading = false;
        });
      }
      break;

    case FAVOURITES_ADD_SUCCESS:
      {
        const favourites = action.payload;
        const filterFavourites = favourites.filter((favourite) => {
          return favourite?.tags?.length > 0;
        });
        console.log("favouritesReducer: FAVOURITES_ADD_SUCCESS ", favourites);
        return produce(state, (draft) => {
          draft.favourites = filterFavourites;
          draft.count = favourites?.length;
        });
      }
      break;

    case FAVOURITES_REMOVE_SUCCESS:
      {
        const favourites = action.payload;
        const filterFavourites = favourites.filter((favourite) => {
          return favourite?.tags?.length > 0;
        });
        console.log("favourites: FAVOURITES_REMOVE_SUCCESS", favourites)
        return produce(state, (draft) => {
          draft.favourites = filterFavourites;
          draft.count = favourites?.length;
        });
      }
      break;

    case SIGNOUT:
      {
        return produce(state, (draft) => {
          draft.favourites = [];
          draft.count = 0;
        });
      }
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default favouritesReducer;
