import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SplashScreen from "components/SplashScreen";
import { setUserData, signout } from "actions/authActions";
import authService from "services/authService";
import { fetchFavourites } from "actions/favouritesAction";
import { fetchRequirements } from "actions/requirementsAction";
import { fetchLeads } from "actions/leadsAction";
import { fetchOrders } from "actions/ordersAction";
import { fetchBusinessById, fetchBusinessByUserId } from "actions/businessActions";
import { regionsActions } from "actions/regionsActions";
// import regionsActions from "actions/regionsActions";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(signout()),
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        // console.log("Auth|Before loginInWithToken ");
        const userId = authService.getUserId();
        // console.log("Auth|Before loginInWithToken :" + userId);

        const user = await authService.loginInWithToken(userId);
        await dispatch(setUserData(user));
        // console.log("Auth|After loginInWithToken " + JSON.stringify(user));
        // Fetch and Set all the configuration data on successful login

        // Initialize User Information
        dispatch(fetchFavourites());
        dispatch(fetchRequirements(user?.userId));
        dispatch(fetchLeads(user?.vendorsIn, user?.userId));
        dispatch(fetchOrders());
        // dispatch(fetchBusinessById(user?.supplierId));
        dispatch(fetchBusinessByUserId(user?.userId))
        dispatch(regionsActions());

      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
