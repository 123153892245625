import axios from "utils/axios";
import {
  GET_VENDOR_QUOTATION_URL,
    SUBMIT_VENDOR_QUOTATION_URL
  } from "config/services";

class VendorQuotationService {
    
    submitMaterialQuotation = (newVendorQuotation) =>
      new Promise((resolve, reject) => {
        axios
          .post(SUBMIT_VENDOR_QUOTATION_URL, newVendorQuotation)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error);
          });
      });

      
      getVendorQuotation = (newVendorQuotation) =>
        new Promise((resolve, reject) => {
          axios
            .get(GET_VENDOR_QUOTATION_URL, {
              params: { // Pass query parameters
                rfqId: newVendorQuotation.rfqId,
                supplierId: newVendorQuotation.supplierId
              }
            })
            .then((response) => {
              resolve(response?.data); // Handle success response
            })
            .catch((error) => {
              reject(error); // Handle errors
            });
        });
      
      


    //   submitLabourQuotation = (newLabourQuotation) =>
    //   new Promise((resolve, reject) => {
    //       axios
    //           .post(SUBMIT_VENDOR_QUOTATION_URL, newLabourQuotation)
    //           .then((response) => {
    //               resolve(response?.data);
    //           })
    //           .catch((error) => {
    //               reject(error);
    //           });
    //   });

  }
  
  export const vendorQuotationService = new VendorQuotationService();
  