import React, { useEffect, useRef, useState } from "react";
import { Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Box,
  FormHelperText,
  Button,
  Autocomplete,
  Typography,
  MenuItem,
  Select,
  FormControl,
  TextareaAutosize,
  Chip,
  Paper,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import * as Yup from "yup";
import { IMAGE_TYPE } from "config";

import { Link } from "react-router-dom";
import {
  addService,
  deleteService,
  editServiceTrigger,
} from "actions/serviceActions";
import { updateStepperCount } from "actions/businessActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function AddServiceForm({ ...rest }) {
  const dispatch = useDispatch();

  const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);

  const handleAccordionChange = (index) => {
    // Toggle the accordion by setting the index to null if it's already selected
    setShowForm(false);
    setSelectedServiceIndex((prevIndex) =>
      prevIndex === index ? null : index
    );

    formik.setValues({
      serviceName: services[index]?.serviceName || "", // Replace with your actual field names

      usp1:
        services[index]?.usps && services[index]?.usps.length > 0
          ? services[index]?.usps[0]
          : "",
      usp2:
        services[index]?.usps && services[index]?.usps.length > 1
          ? services[index]?.usps[1]
          : "",
      usp3:
        services[index]?.usps && services[index]?.usps.length > 2
          ? services[index]?.usps[2]
          : "",
    });
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  /////////////////////////////////////

  const [showForm, setShowForm] = useState(true);

  //////////////////////////////////

  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const businessName = business?.profile?.businessName;
  const services = business.profile?.services;
  console.log("services", services);
  const stepperCountValue = 3;
  const initialValues = {
    serviceName: "",
    usp1: "",
    usp2: "",
    usp3: "",
  };

  const validationSchema = Yup.object({
    serviceName: Yup.string()
      .required("Service Name is required")
      .matches(/[^0-9]/, "Please enter valid service name")
      .max(50, "Service Name cannot exceed 50 characters"),

    usp1: Yup.string()
      .required("USP 1 is required")
      .max(50, "USP cannot exceeded 50 characters"),
    usp2: Yup.string().max(50, "USP cannot exceeded 50 characters"),
    usp3: Yup.string().max(50, "USP cannot exceeded 50 characters"),
  });

  const handleEditService = async (data, service) => {
    data._id = service._id;
    dispatch(addService(supplierId, data));
    await setSelectedServiceIndex(null);
  };

  const handleDeleteService = async (service) => {
    dispatch(
      deleteService(supplierId, service.serviceName, service._id, businessName)
    );
    await setSelectedServiceIndex(null);
  };

  const onSubmit = async (values) => {
    dispatch(addService(supplierId, values));
    setShowForm(false);
  };

  function handleNext() {
    dispatch(updateStepperCount(supplierId, stepperCountValue));
  }

  const clearAllFields = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Grid sx={{ maxWidth: "100vw" }}>
      <Grid container flexDirection="column" gap={2}>
        {/* Saved Services........... */}
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxWidth: "100%",
          }}
        >
          {services?.map((service, index) => (
            <Accordion
              key={index}
              expanded={index === selectedServiceIndex}
              onChange={() => handleAccordionChange(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
              >
                <Typography>{service.serviceName}</Typography>
                <CheckCircleOutlineIcon
                  style={{ marginLeft: "10px", color: "#04EEAD" }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  style={{
                    height: "auto",
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid #B9B9C3",
                    padding: "6px 15px",
                  }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{
                          color: "#5E5873",
                          fontFamily: "Roboto",
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "28.8px",
                          letterSpacing: "0.14px",
                        }}
                      >
                        Service {selectedServiceIndex + 1}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          color: "#FD6600",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteService(service)}
                      >
                        DELETE
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          marginTop: "8px",
                        }}
                      >
                        {/* Formik TextFields */}
                        <Grid
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            size="small"
                            fullWidth
                            type="text"
                            name="serviceName"
                            placeholder="Service Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.serviceName}
                          />
                        </Grid>
                        {formik.touched.serviceName &&
                        formik.errors.serviceName ? (
                          <div
                            style={{
                              color: "#d32f2f",
                              lineHeight: 1.5,
                              fontSize: "0.75rem",
                              fontWeight: "400",
                              marginLeft: "14px",
                              marginTop: "-6px",
                              marginBottom: "-6px",
                            }}
                          >
                            {formik.errors.serviceName}
                          </div>
                        ) : null}

                        <TextField
                          type="text"
                          size="small"
                          fullWidth
                          name="usp1"
                          placeholder="USP 1"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.usp1}
                        />
                        {formik.touched.usp1 && formik.errors.usp1 ? (
                          <div
                            style={{
                              color: "#d32f2f",
                              lineHeight: 1.5,
                              fontSize: "0.75rem",
                              fontWeight: "400",
                              marginLeft: "14px",
                              marginTop: "-6px",
                              marginBottom: "-6px",
                            }}
                          >
                            {formik.errors.usp1}
                          </div>
                        ) : null}

                        <TextField
                          type="text"
                          name="usp2"
                          size="small"
                          fullWidth
                          placeholder="USP 2"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.usp2}
                        />
                        {formik.touched.usp2 && formik.errors.usp2 ? (
                          <div
                            style={{
                              color: "#d32f2f",
                              lineHeight: 1.5,
                              fontSize: "0.75rem",
                              fontWeight: "400",
                              marginLeft: "14px",
                              marginTop: "-6px",
                              marginBottom: "-6px",
                            }}
                          >
                            {formik.errors.usp2}
                          </div>
                        ) : null}

                        <TextField
                          type="text"
                          name="usp3"
                          size="small"
                          fullWidth
                          placeholder="USP 3"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.usp3}
                        />
                        {formik.touched.usp3 && formik.errors.usp3 ? (
                          <div
                            style={{
                              color: "#d32f2f",
                              lineHeight: 1.5,
                              fontSize: "0.75rem",
                              fontWeight: "400",
                              marginLeft: "14px",
                              marginTop: "-6px",
                              marginBottom: "-6px",
                            }}
                          >
                            {formik.errors.usp3}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          gap: "15px",
                          marginTop: "10px",
                          marginBottom: "6px",
                        }}
                      >
                        <Button
                          variant="conatined"
                          style={{
                            borderRadius: "3px",
                            border: " 1px solid #989898",
                            height: "27px",
                            background: "#FFF",
                            color: "#424242",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                          onClick={() => handleAccordionChange(index)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="conatined"
                          style={{
                            borderRadius: "3px",
                            border: " 1px solid #989898",
                            height: "27px",
                            color: "#424242",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                          onClick={() =>
                            handleEditService(formik.values, service)
                          }
                        >
                          Update
                        </Button>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        {/* Services Form .............. */}
        {showForm && (
          <Grid
            style={{
              height: "auto",
              borderRadius: "10px",
              width: "100%",
              border: "1px solid #B9B9C3",
              padding: "6px 15px",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography
                  style={{
                    color: "#5E5873",
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "28.8px",
                    letterSpacing: "0.14px",
                  }}
                >
                  Service {services?.length + 1}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    color: "black",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: "400",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={clearAllFields} // Connect the clearAllFields function to the "Clear All" button
                >
                  Clear all
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <form onSubmit={formik.handleSubmit}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "8px",
                  }}
                >
                  {/* Formik TextFields */}
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      type="text"
                      name="serviceName"
                      placeholder="Service Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceName}
                    />
                  </Grid>
                  {formik.touched.serviceName && formik.errors.serviceName ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "-6px",
                        marginBottom: "-6px",
                      }}
                    >
                      {formik.errors.serviceName}
                    </div>
                  ) : null}

                  <TextField
                    type="text"
                    size="small"
                    fullWidth
                    name="usp1"
                    placeholder="USP 1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.usp1}
                  />
                  {formik.touched.usp1 && formik.errors.usp1 ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "-6px",
                        marginBottom: "-6px",
                      }}
                    >
                      {formik.errors.usp1}
                    </div>
                  ) : null}

                  <TextField
                    type="text"
                    name="usp2"
                    size="small"
                    fullWidth
                    placeholder="USP 2"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.usp2}
                  />
                  {formik.touched.usp2 && formik.errors.usp2 ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "-6px",
                        marginBottom: "-6px",
                      }}
                    >
                      {formik.errors.usp2}
                    </div>
                  ) : null}

                  <TextField
                    type="text"
                    name="usp3"
                    size="small"
                    fullWidth
                    placeholder="USP 3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.usp3}
                  />
                  {formik.touched.usp3 && formik.errors.usp3 ? (
                    <div
                      style={{
                        color: "#d32f2f",
                        lineHeight: 1.5,
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginLeft: "14px",
                        marginTop: "-6px",
                        marginBottom: "-6px",
                      }}
                    >
                      {formik.errors.usp3}
                      
                    </div>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "15px",
                    marginTop: "10px",
                    marginBottom: "6px",
                  }}
                >
                  <Button
                    variant="conatined"
                    style={{
                      borderRadius: "3px",
                      border: " 1px solid #989898",
                      height: "27px",
                      background: "#FFF",
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                    onClick={() => setShowForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="conatined"
                    type="submit"
                    style={{
                      borderRadius: "3px",
                      border: " 1px solid #989898",
                      height: "27px",
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        )}

        {/* Add Service Button .......... */}
        {showForm ? null : (
          <Grid>
            <Grid
              style={{
                width: "175px",
                height: "25px",
                borderRadius: "8px",
                border: "1px solid #767676",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedServiceIndex(null);
                clearAllFields();
                setShowForm(true);
              }}
            >
              <Typography
                style={{
                  color: "#46AC9E",

                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {" "}
                + Add another service
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* Next Button ................  */}
        <Grid
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleNext}
            variant="contained"
            // color="secondary"
            sx={{
              width: "76px",
              height: "25px",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                transition: "background-color 0.3s, box-shadow 0.3s",
              },
              borderRadius: "5px",
            }}
            disabled={!services?.length || showForm}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddServiceForm;
