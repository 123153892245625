import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editRepeatFixedRfq,
  fetchRepeatFixedRfq,
  sendRepeatFixedRfq,
} from "actions/requirementsAction";
import axios from "utils/axios";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import VendorForm from "./vendorForm";
import { fetchRfqProjects } from "actions/rfqActions";
import Loading from "components/Loading";

const validationSchema = Yup.object().shape({
  orderName: Yup.string().required("Order Name is required"),
  orderType: Yup.string().required("Type of Order is required"),
  vendorName: Yup.string().required("Vendor Name is required"),
  orderValue: Yup.number()
    .required("Order Value is required")
    .typeError("Order Value must be a number"),
  remarks: Yup.string().required("Order Description is required"),
  projectName: Yup.string().required("Project Name is required"),
  orderCategory: Yup.string().required("Order Category is required"),
  poDetails: Yup.array()
    .of(
      Yup.object().shape({
        poNumber: Yup.string().required("PO Number is required"),
        poDate: Yup.date()
          .required("PO Create Date is required")
          .typeError("PO Create Date must be a valid date"),
        amount: Yup.number()
          .required("PO Amount is required")
          .typeError("PO Amount must be a number"),
      })
    )
    .test("unique", function (poDetails) {
      const poNumbers = poDetails.map((detail) => detail.poNumber);
      const duplicates = poNumbers.filter(
        (item, index) => poNumbers.indexOf(item) !== index
      );
      if (duplicates.length > 0) {
        return this.createError({
          path: "poDetails",
          message: `Duplicate PO Number(s) found: ${[
            ...new Set(duplicates),
          ].join(", ")}`,
        });
      }
      return true;
    }),
});

const RepeatFixedRfqPopUp = ({
  userId,
  repeatFixedPopUp,
  setRepeatFixedPopUp,
  isEdited = false,
  repeatOrderValue = {},
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const projectData = useSelector((state) => state.rfqProjects);
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;

  const [vendorName, setVendorName] = useState(
    repeatOrderValue?.vendorName ?? ""
  );
  const [vendorData, setVendorData] = useState([]);
  const [errorObject, setErrorObject] = useState({ error: false, message: "" });
  const [selectedVendor, setSelectedVendor] = useState(
    isEdited
      ? {
          vendorId: repeatOrderValue?.vendorId,
          vendorName: repeatOrderValue?.vendorName,
        }
      : null
  );
  const [selectedProject, setSelectedProject] = useState(null);
  const [addVendorPopUp, setAddVendorPopUp] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const projects = projectData?.projects ?? [];
  const sortedProjects = projects.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const handleCloseDialogueBox = () => {
    setRepeatFixedPopUp(false);
    setSelectedVendor(null);
  };

  const handleOpenVendorForm = () => {
    setAddVendorPopUp(true);
  };

  const handleCloseVendorForm = () => {
    setAddVendorPopUp(false);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/directory/vendors?entityId=${entityId}&searchParam=${vendorName}`
        );
        const vendorData = response.data.data;
        setVendorData(vendorData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (vendorName.trim() !== "" && vendorName) {
      fetchData();
    }
  }, [vendorName, entityId]);

  useEffect(() => {
    setVendorName("");
  }, [repeatFixedPopUp]);

  // useEffect(() => {
  //   setVendorName(repeatOrderValue?.vendorName);
  //   setSelectedVendor({
  //     vendorId: repeatOrderValue?.vendorId,
  //     vendorName: repeatOrderValue?.vendorName,
  //   });
  // }, [repeatOrderValue]);
  useEffect(() => {
    dispatch(fetchRfqProjects(entityId));
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={repeatFixedPopUp}
        onClose={handleCloseDialogueBox}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseDialogueBox}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Create Fixed/Repeat Order</DialogTitle>
        <DialogContent>
          {entityId ? (
            <Formik
              initialValues={{
                orderName: isEdited ? repeatOrderValue?.orderName : "",
                orderType: isEdited
                  ? repeatOrderValue?.typeOfOrder === "Fixed Vendor"
                    ? "fixedVendor"
                    : "repeatOrder"
                  : "",
                vendorName: isEdited ? repeatOrderValue?.vendorName : "",
                projectName: isEdited ? repeatOrderValue?.projectId : "",
                orderCategory: isEdited ? repeatOrderValue?.orderCategory : "",
                remarks: isEdited ? repeatOrderValue?.description : "",
                poDetails: isEdited
                  ? repeatOrderValue?.poDetails?.map((poDetail) => {
                      return {
                        ...poDetail,
                        poDate: new Date(poDetail.poDate).toLocaleDateString(
                          "en-CA"
                        ),
                      };
                    })
                  : [{ poNumber: "", poDate: "", amount: "" }],
                orderValue: isEdited ? repeatOrderValue?.orderValue : 0,
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setLoading(true);
                const typeOfOrder =
                  values.orderType === "repeatOrder"
                    ? "REPEAT_ORDER"
                    : "FIXED_ORDER";
                const selectedProject = projects.find(
                  (project) => project._id === values.projectName
                );
                const sendRfqObj = {
                  typeOfOrder,
                  orderName: values.orderName,
                  vendorId: selectedVendor?.vendorId,
                  vendorName: values.vendorName,
                  orderValue: values?.poDetails?.reduce(
                    (acc, curr) => acc + (parseFloat(curr.amount) || 0),
                    0
                  ),
                  description: values.remarks,
                  projectName: selectedProject?.name,
                  projectId: selectedProject._id,
                  orderCategory: values.orderCategory,
                  poDetails: values.poDetails,
                  createdBy: userProfile?.userId,
                  addedBy: userProfile?.name,
                };

                try {
                  if (isEdited) {
                    await dispatch(
                      editRepeatFixedRfq({
                        ...sendRfqObj,
                        repeatRfqId: repeatOrderValue?.repeatRfqId,
                      })
                    );
                  } else {
                    await dispatch(sendRepeatFixedRfq(sendRfqObj));
                  }
                  handleCloseDialogueBox();
                  setSelectedVendor(null);
                  setTimeout(() => {
                    if (userId === userProfile?.userId) {
                      dispatch(fetchRepeatFixedRfq(userProfile?.userId));
                    }
                  }, 2000);
                } catch (error) {
                  console.error("Error submitting form:", error);
                  setErrorObject({
                    error: true,
                    message:
                      error?.message ?? "something went wrong please try again",
                  });
                  setTimeout(() => {
                    setErrorObject({ error: false, message: "" });
                  }, 6000);
                } finally {
                  setLoading(false);
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, setFieldValue, values, errors, touched }) => {
                const calculateTotal = () => {
                  const total = values?.poDetails?.reduce(
                    (acc, curr) => acc + (parseFloat(curr.amount) || 0),
                    0
                  );
                  return total;
                };

                return (
                  <Form>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: "12px" }}
                    >
                      <Typography sx={{ width: "120px", mr: "12px" }}>
                        Order Name
                      </Typography>
                      <Field
                        as={TextField}
                        name="orderName"
                        sx={{ width: "80%" }}
                        size="small"
                        placeholder="Order Name"
                        error={touched.orderName && Boolean(errors.orderName)}
                        helperText={touched.orderName && errors.orderName}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: "12px" }}
                    >
                      <Typography sx={{ width: "120px", mr: "12px" }}>
                        Type of Order
                      </Typography>
                      <RadioGroup
                        aria-label="orderType"
                        name="orderType"
                        value={values.orderType}
                        onChange={(event) =>
                          setFieldValue("orderType", event.target.value)
                        }
                      >
                        <Box display="flex" flexDirection="row">
                          <FormControlLabel
                            value="repeatOrder"
                            control={<Radio />}
                            label="Repeat Order"
                          />
                          <FormControlLabel
                            value="fixedVendor"
                            control={<Radio />}
                            label="Fixed Vendor"
                          />
                        </Box>
                      </RadioGroup>
                      {touched.orderType && errors.orderType && (
                        <Typography color="error" variant="caption">
                          {errors.orderType}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: "12px" }}
                    >
                      <Typography sx={{ width: "120px", mr: "12px" }}>
                        Vendor Name
                      </Typography>
                      <Autocomplete
                        sx={{ width: "80%" }}
                        size="small"
                        options={vendorData}
                        getOptionLabel={(option) => option.vendorName}
                        value={selectedVendor}
                        onChange={(event, newValue) => {
                          setSelectedVendor(newValue);
                          setFieldValue(
                            "vendorName",
                            newValue?.vendorName || ""
                          );
                        }}
                        inputValue={vendorName}
                        onInputChange={(event, newInputValue) =>
                          setVendorName(newInputValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Vendor Name"
                            error={
                              touched.vendorName && Boolean(errors.vendorName)
                            }
                            helperText={touched.vendorName && errors.vendorName}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: "12px" }}
                    >
                      <Typography sx={{ width: "120px", mr: "12px" }}>
                        Project Name
                      </Typography>
                      <Autocomplete
                        sx={{ width: "80%" }}
                        size="small"
                        id="projectName"
                        name="projectName"
                        options={sortedProjects}
                        getOptionLabel={(project) =>
                          `${project.name} - ${project.location.city} - ${project.location.state}`
                        }
                        value={
                          sortedProjects.find(
                            (project) => project._id == values.projectName
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setSelectedProject(newValue);
                          setFieldValue(
                            "projectName",
                            newValue ? newValue._id : ""
                          );
                        }}
                        // inputValue={projectName}
                        // onInputChange={(event, newInputValue) =>
                        //   setProjectName(newInputValue)
                        // }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Project Name"
                            variant="outlined"
                            className="shadow-sm block w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
                            error={
                              touched.projectName && Boolean(errors.projectName)
                            }
                            helperText={
                              touched.projectName && errors.projectName
                            }
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: "12px" }}
                    >
                      <Typography sx={{ width: "120px", mr: "12px" }}>
                        Order Category
                      </Typography>
                      <Field
                        as={TextField}
                        name="orderCategory"
                        select
                        sx={{ width: "80%" }}
                        size="small"
                        placeholder="Order Category"
                        error={
                          touched.orderCategory && Boolean(errors.orderCategory)
                        }
                        helperText={
                          touched.orderCategory && errors.orderCategory
                        }
                      >
                        <MenuItem value="Material Only">Material Only</MenuItem>
                        <MenuItem value="Providing & Fixing">
                          Providing & Fixing
                        </MenuItem>
                        <MenuItem value="Labour Only">Labour Only</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Field>
                    </Box>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: "12px" }}
                    >
                      <Typography sx={{ width: "120px", mr: "12px" }}>
                        Order Description
                      </Typography>
                      <Field
                        as={TextField}
                        name="remarks"
                        sx={{ width: "80%" }}
                        placeholder="Order Description"
                        multiline
                        rows={4}
                        error={touched.remarks && Boolean(errors.remarks)}
                        helperText={touched.remarks && errors.remarks}
                      />
                    </Box>
                    <FieldArray name="poDetails">
                      {({ push, remove, form }) => {
                        const { values, errors, touched } = form;
                        const poDetailsErrors = getIn(errors, "poDetails");
                        const poDetailsTouched = getIn(touched, "poDetails");

                        return (
                          <>
                            {values?.poDetails?.map((poDetail, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  mb: "12px",
                                  border: "1px solid #ddd",
                                  borderRadius: "4px",
                                  p: "12px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    mb: "12px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      mb: "8px",
                                      fontSize: "18px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    Purchase Order : {index + 1}
                                  </Typography>
                                  {index > 0 && (
                                    <IconButton
                                      onClick={() => remove(index)}
                                      sx={{ ml: "8px" }}
                                      // color="error"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: "12px",
                                  }}
                                >
                                  <Typography
                                    sx={{ width: "120px", mr: "12px" }}
                                  >
                                    PO Number
                                  </Typography>
                                  <Field
                                    as={TextField}
                                    name={`poDetails.${index}.poNumber`}
                                    sx={{ width: "80%" }}
                                    size="small"
                                    placeholder="PO Number"
                                    error={
                                      touched.poDetails?.[index]?.poNumber &&
                                      Boolean(
                                        errors.poDetails?.[index]?.poNumber
                                      )
                                    }
                                    helperText={
                                      touched.poDetails?.[index]?.poNumber &&
                                      errors.poDetails?.[index]?.poNumber
                                    }
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: "12px",
                                  }}
                                >
                                  <Typography
                                    sx={{ width: "120px", mr: "12px" }}
                                  >
                                    PO Create Date
                                  </Typography>
                                  <Field
                                    as={TextField}
                                    name={`poDetails.${index}.poDate`}
                                    sx={{ width: "80%" }}
                                    size="small"
                                    type="date"
                                    error={
                                      touched.poDetails?.[index]?.poDate &&
                                      Boolean(errors.poDetails?.[index]?.poDate)
                                    }
                                    helperText={
                                      touched.poDetails?.[index]?.poDate &&
                                      errors.poDetails?.[index]?.poDate
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: "12px",
                                  }}
                                >
                                  <Typography
                                    sx={{ width: "120px", mr: "12px" }}
                                  >
                                    PO Amount
                                  </Typography>
                                  <Field
                                    as={TextField}
                                    name={`poDetails.${index}.amount`}
                                    sx={{ width: "80%" }}
                                    size="small"
                                    placeholder="PO Amount"
                                    error={
                                      touched.poDetails?.[index]?.amount &&
                                      Boolean(errors.poDetails?.[index]?.amount)
                                    }
                                    helperText={
                                      touched.poDetails?.[index]?.amount &&
                                      errors.poDetails?.[index]?.amount
                                    }
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <Button
                                type="button"
                                onClick={() =>
                                  push({
                                    poNumber: "",
                                    poDate: "",
                                    amount: "",
                                  })
                                }
                                variant="outlined"
                                sx={{ mb: "12px", width: "93px" }}
                              >
                                + Add PO
                              </Button>
                              {poDetailsErrors &&
                                typeof poDetailsErrors === "string" && (
                                  <Typography
                                    color="error"
                                    variant="caption"
                                    sx={{ mb: "10px" }}
                                  >
                                    {poDetailsErrors}
                                  </Typography>
                                )}
                            </Box>
                          </>
                        );
                      }}
                    </FieldArray>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: "12px" }}
                    >
                      <Typography sx={{ width: "120px", mr: "12px" }}>
                        Total Amount
                      </Typography>
                      <input
                        value={calculateTotal()}
                        className="w-4/5 p-2 text-sm bg-gray-100 border border-gray-300 rounded text-gray-600"
                        readOnly
                        disabled
                      />
                    </Box>
                    {errorObject?.error && (
                      <Typography
                        color="error"
                        variant="caption"
                        sx={{ mb: "10px" }}
                      >
                        {errorObject?.message}
                      </Typography>
                    )}
                    <DialogActions>
                      <Button
                        sx={{
                          backgroundColor: "#002E33",
                          "&:hover": {
                            backgroundColor: "#002E33",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                            color: "white",
                            transition:
                              "background-color 0.3s, box-shadow 0.3s",
                          },
                        }}
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {loading ? <Loading /> : isEdited ? "Save" : "Submit"}
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Grid
              sx={{
                display: "flex",
                height: "150px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>You're not a member of any entity</Typography>
            </Grid>
          )}
        </DialogContent>
      </Dialog>

      <VendorForm
        open={addVendorPopUp}
        onClose={handleCloseVendorForm}
        userProfile={userProfile}
        onVendorAdded={() =>
          handleSnackbarOpen(
            "Vendor added successfully you can search and create order now!"
          )
        }
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default RepeatFixedRfqPopUp;
