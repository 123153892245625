import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAddressHO, getAddressStr } from "utils/addressUtils";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import SocialIconDailog from "views/Business/components/SocialIconDailog";
import BusinessAddressForm from "views/Business/components/BusinessAddressForm";
import { Edit } from "@mui/icons-material";
function BusinessContactUs({
  profile,
  isMobileView = false,
  permissions,
  isEditView,
}) {
  const [openLogInModal, setOpenLogInModal] = useState(false);
  const [shoWebsite, setShowWebsite] = useState(false);
  const [shoWSocialIcons, setShowSocialIcons] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const auth = useSelector((state) => state.auth);

  const businessProfile = profile?.businessProfile;
  const addresses = businessProfile?.addresses;
  const socialLinks = businessProfile?.socialLinks;
  const userProfile = auth.user;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const socialIconList = [
    {
      name: "Facebook",
      image: "/assets/icons/facebook.svg",
      target: socialLinks?.facebookUrl,
    },
    {
      name: "Twitter",
      image: "/assets/icons/twitter.svg",
      target: socialLinks?.twitterUrl,
    },
    {
      name: "Instagram",
      image: "/assets/icons/instagram.svg",
      target: socialLinks?.instagramUrl,
    },
    {
      name: "Youtube",
      image: "/assets/icons/youtube.svg",
      target: socialLinks?.youtubeUrl,
    },
  ];
  const address = getAddressHO(addresses);
  const addressStr = getAddressStr(address);

  const handleClickShowOwnerDetails = (type) => {
    if (!userProfile) {
      setOpenLogInModal(true);
      return;
    } else if (userProfile.isVerified === false) {
    } else {
      setShowEmail(type === "email" ? !showEmail : showEmail);
      setShowWebsite(type === "url" ? !shoWebsite : shoWebsite);
      setShowSocialIcons(
        type === "socialIcons" ? !shoWSocialIcons : shoWSocialIcons
      );
    }

  };

  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };

  const maskEmail = (email) => {
    if (email && !isEditView) {
      const maskedEmail = email.substring(0, 3) + 'XXXXX@XXX.com'
      return maskedEmail;
    } else {
      return email
    }
  }

  function hasNonEmptyValue(obj) {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== "") {
        count++;
        if (count > 1) return true;
      }
    }
    return false;
  }
  const isImpProfile =
    businessProfile?.businessCategory == "Real Estate Developer" ||
    businessProfile?.businessCategory == "EPC Contractor";

  const website = businessProfile?.websiteUrl;
  const email = maskEmail(businessProfile?.businessEmail);

  const results = hasNonEmptyValue(socialLinks);
  return (
    <Grid>
      <Grid
        sx={{
          border: "1px solid rgba(217, 217, 217, 1)",
          borderRadius: isMobileView ? "30px" : "10px",
          minWidth: isMobileView ? "200px" : "322px",
          paddingLeft: "20px",
          paddingRight: "10px",
          marginX: isMobileView && "8px",
          marginTop: isMobileView && "16px",
        }}
      >
        {/* <Grid
          sx={{
            display: "flex",
            paddingTop: "20px",
          }}
        >
          <Box
            component="img"
            alt="phone"
            src="/assets/home/email.gif"
            style={{ paddingBottom: "10px", width: "36px" }}
          />
          {userProfile && userProfile.isVerified && (
            <Typography
              variant="businessCard"
              sx={{ ml: 2, textAlign: "center", paddingTop: "5px" }}
            >
              {isImpProfile
                ? "Not Disclosed"
                : email
                  ? email
                  : "Not Disclosed"}
            </Typography>
          )}
          {userProfile && !userProfile.isVerified && (
            <Grid>
              <Typography
                onClick={() => handleClickShowOwnerDetails("email")}
                style={{
                  cursor: "pointer",
                  textAlign: "start",
                  marginLeft: "12px",
                  fontSize: "14px",
                  paddingTop: "5px",
                }}
              >
                It will be visible after KYC*
                <Tooltip title="Due to increased demand, we're a bit delayed, but we'll get in touch soon; for urgent KYC, call 950-333-9814.">
                  <IconButton>
                    <Box
                      component="img"
                      sx={{
                        height: "16px",
                        width: "16px",
                      }}
                      alt="logo"
                      src="/assets/icons/questionMarkLogo.svg"
                    />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
          )}
          {!userProfile && (
            <Typography
              onClick={() => handleClickShowOwnerDetails("email")}
              style={{
                cursor: "pointer",
                textAlign: "center",
                marginLeft: "12px",
                color: "#3963AB",
                fontSize: "14px",
                textDecoration: "underline",
                paddingTop: "5px",
              }}
            >
              Login to view Email
            </Typography>
          )}
        </Grid> */}

        <Grid
          sx={{
            paddingTop: "20px",
            display: "flex",
          }}
        >
          <Box
            component="img"
            alt="phone"
            src="/assets/home/web.gif"
            style={{
              paddingBottom: "10px",
              width: "36px",
              textAlign: "center",
            }}
          />
          {userProfile && userProfile.isVerified && (
            <Typography
              variant="businessCard"
              sx={{ ml: 2, paddingTop: "5px", overflow: "hidden" }}
            >
              {website ? (
                <a
                  href={
                    website.startsWith("http") ? website : `https://${website}`
                  }
                  target="_blank"
                >
                  {website}
                </a>
              ) : (
                "Not Disclosed"
              )}
            </Typography>
          )}
          {userProfile && !userProfile.isVerified && (
            <Typography
              onClick={() => handleClickShowOwnerDetails("url")}
              style={{
                cursor: "pointer",
                textAlign: "start",
                marginLeft: "12px",
                fontSize: "14px",
                paddingTop: "5px",
              }}
            >
              It will be visible after KYC*
              <Tooltip title="Due to increased demand, we're a bit delayed, but we'll get in touch soon; for urgent KYC, call 950-333-9814.">
                <IconButton>
                  <Box
                    component="img"
                    sx={{
                      height: "16px",
                      width: "16px",
                    }}
                    alt="logo"
                    src="/assets/icons/questionMarkLogo.svg"
                  />
                </IconButton>
              </Tooltip>
            </Typography>
          )}
          {!userProfile && (
            <Typography
              onClick={() => handleClickShowOwnerDetails("url")}
              style={{
                cursor: "pointer",
                textAlign: "center",
                color: "#3963AB",
                fontSize: "14px",
                marginLeft: "12px",
                textDecoration: "underline",
                paddingTop: "5px",
              }}
            >
              Login to View website
            </Typography>
          )}
        </Grid>

        {results && (
          <Grid sx={{ display: "flex", gap: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                // flexDirection:"",
                mb: "10px",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                alt="link"
                src="/assets/home/link.svg"
                style={{
                  width: "20px",
                  margin: " 0px 8px",
                  textAlign: "center",
                  marginBottom: isEditView && "10px",
                  paddingTop: shoWSocialIcons && "8px",
                  paddingBottom: shoWSocialIcons && "8px",
                }}
              />
              <Typography
                variant="businessCard"
                sx={{ ml: 2, paddingTop: "5px" }}
              >
                {socialIconList.map((item) => {
                  return item.target !== "" ? (
                    <IconButton href={item.target} target="_blank">
                      <img src={item.image} />
                    </IconButton>
                  ) : (
                    ""
                  );
                })}
              </Typography>
            </Grid>

            <Grid>
              {permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed && (
                  <SocialIconDailog
                    supplierId={profile?.supplierId}
                    socialLinks={socialLinks}
                    shoWSocialIcons={shoWSocialIcons}
                    isEditView={isEditView}
                  />
                )}
            </Grid>
          </Grid>
        )}

        <Grid sx={{ display: "flex" }}>
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              alt="phone"
              src="/assets/home/locationIcon.svg"
              style={{ width: "22px", marginBottom: "38px" }}
            />
            {/* <LocationOnOutlinedIcon /> */}
            {addresses?.length > 0 ? (
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  width: "241px",
                  // marginTop: "14px",
                  marginBottom: "29px",
                  marginLeft: "28px",
                  flexWrap: "wrap",
                }}
              >
                {addressStr}
              </Typography>
            ) : (
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  width: "200px",
                  // marginTop: "14px",
                  marginBottom: "29px",
                  marginLeft: "32px",
                }}
              >
                Not Disclosed
              </Typography>
            )}
          </Grid>
          <Grid>
            {permissions &&
              !permissions.isViewOnly &&
              permissions.isFreeUpdateAllowed && (
                <ListItem
                  disablePadding
                  sx={{ textAlign: "right" }}
                  onClick={handleClickOpen}
                >
                  <ListItemIcon>
                    {addressStr ? (
                      <Tooltip title="Edit Address">
                        <Link style={{ fontSize: "14px", color: "#2864BE" }}>
                          {" "}
                          Edit
                        </Link>
                      </Tooltip>
                    ) : (
                      // <Link style={{fontSize:"14px",color:"#2864BE"}}> Edit</Link>
                      <Edit
                        sx={{ color: "primary.main" }}
                        onClick={handleClickOpen}
                      />
                    )}
                  </ListItemIcon>
                </ListItem>
              )}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Edit Address"}
              </DialogTitle>
              <DialogContent>
                <BusinessAddressForm
                  supplierId={profile?.supplierId}
                  address={address}
                  handleClose={handleClose}
                />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />
    </Grid>
  );
}

export default BusinessContactUs;
