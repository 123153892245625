import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Dialog,
  Button,
  CardMedia,
  Card,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { ImageEditor } from "components";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
import { addClient, deleteClient } from "actions/clientAction";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AddClientForm = ({
  title,
  isPublicProfile,
  permissions,
  certificateUrl,
  open,
  handleClose,
  onSubmitSuccess,
  ...rest
}) => {
  const dispatch = useDispatch();
  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const imageToUpload = business.imageToUpload;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Client Name</DialogTitle>
      <Formik
        initialValues={{ logoTitle: "" }}
        validationSchema={Yup.object({
          logoTitle: Yup.string()
            .max(100, "Title must be 100 characters or less")
            .required("Title is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await dispatch(
              addClient(supplierId, values.logoTitle, /*imageToUpload?.file*/)
            );
            handleClose();
            onSubmitSuccess();
          } catch (error) {

            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} {...rest}>
            <DialogContent>
              <Card
                sx={{
                  p: 3,
                  minWidth: 200,
                  maxWidth: 1000,
                  color: "black",
                  border: "solid",
                  borderColor: "#F5F5F5",
                  elevation: 1,
                }}
              >
                <TextField
                  name="logoTitle"
                  label="Name"
                  fullWidth
                  value={values.logoTitle}
                  onChange={handleChange}
                  error={Boolean(touched.logoTitle && errors.logoTitle)}
                  helperText={touched.logoTitle && errors.logoTitle}
                />
                {/* <Grid item xs={12} sm={12} md={12}>
                  <Box
                    sx={{
                      p: 1,
                    }}
                  >
                    {isPublicProfile ? (
                      <CardMedia
                        component="img"
                        image={
                          certificateUrl
                            ? `${BASE_URL}/${certificateUrl}`
                            : DEFAULT_IMAGES.CERTIFICATE
                        }
                        alt="Image not found"
                      />
                    ) : (
                      <Grid item md={12}>
                        <Box>
                          <ImageEditor
                            type={IMAGE_TYPE.CLIENT}
                            supplierId={supplierId}
                            inImage={
                              certificateUrl
                                ? `${BASE_URL}/${certificateUrl}`
                                : DEFAULT_IMAGES.CLIENT
                            }
                          />
                        </Box>
                      </Grid>
                    )}
                  </Box>
                </Grid> */}
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" size="small"
               sx={{backgroundColor: "#757575",
               color:"white",
               "&:hover": {
                 backgroundColor: "#757575",
                 transition: "background-color 0.3s, box-shadow 0.3s",
                 boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
               }
               }}
               >
                Cancel
              </Button>
              <Button onClick={handleSubmit} variant="contained" size="small" 
              sx={{backgroundColor: "black",
              color:"white",
              "&:hover": {
                backgroundColor: "black",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
              }
              }}
              >
                <Box onClick={handleClose}>Submit</Box>
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
AddClientForm.propTypes = {
  Open: PropTypes.bool,
  handleClose: PropTypes.func,
  certificateUrl: PropTypes.string,
  supplierId: PropTypes.number,
  permissions: PropTypes.object,
  isPublicProfile: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
};

AddClientForm.default = {
  onSubmitSuccess: () => {},
};
export default AddClientForm;
