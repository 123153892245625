import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { editServiceTrigger, deleteService } from "actions/serviceActions";

import { ImageEditor } from "components";

import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function ServiceCard({
  supplierId,
  serviceName,
  description,
  serviceDetails,
  usps,
  imageUrl,
  isPublicProfile,
  serviceData,
  permissions,
  serviceId,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEditService = () => {
    // console.log("handleAddService", serviceName);
    dispatch(editServiceTrigger(serviceData, serviceId));
    navigate("/add-service");
  };

  const handleDeleteService = () => {
    dispatch(deleteService(supplierId, serviceName, serviceId));
  };

  useEffect(() => { }, [imageUrl, permissions]);

  // console.log("ServiceCard() ",supplierId,
  // serviceName,
  // description,
  // serviceDetails,
  // usps,
  // imageUrl,
  // isPublicProfile,
  // serviceData,
  // permissions);

  // console.log("usps",usps?.usps )

  const serviceImgUrl = imageUrl
    ? `${BASE_URL}/${imageUrl}`
    : DEFAULT_IMAGES.SERVICE;
  // console.log("ServiceCard() ", imageUrl, serviceImgUrl, permissions);

  return (
    <Grid container>
      <Grid container justifyContent="space-between">
        <Grid container justifyContent="flex-start" xs={8}>
          <Grid item margin={"0 20px 0 0"}>
            <img src="/assets/icons/business.png" alt="icon" />
          </Grid>
          <Grid item >
            <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
              {serviceName}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          {permissions && permissions.isUpdateAllowed && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                textAlign: "right",
              }}
            >
              <>
                <Typography variant="body2" sx={{ mr: 0 }}>
                  <Tooltip title="Edit">
                    <IconButton
                      disabled={!permissions?.isOwner}
                      sx={{ color: "primary.main" }}
                      onClick={handleEditService}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Typography>

                <Typography variant="body2">
                  <Tooltip title="Delete">
                    <IconButton
                      disabled={!permissions?.isOwner}
                      sx={{ color: "primary.main" }}
                      onClick={handleDeleteService}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </>
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider
        xs={12}
        sx={{
          border: " 1px solid",
          width: "100%",
          margin: "8px 0",
          borderColor: "rgb(227, 220, 82)",
        }}
      />

      <Grid container xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          sm={5}
          md={5.5}
          sx={
            {
              // height: "126px",marginBottom:"20px"
            }
          }
        >
          <ImageEditor
            type={IMAGE_TYPE.SERVICE}
            name={serviceName}
            supplierId={supplierId}
            style={{ height: "126px" }}
            inImage={
              imageUrl ? `${BASE_URL}/${imageUrl}` : DEFAULT_IMAGES.SERVICE
            }
            id={serviceId}
          />
        </Grid>

        <Grid item xs={12} sm={7} md={6.5}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0.5}
          >
            <Grid item md={12}>
              <Grid container flexDirection={"column"}>
                {usps?.map((item, index) => (
                  <Grid
                    item
                    md={12}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {index < 3 && (
                      <ListItem alignItems="start" disablePadding dense={true}>
                        <ArrowRightIcon
                          sx={{
                            display: "list-item",
                            ml: 1,
                            color: "primary.main",
                          }}
                        />
                        <ListItemText sx={{ marginTop: "0" }}>
                          <Typography variant="businessBody">{item}</Typography>
                        </ListItemText>
                      </ListItem>
                    )}
                  </Grid>
                ))}
                <Typography>{serviceDetails}</Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Grid xs={12}>
        <Typography variant="businessBody">{description}</Typography>
      </Grid>
    </Grid>
  );
}

ServiceCard.defaultProps = {
  isPublicProfile: true,
};

ServiceCard.propTypes = {
  serviceName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  serviceDetails: PropTypes.string,
  imageUrl: PropTypes.string,
  isPublicProfile: PropTypes.bool,
  serviceData: PropTypes.object,
  supplierId: PropTypes.number,
  usps: PropTypes.array,
  permissions: PropTypes.object,
  serviceId: PropTypes.string,
};

export default ServiceCard;
