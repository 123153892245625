import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const FullComparison = () => {
    const location = useLocation();
    const rfqData = location.state?.rfqData;
    const { rfqType, suppliers, otherCharges } = rfqData || {};

    const sortedSuppliers = rfqData.suppliers
        .filter(supplier => supplier?.quotationReceived)
        .sort((a, b) => {
            const rankA = a.vendorQuotations?.rank; // Only keep rank
            const rankB = b.vendorQuotations?.rank;

            // Convert ranks like "L1", "L2" to numerical values for comparison
            const numA = parseInt(rankA.substring(1)); // Extract the number part (e.g., "1" from "L1")
            const numB = parseInt(rankB.substring(1));

            return numA - numB; // Ascending order
        });


    const navigate = useNavigate()

    const getLineItems = () => {
        if (rfqType === "material") {
            return rfqData?.materials?.map(item => ({
                name: item.name,
                quantity: item.quantity,
                unit: item.unit,
            }));
        } else if (rfqType === "labour") {
            return rfqData?.labourDetails.map(item => ({
                name: item.skill,
                quantity: item.quantity,
            }));
        } else if (rfqType === "contractor") {
            return [{ name: ReactHtmlParser(rfqData.providingFixingDetails) }];
        }
        return [];
    };

    const getExtraCharges = () => {
        return Object.keys(otherCharges || {}).filter(charge => otherCharges[charge]);
    };

    const lineItems = getLineItems();
    const extraChargesKeys = getExtraCharges();

    const getVendorQuotationData = (supplier, index) => {
        const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
        let vendorItem = {};

        if (rfqType === "material") {
            vendorItem = vendorQuotation?.materialDetails?.[index] || {};
        } else if (rfqType === "labour") {
            vendorItem = vendorQuotation?.labourItems?.[index] || {};
        } else if (rfqType === "contractor") {
            vendorItem = vendorQuotation?.contractorDetails || {};
        }

        return {
            ...vendorItem,
            subTotal: vendorQuotation?.totalWithGst,
            grandTotal: supplier.vendorQuotations?.grandTotalWithGst,
            otherCharges: vendorQuotation?.otherCharges || {}
        };
    };

    return (
        <>
            <div className='sticky top-0 shadow-md flex justify-between bg-white w-full z-50 py-4'>
                <div className='w-2/12 px-12'>
                    <button onClick={(() => navigate(-1))} className='bg-[#EDEDE9] px-8 py-2 text-sm'>Back</button>
                </div>
                <h3 className='text-xl text-[#484848] w-11/12 font-semibold text-center'>Full Comparison</h3>
            </div>

            <div className="overflow-x-auto">
                <table className="max-w-[400px]">
                    <thead>
                        {/* Header for Vendor Names */}
                        <tr>
                            <td className="sticky left-0 z-0 bg-white min-w-[200px]"></td>
                            {sortedSuppliers?.map((supplier, idx) => (
                                <th key={idx} className="py-4 px-2 m-2 min-w-[360px] text-left">
                                    <div className="flex items-center">
                                        <hr className="border-t border-[#070707] flex-grow" />
                                        <span className="mx-2 font-bold text-sm">
                                            {supplier.businessName} (Rank: {supplier.vendorQuotations?.rank} - Rs. {supplier.vendorQuotations?.grandTotalWithGst.toFixed(2)})
                                        </span>

                                        <hr className="border-t border-[#070707] flex-grow" />
                                    </div>
                                </th>
                            ))}
                        </tr>

                        {/* Line Item Headers */}
                        <tr>
                            <th className="sticky mx-2 left-0 bg-white px-4 min-w-[200px] text-xs font-normal text-left pb-2 text-gray-500">
                                Line Items
                            </th>
                            {sortedSuppliers?.map((_, idx) => (
                                <td key={idx} className="px-2">
                                    <div className='grid grid-cols-12 text-[10px] min-w-[360px] p-2 uppercase text-gray-500'>
                                        {rfqType === "material" && (
                                            <>
                                                <p className='col-span-2 text-right border border-white'>Unit Cost</p>
                                                <p className='col-span-2 text-right border border-white'>Disc. (%)</p>
                                                <p className='col-span-2 text-right border border-white'>GST (%)</p>
                                                <p className='col-span-3 text-right border border-white'>Delivery Date</p>
                                                <p className='col-span-3 text-right border border-white'>Total (Rs.)</p>
                                            </>
                                        )}
                                        {rfqType === "labour" && (
                                            <>
                                                <p className='col-span-3 text-right border border-white'>Labour Rate</p>
                                                <p className='col-span-2 text-right border border-white'>Quantity</p>
                                                <p className='col-span-2 text-right border border-white'>GST (%)</p>
                                                <p className='col-span-3 text-center border border-white'>Start Date</p>
                                                <p className='col-span-2 text-right border border-white'>Total (Rs.)</p>
                                            </>
                                        )}
                                        {rfqType === "contractor" && (
                                            <>
                                                <p className='col-span-3 text-right border border-white'>Base Price</p>
                                                <p className='col-span-3 text-right border border-white'>GST (%)</p>
                                                <p className='col-span-3 text-right border border-white'>Start Date</p>
                                                <p className='col-span-3 text-right border border-white'>Total (Rs.)</p>
                                            </>
                                        )}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {/* Line Items Mapping */}
                        {lineItems.map((item, index) => (
                            <tr key={index}>
                                <td className={`z-20 sticky left-0 px-4 py-2 my-2 text-xs border border-gray-300 ${index % 2 === 0 ? 'bg-blue-50' : 'bg-white'}`}>
                                    <p className="font-medium text-[#707070] text-xs">{item.name}</p>
                                    {item.quantity && <p className='text-[10px]'>{item.quantity} {item.unit || ''}</p>}
                                </td>
                                {sortedSuppliers?.map((supplier, vendorIdx) => {
                                    const vendorData = getVendorQuotationData(supplier, index);
                                    return (
                                        <td key={vendorIdx} className={`px-2 ${vendorIdx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                            <div className={`text-xs border grid grid-cols-12 h-full ${index % 2 === 0 ? 'bg-blue-50' : ''}`}>
                                                {rfqType === "material" && (
                                                    <>
                                                        <p className='border text-[10px] px-1 py-4 text-right h-full col-span-2'>{vendorData.basePrice || 0}</p>
                                                        <p className='border px-2 py-4 text-right col-span-2'>{vendorData.discount || 0}</p>
                                                        <p className='border px-2 py-4 text-right col-span-2'>{vendorData.gst || 0}</p>
                                                        <p className="text-gray-500 border col-span-3 px-1 py-4 text-right">{new Date(vendorData.deliveryDate).toLocaleDateString('en-GB') || ""}</p>
                                                        <p className="text-gray-500 text-right border col-span-3 px-1 py-4">{vendorData?.netPrice?.toFixed(2) || ''}</p>
                                                    </>
                                                )}
                                                {rfqType === "labour" && (
                                                    <>
                                                        <p className='border px-2 py-4 text-right col-span-3'>{vendorData.labourRate || ''}</p>
                                                        <p className='border px-2 py-4 text-right col-span-2'>{vendorData.quantity || ''}</p>
                                                        <p className='border px-2 py-4 text-right col-span-2'>{vendorData.gst || ''}</p>
                                                        <p className="text-gray-500 border col-span-3 px-1 py-4 text-right">{new Date(vendorData.workStartDate).toLocaleDateString('en-GB') || ""}</p>
                                                        <p className="text-gray-500 text-right border col-span-2 px-1 py-4">{vendorData?.totalAmount?.toFixed(2) || ''}</p>
                                                    </>
                                                )}
                                                {rfqType === "contractor" && (
                                                    <>
                                                        <p className='border px-2 py-4 text-right col-span-3'>{vendorData.basePrice || ''}</p>
                                                        <p className='border px-2 py-4 text-right col-span-3'>{vendorData.gst || ''}</p>
                                                        <p className="text-gray-500 border col-span-3 px-1 py-4 text-right">{new Date(vendorData.workStartDate).toLocaleDateString('en-GB') || ""}</p>
                                                        <p className="text-gray-500 text-right border col-span-3 px-1 py-4">{vendorData?.netPrice?.toFixed(2) || ''}</p>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}

                        {/* Sub Total Row */}
                        <tr>
                            <td className="sticky left-0 bg-white shadow-md pl-4 min-w-[200px] text-sm font-semibold">Sub Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full mt-3 mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                        <div className="border-t border-t-[#004491] mx-[10px] mt-2">
                                            <p className="font-bold text-sm text-right mt-[7px] text-[#004491]">
                                                <span className='bg-[#E7EAEF] px-6 py-2'>Rs. {vendorData.subTotal}</span> {/* Adjusted padding */}
                                            </p>
                                        </div>
                                    </td>


                                );
                            })}
                        </tr>

                        {/* Extra Charges Section */}
                        <tr>
                            <td className="sticky left-0 text-[#5D5D5D] font-semibold bg-white px-4 pt-2 ">Extra Charges</td>
                            {sortedSuppliers?.map((_, idx) => (
                                <td key={idx} className={`p-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}></td>
                            ))}
                        </tr>

                        <tr>
                            <td className="sticky left-0 bg-white px-4"></td>
                            {sortedSuppliers?.map((_, idx) => (
                                <td key={idx} className={`px-2 ${idx < sortedSuppliers?.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                    <div className="grid grid-cols-3 w-full pl-2 text-[10px] mb-2 ml-auto text-right text-gray-500">
                                        <p>GST (%)</p>
                                        <p className='text-right'>Amount (Rs.)</p>
                                        <p>Total Amount (Rs.)</p>
                                    </div>
                                </td>
                            ))}
                        </tr>

                        {extraChargesKeys.map((chargeKey, index) => (
                            <tr key={index}>
                                <td className="sticky left-0 bg-white shadow-md min-w-[200px] px-4">
                                    <div className="flex items-center text-sm text-center text-[#707070]">
                                        <p>{chargeKey.charAt(0).toUpperCase() + chargeKey.slice(1)}</p>
                                    </div>
                                </td>
                                {sortedSuppliers?.map((supplier, vendorIdx) => {
                                    const vendorData = getVendorQuotationData(supplier, 0);
                                    return (
                                        <td key={vendorIdx} className={`px-2 ${vendorIdx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                            <div className="text-xs grid grid-cols-3 text-right border border-gray-300 w-3/4 ml-auto">
                                                <p className='border p-2'>{vendorData.otherCharges[chargeKey]?.gst || 0}</p>
                                                <p className='border p-2'>{vendorData.otherCharges[chargeKey]?.mrp || 0}</p>
                                                <p className="text-gray-500 border p-2">{vendorData.otherCharges[chargeKey]?.totalCost || 0}</p>
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}

                        {/* Grand Total Row */}
                        <tr>
                            <td className="sticky left-0 text-sm px-4 bg-white shadow-md min-w-[200px] font-semibold">Grand Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full mt-3 mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                        <div className="border-t border-t-[#004491] mx-[10px] mt-2">
                                            <p className="font-bold text-sm text-right mt-[7px] text-[#004491]">
                                                <span className='bg-[#E0F0EE] px-6 py-2'>Rs. {vendorData.grandTotal}</span> {/* Adjusted padding */}
                                            </p>
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <td className="sticky left-0 text-sm px-4 pt-4 pb-8 bg-white shadow-md min-w-[200px] font-semibold">
                                Difference from Lowest
                            </td>
                            {sortedSuppliers.map((supplier, idx) => {
                                const vendorQuotation = supplier.vendorQuotations;

                                // Use the appropriate grand total based on withGst state
                                const grandTotal = vendorQuotation.grandTotalWithGst


                                // Calculate L1 supplier data
                                const l1Supplier = sortedSuppliers[0]; // Assuming sortedSuppliers is sorted by total
                                const l1Total = l1Supplier ? l1Supplier.vendorQuotations.grandTotalWithGst : 0;

                                // Calculate deviation
                                const deviation = l1Total ? grandTotal - l1Total : 0;
                                const deviationPercentage = l1Total ? ((deviation / l1Total) * 100).toFixed(2) : 0;
                                // map the data here
                                return (
                                    <td key={idx} className={`${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                        {idx === 0 ? (
                                            <p className='text-sm text-right pt-2 font-medium mr-1'>Lowest Quote</p>
                                        ) : (
                                            <p className='text-sm text-right pt-2 mr-1'>
                                                Rs. {deviation.toFixed(2)} ({deviationPercentage}%)
                                            </p>
                                        )}
                                    </td>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default FullComparison;
