import React, { useState } from "react";
import MobileSearch from "../MobileSearchComponet/MobileSearch";

const SearchButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white h-[62px] w-[310px] rounded-full flex items-center my-6">
      <button 
      onClick={()=>setIsOpen(true)}
      className="flex items-center">
        <img
          src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_VectorSearch.svg"
          alt=""
          className="h-[22px] w-[21px] mx-5 "
        />
        <div>
          <p className="text-[#232323] font-bold text-base text-start">
            Search Vendors
          </p>
          <div className="flex items-center">
            <p className="text-[#868686] text-sm ">Any type</p>
            <div className="h-1.5 w-1.5 rounded-full bg-[#868686] mx-1.5"></div>
            <p className="text-[#868686] text-sm ">Any service</p>
            <div className="h-1.5 w-1.5 rounded-full bg-[#868686] mx-1.5"></div>
            <p className="text-[#868686] text-sm ">Any city</p>
          </div>
        </div>
      </button>
      <MobileSearch isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default SearchButton;
