import axios from "utils/axios";

import { IMAGE_TYPE } from "config";
import {
  UPLOAD_IMAGE_LOGO_URL,
  UPLOAD_IMAGE_BANNER_URL,
  UPLOAD_SERVICE_IMAGE_URL,
  UPLOAD_PROJECT_IMAGE_URL,
  UPLOAD_SEARCH_IMAGE_URL,
  DELETE_BANNER_IMG_URL,
  DELETE_LOGO_IMG_URL
} from "config/services";

class ImageService {
  uploadImage = (data, type, name, supplierId, id) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      let requestURL = "";

      // console.log("ImageService.imageUpload() :",data, type, name );

      formData.append("supplierId", supplierId);
      switch (type) {
        case IMAGE_TYPE.LOGO:
          formData.append("logoFile", data);
          requestURL = UPLOAD_IMAGE_LOGO_URL;
          break;

        case IMAGE_TYPE.BANNER:
          formData.append("bannerFile", data);
          requestURL = UPLOAD_IMAGE_BANNER_URL;
          break;

        case IMAGE_TYPE.SERVICE:
          formData.append("serviceFile", data);
          formData.append("serviceName", name);
          formData.append("id", id);
          requestURL = UPLOAD_SERVICE_IMAGE_URL;
          break;

        case IMAGE_TYPE.PROJECT:
          formData.append("projectFile", data);
          formData.append("projectName", name);
          formData.append("id", id);
          requestURL = UPLOAD_PROJECT_IMAGE_URL;
          break;

        case IMAGE_TYPE.SEARCH:
          formData.append("searchFile", data);
          formData.append("searchName", name);
          requestURL = UPLOAD_SEARCH_IMAGE_URL;
        case IMAGE_TYPE.CERTIFICATE:
          formData.append("certificateName", name);
          requestURL = "";
          break;

        default:
          break;
      }
      //
      // formData.append("Name", name);
      // formData.append("profileImage", file);
      // formData.append("type", type);
      if (requestURL && requestURL !== "") {
        axios
          .post(requestURL, formData)
          .then((response) => {
            // console.log("ImageService:imageUpload: Response", response.data);

            if (response.data.error) {
              // console.log(
              //   "ProfileImageService:imageUpload",
              //   response.error,
              //   response.data
              // );
              reject(response.data.message);
            } else {
              resolve(response?.data?.payload);
            }
          })
          .catch((error) => {
            if (error.response) {
              reject(error?.response?.data);
            }

            reject(error);
          });
      }
    });

    deleteImage = (type, supplierId, id) =>
    new Promise((resolve, reject) => {
      let url = "";
      // console.log("url", url)
      let payload = undefined; 
      switch(type){
        case IMAGE_TYPE.BANNER:
          url = DELETE_BANNER_IMG_URL;
          payload = {
            supplierId
          }
        break;
        case IMAGE_TYPE.LOGO:
          url = DELETE_LOGO_IMG_URL;
          payload = {
            supplierId
          }
        break;
        default:
        break;
      }
     
      axios
        .post(url, payload )
        .then((response) => {

          if (response.data.error) {
            reject(response?.data?.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });
}

const imageService = new ImageService();

export default imageService;
