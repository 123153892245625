import { Delete } from "@mui/icons-material";
import { Card, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { deleteService, editServiceTrigger } from "actions/serviceActions";
import { ImageEditor } from "components";
import { IMAGE_TYPE } from "config";
import { API_BASE_URL } from "config";
import { DEFAULT_IMAGES } from "config";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function BusinessOfferingCard({
  data,
  permissions,
  PublicProfile,
  imageUrl,
  profile,
  isEditView,
}) {

  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const businessName = business?.profile?.businessName;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEditService = () => {
    dispatch(editServiceTrigger(data, data._id));
    navigate("/add-service");
  };

  const handleDeleteService = () => {
    dispatch(deleteService(supplierId, data.serviceName, data._id,businessName));
  };
  return (
    <Grid marginX={"10px"}>
      <Card
        sx={{
          border: "1px solid rgba(217, 217, 217, 1)",
          margin: "auto",
          minHeight: "250px",
          marginBottom: "12px",
          // paddingX:"14px"
        }}
      >
        {" "}
        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              paddingX: "10px",
              paddingTop: "6px",
              fontSize: "16px",
            }}
          >
            {data.serviceName}
          </Typography>
          <Grid>
            {permissions && permissions.isUpdateAllowed && (
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "end",
                  // textAlign: "right",
                }}
              >
                <>
                  <Typography sx={{ mr: 0 }}>
                    <IconButton
                      disabled={!permissions?.isOwner}
                      // sx={{ color: "primary.main" }}
                      onClick={handleEditService}
                    >
                      <Link
                        style={{
                          fontSize: "14px",
                          color: "#2864BE",
                          textDecoration: "underline",
                        }}
                      >
                        {" "}
                        Edit
                      </Link>
                    </IconButton>
                  </Typography>

                  <Typography>
                    <IconButton
                      disabled={!permissions?.isOwner}
                      sx={{ color: "primary.main" }}
                      onClick={handleDeleteService}
                    >
                      <Delete style={{ fontSize: "18px", color: "#2864BE" }} />
                    </IconButton>
                  </Typography>
                </>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          sx={{
            borderBottom: "1px solid rgba(217, 217, 217, 1)",
            marginBottom: "10px",
          }}
        ></Grid>
        <Grid container style={{ display: "flex" }}>
          <Grid xs={6}>
            
            {isEditView ? (
              <ImageEditor
                type={IMAGE_TYPE.SERVICE}
                name={data.serviceName}
                supplierId={profile?.supplierId}
                style={{ height: "126px" }}
                inImage={
                  imageUrl
                    ? `${BASE_URL}/${imageUrl}`
                    : DEFAULT_IMAGES.SERVICE
                }
                id={data._id}
              />
            ) : (
              <div style={{margin:"auto"}}>
                <Box
                component="img"
                alt="img"
                src={
                  data?.bannerUrl
                    ? `${BASE_URL}/${data?.bannerUrl}`
                    : DEFAULT_IMAGES.SERVICE
                }
                sx={{maxWidth: "185px",margin:"auto" ,marginBottom: "8px" }}
              />
              </div>
            )}
          </Grid>
          <Grid xs={6}>
            {data?.usps?.map(
              (point, index) =>
                index < 3 && (
                  <Grid
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      gap: "8px",
                      marginBottom: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "6px",
                        width: "6px",
                        backgroundColor: "black",
                        marginLeft: "10px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        // width: "200px",
                        // "&:hover": {
                        //   whiteSpace: "normal",
                        // },
                        // whiteSpace: "nowrap",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                      }}
                    >
                      {point}
                    </Typography>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default BusinessOfferingCard;
