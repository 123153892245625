import axios from "utils/axios";
import {
  ADD_TO_GLOBAL_TAGS,
  GET_ALL_GLOBAL_TAGS,
  GET_TAGS_BY_ID,
} from "config/services";

class TagsService {
  fetchTags = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_GLOBAL_TAGS)
        .then((response) => {
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("tags error", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  fetchTagByUserId = ({ entityId, userId }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${GET_TAGS_BY_ID}?userId=${userId}&entityId=${entityId}`)
        .then((response) => {
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            if (response?.data) {
              resolve(response?.data);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("tags error", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  addTags = (tag) =>
    new Promise((resolve, reject) => {
      axios
        .post(ADD_TO_GLOBAL_TAGS, tag)
        .then((response) => {
          if (response?.data?.error) {
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("tags error", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const tagsService = new TagsService();

export default tagsService;
