import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Topbar } from "layouts/Main/components";
import pages from "layouts/navigation";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { search as searchAction } from "actions/searchActions";
import { useDispatch, useSelector } from "react-redux";
import { FloatingButton, GlobalNavbar } from "views/Home 3.0/components";
import { sendNewRFQTrigger } from "actions/requirementsAction";
import RequirementFormModal from "views/RequirementFormModal/RequirementFormModal";
import NonEntityRequirmentForm from "views/RequirementFormModal/NonEntityRequirmentForm";

const SearchTopSection = ({
  checkedItems,
  searchType,
  searchKeyWord = "searchKeyWord",
  numFound,
  showBackBtn = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;
  const [openSendRfqModel, setOpenSendRfqModel] = useState(false);
  const typeMap = {
    byService: "By Service/Products",
    byName: "By Company",
    all: "All Profiles",
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("Category");

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down(1280));
  const type = typeMap[searchType] || "";
  const { region } = useParams();
  const regionString =
    region === "Pan-India" ? "PAN India" : region?.replace(/-/g, " ");

  const openPreviousPage = () => {
    dispatch(searchAction(searchKeyWord));
    navigate(`/search/${region}/${searchKeyWord}`);
  };
  const handleMultipleBusiness = async (data) => {
    dispatch(sendNewRFQTrigger(data));
    setOpenSendRfqModel(true);
  };

  return (
    <>
      {/* <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: "#262728",
          color: "black",
        }}
      >
        <Topbar pages={pages} /> */}
      {/* </AppBar> */}
      <GlobalNavbar />
      <Box
        padding={"0px 24px 0px"}
        paddingTop={isMd ? "18px" : "8px"}
        flexWrap={"wrap"}
        zIndex={3}
        position={"sticky"}
        backgroundColor={"#EFF7FA"}
        top={"50px"}
      >
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid
            display="flex"
            flexWrap={"wrap"}
            marginBottom={"4px"}
            paddingTop={isSm && "16px"}
          >
            <Typography
              sx={{ color: "rgba(102, 102, 102, 1)", fontSize: "12px" }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                to="/"
              >
                Home{" "}
              </Link>{" "}
              » {regionString} » {category} »
            </Typography>
            <Typography
              color={"rgba(102, 102, 102, 1)"}
              marginLeft={"4px"}
              textTransform={"capitalize"}
              fontSize={"12px"}
            >
              {" "}
              {searchKeyWord}{" "}
            </Typography>
          </Grid>
          {/* {showBackBtn && (
            <Typography
              style={{
                fontSize: "12px",
                color: "#00AB6B",
                marginRight: "18px",
                // marginBottom: "12px",
                cursor: "pointer",
                paddingTop: isSm && "14px",
              }}
              onClick={openPreviousPage}
            >
              {"<<"} Back
            </Typography>
          )} */}
        </Grid>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingBottom={"10px"}
        >
          <Grid>
            <h1 style={{ fontWeight: 400, fontSize: "20px" }}>
              {" "}
              Showing {numFound > 1 ? "Results" : "Result"} for{" "}
              <strong style={{ textTransform: "capitalize" }}>
                {" "}
                {searchKeyWord}
              </strong>{" "}
              {`(${regionString})`}
              {/* {`(PAN-India)`} */}
            </h1>
          </Grid>

          {!isSm && numFound > 0 && userProfile?.isVerified && (
            <Button
              disabled={checkedItems == 0}
              sx={{
                borderRadius: "17px",
                padding: 0,
                height: "34px",
                color: checkedItems == 0 ? "black" : "white",
                backgroundColor:
                  checkedItems == 0 ? "rgb(142 152 152)" : "#002E33",
                "&:hover": {
                  backgroundColor:
                    checkedItems == 0 ? "rgb(142 152 152)" : "#002E33",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                  // color: "black",
                },
              }}
              onClick={() => handleMultipleBusiness(checkedItems)}
            >
              <Typography
                fontSize="16px"
                paddingX="12px"
                fontWeight="500"
                color={checkedItems == 0 ? "#F8F8F8" : "white"}
              >
                {entityId
                  ? checkedItems.length > 0 ? `Send Requirement to ${checkedItems.length} ${checkedItems.length < 2 ? "company" : "companies"
                    }` : "Send Requirement"
                  : checkedItems.length > 0 ? `Send Message to ${checkedItems.length} ${checkedItems.length < 2 ? "company" : "companies"
                    }` : "Send Message "}
              </Typography>
            </Button>
          )}
        </Grid>

        {isSm && numFound > 0 &&  userProfile?.isVerified  && (
          <Grid
            sx={{
              // marginTop: "15px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            {/* <Tooltip style={{fontSize:"16px"}} title={!checkedItems == 0&&entityId?"Please select at least one business to contact the vendor":"Please select at least one business to send message"}> */}
            <Button
              disabled={checkedItems == 0}
              sx={{
                marginBottom: "10px",
                borderRadius: "17px",
                padding: 0,
                height: "34px",
                color: "black",
                backgroundColor:
                  checkedItems == 0 ? "rgb(142 152 152)" : "#002E33",
                "&:hover": {
                  backgroundColor:
                    checkedItems == 0 ? "rgb(142 152 152)" : "#002E33",
                  boxShadow: "-1px 0px 29px 0px rgba(0,0,0,0.8)",
                },
              }}
              onClick={() => handleMultipleBusiness(checkedItems)}
            >
              <Typography
                fontSize="16px"
                paddingX="12px"
                fontWeight="500"
                color={"white"}
              >
                {entityId
                  ? checkedItems.length > 0 ? `Send Requirement to ${checkedItems.length} ${checkedItems.length < 2 ? "company" : "companies"
                    }` : "Send Requirement"
                  : checkedItems.length > 0 ? `Send Message to ${checkedItems.length} ${checkedItems.length < 2 ? "company" : "companies"
                    }` : "Send Message "}
              </Typography>
            </Button>
            {/* </Tooltip> */}
          </Grid>
        )}
        {entityId ? (
          <RequirementFormModal
            open={openSendRfqModel}
            setOpen={setOpenSendRfqModel}
          />
        ) : (
          <NonEntityRequirmentForm
            open={openSendRfqModel}
            setOpen={setOpenSendRfqModel}
          />
        )}
      </Box>
    </>
  );
};

export default SearchTopSection;
