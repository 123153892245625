import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
 Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import { Helmet } from "react-helmet";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "utils/axios";

import {
  CREATE_CRM_LEAD_URL,
  GET_CRM_LEAD_BY_PHONE_NUMBER,
} from "config/services";
import { updateCrmId } from "actions/authActions";
import { makeStyles } from "@material-ui/core";
import VerificationPopUp from "views/VerificationModal";


const BASE_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  boxCurser:{},
  grid: {
    width: "200px",
    height: "80px",
    background: "#FFFFFF",
    border: "0.5px solid #B9B9B9",
    borderRadius: "10px",
    boxShadow: "0px 20px 70px rgba(86, 89, 146, 0.1)",
    cursor: "pointer",
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "0px 4px 20px 4px rgba(0, 0, 0, 0.35)",
    },
  },

}));

function Dashboard() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  const [isModalOpen, setIsModalOpen] = useState(true);


  // Function to handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!userProfile) {
    navigate("/register");
  } else if (userProfile?.userId > 0 && userProfile?.name) {
    } else {
      navigate("/register/user");
    }
  
  const requirement = useSelector((state) => state.requirement);

  const lead = useSelector((state) => state.lead);

  const favourite = useSelector((state) => state.favourite);

  const notification = useSelector((state) => state.notification);
  const business = useSelector((state) => state.business);
  const logoUrl = business?.profile?.businessProfile?.logoUrl;
 
  let logoSrcUrl = DEFAULT_IMAGES.LOGO;
  if (logoUrl && logoUrl !== "") {
    if (logoUrl.startsWith("/assets/business")) {
      const assetsPortion = logoUrl.slice(0, logoUrl.indexOf("/business"));
      logoSrcUrl = `${BASE_URL}${logoUrl}`;
    } else {
      logoSrcUrl = logoUrl;
    }
  }



  const kpis = [
    { label: "Requirements", value: requirement?.count },
    { label: "Leads", value: lead?.count },
    { label: "Notifications", value: notification?.count },
    { label: "Favourites", value: favourite?.count },
  ];

  const [profileViews, setProfileViews] = useState(0);
  const [crmLeadIdOfUser, setCrmLeadIdOfUser] = useState(
    userProfile?.crmLeadId
  );
  const getCrmOfUserByMobileUrl = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(
    userProfile?.mobile
  );
  const userIdOfUser = userProfile?.userId;
  useEffect(() => {
    if (!userProfile?.crmLeadId || userProfile?.crmLeadId == null) {
      axios.get(getCrmOfUserByMobileUrl).then((response) => {
        setCrmLeadIdOfUser(response.data[0].ProspectID);
        const crmLeadid1 = response.data[0].ProspectID;
        dispatch(updateCrmId(userIdOfUser, crmLeadid1));
      });
    } else {
      const payload = [
        {
          Attribute: "EmailAddress",
          Value: userProfile?.email,
        },
        {
          Attribute: "FirstName",
          Value: userProfile?.name,
        },
        {
          Attribute: "Phone",
          Value: userProfile?.mobile,
        },
      ];

      axios.post(CREATE_CRM_LEAD_URL, payload).then((crmResponse) => {
        // console.log(":CREATE_CRM_LEAD: Response",crmResponse)
        setCrmLeadIdOfUser(crmResponse?.data.Message.Id);
        // dispatch(getCRMLeadByPhone(values.mobile))
        const crmLeadIdNew = crmResponse?.data.Message.Id;
        dispatch(updateCrmId(userIdOfUser, crmLeadIdNew));
      });
    }
  }, []);


  const capitalizeString = (str) => {
    if (str) { return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(1); }
    return;
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Page >
        {
          isMd
            ? <Box sx={{ flexGrow: 1, minHeight: "85vh" }}>
              
              <Grid xs={12} style={{ dispaly: "flex", flexDirection: "column" }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "35px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: "Roboto",

                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "21px",
                        marginTop:"20px",
                        marginLeft:"20px",
                        color: "#000000",
                      }}
                    >
                      Dashboard
                    </Typography>
                  </Grid>
                   </Grid>
                <Grid
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E8ECF5",
                    boxShadow: " 0px 20px 70px rgba(57, 99, 171, 0.15)",
                    borderRadius: "8px",
                  }}
                >
                  <Grid
                    xs={3.5}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderRight: "1px solid #E8ECF5",
                    }}
                  >
                    <Grid>
                      <Avatar
                        alt={userProfile?.name}
                        src={`${BASE_URL}/${userProfile?.imgUrl}`}
                        sx={{
                          width: "75px",
                          height: "75px",
                          margin: "0 auto",
                          marginTop: "20px",
                        }}
                      />
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          marginTop: "15px",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "30px",
                          color: "rgba(0, 0, 0, 0.9)",
                          textAlign: "center",
                        }}
                      >
                        {capitalizeString(userProfile?.name)}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "300",
                          fontSize: "12px",
                          lineHeight: "20px",
                          color: "rgba(0, 0, 0, 0.9)",
                          textAlign: "center",
                          marginTop: "-5px",
                        }}
                      >
                        {capitalizeString(userProfile?.roleInCompany)}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "18px",
                          color: "rgba(0, 0, 0, 0.6)",
                          textAlign: "center",
                        }}
                      >
                        {userProfile?.company}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "18px",
                          color: "rgba(0, 0, 0, 0.6)",
                          textAlign: "center",
                        }}
                      >
                        {userProfile?.email}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "rgba(0, 0, 0, 0.9)",
                          textAlign: "center",
                        }}
                      >
                        {userProfile?.mobile}
                      </Typography>
                      <Grid textAlign={"center"} >
                        <Button
                          variant="contained"
                          // color="secondary"
                          sx={{
                            margin: "23px auto",
                            // background: " #3963AA",
                            borderRadius: "14.5px",
                            width: "122px",
                            height: "28px",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "18px",
                            color:"white",
                            backgroundColor:"#FD6600",
                              color: 'white',
                              '&:hover': {
                                backgroundColor: '#FD6600',
                                boxShadow:  '0px 0px 10px 0px rgba(0,0,0,0.7)',
                                transition: "background-color 0.3s, box-shadow 0.3s",
                               },
                          }}
                           onClick={() => navigate("/user-profile")}
                        >
                          Update Profile
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={8.5}>
                    <Grid
                      style={{
                        border: "0.5px solid #E8ECF5",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding:"10px 20px 0px",
                      }} >
                      <Grid>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#000000",
                          paddingBottom:"5px",
                          display: "inline-block",
                          
                        }}
                      >
                        {business?.profile?.businessName}
                      </Typography>
                    <Typography
                    sx={{
                      width:"70%",
                      borderBottom: "3px solid #47B59E"
                    }}
                    >
                       </Typography>
                    </Grid>
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#000000",
                          // padding: "0px 5px",
                          color: "#3963AB",
                        }}
                      >
                        Multiple Business adding feature coming soon! Stay tuned for
                        updates.
                      </Typography>

                    </Grid>
                    <Grid style={{ padding: "18px" }}>
                      <Grid
                      style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          padding: "14px",
                        }}
                      >
                        <Grid
                          container
                           style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",   
                          }}
                        >
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                             }}
                            sx={{
                              "@media (min-width: 1000px)": {
                               maxWidth:"400px"
                              },
                            }}
                          >
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              
                              }}
                            >
                              <Avatar
                                alt={business?.profile?.businessName}
                                src={logoSrcUrl}
                                sx={{
                                  width: "66px",
                                  height: "66px",
                                }}
                              />
                            </Grid>
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems:"start"
                              }}
                            >
                              <Grid
                              sx={{
                                display:"flex",
                                // justifyContent:"space-between",
                                alignItems:"start"
                              }}
                              >
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {business?.profile?.businessName}
                             
                              </Typography>
                              <span style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => navigate("/my-business")}>
                                  <Box
                                    component="img"
                                    sx={{
                                      height: 16,
                                      width: 110,
                                    }}
                                    alt="premium icon"
                                    // src="/assets/icons/EditBusinessIcon.svg"
                                    src="/assets/icons/editIconImage.svg"
                                  />
                                </span>
                              </Grid>
                             {
                               business?.profile?.businessProfile?.addresses[0]
                               ?.city &&   <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LocationOnIcon
                                  style={{
                                    fontSize: "14px",
                                    color: "#47B59E",
                                    marginRight: "4px",
                                  }}
                                />
                                {
                                  business?.profile?.businessProfile?.addresses[0]
                                    ?.city
                                }
                              </Typography>
                             }
                              {/* <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LocationOnIcon
                                  style={{
                                    fontSize: "14px",
                                    color: "#47B59E",
                                    marginRight: "4px",
                                  }}
                                />
                                {
                                  business?.profile?.businessProfile?.addresses[0]
                                    ?.city
                                }
                              </Typography> */}
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                }}
                              >
                                {business?.profile?.businessProfile?.businessEmail}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            style={{
                              width: "201px",
                              height: "66px",
                              border: "1px solid #CBCBCB",
                              borderRadius: "10px",
                              // padding: "13px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              marginLeft:"auto",
                              marginRight:"8px"
                             
                            }}
                            
                          >
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                             
                            >
                              {business?.profile?.subscriptionType == "PREMIUM" ? (
                                <Box
                                  component="img"
                                  sx={{
                                    height: 43,
                                    width: 40,
                                  }}
                                  alt="premium icon"
                                  src="/assets/icons/premium_crown.svg"
                                />
                              ) : (
                                <Box
                                  component="img"
                                  sx={{
                                    height: 43,
                                    width: 40,
                                  }}
                                  alt="premium icon"
                                  src="/assets/icons/basic_shield.svg"
                                />
                              )}
                            </Grid>

                            <Grid
                              style={{
                                width: "122px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",

                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                  color: "#262728",
                                }}
                              >
                                Active Subscription
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  lineHeight: "14px",
                                  color: "#262728",
                                  marginTop: "3px",
                                }}
                              >
                                You are{" "}
                                {business?.profile?.subscriptionType == "PREMIUM"
                                  ? "Premium"
                                  : "Basic"}{" "}
                                member of Neevay.
                              </Typography>
                            </Grid>
                          </Grid>

                        </Grid>

                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "15px",
                            justifyContent: "space-around",

                          }}
                        >

                          <Grid
                            item
                            xs={3.8}
                            className={classes.grid}
                            onClick={() => navigate(`/leads`)}
                          >
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#000000",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              Leads Received
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "600",
                                fontSize: "20px",
                                lineHeight: "22px",
                                color: "#000000",
                                marginTop: "15px",
                                textAlign: "center",
                              }}
                            >
                              {lead.count}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.8}
                            className={classes.grid}
                            onClick={() =>
                              navigate(`/requirements`)
                            }
                          >
                            {" "}
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#000000",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              Enquiries Sent
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "600",
                                fontSize: "20px",
                                lineHeight: "22px",
                                color: "#000000",
                                marginTop: "15px",
                                textAlign: "center",
                              }}
                            >
                              {requirement.count}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.8}
                            className={classes.grid}
                            onClick={() =>
                              navigate(`/shortlisted-profile`)
                            }
                          >
                            {" "}
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#000000",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              Shortlisted Profiles
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "600",
                                fontSize: "20px",
                                lineHeight: "22px",
                                color: "#000000",
                                marginTop: "15px",
                                textAlign: "center",
                              }}
                            >
                              {favourite.count}
                            </Typography>
                          </Grid>
                        </Grid>
                         <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "15px",
                            justifyContent: "space-around",

                          }}
                        > <Grid
                            item
                            xs={3.8}
                            className={classes.grid}
                            onClick={() => navigate(`/company/${business?.profile?.url}`)}
                          >
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#000000",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              No. of Business
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "600",
                                fontSize: "20px",
                                lineHeight: "22px",
                                color: "#000000",
                                marginTop: "15px",
                                textAlign: "center",
                                // paddingBottom:"5px"
                              }}
                            >
                              1
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.8}
                            className={classes.grid}
                            onClick={() =>
                              navigate(`/company/${business?.profile?.url}`)
                            }
                          >
                            {" "}
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#000000",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              Certificates / Awards
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "600",
                                fontSize: "20px",
                                lineHeight: "22px",
                                color: "#000000",
                                marginTop: "15px",
                                textAlign: "center",
                              }}
                            >
                              {/* {business?.profile?.awards?.length +
                                business?.profile?.businessProfile
                                  ?.certificateImages?.length} */}
                                  {business?.profile?.awards?.length || 0 + business?.profile?.businessProfile?.certificateImages?.length || 0}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3.8}
                            className={classes.grid}
                            onClick={() =>
                              navigate(`/company/${business?.profile?.url}`)
                            }
                          >
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#000000",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              Projects Completed
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "600",
                                fontSize: "20px",
                                lineHeight: "22px",
                                color: "#000000",
                                marginTop: "15px",
                                textAlign: "center",
                              }}
                            >
                              {business.projects.length}
                            </Typography>
                          </Grid>





                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Analytics........... */}
                    {/* <Grid
                  style={{
                    padding: "18px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <Grid
                    style={{
                      border: "1px solid #EBEBEB",
                      borderRadius: "4px",
                      padding: "14px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "25px",
                        color: "rgba(0, 0, 0, 0.9)",
                      }}
                    >
                      Analytics
                    </Typography>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        marginTop: "30px",
                      }}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "200px",
                        }}
                      >
                        <Grid>
                          <Box
                            component="img"
                            sx={{
                              height: 20,
                              width: 18,
                              marginRight: "10px",
                            }}
                            src="/assets/icons/analytics_1.svg"
                          />
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "600",
                              fontSize: "14px",
                              lineHeight: "16px",
                              color: "rgba(0, 0, 0, 0.9)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "rgba(0, 0, 0, 0.9)",
                              }}
                            >
                              {profileViews}
                            </span>{" "}
                            Profile Views
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "rgba(0, 0, 0, 0.9)",
                              marginTop: "3px",
                            }}
                          >
                            Discover who's viewed your profile.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "200px",
                        }}
                      >
                        <Grid>
                          <Box
                            component="img"
                            sx={{
                              height: 20,
                              width: 18,
                              marginRight: "10px",
                            }}
                            src="/assets/icons/analytics_2.svg"
                          />
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "600",
                              fontSize: "14px",
                              lineHeight: "16px",
                              color: "rgba(0, 0, 0, 0.9)",
                            }}
                          >
                            63 Search Appearances
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "rgba(0, 0, 0, 0.9)",
                              marginTop: "3px",
                            }}
                          >
                            How often do I show up in search results?
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "200px",
                        }}
                      >
                        <Grid>
                          <Box
                            component="img"
                            sx={{
                              height: 20,
                              width: 18,
                              marginRight: "10px",
                            }}
                            src="/assets/icons/analytics_3.svg"
                          />
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "600",
                              fontSize: "14px",
                              lineHeight: "16px",
                              color: "rgba(0, 0, 0, 0.9)",
                            }}
                          >
                            30 Website Visits{" "}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "rgba(0, 0, 0, 0.9)",
                              marginTop: "3px",
                            }}
                          >
                            Check how many people visited your website
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
                    {/* <Grid style={{ padding: "18px" }}>
                  <Grid
                    style={{
                      border: "1px solid #EBEBEB",
                      borderRadius: "4px",
                      padding: "14px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "25px",
                        color: "rgba(0, 0, 0, 0.9)",
                      }}
                    >
                      Recommendations
                    </Typography>
                    
                  </Grid>
                </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            :
            <Grid>
              {/* <Grid

                display={"flex"}
              > */}

              {/* <Avatar
                  alt={userProfile?.name}
                  src={`${BASE_URL}/${userProfile?.imgUrl}`}
                  sx={{
                    width: "39px",
                    height: "38px",

                  }}
                /> */}
              {/* <Grid sx={{ display: "flex", alignItems: "end" }}>
                  {
                    isOpen 
                    ?<KeyboardArrowUpIcon onClick={toggleOpen}/>
                    :<KeyboardArrowDownIcon onClick={toggleOpen} />
                  }
                  
                  
                </Grid> */}

              {/* </Grid> */}

              {/* { */}
              {/* // isOpen && */}
              <Grid>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "19px",
                    marginTop:"20px",
                    marginLeft:"10px"
                  }}
                >
                  Personal Details
                </Typography>
              </Grid>
              <Divider
                sx={{
                  marginTop: "5px"
                }}
              />
              <Grid>
                {/* <Avatar
                  alt={userProfile?.name}
                  src={`${BASE_URL}/${userProfile?.imgUrl}`}
                  sx={{
                    width: "75px",
                    height: "75px",
                    margin: "0 auto",
                    marginTop: "20px",
                  }}
                /> */}
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    marginTop: "15px",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "30px",
                    color: "rgba(0, 0, 0, 0.9)",
                    textAlign: "center",
                    textTransform: "capitalize"

                  }}

                >
                  {capitalizeString(userProfile?.name)}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "23px",
                    color: "rgba(0, 0, 0, 0.9)",
                    textAlign: "center",
                    marginTop: "-5px",
                    textTransform: "capitalize"
                  }}
                >
                  {capitalizeString(userProfile?.roleInCompany)}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "23px",
                    color: "rgba(0, 0, 0, 0.6)",
                    textAlign: "center",
                  }}
                >
                  {userProfile?.company}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "23px",
                    color: "rgba(0, 0, 0, 0.6)",
                    textAlign: "center",
                  }}
                >
                  {userProfile?.email}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "23px",
                    color: "rgba(0, 0, 0, 0.9)",
                    textAlign: "center",
                  }}
                >
                  {userProfile?.mobile}
                </Typography>
                <Grid textAlign={"center"}>
                  <Button
                    variant="contained"
                    style={{
                      margin: "23px auto",
                      background: " #3963AA",
                      borderRadius: "14.5px",
                      width: "150px",
                      height: "30px",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "18px",
                      color:"black",
                      backgroundColor:"#04EEAD",
                      "&:hover": {
                        backgroundColor:  "#4CF0C0",}
                    }}
                    onClick={() => navigate("/user-profile")}
                  >
                    Update Profile
                  </Button>
                </Grid>
              </Grid>
              <Divider
                sx={{
                  margin: "5px 0px"
                }}
              />
              <Grid>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "19px",
                    marginLeft:"10px"
                  }}
                >
                  Business Details 
                
                </Typography>
              </Grid>
              <Divider
                sx={{
                  marginTop: "5px",
                  // marginBottom:"18px"
                }}
              />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    marginTop: "15px",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "30px",
                    color: "rgba(0, 0, 0, 0.9)",
                    textAlign: "center",
                    textTransform: "capitalize"

                  }}

                >
                  {business?.profile?.businessName}
                </Typography>

              {/* } */}


              <Grid
                style={{
                  // display: "flex",
                  // flexDirection: "row",
                  padding: "18px 0px",
                  gap: "10px",
                  justifyContent: "center"
                }}
              >

                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt={business?.profile?.businessName}
                    src={logoSrcUrl}
                    sx={{
                      width: "66px",
                      height: "66px",
                    }}
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "18px",
                      color: "rgba(0, 0, 0, 0.6)",
                      display: "flex",
                      alignItems: "center",
                      textAlign:"center"
                    }}
                  >
                    {business?.profile?.businessName}
                    {/* <span style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => navigate("/my-business")}>
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 110,
                        }}
                        alt="premium icon"
                        src="/assets/icons/EditBusinessIcon.svg"
                      />
                    </span> */}
                  </Typography>
                  {
                    business?.profile?.businessProfile?.addresses[0]
                    ?.city && <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "rgba(0, 0, 0, 0.6)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocationOnIcon
                      style={{
                        fontSize: "14px",
                        color: "#47B59E",
                        marginRight: "4px",
                      }}
                    />
                    {
                      business?.profile?.businessProfile?.addresses[0]
                        ?.city
                    }
                  </Typography>
                  }

                  {/* <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "rgba(0, 0, 0, 0.6)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocationOnIcon
                      style={{
                        fontSize: "14px",
                        color: "#47B59E",
                        marginRight: "4px",
                      }}
                    />
                    {
                      business?.profile?.businessProfile?.addresses[0]
                        ?.city
                    }
                  </Typography> */}
                  <Grid
                    sx={{
                      display: "flex",
                      gap: "10px"
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {business?.profile?.businessProfile?.businessEmail}
                    </Typography>
                    <Typography style={{ cursor: "pointer", }} onClick={() => navigate("/my-business")}>

                      <Box
                        component="img"
                        sx={{
                          marginRight: "0px",
                          height: 16,
                        }}
                        alt="premium icon"
                        src="/assets/icons/editIconImage.svg"
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sx={{

                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Grid
                  style={{
                    width: isSm ? "500px" : "350px",
                    height: "66px",
                    border: "1px solid #CBCBCB",
                    borderRadius: "10px",
                    padding: "0px 24px",
                    display: "flex",
                    // flexDirection: "row",
                    // gap:2,
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {business?.profile?.subscriptionType == "PREMIUM" ? (
                      <Box
                        component="img"
                        sx={{
                          height: 43,
                          width: 40,

                        }}
                        alt="premium icon"
                        src="/assets/icons/premium_crown.svg"
                      />
                    ) : (
                      <Box
                        component="img"
                        sx={{
                          height: 43,
                          width: 40,
                        }}
                        alt="premium icon"
                        src="/assets/icons/basic_shield.svg"
                      />
                    )}
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: 2
                    }}
                  >
                    <Typography
                      style={{
                        marginLeft: "5px",
                        fontFamily: "Roboto",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#262728",

                      }}
                    >
                      Active Subscription
                    </Typography>
                    <Typography
                      style={{
                        marginLeft: "5px",
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "14px",
                        color: "#262728",
                        marginTop: "3px",
                        // padding:"0px 30px"
                      }}
                    >
                      You are{" "}
                      {business?.profile?.subscriptionType == "PREMIUM"
                        ? "Premium "
                        : "Basic"}{" "}
                      member of Neevay.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "18px",
                  justifyContent: "space-around"
                }}
              >

                <Grid
                  item
                  xs={3.8}
                  className={classes.grid}
                  onClick={() => navigate(`/leads`)}
                >
                  <Grid
                    style={{
                      height: "25px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000000",
                      textAlign: "center",
                      marginTop: "10px",
                      padding: "0px 10px"
                    }}
                  >
                    Leads Received
                  </Grid>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {lead.count}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3.8}
                  className={classes.grid}
                  onClick={() =>
                    navigate(`/requirements`)
                  }
                >
                  {" "}
                  <Grid
                    style={{
                      height: "25px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000000",
                      textAlign: "center",
                      marginTop: "10px",
                      padding: "0px 15px"
                    }}
                  >
                    Enquiries Sent
                  </Grid>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {requirement.count}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3.8}
                  className={classes.grid}
                  onClick={() =>
                    navigate(`/shortlisted-profile`)
                  }
                >
                  {" "}
                  <Grid
                    style={{
                      height: "25px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000000",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Shortlisted Profiles
                  </Grid>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {favourite.count}
                  </Typography>
                </Grid>

              </Grid>

              <Grid
                container
                xs={12}
                style={{
                  // padding:"10px 0px",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "18px",
                  justifyContent: "space-around"
                }}
              >
                <Grid
                  item
                  xs={3.8}
                  className={classes.grid}
                  onClick={() => navigate(`/company/${business?.profile?.url}`)}
                >
                  <Grid
                    style={{
                      height: "25px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000000",
                      textAlign: "center",
                      marginTop: "10px",
                      padding: "0px 10px"
                    }}
                  >
                    No. of Business
                  </Grid>
                  <Grid
                    style={{

                      fontFamily: "Roboto",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "15px",
                      textAlign: "center",

                    }}
                  >
                    1
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={3.8}
                  className={classes.grid}
                  onClick={() =>
                    navigate(`/company/${business?.profile?.url}`)
                  }
                >
                  {" "}
                  <Grid
                    style={{
                      height: "25px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000000",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Certificates / Awards
                  </Grid>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {business?.profile?.awards?.length +
                      business?.profile?.businessProfile
                        ?.certificateImages?.length}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3.8}
                  className={classes.grid}
                  onClick={() =>
                    navigate(`/company/${business?.profile?.url}`)
                  }
                >
                  <Grid
                    style={{
                      height: "25px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000000",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Projects Completed
                  </Grid>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {business.projects.length}
                  </Typography>
                </Grid>
              </Grid>


            </Grid>

        }

{userProfile.isVerified === false && (
        <VerificationPopUp
          open={isModalOpen} // Pass the modal visibility state
          handleClose={handleCloseModal} // Pass the close modal function
        />
      )}

      </Page>
    </DashboardLayout>
  );
}
Dashboard.propTypes = {
  profileImage: PropTypes.func,
};

export default Dashboard;
