import profileImageService from "services/profileImageService";

export const PROFILE_IMAGE = "@profile/image/upload";
export const PROFILE_IMAGE_SUCCESS = "@profile/image/upload-success";
export const PROFILE_IMAGE_FAILURE = "@profile/image/upload-failure";

export function profileImageUpload(file, type, name, supplierId) {
  return async (dispatch) => {
    try {
      // console.log("ProfileImageAction.profileImage:", file, type, name);
      dispatch({
        type: PROFILE_IMAGE,
        payload: {
          file,
          type,
          name,
        },
      });
      // console.log("ProfileImageAction.profileImage:", file, type, name);

      const resp = await profileImageService.profileImageUpload(
        file,
        type,
        name,
        supplierId
      );

      // console.log("ProfileImageAction.profileImage: Resp:", resp);

      dispatch({
        type: PROFILE_IMAGE_SUCCESS,
        payload: {
          resp,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("ProfileImageAction.profileImage: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PROFILE_IMAGE_FAILURE });
      throw error;
    }
  };
}
