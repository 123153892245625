import { Grid, Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { getUrl } from "actions/businessActions";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { sendNewRFQTrigger } from "actions/requirementsAction";
import { getIsPremium } from "utils/accessControl";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import { fetchTag } from "actions/tagsActions";
import FavoritesDialogBox from "views/Business/components/FavoritesDialogBox";
import VerificationPopUp from "views/VerificationModal";
import RequirementFormModal from "views/RequirementFormModal/RequirementFormModal";
import NonEntityRequirmentForm from "views/RequirementFormModal/NonEntityRequirmentForm";

function SendEnquiryEditView({ profile, IsSearchPublicProfile, isEditView }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const auth = useSelector((state) => state.auth);
  const isPremium = getIsPremium(profile?.subscriptionType);

  const [openLogInModal, setOpenLogInModal] = useState(false);
  const [openFavoritesTag, setOpenFavoritesTag] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [openSendRfqModel, setOpenSendRfqModel] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;
  // console.log(userProfile.isVerified, "userProfile");
  const userIdOfOwner = profile?.owners?.[0];
  const businessProfile = profile?.businessProfile;
  const contact = businessProfile?.contact;
  const whatsappMobileNumber = businessProfile?.businessMobile;
  const businessName = profile?.businessName;

  const isImpProfile =
    businessProfile?.businessCategory == "Real Estate Developer" ||
    businessProfile?.businessCategory == "EPC Contractor";

  const handleCloseSnackbar = () => {
    setOpenSave(false);
  };

  const handleClickSave = (event) => {
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));
      setOpenLogInModal(true);
    } else if (userProfile?.isVerified === false) {
      setIsModalOpen(true);
    } else {
      dispatch(fetchTag());
      setOpenFavoritesTag(true);
    }
  };

  const handleFavoritesTagClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFavoritesTag(false);
  };
  const handleClickShowOwnerDetails = (type) => {
    if (!userProfile) {
      setOpenLogInModal(true);
      return;
    } else if (userProfile?.isVerified === false) {
      setIsModalOpen(true);
    } else {
      setShowNumber(type === "mobile");
    }
  };

  const handleClick = (event, data) => {
    console.log(event, "event");
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));

      setOpenLogInModal(true);
    } else if (userProfile?.isVerified === false) {
      setIsModalOpen(true);
    } else {
      const supplierData = [];
      supplierData.push({
        supplierId: profile?.supplierId,
        businessName: profile?.businessName,
        businessOwnerUserId: userIdOfOwner,
        isPremium: isPremium,
        businessEmail: businessProfile?.businessEmail
      });
      dispatch(sendNewRFQTrigger(supplierData));
      setOpenSendRfqModel(true)
      // navigate("/enquiry");
    }
  };

  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginX: IsSearchPublicProfile ? "25px" : "46px",
          alignItems: "flex-start",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            marginBottom: "20px",
            paddingLeft: isImpProfile && "80px",
          }}
        >
          <Grid
            sx={{
              border: "1px solid rgba(217, 217, 217, 1)",
              borderRadius: "10px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height:"42px",
                width: !userProfile ? "160px" : "150px",
              }}
            >
              <Box
                component="img"
                alt="phone"
                src="/assets/home/questions.gif"
                style={{ width: "34px", marginRight: "8px" }}
              />
              {/* { isEditView && (
                <Typography variant="businessCard">
                  {contact ? contact : "Not Disclosed"}
                </Typography>
              )} */}
              {userProfile && !userProfile.isVerified ? (
                <Typography
                  onClick={() => handleClickShowOwnerDetails("mobile")}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    color: "#3963AB",
                    fontSize: "14px",
                    textDecoration: "underline",
                  }}
                >
                  Hidden
                </Typography>
              ) : (
                <Typography variant="businessCard">
                  {contact ? contact : "Not Disclosed"}
                </Typography>
              )}

              {!userProfile && (
                <Typography
                  onClick={() => handleClickShowOwnerDetails("mobile")}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    color: "#3963AB",
                    fontSize: "14px",
                    textDecoration: "underline",
                  }}
                >
                  Login to View
                </Typography>
              )}
            </Grid>
          </Grid>

          {contact && !isImpProfile && userProfile?.isVerified && (
            <a
              style={{ textDecoration: "none" }}
              href={`https://api.whatsapp.com/send?phone=91${whatsappMobileNumber}&text=Hello%2C%20I%20reviewed%20your%20business%20%22${businessName}%22%20on%20Neevay%20platform%20and%20would%20like%20to%20chat%20with%20you%20for%20my%20construction%20project%20requirements.`}
              target="_blank"
            >
              <Grid
                sx={{
                  borderRadius: "10px",
                  border: " 1px solid rgba(217, 217, 217, 1)",
                  cursor: "pointer",
                  width: "98px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src="/assets/home/WhatsApplogo.svg"
                  style={{
                    width: "28px",
                    paddingLeft: "6px",
                  }}
                />
                <Box
                  variant="outlined"
                  size="medium"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "black",
                    // textAlign: "center",
                    padding: "6px",
                  }}
                >
                  Chat
                </Box>
              </Grid>
            </a>
          )}
          {isImpProfile ? (
            ""
          ) : (
            <Grid
              sx={{
                borderRadius: "10px",
                border: " 1px solid rgba(217, 217, 217, 1)",
              }}
            >
              <Box
                onClick={(e) => isImpProfile || handleClickSave(e)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "6px",
                  borderRadius: "20px",
                  width: "113px",
                  height: "35px",
                  cursor: isImpProfile ? "" : "pointer",
                }}
              >
                <Box
                  component="img"
                  src="/assets/home/ShortListedIcon.svg"
                  style={{
                    width: "16px",
                    height: "12px",
                    color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                  }}
                />
                <Box
                  variant="outlined"
                  size="medium"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                    textAlign: "center",
                    lineHeight: "16px",
                    // paddingLeft: "28px",
                  }}
                >
                  Shortlist
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid >
          <Box
            container
            onClick={(e) => isImpProfile || handleClick(e)}
            sx={{
              backgroundColor: isImpProfile
                ? "rgba(217, 217, 217, 1)"
                : "#002E33",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "150px",
              height: "35px",
              marginBottom: "10px",
              cursor: isImpProfile ? "" : "pointer",
              color: "black",
              "&:hover": {
                backgroundColor: isImpProfile ? "" : "#002E33",
                boxShadow: isImpProfile
                  ? ""
                  : "0px 0px 10px 0px rgba(0,0,0,0.7)",
              },
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: 600,
                color:"white"
              }}
            >
            {entityId ? " Contact Vendor" : "Send Message"}
            </Typography>
            {/* <SendIcon
              sx={{
                color: "rgba(255, 255, 255, 1)",
                height: "100%",
                padding: "4px",
                backgroundColor: "#fbfbfb5e",
                borderTopRightRadius: "24px",
                borderBottomRightRadius: "24px",
              }}
            /> */}
          </Box>
          {isImpProfile ? (
            <Typography
              sx={{ fontSize: "10px", paddingTop: "4px", marginBottom: "20px" }}
            >
              Link of this business are disabled*
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />

      {profile?.supplierId && (
        <FavoritesDialogBox
          supplierId={profile?.supplierId}
          businessName={profile?.businessName}
          handleClose={handleFavoritesTagClose}
          open={openFavoritesTag}
          itemOwnerId={userIdOfOwner}
          userProfile={userProfile}
          setOpenSave={setOpenSave}
          businessEmail={profile?.businessProfile?.businessEmail}
          contact={profile?.businessProfile?.contact}
          subscriptionType={profile?.subscriptionType}
          url={profile?.url}
        />
      )}
      <Snackbar
        open={openSave}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Business shortlisted succesfully"
        direction="left"
      />
      <VerificationPopUp
        open={isModalOpen} // Pass the modal visibility state
        handleClose={handleCloseModal} // Pass the close modal function
      />
  {entityId?

<RequirementFormModal
open={openSendRfqModel}
setOpen={setOpenSendRfqModel}
/>
: <NonEntityRequirmentForm
open={openSendRfqModel}
setOpen={setOpenSendRfqModel}
/>
}
    </>
  );
}

export default SendEnquiryEditView;
