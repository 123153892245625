import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { Formik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";

import { addMembers } from "actions/membersAction";

function AddMemberForm({ className, supplierId, onSubmitSuccess, handleClose, ...rest }) {
  const dispatch = useDispatch();

  const memberType = [
    "OWNER",
    "MEMBER",
    "ASSOCIATE"
  ];


  return (
    <Formik
      initialValues={{
        memberType: "",
        name: "",
        email: "",
        mobile: "",
      }}
      validationSchema={Yup.object().shape({

        memberType: Yup.string().required(),
        email: Yup.string().required().email(),
        mobile: Yup.number().required(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

        try {

          await dispatch(addMembers(supplierId, values));

          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Autocomplete
              sx={{mt:2.1}}
                value={values.memberType}
                onChange={(event, newValue) => {
                  setFieldValue("memberType", newValue);
                }}
                disablePortal
                id="memberType"
                required
                options={memberType}
                fullWidth
                isOptionEqualToValue={(option, value) => option.id === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Member Type"
                    required
                    error={Boolean(touched.memberType && errors.memberType)}
                    helperText={touched.memberType && errors.memberType}
                  />
                )}
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.mobile && errors.mobile)}
                fullWidth
                helperText={touched.mobile && errors.mobile}
                label="Mobile"
                margin="normal"
                name="mobile"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                required
                value={values.mobile}
                inputProps={{ maxLength: 10 }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid item alignItems={"center"} justifyContent={"center"}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item alignItems={"center"} justifyContent={"center"}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

AddMemberForm.propTypes = {
  supplierId: PropTypes.string,
  handleClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
};

export default AddMemberForm;
