import React, { memo, useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import MainLayout from "layouts/Main";
import { Helmet } from "react-helmet";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { moneyFormatter } from "utils/formatUtils";
import useIsMounted from "hooks/useIsMounted";

import {
  RZP_KEY,
  RZP_NAME,
  RZP_DESC,
  RZP_LOGO,
  RZP_COLOR,
  RZP_CURRENCY,
} from "../../config/index";

import {
  Box,
  Button,
  Card,
  Divider,
  CardContent,
  CardMedia,
  Grid,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import {
  paymentSuccessAction,
  paymentFailure,
  cancelOrderAction,
} from "actions/subscribeAction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getAddressHO, getAddressStr } from "utils/addressUtils";

function ConfirmPayment() {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order);
  const draftOrder = order?.draftOrder;

  const business = useSelector((state) => state.business);
  const profilelocalStorage = JSON.parse(localStorage.getItem("profile") || "[]")
  const localGst =  profilelocalStorage?.statutory?.gstin 
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [options, setOptions] = useState({
    key: RZP_KEY,
    name: RZP_NAME,
    description: RZP_DESC,
    image: RZP_LOGO,
    theme: {
      color: RZP_COLOR,
    },
    currency: RZP_CURRENCY,
    amount: draftOrder?.totalAmount,
    order_id: draftOrder?.paymentOrderId,
    notes: {
      // plan_id: order?.planId,
      plan_desc: draftOrder?.orderDesc,
      // plan_name: order?.planName,
      plan_cost: draftOrder?.totalAmount,
      plan_validity: draftOrder?.validity,
    },
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    handler: (response) => {
      confirmPayment(response);
    },
  });

  const confirmPayment = (response) => {
    dispatch(paymentSuccessAction(response));
    navigate("/payment-success");
  };

  const cancelOrder = () => {
    try {
      dispatch(cancelOrderAction());
      navigate("/subscribe-plan");
    } catch (error) {
      // console.log("error");
    }
  };
  const params = useParams();
  const url = params.url;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [order, business]);

  // eslint-disable-next-line no-undef
  const rzp1 = new Razorpay(options);
  rzp1.on("payment.failed", (response) => {
    paymentFailure({
      paymentStatus: "failure",
      ...response.error,
    });
  });

  const confirmClick = (e) => {
    rzp1.open();
    e.preventDefault();
    if (draftOrder?.totalAmount > 0 && draftOrder.orderId) {
      rzp1.open();
      e.preventDefault();
    }
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Preview Order</title>
      </Helmet>
      <Container
        maxWidth="lg"
        className="center-modal"
        sx={{ flexDirection: "column" }}
      >
        <Typography component="h1" variant="h3">
          Lets Finish Powering You Up!
        </Typography>
        <Typography component="h2" variant="h6">
          Plan is right for you.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: 5,
            p: 4,
            borderRadius: 1,
            m: 4,
          }}
        >
          <Grid container spacing={1}>
            <Grid container item xs={12} md={6}>
              <Grid container item xs={12} md={12}>
                <Typography variant="h4">
                  <b>Order Details</b>
                </Typography>
              </Grid>

              <Grid container item xs={6} md={4}>
                <Typography variant="subtitle1">OrderId :</Typography>
              </Grid>
              <Grid container item xs={6} md={8}>
                <Typography>{draftOrder?.orderId}</Typography>
              </Grid>

              <Grid container item xs={6} md={4}>
                <Typography variant="subtitle1">Name :</Typography>
              </Grid>
              <Grid container item xs={6} md={8}>
                <Typography>{draftOrder?.invoiceDetails?.name}</Typography>
              </Grid>

              <Grid container item xs={6} md={4}>
                <Typography variant="subtitle1">Business Name :</Typography>
              </Grid>
              <Grid container item xs={6} md={8}>
                <Typography>
                  {draftOrder?.invoiceDetails?.businessName}
                </Typography>
              </Grid>

              <Grid container item xs={6} md={4}>
                <Typography variant="subtitle1">Address :</Typography>
              </Grid>
              <Grid container item xs={6} md={8}>
                <Typography>{draftOrder?.invoiceDetails?.address}</Typography>
              </Grid>

              <Grid container item xs={6} md={4}>
                <Typography variant="subtitle1">GSTIN :</Typography>
              </Grid>
              <Grid container item xs={6} md={8}>
                <Typography>{draftOrder?.invoiceDetails?.gst || localGst}</Typography>
              </Grid>

              <Grid container item xs={6} md={4}>
                <Typography variant="subtitle1">GSTIN State Code :</Typography>
              </Grid>
              <Grid container item xs={6} md={8}>
                <Typography>{draftOrder?.invoiceDetails?.stateCode}</Typography>
              </Grid>

              {/* GSTIN : {(draftOrder)? business.profile.order.gst.igst : ""} */}
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  <b> Summary</b>
                </Typography>
              </Grid>
              {/* Vishal */}
              <Grid container xs={12} md={12}>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b> Description:</b>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Neevay Portal Subscription
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b> Subscription Period :</b>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  365 days
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b>Subscription Amount : </b>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Rs. {draftOrder?.orderAmount ? draftOrder?.orderAmount : "00"}
                </Grid>
                <Divider />
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b>IGST :</b>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Rs. {draftOrder?.gst.igst ? draftOrder?.gst.igst : "00"}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b>CGST :</b>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Rs. {draftOrder?.gst.cgst ? draftOrder?.gst.cgst : "00"}
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b>SGST :</b>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Rs. {draftOrder?.gst.sgst ? draftOrder?.gst.sgst : "00"}
                </Grid>
              </Grid>
              {/* Vishal */}
              {/* <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <b>Subscription:</b>{" "}
                <span
                  style={{
                    padding: "7px",
                    background: "#5282c4",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: "12px",
                  }}
                >
                  Premium
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <b>Billed:</b>{" "}
                <span
                  style={{
                    padding: "7px",
                    background: "#3fab91",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: "14px",
                  }}
                >
                  Yearly
                </span>
              </Grid> */}
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "space-between" }}
              ></Grid>
              {/* <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <span
                  style={{
                    fontSize: "24px",
                    fontStyle: "italic",
                  }}
                >
                  {moneyFormatter(
                    draftOrder?.orderAmount ? draftOrder?.orderAmount : "00"
                  )}
                </span>
                <span
                  style={{
                    fontSize: "18px",
                    marginTop: "7px",
                  }}
                >
                  /yr
                </span>
              </Grid> */}
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                  }}
                >
                  Total Payable Amount :
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                  }}
                >
                  {moneyFormatter(
                    draftOrder?.totalAmount ? draftOrder?.totalAmount : "00"
                  )}
                  /yr
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={confirmClick}
                  variant="contained"
                  size="large"

                  sx={{ mt: 2, mb: 2, mr: 2, width: 200, backgroundColor: "#FD6600",
                  color:"white",
                  "&:hover": {
                    backgroundColor: "#FD6600",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                  }
                  }} 
                >
                  Proceed to Payment <sup>*</sup>
                </Button>
                <Button
                  onClick={cancelOrder}
                  variant="contained"
                  size="small"
                  sx={{ mt: 2, mb: 2, my: 2, background: "white",backgroundColor: "#FD6600",
                  color:"white",
                  "&:hover": {
                    backgroundColor: "#FD6600",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                  } }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                * Payment will be done using Razorpay
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </MainLayout>
  );
}

ConfirmPayment.propTypes = {
  // planList: PropTypes.array.isRequired,
  // submitForm: PropTypes.func.isRequired,
  // isLogin: PropTypes.bool.isRequired,
  // isSupplierSubscribed: PropTypes.bool.isRequired,
};

export default memo(ConfirmPayment);
