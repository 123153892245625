import { Delete } from "@mui/icons-material";
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { deleteCertificate } from "actions/certificateAction";
import { DEFAULT_IMAGES } from "config";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

function AwardsCertificateCard({ data, permissions }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteCertificate = (
    supplierId,
    certificateId,
    certificateName
  ) => {
    console.log(supplierId, data.certificateId, data.certificateName,"asdfgh$$$");
    console.log(data,"data$$$");
    dispatch(deleteCertificate(supplierId, certificateId, certificateName));
    navigate("/my-business");
  };

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            border: "1px solid rgba(217, 217, 217, 1)",
            width: "206px",
            // height: "206px",
          }}
        >
          {" "}
          {permissions &&
            !permissions.isViewOnly &&
            permissions.isUpdateAllowed && (
              <Typography>
                <Tooltip title="Delete">
                  <IconButton
                    disabled={!permissions?.isOwner}
                    sx={{ color: "primary.main", ml: -2 }}
                    onClick={() =>
                      handleClickOpen(
                        data.supplierId,
                        data.certificateId,
                        data.certificateName
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Typography>
            )}
          <Box
            component="img"
            alt="phone"
            src={
              data?.imgUrl
                ? `${BASE_URL}/${data?.imgUrl}`
                : DEFAULT_IMAGES.SERVICE
            }
            sx={{ width: "206px", height: "206px" }}
          />
        </Card>
        <Typography
          sx={{ textAlign: "center", marginTop: "10px", fontWeight: 600 }}
        >
          {data?.title}
        </Typography>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography>Are you sure want to delete ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleDeleteCertificate(
                data.supplierId,
                data.certificateId,
                data.certificateName
              )
            }
            variant="contained"
          >
            <Box onClick={handleClose}> Submit</Box>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AwardsCertificateCard;
