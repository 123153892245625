import projectService from "services/projectService";
import { NewProjectInfo } from "interface/projectIf";
export const PROJECT_CREATE = "@project/create";
export const PROJECT_CREATE_SUCCESS = "@project/create-success";
export const PROJECT_CREATE_FAILURE = "@project/create-failure";

export const PROJECT_UPDATE = "@project/update";
export const PROJECT_UPDATE_SUCCESS = "@project/update-success";
export const PROJECT_UPDATE_FAILURE = "@project/update-failure";

export const PROJECT_ADD_TRIGGER = "@project/add-trigger";
export const PROJECT_EDIT_TRIGGER = "@project/edit-trigger";

export const PROJECT_DELETE = "@service/delete";
export const PROJECT_DELETE_SUCCESS = "@service/delete-success";
export const PROJECT_DELETE_FAILURE = "@service/delete-failure";

export function addProjectTrigger() {
  return async (dispatch) => {
    dispatch({
      type: PROJECT_ADD_TRIGGER,
      payload: {},
    });
  };
}

export function editProjectTrigger(project) {
  return async (dispatch) => {
    // console.log("editProjectTrigger() ", project);
    dispatch({
      type: PROJECT_EDIT_TRIGGER,
      payload: { project },
    });
  };
}

export function addProject(supplierId, values) {
  return async (dispatch) => {
    const newProject = new NewProjectInfo(supplierId, values);
    try {
      dispatch({
        type: PROJECT_CREATE,
        payload: {
          project: newProject,
        },
      });

      const payload = await projectService.addProject(newProject);

      // console.log("project service", payload);
      dispatch({
        type: PROJECT_CREATE_SUCCESS,
        payload: {
          ...payload
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PROJECT_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateProject(supplierId, values) {
  return async (dispatch) => {
    const newProject = new NewProjectInfo(supplierId, values);
    try {
      dispatch({
        type: PROJECT_UPDATE,
        payload: {
          project: newProject,
        },
      });

      const payload = await projectService.updateProject(newProject);

      // console.log("project service", payload);
      dispatch({
        type: PROJECT_UPDATE_SUCCESS,
        payload: {
          ...payload
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PROJECT_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function deleteProject(supplierId, projectName, projectId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_DELETE,
        payload: {
          projectName,
        },
      });

      const payload = await projectService.deleteProject(
        supplierId,
        projectName,
        projectId

      );
      // console.log("project delete", payload);

      dispatch({
        type: PROJECT_DELETE_SUCCESS,
        payload: { ...payload },
      });
    } catch (error) {
      dispatch({ type: PROJECT_DELETE_FAILURE });
      throw error;
    }
  };
}
