import { OrderInfo, PaymentInfo } from "interface/orderIf";
import { async } from "q";
import subscribeService from "services/subscribeService";
// import { NewCertificateInfo } from "interface/certificateIf";

export const SUBSCRIBE_PLAN = "@subscribe/plan";

export const SUBSCRIBE_CREATE = "@subscribe/create";
export const SUBSCRIBE_SUCCESS = "@subscribe/success";
export const SUBSCRIBE_FAILURE = "@subscribe/failure";
export const CREATE_ORDER = "@order/create";
export const CREATE_ORDER_SUCCESS = "@order/create-success";
export const CREATE_ORDER_FAILURE = "@order/create-failure";

export const INSTANT_PAYMENT = "@instantPayment/payment";
export const INSTANT_PAYMENT_SUCCESS = "@instantPayment/payment-success";
export const INSTANT_PAYMENT_FAILURE = "@instantPayment/payment-failure";

export const CANCEL_ORDER  = "@order/cancel-order";

export function subscribeToPlan(plan) {
  return async (dispatch) => {
    // console.log("subscribeToPlan() ", plan);
    dispatch({
      type: SUBSCRIBE_PLAN,
      payload: plan,
    });
  };
}

export function createOrder(plan, info, supplierId) {
  return async (dispatch) => {
    const orderInfo = new OrderInfo(plan, info, supplierId);
    try {
      dispatch({
        type: CREATE_ORDER,
        payload: {
          order: orderInfo,
        },
      });
      const orderData = await subscribeService.createOrder(orderInfo);
      dispatch({
        type: CREATE_ORDER_SUCCESS,
        payload: orderData,
      });

      // if (orderData?.payableAmount > 0) {
        
        
      // } else {
      
       
      // }
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SUBSCRIBE_FAILURE });
      throw error;
    }
  };
}

export function cancelOrderAction() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_ORDER,
      });
    } catch (error) {}
  };
}

export function paymentSuccessAction(info) {
  return async (dispatch) => {
    const paymentInfo = new PaymentInfo(info);
    try {
      dispatch({
        type: INSTANT_PAYMENT,
        payload: {
          order: info,
        },
      });

      const instantPaymentData = await subscribeService.paymentConfirmation(
        paymentInfo
      );
      dispatch({
        type: INSTANT_PAYMENT_SUCCESS,
        payload: instantPaymentData,
      });

      // const payload = {
      //   payload: {
      //     // subscribedBy: order.email,
      //     // subscriptionId: order.payload.createOrderData.planId,
      //     // validity: order.payload.createOrderData.validity,
      //     // addedBy: order.email,

      //     // amount: order.payload.createOrderData.paymentAmount,
      //     // orderId: order.payload.createOrderData.orderId,

      //     razorpayOrderId: order.payload.response.razorpay_order_id,
      //     razorpayPaymentId: order.payload.response.razorpay_payment_id,
      //     razorpaySignature: order.payload.response.razorpay_signature,
      //   },
      // };
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: INSTANT_PAYMENT_FAILURE });
      throw error;
    }
  };
}

export function paymentFailure() {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: INSTANT_PAYMENT,
      // })
    } catch (error) {
    
    }
  };
}
