import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { filteredSearch } from "actions/searchActions";
import { RadioFilter } from "views/CompanyDirectory/components/FiltersType";
function FilterCard({
  handleIsoFilter,
  handleGstFilter,
  handleSelectedData,
  selectedFacetsData,
  searchKeyWord,
  Category,
  setSelectedFacetsData,
  toggleDrawer,
  handleGetDataFromUrlValues,
  regionString,
}) {
  const dispatch = useDispatch();

  const search = useSelector((state) => state.search);
  const facetsData = search?.facets;
  const resetFilter = () => {
    handleGetDataFromUrlValues(false); // we send false then the selected all filter goes flase and no filter apply
    const filterStr = `businessProfile.businessCategory_str:(${Category})`; // after reset all filter we apply this one filter string for category
    setSelectedFacetsData([]);
    dispatch(
      filteredSearch(
        searchKeyWord,
        regionString,
        filterStr,
        "",
        "Category",
        Category,
        true
      )
    );
  };

  const buttonArray = [
    {
      name: "ISO Certified",
      img: "/assets/home/Iso.svg",
    },
    {
      name: "GST Certified",
      img: "/assets/home/gst.png",
    },
  ];
  const selectedFacets = search?.selectedFacets;
  const appliedFilters = selectedFacets?.reduce((acc, item) => {
    acc[item.facet] =
      item.facet == "GST Certified" ? item?.values?.join() : item.values;
    return acc;
  }, {});
  return (
    <Grid sx={{ width: "360px" }}>
      <Grid
        sx={{
          display: "flex",
          padding: "10px",
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <CloseIcon
          onClick={toggleDrawer}
          sx={{
            color: "#8C9091",
            height: "22px",
            width: "22px",
            paddingTop: "3px",
            cursor: "pointer",
          }}
        />
        <Typography sx={{ margin: "auto", fontWeight: 600, fontSize: "18px" }}>
          ADVANCED FILTERS
        </Typography>
      </Grid>
      <hr />
      <Grid
        style={{
          marginLeft: "14px",
          height: "calc(100vh - 120px)",
          overflow: "overlay",
        }}
      >
        <Grid>
          <Typography
            style={{
              fontSize: "15px",
              fontWeight: 600,
              fontStyle: "normal",
              marginTop: "25px",
              marginBottom: "15px",
            }}
          >
            Quick Filters
          </Typography>
          <Grid>
            {buttonArray?.map((item) => (
              <Button
                sx={{
                  backgroundColor: selectedFacetsData.includes(item.name)
                    ? "rgba(40, 100, 190,0.2)"
                    : "white",
                  height: "32px",
                  color: "black",
                  borderRadius: "3px",
                  border: selectedFacetsData.includes(item.name)
                    ? " 1px solid rgba(40, 100, 190,1)"
                    : "1px solid  rgba(212, 232, 228, 1)",
                  marginRight: "8px",
                  marginBottom: "8px",
                  boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => handleSelectedData(item.name, "true")}
              >
                <Box
                  component="img"
                  alt="Filter image"
                  src={item.img}
                  paddingRight="7px"
                />
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {item.name}
                </Typography>
                {selectedFacetsData.includes(item.name) && (
                  <CloseIcon
                    style={{
                      height: "16px",
                      width: "16px",
                      color: "#8C9091",
                      marginLeft: "4px",
                    }}
                  />
                )}
              </Button>
            ))}
          </Grid>
        </Grid>

        <Grid sx={{ flexWrap: "wrap" }}>
          {facetsData.map((item) => {
            if (
              item.title === "Annual Turnover" ||
              item.title === "Certificates" ||
              item.title === "Company Type" ||
              item.title === "Domains" ||
              (item.title === "Supplier Type" && Category === "Supplier") ||
              item.title === "Projects Delivered"
            ) {
              return (
                item?.facets?.length >= 1 && (
                  <div key={item.title}>
                    <Typography
                      style={{
                        fontSize: "15px",
                        fontWeight: 600,
                        marginTop: "25px",
                        marginBottom: "15px",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Grid>
                      {item.title === "Annual Turnover" ? (
                        <RadioFilter
                          addedFilters={appliedFilters}
                          handleInputChange={handleSelectedData}
                          title={"Turnover"}
                          field={"Annual Turnover"}
                          options={[
                            "Less than 40 Lacs",
                            "Greater than 40 Lacs",
                            "Greater than 1.5 Crores",
                            "Greater than 5 Crores",
                            "Greater than 25 Crores",
                            "Greater than 100 Crores",
                            "Greater than 500 Crores",
                          ]}
                          // showOptions={showOptions}
                        />
                      ) : (
                        item.facets.map((subItem) => (
                          <Button
                            key={subItem.title}
                            style={{
                              backgroundColor: selectedFacetsData.includes(
                                subItem.label
                              )
                                ? "rgba(40, 100, 190, 1)"
                                : "white",
                              height: "35px",
                              color: selectedFacetsData.includes(subItem.label)
                                ? "white"
                                : "black",
                              borderRadius: "3px",
                              border: "1px solid rgba(222, 222, 222, 1)",
                              margin: "6px",
                            }}
                            onClick={() =>
                              handleSelectedData(item.title, subItem.label)
                            }
                          >
                            {subItem.label}
                            {selectedFacetsData.includes(subItem.label) && (
                              <CloseIcon
                                style={{
                                  height: "18px",
                                  width: "18px",
                                  color: "white",
                                  marginLeft: "8px",
                                }}
                              />
                            )}
                          </Button>
                        ))
                      )}
                    </Grid>
                  </div>
                )
              );
            }
            return null;
          })}
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingX: "30px",
          paddingY: "20px",
          boxShadow: "0px -3px 6px 0px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          style={{
            backgroundColor: "rgba(216, 217, 220, 1)",
            width: "110px",
            height: "32px",
            color: "black",
            borderRadius: "3px",
            marginRight: "18px",
          }}
          onClick={resetFilter}
        >
          Reset Filters
        </Button>
      </Grid>
    </Grid>
  );
}

export default FilterCard;
