class BusinessAddress {
  constructor(supplierId, info) {
    if (info) {
      this.supplierId = supplierId;
      this.addressType = info.addressType;
      this.addressLine1 = info.addressLine1;
      this.addressLine2 = info.addressLine2;
      this.city = info.city;
      this.state = info.state;
      this.country = info.country;
      this.pincode = info.pincode;
      this.district = info.district;
      this.area = info.area;
      // this.businessName = "MindsClik Digital Solutions Pvt";
      this.updatedBy = info.updatedBy;
    }
  }
}

class BusinessStepperCount {
  constructor(supplierId, stepperCountValue) {

    if (stepperCountValue) {
      this.supplierId = supplierId;
      this.stepperCountValue = stepperCountValue;
    }
  }
}

class BusinessProfileToUpdate {
  constructor(supplierId, info) {
    console.log("info.stepperCount",info);
    if (info) {
      this.supplierId = supplierId;
      this.businessName = info.businessName;
      this.businessEmail = info.businessEmail;
      this.gstin = info.gstin;
      this.businessMobile = info.businessMobile;
      this.contact = info.contact;
      this.businessType = info.businessType;
      this.businessHeadline = info.businessHeadline;
      this.description = info.description;
      this.businessCategory = info.businessCategory;
      this.establishYear = info.establishYear;
      this.domains = info.domains;
      this.projectCount = info.projectCount;
      this.certificates = info.certificates;
      this.websiteUrl = info.websiteUrl;
      this.supplierType = info.supplierType;
      this.stepperCount = info.stepperCount;
      this.annualTurnOver = info.annualTurnOver
    }
  }
}

class BusinessSocialIconToUpdate {
  constructor(supplierId, info) {
    if (info) {
      this.supplierId = supplierId;
      this.facebookUrl = info.facebook;
      this.twitterUrl = info.twitter;
      this.instagramUrl = info.instagram;
      this.youtubeUrl = info.youtube;
    }
  }
}

export default { BusinessAddress };
export { BusinessAddress, BusinessProfileToUpdate, BusinessSocialIconToUpdate,BusinessStepperCount };
