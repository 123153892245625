import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  Grid,
  Box
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";

import { UserProfileForm } from "./components";
import BusinessRegistrationForm from "views/Business/components/BusinessRegistrationForm";
import MainStepperBusinessForm from "views/Business/components/businessRegistrationFormSteps/BusinessRegStep1";
import AddServiceForm from "views/Business/components/BusinessRegForms/AddServiceForm";
import AddProjectsForm from "views/Business/components/BusinessRegForms/AddProjectsForm";
import AddClientsForm from "views/Business/components/BusinessRegForms/AddClientsForm";

function RegisterUser({ }) {
  const [selectedUserType, setSelectedUserType] = React.useState("");
  const [showComponent2, setShowComponent2] = useState(false);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const auth = useSelector((state) => state.auth);
  console.log("auth.,.,.,>>",auth)
  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    if (selectedUserType === "buyer") {
      navigate("/")
    }
     else if (selectedUserType === "vendor") {
      navigate("/subscribe-plan")
    }
    else {
      navigate("/register/business")
    }
  };

  const userProfile = auth.user;

  return (
    <>
      <Helmet>
        <title>Neevay - Register User</title>
      </Helmet>
      <Grid container xs={12} style={{position:"relative"}}>
        {isMd && <Grid item md={6.5} lg={6} style={{  backgroundColor: "#FBF8F6",display:"flex", height: "100vh", alignItems:"center", justifyContent:"center", position:"fixed", top:"0", width:isMd?"50%":null}}>
          <Box
            height="327px"
            component="img"
            src={`${BASE_URL}/assets/business/web-userform-UserFormImg.svg`}
          />
        </Grid>}
        <Grid  item md={5.5} lg={6} sx={{ paddingX: isSm && "30px", width: "100vw",position:"absolute", top:"0", right:"0" }}>
          <UserProfileForm onSubmitSuccess={handleSubmitSuccess} selectedUserType={selectedUserType} setSelectedUserType={setSelectedUserType} profile={userProfile} />
        </Grid>
      </Grid>
    </>
  );
}

export default RegisterUser;
