import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import OtpInput from "react-otp-input";

import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  Fab,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { verifyRegisterOTP, registerUserWithMobile, sendwhatsAppOTP } from "actions/authActions";
import { SIGN_UP_COUNTER } from "config";
import { useTheme } from "@emotion/react";

function SignUpOTPVerifyForm({
  isWhatsappNo,
  className,
  onSubmitSuccess,
  mobile,
  name,
  ...rest
}) {
  const dispatch = useDispatch();
  const [counter, setCounter] = React.useState(SIGN_UP_COUNTER);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const { sourceName } = location.state;
  console.log("location 3 ==", sourceName);


  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  // useEffect(() => {
  //   //const codesArr = Array.from(document.querySelectorAll("input.code"));
  //   const codesArr = Array.from(document.querySelectorAll("input"));
  //   // console.log(`codesArr: `, codesArr);
  //   codesArr.forEach((code, idx) => {
  //     code.addEventListener("keydown", (e) => {
  //       if (idx == 0) {
  //         if (e.key >= 0 && e.key <= 9) {
  //           codesArr[idx].value = "";
  //           setTimeout(() => codesArr[idx + 1].focus(), 0);
  //         }
  //       } else if (idx == 3) {
  //         if (e.key >= 0 && e.key <= 9) {
  //           codesArr[idx].value = "";
  //         } else if (e.key === "Backspace") {
  //           setTimeout(() => codesArr[idx - 1].focus(), 0);
  //         }
  //       } else {
  //         if (e.key >= 0 && e.key <= 9) {
  //           codesArr[idx].value = "";
  //           setTimeout(() => codesArr[idx + 1].focus(), 0);
  //         } else if (e.key === "Backspace") {
  //           setTimeout(() => codesArr[idx - 1].focus(), 0);
  //         }
  //       }
  //     });
  //   });
  // }, []);

  // console.log("SignInOTPVerifyForm: mobile", mobile);

  const handleChangeMobile = () => {
    navigate("/signin-mobile-send-otp");
  };

  const resendOTP = () => {
    // console.log("Sending Request for sending OTP", mobile);

    try {
      if (isWhatsappNo) {
        dispatch(sendwhatsAppOTP(mobile))
    } else {
        dispatch(registerUserWithMobile(name, mobile));
    }
      setCounter(SIGN_UP_COUNTER);
    } catch (error) {
      // console.log("resendOTP: error", error);
    }
    // dispatch()
  };
  // state = { otp: '' };

  const [otp1, setOTP1] = useState("");

  const otpHandleChange = (otp1) => {
    setOTP1(otp1);
  };

  return (
    <Formik
      initialValues={
        {
          // otp1: "",
          // otp2: "",
          // otp3: "",
          // otp4: "",
        }
      }
      validationSchema={Yup.object().shape({
        // otp1: Yup.string()
        //   .matches(/^[0-9]/, "Please enter valid digit")
        //   .min(1)
        //   .max(1)
        //   .required(),
        // otp2: Yup.string()
        //   .matches(/^[0-9]/, "Please enter valid digit")
        //   .min(1)
        //   .max(1)
        //   .required(),
        // otp3: Yup.string()
        //   .matches(/^[0-9]/, "Please enter valid digit")
        //   .min(1)
        //   .max(1)
        //   .required(),
        // otp4: Yup.string()
        //   .matches(/^[0-9]/, "Please enter valid digit")
        //   .min(1)
        //   .max(1)
        //   .required(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const otpA = [values.otp1, values.otp2, values.otp3, values.otp4];
          const otp = otpA.join("");
          const crmLeadId = "" // we creat crmLeadId becuse require for flow
          await dispatch(verifyRegisterOTP(mobile, name, otp1, crmLeadId, sourceName,isWhatsappNo,onSubmitSuccess));
          // onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={12}
            mt={4}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "& > :not(style)": { m: 2, width: "8ch" },
              }}
              mb={6}
            >
              {/* <TextField
                error={Boolean(touched.otp1 && errors.otp1)}
                className="code"
                fullWidth
                label=""
                margin="normal"
                name="otp1"
                id="otp1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.otp1}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
                min="0"
                max="9"
              />
              <TextField
                error={Boolean(touched.otp2 && errors.otp2)}
                className="code"
                fullWidth
                label=""
                margin="normal"
                name="otp2"
                id="otp2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.otp2}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
                min="0"
                max="9"
              />
              <TextField
                error={Boolean(touched.otp3 && errors.otp3)}
                className="code"
                fullWidth
                label=""
                margin="normal"
                name="otp3"
                id="otp3"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.otp3}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
                min="0"
                max="9"
              />
              <TextField
                error={Boolean(touched.otp4 && errors.otp4)}
                className="code"
                fullWidth
                label=""
                margin="normal"
                name="otp4"
                id="otp4"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.otp4}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
                min="0"
                max="9"
              /> */}
            </Box>
            {isMd ? (
              <>
                <TextField
                  fullWidth
                  required
                  autoFocus
                  sx={{ letterSpacing: "10px" }}
                  placeholder="OTP"
                  onChange={(e) => setOTP1(e.target.value)}
                  value={otp1}
                  inputProps={{
                    maxLength: 4,
                    // pattern: /^[0-9]*$/,
                    // onKeyPress: (e) => {
                    //   const keyCode = e.keyCode || e.which;
                    //   const keyValue = String.fromCharCode(keyCode);
                    //   if (/\D/.test(keyValue)) {
                    //     e.preventDefault();
                    //   }
                    // },
                  }}
                />
              </>
            ) : (
              <OtpInput
                className="otpInputStyleCss"
                value={otp1}
                onChange={otpHandleChange}
                numInputs={4}
                separator={<span></span>}
                shouldAutoFocus
                isInputNum
              />
            )}
            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography>Didn’t receive OTP code?</Typography>
            </Grid>

            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: -2 }}
            >
              {counter > 0 && (
                <Box mt={3}>
                  <Typography
                    fontWeight={500}
                    align="center"
                    color="textSecondary"
                  >
                    You will receive OTP in{" "}
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      {" "}
                      {counter}
                    </span>{" "}
                    secs
                  </Typography>
                </Box>
              )}

              {counter <= 0 && (
                <Box mt={3}>
                  <Typography
                    fontWeight={500}
                    align="left"
                    color="textSecondary"
                  >
                    Not recevied OTP? {"  "}
                    <Link
                      component={"button"}
                      color={"primary"}
                      onClick={resendOTP}
                      underline={"none"}
                    >
                      Resend OTP
                    </Link>
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid
              item
              sm={6}
              md={7}
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 2, p: 2 }}
            >
              <Button
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // color="secondary"
                sx={{backgroundColor: "#FD6600",
               color:"white",
               "&:hover": {
                 backgroundColor: "#FD6600",
                 transition: "background-color 0.3s, box-shadow 0.3s",
                 boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
               }
               }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignUpOTPVerifyForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  mobile: PropTypes.string,
};

SignUpOTPVerifyForm.default = {
  onSubmitSuccess: () => { },
};

export default SignUpOTPVerifyForm;
