/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  REQUIREMENTS_FETCH,
  REQUIREMENTS_FETCH_SUCCESS,
  REQUIREMENTS_FETCH_FAILURE,
  RFQ_VIEW_SUCCESS,
  SEND_NEW_RFQ_TRIGGER,
  SEND_RFQ_TRIGGER,
  SEND_MULTIPLE_RFQ_TRIGGER,
  REQUIREMENTS_FETCH_SUCCESS_RESEND,
  FETCH_SEARCH_RFQS_SUCCESS,
  FETCH_UPDATED_RFQS_SUCCESS,
  SEND_NEW_ENQUIRY_SUCCESS,
} from "actions/requirementsAction";

import { SIGNOUT } from "actions/authActions";

const initialState = {
  requirements: [],
  count: 0,
  isRequirementEdit: false,
  requirementToEdit: undefined,
  enquiryToSend: undefined,
  multiplyEnquiryToSend: undefined,
  loading: false,
  resendMessage: "",
  currentPage: 0,
  totalPages: 0,
  rfqError: false
};

const requirementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUIREMENTS_FETCH:
      {
        return produce(state, (draft) => {
          draft.requirements = [];
          draft.requirementToEdit = undefined;
          draft.count = 0;
          draft.loading = true;
        });
      }
      break;

    case REQUIREMENTS_FETCH_SUCCESS:
      {
        const { requirements } = action.payload;
        // console.log(
        //   "RequirementsReducer: REQUIREMENTS_FETCH_SUCCESS ",
        //   requirements,
        //   action.payload
        // );

        return produce(state, (draft) => {
          draft.requirements = requirements?.rfqs;
          draft.count = requirements?.totalCount;
          draft.currentPage = requirements?.currentPage;
          draft.totalPages = requirements?.totalPages;
          draft.loading = false;
        });
      }
      break;

    case FETCH_UPDATED_RFQS_SUCCESS:
    case REQUIREMENTS_FETCH_SUCCESS_RESEND:
      {
        const { requirements } = action.payload;
        // console.log("this is resesnd data payoad =====", requirements);
        return produce(state, (draft) => {
          draft.requirements = requirements?.rfqs;
          draft.resendMessage = requirements?.message;
          draft.count = requirements?.totalCount;
          draft.currentPage = requirements?.currentPage;
          draft.totalPages = requirements?.totalPages;
          draft.loading = false;
        });
      }
      break;

    case REQUIREMENTS_FETCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.requirements = [];
          draft.count = 0;
          draft.loading = false;
        });
      }
      break;

    case SEND_RFQ_TRIGGER:
      {
        // console.log("requirementReducer() SEND_RFQ_TRIGGER", action.payload);
        // const {
        //   supplierId,
        //   supplierName,
        //   supplierEmail,
        //   supplierMobile,
        //   rfqId,
        // } = action.payload;
        const enquiry = action.payload;
        return produce(state, (draft) => {
          // draft.enquiryToSend = {
          //   supplierId: supplierId,
          //   supplierName: supplierName,
          //   supplierEmail: supplierEmail,
          //   supplierMobile: supplierMobile,
          //   rfqId: rfqId,
          // };
          draft.enquiryToSend = enquiry;
        });
      }
      break;
    case SEND_NEW_RFQ_TRIGGER: // Incase of new rfq the rfqId will be undefined
      {
        // console.log("requirementReducer() SEND_RFQ_TRIGGER", action.payload);
        // const {
        //   supplierId,
        //   supplierName,
        //   supplierEmail,
        //   supplierMobile,
        //   rfqId,
        // } = action.payload;
        const data = action.payload;
        return produce(state, (draft) => {
          draft.requirementToEdit = undefined;
          // draft.enquiryToSend = [{
          //   supplierId: supplierId,
          //   supplierName: supplierName,
          //   supplierEmail: supplierEmail,
          //   supplierMobile: supplierMobile,
          // }];
          draft.enquiryToSend = data;
        });
      }
      break;
    ///////////////////////////////////////////////////////////////////////////////////////////////
    case SEND_MULTIPLE_RFQ_TRIGGER:
      {
        const suppliers = action.payload;

        return produce(state, (draft) => {
          draft.requirementToEdit = undefined;
          draft.multiplyEnquiryToSend = suppliers;
        });
      }
      break;
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    case RFQ_VIEW_SUCCESS:
      {
        const { requirement } = action.payload;
        return produce(state, (draft) => {
          draft.requirementToEdit = requirement;
          draft.isRequirementEdit = true;
          // console.log("requirementrequirement", draft.requirementToEdit);
        });
      }
      break;

    case FETCH_SEARCH_RFQS_SUCCESS:
      {
        const { searchResult } = action.payload;
        console.log("this is data of payload", searchResult);
        return produce(state, (draft) => {
          draft.requirements = searchResult?.rfqs;
          draft.count = searchResult?.totalCount;
          draft.currentPage = searchResult?.currentPage;
          draft.totalPages = searchResult?.totalPages;
          draft.loading = false;
        });
      }
      break;

    case SIGNOUT:
      {
        return produce(state, (draft) => {
          draft.requirements = [];
          draft.count = 0;
          draft.enquiryToSend = undefined;
          draft.requirementToEdit = undefined;
        });
      }
      break;
      case SEND_NEW_ENQUIRY_SUCCESS:
        {
          const error = action.payload?.resp?.error;
          return produce(state, (draft) => {
            if (error === true) {
              draft.rfqError = true
            }else{
              draft.rfqError = false;
            }
          });
        }
        break;

    default:
      {
        return state;
      }
      break;
  }
};

export default requirementsReducer;
