import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Tooltip from "@mui/material/Tooltip";
import { dateFormatter } from "utils/formatUtils";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  SvgIcon,
  Typography,
} from "@mui/material";
import PdfDocument from "./GenerateInvoice/Invoice";
// import {
//   Image as ImageIcon,
//   Edit as EditIcon,
//   ArrowRight as ArrowRightIcon,
//   Search as SearchIcon
// } from 'react-feather';

const orderStatus = (status) => {
  if (status == true) {
    return "PAID";
  } else {
    return "FAILED";
  }
};
const fileName = "Invoice.pdf";
export const headCells = (
  showDelete,
  handlePdf,
  supplierId,
  invoiceDetails
) => [
  { field: "orderId", headerName: "Order ID", width: 100 },
  { field: "orderDesc", headerName: "SKU/Description", width: 300 },
  { field: "orderAmount", headerName: "Amount", width: 150 },
  {
    field: "businessName",
    valueGetter: (params) => params.row?.invoiceDetails?.businessName,
    headerName: "Business Name",
    width: 220,
  },
  {
    field: "createdAt",
    headerName: "Date",
    valueGetter: (params) => dateFormatter(params.row.createdAt),
    width: 100,
  },
  {
    field: "validated",
    valueGetter: (params) => orderStatus(params.row?.paymentOrder?.validated),
    headerName: "Order Status",
    width: 100,
  },

  { field: "supplierId", headerName: "Supplier Id", width: 150 },

  {
    field: "action",
    headerName: "Invoice",
    sortable: false,
    minWidth: 200,
    renderCell: (params) => {
      return (
        <>
        <div>
        <PDFDownloadLink
          document={<PdfDocument invoicedata={params.row} />}
          fileName={fileName}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading..." : "Download Invoice"
          }
        </PDFDownloadLink>
      </div>
          {/* <div className="cellAction">
            <Tooltip title="View Pdf">
              <IconButton
                disabled={!params.row?.paymentOrder?.validated}
                onClick={() => {
                  handlePdf(supplierId, params.row);
                }}
              >
                <SvgIcon fontSize="small">
                  <PictureAsPdfIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </div> */}
        </>
      );
    },
  },
];
