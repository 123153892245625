import StaticPopup from "components/StaticPopup";
import React, { useState } from "react";

const ViewMore = ({ values, title }) => {
  const [openModal, setOpenModal] = useState(false);
  const data = values || ["--"];
  return (
    <>
      {data?.length > 1 ? (
        <button onClick={() => setOpenModal(true)}>
          <span className="font-medium  text-gray-500 ">
            {data[0].charAt(0).toUpperCase() + data[0].slice(1).toLowerCase()}
          </span>

          <span className="underline ml-2.5 text-gray-500 text-xs">{`+${
            data.length - 1
          } more`}</span>
        </button>
      ) : (
        <p className="font-medium text-gray-500 text-sm">
          {" "}
          {data[0].charAt(0).toUpperCase() + data[0].slice(1).toLowerCase()}
        </p>
      )}
      <StaticPopup
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
        header={title}
      >
        <div className="flex flex-wrap gap-3">
          {data.map((name) => (
            <span class="bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
              {name}
            </span>
          ))}
        </div>
      </StaticPopup>
    </>
  );
};

export default ViewMore;
