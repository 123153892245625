import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  MobileStepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";

import { UserProfileForm } from "./components";
import BusinessRegistrationForm from "views/Business/components/BusinessRegistrationForm";
import MainStepperBusinessForm from "views/Business/components/businessRegistrationFormSteps/BusinessRegStep1";
import AddServiceForm from "views/Business/components/BusinessRegForms/AddServiceForm";
import AddProjectsForm from "views/Business/components/BusinessRegForms/AddProjectsForm";
import AddClientsForm from "views/Business/components/BusinessRegForms/AddClientsForm";
import BusinessRegStep1 from "views/Business/components/businessRegistrationFormSteps/BusinessRegStep1";
import BusinessRegStep2 from "views/Business/components/businessRegistrationFormSteps/BusinessRegStep2";
import axios from "utils/axios";

function BusinessStepperForm({}) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up(1024));

  const [activeStep, setActiveStep] = useState(null);
  const [fetchDataEnabled, setFetchDataEnabled] = useState(true);

  // console.log("activeStep...", activeStep);
  const navigate = useNavigate();

  const auth = useSelector((state) => state?.auth);
const business = useSelector((state) => state.business);
const userId = auth?.user?.userId;
const supplierId = business?.profile?.supplierId;





  const steps = [
    <BusinessRegStep1 />,
    <BusinessRegStep2 />,
    <AddServiceForm />,
    <AddProjectsForm
      setActiveStep={setActiveStep}
      setFetchDataEnabled={setFetchDataEnabled}
    />,
    <AddClientsForm />,
  ];

  function findMissingNumber(stepperCount) {
    let expectedNumber = 1;

    for (const num of stepperCount) {
      if (num === expectedNumber) {
        expectedNumber++;
      }

      if (expectedNumber > 5) {
        return 1; // All numbers from 1 to 5 are present
      }
    }

    return expectedNumber;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/supplier/get/steppercount?supplierId=${supplierId}`
        );
        const stepperCount = response.data.stepperCount;

        // Call findMissingNumber and get the result
        const missingNumber = findMissingNumber(stepperCount);
        if (fetchDataEnabled) {
          setActiveStep(missingNumber - 1);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  });
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const stepDivs = steps.map((step, index) => (
    <div
      key={index}
      style={{
        height: "6px",
        background: index <= activeStep ? "#3963AB" : "#D9D9D9",
        width: "calc(20% - 3px)", // 20% width for each step with a 3px gap
        marginRight: index < steps.length - 1 ? "3px" : 0, // Add margin except for the last step
      }}
    />
  ));

  useEffect(()=>{
    if(!userId){
      navigate("/")
    } 
  },[])

  return (
    <>
      <Helmet>
        <title>Neevay - Business Details</title>
      </Helmet>

      <Grid container xs={12} style={{ position: "relative" }}>
        {isMd && (
          <Grid
            item
            md={6}
            lg={6}
            sx={{
              backgroundColor: "#FBF8F6",
              display: "flex",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              position: "fixed",
              top: "0",
              width: isMd ? "50%" : null,
            }}
          >
            {activeStep === 2 ? (
              <Box
                height={"538px"}
                width={"471px"}
                component="img"
                src={`${BASE_URL}/assets/business/web-businesspage-services.webp`}
              />
            ) : activeStep === 3 ? (
              <Box
                height={"538px"}
                width={"471px"}
                component="img"
                src={`${BASE_URL}/assets/business/web-businesspage-projects.webp`}
              />
            ) : activeStep === 4 ? (
              <Box
                height={"538px"}
                width={"471px"}
                component="img"
                src={`${BASE_URL}/assets/business/web-businesspage-clients.webp`}
              />
            ) : (
              <Box
                height="327px"
                component="img"
                src={`${BASE_URL}/assets/business/web-userform-UserFormImg.svg`}
              />
            )}
          </Grid>
        )}
        <Grid
          item
          md={6}
          lg={6}
          sx={{
            paddingX: isMd ? "50px" : "15px",
            paddingY: "35px",
            width: "100%",
            position: "absolute",
            top: "0",
            right: "0",
          }}
        >
          {activeStep === 0 ? (
            <Grid style={{ marginBottom: "23px" }}>
              <Typography
                style={{
                  color: "#5E5873",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "28.8px" /* 120% */,
                  letterSpacing: "0.14px",
                }}
              >
                Just Few Steps More to Complete your Business Registration
              </Typography>
            </Grid>
          ) : null}
          {activeStep === 1 ? (
            <Grid style={{ marginBottom: "23px" }}>
              <Typography
                style={{
                  color: "#5E5873",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "28.8px" /* 120% */,
                  letterSpacing: "0.14px",
                }}
              >
                Just Few Steps More to Complete your Business Registration...{" "}
              </Typography>
            </Grid>
          ) : null}
          {activeStep === 2 ? (
            <Grid style={{ marginBottom: "23px" }}>
              <Typography
                style={{
                  color: "#5E5873",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "28.8px" /* 120% */,
                  letterSpacing: "0.14px",
                }}
              >
                Add your services{" "}
              </Typography>
            </Grid>
          ) : null}
          {activeStep === 3 ? (
            <Grid style={{ marginBottom: "23px" }}>
              <Typography
                style={{
                  color: "#5E5873",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "28.8px" /* 120% */,
                  letterSpacing: "0.14px",
                }}
              >
                Projects Done{" "}
              </Typography>
            </Grid>
          ) : null}
          {activeStep === 4 ? (
            <Grid style={{ marginBottom: "23px" }}>
              <Typography
                style={{
                  color: "#5E5873",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "28.8px" /* 120% */,
                  letterSpacing: "0.14px",
                }}
              >
                Top Clients{" "}
              </Typography>
            </Grid>
          ) : null}

          <div style={{ display: "flex", marginBottom: "25px" }}>
            {stepDivs}
          </div>

          {steps[activeStep]}
        </Grid>
      </Grid>
    </>
  );
}

export default BusinessStepperForm;
