import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSelectedFacetString } from "utils/searchUtils";
import queryString from "query-string";
import axios from "utils/axios";
import useIsMounted from "hooks/useIsMounted";
import { Helmet } from "react-helmet";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import { getUrl } from "actions/businessActions";
import BusinessNavbar from "views/Search2.0/components/BusinessPages/BusinessNavbar";
import BusinessProfileAbout from "views/Search2.0/components/BusinessPages/BusinessProfileAbout";
import BusinessProfileHead from "views/Search2.0/components/BusinessPages/BusinessProfileHead";
import BusinessOfferingPage from "views/Search2.0/components/BusinessPages/BusinessOfferingPage";
import Projects from "views/Search2.0/components/BusinessPages/Projects";
import TopClient from "views/Search2.0/components/BusinessPages/TopClient";
import Domains from "views/Search2.0/components/BusinessPages/Domains";
import AwardsCertification from "views/Search2.0/components/BusinessPages/AwardsCertification";
import { useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useRef } from "react";
import NewBusinessProfileMobileView from "views/Search2.0/components/BusinessPages/MobileView/NewBusinessProfileMobileView";
import CertificateSection from "../components/CertificateSection";
import Awards from "../components/Awards";
const NewMyBusinesspage = ({
  permissions,
  PublicProfile,
  profile,
  projects,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1024));
  const isSm = useMediaQuery(theme.breakpoints.down(768));
  const bProfile = profile?.businessProfile;
  let isAwardExists = false;
  const scrollContainerRef = useRef(null);
  const isEditView = true;
  return (
    <>
      {isSm ? (
        <NewBusinessProfileMobileView
          profile={profile}
          PublicProfile={PublicProfile}
          permissions={permissions}
          isEditView={isEditView}
          projects={projects}
        />
      ) : (
        <Grid
          ref={scrollContainerRef}
          
        >
          <BusinessProfileHead
            profile={profile}
            PublicProfile={PublicProfile}
            permissions={permissions}
            isEditView={isEditView}
          />
          <BusinessNavbar
            profile={profile}
            projects={projects}
            scrollContainerRef={scrollContainerRef}
          />
          <div id="component1">
            <BusinessProfileAbout
              profile={profile}
              PublicProfile={PublicProfile}
              permissions={permissions}
              isEditView={isEditView}
            />
          </div>

          <div id="component3">
            <BusinessOfferingPage
                profile={profile}
                PublicProfile={PublicProfile}
                permissions={permissions}
                isEditView={isEditView}
              />
          </div>
          <div id="component4">
            <Projects
                profile={profile}
                PublicProfile={PublicProfile}
                permissions={permissions}
                isEditView={isEditView}
                projects={projects.filter((project) => !project.isDeleted)}
              />
          </div>
          <div id="component5">
            <TopClient
                profile={profile}
                PublicProfile={PublicProfile}
                permissions={permissions}
                isEditView={isEditView}
              />
          </div>
          {profile?.businessProfile?.domains?.length > 0 && (
            <Domains
              profile={profile}
              PublicProfile={PublicProfile}
              permissions={permissions}
              isEditView={isEditView}
            />
          )}
          <div id="component6">
            <Grid
              container
              sx={{ mt: 3, marginBottom: "20px" }}
              direction={!isAwardExists ? "row-reverse" : "row"}
              spacing={1}

            >
              <Grid
                item
                xs={12}
                md={5.7}
                sm={12}
                style={{ marginY: "20px" }}
            
              >
                <Awards
                  awards={profile?.awards}
                  supplierId={profile?.supplierId}
                  // isPublicProfile={isPublicProfile}
                  permissions={permissions}
                />
              </Grid>
              <Grid item xs={12} md={5.7} sm={12}  >
                <CertificateSection
                  certificateDetails={bProfile?.certificateImages}
                  supplierId={profile?.supplierId}
                  // isPublicProfile={isPublicProfile}
                  permissions={permissions}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
    </>
  );
};

export default NewMyBusinesspage;
