import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from "utils/axios";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import LeadsList from "./LeadsList";

function UnreadLeads() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const lead = useSelector((state) => state.lead);
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const business  = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  // console.log("supplierId", supplierId);

  useEffect(() => {
    // console.log("Leads is refreshed:", lead);
  }, [lead?.leads]);

  // TODO_SP: Check if there is need to pull data, everytime user lands on the page

  return (
    <DashboardLayout path="/leads">
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container height="calc(100vh - 135px)" alignItems="baseline" >
            <Grid
              item
              sm={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant="h4"
                // color={"primary.main"}
                fontWeight="bold"
              // mb={2}
              >
                Business Leads
              </Typography>
            </Grid>
            <Divider />
            <Grid item sm={12} fullWidth height={"90%"}>
              <LeadsList
                title="Active Leads"
                leads={lead?.leads?.filter((row) => {
                    // Check if the row's status is not "CLOSED"
                    if (row.status !== "CLOSED") {
                      // Check if "suppliers" property exists and it's an array with at least one object
                      if (row.suppliers && Array.isArray(row.suppliers) && row.suppliers.length > 0) {
                        // Check if any supplier's status is "ACKNOWLEDGED"
                        const hasAcknowledgedSupplier = row.suppliers.some(supplier => supplier.status === "ACKNOWLEDGED");
                        // Return true (keep the row) only if there is no "ACKNOWLEDGED" supplier
                        return !hasAcknowledgedSupplier;
                      }
                      // If "suppliers" property doesn't exist or is an empty array, keep the row
                      return true;
                    }
                    // If the row's status is "CLOSED", exclude it
                    return false;
                  })}                supplierId={supplierId}
              />
            </Grid>
            {/* <Grid item sm={12} mt={4} fullWidth>
              <LeadsList
                title="Closed Leads"
                leads={lead?.leads?.filter((row) => row.status === "CLOSED")}
                supplierId={supplierId}
              />
            </Grid> */}
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
  );
}

export default UnreadLeads;
