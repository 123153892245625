const fields = [
  {
    key: "businessName",
    title: "Business Name",
    mappedField: "businessName",
    component: "Drawer",
  },
 
  {
    key: "businessMobileNumber",
    title: "Contact Details",
    mappedField: "businessMobileNumber",
    component: "Text",
  },
   {
    key: "businessEmail",
    title: "Business Email",
    mappedField: "businessEmail",
    component: "Text",
  },
  // {
  //   key: "serviceOffered",
  //   title: "Service Offered",
  //   mappedField: "serviceOffered",
  //   component: "ServicePopup",
  // },
  // {
  //   key: "domains",
  //   title: "Sector",
  //   mappedField: "domains",
  //   component: "ViewMore",
  // },
  // {
  //   key: "projectNames",
  //   title: "Projects",
  //   mappedField: "projectNames",
  //   component: "ViewMore",
  // },
  // {
  //   key: "clients",
  //   title: "Client Name",
  //   mappedField: "clients",
  //   component: "Text",
  // },
  {
    key: "annualTurnOver",
    title: "GST Turnover",
    mappedField: "annualTurnOver",
    component: "Text",
  },
  // {
  //   key: "gstCertified",
  //   title: "GST Status",
  //   mappedField: "gstCertified",
  //   component: "Text",
  // },
  {
    key: "gstNumber",
    title: "GST Number",
    mappedField: "gstNumber",
    component: "Text",
  },
  // {
  //   key: "establishYear",
  //   title: "Age Of Business",
  //   mappedField: "establishYear",
  //   component: "Text",
  // },
  // {
  //   key: "websiteUrl",
  //   title: "Website URL",
  //   mappedField: "websiteUrl",
  //   component: "Text",
  // },
  
  // {
  //   key: "businessType",
  //   title: "Business Type",
  //   mappedField: "businessType",
  //   component: "Text",
  // },
];

export default fields;
