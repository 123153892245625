import React, { useEffect, useState } from "react";
import StaticPopup from "components/StaticPopup";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import axios from "utils/axios";
import * as Yup from "yup";
import { editRepeatFixedRfq } from "actions/requirementsAction";
import Loading from "components/Loading";

const validationSchema = Yup.object({
  orderName: Yup.string().required("Order Name is required"),
  vendorName: Yup.string().required("Vendor Name is required"),
  typeOfOrder: Yup.string().required("Type of Order is required"),
  orderValue: Yup.string().required("Order Value is required"),
  description: Yup.string().required("Description is required"),
});

const EditRepeatOrderForm = ({ openEditPopup, handleCloseEditPopup, data }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [vendorName, setVendorName] = useState(null);
  const [vendorData, setVendorData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [typeOfVendor, setTypeOfVendor] = useState({
    RepeatOrder: false,
    FixedOrder: false,
  });

  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;

  const handleChangeTypeOfVendor = (title, value) => {
    setTypeOfVendor({
      ...typeOfVendor,
      [title]: value,
    });
  };
  const handleSubmit = async (values) => {
    setLoader(true);
    const typeOfOrderValue =
      typeOfVendor.RepeatOrder && typeOfVendor.FixedOrder
        ? "REPEAT_AND_FIXED_ORDER"
        : typeOfVendor.RepeatOrder
        ? "REPEAT_ORDER"
        : "FIXED_ORDER";
    const payloadData = {
      repeatRfqId: values?.repeatRfqId,
      orderName: values?.orderName,
      vendorName: values?.vendorName,
      typeOfOrder: typeOfOrderValue,
      vendorId: values?.vendorId,
      orderValue: values?.orderValue,
      description: values?.description,
      createdBy: values?.createdBy,
    };
    const payload = await dispatch(editRepeatFixedRfq(payloadData));
    setLoader(false);
    handleCloseEditPopup();
  };

  useEffect(() => {
    setTypeOfVendor({
      RepeatOrder:
        data?.typeOfOrder == "Fixed/Repeat Order" ||
        data?.typeOfOrder == "Repeat Order",
      FixedOrder:
        data?.typeOfOrder == "Fixed Vendor" ||
        data?.typeOfOrder == "Fixed/Repeat Order",
    });
    setVendorName(data?.vendorName);
    setSelectedVendor({
      vendorId: data?.vendorId,
      vendorName: data?.vendorName,
    });
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make the API request
        const response = await axios.get(
          `/api/auth/directory/vendors?entityId=${entityId}&searchParam=${vendorName}`
        );
        const payload = await response;
        const vendorData = payload?.data?.data;

        // Update the state with the fetched data
        setVendorData(vendorData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data only when vendorName changes
    if (vendorName?.trim() !== "") {
      fetchData();
    } else {
      // If the input is empty, reset the data
      setVendorData([]);
    }
  }, [vendorName]);

  return (
    <div>
      <StaticPopup
        openModal={openEditPopup}
        handleClose={handleCloseEditPopup}
        header={data?.repeatRfqId}
      >
        <Formik
          enableReinitialize
          initialValues={{
            repeatRfqId: data?.repeatRfqId,
            orderName: data?.orderName,
            vendorName: data?.vendorName,
            typeOfOrder: data?.typeOfOrder,
            vendorId: data?.vendorId,
            orderValue: data?.orderValue,
            description: data?.description,
            createdBy: userProfile?.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors }) => (
            <Form className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-4">
                <div className="flex gap-2">
                  <div className="w-[120px]">
                    <p>Order Name</p>
                  </div>
                  <div className="flex-1">
                    <Field
                      type="text"
                      id="orderName"
                      name="orderName"
                      className="w-full h-7 border border-gray-500 rounded-md"
                      placeholder="Enter RFQ name"
                    />
                    <ErrorMessage
                      name="orderName"
                      component="div"
                      className="text-red-500 text-xs"
                    />
                  </div>
                </div>

                <div className="flex gap-2">
                  <div className="w-[120px]">
                    <p>Type Of Order</p>
                  </div>
                  <div className="flex gap-4">
                    <label>
                      <Field
                        type="checkbox"
                        id="repeatOrderCheckbox"
                        name="repeatOrderCheckbox"
                        checked={typeOfVendor?.RepeatOrder}
                        className="mr-2"
                        onClick={(e) =>
                          handleChangeTypeOfVendor(
                            "RepeatOrder",
                            e.target.checked
                          )
                        }
                      />
                      Repeat Order
                    </label>

                    <label>
                      <Field
                        type="checkbox"
                        id="fixedOrderCheckbox"
                        name="fixedOrderCheckbox"
                        checked={typeOfVendor?.FixedOrder}
                        className="mr-2"
                        onClick={(e) =>
                          handleChangeTypeOfVendor(
                            "FixedOrder",
                            e.target.checked
                          )
                        }
                      />
                      Fixed Vendor
                    </label>
                    <ErrorMessage
                      name="typeOfOrder"
                      component="div"
                      className="text-red-500 text-xs"
                    />
                  </div>
                </div>

                <div className="flex gap-2 w-full">
                  <div className="w-[120px]">
                    <p>Vendor Name</p>
                  </div>
                  <div className="flex-1">
                    <Autocomplete
                      sx={{ width: "100%" }}
                      size="small"
                      options={vendorData}
                      getOptionLabel={(option) => option?.vendorName}
                      value={selectedVendor}
                      onChange={(event, newValue) =>
                        setSelectedVendor(newValue)
                      }
                      inputValue={vendorName}
                      onInputChange={(event, newInputValue) =>
                        setVendorName(newInputValue)
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Vendor Name" />
                      )}
                    />
                    <ErrorMessage
                      name="vendorName"
                      component="div"
                      className="text-red-500 text-xs"
                    />
                  </div>
                </div>

                <div className="flex gap-2">
                  <div className="w-[120px]">
                    <p>Order Value</p>
                  </div>
                  <div className="flex-1">
                    <Field
                      type="text"
                      id="orderValue"
                      name="orderValue"
                      className="w-full h-7 border border-gray-500 rounded-md"
                      placeholder="Enter RFQ name"
                    />
                    <ErrorMessage
                      name="orderValue"
                      component="div"
                      className="text-red-500 text-xs"
                    />
                  </div>
                </div>

                <div className="flex gap-2">
                  <div className="w-[120px]">
                    <p>Order Description</p>
                  </div>
                  <div className="flex-1">
                    <textarea
                      type="text"
                      rows="4"
                      value={values?.description}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                      id="description"
                      name="description"
                      className="w-full border h-auto text-wrap border-gray-500 rounded-md"
                      placeholder="Enter RFQ name"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500 text-xs"
                    />
                  </div>
                </div>

                <div className="flex gap-2 mt-3 border-t border-b-gray-300 pt-3">
                  <button
                    className="p-2 bg-blue-700 text-white hover:bg-blue-800 rounded-lg"
                    type="submit"
                  >
                    {loader ? <Loading /> : "Save"}
                  </button>

                  <button
                    className="p-2 hover:bg-gray-200 rounded-lg"
                    onClick={handleCloseEditPopup}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </StaticPopup>
    </div>
  );
};

export default EditRepeatOrderForm;
