import React from "react";

import {
  // Home as HomeView,
  SignUpOTPVerify as SignUpOTPVerifyView,
  SignUp as SignUp,
  RegisterUser as RegisterUser,
  SignIn as SignInView,
  SignInSendMobileOTP as SignInSendMobileOTPPage,
  SignInOTPVerify as SignInOTPVerifyView,
  UserDashboard as UserDashboardPage,
  Profile as UserProfilePage,
  Favourites as FavouritesPage,
  Notifications as NotificationsPage,
  Requirements as RequirementsPage,
  Leads as LeadsPage,
  Orders as OrdesPage,
  MyBusiness as MyBusinessPage,
  BusinessProfile as BusinessProfilePage,
  Project as ProjectPage,
  BusinessService as ServicePage,
  Search as SearchPage,
  TermsAndCondition as TermsAndConditionPage,
  PrivacyPolicy as PrivacyPolicyPage,
  SubscribePlan as SubscribePlanPage,
  NotFoundCover as NotFoundCoverPage,

  // OnboardingInterest as OnboardingInterestView,
  // OnboardingSkills as OnboardingSkillsView,
  // OrganizationProfile as OrganizationProfilePage,
  // CreateAccount as CreateAccountPage,
  ThemePage as ThemePage,
  // Profile as ProfilePage,
  NewRequirement as NewRequirementPage,
  ViewRequirement as ViewRequirementPage,
  SendEnquiry as SendEnquiryPage,
  EditBusiness as EditBusinessView,
  Members as MembersView,
  AboutUs as AboutUsView,
  ConfirmPayment as ConfirmPayment,
  OrderForm as OrderForm,
  PaymentSuccessPage as PaymentSuccessPage,
} from "views";
import { AboutUs } from "views/About2.0/AboutUs";
import Blogs from "views/Blogs/Blogs";
import CompanyDirectory from "views/CompanyDirectory";
import GetStartedFree from "views/GetStartedFree/GetStartedFree";
import Home from "views/Home 3.0/Home";
import BasicUpgrade from "views/Home 3.0/components/NavBar/BasicUpgrade";
import NeevayNetwork from "views/NeevayNetwork";
// import BusinessStepperForm from "views/VendorQuotationForms/StepperForm";
import RegisterBusiness from "views/Register/RegisterBusiness";
import RfqsSections from "views/Rfqs/RfqsSections";
import NewSearch from "views/Search2.0/NewSearch";
import BusinessCategoryResults from "views/Search2.0/components/BusinessCategoryResults";
import BusinessPage from "views/Search2.0/components/BusinessPages/BusinessPage";
import NewBusinessProfilePage from "views/Search2.0/components/BusinessPages/NewBusinessProfilePage";
import FilterUrlConverter from "views/Search2.0/components/FilterUrlConverter/FilterUrlConverter";
import NewSubScribePlan from "views/SubScribePlan2.0/NewSubScribePlan";
import LeadsListView from "views/UserProfile/Leads/LeadsListView";
import { NonPremiumEmailThankYouForm } from "views/UserProfile/Leads/NonPremiumEmailThankYouForm";
import UnreadLeads from "views/UserProfile/Leads/UnreadLeads";
import OrderPdf from "views/UserProfile/Orders/OrdersPdfView/OrderPdfForm";
import RfqsDetails from "../views/Rfqs/RfqsDetails";
import Blog from "views/Blog/Blog";
import BlogForm from "views/Blog/BlogForm";
import ListOfBlog from "views/Blog/ListOfBlog";
import PreViewBlog from "views/Blog/PreViewBlog";
import { PublishBlog } from "views/Blog/PublishBlog";
import { CaseStudy } from "views/Blog/CaseStudy";
import RepeatRFQs from "views/RepeatRFQs";
import ProjectSection from "views/Project/ProjectSection";
import Homepage from "views/NewHomePage/Homepage";
import CorporatePageMain from "views/NewHomePage/CorporatePages/CorporatePageMain";
import BuyerPageMain from "views/NewHomePage/BuyerComponents/BuyerPageMain";
import Material from "views/VendorQuotationForms/Material";
import Labour from "views/VendorQuotationForms/Labour";
import ProvidingAndFixing from "views/VendorQuotationForms/ProvidingAndFixing";
import { Stepper } from "@material-ui/core";
import VendorStepperform from "views/VendorQuotationForms/VendorStepperform";
import BusinessStepperForm from "views/Register/BusinessStepperForm";
import LabourQuotationForm from "views/VendorQuotationForms/LabourQuotationForm";
import MaterialQuotationForm from "views/VendorQuotationForms/MaterialQuotationForm";
import ProvidingQuotationForm from "views/VendorQuotationForms/ProvidingQuotationForm";
import RfqsSectionsNew from "views/Rfqs/RfqsSectionsNew";
import FullComparison from "views/UserProfile/Leads/FullComparison";
import Negotiation from "views/UserProfile/Leads/Negotiation";
import QuotationFormRouter from "views/UserProfile/Leads/QuotationFormRouter";
import SubmittedQuotations from "views/UserProfile/Leads/SubmittedQuotations";

const routes = [
  {
    path: "/",
    renderer: (params = {}) => {
      const schema1 = {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        name: "Neevay",
        url: "https://www.neevay.com/",
        potentialAction: {
          "@type": "SearchAction",
          target: "https://www.neevay.com/{search_term_string}",
          "query-input": "required name=search_term_string",
        },
      };

      const schema2 = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Neevay",
        url: "https://www.neevay.com/",
        logo: "https://www.neevay.com/assets/home/logoHeroSection.svg",
        contactPoint: {
          "@type": "ContactPoint",
          telephone: "8604860478",
          contactType: "technical support",
          areaServed: "IN",
          availableLanguage: "en",
        },
        sameAs: [
          "https://www.facebook.com/neevayofficial?mibextid=ZbWKwL",
          "https://twitter.com/NeevayOfficial?t=KEaVtTflTFA3DvRDpMCeQw&s=09",
          "https://instagram.com/neevay_official?igshid=MzRlODBiNWFlZA==",
          "https://www.youtube.com/channel/UC3dTz8WCJBJXHKWmewBlvfw",
          "https://www.linkedin.com/company/neevay/",
          "https://www.neevay.com/",
        ],
      };

      const schema3 = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        name: "Neevay platform - Introduction video",
        description:
          "Search now best in class vendors for your construction projects",
        thumbnailUrl: "https://youtu.be/Ck-xFo3d_kw",
        uploadDate: "",
        duration: "PT1M30S",
      };

      const schema4 = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        name: "Demo-Neevay- Search Engine Of Construction",
        description:
          "Get your Business visibility increaded 10x with Neevay platform",
        thumbnailUrl: "https://youtu.be/3zt_OG-MQHI",
        uploadDate: "",
        duration: "PT3M3S",
      };

      const schema5 = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [
          {
            "@type": "Question",
            name: "Why should I register myself to Neevay?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Classification of Buyer: Anyone who is looking for construction material or construction services for the construction project.Classification of Seller: Anyone who provides the construction material or construction services like contracting, consulting etc for the construction projects.Neevay is “first of its kind” marketplace and search engine of construction industry which connects the buyers and sellers with unique workflow to avoid spamming and irrelevant leads. So, if you are associated to any business (buyer or seller) related to Construction Industry, Neevay is the right platform which provides you an ability to search anything anywhere. Click Here to register yourself now to get access to Neevay’s Construction Ecosystem.",
            },
          },
          {
            "@type": "Question",
            name: "Why should I create my Business Profile on Neevay?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "If you are providing any material or services to construction projects, you should register create your business profile on Neevay, its Ecosystem and search engine will unlock unlimited growth opportunities for your business with industry specific business profile and search engine. Click Here to register yourself now to get access to Neevay’s Construction Ecosystem.",
            },
          },
          {
            "@type": "Question",
            name: "Where do I receive Business lead for my registered business?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "If your business is registered on Neevay, you will receive leads on email, text message and WhatsApp with quick links to acknowledge which triggers an alert to the sender of leads and shows your promptness increasing the confidence score of the deal.",
            },
          },
          {
            "@type": "Question",
            name: "Is there any cost associated to use portal?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "We have good news for you, Neevay has the FREEMIUM model, which means that you can use the basic features of the platform for free of cost and pay only for the features which comes up paid subscriptions. If you are providing the material or services to the construction projects, we strongly recommend you to subscribe for PREMIUM membership as it enables the features of the Business Profile which are highly recognized by your target customers like business statutory information, projects, clients, recommendations etc. Business Profiles with this information not only increase the number of business enquiries, but also increases the chances of deal closure due to relevance of the business enquiry. Click Here to view the subscription plans of Neevay.",
            },
          },
          {
            "@type": "Question",
            name: "Is there nay hidden cost like commission for enquiry or percentage of deal value from the portal?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "You will be surprised to know that there is no hidden cost or commission as percentage of business value involved in the portal. Neevay only connects the buyer and seller, providing them an ability to negotiate outside the portal following their company internal due diligence processes. Neevay’s pricing model is only for the features comes in its subscription plans as fixed cost for the subscription duration.Click Here to view the subscription plans of Neevay.",
            },
          },
          {
            "@type": "Question",
            name: "How do I contact Neevay support in case of any support needed?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "We believe in providing the best in class support to our customers, and put the best efforts to achieve the same. You can contact us at any of the below channel and we will contact you at earliest to resolve your queries. Your feedback will be highly appreciated as it helps us to improve ! Write to us at support@neevay.com OR Give a Missed Call at +91 8604860478",
            },
          },
        ],
      };

      const scriptTag1 = document.createElement("script");
      scriptTag1.type = "application/ld+json";
      scriptTag1.innerHTML = JSON.stringify(schema1);

      const scriptTag2 = document.createElement("script");
      scriptTag2.type = "application/ld+json";
      scriptTag2.innerHTML = JSON.stringify(schema2);

      const scriptTag3 = document.createElement("script");
      scriptTag3.type = "application/ld+json";
      scriptTag3.innerHTML = JSON.stringify(schema3);

      const scriptTag4 = document.createElement("script");
      scriptTag4.type = "application/ld+json";
      scriptTag4.innerHTML = JSON.stringify(schema4);

      const scriptTag5 = document.createElement("script");
      scriptTag5.type = "application/ld+json";
      scriptTag5.innerHTML = JSON.stringify(schema5);

      document.head.appendChild(scriptTag1);
      document.head.appendChild(scriptTag2);
      document.head.appendChild(scriptTag3);
      document.head.appendChild(scriptTag4);
      document.head.appendChild(scriptTag5);

      // return <Home {...params} />;
      return <Homepage {...params}/>;
    },
  },

  {
    path: "/register",
    renderer: (params = {}) => <SignUp {...params} />,
  },

  // {
  //   path: "/home3",
  //   renderer: (params = {}) => <Home {...params} />,
  // },
  {
    path: "/register/:sourceName",
    renderer: (params = {}) => <SignUp {...params} />,
  },
  {
    path: "/signup-mobile-otp-verify",
    renderer: (params = {}) => <SignUpOTPVerifyView {...params} />,
  },

  {
    path: "/signin",
    renderer: (params = {}) => <SignInView {...params} />,
  },
  {
    path: "/signin-mobile",
    renderer: (params = {}) => <SignInSendMobileOTPPage {...params} />,
  },
  {
    path: "/signin-mobile-send-otp",
    renderer: (params = {}) => <SignInSendMobileOTPPage {...params} />,
  },
  {
    path: "/signin-mobile-otp-verify",
    renderer: (params = {}) => <SignInOTPVerifyView {...params} />,
  },
  {
    path: "/user-dashboard",
    renderer: (params = {}) => <UserDashboardPage {...params} />,
  },
  {
    path: "/user-profile",
    renderer: (params = {}) => <UserProfilePage {...params} />,
  },
  {
    path: "/user-notifications",
    renderer: (params = {}) => <NotificationsPage {...params} />,
  },
  {
    path: "/shortlisted-profile",
    renderer: (params = {}) => <FavouritesPage {...params} />,
  },
  {
    path: "/user-orders",
    renderer: (params = {}) => <OrdesPage {...params} />,
  },
  {
    path: "/download-pdf",
    renderer: (params = {}) => <OrderPdf {...params} />,
  },
  {
    path: "/requirements",
    renderer: (params = {}) => <RequirementsPage {...params} />,
  },
  {
    path: "/add-requirement",
    renderer: (params = {}) => <NewRequirementPage {...params} />,
  },
  {
    path: "/view-requirement",
    renderer: (params = {}) => <ViewRequirementPage {...params} />,
  },
  {
    path: "/enquiry",
    renderer: (params = {}) => <SendEnquiryPage {...params} />,
  },

  {
    path: "/leads",
    renderer: (params = {}) => <LeadsPage {...params} />,
  },
  {
    path: "/submitted-quotation",
    renderer: (params = {}) => <SubmittedQuotations {...params} />,
  },
  {
    path: "/book-call/upgrade/:id",
    renderer: (params = {}) => <NonPremiumEmailThankYouForm {...params} />,
  },
  {
    path: "/leads/pending_leads",
    renderer: (params = {}) => <UnreadLeads {...params} />,
  },

  {
    path: "/leads/:requirmentsId/",
    renderer: (params = {}) => <LeadsListView {...params} />,
  },
  {
    path: "/members",
    renderer: (params = {}) => <MembersView {...params} />,
  },

  {
    path: "/company/:url",
    renderer: (params = {}) => <NewBusinessProfilePage {...params} />,
  },
  // {
  //   path: "/company/:url",
  //   renderer: (params = {}) => <BusinessProfilePage {...params} />,
  // },

  {
    path: "/search/:region/:searchString",
    renderer: (params = {}) => <NewSearch {...params} />,
  },

  {
    path: "/register/user",
    renderer: (params = {}) => <RegisterUser {...params} />,
  },
  {
    path: "/register/business",
    renderer: (params = {}) => <RegisterBusiness {...params} />,
  },
  {
    path: "/register/business/details",
    renderer: (params = {}) => <BusinessStepperForm {...params} />,
  },
  {
    path: "/my-business",
    renderer: (params = {}) => <MyBusinessPage {...params} />,
  },
  {
    path: "/add-service",
    renderer: (params = {}) => <ServicePage {...params} />,
  },
  {
    path: "/terms-and-condition",
    renderer: (params = {}) => <TermsAndConditionPage {...params} />,
  },
  {
    path: "/privacy-policy",
    renderer: (params = {}) => <PrivacyPolicyPage {...params} />,
  },
  {
    path: "/subscribe-plan",
    renderer: (params = {}) => <NewSubScribePlan {...params} />,
  },
  {
    path: "/upgrade",
    renderer: (params = {}) => <BasicUpgrade {...params} />,
  },

  {
    path: "/add-project",
    renderer: (params = {}) => <ProjectPage {...params} />,
  },
  {
    path: "/edit-business",
    renderer: (params = {}) => <EditBusinessView {...params} />,
  },

  {
    path: "/about-us",
    renderer: (params = {}) => <AboutUs {...params} />,
  },
  {
    path: "/confirm-Payment",
    renderer: (params = {}) => <ConfirmPayment {...params} />,
  },
  {
    path: "/prepare-order",
    renderer: (params = {}) => <OrderForm {...params} />,
  },
  {
    path: "/payment-success",
    renderer: (params = {}) => <PaymentSuccessPage {...params} />,
  },
  {
    path: "/not-found-cover",
    renderer: (params = {}) => <NotFoundCoverPage {...params} />,
  },

  {
    path: "/theme",
    renderer: (params = {}) => <ThemePage {...params} />,
  },
  {
    path: "/search-business-category/:searchString/:region",
    renderer: (params = {}) => <BusinessCategoryResults {...params} />,
  },
  {
    path: "/search-business-category/:searchString/:region/:filterData",
    renderer: (params = {}) => <FilterUrlConverter {...params} />,
  },
  {
    path: "/get-started",
    renderer: (params = {}) => <GetStartedFree {...params} />,
  },
  // {
  //   path: "/search-business-category-url/:searchString/:region/:variable/:businessCategory",
  //   renderer: (params = {}) => <FilterUrlConverter />,
  // },
  {
    path: "/business-details/:url/:searchString/:region/:pageNo",
    renderer: (params = {}) => <BusinessPage {...params} />,
  },

  {
    path: "/company-directory",
    renderer: (param = {}) => <CompanyDirectory />,
  },
  {
    path: "/projects",
    renderer: (param = {}) => <ProjectSection />,
  },
  {
    path: "/rfqs",
    renderer: (param = {}) => <RfqsSectionsNew />,
  },
  {
    path: "/rfqs/create",
    renderer: (param = {}) => <RfqsSectionsNew />,
  },
  {
    path: "/rfqs/:rfqId",
    renderer: (param = {}) => <RfqsDetails />,
  },
  {
    path: "/rfqs/:rfqId/:selectedTab",
    renderer: (param = {}) => <RfqsDetails />,
  },
  {
    path: "/repeated-orders",
    renderer: (param = {}) => <RepeatRFQs />,
  },
  {
    path: "/repeated-orders/create",
    renderer: (param = {}) => <RepeatRFQs />,
  },
  {
    path: "/neevay-network",
    renderer: (param = {}) => <NeevayNetwork />,
  },
  {
    path: "/cms/blogs",
    renderer: (params = {}) => <Blog {...params} />,
  },
  {
    path: "/cms/blog-form",
    renderer: (params = {}) => <BlogForm {...params} />,
  },
  {
    path: "/cms/blog-list",
    renderer: (params = {}) => <ListOfBlog {...params} />,
  },
  {
    path: "/cms/pre-view-blog",
    renderer: (params = {}) => <PreViewBlog {...params} />,
  },
  {
    path: "/publish-blog",
    renderer: (params = {}) => <PublishBlog {...params} />,
  },
  {
    path: "/casestudy-blog",
    renderer: (params = {}) => <CaseStudy {...params} />,
  },
  {
    path: "/corporate-page",
    renderer: (params = {}) => <CorporatePageMain {...params} />,
  },
  {
    path: "/vendor-search",
    renderer: (params = {}) => <BuyerPageMain {...params} />,
  },
  {
    path: "/quotation-form/:rfqType/:rfqId/",
    renderer: (params = {}) => <QuotationFormRouter {...params} />,
  },  
  {
    path: "/material-form",
    renderer: (params = {}) => <MaterialQuotationForm {...params} />,
  },
  {
    path: "/providing-form",
    renderer: (params = {}) => <ProvidingQuotationForm {...params} />,
  },
  {
    path: "/labour-form",
    renderer: (params = {}) => <LabourQuotationForm {...params} />,
  },
  {
    path: "/full-comparison/:rfqId?/:rfqType?",
    renderer: (params = {}) => <FullComparison {...params} />,
  },
  {
    path: "/negotiation/:rfqId?/:rfqType?",
    renderer: (params = {}) => <Negotiation {...params} />,
  },
];

export default routes;
