import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";

import Container from "components/Container";

import {
  fetchRequirement,
  sendRFQTrigger,
  sendNewRFQTrigger,
} from "actions/requirementsAction";
import { useNavigate } from "react-router";


const Page1 = ({ children, enquiry, requirement }) => {
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  const theme = useTheme();
  //////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openRequirements = requirement.requirements.filter(
    (item) => item.status == "OPEN"
  );

  const sendNewRFQ = () => {
    dispatch(sendNewRFQTrigger(enquiry));
    navigate("/enquiry");
  };

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const sendRFQ = (rfq) => {
    const newArray = enquiry.map((obj) => ({ ...obj, ["rfqId"]: rfq.rfqId }));
    setOpen(!open);
    dispatch(
      sendRFQTrigger(
        newArray
      )
    );
    dispatch(fetchRequirement(enquiry[0]?.supplierId, rfq?.rfqId));

    navigate("/enquiry");
  };

  return (
    <Box >
      <Container paddingTop={"0 !important"} >
        <Grid>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 3, padding: 2 }}>{children}</Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

Page1.propTypes = {
  children: PropTypes.node,
};

export default Page1;
