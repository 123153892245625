export function getIsOwner(userId, owners) {
  let isOwnerFlag = false;
  // console.log("getIsOwner ", userId, owners)
  if(userId && owners && owners.length > 0 ){
    isOwnerFlag = owners.includes(userId);
    // console.log("getIsOwner ", userId, owners, isOwnerFlag);
  }

  return isOwnerFlag;

}

export function getIsPremium(subscriptionType) {

  if (subscriptionType === "PREMIUM") {
    return true;
  }
  return false;
}


export function hasEditPermisions(userId, members) {


}

export function getBusinessPermissions(isPublicView, userId, businessProfile) {

  const isUpdatePermission = getIsOwner(userId, businessProfile?.owners);
  //const isUpdatePermission = true; // TODO_SP use above line this is for testing only
  const isPremiumFlag = getIsPremium(businessProfile?.subscriptionType);
  //const isPremiumFlag = true;
  const isViewOnly = (isPublicView) || (!isUpdatePermission);
  // const isUpdateAllowed = (!isPublicView ) && (isPremiumFlag) && (isUpdatePermission);
  const isUpdateAllowed = (!isPublicView) && (isUpdatePermission);
  const isFreeUpdateAllowed = (!isPublicView) && (isUpdatePermission);
  const isUpgradeNeeded = (!isPublicView) && (!isPremiumFlag); // TODO_SP later we might have to add who has permissions to upgrade

  const permissions = {
    isViewOnly,
    isUpdateAllowed,
    isFreeUpdateAllowed,
    isUpgradeNeeded,
    isOwner: isUpdatePermission,
  }

  // console.log("getBusinessPermissions()", permissions);
  return permissions;

}
