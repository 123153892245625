import {
  AccountCircle,
  Menu as MenuIcon,
  Mail as MailIcon,
  Notifications as NotificationsIcon,
  Logout,
  CorporateFare,
  Bookmark,
  LockReset,
} from "@mui/icons-material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import GroupsIcon from "@mui/icons-material/Groups";

export const myProfileMenuList = [
  {
    name: "Dashboard",
    icon: <AccountCircle fontSize="small" />,
    link: "/user-dashboard",
  },
  {
    name: "Profile",
    icon: <AccountCircle fontSize="small" />,
    link: "/user-profile",
  },
  {
    name: "Business",
    icon: <CorporateFare fontSize="small" />,
    link: "/my-business",
  },
  // {
  //   name: "Members",
  //   icon: <GroupsIcon fontSize="small" />,
  //   link: "/members",
  // },
  {
    name: "Enquiries Sent",
    icon: <ContentPasteSearchIcon fontSize="small" />,
    link: "/requirements",
  },
  {
    name: "Leads Received",
    icon: <CallToActionIcon fontSize="small" />,
    link: "/leads",
  },
  {
    name: " Shortlisted Profiles",
    icon: <FavoriteIcon fontSize="small" />,
    link: "/shortlisted-profile",
  },
  // {
  //   name: "Logout",
  //   icon: <Logout fontSize="small" />,
  //   link: "/logout",
  // },
];
