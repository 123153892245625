import axios from "utils/axios";

import {
  ADD_AWARD_URL,
  LIST_AWARD_URL,
  DELETE_AWARD_URL,
} from "config/services";

class AwardsService {
  
  listAwards = (supplierId) =>

    new Promise((resolve, reject) => {
      
      axios
        .get(LIST_AWARD_URL, {supplierId})
        .then((response) => {
          // console.log(
          //   "CertificateService.listCertificate()",
          //   supplierId,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "CertificateService.listCertificate() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  addAwards = (supplierId, awardTitle, awardImage) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("supplierId", supplierId);
      formData.append("awardTitle", awardTitle);
      formData.append("awardImage", awardImage);
      axios
        .post(ADD_AWARD_URL, formData)
        .then((response) => {
          // console.log(
          //   "CertificateService.addCertificate()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "CertificateService.addCertificate() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  deleteAwards = (supplierId, awardId, awardTitle ) =>
    new Promise((resolve, reject) => {
      // const formData = new FormData();
      // formData.append("supplierId", supplierId);
      // formData.append("title", title);
      const payload ={
        supplierId, awardId, awardTitle
      }
      axios
        .post(DELETE_AWARD_URL, payload)
        .then((response) => {
          // console.log(
          //   "CertificateService.deleteCertificate()",
          //   formData,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "CertificateService.deleteCertificate() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });
}

const awardsService = new AwardsService();

export default awardsService;
