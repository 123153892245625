import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import { addProject, updateProject } from "actions/projectActions";
import { Grid, TextField, Button } from "@mui/material";


const ProjectForm = ({ data, onSubmitSuccess }) => {
  const dispatch = useDispatch();

  const business = useSelector((state) => state.business);
  const profile = business.profile;
  const supplierId = profile.supplierId;
 
  return (
    <Grid>
      <Formik
        initialValues={{
          projectName: data?.projectName,
          id: data?._id,
          title: data?.title,
          description: data?.description,
          clientName: data?.clientName,
          location: data?.location,
          // qty: data?.qty,
          // uom: data?.uom,
        }}
        validationSchema={Yup.object().shape({
          projectName: Yup.string()
            .max(50)
            .required("Project Name is required"),
          title: Yup.string(),
          description: Yup.string()
            .max(500)
            .required("Description is required"),
          clientName: Yup.string().max(50).required("Client Name is required"),
          location: Yup.string().max(50).required("Project Location is required"),
          // qty: Yup.string().required("Quantity is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if(data){
              await dispatch(updateProject(supplierId, values));
            } else {
              await dispatch(addProject(supplierId, values));
            }
            onSubmitSuccess();
          } catch (error) {
            // console.log("Form: error", error);

            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
        }) => {
          return (
            <Grid>
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="projectName"
                      id="projectName"
                      label="Project Name"
                      fullWidth
                      required
                      value={values.projectName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.projectName && errors.projectName)}
                      helperText={touched.projectName && errors.projectName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="clientName"
                      id="clientName"
                      label="Client Name"
                      variant="outlined"
                      fullWidth
                      required
                      value={values.clientName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.clientName && errors.clientName)}
                      helperText={touched.clientName && errors.clientName}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="description"
                      id="description"
                      label="Project Highlight"
                      variant="outlined"
                      fullWidth
                      required
                      multiline
                      rows={4}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 500 }}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="location"
                      id="location"
                      label="Project Location"
                      variant="outlined"
                      fullWidth
                      required
                      value={values.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.location && errors.location)}
                      helperText={touched.location && errors.location}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      // sx={{  mr: 1 }}
                      variant="contained"
                      href="/my-business"
                      // color="secondary"
                      sx={{backgroundColor: "#757575",
                      mr: 1 ,
                      color:"white",
                      "&:hover": {
                        backgroundColor: "#757575",
                        transition: "background-color 0.3s, box-shadow 0.3s",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                      }
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    <Button variant="contained" onClick={handleSubmit} 
                     sx={{backgroundColor: "black",
                     color:"white",
                     "&:hover": {
                       backgroundColor: "black",
                       transition: "background-color 0.3s, box-shadow 0.3s",
                       boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                     }
                     }}
                    >
                      Save Project
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          );
        }}
      </Formik>
    </Grid>
  );
};

ProjectForm.propTypes = {
  data: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
};

export default ProjectForm;
