import React from "react";

const ContactsPopUp = ({ vendorContactDetails, setMenuOpen }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-lg min-w-min-sm max-w-max-md">
        {/* Pop-up content */}
        <h2 className="text-lg font-semibold mb-4">Vendors details</h2>
        {vendorContactDetails?.map((vendor) => (
          <div className="flex gap-3 items-start justify-start">
            <div className="rounded-full w-9 h-9 bg-black p-4 text-white text-center flex items-center justify-center">
              <p className="font-bold">
                {vendor?.name
                  ?.split(" ")
                  ?.map((word) => word?.charAt(0)?.toUpperCase())
                  ?.join("")}
              </p>
            </div>
            <div className="p-4 pt-0">
              <h1 className="text-black font-semibold text-base">
                {vendor?.name}
              </h1>
              <p className="text-gray-500 text-md">{vendor?.email}</p>
              <p className="text-gray-500 text-md">{vendor?.mobile}</p>
            </div>
          </div>
        ))}
        <div className="mt-4 flex justify-end">
          <button
            onClick={() => setMenuOpen(false)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactsPopUp;
