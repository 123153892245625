import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {useNavigate } from "react-router";

import {
  Box,
  Card,
  Container,
  Grid,
  ListItem,
  ListItemIcon,
  Typography,
  DialogContent,
  Dialog,
  DialogTitle,
} from "@mui/material";
import BusinessAddressForm from "./BusinessAddressForm";
import { Edit } from "@mui/icons-material";

import { getAddressHO, getAddressStr } from "utils/addressUtils";
import { useDispatch, useSelector } from "react-redux";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import { addLeadActivity } from "actions/crmAction";
import axios from "../../../utils/axios"
import { CREATE_CRM_LEAD_URL, GET_CRM_LEAD_BY_PHONE_NUMBER, GET_USER_DATA_BY_ID } from "config/services";
import { updateCrmId } from "actions/authActions";
import VerificationPopUp from "views/VerificationModal";

function ProfileContact({
  bCategory,
  supplierId,
  website,
  email,
  mobile,
  contact,
  addresses,
  isPublicProfile,
  permissions,
  businessOwnerUserId,
  ...rest
}) {
  // console.log("ProfileDescription ", website, email, contact, addresses);
  const isRealEstateDev = bCategory?.businessCategory == "Real Estate Developer" ? true : false;
  const isEpcContractor = bCategory?.businessCategory ==  "EPC Contractor" ? true : false;
  const [open, setOpen] = React.useState(false);

  const address = getAddressHO(addresses);
  const addressStr = getAddressStr(address);
  // console.log("ProfileContact() ", address, addressStr);

  //const addressStr = "This is test address";\
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openLogInModal, setOpenLogInModal] = React.useState(false)
  const [showEmail, setShowEmail] = React.useState(false)
  const [shoWebsite, setShowWebsite] = React.useState(false)
  const [showNumber, setShowNumber] = React.useState(false)
  
  const [ownerCrmId, setOwnerCrmId] =useState(null)

  useEffect(()=>{
    axios.post(GET_USER_DATA_BY_ID, {userId:businessOwnerUserId})
    .then(response => {
      // console.log("GET BUSINESS OWNER USER PROFILE RESPONSE",response)
      if(response?.data?.payload?.crmLeadId){
      setOwnerCrmId(response?.data?.payload?.crmLeadId)
      }else{
        const urlForCrmByMobile = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(response?.data?.payload?.mobile)
        axios.get(urlForCrmByMobile)
        .then((responseD)=>{setOwnerCrmId(responseD.data[0].ProspectID)
          const crmLeadId1 = responseD.data[0].ProspectID;
          dispatch(updateCrmId(businessOwnerUserId,crmLeadId1))
        })
        .catch((error)=>{
          // console.log("ERROR",error)
          const payload = [{
            "Attribute": "EmailAddress",
            "Value": response?.data?.payload?.email
          },
          {
            "Attribute": "FirstName",
            "Value": response?.data?.payload?.name
            },
          {
            "Attribute": "Phone",
            "Value": response?.data?.payload?.mobile
          },]

           axios.post(CREATE_CRM_LEAD_URL, payload).
            then((crmResponse) => {
              // console.log(":CREATE_CRM_LEAD: Response",crmResponse)
              setOwnerCrmId(crmResponse?.data.Message.Id)
              const crmLeadId2 = crmResponse?.data.Message.Id;
              dispatch(updateCrmId(businessOwnerUserId,crmLeadId2))
              // dispatch(getCRMLeadByPhone(values.mobile))
            })
        })
      }
    })
    .catch(error => {
      // console.log("GET BUSINESS OWNER USER PROFILE ERROR",error);
    });
  },[businessOwnerUserId])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  if (userProfile === null || userProfile === undefined) {
  } else {
    var activityData = {
      "RelatedProspectId": ownerCrmId,
      "ActivityEvent": 214,
      "ActivityNote": "Profile View",
      "Fields": [
        {
          "SchemaName": "mx_Custom_1",
          "Value": userProfile.name
        },
        {
          "SchemaName": "mx_Custom_2",
          "Value": userProfile.mobile
        },
        {
          "SchemaName": "mx_Custom_3",
          "Value": userProfile.userId
        },
      ]
    }
  }


  const handleShowEmailClick = () => {
    if (userProfile === null || userProfile === undefined) {
      setOpenLogInModal(true)
    }else if(userProfile?.isVerified === false){
      setIsModalOpen(true)
    }
    else {
      setShowEmail(true)
      const newActivityFieldsData = [...activityData.Fields, {
        "SchemaName": "mx_Custom_5",
        "Value": "Email Address Viewed"
      }]
      activityData["Fields"] = newActivityFieldsData
      dispatch(addLeadActivity(activityData))
    }
  }
  const handleShowWebsiteClick = () => {
    if (userProfile === null || userProfile === undefined) {
      setOpenLogInModal(true)
    }else if(userProfile?.isVerified === false){
      setIsModalOpen(true)
    }
    else {
      setShowWebsite(true)
      const newActivityFieldsData = [...activityData.Fields, {
        "SchemaName": "mx_Custom_5",
        "Value": "Website Viewed"
      }]
      activityData["Fields"] = newActivityFieldsData
      dispatch(addLeadActivity(activityData))
    }
  }
  const handleShowNumberClick = () => {
    if (userProfile === null || userProfile === undefined) {
      setOpenLogInModal(true)
    }else if(userProfile?.isVerified === false){
      setIsModalOpen(true)
    }
    else {
      setShowNumber(true)
      const newActivityFieldsData = [...activityData.Fields, {
        "SchemaName": "mx_Custom_5",
        "Value": "Contact Number Viewed"
      }]
      activityData["Fields"] = newActivityFieldsData
      dispatch(addLeadActivity(activityData))
    }
  }


  const handleCloseLogInModal = () => {
    setOpenLogInModal(false)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container fullWidth="lg">
      <Card
        sx={{
          borderRadius: 5,
          p: 3,
          minHeight: isPublicProfile ? 230 : 300,
          maxHeight: isPublicProfile ? 230 : 300,
          overflow: "auto",
          // overflow: "scroll",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src="/assets/icons/world.png" height="24px" alt="world" />
              {shoWebsite === !false ?
                <Typography variant="businessCard" sx={{ ml: 2 }}>
                  {website ? (<a href={website.startsWith("http") ? website : `https://${website}`} target="_blank">{website}</a>) : (
                    "Not Disclosed")}</Typography> :
                <Typography variant="businessCard" sx={{ ml: 2 }}
                  onClick={handleShowWebsiteClick}
                > <span className="link_comman">Click here</span>&nbsp; to view website </Typography>
              }
            </Box>
          </Grid>

          <Grid item xs={12} md={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src="/assets/icons/mail_new.png" height="24px" alt="world" />

               {/* <Typography variant="businessCard" sx={{ ml: 2 , }}> Private Email</Typography>  */}
              <>{showEmail == !false ?  <Typography variant="businessCard" sx={{ ml: 2 }}>
                {email ?  isRealEstateDev || isEpcContractor ? "Private Email":email : "Not Disclosed"}
              </Typography> :
                <Typography variant="businessCard" sx={{ ml: 2 }}
                  onClick={handleShowEmailClick}
                ><span className="link_comman">Click here</span>&nbsp; to view email address </Typography>
              }</>


            </Box>
          </Grid>

          <Grid item xs={12} md={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src="/assets/icons/user_new.png" height="24px" alt="user" />
              {showNumber == !false ?
                <Typography variant="businessCard" sx={{ ml: 2 }}>
                  {contact ? isRealEstateDev || isEpcContractor ? "Private Contact":contact : "Not Disclosed"}
                </Typography> :
                <Typography variant="businessCard" sx={{ ml: 2 }}
                  onClick={handleShowNumberClick}
                > <span className="link_comman">Click here</span>&nbsp;  to view contact number </Typography>
              }


            </Box>
          </Grid>

          <Grid item xs={12} md={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src="/assets/icons/location.png" height="24px" alt="user" />
              <Typography variant="businessCard" sx={{ ml: 2 }}>
                {" "}
                {addressStr ? addressStr : "Not Disclosed"}
              </Typography>
              {permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed && (
                  <ListItem
                    disablePadding
                    sx={{ textAlign: "right" }}
                    onClick={handleClickOpen}
                  >
                    <ListItemIcon>
                      {addressStr ? (
                        <Edit sx={{ color: "primary.main" }} />
                      ) : (
                        <Edit
                          sx={{ color: "primary.main" }}
                          onClick={handleClickOpen}
                        />
                      )}
                    </ListItemIcon>
                  </ListItem>
                )}
            </Box>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Edit Address"}</DialogTitle>
            <DialogContent>
              <BusinessAddressForm
                supplierId={supplierId}
                address={address}
                handleClose={handleClose}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Card>

      
      <LogInMobilePopUp setOpenLogInModal={setOpenLogInModal} openLogInModal={openLogInModal} handleCloseLogInModal={handleCloseLogInModal} />

      <VerificationPopUp
          open={isModalOpen} // Pass the modal visibility state
          handleClose={handleCloseModal} // Pass the close modal function
        />
    </Container>
  );
}

ProfileContact.defaultProps = {
  isPublicProfile: true,
};

ProfileContact.propTypes = {
  website: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
  addresses: PropTypes.array.isRequired,
  supplierId: PropTypes.number,
  isPublicProfile: PropTypes.bool,
  permissions: PropTypes.object,
};

export default ProfileContact;