import React from 'react'
import EasyStepsForMd from './EasyStepsForMd';
import EasyStepsForSm from './EasyStepsForSm';
import EasyStepsForLg from './EasyStepsForLg';

const EasySteps = ({scrollToHome}) => {
  return (
    <div className='bg-[#EFEDEC] xs:h-[498px] md:h-auto py-[60px] md:py-20 lg:py-[80px]'>
      <p className='md:text-[40px] text-4xl font-bold  text-center '>3 Easy Steps To <br className='md:hidden' /> Hire Vendors</p>
      <p className='md:text-[18px] lg:text-xl xs:text-[18px] xs:px-8 pt-2 md:pt-4 lg:pt-[25px] md:pb-[56px] lg:pb-0 text-center'>Trust us, hiring verified vendors has never been this fast and hassle-free</p>

      {/* above 1280px */}
      <div className='hidden xl:block'>
        <EasyStepsForLg  />
      </div>

      {/* 768 px to 1280px */}
      <div className='hidden md:block xl:hidden'>
        <EasyStepsForMd />
      </div>

      {/* below 768px */}
      <div className='md:hidden'>
        <EasyStepsForSm />
      </div>

      <div className='flex justify-center'>
        <button  onClick={scrollToHome} className='text-lg lg:text-xl text-white w-[171px] h-[51px] md:w-[178px] md:h-[47px] lg:w-[237px] lg:h-[50px] md:mt-[70px] lg:mt-0 bg-gradient-to-tl hover:bg-gradient-to-l hover:from-[#20202B] hover:to-90% hover:to-[#45454E] from-[#0B0B1A] to-[#24242D] border-[#FF6B00] border-l-4'>Hire Now</button>
      </div>
    </div>
  )
}

export default EasySteps
