import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../../utils/axios";

import { useSelector } from "react-redux";
import OtpInput from "react-otp-input";

import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  Fab,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  verifyRegisterOTP,
  registerUserWithMobile,
  sendwhatsAppOTP,
} from "actions/authActions";
import { SIGN_UP_COUNTER } from "config";
import { useTheme } from "@emotion/react";

function SignUpOTPVerifyFormNew({
  isWhatsappNo,
  className,
  onSubmitSuccess,
  mobile,
  name,
  ...rest
}) {
  const dispatch = useDispatch();
  const [counter, setCounter] = React.useState(SIGN_UP_COUNTER);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const { sourceName } = location.state;
  console.log("location 3 ==", sourceName);

  const [secretCode, setSecretCode] = useState("");

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const handleChangeMobile = () => {
    navigate("/register");
  };

  const resendOTP = () => {
    // console.log("Sending Request for sending OTP", mobile);

    try {
      if (isWhatsappNo) {
        dispatch(sendwhatsAppOTP(mobile));
      } else {
        dispatch(registerUserWithMobile(name, mobile));
      }
      setCounter(SIGN_UP_COUNTER);
    } catch (error) {
      // console.log("resendOTP: error", error);
    }
    // dispatch()
  };
  // state = { otp: '' };

  const [otp1, setOTP1] = useState("");

  const otpHandleChange = (otp1) => {
    setOTP1(otp1);
  };
  const lastTwoDigits = mobile.slice(-2);

  // source code
  useEffect(() => {
    try {
      axios
        .get("/api/auth/register/codes")
        .then((response) => {
          setSecretCode(response?.data?.secretCode);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }

    // console.log("this is a secretCode1111111111 ==", secretCode);
  }, []);
  // console.log("this is a secretCode ==", secretCode);
  return (
    <Formik
      initialValues={
        {
          // otp1: "",
          // otp2: "",
          // otp3: "",
          // otp4: "",
        }
      }
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const otpA = [values.otp1, values.otp2, values.otp3, values.otp4];
          const otp = otpA.join("");
          const crmLeadId = ""; // we creat crmLeadId becuse require for flow
          const isVerified = sourceName == secretCode;
          // console.log("this is a secretCode 33333333 ==", secretCode);
          console.log("this is a isVerified 33333333 ==", isVerified);
          await dispatch(
            verifyRegisterOTP(
              mobile,
              name,
              otp1,
              crmLeadId,
              sourceName,
              isWhatsappNo,
              onSubmitSuccess,
              isVerified
            )
          );
          // onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={12}
            mt={-2}
          >
            <Grid style={{ marginBottom: "20px" }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  textAlign: "center",
                  color: "#D5D7DB",
                }}
              >
                Please enter the OTP received at
              </Typography>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 400, color: "#D5D7DB" }}
                >
                  +91 XXXXXXXX
                  <span style={{ fontSize: "20px" }}>{lastTwoDigits}</span>
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 400, color: "#03D097" }}
                  onClick={handleChangeMobile}
                  className="link_change_phone"
                >
                  Change Number
                </Typography>
              </Grid>
            </Grid>
            {isMd ? (
              <>
                <TextField
                  color="primary"
                  focused
                  fullWidth
                  required
                  autoFocus
                  sx={{ letterSpacing: "10px" }}
                  placeholder="OTP"
                  onChange={(e) => setOTP1(e.target.value)}
                  value={otp1}
                  inputProps={{
                    maxLength: 4,
                    style: {
                      color: "#fff", // Set the input text color to white
                    },
                  }}
                />
              </>
            ) : (
              <OtpInput
                className="otpInputStyleCssNew"
                value={otp1}
                onChange={otpHandleChange}
                numInputs={4}
                separator={<span></span>}
                shouldAutoFocus
                isInputNum
              />
            )}
            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid
              item
              sm={6}
              md={7}
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 2, p: 2 }}
            >
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                style={{
                  borderRadius: "5px",
                  height: "36px",
                  width: "153px",
                  backgroundColor: "#FD6600",
                  color:"white",
                  "&:hover": {
                    backgroundColor: "#FD6600",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                  }
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignUpOTPVerifyFormNew.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  mobile: PropTypes.string,
};

SignUpOTPVerifyFormNew.default = {
  onSubmitSuccess: () => {},
};

export default SignUpOTPVerifyFormNew;
