import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useIsMounted from "hooks/useIsMounted";
import axios from "utils/axios";
import { fetchBusinessById } from "actions/businessActions";

import DashboardLayout from "layouts/Dashboard";
import Page from "views/UserProfile/components/Page";
import { Helmet } from "react-helmet";
import EditBusinessForm from "./EditBusinessForm";
import SidePanel from "layouts/SidePanel";

function EditBusiness() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [gstin, setGstIn] = useState("");
  const [annualTurnOver, setAnnualTurnOver] = useState("")

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;

  useEffect(() => {
    setProfile(business?.profile?.businessProfile);
    setBusinessName(business?.profile?.businessName);
    setGstIn(business?.profile?.statutory?.gstin);
    setAnnualTurnOver(business?.profile?.annualTurnOver);
  }, [business?.profile?.businessProfile]);

  const handleSubmitSuccess = () => {
    navigate("/my-business");
  };

  return (
    <SidePanel>
    <DashboardLayout>
      <Helmet>
        <title>Neevay - Edit Your Business Details</title>
      </Helmet>
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Divider />
            <EditBusinessForm
              supplierId={supplierId}
              businessName={businessName}
              gstin={gstin}
              profile={profile}
              onSubmitSuccess={handleSubmitSuccess}
              annualTurnOver={annualTurnOver}
            />
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
    </SidePanel>
  );
}

export default EditBusiness;
