import * as React from "react";
import PropTypes from "prop-types";
import { dateFormatter } from "utils/formatUtils";
import ReactHtmlParser from "react-html-parser";

import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
// const { conversion } = require('html-to-text');
// conversion('<p>Your string of HTML code here</p>');

export default function LeadView({
  title,
  confirmAction,
  cancelAction,
  rfq,
  isOpen,
}) {
  let ackStatus = "Pending";

  if (rfq && rfq.suppliers && rfq.suppliers.length > 0) {
    ackStatus = "Acknowledged";
  }

  return (
    <Dialog open={isOpen} onClose={cancelAction}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText />
        Details of the requirement shared by the requesting party
        <Card
          sx={{
            minWidth: 400,
            maxWidth: 1000,
            color: "black",
            border: "solid",
            borderColor: "#F5F5F5",
            elevation: 10,
          }}
        >
          <CardContent sx={{ color: "#212B36" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography gutterBottom>
                  <b>Id</b> : {rfq.rfqId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography gutterBottom>
                  <b>Project Name</b>: {rfq.projectName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <b>Requirement Summary</b>: {rfq.summary}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography gutterBottom>
                  <b>Requirement Details</b>:
                </Typography>
                <Typography gutterBottom>{rfq.description?ReactHtmlParser(rfq.description):""}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>
                  <b>Work Start Date</b>: {dateFormatter(rfq.workStartDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>
                  <b>Work End Date</b>: {dateFormatter(rfq.workEndDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>
                  <b>Requirement Valid Till</b>:{" "}
                  {dateFormatter(rfq.validityDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>
                  <b>Credit Period</b>: {rfq.creditPeriod} days
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>
                  <b>Project Location</b>: {rfq.location}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>
                  <b>Required Quantity</b>: {rfq.capacity} {rfq.uom}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} md={12}>
                <Typography gutterBottom>
                  <b>Requestor Email</b> : {rfq.requestedBy}
                </Typography>
                <Typography gutterBottom>
                  <b>Requestor Contact</b>: {rfq.requesterContact}
                </Typography>
                <Typography gutterBottom>
                  Acknowledgement Status: <b>{ackStatus}</b>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={cancelAction}>
          Cancel
        </Button>
        <Button variant="contained" onClick={confirmAction}>
          Send Acknowledgement
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LeadView.propTypes = {
  title: PropTypes.string.isRequired,
  confirmAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  rfq: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
