import React, { useEffect, useRef, useState } from 'react';

const Hamburger = () => {
  const [hamburgerClicked, setHamburgerClicked] = useState(false);
  const dropdownRef = useRef(null);

  const handleMenu = () => {
    setHamburgerClicked(!hamburgerClicked);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setHamburgerClicked(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <button onClick={handleMenu}>
        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_hamburger.svg" alt="" className="ml-[22px]" />
      </button>
      {hamburgerClicked && (
        <div ref={dropdownRef} className="fixed z-70 top-0 right-0 h-screen pl-6 pt-16 space-y-8 w-3/4 bg-white">
          <button>For Construction Companies</button><br />
          <button>For Vendors</button><br />
          <button>Post Requirement</button><br />
          <button>Join as Vendor</button>
        </div>
      )}
    </div>
  );
};

export default Hamburger;
