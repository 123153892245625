import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Button,
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  ListItemIcon,
  ListItem,
  Tooltip,
  IconButton,
  Typography
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";

import SocialIconUrl from "./SocialIconUrl";
import { Link } from "react-router-dom";

function SocialIconDailog({
  supplierId,
  shoWSocialIcons,
  socialLinks,
  isOpen,
  isEditView,
  onSubmitSuccess,
}) {
  const [open, setOpen] = React.useState(isOpen);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmitSuccess = () => {
    setOpen(false);
  };

  // console.log("SocialIconDailog supplierId:", supplierId, socialLinks);

  useEffect(() => {
    // console.log("SocialIconDailog: ", isOpen, supplierId, open);
    setOpen(open);
  }, [open, isOpen]);

  // console.log("SocialIcon Dialog opened", isOpen, open);

  return (
    <>
    <ListItem disablePadding sx={{ textAlign: "right" }} onClick={handleOpen}>
    {/* <Typography variant="businessSubTitle" sx={{ color: 'black'}}>Social Links</Typography> */}
  <ListItemIcon>
    <Tooltip title="Edit Business Social Links" sx={{  }}>
      <Link style={{fontSize:"14px",color:"#2864BE", marginTop:isEditView&&"10px",}}> Edit</Link>
    </Tooltip>
  </ListItemIcon>
</ListItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Business Social Links"}
        </DialogTitle>
        <DialogContent>
          <SocialIconUrl
            supplierId={supplierId}
            socialLinks={socialLinks}
            handleCancel={handleClose}
            handleSubmitSuccess={handleSubmitSuccess}
            onSubmitSuccess={onSubmitSuccess}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

SocialIconDailog.propTypes = {
  supplierId: PropTypes.string,
  socialLinks: PropTypes.object,
  isOpen: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
};

export default SocialIconDailog;
