import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import PropTypes, { object } from "prop-types";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Alert,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Tabs,
  Tab,
  IconButton,
  Menu,
  TextField,
  InputAdornment,
  Tooltip,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridPagination, GridToolbar } from "@mui/x-data-grid";
import IosShareIcon from "@mui/icons-material/IosShare";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import axios from "utils/axios";

import { headCells } from "./utils";
import {
  fetchRequirement,
  fetchRequirements,
  deleteRequirement,
  closeRequirement,
  resendRequirements,
  fetchRepeatFixedRfq,
  searchRfqs,
} from "actions/requirementsAction";

import { useDispatch } from "react-redux";

import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

import ReactHtmlParser from "react-html-parser";
import ResendRequirementPopUp from "views/ResendRequirement/ResendRequirementPopUp";
import SuppliersDataView from "./SuppliersDataView";
import RepeatFixedRfqPopUp from "./RepeatFixedRfqPopUp";
import RepeatFixedRfqTableSection from "./RepeatFixedRfqTableSection";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SearchRfqs from "./SearchRfqs";
import AddExtraVendorSection from "./AddExtraVendorSection";
import { format, parseISO } from "date-fns";

// table design code start from here

const useStyles = makeStyles({
  dataGrid: {
    "& .MuiDataGrid-cell": {
      borderRight: "2px solid rgba(0, 0, 0, 0.1)", // Add vertical border to cells
    },
    "& .MuiDataGrid-colCellTitleContainer": {
      borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Add vertical border to header cells
    },
    "& .MuiDataGrid-footerContainer": {
      display: "none", //hideFooter pagination unused blank spacr
    },
  },
});

// table design code end

// ############renderCellExpand code start from here (renderCellExpand use foe hover effect to show full data)######################

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.string,
};

// ############renderCellExpand code end from here#####################

function RequirementsList({
  title,
  requirements,
  setNewRfqDataExcel,
  loading,
}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  const RFQ = useSelector((state) => state.requirement);
  const rfqResendmessage = RFQ?.resendMessage;
  const totalPages = RFQ?.totalPages;
  const userProfile = auth.user;
  const userId = userProfile.userId;
  const userEntity = userProfile?.userEntity;
  const entityId = userProfile?.userEntity?.entityId;
  const hasRepeatFeature = userEntity?.features?.some(
    (object) => object?.featureName === "REPEAT_ORDER" && object?.hasFeature
  );
  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(RFQ?.currentPage);
  const [teamMemberData, setTeamMemberData] = useState(userProfile?.userId);
  const [modal, setModal] = useState(false);
  const [modalClose, setModalClose] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = useState(30);
  const [tableTabValue, setTableTabValue] = useState("1");
  const [searchValue, setSearchValue] = useState("");

  const [newRequirements, setNewRequirements] = useState([]); // this state is use for sperate all supplier datad
  const [newRequirementsFixedData, setnewRequirementsFixedData] = useState([]); // this state is use for save the data and use for filter

  const navigate = useNavigate();

  useEffect(() => {
    // below code is creat a speratred obj for all supplier inside one req
    // let sperateSupplierData = requirements.flatMap(
    //   ({ srNo, suppliers, ...rest }) =>
    //     suppliers.map((supplier) => {
    //       const createdAt = rest.createdAt;
    //       // console.log("supplierDatte : ", supplier.createdAt, "\\\\\\\\createdAt : ", createdAt, "///////update date : ", rest.updatedAt);
    //       return {
    //         ...rest,
    //         ...supplier,
    //         status: supplier.status == "ACKNOWLEDGED" ? "Read" : "Delivered",
    //         createdAt: createdAt, // this code make for when the createdAt is not avilable in old rfq then use updateAt date
    //       };
    //     })
    // );
    let sperateSupplierData = requirements;
    // console.log("this is a userProfile data ====", userProfile);
    sperateSupplierData = sperateSupplierData.map((data, index) => {
      return { ...data, id: index + 1, supplierCount: data?.suppliers?.length };
    });
    // sperateSupplierData.sort((a, b) => b.rfqId - a.rfqId);
    setNewRequirements(sperateSupplierData);
    setnewRequirementsFixedData(sperateSupplierData);
  }, [requirements]);

  // console.log("RequirementsList: ", requirements);
  // console.log("newRequirements : ", newRequirements);

  const handleViewRequirement = (supplierId, rfqId) => {
    try {
      dispatch(fetchRequirement(supplierId, rfqId));
      navigate("/view-requirement");
    } catch (error) {
      // console.error();
    }
  };

  const deleteRfq = (supplierId, rfqId) => {
    window.location.reload();
    try {
      dispatch(deleteRequirement(supplierId, rfqId));
      // dispatch(fetchRequirements(userProfile?.mobile));
      //navigate("/requirements");
    } catch (error) {}
  };

  const closeRfq = (supplierId, rfqId) => {
    try {
      dispatch(closeRequirement(supplierId, rfqId, userProfile?.mobile));
      // dispatch(fetchRequirements(userProfile?.mobile));
      //navigate("/requirements");
    } catch (error) {}
  };

  const handleChangePageNumber = (event, value) => {
    // const filterButtonClick = last7DaysClick || last3oDaysClick;
    // if (filterButtonClick) {
    //   const filterDayValue = last7DaysClick ? 7 : 30;
    //   dispatch(fetchRequirements(teamMemberData, value, filterDayValue));
    // } else {
    //   dispatch(fetchRequirements(teamMemberData, value));
    // }
    dispatch(searchRfqs(teamMemberData, searchValue, value));
    setPageNumber(value);
  };

  const handleChangeTableView = (e, value) => {
    setTableTabValue(value);
  };
  ///////////////////////////////////////////// FILTER AND SORT LOGIC HERE START

  const [sortOption, setSortOption] = useState("name");
  const [filterType, setFilterType] = useState("");
  const [last7DaysClick, setLast7DaysClick] = useState(false);
  const [last3oDaysClick, setLast3oDaysClick] = useState(false);
  const [allDaysClick, setAllDaysClick] = useState(true);
  const [resendRfqPopUp, setResendRfqPopUp] = useState(false);
  const [resendRfqData, setresendRfqData] = useState();
  const [repeatFixedPopUp, setRepeatFixedPopUp] = useState(false);
  const [resendFixedView, setResendFixedView] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [customDays, setCustomDays] = useState();
  const [noDataFound, setNoDataFound] = useState(false);
  const [error, setError] = useState("");
  // const menuRef = useRef(null);
  const dropdown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setResendRfqPopUp(false);
  };

  // console.log("!userProfile.teamMembers",userProfile.teamMembers<);

  const handleFilterOptionChange = (event) => {
    // console.log("this is event", event.target.value);
    setSortOption(event.target.value);
    let sortedArray = [...newRequirements];
    switch (event.target.value) {
      case "null": {
        sortedArray.sort((a, b) => a.id - b.id);
        break;
      }
      case "name": {
        sortedArray.sort((a, b) => {
          if (a.businessName && b.businessName) {
            return a.businessName.localeCompare(b.businessName);
          }
          return 0;
        });
        break;
      }
      case "oldest": {
        sortedArray.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        break;
      }
      case "newest": {
        sortedArray.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        break;
      }
      case "viewed": {
        sortedArray.sort((a, b) => {
          if (a.status && b.status) {
            return b.status.localeCompare(a.status);
          }
          return 0;
        });
        break;
        break;
      }
      case "notView": {
        sortedArray.sort((a, b) => {
          if (a.status && b.status) {
            return a.status.localeCompare(b.status);
          }
          return 0;
        });
        break;
        break;
      }
    }
    setNewRequirements((preVal) => sortedArray);
  };

  // const handleFilterTypeChange = (event) => {
  //   handleFilterDataAllDays();

  //   setFilterType(event.target.value);
  // };

  const handleSearchQueryChange = (event) => {
    const searchKey = event.target.value;

    const filteredArray = newRequirementsFixedData.filter((obj) => {
      if (filterType === "name") {
        const businessName = obj?.businessName || ""; // Default value: empty string
        return businessName.toLowerCase().includes(searchKey.toLowerCase());
      } else if (filterType === "location") {
        const location = obj?.location || ""; // Default value: empty string
        return location.toLowerCase().includes(searchKey.toLowerCase());
      }
      return true;
    });
    setNewRequirements(filteredArray);
  };

  // const handleFilterDataLastSevenDays = () => {
  //   dispatch(fetchRequirements(teamMemberData, 1, 7));
  //   setPageNumber(1);
  //   setLast7DaysClick(true);
  //   setLast3oDaysClick(false);
  //   setAllDaysClick(false);
  // };
  // const handleFilterDataLastMonths = () => {
  // setFilterType("");
  // const today = new Date();
  // const sevenDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  // const filteredArray = newRequirementsFixedData.filter((obj) => {
  //   const objDate = new Date(obj.createdAt);
  //   return objDate >= sevenDaysAgo;
  // });
  // setNewRequirements(filteredArray);
  //   dispatch(fetchRequirements(teamMemberData, 1, 30));
  //   setPageNumber(1);
  //   setLast7DaysClick(false);
  //   setLast3oDaysClick(true);
  //   setAllDaysClick(false);
  // };
  // const handleFilterDataAllDays = () => {
  //   dispatch(fetchRequirements(teamMemberData, 1));
  //   setPageNumber(1);
  //   setLast7DaysClick(false);
  //   setLast3oDaysClick(false);
  //   setAllDaysClick(true);
  // };

  const handleRepeatFixedRfq = () => {
    setRepeatFixedPopUp(true);
  };
  ///////////////////////////////////////////// FILTER AND SORT LOGIC END

  // send enq code start froam here
  const [selectionModel, setSelectionModel] = useState([]);

  const handleSelectionModelChange = (newSelection) => {
    // console.log("this is data of selection", newSelection)
    // setSelectedRow(newSelection.length) // get the value of how many row or check box selected
    // setSelectedRowDataId(newSelection) // newSelection give the suplierId slected company
    setSelectionModel(newSelection);
    setNewRfqDataExcel(
      newRequirementsFixedData.filter((object) =>
        newSelection.includes(object.id)
      )
    );
  };
  // send enq code end  here

  // description and suplliers dialog box code here satrt
  const [openDescription, setOpenDescription] = useState(false);
  const [businessEnquiresDescription, setBusinessEnquiresDescription] =
    useState("");
  const [openSupllierdata, setOpenSupllierdata] = useState(false);
  const [openRfqId, setopenRfqId] = useState([]);
  const handleCloseDescription = () => {
    setOpenDescription(false);
  };
  const handleOpenDescription = (description) => {
    setOpenDescription(true);
    const parserDescription = ReactHtmlParser(description);
    setBusinessEnquiresDescription(parserDescription);
  };
  const handleCloseSupllierData = () => {
    setOpenSupllierdata(false);
  };
  const handleOpenSuppllierData = (suppliersArray) => {
    setOpenSupllierdata(true);
    const viewRfqId = suppliersArray?.rfqId;
    //we send rfqid so they can get letest data
    setopenRfqId(viewRfqId);
  };
  // description and suplliers dialog box code here end

  const handleChangeTeamMember = (event) => {
    const selectedUserId = event.target.value;
    dispatch(fetchRequirements(selectedUserId, 1));
    dispatch(fetchRepeatFixedRfq(selectedUserId, 1));
    setPageNumber(1);
    setTeamMemberData(event.target.value);
    setSearchValue("");
    setLast7DaysClick(false);
    setLast3oDaysClick(false);
    setAllDaysClick(true);
  };

  const handleResendRequirement = async (rfqData) => {
    try {
      setResendRfqPopUp(true);
      setresendRfqData(rfqData);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleCustomDaysChange = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCustomDays(numericValue);
    setError("");
  };

  const formattedData = [];
  newRequirements.forEach((rfq) => {
    const parserDescription = ReactHtmlParser(rfq.description);
    rfq.suppliers.forEach((supplier) => {
      if (
        rfq.suppliers.every(
          (supplier) => supplier !== null && supplier !== undefined
        )
      ) {
        const row = {
          id: formattedData.length + 1, // or use a unique identifier
          rfqId: rfq.rfqId,
          description: rfq.description,
          createdAt: rfq.createdAt,
          emails: supplier?.emails,
          supplierId: supplier?.supplierId,
          businessName: supplier?.businessName,
          status: supplier?.status,
          mobile: supplier?.mobile,
          isPremium: supplier?.isPremium,
        };
        formattedData.push(row);
      }
    });
  });

  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        setOpen(false);
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [open]);
  useEffect(() => {
    dispatch(fetchRequirements(teamMemberData, 1));
    dispatch(fetchRepeatFixedRfq(teamMemberData, 1));
  }, []);

  const handleDownload = async (selectedValue) => {
    try {
      // console.log("inside the function",customDays );
      if (!customDays && selectedValue === "custom") {
        setError("**Please select an option from the dropdown menu");
        return; // Exit function if customDays is empty
      }
      // Clear any previous error message
      setError("");
      let range;

      if (selectedValue === "custom") {
        range = customDays && !isNaN(customDays) ? customDays : undefined;
      } else {
        range = selectedValue;
      }
      const response = await axios.get(
        `/api/rfqs/send/excel?userId=${userId}&range=${range}`,
        {
          responseType: "blob", // Important for binary data
        }
      );
      // Create a blob and download the file
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      const currentDate = new Date().toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata", // Indian Standard Time (IST)
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      // Get current time and format it as "hhmmss"
      const currentTime = new Date().toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata", // Indian Standard Time (IST)
        hour12: false, // Use 24-hour format
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      link.href = url;
      link.setAttribute(
        "download",
        `requirements_${userProfile.name}_${currentDate}_${currentTime}.xlsx`
      ); // Append date and time to file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setAnchorEl(null);
    } catch (error) {
      setNoDataFound(true);
      console.error("Error downloading RFQs:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setNoDataFound(false);
  };

  return (
    <Card mt={2} style={{ height: "100%" }}>
      {/* <CardHeader title={title} />
      <Divider sx={{ mt: 1 }} /> */}
      <Grid
        xs={12}
        sx={{
          overflowY: "scroll",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          display="flex"
          mb="12px"
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid display="flex" gap="16px" mt="10px" flexWrap={"wrap"}>
            <Grid>
              {userProfile?.teamMembers?.length > 0 && (
                <FormControl size="small" sx={{ marginX: 1, minWidth: 120 }}>
                  {/* <InputLabel id="demo-simple-select-helper-label">Age</InputLabel> */}
                  <Select
                    id="demo-simple-select-helper"
                    value={teamMemberData}
                    onChange={handleChangeTeamMember}
                  >
                    <MenuItem value={userProfile?.userId}>My RFQs</MenuItem>
                    {userProfile?.teamMembers?.map((member) => (
                      <MenuItem value={member.userId}>{member?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <SearchRfqs
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedUserId={teamMemberData}
              setPageNumber={setPageNumber}
            />
            {/* <Grid
              height="25px"
              display="flex"
              gap="8px"
              border="1px solid rgba(217, 217, 217, 1)"
              sx={{ backgroundColor: "rgba(245, 245, 246, 1)", paddingLeft: "6px" }}
              borderRadius="5px">
              <Typography >Sort By</Typography>
              <FormControl >
                <Select style={{ height: '25px', border: "none" }} value={sortOption} onChange={handleFilterOptionChange}>
                  <MenuItem value="null">Null</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="oldest">Oldest</MenuItem>
                  <MenuItem value="newest">Newest</MenuItem>
                  <MenuItem value="viewed">Viewed</MenuItem>
                  <MenuItem value="notView">Not view</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid
              display="flex"
              flexWrap="wrap"
              gap="8px"
              maxWidth="160px"
              border="1px solid rgba(217, 217, 217, 1)"
              sx={{ backgroundColor: "rgba(245, 245, 246, 1)", paddingLeft: "6px" }}
              borderRadius="5px"
            >
              <Typography>Filters</Typography>
              <FormControl >
                <Select style={{ height: '25px', widows: "auto" }} value={filterType} onChange={handleFilterTypeChange}>
                  <MenuItem value="">Null</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="location">Location</MenuItem>
                </Select>
              </FormControl>
              {filterType && (
                <Grid sx={{ backgroundColor: "white", }}>
                  <TextField
                    InputProps={{ style: { height: '25px', } }}
                    placeholder={`Search ${filterType}`}
                    onChange={handleSearchQueryChange}
                  />
                </Grid>
              )}
            </Grid> */}
          </Grid>
          {!userProfile?.teamMembers?.length > 0 &&
            !hasRepeatFeature &&
            formattedData.length > 0 && (
              <Grid
                marginLeft={"20px"}
                marginRight={"30px"}
                marginTop={!isSm && "20px"}
              >
                {/* {<Grid marginX={"20px"} marginTop={!isSm && "20px"}> */}
                <Button
                  variant="contained"
                  // color="primary"
                  id="basic-button"
                  aria-controls={dropdown ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={dropdown ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    padding: "4px",
                    background: "#808080",
                    color: "white",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                    "&:hover": {
                      backgroundColor: "#808080",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                      color: "white",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                    },
                  }}
                >
                  <Typography> Download</Typography>

                  <IosShareIcon
                    fontSize="small"
                    sx={{
                      color: "rgba(255, 255, 255, 1)",
                      // width: "1.4em"
                    }}
                  />
                </Button>
                <Menu
                  id="download-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={dropdown}
                  onClose={handleCloseDropdown}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <label style={{ fontSize: "14px", paddingLeft: "15px" }}>
                    Custom Days
                  </label>
                  <MenuItem>
                    <TextField
                      size="small"
                      placeholder={"Select"}
                      onClose={handleClose}
                      value={customDays}
                      onChange={handleCustomDaysChange}
                      error={!!error} // Set error state of TextField
                      helperText={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Download here">
                              <IconButton
                                sx={{
                                  backgroundColor: "#47B49D",
                                  borderRadius: "3px",
                                  padding: "2px", // Adjust the padding value as needed
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() => handleDownload("custom")}
                              >
                                <IosShareIcon
                                  sx={{
                                    borderRadius: "5px",
                                    backgroundColor: "#808080",
                                    color: "white",
                                    padding: "2px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => handleDownload(0)}>Today</MenuItem>
                  <MenuItem onClick={() => handleDownload(7)}>
                    Last 7 days
                  </MenuItem>
                  <MenuItem onClick={() => handleDownload(30)}>
                    Last 30 days
                  </MenuItem>
                </Menu>
                <Snackbar
                  open={noDataFound}
                  severity="warning"
                  autoHideDuration={4000}
                  onClose={handleCloseSnackbar}
                  message="There are no requirements found."
                  direction="left"
                />
              </Grid>
            )}
          {
            hasRepeatFeature && (
              <Grid display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                {/* <Button
                variant="contained"
                sx={{
                  // padding: "2px",

                  borderRadius: "5px",
                  cursor: "pointer",
                  background: "#fff",
                  height: "40px",
                  border: "1px solid #ABB3C0",
                  boxShadow: "0px 0.5px 0px #ABB3C0",
                  color: "black",
                  marginRight: (tableTabValue === "2" || userProfile?.teamMembers?.length > 0 ||newRequirements.length == 0) && "30px",
                  "&:hover": {
                    backgroundColor: "#D9D9D9",
                  },
                }}
                onClick={handleRepeatFixedRfq}
              >
                Create Repeat Order
              </Button> */}

                {!userProfile?.teamMembers?.length > 0 &&
                  tableTabValue === "1" &&
                  newRequirements.length > 0 && (
                    <Grid marginLeft={"20px"} marginRight={"30px"}>
                      {/* {<Grid marginX={"20px"} marginTop={!isSm && "20px"}> */}
                      <Button
                        variant="contained"
                        // color="primary"
                        id="basic-button"
                        aria-controls={dropdown ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={dropdown ? "true" : undefined}
                        onClick={handleClick}
                        sx={{
                          padding: "4px",
                          background: "#808080",
                          color: "white",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                          "&:hover": {
                            backgroundColor: "#808080",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                            color: "white",
                            transition:
                              "background-color 0.3s, box-shadow 0.3s",
                          },
                        }}
                      >
                        <Typography> Download</Typography>

                        <IosShareIcon
                          fontSize="small"
                          sx={{
                            color: "rgba(255, 255, 255, 1)",
                            // width: "1.4em"
                          }}
                        />
                      </Button>
                      <Menu
                        id="download-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={dropdown}
                        onClose={handleCloseDropdown}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <label
                          style={{ fontSize: "14px", paddingLeft: "15px" }}
                        >
                          Custom Days
                        </label>
                        <MenuItem>
                          <TextField
                            size="small"
                            placeholder={"Select"}
                            onClose={handleClose}
                            value={customDays}
                            onChange={handleCustomDaysChange}
                            error={!!error} // Set error state of TextField
                            helperText={error}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="Download here">
                                    <IconButton
                                      sx={{
                                        backgroundColor: "black",
                                        borderRadius: "3px",
                                        padding: "2px", // Adjust the padding value as needed
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      onClick={() => handleDownload("custom")}
                                    >
                                      <IosShareIcon
                                        sx={{
                                          borderRadius: "5px",
                                          backgroundColor: "#808080",
                                          color: "white",
                                          padding: "2px",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MenuItem>
                        <MenuItem onClick={() => handleDownload(0)}>
                          Today
                        </MenuItem>
                        <MenuItem onClick={() => handleDownload(7)}>
                          Last 7 days
                        </MenuItem>
                        <MenuItem onClick={() => handleDownload(30)}>
                          Last 30 days
                        </MenuItem>
                      </Menu>
                      <Snackbar
                        open={noDataFound}
                        severity="warning"
                        autoHideDuration={4000}
                        onClose={handleCloseSnackbar}
                        message="There are no requirements found."
                        direction="left"
                      />
                    </Grid>
                  )}

                {/* <>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      padding: "2px 6px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: last7DaysClick ? "#D9D9D9" : "#fff",
                      border: "1px solid #ABB3C0",
                      boxShadow: "0px 0.5px 0px #ABB3C0",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "#D9D9D9",
                      },
                    }}
                    onClick={handleFilterDataLastSevenDays}
                  >
                    Last 7 Days
                  </Button>

                  <Button
                    // color="secondary"
                    variant="contained"
                    sx={{
                      padding: "2px 6px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: last3oDaysClick ? "#D9D9D9" : "#fff",
                      border: "1px solid #ABB3C0",
                      boxShadow: "0px 0.5px 0px #ABB3C0",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "#D9D9D9",
                      },
                    }}
                    onClick={handleFilterDataLastMonths}
                  >
                    Last 30 Days
                  </Button>

                  <Button
                    sx={{
                      padding: "2px 6px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor:
                        last3oDaysClick || last7DaysClick ? "#fff" : "#D9D9D9",
                      border: "1px solid #ABB3C0",
                      boxShadow: "0px 0.5px 0px #ABB3C0",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "#D9D9D9",
                      },
                    }}
                    onClick={handleFilterDataAllDays}
                  >
                    All Requirements
                  </Button>
                </> */}
              </Grid>
            )
            // :
            // <Grid>
            //   {/* <FormControl >
            //     <Select style={{ height: '25px', widows: "auto" }} value={filterType} onChange={handleFilterTypeChange}>
            //       <MenuItem value="">Null</MenuItem>
            //       <MenuItem value="name">Name</MenuItem>
            //       <MenuItem value="location">Location</MenuItem>
            //     </Select>
            //   </FormControl> */}
            // </Grid>
          }
        </Grid>
        {open && (
          <Alert
            severity="success"
            sx={{ width: "50%", marginBottom: "10px", zIndex: "2" }}
          >
            {rfqResendmessage}
          </Alert>
        )}

        {userEntity ? (
          <TabContext value={tableTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeTableView}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Regular Rfqs"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                  value="1"
                />
                {hasRepeatFeature && (
                  <Tab
                    label="Repeat Orders"
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                    value="2"
                  />
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid xs>
                {loading ? (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "100px",
                    }}
                  >
                    <CircularProgress sx={{ color: "#FD6600" }} />
                  </Grid>
                ) : newRequirements.length === 0 ? (
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      paddingTop: "100px",
                    }}
                  >
                    {last7DaysClick ? (
                      <Typography>
                        You have'nt received any requirement from last 7 days
                      </Typography>
                    ) : last3oDaysClick ? (
                      <Typography>
                        You have'nt received any requirement from last 30 days
                      </Typography>
                    ) : (
                      <Typography>You haven't sent any requirement</Typography>
                    )}
                  </Grid>
                ) : (
                  <>
                    <Box sx={{ height: "400px" }}>
                      <DataGrid
                        // sx={{ maxWidth: "1145px", margin: "auto" }} // add width as per we need col
                        // loading={newRequirements.length === 0 ? false : "asdfghj"}
                        // checkboxSelection
                        selectionModel={selectionModel}
                        onSelectionModelChange={handleSelectionModelChange}
                        className={classes.dataGrid}
                        rows={newRequirements}
                        columns={headCells(
                          userEntity,
                          true,
                          deleteRfq,
                          closeRfq,
                          modal,
                          setModal,
                          modalClose,
                          setModalClose,
                          handleViewRequirement,
                          supplierId,
                          renderCellExpand,
                          handleOpenDescription,
                          handleResendRequirement,
                          rfqResendmessage,
                          handleOpenSuppllierData
                        )}
                        // pageSize={pageSize}
                        // onPageSizeChange={(size) => setPageSize(size)}
                        // rowsPerPageOptions={[30, 50, 100]}
                        rowCount={14}
                        pageSizeOptions={[10]}
                        rowHeight={32}
                        disableSelectionOnClick
                        disableColumnMenu
                        components={{
                          Pagination: () => null, // Completely hide pagination component
                        }}
                      />
                      <Pagination
                        sx={{ float: "right", m: "12px" }}
                        size="small"
                        page={pageNumber}
                        count={totalPages}
                        onChange={handleChangePageNumber}
                      />
                    </Box>
                  </>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              {" "}
              <RepeatFixedRfqTableSection
                searchValue={searchValue}
                userId={teamMemberData}
              />
            </TabPanel>
          </TabContext>
        ) : (
          <>
            {/* // data grid for nonentity user */}
            <Box sx={{ height: "350px" }}>
              <DataGrid
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                className={classes.dataGrid}
                rows={formattedData}
                columns={headCells(
                  userEntity,
                  true,
                  deleteRfq,
                  closeRfq,
                  modal,
                  setModal,
                  modalClose,
                  setModalClose,
                  handleViewRequirement,
                  supplierId,
                  renderCellExpand,
                  handleOpenDescription,
                  handleResendRequirement,
                  rfqResendmessage,
                  handleOpenSuppllierData
                )}
                // pageSize={pageSize}
                // onPageSizeChange={(size) => setPageSize(size)}
                // rowsPerPageOptions={[30, 50, 100]}
                rowCount={14}
                pageSizeOptions={[10]}
                rowHeight={32}
                disableSelectionOnClick
                disableColumnMenu
                components={{
                  Pagination: () => null, // Completely hide pagination component
                }}
              />
              <Pagination
                sx={{ float: "right", m: "12px", paddingBottom: "50px" }}
                size="small"
                page={pageNumber}
                count={totalPages}
                onChange={handleChangePageNumber}
              />
            </Box>
          </>
        )}
      </Grid>
      <RepeatFixedRfqPopUp
        repeatFixedPopUp={repeatFixedPopUp}
        setRepeatFixedPopUp={setRepeatFixedPopUp}
        userId={teamMemberData}
      />
      <Dialog
        open={openDescription}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDescription}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Requirement Description"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {businessEnquiresDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDescription}>Close</Button>
        </DialogActions>
      </Dialog>

      {resendRfqPopUp && (
        <ResendRequirementPopUp
          selectedUserId={teamMemberData}
          enquiry={resendRfqData}
          resendRfqPopUp={resendRfqPopUp}
          handleClose={handleClose}
          setOpen={setOpen}
        />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openSupllierdata}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseSupllierData}
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseSupllierData}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{"Vendors Details"}</DialogTitle>
        <DialogContent>
          {openSupllierdata && (
            <>
              {!entityId && (
                <AddExtraVendorSection
                  userId={teamMemberData}
                  rfqId={openRfqId}
                  currentPage={pageNumber}
                  newRequirements={newRequirements}
                />
              )}
              <SuppliersDataView
                newRequirements={newRequirements}
                openRfqId={openRfqId}
                handleResendRequirement={handleResendRequirement}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSupllierData}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

RequirementsList.propTypes = {
  title: PropTypes.string,
  requirements: PropTypes.array,
};

export default RequirementsList;
