import React from 'react';
import sectorData from '../sectordata.json';

const Sectors = () => {
  // Split sectorData into two arrays for two rows
  const firstRow = sectorData.slice(0, 4);
  const secondRow = sectorData.slice(4);

  return (
    <div className="container mx-auto my-[65px]">
      <div className="grid grid-cols-4 gap-4">
        {/* First row */}
        {firstRow.map((sector) => (
          <div key={sector.id} className="flex flex-col items-center">
            <img
              src={sector.src}
              alt={sector.alt}
              className="lg:h-[87px] lg:w-[180px] object-cover rounded-t-lg"
            />
            <p className="text-white pt-[29px] w-[180px] text-xl text-left">{sector.title}</p>
          </div>
        ))}
      </div>

      {/* Second row */}
      <div className="grid grid-cols-4 gap-4 mt-[53px]">
        {secondRow.map((sector) => (
          <div key={sector.id} className="flex flex-col items-center">
            <img
              src={sector.src}
              alt={sector.alt}
              className="lg:h-[87px] lg:w-[180px] object-cover rounded-t-lg"
            />
            <p className="text-white pt-[29px] w-[180px] text-xl text-left">{sector.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sectors;
