import axios from "utils/axios";

import { PROFILE_IMAGE_UPLOAD_URL } from "config/services";
class ProfileImageService {
  profileImageUpload = (file, type, name, supplierId) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("profileImage", file);
      formData.append("type", type);
      formData.append("supplierId", supplierId);
      axios
        .post(PROFILE_IMAGE_UPLOAD_URL, formData)
        .then((response) => {
          // console.log("ProfileImageService:profile: Response", response.data);

          if (response.data.error) {
            // console.log(
            //   "ProfileImageService:profile",
            //   response.error,
            //   response.data
            // );
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.payload);
          }

          reject(error);
        });
    });
}

const profileImageService = new ProfileImageService();

export default profileImageService;
