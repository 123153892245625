import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Dialog,
  Button,
  CardMedia,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Card
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { ImageEditor } from "components";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
import { addAwards, listCertificate } from "actions/awardsAction";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AddAwardsForm = ({
  title,
  isPublicProfile,
  permissions,
  certificateUrl,
  open,
  handleClose,
  onSubmitSuccess,
  ...rest
}) => {
  const dispatch = useDispatch();
  const business = useSelector((state) => state.business);
  const profile = business?.profile;
  const supplierId = profile?.supplierId;
  const imageToUpload = business.imageToUpload;
  onSubmitSuccess=()=>{
    handleClose()
      }

  return (

    <Dialog open={open} onClose={handleClose} >
      <DialogTitle>Upload Awards</DialogTitle>
      <Divider/>
      <Formik
        initialValues={{ title: "" }}
        validationSchema={Yup.object({
          title: Yup.string()
            .max(100, "Title must be 100 characters or less")
            .required("Title is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // console.log(
            //   "Submitting the request for certificate",
            //   values,
            //   imageToUpload
            // );

            await dispatch(
              addAwards(supplierId, values.title, imageToUpload?.file)
            );
            handleClose();
            onSubmitSuccess();
          } catch (error) {
            // console.log("Form: error", error);

            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} {...rest}>
            <DialogContent>
            <Card
          sx={{
            p:3,
            minWidth: 200,
            maxWidth: 1000,
            color: "black",
            border: "solid",
            borderColor: "#F5F5F5",
            elevation: 1,

          }}
        >
              <TextField
                name="title"
                label="Title"
                fullWidth
                value={values.title}
                onChange={handleChange}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <Grid item xs={12} sm={12} md={12} sx={{textAlign:'left'}}>
                <Box
                  sx={{
                    pt: 2,

                  }}
                >
                  {isPublicProfile ? (
                    <CardMedia

                      component="img"
                      image={
                        certificateUrl
                          ? `${BASE_URL}/${certificateUrl}`
                          : DEFAULT_IMAGES.AWARDS
                      }
                      alt="Image not found"
                    />
                  ) : (
                   <Grid item md={12} >
                   <Box >
                    <ImageEditor
                      type={IMAGE_TYPE.AWARDS}
                      supplierId={supplierId}
                      name={title}
                      inImage={
                        certificateUrl
                          ? `${BASE_URL}/${certificateUrl}`
                          : DEFAULT_IMAGES.AWARDS
                      }
                    />
                    </Box>
                    </Grid>
                  )}
                </Box>
              </Grid>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" 
               size="small"
               sx={{backgroundColor: "#757575",
                    color:"white",
                    "&:hover": {
                      backgroundColor: "#757575",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                    }
                    }}
               >Cancel</Button>
              <Button onClick={handleSubmit} variant="contained" 
                size="small"
                sx={{backgroundColor: "black",
                    color:"white",
                    "&:hover": {
                      backgroundColor: "black",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                    }
                    }}
                >Submit</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>

  );
};
AddAwardsForm.propTypes = {
  Open: PropTypes.bool,
  handleClose: PropTypes.func,
  certificateUrl: PropTypes.string,
  permissions: PropTypes.object,
  isPublicProfile: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
};

AddAwardsForm.default = {
  onSubmitSuccess: () => {},
};
export default AddAwardsForm;
