import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
// import "./Pdf.css";
// import axios from 'axios'
import PropTypes from "prop-types";
import { Button } from "@mui/material";

import { useSelector } from "react-redux";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PdfDocument from "../GenerateInvoice/Invoice";

function OrderPdf({ className, ...rest }) {
  const invoiceDetails = useSelector(
    (state) => state.invoiceDetails?.invoiceToPrint
  );
  const fileName = "Invoice.pdf";

  return (
    <div className="App">
      {/* <div className="download-link">
        <Button variant="outlined" color="secondary" href="/user-orders" sx={{m:2}}>
          Back To Orders
        </Button>
        <PDFDownloadLink
          document={<PdfDocument invoicedata={invoiceDetails} />}
          fileName={fileName}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading..." : "Download Invoice"
          }
        </PDFDownloadLink>
      </div> */}
      <br />
      <PDFViewer width={800} height={600} showToolbar={false}>
        <PdfDocument invoicedata={invoiceDetails} />
      </PDFViewer>
    </div>
  );
}
OrderPdf.propTypes = {
  invoiceDetails: PropTypes.array,
};

export default OrderPdf;
