import React from "react";

const Slider = ({
  startCount,
  endCount,
  difference,
  value,
  onChange,
  className,
}) => {
  return (
    <div className={"flex items-center gap-4 " + className}>
      <input
        type="range"
        min={startCount}
        max={endCount}
        step={difference}
        value={value}
        onChange={onChange}
        className="slider w-full h-1 bg-gray-200 outline-none opacity-50 transition duration-200 hover:opacity-100 focus:opacity-100 cursor-pointer"
      />
      <span>{value}</span>
    </div>
  );
};

export default Slider;
