import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import NavItem from "./components/NavItem";

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    landings: landingPages,
    profile: profilePages,
    jobs: jobPages,
    blog: blogPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="WC"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={"img"}
            src={mode === "light" ? "/logos/logo.png" : "/logos/logo.png"}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={"Landings"} items={landingPages} />
        </Box>
        <Box>
          <NavItem title={"Profile"} items={profilePages} />
        </Box>
        <Box>
          <NavItem title={"Jobs"} items={jobPages} />
        </Box>
        <Box>
          <NavItem title={"Blog"} items={blogPages} />
        </Box>
        <Box marginLeft={2}>
          <Button
            fullWidth
            size="large"
            type="submit"
            component="a"
            variant="contained"
            target="blank"
            href="/signup"
            sx={{
              background: "#F1C12B",
              // height: 48,
              color: "#000000",
              borderRadius: 2,
              "&:hover": {
                background: "#CDA425",
                color: "white",
              },
            }}
          >
            Sign Up
          </Button>
        </Box>
        <Box marginLeft={2}>
          <Button
            fullWidth
            size="large"
            type="submit"
            component="a"
            variant="contained"
            target="blank"
            href="/signin"
            sx={{
              background: "#F1C12B",
              // height: 48,
              color: "#000000",
              borderRadius: 2,
              "&:hover": {
                background: "#CDA425",
                color: "white",
              },
            }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
