import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  CardMedia,
  Link,
  Modal,
  Icon,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Chip,
  Paper,
  useMediaQuery,
} from "@mui/material";
import axios from "utils/axios";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

import ServiceCard from "./ServiceCard";

import { addServiceTrigger } from "actions/serviceActions";

import { SEARCH_DEFAULT_IMAGE } from "config";
import ServiceCardPublic from "./ServiceCardPublic";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTheme } from "@mui/material/styles";
import BusinessRegionsMobile from "./BusinessRegionsMobile";
import {
  GET_ALL_CITIES,
  UPDATE_REGIONS_IN_SUPPLIER_URL,
  REMOVE_REGIONS_IN_SUPPLIER_URL,
  GET_ALL_DISTRICTS,
} from "config/services";
const BASE_URL = process.env.REACT_APP_BASE_URL;
function BusinessRegions({
  supplierId,
  services,
  isPublicProfile,
  permissions,
  businessRegions,
  ...rest
}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const [regionModel, setRegionModel] = useState(false);
  const preSelectedStates = businessRegions?.state ?? [];
  const preSelectedCitys = businessRegions?.Selectedcities ?? [];
  const preSelectedAllCitys = businessRegions?.Allcities ?? [];
  const preSelectedCountry = businessRegions?.country ?? "";
  // const [preSelectedCountry, setPreSelectedCountry] = useState(preSelectedCountries);
  const regiosAllData =
    preSelectedStates?.length == 36
      ? ["All over india "]
      : preSelectedStates?.length > 0
      ? [
          ...preSelectedStates.map((state) => `All over ${state}`),
          ...preSelectedCitys,
        ]
      : preSelectedAllCitys?.length && [...preSelectedAllCitys];

  const [districts, setDistricts] = useState([]);
  const [apiDistricts, setApiDistricts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(GET_ALL_DISTRICTS);
        setApiDistricts(response?.data?.payload);
        const allDistricts = response?.data?.payload.map((data) => [
          ...data.districts,
        ]);
        setDistricts(allDistricts.flat());
      } catch (error) {
        // console.error("Error fetching districts:", error);
      }
    };

    fetchDistricts();
  }, []);

  const handleAddRegions = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isOwner) {
        setRegionModel(true);
      } else {
      }
    } else {
      setRegionModel(true);
    }
    setCheckedStates((prevCheckedStates) =>
      prevCheckedStates.map((state) => {
        if (preSelectedStates.includes(state.stateName)) {
          return {
            ...state,
            value: true,
            stateCode: state.stateCode,
          };
        }
        return state;
      })
    );

    setDistricts(
      apiDistricts
        .filter((data) => !preSelectedStates.includes(data.state))
        .map((obj) => obj.districts)
        .flat()
    );
  };

  const RegionModalstyle = {
    overflow: "hidden",
    borderRadius: "10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMd ? "90%" : 880,
    height: 537,
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 2,
  };

  const statesOfIndia = [
    { stateName: "Andhra Pradesh", stateCode: "AP", value: false },
    { stateName: "Arunachal Pradesh", stateCode: "AR", value: false },
    { stateName: "Assam", stateCode: "AS", value: false },
    { stateName: "Bihar", stateCode: "BR", value: false },
    { stateName: "Chhattisgarh", stateCode: "CG", value: false },
    { stateName: "Goa", stateCode: "GA", value: false },
    { stateName: "Gujarat", stateCode: "GJ", value: false },
    { stateName: "Haryana", stateCode: "HR", value: false },
    { stateName: "Himachal Pradesh", stateCode: "HP", value: false },
    { stateName: "Jharkhand", stateCode: "JH", value: false },
    { stateName: "Karnataka", stateCode: "KA", value: false },
    { stateName: "Kerala", stateCode: "KL", value: false },
    { stateName: "Madhya Pradesh", stateCode: "MP", value: false },
    { stateName: "Maharashtra", stateCode: "MH", value: false },
    { stateName: "Manipur", stateCode: "MN", value: false },
    { stateName: "Meghalaya", stateCode: "ML", value: false },
    { stateName: "Mizoram", stateCode: "MZ", value: false },
    { stateName: "Nagaland", stateCode: "NL", value: false },
    { stateName: "Odisha", stateCode: "OR", value: false },
    { stateName: "Punjab", stateCode: "PB", value: false },
    { stateName: "Rajasthan", stateCode: "RJ", value: false },
    { stateName: "Sikkim", stateCode: "SK", value: false },
    { stateName: "Tamil Nadu", stateCode: "TN", value: false },
    { stateName: "Telangana", stateCode: "TG", value: false },
    { stateName: "Tripura", stateCode: "TR", value: false },
    { stateName: "Uttar Pradesh", stateCode: "UP", value: false },
    { stateName: "Uttarakhand", stateCode: "UK", value: false },
    { stateName: "West Bengal", stateCode: "WB", value: false },
    { stateName: "Andaman and Nicobar Islands", stateCode: "AN", value: false },
    { stateName: "Chandigarh", stateCode: "CH", value: false },
    {
      stateName: "Dadra and Nagar Haveli and Daman and Diu",
      stateCode: "DNDD",
      value: false,
    },
    { stateName: "Delhi", stateCode: "DL", value: false },
    { stateName: "Ladakh", stateCode: "LA", value: false },
    { stateName: "Lakshadweep", stateCode: "LD", value: false },
    { stateName: "Puducherry", stateCode: "PY", value: false },
    { stateName: "Jammu and Kashmir", stateCode: "JK", value: false },
  ];
  const [successMessage, setSuccessMessage] = useState("");
  const [checkedStates, setCheckedStates] = useState(statesOfIndia);

  const [countrySelect, setCountrySelect] = useState("");

  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState(preSelectedCitys);
  const [checkFlag, setCheckFlag] = useState(false);
  const [allOptions, setAllOptions] = useState(districts);
  const [searchValue, setSearchValue] = useState("");
  const [searchStateValue, setSearchStateValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const trueValueCount = checkedStates?.filter(
    (item) => item.value === true
  ).length;

  const handleCheckCountry = (event) => {
    const isChecked = event.target.checked;
    setCountrySelect(isChecked ? event.target.value : "");
    // Create a new array with updated values based on the current state
    const updatedStates = checkedStates.map((state) => ({
      ...state,
      value: isChecked,
    }));
    setCheckedStates(updatedStates);
  };

  const handleClickCheckBox = (e) => {
    // setPreSelectedCountry("")
    setCheckFlag(true);
    setCheckedStates(
      checkedStates.map((states) => {
        if (states.stateName == e.target.name) {
          return {
            stateName: e.target.name,
            value: e.target.checked,
            stateCode: states.stateCode,
          };
        }
        return states;
      })
    );
  };
  const handleClickChipSetStateRemove = (stateName) => {
    setCountrySelect(" ");
    setCheckFlag(false);
    setCheckedStates(
      checkedStates.map((states) => {
        if (states.stateName == stateName) {
          return { stateName: stateName, value: false };
        }
        return states;
      })
    );
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchStateValue(value);
  };
  useEffect(() => {
    const updatedFilteredOptions = districts.filter((city) =>
      city.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredOptions(updatedFilteredOptions);
  }, [searchValue, districts]);
  const handleCitySelection = (event, value) => {
    setSelectedCities(value);

    // Update remaining options based on selected cities and states
    const updatedRemainingOptions = allOptions.filter(
      (option) =>
        !value.includes(option) &&
        selectedStates.every((state) => !option.includes(state))
    );
    setFilteredOptions(updatedRemainingOptions);
  };
  // const [allCities, setAllCities] = useState([...businessRegions?.cities ?? []]);

  const handleCancleModel = () => {
    setRegionModel(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitClick = () => {
    const selectedStates = checkedStates.reduce((result, state) => {
      if (state.value) {
        result.push(state.stateName);
      }
      return result;
    }, []);

    const country = countrySelect;
    const state = selectedStates;
    const city = selectedCities;
    const citiesToDelete = preSelectedCitys.filter(
      (element) => !selectedCities.includes(element)
    );
    axios
      .post(UPDATE_REGIONS_IN_SUPPLIER_URL, {
        country,
        state,
        city,
        supplierId,
        citiesToDelete,
      })
      .then((response) => {
        // console.log("REGIONS ADDING RESPONSE", response);
        setIsLoading(false); // Set loading state to false on success
        setRegionModel(false);
        setSuccessMessage("Regions added successfully...!");
      })
      .catch((error) => {
        // console.log("REGIONS ADDING ERROR", error);
        setIsLoading(false); // Set loading state to false on error
      });
    setIsLoading(true);
    setTimeout(() => {
      window.location.reload();
    }, [1000]);
  };

  useEffect(() => {
    if (successMessage) {
      const timeout = setTimeout(() => {
        setSuccessMessage(""); // Clear success message after 5 seconds
      }, 4000);

      return () => clearTimeout(timeout); // Clear timeout on component unmount
    }
  }, [successMessage]);

  const [showAllCities, setShowAllCities] = useState(false);

  // const citiesToShow = businessRegions?.cities?.slice(0, isMd ? 6 : 7);
  const citiesToShow = regiosAllData
    ? regiosAllData.slice(0, isMd ? 6 : 7)
    : [];

  const remainingCities = regiosAllData?.length - citiesToShow?.length;

  const handleViewMore = () => {
    setShowAllCities(true);
  };

  const handleViewLess = () => {
    setShowAllCities(false);
  };

  // useEffect(
  //   () => setSelectedCities((preVal) => preSelectedCitys),
  //   [preSelectedCitys]
  // ); // the data .is come from api so we update selected citys when preSelectedCitys got data from api

  useEffect(() => {
    const arrayOfcheckedStates = checkedStates
      ?.filter((data) => data.value == true)
      .map((data) => data.stateName);

    setDistricts(
      apiDistricts
        .filter((data) => !arrayOfcheckedStates.includes(data.state))
        .map((obj) => obj.districts)
        .flat()
    );
  }, [checkedStates]);
  useEffect(() => {
    setSelectedCities(preSelectedCitys);
  }, [preSelectedCitys]);

  useEffect(() => {
    setAllOptions(districts);
    if (districts?.length > 0 && selectedCities.length > 0) {
      const filterCityByState = selectedCities.filter((data) =>
        districts.includes(data)
      );
      setSelectedCities(filterCityByState);
    }
  }, [districts]);

  return (
    <>
      {/* <Grid container mt={2}> */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container>
          <Grid
            sx={{ display: isSm && "flex", flexDirection: isSm && "column" }}
          >
            <Grid item sm={12}>
              {permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed && (
                  <Typography
                    sx={{
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    color="secondary"
                    size="small"
                    disabled={!(permissions && permissions.isUpdateAllowed)}
                    // sx={{ ml: 2 }}
                    // endIcon={<AddCircleRoundedIcon />}
                    onClick={handleAddRegions}
                  >
                    <Link
                      sx={{ textDecoration: "underline", color: "#2864BE" }}
                    >
                      {" "}
                      Click Here{" "}
                    </Link>
                    <span style={{ color: "black" }}>
                      {" "}
                      to Add / Update Regions
                    </span>
                  </Typography>
                )}
              <Grid>
                <Typography
                  style={{
                    backgroundColor: "#47B59E",
                    // width: isSm ? "110%" : "30%",
                    color: "white",
                    borderRadius: "4px",
                    paddingLeft: "5px",
                    fontSize: isSm ? "10px" : "",
                  }}
                >
                  {successMessage && <Typography>{successMessage}</Typography>}
                </Typography>
              </Grid>
            </Grid>

            {permissions &&
              !permissions.isViewOnly &&
              permissions.isFreeUpdateAllowed &&
              (businessRegions?.state?.length > 0 ||
                businessRegions?.Allcities?.length > 0) && (
                <Grid item sm={12}>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {citiesToShow?.map((location, index) => (
                      <Chip
                        key={index}
                        label={location}
                        style={{
                          margin: "4px",
                          borderRadius: "30px",
                          backgroundColor: "white",
                          border: "1px solid #C0C0C0",
                        }}
                      />
                    ))}
                    {regiosAllData?.length > 7 && !showAllCities && (
                      <Button
                        onClick={handleViewMore}
                        style={{
                          marginLeft: "15px",
                          color: "blue",
                          marginLeft: "4px",
                        }}
                        size="small"
                      >
                        <u>View More ({remainingCities} regions)</u>
                      </Button>
                    )}
                    {showAllCities &&
                      regiosAllData.slice(7).map((location, index) => (
                        <Chip
                          key={index}
                          label={location}
                          style={{
                            margin: "4px",
                            borderRadius: "30px",
                            backgroundColor: "white",
                            border: "1px solid #C0C0C0",
                          }}
                        />
                      ))}
                    {showAllCities && (
                      <Button
                        onClick={handleViewLess}
                        size="small"
                        style={{ color: "blue" }}
                      >
                        <u>View Less</u>
                      </Button>
                    )}
                  </div>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Box>

      {
        <Grid
          container
          columnGap={6}
          justifyContent="space-between"
          margin={"20px ,0"}
        >
          {permissions && !permissions?.isViewOnly && (
            <>
              {isSm ? (
                <BusinessRegionsMobile
                  regionModel={regionModel}
                  setRegionModel={setRegionModel}
                  // citiesInIndia={citiesInIndia}
                  statesOfIndia={statesOfIndia}
                  setSuccessMessage={setSuccessMessage}
                  supplierId={supplierId}
                  businessRegions={businessRegions}
                  selectedCities={selectedCities}
                  handleCitySelection={handleCitySelection}
                  preSelectedCitys={preSelectedCitys}
                  Districts={districts}
                  setSearchQuery={setSearchQuery}
                  filteredOptions={filteredOptions}
                  handleCheckCountry={handleCheckCountry}
                  apiDistricts={apiDistricts}
                  setDistricts={setDistricts}
                />
              ) : (
                <Modal
                  open={regionModel}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={RegionModalstyle}>
                    <Grid
                      xs={12}
                      sx={{ textAlign: "center", marginBottom: "20px" }}
                    >
                      <Typography>
                        Where do you provide your products or services ?
                      </Typography>
                    </Grid>

                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={isMd ? 5 : 4}>
                        <Grid
                          sx={{
                            height: "500px",
                            borderRight: "1px solid #CCCCCC",
                          }}
                        >
                          <Grid
                            xs={11}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #CCCCCC",
                              padding: "5px",
                              borderRadius: "5px",
                              height: "34px",
                            }}
                          >
                            <Icon
                              sx={{
                                marginRight: "10px",
                                borderRight: "1px solid #CCCCCC",
                                padding: "0 35px 0 5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                width={"22px"}
                                height={"18px"}
                                src="/assets/icons/indiaFlag.svg"
                                alt="india-flag"
                              />
                            </Icon>
                            <Typography>India</Typography>
                          </Grid>

                          <Grid
                            xs={11}
                            sx={{ marginTop: "18px", borderRadius: "20px" }}
                          >
                            <TextField
                              size="small"
                              label="Search for states"
                              placeholder="Search for states"
                              value={searchStateValue}
                              onChange={handleSearchChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {" "}
                                    <IconButton>
                                      {" "}
                                      <SearchIcon />{" "}
                                    </IconButton>{" "}
                                  </InputAdornment>
                                ),
                                style: {
                                  borderRadius: "20px",
                                },
                              }}
                            />
                          </Grid>

                          <Grid
                            xs={12}
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div
                              style={{
                                maxHeight: "370px",
                                overflow: "scroll",
                                overflowX: "hidden",
                                fontSize: "5px",
                              }}
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        trueValueCount == 36 ? true : false
                                      }
                                      size="small"
                                      defaultChecked={
                                        preSelectedCountry == "India"
                                          ? true
                                          : false
                                      }
                                    />
                                  }
                                  label={
                                    <span
                                      style={{
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        color: "#757373",
                                      }}
                                    >
                                      All over India
                                    </span>
                                  }
                                  onChange={handleCheckCountry}
                                  value={"India"}
                                />
                                {checkedStates
                                  .filter((state) =>
                                    state.stateName
                                      .toLowerCase()
                                      .includes(searchStateValue.toLowerCase())
                                  ) // Apply the search filter
                                  .map((state) => {
                                    return (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            // defaultChecked={true}
                                            // defaultChecked={true}
                                            checked={state.value}
                                            name={state.stateName}
                                            size="small"
                                            onClick={handleClickCheckBox}
                                          />
                                        }
                                        label={
                                          <span
                                            style={{
                                              fontFamily: "Roboto",
                                              fontStyle: "normal",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              lineHeight: "16px",
                                              color: "#757373",
                                            }}
                                          >
                                            All over {state.stateName}
                                          </span>
                                        }
                                        // onChange={handleCheckboxChange}
                                        //   value={state}
                                      />
                                    );
                                  })}
                              </FormGroup>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={isMd ? 7 : 8} sx={{}}>
                        {/* <Grid sx={{minHeight:"200px"}}> */}
                        <Typography mb={"3px"}>Selected States :</Typography>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            border: "1px solid #CCCCCC",
                            minHeight: "40px",
                            borderRadius: "5px",
                            maxHeight: "180px",
                            overflow: "auto",
                          }}
                        >
                          {checkedStates.map((data) => {
                            if (data.value)
                              return (
                                <Chip
                                  size="small"
                                  label={`All over ${data.stateName}`}
                                  variant="outlined"
                                  onDelete={() =>
                                    handleClickChipSetStateRemove(
                                      data.stateName
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#292A2D",
                                    color: "white",
                                    margin: "4px",
                                    borderRadius: "5px",
                                  }}
                                  deleteIcon={
                                    <ClearIcon
                                      style={{
                                        color: "#E2E2E2",
                                        fontSize: "14px",
                                      }}
                                    />
                                  }
                                />
                              );
                          })}
                          {/* </Grid> */}
                        </Grid>
                        <Grid xs={12} sx={{ marginTop: "18px" }}>
                          <Typography mb={"3px"}>Selected Cities :</Typography>
                          <Autocomplete
                            //   freeSolo
                            disabled={trueValueCount == 36}
                            // clearOnBlur
                            style={{ maxHeight: "230px", overflow: "auto" }}
                            size="small"
                            multiple
                            // value={citiesInIndia}
                            disableClearable
                            onChange={handleCitySelection}
                            options={searchValue ? filteredOptions : allOptions}
                            value={trueValueCount === 36 ? [] : selectedCities}
                            // ...
                            onInputChange={(event, newValue) =>
                              setSearchValue(newValue)
                            }
                            // filterOptions={filterOptions}
                            // filterOptions={filterOptions}
                            PaperComponent={({ children }) => (
                              <Paper
                                style={{ fontSize: "14px", color: "#757373" }}
                              >
                                {children}
                              </Paper>
                            )}
                            // getOptionLabel={(option) => citiesInIndia}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((citiesInIndia, index) => (
                                <Chip
                                  size="small"
                                  label={citiesInIndia}
                                  {...getTagProps({ index })}
                                  style={{
                                    margin: "4px",
                                    backgroundColor: "#292A2D",
                                    color: "white",
                                    borderRadius: "5px",
                                  }}
                                  deleteIcon={
                                    <ClearIcon
                                      style={{
                                        color: "#E2E2E2",
                                        fontSize: "14px",
                                      }}
                                    />
                                  }
                                />
                              ))
                            }
                            // style={{ width: 500 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Fixed tag"
                                placeholder="Type to select city"
                                onChange={(event) =>
                                  setSearchQuery(event.target.value)
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          style={{
                            justifyContent: "end",
                            display: "flex",
                            position: "absolute",
                            bottom: "10px",
                            right: "15px",
                            gap: "10px",
                          }}
                        >
                          <Button
                            onClick={handleCancleModel}
                            sx={{ padding: "5px 20px",backgroundColor: "#757575",
                            color:"white",
                            "&:hover": {
                              backgroundColor: "#757575",
                              transition: "background-color 0.3s, box-shadow 0.3s",
                              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                            }
                            }}
                            variant="contained"
                            size="small"
                            color="inherit"
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            // disabled={
                            //   selectedCities.length > 0 || checkFlag == true
                            //     ? false
                            //     : true
                            // }
                            loading={isLoading}
                            loadingPosition="end"
                            onClick={handleSubmitClick}
                            sx={{
                              backgroundColor: "black",
                              color: "white",
                              padding: isLoading
                                ? "5px 25px 5px 15px"
                                : "5px 25px",

                              "&:hover": {
                                backgroundColor: "black",
                                transition:
                                  "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                              },
                            }}
                           
                            variant="contained"
                            size="small"
                          >
                            {isLoading ? "Adding.." : "Submit"}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              )}
            </>
          )}
        </Grid>
      }
    </>
  );
}

BusinessRegions.defaultProps = {
  isPublicProfile: true,
};

BusinessRegions.propTypes = {
  isPublicProfile: PropTypes.bool,
  supplierId: PropTypes.number,
  permissions: PropTypes.object,
};

export default BusinessRegions;
