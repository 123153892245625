import React, { useState } from "react";

import {
  Box,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";

import BusinessRegistrationForm from "views/Business/components/BusinessRegistrationForm";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useEffect } from "react";


function RegisterBusiness({ }) {

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up(1024));
  const BASE_URL = process.env.REACT_APP_BASE_URL;
const navigate = useNavigate();

const auth = useSelector((state) => state?.auth);
const business = useSelector((state) => state.business);
console.log("Business",business )
const userId = auth?.user?.userId;
const supplierId = business?.profile?.supplierId;
console.log("supplierId",supplierId)



  useEffect(()=>{
    if(!userId){
      navigate("/")
    } 
  },[])

  return (
    <> 
      <Helmet>
        <title>Neevay - Register Business</title>
      </Helmet>
      
      <Grid container xs={12} style={{position:"relative"}}>
        {isMd && <Grid item md={6.5} lg={6} style={{  backgroundColor: "#FBF8F6",display:"flex", height: "100vh", alignItems:"center", justifyContent:"center", position:"fixed", top:"0", width:isMd?"50%":null}}>
          <Box
            height="327px"
            component="img"
            src={`${BASE_URL}/assets/business/web-userform-UserFormImg.svg`}
          />
        </Grid>}
        <Grid  item md={5.5} lg={6} sx={{ paddingX: isSm && "30px", width: "100vw",position:"absolute", top:"0", right:"0" }}>
        <BusinessRegistrationForm /> 
        </Grid>
      </Grid>
    </>
  );
}

export default RegisterBusiness;
