const tableData = {
  row: [
    "Company",
    "Default Contact",
    "Tags",
    "Location",
    // "Qualified",
    "Service Offered",
    // "Sector",
    "Awarded (Count)",
    // "Internal Rating",
    // "Internal Notes",
    "Annual Turnover",
    "GST Status",
    "GST No.",
    "Created Date",
    "Added By",
  ],
  column: [
    {
      Company: "Omkar construction",
      "Default Contact": "9096782972",
      Tags: ["steel"],
      Location: "Pune",
      Qualified: false,
      "Service Offered": "waterproofing",
      Sector: "Healthcare",
      "Contracts Awarded": 1,
      "Internal Rating": 3,
      "Internal Notes": "Best services provider",
      "GST Turnover": "1-7Cr",
      "GST Status": "Active",
      "GST No.": "ABC690876",
      "Added By": "Vishal",
      onNeevay: true,
    },
    {
      Company: "abc construction pvt limited, pune",
      "Default Contact": "9096782972",
      Tags: ["steel", "sand", "bricks"],
      Location: "Pune",
      Qualified: true,
      "Service Offered": "waterproofing",
      Sector: "Healthcare",
      "Contracts Awarded": 2,
      "Internal Rating": 3,
      "Internal Notes": "Best services provider",
      "GST Turnover": "1-7Cr",
      "GST Status": "Active",
      "GST No.": "ABC690876",
      "Added By": "Vishal",
      onNeevay: false,
    },
    {
      Company: "ABC Builders",
      "Default Contact": "9876543210",
      Tags: ["concrete", "glass", "paint"],
      Location: "Mumbai",
      Qualified: false,
      "Service Offered": "construction",
      Sector: "Real Estate",
      "Contracts Awarded": 0,
      "Internal Rating": 5,
      "Internal Notes": "Reliable contractor",
      "GST Turnover": "5-10Cr",
      "GST Status": "Active",
      "GST No.": "XYZ123456",
      "Added By": "Sonia",
      onNeevay: true,
    },
    {
      Company: "Green Landscapes",
      "Default Contact": "7890123456",
      Tags: ["plants"],
      Location: "Bangalore",
      Qualified: true,
      "Service Offered": "landscaping",
      Sector: "Environment",
      "Contracts Awarded": 0,
      "Internal Rating": 4,
      "Internal Notes": "Top-notch landscaping services",
      "GST Turnover": "2-8Cr",
      "GST Status": "Active",
      "GST No.": "PQR987654",
      "Added By": "Rahul",
      onNeevay: false,
    },
    {
      Company: "HealthFirst Wellness",
      "Default Contact": "6543210987",
      Tags: ["healthcare", "wellness"],
      Location: "Los Angeles",
      Qualified: true,
      "Service Offered": "health and wellness programs",
      Sector: "Healthcare",
      "Contracts Awarded": 6,
      "Internal Rating": 2,
      "Internal Notes": "Promoting a healthy and active lifestyle",
      "GST Turnover": "3-10Cr",
      "GST Status": "Active",
      "GST No.": "DEF876543",
      "Added By": "Ryan",
      onNeevay: true,
    },
    {
      Company: "Golden Finance Solutions",
      "Default Contact": "7654321098",
      Tags: [
        "finance",
        "investment",
        "banking",
        "construction",
        "construction",
        "construction",
        "construction",
      ],
      Location: "New York",
      Qualified: true,
      "Service Offered": "financial consulting",
      Sector: "Finance",
      "Contracts Awarded": 4,
      "Internal Rating": 2,
      "Internal Notes": "Expertise in financial planning and investment",
      "GST Turnover": "8-12Cr",
      "GST Status": "Active",
      "GST No.": "ABC987654",
      "Added By": "Emily",
      onNeevay: true,
    },
    {
      Company: "Sunshine Realty",
      "Default Contact": "8765432109",
      Tags: ["real estate", "property", "construction", "construction"],
      Location: "Miami",
      Qualified: true,
      "Service Offered": "real estate development",
      Sector: "Real Estate",
      "Contracts Awarded": 0,
      "Internal Rating": 4,
      "Internal Notes": "Specializing in luxury property development",
      "GST Turnover": "10-20Cr",
      "GST Status": "Active",
      "GST No.": "LMN123456",
      "Added By": "Sophia",
      onNeevay: false,
    },
    {
      Company: "Blue Innovations",
      "Default Contact": "9876543210",
      Tags: ["technology", "innovation"],
      Location: "San Francisco",
      Qualified: true,
      "Service Offered": "software development",
      Sector: "Technology",
      "Contracts Awarded": 5,
      "Internal Rating": 3,
      "Internal Notes":
        "Positioning ourselves at the forefront of the industry, we strive to provide cutting-edge and innovative software solutions, setting new standards for excellence and paving the way for technological advancements.",
      "GST Turnover": "5-15Cr",
      "GST Status": "Active",
      "GST No.": "XYZ123456",
      "Added By": "Alex",
      onNeevay: true,
    },
  ],
};

export default tableData;
