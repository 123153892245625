import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAddressHO, getAddressStr } from "utils/addressUtils";
import SignInMobileOtpPopUp from "views/Auth/SignInSendMobileOTP/SignInMobileOtpPopUp";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import VerificationPopUp from "views/VerificationModal";
import SocialIconDailog from "views/Business/components/SocialIconDailog";
import BusinessAddressForm from "views/Business/components/BusinessAddressForm";
import { Edit } from "@mui/icons-material";
function BusinessContactEditView({
  profile,
  isMobileView = false,
  PublicProfile,
  permissions,
  isEditView,
}) {
  const [openLogInModal, setOpenLogInModal] = useState(false);
  const [shoWebsite, setShowWebsite] = useState(false);
  const [shoWSocialIcons, setShowSocialIcons] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const auth = useSelector((state) => state.auth);

  const businessProfile = profile?.businessProfile;
  const addresses = businessProfile?.addresses;
  const socialLinks = businessProfile?.socialLinks;
  const website = businessProfile?.websiteUrl;
  const email = businessProfile?.businessEmail;
  const userProfile = auth.user;
  const contact = businessProfile?.contact;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const socialIconList = [
    {
      name: "Facebook",
      image: "/assets/icons/facebook.svg",
      target: socialLinks?.facebookUrl,
    },
    {
      name: "Twitter",
      image: "/assets/icons/twitter.svg",
      target: socialLinks?.twitterUrl,
    },
    {
      name: "Instagram",
      image: "/assets/icons/instagram.svg",
      target: socialLinks?.instagramUrl,
    },
    {
      name: "Youtube",
      image: "/assets/icons/youtube.svg",
      target: socialLinks?.youtubeUrl,
    },
  ];
  const address = getAddressHO(addresses);
  const addressStr = getAddressStr(address);

  const handleClickShowOwnerDetails = (type) => {
    if (!userProfile) {
      setOpenLogInModal(true);
      return;
    } else if (userProfile.isVerified === false) {
      setIsModalOpen(true);
    } else {
      setShowEmail(type === "email" ? !showEmail : showEmail);
      setShowWebsite(type === "url" ? !shoWebsite : shoWebsite);
      setShowSocialIcons(
        type === "socialIcons" ? !shoWSocialIcons : shoWSocialIcons
      );
    }
  };

  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };

  function hasNonEmptyValue(obj) {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== "") {
        count++;
        if (count > 1) return true;
      }
    }
    return false;
  }

  const results = hasNonEmptyValue(socialLinks);
  return (
    <Grid>
      <Grid
        sx={{
          border: "1px solid rgba(217, 217, 217, 1)",
          borderRadius: isMobileView ? "30px" : "10px",
          minWidth: isMobileView ? "200px" : "322px",
          paddingLeft: "20px",
          paddingRight: "10px",
          marginX: isMobileView && "8px",
          marginTop: isMobileView && "16px",
        }}
      >
        <Grid>
          <Grid
            sx={{
              display: "flex",
              paddingTop: "20px",
              // justifyContent: "center",
              alignItems: "center",
              width: !userProfile ? "160px" : "150px",
            }}
          >
            <Box
              component="img"
              alt="phone"
              src="/assets/home/questions.gif"
              style={{ width: "34px" }}
            />
           
            {userProfile  ?
            (
              <Typography variant="businessCard" sx={{paddingLeft:"17px"}} >
                {contact ? contact : "Not Disclosed"}
              </Typography>
            ) : (
              <Typography
                onClick={() => handleClickShowOwnerDetails("mobile")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#3963AB",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Hidden
              </Typography>
            ) }

            {!userProfile && (
              <Typography
                onClick={() => handleClickShowOwnerDetails("mobile")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#3963AB",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Login to View
              </Typography>
            )}
          </Grid>

        </Grid>
        <Grid
          sx={{
            display: "flex",
            paddingTop: "10px",
          }}
        >
          <Box
            component="img"
            alt="phone"
            src="/assets/home/email.gif"
            style={{ paddingBottom: "10px", width: "36px" }}
          />
          {/* { isEditView && (
              <Typography
                variant="businessCard"
                sx={{ ml: 2, textAlign: "center", paddingTop: "5px" }}
              >
                {email ? email : "Not Disclosed"}
              </Typography>
            )} */}
          {userProfile  ? 
          (
            <Typography
              variant="businessCard"
              sx={{ ml: 2, textAlign: "center", paddingTop: "5px" }}
            >
              {email ? email : "Not Disclosed"}
            </Typography>
          )
          : (
            <Typography
              onClick={() => handleClickShowOwnerDetails("email")}
              style={{
                cursor: "pointer",
                textAlign: "center",
                marginLeft: "12px",
                color: "#3963AB",
                fontSize: "14px",
                textDecoration: "underline",
                paddingTop: "5px",
              }}
            >
              Hidden
            </Typography>
          ) 
          }
          {!userProfile && (
            <Typography
              onClick={() => handleClickShowOwnerDetails("email")}
              style={{
                cursor: "pointer",
                textAlign: "center",
                marginLeft: "12px",
                color: "#3963AB",
                fontSize: "14px",
                textDecoration: "underline",
                paddingTop: "5px",
              }}
            >
              Login to view Email
            </Typography>
          )}
        </Grid>

        <Grid
          sx={{
            display: "flex",
          }}
        >
          <Box
            component="img"
            alt="phone"
            src="/assets/home/web.gif"
            style={{
              paddingBottom: "10px",
              width: "36px",
              textAlign: "center",
            }}
          />
          {userProfile &&  isEditView && (
            <Typography
              variant="businessCard"
              sx={{ ml: 2, paddingTop: "5px", overflow: "hidden" }}
            >
              {website ? (
                <a
                  href={
                    website.startsWith("http") ? website : `https://${website}`
                  }
                  target="_blank"
                >
                  {website}
                </a>
              ) : (
                "Not Disclosed"
              )}
            </Typography>
          )}
          
          {!userProfile && (
            <Typography
              onClick={() => handleClickShowOwnerDetails("url")}
              style={{
                cursor: "pointer",
                textAlign: "center",
                color: "#3963AB",
                fontSize: "14px",
                marginLeft: "12px",
                textDecoration: "underline",
                paddingTop: "5px",
              }}
            >
              Login to View website
            </Typography>
          )}
        </Grid>

        {results && (
          <Grid sx={{ display: "flex", gap: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                // flexDirection:"",
                mb: "10px",
                alignItems: "end",
              }}
            >
              <Box
                component="img"
                alt="link"
                src="/assets/home/link.svg"
                style={{
                  width: "20px",
                  margin: " 0px 8px",
                  textAlign: "center",
                  marginBottom: isEditView && "10px",
                  paddingTop: shoWSocialIcons && "8px",
                  paddingBottom: shoWSocialIcons && "8px",
                }}
              />
              {userProfile && userProfile.isVerified && isEditView && (
                <Typography
                  variant="businessCard"
                  sx={{ ml: 2, paddingTop: "5px" }}
                >
                  {socialIconList.map((item) => {
                    return item.target !== "" ? (
                      <IconButton href={item.target} target="_blank">
                        <img src={item.image} />
                      </IconButton>
                    ) : (
                      ""
                    );
                  })}
                </Typography>
              )}
              {userProfile && !userProfile.isVerified && (
                <Typography
                  onClick={() => handleClickShowOwnerDetails("socialIcons")}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    color: "#3963AB",
                    fontSize: "14px",
                    marginLeft: "12px",
                    textDecoration: "underline",
                    paddingTop: "5px",
                  }}
                >
                  Hidden
                </Typography>
              )}
              {!userProfile && (
                <Typography
                  onClick={() => handleClickShowOwnerDetails("socialIcons")}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    color: "#3963AB",
                    fontSize: "14px",
                    marginLeft: "12px",
                    textDecoration: "underline",
                    paddingTop: "5px",
                  }}
                >
                  Login to view
                </Typography>
              )}
            </Grid>

            <Grid>
              {permissions &&
                !permissions.isViewOnly &&
                permissions.isFreeUpdateAllowed && (
                  <SocialIconDailog
                    supplierId={profile?.supplierId}
                    socialLinks={socialLinks}
                    shoWSocialIcons={shoWSocialIcons}
                    isEditView={isEditView}
                  />
                )}
            </Grid>
          </Grid>
        )}

        <Grid sx={{ display: "flex" }}>
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              alt="phone"
              src="/assets/home/locationIcon.svg"
              style={{ width: "22px", marginBottom: "38px" }}
            />
            {/* <LocationOnOutlinedIcon /> */}
            {addresses?.length > 0 ? (
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  width: "241px",
                  // marginTop: "14px",
                  marginBottom: "29px",
                  marginLeft: "28px",
                  flexWrap: "wrap",
                }}
              >
                {addressStr}
              </Typography>
            ) : (
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  width: "200px",
                  // marginTop: "14px",
                  marginBottom: "29px",
                  marginLeft: "32px",
                }}
              >
                Not Disclosed
              </Typography>
            )}
          </Grid>
          <Grid>
            {permissions &&
              !permissions.isViewOnly &&
              permissions.isFreeUpdateAllowed && (
                <ListItem
                  disablePadding
                  sx={{ textAlign: "right" }}
                  onClick={handleClickOpen}
                >
                  <ListItemIcon>
                    {addressStr ? (
                      <Tooltip title="Edit Address">
                        <Link style={{ fontSize: "14px", color: "#2864BE" }}>
                          {" "}
                          Edit
                        </Link>
                      </Tooltip>
                    ) : (
                      <Link style={{ fontSize: "14px", color: "#2864BE" }}>
                        {" "}
                        Edit
                      </Link>
                      // <Edit
                      //   sx={{ color: "primary.main" }}
                      //   onClick={handleClickOpen}
                      // />
                    )}
                  </ListItemIcon>
                </ListItem>
              )}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Edit Address"}
              </DialogTitle>
              <DialogContent>
                <BusinessAddressForm
                  supplierId={profile?.supplierId}
                  address={address}
                  handleClose={handleClose}
                />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />
      <VerificationPopUp
        open={isModalOpen} // Pass the modal visibility state
        handleClose={handleCloseModal} // Pass the close modal function
      />
    </Grid>
  );
}

export default BusinessContactEditView;
