export const SIGNIN_SEND_OTP_URL = "/api/auth/signin/send-otp";
export const SIGNIN_WITH_MOBILE_OTP = "/api/auth/signin/validate-otp";
export const SIGNIN_EMAIL_PASS_URL = "/api/auth/signin";
export const GET_USER_DATA_BY_ID = "/api/auth/user-data";

export const REGISTER_USER_PROFILE_URL = "/api/auth/profile"; //"/api/auth/signup";
export const UPDATE_USER_PROFILE_URL = "/api/auth/profile";

export const FAVOURITES_FETCH_URL = "/api/user/favourite/myitems";
export const FAVOURITES_ADD_URL = "/api/user/favourite/additem";
export const FAVOURITES_REMOVE_URL = "/api/user/favourite/removeitem";

export const ORDERS_FETCH_URL = "/api/oms/myorders";

export const REQUIREMENTS_FETCH_URL = "/api/rfqs/rfqs";
export const LEADS_FETCH_URL = "/api/rfqs/lead/leads";
export const SEND_ACK_URL = "api/rfqs/lead/acknowledge";
export const REQUIREMENT_RESEND_URL = "api/rfqs/resend";

export const SEARCH_URL = "/api/search/list";
export const SEARCH_URL_BY_NAME = "/api/search/list/byname";
export const SEARCH_URL_BY_SERVICE_NAME = "/api/search/list/byservicename";
export const SEARCH_COMPANY_URL = "/api/search/businesslist";
export const SEARCH_BUSINESS_BY_NAME = "/api/supplier/byname";
export const SEARCH_BUSINESS_BY_NAME_AUTH = "/api/auth/user/companies";

export const SIGNUP_SEND_OTP_URL = "/api/auth/signup/otp";
export const SIGNUP_VALIDATE_OTP_URL = "/api/auth/signup/mobile";
export const PROFILE_IMAGE_UPLOAD_URL = "api/auth/profile/image";

export const WHATSAPP_OTP_URL = "/api/auth/whatsapp/otp";
export const WHATSAPP_VALIDATE_OTP_URL = "/api/auth/whatsapp/otp/verify";

export const REQUIREMENT_ADD_URL = "/api/rfqs/create";
export const REQUIREMENT_CLOSE_URL = "/api/rfqs/close";

export const REQUIREMENT_UPDATE_URL = "/api/rfqs/update";
export const REQUIREMENT_DELETE_URL = "/api/rfqs/delete";
export const VIEW_REQUIREMENT_FETCH_URL = "/api/rfqs/rfq";
export const SEND_NEW_ENQUIRY_URL = "api/rfqs/create";
export const SEND_EXISTING_ENQUIRY_URL = "api/rfqs/add/supplier";
export const SEND_REPEAT_FIXED_RFQ = "/api/rfqs/repeat-fixed/create";
export const EDIT_REPEAT_FIXED_RFQ = "/api/rfqs/update/repeat/order";
export const GET_REPEAT_FIXED_RFQ = "/api/rfqs/repeat-fixed/rfqs";
export const SEARCH_REPEAT_FIXED_RFQ = "/api/rfqs/search/repeat/order";
export const GET_SEARCH_REPEAT_FIXED_RFQ = "/api/rfqs/repeat-fixed/search";
export const DELETE_REPEAT_FIXED_RFQ = "/api/rfqs/delete/repeat/order";
export const GET_SEARCH_RFQ = "/api/rfqs/search";
export const ADD_SUPPLIER_TO_RFQ = "/api/rfqs/add/supplier";

export const ADD_PROJECT_URL = "/api/supplier/project/add";
export const UPDATE_PROJECT_URL = "/api/supplier/project/update";
export const DELETE_PROJECT_URL = "/api/supplier/project/delete";

export const ADD_UPDATE_SERVICE_URL = "/api/supplier/services/addupdate";
export const DELETE_SERVICE_URL = "/api/supplier/services/delete";

export const UPLOAD_IMAGE_LOGO_URL = "/api/supplier/update/logo";
export const UPLOAD_IMAGE_BANNER_URL = "/api/supplier/update/banner";
export const UPLOAD_SERVICE_IMAGE_URL = "/api/supplier/services/serviceimage";
export const UPLOAD_PROJECT_IMAGE_URL =
  "/api/supplier/project/update/projectimage";
export const UPLOAD_SEARCH_IMAGE_URL = "";

export const FEEDBACK_CREATE_URL = "/api/user/feedback";

export const HIDE_CONTACT_DETAIL = "/api/auth/hide/contact";

export const FETCH_BUSINESS_BY_USER_ID_URL = "/api/supplier/profile/userid";

export const FETCH_BUSINESS_BY_ID_URL = "/api/supplier/profile";
export const FETCH_BUSINESS_BY_URL_URL = "/api/supplier/profileurl";
export const FETCH_TAGS_BY_BUSINESS = "/api/directory/vendor/tags";

export const UPDATE_BUSINESS_PROFILE_URL = "/api/supplier/update/profile";
export const EDIT_ADDRESS_URL = "/api/supplier/update/address/addupdate";

export const PROFILE_URL_URL = "api/supplier/update/profileurl";
export const NEW_COMPANY_URL = "api/supplier/register";

export const MEMBERS_FETCH_URL = "/api/supplier/member/get";
export const ADD_MEMBERS_URL = "/api/supplier/member/add";
export const DELETE_MEMBERS_URL = "/api/supplier/member/delete";

export const GST_INFO_URL = "api/gstn/gstinInfo";

export const BUSINESS_SOCIAL_ICON_URL = "/api/supplier/social/update";
export const OWNER_CHANGE_URL = "api/rfqs/delete";

export const LIST_AWARD_URL = "/api/supplier/awards/list";
export const ADD_AWARD_URL = "/api/supplier/awards/add";
export const DELETE_AWARD_URL = "/api/supplier/awards/remove";

export const LIST_CERTIFICATE_URL = "/api/supplier/certificates/list";
export const ADD_CERTIFICATE_URL = "/api/supplier/certificates/add ";
export const DELETE_CERTIFICATE_URL = "/api/supplier/certificates/remove";

export const ADD_CLIENT_URL = "/api/supplier/clients/add ";
export const DELETE_CLIENT_URL = "/api/supplier/clients/remove";

export const SUBSCRIBE_PLAN = "/api/oms/create";
export const SUBSCRIBE_OMS_UPDATE = "api/oms/update/address";
export const INSTANT_PAYMENT = "api/oms/updatePaymentOrder";

export const DELETE_BANNER_IMG_URL = "api/supplier/banner/delete";
export const DELETE_LOGO_IMG_URL = "api/supplier/logo/delete";

export const GET_ALL_GLOBAL_TAGS = "api/user/getallglobaltags";
export const GET_TAGS_BY_ID = "/api/directory/user/tags";
export const ADD_TO_GLOBAL_TAGS = "api/user/addtoglobaltags";

export const UPDATE_CRM_ID_IN_USER_URL = "/api/auth/update-crm-id";
export const UPDATE_REGIONS_IN_SUPPLIER_URL =
  "/api/supplier/update/profile/region";
export const REMOVE_REGIONS_IN_SUPPLIER_URL =
  "/api/supplier/update/profile/remove/region";
export const GET_ALL_DISTRICTS = "/api/master/region/state/districts";

export const CREATE_CRM_LEAD_URL = `https://${process.env.REACT_APP_CRM_CREATE_LEAD_HOSTNAME}/v2/LeadManagement.svc/Lead.Create?accessKey=${process.env.REACT_APP_CRM_CREATE_LEAD_ACCESS_ID}&secretKey=${process.env.REACT_APP_CRM_CREATE_LEAD_SECRET_KEY}`;
export const GET_CRM_LEAD_BY_PHONE_NUMBER = `https://${process.env.REACT_APP_CRM_CREATE_LEAD_HOSTNAME}/v2/LeadManagement.svc/RetrieveLeadByPhoneNumber?accessKey=${process.env.REACT_APP_CRM_CREATE_LEAD_ACCESS_ID}&secretKey=${process.env.REACT_APP_CRM_CREATE_LEAD_SECRET_KEY}&phone=`;
export const UPDATE_CRM_LEAD_BY_ID = `https://${process.env.REACT_APP_CRM_CREATE_LEAD_HOSTNAME}/v2/LeadManagement.svc/Lead.Update?accessKey=${process.env.REACT_APP_CRM_CREATE_LEAD_ACCESS_ID}&secretKey=${process.env.REACT_APP_CRM_CREATE_LEAD_SECRET_KEY}&leadId=`;
export const CREATE_LEAD_ACTIVITY_URL = `https://${process.env.REACT_APP_CRM_CREATE_LEAD_HOSTNAME}/v2/ProspectActivity.svc/Create?accessKey=${process.env.REACT_APP_CRM_CREATE_LEAD_ACCESS_ID}&secretKey=${process.env.REACT_APP_CRM_CREATE_LEAD_SECRET_KEY}`;

export const UPDATE_SUPPLIER_DETAILS = "/api/supplier/update/business/profile";
export const UPDATE_STEPPER_COUNT = "/api/supplier/update/stepper/count";
export const ADD_MULTIPLE_CLIENTS = "/api/supplier/multiple/clients/add";

export const SEARCH_COMPANY_DIR_VENDOR = "/api/directory/search";
export const FILTER_COMPANY_DIR = "/api/directory/filters";
export const ADD_TAGS_TO_COMPANY_DIR_VENDOR = "/api/directory/add/tags";
export const REMOVE_TAGS_TO_COMPANY_DIR_VENDOR = "/api/directory/remove/tags";
export const ADD_VENDORS_TO_CD_FROM_N2 = "/api/directory/add/neevay/vendors";

export const SAVE_RFQ = "/api/rfqs/save";
export const CREATE_NEW_PROJECT = "/api/entity/add/project";
export const SEARCH_RFQ = "/api/rfqs/cd/search";
export const EDIT_RFQ = "/api/rfqs/update/rfq";
export const TRASH_RFQ = "api/rfqs/trash/rfq";
export const DELETE_RFQ = "api/rfqs/delet/rfq";
export const RESTORE_RFQ = "api/rfqs/restore/rfq";
export const INVITE_VENDORS = "/api/rfqs/invite/selected-vendor";
export const REMOVE_VENDORS_RFQ = "/api/rfqs/remove/suppliers";
export const REINVITE_RFQ = "/api/rfqs/reinvite";
export const MUL_REINVITE_RFQ = "/api/rfqs/multiple/reinvite";
export const SEND_RFQ = "";
export const GET_RFQS = "/api/rfqs/entity/rfqs";
export const GET_RFQS_BY_STATUS = "/api/rfqs/entity/rfqs/status";
export const GET_RFQ = "/api/rfqs/entity/rfq";
export const RFQ_FILTER_DATE = "/api/rfqs/entity/rfqs/date-range";
export const CLOSE_RFQ = "/api/rfqs/update/status";
export const CLOSE_RFQ_V2 = "/api/rfqs/closing/details";
export const CLOSE_RFQ_WITHOUT_VENDOR = "/api/rfqs/close/without/vendors";
export const DELETE_SINGLE_RFQ = "/api/rfqs/trash/single/rfq";
export const ADD_VENDORS_TO_RFQ = "/api/rfqs/add/suppliers";
export const ADD_EMAILS_TO_SUPPLIER = "/api/rfqs/supplier/add/emails";
export const QUOTATION_RECEIVED = "/api/rfqs/quotation/details";
export const CHANGE_STATUS = "/api/rfqs/change/status";
export const UPDATE_EMAIL_VENDOR = "/api/rfqs/update/vendor/email";

export const GET_USER_CONTACTS_BY_ID = "/api/directory/user/contact";

export const GET_RFQ_PROJECTS = "/api/entity/projects";
export const EDIT_RFQ_PROJECT = "/api/entity/update/project";

export const GET_MEMBER_LIST = "/api/entity/member/emails";

// vendor quotation urls

export const SUBMIT_VENDOR_QUOTATION_URL = "/api/rfqs/add-update/vendor/quotations"
export const GET_VENDOR_QUOTATION_URL = "/api/rfqs/vendor-quotation"
