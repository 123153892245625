import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  FormControl,
  Fab,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { updateUserProfile } from "actions/registerActions";
import { TYPES } from "config";

function ProfileForm({ className, profileData, onSubmitSuccess, ...rest }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("ProfileForm profileData", profileData);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: profileData?.name,
        email: profileData?.email,
        mobile: profileData?.mobile,
        whatsapp: profileData?.whatsapp,
        roleInCompany: profileData?.roleInCompany, // designation
        // userType: profileData?.userType,
        company: profileData?.company || profileData?.companyName,
        personaldetails: profileData?.personaldetails,
        validEmail: profileData?.isEmailValidated,
        validMobile: profileData?.isMobileValidated,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().min(2).max(50).required(),
        userType: Yup.string(),
        roleInCompany: Yup.string()
          .min(2)
          .max(80)
          .required("Please enter your designation"),
        mobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .max(10)
          .required(),
        whatsapp: Yup.string()
          .matches(/^[6-9][0-9]{9}/, "Please enter valid 10 digit whatsApp No.")
          .max(10)
          .min(10)
          .required("Please enter whatsApp No."),
        company: Yup.string()
          .min(2)
          .max(120)
          .required("Please enter your company Name"),
        personaldetails: Yup.string()
          .min(2)
          .max(500)
          .required("Please enter your PersonalDetails"),
        email: Yup.string().email().max(80),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(updateUserProfile(values));
          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>
          {/*   <Grid item xs={12} lg={4} md={4}>
              <Autocomplete
                value={values.userType}
                onChange={(event, newValue) => {
                  console.log("Autocomplete: ", newValue);
                  setFieldValue("userType", newValue);
                }}
                disablePortal
                id="userType"
                name="userType"
                options={TYPES}
                fullWidth
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField {...params} label="User Type" />
                )}
                // freeSolo
              />
            </Grid>
            <Grid item xs={12} lg={4} md={4}>
              <TextField
                disabled
                error={Boolean(touched.validEmail && errors.validEmail)}
                fullWidth
                helperText={touched.validEmail && errors.validEmail}
                label="Email Validated"
                name="validEmail"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.validEmail}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={4} md={4}>
              <TextField
                disabled
                error={Boolean(touched.validMobile && errors.validMobile)}
                fullWidth
                helperText={touched.validMobile && errors.validMobile}
                label="Mobile Validated"
                name="validMobile"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.validMobile}
                variant="outlined"
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                id="email"
                label="Email"
                type="email"
                value={values.email}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 80 }}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <TextField
                disabled
                error={Boolean(touched.mobile && errors.mobile)}
                fullWidth
                helperText={touched.mobile && errors.mobile}
                label="Mobile"
                name="mobile"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mobile}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                id="whatsapp"
                label="Whats App No."
                value={values.whatsapp}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.whatsapp && errors.whatsapp)}
                helperText={touched.whatsapp && errors.whatsapp}
              />
            </Grid>

            <Grid item xs={12} lg={8} md={8}>
              <TextField
                error={Boolean(touched.company && errors.company)}
                fullWidth
                disabled
                helperText={touched.company && errors.company}
                inputProps={{ maxLength: 80 }}
                label="Company"
                name="company"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={4} md={4}>
              <TextField
                error={Boolean(touched.roleInCompany && errors.roleInCompany)}
                fullWidth
                helperText={touched.roleInCompany && errors.roleInCompany}
                inputProps={{ maxLength: 80 }}
                label="Designation"
                name="roleInCompany"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.roleInCompany}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                required
                multiline
                rows={5}
                id="personaldetails"
                label="Personal Information in Details"
                value={values.personaldetails}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(
                  touched.personaldetails && errors.personaldetails
                )}
                helperText={touched.personaldetails && errors.personaldetails}
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid item alignItems={"center"} justifyContent={"center"}>
            <Link to="/user-dashboard" style={{textDecoration:'none'}}>
              <Button color="secondary" variant="outlined" >
                Cancel
              </Button>
              </Link>
              <Button
                disabled={isSubmitting}
                // color="secondary"
                sx={{
                  ml: 1,
                  backgroundColor: '#FD6600',
                  '&:hover': {
                  backgroundColor: '#FD6600',
                  boxShadow:  '0px 0px 10px 0px rgba(0,0,0,0.7)',
                  transition: "background-color 0.3s, box-shadow 0.3s",
                 },
                }}
                variant="contained"
                type="submit"
                size="large"
                // sx={{ ml: 1 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

ProfileForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  profileData: PropTypes.object,
};

ProfileForm.default = {
  onSubmitSuccess: () => {},
};

export default ProfileForm;
