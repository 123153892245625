import { Card, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DEFAULT_IMAGES } from 'config'
import { API_BASE_URL } from 'config'
import React from 'react'

function MobileAwardsCertification({ data }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", maxWidth: "100%" }}>
    <Card
      sx={{
        border: "1px solid rgba(217, 217, 217, 1)",
        width: "202px",
        height: "202px",
      }}
    >
      <Box
        component="img"
        alt="phone"
        src={
          data?.imgUrl
            ? `${BASE_URL}/${data?.imgUrl}`
            : DEFAULT_IMAGES.SERVICE
        }
        sx={{ width: "202px", height: "202px" }}
      />
    </Card>
    <Typography
      sx={{ textAlign: "center", marginTop: "10px", fontWeight: 600 }}
    >
      {data?.title}
    </Typography>
  </Grid>
}

export default MobileAwardsCertification