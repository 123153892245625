import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { useState } from 'react';

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        // borderBottomWidth: 1,
        alignItems: 'center',
        height: 10,
        fontSize: 12,
        // fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});

const items = {
    "amount": "4500",
    "IGST": "0",
    "CGST": "405",
    "SGST": "405",
}

const InvoiceTableFooter = () => {


    return (
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            {/* <Text style={styles.c}>{Number.parseFloat(c).toFixed(2)}</Text> */}
        </View>
    )
};

export default InvoiceTableFooter;