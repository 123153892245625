import StaticPopup from "components/StaticPopup";
import { Modal } from "flowbite-react";
import React, { useState } from "react";

const ServiceOffered = ({ values, title }) => {
  const [openModal, setOpenModal] = useState(false);
  const [namesByLetter, setNamesByLetter] = useState({});
  const data = values || ["--"];

  const handleClickOpen = () => {
    setOpenModal(true);
    // Object to store names by first letter
    const namesByLetterObj = {};

    // Group names by first letter
    data?.forEach((name) => {
      const firstLetter = name.charAt(0).toUpperCase();

      if (namesByLetterObj[firstLetter]) {
        namesByLetterObj[firstLetter].push(name);
      } else {
        namesByLetterObj[firstLetter] = [name];
      }
    });
    setNamesByLetter(namesByLetterObj);
  };
  const handleClose = () => {
    setOpenModal(false);
    setNamesByLetter({});
  };
  // console.log("this data 1st", data[0]);
  // console.log("this values 1st", values);
  return (
    <div>
      {data?.length > 1 ? (
        <button onClick={handleClickOpen}>
          <span class="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
            {data[0]}
          </span>
          <span className="underline text-xs text-gray-500 cursor-pointer ml-1">{`+${
            data.length - 1
          } more`}</span>
        </button>
      ) : (
        <p>
          {data[0] !== "--" ? (
            <span class="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
              {data[0]}
            </span>
          ) : (
            <span class="text-sm font-medium pr-2.5 py-0.5">{data[0]}</span>
          )}
        </p>
      )}
      <StaticPopup
        openModal={openModal}
        handleClose={handleClose}
        header={"Services Offered"}
        headerSub={"arranged alphabetically"}
      >
        {Object.entries(namesByLetter)
          .sort()
          .map(([letter, names]) => (
            <div>
              <h3 className="font-bold text-gray-300 text-2xl mb-1">
                {letter}
              </h3>
              <div className="flex flex-wrap gap-3">
                {names.map((name) => (
                  <span class="bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
                    {name}
                  </span>
                ))}
              </div>
            </div>
          ))}
      </StaticPopup>
    </div>
  );
};

export default ServiceOffered;
