import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "utils/axios";
import Skeleton from "@mui/material/Skeleton"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getVendorQuotation, submitLabourQuotation } from "actions/vendorQuotationAction";
import { useSnackbar } from 'notistack';
import CustomGstDropdown from "./CustomGstDropdown";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StaticPopup from "components/StaticPopup";
import FileUpload from "./FileUpload";
import { vendorQuotationService } from "services/vendorQuotationService";

const LabourQuotationForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // RFQ data from location
    // const rfqData = location.state?.rfqData;
    const { loading, error, errorMessage, quotation } = useSelector((state) => state.vendorQuotation);
    const { enqueueSnackbar } = useSnackbar();

    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;
    const [rfqData, setRfqData] = useState(location.state?.rfqData || {});
    const isEdit = location.state?.isEdit;
    const supplierId = location.state?.supplierId || "";


    useEffect(() => {
        const fetchVendorQuotation = async () => {
            if (supplierId) {
                try {
                    const payload = { rfqId: rfqData?.rfqId, supplierId };
                    const response = await vendorQuotationService.getVendorQuotation(payload);

                    // Update rfqData.suppliers[0] with fetched vendorQuotations
                    if (response?.vendorQuotations) {
                        setRfqData(prevData => ({
                            ...prevData,
                            suppliers: prevData.suppliers?.map((supplier, index) =>
                                index === 0 ? { ...supplier, vendorQuotations: response.vendorQuotations } : supplier
                            ) || []
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching vendor quotation:", error);
                }
            }
        };

        fetchVendorQuotation();
    }, [supplierId, isEdit, rfqData?.rfqId]);

    useEffect(() => {
        if (rfqData?.suppliers?.[0]?.vendorQuotations) {
            setTotalProposal(rfqData.suppliers[0].vendorQuotations.grandTotalWithGst || 0);
            setIsCounterTerm(!rfqData.suppliers[0].vendorQuotations.quotationSets?.slice(-1)[0]?.agreedToPaymentTerms || false);
            setSelectedFilesBase(rfqData.suppliers[0].vendorQuotations.quotationSets?.slice(-1)[0]?.attachments || []);
        }
    }, [rfqData]);

    const formInitialValues = useMemo(() => ({

        labourItems: (rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.labourItems?.map((item, index) => ({
            skill: item.skill || rfqData?.labourDetails[index]?.skill || "",
            quantity: item.quantity || rfqData?.labourDetails[index]?.quantity || "",
            buyerRemarks: rfqData?.labourDetails[index]?.remark || "",
            remarks: item.remarks || "",
            labourRate: item.labourRate || "",
            gst: item.gst || 0,
            workStartDate: item.workStartDate ? new Date(item.workStartDate).toISOString().split('T')[0] : "",
            attachments: item.attachments || [],
            totalAmount: item.totalAmount || 0
        })) || rfqData?.labourDetails?.map(detail => ({
            // Default values when no quotation data is available
            skill: detail.skill || "",
            quantity: detail.quantity || "",
            buyerRemarks: detail.remark || "",
            remarks: "",
            labourRate: "",
            gst: 0,
            workStartDate: rfqData?.providingDate ? new Date(rfqData?.providingDate).toISOString().split('T')[0] : "",
            attachments: [],
            totalAmount: 0
        }))),
        agreedToPaymentTerms: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.agreedToPaymentTerms,
        counterPaymentTerms: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.counterPaymentTerms || "",
        notes: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.notes || "",
        rfqId: rfqData?.rfqId,
        rfqType: rfqData?.rfqType,
        supplierId: rfqData?.suppliers?.[0]?.supplierId,
        submittedBy: userProfile?.name,
        userId: userProfile?.userId,

        miscellaneousCosts: {
            mathadeCharges: {
                mrp: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.otherCharges?.mathadeCharges?.mrp || 0,
                gst: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.otherCharges?.mathadeCharges?.gst || 0,
                totalCost: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.otherCharges?.mathadeCharges?.totalCost || 0
            },
            miscCharges: {
                mrp: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.otherCharges?.miscCharges?.mrp || 0,
                gst: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.otherCharges?.miscCharges?.gst || 0,
                totalCost: rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.otherCharges?.miscCharges?.totalCost || 0
            }
        }


    }), [rfqData]);

    // State management
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [totalProposal, setTotalProposal] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.grandTotalWithGst || 0);
    const [isCounterTerm, setIsCounterTerm] = useState(!rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.agreedToPaymentTerms || false);
    const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
    const [selectedFilesBase, setSelectedFilesBase] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.attachments || []);
    const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
    const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState('');
    const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
    const [openAccordian, setOpenAccordian] = useState(null); // State to manage open accordion
    const [labourFilesBase, setLabourFilesBase] = useState([]);
    const [labourFilesSkeleton, setLabourFilesSkeleton] = useState([]);
    const [labourFilesTotalSize, setLabourFilesTotalSize] = useState([]);
    const [labourUploadsErrors, setLabourUploadsErrors] = useState("");
    const [openLabourFileSizeError, setOpenLabourFileSizeError] = useState(false);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [selectedLabourIndex, setSelectedLabourIndex] = useState(null);


    const [miscellaneousCosts, setMiscellaneousCosts] = useState({
        mathadeCharges: { mrp: 0, gst: 0, totalCost: 0 },
        miscCharges: { mrp: 0, gst: 0, totalCost: 0 }
    });

    const miscellaneousItems = [
        { label: "Mathade Charges", name: "mathadeCharges" },
        { label: "Misc. Charges", name: "miscCharges" },
    ];

    const { otherCharges } = rfqData || {};

    const visibleMiscellaneousItems = miscellaneousItems.filter(item => otherCharges?.[item.name]);

    // Check if any items are visible
    const hasVisibleMiscellaneousItems = visibleMiscellaneousItems.length > 0;

    const attachmentsData = rfqData?.selectedFilesBase
        ? rfqData.selectedFilesBase.map((item) => {
            const lastIndex = item.Name.lastIndexOf('.'); // Extract file extension
            const path = lastIndex !== -1 ? item.Name.substring(lastIndex + 1) : null;
            return { ...item, Path: path }; // Add the Path to determine file type
        })
        : [];



    // Mock data for rows
    const rows = [
        { name: 'Shuttering', value: '60', price: 'Rs. 0', remarks: "1 remark" },
        { name: 'Fitters', value: '60', price: 'Rs. 0', remarks: "2 remark" },
        // Add more rows as needed
    ];

    const attachedDocument = [
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
    ];

    const toggleAccordion = (index) => {
        setOpenAccordian(openAccordian === index ? null : index); // Toggle the open state
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };


    // Yup validation schema
    const miscellaneousSchema = Yup.object().shape({
        mrp: Yup.number()
            .typeError("Enter a valid MRP")
            .required("MRP is required")
            .min(0, "Invalid MRP"),
        gst: Yup.number()
            .typeError("Enter a valid GST")
            .required("GST is required")
            .min(0, "Invalid GST")
    });

    const validationSchema = Yup.object().shape({
        labourItems: Yup.array().of(
            Yup.object().shape({
                labourRate: Yup.number().typeError('Rate should be number').required('Rate is required').min(0, 'Invalid rate'),
                gst: Yup.number().typeError('Enter a valid GST').required('GST is required').min(0, 'Invalid GST'),
            })
        ),

        miscellaneousCosts: Yup.object().shape({
            // transport: miscellaneousSchema,
            // loading: miscellaneousSchema,
            mathadeCharges: miscellaneousSchema,
            miscCharges: miscellaneousSchema,
        }),
    });

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes


    // Utility function to validate file size
    const isFileSizeValid = (files, existingSize) => {
        const sizeOfSelectedFiles = Array.from(files).reduce((acc, file) => acc + file.size, 0);
        const totalSize = sizeOfSelectedFiles + existingSize;
        return totalSize < MAX_FILE_SIZE;
    };

    // Utility function to read files and convert them to base64
    const readAndConvertFiles = (files) => {
        return Array.from(files).map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const fileContent = e.target.result;
                    const encryptedContent = btoa(fileContent); // Encrypt in Base64
                    let contentType = file.type;
                    if (!contentType && file.name.endsWith(".dwg")) {
                        contentType = "application/acad"; // Handle .dwg files
                    }
                    resolve({
                        Name: file.name,
                        Content: encryptedContent,
                        ContentType: contentType,
                        FileSize: file.size,
                    });
                };
                reader.readAsBinaryString(file);
            });
        });
    };

    // Handle file upload click for clearing input on click
    const handleLabourFileUploadClick = (event) => {
        event.target.value = ""; // Clear file input on click
    };

    // Handle closing the file size error
    const handleCloseLabourFileSizeError = () => {
        setOpenLabourFileSizeError(false);
    };

    const handleLabourFileUpload = (event, labourIndex, setFieldValue, values) => {
        const files = event.target.files;
        const totalExistingSize = labourFilesTotalSize.reduce((acc, file) => acc + file.FileSize, 0);

        if (isFileSizeValid(files, totalExistingSize)) {
            setLabourFilesSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

            const convertedFilesPromises = readAndConvertFiles(files);

            Promise.all(convertedFilesPromises)
                .then(async (convertedFiles) => {
                    try {
                        const response = await axios.post("/api/rfqs/upload/attachments", convertedFiles);

                        const currentAttachments = values.labourItems[labourIndex]?.attachments || [];
                        const updatedAttachments = [...currentAttachments, ...response.data];

                        setFieldValue(`labourItems[${labourIndex}].attachments`, updatedAttachments);
                        setLabourFilesSkeleton([]);
                    } catch (error) {
                        setLabourFilesSkeleton([]);
                        setLabourUploadsErrors(error.message);
                        setTimeout(() => setLabourUploadsErrors(""), 5000);
                    }
                })
                .catch((error) => {
                    console.error("Error reading files", error);
                });
        } else {
            setOpenLabourFileSizeError(true);
            setTimeout(() => setOpenLabourFileSizeError(false), 6000);
        }
    };

    const handleDeleteLabourFile = (name, event, setFieldValue, selectedLabourIndex, values) => {
        event.preventDefault();

        // Update local state to remove the file from the labourFilesBase array
        setLabourFilesBase((prevVal) => prevVal.filter((file) => file.Name !== name));
        setLabourFilesTotalSize((prevVal) => prevVal.filter((file) => file.Name !== name));

        // Update Formik state to remove the file from the specific labour item
        setFieldValue(
            `labourItems[${selectedLabourIndex}].attachments`,
            values.labourItems[selectedLabourIndex].attachments.filter((file) => file.Name !== name)
        );
    };



    // Event handlers
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleCloseForm = () => {
        if (supplierId) {
            navigate('/submitted-quotation')
        } else {
            navigate(-1)
        }
    }


    // Helper functions
    const calculateTotalAmount = (index, rate, gst, labourItems) => {
        const strength = parseFloat(labourItems[index].quantity);
        const ratePerLabour = rate ? parseFloat(rate) : 0;
        const gstPercent = gst ? parseFloat(gst) : 0;
        return strength * ratePerLabour * (1 + gstPercent / 100);
    };

    const calculateOverallTotal = (labourItems, miscCosts) => {
        const lineItemsTotal = labourItems.reduce((sum, item) => sum + (item.totalAmount || 0), 0);
        const miscCostsTotal = Object.values(miscCosts).reduce((sum, item) => sum + (item.totalCost || 0), 0);
        return lineItemsTotal + miscCostsTotal;
    };

    const handleLabourRateChange = (index, value, setFieldValue, labourItems) => {
        setFieldValue(`labourItems[${index}].labourRate`, value);
        const totalAmount = calculateTotalAmount(index, value, labourItems[index].gst, labourItems);
        setFieldValue(`labourItems[${index}].totalAmount`, totalAmount);

        const updatedLineItems = [...labourItems];
        updatedLineItems[index].totalAmount = totalAmount;
        const newTotalProposal = calculateOverallTotal(updatedLineItems, miscellaneousCosts);
        setTotalProposal(newTotalProposal);
    };

    const handleRemarkChange = (index, value, setFieldValue) => {
        setFieldValue(`labourItems[${index}].remarks`, value);
    };

    const handleGstChange = (index, value, setFieldValue, labourItems) => {
        setFieldValue(`labourItems[${index}].gst`, value);
        const totalAmount = calculateTotalAmount(index, labourItems[index].labourRate, value, labourItems);
        setFieldValue(`labourItems[${index}].totalAmount`, totalAmount);

        const updatedLineItems = [...labourItems];
        updatedLineItems[index].totalAmount = totalAmount;
        const newTotalProposal = calculateOverallTotal(updatedLineItems, miscellaneousCosts);
        setTotalProposal(newTotalProposal);
    };

    const calculateTransportationTotalCost = (mrp, gst) => {
        const gstValue = parseFloat(gst) || 0;
        const mrpValue = parseFloat(mrp) || 0;
        return mrpValue + (mrpValue * gstValue) / 100;
    };

    const handleMiscellaneousChange = (field, value, setFieldValue, values, type) => {
        const parsedValue = value === "" ? "" : parseFloat(value) || 0;
        const updatedMiscellaneousCosts = { ...values.miscellaneousCosts };

        updatedMiscellaneousCosts[field][type] = parsedValue;

        if (updatedMiscellaneousCosts[field].mrp !== "" && updatedMiscellaneousCosts[field].gst !== "") {
            updatedMiscellaneousCosts[field].totalCost = calculateTransportationTotalCost(
                updatedMiscellaneousCosts[field].mrp,
                updatedMiscellaneousCosts[field].gst
            );
        } else {
            updatedMiscellaneousCosts[field].totalCost = 0;
        }

        setFieldValue(`miscellaneousCosts.${field}.${type}`, parsedValue);
        setMiscellaneousCosts(updatedMiscellaneousCosts);

        const newTotalProposal = calculateOverallTotal(values.labourItems, updatedMiscellaneousCosts);
        setTotalProposal(newTotalProposal);
    };

    const handleMiscGstChange = (field, value, setFieldValue, values) => {
        handleMiscellaneousChange(field, value, setFieldValue, values, 'gst');
    };


    const handleFileUploadClick = (event) => {
        event.target.value = '';
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => acc + obj.size, 0);
        const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce((acc, obj) => acc + obj.FileSize, sizeOfSelectedFiles);

        if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
            setAttachmentsSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

            const convertedFilesPromises = Array.from(files).map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const fileContent = e.target.result;
                        const encryptedContent = btoa(fileContent); // Encrypt file content in Base64
                        let contentType = file.type;
                        if (!contentType && file.name.endsWith('.dwg')) {
                            contentType = 'application/acad'; // MIME type for DWG files
                        }
                        const payload = {
                            Name: file.name,
                            Content: encryptedContent,
                            ContentType: contentType,
                            FileSize: file.size,
                        };
                        resolve(payload);
                    };
                    reader.readAsBinaryString(file);
                });
            });

            Promise.all(convertedFilesPromises)
                .then((convertedFiles) => attachmentBlobLink(convertedFiles))
                .catch((error) => console.error(error));
        } else {
            setOpenFilesSizeError(true);
            setTimeout(() => setOpenFilesSizeError(false), 6000);
        }
    };

    const handleDeleteSelectedFile = (name, event) => {
        event.preventDefault();
        setSelectedFilesBase((prevVal) => prevVal.filter((data) => data.Name !== name));
        setSelectedFilesTotalSize((prevVal) => prevVal.filter((data) => data.Name !== name));
    };

    const handleCloseFileSizeError = () => {
        setOpenFilesSizeError(false);
    };

    const attachmentBlobLink = (convertedFiles) => {
        axios.post('/api/rfqs/upload/attachments', convertedFiles)
            .then((response) => {
                setSelectedFilesBase((prevVal) => [...prevVal, ...response.data]);
                setSelectedFilesTotalSize((prevVal) => [...prevVal, ...convertedFiles]);
                setAttachmentsSkeleton([]);
            })
            .catch((error) => {
                setAttachmentsSkeleton([]);
                setAttachmentsUploadsErrors(error.message);
                setTimeout(() => setAttachmentsUploadsErrors(''), 5000);
                console.error(error);
            });
    };

    useEffect(() => {
        if (!loading && quotation && Object.keys(quotation).length > 0) {
            // Show success snackbar when quotation is successfully submitted
            enqueueSnackbar('Vendor quotation submitted successfully!', {
                variant: 'success',
                autoHideDuration: 1000,
            });
        }

        if (!loading && error) {
            // Show error snackbar when there's an error
            enqueueSnackbar(`Error: ${errorMessage}`, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    }, [loading, quotation, error, errorMessage, enqueueSnackbar]);

    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        "rfq info",
        "Input Fields",
        "Miscellaneous",
        "Supporting Documents",
        "Additional Information & Upload",
        "Completion"
    ];

    const handleNextStep = async (validateForm, setTouched, values) => {
        const errors = await validateForm(); // Trigger Formik validation for current step
        // Find the index of the first material with an error
        const errorIndex = errors.labourItems?.findIndex(error => error != null); // Check for the first non-null error object
        if (errorIndex !== -1) {
            // Set the accordion to open at the first error index
            setOpenAccordian(errorIndex);
            setTouched({
                labourItems: values.labourItems.map((material, index) =>
                    index === errorIndex ? { ...material, ...errors.labourItems[errorIndex] } : material
                ),
            });
        }
        const hasErrors = Object.keys(errors).length > 0; // Check if there are any errors
        if (!hasErrors) {
            // If no errors, move to the next step
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };


    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };


    return (
        <div className="max-w-7xl lg:mx-auto min-h-full bg-gray-100 shadow-md" style={{ fontFamily: "goldman_sans" }}>

            <Formik
                enableReinitialize={true}
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    const payload = { ...values, selectedFilesBase, otherCharges: values.miscellaneousCosts };
                    try {
                        await dispatch(submitLabourQuotation({ values: payload }));
                        if (supplierId) {
                            navigate('/submitted-quotation')
                        } else {
                            navigate(-1)
                        }
                    } catch (error) {
                        console.error("Submission failed:", error);
                    }
                }}
            >
                {({ values, errors, touched, setFieldValue, setTouched, validateForm }) => (
                    <Form onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
                        {/*Mobile View */}
                        <div className="lg:hidden h-screen flex-1 overflow-y-auto bg-white ">
                            {/* Stepper */}
                            {currentStep === 0 ? " " : (
                                <div className="sticky top-0 shadow-md bg-white flex justify-center items-center px-10 py-6 w-full lg:hidden">
                                    {/* Step 1 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 0 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 0 ? 'text-white' : '#313119'}`}>1</p>
                                    </div>
                                    <div className={`flex-grow h-[2px] ${currentStep > 1 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                                    {/* Step 2 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 1 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 1 ? 'text-white' : '#313119'}`}>2</p>
                                    </div>
                                    <div className={`flex-grow h-[2px] ${currentStep > 2 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                                    {/* Step 3 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 2 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 2 ? 'text-white' : '#313119'}`}>3</p>
                                    </div>
                                    <div className={`flex-grow h-[2px] ${currentStep > 3 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                                    {/* Step 4 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 3 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 3 ? 'text-white' : '#313119'}`}>4</p>
                                    </div>
                                </div>
                            )}

                            {/* stepper content */}
                            <div className="h-auto flex-grow overflow-y-auto">
                                <div className="flex justify-between">
                                    {steps.map((step, index) => (
                                        <div key={index} className={`step ${currentStep === index ? 'active' : ''}`}>
                                            {/* {step} */}
                                        </div>
                                    ))}
                                </div>

                                <div className="step-content min-h-full">
                                    {currentStep === 0 && (
                                        <div>
                                            {/* rfq information */}
                                            <div className="px-4 lg:py-[24px] lg:px-8">
                                                <div className=" mt-4 mb-1 lg:hidden flex justify-between space-x-10">
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B]"><span className="text-[#787878]">Requirement Name:</span> {rfqData?.name}</h3>
                                                </div>
                                                <p className="text-sm font-normal text-[#787878] lg:hidden">Create Date: {new Date(rfqData?.createdAt).toLocaleDateString('en-GB')}</p>

                                                <p className="text-sm font-medium text-[#787878] mb-2 mt-6 lg:hidden">RFQ Information</p>
                                                <div className="border sm:grid sm:grid-cols-2 space-y-4 sm:space-y-0 shadow-lg bg-white p-5 lg:py-[28px] lg:px-[20px]">
                                                    <div className="space-y-4">
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Status</p>
                                                            <div className="flex items-center space-x-2">
                                                                <div className="w-[15px] h-[15px] rounded-full bg-[#BFFFBA]"></div>
                                                                <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.status}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">RFQ Name</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.name}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">RFQ Type</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.rfqType}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Client</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.entityName}</p>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-4">
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Expected Delivery Date</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.providingDate ? new Date(rfqData?.providingDate).toLocaleDateString('en-GB') : "-"}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Project Name</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.projectName}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Delivery Location</p>
                                                            <div className="flex items-center gap-2">
                                                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/map_pin_vendor_quotation.svg" alt="" className="mb-1" />
                                                                <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.location}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal  ">Contact</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold ">{rfqData?.requesterName} ({rfqData?.requesterContact})</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="px-4 py-6">
                                        {currentStep === 1 && (
                                            <div>
                                                {/* input fields */}
                                                <div>
                                                    {/* Labour */}
                                                    <div className="lg:flex items-center my-[30px] hidden">
                                                        <h3 className="whitespace-nowrap text-[#434343] uppercase text-base font-medium">Labour</h3>
                                                        <hr className="flex-grow border-t border-gray-300 ml-4" />
                                                    </div>
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Labour Requirements</h3>
                                                    <p className="text-xs font-normal text-[#9D9D9D] lg:hidden">Add quotations for the labour requirements below</p>

                                                    <div className="space-y-2 mt-4 mb-8">
                                                        {rfqData?.labourDetails?.map((row, index) => (
                                                            <div key={index}>
                                                                {/* Mobile Accordion Header */}
                                                                <div className={`block lg:hidden border shadow-md p-4 cursor-pointer ${openAccordian === index ? 'shadow-none' : ''}`} onClick={() => toggleAccordion(index)}>
                                                                    <div className="flex justify-between items-center">
                                                                        <div className="flex justify-start items-center space-x-4">
                                                                            <div className="w-[24px] h-[24px] bg-[#EFEFEB] rounded-full flex justify-center items-center">
                                                                                <p className="text-xs text-[#6A6A6A]">{index + 1}</p>
                                                                            </div>
                                                                            <h2 className="text-sm font-semibold">{row?.skill}</h2>
                                                                        </div>
                                                                        <div className="flex justify-end space-x-4">
                                                                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/tick_vendor_quotation.svg" alt="tick" />
                                                                            <img
                                                                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/dropdown_arrow.svg"
                                                                                alt=""
                                                                                className={`ml-2 transition-transform ${openAccordian === index ? 'rotate-180' : ''}`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Accordion Content */}
                                                                {openAccordian === index && (
                                                                    <div className="space-y-6 text-sm px-4 py-4 border  border-t-transparent shadow-md">
                                                                        <div>
                                                                            <label className="font-semibold text-sm col-span-2 flex items-center relative">
                                                                                Strength
                                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                                    i
                                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                                    </span>
                                                                                </span>
                                                                            </label>
                                                                            <p className=" border-none text-sm placeholder-[#373737]">
                                                                                {row?.quantity}
                                                                            </p>
                                                                        </div>
                                                                        {row?.remark?.length > 0 && <div>
                                                                            <p className="text-sm font-semibold mb-2"> Remarks</p>
                                                                            <div className="bg-[#F6F6F4] max-h-[100px] overflow-y-auto col-span-2 py-2 px-4 w-full " >
                                                                                <p className="w-full border-none col-span-3 p-0 bg-[#F6F6F4] text-sm" dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        row?.remark?.length > 100
                                                                                            ? row?.remark.slice(0, 100) + "..."
                                                                                            : row?.remark,
                                                                                }}>
                                                                                </p>
                                                                                {row?.remark?.length > 100 && <button
                                                                                    onClick={togglePopup}
                                                                                    type="button"
                                                                                    className="text-blue-600 text-xs underline pt-2"
                                                                                >
                                                                                    Read More
                                                                                </button>}

                                                                                {/* Popup Modal */}
                                                                                {isPopupOpen && (
                                                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                                                        <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full max-h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                                                            <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                                      scrollbar-width: none; /* Firefox */
                                                                                       }
                                                                                     .hide-scrollbar::-webkit-scrollbar {
                                                                                    display: none; /* Safari and Chrome */
                                                                                   }
                                                                               `}
                                                                                            </style>
                                                                                            <div className="sticky top-0 bg-white pb-4">
                                                                                                {/* Close Button */}
                                                                                                <button
                                                                                                    onClick={togglePopup}
                                                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                                                >
                                                                                                    <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                                                    </svg>
                                                                                                </button>
                                                                                                <h2 className="text-sm font-semibold mb-2"> Remarks</h2>
                                                                                                <hr />
                                                                                            </div>
                                                                                            <p className="text-sm text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{
                                                                                                __html: row?.remark
                                                                                            }}>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>}
                                                                        {/* rate per labourer per day */}
                                                                        <div>
                                                                            <label className="font-semibold text-sm pb-2 col-span-2 flex items-center relative">
                                                                                Rate per labourer per day
                                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                                    i
                                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                                    </span>
                                                                                </span>
                                                                            </label>
                                                                            <Field
                                                                                name={`labourItems[${index}].labourRate`}
                                                                                type="text"
                                                                                className=" h-[40px] border-[#DCDCD0] border px-2 py-1"
                                                                                onChange={e => handleLabourRateChange(index, e.target.value, setFieldValue, values.labourItems)}
                                                                            />
                                                                            <ErrorMessage name={`labourItems[${index}].labourRate`} component="div" className="text-red-500 text-sm" />
                                                                        </div>
                                                                        {/* gst */}
                                                                        <div>
                                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">GST (%)</label>
                                                                            <CustomGstDropdown
                                                                                options={[0, 5, 12, 18, 28]}
                                                                                // Display the current value
                                                                                value={values.labourItems[index]?.gst || ''}
                                                                                onChange={value => handleGstChange(index, value, setFieldValue, values.labourItems)}
                                                                                className="h-[40px] w-1/3 justify-between"
                                                                            />
                                                                            {/* <ErrorMessage name={`labourItems[${index}].gst`} component="div" className="text-red-500 text-sm h-fit" /> */}
                                                                        </div>

                                                                        {/* work start date */}
                                                                        <div className="self-end ">
                                                                            <label className="font-semibold text-sm pb-2 col-span-2 flex items-center relative">
                                                                                Work Start Date
                                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                                    i
                                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                                    </span>
                                                                                </span>
                                                                            </label>
                                                                            {/* <div className="col-span-2 justify-self-center"> */}
                                                                            <Field
                                                                                type="date"
                                                                                name={`labourItems[${index}].workStartDate`}
                                                                                className="p-2 h-[40px] text-xs border border-gray-300 placeholder-[#373737]"
                                                                            />
                                                                            <ErrorMessage name={`labourItems[${index}].workStartDate`} component="div" className="text-red-500 text-xs absolute" />
                                                                            {/* </div> */}
                                                                            {/* <ErrorMessage name="workStartDate" component="div" className="text-red-500 text-sm mt-1" /> */}
                                                                        </div>
                                                                        <div className=" flex justify-start space-x-4 items-center text-center col-span-1 mt-2">
                                                                            <p className="text-sm pb-2 font-semibold ">Add Attachment</p>
                                                                            <div className="relative">
                                                                                <AttachFileIcon
                                                                                    className="hover:cursor-pointer"
                                                                                    sx={{
                                                                                        width: "0.7em", ml: "2px", mb: "8px",
                                                                                        transform: 'rotate(45deg)',
                                                                                        transition: 'transform 0.3s ease'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setSelectedLabourIndex(index); // Set the labor index
                                                                                        setOpenUploadFile(true); // Open the file upload popup
                                                                                    }}
                                                                                />

                                                                                {values.labourItems[index]?.attachments?.length > 0 && (
                                                                                    <span
                                                                                        className="absolute top-0 right-2 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
                                                                                        style={{ transform: 'translate(50%, -50%)' }}
                                                                                    >
                                                                                        {values.labourItems[index].attachments.length}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Remarks</label>
                                                                            <Field
                                                                                name={`labourItems[${index}].remarks`}
                                                                                type="text"
                                                                                className="w-full h-[40px] text-xs border-[#DCDCD0] border px-2 py-1"
                                                                                placeholder=""
                                                                                onChange={e => handleRemarkChange(index, e.target.value, setFieldValue)}
                                                                            />
                                                                            <ErrorMessage name={`labourItems[${index}].remarks`} component="div" className="text-red-500 text-xs absolute" />
                                                                        </div>
                                                                        {/* amount */}
                                                                        <div className="flex justify-between lg:justify-end items-center p-4 bg-[#E7EAEF] border border-t-[#004491] lg:border-none lg:bg-white">
                                                                            <p className="text-[#004491] text-sm font-semibold lg:hidden">Amount</p>
                                                                            <p className="text-[#004491] font-semibold text-sm">
                                                                                Rs. {values.labourItems[index].totalAmount ? values.labourItems[index].totalAmount.toFixed(2) : '0.00'}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {currentStep === 2 && (
                                            <div>
                                                {/* miscellaneous */}
                                                <div>
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Other Charges</h3>
                                                    <p className="text-xs font-normal text-[#9D9D9D] pb-4 lg:hidden">Please indicate any Other charges that will be incurred</p>

                                                    <div className="border lg:border-none shadow-md px-3 pb-3 pt-4 space-y-4 lg:space-y-2">
                                                        {hasVisibleMiscellaneousItems ? (
                                                            visibleMiscellaneousItems.map((item, index) => (
                                                                <div key={index} className="lg:grid lg:grid-cols-6 lg:gap-4 ">
                                                                    <React.Fragment>
                                                                        {/* Mobile view: MRP input field and GST dropdown */}
                                                                        <div className="flex justify-between items-center lg:hidden pb-2">
                                                                            <div>
                                                                                <p className="col-span-3 text-sm pb-1 lg:pb-0">{item.label}</p>
                                                                                <Field
                                                                                    name={`miscellaneousCosts.${item.name}.mrp`}
                                                                                    type="text"
                                                                                    className="h-[40px] text-sm border border-gray-300"
                                                                                    placeholder="Rs."
                                                                                    value={values.miscellaneousCosts[item.name]?.mrp || ''}
                                                                                    onChange={(e) => handleMiscellaneousChange(item.name, e.target.value, setFieldValue, values, 'mrp')}
                                                                                />
                                                                                <ErrorMessage name={`miscellaneousCosts.${item.name}.mrp`} component="div" className="text-red-500" />
                                                                            </div>
                                                                            <div>
                                                                                <p className="text-sm pb-1">GST</p>
                                                                                <CustomGstDropdown
                                                                                    options={[0, 5, 12, 18, 28]}
                                                                                    value={values.miscellaneousCosts[item.name]?.gst}
                                                                                    onChange={(value) => handleMiscGstChange(item.name, value, setFieldValue, values)}
                                                                                    className="col-span-1 h-[40px] w-[64px]"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {/* Subtotal */}
                                                                        <div className="flex justify-between lg:justify-end items-center text-sm py-2 px-4 bg-[#E7EAEF] border border-t-[#004491] lg:border-none lg:bg-white">
                                                                            <p className="text-[#004491] font-semibold lg:hidden">Sub Total</p>
                                                                            <p className="text-[#004491] font-semibold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                                                Rs. {values.miscellaneousCosts[item.name]?.totalCost?.toFixed(2) || '0.00'}
                                                                            </p>
                                                                        </div>
                                                                    </React.Fragment>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            // Message when hasVisibleMiscellaneousItems is false
                                                            <div className="text-center text-sm text-[#9D9D9D] py-4">
                                                                No other charges are being accepted by the buyer. The total quotation amount is:
                                                            </div>
                                                        )}

                                                        {/* Total Amount */}
                                                        <div className="lg:hidden">
                                                            <div className="flex px-4 py-2 text-sm lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                                                <p className="text-[#007366] lg:text-black font-semibold">Total Amount</p>
                                                                <p className="bg-[#E0F0EE] text-[#007366] font-semibold text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                                    {totalProposal.toFixed(2)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        )}
                                        {currentStep === 3 && (
                                            <div>
                                                {/* Supporting Documents Section */}
                                                <div className="mb-8 lg:mb-[40px]">
                                                    {attachmentsData.length > 0 ? (
                                                        <>
                                                            <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Supporting Documents</h3>
                                                            <p className="text-xs font-normal text-[#9D9D9D] pb-4 lg:hidden">Below are the supporting documents uploaded by the buyer. Please review them.</p>
                                                            <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4">
                                                                {attachmentsData.map((doc, index) => (
                                                                    <div key={index} className="flex justify-between items-center">
                                                                        <div className="flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-2 px-2 space-x-2 text-[#434343]">
                                                                            {/* Dynamic icon based on file type */}
                                                                            <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                                                            {/* Document name clickable */}
                                                                            <a
                                                                                href={doc.Content}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                download={doc.Name}
                                                                                className="text-xs text-[#434343] truncate hover:underline"
                                                                            >
                                                                                {doc.Name}
                                                                            </a>
                                                                        </div>
                                                                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/download_solid.svg" alt="" className="lg:hidden" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    ) :
                                                        <>
                                                            <div className="text-gray-400 italic">No Supporting Documents provided by Buyer, move to next step </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {currentStep === 4 && (
                                            <div >
                                                {/* Additional Information Section */}
                                                <div className="mb-8">

                                                    <h3 className="text-sm mt-4 font-semibold text-[#3B3B3B] lg:hidden">Additional Information</h3>
                                                    <p className="text-sm font-normal lg:mx-0 pb-2 pt-4 lg:hidden">Below are our payment Terms. </p>

                                                    <div className="lg:flex justify-between items-center">
                                                        <div>
                                                            <div className="bg-[#F6F6F4] py-2 px-4 lg:w-[390px] lg:h-[182px] lg:mx-0 " >
                                                                <p
                                                                    className="w-full border-none p-0 bg-[#F6F6F4] text-[#3D3D3D] text-sm"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            rfqData?.paymentTerms.length > 180
                                                                                ? rfqData.paymentTerms.slice(0, 180) + "..."
                                                                                : rfqData.paymentTerms,
                                                                    }}
                                                                ></p>

                                                                {rfqData?.paymentTerms.length > 180 && <button
                                                                    onClick={togglePopup}
                                                                    type="button"
                                                                    className="text-[#8E8E85] text-sm underline pt-2"
                                                                >
                                                                    Read More
                                                                </button>}

                                                                {/* Popup Modal */}
                                                                {isPopupOpen && (
                                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                                        <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full max-h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                                            <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                      scrollbar-width: none; /* Firefox */
                                                                         }
                                                                      .hide-scrollbar::-webkit-scrollbar {
                                                                       display: none; /* Safari and Chrome */
                                                                       }
                                                                     `}
                                                                            </style>
                                                                            <div className="sticky top-0 bg-white pb-4">
                                                                                {/* Close Button */}
                                                                                <button
                                                                                    onClick={togglePopup}
                                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                                >
                                                                                    <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                                    </svg>
                                                                                </button>
                                                                                <h2 className="text-lg font-semibold">Payment Details</h2>
                                                                                <hr />
                                                                            </div>
                                                                            <p className="text-sm text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="lg:hidden text-sm mx-4 justify-between items-center py-4">
                                                            <p >
                                                                Do you agree ?
                                                            </p>
                                                            <div className="flex space-x-12 mt-2">
                                                                <label className="inline-flex items-center">
                                                                    <input type="radio" name="paymentTerms" className="form-radio" />
                                                                    <span className="ml-2 ">Yes</span>
                                                                </label>
                                                                <label className="inline-flex items-center">
                                                                    <input type="radio" name="paymentTerms" className="form-radio" />
                                                                    <span className="ml-2">Counter Offer</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="font-semibold text-sm text-[#4F4F42]">Counter Payment Terms</p>
                                                            <Field
                                                                as="textarea"
                                                                name="counterPaymentTerms"
                                                                className="lg:w-[390px] w-full text-sm h-[144px] my-2 border border-gray-300 placeholder:italic"
                                                                placeholder="Please write your counter payment terms Rich Text "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" mt-4">
                                                        <p className="text-[#434343] mb-2 lg:mb-0">Notes</p>
                                                        <Field
                                                            as="textarea"
                                                            name="notes"
                                                            placeholder="Add Notes (Optional) "
                                                            className="border-[#CDCDB8] w-full placeholder:text-sm lg:ml-5 lg:w-full h-[125px] placeholder:italic placeholder-[#8C8C8C]"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Upload Supporting Documents */}
                                                <div className="pb-12 ">
                                                    <h3 className="whitespace-nowrap text-[#434343] text-sm font-bold pb-4">Upload Supporting Documents</h3>

                                                    {attachmentsSkeleton.length > 0 && (
                                                        <p className="text-xs pl-3">
                                                            Your file is being scanned for upload, be patient and continue to fill
                                                            the rest of the form.
                                                        </p>
                                                    )}

                                                    <div className="">
                                                        {/* Display the selected file names */}
                                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                                            {selectedFilesBase.map((file, index) => (
                                                                <a
                                                                    href={file.Content}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                                    key={file.Name}
                                                                >
                                                                    <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                                        {file.Name}
                                                                    </span>
                                                                    <span
                                                                        className="ml-1 cursor-pointer"
                                                                        onClick={(event) => handleDeleteSelectedFile(file.Name, event)}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 48 48"
                                                                        >
                                                                            <circle cx="24" cy="24" r="22" fill="gray" />
                                                                            <line
                                                                                x1="15"
                                                                                y1="15"
                                                                                x2="33"
                                                                                y2="33"
                                                                                stroke="white"
                                                                                strokeWidth="2"
                                                                            />
                                                                            <line
                                                                                x1="33"
                                                                                y1="15"
                                                                                x2="15"
                                                                                y2="33"
                                                                                stroke="white"
                                                                                strokeWidth="2"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            ))}

                                                            {/* Skeleton loaders for files being processed */}
                                                            {attachmentsSkeleton.map((_, index) => (
                                                                <Skeleton
                                                                    key={index}
                                                                    variant="rounded"
                                                                    sx={{ borderRadius: "20px", margin: "4px" }}
                                                                    width={150}
                                                                    height={32}
                                                                />
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div>
                                                        {attachmentsUploadsErrors && (
                                                            <div className="mt-3">
                                                                <p className="text-red-500 font-semibold text-sm mt-14">
                                                                    {attachmentsUploadsErrors}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Error handling for file size */}
                                                    <div
                                                        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                                            }`}
                                                    >
                                                        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                                            <p>Total size of attachments is not more than 10 MB</p>
                                                            <button
                                                                onClick={handleCloseFileSizeError}
                                                                className="px-4 py-2 focus:outline-none"
                                                            >
                                                                <svg
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M6 18L18 6M6 6l12 12"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>


                                                    {/* drag and drop file */}
                                                    <div className="mb-10 flex items-center justify-center h-[175px] bg-[#F1F7FF] border-2 border-dashed border-[#004491] relative">
                                                        <div className="lg:hidden flex flex-col items-center justify-center">
                                                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" alt="" className="mx-auto" />
                                                            <p className="text-center text-sm mt-4">Upload your files here</p>
                                                            <label htmlFor="file" className="text-[#1D28FA] text-sm underline text-center cursor-pointer">
                                                                Browse
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                            onClick={handleFileUploadClick}
                                                            onChange={handleFileUpload}
                                                            multiple // Allow multiple files to be selected
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {currentStep === 5 &&
                                            (<div className="text-gray-400 italic">No Supporting Documents provided by Buyer, move to next step </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* desktop content */}
                        <div className="hidden lg:block bg-[#F6F6F4]">
                            <div className="max-w-7xl mx-auto shadow-md" style={{ fontFamily: "goldman_sans" }}>
                                {/* Header Section */}
                                <div className=" lg:grid lg:grid-cols-2 flex items-center flex-col-reverse lg:px-8 lg:py-2 shadow-md sticky top-0 bg-white z-20">
                                    <div >
                                        <div className="lg:flex items-center hidden  ">
                                            {/* <h1 className="text-[28px] text-[#434343] font-semibold ">Add Proposal: Rs. {totalProposal.toFixed(2)}</h1> */}
                                            {/* <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span> */}
                                            <button type="button" onClick={(() => isEdit ? navigate(-1) : (supplierId ? navigate("/leads") : navigate(-1)))} className="text-sm bg-[#E9E9E9] cursor:pointer px-4 py-1 my-1 text-[#434343]">Back</button>
                                        </div>
                                    </div>
                                    {/* <div className="flex justify-center items-center lg:items-start lg:justify-start my-4">
                                        <span class="relative flex items-center justify-center h-9 w-9 ">
                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full h-[18px] w-[18px] bg-lime-500"></span>
                                        </span>

                                        <div className="lg:w-9 lg:h-9 w-6 h-6 mx-3 flex justify-center items-center rounded-full bg-[#DFF1C8] opacity-95">
                                            <div className="lg:w-[18px] lg:h-[18px] w-3 h-3 rounded-full bg-lime-500"></div>
                                        </div>

                                        <h3 className="lg:text-2xl lg:mt-1 text-center text-sm text-[#737373]">Live Rank: <span className="font-bold text-black">L2</span></h3>
                                    </div> */}
                                </div>


                                {/* rfq information */}
                                <div className="px-4 lg:py-[24px] lg:px-8">
                                    <div className="shadow-md bg-white border px-8 lg:py-[20px]">
                                        <h3 className="text-sm font-semibold hidden lg:block pb-2">RFQ Information</h3>
                                        <div className="text-xs flex flex-col-reverse lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-4 bg-white ">
                                            <div className="lg:space-y-5 space-y-4">
                                                <div>
                                                    <p className="text-[#6B7280] font-normal hidden lg:block">RFQ Name</p>
                                                    <p className="text-[#4F4F42] font-bold hidden lg:block">{rfqData?.name}</p>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal  hidden lg:block">Expected Delivery Date</p>
                                                    <p className="text-[#4F4F42] font-bold hidden lg:block">{rfqData?.providingDate ? new Date(rfqData?.providingDate).toLocaleDateString('en-GB') : "-"}</p>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal  ">Client Name</p>
                                                    <p className="text-[#4F4F42] font-bold ">{rfqData?.userBusinessName}</p>
                                                </div>
                                                {/* <div>
                                                    <p className="text-[#6B7280] font-normal">Payment Terms</p>
                                                    <p
                                                        className="text-[#4F4F42] font-bold bg-[#ECECEC] lg:bg-white mt-1 p-4 lg:m-0 lg:p-0"
                                                        dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}
                                                    ></p>

                                                </div> */}
                                            </div>
                                            <div className="lg:space-y-5 space-y-4">
                                                <div>
                                                    <p className="text-[#6B7280] font-normal  hidden lg:block">Requirement Type</p>
                                                    <p className="text-[#4F4F42] font-bold  hidden lg:block">{capitalizeFirstLetter(rfqData?.rfqType)}</p>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal">Project Name</p>
                                                    <p className="text-[#4F4F42] font-bold">{rfqData?.projectName}</p>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal">Delivery Location</p>
                                                    <div className="flex items-center gap-2">
                                                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/map_pin_vendor_quotation.svg" alt="" className="mb-1" />
                                                        <p className="text-[#4F4F42] font-bold">{rfqData?.location}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lg:space-y-5 space-y-4">
                                                <div>
                                                    <p className="text-[#6B7280] font-normal">Status</p>
                                                    <div className="flex items-center space-x-2">
                                                        <div className="w-[15px] h-[15px] rounded-full bg-[#BFFFBA]"></div>
                                                        <p className="text-[#4F4F42] font-bold">{rfqData?.status}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal  ">Contact</p>
                                                    <p className="text-[#4F4F42] font-bold ">{rfqData?.requesterName} ({rfqData?.requesterMobile})</p>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 lg:px-8 lg:space-y-6">
                                    {/* Labour */}
                                    <div>
                                        <div className="flex items-center">
                                            <h3 className="whitespace-nowrap text-[#434343] hidden lg:block uppercase text-sm font-semibold">Labour</h3>
                                            <hr className="flex-grow border-t hidden lg:block border-gray-300 ml-4" />
                                        </div>
                                        <p className="text-xs font-medium hidden lg:block text-[#434343] mt-2 mb-4">Your bid value is the sum of these line items</p>

                                        {/* input fields */}

                                        {/* Line items Section */}
                                        {/* <p className="text-[32px] font-bold text-[rgb(67,67,67)] hidden lg:block">Line Items</p> */}
                                        <div className="mb-5 hidden lg:grid lg:grid-cols-14 items-center lg:gap-4 text-[#777777] text-xs font-normal uppercase border-y py-2">
                                            <label className="block font-medium col-span-2">SKILLS</label>
                                            <label className="block font-medium col-span-1 uppercase">Buyer Req.</label>
                                            <label className="block text-center font-medium col-span-2 uppercase">Buyer Remarks</label>
                                            <label className="block text-center font-medium col-span-2 uppercase">Remarks</label>
                                            <label className="block font-medium text-center col-span-2">RATE PER LABOUR PER DAY</label>
                                            <label className="block font-medium col-span-1 text-center">GST (%)</label>
                                            <label className="block font-medium col-span-2 text-center">WORK START DATE</label>
                                            <label className="block text-center font-medium col-span-1">Total cost</label>
                                            <label className="block text-center font-medium col-span-1">attach.</label>
                                        </div>


                                        <div className="space-y-6 mb-8 text-xs">
                                            {rfqData?.labourDetails?.map((detail, index) => (
                                                <div key={index}>
                                                    <div className="lg:grid lg:grid-cols-14 gap-2 hidden text-[#373737]">
                                                        <p style={{
                                                            overflowX: 'hidden',
                                                            wordWrap: 'break-word',
                                                        }} className="resize-none border-none p-2 col-span-2 placeholder-[#373737] max-h-[120px] overflow-y-auto">
                                                            {detail.skill}
                                                        </p>
                                                        <p className="border-none p-2 col-span-1 max-h-[120px] overflow-y-auto placeholder-[#373737] text-center">
                                                            {detail.quantity}
                                                        </p>
                                                        {/* Remark from labourDetails */}
                                                        <p
                                                            className="border-none p-2 text-xs col-span-2 placeholder-[#373737] max-h-[120px] overflow-y-auto"
                                                            dangerouslySetInnerHTML={{ __html: detail.remark }}
                                                        />

                                                        {/* User-entered remark */}
                                                        <div className="col-span-2 justify-self-center">
                                                            <Field
                                                                name={`labourItems[${index}].remarks`}
                                                                rows={1}
                                                                className="w-full text-xs min-h-[40px] border-[#DCDCD0] border px-2 py-1 resize-none overflow-hidden h-auto"
                                                                placeholder=""
                                                                onChange={(e) => {
                                                                    handleRemarkChange(index, e.target.value, setFieldValue);

                                                                    // Auto-expand textarea height based on content
                                                                    e.target.style.height = "auto"; // Reset the height to auto first
                                                                    e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on content
                                                                }}
                                                            />
                                                            <ErrorMessage name={`labourItems[${index}].remarks`} component="div" className="text-red-500 text-xs absolute" />
                                                        </div>


                                                        {/* Labour rate input */}
                                                        <div className="col-span-2 justify-self-center">
                                                            <Field
                                                                name={`labourItems[${index}].labourRate`}
                                                                type="text"
                                                                className="w-[120px] h-[40px] text-xs border-[#DCDCD0] border px-2 py-1"
                                                                onChange={e => handleLabourRateChange(index, e.target.value, setFieldValue, values.labourItems)}
                                                            />
                                                            <ErrorMessage name={`labourItems[${index}].labourRate`} component="div" className="text-red-500 text-xs absolute" />
                                                        </div>

                                                        {/* GST Dropdown */}
                                                        <div className="col-span-1 flex justify-center h-fit lg:w-full w-[262px]">
                                                            <CustomGstDropdown
                                                                options={[0, 5, 12, 18, 28]}
                                                                value={values.labourItems[index]?.gst || ''}
                                                                onChange={value => handleGstChange(index, value, setFieldValue, values.labourItems)}
                                                                className="lg:w-[64px] w-[262px] justify-between h-[40px]"
                                                            />
                                                        </div>

                                                        {/* Work Start Date */}
                                                        <div className="col-span-2 justify-self-center">
                                                            <Field
                                                                type="date"
                                                                name={`labourItems[${index}].workStartDate`}
                                                                className="p-2 lg:w-full w-[262px] h-[40px] text-xs border border-gray-300 placeholder-[#373737]"
                                                            />
                                                            <ErrorMessage name={`labourItems[${index}].workStartDate`} component="div" className="text-red-500 text-xs absolute" />
                                                        </div>

                                                        {/* Total amount */}
                                                        <div className="col-span-1 justify-self-end">
                                                            <div className="p-2 w-[120px] lg:w-auto h-[44px] mt-2 col-span-1 text-right placeholder-[#373737]">
                                                                {values.labourItems[index].totalAmount ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.labourItems[index].totalAmount.toFixed(0)) : '0.00'}
                                                            </div>
                                                        </div>

                                                        <div className="relative text-center col-span-1 mt-2">
                                                            <AttachFileIcon
                                                                className="hover:cursor-pointer"
                                                                sx={{
                                                                    width: "0.7em", ml: "2px",
                                                                    transform: 'rotate(45deg)',
                                                                    transition: 'transform 0.3s ease'
                                                                }}
                                                                onClick={() => {
                                                                    setSelectedLabourIndex(index); // Set the labor index
                                                                    setOpenUploadFile(true); // Open the file upload popup
                                                                }}
                                                            />

                                                            {values.labourItems[index]?.attachments?.length > 0 && (
                                                                <span
                                                                    className="absolute top-0 right-8 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
                                                                    style={{ transform: 'translate(50%, -50%)' }}
                                                                >
                                                                    {values.labourItems[index].attachments.length}
                                                                </span>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>

                                    {/* miscellaneous */}
                                    <div className="p-8 bg-white shadow-md">
                                        {hasVisibleMiscellaneousItems && (
                                            <div className="pb-6">
                                                <div className="hidden lg:block">
                                                    <h3 className="text-sm font-semibold text-[#434343] hidden lg:block mb-3">Other Charges</h3>

                                                    <div className="text-xs bg-white border py-4 shadow-md lg:border-none lg:mx-0 lg:shadow-none lg:py-0">
                                                        <div className="mb-3 hidden lg:grid lg:grid-cols-6 items-center gap-2 text-[#777777] font-normal uppercase border-y py-2">
                                                            <label className="block font-medium col-span-3">Charges</label>
                                                            <label className="font-medium text-start col-span-1 flex justify-center items-center relative">
                                                                MRP
                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                    i
                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                    </span>
                                                                </span>
                                                            </label>
                                                            <label className="block font-medium text-center col-span-1">GST (%)</label>
                                                            <label className="block font-medium text-right col-span-1">Total Cost</label>
                                                        </div>

                                                        {/* Render visible miscellaneous items */}
                                                        {visibleMiscellaneousItems.map((item) => (
                                                            <div key={item.name} className="lg:grid lg:grid-cols-6 mb-8 lg:mb-0 space-y-3 lg:space-y-2 items-center">
                                                                <label className="block lg:mb-2 col-span-3 font-semibold lg:font-medium ">
                                                                    {item.label}
                                                                </label>

                                                                {/* MRP Input Field */}
                                                                <div className="col-span-1 w-full">
                                                                    <Field
                                                                        name={`miscellaneousCosts.${item.name}.mrp`}
                                                                        type="text"
                                                                        className="w-fit hidden text-right lg:block lg:w-full h-[30px] text-xs placeholder-[#373737] p-2 border border-gray-300"
                                                                        placeholder="Rs."
                                                                        onChange={(e) => handleMiscellaneousChange(item.name, e.target.value, setFieldValue, values, 'mrp')}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`miscellaneousCosts.${item.name}.mrp`}
                                                                        component="div"
                                                                        className="text-red-500 text-xs mt-1"
                                                                    />
                                                                </div>

                                                                {/* GST Dropdown */}
                                                                <div className="col-span-1 lg:flex hidden justify-center h-fit lg:w-full w-[262px]">
                                                                    <CustomGstDropdown
                                                                        options={[0, 5, 12, 18, 28]}
                                                                        value={values.miscellaneousCosts[item.name]?.gst}
                                                                        onChange={(value) => handleMiscGstChange(item.name, value, setFieldValue, values)}
                                                                        className="lg:w-[64px] w-[262px] justify-between h-[30px]"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`miscellaneousCosts.${item.name}.gst`}
                                                                        component="div"
                                                                        className="text-red-500 text-xs mt-1"
                                                                    />
                                                                </div>

                                                                {/* Total Cost */}
                                                                <div className="flex justify-between lg:justify-end items-center border border-t-[#004491] py-3 px-4 bg-[#E7EAEF] lg:border-none lg:bg-white">
                                                                    <p className="text-[#004491] font-bold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                                        Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.miscellaneousCosts[item.name]?.totalCost?.toFixed(2)) || '0.00'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="">
                                            <hr className="hidden lg:block" />
                                            <div className="flex py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                                <p className=" text-[#007366] lg:text-black text-sm">Total Amount</p>
                                                <p className="bg-[#E0F0EE] text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalProposal.toFixed(2))}</p>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Supporting Documents Section */}
                                    {attachmentsData.length > 0 && (
                                        <div className="mb-8 hidden lg:block bg-white p-8 shadow-md">
                                            <>
                                                <h3 className="text-sm font-semibold text-[#434343] hidden lg:block">Supporting Documents</h3>
                                                <p className="text-xs font-normal text-[#9D9D9D] "> <span className="font-bold text-gray-500">Note: </span>  Below are the supporting documents uploaded by the buyer. Please review them.</p>
                                                <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4 mx-auto">
                                                    {attachmentsData.map((doc, index) => (
                                                        <div key={index} className="flex justify-between items-center mx-2">
                                                            <div className="px-2 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-2 text-[#434343]">
                                                                {/* Dynamic icon based on file type */}
                                                                <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                                                {/* Document name now clickable */}
                                                                <a
                                                                    href={doc.Content}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    download={doc.Name}
                                                                    className="text-xs text-[#434343] hover:underline"
                                                                >
                                                                    {doc.Name}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        </div>
                                    )}

                                    {/* Additional Information Section */}
                                    <div className="mb-6 bg-white p-8 shadow-md">
                                        <h3 className="text-sm font-semibold text-[#434343] hidden lg:block">Additional Information</h3>

                                        {rfqData?.acceptCounterTerms && <div className="hidden lg:flex justify-between items-center ">
                                            <p className="mb-2 text-sm ">
                                                Below are our payment terms. Do you agree?
                                            </p>
                                            <div className="flex space-x-8 mb-4">
                                                <label className="inline-flex items-center">
                                                    <Field
                                                        type="radio"
                                                        name="agreedToPaymentTerms"
                                                        value="true"
                                                        checked={values.agreedToPaymentTerms === true} // Controlled check status
                                                        onChange={() => {
                                                            setFieldValue('agreedToPaymentTerms', true);
                                                            setIsCounterTerm(false); // Update counter term
                                                        }}
                                                        className="form-radio"
                                                    />
                                                    <span className="ml-2 text-sm">Yes</span>
                                                </label>

                                                <label className="inline-flex items-center">
                                                    <Field
                                                        type="radio"
                                                        name="agreedToPaymentTerms"
                                                        value="false"
                                                        checked={values.agreedToPaymentTerms === false} // Controlled check status
                                                        onChange={() => {
                                                            setFieldValue('agreedToPaymentTerms', false);
                                                            setIsCounterTerm(true); // Update counter term
                                                        }}
                                                        className="form-radio"
                                                    />
                                                    <span className="ml-2 text-sm">Counter Offer</span>
                                                </label>
                                            </div>
                                            <ErrorMessage name="agreedToPaymentTerms" component="div" className="text-red-500 text-xs " />
                                        </div>}
                                        {rfqData?.acceptCounterTerms && <div className="lg:flex justify-between items-center">
                                            <div>
                                                {/* <p className="font-medium text-sm text-[#4F4F42] pb-2 hidden lg:block">Payment Terms</p> */}

                                                <div className="bg-[#F6F6F4] my-4 p-4 lg:w-[390px] lg:h-[120px] lg:mx-0 " >
                                                    <p className="w-full border-none text-xs p-0 bg-[#F6F6F4] text-black" dangerouslySetInnerHTML={{
                                                        __html: rfqData?.paymentTerms?.length > 180
                                                            ? `${rfqData.paymentTerms.substring(0, 180)}...`
                                                            : rfqData?.paymentTerms
                                                    }}>
                                                    </p>
                                                    {rfqData?.paymentTerms.length > 180 && <button
                                                        onClick={togglePopup}
                                                        type="button"
                                                        className=" text-xs underline pt-2"
                                                    >
                                                        Read More
                                                    </button>}

                                                    {/* Popup Modal */}
                                                    {isPopupOpen && (
                                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                            <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                                <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                           scrollbar-width: none; /* Firefox */}
                                                                           .hide-scrollbar::-webkit-scrollbar {
                                                                            display: none; /* Safari and Chrome */}`}
                                                                </style>
                                                                <div className="sticky top-0 bg-white pb-4">
                                                                    {/* Close Button */}
                                                                    <button
                                                                        onClick={togglePopup}
                                                                        className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                    >
                                                                        <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                        </svg>
                                                                    </button>
                                                                    <h2 className="text-lg font-semibold mb-4">Payment Details</h2>
                                                                    <hr />
                                                                </div>
                                                                <p className="text-sm text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {isCounterTerm && <div className=" mx-4 lg:mx-0">
                                                <p className="font-medium text-sm pb-1 text-[#4F4F42]">Counter Payment Terms</p>
                                                <Field
                                                    as="textarea"
                                                    name="counterPaymentTerms"
                                                    className={`lg:w-[390px] w-full h-[120px] text-xs placeholder:text-xs border border-gray-300 placeholder:italic 
                                                    ${values.agreedToPaymentTerms === true ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white'}`}
                                                    placeholder="Please write your counter payment terms Rich Text"
                                                    disabled={values.agreedToPaymentTerms === true}
                                                />
                                            </div>}
                                        </div>}
                                        <div className=" lg:flex mt-6 mx-4 lg:mx-0">
                                            <p className="text-[#434343] text-sm mb-2 lg:mb-0">Notes</p>
                                            <Field
                                                as="textarea"
                                                name="notes"
                                                placeholder="Add Notes (Optional) "
                                                className="border-[#CDCDB8] w-full text-xs placeholder:text-xs lg:ml-5 lg:w-full h-[120px] placeholder:italic placeholder-[#8C8C8C]"
                                            />
                                        </div>
                                    </div>

                                    {/* Upload Supporting Documents */}
                                    <div className="pb-8">
                                        <div className="pb-8 mx-4 lg:mx-0 shadow-md bg-white p-8">
                                            <h3 className="whitespace-nowrap text-[#434343] text-sm font-bold pb-4 lg:pb-0 lg:mb-[15px]">Upload Supporting Documents</h3>
                                            {attachmentsSkeleton.length > 0 && (
                                                <p className="text-xs pl-3">
                                                    Your file is being scanned for upload, be patient and continue to fill
                                                    the rest of the form.
                                                </p>
                                            )}

                                            <div className="">
                                                {/* Display the selected file names */}
                                                <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {selectedFilesBase.map((file, index) => (
                                                        <a
                                                            href={file.Content}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                            key={file.Name}
                                                        >
                                                            <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                                {file.Name}
                                                            </span>
                                                            <span
                                                                className="ml-1 cursor-pointer"
                                                                onClick={(event) => handleDeleteSelectedFile(file.Name, event)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="18"
                                                                    height="18"
                                                                    viewBox="0 0 48 48"
                                                                >
                                                                    <circle cx="24" cy="24" r="22" fill="gray" />
                                                                    <line
                                                                        x1="15"
                                                                        y1="15"
                                                                        x2="33"
                                                                        y2="33"
                                                                        stroke="white"
                                                                        strokeWidth="2"
                                                                    />
                                                                    <line
                                                                        x1="33"
                                                                        y1="15"
                                                                        x2="15"
                                                                        y2="33"
                                                                        stroke="white"
                                                                        strokeWidth="2"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    ))}

                                                    {/* Skeleton loaders for files being processed */}
                                                    {attachmentsSkeleton.map((_, index) => (
                                                        <Skeleton
                                                            key={index}
                                                            variant="rounded"
                                                            sx={{ borderRadius: "20px", margin: "4px" }}
                                                            width={150}
                                                            height={32}
                                                        />
                                                    ))}
                                                </ul>
                                            </div>

                                            <div>
                                                {attachmentsUploadsErrors && (
                                                    <div className="mt-3">
                                                        <p className="text-red-500 font-semibold text-base mt-14">
                                                            {attachmentsUploadsErrors}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>

                                            {/* Error handling for file size */}
                                            <div
                                                className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                                    }`}
                                            >
                                                <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                                    <p>Total size of attachments is not more than 10 MB</p>
                                                    <button
                                                        onClick={handleCloseFileSizeError}
                                                        className="px-4 py-2 focus:outline-none"
                                                    >
                                                        <svg
                                                            className="h-6 w-6"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M6 18L18 6M6 6l12 12"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>


                                            {/* drag and drop file */}
                                            <div className="lg:ml-14 lg:mr-0 flex items-center justify-center h-[175px] lg:h-[180px] bg-[#F1F7FF] lg:bg-white border-2 border-dashed border-[#004491] lg:border-[#7D7D7D] relative">
                                                <input
                                                    type="file"
                                                    accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                    onClick={handleFileUploadClick}
                                                    onChange={handleFileUpload}
                                                    multiple // Allow multiple files to be selected
                                                />
                                                <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500 pointer-events-none">
                                                    <div className="relative mx-auto hidden lg:block">
                                                        {/* Overlapping rectangles */}
                                                        <div className="absolute top-10 -left-8 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                                        <div className="absolute top-2 left-0 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                                    </div>
                                                    {/* Centered text */}
                                                    <p className="mt-32 text-sm text-center hidden lg:block">Drag & Drop or Click to upload your proposal instead</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* action buttons */}
                        <div className="sticky bottom-0 z-20">
                            {/* Action Buttons for mobile */}
                            <div
                                style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }}
                                className="lg:hidden flex justify-end p-4 space-x-8 bg-white "
                            >
                                {currentStep === 0 ? (
                                    <button
                                        type="button"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        onClick={() => setCurrentStep(currentStep + 1)}
                                    >
                                        Start Quotation
                                    </button>
                                ) : (
                                    currentStep > 0 && (
                                        <button
                                            type="button"
                                            className="underline text-xs"
                                            onClick={handlePreviousStep}
                                        >
                                            Back
                                        </button>
                                    )
                                )}

                                {/* Show "Next" button for all steps except "Additional Information & Upload" and "Completion" */}
                                {currentStep > 0 && currentStep < steps.length - 2 && (
                                    <button
                                        type="button"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        onClick={() => handleNextStep(validateForm, setTouched, values)}
                                    >
                                        Next
                                    </button>
                                )}

                                {/* Show "Submit" button on "Additional Information & Upload" step */}
                                {currentStep === steps.length - 2 && (
                                    <button
                                        type="submit"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        disabled={loading}
                                    >
                                        {loading ? "Submitting..." : "Submit"}
                                    </button>
                                )}

                                {/* No buttons displayed on "Completion" step */}
                            </div>
                            {/* Action Buttons for desktop*/}
                            <div style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }} className="bg-white hidden space-x-4 lg:flex justify-end items-center py-3 lg:px-8 px-4 ">
                                <button type="button" className=" w-[120px] border border-[#1E1E08] px-3 py-2 text-sm text-[#434343] font-bold" onClick={handleCloseForm}>
                                    Cancel
                                </button>
                                <button type="submit" disabled={loading} className="bg-black border border-[#1E1E08] text-white px-3 py-2 text-sm flex justify-center items-center">
                                    {loading ? "Submitting..." : isEdit ? "Update Quotation" : "Submit Quotation"}
                                </button>
                            </div>
                        </div>


                        <StaticPopup
                            openModal={openUploadFile}
                            handleClose={() => setOpenUploadFile(false)}
                            size={"sm"}
                            width={"xl"}
                            bodyStyle="pb-0 pt-0"
                            className="px-0"
                            header={"Upload Supporting Documents"}
                        >
                            <FileUpload
                                handleFileUploadClick={handleLabourFileUploadClick} // Reuse the click handler
                                handleFileUpload={(event) => handleLabourFileUpload(event, selectedLabourIndex, setFieldValue, values)}
                                handleDeleteSelectedFile={(name, event) => handleDeleteLabourFile(name, event, setFieldValue, selectedLabourIndex, values)}
                                selectedFilesBase={values.labourItems[selectedLabourIndex]?.attachments || []}
                                attachmentsSkeleton={labourFilesSkeleton}
                                attachmentsUploadsErrors={labourUploadsErrors}
                                openFilesSizeError={openLabourFileSizeError}
                                handleCloseFileSizeError={handleCloseLabourFileSizeError} // Reuse the file size error handler
                                selectedMaterialIndex={selectedLabourIndex} // Keep this for file indexing
                            />
                        </StaticPopup>

                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default LabourQuotationForm
