import React, { useEffect, useMemo, useRef, useState } from "react";
import { TextField, Button, Container, Typography, Box, Grid, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import JoditEditor from "jodit-react";

import axios from "utils/axios";
import { useLocation, useNavigate } from "react-router-dom";

const BlogForm = () => {
  const location = useLocation();
  const blogData = location.state;
  const navigate = useNavigate()
	const editor = useRef(null);
  
  const [formData, setFormData] = useState({
    type: "",
    date: "",
    heading: "",
    summary: "",
    author: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDescriptionChange = (value) => {
    setFormData({
      ...formData,
      description: value,
    });
  };

  const handleRadioChange = (e) => {
    setFormData({
      ...formData,
      type: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    console.log("called");
    e.preventDefault();
    if (blogData) {
      const updatedData = {
        ...formData,
        _id: blogData._id
      };
      await axios.put(`/api/blogs/update/${blogData._id}`, updatedData);
    } else {
      await axios.post(`/api/blogs/add`, formData);

    }
    setFormData({
      type: "",
      date: "",
      heading: "",
      summary: "",
      author: "",
      description: "",
    });
    navigate('/cms/blog-list');
  };

  useEffect(() => {
    if (location.state) {
      const { type, date, heading, summary, author, description } = location.state;
      setFormData({
        type,
        date,
        heading,
        summary,
        author,
        description,
      });
    }
  }, [location.state]);
  return (
    <Container maxWidth="sm">
      <Typography
        style={{ marginTop: "50px" }}
        variant="h5"
        align="center"
        gutterBottom
      >
        Blog Form
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", marginBottom: "50px" }}
      >

        <Grid>
          <label className="inputLabel">What Brings you here?</label>
          <Grid>
            <FormControl style={{ textAlign: "left" }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type"
                value={formData.type}
                onChange={handleRadioChange}

              >
                <FormControlLabel
                  sx={{ color: "#6E6B7B", fontSize: "12px" }}
                  value="blog"
                  control={<Radio />}
                  label="Blog"
                />
                <FormControlLabel
                  sx={{ color: "#6E6B7B", fontSize: "12px" }}
                  value="case study"
                  control={<Radio />}
                  label="Case Study"
                />
              </RadioGroup>
            </FormControl>

          </Grid>
        </Grid>
        <TextField
          // label="Date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          type="date"
        />
        <TextField
          label="Heading"
          name="heading"
          value={formData.heading}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Summary"
          name="summary"
          value={formData.summary}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Author"
          name="author"
          value={formData.author}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        />
        <Typography style={{ fontSize: "15px", marginBottom: "10px", marginTop: "6px" }} gutterBottom>
          Description
        </Typography>
        <JoditEditor
        	// config={config}
          ref={editor}
          value={formData.description}
          onChange={handleDescriptionChange}
          config={{
            height: 500,
            buttons: "bold,italic,underline,|,ul,ol,|,image,link",
            uploader: {
              insertImageAsBase64URI: true, // This option allows inserting images as base64 URIs
            },
          }}
        />
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          sx={{
            cursor: "pointer",
            width: "100%",
            paddingY: "10px",
            // background: "#FF6600",
            borderRadius: "6px",
            margin: "auto",
             mt: 3,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#46AC9E", // Change background color on hover
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
              transition: "background-color 0.3s, box-shadow 0.3s",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default BlogForm;
