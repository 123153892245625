import React, { useEffect, useState } from "react";
import { ErrorMessage, useFormikContext } from 'formik';

const AddIndividual = ({ setOpenAddMaterialPopUp, editIndex, setEditIndex, setShowAddOptions, }) => {

    const { values, setFieldValue, errors, validateForm, setTouched } = useFormikContext();
    const [materials, setMaterials] = useState(values.materials);
    const [showErrors, setShowErrors] = useState(false);
    const allBrands = ["Berger", "Nerolac", "Asian Paints", "Dulux", "Indigo"];
    const [isClose, setIsClosed] = useState(true);
    const [inputValues, setInputValues] = useState({});
    const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);
    const [activeUnitIndex, setActiveUnitIndex] = useState(null);

    const unitOptions = [
        "Sq. Feet(SFT)",
        "Running Meter(RMT)",
        "Sq. Meter",
        "Numbers(Qty)",
        "Cubic Meters(CUM)",
        "Kilograms(Kgs)",
        "Metric Ton(M.Ton)",
        "Liters",
        "Bags",
        "Cubic Foot(CFT)"
    ];

    const isMaterialEmpty = (material) => {
        return !material.name && !material.quantity && !material.unit && material.brands.length === 0 && !material.specifications;
    };

    const cleanUpMaterials = (materials) => {
        const filteredMaterials = materials.filter((material) => !isMaterialEmpty(material));
        if (filteredMaterials.length === 0) {
            return [{
                name: '',
                quantity: '',
                unit: '',
                brands: '',
                specifications: ''
            }];
        }

        return filteredMaterials;
    };


    const handleInputChange = (index, field, value) => {
        const updatedMaterials = [...materials];
        updatedMaterials[index][field] = value;
        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials); // Sync with Formik
    };

    // const handleAddBrand = (index, brand) => {
    //     const updatedMaterials = [...materials];
    //     if (!updatedMaterials[index].brands.includes(brand)) {
    //         updatedMaterials[index].brands.push(brand);
    //     }
    //     setMaterials(updatedMaterials);
    //     setFieldValue('materials', updatedMaterials);


    //     setInputValues((prev) => ({
    //         ...prev,
    //         [index]: '', 
    //     }));
    // };

    // const handleKeyPress = (index, e) => {
    //     if (e.key === 'Enter' && inputValues[index]?.trim()) {
    //         e.preventDefault();
    //         handleAddBrand(index, inputValues[index]);
    //     }
    // };

    // const handleBrandInputChange = (index, value) => {
    //     setInputValues((prev) => ({
    //         ...prev,
    //         [index]: value, 
    //     }));
    // };

    const handleBrandInputChange = (index, value) => {
        const updatedMaterials = [...materials];
        updatedMaterials[index].brands = [value];
        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials);
    };

    const handleEditBrandChange = (index, value) => {
        const updatedMaterials = materials.map((material, i) =>
            i === index ? { ...material, brands: [value] } : material
        );

        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials); // Update Formik field value
    };


    const handleRemoveBrand = (index, brand) => {
        const updatedMaterials = [...materials]; // Copy the current materials array
        // Filter out the brand to remove it from the specific material's brands array
        updatedMaterials[index].brands = updatedMaterials[index].brands.filter(b => b !== brand);

        // Update the materials state and sync with Formik
        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials); // Sync with Formik if using Formik
    };

    const handleEditInputChange = (index, value) => {
        setInputValues((prev) => ({
            ...prev,
            [index]: value, // Update input value for the specific material (editIndex)
        }));
    };

    const removeRow = (index) => {
        const updatedMaterials = materials.filter((_, i) => i !== index);
        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials); // Sync with Formik
    };

    console.log(Object.keys(errors))

    const handleSave = async () => {
        const errors = await validateForm(); // Trigger Formik validation and get errors
        setTouched({
            materials: materials.map(() => ({
                name: true,
                quantity: true,
                unit: true,
                brands: true,
                specifications: true,
            })),
        }); // Mark all fields as touched to ensure error messages are shown

        if (Object.keys(errors).includes("materials")) {
            setShowErrors(true); // Show errors if validation fails
            return;
        }

        const cleanedMaterials = cleanUpMaterials(materials);
        setMaterials(cleanedMaterials);
        setFieldValue('materials', cleanedMaterials);
        setEditIndex(null);
        setShowAddOptions(false);
        setOpenAddMaterialPopUp(false);
    };


    const handleCancel = () => {
        const cleanedMaterials = cleanUpMaterials(materials);
        setMaterials(cleanedMaterials); // Reset the materials array with at least one row
        setFieldValue('materials', cleanedMaterials); // Sync with Formik

        setOpenAddMaterialPopUp(false); // Close modal
        setEditIndex(null);
        setShowAddOptions(false);
    };

    const addNewRow = () => {
        setEditIndex(null)
        const newMaterial = {
            name: "",
            quantity: "",
            unit: "",
            brands: [],
            specifications: "",
            // inputValue: "", // For brand input
        };
        const updatedMaterials = [...materials, newMaterial];
        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials); // Sync with Formik
    };

    // Check if materials array is empty and initialize with one empty object if needed
    useEffect(() => {
        if (materials.length === 0) {
            setMaterials([{
                name: '',
                quantity: '',
                unit: '',
                brands: [],
                specifications: ''
            }]);
            setFieldValue('materials', [{
                name: '',
                quantity: '',
                unit: '',
                brands: [],
                specifications: ''
            }]); // Sync with Formik
        }
    }, [materials, setFieldValue]);


    return (
        <>
            <div className="flex items-center justify-center w-full">
                {isClose && (
                    <div className="bg-gray-500 bg-opacity-50">
                        <div className="min-h-[300px] max-h-[300px] bg-white w-full overflow-y-auto">
                            {/* Header for the grid layout */}
                            {editIndex !== null ? (
                                <div className="sticky top-0 bg-white z-50 grid grid-cols-9 gap-2 text-sm text-[#777777] px-8 py-6">
                                    <p className="col-span-2">Material Name</p>
                                    <p>Quantity</p>
                                    <p className="col-span-2">Unit</p>
                                    <p className="col-span-2">Material Brands</p>
                                    <p className="col-span-2">Specifications</p>
                                    <p className="col-span-1"></p> {/* Empty for the delete icon */}
                                </div>) : (
                                <div className="sticky top-0 bg-white z-50 grid grid-cols-10 gap-2 text-sm text-[#777777] px-8 py-6">
                                    <p className="col-span-2">Material Name</p>
                                    <p>Quantity</p>
                                    <p className="col-span-2">Unit</p>
                                    <p className="col-span-2">Material Brand</p>
                                    <p className="col-span-2">Specifications</p>
                                    <p className="col-span-1"></p> {/* Empty for the delete icon */}
                                </div>)}

                            <div className="px-8 space-y-4 py-2 text-sm">
                                {editIndex !== null ? (
                                    <div className="grid grid-cols-9 gap-2 text-sm">
                                        {/* Name Field */}
                                        <div className="col-span-2 flex flex-col self-start justify-end">
                                            <input
                                                type="text"
                                                value={materials[editIndex].name}
                                                onChange={(e) => handleInputChange(editIndex, 'name', e.target.value)}
                                                // placeholder="Material Name"
                                                className="border border-[#DCDCD0] h-[40px] w-full text-sm"
                                            />
                                            <ErrorMessage
                                                name={`materials.${editIndex}.name`}
                                                component="div"
                                                className="text-red-500 text-xs mt-1"
                                            />
                                        </div>

                                        {/* Quantity Field */}
                                        <div className="col-span-1 self-start flex flex-col justify-end text-sm">
                                            <input
                                                type="text"
                                                value={materials[editIndex].quantity}
                                                onChange={(e) => handleInputChange(editIndex, 'quantity', e.target.value)}
                                                // placeholder="Quantity"
                                                className="border border-[#DCDCD0] p-2 h-[40px] w-full text-sm"
                                            />
                                            <ErrorMessage
                                                name={`materials.${editIndex}.quantity`}
                                                component="div"
                                                className="text-red-500 text-xs mt-1"
                                            />
                                        </div>

                                        {/* Unit Field */}
                                        <div className="relative self-start col-span-2 text-sm flex flex-col justify-end">
                                            <input
                                                type="text"
                                                value={materials[editIndex].unit}
                                                onChange={(e) => handleInputChange(editIndex, 'unit', e.target.value)}
                                                onFocus={() => setActiveUnitIndex(editIndex)} // Open dropdown on focus
                                                // placeholder="Unit"
                                                className="border border-[#DCDCD0] p-2 h-[40px] w-full text-sm"
                                            />
                                            <ErrorMessage
                                                name={`materials.${editIndex}.unit`}
                                                component="div"
                                                className="text-red-500 text-xs mt-1"
                                            />

                                            {/* Dropdown suggestions */}
                                            {activeUnitIndex === editIndex && (
                                                <ul className="absolute z-50 bg-white text-sm border mt-1 max-h-[100px] overflow-y-auto w-full shadow-lg" style={{ top: '100%', left: 0 }}>
                                                    {unitOptions.map((option, i) => (
                                                        <li
                                                            key={i}
                                                            onClick={() => {
                                                                handleInputChange(editIndex, 'unit', option);
                                                                setUnitDropdownOpen(false); // Close dropdown after selecting
                                                            }}
                                                            className="p-2 cursor-pointer text-sm hover:bg-gray-100"
                                                        >
                                                            {option}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>


                                        {/* Brands Field Edit*/}
                                        {/* <div className="relative col-span-2 bg-white flex flex-col overflow-y-auto justify-end">
                                            <div className="flex flex-wrap gap-2 p-1 border h-[40px] border-[#DCDCD0]">
                                                {materials[editIndex].brands.length > 0 ? (
                                                    materials[editIndex].brands.map((brand, i) => (
                                                        <div key={i} className="bg-[#DCDCD0] rounded-full px-2 py-1 flex items-center">
                                                            {brand}
                                                            <button className="ml-2 text-xs" onClick={() => handleRemoveBrand(editIndex, brand)}>
                                                                ✕
                                                            </button>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={inputValues[editIndex] || ''} // Use input value from inputValues state
                                                        placeholder="Material Brands"
                                                        onChange={(e) => handleEditInputChange(editIndex, e.target.value)}
                                                        onKeyDown={(e) => handleKeyPress(editIndex, e)}
                                                        className="border-none flex-1 focus:outline-none placeholder:text-sm focus:ring-0 h-[30px]"
                                                        style={{ minWidth: '80px', visibility: materials[editIndex].brands.length > 0 ? 'hidden' : 'visible' }} // Hide input when a brand is selected
                                                        disabled={materials[editIndex].brands.length > 0} // Disable input if a brand is selected
                                                    />
                                                )}
                                            </div>

                                            {inputValues[editIndex] && (
                                                <ul className="absolute z-10 bg-white border bottom-0 w-full rounded-md shadow-lg">
                                                    {allBrands
                                                        .filter((brand) =>
                                                            brand.toLowerCase().includes(inputValues[editIndex].toLowerCase())
                                                        )
                                                        .map((brand, i) => (
                                                            <li
                                                                key={i}
                                                                onClick={() => handleAddBrand(editIndex, brand)} 
                                                                className="p-2 my-2 cursor-pointer hover:bg-gray-100"
                                                            >
                                                                {brand}
                                                            </li>
                                                        ))}
                                                </ul>
                                            )}
                                        </div> */}

                                        <div className="relative self-start col-span-2 bg-white flex flex-col justify-end">
                                            <input
                                                type="text"
                                                value={materials[editIndex].brands.join(", ") || ''}
                                                placeholder=""
                                                onChange={(e) => handleEditBrandChange(editIndex, e.target.value)}
                                                className="p-1 border focus:ring-2 h-[40px] border-[#DCDCD0] focus:outline-none placeholder:text-sm w-full"
                                            />

                                            {/* Error messages */}
                                            <ErrorMessage
                                                name={`materials.${editIndex}.brands`}
                                                component="div"
                                                className="text-red-500 text-xs mt-1"
                                            />
                                        </div>


                                        {/* Specifications Field */}
                                        <div className="col-span-2 self-start flex flex-col justify-end">
                                            <input
                                                type="text"
                                                value={materials[editIndex].specifications}
                                                onChange={(e) => handleInputChange(editIndex, 'specifications', e.target.value)}
                                                // placeholder="Specifications"
                                                className="border border-[#DCDCD0] p-2 h-[40px] w-full text-sm"
                                            />
                                            <ErrorMessage
                                                name={`materials.${editIndex}.specifications`}
                                                component="div"
                                                className="text-red-500 text-xs mt-1"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    materials.map((material, index) => (
                                        <div key={index} className="grid grid-cols-10 gap-2">
                                            {/* Material Name Field */}
                                            <div className="col-span-2 self-start flex flex-col justify-end">
                                                <input
                                                    type="text"
                                                    name={`materials.${index}.name`}
                                                    value={material.name}
                                                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                                    // placeholder="Material Name"
                                                    className="border border-[#DCDCD0] p-2 h-[40px] w-full text-sm"
                                                />
                                                <ErrorMessage
                                                    name={`materials.${index}.name`}
                                                    component="div"
                                                    className="text-red-500 text-xs mt-1"
                                                />
                                            </div>

                                            {/* Quantity Field */}
                                            <div className="col-span-1 self-start flex flex-col justify-end">
                                                <input
                                                    type="number"
                                                    name={`materials.${index}.quantity`}
                                                    value={material.quantity}
                                                    onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                                    // placeholder="Quantity"
                                                    className="border border-[#DCDCD0] p-2 h-[40px] w-full text-sm"
                                                />
                                                <ErrorMessage
                                                    name={`materials.${index}.quantity`}
                                                    component="div"
                                                    className="text-red-500 text-xs mt-1"
                                                />
                                            </div>

                                            {/* Unit Field */}
                                            <div className="relative self-start col-span-2 flex flex-col justify-end text-sm">
                                                <input
                                                    type="text"
                                                    name={`materials.${index}.unit`}
                                                    value={material.unit}
                                                    onChange={(e) => handleInputChange(index, 'unit', e.target.value)}
                                                    onFocus={() => setActiveUnitIndex(index)}  // Open dropdown on focus
                                                    // placeholder="Unit"
                                                    className="border border-[#DCDCD0] p-2 h-[40px] w-full texr-sm font-normal"
                                                />
                                                <ErrorMessage
                                                    name={`materials.${index}.unit`}
                                                    component="div"
                                                    className="text-red-500 text-xs mt-1"
                                                />

                                                {/* Dropdown suggestions */}
                                                {activeUnitIndex === index && (
                                                    <ul className="absolute z-10  font-normal bg-white border h-[180px] overflow-y-auto mt-1 w-full rounded-md shadow-lg" style={{ top: '100%', left: 0 }}>
                                                        {unitOptions.map((option, i) => (
                                                            <li
                                                                key={i}
                                                                onClick={() => {
                                                                    handleInputChange(index, 'unit', option);
                                                                    setActiveUnitIndex(null); // Close dropdown after selecting
                                                                }}
                                                                className="p-2 cursor-pointer hover:bg-gray-100 text-sm"
                                                            >
                                                                {option}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>

                                            {/* Material Brands Field */}
                                            {/* <div className="relative col-span-2 flex flex-col justify-end ">
                                                <div className="flex flex-wrap gap-2 p-2 border h-[40px] border-[#DCDCD0]">
                                                    {material.brands.length > 0 ? (
                                                        material.brands.map((brand, i) => (
                                                            <div key={i} className="bg-[#F5F5F3] rounded-full px-2 py-1 flex items-center">
                                                                {brand}
                                                                <button className="ml-2 text-xs" onClick={() => handleRemoveBrand(index, brand)}>
                                                                    ✕
                                                                </button>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            value={inputValues[index] || ''} // Use the input value from the state
                                                            onChange={(e) => handleBrandInputChange(index, e.target.value)}
                                                            onKeyDown={(e) => handleKeyPress(index, e)} // Handle Enter key
                                                            className="border-none flex-1 h-[30px] focus:outline-none focus:ring-0 text-sm"
                                                            style={{ minWidth: '80px', visibility: material.brands.length > 0 ? 'hidden' : 'visible' }} // Hide the input if a brand is selected
                                                            disabled={material.brands.length > 0} // Disable input after brand is selected
                                                        />
                                                    )}
                                                </div>

                                                {inputValues[index] && (
                                                    <ul className="absolute z-10 bg-white border mt-1 w-full shadow-lg" style={{ top: '100%', left: 0 }}>
                                                        {allBrands
                                                            .filter((brand) =>
                                                                brand.toLowerCase().includes(inputValues[index].toLowerCase())
                                                            )
                                                            .map((brand, i) => (
                                                                <li
                                                                    key={i}
                                                                    onClick={() => handleAddBrand(index, brand)} // Handle brand selection
                                                                    className="p-2 cursor-pointer hover:bg-gray-100 text-sm"
                                                                >
                                                                    {brand}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                )}

                                                {showErrors && errors.materials && errors.materials[index]?.brands && (
                                                    <div className="text-red-500 text-xs">{errors.materials[index].brands}</div>
                                                )}
                                            </div> */}

                                            <div className="col-span-2 self-start flex flex-col justify-end">
                                                <input
                                                    type="text"
                                                    value={materials[index].brands.join(", ") || ''} // Display the brand in the input
                                                    onChange={(e) => handleBrandInputChange(index, e.target.value)} // Update as the user types
                                                    className="border border-[#DCDCD0] p-2 h-[40px] w-full text-sm focus:outline-none focus:ring-0"
                                                    placeholder=""
                                                />

                                                {/* Error messages */}
                                                {showErrors && errors.materials && errors.materials[index]?.brands && (
                                                    <div className="text-red-500 text-xs">{errors.materials[index].brands}</div>
                                                )}
                                            </div>

                                            {/* Specifications Field */}
                                            <div className="col-span-2 self-start flex flex-col justify-end">
                                                <input
                                                    type="text"
                                                    name={`materials.${index}.specifications`}
                                                    value={material.specifications}
                                                    onChange={(e) => handleInputChange(index, 'specifications', e.target.value)}
                                                    // placeholder="Specifications"
                                                    className="border border-[#DCDCD0] p-2 h-[40px] w-full text-sm"
                                                />
                                                {showErrors && errors.materials && errors.materials[index]?.specifications && (
                                                    <div className="text-red-500 text-xs">{errors.materials[index].specifications}</div>
                                                )}
                                            </div>

                                            {/* Delete Button */}
                                            {materials.length > 1 && (
                                                <div className="col-span-1 self-start flex flex-col justify-end">
                                                    <button
                                                        type="button"
                                                        className="text-red-500"
                                                        onClick={() => removeRow(index)}
                                                    >
                                                        <svg
                                                            className="w-5 h-5"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            tabIndex="-1"
                                                            title="Delete Forever"
                                                        >
                                                            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zm2.46-7.12 1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                )}

                                {editIndex === null && (
                                    <div className="flex justify-end w-full">
                                        <button
                                            className={`font-medium py-4 w-fit underline ${(
                                                !values?.materials[values.materials.length - 1]?.name ||
                                                !values?.materials[values.materials.length - 1]?.quantity ||
                                                !values?.materials[values.materials.length - 1]?.unit ||
                                                values?.materials[values.materials.length - 1]?.brands.length === 0 ||
                                                !values?.materials[values.materials.length - 1]?.specifications
                                            ) ? 'text-black' : 'text-black'}`}
                                            onClick={addNewRow}
                                        // disabled={
                                        //     !values?.materials[values.materials.length - 1]?.name ||
                                        //     !values?.materials[values.materials.length - 1]?.quantity ||
                                        //     !values?.materials[values.materials.length - 1]?.unit ||
                                        //     values?.materials[values.materials.length - 1]?.brands.length === 0 ||
                                        //     !values?.materials[values.materials.length - 1]?.specifications
                                        // }
                                        >
                                            + Add Another
                                        </button>

                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="sticky bottom-0 rounded-b-lg bg-white p-4 border-t  flex justify-end">
                            <div className="w-1/5 flex justify-between bg-white">
                                <button className="text-gray-600 text-sm" type="button" onClick={handleCancel}>Cancel</button>
                                <button className="bg-[#1E1E08] text-white text-sm px-6 py-2 " type="button" onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>


        </>
    );
};

export default AddIndividual;
