import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useIsMounted from "hooks/useIsMounted";
import axios from "utils/axios";
import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
import MainLayout from "layouts/Main";
import BusinessProfile from "../components/BusinessProfile";

function PublicProfile({ match, location }) {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [projects, setProjects] = useState(null);

  const params = useParams();
  const url = params.url;

  const isMounted = useIsMounted();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });



  // NOTE: The fetching and display of public profile is by design like it instead
  //       of using reducer as there are be more windows open with different public profiles

  const getProfile = useCallback(() => {
    axios
      .get(`/api/supplier/profileurl?businessUrl=${url}`) //, {})
      .then((response) => {
        

        if (isMounted()) {
          setProfile(response.data.payload.profile);
          setProjects(response?.data?.payload?.projects?.projects);
        }
      });
  }, [isMounted]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);


  if (!profile) {
    return null;
  }

  const businessProfile = profile.businessProfile;

  return (
    <MainLayout>
      <Helmet>
        <title>{profile.businessName}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container>
          <BusinessProfile profile={profile} projects={projects} />
        </Grid>
      </Container>
    </MainLayout>
  );
}

export default PublicProfile;
