import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchMembers } from "actions/membersAction";
import axios from "utils/axios";
import useIsMounted from "hooks/useIsMounted";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import { Helmet } from "react-helmet";

import MembersList from "./MembersList";
import AddMemberForm from "./AddMemberForm";

function Members() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmitSuccess = () => {
    // console.log("Member added successful");
    setOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const business  = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;

  useEffect(() => {
    dispatch(fetchMembers(supplierId));
  }, [auth?.user?.supplierId]);

  const member = useSelector((state) => state.member);

  useEffect(() => {
    //  console.log("member/************************:", member);
  }, [member?.member]);

  // TODO_SP: Check if there is need to pull data, everytime user lands on the page

  return (
    <DashboardLayout path="/members">
      <Helmet>
        <title>Members</title>
      </Helmet>
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid
              item
              sm={12}
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography
                variant="h5"
                color="#FD6600"
                fontWeight="bold"
                mb={2}
              >
                Members
              </Typography>

              <Button
              disabled
                color="secondary"
                onClick={handleClickOpen}
                mb={2}
                variant="contained"
              >
                {" "}
                Add Members
              </Button>

              {/* <Button href="/add-lead" variant="contained">
                Create Lead
              </Button> */}
            </Grid>
            <Divider />
            <Grid item sm={12} fullWidth>
              <MembersList title="Members" members={member?.members} />
            </Grid>
          </Grid>
        </Box>
      </Page>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Add Members"}</DialogTitle>
        <DialogContent>
          <AddMemberForm
            supplierId={supplierId}
            handleClose={handleClose}
            onSubmitSuccess={handleOnSubmitSuccess}
          />
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default Members;
