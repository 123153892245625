import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { orderDataSubmit } from "actions/subscribeAction";
import MainLayout from "layouts/Main";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { createOrder } from "actions/subscribeAction";
import {
  Box,
  Button,
  FormHelperText,
  FormControl,
  Fab,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  Container,
  Autocomplete,
  Link,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { getAddressHO, getAddressStr } from "utils/addressUtils";
import { getUrl } from "actions/businessActions";

const state = [
  { label: "Jammu And Kashmir", code: "01" },
  { label: "Himachal Pradesh", code: "02" },
  { label: "Punjab", code: "03" },
  { label: "Chandigarh", code: "04" },
  { label: "Uttarakhand", code: "05" },
  { label: "Haryana", code: "06" },
  { label: "Delhi", code: "07" },
  { label: "Rajasthan", code: "08" },
  { label: "Uttar Pradesh", code: "09" },
  { label: "Bihar", code: "10" },
  { label: "Sikkim", code: "11" },
  { label: "Arunachal Pradesh", code: "12" },
  { label: "Nagaland", code: "13" },
  { label: "Manipur", code: "14" },
  { label: "Mizoram", code: "15" },
  { label: "Tripura", code: "16" },
  { label: "Meghalaya", code: "17" },
  { label: "Assam", code: "18" },
  { label: "West Bengal", code: "19" },
  { label: "Jharkhand", code: "20" },
  { label: "Odisha", code: "21" },
  { label: "Chattisgarh", code: "22" },
  { label: "Madhya Pradesh", code: "23" },
  { label: "Gujarat", code: "24" },
  {
    label: "Dadra And Nagar Haveli And Daman And Diu (Newly Merged Ut)",
    code: "26",
  },
  { label: "Maharashtra", code: "27" },
  { label: "Andhra Pradesh(Before Division)", code: "28" },
  { label: "Karnataka", code: "29" },
  { label: "Goa", code: "30" },
  { label: "Lakshadweep", code: "31" },
  { label: "Kerala", code: "32" },
  { label: "Tamil Nadu", code: "33" },
  { label: "Puducherry", code: "34" },
  { label: "Andaman And Nicobar Islands", code: "35" },
  { label: "Telangana", code: "36" },
  { label: "Andhra Pradesh (Newly Added)", code: "37" },
  { label: "Ladakh (Newly Added)", code: "38" },
  { label: "Other Territory", code: "97" },
  { label: "Centre Jurisdiction", code: "99" },
];

const OrderForm = ({ ...rest }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order);
  const planToSubscribe = order.planToSubscribe;


  const planLS = JSON.parse(localStorage.getItem("plan") || "[]");
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  const business = useSelector((state) => state.business);

  let businessName = business?.profile?.businessName;
  const gstin = business?.profile?.statutory?.gstin;
  const supplierId = business?.profile?.supplierId;
  let isRegistered = businessName != undefined && businessName != "";
  const address = getAddressHO(business?.profile?.businessProfile?.addresses);

  const addressStr = getAddressStr(address);

  const mobile = business?.profile?.businessProfile?.businessMobile;


  const profilelocalStorage = JSON.parse(
    localStorage.getItem("profile") || "[]"
  );

  // const getLocalStorageAddress = profilelocalStorage.businessProfile.addresses;
  const localAddressHo = getAddressHO(
    profilelocalStorage?.businessProfile?.addresses
  );
  const localAddressStr = getAddressStr(localAddressHo);

  const localGst = profilelocalStorage?.statutory?.gstin;
  const supplierIdLS = profilelocalStorage?.supplierId;
  const navigate = useNavigate();

  const onSubmitSuccess = () => {
    navigate("/confirm-Payment");
  };
  const currentLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    try {
      dispatch(getUrl(currentLocation.pathname));
    } catch (error) {
    }
    if (!userProfile) {
      navigate("/signin-mobile");
    }
  }, []);

  // const sliceGST = localGst.slice(0, 2)
  const [selectedCode, setSelectedCode] = useState("");

  const [selectedLabel, setSelectedLabel] = useState("");

  const handleCodeChange = (event) => {
    const enteredCode = event.target.value;

    const codePrefix = enteredCode.substring(0, 2);

    const matchingState = state.find((item) =>
      item.code.toString().startsWith(codePrefix)
    );

    if (matchingState) {
      setSelectedCode(matchingState.code.toString());

      setSelectedLabel(matchingState.label);
    } else {
      setSelectedCode("");

      setSelectedLabel("");
    }
  };

  const getStateCode = () => {
    // const sliceGst = localGst.slice(0, 2);
    const codePrefix = localGst.substring(0, 2);

    const matchingState = state.find((item) =>
      item.code.toString().startsWith(codePrefix)
    );

    if (matchingState) {
      setSelectedCode(matchingState.code.toString());

      setSelectedLabel(matchingState.label);
    } else {
      setSelectedCode("");

      setSelectedLabel("");
    }
  };

  useEffect(() => {
    getStateCode();
  }, []);
  const [gst, setGst] = useState(localGst);

  return (
    <MainLayout>
      <Container
        maxWidth="md"
        className="center-modal"
        sx={{ flexDirection: "column" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            boxShadow: 5,
            p: 8,
            borderRadius: 1,
            m: 4,
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12} sx={{ ml: -2 }}>
              {isRegistered ? (
                <Typography variant="h6">
                  Subscription for Business: {businessName}
                </Typography>
              ) : (
                <Typography variant="h6">
                  Kindly register business before subscription
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12} md={12} sx={{ ml: -2 }}>
              <Typography component="h1" variant="h3">
                Order Details
              </Typography>
              <Typography component="h2" variant="h6">
                Kindly add the details that needs to reflect on your invoice
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ mt: 5 }}>
            <Formik
              enableReinitialize
              initialValues={{
                name: userProfile ? userProfile.name : "",
                mobile: business
                  ? business?.profile?.businessProfile?.businessMobile ||
                    profilelocalStorage?.businessProfile?.businessMobile
                  : "",
                email: business
                  ? business?.profile?.businessProfile?.businessEmail ||
                    profilelocalStorage?.businessProfile?.businessEmail
                  : "",
                businessName: businessName
                  ? businessName
                  : "" || profilelocalStorage?.businessName,
                // gstin: gstin ? gstin : ""|| name || localGst,gst
                gstin: gst,
                // stateCode:  getState(gstin ? gstin : "" || localGst),
                stateCode: selectedCode,
                address: addressStr ? addressStr : "" || localAddressStr,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().min(2).max(80).required(),
                businessName: Yup.string().min(2).required(),
                mobile: Yup.string().min(10).max(10),
                email: Yup.string().email(),
                gstin: Yup.string().matches(
                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                  "Please Enter Valid GST Number"
                )
                .max(20)
                .min(10),
                stateCode: Yup.string().required(),
                address: Yup.string().required(),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await dispatch(
                    createOrder(
                      planToSubscribe || planLS,
                      values,
                      supplierId || supplierIdLS
                    )
                  );
                  
                  onSubmitSuccess();
                } catch (error) {
                
                  //   onSubmitSuccess();
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);

                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit} {...rest}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        name="name"
                        id="name"
                        label="Name"
                        type="text"
                        value={values.name}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 80 }}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        name="businessName"
                        id="businessName"
                        label="Business Name"
                        type="text"
                        value={values.businessName}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 80 }}
                        error={Boolean(
                          touched.businessName && errors.businessName
                        )}
                        helperText={touched.businessName && errors.businessName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        name="mobile"
                        id="mobile"
                        label="Mobile"
                        type="text"
                        value={values.mobile}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 80 }}
                        error={Boolean(touched.mobile && errors.mobile)}
                        helperText={touched.mobile && errors.mobile}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        name="email"
                        id="email"
                        label="Email"
                        type="text"
                        value={values.email}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 80 }}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        name="stateCode"
                        id="stateCode"
                        options={state}
                        getOptionLabel={(option) => option.label}
                        value={{ label: selectedLabel, code: selectedCode }}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedLabel(newValue.label);

                            setSelectedCode(newValue.code);
                          } else {
                            setSelectedLabel("");

                            setSelectedCode("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            InputProps={{
                              ...params.InputProps,

                              readOnly: true,
                            }}
                            error={Boolean(
                              touched.stateCode && errors.stateCode
                            )}
                            helperText={touched.stateCode && errors.stateCode}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        name="gstin"
                        id="gstin"
                        value={gst || values.gstin}
                        onBlur={handleBlur}
                        label="GSTIN" 
                        onChange={(e) => {
                          handleChange(e);
                          setGst(e.target.value);
                          handleCodeChange(e);
                        }}
                        error={Boolean(touched.gstin && errors.gstin)}
                        helperText={touched.gstin && errors.gstin}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        name="address"
                        id="address"
                        label="Address"
                        multiline
                        rows={4}
                        type="text"
                        value={values.address}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 80 }}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}
                    </Grid>
                    <Grid item alignItems={"center"} justifyContent={"center"}>
                     
                      <Button
                        // disabled={!isRegistered}
                        color="secondary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Review Order
                      </Button>
                      {/* </Link> */}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Box>
      </Container>
    </MainLayout>
  );
};

export default OrderForm;
