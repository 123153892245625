import React from "react";
import PropTypes from "prop-types";

import { Grid, Box, Typography, Link } from "@mui/material";

import Logo from "components/Logo";
import SignInSendMobileOTPForm from "./SignInSendMobileOTPForm";
import { Close } from "@mui/icons-material";

const SignIn = ({ onSubmitSuccess }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      mt={10}
    >
      <Grid xs={12}>
        <Logo width="120px" />
      </Grid>
      <Grid xs={12} mt={4}>
        <Typography variant="h5" color={"black"} fontWeight="bold">
          {" "}
          SIGN IN{" "}
        </Typography>
        <Typography variant={"subtitle2"} mt={2}>
          Don't have an account?{" "}
          <Link
            component={"a"}
            color={"primary"}
            // color={"#FD6600"}
            href={"/register"}
            underline={"none"}
          >
            Sign Up
          </Link>
        </Typography>
      </Grid>
      <Grid p={6}>
        <SignInSendMobileOTPForm onSubmitSuccess={onSubmitSuccess} />
        <Typography variant={"subtitle2"} mt={2}>
          Login with Email/Password {" "}
          <Link
            component={"a"}
            // color={"#FD6600"}
            color={"primary"}
            href={"/signin"}
            underline={"none"}
          >
           Login using UserId
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

SignIn.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

export default SignIn;
