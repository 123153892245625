import axios from "axios";

const gateway_port = process.env.REACT_APP_API_PORT || 5000;
const gateway_protocol = process.env.REACT_APP_PROTOCOL || "https";
const api_hostname = process.env.REACT_APP_API_HOSTNAME || window.location.hostname;

// console.log(
//   "ENV : " + process.env
// );

const port = ( gateway_port === "80" ) ? "" : ":" + gateway_port;

const url = gateway_protocol + "://" + api_hostname + port;

// console.log(
//   "Axios URL : " + url + " EnvPort:" + process.env.REACT_APP_API_PORT
// );



const instance = axios.create({
  baseURL: url,
  timeout: 30000,
});

export default instance;
