import React from "react";
import {  useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import Minimal from "layouts/Minimal";
import { Helmet } from "react-helmet";

const notFoundImg = "/assets/general/404.jpeg";

const OnboardingGreetings = () => {
  const theme = useTheme();

  return (
    <Minimal>
    <Helmet>
      <title>Page Not Found</title>
    </Helmet>
      <Box>
        <img src={notFoundImg} width={200} height={200} />
        <Typography fontSize={50} fontWeight={600} color="primary">
          Sorry Page is Missing
        </Typography>
      </Box>
    </Minimal>
  );
};

export default OnboardingGreetings;
