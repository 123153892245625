import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate,  } from "react-router";


import {
  Box,
  Divider,
  InputBase,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import { Search } from "@mui/icons-material";

import { search } from "actions/searchActions";

function SearchBar(props) {
  const textRef = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearch = () => {
    //search(textRef.current.value);
    // console.log("SearchBar: text", textRef.current.value);
 
    const searchKeyWord = textRef.current.value;
    if (searchKeyWord && searchKeyWord.length > 2) {
      try {
        dispatch(search(textRef.current.value));
        //navigate(`/search/${searchKeyWord}`);
        navigate('/search');
       
      } catch (error) {
        // console.log("SearchBar Error: ", error);
      }
    } else {
      // console.log("SearchBar: empty or 1 char [", searchKeyWord, "]");
    }
  };
  const handleKeyDown = (e) => {
    
    if (e.key === "Enter") {
      handleSearch();
      e.preventDefault();
    }
   
  };

  return (
    <Box sx={{ flexGrow: 3 }}>
      <Paper
        elevation={6}
        component="form"
        id="paperDiv"
        sx={{
          p: "0px 4px 0px 0px",
          display: "flex",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <Search />
        </IconButton>
        <InputBase
          inputRef={textRef}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Here!  (example: plywood, labour, consultant ...)"
          inputProps={{ "aria-label": "Looking for " }}
          onKeyDown={handleKeyDown}
        />

        <Divider sx={{ height: 40, m: 0 }} orientation="vertical" />
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 2, ml: 2, borderRadius: "25px", height: "35px" }}
          onClick={handleSearch}
        >
          SEARCH
        </Button>
      </Paper>
    </Box>
  );
}

export default SearchBar;
