import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { ConfirmDialog } from "components";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Stack,
  Link,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import { removeFavourites } from "actions/favouritesAction";
import { headCells } from "./utils";
import { invoiceToPrint } from "actions/ordersAction";
function OrdersList({ orders }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  useEffect(() => {}, [orders]);

  const auth = useSelector((state) => state.auth);
  const business  = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const handlePdf = (supplierId, invoiceDetails) => {
    dispatch(invoiceToPrint(supplierId, invoiceDetails));
    navigate("/download-pdf");
  };
  // console.log("orders=>>>>" ,orders)

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={orders}
          columns={headCells(true, handlePdf, supplierId)}
          getRowId={(row: any) => row.orderId}
          pageSize={25}
          disableSelectionOnClick
        />
      </Box>
    </Stack>
  );
}

OrdersList.propTypes = {
  orders: PropTypes.array,
};

export default OrdersList;
