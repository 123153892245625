import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  CardMedia,
  Link,
} from "@mui/material";

import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";

import Slick from "react-slick";
import FlipCard from "./FlipCard";

import { addProjectTrigger } from "actions/projectActions";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ProjectCard from "./ProjectCard";

const BASE_URL = process.env.REACT_APP_BASE_URL;

//  Settings for view only page 
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 730,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// Setting for edit and update details page
const settingsEdit = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function ProfileHallOfFame({
  supplierId,
  projects,
  isPublicProfile,
  permissions,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddProject = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isOwner) {
        dispatch(addProjectTrigger);
        navigate("/add-project");
      } else {

      }
    } else {
      navigate("/subscribe-plan");
    }
  };

  const handlePlanUpgrade = () => {
    navigate("/subscribe-plan");
  };

  let isProjectExists = false;
  if (projects) {
    projects.map((project) => {
      if (!project.isDeleted) {
        isProjectExists = true;
      }
    });
  }

  return (
    <Container fullWidth="lg">
      {/* <Grid container> */}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container mt={2}>
          <Grid item sm={12}>
            {((isPublicProfile && isProjectExists) || !isPublicProfile) && (
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="businessTitle"
                // fontSize={"18px"}
                // color={"rgba(0, 0, 0, 0.6)"}
                // fontWeight={"700"}
                >
                  HALL OF FAME / PROJECTS
                </Typography>
                {!isPublicProfile && (
                  <CardMedia
                    component="img"
                    image={DEFAULT_IMAGES.PREMIUM_BADGE}
                    height="20px"
                    sx={{ width: "25px", ml: 1, mt: -1 }}
                  />
                )}
              </Box>
            )}
          </Grid>

          <Grid item sm={12} xs={12}>
            {permissions &&
              !permissions.isViewOnly &&
              permissions.isFreeUpdateAllowed && (
                <Typography
                  // variant="contained"
                  color="secondary"
                  size="small"
                  disabled={!(permissions && permissions.isUpdateAllowed)}
                  sx={{ fontSize: "15px", cursor: "pointer" }}
                  // endIcon={<AddCircleRoundedIcon />}
                  onClick={handleAddProject}
                >
                  <Link sx={{ textDecoration: "none", color: "blue" }}>
                    {" "}
                    Click Here{" "}
                  </Link>
                  <span style={{ color: "black" }}> to upload Project</span>
                </Typography>
              )}
          </Grid>
        </Grid>
        {/* {!isPublicProfile && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disabled={permissions && permissions.isUpdateAllowed}
              sx={{ ml: 2 }}
              endIcon={<WorkspacePremiumIcon />}
              onClick={handlePlanUpgrade}
            >
              Upgrade
            </Button>
          )} */}
      </Box>

      {isProjectExists && (
        !permissions?.isUpdateAllowed
          ?
          <Slick {...settings}>
            {/* <Grid container > */}
            {projects?.map(
              (project) =>
                !project.isDeleted && (
                  <Grid container margin={"20px 0"} padding={"0 5px"} key={project.id}>

                    {/* <FlipCard */}

                    {/* Implement new design for project card */}

                    <ProjectCard
                      imageUrl={
                        project.projectImageUrl === undefined ||
                          project.projectImageUrl === ""
                          ? undefined
                          : BASE_URL + project.projectImageUrl
                      }
                      projectName={project.projectName}
                      clientName={project.clientName}
                      location={project.location}
                      description={project.description}
                      isPublicProfile={isPublicProfile}
                      projectData={project}
                      supplierId={supplierId}
                      permissions={permissions}
                      projectId={project._id}
                    />

                  </Grid>
                )
            )}
          </Slick>
          :
          <Slick {...settingsEdit}>
            {projects?.map(
              (project) =>
                !project.isDeleted && (
                  <Grid container margin={"20px 0"} padding={"0 5px"} key={project.id}>

                    {/* <FlipCard */}

                    {/* Implement new design for project card */}

                    <ProjectCard
                      imageUrl={
                        project.projectImageUrl === undefined ||
                          project.projectImageUrl === ""
                          ? undefined
                          : BASE_URL + project.projectImageUrl
                      }
                      projectName={project.projectName}
                      clientName={project.clientName}
                      location={project.location}
                      description={project.description}
                      isPublicProfile={isPublicProfile}
                      projectData={project}
                      supplierId={supplierId}
                      permissions={permissions}
                      projectId={project._id}
                    />

                  </Grid>
                )
            )}
          </Slick>
      )}
      {/* </Grid> */}
    </Container>
  );
}

ProfileHallOfFame.propTypes = {
  projects: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool,
  supplierId: PropTypes.number,
  permissions: PropTypes.object,
};

export default ProfileHallOfFame;
