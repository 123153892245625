import axios from "utils/axios";

import {
  REGISTER_USER_PROFILE_URL,
  UPDATE_USER_PROFILE_URL,
  NEW_COMPANY_URL,
  GST_INFO_URL,
} from "config/services";

class RegisterService {
  registerUserProfile = (newUserInfo) =>
    new Promise((resolve, reject) => {
      // console.log("registerService:registerUserProfile:", newUserInfo);

      axios
        .post(REGISTER_USER_PROFILE_URL, {
          ...newUserInfo,
          password: "Test1234",
        })
        .then((response) => {
          // console.log(
          //   "registerService:registerUserProfile:: Response",
          //   response?.data
          // );

          if (response?.data?.error) {
            // console.log(
            //   "registerService:registerUserProfile:",
            //   response?.error,
            //   response?.data
            // );
            reject(response?.data?.message);
          } else {
            if (response?.data?.payload) {
            }
            // resolve(user);
            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.data);
          }

          reject(error);
        });
    });

  updateUserProfile = (newUserInfo) =>
    new Promise((resolve, reject) => {
      // console.log("registerService:updateUserProfile:", newUserInfo);

      axios
        .post(UPDATE_USER_PROFILE_URL, { ...newUserInfo })
        .then((response) => {
          // console.log(
          //   "registerService:updateUserProfile:: Response",
          //   response.data
          // );

          if (response.data.error) {
            // console.log(
            //   "registerService:updateUserProfile:",
            //   response.error,
            //   response.data
            // );
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
            }
            // resolve(user);
            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.data);
          }

          reject(error);
        });
    });

  registerNewCompany = (payload) =>
    new Promise((resolve, reject) => {
      // console.log("registerService:registerNewCompany:", payload);
      axios
        .post(NEW_COMPANY_URL, { payload })
        .then((response) => {
          // console.log(
          //   "registerService:registerNewCompany:: Response",
          //   response.data
          // );

          if (response.data.error) {
            // console.log(
            //   "registerService:registerNewCompany:",
            //   response.error,
            //   response.data
            // );
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
            }
            // resolve(user);
            resolve(response?.data?.payload?.profile);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.data);
          }

          reject(error);
        });
    });

  fetchGstInfo = (gstin) =>
    new Promise((resolve, reject) => {
      // console.log("registerService:fetchGstInfo:", gstin);
      const gstUrl = `${GST_INFO_URL}?gstin=${gstin}`;

      axios
        .get(gstUrl)
        .then((response) => {
          console.log(
            "registerService:updateUserProfile:: Response",
            response.data
          );

          if (response.data.error) {
            // console.log(
            //   "registerService:updateUserProfile:",
            //   response.error,
            //   response.data
            // );
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {

              // const companyName = response?.data?.payload?.legal_name;
              const companyName = response?.data?.payload?.legal_name;
              const annualTurnOver = response?.data?.payload?.aggregate_turn_over.replace("Slab: ", "");
              // const annualTurnOver = response?.data?.payload?.aggregate_turn_over;
              const businessConstitution =
                response?.data?.payload?.business_constitution;
              let businessType = "OTHERS";

              switch (businessConstitution) {
                case "Private Limited":
                  businessType = "Private Limited";
                  break;
                case "Public Limited":
                  businessType = "Public Limited";
                  break;
                case "Proprietorship":
                  businessType = "Proprietorship";
                  break;
                case "Partnership":
                  businessType = "Partnership";
                  break;
                case "Limited Liability Partnership":
                  businessType = "Limited Liability Partnership";
                  break;
                default:
                  businessType = "Others";
              }

              const establishDate = response?.data?.payload?.register_date;
              const establishYear = establishDate?.substr(
                establishDate?.length - 4
              );
              // const addr = response?.data?.payload?.primary_business_address;
              // const address =
              //   response?.data?.payload?.primary_business_address?.detailed_address || 
              //   response?.data?.payload?.primary_business_address?.registered_address;
              const detailedAddress = response?.data?.payload?.primary_business_address?.detailed_address;
              let addressLine1 = "";
              let addressLine2 = "";
              let city = "";
              let state = "";
              let pincode = "";
              if (detailedAddress) {
                const parts = detailedAddress.split(',').map(part => part.trim());
                addressLine1 = parts[0] + parts[1];
                addressLine2 = parts?.length == 5 ? "" : parts[2];
                city = parts[parts.length - 3];
                state = parts[parts.length - 2];
                pincode = parts[parts.length - 1];
              }

              const address = {
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                country: "India",
                pincode: pincode
              };
              resolve({
                companyName,
                businessType,
                address,
                establishYear,
                annualTurnOver
              });
            } // resolve(user);

            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error?.response?.data);
          }

          reject(error);
        });
    });
}

const registerService = new RegisterService();

export default registerService;
