import React from 'react'
import { Typography, Box, Modal } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function ThankYou({ setThankYouPopUp, thankYouPopUp }) {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"))
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMd ? 355 : 400,
        bgcolor: 'background.paper',
        border: 'none',
        outline:"none",
        boxShadow: 24,
        borderRadius: "15px",
        p: isMd ? 3 : 4,
    };
    return (
        <Modal
            open={thankYouPopUp}
            onClose={() => setThankYouPopUp(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography sx={{ fontSize: "24px", fontWeight:600, textAlign:"center" }}>Thank You !</Typography>
                <Typography>We have received your request and we will get back to you shortly.</Typography>
                <Typography>For more info call us at <span style={{fontWeight:400, color:"#3963AB", textDecoration:"underline"}}>9503339814</span>.</Typography>
            </Box>
        </Modal>
    )
}

export default ThankYou