import React from 'react'

const EasyStepsForLg = () => {
  return (
      <div className='flex justify-evenly items-center my-[83px]'>
        <div className='flex space-x-4'>
          <p className='text-white bg-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px]'>1</p>
          <p className='flex items-center text-[#FF6B00] text-[24px] '>Search & Filter </p>
        </div>
        {/* line */}
        <div className='bg-[#ACACAC] h-[1px] w-[72px] flex items-center'></div>
        <div className='flex space-x-4'>
          <p className='bg-white text-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px]'>2</p>
          <p className='flex items-center text-[24px] '>Contacts & Send RFQs </p>
        </div>
        {/* line */}
        <div className='bg-[#ACACAC] h-[1px] w-[72px] flex items-center'></div>
        <div className='flex space-x-4'>
          <p className='bg-white text-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px]'>3</p>
          <p className='flex items-center text-[24px] '>Close Deals Offline</p>
        </div>
      </div>
  )
}

export default EasyStepsForLg
