import registerService from "services/registerService";

import {
  NewUserRegisterInfo,
  UserProfileInfo,
  NewCompanyInfo,
} from "interface/userProfileIf";
import { resolvePath } from "react-router-dom";

import { fetchFavourites } from "./favouritesAction";
import { fetchRequirements } from "./requirementsAction";
import { fetchLeads } from "./leadsAction";
import { fetchOrders } from "./ordersAction";

export const REGISTER_USER = "@register/user";
export const REGISTER_USER_SUCCESS = "@register/user-success";
export const REGISTER_USER_FAILURE = "@register/user-failure";

export const UPDATE_USER_PROFILE = "@user/profile/update";
export const UPDATE_USER_PROFILE_SUCCESS = "@user/profile/update-success";
export const UPDATE_USER_PROFILE_FAILURE = "@user/profile/update-failure";

export const REGISTER_NEW_COMPANY = "@company/register";
export const REGISTER_NEW_COMPANY_SUCCESS = "@company/register-success";
export const REGISTER_NEW_COMPANY_FAILURE = "@company/register-failure";

export const GST_FETCH_INFO = "@company/fetch";
export const GST_FETCH_INFO_SUCCESS = "@company/fetch-success";
export const GST_FETCH_INFO_FAILURE = "@company/fetch-failure";

export function registerUserProfile(
  userId,
  userInfo,
  onSubmitSuccess
) {
  return async (dispatch) => {
    try {
      const newUserInfo = new NewUserRegisterInfo(
        userId,
        userInfo
      );

      dispatch({
        type: REGISTER_USER,
        payload: {
          newUserInfo,
        },
      });
      // console.log("RegisterAction.registerUserProfile:", newUserInfo);
      const user = await registerService.registerUserProfile(newUserInfo);
      // console.log("RegisterAction.registerUserProfile: User:", user);
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: {
          user,
        },
      });
      if(user?.name){
        await onSubmitSuccess()
      }
      // Initialize User Information
      dispatch(fetchFavourites());
      dispatch(fetchRequirements(user?.userId));
      dispatch(fetchLeads(user?.vendorsIn, user?.userId));
      dispatch(fetchOrders());
    } catch (err) {
      // console.log("RegisterAction.registerUserProfile: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: REGISTER_USER_FAILURE });
      throw error;
    }
  };
}

export function updateUserProfile(userInfo) {
  return async (dispatch) => {
    try {
      const newUserInfo = new UserProfileInfo(userInfo);

      dispatch({
        type: UPDATE_USER_PROFILE,
        payload: {
          newUserInfo,
        },
      });
      // console.log("RegisterAction.updateUserProfile:", newUserInfo);

      const user = await registerService.updateUserProfile(newUserInfo);

      // console.log("RegisterAction.registerUserProfile: UserProfile:", user);

      dispatch({
        type: UPDATE_USER_PROFILE_SUCCESS,
        payload: {
          user,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("RegisterAction.updateUserProfile: Error", err);
      dispatch({ type: UPDATE_USER_PROFILE_FAILURE });
      throw err;
    }
  };
}

export function registerNewCompany(data, address) {
  return async (dispatch) => {
    console.log("******",data);
    try {
      const newUserInfo = new NewCompanyInfo(data, address);
      console.log(")))))))))",newUserInfo);

      dispatch({
        type: REGISTER_NEW_COMPANY,
        payload: {
          newUserInfo,
        },
      });
      // console.log("RegisterAction.registerNewCompany:", newUserInfo);

      const profile = await registerService.registerNewCompany(newUserInfo);

      console.log(
        "RegisterAction.registerNewCompany: company profile:****",
        profile
      );

      dispatch({
        type: REGISTER_NEW_COMPANY_SUCCESS,
        payload: {
          profile,
        },
      });
      return profile;

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      console.log("RegisterAction.registerNewCompany: Error*******", err);
      dispatch({ type: REGISTER_NEW_COMPANY_FAILURE });
      return err
    }
  };
}

export function fetchGstInfo(gstin) {
  // console.log("fecthgstinfo:", gstin);
  return async (dispatch) => {
    try {
      dispatch({
        type: GST_FETCH_INFO,
        payload: {
          gstin,
        },
      });
      // console.log("RegisterAction.fetchGstInfo:", gstin);

      const gstInfo = await registerService.fetchGstInfo(gstin);

      // console.log("RegisterAction.fetchGstInfo: fetchGstInfo:", gstInfo);

      dispatch({
        type: GST_FETCH_INFO_SUCCESS,
        payload: {
          gstInfo,
        },
      });

      return gstInfo;

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("RegisterAction.fetchGstInfo: Error", err);
      dispatch({ type: GST_FETCH_INFO_FAILURE });
      throw err;
    }
  };
}
