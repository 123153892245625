import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 24,
  },
  invoiceDate: {
    fontSize: 12,
    // fontStyle: "bold",
    textAlign: "left",
  },
  label: {
    width: 100,
    textAlign: "left",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    marginTop: 10,
    // justifyContent: 'flex-end',
    alignItems: "right",
    textAlign: "left",
    fontWeight: "demibold",
    fontFamily: "Cambria",
  },
  label: {
    width: 60,
    alignItems: "left",
    fontWeight: "heavy",
  },
});

const InvoiceNumber = ({ invoice, number }) => {
  // console.log("INVOICENO",invoice)
  const invoiceNumber = invoice ? `${invoice.orderId}` : undefined;
  // console.log("invoiceNumber",invoiceNumber)

  const date = invoice ? `${invoice.createdAt}` : undefined;

  let timestamp = new Date(date).getTime();
  let Time = new Date(timestamp).getTime();

  let Day = new Date(timestamp).getDate();

  let Month = new Date(timestamp).toLocaleString("default", { month: "short" });
  let Year = new Date(timestamp).getFullYear();
  let OurNewDateFormat = `${Day}-${Month}-${Year}`;

  function getFinancialYear(OurNewDateFormat) {
    const date = new Date(OurNewDateFormat);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (month >= 4) {
      return `${year}-${year + 1}`;
    } else {
      return `${year - 1}-${year}`;
    }
  }
  const financialYear = getFinancialYear(OurNewDateFormat);
  const parts = financialYear.split("-");
  const financialResult = parts[0] + "-" + parts[1].slice(2);
  console.log("financialYear", financialResult);
  return (
    <Fragment>
      {/* <View style={styles.titleContainer}>
            <Text style={styles.label}>Invoice #: {number}</Text>
          
        </View > */}
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>Invoice #: </Text>
        <Text style={{ fontFamily: "Cambria" }}>
          TTS/{invoiceNumber}/{financialResult}
        </Text>
      </View>
    </Fragment>
  );
};

export default InvoiceNumber;
