import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchTopSection from "./SearchTopSection";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FilterCard from "./FilterCard";
import {
  SEARCH_FILTERED,
  filteredSearch,
  searchByPage,
} from "actions/searchActions";
import { getSelectedFacetString, updateSearchFilter } from "utils/searchUtils";
import { useDispatch, useSelector } from "react-redux";
import SearchCard from "./SearchCard";
import queryString from "query-string";
import SearchPagination from "./BusinessPages/SearchPagination";
import SearchCardSkeleton from "./SearchCardSkeleton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import MobileSearchCategory from "./MobileSearchCategory";
import MobileSearchCategorySkeleton from "./MobileSearchCategorySkeleton";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import { getUrl } from "actions/businessActions";
import { FloatingButton } from "views/Home 3.0/components";
import { fetchFavourites } from "actions/favouritesAction";
const BusinessCategoryResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const search = useSelector((state) => state.search);
  const { searchString, region } = useParams(); //get searchString
  const numFound = search?.numFound;
  const [currentPage, setCurrentPage] = useState(1);
  const [isoFilterApplied, setIsoFilterApplied] = useState(false);
  const [gstFilterApplied, setGstFilterApplied] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFacetsData, setSelectedFacetsData] = useState([]); // use for highlight btn of selected
  const [showResults, setShowResults] = useState(false);
  const [openLogInModal, setOpenLogInModal] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [totalPages, setTotalPages] = useState(Math.ceil(numFound / 60));
  const currentLocation = useLocation();
  // console.log(totalPages,"totalPages");
  // console.log(currentPage,"currentPage");
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up(1280));
  const queryParams = queryString.parse(location.search); // get values from url after ?
  const { user } = useSelector((state) => state.auth);

  const { Category } = queryParams; //get catagory data and searchBy from the url for filter string
  const searchby = "all";
  const regionString = region?.replace(/-/g, " ");
  const searchType = search?.searchType || searchby;
  const data = search?.results;
  const searchKeyWord =
    search?.searchKeyWord || searchString?.replace(/-/g, " ");
  const selectedFacets = search.selectedFacets;
  const buttonArray = [
    {
      name: "ISO Certified",
      img: "/assets/home/Iso.svg",
    },
    {
      name: "GST Certified",
      img: "/assets/home/gst.png",
    },
  ];
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const handleIsoFilter = () => setIsoFilterApplied(!isoFilterApplied);
  const handleGstFilter = () => setGstFilterApplied(!gstFilterApplied);

  const handleFacetSelection = (facetTitle, facetValue, isChecked) => {
    //send filter title and value to 2 fun for getting proper filter string
    const filterSet = updateSearchFilter(
      selectedFacets,
      facetTitle,
      facetValue,
      isChecked
    ); // give the array of obj that inclue {facetTitle:facetValue}
    const filterStr = getSelectedFacetString(filterSet); // give slected array they give string for backend
    dispatch(
      filteredSearch(
        searchKeyWord,
        regionString,
        filterStr,
        searchType,
        facetTitle,
        facetValue,
        isChecked
      )
    ); // update data
  };

  const handleSelectedData = (title, label) => {
    const filterName = label == "true" ? title : label; //when iso or gst filter are selected then print button name or title

    //upadte selected filter data if data is avilable on state then remove from array and is not included in array then add this data
    const updatedSelectedFacetsData = selectedFacetsData.includes(filterName)
      ? selectedFacetsData.filter((item) => item !== filterName)
      : [...selectedFacetsData, filterName];

    setSelectedFacetsData(updatedSelectedFacetsData);
    handleFacetSelection(
      title,
      label,
      !selectedFacetsData.includes(filterName)
    ); // send data to fun is alredy include array then give false value is not then send true value in param
  };

  const handleGetDataFromUrlValues = (isSelected) => {
    // isSelected is true or false value when we add filter then true and when we clr filter then false and restet all data
    const filterSet = []; // use for getSelectedFacetString ()
    // get data foam the url ?Category=Contractors&Domains=COMMERCIAL that data store queryParams
    for (const [key, value] of Object.entries(queryParams)) {
      const facetTitle = key.replace(/-/g, " ");

      const processedValues = Array.isArray(value)
        ? value.map((item) => item.replace(/-/g, " "))
        : [value.replace(/-/g, " ")]; // we want array so we add this is array box

      processedValues.forEach((facetValue) => {
        // send one by one filter to redux store
        dispatch({
          type: SEARCH_FILTERED,
          payload: { facetTitle, facetValue, isSelected },
        });
      });

      filterSet.push({ facet: facetTitle, values: processedValues }); // make one array of all selected filters for sending the url to backend
    }

    const filterStr = getSelectedFacetString(filterSet); // give converted filter string [businessProfile.businessCategory_str:(Category)] like this
    dispatch(
      searchByPage(
        searchKeyWord,
        regionString,
        filterStr,
        searchType,
        currentPage
      )
    );
  };

  const convertFacetsIntoString = () => {
    return selectedFacets
      ?.map((obj) =>
        obj?.values
          ?.map(
            (value) =>
              `${obj?.facet?.replace(/ /g, "-")}=${value?.replace(/ /g, "-")}`
          )
          .join("&")
      )
      .join("&");
  };

  useEffect(() => {
    handleGetDataFromUrlValues(true); //send true add the filter to redux as true
    const filteredValues = Object.values(queryParams)
      .flat()
      .map((value) => value.replace(/-/g, " "));

    setSelectedFacetsData(filteredValues);
    dispatch(fetchFavourites());
  }, []);

  useEffect(() => {
    // upadte url when filter is applyed
    // get slectedFacets then give map on here in that obj facetTitle with array of facetValue so we use 2 maps
    const queryString = convertFacetsIntoString();
    // const searchByString = searchType || "all";
    navigate(
      `/search-business-category/${searchString}/${region}?${queryString}`
    );
  }, [selectedFacets]);

  useEffect(() => {
    try {
      dispatch(getUrl(currentLocation.pathname));
    } catch (error) {
      // console.log(error);
    }
    if (data.length > 0) {
      window.scrollTo(0, 0);
      setShowResults(true); // show result not skeleton
    }
  }, [data]);

  return (
    <>
      <Grid backgroundColor="#EFF7FA" minHeight="100vh" paddingBottom={"20px"}>
        <SearchTopSection
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          searchKeyWord={searchKeyWord}
          searchType={searchType}
          numFound={search?.numFound}
          showBackBtn={true}
        />
        <Grid
          padding={"0px 24px 10px"}
          paddingTop={isMd ? "12px" : "10px"}
          display="flex"
          flexWrap={"wrap"}
          position={"sticky"}
          top={isSm ? "180px" : "115px"}
          backgroundColor={"#EFF7FA"}
          justifyContent="space-between"
          zIndex={"4"}
        >
          <Grid
            style={{
              display: "",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {buttonArray?.map((item) => (
              <>
                <Button
                  sx={{
                    backgroundColor: selectedFacetsData.includes(item.name)
                      ? "rgba(40, 100, 190,0.2)"
                      : "white",
                    height: "32px",

                    color: "black",
                    marginTop: isSm ? "10px" : "",
                    marginBottom: "10px",
                    borderRadius: "3px",
                    border: selectedFacetsData.includes(item.name)
                      ? " 1px solid rgba(40, 100, 190,1)"
                      : "1px solid  rgba(212, 232, 228, 1)",
                    marginRight: "18px",
                    boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => handleSelectedData(item.name, "true")}
                >
                  <Box
                    component="img"
                    alt="Filter image"
                    src={item.img}
                    paddingRight="7px"
                  />
                  <Typography
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {item.name}
                  </Typography>
                  {selectedFacetsData.includes(item.name) && (
                    <CloseIcon
                      style={{
                        height: "18px",
                        width: "18px",
                        color: "#8C9091",
                        marginLeft: "6px",
                      }}
                    />
                  )}
                </Button>
              </>
            ))}
            {!isSm && (
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "15px",
                    width: "70px",
                    // marginTop: "4px",
                    // marginLeft: "0px",
                  }}
                  src="/assets/home/verified.png"
                />
                <Typography style={{ marginLeft: "8px", fontSize: "12px" }}>
                  : Vendor has been verified for their services and contact
                  details in their profile.
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid>
            <Button
              onClick={toggleDrawer}
              style={{
                marginTop: isSm ? "10px" : "",
                backgroundColor: "white",
                width: "120px",
                height: "32px",
                color: "black",
                borderRadius: "3px",
                border: "1px solid  rgba(212, 232, 228, 1)",
                marginRight: "18px",
                boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  paddingRight: "6px",
                }}
              >
                Adv. Filters
              </Typography>
              <Box
                component="img"
                alt="Filter image"
                src="/assets/home/FilterIcon.svg"
              />
            </Button>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
              <FilterCard
                handleIsoFilter={handleIsoFilter}
                handleGstFilter={handleGstFilter}
                handleSelectedData={handleSelectedData}
                selectedFacetsData={selectedFacetsData}
                data={search}
                searchKeyWord={searchKeyWord}
                Category={Category}
                setSelectedFacetsData={setSelectedFacetsData}
                toggleDrawer={toggleDrawer}
                handleGetDataFromUrlValues={handleGetDataFromUrlValues}
                regionString={regionString}
              />
            </Drawer>
          </Grid>
        </Grid>
        {isSm && (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              marginLeft: "24px",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "17px",
                width: "70px",
                // marginTop: "4px",
                marginLeft: "0px",
              }}
              src="/assets/home/verified.png"
            />
            <Typography style={{ marginLeft: "7px", fontSize: "10px" }}>
              : Vendor has been verified for their services and contact details
              in their profile.
            </Typography>
          </Grid>
        )}
        {search.isSearching ? (
          <Box
            sx={{
              position: "absolute",
              top: "55%",
              left: "55%",
              transform: "translate(-50%,-50%)",
              zIndex: 999,
            }}
          >
            <CircularProgress sx={{ color: "#FD6600" }} />
          </Box>
        ) : search?.numFound < 1 ? (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isSm ? "100px" : "70px",
            }}
          >
            <Box component="img" src="/assets/home/NoResultFoundImg.svg" />
          </Grid>
        ) : isSm ? (
          <Grid
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {!showResults ? (
              <MobileSearchCategorySkeleton />
            ) : (
              data?.map((item) => (
                <MobileSearchCategory
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  data={item}
                  currentPage={currentPage}
                />
              ))
            )}
          </Grid>
        ) : (
          <Grid paddingX="24px" display="flex" flexWrap="wrap" gap="16px">
            {!showResults ? (
              <>
                <SearchCardSkeleton />
                <SearchCardSkeleton />
              </>
            ) : (
              <>
                {data?.map((item) => (
                  <Grid key={item.id}>
                    <SearchCard
                      checkedItems={checkedItems}
                      setCheckedItems={setCheckedItems}
                      BusinessData={item}
                      searchString={searchString}
                      searchBy={searchType || "all"}
                      filterUrl={convertFacetsIntoString()}
                      region={region}
                      currentPage={currentPage}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        )}
      </Grid>
      <Grid style={{ marginTop: "auto" }}>
        {(data?.length >= 60 || totalPages > 1) && (
          <SearchPagination
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            search={search}
            regionString={regionString}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </Grid>
      {!user && (
        <div
          style={{
            backdropFilter: "blur(2px)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent gray background
            zIndex: 999,
          }}
        >
          <LogInMobilePopUp
            setOpenLogInModal={setOpenLogInModal}
            handleCloseLogInModal={() => {
              setOpenLogInModal(false);
              navigate("/");
            }}
            openLogInModal={openLogInModal}
          />
        </div>
      )}
      <FloatingButton />
    </>
  );
};

export default BusinessCategoryResults;
