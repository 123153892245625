import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const AddEmail = ({
  emails,
  setEmails,
  openAddEmailDialog,
  setOpenAddEmailDialog,
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);

  const validateEmail = (email) => {
    const validationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = validationRegex.test(email) &&
        !/\.(com|in)[^a-zA-Z0-9]/.test(email) &&
        !/\.{2,}/.test(email) &&
        !/\.,com$/.test(email);

    return isValid;
  };
  const handleAddEmail = () => {
    if (validateEmail(newEmail)) {
      if (emails?.includes(newEmail)) {
        setDuplicateError(true);
        setTimeout(() => {
          setDuplicateError(false);
        }, 3000);
      } else {
        setEmails((preVal) => {
          return [newEmail, ...preVal];
        });
        setOpenAddEmailDialog(false);
        setNewEmail("");
      }
    } else {
      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
      }, 3000);
    }
  };
  const handleCloseAddEmailDialog = () => {
    setOpenAddEmailDialog(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddEmail();
    }
  };
  return (
    <Dialog
      open={openAddEmailDialog}
      onClose={handleCloseAddEmailDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Email To CC</DialogTitle>
      <DialogContent>
        <DialogContentText>Add one email at a time</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={newEmail}
          error={emailError || duplicateError}
          helperText={
            duplicateError
              ? "Email is already exist"
              : emailError
              ? "Please enter a valid email"
              : ""
          }
          onKeyDown={handleKeyDown}
          onChange={(e) => setNewEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAddEmailDialog}>Cancel</Button>
        <Button onClick={handleAddEmail}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEmail;
