import { Grid, Snackbar, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { sendNewRFQTrigger } from "actions/requirementsAction";
import { fetchTag } from "actions/tagsActions";
import { getUrl, hideContactDetails } from "actions/businessActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import FavoritesDialogBox from "views/Business/components/FavoritesDialogBox";
import SignInMobileOtpPopUp from "views/Auth/SignInSendMobileOTP/SignInMobileOtpPopUp";
import { getIsPremium } from "utils/accessControl";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import VerificationPopUp from "views/VerificationModal";
import ShortlistedTags from "../ShortlistedTags";
import RequirementFormModal from "views/RequirementFormModal/RequirementFormModal";
import NonEntityRequirmentForm from "views/RequirementFormModal/NonEntityRequirmentForm";
function SendEnquiryButton({ profile, isMobileView, isEditView }) {
  const [showNumber, setShowNumber] = useState(false);
  const [openLogInModal, setOpenLogInModal] = useState(false);
  const [openFavoritesTag, setOpenFavoritesTag] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const isPremium = getIsPremium(profile?.subscriptionType);
  const favourite = useSelector((state) => state.favourite.favourites);
  const business = useSelector((state) => state.business);
  const count = business?.contactsData?.count;
  const tagsData = favourite.filter(
    (data) => data.supplierId == profile?.supplierId
  );
  const [openSendRfqModel, setOpenSendRfqModel] = useState(false);
  const userId = auth?.user?.userId;

  const businessName = profile?.businessName;
  const businessProfile = profile?.businessProfile;
  const supplierId = profile?.supplierId;
  const isISO = businessProfile?.certificates;
  const whatsappMobileNumber = businessProfile?.businessMobile;

  const userIdOfOwner = profile?.owners?.[0];
  const isImpProfile =
    businessProfile?.businessCategory == "Real Estate Developer" ||
    businessProfile?.businessCategory == "EPC Contractor";

  const maskNumber = (number) => {
    if (number) {
      const numberStr = number.toString();
      const maskedNumber =
        numberStr.substring(0, 2) +
        "XXXXXX" +
        numberStr.substring(numberStr.length - 2);
      return maskedNumber;
    } else {
      return number;
    }
  };

  const contact = maskNumber(businessProfile?.contact);

  const handleClickShowOwnerDetails = (type) => {
    if (!userProfile) {
      setOpenLogInModal(true);
      return;
    } else if (userProfile?.isVerified === false) {
      setIsModalOpen(true);
    } else {
      setShowNumber(type === "mobile");
    }
  };

  const handleClickSave = (event) => {
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));
      setOpenLogInModal(true);
    } else if (userProfile?.isVerified === false) {
      setIsModalOpen(true);
    } else {
      dispatch(fetchTag());
      setOpenFavoritesTag(true);
    }
  };

  const handleClick = (event, data) => {
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));

      setOpenLogInModal(true);
    } else if (userProfile?.isVerified === false) {
      setIsModalOpen(true);
    } else {
      const supplierData = [];
      supplierData.push({
        supplierId: profile?.supplierId,
        businessName: profile?.businessName,
        businessOwnerUserId: userIdOfOwner,
        isPremium: isPremium,
        businessEmail: businessProfile?.businessEmail,
      });
      dispatch(sendNewRFQTrigger(supplierData));
      setOpenSendRfqModel(true);
      // navigate("/enquiry");
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSave(false);
  };

  const handleFavoritesTagClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFavoritesTag(false);
  };

  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };
  const handleClickToContact = () => {
    if (!userProfile) {
      setOpenLogInModal(true);
    } else {
      const supplierDetails = {
        supplierId: supplierId,
        contact:
          profile?.businessProfile?.contact ??
          profile?.businessProfile?.businessMobile,
        supplierUrl: profile?.url,
      };
      dispatch(hideContactDetails(userId, supplierDetails, entityId));
      setShowContactDetails(true);
      setShowMessage(true);
    }
  };

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
          marginBottom: "20px",
          paddingX: "16px",
        }}
      >
        {/* <Grid
          sx={{
            border: "1px solid rgba(217, 217, 217, 1)",
            borderRadius: "10px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "147px",
            }}
          >
            <Box
              component="img"
              alt="phone"
              src="/assets/home/questions.gif"
              style={{ width: "34px", marginRight: "8px" }}
            />
            {userProfile && userProfile.isVerified && (
              <Typography variant="businessCard">
                {isImpProfile
                  ? "Not Disclosed"
                  : contact
                    ? contact
                    : "Not Disclosed"}
              </Typography>
            )}

            {userProfile && !userProfile.isVerified && (
              <Typography
                onClick={() => handleClickShowOwnerDetails("mobile")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#3963AB",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Hidden
              </Typography>
            )}
            {!userProfile && (
              <Typography
                onClick={() => handleClickShowOwnerDetails("mobile")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#3963AB",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Login to view
              </Typography>
            )}
          </Grid>
        </Grid> */}

        {/* {contact && !isImpProfile && (
          <a
            style={{ textDecoration: "none" }}
            href={`https://api.whatsapp.com/send?phone=91${whatsappMobileNumber}&text=Hello%2C%20I%20reviewed%20your%20business%20%22${businessName}%22%20on%20Neevay%20platform%20and%20would%20like%20to%20chat%20with%20you%20for%20my%20construction%20project%20requirements.`}
            target="_blank"
          >
            <Grid
              sx={{
                borderRadius: "10px",
                border: " 1px solid rgba(217, 217, 217, 1)",
                cursor: "pointer",
                width: "74px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src="/assets/home/WhatsApplogo.svg"
                style={{
                  width: "28px",
                  paddingLeft: "6px",
                }}
              />
              <Box
                variant="outlined"
                size="medium"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "black",
                  padding: "6px",
                }}
              >
                Chat
              </Box>
            </Grid>
          </a>
        )} */}
        {isImpProfile ? (
          ""
        ) : userProfile && !userProfile.isVerified ? (
          <Grid
            sx={{
              borderRadius: "10px",
              border: " 1px solid rgba(217, 217, 217, 1)",
            }}
          >
            <Box
              onClick={(e) => isImpProfile || handleClickSave(e)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px",
                borderRadius: "20px",
                width: tagsData[0]?.tags?.length > 0 ? "110px" : "100px",
                height: "35px",
                cursor: isImpProfile ? "" : "pointer",
              }}
            >
              <Box
                component="img"
                src="/assets/home/ShortListedIcon.svg"
                style={{
                  width: "16px",
                  height: "12px",
                  color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                }}
              />
              <Box
                variant="outlined"
                size="medium"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                  textAlign: "center",
                  lineHeight: "16px",
                }}
              >
                {tagsData[0]?.tags?.length > 0 ? "shortlisted" : "Shortlist"}
              </Box>
            </Box>
          </Grid>
        ) : (
          <>
            <Grid
              sx={{
                borderRadius: "10px",
                border: " 1px solid rgba(217, 217, 217, 1)",
              }}
            >
              <Box
                onClick={(e) => isImpProfile || handleClickSave(e)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "6px",
                  borderRadius: "20px",
                  width: tagsData[0]?.tags?.length > 0 ? "110px" : "100px",
                  height: "35px",
                  cursor: isImpProfile ? "" : "pointer",
                }}
              >
                <Box
                  component="img"
                  src="/assets/home/ShortListedIcon.svg"
                  style={{
                    width: "16px",
                    height: "12px",
                    color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                  }}
                />
                <Box
                  variant="outlined"
                  size="medium"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                    textAlign: "center",
                    lineHeight: "16px",
                  }}
                >
                  {tagsData[0]?.tags?.length > 0 ? "shortlisted" : "Shortlist"}
                </Box>
              </Box>
            </Grid>

            {entityId ? (
              <Grid>
                {!showContactDetails ? (
                  <Grid
                    sx={{
                      borderRadius: "10px",
                      border: " 1px solid rgba(217, 217, 217, 1)",
                    }}
                  >
                    <Box
                      onClick={handleClickToContact}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingX: "12px",
                        gap: "6px",
                        borderRadius: "20px",
                        height: "35px",
                        cursor: isImpProfile ? "" : "pointer",
                      }}
                    >
                      <Box
                        variant="outlined"
                        size="medium"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: isImpProfile
                            ? "rgba(152, 152, 152, 1)"
                            : "black",
                          textAlign: "center",
                          lineHeight: "16px",
                        }}
                      >
                        View Contact Details
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  <Grid style={{ display: "flex", gap: "8px" }}>
                    {profile?.businessProfile?.businessMobile && (
                      <Grid
                        sx={{
                          borderRadius: "10px",
                          border: " 1px solid rgba(217, 217, 217, 1)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingX: "8px",
                            gap: "6px",
                            borderRadius: "20px",
                            height: "30px",
                            // cursor: isImpProfile ? "" : "pointer",
                          }}
                        >
                          <Box
                            variant="outlined"
                            size="medium"
                            sx={{
                              fontSize: "14px",
                              // fontWeight: "600",
                              color: isImpProfile
                                ? "rgba(152, 152, 152, 1)"
                                : "black",
                              textAlign: "center",
                              lineHeight: "16px",
                            }}
                          >
                            {profile?.businessProfile?.businessMobile}
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {profile?.businessProfile?.businessEmail && (
                      <Grid
                        sx={{
                          borderRadius: "10px",
                          border: " 1px solid rgba(217, 217, 217, 1)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingX: "8px",
                            gap: "6px",
                            borderRadius: "20px",
                            height: "30px",
                            // cursor: isImpProfile ? "" : "pointer",
                          }}
                        >
                          <Tooltip
                            title={profile?.businessProfile?.businessEmail}
                          >
                            <Box
                              variant="outlined"
                              size="medium"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px",
                                fontSize: "14px",
                                // fontWeight: "600",
                                color: isImpProfile
                                  ? "rgba(152, 152, 152, 1)"
                                  : "black",
                                textAlign: "center",
                                lineHeight: "16px",
                              }}
                            >
                              {profile?.businessProfile?.businessEmail}
                            </Box>
                          </Tooltip>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid>
                {!showContactDetails ? (
                  <Grid
                    sx={{
                      borderRadius: "10px",
                      border: " 1px solid rgba(217, 217, 217, 1)",
                    }}
                  >
                    <Box
                      onClick={handleClickToContact}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingX: "12px",
                        gap: "6px",
                        borderRadius: "20px",
                        height: "35px",
                        cursor: isImpProfile ? "" : "pointer",
                      }}
                    >
                      <Box
                        variant="outlined"
                        size="medium"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: isImpProfile
                            ? "rgba(152, 152, 152, 1)"
                            : "black",
                          textAlign: "center",
                          lineHeight: "16px",
                        }}
                      >
                        View Contact Details
                      </Box>
                    </Box>
                  </Grid>
                ) : count?.length >= 20 &&
                  !count?.some((id) => id === supplierId) ? (
                  <Grid>
                    <Typography style={{ fontSize: "10px" }}>
                      **Free vendor contacts viewing limit reached for today.
                    </Typography>
                    <Typography style={{ fontSize: "10px" }}>
                      Become a Neevay entity for unlimited access.
                    </Typography>
                  </Grid>
                ) : (
                  count?.some((id) => id === supplierId) && (
                    <Grid style={{ display: "flex", gap: "8px" }}>
                      {profile?.businessProfile?.businessMobile && (
                        <Grid
                          sx={{
                            borderRadius: "10px",
                            border: " 1px solid rgba(217, 217, 217, 1)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingX: "8px",
                              gap: "6px",
                              borderRadius: "20px",
                              height: "30px",
                              // cursor: isImpProfile ? "" : "pointer",
                            }}
                          >
                            <Box
                              variant="outlined"
                              size="medium"
                              sx={{
                                fontSize: "14px",
                                // fontWeight: "600",
                                color: isImpProfile
                                  ? "rgba(152, 152, 152, 1)"
                                  : "black",
                                textAlign: "center",
                                lineHeight: "16px",
                              }}
                            >
                              {profile?.businessProfile?.businessMobile}
                            </Box>
                          </Box>
                        </Grid>
                      )}

                      {profile?.businessProfile?.businessEmail && (
                        <Grid
                          sx={{
                            borderRadius: "10px",
                            border: " 1px solid rgba(217, 217, 217, 1)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingX: "8px",
                              gap: "6px",
                              borderRadius: "20px",
                              height: "30px",
                              // cursor: isImpProfile ? "" : "pointer",
                            }}
                          >
                            <Box
                              variant="outlined"
                              size="medium"
                              sx={{
                                fontSize: "14px",
                                // fontWeight: "600",
                                color: isImpProfile
                                  ? "rgba(152, 152, 152, 1)"
                                  : "black",
                                textAlign: "center",
                                lineHeight: "16px",
                              }}
                            >
                              {profile?.businessProfile?.businessEmail}
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  )
                )}
                {showMessage &&
                  count?.length <= 20 &&
                  count?.some((id) => id === supplierId) && (
                    <Grid marginTop={"4px"}>
                      <Typography
                        style={{ marginLeft: "4px", fontSize: "10px" }}
                      >
                        **You are viewing {count.length}/20 free vendor contacts
                        today.
                      </Typography>
                      <Typography
                        style={{ marginLeft: "4px", fontSize: "10px" }}
                      >
                        Become a Neevay entity for unlimited access.
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            )}
          </>
        )}
      </Grid>

      <ShortlistedTags profile={profile} isEditView={isEditView} />

      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          container
          onClick={(e) => isImpProfile || handleClick(e)}
          sx={{
            backgroundColor: isImpProfile
              ? "rgba(217, 217, 217, 1)"
              : "#002E33",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            marginX: "10px",
            cursor: isImpProfile ? "" : "pointer",
            color: "white",
            "&:hover": {
              backgroundColor: isImpProfile ? "" : "#002E33)",
              boxShadow: isImpProfile ? "" : "0px 0px 10px 0px rgba(0,0,0,0.7)",
            },
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 600,
              // color: "white",
            }}
          >
            {entityId ? "Contact Vendor" : "Send Message"}
          </Typography>
        </Box>
        {isImpProfile ? (
          <Typography
            sx={{
              fontSize: "10px",
              paddingTop: "6px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Link of this business are disabled*
          </Typography>
        ) : (
          ""
        )}
      </Grid>
      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />
      {profile?.supplierId && (
        <FavoritesDialogBox
          supplierId={profile?.supplierId}
          businessName={profile?.businessName}
          handleClose={handleFavoritesTagClose}
          open={openFavoritesTag}
          itemOwnerId={userIdOfOwner}
          userProfile={userProfile}
          setOpenSave={setOpenSave}
          businessEmail={profile?.businessProfile?.businessEmail}
          contact={profile?.businessProfile?.contact}
          subscriptionType={profile?.subscriptionType}
          url={profile?.url}
        />
      )}
      <Snackbar
        open={openSave}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Business shortlisted succesfully"
        direction="left"
      />
      <VerificationPopUp
        open={isModalOpen} // Pass the modal visibility state
        handleClose={handleCloseModal} // Pass the close modal function
      />
      {entityId ? (
        <RequirementFormModal
          open={openSendRfqModel}
          setOpen={setOpenSendRfqModel}
        />
      ) : (
        <NonEntityRequirmentForm
          open={openSendRfqModel}
          setOpen={setOpenSendRfqModel}
        />
      )}
    </>
  );
}

export default SendEnquiryButton;
