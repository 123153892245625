import React from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import DashboardLayout from "layouts/Dashboard";
import Page from "views/UserProfile/components/Page";
import { Helmet } from "react-helmet";

import ProjectForm from "./ProjectForm";

function Project({}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1024));
  const isSm = useMediaQuery(theme.breakpoints.down(768));

  const business = useSelector((state) => state.business);
  const supplierId = business.supplierId;

  const projectDataToEdit = business.projectDataToEdit;

  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    navigate("/my-business");
  };

  return (
    <DashboardLayout>
    <Helmet>
      <title>Neevay - My Projects</title>
    </Helmet>
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item>
              <Typography
                variant="h5"
                color={"black"}
                fontWeight="bold"
                mb={2}
                style={{marginTop:isSm&&"20px"}}
              >
                {business.isProjectEdit ? "Edit Project" : "Add Project"}
              </Typography>

              <ProjectForm
                data={projectDataToEdit}
                onSubmitSuccess={handleSubmitSuccess}
              />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
  );
}

Project.propTypes = {};

export default Project;
