import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionPanel,
  AccordionTitle,
} from "flowbite-react";

const Faq = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2
          style={{ width: "575px" }}
          className="mb-12 mt-48 text-4xl pl-1 md:px-0 tracking-tight font-extrabold text-black"
        >
          Still have some doubts?
          <div className="text-4xl pl-2 md:px-0 text-black">
            {" "}
            We can answer all
          </div>
        </h2>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "40px",
        }}
      >
        <div style={{ maxWidth: "578px" }}>
          <Accordion collapseAll>
            <AccordionPanel>
              <AccordionTitle>
                {" "}
                <span className="text-xl text-black">
                  Why should I register myself on Neevay?
                </span>
              </AccordionTitle>
              <AccordionContent>
                <p className="mb-2 text-gray-500 dark:text-gray-300">
                  Classification of Buyer: Anyone who is looking for
                  construction material or construction services for the
                  construction project.
                  <br />
                  <br />
                  Classification of Seller: Anyone who provides the construction
                  material or construction services like contracting, consulting
                  etc for the construction projects.
                  <br />
                  <br />
                  Neevay is “first of its kind” marketplace and search engine of
                  construction industry which connects the buyers and sellers
                  with unique workflow to avoid spamming and irrelevant leads.
                  <br />
                  <br />
                  So, if you are associated to any business (buyer or seller)
                  related to Construction Industry, Neevay is the right platform
                  which provides you an ability to search anything anywhere.
                </p>
              </AccordionContent>
            </AccordionPanel>

            <AccordionPanel>
              <AccordionTitle>
                <span className="text-xl text-black">
                  Why should I create my Business Profile on Neevay ?
                </span>
              </AccordionTitle>
              <AccordionContent>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  If you are providing any material or services to construction
                  projects, you should register create your business profile on
                  Neevay, its Ecosystem and search engine will unlock unlimited
                  growth opportunities for your business with industry specific
                  business profile and search engine.
                </p>
              </AccordionContent>
            </AccordionPanel>

            <AccordionPanel>
              <AccordionTitle>
                <span className="text-xl text-black">
                  Where do I receive the Business Leads for my registered
                  business?
                </span>
              </AccordionTitle>
              <AccordionContent>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  If your business is registered on Neevay, you will receive
                  leads on email, text message and WhatsApp with quick links to
                  acknowledge which triggers an alert to the sender of leads and
                  shows your promptness increasing the confidence score of the
                  deal.
                </p>
              </AccordionContent>
            </AccordionPanel>

            <AccordionPanel>
              <AccordionTitle>
                <span className="text-xl text-black">
                  Is there any hidden cost like commission for the enquiry or
                  percentage of deal value from the portal?
                </span>
              </AccordionTitle>
              <AccordionContent>
                <p className="mb-4 text-gray-500 dark:text-gray-400">
                  You will be surprised to know that there is no hidden cost or
                  commission as percentage of business value involved in the
                  portal. Neevay only connects the buyer and seller, providing
                  them an ability to negotiate outside the portal following
                  their company internal due diligence processes.
                </p>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Neevay’s pricing model is only for the features comes in its
                  subscription plans as fixed cost for the subscription
                  duration.
                </p>
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Faq;
