import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from "utils/axios";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import FavouritesList from "./FavouritesList";
import {
  fetchBusinessById,
  fetchBusinessOwnerEmail,
} from "actions/businessActions";
import businessService from "services/businessService";
import { sendNewRFQTrigger } from "actions/requirementsAction";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import * as XLSX from "xlsx";
import { addLeadActivity } from "actions/crmAction";
import {
  CREATE_CRM_LEAD_URL,
  GET_CRM_LEAD_BY_PHONE_NUMBER,
  GET_USER_DATA_BY_ID,
} from "config/services";
import { updateCrmId } from "actions/authActions";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl } from "@mui/base";
import { makeStyles } from "@mui/styles";
import { fetchFavourites } from "actions/favouritesAction";
import RequirementFormModal from "views/RequirementFormModal/RequirementFormModal";
import { Helmet } from "react-helmet";
import NonEntityRequirmentForm from "views/RequirementFormModal/NonEntityRequirmentForm";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid",
    borderRadius: "50px",
    padding: "3px",
    height: "33px",
  },
  searchBar: {
    flexGrow: 1,
    borderRadius: theme.shape.borderRadius,
  },
}));

function Favourites() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  /// new fav logic here  we get fev data and add new obj key like url , email and contact number
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favourite = useSelector((state) => state.favourite);
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;
  const loading = favourite.loading;
  const favouriteDataObjets = favourite.favourites.map((data) => data); // get fev data
  // console.log(favouriteDataObjets);
  const reversedData = favouriteDataObjets.reverse();
  // console.log(reversedData,"reversedData");
  const [favouriteData, setFavouriteData] = useState([]); // new fev data for row

  const [favouriteTableData, setFavouriteTableData] = useState([]); // new fev data for update after filtering
  const [filterSelectedTags, setFilterSelectedTags] = useState([]); // new fev data new tags data for show in filters btn
  const [selectedRow, setSelectedRow] = useState(0); // display count on enq btn
  const [selectedRowDataId, setSelectedRowDataId] = useState([]); // store suplier id data for pass this data in enq page
  const [isLoading, setIsLoading] = useState(false);
  const [tagSearchQuery, setTagSearchQuery] = useState("");
  const [openSendRfqModel, setOpenSendRfqModel] = useState(false);

  // userprofile
  const userCrmLeadId = userProfile?.crmLeadId;
  const [crmLeadIdOfUser, setCrmLeadIdOfUser] = useState(userCrmLeadId);

  useEffect(() => {
    dispatch(fetchFavourites());
    if (!userCrmLeadId || userCrmLeadId == null) {
      const urlForCrmByMobile = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(
        userProfile?.mobile
      );
      axios
        .get(urlForCrmByMobile)
        .then((responseD) => {
          setCrmLeadIdOfUser(responseD.data[0].ProspectID);
          const crmLeadId1 = responseD.data[0].ProspectID;
          dispatch(updateCrmId(userProfile?.userId, crmLeadId1));
        })
        .catch((error) => {
          // console.log("ERROR-LEAD CREATED",error)
          const payload = [
            {
              Attribute: "EmailAddress",
              Value: userProfile?.email,
            },
            {
              Attribute: "FirstName",
              Value: userProfile?.name,
            },
            {
              Attribute: "Phone",
              Value: userProfile?.mobile,
            },
          ];

          axios.post(CREATE_CRM_LEAD_URL, payload).then((crmResponse) => {
            //   console.log(":CREATE_CRM_LEAD: Response",crmResponse)
            setCrmLeadIdOfUser(crmResponse?.data.Message.Id);
            const crmLeadId2 = crmResponse?.data.Message.Id;
            dispatch(updateCrmId(userProfile?.userId, crmLeadId2));
          });
        });
    }
  }, []);

  const activityData = {
    RelatedProspectId: crmLeadIdOfUser,
    ActivityEvent: 217,
    ActivityNote: "File downloaded",
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: "Profile shortlisted file downloaded",
      },
    ],
  };

  const getSuppliersBusinessData = async () => {
    // let suppliersBusinessData = await Promise.allSettled(
    //   favouriteDataObjets.map(async (data) => {
    //     const payload = await businessService.fetchBusinessById(
    //       data?.supplierId
    //     );
    //     const { profile } = payload;
    //     return profile;
    //   })
    // );

    // suppliersBusinessData = suppliersBusinessData?.map((iteam) => iteam.value);
    // console.log(suppliersBusinessData);
    // const businessProfile = profile.businessProfile
    const updateData = favouriteDataObjets;
    // ?.map((data, i) => ({
    //   ...data,
    //   supplierEmail: suppliersBusinessData[i]?.businessProfile?.businessEmail
    //     ? suppliersBusinessData[i]?.businessProfile?.businessEmail
    //     : "Not Disclosed",
    //   supplierUrl: suppliersBusinessData[i]?.url,
    //   supplierContact: suppliersBusinessData[i]?.businessProfile?.contact
    //     ? suppliersBusinessData[i]?.businessProfile?.contact
    //     : "Not Disclosed",
    //   ownersId: suppliersBusinessData[i]?.owners[0],
    //   isPremium: suppliersBusinessData[i]?.subscriptionType === "PREMIUM",
    // }));
    setFavouriteData(updateData.filter((data) => data.url != undefined));
    setFavouriteTableData(updateData.filter((data) => data.url != undefined));
  };

  ///  nwe fav logic end here

  // remove Duplicates Tags code start froam here
  let allSelectedTags = favouriteData.map((data) => data.tags).flat();
  // allSelectedTags = allSelectedTags.flat();
  const filterTags = [...new Set(allSelectedTags)];
  // remove Duplicates Tags code end froam here

  const filterBtnClick = (tag) => {
    let updatedTags = [];
    if (filterSelectedTags.indexOf(tag) !== -1) {
      updatedTags = filterSelectedTags.filter((tags) => tags !== tag); // if tag are already slected then remove froam array
      setFilterSelectedTags(updatedTags);
    } else {
      updatedTags = [...filterSelectedTags, tag];
      setFilterSelectedTags(updatedTags); // if tag are not in array then push
    }
    const foundTagsFromData = favouriteData.filter((data) => {
      //filter when some one choose filter btn
      return updatedTags.some((tag) => data.tags.includes(tag));
    });
    // we r use if condition for checking updated  tags data is empty then display all tags
    if (updatedTags?.length > 0) {
      setFavouriteTableData(foundTagsFromData);
    } else {
      setFavouriteTableData(favouriteData);
    }
  };

  useEffect(() => {
    console.log("favourites ===", favouriteDataObjets);
    getSuppliersBusinessData();
  }, [favourite.favourites]);

  const getCrmIdsOfBusinessOwners = async (itemOwnerId) => {
    try {
      const response = await axios.post(GET_USER_DATA_BY_ID, {
        userId: itemOwnerId,
      });
      // console.log("GET BUSINESS OWNER USER PROFILE RESPONSE", response);

      if (response?.data?.payload?.crmLeadId) {
        const crmLeadId = response.data.payload.crmLeadId;
        const businessOwnerMobileNumber = response.data.payload.mobile;
        return { crmLeadId, businessOwnerMobileNumber };
      } else {
        const businessOwnerMobileNumber = response.data.payload.mobile;
        const urlForCrmByMobile = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(
          businessOwnerMobileNumber
        );
        try {
          const responseD = await axios.get(urlForCrmByMobile);
          const crmLeadId = responseD.data[0].ProspectID;
          dispatch(updateCrmId(itemOwnerId, crmLeadId));
          const businessOwnerMobileNumber = response.data.payload.mobile;
          return { crmLeadId, businessOwnerMobileNumber };
        } catch (error) {
          const payload = [
            {
              Attribute: "EmailAddress",
              Value: response?.data?.payload?.email,
            },
            { Attribute: "FirstName", Value: response?.data?.payload?.name },
            { Attribute: "Phone", Value: businessOwnerMobileNumber },
          ];

          try {
            const crmResponse = await axios.post(CREATE_CRM_LEAD_URL, payload);
            // console.log(":CREATE_CRM_LEAD: Response", crmResponse);
            const crmLeadId = crmResponse?.data.Message.Id;
            dispatch(updateCrmId(itemOwnerId, crmLeadId));
            const businessOwnerMobileNumber = response.data.payload.mobile;
            return { crmLeadId, businessOwnerMobileNumber };
          } catch (error) {
            // console.error("Error creating CRM lead:", error);
          }
        }
      }
    } catch (error) {
      // console.log("GET BUSINESS OWNER USER PROFILE ERROR", error);
    }
  };
  const handleMultipleBusiness = async () => {
    setIsLoading(true);

    try {
      const selectedRowData = favouriteData.filter((data) =>
        selectedRowDataId.includes(data.supplierId)
      );
      const suppliers = selectedRowData.map((data) => ({
        supplierId: data.supplierId,
      }));
      console.log(selectedRowData, "selectedRowData");
      const result = await dispatch(fetchBusinessOwnerEmail(suppliers));
      console.log("#@#@#@#@#@@@=>", result);
      const uniqueEmailsSet = new Set();
      const userEmails = result.map((item) => {
        // If item.emails is an array, add each email to the set
        if (Array.isArray(item.emails)) {
          item.emails.forEach((email) => uniqueEmailsSet.add(email));
        }
        return {
          supplierId: item.supplierId,
          userEmail: item.emails,
        };
      });
      // Convert Set to an array to get unique emails
      const uniqueEmails = [...uniqueEmailsSet];
      // Open the popup form after fetching data
      setOpenSendRfqModel(true);
      const selectedEnquiryData = selectedRowData.map((map) => {
        const mapBusinessEmail = map?.businessEmail || "";
        // Add ownerEmail from the userEmails array by matching supplierId
        const userEmail =
          userEmails.find((item) => item.supplierId === map.supplierId)
            ?.userEmail || [];
        // Ensure mapBusinessEmail is not duplicated in userEmail
        const emails = Array.from(
          new Set([...userEmail, mapBusinessEmail])
        ).filter((email) => email !== "");
        return {
          supplierId: map?.supplierId,
          businessName: map?.supplierName,
          mobile: map?.contact,
          isPremium: map?.isPremium,
          businessEmail: mapBusinessEmail,
          emails: emails,
        };
      });

      dispatch(sendNewRFQTrigger(selectedEnquiryData));
      // setOpenSendRfqModel(true);
      // navigate("/enquiry");
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle the error if needed
    } finally {
      setIsLoading(false);
    }
  };

  // TODO_SP: Check if there is need to pull data, everytime user lands on the page

  // export data in excel format code start from here
  // const WhitelistUser = [ 1459, 939];
  const WhitelistUser = [
    89, 90, 91, 92, 94, 95, 96, 97, 98, 126, 1010, 1504, 1507, 5145, 8873, 8874,
    9373, 9591, 9609, 9610, 9611, 12113, 12125, 12225, 12236,
  ];
  const exportToExcel = () => {
    const favouriteDataForExcel = favouriteTableData.filter((data) =>
      selectedRowDataId.includes(data.supplierId)
    );
    const modifiedData = favouriteDataForExcel.map(
      ({
        isDeleted,
        _id,
        supplierId,
        supplierUrl,
        supplierEmail,
        supplierContact,
        ...rest
      }) => {
        const tags = rest.tags.join(" ");
        const newUrl = `www.neevay.com/${supplierUrl}`;
        const result = {
          ...rest,
          tags,
          supplierUrl: newUrl,
        };

        if (WhitelistUser.some((user) => user === userProfile.userId)) {
          result.supplierContact = supplierContact;
          result.supplierEmail = supplierEmail;
        }

        return result;
      }
    );

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Shortlisted Business Profiles"
    );

    XLSX.writeFile(workbook, `ShortlistedProfiles.xlsx`);

    // capturing activity in crm
    dispatch(addLeadActivity(activityData));
  };
  // export data in excel format code end here
  // search bar filter tag start
  const handleTagSearchInputChange = (event) => {
    const query = event.target.value;
    setTagSearchQuery(query);
  };

  const classes = useStyles();
  // search bar filter tag end
  useEffect(() => {
    if (entityId) {
      navigate("/company-directory");
    }
  }, [userProfile]);
  return (
    <DashboardLayout path="/shortlisted-profile">
      <Page>
        <Helmet>
          <title>Neevay - Shortlisted Profiles</title>
        </Helmet>
        <Box sx={{ flexGrow: 1, marginBottom: !isMd && "50px" }}>
          <Grid container flexDirection={"column"}>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Typography variant="h4" fontWeight="bold" marginX={"20px"}>
                Shortlisted Business Profiles
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  marginLeft: "20px",
                }}
              >
                Filters
              </Typography>
              <Grid
                className={classes.searchContainer}
                style={{
                  fontSize: "14px",
                  paddingLeft: "10px",
                  marginRight: "20px",
                }}
              >
                <SearchIcon
                  style={{
                    color: "rgba(85, 85, 85, 1)",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
                <TextField
                  className={classes.searchBar}
                  variant="standard"
                  placeholder="Search Tags"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "15px",
                      paddingLeft: "7px",
                    },
                  }}
                  value={tagSearchQuery}
                  onChange={handleTagSearchInputChange}
                />
              </Grid>
            </Grid>
            <Grid style={{ marginBottom: "15px", marginLeft: "-7px" }}>
              {filterTags.length > 0 &&
                filterTags
                  .filter((tag) => {
                    const words = tag.split(" ");
                    return words.some((word) =>
                      word.toLowerCase().startsWith(tagSearchQuery)
                    );
                  })
                  .map((tag) => {
                    const newTag = tag.slice(0, -2); //remove (,) and space
                    const capitalizedTag =
                      newTag.charAt(0).toUpperCase() + newTag.slice(1); // Capitalize first letter
                    return (
                      <>
                        {filterSelectedTags.indexOf(tag) !== -1 ? ( // check this is slected tag or not
                          <Button
                            sx={{ m: "6px", height: "24px" }}
                            variant="contained"
                            onClick={() => filterBtnClick(tag)}
                          >
                            {capitalizedTag}
                          </Button>
                        ) : (
                          <Chip
                            sx={{ m: "6px", height: "24px" }}
                            variant="outlined"
                            onClick={() => filterBtnClick(tag)}
                            label={capitalizedTag}
                          />
                        )}{" "}
                      </>
                    );
                  })}
            </Grid>
            {selectedRow > 0 && (
              <Grid
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // color="secondary"
                  sx={{
                    py: "0.5",
                    px: "1",
                    height: "30px",
                    backgroundColor: "#002E33",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#002E33",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                      color: "white",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                    },
                  }}
                  float="right"
                  onClick={() => handleMultipleBusiness()}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress
                        sx={{ color: "#FD6600" }}
                        size={20}
                        color="inherit"
                      />
                      <span style={{ marginLeft: "8px" }}>Please Wait</span>
                    </>
                  ) : entityId ? (
                    `Send Requirement to ${selectedRow} ${
                      selectedRow < 2 ? "company" : "companies"
                    }`
                  ) : (
                    `Send Message to ${selectedRow} ${
                      selectedRow < 2 ? "company" : "companies"
                    }`
                  )}
                </Button>
                <Button onClick={exportToExcel}>
                  <DownloadForOfflineIcon
                    fontSize="large"
                    style={{
                      color: "#808080",
                      "&:hover": {
                        color: "#808080",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                      },
                    }}
                  />
                </Button>
              </Grid>
            )}
            <Divider />
            <FavouritesList
              loading={loading}
              favourites={favouriteTableData}
              setSelectedRow={setSelectedRow}
              setSelectedRowDataId={setSelectedRowDataId}
            />
            {/* <FavouritesList favourites={favouriteData} /> */}
            {/* {<FavouritesList favourites={favouriteAllData} />} */}
          </Grid>
        </Box>
      </Page>
      {entityId ? (
        <RequirementFormModal
          open={openSendRfqModel}
          setOpen={setOpenSendRfqModel}
        />
      ) : (
        <NonEntityRequirmentForm
          open={openSendRfqModel}
          setOpen={setOpenSendRfqModel}
        />
      )}
    </DashboardLayout>
  );
}

export default Favourites;
