import axios from "utils/axios";

import { ADD_PROJECT_URL, UPDATE_PROJECT_URL, DELETE_PROJECT_URL } from "config/services";

class ProjectService {
  addProject = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post(ADD_PROJECT_URL, { payload })
        .then((response) => {
          // console.log(
          //   "ProjectService.addProject()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ProjectService.addProject() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  updateProject = (payload) =>
    new Promise((resolve, reject) => {
      axios
        // .post("http://localhost:5030/api/supplier/project/update", { payload })
        .post(UPDATE_PROJECT_URL, { payload })
        .then((response) => {
          // console.log(
          //   "ProjectService.updateProject()",
          //   payload,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ProjectService.updateProject() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });

  deleteProject = (supplierId, projectName, id) =>
    new Promise((resolve, reject) => {
      axios
        .post(DELETE_PROJECT_URL, { payload: { supplierId, projectName, id } })
        .then((response) => {
          // console.log(
          //   "ProjectService.deleteProject()",
          //   supplierId,
          //   projectName,
          //   JSON.stringify(response)
          // );

          if (response.data.error) {
            reject(response.data.message);
          } else {
            resolve(response?.data?.payload);
          }
        })
        .catch((error) => {
          // console.log(
          //   "ProjectService.deleteProject() Service(Response Error):",
          //   error
          // );
          reject(error);
        });
    });
}

const projectService = new ProjectService();

export default projectService;
