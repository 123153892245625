// import { AppBar, Button, Toolbar, Typography } from "@mui/material";
// import React from "react";
// import { useSelector } from "react-redux";

// const CdHeader = () => {
//   const auth = useSelector((state) => state.auth);
//   const user = auth?.user;
//   const entityName = user?.userEntity?.entityName;
//   const directoryName = user?.userEntity?.directoryName;
//   return (
//     <AppBar
//       position="static"
//       style={{ height: 80, background: "#fff", justifyContent: "center" }}
//     >
//       <Toolbar style={{ paddingLeft: 20, paddingRight: 46 }}>
//         {/* Left Side */}
//         <div style={{ flex: 1 }}>
//           {/* <Typography
//             fontWeight={500}
//             fontSize={14}
//             lineHeight="16px"
//             letterSpacing={0}
//             textAlign="left"
//             color="#8F8F8F"
//           >
//             Find bidders for Kharadi Housing, Pune
//           </Typography> */}
//           <Typography
//             fontWeight={700}
//             fontSize={16}
//             lineHeight="29px"
//             letterSpacing={0}
//             textAlign="left"
//             color="#000000"
//           >
//             {/* {directoryName} Directory */}
//             Internal Vendors
//           </Typography>
//         </div>

//         {/* Right Side */}
//         {/* <Button
//           variant="contained"
//           style={{
//             backgroundColor: "#1A56DB",
//             color: "white",
//             borderRadius: "12px",
//             height:"38px"
//           }}
//           size="small"
//         >
//           Add New Vendor
//         </Button> */}
//       </Toolbar>
//     </AppBar>
//   );
// };

import React from "react";
// import GlobalSearchBar from "./GlobalSearchBar";
// import SearchNew from "./SearchNew";

const CdHeader = () => {
  return (
    <div className="flex items-center justify-between w-full p-4 bg-white shadow-md">
      <div className="min-w-52">
        <h1 className="text-base font-bold ">Internal Vendors</h1>
      </div>
      {/* <GlobalSearchBar /> */}
      {/* <SearchNew /> */}
      {/* <div className="w-52"> </div> */}
    </div>
  );
};

export default CdHeader;


// export default CdHeader;
