import React from 'react'
import { useNavigate } from 'react-router';

const RFQmngt = () => {
  const navigate= useNavigate()
  const exploreNow = () => {
    navigate("/get-started");
    window.scroll(0, 0);
}

  return (
    <div className="md:flex md:justify-between md:h-[368px]  lg:h-[565px] xs:h-[517px] md:bg-gradient-to-br from-[#903D00] to-[#24242D] xs:bg-[url('https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_mrugesh-shah-LP-unsplash.svg')] xs:bg-cover ">
      <div className='px-8 md:px-0 lg:py-20 md:py-10 md:pl-12 md:bg-transparent xs:bg-[#24242d] xs:bg-opacity-75 h-full'>

        {/* heading for desktop */}
        <p className='hidden xl:block font-bold xl:text-5xl text-white '>Also want to manage vendor <br />
        RFQs in your team ?</p>
        {/* heading for tab to 1280px */}
        <p className='hidden md:block xl:hidden lg:text-[40px] font-bold md:text-[32px] xl:text-5xl text-white '>Want help managing vendor <br />
          requests within your team ?</p>
        {/* heading for mobile */}
        <p className='md:hidden font-bold text-4xl text-white pt-20'>Want to manage <br />vendor requests with<br />your team ?</p>

        {/* text for md and lg*/}
        <p className='hidden md:block lg:pt-[56px] lg:pb-[70px] md:pt-[50px] md:pb-[42px] md:text-lg lg:text-2xl text-white'>Explore our RFQ management package <br /> and automate your bidding processes.</p>
        {/* text for mobile */}
        <p className='md:hidden pt-8 pb-4 text-2xl text-white'>Explore our RFQ management <br /> package  and automate your<br /> bidding processes.</p>

        <button onClick={exploreNow}className='border-l-4 border-[#F16500] bg-gradient-to-r from-[#1B1B26] to-[#0B0B1A] text-white xs:w-[167px] lg:w-[180px] xs:h-[50px] lg:h-[54px] md:h-[44px] md:w-[150px] md:text-base hover:font-bold md:mt-0 text-xl mt-8'>Contact Sales</button>
      </div>
      <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_mrugesh-shah-LP-unsplash.svg" alt="" className='lg:h-[565px] lg:w-[424px] md:w-[276px] object-cover md:flex xs:hidden' />
    </div>
  )
}

export default RFQmngt
