import React from 'react'

const EasyStepsForMd = () => {
    return (
        <div>
            <div className='flex justify-evenly items-center my-[70px]'>
                <div className='flex flex-col justify-center items-center'>
                    <p className='text-white bg-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px]'>1</p>
                    <p className='text-2xl font-bold text-[#FF6B00] whitespace-nowrap pt-4'>Search & Filter</p>
                </div>

                {/* line */}
                <div className='bg-[#ACACAC] h-[1px] w-[72px] flex items-center mb-10'></div>

                <div className='flex flex-col justify-center items-center'>
                    <p className='bg-white text-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px]'>2</p>
                    <p className='text-2xl font-bold whitespace-nowrap pt-4'>Contacts & Send RFQs</p>
                </div>

                {/* line */}
                <div className='bg-[#ACACAC] h-[1px] w-[72px] flex items-center mb-10'></div>

                <div className='flex flex-col justify-center items-center'>
                    <p className='bg-white text-[#FF6B00] text-center flex items-center justify-center text-xl font-extrabold rounded-full h-[42px] w-[42px]'>3</p>
                    <p className='text-2xl font-bold whitespace-nowrap pt-4'>Close Deals Offline</p>
                </div>
            </div>
           
        </div>
    )
}

export default EasyStepsForMd
