import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { QuillEditor } from "components";
import CustomToolbar from "components/QuillCustomTollBar";
import { makeStyles } from "@mui/styles";
import AddAttachmentSection from "./AddAttachmentSection";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: "78px",
    },
  },
}));

const AddNote = ({
  selectedUserId,
  enquiry,
  handleClose,
  setOpen,
  seterrorMessage,
  emails,
  ccEmails,
}) => {
  const [desc, setDesc] = useState("");
  const classes = useStyles();
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };
  return (
    <>
      <Grid>
        <Typography marginY={"8px"}>Add Note</Typography>
        <Grid sx={{ boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)" }}>
          <QuillEditor
            required
            className={classes.editor}
            value={desc}
            onChange={(value) => setDesc(value)}
            modules={modules}
            placeholder={"Description......"}
          />
          <CustomToolbar />
        </Grid>
        <AddAttachmentSection
          selectedUserId={selectedUserId}
          desc={desc}
          enquiry={enquiry}
          handleClose={handleClose}
          setOpen={setOpen}
          seterrorMessage={seterrorMessage}
          emails={emails}
          ccEmails={ccEmails}
        />
      </Grid>
    </>
  );
};

export default AddNote;
