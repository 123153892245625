/**
 *
 * TermsAndCondition
 *
 */

import React, { memo, useEffect } from "react";


import MainLayout from "layouts/Main";
import { Helmet } from "react-helmet";
import TermsAndConditionData from "./TermsAndConditionData";

export function TermsAndCondition() {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Neevay - Terms And Condition</title>
      </Helmet>
      <MainLayout>
        <TermsAndConditionData /> {/* create diff componet bec we will use terms and condition data in another comp also  */}
      </MainLayout>
    </>
  );
}

TermsAndCondition.propTypes = {};

export default memo(TermsAndCondition);
