import React from 'react'
import axios from "utils/axios";
export const GET_REGIONS_SUCCESS = "@region/get-regions";
export const GET_REGIONS_FAILURE = "@region/get-regions";

export const GET_CITIES_SUCCESS = "@region/get-cities"
export const GET_CITIES_FAILURE = "@region/get-cities"



export function regionsActions ()  {
    return async (dispatch) => {
        try {

            axios.get("/api/master/region/state/districts")
                .then((response) => {
                    const payload = response?.data?.payload;
                    const allDistricts = payload?.flatMap((item) => item?.districts).sort();
                    dispatch({
                        type: GET_REGIONS_SUCCESS,
                        allDistricts: allDistricts
                    })
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    return error
                });
        } catch (error) {
            dispatch({
                type: GET_REGIONS_FAILURE,
            })
            throw error
        }
    }
}

export function searchCitiesAction () {
    return async (dispatch) => {
        try {

            axios.get("/api/master/region/get-cities")
                .then((response) => {
                    const payload = response?.data?.payload[0].cities;
                    dispatch({
                        type: GET_CITIES_SUCCESS,
                        allCities: payload
                    })
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    return error
                });
        } catch (error) {
            dispatch({
                type: GET_CITIES_FAILURE,
            })
            throw error
        }
    }
}

