/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  MEMBERS_FETCH,
  MEMBERS_FETCH_SUCCESS,
  MEMBERS_FETCH_FAILURE
} from 'actions/membersAction';

import { SIGNOUT } from 'actions/authActions';

const initialState = {
  members: [],
  count: 0,
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {


    case MEMBERS_FETCH: {
      return produce(state, (draft) => {
        draft.members = [];
        draft.count = 0;
      });
    }
    break;

    case MEMBERS_FETCH_SUCCESS: {
      const { members } = action.payload;
      //console.log("LeadsReducer: LEADS_FETCH_SUCCESS ", Leads, action.payload);

      return produce(state, (draft) => {
        draft.members = members;
        draft.count = members?.length;
      });
    }
    break;

    case MEMBERS_FETCH_FAILURE: {
      return produce(state, (draft) => {
        draft.members = [];
        draft.count = 0;
      });
    }
    break;

    case SIGNOUT: {
      return produce(state, (draft) => {
        draft.members = [];
        draft.count = 0;
      });
    }
    break;


    default: {
      return state;
    }
    break;
  }
};

export default memberReducer;
