import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import BusinessOfferingCard from "./BusinessOfferingCard";
import { addServiceTrigger } from "actions/serviceActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function BusinessOfferingPage({
  profile,
  IsSearchPublicProfile = false,
  PublicProfile,
  permissions,
  isEditView,
}) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down(800));
  const services = profile?.services;
  const businessProfile = profile?.businessProfile;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visibleCards, setVisibleCards] = useState(4);

  const handleAddService = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isOwner) {
        dispatch(addServiceTrigger);
        navigate("/add-service");
      } else {
        // Add logic here if user is not owner
      }
    } else {
      navigate("/subscribe-plan");
    }
  };

  const handleViewMore = () => {
    setVisibleCards((prevVisibleCards) =>
      Math.min(prevVisibleCards + 8, services.length)
    );
  };

  // const handleViewLess = () => {
  //   setVisibleCards((prevVisibleCards) => Math.max(prevVisibleCards - 8, 4));
  // };
  const handleViewLess = () => {
    setVisibleCards(4); // Reset visibleCards to default value
  };
useEffect(()=>{
  setVisibleCards(4)
},[businessProfile])
  return (
    <Grid
      sx={{
        marginY: "33px",
        marginX: "46px",
        paddingX: IsSearchPublicProfile ? "26px" : "",
      }}
    >
      <Typography
        sx={{ fontSize: "20px", fontWeight: 600, marginBottom: "20px" }}
      >
        Business Offerings
      </Typography>
      <Grid>
        {permissions &&
          !permissions.isViewOnly &&
          permissions.isFreeUpdateAllowed && (
            <Typography
              sx={{
                fontSize: "15px",
                cursor: "pointer",
              }}
              color="secondary"
              size="small"
              disabled={!(permissions && permissions.isUpdateAllowed)}
              onClick={handleAddService}
            >
              <Link style={{ textDecoration: "underline", color: "#2864BE" }}>
                {" "}
                Click Here{" "}
              </Link>
              <span style={{ color: "black" }}> to Add Service</span>
            </Typography>
          )}
      </Grid>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        {services?.slice(0, visibleCards).map((data, index) => (
          <BusinessOfferingCard
            key={index}
            imageUrl={
              data.bannerUrl === undefined || data.bannerUrl === ""
                ? undefined
                : data.bannerUrl
            }
            data={data}
            IsSearchPublicProfile={true}
            PublicProfile={PublicProfile}
            permissions={permissions}
            isEditView={isEditView}
            profile={profile}
          />
        ))}
      </div>

      {services?.length > visibleCards ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {visibleCards === services.length ? (
            <button onClick={handleViewLess} style={{  color: "#2864BE",textDecoration:"underline"}}>View Less</button>
          ) : (
            <button onClick={handleViewMore}  style={{  color: "#2864BE",textDecoration:"underline"}}>View More</button>
          )}
        </div>
      ) : (
        visibleCards > 4 && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button onClick={handleViewLess}  style={{  color: "#2864BE",textDecoration:"underline"}}>View Less</button>
          </div>
        )
      )}
    </Grid>
  );
}

export default BusinessOfferingPage;
