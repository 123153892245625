import axios from "utils/axios";

import {
  FAVOURITES_FETCH_URL,
  FAVOURITES_ADD_URL,
  FAVOURITES_REMOVE_URL,
} from "config/services";

class FavouritesService {

  fetchFavourites = () =>
    new Promise((resolve, reject) => {

      // console.log("FavouritesService.fetchFavourites called");

      axios.get(FAVOURITES_FETCH_URL, {})
        .then((response) => {
          // console.log("FavouritesService.fetchFavourites() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload?.favourites);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("FavouritesService.fetchFavourites() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  addFavourites = (supplierId, supplierName, tags, businessEmail, contact, isPremium, url,) =>
    new Promise((resolve, reject) => {
      // console.log("FavouritesService.addFavourites called", tags);

      axios
        .post(FAVOURITES_ADD_URL, { supplierId, supplierName, tags, businessEmail, contact, isPremium, url, })
        .then((response) => {
          // console.log("FavouritesService.addFavourites() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload?.favourites);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("FavouritesService.addFavourites() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });


  removeFavourites = (supplierId, supplierName) =>
    new Promise((resolve, reject) => {
      // console.log("FavouritesService.removeFavourites called", tags);

      axios
        .post(FAVOURITES_REMOVE_URL, { supplierId, supplierName })
        .then((response) => {
          // console.log("FavouritesService.removeFavourites() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload?.item?.items);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("FavouritesService.removeFavourites() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const favouritesService = new FavouritesService();

export default favouritesService;
