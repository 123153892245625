import { QuillEditor } from "components";
import CustomToolbar from "components/QuillCustomTollBar";
import { useFormikContext } from "formik";
import { makeStyles } from "@mui/styles";

import React from "react";
const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 200,
    },
  },
}));
const QuillEditorComponent = ({ fieldName, placeholder }) => {
  const { setFieldValue, values } = useFormikContext();
  const classes = useStyles();
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
    // counter: {
    //   container: '#counter',
    //   unit: 'character'
    // }
  };
  return (
    <div className=" border border-[#DDDDDD] border-solid">
      <QuillEditor
        required
        className={classes.editor}
        value={values[fieldName]}
        onChange={(value) => {
          setFieldValue(fieldName, value);
        }}
        // onBlur={() => handleOnBlur("description", values.description)}
        modules={modules}
        placeholder={placeholder}
        // error={Boolean(touched.description && errors.description)}
        // helperText={
        //   touched.description && errors.description
        //     ? "Please enter discription"
        //     : ""
        // }
      />
      <CustomToolbar />
    </div>
  );
};

export default QuillEditorComponent;
