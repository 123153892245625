import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";


function PrivacyPolicyData() {
    const theme = createTheme();
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" className="center-modal">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        boxShadow: 5,
                        p: 4,
                        borderRadius: 1,
                        mb: 4,
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Privacy Policy
                    </Typography>
                    <br />
                    <br />
                    <Typography>
                        The Privacy Policy of www.neevay.com (hereinafter referred to as
                        “site ") detailed herein below governs the collection, possession,
                        storage, handling and dealing of personal identifiable
                        information/data and sensitive personal data (hereinafter
                        collectively referred to as “information”) of the user(s) of the
                        site. All the user(s) must read and understand this Privacy Policy
                        as it has been formulated to safeguard the user’s privacy. This
                        Privacy Policy also outlines the ways the user(s) can ensure
                        protection of their personal identifiable information. You must
                        accept the contents of this Policy in order to use or continue
                        using our site. This Privacy Policy detailed herein is also
                        applicable to user of the site or mobile application through
                        mobile or any other similar device.
                    </Typography>
                    <br />
                    <br />

                    <Typography>COLLECTION OF INFORMATION</Typography>
                    <br />
                    <br />

                    <Typography>
                        We confirm that we collect that information from you which is
                        required to extend the services available on the site. • At the
                        time of signing up and registration with the site, we collect user
                        information including name, Company name, email address,
                        phone/mobile number, postal address and other business information
                        which may also include business statutory details and tax
                        registration numbers. In this regard, we may also record
                        conversations and archive correspondence between user(s) and the
                        representatives of the site (including the additional information,
                        if any) in relation to the services for quality control or
                        training purposes. • In relation to our paid services, we may
                        collect personal information of a more sensitive nature which
                        includes bank account numbers and related details to facilitate
                        the sale or purchase of the services available on the site. • We
                        also gather and stores the user’s usage statistics such as IP
                        addresses, pages viewed, user behaviour pattern, number of
                        sessions and unique visitors, browsing activities, browser
                        software operating system etc. for analysis, which helps us to
                        provide improved experience and value added services to you. •
                        Once a user registers, the user is no longer anonymous to us and
                        thus all the information provided by you shall be stored,
                        possessed in order to provide you with the requested services and
                        as may be required for compliance with statutory requirements. •
                        User’s registration with us and providing information is intended
                        for facilitating the user(s) in its business. • We retain user
                        provided Information for as long as the Information is required
                        for the purpose of providing services to you or where the same is
                        required for any purpose for which the Information can be lawfully
                        processed or retained as required under any statutory enactments
                        or applicable laws.
                    </Typography>
                    <br />

                    <Typography>
                        User may update, correct, or confirm provided information by
                        logging on to their accounts on the site or by sending a request
                        to customer case. The requested changes may take reasonable time
                        due to verification process and server cache policies. In case you
                        would like to receive a copy of our information held by us for
                        porting to another service, please contact us with your request at
                        the cdpofficer@neevay.com
                    </Typography>
                    <br />
                    <Typography>
                        User(s) may also choose to delete or deactivate their accounts on
                        the site. We will evaluate such requests on a case-to-case basis
                        and take the requisite action as per applicable law. In this
                        regard, please note that information sought to be deleted may
                        remain with us in archival records for the purpose of compliance
                        of statutory enactments, or for any other lawful purpose.
                        Therefore, user(s) are requested to carefully evaluate what types
                        of information they would like to provide to us at the time of
                        registration.
                    </Typography>
                    <br />

                    <Typography>PURPOSE AND USAGE OF INFORMATION</Typography>
                    <br />

                    <Typography>
                        The following are the purposes of collecting the Information: i.
                        For the verification of your identity, eligibility, registration
                        and to provide customized services. ii. For facilitating the
                        services offered/available on the site. iii. For advertising,
                        marketing, displaying & publication. iv. For enabling
                        communication with the user(s) of the site, so that the user(s)
                        may fetch maximum business opportunities. v. For generating
                        business enquiries and trade leads. vi. For sending communications,
                        notifications, newsletters and customized mailers etc. Please get
                        in touch with us at the cdpofficer@neevay.com in case you would
                        like to object to any purpose of data processing. However, please
                        note that if you object or withdraw consent to process data as
                        above, we may discontinue providing you with services through our
                        site.
                    </Typography>
                    <br />

                    <Typography>DISCLOSURE OF INFORMATION</Typography>
                    <br />

                    <Typography>
                        Information we collect from you may be disclosed and transferred
                        to external service providers who we rely on to provide services
                        to us or to you directly. For instance, information may be shared
                        with
                    </Typography>
                    <br />

                    <Typography>
                        • Affiliated companies for better efficiency, more relevancy,
                        innovative business matchmaking and better personalised services.
                        • Government or regulatory or law enforcement agencies, as
                        mandated under statutory enactment, for verification of identity
                        or for prevention, detection, investigation including cyber
                        incidents, prosecution and punishment of offences. • Service
                        provider including but not limited to payment, customer and cloud
                        computing service provider (“Third Party”) engaged for
                        facilitating service requirements of user. • Business partners for
                        sending their business offers to the user(s), which are owned and
                        offered by them solely without involvement of the site.
                    </Typography>
                    <br />

                    <Typography>
                        Links to the websites of any of the above may be available on the
                        site as a convenience to user(s)and the site does not have any
                        control over such websites. The usage of such websites by the user
                        will be governed by their respective Privacy Policies and the
                        present Privacy Policy will not apply to usage of such websites.
                        The user(s) of such websites are cautioned to read the privacy
                        policies of such websites.
                    </Typography>
                    <br />

                    <Typography>
                        Please get in touch with us at the cdpofficer@neevay.com in case
                        you would like to object to any purpose of data processing.
                        However, please note that if you object or withdraw consent to
                        process data as above, we may discontinue providing you with
                        services through our site.
                    </Typography>
                    <br />

                    <Typography>
                        In relation to such disclosures, receiving parties have consented
                        and confirmed that: • There shall be limited disclosure of any
                        Information to its Directors, officers, employees, agents or
                        representatives who have a need to know such Information in
                        connection with the business transaction and are only permitted to
                        use your Information in connection with the said purpose, • They
                        shall keep the Information confidential and secure by using a
                        reasonable degree of care, and • They shall not disclose any
                        Information received by them further and must abide by the Privacy
                        Policy of the site.
                    </Typography>
                    <br />

                    <Typography>
                        Please keep in mind that whenever a user(s) post personal &
                        business information online, the same becomes accessible to the
                        public and the user(s) may receive messages/emails from visitors
                        of the site.
                    </Typography>
                    <br />

                    <Typography>REASONABLE PROTECTION OF INFORMATION</Typography>
                    <br />

                    <Typography>
                        We employ commercially reasonable and industry-standard security
                        measures to prevent unauthorized access, maintain data accuracy
                        and ensure proper use of information we receive.
                    </Typography>
                    <br />

                    <Typography>
                        These security measures are both electronic as well as physical
                        but at the same time no data transmission over the Internet can be
                        guaranteed to be 100% secure.
                    </Typography>
                    <br />

                    <Typography>
                        We strive to protect the User Information, although we cannot
                        guarantee the security of Information furnished/transmitted by the
                        user(s) to us.
                    </Typography>
                    <br />

                    <Typography>
                        We recommend you not to disclose password of your email address,
                        online bank transaction and other important credentials to our
                        employees / agents / affiliates/ personnel, as we do not ask for
                        the same.
                    </Typography>
                    <br />

                    <Typography>
                        We recommend that registered user(s) not to share their site’s
                        account password and also to sign out of their account when they
                        have completed their work. This is to ensure that others cannot
                        access Information of the user(s) and correspondence, if the user
                        shares the computer with someone else or is using a computer in a
                        public place
                    </Typography>
                    <br />

                    <Typography>COOKIES</Typography>
                    <br />

                    <Typography>
                        We, and third parties with whom we partner, may use cookies, pixel
                        tags, web beacons, mobile device IDs, “flash cookies” and similar
                        files or technologies to collect and store information in respect
                        to your use of the site and track your visits to third party
                        websites. We also use cookies to recognize your browser software
                        and to provide features such as recommendations and
                        personalization.
                    </Typography>
                    <br />

                    <Typography>
                        Third parties whose products or services are accessible or
                        advertised through the site, including social media sites, may
                        also use cookies or similar tools, and we advise you to check
                        their privacy policies for information about their cookies and the
                        practices followed by them. We do not control the practices of
                        third parties and their privacy policies govern their interactions
                        with you. Moreover, the detailed information of cookies policy is
                        elaborated in the Cookie Notice.
                    </Typography>
                    <br />

                    <Typography>DATA COLLECTION RELATING TO CHILDREN</Typography>
                    <br />

                    <Typography>
                        We strongly believe in protecting the privacy of children. In line
                        with this belief, we do not knowingly collect or maintain
                        Personally Identifiable Information on our Site from persons under
                        18 years of age, and no part of our Site is directed to persons
                        under 18 years of age. If you are under 18 years of age, then
                        please do not use or access our services at any time or in any
                        manner. We will take appropriate steps to delete any Personally
                        Identifiable Information of persons less than 18 years of age that
                        has been collected on our Site without verified parental consent
                        upon learning of the existence of such Personally Identifiable
                        Information.
                    </Typography>
                    <br />

                    <Typography>
                        If we become aware that a person submitting personal information
                        is under 18, we will delete the account and all related
                        information as soon as possible. If you believe we might have any
                        information from or about a child under 18 please contact us at
                        customer care.
                    </Typography>
                    <br />

                    <Typography>DATA TRANSFERS</Typography>
                    <br />

                    <Typography>
                        User Information that we collect may be transferred to, and stored
                        at, any of our affiliates, partners or service providers which may
                        be inside or outside the country you reside in. By submitting your
                        personal data, you agree to such transfers.
                    </Typography>
                    <br />

                    <Typography>
                        Your Personal Information may be transferred to countries that do
                        not have the same data protection laws as the country in which you
                        initially provided the information. When we transfer or disclose
                        your Personal Information to other countries, we will protect that
                        information as described in this Privacy Policy. We will ensure
                        appropriate contractual safeguards to ensure that your information
                        is processed with the highest standards of transparency and
                        fairness.
                    </Typography>
                    <br />

                    <Typography>QUESTIONS</Typography>
                    <br />

                    <Typography>
                        Please contact us regarding any questions, clarifications, or
                        grievances. Please email us at enquiry@neevay.com
                    </Typography>
                    <br />

                    <Typography>
                        GRIEVANCE OFFICER In accordance with the applicable laws, the
                        details of the Grievance officer are provided below: Email
                        Address: grievances@neevay.com
                    </Typography>
                    <br />

                    <Typography>CHANGES IN PRIVACY POLICY</Typography>
                    <br />

                    <Typography>
                        The policy may change from time to time, so user(s) are requested
                        to check it periodically.
                    </Typography>
                    <br />

                    <Typography>COOKIE NOTICE</Typography>
                    <br />

                    <Typography>
                        At Company, we believe in being clear and open about how we
                        collect and use data related to you. This Cookie Policy applies to
                        any Company product or service that links to this policy or
                        incorporates it by reference. We use cookies and similar
                        technologies such as pixels, local storage and mobile ad IDs
                        (collectively referred to in this policy as “cookies”) to collect
                        and use data as part of our Services, as defined in our Privacy
                        Policy (“Services”) and which includes our sites, communications,
                        mobile applications and off-site Services, such as our ad services
                        and the “Apply with Company” and “Share with Company” plugins or
                        tags. In the spirit of transparency, this policy provides detailed
                        information about how and when we use these technologies. By
                        continuing to visit or use our Services, you are agreeing to the
                        use of cookies and similar technologies for the purposes described
                        in this policy.
                    </Typography>
                    <br />

                    <Typography>COOKIES AND SIMILAR TECHNOLOGIES</Typography>
                    <br />

                    <Typography>
                        A cookie is a small data file. When you visit a website, the
                        website sends the data file to your device and allows the website
                        to identify your device. Like virtually all websites, the Sites
                        use certain types of cookies. First-party cookies are cookies that
                        belong to Company and that we place on your device when you visit
                        the Sites. Third-party cookies are cookies that another party
                        places on your device when you visit the Sites. We allow
                        third-parties to use cookies on the Sites, which they may use to
                        target advertising to you. Third-party cookies and the use of
                        information collected by third-party cookies on the Sites are
                        governed by the privacy policies of the third-parties that set the
                        cookies on your device, even though they are being set on your
                        device when you visit our Sites. Information about and from those
                        third-parties is available to you by using the Cookie Preferences
                        icon on the Sites. We also use other technologies similar to
                        cookies, such as web beacons (sometimes called “tracking pixels”
                        or “clear gifs”), tags and scripts, which may, for example, help
                        us deliver cookies and understand usage and traffic on the Sites.
                    </Typography>
                    <br />

                    <Typography>
                        DURATION OF COOKIES AND SIMILAR TECHNOLOGIES
                    </Typography>
                    <br />

                    <Typography>
                        The length of time a cookie will stay on your device depends on
                        whether the cookie is a session cookie or a persistent cookie.
                        Session cookies are temporary, only stay on your device while your
                        browser is open and are automatically deleted when you close your
                        browser. Persistent cookies stay on your device until they expire
                        or you or your browser delete them. The Sites use both session and
                        persistent cookies. Other similar technologies vary in how long
                        they may stay active as well.
                    </Typography>
                    <br />

                    <Typography>USE OF THESE TECHNOLOGIES</Typography>
                    <br />

                    <Typography>
                        Some are required to enable the Sites to operate. Others help us
                        enhance your experience when using the Sites; for example, they
                        can help us recognize you when you visit one of our Sites,
                        remember your preferences and, overall, give you a more
                        personalized experience. They can make your interactions with our
                        Sites faster and more secure, enable social media interaction or
                        facilitate the delivery of advertising more relevant to your
                        interests. They can also give us a better understanding of how
                        people use our Sites, such as what pages are most popular. COOKIES
                        AND SIMILAR TECHNOLOGIES USED ON THE SITE
                    </Typography>
                    <br />

                    <Typography>
                        We group the cookies and similar technologies used on our Sites
                        into three categories: Required, Functional and Advertising. By
                        using the “Cookie Preferences” icon on the Sites, you can obtain
                        more information about each category and make choices about our
                        use of such technologies during your Site visits.
                    </Typography>
                    <br />

                    <Typography>
                        REQUIRED: Required cookies and technologies enable core
                        functionality on the Sites, such as remembering previous actions
                        when navigating back to a page during a single session. You are
                        not able refuse Required cookies and technologies through our
                        Cookie Preferences icon, but they do not gather information about
                        you that could be used for purposes such as marketing.
                    </Typography>
                    <br />

                    <Typography>
                        FUNCTIONAL: Functional cookies and technologies allow the Sites to
                        remember choices you make, such as your preferred language, to
                        improve your browsing experience. They also allow us to analyse
                        your use of the Sites and to evaluate and improve Site
                        performance.
                    </Typography>
                    <br />

                    <Typography>
                        ADVERTISING: Advertising cookies and similar technologies help us
                        deliver marketing and advertising, and track the performance of
                        our campaigns. Similarly, we and our advertising and other
                        partners may use these technologies to better understand your
                        interests and show you relevant ads on other websites.
                    </Typography>
                    <br />

                    <Typography>UPDATES TO THIS COOKIE NOTICE:</Typography>
                    <br />

                    <Typography>
                        We have provided this Cookie Notice for your general information
                        and use only. Although we may update this Cookie Notice from time
                        to time to reflect changes in our use of cookies and similar
                        technologies, or for other operational, legal or regulatory
                        reasons, the information in this Cookie Notice is subject to
                        change. FOR MORE INFORMATION, CONTACT US:
                    </Typography>
                    <br />

                    <Typography>
                        If you have questions about our use of cookies or similar
                        technologies on the Sites, or if you have any other questions
                        about this Cookie Notice, please contact Company’s Data Protection
                        Officer via email at cdpofficer@neevay.com
                    </Typography>
                    <br />
                </Box>
            </Container>
        </ThemeProvider>
    )
}

PrivacyPolicyData.propTypes = {}

export default PrivacyPolicyData
