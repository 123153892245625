import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import BusinessSendEnquiry from "./BusinessSendEnquiry";
import { getIsPremium } from "utils/accessControl";
import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ImageEditor } from "components";
import SendEnquiryEditView from "views/Business/MyBusiness/SendEnquiryEditView";
import ShortlistedTags from "./ShortlistedTags";

export default function BusinessProfileHead({
  businessDataDetails,
  PublicProfile,
  permissions,
  isEditView,
  profile,
  IsSearchPublicProfile,
}) {
  const isPremium = getIsPremium(profile?.subscriptionType);
  const businessProfile = profile?.businessProfile;
  const isISO = businessProfile?.certificates;

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let defaultImg = "/assets/default/default-search-image.jpg";
  const premiumFlagUrl = "/assets/default/premium_flag_new.png";

  const logoSrcUrl =
    businessProfile?.logoUrl && businessProfile?.logoUrl !== ""
      ? businessProfile?.logoUrl.startsWith("/assets/business")
        ? `${BASE_URL}${businessProfile?.logoUrl}`
        : businessProfile?.logoUrl
      : defaultImg;
  // const maskNumber = (number) => {
  //   if (number) {
  //     const maskedNumber =
  //       number.substring(0, 2) +
  //       "XXXXXXXX" +
  //       number.substring(number.length - 2);
  //     return maskedNumber;
  //   } else {
  //     return number;
  //   }
  // };
  // const gstNumber = maskNumber(profile?.statutory?.gstin);
  const gstNumber = profile?.statutory?.gstin;
  const capitalizeFirstLetter = (str) => {
    if (typeof str === "string") {
      return str
        ?.split(" ")
        ?.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        ?.join(" ");
    } else {
      return str;
    }
  };

  return (
    <Grid sx={{ marginX: IsSearchPublicProfile ? "46px" : "" }}>
      <Grid
        sx={{
          paddingY: "28px",
          display: "flex",
          justifyContent: "space-between",
          marginX: IsSearchPublicProfile ? "20px" : "44px",
        }}
      >
        <Grid sx={{ display: "flex", gap: "25px" }}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "top",
            }}
          >
            {isEditView ? (
              <ImageEditor
                type="logo"
                inImage={logoSrcUrl}
                supplierId={profile?.supplierId}
              />
            ) : (
              <Box
                component="img"
                sx={{
                  overflow: "hidden",
                  border: "1px solid #D9D9D9",
                  borderRadius: "50%",
                  width: "65px",
                  height: "65px",
                }}
                alt="Logo"
                src={logoSrcUrl}
              />
            )}
          </Grid>
          <Grid flexWrap="wrap">
            <Grid sx={{ display: "flex" }}>
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "rgba(10, 102, 194, 1)",
                }}
              >
                {profile?.businessName}
              </Typography>
              {!isEditView && (
                <Grid>
                  {profile?.subscriptionType == "PREMIUM" ? (
                    <Box
                      component="img"
                      sx={{
                        height: "20px",
                        // width: "60px",
                        paddingTop: "3px",
                        paddingLeft: "5px",
                      }}
                      src="/assets/home/verified.png"
                    />
                  ) : (
                    <Box sx={{ height: "17px", marginTop: "8px" }}></Box>
                  )}
                </Grid>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignContent: "center",
                }}
              >
                <Typography
                  // variant="businessTitle"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {permissions &&
                    !permissions.isViewOnly &&
                    permissions.isFreeUpdateAllowed && (
                      <Link
                        to="/edit-business"
                        style={{
                          fontSize: "14px",
                          color: "#2864BE",
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                        Edit Business
                      </Link>
                    )}
                </Typography>
              </Box>
            </Grid>

            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "20px",
                // marginBottom: "18px",
                marginTop: "6px",
              }}
            >
              {businessProfile?.businessCategory.join(" || ")}
            </Typography>
            {businessProfile?.establishYear ? (
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                {/* <Box
                  sx={{
                    height: "6px",
                    width: "6px",
                    borderRadius: "50px",
                    backgroundColor: "grey",
                    marginRight: "8px",
                  }}
                /> */}
                <Typography
                  style={{
                    color: "rgba(68, 64, 64, 1)",
                  }}
                >
                  {new Date().getFullYear() - businessProfile?.establishYear >
                    0 &&
                    `${
                      new Date().getFullYear() - businessProfile?.establishYear
                    } ${
                      new Date().getFullYear() -
                        businessProfile?.establishYear >
                      1
                        ? "Years"
                        : "Year"
                    }  in Business`}
                </Typography>
              </Grid>
            ) : (
              <Typography sx={{ marginBottom: "30px" }}></Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      {isEditView ? (
        // <SendEnquiryEditView
        //   profile={profile}
        //   IsSearchPublicProfile={true}
        //   isEditView={isEditView}
        // />
        ""
      ) : (
        <BusinessSendEnquiry
          profile={profile}
          IsSearchPublicProfile={true}
          isEditView={isEditView}
        />
      )}
      <ShortlistedTags profile={profile} isEditView={isEditView} />
      <Grid
        sx={{
          backgroundColor: "rgba(215, 221, 233, 0.3)",
          borderRadius: "10px",
          height: "97px",
          minwidth: "100%",
          paddingX: "30px",
          paddingY: "17px",
          display: "flex",
          justifyContent: "space-around",
          marginX: "18px",
        }}
      >
        <Grid>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            Establishment in
          </Typography>
          <Typography
            style={{ textAlign: "center", marginTop: "14px", fontWeight: 600 }}
          >
            {businessProfile?.establishYear
              ? businessProfile?.establishYear
              : "-"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              marginBottom: "14px",
              color: "#555",
              fontSize: "16px",
            }}
          >
            GST Number
          </Typography>
          <Typography style={{ fontWeight: 600, textAlign: "center" }}>
            {gstNumber
              ? isEditView
                ? profile?.statutory?.gstin
                : gstNumber
              : "NA"}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              marginBottom: "14px",
              color: "#555",
              fontSize: "16px",
            }}
          >
            Annual Turnover
          </Typography>
          <Typography style={{ fontWeight: 600, textAlign: "center" }}>
            {profile?.annualTurnOver
              ? profile?.annualTurnOver
              : "Not disclosed"}
          </Typography>
        </Grid>
        <Grid>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            Company Type
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              marginTop: "14px",
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            {businessProfile?.businessType
              ? businessProfile.businessType
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")
              : "-"}
          </Typography>
        </Grid>
        <Grid>
          <Typography style={{ color: "#555", fontSize: "16px" }}>
            ISO Certification
          </Typography>
          {isISO?.length > 0 ? (
            isISO?.map((item) => (
              <Typography
                style={{
                  textAlign: "center",
                  marginTop: "14px",
                  fontWeight: 600,
                }}
              >
                {item}
              </Typography>
            ))
          ) : (
            <Typography
              style={{
                textAlign: "center",
                marginTop: "14px",
                fontWeight: 600,
              }}
            >
              None
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
