import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Box,
  FormHelperText,
  Button,
  Autocomplete,
} from "@mui/material";
import * as Yup from "yup";

import { updateAddress } from "actions/businessActions";
const OFFICE_TYPE = ["HEAD OFFICE", "BRANCH OFFICE 1", "BRANCH OFFICE 2"];

const BusinessAddressForm = ({ supplierId, address, handleClose, ...rest }) => {
  const dispatch = useDispatch();
  return (
    <>
      <Formik
        initialValues={{
          addressType: "HEAD OFFICE",
          addressLine1: address?.addressLine1,
          addressLine2: address?.addressLine2,
          city: address?.city,
          state: address?.state,
          country: address?.country,
          pincode: address?.pincode,
          // district: "",
          // area: "",
        }}
        validationSchema={Yup.object().shape({
          // addressType: Yup.string().required("Please enter addressType"),
          addressLine1: Yup.string().required("Please Enter Address Line 1 "),
          addressLine2: Yup.string(),
          city: Yup.string().required("Please enter city "),
          state: Yup.string().required("Please enter state"),
          country: Yup.string().required("Please enter country"),
          pincode: Yup.string()
            .matches(/^[1-9][0-9]{5}/, "Please enter valid pincode")
            .min(6)
            .max(6)
            .required(),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await dispatch(updateAddress(supplierId, values));
            //   onSubmitSuccess();
            handleClose();
          } catch (error) {
            // console.log("Form: error", error);

            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} {...rest}>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={12}>
                <TextField
                  name="addressLine1"
                  id="addressLine1"
                  label="Address Line 1"
                  type="addressLine1"
                  value={values.addressLine1}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.addressLine1 && errors.addressLine1)}
                  helperText={touched.addressLine1 && errors.addressLine1}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  name="addressLine2"
                  id="addressLine2"
                  label="Address Line2 "
                  type="addressLine2"
                  value={values.addressLine2}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.addressLine2 && errors.addressLine2)}
                  helperText={touched.addressLine2 && errors.addressLine2}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="city"
                  id="city"
                  label="City"
                  type="city"
                  value={values.city}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="state"
                  id="state"
                  label="state"
                  type="state"
                  value={values.state}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="country"
                  id="country"
                  label="country"
                  type="country"
                  value={values.country}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.country && errors.country)}
                  helperText={touched.country && errors.country}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="pincode"
                  id="pincode"
                  label="Pincode"
                  type="pincode"
                  value={values.pincode}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.pincode && errors.pincode)}
                  helperText={touched.pincode && errors.pincode}
                />
              </Grid>
              <Grid item xs={12}>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  // color="secondary"
                  disabled={isSubmitting}
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                    },
                  }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

BusinessAddressForm.propTypes = {
  supplierId: PropTypes.number,
  address: PropTypes.object,
  handleClose: PropTypes.func,
};

export default BusinessAddressForm;
