import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import AddClient from "./AddClient";
import ClientCard from "./ClientCard";
import { deleteClient } from "actions/clientAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
const ClientSection = ({
  clients,
  supplierId,
  isPublicProfile,
  permissions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClientDelete = (supplierId, clientId, clientName) => {
    dispatch(deleteClient(supplierId, clientId, clientName));
  };
  // const onSubmitSuccess = () => {
  //   navigate("/my-business");
  // };
  const isClientExists = clients?.length > 0 ? true : false;
  return (
    <Grid container>
      <Grid item md={12} xs={12} sm={12} mb={2}>
        <AddClient
          supplierId={supplierId}
          isPublicProfile={isPublicProfile}
          permissions={permissions}
          isClientExists={isClientExists}
        />
      </Grid>
      <Grid container spacing={3} sx={{ml:0}}>
        {clients?.map((client) => (
          <Grid item md={3} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <ClientCard
              supplierId={supplierId}
              clientName={client.clientName}
              clientId={client.clientId}
              clientLogoImage={client.clientLogoImage}
              handleDeleteClient={onClientDelete}
              isPublicProfile={isPublicProfile}
              permissions={permissions}
              // onSubmitSuccess={onSubmitSuccess}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

ClientSection.propTypes = {
  isPublicProfile: PropTypes.bool,
  clients: PropTypes.array.isRequired,
  supplierId: PropTypes.number,
  permissions: PropTypes.object,
};

export default ClientSection;
