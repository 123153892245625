import React from 'react'
import { Grid, Typography,useMediaQuery, useTheme, Box } from '@mui/material';


const Customers = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"))
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const customersData = [
        {
            img: `/business/web-get-started-free-Megha Puranik.webp`,
            star: "/business/web-get-started-free-fourStar.webp",
            desc: "The platform's user-friendly interface and diverse options made our decision-making process smooth and stress-free. A valuable resource for anyone in the construction industry!",
            name: "Megha Puranik",
            designation: "Purchase Head - Vascon Engineers Ltd"
        },
        {
            img: "/business/web-get-started-free-Yogesh Mewani.webp",
            star: "/business/web-get-started-free-halfStar.webp",
            desc: "“Using Neevay portal has been a game-changer for my team. Neevay portal simplified our search for contractors, suppliers, manufacturers and labor contractors in different regions”",
            name: "Yogesh Mewani",
            designation: "Owner - Ganesha Construction"
        },
        {
            img: "/business/web-getstartedfree-gokul-tiwari.webp",
            star: "/business/web-get-started-free-fourStar.webp",
            desc: "“As a s steel seller on Neevay portal has really helped our business. Easy connections with buyers and a straightforward approach make it a valuable resource for any steel seller. Highly recommended!”",
            name: "Gokul Tiwari",
            designation: "Owner - AMP Impex "
        },
    ]
    return (
        <>


            <Grid sx={{
                padding: isMd ? "64px 22px 0px 22px" : "64px 66px 121px 66px",
                background: "#FDFDFB",

            }}>
                <Typography
                    sx={{
                        color: "#1A2024",
                        textAlign: "center",
                        fontFamily: "PP Telegraf",
                        fontSize: isMd ? "24px" : "42px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: isMd ? "28.48px" : "49.98px", /* 118.667% */
                        letterSpacing: "-0.285px",
                        marginBottom: "63px"

                    }}
                >
                    See what Our Customers are saying
                </Typography>
                <Grid
                    container
                    spacing={isMd ? "" : "24px"}
                    display={"flex"}
                    justifyContent={"center"}
                    // flexWrap={"wrap"}
                >
                    {
                        customersData.map((data) => <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} gap={"22px"} alignItems={"center"} maxWidth={"320px"} marginBottom={isMd ? "90px" : ""}>
                            <Box
                                width={"158px"}
                                // height={"139px"}
                                component={"img"}
                                src={`${BASE_URL}/assets${data.img}`}
                            />
                            <Box
                                width={"109px"}
                                // height={"21px"}
                                component={"img"}
                                src={`${BASE_URL}/assets${data.star}`}
                            />
                            <Typography
                                sx={{
                                    height:"170px",
                                    maxWidth:"320px",
                                    color: " #3F3F3F",
                                    textAlign: "center",
                                    fontFamily: "Roboto",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "27px",
                                    letterSpacing: "-0.285px",
                                }}
                            >
                                {data.desc}
                            </Typography>
                            <Grid maxWidth={"226px"}>
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        color: "#000",
                                        fontFamily: "Roboto",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "22.5px", /* 125% */
                                        letterSpacing: "-0.285px",
                                    }}
                                >
                                    {data.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontFamily: "Roboto",
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "22.5px",
                                        letterSpacing: "-0.285px",
                                        textAlign: "center"
                                    }}
                                >
                                    {data.designation}
                                </Typography>
                            </Grid>

                        </Grid>)
                    }
                </Grid>

            </Grid >
        </>
    )
}

export default Customers