import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import { useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import { Box, Card, Grid, Typography } from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { deleteAward } from "actions/awardsAction";

// import { deleteCertificate } from "actions/certificateAction";

// import { ImageEditor } from "components";
import AddAwards from "./AddAwards";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";
// import DeleteAward from "./DeleteAward"
import AwardCard from "./AwardCard";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Awards({
  supplierId,
  certificateId,
  title,
  certificateUrl,
  isPublicProfile,
  permissions,
  awards,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {}, [certificateUrl, permissions, awards]);

  
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [autoPlayState, setAutoPlayState] = useState(true);
  const [openn,SetOpenn]=useState()
  const maxSteps = awards ? awards.length : 0;
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const onAwardDelete = (supplierId, awardId, awardTitle) => {
    dispatch(deleteAward(supplierId, awardId, awardTitle));
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (title, supplierId) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let isAwardExists = false;

  if (awards && awards.length > 0) {
    isAwardExists = true;
  }

  
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} >
        <AddAwards
          sx={{ mt: 4 }}
          permissions={permissions}
          isPublicProfile={isPublicProfile}
          isAwardExists={isAwardExists}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={10} sx={{ mt: 2 }} >
        <Card sx={{ boxShadow:'0px 8px 8px rgba(0, 0, 0, 0.5)' }}>
        {awards && awards.length > 0 &&
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents={false} // Disable swiping with mouse events
            interval={3000} // Auto-play interval
            autoplay={autoPlayState}
          >
            {awards?.map((data) => (
              <AwardCard
                supplierId={supplierId}
                awardTitle={data.awardTitle}
                awardImageUrl={data.awardImage}
                handleDeleteAward={onAwardDelete}
                isPublicProfile={isPublicProfile}
                permissions={permissions}
                isAwardExists={isAwardExists}
                awardId={data.awardId}
                setAutoPlayState={setAutoPlayState}
              />
            ))}
          </AutoPlaySwipeableViews>
}
          {awards?.length > 0 && (
          <MobileStepper
          sx={{".MuiMobileStepper-dotActive": { backgroundColor: "#2979FF" }}}
            steps={maxSteps}
            onChangeIndex={handleStepChange}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
              sx={{color:"secondary.main"}}
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
              sx={{color:"secondary.main"}}
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

Awards.defaultProps = {
  isPublicProfile: true,
};

Awards.propTypes = {
  certificateUrl: PropTypes.string,
  isPublicProfile: PropTypes.bool,
  supplierId: PropTypes.number,
  permissions: PropTypes.object,
  awards: PropTypes.array,
};

export default Awards;
