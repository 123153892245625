import SidePanel from 'layouts/SidePanel';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LeadsFilters from './LeadsFilters';
import { useSelector } from 'react-redux';

const SubmittedQuotations = () => {  // Assuming `leads` is passed as a prop
    const lead = useSelector((state) => state.lead);
    const loading = lead.loading;
    // const leadsCount = leads?.length
    const leads = lead?.leads?.filter((row) =>
        row.status !== "CLOSED" &&
        row.suppliers?.some(supplier => supplier.quotationReceived === true)
      );
    const navigate = useNavigate();
    const [showAll, setShowAll] = useState(false);

    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const handleToggleView = () => {
        setShowAll(prevState => !prevState);
    };

    // Filter the leads based on the required conditions:
    const filteredLeads = (leads || []).filter((lead) => {
        // Ensure the lead is not closed and has a supplier with quotationReceived as true
        return lead.status !== "CLOSED" && 
               lead.suppliers?.some(supplier => supplier.quotationReceived === true);
    });

    const openQuotationForm = (rfq) => {
        console.log("LEAD", rfq);
        switch (rfq?.rfqType) {
            case "material":
                navigate(`/material-form?rfqId=${rfq?.rfqId}&isEdit=true&rfqType=${rfq?.rfqType}`, { state: { rfqData: rfq, isEdit:true, supplierId:rfq?.suppliers[0]?.supplierId } });
                break;

            case "labour":
                navigate(`/labour-form?rfqId=${rfq?.rfqId}&isEdit=true&rfqType=${rfq?.rfqType}`, { state: { rfqData: rfq, isEdit:true, supplierId:rfq?.suppliers[0]?.supplierId } });
                break;

            case "contractor":
                navigate(`/providing-form?rfqId=${rfq?.rfqId}&isEdit=true&rfqType=${rfq?.rfqType}`, { state: { rfqData: rfq, isEdit:true, supplierId:rfq?.suppliers[0]?.supplierId } });
                break;
            default:
                break;
        }
    };

    // Sort filtered leads by createdAt in descending order (latest first)
    const sortedLeads = filteredLeads.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA; // descending order (most recent first)
    });

    // Show all or a subset of leads based on the showAll state
    const displayedLeads = showAll ? sortedLeads : sortedLeads.slice(0, 5); // Show only 5 leads initially

    return (
        <SidePanel>
            <div className="w-full h-screen bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
                <div className="bg-white sticky top-0 z-10 shadow-md p-4">
                    <h1 className="text-base font-bold">Submitted Quotations</h1>
                </div>
                {/* <LeadsFilters /> */}
               { leads?.length === 0 ? <div className=" min-h-[calc(100vh-5rem)] flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <img
                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Search_image.png"
                    alt="Search Image"
                    className="h-[116px] w-[122px] mb-6"
                  />
                  <p className="text-md font-semibold text-[#3C3C3C] text-center">
                  You can't submit any quotations to the buyer.<br/> Please open and submit the quote for the RFQ you received. </p>                  
                </div>
              </div>
              
            
                :<div className="mx-4 pt-4 space-y-3 overflow-y-auto ">
                    <div className="flex items-center space-x-2">
                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/filecheck.svg" alt="" />
                        <h3 className="font-semibold text-sm">Submitted Quotations</h3>
                        <p className="text-[#727269] text-xs">({filteredLeads?.length || 0})</p>
                    </div>
                    <div className="rounded-md bg-white border border-[#DCDCD0] shadow-md py-1">
                        <div className="max-h-[400px] overflow-y-auto">
                            {displayedLeads.map((lead, index) => (
                                <div key={index} className="flex justify-between border-b border-b-[#DCDCD0] py-1 px-4">
                                    <div className="flex items-center 2xl:hidden space-x-2">
                                        <div className="h-[6px] w-[6px] bg-[#15E003] rounded-full"></div>
                                        <p className="text-xs">{lead?.name?.length > 80 ? `${lead.name.slice(0, 80)}...` : lead?.name}</p>
                                    </div>
                                    <div className="hidden 2xl:flex items-center space-x-2">
                                        <div className="h-[6px] w-[6px] bg-[#15E003] rounded-full"></div>
                                        <p className="text-xs">{lead?.name?.length > 120 ? `${lead.name.slice(0, 120)}...` : lead?.name}</p>
                                    </div>
                                    
                                    <div className="flex items-center">
                                        <button className="underline text-[10px] text-blue-600" onClick={() => openQuotationForm(lead)}>View Quotation</button>
                                        <div className="flex items-center space-x-2 px-4">
                                            <div className="2xl:hidden bg-blue-100 bg-opacity-60 flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md">
                                                <div className="h-[6px] w-[6px] bg-[#5279AD] rounded-full"></div>
                                                <p className="truncate">{lead?.userBusinessName?.length > 20 ? `${lead.userBusinessName.slice(0, 20)}...` : lead?.userBusinessName}</p>
                                            </div>
                                            <div className="bg-blue-100 bg-opacity-60 hidden 2xl:flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md">
                                                <div className="h-[6px] w-[6px] bg-[#5279AD] rounded-full"></div>
                                                <p className="truncate">{lead?.userBusinessName?.length > 60 ? `${lead.userBusinessName.slice(0, 60)}...` : lead?.userBusinessName}</p>
                                            </div>
                                            <div className="2xl:hidden bg-lime-100 bg-opacity-60 flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md">
                                                <div className="h-[6px] w-[6px] bg-[#88CC66] rounded-full"></div>
                                                <p className="truncate">{lead?.requesterName?.length > 15 ? `${lead.requesterName.slice(0, 15)}...` : lead?.requesterName}</p>
                                            </div>
                                            <div className="bg-lime-100 bg-opacity-60 hidden 2xl:flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md">
                                                <div className="h-[6px] w-[6px] bg-[#88CC66] rounded-full"></div>
                                                <p className="truncate">{lead?.requesterName?.length > 50 ? `${lead.requesterName.slice(0, 50)}...` : lead?.requesterName}</p>
                                            </div>
                                            {lead?.rfqType && (
                                                <div className={`flex items-center text-[10px] px-2 py-1 gap-1 border rounded-md
                                        ${lead?.rfqType === 'material' ? 'bg-rose-100 bg-opacity-60' :
                                                        lead?.rfqType === 'contractor' ? 'bg-cyan-100 bg-opacity-60' :
                                                            'bg-orange-100 bg-opacity-60'}`}>
                                                    <div className={`h-[6px] w-[6px] rounded-full 
                                            ${lead?.rfqType === 'material' ? 'bg-[#C6546C]' :
                                                            lead?.rfqType === 'contractor' ? 'bg-cyan-400' :
                                                                'bg-orange-400'}`}></div>
                                                    <p>{lead?.rfqType}</p>
                                                </div>
                                            )}
                                        </div>
                                        <p className="text-[10px]">
                                            {lead?.createdAt
                                                ? `${new Date(lead.createdAt).getDate()} ${monthNames[new Date(lead.createdAt).getMonth()]}, ${new Date(lead.createdAt).getFullYear()}`
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>}
            </div>
        </SidePanel>
    );
};

export default SubmittedQuotations;
