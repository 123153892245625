import React from "react";
import { useNavigate } from "react-router";

import DashboardLayout from "layouts/Dashboard";
import Page from "views/UserProfile/components/Page";
import { Helmet } from "react-helmet";

import RequirementForm from "../components/RequirementForm";

function NewRequirement({}) {
  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    navigate("/requirements");
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Neevay - New Requirement</title>
      </Helmet>
      <Page>
        <RequirementForm
          isNewRequirement={true}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </Page>
    </DashboardLayout>
  );
}

NewRequirement.propTypes = {};

export default NewRequirement;
