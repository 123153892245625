import { Delete } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  addProjectTrigger,
  deleteProject,
  editProjectTrigger,
} from "actions/projectActions";
import { ImageEditor } from "components";
import { IMAGE_TYPE } from "config";
import { DEFAULT_IMAGES } from "config";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MobileProjectRow } from "./MobileProjectRow";

function MobileProjects({ profile, projects, permissions,isEditView }) {
  const [showFullText, setShowFullText] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const cellStyle = {
    border: "1px solid rgba(217, 217, 217, 1)",
    borderRadius: "10px",
    overflow: "hidden",
    textalign: "center",
  };

  const TabelCellData = [
    { title: "Photo" },
    { title: "Projects" },
    { title: "Client" },
    { title: "Work Done" },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEditProject = (projectData) => {
    dispatch(editProjectTrigger(projectData));
    navigate("/add-project");
  };

  const handleDeleteProject = (projectName, projectId) => {
    console.log(profile?.supplierId, projectName, projectId,"profile?.supplierId, projectName, projectId");
    dispatch(deleteProject(profile?.supplierId, projectName, projectId));
  };
  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };
  const handleAddProject = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isOwner) {
        dispatch(addProjectTrigger);
        navigate("/add-project");
      } else {
      }
    } else {
      navigate("/subscribe-plan");
    }
  };
  return (
    <Grid
      sx={{
        marginX: "10px",
        paddingBottom: "35px",
      }}
    >
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          marginBottom: "16px",
        }}
      >
        Projects
      </Typography>

      <Grid style={{ marginBottom: "36px" }}>
        {permissions &&
          !permissions.isViewOnly &&
          permissions.isFreeUpdateAllowed && (
            <Typography
              // variant="contained"
              color="secondary"
              size="small"
              disabled={!(permissions && permissions.isUpdateAllowed)}
              sx={{ fontSize: "15px", cursor: "pointer" }}
              // endIcon={<AddCircleRoundedIcon />}
              onClick={handleAddProject}
            >
              <Link style={{ textDecoration: "underline", color: "#2864BE" }}>
                {" "}
                Click Here{" "}
              </Link>
              <span style={{ color: "black" }}> to upload Project</span>
            </Typography>
          )}
      </Grid>
      {projects?.length > 0 && (
        <TableContainer
        component={Paper}
        sx={{
          border: "1px solid rgba(217, 217, 217, 1)",
          borderRadius: "16px ",
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: "center",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  overflow: "hidden",
                  paddingY: "6px",
                  fontWeight: 600,
                }}
              >
                Photo
              </TableCell>
              {projects?.map((data, index) => (
                <TableCell style={cellStyle}>
                  {permissions && permissions.isUpdateAllowed && (
                    <Box
                      mr={3}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        textAlign: "right",
                      }}
                    >
                      <>
                        <Typography variant="body2" sx={{ mr: 0 }}>
                          <IconButton
                            disabled={!permissions?.isOwner}
                            sx={{ color: "primary.main" }}
                            onClick={() => handleEditProject(data)}
                          >
                            <Link
                              style={{ fontSize: "14px", color: "#2864BE" }}
                            >
                              {" "}
                              Edit
                            </Link>
                          </IconButton>
                        </Typography>

                        <Typography variant="body2">
                          <IconButton
                            disabled={!permissions?.isOwner}
                            sx={{ color: "primary.main" }}
                            onClick={() =>
                              handleDeleteProject(
                                data.projectName,
                                data._id
                              )
                            }
                          >
                            <Delete
                              style={{ fontSize: "18px", color: "#2864BE" }}
                            />
                          </IconButton>
                        </Typography>
                      </>
                    </Box>
                  )}
                  {isEditView ? (
                    <ImageEditor
                      type={IMAGE_TYPE.PROJECT}
                      name={data.projectName}
                      supplierId={profile?.supplierId}
                      inImage={
                        data.projectImageUrl === undefined ||
                        data.projectImageUrl === ""
                          ? undefined
                          : BASE_URL + data.projectImageUrl
                          ? `${
                              data.projectImageUrl === undefined ||
                              data.projectImageUrl === ""
                                ? undefined
                                : BASE_URL + data.projectImageUrl
                            }`
                          : DEFAULT_IMAGES.PROJECT
                      }
                      id={data._id}
                    />
                  ) : (
                    <Box
                      component="img"
                      alt="img"
                      src={
                        data?.projectImageUrl
                          ? `${BASE_URL}/${data?.projectImageUrl}`
                          : DEFAULT_IMAGES.SERVICE
                      }
                      sx={{ width: "150px" }}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: "center",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  overflow: "hidden",
                  paddingY: "6px",
                  fontWeight: 600,
                }}
              >
                Projects
              </TableCell>
              {projects?.map((data, index) => (
                <TableCell style={cellStyle}>{data.projectName}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: "center",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  overflow: "hidden",
                  paddingY: "6px",
                  fontWeight: 600,
                }}
              >
                Client
              </TableCell>
              {projects?.map((data, index) => (
                <TableCell style={cellStyle}>{data.clientName}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: "center",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  overflow: "hidden",
                  paddingY: "6px",
                  fontWeight: 600,
                }}
              >
                Work Done
              </TableCell>
              {projects?.map((data, index) => (
                <MobileProjectRow  data={data} cellStyle={cellStyle} showFullText={showFullText} setShowFullText={setShowFullText} toggleFullText={toggleFullText}/>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
        )}
      
    </Grid>
  );
}

export default MobileProjects;
