import React from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { useFormikContext } from "formik";

const FileUpload = ({
    handleFileUploadClick,
    handleFileUpload,
    handleDeleteSelectedFile,
    selectedFilesBase,
    attachmentsSkeleton,
    attachmentsUploadsErrors,
    openFilesSizeError,
    handleCloseFileSizeError,
    selectedMaterialIndex
}) => {

    const { values, setFieldValue } = useFormikContext();

    return (
        <div className="pb-2">
            {attachmentsSkeleton.length > 0 && (
                <p className="text-xs pl-3">
                    Your file is being scanned for upload, be patient and continue to fill the rest of the form.
                </p>
            )}

            <div className=" mt-4 gap-2 max-h-[80px] overflow-y-auto">
                {/* Display the selected file names */}
                <ul style={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedFilesBase.map((file, index) => (
                        <a
                            href={file.Content}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-5"
                            key={file.Name}
                        >
                            <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">{file.Name}</span>
                            <span className="ml-1 cursor-pointer" onClick={(event) => handleDeleteSelectedFile(file.Name, event, setFieldValue, selectedMaterialIndex, values)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 48 48">
                                    <circle cx="24" cy="24" r="22" fill="gray" />
                                    <line x1="15" y1="15" x2="33" y2="33" stroke="white" strokeWidth="2" />
                                    <line x1="33" y1="15" x2="15" y2="33" stroke="white" strokeWidth="2" />
                                </svg>
                            </span>
                        </a>
                    ))}

                    {/* Skeleton loaders for files being processed */}
                    {attachmentsSkeleton.map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="rounded"
                            sx={{ borderRadius: "20px", margin: "4px" }}
                            width={150}
                            height={32}
                        />
                    ))}
                </ul>
            </div>

            {attachmentsUploadsErrors && (
                <div className="mt-3">
                    <p className="text-red-500 font-semibold text-sm mt-14">{attachmentsUploadsErrors}</p>
                </div>
            )}

            <div className={`fixed bottom-0 left-2 p-4 ${openFilesSizeError ? "block" : "hidden"}`}>
                <div className="bg-orange-100 w-[440px] flex items-center justify-between px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                    <p>Total size of attachments is not more than 10 MB</p>
                    <button onClick={handleCloseFileSizeError} className="px-4 py-2 focus:outline-none">
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </div>
            </div>

            {/* Updated file upload area with icon and clickable label */}
            <div className="h-[180px] my-4 flex flex-col justify-center items-center border-dashed border-2 border-blue-900 bg-[#F1F7FF]">
                <input
                    id="file-upload-material"
                    type="file"
                    className="hidden"
                    onClick={handleFileUploadClick}
                    onChange={handleFileUpload}
                    multiple
                />
                <label htmlFor="file-upload-material" className="flex flex-col items-center cursor-pointer">
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" className='w-8 h-8 mb-2' alt="Upload Icon" />
                    <p className=" whitespace-nowrap text-sm">
                        Drag & Drop or
                        <span className="underline px-2 text-[#1D28FA]">
                            Choose File
                        </span>
                        to upload
                    </p>
                </label>
            </div>
        </div>
    );
};

export default FileUpload;
