import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

function VerificationPopUp({ open, handleClose }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    boxShadow: 24,
    p: 2,
    borderRadius: "7px",
  };

  const iconStyle = {
    position: "absolute",
    top: "-10px", // Adjust this value as needed
    right: "-10px", // Adjust this value as needed
    // zIndex: 99999,
    cursor:"pointer",
    color: "#fff",
    backgroundColor: "#4B4747",
    borderRadius: "50%",
  };

  const handleIconClick = () => {
    handleClose(); // Call the handleClose function to close the modal
  };
  return (
    <Modal open={open} onClose={handleClose}>
       <Box sx={style} style={{ width: isMd ? "453px" : "320px" }}>
        <Grid container>
          <Grid item xs={12}>
       
            <Box
              component="img"
              sx={{
                height: 25,
                width: 25,
                position: "absolute",
                right: -10,
                top: -10,
                cursor:"pointer",

              }}
              alt="green vector"
              src="/assets/icons/crossIcon.svg"
              onClick={handleIconClick}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontSize:"16px", fontWeight:600, color:"#111827"}}>
            Thank you and Welcome to the Neevay community !
            </Typography>
            <Typography
              style={{
                color: "#404145",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight:"22.4px",
                letterSpacing: "0.28px",
                marginTop:"5px"
              }}
            >             
              Neevay is a closed platform and require KYC to connect with our vendors, our representative will call you. Till then, feel free to search and explore the quality of our listed vendors.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default VerificationPopUp;
