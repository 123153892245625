import { initialize } from "react-ga";

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

export const typography = {
  fontFamily: ["Roboto", "sans-serif"].join(","),

  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 40,
    fontSize: pxToRem(40), // 32,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 500,
    lineHeight: 64 / 48,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 100,
    lineHeight: 1.5,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    // TODO_SP: This is approximated and not defined by Designer
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },

  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(16),
  },
  subtitle1: {
    // equal to body3
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    textTransform: "capitalize",
  },
  subtitle2: {
    // equal to body4
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },

  subtitle3: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(12),
  },
  searchCard: {
    // equal to body4
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(12),
  },
  searchCardTitle: {
    fontWeight: 700,
    lineHeight: 16 / 14,
    fontSize: pxToRem(14),
  },
  searchCardContent: {
    fontWeight: 400,
    lineHeight: 12 / 12,
    fontSize: pxToRem(12),
  },
  searchCardHighlight: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(12),
  },
  searchFilterCardTitle: {
    fontWeight: 700,
    lineHeight: 16 / 12,
    fontSize: pxToRem(14),
  },
  searchFilterCardLabel: {
    fontWeight: 400,
    lineHeight: 16 / 12,
    fontSize: pxToRem(12),
    textTransform: "lowercase",
  },

  businessCard: {
    fontWeight: 400,
    lineHeight: 20 / 16,
    fontSize: 14,
  },
  businessTitle: {
    fontWeight: 700,
    lineHeight: 20 / 16,
    fontSize: 16,
  },
  businessSubTitle: {
    fontWeight: 700,
    lineHeight: 16 / 14,
    fontSize: 14,
  },
  businessBody: {
    fontWeight: 400,

    lineHeight: 20 / 16,
    fontSize: 14,
  },
  // link1: {
  //   lineHeight: 24,
  //   fontSize: 16,
  // },
  // link2: {
  //   lineHeight: 22,
  //   fontSize: 14,
  // },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  // caption1: {
  //   lineHeight: 22,
  //   fontSize: 14,
  // },
  // caption2: {
  //   lineHeight: 18,
  //   fontSize: 12,
  // },

  button: {
    fontWeight: 200,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "capitalize",
  },
  spDetails: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",

    color: "rgba(0, 0, 0, 0.8)",
  },
  spDetailsTitle: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "26px",

    color: "rgba(0, 0, 0, 0.8)",
  },
  spOffer: {
    fontWeight: "800",
    fontSize: "22px",
    lineHeight: "25px",

    color: "#47B59E",
  },
  guidedText: {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "500",
  },
  sliderHeadline: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
  },
  sliderContent: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "400",
  },
  sliderName: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
  },
  
    searchCardNewLocation:{
       fontSize:"14px",
       fontWeight:"400px",
       color:"#757373"
    },
    searchCardNewBusinessType:{
       fontSize:"14px",
       fontWeight:"400px",
       color:"#757373"
    },
  
};
