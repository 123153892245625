import certificateService from "services/certificateService";
export const CERTIFICATE_CREATE = "@certifcate/create";
export const CERTIFICATE_CREATE_SUCCESS = "@certifcate/create-success";
export const CERTIFICATE_CREATE_FAILURE = "@certifcate/create-failure";

export const CERTIFICATE_DELETE = "@certifcate/delete";
export const CERTIFICATE_DELETE_SUCCESS = "@certifcate/delete-success";
export const CERTIFICATE_DELETE_FAILURE = "@certifcate/delete-failure";

export function addCertificate(supplierId, certificateTitle, certificateImage) {
  return async (dispatch) => {
    // const newCertificate = new NewCertificateInfo(supplierId, values);
    try {
      // dispatch({});
      const payload = await certificateService.addCertificate(
        supplierId,
        certificateTitle,
        certificateImage
      );

      dispatch({
        type: CERTIFICATE_CREATE_SUCCESS,
        payload: {
          ...payload,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: CERTIFICATE_CREATE_FAILURE });
      throw error;
    }
  };
}

export function deleteCertificate(supplierId, certificateId, certificateName) {
  return async (dispatch) => {
    console.log(supplierId, certificateId, certificateName,"from action")
    try {
      dispatch({
        type: CERTIFICATE_DELETE,
        payload: {
          certificateId,
        },
      });

      const payload = await certificateService.deleteCertificate(
        supplierId,
        certificateId,
        certificateName
      );

      dispatch({
        type: CERTIFICATE_DELETE_SUCCESS,
        payload: { ...payload },
      });
    } catch (error) {
      console.log(error,"error");
      dispatch({ type: CERTIFICATE_DELETE_FAILURE });
      throw error;
    }
  };
}
