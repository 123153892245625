import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import PropTypes from "prop-types";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import {
  Grid,
  Box,
  Dialog,
  Button,
  CardMedia,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  Card,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { DEFAULT_IMAGES, IMAGE_TYPE } from "config";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const AwardCard = ({
  supplierId,
  awardTitle,
  awardId,
  awardImageUrl,
  handleDeleteAward,
  isAwardExists,
  isPublicProfile,
  permissions,
  setAutoPlayState,
  ...rest
}) => {

  
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (supplierId, awardId, awardTitle) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleMouseEnter = () => {
    setAutoPlayState(false);
  };

  const handleMouseLeave = () => {
    setAutoPlayState(true);
  };

  return (
    <Grid
      container
      md={12}
      xs={12}
      sm={12}
      // sx={{ border: "1px solid black" }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography>Are you sure want to delete ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" 
          sx={{backgroundColor: "#FD6600",
          color:"white",
          "&:hover": {
            backgroundColor: "#FD6600",
            transition: "background-color 0.3s, box-shadow 0.3s",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
          }
          }}
          >Cancel</Button>
          <Button onClick={() => handleDeleteAward(supplierId, awardId, awardTitle)} 
          variant="contained"
          sx={{backgroundColor: "#FD6600",
               color:"white",
               "&:hover": {
                 backgroundColor: "#FD6600",
                 transition: "background-color 0.3s, box-shadow 0.3s",
                 boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
               }
               }}
          >
          <Box onClick={handleClose}>  Submit</Box>
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item md={12} xs={12} sm={12} >
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {/* <Typography variant="body1" pl={3}>
              {awardTitle}
            </Typography> */}
            {permissions && !permissions.isViewOnly && permissions.isUpdateAllowed && (
              <Typography variant="body2">
                <Tooltip title="Delete">
                  <div
                  disabled={!permissions?.isOwner}
                    sx={{ color: "primary.main", ml: -2 }}
                    onClick={() => handleClickOpen(supplierId, awardId, awardTitle )}
                  >
                    <Delete style={{fontSize:"20px",color:"#2864BE", marginRight:"10px",cursor:"pointer"}} />
                  </div>
                </Tooltip>
              </Typography>
            
              )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={12} xs={12} sm={12}>
        <Box
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "center",
          }}
          onMouseEnter={() => setAutoPlayState(false)}
          onMouseLeave={() => setAutoPlayState(true)}
        >
          <CardMedia
            sx={{ height: "300px", width: "300px", border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius:'10px' }}
            component="img"
            image={
              awardImageUrl
                ? `${BASE_URL}/${awardImageUrl}`
                : DEFAULT_IMAGES.AWARD
            }
            alt="Award Image"
          />
        </Box>
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{textAlign:'center'}}>
      <Typography variant="body1"  pl={3} sx={{fontWeight:'700px'}}>
              {awardTitle}
            </Typography>
      </Grid>
      
    </Grid>
  );
};
AwardCard.propTypes = {
  supplierId: PropTypes.number,
  awardTitle: PropTypes.string,
  awardImageUrl: PropTypes.string,
  handleDeleteAward: PropTypes.func,
  isPublicProfile: PropTypes.bool,
  permissions: PropTypes.object,
};

AwardCard.default = {};
export default AwardCard;
