/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  SEARCH,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_COMPANY,
  SEARCH_COMPANY_SUCCESS,
  SEARCH_COMPANY_FAILURE,
  SEARCH_FILTERED,
  SEARCH_FILTERED_SUCCESS,
  SEARCH_PAGE,
  SEARCH_PAGE_SUCCESS,
  SEARCH_PAGE_FAILURE,
  CLEAR_NETWORK_FILTERS,
} from "actions/searchActions";

import { SIGNOUT } from "actions/authActions";

import {
  processSearchResults,
  processSearchFacets,
  processCompanySearchResults,
  updateSearchFilter,
} from "utils/searchUtils";

const initialState = {
  searchKeyWord: "",
  regionString: "",
  results: [],
  facets: [],
  selectedFacets: [],
  numFound: 0,
  numFoundExact: false,
  start: 0,
  currentPage: 0,
  companies: [],
  companySearchKeyWord: "",
  isSearching: false,
  searchType: "",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      {
        const { searchKeyWord, searchType, regionString } = action.payload;
        return produce(state, (draft) => {
          draft.searchKeyWord = searchKeyWord;
          draft.regionString = regionString ?? "";
          draft.results = [];
          draft.facets = [];
          draft.currentPage = 0;
          draft.selectedFacets = [];
          draft.isSearching = true;
          draft.searchType = searchType;
        });
      }
      break;

    case SEARCH_SUCCESS:
    case SEARCH_FILTERED_SUCCESS:
    case SEARCH_PAGE_SUCCESS:
      {
        const { facet_counts, response } = action.payload;
        const docs = processSearchResults(response?.docs);
        const facet_fields = processSearchFacets(facet_counts?.facet_fields);

        // console.log("searchReducer: SEARCH_SUCCESS ", response, action.payload);

        // console.log("searchReducer: SEARCH_SUCCESS docs ", response?.docs);
        // console.log("searchReducer: SEARCH_SUCCESS facets ", facet_fields);

        return produce(state, (draft) => {
          draft.results = [...docs];
          draft.facets = facet_fields;
          draft.numFound = response?.numFound;
          draft.currentPage = response?.numFound > 0 ? 1 : 0;
          draft.numFoundExact = response?.numFoundExact;
          draft.start = response?.start;
          draft.isSearching = false;
          // draft.selectedFacets = [];
        });
      }
      break;

    case SEARCH_FAILURE:
      {
        return produce(state, (draft) => {
          draft.results = [];
          draft.facets = [];
          draft.numFound = 0;
          draft.numFoundExact = 0;
          draft.currentPage = 0;
          draft.start = false;
          draft.selectedFacets = [];
          draft.isSearching = false;
        });
      }
      break;

    case SEARCH_COMPANY:
      {
        const { searchKeyWord } = action.payload;
        return produce(state, (draft) => {
          draft.companySearchKeyWord = searchKeyWord;
          draft.companies = [];
        });
      }
      break;

    case SEARCH_FILTERED:
      {
        const { facetTitle, facetValue, isSelected } = action.payload;
        // console.log(
        //   "searchReducer() Before ",
        //   state.selectedFacets,
        //   facetTitle,
        //   facetValue,
        //   isSelected
        // );
        // console.log("this is selectedFcaets 88888", state.selectedFacets);
        return produce(state, (draft) => {
          draft.selectedFacets = updateSearchFilter(
            state.selectedFacets,
            facetTitle,
            facetValue,
            isSelected
          );
          draft.isSearching = true;
        });
      }
      break;

    case SEARCH_PAGE:
      {
        const { pageNo, regionString } = action.payload;
        // console.log(
        //   "searchReducer() Before ",
        //   state.selectedFacets,
        //   facetTitle,
        //   facetValue,
        //   isSelected
        // );

        return produce(state, (draft) => {
          draft.currentPage = pageNo;
          draft.isSearching = true;
          draft.regionString = regionString;
        });
      }
      break;

    case SEARCH_COMPANY_SUCCESS:
      {
        const { facet_counts, response } = action.payload;
        const docs = processCompanySearchResults(response?.docs);
        // const facet_fields = processSearchFacets(facet_counts?.facet_fields);

        // console.log(
        //   "searchReducer: SEARCH_COMPANY_SUCCESS ",
        //   response,
        //   action.payload
        // );

        // console.log("searchReducer: SEARCH_SUCCESS docs ", response?.docs);
        // console.log("searchReducer: SEARCH_COMPANY_SUCCESS facets ", facet_fields);

        return produce(state, (draft) => {
          draft.companies = [...docs];
          // draft.facets = facet_fields;
          // draft.numFound = response?.numFound;
          // draft.numFoundExact = response?.numFoundExact;
          // draft.start = response?.start;
        });
      }
      break;

    case SIGNOUT: {
      return produce(state, (draft) => {
        // Clear any user specific data
        draft.isSearching = false;
        draft.currentPage = 0;
      });
    }
    case CLEAR_NETWORK_FILTERS:
      {
        return produce(state, (draft) => {
          draft.selectedFacets = [];
          draft.currentPage = 0;
        });
      }
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default searchReducer;
