import React, { useEffect, useState } from "react";
import axios from "utils/axios";
import { GlobalNavbar } from "views/Home 3.0/components";
import FooterNew from "views/Home/components/FooterNew";

export const CaseStudy = () => {
  const maxLengthForSummary = 8;
  const maxLengthForHeading = 5;
  const [fetchBlog, setFetchBlog] = useState([]);
  const [desc, setDesc] = useState([]);
  
  const getAllBlogs = async () => {
    try {
      const response = await axios.get(
        "/api/blogs/get/published-blogs"
      );
      setFetchBlog(
        response?.data?.blogs?.filter((blog) => blog.type === "case study")
      );
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };
  const lastThreeBlogs = fetchBlog.slice(-4);

  const extractTextContent = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent.trim();
  };
  useEffect(() => {
    getAllBlogs();
  }, []);
  useEffect(() => {
    // Extract text content for each blog once fetchBlog state updates
    const extractedDesc = extractTextContent(fetchBlog[0]?.description);
    setDesc(extractedDesc);
  }, [fetchBlog]);

  const extractImageFromHTML = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const imageElements = doc.querySelectorAll("img");
    return Array.from(imageElements).map((img) => img.src);
  };
  return (
    <div>
      <GlobalNavbar />
      <>
        {fetchBlog && fetchBlog.length > 0 && (
          <>
            <main class="pb-16  lg:pb-24 bg-white dark:bg-gray-900 antialiased">
              <header class="bg-[url('https://flowbite.s3.amazonaws.com/blocks/marketing-ui/articles/background.png')] w-full pl-5 h-[460px] xl:h-[537px] bg-no-repeat bg-cover bg-center bg-blend-darken relative">
                <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 pl-7"></div>
                <div class="absolute top-20 left-1/2 px-4 mx-auto w-full max-w-screen-xl -translate-x-1/2 xl:top-1/2 xl:-translate-y-1/2 xl:px-0">
                  <span class="block mb-4 text-gray-300 pl-7">
                    Published in{" "}
                    <a
                      href="#"
                      class="font-semibold text-white hover:underline"
                    >
                      {fetchBlog[0]?.date}
                    </a>
                  </span>
                  <h1 class="mb-4 max-w-4xl text-2xl font-extrabold leading-none text-white sm:text-3xl lg:text-4xl pl-7">
                    {" "}
                    {fetchBlog[0]?.heading}
                  </h1>
                  <p class="text-lg font-normal text-gray-300 pl-7 pr-7">
                    {" "}
                    {fetchBlog[0]?.summary}
                  </p>
                </div>
              </header>
              <div class="flex relative z-20 justify-between p-6 -m-36 mx-4 max-w-screen-xl bg-white dark:bg-gray-800 rounded xl:-m-32 xl:p-9 xl:mx-auto">
                <article class="xl:w-[828px] w-full max-w-none format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                  <div class="flex flex-col lg:flex-row justify-between lg:items-center">
                    <div class="flex items-center space-x-3 text-gray-500 dark:text-gray-400 text-base mb-2 lg:mb-0">
                      <span className="mb-5">
                        By{" "}
                        <a
                          href="#"
                          class="text-gray-900 dark:text-white hover:underline no-underline font-semibold"
                        >
                          {" "}
                          {fetchBlog[0]?.author}
                        </a>
                      </span>
                      <span class="bg-gray-300 mb-5 dark:bg-gray-400 w-2 h-2 rounded-full"></span>
                      <span className="mb-5">
                        <time
                          class="font-normal text-gray-500 dark:text-gray-400"
                          pubdate
                          datetime="2022-03-08"
                          title="August 3rd, 2022"
                        >
                          {fetchBlog[0]?.date}
                        </time>
                      </span>
                    </div>
                  </div>
                  <p
                    class="lead"
                    dangerouslySetInnerHTML={{
                      __html: desc,
                    }}
                  />
                  <figure style={{ marginTop: "30px" }}>
                    {extractImageFromHTML(fetchBlog[0].description).map(
                      (imageUrl, index) => (
                        <a href="#" className="shrink-0">
                          <img
                            src={imageUrl}
                            class="mx-auto"
                            alt={`Image ${index}`}
                          />
                        </a>
                      )
                    )}
                  </figure>
                </article>
                <aside class="hidden xl:block" aria-labelledby="sidebar-label">
                  <div class="xl:w-[336px] sticky top-6">
                    <h3 id="sidebar-label" class="sr-only">
                      Sidebar
                    </h3>

                    {lastThreeBlogs.length>1&&<div class="mb-12">
                      <h4 class="mb-4 text-sm font-bold text-gray-900 dark:text-white uppercase">
                        Latest Blogs
                      </h4>
                      {lastThreeBlogs?.slice(1).map((item) => (
                        <div className="mb-6 flex items-center">
                          {extractImageFromHTML(item.description).map(
                            (imageUrl, index) => (
                              <a href="#" className="shrink-0">
                                <img
                                  src={imageUrl}
                                  className="mr-4 max-w-full w-24 h-24 rounded-lg"
                                  alt={`Image ${index}`}
                                />
                              </a>
                            )
                          )}

                          <div>
                            <h5 className="mb-2 text-lg font-bold leading-tight dark:text-white text-gray-900">
                              {item.heading.length > maxLengthForHeading
                                ? item.heading
                                    .split(" ")
                                    .slice(0, maxLengthForHeading)
                                    .join(" ") + "..."
                                : item.heading}
                            </h5>
                            <p className="mb-2 text-gray-500 dark:text-gray-400">
                              {item.summary.length > maxLengthForSummary
                                ? item.summary
                                    .split(" ")
                                    .slice(0, maxLengthForSummary)
                                    .join(" ") + "..."
                                : item.summary}
                            </p>
                            <a
                              href="#"
                              className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline"
                            >
                              Read in 9 minutes
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>}
                  </div>
                </aside>
              </div>
            </main>
          </>
        )}
        
      </>
      <FooterNew />
    </div>
  );
};
