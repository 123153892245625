import React, { memo, useState, useRef } from "react";
import { Avatar, Button, Tooltip } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { CameraAlt as CameraAltIcon } from "@mui/icons-material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ProfileImageUploader({ type, name, profileImage, inImage }) {
  const uploadFile = useRef(type);
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = useState(null);
  const [hasUploaded, setHasUploaded] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    handlePhoto();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const inputRef2 = useRef(null);

  const [state, setState] = useState({
    editor: "",
    allowZoomOut: false,
    cropperOpen: false,
    img: null,
    scale: 1,
    croppedImg: inImage,
  });

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setState({ ...state, scale });
  };
  const handleAllowZoomOut = (e) => {
    setState({ ...state, allowZoomOut: e.target.value });
  };

  const handleFileChange = (e) => {
    // let url = window.URL.createObjectURL(e.target.files[0]);
    // setState({
    //   img: url,
    //   cropperOpen: true,
    // });
    e.preventDefault();
    const newImage = e.target.files[0];
    setImage(newImage);
    setHasUploaded(true);
  };

  const handleSave = (e) => {
    if (state.editor) {
      const canvasScaled = state.editor.getImageScaledToCanvas();

      const croppedImg = canvasScaled.toDataURL();
      setState({
        ...state,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
      });

      let fileName = "profileImage.png";
      fetch(croppedImg)
        .then((res) => res.blob())
        .then((blob) => new File([blob], fileName, { type: blob.type }))
        .then((file) => {
          // console.log(
          //   "profileImageuploader====>>>: file",
          //   file,
          //   "type",
          //   type,
          //   "name",
          //   file.name
          // );
          profileImage(file, type, file.name);
        });
    }
  };

  const handleCancel = () => {
    setState({ croppedImg: inImage, cropperOpen: false });
    window.location.reload(true);
  };

  const setEditorRef = (editor) => {
    state.editor = editor;
  };

  const handlePhoto = (e) => {
    e.preventDefault();
    uploadFile.current.click();
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          src={state.croppedImg}
          disableCache={true}
          style={{ height: 100, width: 100 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip title="Select Image for Preview">
          <IconButton
            id="uploadImageBtn"
            // color="#FD6600"
            aria-label="preview picture"
            component="label"
            sx={{ backgroundColor: "white" ,color:"#FD6600"}}
          >
            <input
              onClick={handleClickOpen}
              ref={uploadFile}
              type="file"
              accept="image/*"
              id={`contained-button-file-${type}`}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <CameraAltIcon />
          </IconButton>
        </Tooltip>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Edit Profile
        </BootstrapDialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: 500,
          }}
        >
             {image && hasUploaded && (
          <AvatarEditor
            ref={setEditorRef}
            image={image}
            width={200}
            height={200}
            border={50}
            borderRadius={100}
            color={[255, 255, 255, 0.6]}
            scale={state.scale}
            style={{
              marginBottom: 10,
              paddingBottom: 0,
              backgroundColor: "#47e59e",
            }}
          />
             )}

          {image && (
            <>
              Zoom In:
              <input
                name="scale"
                type="range"
                onChange={(e) => {
                  handleScale(e);
                  handleAllowZoomOut();
                }}
                min="0.1"
                max="2"
                step="0.01"
                defaultValue="1"
              />
              <div style={{ marginBottom: "10px" }}>
                {""}
                <Button
                  onClick={() => {
                    handleCancel();
                    handleClose();
                  }}
                  style={{ mr: 2 }}
                >
                  Cancel
                </Button>
                {""}
                <Button
                  onClick={() => {
                    handleSave();
                    handleClose();
                  }}
                >
                  Save
                </Button>
              </div>
              {""}
            </>
          )}
        </div>
      </BootstrapDialog>
    </div>
  );
}
ProfileImageUploader.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profileImage: PropTypes.func.isRequired,
  inImage: PropTypes.object.isRequired,
};

export default memo(ProfileImageUploader);
