import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "utils/axios";
import React, { useEffect } from "react";
import { useParams } from "react-router";

export const NonPremiumEmailThankYouForm = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down(600));
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const phoneNumber = "+91.9503339814";
  const { id } = useParams();
  useEffect(() => {
    try {
      axios.post("/api/auth/demo/request", {
        supplierId: id,
        interestShown: "upgrade to premium",
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box
        component="img"
        sx={{
          height: "28px",
          cursor: "pointer",
          marginBottom: "50px",
        }}
        alt="logo"
        src={`${BASE_URL}/assets/business/web-homepage-Neevay-new-logo.svg`}
      />
      <Grid
        sx={{
          backgroundColor: "rgba(201, 201, 201, 1)",
          width: isSm ? "330px" : "387px",
          height: "270px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "Inter",
            lineHeight: "24px",
            fontWeight: 700,
            paddingTop: "40px ",
            paddingBottom: isSm ? "30px" : "50px",
            textAlign: "center",
          }}
        >
          Thank you for your interest...
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "15px",
            textAlign: "center",
            paddingX: isSm ? "30px" : "46px",
          }}
        >
          Our representative will call you shortly and guide you through the
          upgrade process
        </Typography>
        <Typography
          sx={{
            marginTop: isSm ? "8px" : "15px",
            paddingBottom: "30px",
            fontFamily: "Inter",
            fontSize: "15px",
            textAlign: "center",
            paddingX: "25px", // Assuming always mobile for simplicity
          }}
        >
          You can also call us at{" "}
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> and a representative
          will expedite the process
        </Typography>
      </Grid>
    </Grid>
  );
};
