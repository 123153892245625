import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useIsMounted from "hooks/useIsMounted";

import {
  fetchBusinessById,
  fetchBusinessByUserId,
} from "actions/businessActions";
import { getBusinessPermissions } from "utils/accessControl.js";

import DashboardLayout from "layouts/Dashboard";
import Page from "views/UserProfile/components/Page";
import { Helmet } from "react-helmet";
import BusinessProfile from "../components/BusinessProfile";
import NewMyBusinesspage from "./NewMyBusinesspage";
import SidePanel from "layouts/SidePanel";

function Business() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [projects, setProjects] = useState([]);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const userId = userProfile?.userId;

  const business = useSelector((state) => state.business);

  // console.log("business",business )

  if (!userProfile) {
    navigate("/register");
  } else if (userProfile?.userId > 0 && userProfile?.name) {
  } else {
    navigate("/register/user");
  }

  useEffect(() => {
    // dispatch(fetchBusinessById(supplierId));
    dispatch(fetchBusinessByUserId(userId));
  }, [isMounted]);

  useEffect(() => {
    setProfile(business.profile);
    setProjects(business.projects);
  }, [business]);
  const isPublicProfile = false; // This is set to ensure edit feature is enabled

  const permissions = getBusinessPermissions(
    isPublicProfile,
    userProfile?.userId,
    business?.profile
  );
  return (
    <SidePanel>
      {!business?.profile || Object.keys(business.profile).length === 0 ?
        <div className=" min-h-screen flex justify-center items-center bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
          <div className="flex flex-col items-center">
            <img
              src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Search_image.png"
              alt="Search Image"
              className="h-[116px] w-[122px] mb-6"
            />
            <p className="text-md font-bold text-[#3C3C3C] text-center">
              Register your Business</p>
            <a href="/register/user" className="text-[10px] text-blue-600" target="_blank" rel="noopener noreferrer">
              Click here to Register
            </a>
          </div>
        </div>

        : <DashboardLayout path="/my-business ">
          <Helmet>
            <title>{`Neevay - ${business?.profile?.businessName}`} </title>
          </Helmet>
          <Page isPublicProfile={isPublicProfile}>
            {/* <BusinessProfile profile={profile} projects={projects} isPublicProfile={isPublicProfile} permissions={permissions} /> */}
            <NewMyBusinesspage
              profile={profile}
              projects={projects}
              isPublicProfile={isPublicProfile}
              permissions={permissions}
            />
          </Page>
        </DashboardLayout>}
    </SidePanel>
  );
}

export default Business;
