import {
  Grid,
  Snackbar,
  Typography,
  Tooltip,
  CircularProgress,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import {
  fetchBusinessOwnerEmail,
  getUrl,
  hideContactDetails,
} from "actions/businessActions";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { sendNewRFQTrigger } from "actions/requirementsAction";
import { getIsPremium } from "utils/accessControl";
import LogInMobilePopUp from "views/Auth/SignInSendMobileOTP/LogInMobilePopUp";
import { fetchTag } from "actions/tagsActions";
import FavoritesDialogBox from "views/Business/components/FavoritesDialogBox";
import RequirementFormModal from "views/RequirementFormModal/RequirementFormModal";
import NonEntityRequirmentForm from "views/RequirementFormModal/NonEntityRequirmentForm";

function BusinessSendEnquiry({ profile, IsSearchPublicProfile, isEditView }) {
  // console.log("this is a profile data ====", profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const auth = useSelector((state) => state.auth);
  const business = useSelector((state) => state.business);
  const count = business?.contactsData?.count;
  const userProfile = auth.user;
  const entityId = userProfile?.userEntity?.entityId;
  const isPremium = getIsPremium(profile?.subscriptionType);
  const favourite = useSelector((state) => state.favourite.favourites);
  const tagsData = favourite.filter(
    (data) => data.supplierId == profile?.supplierId
  );
  const userId = auth?.user?.userId;
  const supplierId = profile?.supplierId;

  const [openLogInModal, setOpenLogInModal] = useState(false);
  const [openFavoritesTag, setOpenFavoritesTag] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [openSendRfqModel, setOpenSendRfqModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  // console.log(userProfile.isVerified, "userProfile");
  const userIdOfOwner = profile?.owners?.[0];
  const businessProfile = profile?.businessProfile;
  const whatsappMobileNumber = businessProfile?.businessMobile;
  const businessName = profile?.businessName;

  const isImpProfile =
    businessProfile?.businessCategory == "Real Estate Developer" ||
    businessProfile?.businessCategory == "EPC Contractor";

  const maskNumber = (number) => {
    if (number) {
      const numberStr = number.toString();
      const maskedNumber =
        numberStr.substring(0, 2) +
        "XXXXXX" +
        numberStr.substring(numberStr.length - 2);
      return maskedNumber;
    } else {
      return number;
    }
  };
  const contact = maskNumber(businessProfile?.contact);

  const handleCloseSnackbar = () => {
    setOpenSave(false);
  };

  const handleClickSave = (event) => {
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));
      setOpenLogInModal(true);
    } else {
      dispatch(fetchTag());
      setOpenFavoritesTag(true);
    }
  };

  const handleFavoritesTagClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFavoritesTag(false);
  };
  const handleClickShowOwnerDetails = (type) => {
    if (!userProfile) {
      setOpenLogInModal(true);
      return;
    } else if (userProfile?.isVerified === false) {
    } else {
      setShowNumber(type === "mobile");
    }
  };

  const handleClick = async (event, data) => {
    setIsLoading(true);
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));

      setOpenLogInModal(true);
      setIsLoading(false);
    } else if (userProfile?.isVerified === false) {
      setIsLoading(false);
    } else {
      const supplierData = [];
      const suppliers = [{ supplierId: profile?.supplierId }];
      const result = await dispatch(fetchBusinessOwnerEmail(suppliers));
      const resultEmails = result[0]?.emails;
      const emails = [
        ...new Set([...resultEmails, businessProfile?.businessEmail]),
      ];
      supplierData.push({
        supplierId: profile?.supplierId,
        businessName: profile?.businessName,
        mobile: profile?.businessProfile?.businessMobile,
        // businessOwnerUserId: userIdOfOwner,
        isPremium: isPremium,
        // businessEmail: businessProfile?.businessEmail,
        emails: emails,
      });
      dispatch(sendNewRFQTrigger(supplierData));
      setOpenSendRfqModel(true);
      setIsLoading(false);
      // navigate("/enquiry");
    }
  };
  const handleCloseLogInModal = () => {
    setOpenLogInModal(false);
  };
  const handleClickToContact = () => {
    if (!userProfile) {
      dispatch(getUrl(currentLocation.pathname));
      setOpenLogInModal(true);
    } else {
      const supplierDetails = {
        supplierId: supplierId,
        contact:
          profile?.businessProfile?.contact ??
          profile?.businessProfile?.businessMobile,
        supplierUrl: profile?.url,
      };
      dispatch(hideContactDetails(userId, supplierDetails, entityId));
      setShowContactDetails(true);
      setShowMessage(true);
    }
  };

  useEffect(() => {
    setShowContactDetails(false);
    setShowMessage(false);
  }, [profile?.businessName]);
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginX: IsSearchPublicProfile ? "25px" : "46px",
          alignItems: "flex-start",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "top",
            gap: "10px",
            marginBottom: "20px",
            paddingLeft: isImpProfile && "80px",
          }}
        >
          {/* <Grid
            sx={{
              border: "1px solid rgba(217, 217, 217, 1)",
              borderRadius: "10px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height:"42px",
                width: !userProfile ? "160px" : "150px",
              }}
            >
              <Box
                component="img"
                alt="phone"
                src="/assets/home/questions.gif"
                style={{ width: "34px", marginRight: "8px" }}
              />
              {userProfile && userProfile.isVerified && (
                <Typography variant="businessCard">
                  {isImpProfile
                    ? "Not Disclosed"
                    : contact
                    ? contact
                    : "Not Disclosed"}
                </Typography>
              )}

              {userProfile && !userProfile.isVerified && (
                <Tooltip title="It will be visible after KYC">
                  <Typography
                    onClick={() => handleClickShowOwnerDetails("mobile")}
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      color: "#3963AB",
                      fontSize: "14px",
                      textDecoration: "underline",
                    }}
                  >
                    Hidden
                  </Typography>
                </Tooltip>
              )}

              {!userProfile && (
                <Typography
                  onClick={() => handleClickShowOwnerDetails("mobile")}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    color: "#3963AB",
                    fontSize: "14px",
                    textDecoration: "underline",
                  }}
                >
                  Login to View
                </Typography>
              )}
            </Grid>
          </Grid> */}

          {/* {contact && !isImpProfile && userProfile?.isVerified && (
            <a
              style={{ textDecoration: "none" }}
              href={`https://api.whatsapp.com/send?phone=91${whatsappMobileNumber}&text=Hello%2C%20I%20reviewed%20your%20business%20%22${businessName}%22%20on%20Neevay%20platform%20and%20would%20like%20to%20chat%20with%20you%20for%20my%20construction%20project%20requirements.`}
              target="_blank"
            >
              <Grid
                sx={{
                  borderRadius: "10px",
                  border: " 1px solid rgba(217, 217, 217, 1)",
                  cursor: "pointer",
                  width: "98px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src="/assets/home/WhatsApplogo.svg"
                  style={{
                    width: "28px",
                    paddingLeft: "6px",
                  }}
                />
                <Box
                  variant="outlined"
                  size="medium"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "black",
                    // textAlign: "center",
                    padding: "6px",
                  }}
                >
                  Chat
                </Box>
              </Grid>
            </a>
          )} */}
          {isImpProfile ? (
            ""
          ) : userProfile && !userProfile.isVerified ? (
            <Grid
              sx={{
                borderRadius: "10px",
                border: " 1px solid rgba(217, 217, 217, 1)",
              }}
            >
              <Tooltip title="It will be enable after KYC">
                <Box
                  // onClick={(e) => isImpProfile || handleClickSave(e)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "6px",
                    borderRadius: "20px",
                    width: tagsData[0]?.tags?.length > 0 ? "116px" : "113px",
                    height: "35px",
                    cursor: isImpProfile ? "" : "pointer",
                  }}
                >
                  <Box
                    component="img"
                    src="/assets/home/ShortListedIcon.svg"
                    style={{
                      width: "16px",
                      height: "12px",
                      color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                    }}
                  />
                  <Box
                    variant="outlined"
                    size="medium"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                      textAlign: "center",
                      lineHeight: "16px",
                      // paddingLeft: "28px",
                    }}
                  >
                    {tagsData[0]?.tags?.length > 0
                      ? "shortlisted"
                      : "Shortlist"}
                  </Box>
                </Box>
              </Tooltip>
            </Grid>
          ) : (
            <>
              <Grid
                sx={{
                  borderRadius: "10px",
                  border: " 1px solid rgba(217, 217, 217, 1)",
                  height: "35px",
                }}
              >
                <Box
                  onClick={(e) => isImpProfile || handleClickSave(e)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "6px",
                    borderRadius: "20px",
                    width: tagsData[0]?.tags?.length > 0 ? "116px" : "113px",
                    height: "35px",
                    cursor: isImpProfile ? "" : "pointer",
                  }}
                >
                  <Box
                    component="img"
                    src="/assets/home/ShortListedIcon.svg"
                    style={{
                      width: "16px",
                      height: "12px",
                      color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                    }}
                  />
                  <Box
                    variant="outlined"
                    size="medium"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: isImpProfile ? "rgba(152, 152, 152, 1)" : "black",
                      textAlign: "center",
                      lineHeight: "16px",
                      // paddingLeft: "28px",
                    }}
                  >
                    {tagsData[0]?.tags?.length > 0
                      ? "shortlisted"
                      : "Shortlist"}
                  </Box>
                </Box>
              </Grid>

              {entityId ? (
                <Grid>
                  {!showContactDetails ? (
                    <Grid
                      sx={{
                        borderRadius: "10px",
                        border: " 1px solid rgba(217, 217, 217, 1)",
                      }}
                    >
                      <Box
                        onClick={handleClickToContact}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingX: "12px",
                          gap: "6px",
                          borderRadius: "20px",
                          height: "33px",
                          cursor: isImpProfile ? "" : "pointer",
                        }}
                      >
                        <Box
                          variant="outlined"
                          size="medium"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: isImpProfile
                              ? "rgba(152, 152, 152, 1)"
                              : "black",
                            textAlign: "center",
                            lineHeight: "16px",
                          }}
                        >
                          View Contact Details
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid style={{ display: "flex", gap: "10px" }}>
                      {profile?.businessProfile?.businessMobile && (
                        <Grid
                          sx={{
                            borderRadius: "10px",
                            border: " 1px solid rgba(217, 217, 217, 1)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingX: "12px",
                              gap: "6px",
                              borderRadius: "20px",
                              height: "32px",
                              // cursor: isImpProfile ? "" : "pointer",
                            }}
                          >
                            <Box
                              variant="outlined"
                              size="medium"
                              sx={{
                                fontSize: "16px",
                                // fontWeight: "600",
                                color: isImpProfile
                                  ? "rgba(152, 152, 152, 1)"
                                  : "black",
                                textAlign: "center",
                                lineHeight: "16px",
                              }}
                            >
                              {profile?.businessProfile?.businessMobile}
                            </Box>
                          </Box>
                        </Grid>
                      )}

                      {profile?.businessProfile?.businessEmail && (
                        <Grid
                          sx={{
                            borderRadius: "10px",
                            border: " 1px solid rgba(217, 217, 217, 1)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingX: "12px",
                              gap: "6px",
                              borderRadius: "20px",
                              height: "32px",
                              // cursor: isImpProfile ? "" : "pointer",
                            }}
                          >
                            <Box
                              variant="outlined"
                              size="medium"
                              sx={{
                                fontSize: "16px",
                                // fontWeight: "600",
                                color: isImpProfile
                                  ? "rgba(152, 152, 152, 1)"
                                  : "black",
                                textAlign: "center",
                                lineHeight: "16px",
                              }}
                            >
                              {profile?.businessProfile?.businessEmail}
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid>
                  {!showContactDetails ? (
                    <Grid
                      sx={{
                        borderRadius: "10px",
                        border: " 1px solid rgba(217, 217, 217, 1)",
                      }}
                    >
                      <Box
                        onClick={handleClickToContact}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingX: "12px",
                          gap: "6px",
                          borderRadius: "20px",
                          height: "33px",
                          cursor: isImpProfile ? "" : "pointer",
                        }}
                      >
                        <Box
                          variant="outlined"
                          size="medium"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: isImpProfile
                              ? "rgba(152, 152, 152, 1)"
                              : "black",
                            textAlign: "center",
                            lineHeight: "16px",
                          }}
                        >
                          View Contact Details
                        </Box>
                      </Box>
                    </Grid>
                  ) : count?.length >= 20 &&
                    !count?.some((id) => id === supplierId) ? (
                    <Grid marginTop={"2px"}>
                      <Typography style={{ fontSize: "10px" }}>
                        **Free vendor contacts viewing limit reached for today.
                      </Typography>
                      <Typography style={{ fontSize: "10px" }}>
                        Become a Neevay entity for unlimited access.
                      </Typography>
                    </Grid>
                  ) : (
                    count?.some((id) => id === supplierId) && (
                      <Grid style={{ display: "flex", gap: "10px" }}>
                        {profile?.businessProfile?.businessMobile && (
                          <Grid
                            sx={{
                              borderRadius: "10px",
                              border: " 1px solid rgba(217, 217, 217, 1)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingX: "12px",
                                gap: "6px",
                                borderRadius: "20px",
                                height: "32px",
                                // cursor: isImpProfile ? "" : "pointer",
                              }}
                            >
                              <Box
                                variant="outlined"
                                size="medium"
                                sx={{
                                  fontSize: "16px",
                                  // fontWeight: "600",
                                  color: isImpProfile
                                    ? "rgba(152, 152, 152, 1)"
                                    : "black",
                                  textAlign: "center",
                                  lineHeight: "16px",
                                }}
                              >
                                {profile?.businessProfile?.businessMobile}
                              </Box>
                            </Box>
                          </Grid>
                        )}

                        {profile?.businessProfile?.businessEmail && (
                          <Grid
                            sx={{
                              borderRadius: "10px",
                              border: " 1px solid rgba(217, 217, 217, 1)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingX: "12px",
                                gap: "6px",
                                borderRadius: "20px",
                                height: "32px",
                                // cursor: isImpProfile ? "" : "pointer",
                              }}
                            >
                              <Box
                                variant="outlined"
                                size="medium"
                                sx={{
                                  fontSize: "16px",
                                  // fontWeight: "600",
                                  color: isImpProfile
                                    ? "rgba(152, 152, 152, 1)"
                                    : "black",
                                  textAlign: "center",
                                  lineHeight: "16px",
                                }}
                              >
                                {profile?.businessProfile?.businessEmail}
                              </Box>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    )
                  )}
                  {showMessage &&
                    count?.length <= 20 &&
                    count?.some((id) => id === supplierId) && (
                      <Typography
                        style={{
                          marginLeft: "4px",
                          fontSize: "10px",
                          marginTop: "2px",
                        }}
                      >
                        **You are viewing {count.length}/20 free vendor contacts
                        today. Become a Neevay entity for unlimited access.
                      </Typography>
                    )}
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          {userProfile && !userProfile.isVerified && !isImpProfile ? (
            <Tooltip title="It will be enable after KYC">
              <Button
                container
                onClick={(e) => isImpProfile || handleClick(e)}
                sx={{
                  backgroundColor: isImpProfile
                    ? "rgba(217, 217, 217, 1)"
                    : "black",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "150px",
                  height: "35px",
                  marginBottom: "10px",
                  cursor: isImpProfile ? "" : "pointer",

                  "&:hover": {
                    backgroundColor: isImpProfile ? "" : "black",
                    boxShadow: isImpProfile
                      ? ""
                      : "0px 0px 10px 0px rgba(0,0,0,0.7)",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  },
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      sx={{ color: "#FD6600" }}
                      size={20}
                      color="inherit"
                    />
                    <span style={{ marginX: "4px" }}>Please Wait</span>
                  </>
                ) : (
                  <Typography
                    style={{
                      fontSize: "16px",
                      // textAlign:"center",
                      paddingLeft: "13px",
                      color: "white",
                      fontWeight: "600px",
                    }}
                  >
                    {entityId ? "Contact Vendor" : "Send Message"}
                  </Typography>
                )}
                {/* <SendIcon
                  sx={{
                    color: "rgba(255, 255, 255, 1)",
                    height: "100%",
                    padding: "4px",
                    backgroundColor: "#fbfbfb5e",
                    borderTopRightRadius: "24px",
                    borderBottomRightRadius: "24px",
                  }}
                /> */}
              </Button>
            </Tooltip>
          ) : (
            <Button
              // container
              disabled={isLoading}
              onClick={(e) => isImpProfile || handleClick(e)}
              sx={{
                backgroundColor: isImpProfile
                  ? "rgba(217, 217, 217, 1)"
                  : "#002E33",
                color: "white",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "150px",
                height: "35px",
                marginBottom: "10px",
                cursor: isImpProfile ? "" : "pointer",
                // boxShadow: isImpProfile
                //   ? ""
                //   : "0px 4px 4px 0px rgba(57, 99, 171, 0.5)",
                "&:hover": {
                  backgroundColor: isImpProfile ? "" : "#002E33",

                  boxShadow: isImpProfile
                    ? ""
                    : "0px 0px 10px 0px rgba(0,0,0,0.7)",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                },
              }}
            >
              {isLoading ? (
                <>
                  <CircularProgress
                    sx={{ color: "white" }}
                    size={20}
                    color="inherit"
                  />
                  <span style={{ marginLeft: "8px" }}>Please Wait</span>
                </>
              ) : (
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    paddingLeft: "13px",

                    color: "white",
                  }}
                >
                  {entityId ? "Contact Vendor" : "Send Message"}
                </Typography>
              )}
              {/* <SendIcon
                sx={{
                  color: "rgba(255, 255, 255, 1)",
                  height: "100%",
                  padding: "4px",
                  backgroundColor: "#fbfbfb5e",
                  borderTopRightRadius: "24px",
                  borderBottomRightRadius: "24px",
                }}
              /> */}
            </Button>
          )}
          {isImpProfile ? (
            <Typography
              sx={{ fontSize: "10px", paddingTop: "4px", marginBottom: "20px" }}
            >
              Link of this business are disabled*
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <LogInMobilePopUp
        setOpenLogInModal={setOpenLogInModal}
        openLogInModal={openLogInModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />

      {profile?.supplierId && (
        <FavoritesDialogBox
          supplierId={profile?.supplierId}
          businessName={profile?.businessName}
          handleClose={handleFavoritesTagClose}
          open={openFavoritesTag}
          itemOwnerId={userIdOfOwner}
          userProfile={userProfile}
          setOpenSave={setOpenSave}
          businessEmail={profile?.businessProfile?.businessEmail}
          contact={profile?.businessProfile?.contact}
          subscriptionType={profile?.subscriptionType}
          url={profile?.url}
        />
      )}
      <Snackbar
        open={openSave}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Business shortlisted succesfully"
        direction="left"
      />
      {entityId ? (
        <RequirementFormModal
          open={openSendRfqModel}
          setOpen={setOpenSendRfqModel}
        />
      ) : (
        <NonEntityRequirmentForm
          open={openSendRfqModel}
          setOpen={setOpenSendRfqModel}
        />
      )}
    </>
  );
}

export default BusinessSendEnquiry;
