import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from "utils/axios";

import DashboardLayout from "layouts/Dashboard";
import Page from "../components/Page";
import LeadsList from "./LeadsList";
import { Helmet } from "react-helmet";
import VendorLeads from "./VendorLeads";

function Leads() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const lead = useSelector((state) => state.lead);
  const loading=lead.loading
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const business  = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;

  useEffect(() => {
    // console.log("Leads is refreshed:", lead);
  }, [lead?.leads]);

  // TODO_SP: Check if there is need to pull data, everytime user lands on the page

  return (
    <DashboardLayout path="/leads">
        <Helmet>
        <title>Neevay - Leads</title>
      </Helmet>
      <Page>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container height="" alignItems="baseline"  >
            {/* <Grid
              item
              sm={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                // variant="h4"
                fontWeight="bold"
                fontSize={16}
                marginTop="10px"
                marginLeft="20px"
              >
                Business Leads
              </Typography>
            </Grid> */}
            <Divider />
            <Grid item sm={12} fullWidth overflow={"auto"}>
              {/* <LeadsList
                title="Active Leads"
                loading={loading}
                leads={lead?.leads?.filter((row) => row.status !== "CLOSED")}
                supplierId={supplierId}
              /> */}
              <VendorLeads
                title="Active Leads"
                loading={loading}
                leads={lead?.leads?.filter((row) => row.status !== "CLOSED")}
                supplierId={supplierId}
              />
            </Grid>
            {/* <Grid item sm={12} mt={4} fullWidth>
              <LeadsList
                title="Closed Leads"
                leads={lead?.leads?.filter((row) => row.status === "CLOSED")}
                supplierId={supplierId}
              />
            </Grid> */}
          </Grid>
        </Box>
      </Page>
    </DashboardLayout>
  );
}

export default Leads;
