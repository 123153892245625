import React from "react";

const OverviewContacts = ({ newCcEmails, creator }) => {
  const getAvatar = (name) => {
    const nameArray = name?.split(" ");
    const initials =
      nameArray?.length === 1
        ? nameArray[0]?.charAt(0).toUpperCase()
        : nameArray[0]?.charAt(0) + nameArray[1]?.charAt(0);
    return (
      <div className="flex items-center gap-x-2">
        <div className="flex items-center justify-center w-4 h-4 bg-black rounded-full">
          <span style={{ fontSize: "8px" }} className="text-white ">
            {initials}
          </span>
        </div>
        <b className="text-xs font-semibold">{name}</b>
      </div>
    );
  };
  return (
    <div className="flex flex-col w-full justify-start" >
      <h2 className=" text-gray-900 font-semibold mb-2">RFQ Contacts</h2>
      <h4 className="text-xs font-semibold text-gray-600">Creator</h4>
      <p className="text-xs font-normal">{getAvatar(creator)}</p>
      {newCcEmails.length > 0 && (
        <h4 className="text-xs mt-4 font-semibold text-gray-600">CC List</h4>
      )}
      {newCcEmails.map((email) => (
        <b className="text-xs w-full truncate" key={email}>
          {email}
        </b>
      ))}
    </div>
  );
};

export default OverviewContacts;
