import React, { useState } from 'react'
import SearchButton from './SearchButton';
import NewSearchbar from './NewSearchbar';

const Hero = React.forwardRef((props,ref) => {
    const [postRfq, setpostRfq] = useState(false);
    const [hireVendor, sethireVendor] = useState(true);

    const handlePostRequirement=()=>{
        setpostRfq(!postRfq);
        sethireVendor(!hireVendor)
    }

    return (
        <div ref={ref} className='md:ml-11 ml-6 pt-36 md:pt-[192px] lg:pt-[232px] xl:pt-[212px]'>
            {hireVendor && <div>
                <div className='flex flex-col'>
                    <p className='hidden md:block text-white md:text-sm md:pb-3 lg:pb-4 z-10'>VENDOR SEARCH FOR CONSTRUCTION COMPANIES</p>
                    <p className='hidden md:block md:font-semibold md:text-5xl lg:text-6xl md:w-full md:text-white'>Thousands Of Construction <br /> Vendors At Your Fingertips</p>
                    <p className='md:hidden text-white text-[40px] font-bold pt-8 z-50'>1000s of<br /> Construction<br /> Vendors <br />at your Fingertips</p>
                </div>

                <div className='flex pt-[20px] md:pt-[34px] lg:pt-[31px]'>
                    <button className='flex items-center justify-around font-bold px-2 lg:px-4 py-2 md:w-[115px] md:h-[29px] lg:w-[123px] lg:h-[37px] text-white md:text-[12px] lg:text-[14px] underline md:font-bold bg-[#24242d] bg-opacity-75 border-l-4 border-[#F16500]'>
                        Hire a Vendor
                    </button>
                    <button onClick={handlePostRequirement} className='flex items-center justify-around px-2 lg:px-4 py-2 md:h-[29px] md:w-[140px] lg:h-[37px] lg:w-[150px] ml-4 text-white md:text-[12px] lg:text-[14px] underline bg-[#24242d] bg-opacity-75'>
                        Post Requirement
                    </button>
                </div>

                <div className='hidden md:block'>
                    <NewSearchbar />
                </div>
                <div className='block md:hidden '>
                    <SearchButton />
                </div>

                <p className='hidden md:block w-fit md:p-2 md:mt-10 md:text-[14px] text-white bg-[#24242d] bg-opacity-75'>Try searching for a <span className='underline'>Demolition, Electrical, RCC, Waterproofing</span> vendor.</p>
                <p className='block md:hidden w-fit mt-[10px] text-[14px] px-2 py-1 text-left text-white bg-[#24242d] bg-opacity-75'>Try searching for a <span className='underline'>Demolition, Electrical, RCC</span> vendor.</p>
            </div>}

            {postRfq &&<div>
                <div className='flex pt-[60px] md:pt-[34px] lg:pt-0'>
                    <button onClick={handlePostRequirement} className='flex items-center justify-around px-2 md:px-4 py-2 h-[29px] md:h-[37px] text-xs text-white lg:text-[14px] underline bg-[#24242d] bg-opacity-75 '>
                        Hire a Vendor
                    </button>
                    <button className='flex items-center justify-around px-2 md:px-4 py-2 h-[29px] md:h-[37px] ml-4 font-bold text-xs text-white md:text-[12px] lg:text-[14px] underline bg-[#24242d] bg-opacity-75 border-l-4 border-[#F16500]'>
                        Post a Requirement
                    </button>
                </div>

                <div className='flex flex-col'>
                    <p className='hidden md:block md:font-semibold md:text-5xl lg:text-6xl md:w-full md:pt-[23px] md:pb-[40px] text-white'>Post On Neevay, <br /> Vendors Will Contact You</p>
                    <p className='md:hidden text-[40px] font-bold pt-4 pb-6 text-white'>Post On Neevay, <br /> Vendors Will<br /> Contact You</p>
                    <p className=' text-white text-sm lg:text-xl md:pb-8 z-10'>Post your construction requirement as tenders<br /> and see the vendors start reaching out to you</p>
                </div>
                    <a href="https://forms.neevay.com/neevay/form/NeevayRequirement/formperma/mE6ER_q7Ftu-mYwW9sJIUwm9mHM0Jq7fVIxtn6WiLrw?referrername=website" target='blank'>
                <button className='flex items-center justify-around mt-8 md:mt-0 px-6 py-2 md:px-4 lg:px-6 h-[43px] lg:h-[50px] text-white md:text-xl bg-[#24242D] border-l-4 border-[#FF3D00]'>
                    Get Started
                </button>
                </a>
            </div>}
        </div>
    )
})

export default Hero
