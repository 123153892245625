import { vendorQuotationService } from "services/vendorQuotationService";

// Action types
export const SUBMIT_MATERIAL_QUOTATION = "@vendorQuotation/submit";
export const SUBMIT_MATERIAL_QUOTATION_SUCCESS = "@vendorQuotation/submit/success";
export const SUBMIT_MATERIAL_QUOTATION_FAILURE = "@vendorQuotation/submit/failure";

export const SUBMIT_LABOUR_QUOTATION = "@labourQuotation/submit";
export const SUBMIT_LABOUR_QUOTATION_SUCCESS = "@labourQuotation/submit/success";
export const SUBMIT_LABOUR_QUOTATION_FAILURE = "@labourQuotation/submit/failure";


export const SUBMIT_PROVIDING_QUOTATION = "@providingQuotation/submit";
export const SUBMIT_PROVIDING_QUOTATION_SUCCESS = "@providingQuotation/submit/success";
export const SUBMIT_PROVIDING_QUOTATION_FAILURE = "@providingQuotation/submit/failure";





export function submitMaterialQuotation({ values }) {
  console.log("IN THE ACTION",values)
    return async (dispatch) => {
      try {
        // Dispatch action to indicate submission is in progress
        dispatch({ type: SUBMIT_MATERIAL_QUOTATION });

        // Call your service to submit the vendor quotation
        const response = await vendorQuotationService.submitMaterialQuotation(values);
        console.log("ACTION RESPONSE",response);
        // Dispatch action to indicate successful submission
        dispatch({
          type: SUBMIT_MATERIAL_QUOTATION_SUCCESS,
          payload: { quotation: response?.rfqVendorQuotations?.vendorQuotations },
        });
      } catch (error) {
        // Dispatch action to indicate submission failed
        dispatch({
          type: SUBMIT_MATERIAL_QUOTATION_FAILURE,
          payload: { error: "Failed to submit quotation" },
        });
        throw error;
      }
    };
  }

  export function submitLabourQuotation({ values }) {
    return async (dispatch) => {
        try {
            // Dispatch action to indicate submission is in progress
            dispatch({ type: SUBMIT_LABOUR_QUOTATION });

            // Call your service to submit the labour quotation
            const response = await vendorQuotationService.submitMaterialQuotation(values);
            
            // Dispatch action to indicate successful submission
            dispatch({
                type: SUBMIT_LABOUR_QUOTATION_SUCCESS,
                payload: { quotation: response?.rfqVendorQuotations?.vendorQuotations },
            });
        } catch (error) {
            // Dispatch action to indicate submission failed
            dispatch({
                type: SUBMIT_LABOUR_QUOTATION_FAILURE,
                payload: { error: "Failed to submit quotation" },
            });
            throw error;
        }
    };
}


  export function submitProvidingQuotation({ values }) {
    return async (dispatch) => {
        try {
            // Dispatch action to indicate submission is in progress
            dispatch({ type: SUBMIT_PROVIDING_QUOTATION });

            // Call your service to submit the labour quotation
            const response = await vendorQuotationService.submitMaterialQuotation(values);
            
            // Dispatch action to indicate successful submission
            dispatch({
                type: SUBMIT_PROVIDING_QUOTATION_SUCCESS,
                payload: { quotation: response?.rfqVendorQuotations?.vendorQuotations },
            });
        } catch (error) {
            // Dispatch action to indicate submission failed
            dispatch({
                type: SUBMIT_PROVIDING_QUOTATION_FAILURE,
                payload: { error: "Failed to submit quotation" },
            });
            throw error;
        }
    };
}


export async function getVendorQuotation({ values }) {
  try {
    // Call the service to get vendor quotation
    const response = await vendorQuotationService.getVendorQuotation(values);
    console.log("Received Response:", response);
    return response; // Return the data directly
  } catch (error) {
    console.error("Failed to fetch vendor quotation:", error);
    throw error; // Re-throw error for caller to handle
  }
}



  