import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Minimal from "layouts/Minimal";
import Logo from "components/Logo";
import { Helmet } from "react-helmet";

import AboutNeevay from "../components/AboutNeevay";
import SignUpOTP from "./SignUPOTP";
import SignUpOTPnew from "./SignUPOTPnew";
import { registerUserWithMobile, sendwhatsAppOTP } from "actions/authActions";
import { useDispatch } from "react-redux";

function SignUpOTPVerify() {
  const [counter, setCounter] = React.useState(30);
  const dispatch = useDispatch()
  const location = useLocation();
  const {isWhatsappNo,mobile} = location.state
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    // navigate("/signin-mobile-otp-verify");
  };

 

const BASE_URL = process.env.REACT_APP_BASE_URL;

  const backgroundImage = `url("${BASE_URL}/assets/business/web-registerpage-registerBgImage.png")`;
  const backgroundImageMb = `url("${BASE_URL}/assets/business/web-registerpage-registerBgImageMb.svg")`;

  const styles = {
    container: {
      height: "100vh",
      width: "100vw",
      background: backgroundImage,
      objectFit:"cover",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"

    },
    mbContainer: {
      height: "100vh",
      width: "100vw",
      background: backgroundImageMb,
      objectFit:"cover",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"

    },
  };
  const resendOTP = () => {

    try {
        const name = "";
        if (isWhatsappNo) {
            dispatch(sendwhatsAppOTP(mobile))
        } else {
            dispatch(registerUserWithMobile(name, mobile));
        }
        setCounter(30);
    } catch (error) {
        // console.log("resendOTP: error", error);
    }
};
  React.useEffect(() => {
    const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
        clearInterval(timer);
    };
}, [counter]);

  return (
    // <Minimal>
    //   <Helmet>
    //     <title>SignUp OTP Verify</title>
    //   </Helmet>

    //   {isMd ? (
    //     <Box sx={{ flexGrow: 1 }}>
    //       <Grid container>
    //         <Grid
    //           item
    //           height="100vh"
    //           xs={12}
    //           sm={12}
    //           md={6}
    //           sx={{
    //             backgroundColor: "primary.main",
    //             color: "#ffffff",
    //           }}
    //         >
    //           <Grid py={"100px"}>
    //             <AboutNeevay />
    //           </Grid>
    //         </Grid>
    //         <Grid item xs={12} sm={12} md={6}>
    //           <SignUpOTP onSubmitSuccess={handleSubmitSuccess} />
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   ) : (
    //     <Box sx={{ flexGrow: 1 }}>
    //       <Grid container>
    //         <Grid item xs={12} sm={12} md={6}>
    //           <SignUpOTP onSubmitSuccess={handleSubmitSuccess} />
    //         </Grid>
    //         <Grid
    //           item
    //           // height="100vh"
    //           xs={12}
    //           sm={12}
    //           md={6}
    //           sx={{
    //             backgroundColor: "primary.main",
    //             color: "#ffffff",
    //           }}
    //         >
    //           <Grid py={"100px"}>
    //             <AboutNeevay />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   )}
    // </Minimal>
    <>
      <Helmet>
        <title>Register with OTP</title>
      </Helmet>
      {isMd? 
      <Box sx={styles.container}>
        <Grid container>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "100vh",
              width: "50vw",
            }}
          >
            <Typography
              style={{
                color: "#E1E1E1",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "38px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "43px",
                textTransform: "uppercase",
                width: "407px",
                height: "53px",
                letterSpacing:"0.5px"
              }}
            >
              Welcome to Neevay
            </Typography>
            <Typography
              style={{
                color: "#B9B9C3",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "32px",
                width: "489px",
                height: "64px",
              }}
            >
              Be a part of Growing Digital Ecosystem of Construction.{" "}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "100vh",
              width: "50vw",
            }}
          >
            <Box
              style={{
                width: "328px",
                height: "357px",
                borderRadius: "10px",
                background: "rgba(0, 0, 0, 0.64)",
                padding: "0",
                position: "relative",
              }}
            >
              <Grid
                style={{
                  width: "100%",
                  height: "53px",
                  backgroundColor: "#000",
                  borderRadius: "10px 10px 0 0",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <Typography
                  style={{
                    color: "#B9B9C3",

                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "53px" /* 220.833% */,
                    textTransform: "uppercase",
                  }}
                >
                  OTP Verification
                </Typography>
                <Box
                  component="img"
                  sx={{
                    height: 22,
                    width: 22,
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer",
                  }}
                  alt="green vector"
                  src="/assets/icons/crossIconRegisterForm.svg"
                  onClick={() => navigate("/")}
                />
              </Grid>
              <Grid style={{
                  width: "100%",
                  position: "absolute",
                  top: "80px",
                  display:"flex",
                  flexDirection:"column",
                  alignItems:"center",
                  justifyContent:"center",
                  alignItems:"center"
                }}>

                 {/* OTP Verify Form ............................. */}
                <SignUpOTPnew onSubmitSuccess={handleSubmitSuccess} />

              </Grid>
              <Grid
                style={{
                  width: "100%",
                  height: "31px",
                  backgroundColor: "#000",
                  borderRadius: "0 0 10px 10px",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center"
                }}
              >
                {counter > 0 && (
                                <Box>
                                    <Typography
                                        sx={{ fontSize: "12px", fontWeight: 400, color: "#fff", textAlign: "center" }}
                                    >
                                        You will receive OTP in{" "}
                                        <span style={{ color: "#6B6464", fontWeight: 400, fontSize: "12px", }}>
                                            {" "}
                                            {counter}
                                        </span>{" "}
                                        sec
                                    </Typography>
                                </Box>
                            )}
                            {counter <= 0 && (
                                <Box display={"flex"} style={{cursor:"pointer"}}>
                                    <Link
                                        sx={{ color: "#03D097", fontSize: "15px", fontWeight: 400, textAlign: "center" }}
                                        onClick={resendOTP}
                                    >
                                        Resend OTP
                                    </Link>
                                </Box>
                            )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>:
      <Box sx={styles.mbContainer}>
      <Grid container>
      <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "100vw",
              marginTop:"50px"
            }}
          >
            <Typography
              style={{
                color: "#E1E1E1",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "43px",
                textTransform: "uppercase",
                letterSpacing:"0.5px"
              }}
            >
              Welcome to Neevay
            </Typography>
            <Typography
              style={{
                color: "#B9B9C3",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              Be a part of Growing Digital Ecosystem of Construction.{" "}
            </Typography>
          </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100vw",
            marginTop: "47px"
          }}
        >
          <Box
            style={{
              width: "328px",
              height: "357px",
              borderRadius: "10px",
              background: "rgba(0, 0, 0, 0.64)",
              padding: "0",
              position: "relative",
              
            }}
          >
            <Grid
              style={{
                width: "100%",
                height: "53px",
                backgroundColor: "#000",
                borderRadius: "10px 10px 0 0",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <Typography
                style={{
                  color: "#B9B9C3",

                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "53px" /* 220.833% */,
                  textTransform: "uppercase",
                }}
              >
                                  OTP Verification

              </Typography>
              <Box
                component="img"
                sx={{
                  height: 22,
                  width: 22,
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer",
                }}
                alt="green vector"
                src="/assets/icons/crossIconRegisterForm.svg"
                onClick={() => navigate("/")}
              />
            </Grid>
            <Grid style={{
                width: "100%",
                position: "absolute",
                top: "80px",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"center",
                alignItems:"center"
              }}>

                {/* OTP VERIFY FORM ................... */}

                <SignUpOTPnew onSubmitSuccess={handleSubmitSuccess} />


            </Grid>
            <Grid
              style={{
                width: "100%",
                height: "31px",
                backgroundColor: "#000",
                borderRadius: "0 0 10px 10px",
                position: "absolute",
                bottom: 0,
                left: 0,
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
              }}
            >
               {counter > 0 && (
                                <Box>
                                    <Typography
                                        sx={{ fontSize: "12px", fontWeight: 400, color: "#fff", textAlign: "center" }}
                                    >
                                        You will receive OTP in{" "}
                                        <span style={{ color: "#6B6464", fontWeight: 400, fontSize: "12px", }}>
                                            {" "}
                                            {counter}
                                        </span>{" "}
                                        sec
                                    </Typography>
                                </Box>
                            )}
                            {counter <= 0 && (
                                <Box  display={"flex"} style={{cursor:"pointer"}}>
                                    <Link
                                        sx={{ color: "#03D097", fontSize: "15px", fontWeight: 400, textAlign: "center" }}
                                        onClick={resendOTP}
                                    >
                                        Resend OTP
                                    </Link>
                                </Box>
                            )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
      }
      
    </>
  );
}

export default SignUpOTPVerify;
