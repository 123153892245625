import React from "react";

import { Link } from "react-router-dom";
import { Container, Button, Box, Typography } from "@mui/material";

import MainLayout from "layouts/Main";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const PaymentSuccessPage = () => {
  

  return (
    <MainLayout>
      <Container
        maxWidth="lg"
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: 5,
            p: 4,
            borderRadius: 1,
            m: 4,
          }}
        >
         

          <CheckCircleIcon sx={{ width: 100, height: 100, color: "green" }} />
          <Typography component="h1" variant="h3">
            Payment successful
           
          </Typography>

          <Button sx={{mt:5}} component={Link} to="/my-business" variant="contained">
            Update Business{" "}
          </Button>
          
        </Box>
      </Container>
    </MainLayout>
  );
};

export default PaymentSuccessPage;
