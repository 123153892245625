const pages = {
  landings: [
    {
      title: 'Sign Up',
      href: '/sign-up',
    },
    {
      title: 'Sign In',
      href: '/sign-in',
    }
  ],

  profile: [
    {
      title: 'My Profile',
      href: '/my-profile',
    },
  ],
  jobs: [
    {
      title: 'Full Time',
      href: '/jobs-fulltime',
    },
    {
      title: 'Part Time',
      href: '/jobs-partime',
    },
  ],
  account: [
    {
      title: 'General Settings',
      href: '/account-general',
    },
    {
      title: 'Security Settings',
      href: '/account-security',
    },
  ],
  blog: [
    {
      title: 'Blog 1',
      href: '/blog-1',
    },
    {
      title: 'Blog 2',
      href: '/blog-2',
    },
    {
      title: 'Newsroom',
      href: '/blog-newsroom',
    },
    {
      title: 'Article',
      href: '/blog-article',
    },
  ],
};

export default pages;
