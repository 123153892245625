import React from 'react'
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

const FilterUrlConverter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    //const { searchString, region, variable, businessCategory } = useParams(); //get searchString and region
    const { searchString, region, filterData } = useParams(); //get searchString and region
    const queryString = `${filterData}`
    useEffect(() => {
        // console.log(`/search-business-category/${searchString}/${region}?${queryString}`, "---000");
        navigate(
            `/search-business-category/${searchString}/${region}?${queryString}`
        );
        // `/search-business-category/${searchString}/${region}?Category=${businessCategory}`

    }, [])
    return (
        <div></div>
    )
}

export default FilterUrlConverter