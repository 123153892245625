// CreateNewProjectForm.jsx
import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import UploadMaterialFile from "./UploadMaterialFile";
import { addNewProject, EditRfqProject } from "actions/rfqActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading";
import ProjectDetailsView from "./ProjectDetailsView";
import MaterialServiceView from "./MaterialServiceView";
import RfqsTabView from "./RfqsTabView";

const projectDirectors = ["okmar", "arjun", "snehal"];

const CreateNewProjectForm = ({
  setShowCreateProjectForm,
  isEdit = false,
  data = [],
  onProjectCreated
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [steeperName, setSteeperName] = useState("project");
  const [loading, setLoading] = useState(false);
  const [projectViewEdit, setProjectViewEdit] = useState(isEdit);

  const user = auth.user;
  const userId = user?.userId;
  const entityId = user?.userEntity?.entityId;

  const validationSchema = Yup.object({
    projectName: Yup.string().required("Required"),
    projectEstimation: Yup.number()
      .typeError("Project Estimation must be a number")
      .positive("Project Estimation must be a positive number")
      .integer("Project Estimation must be an integer"),
    region: Yup.string(),
    projectType: Yup.string().required("Required"),
    area: Yup.string(),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    projectDirector: Yup.string(),
    materials: Yup.array().of(
      Yup.object().shape({
        basePrice: Yup.number()
          .typeError("Budget Rate must be a number")
          .positive("Budget Rate must be a positive number")
          .when([], {
            is: () => steeperName === "material",
            then: Yup.number()
              // .required("Required")
              .typeError("Budget Rate must be a number")
              .positive("Budget Rate must be a positive number"),
            otherwise: Yup.number()
              .typeError("Budget Rate must be a number")
              .positive("Budget Rate must be a positive number"),
          }),
        name: Yup.string().when([], {
          is: () => steeperName === "material",
          // then: Yup.string().required("Required"),
          otherwise: Yup.string(),
        }),
        type: Yup.string().when([], {
          is: () => steeperName === "material",
          // then: Yup.string().required("Required"),
          otherwise: Yup.string(),
        }),
      })
    ),
    // engineerMobile: Yup.string()
    //   .matches(
    //     /^[0-9]*$/,
    //     "Contact Number must contain only numeric characters"
    //   )
    //   .matches(/^\d{10}$/, "Contact Number must be exactly 10 digits"),

      engineerDetails: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          mobile: Yup.string()
            .matches(/^[0-9]*$/, "Contact Number must contain only numeric characters")
            .matches(/^\d{10}$/, "Contact Number must be exactly 10 digits")
            // .required("Contact Number is required"),
        })
      ),
  });

  const handleSubmit = async (values) => {
    const filteredValues = values.engineerDetails.filter(
      (detail) => detail.name.trim() !== "" || detail.mobile.trim() !== ""
    );

    setLoading(true);
    const formPayload = {
      entityId: entityId,
      name: values.projectName,
      projectType: values?.projectType,
      projectDirector: values?.projectDirector,
      estimateValue: values.projectEstimation,
      location: {
        area: values.area,
        city: values.city,
        state: values.state,
        region: values.region,
      },
      // engineerDetails: [
      //   {
      //     name: values.engineerName,
      //     mobile: values.engineerMobile,
      //   },
      // ],
      engineerDetails : filteredValues,
      materialDetails: values?.materials ?? [],
      createdBy: userId,
    };
    if (isEdit) {
      const responseData = await dispatch(
        EditRfqProject(formPayload, data?._id)
      );
    } else {
      const response = await dispatch(addNewProject(formPayload));
      if (typeof onProjectCreated === "function") {
        onProjectCreated(response);
      }
    }
    setLoading(false);
    setShowCreateProjectForm(false);
  };

  const handleSubmitEdit = async (values) => {
    setLoading(true);
    const projectId = data?._id;
    const formPayload = {
      name: values.projectName,
      estimateValue: values.projectEstimation,
      projectType: values?.projectType,
      projectDirector: values?.projectDirector,
      location: {
        area: values.area,
        city: values.city,
        state: values.state,
        region: values.region,
      },
      engineerDetails: values?.extraEngineerDetails,
    };
    const response = await dispatch(EditRfqProject(formPayload, projectId));
    setLoading(false);
    setShowCreateProjectForm(false);
  };
  return (
    <div>
      {projectViewEdit ? (
        <div className="flex w-full items-center gap-8">
          <p
            className={`text-nowrap cursor-pointer flex items-center  ${steeperName == "project"
                ? "border-b-2 border-orange-500 font-medium"
                : "text-gray-700"
              }`}
            onClick={() => setSteeperName("project")}
          >
            Project Details
          </p>

          <p
            className={`text-nowrap cursor-pointer  ${steeperName == "material"
                ? "border-b-2 border-orange-500 font-medium"
                : "text-gray-700"
              }`}
            onClick={() => setSteeperName("material")}
          >
            Material Category
          </p>

          <p
            className={`text-nowrap cursor-pointer ${steeperName == "rfqs"
                ? "border-b-2 border-orange-500 font-medium"
                : "text-gray-700"
              }`}
            onClick={() => setSteeperName("rfqs")}
          >
            RFQs
          </p>
        </div>
      ) : (
        <div className="flex w-full mb-4 items-center gap-1">
          <p
            className={`text-nowrap flex items-center text-lg font-semibold ${steeperName == "project" ? "" : "text-gray-500 cursor-pointer"
              }`}
            onClick={() => setSteeperName("project")}
          >
            Project Details
            {steeperName != "project" && (
              <span>
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  tabindex="-1"
                  title="CheckCircle"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path>
                </svg>
              </span>
            )}
          </p>
          <hr className="w-full" />
          <p
            className={`text-nowrap text-lg font-semibold ${steeperName == "material" ? "" : "text-gray-500"
              }`}
          >
            Material / Service
          </p>
        </div>
      )}
      <Formik
        initialValues={{
          projectName: isEdit ? data?.name || "--" : "",
          projectEstimation: isEdit ? data?.estimateValue || "" : "",
          projectType: isEdit ? data?.projectType || "--" : "",
          area: isEdit ? data?.location?.area || "--" : "",
          city: isEdit ? data?.location?.city || "--" : "",
          state: isEdit ? data?.location?.state || "--" : "",
          region: isEdit ? data?.location?.region || "--" : "",
          // engineerName: isEdit ? data?.engineerDetails[0]?.name || "" : "",
          // engineerMobile: isEdit ? data?.engineerDetails[0]?.mobile || "" : "",
          engineerDetails: isEdit
            ? data?.engineerDetails.map((detail) => ({
              name: detail.name || "",
              mobile: detail.mobile || "",
            }))
            : [{ name: "", mobile: "" }],
          projectDirector: isEdit ? data?.projectDirector || "--" : "",
          materials: isEdit
            ? data?.materialDetails ?? []
            : [{ type: "", name: "", basePrice: "" }],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // if (isEdit) {
          //   handleSubmitEdit(values);
          // } else {
          if (steeperName == "material") {
            handleSubmit(values);
          } else {
            setSteeperName("material");
          }
          // }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="py-1 space-y-4">
            {steeperName == "project" && (
              <div className="p-2 px-4  h-[80vh]">
                {" "}
                <ProjectDetailsView
                  edit={projectViewEdit}
                  loading={loading}
                  setProjectViewEdit={setProjectViewEdit}
                />{" "}
              </div>
            )}
            {/* Material/Service Section */}

            {steeperName == "material" && (
              <div className="p-2 px-4 h-[80vh]">
                <MaterialServiceView
                  handleSubmit={handleSubmit}
                  loading={loading}
                  edit={isEdit}
                  setProjectViewEdit={setProjectViewEdit}
                  projectViewEdit={projectViewEdit}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
      {steeperName == "rfqs" && (
        <RfqsTabView projectId={data?._id} steeperName={steeperName} />
      )}
    </div>
  );
};

export default CreateNewProjectForm;
// export default CreateNewProjectForm;
