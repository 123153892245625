import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider
} from "@mui/material";
import { useSelector } from "react-redux";
const ChangeRoll = ({ viewClose, getOwnerType, rowData}) => {

    const business = useSelector((state) => state.business);
    const supplierId = business.supplierId;
  const [type, setType] = React.useState(rowData.memberType);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  
  return (
    <>
      <Card>
        <CardHeader title="CHANGE OWNER" />
        <Divider/>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>Name: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography> {rowData.memberName} </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Member Id: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography> {rowData.memberId} </Typography>
            </Grid>
            <Grid item xs={3} sx={{alignItems:'center'}}>
              <Typography>Change Owner Type: </Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" >Owner Type</InputLabel>
                <Select
                size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Owner Type"
                  onChange={handleChange}
                >
                  <MenuItem value="OWNER">OWNER</MenuItem>
                  <MenuItem  value="MEMBER">MEMBER</MenuItem>
                  <MenuItem  value="ASSOCIATE">ASSOCIATE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} >
              
              <Button  size="small" variant="contained" onClick={()=>getOwnerType(supplierId, type, rowData.memberId)}>Save</Button>
              <Button size="small"  sx={{ml:1}} variant="contained" onClick={viewClose}>Cancel </Button>
            </Grid>
           
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};


ChangeRoll.propTypes = {
    getOwnerType: PropTypes.func.isRequired,
    viewClose: PropTypes.func.isRequired,
    rowData: PropTypes.func.isRequired,
    
  };


export default ChangeRoll;
