import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBusinessOwnerEmail } from "actions/businessActions";

const BusinessNameDropDown = ({
  enquiryState,
  setEnquiryState,
  setBusinessNameKey,
  existingRfqEditView = false,
}) => {
  const dispatch = useDispatch();

  const businessData = useSelector((state) => state.searchBusiness.suppliers);
  const [emailError, setEmailError] = useState(false);
  const [showBusinessData, setShowBusinessData] = useState([]);
  const [selectedBusinesIndex, setSelectedBusinesIndex] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [newEmailValue, setNewEmailValue] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [addEmailError, setAddEmailError] = useState(false);

  const validateEmail = (email) => {
    const validationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid =
      validationRegex.test(email) &&
      !/\.(com|in)[^a-zA-Z0-9]/.test(email) &&
      !/\.{2,}/.test(email) &&
      !/\.,com$/.test(email);

    return isValid;
  };
  const handleCheckboxChange = (email) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails(
        selectedEmails.filter((selectedEmail) => selectedEmail !== email)
      );
    } else {
      setSelectedEmails([...selectedEmails, email]);
    }
  };
  const handleOnHoverBusiness = async (index) => {
    setSelectedBusinesIndex(index);
    setNewEmailValue("");
    //get supplier owner emails and and into show data
    const suppliers = [{ supplierId: showBusinessData[index]?.supplierId }];
    const result = await dispatch(fetchBusinessOwnerEmail(suppliers));
    const newEmailValue = result[0]?.emails
      ? result[0]?.emails?.filter((data) => data !== "" && data !== undefined)
      : [];
    setSelectedEmails([
      ...new Set([...showBusinessData[index]?.emails, ...newEmailValue]),
    ]);
    setShowBusinessData((prevBusinessData) => {
      const updatedData = [...prevBusinessData];
      if (updatedData[index]) {
        updatedData[index] = {
          ...updatedData[index],
          emails: [
            ...new Set([...updatedData[index].emails, ...newEmailValue]),
          ],
        };
      }
      return updatedData;
    });
  };
  const handleMouseLeaveBusiness = () => {
    // setSelectedBusinesIndex(null);
  };
  const handleChangeNewEmailValue = (value) => {
    setNewEmailValue(value);
  };
  const handleAddNewEmail = () => {
    if (validateEmail(newEmailValue)) {
      if (
        showBusinessData[selectedBusinesIndex]?.emails?.includes(newEmailValue)
      ) {
        setEmailError(true);
        setTimeout(() => {
          setEmailError(false);
        }, 3000);
        setEmailErrorMsg("This email is already exist");
      } else {
        setShowBusinessData((prevBusinessData) => {
          const updatedData = [...prevBusinessData];
          if (updatedData[selectedBusinesIndex]) {
            updatedData[selectedBusinesIndex] = {
              ...updatedData[selectedBusinesIndex],
              emails: [
                ...updatedData[selectedBusinesIndex].emails,
                newEmailValue,
              ],
            };
          }
          return updatedData;
        });
        setSelectedEmails([...selectedEmails, newEmailValue]);
        setNewEmailValue("");
        setEmailError(false);
      }
    } else {
      setTimeout(() => {
        setEmailError(false);
      }, 3000);
      setEmailErrorMsg("Please enter a valid email");
      setEmailError(true);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddNewEmail();
    }
  };
  const addSupplierInVendorList = (data) => {
    if (selectedEmails?.length > 0) {
      const newSuplierData = { ...data, emails: selectedEmails };
      setEnquiryState((preVal) => [...preVal, newSuplierData]);
      setBusinessNameKey("");
    } else {
      setAddEmailError(true);
      setTimeout(() => {
        setAddEmailError(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const allVendorsSupplierId = enquiryState.map((obj) => obj.supplierId);
    const filterSupliersData = businessData.filter(
      (data) => !allVendorsSupplierId?.includes(data?.supplierId)
    );
    setShowBusinessData(filterSupliersData);
    setSelectedBusinesIndex(null);
  }, [businessData]);
  return (
    <div>
      <Box
        sx={{
          paddingY: "8px",
          position: "absolute",
          width: "200px",
          background: "#EEE",
          left: 0,
          zIndex: 111,
          top: "110%",
          borderRadius: "12px",
          border: "1px solid #848484",
        }}
      >
        {showBusinessData?.length == 0 ? (
          <Typography sx={{ padding: "8px" }}>No Vendor found</Typography>
        ) : (
          showBusinessData?.map((data, i) => (
            <Box
              key={i}
              sx={{ position: "relative" }}
              // onMouseEnter={() => handleOnHoverBusiness(i)}
              onMouseLeave={handleMouseLeaveBusiness}
            >
              <Grid
                onClick={() => handleOnHoverBusiness(i)}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  background: i === selectedBusinesIndex ? "#D9D9D9" : "#EEE",
                  "&:hover": {
                    background: "#D9D9D9",
                  },
                  padding: "8px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    // fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                  title={data.businessName}
                >
                  {data.businessName}
                </Typography>
                <ArrowDropDownIcon
                  style={{
                    transform: "rotate(270deg)", // Rotate the icon 270 degrees
                  }}
                />
              </Grid>

              {i === selectedBusinesIndex && (
                <Grid
                  sx={{
                    position: "absolute",
                    minWidth: "280px",
                    background: "#EEE",
                    borderRadius: "12px",
                    border: "1px solid #848484",
                    left: "100%",
                    padding: "8px",
                    top: 0,
                  }}
                >
                  <Typography>Add new Email</Typography>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap="8px"
                    mt="8px"
                  >
                    <TextField
                      size="small"
                      autoFocus
                      name="new email"
                      id="newEmail"
                      placeholder="requirement1@stresstech.com"
                      sx={{ width: "100%" }}
                      value={newEmailValue}
                      onKeyDown={handleKeyDown}
                      onChange={(e) =>
                        handleChangeNewEmailValue(e.target.value)
                      }
                    />
                    <Box onClick={handleAddNewEmail} sx={{ cursor: "pointer" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <rect
                          width="19"
                          height="19"
                          rx="4"
                          fill={
                            newEmailValue?.length > 0 ? "#04EEAD" : "#707070"
                          }
                        />
                        <path
                          d="M4 9.5C4.29333 9.5 11.4556 9.5 15 9.5M9.5 4V15"
                          stroke={newEmailValue?.length > 0 ? "black" : "white"}
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Box>
                  </Grid>
                  {emailError && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      {emailErrorMsg}{" "}
                    </Typography>
                  )}
                  {data.emails.map((email, index) => (
                    <Grid
                      key={index}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        checked={selectedEmails.includes(email)}
                        onChange={() => handleCheckboxChange(email)}
                      />
                      <Typography>{email}</Typography>
                    </Grid>
                  ))}
                  <Grid width="100%">
                    <Button
                      sx={{
                        float: "right",
                        background: "#FD6600",
                        "&:hover": {
                          background: "#FD6600",
                           boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)", // Add box shadow on hover
                          transition: "background-color 0.3s, box-shadow 0.3s",
                        },
                        color: "white",
                        height: "36px",
                        //   width: "83px",
                      }}
                      onClick={() => addSupplierInVendorList(data)}
                    >
                      Add selected
                    </Button>
                  </Grid>
                  {addEmailError && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      Atleast one email is required
                    </Typography>
                  )}
                </Grid>
              )}
            </Box>
          ))
        )}
      </Box>
    </div>
  );
};

export default BusinessNameDropDown;
