import { Button, Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PreViewBlog = () => {
    const maxLengthForSummary = 8;
    const maxLengthForHeading = 5;
  
    const  navigate=useNavigate()
  const location = useLocation();
  const { blog, getBlogs } = location.state;
  const lastThreeBlogs = getBlogs.slice(-4);
  ;
  const extractImageFromHTML = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const imageElements = doc.querySelectorAll("img");
    return Array.from(imageElements).map((img) => img.src);
  };
  const handleBackButton=()=>{
    navigate("/cms/blogs")
  }
  return (
    <div>
      <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "start",
          marginLeft: "50px",
          marginTop: "20px",
        }}
      >
        <Button
        onClick={handleBackButton}
          sx={{ textAlign: "center", fontSize: "14px", fontWeight: "500" }}
        >
          {/* «  */}
          Back
        </Button>
      </Grid>
        <main className="px-4 pb-4 bg-white xl:max-w-lwh dark:bg-gray-900 antialiased ">
          <header className="py-12">
            <div className="px-4 mx-auto w-full max-w-screen-xl text-center">
              <span className="block mb-4 font-semibold text-gray-900 dark:text-white">
                Published{" "}
                <time
                  className="font-normal text-gray-500 dark:text-gray-400"
                  datetime="2022-03-08"
                  title="August 3rd, 2022"
                >
                  {blog?.date}
                </time>
              </span>
              <h1 className="mx-auto mb-4 max-w-2xl text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
                {blog?.heading}
              </h1>
              <p className="text-lg font-normal text-gray-500 dark:text-gray-500">
                {blog?.summary}
              </p>
              <div class="flex justify-center items-center mt-2 mb-4 text-sm text-red-500 dark:text-white">
                                <div class="mt-1 mr-1.5 w-2.5 h-[2px] bg-red-400 rounded-full">
                                </div>7 min read
                            </div>
            </div>
          </header>
          <div className="flex relative z-20 justify-between px-4 mx-auto max-w-screen-xl bg-white dark:bg-gray-900 rounded">
            <article className="xl:w-[828px] w-full max-w-none format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center py-6 border-t border-b border-gray-200 dark:border-gray-700">
                <span className="text-base mb-4 lg:mb-0 font-normal text-gray-500 dark:text-gray-400">
                  By{" "}
                  <a
                    href="#"
                    rel="author"
                    className="font-bold text-xl text-gray-900 dark:text-white no-underline hover:underline"
                  >
                    {blog?.author}
                  </a>{" "}
                  in{" "}
                  <a
                    href="#"
                    className="font-normal text-gray-500 dark:text-gray-400 no-underline hover:underline"
                  >
                    World News
                  </a>
                </span>
                {/* <aside aria-label="Share social media">
                  <a
                    href={"https://www.facebook.com/neevayofficial"}
                    className="inline-flex items-center py-2 px-6 mr-2 text-xs font-medium text-gray-900 no-underline bg-white rounded-lg border border-gray-200 focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      className="w-3 h-3 mr-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 8 19"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Share
                  </a>
                  <a
                    href={"https://twitter.com/NeevayOfficial"}
                    className="inline-flex items-center py-2 px-6 mr-2 text-xs font-medium text-gray-900 no-underline bg-white rounded-lg border border-gray-200 focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      className="w-3 h-3 mr-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="currentColor"
                        d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z"
                      />
                    </svg>
                    Tweet
                  </a>
                  <button
                    type="button"
                    onClick={handleCopyLink}
                    className="inline-flex items-center py-2 px-6 text-xs font-medium text-gray-900 no-underline bg-white rounded-lg border border-gray-200 focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      className="w-3 h-3 mr-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 19 19"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11.013 7.962a3.519 3.519 0 0 0-4.975 0l-3.554 3.554a3.518 3.518 0 0 0 4.975 4.975l.461-.46m-.461-4.515a3.518 3.518 0 0 0 4.975 0l3.553-3.554a3.518 3.518 0 0 0-4.974-4.975L10.3 3.7"
                      />
                    </svg>
                    Copy link
                  </button>
                </aside> */}
              </div>
              <p
                className="lead py-2 text-lg"
                dangerouslySetInnerHTML={{ __html: blog?.description }}
              />
            </article>
           {<aside className="hidden xl:block" aria-labelledby="sidebar-label">
              <div className="xl:w-[336px] sticky top-6">
                <div className="p-4 mb-6 rounded-lg  border-gray-200 dark:border-gray-700">
                  <h4 className="mb-4 text-sm font-bold border-b-2 text-gray-900 dark:text-white uppercase">
                    Latest Blogs
                  </h4>

                  {lastThreeBlogs?.slice(1).map((item) => (
                    <div className="mb-6 flex items-center">
                      {extractImageFromHTML(item.description).map(
                        (imageUrl, index) => (
                          <a href="#" className="shrink-0">
                            <img
                              src={imageUrl}
                              className="mr-4 max-w-full w-24 h-24 rounded-lg"
                              alt={`Image ${index}`}
                            />
                          </a>
                        )
                      )}

<div>
                          <h5 className="mb-2 text-lg font-bold leading-tight dark:text-white text-gray-900">
                            {/* {item.heading} */}
                            {item.heading.length > maxLengthForHeading
                              ? item.heading
                                  .split(" ")
                                  .slice(0, maxLengthForHeading)
                                  .join(" ") + "..."
                              : item.heading}
                          </h5>
                          <p className="mb-2 text-gray-500 dark:text-gray-400">
                            {item.summary.length > maxLengthForSummary
                              ? item.summary
                                  .split(" ")
                                  .slice(0, maxLengthForSummary)
                                  .join(" ") + "..."
                              : item.summary}
                          </p>
                          <a href="#" className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline">
                                                Read in 9 minutes
                                            </a>
                        </div>
                    </div>
                  ))}
                </div>
              </div>
            </aside>}
          </div>
        </main>

        <footer className="bg-gray-50 dark:bg-gray-800 antialiased">
          <div className="p-4 py-6 mx-auto max-w-screen-xl md:p-8 lg:p-10">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-5"></div>
          </div>
        </footer>
      </>
    </div>
  );
};

export default PreViewBlog;
