import React from 'react'
import { FloatingButton, GlobalNavbar } from 'views/Home 3.0/components'
import HeroSection from './HeroSection'
import FooterNew from 'views/Home/components/FooterNew'
import Companies from './Companies'
import Testimonial from './Testimonial'
import Faq from './Faq'
import { Helmet } from 'react-helmet'

const NewSubScribePlan = () => {
  return <>
  <Helmet>
        <title>Neevay - Subscription</title>
      </Helmet>
      <GlobalNavbar/>
      <HeroSection/>
      <Companies/>
      <Testimonial/>
      <Faq/>
      <FooterNew/>
      <FloatingButton/>
    </>
  
}

export default NewSubScribePlan
