import React from "react";

import { Button } from "@mui/material";
import { dateFormatter } from "utils/formatUtils";
import { ConfirmDialog } from "components";
import { Dialog, DialogContent , MenuList, MenuItem} from "@mui/material";
import LeadView from "./MemberView";

export const headCells = (
  showDelete,
  deleteMember,
  modal,
  setModal,
  openDialogView,
  Dialog,
  viewClose,
  view
) => [
  { field: "memberId", headerName: "Member Id", minWidth: 50 },
  {
    field: "memberName",
    headerName: "Member Name",
    minWidth: 200,
  },
  {
    field: "memberMobile",
    headerName: "Member Mobile",
    minWidth: 150,
  },

  {
    field: "email",
    headerName: "Email",
    minWidth: 200,
  },
  {
    field: "memberType",
    headerName: "Member Type ",
    minWidth: 100,
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   minWidth: 150,
  // },
  {
    field: "addedBy",
    headerName: "Added By",
    minWidth: 200,
  },

  {
    field: "requesterContact",
    headerName: "Requester Contact",
    minWidth: 150,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    valueGetter: (params) => dateFormatter(params.row.createdAt),
  },
  {
    field: "isDeleted",
    headerName: "Is Deleted",
    minWidth: 100,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    minWidth: 250,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const { api } = params;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            // eslint-disable-next-line no-return-assign
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );

      };
      const supplierId = 6;
      return (
        <>
          {/* <Dialog
        open={modal}

        keepMounted
        onClose={closeDailog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
         12113
         <Button onClick={(paramsId)=>deleteMember(params.memberId)}>YEs</Button>
        </DialogContent>
      </Dialog> */}

         
          {showDelete && params.row.memberId && (
            <>

              <ConfirmDialog
                // open={modal}
                title="Are you Sure"
                description={`Do you want to permanently delete ${params.row.summary}, action can't be undone`}
                isOpen={modal === params.row.memberId && params.row.memberId}

                confirmAction={() => {
                  setModal(false);
                  deleteMember(supplierId, params.row.memberId);
                }}
                cancelAction={() => setModal(false)}
              />
               <Button  onClick={() => openDialogView(params.row)}>Change Role</Button>
               
              <Button onClick={() => setModal(params.id)}>Delete</Button>
            </>
          )}
        </>
      );
    },
  },
  // {
  //   field: "acknowledge",
  //   headerName: "Acknowledge Status",
  //   minWidth: 150,
  //   renderCell: (params) => {
  //     let ackStatus = "Pending  ";

  //     if (
  //       params &&
  //       params.row &&
  //       params.suppliers &&
  //       params.suppliers.length > 0
  //     ) {
  //       ackStatus = "Acknowledged";
  //     }
  //     return ackStatus;
  //   },
  // },
  // {
  //   field: "action",
  //   headerName: "Actions",
  //   width: 150,
  //   sortable: false,

  //   renderCell: (params) => {
  //     const onClickView = (e) => {
  //       e.stopPropagation();
  //     };

  //     return (
  //       <>
  //         {showView && params.id && (
  //           <>
  //             <LeadView
  //               title="View Requirement"
  //               isOpen={modal === params.id && params.id}
  //               confirmAction={() => {
  //                 setModal(false);
  //                 sendRFQAck(params.id);
  //               }}
  //               cancelAction={() => setModal(false)}
  //               rfq={params.row}
  //             />
  //             <Button onClick={() => setModal(params.id)}>
  //               View & Acknowledge
  //             </Button>
  //           </>
  //         )}
  //       </>
  //     );
  //   },
  // },
];
