import * as React from "react";
import Dialog from "@mui/material/Dialog";
import SearchIcon from "@mui/icons-material/Search";
import Slide from "@mui/material/Slide";
import { Grid, InputBase, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import SearchCityMobileView from "./SearchCityMobileView";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import { Person } from "@mui/icons-material";
import SearchBarNew from "views/Home 3.0/components/HeroSectionNew.js/SearchBarNew";
import SearchBar from "views/Home 3.0/components/HeroSection/SearchBar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function NewTopbar({
  open,
  setOpen,
  handleClose,
  regionstring,
  handleSearch,
  handleSearchInputChange,
  searchInputValue,
  handleKeyDown,
  setSearchInputValue,
  setSearchInputRegionValue,
  searchInputResionValue,
  handleSearchRegionInputChange,
  searchTerm,
}) {
  const textRef = React.useRef(null);
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [topRegionValue, setTopRegionValue] = useState(
    regionstring || "Region"
  );

  const inputRef = React.useRef(null);
const isMobileSearchBar=true
  const mostPopularSerchesData = [
    "Civil Contractor",
    "Plumbing Contractor",
    "Cement Supplier",
    "Structural Consultants",
    "Waterproofing Contractor",
    "TMT Bar Supplier",
  ];

  const openDialogBoxFun = () => {
    setOpenDialogBox(true);
    setSearchInputRegionValue("");
  };
  const searchesClick = (data) => {
    setSearchInputValue(data);
  };
  const handleClickOnRegion = (value) => {
    setTopRegionValue(value);
    setSearchInputRegionValue(value);
    setOpenDialogBox(false);
  };
  const handleChangeRegion = () => {
    // setSearchInputRegionValue("");
  };
  const handleClear = () => {
    setSearchInputRegionValue("");
  };
  const handleSearchDialog = () => {
    handleSearch();
    handleClose();
  };

  React.useEffect(() => {
    if (open) {
      // Use setTimeout to ensure the ref is available
      setTimeout(() => {
        inputRef?.current?.click();
      }, 0);
    }
  }, [open, openDialogBox]);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        // TransitionComponent={Transition}
      >
        <div style={{color:"#00AB6B",display:"flex",alignItems:"center",paddingTop:"10px",paddingLeft:"8px"}}   onClick={handleClose}> 
        {/* <ArrowBackIosIcon
          style={{ width: "16px", height: "12px", marginTop: "4px",color:"#00AB6B", }}
          onClick={handleClose}
          /> */}
     {/* <p style={{paddingTop:"4px",fontSize:"12px"}}>
     « {" "}
     </p> */}
     <p style={{paddingTop:"4px",fontSize:"10px",color:"black"}}>
     « {" "}  Back
     </p>
          </div>

        <div style={{ marginTop: "20px" }}>
          <SearchBarNew handleClose={handleClose} isMobileSearchBar={true}/>
          
        </div>
        {/* <Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "12px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <ArrowBackIosIcon
              style={{ width: "16px", height: "14px", marginTop: "4px" }}
              onClick={handleClose}
            />
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LocationOnOutlinedIcon
                style={{ color: "rgba(149, 149, 149, 1)", height: "18px" }}
              />
              <Typography onClick={openDialogBoxFun}>
                {topRegionValue}{" "}
              </Typography>
              <ExpandMoreOutlinedIcon />
            </Grid>
            {userProfile?.name[0] ? (
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "rgba(71, 181, 158, 1)",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {userProfile?.name[0]}
              </Box>
            ) : (

              <Box
                sx={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "rgba(71, 181, 158, 1)",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <Person sx={{ color: "white" }} />
              </Box>
            )}
          </Grid>
          <Typography
            style={{
              textAlign: "center",
              marginTop: "8px",
              marginBottom: "8px",
              fontSize: "14px",
            }}
          >
            Search Engine of Construction Industry
          </Typography>

          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              border: "1px solid black",
              borderRadius: "10px",
              marginX: "10px",
            }}
          >
            <InputBase
              placeholder="example: Contractor, Supplier"
              onKeyDown={handleKeyDown}
              value={searchInputValue}
              onChange={handleSearchInputChange}
              sx={{
                minWidth: "300px",
                padding: "0px 9px 3px 11px",
              }}
              ref={inputRef} // Create a ref to the InputBase element
            />

            <SearchIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4px",
                marginRight: "10px",
              }}
              onClick={handleSearchDialog}
            />
          </Grid>
          <Grid sx={{ marginX: "10px", marginTop: "10px" }}>
            <Typography style={{ color: "#757373", fontSize: "14px" }}>
              Most Popular Searches
            </Typography>
            <Grid style={{ paddingLeft: "6px" }}>
              {mostPopularSerchesData?.map((item) => (
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#757373",
                  }}
                  gap={1}
                >
                  <NorthWestIcon sx={{ color: "#cccccc", fontSize: "18px" }} />

                  <Typography
                    onClick={() => searchesClick(item)}
                    style={{ margin: "3px 0px", fontSize: "14px" }}
                  >
                    {item}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid> */}
      </Dialog>
      {/* {openDialogBox && (
        <Dialog fullScreen open={open}>
          <Grid>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Grid style={{ marginTop: "8px" }}>
                <Box
                  component="img"
                  sx={{
                    height: "29px",
                    width: "39px",
                    cursor: "pointer",
                  }}
                  alt="logo"
                  src="/assets/home/logoHeroSection.svg"
                  onClick={handleClose}
                />
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LocationOnOutlinedIcon
                  style={{ color: "rgba(149, 149, 149, 1)", height: "18px" }}
                />
                <Typography onClick={openDialogBoxFun}>
                  {topRegionValue}{" "}
                </Typography>
                <ExpandMoreOutlinedIcon />
              </Grid>
              {userProfile?.name[0] ? (
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: "rgba(71, 181, 158, 1)",
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  {userProfile?.name[0]}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: "rgba(71, 181, 158, 1)",
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  <Person sx={{ color: "white" }} />
                </Box>

              )}
            </Grid>

            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              sx={{
                border: "1px solid black",
                borderRadius: "10px",
                marginX: "10px",
                marginTop: "20px",
              }}
              onClick={handleChangeRegion}
            >
              <InputBase
                placeholder="Search Region"
                ref={inputRef} // Create a ref to the InputBase element
                value={searchInputResionValue}
                onChange={handleSearchRegionInputChange}
                sx={{
                  minWidth: "300px",
                  padding: "0px 9px 3px 11px",
                }}
                autoFocus
              />
              {searchInputResionValue && (
                <CloseIcon
                  style={{
                    color: "grey",
                    marginRight: "10px",
                    textAlign: "center",
                    paddingTop: global ? "4px" : "",
                    marginTop: global ? "" : "6px",
                    cursor: "pointer",
                  }}
                  onClick={handleClear}
                />
              )}
            </Grid>
          </Grid>
          <SearchCityMobileView
            searchTerm={searchTerm}
            handleClickOnRegion={handleClickOnRegion}
          />
        </Dialog>
      )} */}
    </>
  );
}
