import awardsService from "services/awardsService";
// import { NewCertificateInfo } from "interface/certificateIf";
export const AWARD_CREATE = "@award/create";
export const AWARD_CREATE_SUCCESS = "@award/create-success";
export const AWARD_CREATE_FAILURE = "@award/create-failure";

export const AWARD_DELETE = "@award/delete";
export const AWARD_DELETE_SUCCESS = "@award/delete-success";
export const AWARD_DELETE_FAILURE = "@award/delete-failure";



export function addAwards(supplierId, awardsTitle, awardsImage) {

  // console.log(
  //   "Add Certificate Action ",
  //   supplierId,
  //   awardsTitle,
  //   awardsImage
  // );
  return async (dispatch) => {
    // const newCertificate = new NewCertificateInfo(supplierId, values);
    try {
      // dispatch({});
      const payload = await awardsService.addAwards(
        supplierId,
        awardsTitle,
        awardsImage
      );


      dispatch({
        type: AWARD_CREATE_SUCCESS,
        payload: {
          ...payload,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: AWARD_CREATE_FAILURE });
      throw error;
    }
  };
}

export function deleteAward(supplierId, awardId, awardTitle) {
  // console.log("delet awards", supplierId, awardId, awardTitle)
  return async (dispatch) => {
    try {
      dispatch({
        type: AWARD_DELETE,
        payload: {
           awardId
        },
      });

      const payload = await awardsService.deleteAwards(
        supplierId, awardId, awardTitle
      );

      dispatch({
        type: AWARD_DELETE_SUCCESS,
        payload: { ...payload },
      });
    } catch (error) {
      dispatch({ type: AWARD_DELETE_FAILURE });
      throw error;
    }
  };
}
