import React from "react";

import { Chip } from "@mui/material";
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { Button } from "@mui/material";
import { dateFormatter } from "utils/formatUtils";
import { ConfirmDialog } from "components";
import { Link } from 'react-router-dom';
import LeadView from "./LeadView";
import { useNavigate } from "react-router-dom";

export const headCells = (
  showView,
  sendRFQAck,
  modal,
  setModal,
  supplierId,
  renderCellExpand,
  handleClickLeadsViewData
) => [
    // { field: 'rfqId', headerName: 'Id', minWidth: 50 },
    // {
    //   field: "name",
    //   headerName: "Name",
    //   minWidth: 150,
    //   renderCell: renderCellExpand,
    // },
    // {
    //   field: "projectName",
    //   headerName: "Project Name",
    //   minWidth: 170,
    //   renderCell: renderCellExpand,
    // },
    // {
    //   field: "summary",
    //   headerName: "Summary",
    //   minWidth: 300,
    //   renderCell: renderCellExpand,
    // },
    // {
    //   field: "srNo",
    //   headerName: "Sr No",
    //   headerAlign: 'center',
    //   align: 'center',
    //   width: 60,
    //   sortable: false
    // },
    {
      field: "action",
      headerName: "Lead Details",
      headerAlign: 'center',
      width: 150,
      sortable: false,

      renderCell: (params) => {
        const onClickView = (e) => {
          e.stopPropagation();
        };

        return (
          <>
            {showView && params.id && (
              <>
                <LeadView
                  title="View Requirement"
                  isOpen={modal === params.id && params.id}
                  confirmAction={() => {
                    setModal(false);
                    // console.log("supplierId", supplierId);
                    sendRFQAck(supplierId, params.id);
                  }}
                  cancelAction={() => setModal(false)}
                  rfq={params.row}
                />
                <Button component={Link} sx={{ color: 'blue' }} target="_blank" to={`/leads/${params.id}`}
                //  onClick={() => { handleClickLeadsViewData(params.id) } }
                >
                  <u> Click here to view</u>
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "leadStatus",
      headerName: "Status",
      headerAlign: 'center',
      width: 110,
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.value}
          variant="outlined"
          size="small"
          style={{
            color: params.value === 'Unread' ? 'grey' : '#1212c6',
            border: "none",
            // borderColor: params.value === 'Delivered' ? 'grey' : 'blue',
          }}
          // color={params.value === 'Delivered' ? 'grey' : 'secondary'}
          icon={params.value == "Unread" ? <DoneAllIcon /> : <DoneAllIcon color="#1212c6" />}
        />

      ),
    },
    {
      field: "rfqId",
      headerName: "Lead ID",
      headerAlign: 'center',
      align: 'center',
      // sortable: false,
      width: 80,
    },
    {
      field: "createDate",
      headerName: "Received Date",
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormatter(params.row.createDate),
      sortable: false,
      width: 130,
    },

    {
      field: "userBusinessName",
      headerName: "Company Name",
      headerAlign: 'center',
      width: 300,
      sortable: false
    },
    // {
    //   field: "location",
    //   headerName: "Project Location",
    // },
    // {
    //   field: "acknowledge",
    //   headerName: "Acknowledge Status",
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     let ackStatus = "Pending";
    //     if (
    //       params &&
    //       params?.row &&
    //       params?.row?.suppliers &&
    //       params?.row?.suppliers?.length > 0
    //     ) {
    //       ackStatus = "Acknowledged";
    //     }
    //     return ackStatus;
    //   },
    // },
    // {
    //   field: "validityDate",
    //   headerName: " Valid Till",
    //   valueGetter: (params) => dateFormatter(params.row.validityDate),
    // },
    // {
    //   field: "supplyby",
    //   headerName: " Supply by",
    //   valueGetter: (params) => dateFormatter(params.row.supplyby),
    // },
    {
      field: "workStartDate",
      headerName: " Supply by",
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormatter(params.row.workStartDate),
      // sortable: false
    },
    // {
    //   field: "projectName",
    //   headerName: "Project Name",
    //   renderCell: renderCellExpand,
    //   width: 150,
    // },
    {
      field: "creditPeriod",
      headerName: "Credit Period ",
      align: 'center',
      headerAlign: 'center',
      width: 120,
      sortable: false
    },
    {
      field: "location",
      headerName: "Project Location",
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: renderCellExpand,
      sortable: false
    },
    // {
    //   field: "workEndDate",
    //   headerName: "Work End Date",
    //   valueGetter: (params) => dateFormatter(params.row.workEndDate),
    //   width: 150,
    // },
  ];
