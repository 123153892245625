import { Typography } from "@mui/material";
import React from "react";
import { dateFormatter } from "utils/formatUtils";

const repeatFixedUtils = () => [
  {
    field: "createdAt",
    headerName: "Create Date",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => dateFormatter(params.row.createdAt),
    width: 120,
    sortable: false,
  },
  {
    field: "repeatRfqId",
    headerName: "Order Id",
    headerAlign: "center",
    align: "center",
    width: 150,
    sortable: false,
  },
  {
    field: "orderName",
    headerName: "Order Name",
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 300,
    align: "center",
    renderCell: (params) => {
      return (
        <>
          {/* <Tooltip title={params.value} arrow> */}
          <Typography
            sx={{
              textAlign: "start",
              width: 190,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "default",
            }}
            title={params?.value}
          >
            {params.value}
          </Typography>
          {/* </Tooltip> */}
        </>
      );
    },
    sortable: false,
    headerAlign: "center",
  },
  {
    field: "orderValue",
    headerName: "Order Value",
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
    align: "center",
  },
  {
    field: "vendorName",
    headerName: "Vendor Name",
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "typeOfOrder",
    headerName: "Order Type",
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
];

export default repeatFixedUtils;
