import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Minimal from "layouts/Minimal";
import Logo from "components/Logo";
import { Helmet } from "react-helmet";

import AboutNeevay from "../components/AboutNeevay";
import SignInSendMobileOTPForm from "./SignInSendMobileOTPForm";
import SignIn from "./SignIn";

function SignInSendMobileOTP() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const navigate = useNavigate();
  const location =useLocation();
  const handleSubmitSuccess = () => {
    navigate("/signin-mobile-otp-verify",{state:location.state});
  };

  return (
    <Minimal>
      <Helmet>
        <title>Neevay - SignIn Mobile</title>
      </Helmet>

      {isMd ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid
              item
              height="100vh"
              xs={12}
              sm={12}
              md={6}
              sx={{
                backgroundColor: "black",
                color: "#ffffff",
              }}
            >
              <Grid py={"100px"}>
                <AboutNeevay />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <SignIn onSubmitSuccess={handleSubmitSuccess} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <SignIn onSubmitSuccess={handleSubmitSuccess} />
            </Grid>
            <Grid
              item
              // height="100vh"
              xs={12}
              sm={12}
              md={6}
              sx={{
                backgroundColor: "primary.main",
                color: "#ffffff",
              }}
            >
              <Grid py={"100px"}>
                <AboutNeevay />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Minimal>
  );
}

export default SignInSendMobileOTP;
