import imageService from "services/imageService";
import { IMAGE_TYPE } from "config";

export const IMAGE_UPLOAD = "@image/upload";
export const IMAGE_UPLOAD_SUCCESS = "@image/upload-success";
export const IMAGE_UPLOAD_FAILURE = "@image/upload-failure";

export const DELETE_IMAGE = "@image/delete";
export const DELETE_IMAGE_SUCCESS = "@image/delete-success";
export const DELETE_IMAGE_FAILURE = "@image/delete-failure";

export const BUSINESS_IMAGE_UPLOAD_SUCCESS = "@banner/update-success";

export function uploadImage(file, type, name, supplierId, id) {
  return async (dispatch) => {
    try {
      // console.log("ImageAction.uploadImage:", file, type, name, supplierId);
      dispatch({
        type: IMAGE_UPLOAD,
        payload: {
          file,
          type,
          name,
          supplierId,
          id,
        },
      });
      // console.log("ImageAction.uploadImage:", file, type, name, supplierId);

      const resp = await imageService.uploadImage(file, type, name, supplierId, id);

      // console.log("ImageAction.uploadImage: Resp:", resp);
      if( type === IMAGE_TYPE.PROJECT || type === IMAGE_TYPE.LOGO || type === IMAGE_TYPE.SERVICE || type === IMAGE_TYPE.BANNER){
        dispatch({
          type: BUSINESS_IMAGE_UPLOAD_SUCCESS,
          payload: {
            ...resp,
          },
        });
      }

      dispatch({
        type: IMAGE_UPLOAD_SUCCESS,
        payload: {
          resp,
        },
      });
    } catch (err) {
      // console.log("ImageAction.uploadImag: Error", err);

      let errMsg = err?.message;
      const error = { message: errMsg };

      dispatch(
        { type: IMAGE_UPLOAD_FAILURE, 
          payload:{
        message:errMsg
      } });
      throw error;
    }
  };
}


export function deleteImage(type, supplierId, id){
 
  return async(dispatch)=>{
    try{
      dispatch({
        type: DELETE_IMAGE,
        payload: {
          type,
          supplierId,
          id,
        },
      })
      const resp = await imageService.deleteImage(type, supplierId, id);

      dispatch({
        type: DELETE_IMAGE_SUCCESS,
        payload: {
          ...resp,
        },
      })
    }catch(err){

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };
      dispatch({ type: DELETE_IMAGE_FAILURE });
      throw error;
     
    }
  }
 
}