import { Button } from "@mui/base";
import { Delete } from "@mui/icons-material";
import { Grid, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ImageEditor } from "components";
import { IMAGE_TYPE } from "config";
import { DEFAULT_IMAGES } from "config";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export const ProjectRow = ({
  handleDeleteProject,
  handleEditProject,
  permissions,
  isEditView,
  data,
  cellStyle,
  index,
  profile,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [showFullText, setShowFullText] = useState(false);
  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };
    return (
    <TableRow>
      <TableCell style={{ width: isEditView && "200px" }}>
        {isEditView ? (
          <ImageEditor
            type={IMAGE_TYPE.PROJECT}
            name={data.projectName}
            supplierId={profile?.supplierId}
            inImage={
              data.projectImageUrl === undefined || data.projectImageUrl === ""
                ? undefined
                : BASE_URL + data.projectImageUrl
                  ? `${data.projectImageUrl === undefined ||
                    data.projectImageUrl === ""
                    ? undefined
                    : BASE_URL + data.projectImageUrl
                  }`
                  : DEFAULT_IMAGES.PROJECT
            }
            id={data._id}
          />
        ) : (
          <Box
            component="img"
            alt="img"
            src={
              data?.projectImageUrl
                ? `${BASE_URL}/${data?.projectImageUrl}`
                : DEFAULT_IMAGES.SERVICE
            }
            sx={{ width: "150px" }}
          />
        )}
        {/* <ImageEditor/> */}
      </TableCell>
      <TableCell style={cellStyle} width={isEditView && "110px"}>{data.projectName}</TableCell>
      <TableCell style={cellStyle} width={isEditView && "110px"}>{data.clientName}</TableCell>
      <TableCell style={cellStyle} >
        {permissions && permissions.isUpdateAllowed && (
          <Box
            mr={3}
            sx={{
              display: "flex",
              justifyContent: "end",
              textAlign: "right",
            }}
          >
            <>
              <Typography variant="body2" sx={{ mr: 0 }}>
                <Tooltip title="Edit">
                  <div
                    disabled={!permissions?.isOwner}
                    sx={{ color: "primary.main" }}
                    onClick={() => handleEditProject(data)}
                  >
                    <Link style={{ fontSize: "14px", color: "#2864BE", cursor: "pointer" }}>
                      {" "}
                      Edit
                    </Link>
                  </div>
                </Tooltip>
              </Typography>

              <Typography variant="body2">
                <Tooltip title="Delete">
                  <div
                    disabled={!permissions?.isOwner}
                    sx={{ color: "primary.main" }}
                    onClick={() =>
                      handleDeleteProject(data.projectName, data._id)
                    }
                  >
                    <Delete
                      style={{
                        fontSize: "18px",
                        color: "#2864BE",
                        marginTop: "6px",
                        marginLeft: "6px",
                        cursor: "pointer"
                      }}
                    />
                  </div>
                </Tooltip>
              </Typography>
            </>
          </Box>
        )}
        {data.description.length > 200 ? (
          <Grid>
            <Typography fontSize="14px" fontWeight="400">
              {showFullText ? data.description : data.description.slice(0, 200)}
              {showFullText ? " " : "...  "}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#2864BE",
                  cursor: "pointer"
                }}
                onClick={toggleFullText}
              >
                {" "}
                {showFullText ? "Read Less" : "Read More"}{" "}
              </span>
            </Typography>
          </Grid>
        ) : (
          <Typography fontSize="14px" fontWeight="400">
            {data.description ? data.description : "Description"}
          </Typography>
        )}
      </TableCell>
      {/*      */}
    </TableRow>
  );
};
