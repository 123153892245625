import { Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

function BusinessListData({ data, url, index, ItemClick }) {
  const [openHoverTooltip, setOpenHoverTooltip] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const isSelected = url == data?.url ? true : false;
  let defaultImg = "/assets/default/default-search-image.jpg";

  const logo =
    data?.logoUrl && data?.logoUrl !== ""
      ? data?.logoUrl.startsWith("/assets/business")
        ? `${BASE_URL}${data?.logoUrl}`
        : data?.logoUrl
      : defaultImg;
      
  //create a business area value if dist not available the store the value of city
  const businessArea = data.addresses_district
    ? data.addresses_district
    : data.addresses_city;

  const businessLocation =
    businessArea && data.addresses_state
      ? `${businessArea}, ${data.addresses_state}`
      : businessArea
      ? businessArea
      : data.addresses_state
      ? data.addresses_state
      : "";

  const capitalizeFirstLetter = (str) => {
    if (typeof str === "string") {
      return str
        ?.split(" ")
        ?.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        ?.join(" ");
    } else {
      return str;
    }
  };

  const handleCloseTooltip = () => setOpenHoverTooltip(false);

  const handleOpeneTooltip = () => {
    if (data?.businessName[0]?.length > 23) setOpenHoverTooltip(true);
  };

  return (
    <Grid
      onClick={() => ItemClick(data?.url)}
      sx={{
        borderBottom: "1px solid rgba(153, 153, 153, 1)",
        backgroundColor: isSelected ? "white" : "rgba(239, 247, 250, 1)",
        "&:hover": {
          boxShadow: "0px 0px 29px 0px rgba(0,0,0,0.8)",
          transform: "scale(1.01)",
        },
      }}
    >
      {data?.subscriptionType == "PREMIUM" ? (
        <Box
          component="img"
          sx={{
            height: "18px",
            // width: "60px",
            paddingTop: "5px",
            paddingLeft: "5px",
          }}
          src="/assets/home/verified.png"
        />
      ) : (
        <Box sx={{ height: "17px", marginTop: "8px" }}></Box>
      )}
      <Grid
        sx={{
          display: "flex",
          gap: "15px",
          padding: "16px 0 16px 24px",
        }}
      >
        <Grid>
          <Box
            component="img"
            sx={{ height: "56px", width: "56px" }}
            alt="Logo"
            src={logo}
          />
        </Grid>
        <Grid>
          <Tooltip
            title={data?.businessName}
            open={openHoverTooltip}
            onClose={handleCloseTooltip}
            onOpen={handleOpeneTooltip}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "rgba(10, 102, 194, 1)",
                width: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data?.businessName}
              {/* {data.businessName} */}
            </Typography>
          </Tooltip>
          <Typography>
            {/* {data.businessCategory} */}
            {businessLocation}
          </Typography>
          <Grid display={"flex"} justifyContent={"space-between"}>
            {data.establishYear ? (
              <Typography
                style={{
                  marginBottom: "10px",
                  color: "rgba(68, 64, 64, 1)",
                }}
              >
                {new Date().getFullYear() - data?.establishYear > 0 &&
                  `${
                    new Date().getFullYear() - data?.establishYear
                  } Years in Business`}
              </Typography>
            ) : (
              <Typography sx={{ marginBottom: "30px" }}></Typography>
            )}
            {data?.isISOCertified && data?.gstCertified == "true" ? (
              <Grid display={"flex"} gap={1}>
                <Box
                  component="img"
                  sx={{ height: "19px", width: "19px" }}
                  src="/assets/home/Iso.svg"
                />

                <Box
                  component="img"
                  sx={{ height: "19px", width: "19px" }}
                  src="/assets/home/gst.png"
                />
              </Grid>
            ) : data?.isISOCertified ? (
              <Box
                component="img"
                sx={{ height: "19px", width: "19px" }}
                src="/assets/home/Iso.svg"
              />
            ) : data?.gstCertified == "true" ? (
              <Box
                component="img"
                sx={{ height: "19px", width: "19px" }}
                src="/assets/home/gst.png"
              />
            ) : (
              <Box />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BusinessListData;
