import {
  Button,
  Grid,
  Skeleton,
    Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchCard from "./SearchCard";
import { useNavigate } from "react-router-dom";
import SearchCardSkeleton from "./SearchCardSkeleton";
import searchService from "services/searchService";
import { processSearchResults } from "utils/searchUtils";
import MobileSearchCardContainer from "./MobileSearchCardContainer";
import { useTheme } from "@mui/material/styles";

const SearchCardsContainer = ({
  checkedItems,
  setCheckedItems,
  searchBy,
  searchString,
  businessCategory,
  count,
  results,
  showResults,
  regionString,
  region,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [showSkeletonData, setShowSkeletonData] = useState(true);
  const [showCardsData, setShowCardsData] = useState([]);

  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const modifySearchString = searchString.replace(/-/g, " ");

  const handleAllCategoryResults = () => {
    // if (searchBy) {
    //   navigate(
    //     `/search-business-category/${searchString}/${searchBy}?Category=${businessCategory}`
    //   );
    // } else {
    navigate(
      `/search-business-category/${searchString}/${region}?Category=${businessCategory}`
    );
    // }
  };

  const getResultsOfBusinessCategory = async (businessCategory) => {
    const filterStr =
      regionString == "Pan India"
        ? `businessProfile.businessCategory_str:("${businessCategory}")`
        : `regions.Allcities_str:("${regionString}") AND businessProfile.businessCategory_str:("${businessCategory}")`;
    let getData;
    // if (searchBy) {
    //   if (searchBy == "name") {
    //     getData = await searchService.searchByName(modifySearchString, filterStr, 0);
    //   } else {
    //     getData = await searchService.searchByService(
    //       modifySearchString,
    //       filterStr,
    //       0
    //     );
    //   }
    // } else {
    getData = await searchService.search(modifySearchString, filterStr, 0);
    // }
    const responseData = processSearchResults(getData?.response?.docs);

    // console.log("!!!!!!! responseData ", responseData);

    if (responseData?.length > 0) {
      const sliceResults =
        responseData?.length > 6 ? responseData?.slice(0, 6) : responseData; //show only 1st 6 results
      setShowCardsData((preVal) => sliceResults);
      setShowSkeletonData(false);
    }
  };
  useEffect(() => {
    if (showResults) {
      // use if when the results is less than 4 and count is grether than 4 (this is happened besuce of api give 1st 60 results)
      if (
        (count > 6 && results?.length < 6) ||
        results == undefined ||
        (count < 6 && count > results?.length)
      ) {
        getResultsOfBusinessCategory(businessCategory);
      } else {
        setShowSkeletonData(false);
      }
    }
  }, [showResults]);

  useEffect(() => {
    setShowCardsData((preVal) =>
      results?.length > 6 ? results?.slice(0, 6) : results
    );
  }, [results]);

  return (
    <>
      {isSm ? (
        <MobileSearchCardContainer
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          count={count}
          searchBy={searchBy}
          searchString={searchString}
          businessCategory={businessCategory}
          showSkeletonData={showSkeletonData}
          handleAllCategoryResults={handleAllCategoryResults}
          region={region}
        />
      ) : (
        <Grid
          sx={{
            height: "290px",
            overflow: "hidden",
            backgroundColor: "white",
            padding: "8px 12px",
            borderRadius: "16px",
            marginBottom: "20px",
          }}
        >
          <Grid
            display="flex"
            gap="16px"
            alignItems="center"
            marginBottom={"8px"}
          >
            <Grid display="flex" gap="8px" alignItems="baseline">
              <Typography
                variant="h5"
                fontWeight={600}
                color={"rgba(10, 102, 194, 1)"}
              >
                {businessCategory}
              </Typography>
              {/* {count > 1 && (
                <Typography sx={{ color: "#757373", fontSize: "14px" }}>
                  {count}{" "}
                  {businessCategory[businessCategory?.length - 1] === "s"
                    ? businessCategory
                    : businessCategory + "s"}{" "}
                  Found
                </Typography>
              )} */}
            </Grid>
            <Grid>
              {count > 4 && (
                <Button
                  sx={{
                    // width: "128px",
                    height: "28px",
                    fontWeight: 500,
                    borderRadius: "6px",
                    background: "#04EEAD",
                    color: "black",
                    "&:hover": {
                      background: "#4CF0C0",
                      boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.25)",
                    },
                  }}
                  onClick={handleAllCategoryResults}
                >
                  View {count}&nbsp;
                  {" "} <span style={{ textTransform: "lowercase" }}>
                    more{" "}
                    {businessCategory[businessCategory?.length - 1] === "s"
                      ? businessCategory
                      : businessCategory + "s"}{" "}
                  </span>
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              // paddingLeft: "10px",
              gap: "16px",
              flexWrap: "wrap",
            }}
          >
            {showSkeletonData ? (
              <SearchCardSkeleton />
            ) : (
              showCardsData?.map((data) => (
                <Grid key={data?.url}>
                  <SearchCard
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    BusinessData={data}
                    region={region}
                    searchString={searchString}
                    searchBy={
                      searchBy == "name"
                        ? "byName"
                        : searchBy == "service"
                          ? "byService"
                          : "all"
                    }
                    filterUrl={`Category=${businessCategory}`}
                    currentPage={1}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SearchCardsContainer;
