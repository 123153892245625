import axios from "utils/axios";

import { LEADS_FETCH_URL, SEND_ACK_URL } from "config/services";

class LeadsService {

  fetchLeads = (vendorsIn, userId) =>
    new Promise((resolve, reject) => {

      // console.log("LeadsService.fetchLeads called");

      axios.post(LEADS_FETCH_URL, {vendorsIn, userId})
        .then((response) => {
          // console.log("LeadsService.fetchLeads() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("LeadsService.fetchLeads() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

  sendAckLead = (supplierId, rfqId, viewByName, viewById, acknowledgeDate) =>
    new Promise((resolve, reject) => {
      // console.log("LeadsService.sendAckLead called");
      const payload = { supplierId, rfqId, viewByName, viewById, acknowledgeDate };
      axios
        .post(SEND_ACK_URL, { payload })
        .then((response) => {
          // console.log("LeadsService.fetchLeads() resp:", response)
          if (response?.data?.error) {
            // console.log("Failed to fetch records");
            reject(response.data.message);
          } else {
            if (response?.data?.payload) {
              resolve(response?.data?.payload);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log("LeadsService.fetchLeads() error:", error);
            reject(error?.response?.data);
          }
          reject(error);
        });
    });
}

const leadsService = new LeadsService();

export default leadsService;
