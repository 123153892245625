import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { Formik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  Icon,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import { registerUserWithMobile, sendwhatsAppOTP } from "actions/authActions";
import SignInOtpVerifyFormPopUp from "../SignInOTPVerify/SignInOtpVerifyFormPopUp";
import { Link } from "react-router-dom";
import "./style.css";
import { CREATE_CRM_LEAD_URL } from "../../../config/services";
import axios from "utils/axios";
import { getCRMLeadByPhone } from "actions/crmAction";
import BlockedUserPopUp from "views/BlockedUserModal";

function SignInMobileOtpPopUp({
  setOpenLogInModal,
  handleCloseLogInModal,
  openLogInModal,
  className,
  ...rest
}) {
  const dispatch = useDispatch();

  const [otpPopUp, setOtpPopUp] = React.useState(true);
  const [mobileNumber, setMobileNumber] = React.useState();
  const [isWhatsappNo, setIsWhatsappNo] = React.useState(false);
  const [isUserBlocked, setIsUserBlocked] = React.useState(null);

  const onSubmitSuccess = () => {
    setOtpPopUp(false);
  };
  // modal style here
  const modalStyle = {
    borderRadius: "5px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxWidth: 440,
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 4,
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setIsWhatsappNo(checked);
  };

  // auto focus on text filled code start....
  // const inputRef = React.useRef();
  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 100);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);
  // auto focus on text filled code end....

  return (
    <Modal
      open={openLogInModal}
      onClose={handleCloseLogInModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {otpPopUp ? (
          isUserBlocked ? (
            <>
              <Typography
                style={{
                  fontSize: "24px",

                  textAlign: "center",

                  fontWeight: "600",

                  marginBottom: "14px",
                  color: "#FD0B0B",
                }}
              >
                Blocked !{" "}
              </Typography>

              <Grid
                style={{
                  backgroundColor: "#FFE9E9",
                  padding: "24px",
                  marginBottom: "14px",
                  borderLeft: "8px solid #F97C7C",
                  borderWidth: "8px",
                }}
              >
                <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
                  Your account has been blocked please contact us for
                  reactivating.
                </Typography>
              </Grid>
            </>
          ) : (
            <Formik
              initialValues={{
                mobile: "",
              }}
              validationSchema={Yup.object().shape({
                mobile: Yup.string()
                  .matches(
                    /^[6-9][0-9]{9}/,
                    "Please enter valid 10 digit mobile number"
                  )
                  .min(10)
                  .max(10)
                  .required("Please Enter your mobile"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                setMobileNumber(values.mobile);
                try {
                  const name = "";
                  if (isWhatsappNo) {
                    dispatch(sendwhatsAppOTP(values.mobile));
                  } else {
                    dispatch(registerUserWithMobile(name, values.mobile));
                  }
                  onSubmitSuccess();
                  const payload = [
                    {
                      Attribute: "EmailAddress",
                      Value: "",
                    },
                    {
                      Attribute: "Phone",
                      Value: values.mobile,
                    },
                  ];

                  await axios
                    .post(CREATE_CRM_LEAD_URL, payload)
                    .then((crmResponse) => {
                      // console.log(":CREATE_CRM_LEAD: Response",crmResponse)
                      dispatch(getCRMLeadByPhone(values.mobile));
                    })
                    .catch((crmError) => {
                      // console.log("CRMERROR",crmError)
                      dispatch(getCRMLeadByPhone(values.mobile));
                    });
                } catch (error) {
                  if (error?.response?.data?.isBlocked) {
                    setIsUserBlocked(error?.response?.data?.isBlocked);
                  }
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit} {...rest}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        color={"#000"}
                        alignItems={"center"}
                        p={1}
                        fullWidth
                        xs={12}
                        borderRadius={"5px"}
                        mt={-2}
                      >
                        <Icon
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "block",
                            alignSelf: "center",
                          }}
                        >
                          <img src="/assets/icons/userLogin.svg" />
                        </Icon>
                        <Typography variant="subtitle1">
                          Log in to continue
                        </Typography>
                      </Grid>

                      <TextField
                        error={Boolean(touched.mobile && errors.mobile)}
                        fullWidth
                        helperText={touched.mobile && errors.mobile}
                        label="Enter Mobile Number"
                        margin="normal"
                        name="mobile"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          // Only allow digits
                          const regex = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            regex.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        inputProps={{ maxLength: 10 }}
                        required
                        value={values.mobile}
                        variant="outlined"
                        autoFocus
                        // inputRef={inputRef} // use for auto focus
                      />
                      {values.mobile && (
                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            name="whatsappNo"
                            onChange={handleCheckboxChange}
                            checked={isWhatsappNo}
                          />
                          <Typography>Is this your WhatsApp Number?</Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {errors.submit && (
                        <Box mb={2}>
                          <FormHelperText error>
                            Something went wrong, please try again.
                          </FormHelperText>
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      alignItems={"center"}
                      justifyContent={"center"}
                      mt={-2}
                      xs={12}
                    >
                      <Button
                        className="customButtonLoginPopup"
                        // color="secondary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: "#FD6600",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#FD6600",
                            transition:
                              "background-color 0.3s, box-shadow 0.3s",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                          },
                        }}
                      >
                        Send Mobile OTP
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          )
        ) : (
          <>
            <SignInOtpVerifyFormPopUp
              isWhatsappNo={isWhatsappNo}
              setOtpPopUp={setOtpPopUp}
              setOpenLogInModal={setOpenLogInModal}
              mobile={mobileNumber}
            />
          </>
        )}
      </Box>
    </Modal>
  );
}

SignInMobileOtpPopUp.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

SignInMobileOtpPopUp.default = {
  onSubmitSuccess: () => {},
};

export default SignInMobileOtpPopUp;
