import ordersService from "services/ordersService";

export const ORDERS_FETCH = "@orders/fetch";
export const ORDERS_FETCH_SUCCESS = "@orders/fetch-success";
export const ORDERS_FETCH_FAILURE = "@orders/fetch-failure";

export const INVOICE_TO_PRINT = "@invoice/fetch";
export const INVOICE_TO_PRINT_SUCCESS = "@invoice/fetch-success";
export const INVOICE_TO_PRINT_FAILURE = "@invoice/fetch-failure";

export function fetchOrders() {
  return async (dispatch) => {
    try {
      dispatch({ type: ORDERS_FETCH });

      const orders = await ordersService.fetchOrders();

      // console.log("fetchOrders()(after):" + JSON.stringify(orders));
      dispatch({
        type: ORDERS_FETCH_SUCCESS,
        payload: {
          orders,
        },
      });
    } catch (error) {
      dispatch({ type: ORDERS_FETCH_FAILURE });
      throw error;
    }
  };
}
export function invoiceToPrint(supplierId, invoiceDetails) {
  // console.log("invoice To PRINT aCTION",invoiceDetails)
  return async (dispatch) => {
    try {
      dispatch({
        type: INVOICE_TO_PRINT,
        payload: { supplierId, invoiceDetails },
      });

      dispatch({
        type: INVOICE_TO_PRINT_SUCCESS,
        payload: {
          invoiceDetails,
        },
      });
    } catch (error) {
      dispatch({ type: INVOICE_TO_PRINT_FAILURE });
      throw error;
    }
  };
}
