import { Autocomplete, Chip, TextField } from "@mui/material";
import { addTag } from "actions/tagsActions";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ApplyTagsPopUp = ({
  tagsLoading,
  setIsOpen,
  handleAddTagsToVendors,
  showError,
}) => {
  const dispatch = useDispatch();
  const tagsNew = useSelector((state) => state.tags);
  const [newTag, setNewTag] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedOption, setSelectedOption] = useState("add");
  const [chipData, setChipData] = useState(tagsNew.tags);
  const handleApplyTags = () => {
    const selectedTags = selectedValues?.map((value) => value.tagName);
    handleAddTagsToVendors(selectedTags, selectedOption);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleClick = (data) => {
    // console.log(data.target.value);
    setNewTag(data.target.value);
  };

  const handleAddTag = () => {
    dispatch(addTag({ tagName: newTag }));
    setChipData([...chipData, { tagName: newTag }]);
  };

  useEffect(() => {
    setChipData(tagsNew.tags);
  }, [tagsNew.tags]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="relative mx-auto p-4 w-full max-w-md rounded-lg bg-white shadow-md">
        <div className="flex justify-between items-center pb-3 border-b border-gray-200">
          <h3 className="text-xl font-bold">Edit Tags</h3>
          <button onClick={() => setIsOpen(false)}>Close</button>
        </div>
        <div className="mb-3">
          <label className="inline-block text-base font-medium mb-2">
            Tag Type:
          </label>
          <div className="flex items-center space-x-3">
            <label>
              <input
                type="radio"
                name="tagType"
                value="add"
                checked={selectedOption === "add"}
                onChange={handleOptionChange}
              />
              <span className="ml-2">Add Tags</span>
            </label>
            <label>
              <input
                type="radio"
                name="tagType"
                value="remove"
                checked={selectedOption === "remove"}
                onChange={handleOptionChange}
              />
              <span className="ml-2">Remove Tags</span>
            </label>
          </div>
        </div>
        <Autocomplete
          sx={{ width: "100%" }}
          multiple
          id="multiple-limit-tags"
          // selectedValues={selectedValues}
          value={selectedValues}
          options={chipData}
          getOptionLabel={(option) => option.tagName}
          isOptionEqualToValue={(option, value) =>
            option.tagName === value.tagName
          }
          onChange={(event, newValue) => {
            setSelectedValues(newValue);
          }}
          renderInput={(params) => {
            return (
              <TextField
                onChange={(data) => handleClick(data)}
                {...params}
                placeholder="Search Tags"
              />
            );
          }}
          noOptionsText={<Chip label="add tag" onClick={handleAddTag} />}
        />
        <div className="flex justify-end">
          <button
            disabled={!selectedValues?.length >= 1}
            onClick={handleApplyTags}
            className={`px-4 py-2 mt-5 rounded-md ${
              selectedValues?.length >= 1
                ? "bg-blue-500 text-white hover:bg-indigo-600 focus:outline-none"
                : "bg-gray-300 text-white "
            } `}
          >
            {tagsLoading ? <Loading /> : "Apply"}
          </button>
        </div>
        {showError?.status && (
          <p className="text-red-700 text-xs ">{showError?.message} </p>
        )}
      </div>
    </div>
  );
};

export default ApplyTagsPopUp;
