import Loading from "components/Loading";
import { FieldArray, ErrorMessage, Field, useFormikContext } from "formik";
import React, { useState } from "react";

const ProjectDetailsView = ({ edit, loading, setProjectViewEdit }) => {
  //   console.log("9898989", data);
  const [isEdit, setIsEdit] = useState(!edit);
  const { values, errors, setFieldValue } = useFormikContext();
  const projectTypes = [
    "Residential",
    "Infra",
    "Commercial",
    "Hotel",
    "Hospital",
    "Other"
  ];
  return (
    <>
      <div className="mx-h-[60vh] overflow-auto">
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <label className="block text-sm font-bold" htmlFor="projectName">
              Project Name{isEdit && <span className="text-red-500">*</span>}
            </label>
            {isEdit ? (
              <Field
                name="projectName"
                type="text"
                className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            ) : (
              <p>{values?.projectName}</p>
            )}
            <ErrorMessage
              name="projectName"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-bold" htmlFor="projectName">
              Project Budget
            </label>
            {isEdit ? (
              <Field
                name="projectEstimation"
                type="text"
                className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            ) : (
              <p>{values?.projectEstimation || "--"}</p>
            )}
            <ErrorMessage
              name="projectEstimation"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-bold" htmlFor="projectType">
              Project Type{isEdit && <span className="text-red-500">*</span>}
            </label>
            {isEdit ? (
              <Field
                as="select"
                name="projectType"
                className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              >
                <option value="" label="Select type" />
                {projectTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Field>
            ) : (
              <p>{values?.projectType}</p>
            )}
            <ErrorMessage
              name="projectType"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-bold" htmlFor="area">
              {"Address line 1"}
              
            </label>
            {isEdit ? (
              <Field
                name="area"
                type="text"
                className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            ) : (
              <p>{values?.area}</p>
            )}
            <ErrorMessage
              name="area"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-bold" htmlFor="city">
              City{isEdit && <span className="text-red-500">*</span>}
            </label>
            {isEdit ? (
              <Field
                name="city"
                type="text"
                className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            ) : (
              <p>{values?.city}</p>
            )}
            <ErrorMessage
              name="city"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-bold" htmlFor="state">
              State{isEdit && <span className="text-red-500">*</span>}
            </label>
            {isEdit ? (
              <Field
                name="state"
                type="text"
                className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            ) : (
              <p>{values?.state}</p>
            )}
            <ErrorMessage
              name="state"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-bold">
              {" "}
              Region
            </label>
            {isEdit ? (
              <Field
                as="select"
                name="region"
                id="region"
                className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              >
                <option value="">Select Region</option>
                <option value="NORTH India">North India</option>
                <option value="NORTH-EAST India">North-East India</option>
                <option value="EAST India">East India</option>
                <option value="CENTRAL India">Central India</option>
                <option value="WEST India">West India</option>
                <option value="SOUTH India">South India</option>
              </Field>
            ) : (
              <p>{values?.region}</p>
            )}
            <ErrorMessage
              name="region"
              component="div"
              className="mt-2 text-sm text-red-500"
            />
          </div>

          <div>
            <label
              className="block text-sm font-bold"
              htmlFor="projectDirector"
            >
              Project Director
            </label>
            {isEdit ? (
              <Field
                name="projectDirector"
                type="text"
                className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            ) : (
              <p>{values?.projectDirector}</p>
            )}
            <ErrorMessage
              name="projectDirector"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>
        </div>
        <div className="pt-4">
          {values.engineerDetails.length > 1 ?  <h3 className="font-bold text-base">Project Engineer Details</h3> : isEdit ? <h3 className="font-bold text-base">Project Engineer Details</h3> : ""}
          <FieldArray
            name="engineerDetails"
            render={(arrayHelpers) => (
              <div>
                {values.engineerDetails.map((engineer, index) => (
                  <div key={index} className="flex gap-5 pt-3 pb-3">
                    <div>
                      <label className="block text-sm font-bold">
                        Name
                      </label>
                      {isEdit ? (
                        <Field
                          type="text"
                          name={`engineerDetails.${index}.name`}
                          className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      ) : (
                        <p>
                          {engineer.name !== "" ? engineer.name : "--"}
                        </p>
                      )}
                      <ErrorMessage
                        name={`engineerDetails.${index}.name`}
                        component="div"
                        className="mt-2 text-sm text-red-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-bold">
                        Contact Number
                      </label>
                      {isEdit ? (
                        <Field
                          type="text"
                          name={`engineerDetails.${index}.mobile`}
                          onKeyDown={(event) => {
                            if (
                              event.target.value.length >= 10 &&
                              event.key !== "Backspace"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          className="mt-1 focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-44 shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      ) : (
                        <p>
                          {engineer.mobile !== "" ? engineer.mobile : "--"}
                        </p>
                      )}
                      <ErrorMessage
                        name={`engineerDetails.${index}.mobile`}
                        component="div"
                        className="mt-2 text-sm text-red-500"
                      />
                    </div>
                    {isEdit && values.engineerDetails.length > 1 && (
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        className="mt-6"
                      >
                        <svg
                          className="w-6 h-6"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          tabindex="-1"
                          title="DeleteOutline"
                        >
                          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM8 9h8v10H8zm7.5-5-1-1h-5l-1 1H5v2h14V4z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                ))}
                {isEdit && (
                  <button
                    type="button"
                    onClick={() => arrayHelpers.push({ name: "", mobile: "" })}
                    className="underline p-2"
                  >
                    +Add More
                  </button>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div
        className="px-1 py-2 sticky bottom-0 shadow-lg bg-gray-50 mx-[-18px]"
        style={{
          boxShadow: "0px -1px 16.100000381469727px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        {edit ? (
          <div>
            {isEdit ? (
              <button
                type="submit"
                className="bg-blue-500 rounded-lg text-white p-2 mt-4"
              >
                {loading ? <Loading /> : "Save Changes"}
              </button>
            ) : (
              <button
                type="button"
                className="bg-blue-500 rounded-lg text-white p-1 px-3 mt-4"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(true);
                  setProjectViewEdit(false);
                }}
              >
                Edit
              </button>
            )}
          </div>
        ) : (
          <button
            type="submit"
            className="bg-blue-500 rounded-lg text-white p-2 mt-4"
          >
            Save & Next
          </button>
        )}
      </div>
    </>
  );
};

export default ProjectDetailsView;
