import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DearTeamTemplateSection = ({
  setFormData,
  setFieldValue,
  handleChange,
  values,
  errors,
  touched,
  handleOnBlur,
}) => {
  return (
    <>
      <Grid sx={{ mt: "25px" }}>
        <Typography>Dear Team,</Typography>

        <Grid sx={{ marginTop: "8px" }}>
          <Grid
            display={"flex"}
            flexWrap={"wrap"}
            alignItems={"baseline"}
            gap="10px"
          >
            <Typography>
              Please quote your competitive rates for below requirement at
              earliest for our project
            </Typography>
            <TextField
              variant="standard"
              name="projectName"
              id="projectName"
              placeholder="Project name"
              onChange={handleChange}
              onBlur={() => handleOnBlur("projectName", values.projectName)}
              value={values.projectName}
              error={Boolean(touched.projectName && errors.projectName)}
              helperText={touched.projectName && errors.projectName}
            />
            <Typography> at the site : </Typography>
            <TextField
              variant="standard"
              required
              name="location"
              id="location"
              placeholder="Project Location"
              onChange={handleChange}
              onBlur={() => handleOnBlur("location", values.location)}
              inputProps={{ maxLength: 50 }}
              value={values.location}
              error={Boolean(touched.location && errors.location)}
              helperText={touched.location && errors.location}
            />
          </Grid>
        </Grid>

        <Grid sx={{ marginTop: "20px" }}>
          <Typography>
            You may contact our engineer for any technical details{" "}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
              alignItems: "baseline",
              marginTop: "16px",
            }}
          >
            {/* <TextField id="standard-basic" label="Engineer Name" variant="standard" /> */}
            <TextField
              variant="standard"
              size={"small"}
              name="siteEngineerName"
              id="siteEngineerName"
              placeholder="Engineer Name (Optional)"
              onChange={handleChange}
              onBlur={() =>
                handleOnBlur("siteEngineerName", values.siteEngineerName)
              }
              inputProps={{ maxLength: 25 }}
              value={values.siteEngineerName}
              error={Boolean(
                touched.siteEngineerName && errors.siteEngineerName
              )}
              helperText={touched.siteEngineerName && errors.siteEngineerName}
            />
            <Typography>at</Typography>
            {/* <TextField id="standard-basic" label="Engineer Phone Number" variant="standard" /> */}
            <TextField
              variant="standard"
              size={"small"}
              name="siteEngineerContact"
              id="siteEngineerContact"
              placeholder="Engineer Phone Number (Optional)"
              onChange={handleChange}
              style={{width:"250px"}}
              onBlur={() =>
                handleOnBlur("siteEngineerContact", values.siteEngineerContact)
              }
              inputProps={{ maxLength: 25 }}
              value={values.siteEngineerContact}
              error={Boolean(
                touched.siteEngineerContact && errors.siteEngineerContact
              )}
              helperText={
                touched.siteEngineerContact && errors.siteEngineerContact
              }
            />
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "baseline",
            gap: "8px",
            mt: "20px",
          }}
        >
          <Typography>Applicable credit period would be</Typography>
          {/* <TextField id="standard-basic" label="Credit Period" variant="standard" /> */}
          <TextField
            variant="standard"
            size={"small"}
            name="creditPeriod"
            id="creditPeriod"
            style={{width:"230px"}}
            placeholder="Credit Period (Optional)"
            onChange={handleChange}
            onBlur={() => handleOnBlur("creditPeriod", values.creditPeriod)}
            value={values.creditPeriod}
            error={Boolean(touched.creditPeriod && errors.creditPeriod)}
            helperText={touched.creditPeriod && errors.creditPeriod}
          />
          <Typography>and this requirement need to be supplied by</Typography>
          {/* <TextField id="standard-basic" label="Date" variant="standard" /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startDate"
              name="startDate"
              openTo="day"
              inputFormat="dd/MM/yyyy"
              views={["year", "month", "day"]}
              value={values.startDate}
              onChange={(newValue) => {
                setFormData((preVal) => ({
                  ...preVal,
                  ["startDate"]: newValue,
                }));
                setFieldValue("startDate", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  sx={{
                    width: "150px !important",
                    "& .MuiInputBase-input ": {
                      padding: "4px 8px !important",
                    },
                  }}
                  size={"small"}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default DearTeamTemplateSection;
