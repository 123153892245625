import clientService from "services/clientService";
export const CLIENT_CREATE = "@client/create";
export const CLIENT_CREATE_SUCCESS = "@client/create-success";
export const CLIENT_CREATE_FAILURE = "@client/create-failure";

export const CLIENT_DELETE = "@client/delete";
export const CLIENT_DELETE_SUCCESS = "@client/delete-success";
export const CLIENT_DELETE_FAILURE = "@client/delete-failure";

export function addClient(supplierId, clientName, clientLogoImage) {
  return async (dispatch) => {
    try {
      const payload = await clientService.addClient(
        supplierId,
        clientName,
        clientLogoImage
      );

      dispatch({
        type: CLIENT_CREATE_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: CLIENT_CREATE_FAILURE });
      throw error;
    }
  };
}


export function addMultipleClient(supplierId, chips,stepperCountValue) {
  return async (dispatch) => {
    
    try {
      const clientPayload = {supplierId:supplierId,clientNames:chips,stepperCountValue:stepperCountValue}
      const payload = await clientService.addMultipleClient(clientPayload);

      dispatch({
        type: CLIENT_CREATE_SUCCESS,
        payload: {
          ...payload,
        },
      });
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: CLIENT_CREATE_FAILURE });
      throw error;
    }
  };
}

export function deleteClient(supplierId, clientId, clientName) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLIENT_DELETE,
        payload: {
          clientId,
        },
      });

      const payload = await clientService.deleteClient(
        supplierId,
        clientId,
        clientName
      );

      dispatch({
        type: CLIENT_DELETE_SUCCESS,
        payload: { ...payload },
      });
    } catch (error) {
      dispatch({ type: CLIENT_DELETE_FAILURE });
      throw error;
    }
  };
}
