import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Typography, Grid, TextField, Box, Button, FormHelperText } from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from "utils/axios";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function BookDemoForm({ openCompo, setOpenCompo, setThankYouPopUp, ...rest }) {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"))
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMd ? 355 : 400,
        bgcolor: 'background.paper',
        border:"none",
        outlined: 'none',
        boxShadow: 24,
        borderRadius: "15px",
        p: isMd ? 3 : 4,
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        number:  Yup.number()
        .typeError('Mobile number must be a number')
        .required('Mobile number is required')
        .test(
          'len',
          'Mobile number must be 10 digits',
          (val) => val && val.toString().length === 10
        ),
    });
    const handleClose = () => setOpenCompo(false)
    return (
        <Formik
            initialValues={{ name: '', number: "", interestShown: "Book a Demo" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const response = await axios.post("/api/auth/demo/request", values);
                    if (response.data.error === false) {
                        setOpenCompo(false)
                        setThankYouPopUp(true)
                    }
                } catch (error) {
                    console.error("Error:", error);
                    setSubmitting(false);
                }
            }}
        >
            {({ handleSubmit, values, handleChange, errors, touched, handleBlur, }) => (
                <>
                    <form onSubmit={handleSubmit}{...rest}>
                        <Modal
                            open={openCompo}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Grid
                                    display={"flex"}
                                    gap={"16px"}
                                    flexDirection={"column"}
                                    justifyContent={"center"}
                                    sx={{ margin: "auto" }}
                                    style={{position:"relative"}}
                                >
                                    <Typography sx={{ fontSize: "20px", fontWeight: 500,textAlign:"center"}}>Thank you for showing interest !</Typography>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 400,textAlign:"center"}}>
                                        Kindly provide the following details
                                    </Typography>

                                    <Grid sx={{ display: "flex", gap: 4, alignItems: "center" }}>
                                        <label>Name<span style={{ color: "red" }}>*</span></label>
                                        <Grid>
                                            <TextField
                                                type="text"
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                size="small"
                                                placeholder="Name"
                                            />
                                            {touched.name && errors.name && (
                                                <FormHelperText sx={{ color: "red", marginLeft: "10px" }}>{errors.name}</FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                        <label>Number<span style={{ color: "red" }}>*</span></label>
                                        <Grid>
                                            <TextField
                                                // type="number"
                                                name="number"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                                    handleChange({ target: { name: 'number', value: numericValue } });
                                                  }}
                                                value={values.number}
                                                size="small"
                                                placeholder="Number"
                                            />
                                            {touched.number && errors.number && (
                                                <FormHelperText sx={{ color: "red", marginLeft: "10px" }}>{errors.number}</FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ textAlign: "center" }}>
                                        <Button
                                            onClick={handleSubmit}
                                            type='submit'
                                            variant="contained"
                                            // color="secondary"

                                            sx={{ borderRadius: "5px", padding: "5px 40px",  textAlign: "end", color:"white",fontWeight:"500", fontSize:"15px",
                                            backgroundColor: "#FD6600",
                                            color:"white",
                                            "&:hover": {
                                              backgroundColor: "#FD6600",
                                              transition: "background-color 0.3s, box-shadow 0.3s",
                                              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)"
                                            }
                                         }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                    </form>
                </>
            )}
        </Formik>
    );
}

export default BookDemoForm

