import React from "react";
import { Button, Modal } from "flowbite-react";
import Loading from "./Loading";
const WarningPopup = ({
  openModal,
  handleClosePopup,
  handleSubmit,
  warningText,
  loading = false,
}) => {
  return (
    <div>
      <Modal show={openModal} size="md" onClose={handleClosePopup} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              aria-hidden="true"
              class="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>

            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {warningText}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="blue" onClick={handleSubmit}>
                {loading ? <Loading /> : "Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={handleClosePopup}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WarningPopup;
